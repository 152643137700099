import { accountReducer } from '@/features/account/slice';
import { ordersReducers } from '@/features/order/slice';
import { widgetReducers } from '@/features/widget/slice';
import { vehicleTypesReducers } from '@/features/vehicle-types/slice';
import { customersReducers } from '@/features/customers/slice';
import { carriersReducers } from '@/features/carriers/slice';
import { securityReducers } from '@/features/security/slice';
import { spinnerReducers } from '@/features/spinner/slice';
import { bookerOrdersReducers } from '@/features/booker/slice';
import { myOrganizationsReducers } from '@/features/my-organizations/slice';
import { settingsReducers } from '@/features/settings/slice';
import { cargoTypesReducer } from '@/features/cargo/slice';
import { loadsReducer } from '@/features/loads/slice';
import { funnelReducers } from '@/features/funnel/slice';
import { carriersLoadsReducers } from '@/features/carriers-loads/slice';
import { resourcesReducers } from '@/features/resources/slice';

export const reducer = {
  account: accountReducer,
  orders: ordersReducers,
  widgets: widgetReducers,
  vehicleTypes: vehicleTypesReducers,
  customer: customersReducers,
  carriers: carriersReducers,
  security: securityReducers,
  spinner: spinnerReducers,
  bookerOrders: bookerOrdersReducers,
  myOrganizations: myOrganizationsReducers,
  settings: settingsReducers,
  cargoTypes: cargoTypesReducer,
  loads: loadsReducer,
  funnel: funnelReducers,
  carriersLoads: carriersLoadsReducers,
  resources: resourcesReducers,
};
