import { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { NotificationDeps } from 'config/constants/types';
import { AppNotification } from '@/types/models/app-notification';
import { UiButtonOutlined } from '@/ui-elements';
import './notification-full-info.scss';
import { useIcons } from '@/hooks/use-icons';
import { BooleanVoidFunction } from '@/types/common/void-function';
import { UiIconButtonSoaring } from '@/ui-elements/icon-button/icon-button-soaring';
import { formatMomentDate } from '@/helpers/moment/format-moment-date';
import { MOMENT_24_HOUR_TIME } from '@/helpers/moment/constants';
import { formatMomentLocal } from '@/helpers/moment/format-moment-local';
import { getNotificationMessage } from '@/features/notifications/get-notification-message';
import { getNotificationTitle } from '@/features/notifications/get-notification-title';
import { emptyFunction } from '@/helpers/empty-function';

export const NotificationFullInfo: FC<{ item: AppNotification; setInfoOpened: BooleanVoidFunction; navigateToOrderClick?: (id: number) => void }> = ({
  item,
  setInfoOpened,
  navigateToOrderClick = emptyFunction,
}) => {
  const { ArrowLeft } = useIcons();

  const hasOrderLink = item.action?.dependent === NotificationDeps.Order && item.action.dependentId;

  const openOrder = () => {
    if (item.action?.dependent === NotificationDeps.Order) navigateToOrderClick(item.action?.dependentId);
  };

  return (
    <Box className="notification-full-info" key={item.id}>
      <Box className="notification-full-info__arrow">
        <UiIconButtonSoaring icon={ArrowLeft} onClick={() => setInfoOpened(false)} aria-label="close-btn" />
      </Box>
      <Box as="h4" fontWeight="700">
        {getNotificationTitle(item.action)}
      </Box>
      <Box as="span" className="notification-full-info__date">
        {formatMomentDate(item.created)} {formatMomentLocal(item.created, MOMENT_24_HOUR_TIME)}
      </Box>
      <p className="notification-full-info__desc">{getNotificationMessage(item.action)}</p>
      {hasOrderLink && (
        <UiButtonOutlined className="notification-full-info__btn" mode="dark" onClick={openOrder}>
          Перейти к заявке
        </UiButtonOutlined>
      )}
    </Box>
  );
};
