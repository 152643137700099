export const ENTER = 'Войти';
export const INN_MASK = '************';
export const KPP_MASK = '*********'; // 9 цифр
export const OGRN_MASK = '*************'; // 13 цифр
export const OKPO_MASK = '**********'; // для юридических лиц – 8 цифр, для ИП – 10 цифр
export const PASSPORT_MASK = '**** ******';
export const REG_NUMBER_MASK = '* *** ** ***';
export const REG_NUMBER_TRAILER_MASK = '** **** ***';
export const LICENSE_MASK = '** ** ******';
export const DEBT_MASK = '***';
export const BANK_ACCOUNT_MASK = '********************';
