import { FC } from 'react';
import { UiDivider } from '@/ui-elements';
import { LoadTitles } from '@/components/loads/titles/load-titles';
import { Order } from '@/types/models/order';
import { CarrierLoadExecutionDetails } from './execution-details/load-execution-details';
import { CarrierLoadPaymentDetails } from './payment-details/carrier-load-payment-details';
import { CarrierLoadRouteDetails } from './route-details/load-route-details';

interface Props {
  load: Order | null;
}

export const CarrierLoadDetails: FC<Props> = ({ load }) => {
  if (!load) return null;
  return (
    <div>
      <LoadTitles name={load.customer?.name} id={load.id} />
      <UiDivider mt={6} mb={6} variant="dark" />
      <CarrierLoadPaymentDetails load={load} />
      <UiDivider mt={6} mb={6} variant="dark" />
      <CarrierLoadExecutionDetails load={load} />
      <UiDivider mt={6} mb={6} variant="dark" />
      <CarrierLoadRouteDetails load={load} />
    </div>
  );
};
