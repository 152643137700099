import * as yup from 'yup';
import { ErrorMessage } from './error-messages';

const requiredString = yup.string().required(ErrorMessage.Required);
const requiredObject = yup.object().required(ErrorMessage.Required);
const requiredNumber = yup.number().required(ErrorMessage.Required);

export const createCarrierSchema = yup.object({
  organizationTypeID: requiredObject,
  name: requiredString,
  INN: requiredString,
  bik: requiredNumber,
  bankAccount: requiredNumber,
  bankName: requiredString,
  correspondentAccount: requiredNumber,
  vatType: requiredObject,
  contactName: requiredString,
  phone: requiredString,
  passportDate: requiredString,
  birthday: requiredString,
  legalAddress: requiredString,
});
