import { InputProps } from '@chakra-ui/react';
import { Props as SelectProps, AsyncSelect } from 'chakra-react-select';
import { DOWNLOADING, EMPTY_STRING } from 'config/constants/common-strings';
import classNames from 'classnames';
import { chakraStyles } from './config';
import { SelectLoadOptions } from './types';
import './ui-select.scss';

type Props = {
  className?: string;
  overflown?: boolean;
  loadOptions: SelectLoadOptions;
};

export const UiSelectAsync = ({
  placeholder = 'Выберите вариант',
  isClearable = true,
  className,
  overflown = false, // prop для вывода dropdown в портале, нужен, если dropdown обрезается родителем с overflow:hidden
  loadOptions,
  ...props
}: Props & Omit<InputProps, 'size' | 'variant' | 'placeholder'> & SelectProps) => {
  return (
    <AsyncSelect
      {...props}
      className={classNames('ui-select', className)}
      loadOptions={loadOptions}
      placeholder={placeholder}
      errorBorderColor="accentRed.100"
      focusBorderColor="products.200"
      loadingMessage={props.loadingMessage || (() => DOWNLOADING)}
      noOptionsMessage={props.noOptionsMessage || (() => EMPTY_STRING)}
      isClearable={isClearable}
      styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 1200 }) }}
      chakraStyles={chakraStyles}
      menuPortalTarget={overflown ? document.body : undefined}
      menuPosition={overflown ? 'fixed' : 'absolute'}
    />
  );
};
