import { createSlice } from '@reduxjs/toolkit';
import { SpinnerState } from '@/features/spinner/types';

const initialState: SpinnerState = {
  visible: false, // показывает или скрывает спиннер
};

const slice = createSlice({
  name: 'spinner',
  initialState,
  reducers: {
    show: (state) => {
      state.visible = true;
    },
    hide: (state) => {
      state.visible = false;
    },
  },
});

export const spinnerReducers = slice.reducer;
export const spinnerActions = slice.actions;
