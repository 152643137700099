import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/types/redux/root-state';

/**
 * NOTЕ это роль аккаунта
 */
export const accountDriverIdSelector = createSelector(
  (state: RootState) => state.account,
  (account) => account.user?.driverId ?? 0
);
