import { useMutation } from '@tanstack/react-query';
import { CodeApi } from '@/api/code';
import { CodeCheck, CodeSend } from '@/types/api/code-partial';

export const useCodeActions = () => {
  const sendCode = useMutation({
    mutationKey: ['sendCode'],
    mutationFn: (options: CodeSend) => CodeApi.sendCode(options),
  });

  const checkCode = useMutation({
    mutationKey: ['checkCode'],
    mutationFn: (query: CodeCheck) => CodeApi.getCodeCheck(query),
  });

  return { sendCode, checkCode };
};
