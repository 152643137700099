import { getEmptyStr } from '@/helpers/get-empty-str';
import { Customer } from '@/types/models/customer';

export const customerToApprovalForm = ({ customer }: { customer?: Customer }) => {
  const managerName = `${getEmptyStr(customer?.manager?.surname)} ${getEmptyStr(customer?.manager.name)} ${getEmptyStr(
    customer?.manager?.patronymic
  )}`;
  return {
    customer: customer?.name || 'Нет данных',
    inn: customer?.INN || 'Нет данных',
    contract: customer?.contractNumber || 'Нет данных',
    date: customer?.expiration || '',
    manager: managerName || '',
    comment: customer?.comment || 'Нет данных',
    tarif: customer?.tariffComment || 'Нет данных',
    vipCustomer: customer?.vipCustomer || '',
  };
};
