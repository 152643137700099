import { ApprovalsApi } from '@/api/approvals';
import { ApprovalsFilter } from '@/types/api/approvals';
import { useInfiniteQueryWithUpdates } from '@/hooks/use-infinite-query-with-updates';

export const useInfiniteApprovalsUpdates = (query?: ApprovalsFilter, perPage: number = 10) => {
  const queryFn =
    (isFirst: Boolean) =>
    ({ pageParam = 1 }) =>
      ApprovalsApi.getApprovals({ ...query, updates: !isFirst, perPage, page: pageParam });

  return useInfiniteQueryWithUpdates({
    queryKey: 'approvals',
    query,
    enabled: true,
    refetchOnWindowFocus: false,
    queryFn,
  });
};
