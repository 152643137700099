import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrdersApi } from '@/api/orders';

export const getCargoTypes = createAsyncThunk('CARGO_TYPES/GET', async (_, { rejectWithValue }) => {
  try {
    const data = await OrdersApi.getCargoTypes();
    return data.data;
  } catch (error) {
    return rejectWithValue((error as { response: { data: {} } })?.response?.data);
  }
});
