import { useInfiniteQuery } from '@tanstack/react-query';
import { QueryKeys } from 'config/constants/query-keys';
import { NotificationsApi } from '@/api/notifications';
import { isLastPage } from '@/helpers/api/is-last-page';

export const useNotifications = (onlyNew: boolean, perPage: number = 10) => {
  const notifications = useInfiniteQuery({
    queryKey: [QueryKeys.notifications, { onlyNew }],
    refetchOnWindowFocus: false,
    queryFn: ({ pageParam = 1 }) => NotificationsApi.getNotifications({ onlyNew, perPage, page: pageParam }),
    getNextPageParam: ({ pager }) => (isLastPage(pager) ? undefined : pager.page + 1),
  });
  return { notifications };
};
