import { FC, ReactNode } from 'react';
import { Box, Modal, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import closedIcon from 'public/assets/icons/CloseIcon.svg';
import { UiButtonFilled, UiButtonOutlined, UiIconButton } from '@/ui-elements';
import { useForm } from '@/helpers/forms/use-form';
import './order-modal-view.scss';

type Props = {
  initialValues?: any;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: any) => void;
  title: string;
  justifyTitle?: 'center' | 'left' | 'right';
  modalWidth?: number;
  children?: ReactNode;
  submitButtonText: string;
  cancelButtonText?: string;
  marginRight?: number;
  marginTop?: string | number;
  alignDesc?: 'center' | 'left' | 'right';
  desc?: string;
  accentXBtn?: boolean;
  buttonsAutoWidth?: boolean;
};

export const ModalView: FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  justifyTitle = 'center',
  modalWidth = 380,
  children,
  submitButtonText,
  cancelButtonText,
  marginRight = 0,
  marginTop = 500,
  alignDesc = 'center',
  desc,
  accentXBtn = false,
  buttonsAutoWidth = false,
  initialValues = {},
}) => {
  const Form = useForm<any>();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {/* затемнение фона */}
      <ModalOverlay />
      <Form onSubmit={onSubmit} initialValues={initialValues}>
        <ModalContent
          borderRadius={24}
          width={modalWidth}
          boxShadow="xl"
          pos="relative"
          mt={marginTop}
          maxW={modalWidth}
          w={modalWidth}
          mr={marginRight}
        >
          {!accentXBtn && (
            <Box className="close-icon">
              <UiIconButton aria-label="close-modal-button" icon={closedIcon} onClick={onClose} style={{ left: modalWidth - 36 }} />
            </Box>
          )}

          <ModalHeader marginTop={2} display="flex" justifyContent={justifyTitle} fontWeight={700} fontSize="large">
            {title}
          </ModalHeader>

          <Box paddingRight={6} paddingLeft={6} display="flex" flexDirection="column" justifyContent="center" textAlign={alignDesc}>
            {children}
            {desc}
          </Box>

          {accentXBtn ? (
            <Box display="flex" justifyContent="right" mb={8} mt={7} mr={6} ml={6}>
              <UiButtonOutlined width={buttonsAutoWidth ? 'auto' : '100%'} mode="dark" className="modal__button-cancel" onClick={onClose}>
                {cancelButtonText || 'Отменить'}
              </UiButtonOutlined>
              <UiButtonFilled width={buttonsAutoWidth ? 'auto' : '100%'} type="submit" mode="accent">
                {submitButtonText}
              </UiButtonFilled>
            </Box>
          ) : (
            <Box display="flex" justifyContent="right" mr={6} mb={8} mt={6}>
              <UiButtonOutlined mode="dark" className="modal__button-cancel" onClick={onClose}>
                {cancelButtonText || 'Отменить'}
              </UiButtonOutlined>
              <UiButtonFilled type="submit">{submitButtonText}</UiButtonFilled>
            </Box>
          )}
        </ModalContent>
      </Form>
    </Modal>
  );
};
