import { CarrierOrganizationList } from 'components/carrier-card/carrier-organization-list';
import { SearchCarrierFilter } from 'components/search-carrier/search-carrier-filter/search-carrier-filter';
import { useCallback, useRef, useState } from 'react';
import { WidgetContainer } from 'components/widget-container/widget-container';
import { toast } from 'react-toastify';
import { OrderExecutionStatus } from '@/config/constants/statuses';
import { useWidgetActions } from '@/features/widget/hooks/use-widget-actions';
import { useAllCarriers } from '@/features/carriers/hooks/use-all-carriers';
import { useOrderActions } from '@/features/order/hooks/use-order-actions';
import { WidgetContainers } from '@/features/widget/widget-containers';
import { useActiveOrder } from '@/features/order/hooks/use-active-order';
import useAutomaticCollapse from '@/hooks/use-auto-collapse';
import { SortType } from '@/config/constants/types';
import { CarrierDriverRequest, CarrierFilter } from '@/types/api/carrier-partial';
import { CarrierSearchFilter } from '@/components/search-carrier/search-carrier-filter/type';
import { useCarrierRequest } from '@/features/carriers/hooks/use-carrier-request';
import { useOrderInvalidate } from '@/features/order/hooks/use-order-invalidate';
import { useOrderUpdates } from '@/features/order/hooks/use-order-updates';
import { checkIsVatMatch } from '@/helpers/price/check-is-vat-match';

export const OrderSearchView = () => {
  const [filters, setFilters] = useState<CarrierFilter>({
    search: '',
    from: '',
    capacityFrom: '',
    volumeFrom: '',
    vehicleTypes: '',
    onlyAvailable: false,
    sortBy: 'Rating',
    sortType: SortType.Desc,
    riskGroup: '',
    radiusSearch: '',
  });

  const { compRef, handleExpandChange, isExpanded } = useAutomaticCollapse({ initialValue: true });
  const { shipmentRequestsInvalidate } = useOrderInvalidate();
  const { activeOrderId } = useActiveOrder();
  const { order: orderResp } = useOrderUpdates({ id: activeOrderId });
  const { data: order } = orderResp;
  const {
    allCarriers: { data: allCarriers, refetch: refetchCarriers },
  } = useAllCarriers({ ...filters, orderId: activeOrderId });
  const { addCarriersRequest, addAllCarriersRequest } = useCarrierRequest();
  const { CarrierWidget } = useWidgetActions(WidgetContainers.CarriersSearch);
  const { openReviews } = useOrderActions();
  const ref = useRef<HTMLDivElement>(null);

  const handleFilterChange = useCallback((values: CarrierSearchFilter) => {
    setFilters((prev) => {
      return {
        vehicleTypes: values.typeTC?.map((vehicleType) => vehicleType).join(',') || '',
        capacityFrom: values.weight || '',
        volumeFrom: values.volume || '',
        riskGroup: values.riskGroup?.value || '',
        from: values.from || '',
        where: values.where || '',
        radiusSearch: values.radius?.value || '',
        onlyAvailable: values.isfree || false,
        area: values.filial || false,
        sortBy: prev.sortBy,
        sortType: prev.sortType,
        search: values.search || '',
      };
    });
  }, []);

  const handleChangeSorting = useCallback(
    (sortType: SortType) =>
      setFilters((prev) => ({
        ...prev,
        sortType,
      })),
    []
  );

  const addAllCarriersRequestFunction = () => {
    // TODO убрать mock данные
    if (activeOrderId) {
      const req = {
        enabled: !!activeOrderId,
        executorsFilter: {
          addressSearch: 'string',
          radius: 'string',
          onlyEmpty: true,
          onlyWithSubscription: true,
          typeSuitable: true,
        },
        orderId: activeOrderId,
      };
      addAllCarriersRequest.mutate(req, {
        onSuccess: () => {
          toast.success('Запрошено');
          shipmentRequestsInvalidate(activeOrderId);
          refetchCarriers();
        },
      });
    }
  };

  const addCarriersRequestFunction = (requestParams: Omit<CarrierDriverRequest, 'orderId'>) => {
    if (activeOrderId)
      addCarriersRequest.mutate(
        { orderId: activeOrderId, ...requestParams },
        {
          onSuccess: () => {
            toast.success('Запрошено');
            shipmentRequestsInvalidate(activeOrderId);
            refetchCarriers();
          },
        }
      );
  };

  const showCarrierInfo = (data: { id: number }) => {
    CarrierWidget.show({ carrierId: data.id });
  };

  const orderVatType = order?.data.carrierPayment.vatType;

  const carriers = allCarriers?.data;
  const carriersDriversWithVehicles = carriers?.filter((carrier) => carrier.drivers.some((driver) => driver.vehicles?.length));
  const canRequestAll = Boolean(carriersDriversWithVehicles?.every(({ vatType }) => checkIsVatMatch({ vatType, orderVatType })));

  return (
    <div
      style={{
        height: '100%',
      }}
      ref={ref}
    >
      <SearchCarrierFilter
        canRequestAll={canRequestAll}
        addAllCarriersRequest={addAllCarriersRequestFunction}
        onClickExpand={handleExpandChange}
        isExpanded={isExpanded}
        onChangeSorting={handleChangeSorting}
        onFilterChange={handleFilterChange}
        filters={filters}
        foundAmount={(allCarriers as any)?.totalDrivers}
      />
      {carriersDriversWithVehicles?.map((item) => (
        <CarrierOrganizationList
          orderVatType={orderVatType}
          key={item.id}
          carrier={item}
          onClickShowInfo={showCarrierInfo}
          addCarriersRequest={addCarriersRequestFunction}
          openReviews={openReviews}
          scrollRef={compRef}
          readOnly={order?.data?.status !== OrderExecutionStatus.CarrierSearch}
        />
      ))}
      <WidgetContainer containerKey={WidgetContainers.CarriersSearch} parentRef={ref} />
    </div>
  );
};
