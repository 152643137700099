import './resources-statuses-list.scss';
import { VStack, Text } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { VerificationStatus } from '@/config/constants/statuses';
import { ResourcesCardStatus } from '@/components/resources-statuses-list/card-status/resources-card-status';

export const ResourcesStatusesList = ({ approvalStatuses }: { approvalStatuses?: Array<Partial<VerificationStatus>> }) => {
  const hasEmptyStatuses = isEmpty(approvalStatuses) || approvalStatuses?.every((approvalStatus) => !approvalStatus?.status);
  if (hasEmptyStatuses) return null;

  return (
    <VStack spacing="2" className="resources-statuses-list" alignItems="flex-start">
      <Text className="resources-statuses-list__subtitle">Статусы проверки:</Text>
      {approvalStatuses?.map((approvalStatus) => (
        <ResourcesCardStatus approvalStatus={approvalStatus} key={approvalStatus?.status} />
      ))}
    </VStack>
  );
};
