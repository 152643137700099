import { isNumber } from 'lodash';

export const getDaysString = (days: number) => {
  const daysString = days && isNumber(Number(days)) ? days?.toString() : '0';

  switch (daysString.at(-1)) {
    case '1':
      if (daysString.at(-2) === '1') {
        // 11
        return `${daysString} дней`;
      }
      return `${daysString} день`;
    case '2':
    case '3':
    case '4':
      if (daysString.at(-2) === '1') {
        // 12, 13, 14
        return `${daysString} дней`;
      }
      return `${daysString} дня`;
    default:
      return `${daysString} дней`;
  }
};
