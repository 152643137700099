import React, { FC } from 'react';
import { BookerSearch } from 'components/booker-orders/booker-orders-filters/type';
import { FormSpy } from 'react-final-form';
import { useForm } from '@/helpers/forms/use-form';
import { UiInputField } from '@/ui-elements';
import { emptyFunction } from '@/helpers/empty-function';
import { BookerFilterFields } from '@/features/booker/hooks/booker-filter';
import { FormAutosave } from '@/hooks/use-autosave';

/*
 * Учет оплаты рейсов search
 * */

type Props = {
  onSubmit?: () => void;
  handleFilterChange?: (values: BookerFilterFields) => void;
};
export const BookerOrdersSearch: FC<Props> = ({ onSubmit = emptyFunction, handleFilterChange = emptyFunction }) => {
  const Form = useForm<BookerSearch>();
  return (
    <Form
      initialValues={{
        search: '',
      }}
      onSubmit={onSubmit}
      className="booker-search"
    >
      <FormSpy subscription={{ values: true }}>{({ values }) => <FormAutosave values={values} onSave={handleFilterChange} />}</FormSpy>
      <UiInputField name="search" placeholder="Поиск" />
    </Form>
  );
};
