import React, { RefObject, useCallback, useEffect, useState } from 'react';
import { useRaf } from '@/hooks/use-raf';
import { emptyFunction } from '@/helpers/empty-function';
import { useWindowResize } from '@/hooks/use-window-resize';

export type WidgetPosition = { left: number; top: number };
export type WidgetSize = { x: number; y: number };
export type WidgetCoords = { x: number; y: number };

type ResizeProps = {
  ref: React.MutableRefObject<HTMLDivElement | null>;
  parentRef?: RefObject<HTMLDivElement | undefined>;
  onRelease?: () => void;
  isFixed?: boolean;
  topMinPosition?: number;
};

type DragDirection = 'right' | 'top' | 'left' | 'bottom' | 'drag';

/**
 * @ref перетаскиваемый элемент
 * @parentRef  - родительсткий контейнер
 * @isFixed -если true - отключает перемещения
 * @topMinPosition - минимальная позиция сверху
 * Возвращает
 * обработчики события "onPointerDown": pointerDrag, pointerRight, pointerBottom, pointerLeft, pointerTop
 *  @positionAndSize - новое положение и размер виджета
 */
const useDragWidget = (props: ResizeProps) => {
  const { ref, parentRef, onRelease = () => {}, isFixed = false, topMinPosition = 0 } = props;
  const [initial, setInitial] = useState<{ direction: DragDirection; pos: WidgetCoords; size: WidgetSize; offset: WidgetPosition } | null>(null);
  const widget = ref.current;
  const parent = parentRef?.current;

  const [parentSize, setParentSize] = useState<WidgetSize>({ x: 0, y: 0 });
  const [isChanged, setIsChanged] = useState(false);
  const [positionAndSize, setPositionAndSize] = useState<{ size: WidgetSize; position: WidgetPosition }>();

  const isVisible = useCallback(() => {
    const rect = widget?.getBoundingClientRect();
    if (rect) {
      const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
      const viewWidth = Math.max(document.documentElement.clientWidth, window.innerWidth);
      return !(rect.bottom < 0 || rect.top - viewHeight >= 0) && !(rect.right < 0 || rect.left - viewWidth >= 0);
    }
    return true;
  }, [widget]);

  /**
   * Центрирование в родительском контейнере
   * */
  const centerInParent = useCallback(() => {
    if (widget && parent) {
      // центрируем виджет относительно родительского
      const parentRect = parent.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const height = widget.clientHeight;

      // если брать из  parentRect - то ломается определение центра при скролле вниз
      const parentTop = parent.offsetTop;
      const positionY = (windowHeight - parentTop) / 2 - height / 2 + parentTop;
      const windowWidth = window.innerWidth;
      const width = widget.clientWidth;
      const parentLeft = parentRect.left;
      const positionX = (windowWidth - parentLeft) / 2 - width / 2 + parentLeft;

      widget.style.top = `${positionY}px`;
      widget.style.left = `${positionX}px`;
      widget.style.opacity = '1';

      setPositionAndSize({ size: { x: width, y: height }, position: { top: positionY, left: positionX } });
    }
  }, [widget, parent]);

  /**
   * При обновлении размеров родителького контейнре обновляем ограничения для перетаскивания и если виджет оказался не виден вытаскиваем его наверх
   * */
  const updateParentSize = useCallback(() => {
    const y = window.innerHeight;
    const x = window.innerWidth;
    setParentSize({ x, y });

    if (!isVisible() && widget) {
      centerInParent();
      widget.style.top = '0px';
    }
  }, [centerInParent, isVisible, widget]);

  useWindowResize({
    callback: updateParentSize,
  });

  // initial widget position and size
  useEffect(() => {
    centerInParent();
  }, [centerInParent, widget]);

  // parent div size
  useEffect(() => {
    updateParentSize();
  }, [parent, updateParentSize]);

  const movedX = (left: number, newPosition: { size: WidgetSize; position: WidgetPosition }) => {
    if (left < 0) return 0;
    // проверка на границу  по x
    return left + newPosition.size.x > parentSize.x ? parentSize.x - newPosition.size.x : left;
  };
  const movedY = (top: number, newPosition: { size: WidgetSize; position: WidgetPosition }) => {
    if (top < topMinPosition) return topMinPosition;
    // проверка на границу  по y
    return top + newPosition.size.y > parentSize.y ? parentSize.y - newPosition.size.y : top;
  };

  /** метод вычисления нового положения и размера виджета
   * xMove, yMove - координаты положения курсора.Если по координате нет изменений ее значение -undefined
   * move - признак идет изменения положения виджета
   * */
  const resize = (args: { xMove?: number; yMove?: number; move?: boolean }) => {
    if (!positionAndSize) return;
    const { xMove, yMove, move } = args;

    const resizable = ref.current;

    const newPosition = { ...positionAndSize };
    if (!initial) return;

    if (resizable) {
      if (move) {
        //    resizable.style.transform = 'unset';
        if (xMove) {
          const left = movedX(initial.offset.left + xMove - initial.pos.x, newPosition);
          newPosition.position.left = left;
          resizable.style.left = `${left}px`;
        }
        if (yMove) {
          const top = movedY(initial.offset.top + yMove - initial.pos.y, newPosition);
          newPosition.position.top = top;
          resizable.style.top = `${top}px`;
        }
      }
      setPositionAndSize(newPosition);
    }
  };
  const rafResize = useRaf(resize);
  const drag = (e: PointerEvent) => {
    rafResize({ yMove: e.clientY, xMove: e.clientX, move: true });
  };

  // подписка на события курсора после первичного нажатия
  useEffect(() => {
    if (initial) {
      document.addEventListener('pointermove', drag);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      document.addEventListener('pointerup', release);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      document.addEventListener('pointercancel', cancel);
      return () => {
        document.removeEventListener('pointermove', drag);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        document.removeEventListener('pointerup', release);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        document.removeEventListener('pointercancel', cancel);
      };
    }
    return emptyFunction;
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [initial]);

  const beforeMove = (direction: DragDirection, ev: React.PointerEvent) => {
    const resizable = ref.current;
    setIsChanged(true);
    if (resizable) {
      setInitial({
        size: { x: resizable.offsetWidth, y: resizable.offsetHeight },
        offset: { left: resizable.offsetLeft, top: resizable.offsetTop },
        pos: { x: ev.clientX, y: ev.clientY },
        direction,
      });
    }
  };

  // отмена последнего изменения положения
  const undo = () => {
    if (!positionAndSize) return;
    const resizable = ref.current;
    if (!resizable) return;
    const newPosition = { ...positionAndSize };
    const left = initial?.offset.left || 0;
    const top = initial?.offset.top || 0;

    resizable.style.left = `${left}px`;
    resizable.style.top = `${top}px`;

    newPosition.position.left = left;
    newPosition.position.top = top;
    setPositionAndSize(newPosition);
  };

  const cancel = () => {
    document.removeEventListener('pointermove', drag);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    document.removeEventListener('pointerup', release);
    document.removeEventListener('pointercancel', cancel);
  };

  const release = () => {
    onRelease();
    rafResize.cancel();
    document.removeEventListener('pointermove', drag);
    document.removeEventListener('pointerup', release);
    document.removeEventListener('pointercancel', cancel);
  };

  const pointerDrag = (ev: React.PointerEvent) => {
    if (!isFixed) {
      beforeMove('drag', ev);
      ev.preventDefault();
    }
  };

  return {
    undo,
    pointerDrag,
    positionAndSize,
    isChanged,
  };
};

export default useDragWidget;
