import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@/config/constants/query-keys';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useDriver } from '@/features/drivers/hooks/use-driver';
import { resourcesSelectedDriverIdSelector } from '@/features/resources/selectors';
import { useResetDriverPassword } from '@/features/user/hooks/use-reset-driver-password';
import { ResourcesFormFieldNames } from '@/features/resources/constants/form';

export const useResourcesResetPassword = ({ onClose }: { onClose: VoidFunction }) => {
  const client = useQueryClient();
  const selectedDriverId = useAppSelector(resourcesSelectedDriverIdSelector);
  const { driver: driverQuery } = useDriver(selectedDriverId!);
  const { data: response, isInitialLoading } = driverQuery;
  const driver = response?.data;
  const initialValues = {
    [ResourcesFormFieldNames.phoneNumber]: driver?.phone,
  };

  const { resetDriverPassword } = useResetDriverPassword();
  const handleSubmit = () => {
    if (driver?.userId) {
      resetDriverPassword.mutate(driver?.userId, {
        onSuccess: () => {
          client.invalidateQueries([QueryKeys.allDrivers]);
          client.invalidateQueries([QueryKeys.driver, selectedDriverId]);
          toast.success('Пароль успешно отправлен');
          onClose();
        },
        onError: () => {
          onClose();
        },
      });
    }
  };

  return {
    initialValues,
    loading: isInitialLoading,
    handleSubmit,
  };
};
