import { settingsConfig } from 'components/settings/settings-config';
import { Flex } from '@chakra-ui/react';
import { SettingsCard } from 'components/settings/settings-card';
import { useAppSelector } from '@/hooks/use-app-selector';
import { activeSettingsIdSelector } from '@/features/settings/selectors/active-settings-id-selector';
import { useSettingsSelect } from '@/features/settings/hooks/use-settings-select';

export const SettingsView = () => {
  const activeSettingsId = useAppSelector(activeSettingsIdSelector);
  const { select, unselect } = useSettingsSelect();
  const handleSettingsClick = (settingsId: number) => {
    if (settingsId === activeSettingsId) {
      unselect();
    } else {
      select(settingsId);
    }
  };

  return (
    <>
      {settingsConfig.map((settings) => (
        <Flex w="100%" key={settings.id}>
          <SettingsCard data={settings} selected={activeSettingsId === settings.id} handleSettingsClick={handleSettingsClick} />
        </Flex>
      ))}
    </>
  );
};
