import { isEqual } from 'lodash';
import { useForm, useFormState } from 'react-final-form';
import { UiButtonOutlined } from '@/ui-elements';
import { RESET_BUTTON_TEXT } from '@/config/constants/loads';

export const LoadsResetButton = ({ handleRefetchLoads }: { handleRefetchLoads: () => void }) => {
  const { reset } = useForm();
  const { initialValues, values } = useFormState();

  const formValuesAreDefault = isEqual(initialValues, values);

  const onClickHandleResetForm = () => {
    reset();
    handleRefetchLoads();
  };

  return (
    <UiButtonOutlined onClick={onClickHandleResetForm} mode="light" size="small" isDisabled={formValuesAreDefault}>
      {RESET_BUTTON_TEXT}
    </UiButtonOutlined>
  );
};
