import { useQueryClient } from '@tanstack/react-query';
import { useAppSelector } from '@/hooks/use-app-selector';
import { Load } from '@/types/models/loads';
import { BackForwardButtonsDisabledType, FunnelLoadStatus, FunnelSwitcherStatus } from '@/types/models/funnel';
import { ModalType } from '@/types/common/modals';
import { ModalAction, useModal } from '@/hooks/use-modal';
import { activeFunnelTabSelector } from '@/features/funnel/selectors/active-funnel-tab-selector';
import { funnelPageSelectedLoadIDSelector } from '@/features/funnel/selectors/funnel-page-selected-load-id';
import { useBackForwardButtonsActions, useFunnelActions } from '@/features/funnel/hooks';
import { useLoadUpdates, useLoadsDataActions } from '@/features/loads/hooks';
import { userHasIntegrationSelector } from '@/features/loads/selectors/user-has-integration';
import { QueryKeys } from '@/config/constants/query-keys';
import { useOrderDataActions } from '@/features/order/hooks/use-order-data-actions';
import { useOrderUpdates } from '@/features/order/hooks/use-order-updates';
import { Order } from '@/types/models/order';
import { OrderCreateSource } from '@/types/api/order-partial';
import { OrderExecutionStatus, OrderStatus } from '@/config/constants/statuses';
import { useFunnelDataActions } from './use-funnel-data-action';

interface ReturnValues {
  order?: Load;
  isLoadLoading: boolean;
  activeTab: FunnelSwitcherStatus;
  userHasIntegration: boolean;
  buttonsDisabled: BackForwardButtonsDisabledType;
  ModalDeleteLoad?: JSX.Element | null;
  handleClickBack?: VoidFunction;
  handleClickForward?: VoidFunction | VoidFunction[];
  handleUpdateLoad: VoidFunction;
  deleteAllowed: boolean;
  handleDeleteLoad: VoidFunction;
}

export const useFunnelDetails = (): ReturnValues => {
  const client = useQueryClient();
  const { update, fetchLoadForUpdate } = useFunnelActions();
  const id = useAppSelector(funnelPageSelectedLoadIDSelector);
  const activeTab = useAppSelector(activeFunnelTabSelector);
  const userHasIntegration = useAppSelector(userHasIntegrationSelector);

  const { order: orderQ } = useOrderUpdates({ id, fromSource: OrderCreateSource.ILSConfirmation });
  const { data: respData, isInitialLoading, refetch: loadRefetch } = orderQ;
  const order = respData?.data as Load;

  // const {  } = useOrderDataActions(id!);
  const { updateOrderStatus } = useFunnelDataActions(id!);

  const setNewLoadStatus = (nextStatus: FunnelLoadStatus | OrderExecutionStatus.New, onSuccess?: VoidFunction) => {
    if (!id) return;
    updateOrderStatus.mutate(
      { status: nextStatus, fromSource: OrderCreateSource.ILSConfirmation },
      {
        onSuccess: () => {
          onSuccess?.();
          loadRefetch();
          client.invalidateQueries([QueryKeys.allOrders]);
          client.invalidateQueries([QueryKeys.loadsCount]);
        },
      }
    );
  };

  const { handleClickBack, handleClickForward, buttonsDisabled } = useBackForwardButtonsActions(activeTab, setNewLoadStatus, order);

  const { modalView: ModalDeleteLoad, setModalOpened: showDeleteLoad } = useModal<{ text: string; loadID?: number } & ModalAction>(
    ModalType.DeleteLoad,
    {
      text: 'Заявка будет удалена',
      loadID: order?.id,
    }
  );

  const handleUpdateLoad = () => {
    if (id) {
      fetchLoadForUpdate(id);
    }
    return update();
  };

  /** На вкладке Согласование удаление возможно во всем статусах кроме Согласования.
   * В статусе Согласование удаление возможно только, если статус OrderExecutionStatus.New
   */
  const deleteAllowed = [...Object.values(FunnelLoadStatus), OrderExecutionStatus.New].includes(order?.status);
  const handleDeleteLoad = () => {
    if (deleteAllowed) {
      showDeleteLoad(true);
    }
  };

  return {
    order,
    isLoadLoading: isInitialLoading,
    activeTab,
    userHasIntegration,
    buttonsDisabled,
    deleteAllowed,
    ModalDeleteLoad,
    handleClickBack,
    handleClickForward,
    handleUpdateLoad,
    handleDeleteLoad,
  };
};
