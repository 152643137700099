import { EMPTY_STRING } from 'config/constants/common-strings';
import { NotificationActionType } from 'config/notifications/notification-action-types';
import { AppNotificationAction } from '@/types/models/app-notification';

export const getNotificationTitle = (action?: AppNotificationAction) => {
  if (!action) return EMPTY_STRING;
  switch (action.type) {
    case NotificationActionType.executing:
      return 'Исполнитель начал выполнение';
    default:
      return action.message || EMPTY_STRING;
  }
};
