import { ResourcesDriverForm } from '@/components/resources/forms/driver/resources-driver-form';
import { useResourcesDriverForm } from '@/features/resources/hooks';

export const ResourcesDriverFormView = () => {
  const { initialValues, driverInitialDocs, mode, submitButtonTexts, closeForm, submitHandlers } = useResourcesDriverForm();

  return (
    <ResourcesDriverForm
      mode={mode}
      initialValues={initialValues}
      driverInitialDocs={driverInitialDocs}
      submitHandlers={submitHandlers}
      submitButtonTexts={submitButtonTexts}
      closeForm={closeForm}
    />
  );
};
