import { FC, MutableRefObject, useCallback } from 'react';
import { Box, Divider } from '@chakra-ui/react';
import { CarrierCard } from 'components/carrier-card/carrier-card';
import { CarrierCardTitle } from 'components/carrier-card/carrier-card-title';
import { isArray } from 'lodash';
import { VatType } from 'config/constants/types';
import { Carrier } from '@/types/models/carrier';
import { CarrierDriverRequest } from '@/types/api/carrier-partial';
import { checkIsVatMatch } from '@/helpers/price/check-is-vat-match';
import { getCarrierOrganizationVatType } from '@/features/carriers/helpers/get-carrier-organization-vat-type';

type Props = {
  carrier: Carrier;
  onClickShowInfo?: (data: { id: number }) => void;
  addCarriersRequest: (requestParams: Omit<CarrierDriverRequest, 'orderId'>) => void;
  openReviews: (carrier?: Carrier) => void;
  scrollRef: MutableRefObject<HTMLDivElement | null>;
  readOnly?: boolean;
  orderVatType: VatType | undefined;
};

export const CarrierOrganizationList: FC<Props> = ({
  carrier,
  onClickShowInfo,
  addCarriersRequest,
  openReviews,
  scrollRef,
  readOnly,
  orderVatType,
}) => {
  const { drivers } = carrier;
  const organizationVatType = getCarrierOrganizationVatType(carrier);

  const isVatMatch = checkIsVatMatch({ vatType: organizationVatType, orderVatType });
  const openReview = useCallback(() => {
    openReviews({ ...carrier, id: carrier.id });
  }, [carrier, openReviews]);

  return (
    <Box ref={scrollRef} className="carrier-organization-search">
      <CarrierCardTitle carrier={carrier} onClickShowInfo={onClickShowInfo} />
      {(drivers || []).map((driver) => {
        return (
          isArray(driver?.vehicles) &&
          driver?.vehicles?.map((vehicle) => {
            return (
              <>
                <CarrierCard
                  isVatMatch={isVatMatch}
                  onClickReviewButton={openReview}
                  driver={driver}
                  addCarriersRequest={() =>
                    addCarriersRequest({
                      carrierId: carrier.id,
                      driverId: driver.id,
                      vehicleId: vehicle.id,
                    })
                  }
                  vehicle={vehicle}
                  readOnly={readOnly}
                />
                <Divider className="order-search__divider" />
              </>
            );
          })
        );
      })}
    </Box>
  );
};
