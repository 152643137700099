import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import { useGetMyOrganizationTypes } from '@/features/my-organization/use-get-organization-types';
import { useMyOrganizationData } from '@/features/my-organization/use-my-organization-data';
import { useMyOrganizationDocuments } from '@/features/my-organization/use-my-organization-documents';
import { useMyOrganizationDriverData } from '@/features/my-organization/use-my-organization-driver-data';
import { useMyOrganizationDriverActions } from '@/features/my-organization/use-my-organization-driver-actions';
import { useAccount } from '@/features/account/hooks/use-account';
import { FileFormField, MyOrganizationFormField, MyOrganizationFormOptions, MyOrganizationFormValues } from '@/types/models/my-organization/form';
import { MyOrganizationType } from '@/types/models/my-organization/my-organization';
import { DriverVerificationRequest } from '@/types/models/driver-verification';
import { getFormValuesToData } from '@/features/my-organization/helper/get-form-values-to-data';
import { getDataToFormValues } from '@/features/my-organization/helper/get-data-to-form-values';
import { getDefaultBankDetails } from '@/features/my-organization/helper/get-default-bank-details';
import { getDriverFormValuesToData } from '@/features/my-organization/helper/get-driver-form-values-to-data';
import { useMyOrganizationForm } from './use-my-organization-form';
import { AttachmentDeps } from '@/config/constants/types';
import { splitFormFiles } from './helper/split-form-files';
import { accountDriverIdSelector } from './selectors/user-account-selector';
import { queryClient } from '@/config/query-client';

export const useMyOrganization = () => {
  const { currentAccount, isCarrierSimple, accountPhone } = useAccount();
  const driverId = useSelector(accountDriverIdSelector);

  const { organizationTypes } = useGetMyOrganizationTypes();
  const { myOrganizationData, organizationID, createOrganization, updateOrganization, approvalStatuses } = useMyOrganizationData();

  const { driver } = useMyOrganizationDriverData({ driverId });

  const { documents, sendFiles, updateFile, downloadAttachmentById, removeAttachment, documentConfirmProps, onOpenDeleteDocumentModal } =
    useMyOrganizationDocuments({ organizationID, driverId });

  const { onToggleEditForm, isEditStatus, formEditConfirmProps, setEditForm } = useMyOrganizationForm({ approvalStatuses });

  const { submitDriver } = useMyOrganizationDriverActions({ driverID: driver?.id, sendFiles, carrierId: currentAccount.user?.accountId });

  const onSuccessFormSubmit = (args: {
    data: MyOrganizationType;
    driverRequestData?: DriverVerificationRequest;
    notify: string;
    files: Record<FileFormField, File[]>;
    isUpdate?: boolean;
  }) => {
    const { data, driverRequestData, notify, files, isUpdate } = args;
    toast.success(notify);
    queryClient.invalidateQueries(['myOrganizationData']);

    if (!isUpdate) {
      const { id } = data;
      const { driverFiles, organizationFiles } = splitFormFiles(files);
      if (id) {
        sendFiles({ files: organizationFiles, dependentID: id, dependent: AttachmentDeps.Organization });
      }
      if (isCarrierSimple && driverRequestData) {
        submitDriver({ driverRequestData, files: driverFiles });
      }
    }
  };

  const onSubmit = (formValues: MyOrganizationFormValues) => {
    const { requestData, files } = getFormValuesToData({ formValues, isCarrierSimple, accountPhone });
    const { driverRequestData } = getDriverFormValuesToData({ ...formValues, phoneNumber: accountPhone });
    if (organizationID) {
      const params = { ...requestData };
      params.bankDetails = {
        ...requestData.bankDetails,
        id: getDefaultBankDetails(myOrganizationData?.bankDetails ?? [])?.id,
      };
      updateOrganization(
        { params, id: organizationID },
        {
          onSuccess: ({ data }) =>
            onSuccessFormSubmit({ isUpdate: true, data, driverRequestData, files, notify: 'Данные организации успешно обновлены' }),
        }
      );
    } else {
      createOrganization(requestData, {
        onSuccess: ({ data }) => {
          onSuccessFormSubmit({ data, driverRequestData, files, notify: 'Организация успешно добавлена' });
        },
      });
    }
  };

  const options: MyOrganizationFormOptions = {
    [MyOrganizationFormField.OrganizationTypeId]: organizationTypes,
  };

  const initialValues = getDataToFormValues({ data: myOrganizationData, driver, isCarrierSimple, organizationTypes });

  return {
    organizationID,
    onSubmit,
    initialValues,
    organizationTypes,
    options,
    approvalStatuses,
    disabled: isEditStatus ? false : !isEmpty(approvalStatuses),
    documents,
    isCarrierSimple,
    removeAttachment,
    updateFile,
    downloadAttachmentById,
    confirmProps: [formEditConfirmProps, documentConfirmProps],
    onOpenDeleteDocumentModal,
    setEditForm,
    isEditStatus,
    onToggleEditForm,
  };
};
