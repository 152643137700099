import { NotificationActionType } from 'config/notifications/notification-action-types';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { OrderSwitcherStatus } from 'config/constants/statuses';
import { OrderSwitcherNames } from 'config/constants/texts';
import { Box } from '@chakra-ui/react';
import { AppNotificationAction } from '@/types/models/app-notification';
import { UiText } from '@/ui-elements/ui-text/ui-text';

const getNewStatusMessage = (id: number, status?: OrderSwitcherStatus) => {
  return (
    <>
      <UiText display="inline" color="typography.400">
        Заявка
      </UiText>
      <UiText display="inline" color="#00000" fontWeight={700}>
        {` № ${id}`}
      </UiText>
      {status ? (
        <>
          <UiText display="inline" color="typography.400">
            {` перешла в статус`}
          </UiText>
          <UiText display="inline" color="#00000" fontWeight={700}>
            {` "${OrderSwitcherNames?.[status]}". `}
          </UiText>
        </>
      ) : (
        '.'
      )}
    </>
  );
};
export const getNotificationMessage = (action?: AppNotificationAction) => {
  if (!action) return EMPTY_STRING;
  let message;
  switch (action.type) {
    case NotificationActionType.executing:
      message = (
        <>
          {getNewStatusMessage(action.dependentId, OrderSwitcherStatus.Executing)}
          <UiText display="inline">Водитель начал выполнение рейса. Следить за выполнением заявки можно по точкам</UiText>
          <Box mt={4}>
            <UiText display="inline">
              Если в течении рейса нужно будет подтверждать документы по точкам, водитель должен будет прикрепить документы которые необходимы.
            </UiText>
          </Box>
        </>
      );
      break;
    default:
      message = (
        <>
          {getNewStatusMessage(action.dependentId)} <UiText display="inline">{action.message}</UiText>
        </>
      );
  }
  return message;
};
