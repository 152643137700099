import { useQuery } from '@tanstack/react-query';
import { SettingsApi } from '@/api/settings';

export const useAllSettings = () => {
  const allSettings = useQuery({
    queryKey: ['allSettingss'],
    refetchOnWindowFocus: false,
    queryFn: () => SettingsApi.getAllSettings(),
  });

  return { allSettings };
};
