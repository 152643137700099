import { EMPTY_STRING } from 'config/constants/common-strings';
import { AccountRole, AccountRoleMap } from '@/types/api/account-role';

export const getAliasMapTransform = (alias: AccountRole | undefined): string => {
  if (!alias) return EMPTY_STRING;
  switch (alias) {
    case AccountRole.CargoOwner:
      return 'Грузовладельца';
    case AccountRole.Carrier:
    case AccountRole.CarrierSimple:
    case AccountRole.Intermediary:
      return `${AccountRoleMap[alias]}a`;
    default:
      return EMPTY_STRING;
  }
};
