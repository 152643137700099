import { ApiGet } from '@/helpers/api/methods';
import { IDocumentsApi } from '@/api/documents/documents-types';
import { Dictionary } from '@/config/constants/dictionary';
import { TemplateData } from '@/api/documents/templates-types';
import { stringifyQuery } from '@/helpers/validate-query-string';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';

export const DocumentsApi: IDocumentsApi = {
  getDocument: ({ organizationId, bankId, order, origin }) =>
    ApiGet<ResponseWithoutPagerSimple<TemplateData>>(
      `${Dictionary.Organizations}/${organizationId}/${Dictionary.Documents}/${bankId}${stringifyQuery({ order, origin })}`
    ),
  // indexTemplates: (data) => ApiPost('/newTms/DocumentTemplate/index', data),
  // indexDocuments: (payload) => ApiPost('/newTms/Document/index', payload),
  // getDependentList: ({ type, projectId, setId }) =>
  //   ApiPost('/newTms/Document/getDependentList', projectId ? { type, projectId } : setId ? { setId } : { type }),
  // create: (data) => ApiPost('newTms/Document/create', data),
  // createDeal: (data) => ApiPost('newTms/Document/createDeal', data),
  // update: (data) => ApiPost('newTms/Document/update', data),
  // getDocumentData: (data) => ApiPost('/newTms/Document/getDocumentData', data),
  // deleteFromTrash: (data) => ApiPost('/newTms/Document/deleteFromTrash', data),
  // saveAsTemplate: ({ id }) => ApiPost('/newTms/Document/saveAsTemplate', { id }),
  // getUserOptions: () => ApiPost('/newTms/Document/getUserOptions', {}),
  // setUserOptions: (data) => ApiPost('/newTms/Document/setUserOptions', data),
  // suggestContractor: (data) => ApiPost('/newTms/Document/suggestions', data),
  // sendContractor: (data) => ApiPost('/newTms/Document/send', data),
  // getDocumentMetrics: (data) => ApiPost('/newTms/Document/getDocumentMetrics', data),
  // getReceivedDocuments: () => ApiPost('/newTms/Document/getReceivedDocuments'),
  // getSendedDocuments: () => ApiPost('/newTms/Document/getSendedDocuments'),
  // setDocumentStatus: (data) => ApiPost('/newTms/Document/setDocumentStatus', data),
};
