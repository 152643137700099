import { FC } from 'react';
import { useIcons } from '@/hooks/use-icons';
import './verification-empty.scss';
import { NO_VERIFICATION } from './texts';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';

/*
 * Сайдбар, когда не выбрана карточка для проверки
 * */
export const VerificationEmpty: FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const { ClaimIcon64 } = useIcons();
  return (
    <div className="verification-empty">
      {isLoading ? (
        <div className="verification-empty__spinner">
          <UiSpinner visible loadingMessage="Загрузка карточки..." />
        </div>
      ) : (
        <div className="verification-empty__caption">
          <ClaimIcon64 />
          {NO_VERIFICATION}
        </div>
      )}
    </div>
  );
};
