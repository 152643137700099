import { FC } from 'react';
import { Divider } from '@chakra-ui/react';
import { OrderDetailsEmptyCarrier } from 'components/order-details/order-details-empty-carrier/order-details-empty-carrier';
import { OrderDetailsCarrierDriverInfo } from 'components/order-details/order-details-carrier-driver-info/order-details-carrier-driver-info';
import { Order } from '@/types/models/order';
import { OrderGeneralDistance, OrderGeneralInfo, OrderRoute } from '@/components';
import { OrderOffer } from '@/types/models/order-offer';
import { OrderExecutionStatus } from '@/config/constants/statuses';
import './order-details-carrier-execution.scss';
import { Load } from '@/types/models/loads';
import { checkHasVat } from '@/helpers/price/check-has-vat';

/**
 * Блок исполнение и детали на вкладке Заказ Исполнителя
 * */
export const OrderDetailsCarrierExecution: FC<{
  order: Order | Load;
  // открыть поиск исполнителя
  onCarrierSearchClick: VoidFunction;
  // Выбранное предложение
  selectedOffer?: OrderOffer;
  // убирает доплнительную информацию по заявке, которая нужна только логисту
  hideOrderLogistInfo?: boolean;
  // Начать рейс
  onOrderStart?: () => void;
}> = ({ order, onCarrierSearchClick, selectedOffer, hideOrderLogistInfo = false, onOrderStart }) => {
  const { distance, type, shippedTotals } = order;
  const isOrderConfirmed = order.status === OrderExecutionStatus.Confirmed;
  return (
    <>
      {selectedOffer ? (
        <OrderDetailsCarrierDriverInfo
          onOrderStart={onOrderStart}
          orderOffer={selectedOffer}
          hideOrderLogistInfo={hideOrderLogistInfo}
          isOrderConfirmed={isOrderConfirmed}
          prepaymentValue={order.prepayment?.prepayment}
          showOrderPrice
          orderPrice={checkHasVat(order.carrierPayment.vatType) ? order.carrierPayment.priceWithVAT : order.carrierPayment.priceWithoutVAT}
        />
      ) : (
        <OrderDetailsEmptyCarrier onCarrierSearchButtonClick={onCarrierSearchClick} />
      )}
      <Divider className="order-details__divider" />
      {!hideOrderLogistInfo && (
        <>
          <OrderGeneralDistance range={distance} type={type} className="order-details-carrier-execution__general-info" />
          <OrderGeneralInfo
            className="order-details__general-info"
            loadingType={order.loadingType || []}
            vehicleTypes={order.vehicleTypes}
            shippedTotals={shippedTotals}
          />
          <Divider className="order-details__divider" />
        </>
      )}
      <OrderRoute order={order} />
    </>
  );
};
