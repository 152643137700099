import { TabListProps } from '@chakra-ui/react';
import { FC } from 'react';
import './tabs-menu-select.scss';
import classNames from 'classnames';
import { UiTabList } from '@/ui-elements/ui-tabs/tab-list/ui-tab-list';

export type Props = TabListProps & {
  size: string | undefined;
};
export const UiTabListMenuSelect: FC<Props> = ({ children, size, ...props }) => (
  <UiTabList className={classNames('ui-tabs-menu-selected__tabList', { 'ui-tabs-menu-selected-tabList_small': size === 'small' })} {...props}>
    {children}
  </UiTabList>
);
