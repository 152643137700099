import { useMutation } from '@tanstack/react-query';
import { OrderNew, OrderSetStatus } from '@/types/api/order-partial';
import { OrdersApi } from '@/api/orders';

export const useCarrierLoadsDataActions = (id?: number) => {
  const updateOrder = useMutation({
    mutationKey: ['updateCarrierLoad'],
    mutationFn: id ? (options: Partial<OrderNew>) => OrdersApi.updateOrder(id, options) : undefined,
  });

  const updateOrderStatus = useMutation({
    mutationKey: ['updateOrderStatus'],
    mutationFn: id ? (options: OrderSetStatus) => OrdersApi.updateOrderStatus(id, options) : undefined,
  });

  return {
    updateOrderStatus,
    updateOrder,
  };
};
