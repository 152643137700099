import { FC, memo, useMemo } from 'react';
import { isEqual } from 'lodash';
import { Grid, GridItem, Text } from '@chakra-ui/react';
import classNames from 'classnames';
import { GridTableConfig } from '@/ui-elements/ui-grid-table/uii-grid-table-type';
import './ui-grid-table.scss';

type Props<T extends { id: number }> = {
  config: GridTableConfig<T>;
  className?: string;
};
const UiGridTableHeader: FC<Props<any>> = ({ config, className }) => {
  const countColumns = useMemo(() => config.columns.reduce((acc, curr) => acc + (curr?.width || 1), 0), [config]);
  return (
    <div className="ui-grid-table__header-container">
      <Grid templateColumns={`repeat(${countColumns}, 1fr)`} gap={config.gap} className={classNames('ui-grid-table__header', className)}>
        {config.columns.map((column) => (
          <GridItem key={column.key} colSpan={column.width || 1} className={classNames({ 'centered-header': column.centredHeader })}>
            <Text>{column.label || column.key}</Text>
          </GridItem>
        ))}
      </Grid>
    </div>
  );
};
export const UiGridTableHeaderMemoized = memo(UiGridTableHeader, (prevProps, nextProps) => isEqual(prevProps, nextProps));
