import { Approval } from '@/types/models/approval';
import { VeificationSubjects } from '@/features/approvals/types/verification-subjects';
import { VerificationItemApproveStatus } from '@/config/constants/statuses';

export const getApprovalElementStatus = (approval: Approval, subject?: VeificationSubjects) => {
  switch (subject) {
    case VeificationSubjects.Driver:
    case VeificationSubjects.Vehicle:
      return approval?.[subject]?.approvalStatus;
    case VeificationSubjects.Organization:
      return approval?.carrierOrganization?.approvalStatus;
    case VeificationSubjects.Customer:
      return approval?.customer?.approvalStatus;
    default:
      return VerificationItemApproveStatus?.Waiting;
  }
};
