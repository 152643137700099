import { FC } from 'react';
import { VStack } from '@chakra-ui/react';
import './order-fuel-filter.scss';
import { UiSwitcher } from '@/ui-elements/switcher/switcher';
import { Tab } from '@/ui-elements/switcher/types';
import { SwitcherWithFuelStatus, SwitcherWithoutFuelStatus } from '@/config/constants/statuses';

type Props = {
  switcherLabels: { labelsWithoutFuel: Tab[]; labelsWithFuel: Tab[] };
  onChangeActiveTab: (activeKey: string) => void;
  activeTab: string | undefined;
};

export const OrderFuelFilter: FC<Props> = ({ switcherLabels, onChangeActiveTab, activeTab }) => {
  return (
    <VStack className="order-fuel-filter" gap={2}>
      <UiSwitcher
        className="order-fuel-filter__no-fuel-switcher"
        tabsList={switcherLabels?.labelsWithoutFuel}
        onChange={onChangeActiveTab}
        activeTab={activeTab && Object.values(SwitcherWithoutFuelStatus).includes(activeTab) ? activeTab : ''}
      />
      <UiSwitcher
        className="order-fuel-filter__fuel-switcher"
        tabsList={switcherLabels.labelsWithFuel}
        onChange={onChangeActiveTab}
        activeTab={activeTab && Object.values(SwitcherWithFuelStatus).includes(activeTab) ? activeTab : ''}
      />
    </VStack>
  );
};
