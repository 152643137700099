import { Text } from '@chakra-ui/react';
import { TopRoutesMenuMobile } from '@/components/top-menu/mobile/mobile-menu';
import { Routes } from '@/config/routes/routes';
import { routesConfig } from '@/config/routes/routes-config';
import { useIcons } from '@/hooks/use-icons';
import './app-toolbar.scss';

export const CarriersLoadsAnonymToolbar = () => {
  const { IconIntelogis, Line } = useIcons();

  return (
    <header className="app-anonym-carrier-toolbar">
      <div className="app-anonym-carrier-toolbar_log">
        <IconIntelogis />
        <Line />
        <Text className="text">Цифровой экспедитор </Text>
      </div>
      <div className="app-anonym-carrier-toolbar_menu">
        <TopRoutesMenuMobile
          menu={{
            items: [
              { route: routesConfig.anonymousRoutes[Routes.Register], name: 'Регистрация', sortingOrder: 1 },
              { route: routesConfig.anonymousRoutes[Routes.LogIn], name: 'Вход', sortingOrder: 2 },
            ],
          }}
        />
      </div>
    </header>
  );
};
