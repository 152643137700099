export enum CarrierLoadApplicationAgreementFieldNames {
  applicationAgreementSigned = 'applicationAgreementSigned',
}

export enum CarrierLoadApplicationAgreementsFormLabels {
  applicationAgreementSigned = 'Загрузите подписанные документы',
}

export interface CarrierLoadApplicationAgreementsFormValues {
  [CarrierLoadApplicationAgreementFieldNames.applicationAgreementSigned]?: Array<File>;
}
