import { FC } from 'react';
import { TabProps, Text } from '@chakra-ui/react';
import { UiTab } from '@/ui-elements';

type Props = TabProps & {
  children: React.ReactNode;
};

export const ResourcesTab: FC<Props> = ({ children, ...props }) => {
  return (
    <UiTab p={0} mr="20px" className="resources-tab" {...props}>
      <Text>{children}</Text>
    </UiTab>
  );
};
