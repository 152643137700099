import React from 'react';
import {
  InputGroup,
  InputRightElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
} from '@chakra-ui/react';
import { UiClearButton } from '@/ui-elements';
import { useIcons } from '@/hooks/use-icons';
import { isNilOrEmpty } from '@/helpers/is-nil-or-empty';
import { styles } from '@/ui-elements/inputs/ui-input/styles';

type Props = {
  onClear?: any;
  onChange?: any;
  hideClearButton?: boolean;
  numbersBehindDecimal?: number;
  hideStepper?: boolean;
  onHandleChange?: any;
  onKeyPress?: (e: React.KeyboardEvent) => false | RegExp;
  placeholder?: string;
};

export const UiNumberPicker = ({
  onClear,
  hideClearButton,
  numbersBehindDecimal,
  hideStepper,
  onHandleChange,
  onKeyPress,
  ...props
}: Props & NumberInputProps) => {
  const { name, value, placeholder, onChange, isDisabled } = props;
  const { StepUp, StepDown } = useIcons();

  return (
    <InputGroup>
      <NumberInput
        {...props}
        onChange={(e) => {
          onChange?.(e);
          onHandleChange?.(e);
        }}
        isDisabled={isDisabled}
        {...styles}
        precision={numbersBehindDecimal ?? 0}
        onKeyPress={onKeyPress}
      >
        <NumberInputField name={name} placeholder={placeholder} {...styles} />
        {!isNilOrEmpty(value) && !hideClearButton && onClear && (
          <InputRightElement mr="24px">
            <UiClearButton onClear={onClear} />
          </InputRightElement>
        )}
        {!hideStepper && (
          <NumberInputStepper>
            <NumberIncrementStepper children={<StepUp />} />
            <NumberDecrementStepper children={<StepDown />} />
          </NumberInputStepper>
        )}
      </NumberInput>
    </InputGroup>
  );
};
