import { WidgetContainer } from 'components/widget-container/widget-container';
import { FC } from 'react';
import { OrderExecutionStatus, OrderStatus } from 'config/constants/statuses';
import { OrderDetailsComponent } from '@/components';
import { WidgetContainers } from '@/features/widget/widget-containers';
import { useOrderDetails } from '@/views/order-details/use-order-details';

export const OrderDetailsView: FC<{ isFuel?: boolean }> = ({ isFuel = false }) => {
  const {
    ref,
    showSetCarrierWidget,
    orderOffers,
    visibleRequests,
    selectedOffer,
    activeOrdersArray,
    order,
    onConfirmOrder,
    onCarrierSearchClick,
    onChooseOwnDriverAndVehicle,
    onStartCarrierSearch,
    onSplit,
    onCancelMultipleSelect,
    onJoinOrders,
    updateOrderFuelStatus,
    onCancelOrder,
    disableSearch,
    isOrderLoading,
    handleUpdateOrder,
    onSendToCheck,
    onMoreInformation,
    handleDeleteOrder,
    orderIsClosed,
    isLogist,
    handleOrderStart,
    onConfirmOriginals,
    onCloneOrder,
    isEmptyOrderList,
    fuelReadOnly,
    ConfirmModal,
    ModalSplit,
    ModalDeleteOrder,
    ReviewModal,
    ModalCloneOrder,
    ModalChooseOwnDriverVehicle,
  } = useOrderDetails(isFuel);

  return (
    <div ref={ref}>
      <OrderDetailsComponent
        onClickSubmitOffer={showSetCarrierWidget}
        orderOffers={orderOffers}
        shipmentRequests={visibleRequests}
        selectedOffer={selectedOffer}
        isMultipleOrdersSelected={activeOrdersArray.length > 1}
        order={order}
        onConfirmClick={onConfirmOrder}
        onCarrierSearchClick={onCarrierSearchClick}
        onChooseOwnDriverAndVehicle={onChooseOwnDriverAndVehicle}
        onStartCarrierSearch={onStartCarrierSearch}
        onSplitClick={onSplit}
        onCancelMultipleSelectClick={onCancelMultipleSelect}
        onJoinOrdersClick={onJoinOrders}
        onUpdateOrderFuelStatus={updateOrderFuelStatus}
        onCancelOrderClick={onCancelOrder}
        disableSearchButton={disableSearch}
        isOrderLoading={isOrderLoading}
        handleUpdateOrder={handleUpdateOrder}
        handleDeleteOrder={handleDeleteOrder}
        onSendToCheck={onSendToCheck}
        onMoreInformation={onMoreInformation}
        hideOrderActions={orderIsClosed}
        hideOrderLogistInfo={!isLogist}
        onOrderStart={handleOrderStart}
        onConfirmOriginalsClick={onConfirmOriginals}
        onCloneOrder={onCloneOrder}
        hideOffers={order?.status !== OrderExecutionStatus.CarrierSearch}
        showEmptyOrdersListMessage={isEmptyOrderList}
        isFuel={isFuel}
        fuelReadOnly={fuelReadOnly}
      />
      <WidgetContainer containerKey={WidgetContainers.OrderDetails} parentRef={ref} />
      {ConfirmModal}
      {ModalSplit}
      {ModalDeleteOrder}
      {ReviewModal}
      {ModalCloneOrder}
      {ModalChooseOwnDriverVehicle}
    </div>
  );
};
