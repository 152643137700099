import _ from 'lodash';

// helper для очищения query строки
export const validateQueryStringAndCleanEmpty = (query: Record<string, any> | undefined) => {
  let str = '';
  if (
    !_.isEmpty(query) &&
    Object.entries(query).some(([key, value]) => {
      return key && ((typeof query[key] !== 'object' && !!value) || (typeof query[key] === 'object' && !_.isEmpty(value)));
    })
  ) {
    str = str.concat('?');
    Object.entries(query).forEach(([key, value]) => {
      if (typeof query[key] === 'object') {
        // eslint-disable-next-line
        Object.entries(query[key]).forEach(([key, value]) => {
          str = str.concat(`&${key}=${value}`);
        });
      } else if (value) {
        str = str.concat(`&${key}=${value}`);
      }
    });
  }
  return str;
};
