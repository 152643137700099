import { AttachmentDeps } from 'config/constants/types';
import { DocumentTypes } from 'config/constants/document-types';
import { OrderDetailsDocumentsConfirm } from 'components/order-details/order-details-documents/order-details-documents-confirm';
import { HStack } from '@chakra-ui/react';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { FormSpy } from 'react-final-form';
import { useEffect, useState } from 'react';
import { OrderExecutionStatus, OrderStatus } from 'config/constants/statuses';
import { useOrderDetailsView } from '@/features/order/hooks/use-order-details-view';
import { useDependentAttachments } from '@/features/attachments/hooks/use-dependent-attachments';
import { emptyFunction } from '@/helpers/empty-function';
import { useAccount } from '@/features/account/hooks/use-account';
import { UserRole } from '@/types/api/user-role';
import { UiText } from '@/ui-elements/ui-text/ui-text';
import { UiInputField } from '@/ui-elements';
import './carrier-origin-order-document-view.scss';
import { useForm } from '@/helpers/forms/use-form';
import { FormAutosave } from '@/hooks/use-autosave';
import { useOrderDataActions } from '@/features/order/hooks/use-order-data-actions';
import { useOrderInvalidate } from '@/features/order/hooks/use-order-invalidate';
import { orderStatusArray } from '@/views/order-details-document/helper/get-order-status';

type TrackNumber = {
  trackNumber?: string;
};

export const CarrierOriginOrderDocumentView = () => {
  const { order } = useOrderDetailsView();
  const { currentRole } = useAccount();
  const hasEditRight = currentRole && [UserRole.Administrator, UserRole.Manager].includes(currentRole);
  const { updateOrder } = useOrderDataActions(order!.id);
  const { orderInvalidate } = useOrderInvalidate();
  const [trackNumber, setTrackNumber] = useState<string | undefined>();

  useEffect(() => {
    setTrackNumber(order?.carrierOrigins?.trackNumber);
  }, [order?.carrierOrigins?.trackNumber]);
  const Form = useForm<TrackNumber>();

  const {
    deleteAttachmentById: deleteDocument,
    attachments,
    downloadAttachmentById,
    postAttachments,
  } = useDependentAttachments({
    dependent: AttachmentDeps.Order,
    dependentId: order?.id,
    type: DocumentTypes.dlDocumentsOrderCarrierOrigins,
    staleTime: 3000,
  });

  const submit = () => {
    if (order?.id) {
      updateOrder.mutate(
        {
          carrierOrigins: {
            confirmed: true,
            trackNumber,
          },
        },
        { onSuccess: () => orderInvalidate(order.id) }
      );
    }
  };

  const loading = updateOrder.isLoading;

  const handleTrackNumberChange = (values: TrackNumber) => {
    setTrackNumber(values.trackNumber || EMPTY_STRING);
  };
  const isOrderExecuting = orderStatusArray.some(() => order?.status !== OrderExecutionStatus.Executing);

  const confirmed = order?.carrierOrigins?.confirmed && trackNumber === order.carrierOrigins.trackNumber;
  return (
    <OrderDetailsDocumentsConfirm
      title="Отправка оригиналов"
      documents={attachments || []}
      onUpload={postAttachments}
      onSaveClick={downloadAttachmentById}
      onDeleteClick={!order?.carrierOrigins?.confirmed ? deleteDocument : undefined}
      onConfirmClick={submit}
      disabled={!hasEditRight || confirmed || isOrderExecuting}
      confirmed={confirmed}
      confirmableWithOutDocs={Boolean(trackNumber?.length)}
      loading={loading}
    >
      <Form onSubmit={emptyFunction} initialValues={{ trackNumber: order?.carrierOrigins?.trackNumber }}>
        <FormSpy subscription={{ values: true }}>{({ values }) => <FormAutosave values={values} onSave={handleTrackNumberChange} />}</FormSpy>
        <HStack alignItems="center">
          <UiText color="typography.100" className="carrier-origin-order-document-view__track-number">
            Трек-номер
          </UiText>
          <UiInputField name="trackNumber" hideBottomText isDisabled={isOrderExecuting} placeholder="Укажите трек" />
        </HStack>
      </Form>
    </OrderDetailsDocumentsConfirm>
  );
};
