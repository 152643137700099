import { FC } from 'react';
import { Routes } from 'config/routes/routes';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';
import { useCarriersLoadsOrderSidebar } from '@/features/carriers-loads/hooks/use-carriers-loads-order-sidebar';

export const CompletedRoutesSidebarView: FC = () => {
  const { closeSidebar, SidebarContent, order, isInitialLoading } = useCarriersLoadsOrderSidebar(Routes.CompletedRoutes);

  if (!SidebarContent) {
    return null;
  }

  if (isInitialLoading) {
    return <UiSpinner visible mt={48} />;
  }

  return <SidebarContent closeSidebar={closeSidebar} order={order} />;
};
