import { Menu, MenuProps, MenuButton, MenuList, MenuItemOption, MenuOptionGroup } from '@chakra-ui/react';
import classNames from 'classnames';
import { MenuOption } from '@/components/order-filter/types';
import { DropdownMenuButton } from './dropdown-menu-button';
import './ui-options-menu.scss';

type Props = {
  menuOptions: Array<MenuOption>;
  onChange: (arg1: number) => void;
  menuButtonIcon?: React.ReactElement;
  className?: string;
  selectedOption?: number;
} & Omit<MenuProps, 'children'>;

export const UiOptionsMenu = ({ selectedOption = 0, menuOptions = [], onChange, menuButtonIcon, className, ...menuProps }: Props) => {
  return (
    <div className={classNames('ui-options-menu', className)}>
      <Menu variant="filterDropdown" {...menuProps}>
        <MenuButton as={DropdownMenuButton} leftIcon={menuButtonIcon}>
          {menuOptions[selectedOption].value}
        </MenuButton>
        <MenuList minWidth="300px">
          <MenuOptionGroup value={menuOptions[selectedOption].value} type="radio">
            {menuOptions.map(({ id, value }, index) => (
              <MenuItemOption key={id} value={value} onClick={() => onChange(index)} isChecked={selectedOption === index}>
                {value}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </div>
  );
};
