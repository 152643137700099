import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';

type Props = {
  regNumber?: string;
  region?: string;
};

export const CarrierCardRegNumber: FC<Props> = ({ regNumber, region }) => (
  <Flex className="regNumber">
    <Text padding="4px">{regNumber || ''}</Text>
    {region && (
      <>
        <div className="regNumber__divider" />
        <Text padding="4px">{region || ''}</Text>
      </>
    )}
  </Flex>
);
