import './empty.scss';
import { FC } from 'react';
import { routesPath } from 'config/routes/routes-path';
import { Routes } from 'config/routes/routes';
import { navigationTitles } from 'config/constants/navigation-titles';
import { UiText } from '@/ui-elements/ui-text/ui-text';
import { UiButtonFilled } from '@/ui-elements';
import { useNavigateTo } from '@/hooks/use-navigate-to';
import { CARRIER_REDIRECT_ROUTES } from '@/views/carriers-loads/constants';

type Props = {
  emptyText: string;
  showButtons?: boolean;
  redirectRoutes?: Array<Routes>;
};

export const CarriersLoadsEmpty: FC<Props> = ({ emptyText, showButtons = true, redirectRoutes = CARRIER_REDIRECT_ROUTES }) => {
  const { navigateTo } = useNavigateTo();

  return (
    <section className="carrier-empty">
      <UiText className="carrier-empty-text" children={emptyText} />
      {showButtons &&
        redirectRoutes.map((item) => {
          return <UiButtonFilled onClick={navigateTo(routesPath[item])} className="carrier-empty-button" children={navigationTitles[item]} />;
        })}
    </section>
  );
};
