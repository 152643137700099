import { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { UiText } from '@/ui-elements/ui-text/ui-text';

type Props = {
  vehicleTypeIcon?: string;
  vehicleTypeName?: string;
};

export const VehicleType: FC<Props> = ({ vehicleTypeIcon = `/assets/icons/TrailerHead.svg`, vehicleTypeName }) => {
  return (
    <Flex direction="column" gap="1em">
      <UiText textStyle="h3" children={vehicleTypeName} />
      {vehicleTypeIcon && <img width="48px" height="20px" src={vehicleTypeIcon} alt="vehicle img" />}
    </Flex>
  );
};
