import { useState } from 'react';

export const useFiles = () => {
  const [files, setFiles] = useState<File[]>([]);

  const onFilesChange = (newFiles: File[]) => {
    setFiles(newFiles);
  };

  return { files, onFilesChange };
};
