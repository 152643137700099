import { FC, useCallback, useRef, useState } from 'react';
import { CarrierFormData, FormMode, VerificationProps } from 'components/verification-forms/types';
import { Box, useOutsideClick } from '@chakra-ui/react';
import { organizationFields } from 'components/verification-forms/constants';
import { formValuesToCarrier } from 'components/verification-forms/helpers/form-values-to-carrier';
import { CarrierTitleSubform } from 'components/verification-forms/organization-verification/subforms/carrier-title-subform';
import { CarrierButtonsSubform } from 'components/verification-forms/organization-verification/subforms/carrier-buttons-subform';
import { CarrierInfoSubform } from 'components/verification-forms/organization-verification/subforms/carrier-info-subform';
import { CarrierDocsSubform } from 'components/verification-forms/organization-verification/subforms/carrier-docs-subform';
import { CarrierBankSubform } from 'components/verification-forms/organization-verification/subforms/carrier-bank-subform';
import { CarrierContactsSubform } from 'components/verification-forms/organization-verification/subforms/carrier-contacts-subform';
import { useForm } from '@/helpers/forms/use-form';
import '../verification-form.scss';
import { UiCheckboxField, UiTextareaField } from '@/ui-elements';
import { formatFormFields } from '@/helpers/forms/format-form-fields';
import { useCarrierActions } from '@/features/carriers/hooks/use-carrier-actions';
import { CarrierEdit } from '@/types/models/carrier';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { validateForm } from '@/helpers/forms/validate';
import { createCarrierSchema } from '@/helpers/forms/validation-schemas/create-carrier-form';

export const OrganizationVerificationForm: FC<
  Partial<VerificationProps<CarrierFormData, CarrierFormData & { comment?: string; submitting?: boolean }>>
> = ({
  // onApprovalApproved,
  // onApprovalDeclined,
  onSubmit,
  onCancel,
  onClose,
  mode = FormMode.create,
  carrierId,
  initialValues = {},
  submitting,
}) => {
  const Form = useForm<CarrierFormData>();
  const [comment, setComment] = useState('');
  const { updateCarrier } = useCarrierActions(carrierId!); // TODO вынести получени carrier во view

  const datePickerRef = useRef(null);
  const handleDatePickerClose = useCallback(() => (datePickerRef?.current as any)?.closeCalendar(), [datePickerRef]);
  useOutsideClick({
    ref: datePickerRef,
    handler: handleDatePickerClose,
  });

  const onApprove = (values: CarrierEdit) => {
    const newValues = formatFormFields(values, 'pick', organizationFields);
    updateCarrier.mutate(newValues as CarrierEdit); // TODO:мутации вынести во view компонент должне принимать только колбеки
    // onApprovalApproved?.(comment);
    onClose?.();
  };

  const handleClose = () => {
    // onApprovalDeclined?.(comment);
    onClose?.();
  };
  const handleFormSubmit = (values: CarrierFormData) => {
    if (onSubmit) {
      onSubmit({
        ...values,
        comment,
      });
    }
    if (mode === 'check') onApprove(formValuesToCarrier(values)); // TODO: методы onApprove  должны приходить как  onSubmit
  };

  const handleFormCancel = () => {
    if (onCancel) {
      onCancel();
    }
    if (mode === FormMode.check) handleClose(); // TODO: методы onApprovalDeclined и onCancelCreation должны приходить как  onCancel
  };
  const validate = validateForm<CarrierFormData>(createCarrierSchema);

  return (
    <Form keepDirtyOnReinitialize onSubmit={handleFormSubmit} initialValues={initialValues} validate={validate}>
      <CarrierTitleSubform mode={mode} />

      <div className="carriers-verification-form__body">
        <UiScrollbars autoHide>
          <div className="carriers-verification-form__main">
            <CarrierInfoSubform mode={mode} datePickerRef={datePickerRef} />

            {mode === 'check' && (
              <>
                <Box mt={-1}>
                  <UiCheckboxField name="loans" label="Наличие кредитов" />
                </Box>
                <Box mt={4}>
                  <UiCheckboxField name="companyConvictions" label="Наличие судов организации" />
                </Box>
                <Box mt={4}>
                  <UiCheckboxField name="convictions" label="Наличие судимостей" />
                </Box>
              </>
            )}

            <CarrierDocsSubform mode={mode} carrierId={carrierId} />

            <CarrierBankSubform mode={mode} />

            <CarrierContactsSubform mode={mode} />
            {mode === 'edit' && (
              <Box mt={4} mb={6}>
                <UiTextareaField
                  name="securityComment"
                  className="carriers-verification-form__comment"
                  label="Оставьте комментарий что вы отредактировали (этот комментарий увидит специалист службы безопасности)"
                  variant="noResize"
                  placeholder="Введите текст"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </Box>
            )}
          </div>
        </UiScrollbars>
      </div>
      <CarrierButtonsSubform mode={mode} handleFormCancel={handleFormCancel} submitting={submitting} />
    </Form>
  );
};
