import classNames from 'classnames';
import { Box, Flex, BoxProps, VStack, Text } from '@chakra-ui/react';
import last from 'lodash/last';
import { emptyFunction } from '@/helpers/empty-function';
import { DownloadIcon } from './ui-download-icon';
import './ui-download-card.scss';
import { downloadFileDocument } from '@/helpers/api/download-file-document';
import { CarrierDoc } from '@/types/models/doc';
import { downloadFileByUrl } from '@/helpers/download-file-by-url';

type Props = {
  disabled?: boolean;
  fileName: string;
  url?: string;
  extension?: string;
  doc?: CarrierDoc;
} & BoxProps;

export const UiDownloadCard = ({ doc, disabled = false, fileName, url, extension, ...boxProps }: Props) => {
  const fileExtension = doc?.extension ?? extension ?? (fileName?.includes('.') ? last(fileName.split('.')) : null);

  const downloadDocument = () => {
    if (doc && !disabled) {
      return downloadFileDocument(doc);
    }
    if (url && !disabled) {
      return downloadFileByUrl(url, fileName);
    }
    return emptyFunction;
  };

  return (
    <Box className={classNames('ui-download-card', { 'ui-download-card_disabled': disabled })} onClick={downloadDocument} {...boxProps}>
      <Flex align="start">
        <VStack align="start" className={classNames('ui-download-card__box', { 'ui-download-card__box_disabled': disabled })}>
          <Text py={1} noOfLines={3} textOverflow="ellipsis" className="ui-download-card__file-name">
            {fileName}
          </Text>
          {fileExtension && (
            <Box px={1} className={classNames('ui-download-card__badge', { 'ui-download-card__badge_disabled': disabled })}>
              {fileExtension.toUpperCase()}
            </Box>
          )}
        </VStack>
        <Box pt={1} ml={2}>
          <DownloadIcon fill={disabled ? '#D3D4D8' : undefined} />
        </Box>
      </Flex>
    </Box>
  );
};
