import { FC, MouseEventHandler, useState } from 'react';
import { OrderDetailsClient } from 'components/order-details/order-details-component/order-details-client';
import { OrderDetailsCarrier } from 'components/order-details/order-details-component/order-details-carrier';
import { OrderDetailsTabs, tabNames } from 'components/order-details/order-details-component/constants';
import { OrderDetailsMultipleSelected } from 'components/order-details/order-details-multiple-selected/order-details-multiple-selected';
import { OrderDetailsEmpty } from 'components/order-details/order-details-empty/order-details-empty';
import { UiSwitcher } from '@/ui-elements/switcher/switcher';
import './order-details-component.scss';
import { Order } from '@/types/models/order';
import { OrderOffer } from '@/types/models/order-offer';
import { emptyFunction } from '@/helpers/empty-function';
import { VoidFunction } from '@/types/common/void-function';
import { ShipmentRequest } from '@/types/models/shipment-request';
import { FuelStatus } from '@/config/constants/statuses';
import { Load } from '@/types/models/loads';

type Props = {
  order?: Order | Load;

  // Предложения
  orderOffers?: Array<OrderOffer>;
  // отправленные запросы
  shipmentRequests?: Array<ShipmentRequest>;
  // Выбранное предложение
  selectedOffer?: OrderOffer;
  // отправить документы исполнителю
  onConfirmClick?: VoidFunction;

  // открыть форму разделения заявки
  onSplitClick?: (partsToSplit: number) => void;

  // открыть поиск исполнителя
  onCarrierSearchClick?: VoidFunction;

  // открыть выбор собственных ресурсов
  onChooseOwnDriverAndVehicle?: VoidFunction;

  // перевести в статус поиск исполнителя
  onStartCarrierSearch?: VoidFunction;

  //  выбрано несколько заявок
  isMultipleOrdersSelected?: boolean;

  // отмена выбора несолкьих заявок
  onCancelMultipleSelectClick?: VoidFunction;

  // клик по кнопке объединить заявки
  onJoinOrdersClick?: VoidFunction;

  // клик по кнопке перевода статуса топлива
  onUpdateOrderFuelStatus?: (status?: FuelStatus) => void;

  // открыть виджет выбрать исполнителя
  onClickSubmitOffer?: (orderOffer: Pick<OrderOffer, 'carrier' | 'driver' | 'vehicle'>, offerId?: number) => void;

  // отправить оффер на проверку
  onSendToCheck: (offer: Pick<OrderOffer, 'carrier' | 'driver' | 'vehicle'>) => void;

  // кнопка Подробнее - открыть виджет Информация о перевозчике
  onMoreInformation: (id: number) => void;
  // копировать заявку
  onCloneOrder?: (orderId: number) => void;
  // отменить заявку
  onCancelOrderClick?: VoidFunction;
  // Изменить заявку
  handleUpdateOrder?: MouseEventHandler<HTMLButtonElement>;
  handleDeleteOrder?: MouseEventHandler<HTMLButtonElement>;

  // Начать рейс
  onOrderStart?: () => void;

  // отключает кнопку Найти исполнителя
  disableSearchButton?: boolean;

  // скрыть все кнопки по управлению заявкой
  hideOrderActions?: boolean;
  // скрыть предложения
  hideOffers?: boolean;
  // убирает доплнительную информацию по заявке, которая нужна только логисту
  hideOrderLogistInfo?: boolean;
  // скрыть группу риска для заявки
  hideOrderRiskGroup?: boolean;

  isOrderLoading?: boolean;

  showEmptyOrdersListMessage?: boolean;

  // подтвердить получение оригиналов
  onConfirmOriginalsClick?: VoidFunction;
  isFuel?: boolean;
  fuelReadOnly?: boolean;
};

/**
 * Главный компонент Детали выбранной заявки или нескольких завявок
 * */
export const OrderDetailsComponent: FC<Props> = ({
  order,
  isFuel = false,
  fuelReadOnly = false,
  onConfirmClick = emptyFunction,
  onSplitClick = emptyFunction,
  onCarrierSearchClick = emptyFunction,
  onChooseOwnDriverAndVehicle = emptyFunction,
  onStartCarrierSearch = emptyFunction,
  isMultipleOrdersSelected = false,
  onCancelMultipleSelectClick = emptyFunction,
  onJoinOrdersClick = emptyFunction,
  onUpdateOrderFuelStatus = emptyFunction,
  shipmentRequests = [],
  orderOffers = [],
  selectedOffer,
  onClickSubmitOffer = emptyFunction,
  onCancelOrderClick = emptyFunction,
  disableSearchButton = false,
  hideOrderActions = false,
  hideOffers = false,
  hideOrderLogistInfo = false,
  hideOrderRiskGroup = false,
  isOrderLoading = false,
  handleUpdateOrder = emptyFunction,
  handleDeleteOrder = emptyFunction,
  onSendToCheck = emptyFunction,
  onMoreInformation = emptyFunction,
  onCloneOrder = emptyFunction,
  onOrderStart,
  onConfirmOriginalsClick = emptyFunction,

  showEmptyOrdersListMessage = false,
}) => {
  const [tab, setTab] = useState<OrderDetailsTabs | string>(OrderDetailsTabs.DetailsClient);
  const onViewChange = (value: string) => {
    setTab(value);
  };

  if (isMultipleOrdersSelected) {
    return <OrderDetailsMultipleSelected onCancelMultipleSelectClick={onCancelMultipleSelectClick} onJoinOrdersClick={onJoinOrdersClick} />;
  }

  if (!order) return <OrderDetailsEmpty isLoading={isOrderLoading} isEmpty={showEmptyOrdersListMessage} />;

  return (
    <div className="order-details">
      <div className="order-details__main">
        <UiSwitcher onChange={onViewChange} className="order-details__switcher" tabsList={tabNames} activeTab={tab} />
        <div className="order-details__content">
          {tab === OrderDetailsTabs.DetailsClient && (
            <OrderDetailsClient
              onConfirmOriginalsClick={onConfirmOriginalsClick}
              onClickSubmitOffer={onClickSubmitOffer}
              order={order}
              selectedOffer={selectedOffer}
              onCarrierSearchClick={onCarrierSearchClick}
              onChooseOwnDriverAndVehicle={onChooseOwnDriverAndVehicle}
              onStartCarrierSearch={onStartCarrierSearch}
              onSplitClick={onSplitClick}
              orderOffers={orderOffers}
              onCancelOrderClick={onCancelOrderClick}
              disableSearchButton={disableSearchButton}
              hideOrderActions={hideOrderActions}
              hideOffers={hideOffers}
              hideOrderLogistInfo={hideOrderLogistInfo}
              hideOrderRiskGroup={hideOrderRiskGroup}
              handleUpdateOrder={handleUpdateOrder}
              handleDeleteOrder={handleDeleteOrder}
              onCloneOrder={onCloneOrder}
              onSendToCheck={onSendToCheck}
              onMoreInformation={onMoreInformation}
              shipmentRequests={shipmentRequests}
              onUpdateOrderFuelStatus={onUpdateOrderFuelStatus}
              isFuel={isFuel}
              fuelReadOnly={fuelReadOnly}
            />
          )}
          {tab === OrderDetailsTabs.DetailsCarrier && (
            <OrderDetailsCarrier
              order={order}
              selectedOffer={selectedOffer}
              onConfirmClick={onConfirmClick}
              onCarrierSearchClick={onCarrierSearchClick}
              hideOrderLogistInfo={hideOrderLogistInfo}
              onOrderStart={onOrderStart}
              fuelReadOnly={fuelReadOnly}
            />
          )}
        </div>
      </div>
    </div>
  );
};
