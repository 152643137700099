import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CarriersState } from '@/features/carriers/types/state';

const initialState: CarriersState = {
  list: [], // список перевозчиков
  selectedCarrierId: undefined, // id выбраного перевозчика
};

const slice = createSlice({
  name: 'carriers',
  initialState,
  reducers: {
    select: (state, action: PayloadAction<number | undefined>) => {
      state.selectedCarrierId = action.payload;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const carriersReducers = slice.reducer;
export const carriersActions = slice.actions;
