import { useMutation } from '@tanstack/react-query';
import { OffersApi } from '@/api/offers';
import { OfferNew } from '@/types/models/offers';

export const useOffersActions = () => {
  const addOffer = useMutation({
    mutationKey: ['addOffer'],
    mutationFn: (options: OfferNew) => OffersApi.addOffer(options),
  });

  const acceptOffer = useMutation({
    mutationKey: ['acceptOffer'],
    mutationFn: (id: number) => OffersApi.acceptOffer(id),
  });

  return {
    addOffer,
    acceptOffer,
  };
};
