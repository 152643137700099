import { HStack } from '@chakra-ui/react';
import { FC } from 'react';
import {
  DECLINE_APPROVALS_COUNT_CAPTION,
  SUCCESS_APPROVALS_COUNT_CAPTION,
  TOTAL_APPROVALS_COUNT_CAPTION,
} from 'components/security/security-header-component/texts';
import { UiStatisticBlock } from '@/ui-elements';
import { useMetricsUpdates } from '@/features/approvals/hooks/use-metrics-updates';
import { Metrics } from '@/types/models/metrics';

export const CarriersStatistics: FC = () => {
  const { metrics: metricsResp } = useMetricsUpdates();
  // TODO: поменять статусы метрик, текущие неактуальны
  const metrics = metricsResp.data?.data;

  return (
    <HStack>
      <UiStatisticBlock title={TOTAL_APPROVALS_COUNT_CAPTION} number={metrics?.total || 0} level="checked" />
      <UiStatisticBlock title={SUCCESS_APPROVALS_COUNT_CAPTION} number={(metrics as Metrics)?.approved || 0} />
      <UiStatisticBlock title={DECLINE_APPROVALS_COUNT_CAPTION} number={(metrics as Metrics)?.declined || 0} level="rejected" />
    </HStack>
  );
};
