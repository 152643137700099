import './sidebar-wrapper.scss';
import { FC, ReactElement } from 'react';
import { UiCloseablePortal } from '@/ui-elements/ui-sidebar/ui-closeable-portal';
import { UISidebar } from '@/ui-elements/ui-sidebar/ui-sidebar';

interface Props {
  children: ReactElement;
  closeSidebar: VoidFunction;
}

export const SidebarWrapper: FC<Props> = ({ children, closeSidebar }) => {
  return (
    <UISidebar isOpen animation className="sidebar-wrapper">
      <UiCloseablePortal onClose={closeSidebar}>{children}</UiCloseablePortal>
    </UISidebar>
  );
};
