import { FC } from 'react';
import classNames from 'classnames';
import { Box, HStack, Spacer } from '@chakra-ui/react';
import { UiButtonOutlined, UiButtonFilled } from '@/ui-elements';
import { UISidebar } from '@/ui-elements/ui-sidebar/ui-sidebar';
import { UiCloseablePortal } from '@/ui-elements/ui-sidebar/ui-closeable-portal';
import { useOrderActions } from '@/features/order/hooks/use-order-actions';
import { SplitOrderForm } from '@/components/split-order-form/split-order-form';
import './order-split-sidebar.scss';

export const OrderSplitSidebar: FC<{
  open: boolean;
  splitSize: number;
}> = ({ open, splitSize }) => {
  const { split } = useOrderActions();
  return (
    <UISidebar
      className={classNames(`order-sidebar-split-${splitSize}`, { [`order-sidebar-split-${splitSize}_left`]: open })}
      animation={false}
      isOpen={open}
      blocksScroll
    >
      <SplitOrderForm parts={1} title="Разделение заявки" />
      <UISidebar isOpen={open} isDrawer delay={1} animation className={`order-sidebar-split-${splitSize}_right`}>
        <UiCloseablePortal onClose={() => split({ order: undefined })}>
          <>
            <SplitOrderForm parts={splitSize} />
            <Box className="order-sidebar-split__bottom-bar">
              <HStack ml="auto" maxW="340px" justify="end">
                <UiButtonOutlined mode="dark" onClick={() => split({ order: undefined })}>
                  Отменить
                </UiButtonOutlined>
                <Spacer w="16px" flex={0} />
                <UiButtonFilled mode="dark" px="62px !important">
                  Применить
                </UiButtonFilled>
              </HStack>
            </Box>
          </>
        </UiCloseablePortal>
      </UISidebar>
    </UISidebar>
  );
};
