import { HStack, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { isObject } from 'lodash';
import { UiMultiDatePickerField, UiSelectField, UiTimeInputField } from '@/ui-elements';
import { arrivalTimeTypeConfig, TimeType } from './form-configs';
import { DateFormat } from '@/config/constants/date';

type Props = {
  name: string;
  arrivalTimeOption?: { value: TimeType; label: string } | TimeType;
};

export const TimeSubform = ({ name, arrivalTimeOption }: Props) => {
  const [timeType, setTimeType] = useState<TimeType>(() =>
    isObject(arrivalTimeOption) ? arrivalTimeOption?.value || TimeType.simple : arrivalTimeOption || TimeType.simple
  );

  const handleTimeTypeChange = (value: any) => {
    setTimeType(value.value);
  };

  return (
    <VStack alignItems="start" minWidth="300px" pt={4}>
      <HStack mb={4} spacing={4}>
        <Text>Параметры времени прибытия</Text>
        <UiSelectField
          name={`${name}.arrivalTimeOption`}
          options={arrivalTimeTypeConfig}
          onChange={handleTimeTypeChange}
          value={arrivalTimeTypeConfig.find((type) => timeType === type.value)}
        />
      </HStack>
      {timeType === TimeType.exact && (
        <HStack className="route__period-datetime" gap={10}>
          <UiMultiDatePickerField name={`${name}.arrivalDate`} label="Выберите дату" required format={DateFormat.YYYYMMDDSlash} portal />
          <HStack className="route__period-datetime__time">
            <UiTimeInputField required name={`${name}.arrivalStartTime`} label="Выберите время:" />
          </HStack>
        </HStack>
      )}
      {timeType === TimeType.interval && (
        <VStack className="route__period-datetime">
          <UiMultiDatePickerField name={`${name}.arrivalDate`} label="Выберите дату" required format={DateFormat.YYYYMMDDSlash} portal />
          <HStack className="route__period-datetime__time">
            <Text>Выберите временной интервал:</Text>
            <UiTimeInputField maxWidth="100px" required name={`${name}.arrivalStartTime`} label="С" />
            <span className="route__period-datetime__time-divider">-</span>
            <UiTimeInputField maxWidth="100px" required name={`${name}.arrivalEndTime`} label="До" />
          </HStack>
        </VStack>
      )}
      {timeType === TimeType.simple && (
        <HStack>
          <UiMultiDatePickerField name={`${name}.arrivalDate`} label="Выберите дату" required format={DateFormat.YYYYMMDDSlash} portal />
        </HStack>
      )}
    </VStack>
  );
};
