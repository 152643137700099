import { Dictionary } from 'config/constants/dictionary';
import { ApiDelete, ApiGet, ApiPatch, ApiPost } from '@/helpers/api/methods';
import { ResponseWithoutPagerSimple, ResponseWithPager } from '@/types/models/pager';
import { ResponseSimpleApi } from '@/types/response';
import { MyOrganizationBanking, MyOrganizationType, MyOrganizationTypeWithBankDetails } from '@/types/models/my-organization/my-organization';
import { stringifyQuery } from '@/helpers/validate-query-string';

export const MyOrganizationsApi = {
  getAllOrganizations: (updates?: boolean) =>
    ApiGet<ResponseWithPager<MyOrganizationTypeWithBankDetails>>(`${Dictionary.Organizations}${stringifyQuery({ updates })}`),

  addOrganization: (options: Partial<MyOrganizationType>) =>
    ApiPost<ResponseWithoutPagerSimple<MyOrganizationType>>(`${Dictionary.Organizations}`, options),

  addOrganizationBanking: (id: number, options: Partial<MyOrganizationBanking>) =>
    ApiPost<ResponseWithoutPagerSimple<MyOrganizationBanking>>(`${Dictionary.Organizations}/${id}/bankDetails`, options),

  getOrganization: (id: number) => ApiGet<ResponseWithoutPagerSimple<MyOrganizationType>>(`${Dictionary.Organizations}/${id}`),

  updateOrganization: (id: number, options: Partial<MyOrganizationType>) =>
    ApiPatch<ResponseWithoutPagerSimple<MyOrganizationType>>(`${Dictionary.Organizations}/${id}`, options),

  deleteOrganization: (id: number) => ApiDelete<ResponseSimpleApi>(`${Dictionary.Organizations}/${id}`),
  //
  getOrganizationBanking: (id: number, updates?: boolean) =>
    ApiGet<ResponseWithoutPagerSimple<MyOrganizationBanking>>(`${Dictionary.Organizations}/${id}/bankDetails?updates=${!!updates}`),

  updateOrganizationBanking: (id: number, bankId: number, options: Partial<MyOrganizationType>) =>
    ApiPatch<ResponseWithoutPagerSimple<Array<MyOrganizationBanking>>>(`${Dictionary.Organizations}/${id}/bankDetails/${bankId}`, options),

  deleteOrganizationBanking: (id: number, bankId: number) => ApiDelete<ResponseSimpleApi>(`${Dictionary.Organizations}/${id}/bankDetails/${bankId}`),

  getOrganizationEnum: () => ApiGet<ResponseWithoutPagerSimple<Record<number, string>>>(`${Dictionary.Organizations}/enum`), // возвращает список организаций без постраничной разбивки для селекта

  getOrganizationTypes: () => ApiGet<ResponseWithoutPagerSimple<Record<string, string>>>(`${Dictionary.Organizations}/types`),
};
