import { useMemo } from 'react';

/**
 * Хук оборачивает колбэк в window.requestAnimationFrame().
 * Планирует вызов колбэка в слелудющем кадре анимации.
 * Блокирует отработку колбэка, если предыдущий вызов еще не отработал.
 * */
export const useRaf = <T>(callback: (args: T) => any) => {
  const rafAction = useMemo(() => {
    let requestId: number | null = null;

    let lastArgs: T;

    const worker = () => () => {
      requestId = null;
      callback(lastArgs);
    };

    const raf = (args: T) => {
      lastArgs = args;
      if (requestId === null) {
        requestId = requestAnimationFrame(worker());
      }
    };

    raf.cancel = () => {
      if (requestId) cancelAnimationFrame(requestId);
      requestId = null;
    };

    return raf;
  }, [callback]);

  return rafAction;
};
