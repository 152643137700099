import { OrderService } from '@/types/models/order-service';

export const formatInitialServiceWidget = (service: OrderService) => {
  return {
    name: service.name,
    amount: service.amount,
    comment: service.comment,
    customerPrice: service.customerPayment?.price,
    customerVat: service.customerPayment?.vat,
    carrierPrice: service.carrierPayment?.price,
    carrierVat: service.carrierPayment?.vat,
  };
};
