import { FC } from 'react';
import { isEmpty } from 'lodash';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Box } from '@chakra-ui/layout';
import { Cargos } from '@/types/models/cargos';
import { CreateLoadFormValues } from '@/types/models/funnel-create-load-form';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { CreateLoadFormFields } from '@/components/funnel/create-load-form-fields/create-load-form-fields';
import { CreateLoadFormBottomButtons } from '@/components/funnel/create-load-form-bottom-buttons/create-load-form-bottom-buttons';

interface Props {
  onSubmit: (values: any) => void;
  onCancel: () => void;
  initialValues?: any;
  submitting?: boolean;
  updateValues?: any;
  cargos?: Array<Cargos>;
}

export const CreateLoadForm: FC<Props> = ({ onSubmit, onCancel, initialValues, submitting, updateValues, cargos }) => {
  const validate = (values: CreateLoadFormValues) => {
    let errors: any = { vehicleType: { vehicleTypes: '' } };
    const REQUIRED = 'Обязательно поле';
    if (isEmpty(values.vehicleType.vehicleTypes)) {
      errors.vehicleType.vehicleTypes = REQUIRED;
    } else errors = {};
    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={updateValues || initialValues}
      validate={validate}
      mutators={{ ...arrayMutators }}
      keepDirtyOnReinitialize
      render={({ form, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box h="83vh">
            <UiScrollbars autoHide>
              <CreateLoadFormFields form={form} cargos={cargos} />
            </UiScrollbars>
          </Box>
          <CreateLoadFormBottomButtons onCancel={onCancel} submitting={submitting} />
        </form>
      )}
    />
  );
};
