import classNames from 'classnames';
import { List, ListItem, ListProps } from '@chakra-ui/react';
import { FileDocument } from '@/types/models/document';
import { UiFileUploadCard, Extension } from '@/ui-elements/inputs/ui-file-upload-input/ui-file-upload-card/ui-file-upload-card';
import { emptyFunction } from '@/helpers/empty-function';

type Props = {
  fileList?: Array<FileDocument>;
  className?: string;
  onDownload?: (id: number) => void;
  onDelete?: (id: any) => void;
} & ListProps;

export const UiFileList = ({ fileList = [], className, onDownload = emptyFunction, onDelete, ...listProps }: Props) => {
  return (
    <List className={classNames('ui-file-list', className)} {...listProps} spacing={4}>
      {fileList.map(({ id, name, extension }) => (
        <ListItem key={id}>
          <UiFileUploadCard
            fileName={name}
            extension={extension as Extension}
            onIconClick={() => onDownload(id)}
            onDeleteClick={() => onDelete?.(id)}
          />
        </ListItem>
      ))}
    </List>
  );
};
