import { useQueryClient } from '@tanstack/react-query';
import isNil from 'lodash/isNil';
import { CarriersApi } from '@/api/carriers';
import { useQueryWithUpdates } from '@/hooks/use-query-with-updates';
import { Carrier } from '@/types/models/carrier';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';

export const useCarrierUpdates = (id?: number) => {
  const queryClient = useQueryClient();
  const queryFn = () => {
    return CarriersApi.getCarrier(id!);
  };

  const carrier = useQueryWithUpdates<ResponseWithoutPagerSimple<Carrier>>({
    queryKey: ['carrier', id],
    queryFn,
    enabled: !isNil(id),
  });

  const invalidateCarrier = () => {
    return queryClient.invalidateQueries({ queryKey: ['carrier', id] });
  };
  return { carrier, invalidateCarrier };
};
