import { ApprovalsApi } from '@/api/approvals';
import { ApprovalsFilter } from '@/types/api/approvals';
import { useQueryWithUpdates } from '@/hooks/use-query-with-updates';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';
import { CarriersVerificationMetrics, Metrics } from '@/types/models/metrics';

export const useMetricsUpdates = (query?: ApprovalsFilter) => {
  const queryFn = (isFirst: boolean) => ApprovalsApi.getMetrics({ ...query, updates: !isFirst });

  const metrics = useQueryWithUpdates<ResponseWithoutPagerSimple<Metrics | CarriersVerificationMetrics>>({
    queryKey: ['approveMetrics', query],
    refetchOnWindowFocus: false,
    queryFn,
  });
  return { metrics };
};
