import { Routes } from 'config/routes/routes';
import { routesPath } from 'config/routes/routes-path';

import { UserRole } from '@/types/api/user-role';
import { RoutesConfig } from '@/types/routes/routes-config';
import {
  AnalyticsPage,
  BookerClientsPage,
  BookerOrdersPage,
  CarriersLoadsAnonymPage,
  CarriersLoadsPage,
  CarriersPage,
  CarriersVerificationPage,
  CRMPage,
  CurrentRoutesPage,
  FuelCardsPage,
  FunnelPage,
  FutureRoutesPage,
  LoginPage,
  MapPage,
  MyOrganizationPage,
  MyOrganizationsPage,
  OrdersPage,
  OrdersWithMyParticipationPage,
  PublicOfferAgreement,
  RegisterPage,
  ResourcesPage,
  SecurityPage,
  SettingsPage,
  StatisticsPage,
  UnderConstructPage,
  VerificationHistoryPage,
} from '@/pages';
import { AccountRole } from '@/types/api/account-role';
import { CompletedRoutesPage } from '@/pages/completed-routes/completed-routes-page';

export const routesConfig: RoutesConfig = {
  routes: {
    // TODO - модуль в разработке
    // [Routes.Loads]: {
    //   element: <LoadsPage />,
    //   path: routesPath[Routes.Loads],
    //   availableRoles: [UserRole.Administrator, UserRole.Manager],
    // },
    [Routes.Funnel]: {
      element: <FunnelPage />,
      path: routesPath[Routes.Funnel],
      availableRoles: [UserRole.Administrator, UserRole.Manager],
      availableAccountRoles: [AccountRole.Intelogis_truck, AccountRole.CargoOwner, AccountRole.Intermediary],
    },
    [Routes.CRM]: {
      element: <CRMPage />,
      path: routesPath[Routes.CRM],
      availableRoles: [UserRole.Administrator, UserRole.Manager, UserRole.Booker],
      availableAccountRoles: [AccountRole.Intelogis_truck, AccountRole.CargoOwner, AccountRole.Intermediary],
    },
    [Routes.Orders]: {
      element: <OrdersPage />,
      path: routesPath[Routes.Orders],
      availableRoles: [UserRole.Administrator, UserRole.Manager],
      availableAccountRoles: [AccountRole.Intelogis_truck, AccountRole.CargoOwner, AccountRole.Intermediary],
      isDefault: true,
    },
    [Routes.CarriersLoadsAuthorized]: {
      element: <CarriersLoadsPage />,
      path: routesPath[Routes.CarriersLoadsAuthorized],
      availableRoles: [UserRole.Administrator],
      availableAccountRoles: [AccountRole.Carrier],
      isDefault: true,
    },
    [Routes.CarriersLoadsAuthorizedSimple]: {
      element: <CarriersLoadsPage />,
      path: routesPath[Routes.CarriersLoadsAuthorizedSimple],
      availableRoles: [UserRole.Administrator],
      availableAccountRoles: [AccountRole.CarrierSimple],
      isDefault: true,
    },
    [Routes.Carriers]: {
      element: <CarriersPage />,
      path: routesPath[Routes.Carriers],
      availableRoles: [UserRole.Administrator, UserRole.Manager],
      availableAccountRoles: [AccountRole.Intelogis_truck, AccountRole.CargoOwner, AccountRole.Intermediary],
    },
    [Routes.FuelCards]: {
      element: <FuelCardsPage />,
      path: routesPath[Routes.FuelCards],
      availableRoles: [UserRole.Administrator, UserRole.FuelManager, UserRole.Manager, UserRole.Booker],
      availableAccountRoles: [AccountRole.Intelogis_truck, AccountRole.CargoOwner, AccountRole.Intermediary],
    },
    // [Routes.Clients]: {
    //   element: <ClientsPage />,
    //   path: routesPath[Routes.Clients],
    //   availableRoles: [UserRole.Administrator, UserRole.Manager],
    // },
    [Routes.Analytics]: {
      element: <AnalyticsPage />,
      path: routesPath[Routes.Analytics],
      availableRoles: [UserRole.Administrator, UserRole.Manager],
      availableAccountRoles: [AccountRole.Intelogis_truck, AccountRole.CargoOwner, AccountRole.Intermediary],
    },
    [Routes.Map]: {
      element: <MapPage />,
      path: routesPath[Routes.Map],
      availableRoles: [],
      availableAccountRoles: [AccountRole.Intelogis_truck, AccountRole.CargoOwner, AccountRole.Intermediary],
    },
    [Routes.Security]: {
      element: <SecurityPage />,
      path: routesPath[Routes.Security],
      availableRoles: [UserRole.Administrator],
      availableAccountRoles: [AccountRole.Intelogis_truck, AccountRole.CargoOwner, AccountRole.Intermediary],
    },
    [Routes.Verification]: {
      element: <CarriersVerificationPage />,
      path: routesPath[Routes.Verification],
      availableRoles: [UserRole.Security, UserRole.Administrator],
      availableAccountRoles: [AccountRole.Intelogis_truck, AccountRole.CargoOwner, AccountRole.Intermediary],
    },
    [Routes.VerificationHistory]: {
      element: <VerificationHistoryPage />,
      path: routesPath[Routes.VerificationHistory],
      availableRoles: [UserRole.Security],
      availableAccountRoles: [AccountRole.Intelogis_truck, AccountRole.CargoOwner, AccountRole.Intermediary],
    },
    [Routes.Statistics]: {
      element: <StatisticsPage />,
      path: routesPath[Routes.Statistics],
      availableRoles: [UserRole.Security],
      availableAccountRoles: [AccountRole.Intelogis_truck, AccountRole.CargoOwner, AccountRole.Intermediary],
    },
    [Routes.BookerOrders]: {
      element: <BookerOrdersPage />,
      path: routesPath[Routes.BookerOrders],
      availableRoles: [UserRole.Booker, UserRole.Administrator],
      availableAccountRoles: [AccountRole.Intelogis_truck, AccountRole.CargoOwner, AccountRole.Intermediary],
    },
    [Routes.BookerClients]: {
      element: <BookerClientsPage />,
      path: routesPath[Routes.BookerClients],
      availableRoles: [UserRole.Booker],
      availableAccountRoles: [AccountRole.Intelogis_truck, AccountRole.CargoOwner, AccountRole.Intermediary],
    },
    [Routes.CurrentRoutes]: {
      element: <CurrentRoutesPage />,
      path: routesPath[Routes.CurrentRoutes],
      availableRoles: [UserRole.Administrator, UserRole.Driver],
      availableAccountRoles: [AccountRole.Carrier, AccountRole.CarrierSimple],
    },
    [Routes.FutureRoutes]: {
      element: <FutureRoutesPage />,
      path: routesPath[Routes.FutureRoutes],
      availableRoles: [UserRole.Driver],
      availableAccountRoles: [AccountRole.Carrier],
    },
    /* дубль FutureRoutes для AccountRole.CarrierSimple */
    [Routes.FutureRoutesSimple]: {
      element: <FutureRoutesPage />,
      path: routesPath[Routes.FutureRoutesSimple],
      availableRoles: [UserRole.Administrator],
      availableAccountRoles: [AccountRole.CarrierSimple],
    },
    [Routes.CompletedRoutes]: {
      element: <CompletedRoutesPage />,
      path: routesPath[Routes.CompletedRoutes],
      availableRoles: [UserRole.Driver],
      availableAccountRoles: [AccountRole.Carrier],
    },
    /* дубль CompletedRoutes для AccountRole.CarrierSimple */
    [Routes.CompletedRoutesSimple]: {
      element: <CompletedRoutesPage />,
      path: routesPath[Routes.CompletedRoutesSimple],
      availableRoles: [UserRole.Administrator],
      availableAccountRoles: [AccountRole.CarrierSimple],
    },
    [Routes.OrdersWithMyParticipation]: {
      element: <OrdersWithMyParticipationPage />,
      path: routesPath[Routes.OrdersWithMyParticipation],
      availableRoles: [UserRole.Administrator],
      availableAccountRoles: [AccountRole.Carrier, AccountRole.CarrierSimple],
    },
    [Routes.UnderConstruct]: {
      element: <UnderConstructPage />,
      path: routesPath[Routes.UnderConstruct],
      availableRoles: [UserRole.Booker, UserRole.Driver],
      availableAccountRoles: [AccountRole.Intelogis_truck, AccountRole.CargoOwner, AccountRole.Intermediary],
    },
    [Routes.MyOrganizations]: {
      element: <MyOrganizationsPage />,
      path: routesPath[Routes.MyOrganizations],
      availableRoles: [UserRole.Booker, UserRole.Manager, UserRole.Administrator, UserRole.FuelManager],
      availableAccountRoles: [AccountRole.Intelogis_truck, AccountRole.CargoOwner, AccountRole.Intermediary],
    },
    [Routes.MyOrganization]: {
      element: <MyOrganizationPage />,
      path: routesPath[Routes.MyOrganization],
      availableRoles: [UserRole.Booker, UserRole.Manager, UserRole.Administrator, UserRole.FuelManager],
      availableAccountRoles: [AccountRole.Carrier],
    },
    /* дубль MyOrganization для AccountRole.CarrierSimple */
    [Routes.MyOrganizationSimple]: {
      element: <MyOrganizationPage />,
      path: routesPath[Routes.MyOrganizationSimple],
      availableRoles: [UserRole.Administrator],
      availableAccountRoles: [AccountRole.CarrierSimple],
    },
    [Routes.Settings]: {
      element: <SettingsPage />,
      path: routesPath[Routes.Settings],
      availableRoles: [UserRole.Administrator],
      availableAccountRoles: [AccountRole.Intelogis_truck, AccountRole.CargoOwner, AccountRole.Intermediary],
    },
    [Routes.Resources]: {
      element: <ResourcesPage />,
      path: routesPath[Routes.Resources],
      availableRoles: [UserRole.Administrator, UserRole.Manager, UserRole.Booker],
      availableAccountRoles: [AccountRole.Carrier, AccountRole.Intermediary],
    },
    /* дубль Resources для AccountRole.CarrierSimple */
    [Routes.ResourcesSimple]: {
      element: <ResourcesPage />,
      path: routesPath[Routes.ResourcesSimple],
      availableRoles: [UserRole.Administrator],
      availableAccountRoles: [AccountRole.CarrierSimple],
    },
  },
  anonymousRoutes: {
    [Routes.LogIn]: {
      path: routesPath[Routes.LogIn],
      element: <LoginPage />,
    },
    [Routes.Register]: {
      path: routesPath[Routes.Register],
      element: <RegisterPage />,
    },
    [Routes.CarriersLoads]: {
      path: routesPath[Routes.CarriersLoads],
      element: <CarriersLoadsAnonymPage />,
    },
    [Routes.PublicOfferAgreement]: {
      element: <PublicOfferAgreement />,
      path: routesPath[Routes.PublicOfferAgreement],
    },
  },
};
