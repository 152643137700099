import { VStack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { getEmptyStr } from '@/helpers/get-empty-str';
import { InfoLine } from '@/components/';
import { MyOrganizationType } from '@/types/models/my-organization/my-organization';
import { getVatString } from '@/helpers/vat/get-vat-string';

type Props = {
  data: MyOrganizationType;
};
export const MyOrganizationsInfoCard: FC<Props> = ({ data }) => {
  const adminName = `${getEmptyStr(data.directorSurname)} ${getEmptyStr(data.directorName)} ${getEmptyStr(data.directorPatronymic)}`;
  const vatString = getVatString(data?.vatType);

  if (!data) return null;
  return (
    <VStack align="start" mt={6}>
      <Text textStyle="h2">Информация об организации</Text>
      <VStack textStyle="h5" pt={4} alignItems="flex-start">
        <InfoLine text="Руководитель:" value={adminName} />
        <InfoLine text="Тип налогообложения:" value={vatString} />
      </VStack>
    </VStack>
  );
};
