import { useRef } from 'react';
import { VehiclesListCompact } from 'components/security/vehicles-compact-list/vehicles-list-compact';
import { WidgetContainer } from 'components/widget-container/widget-container';
import { useSecurityActions } from '@/features/security/hooks/use-security-actions';
import { WidgetContainers } from '@/features/widget/widget-containers';
import { useWidgetActions } from '@/features/widget/hooks/use-widget-actions';
import { Vehicle } from '@/types/models/vehicle';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';
import { useCarrierUpdates } from '@/features/carriers/hooks/use-carrier-updates';
import { useCarrierVehiclesUpdates } from '@/features/carriers/hooks/use-carrier-vehicles-updates';
import { useApprovalUpdates } from '@/features/approvals/hooks/use-approval-updates';

export const SecurityVehiclesView = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { activeApprovalId } = useSecurityActions();
  const { data: approval } = useApprovalUpdates(activeApprovalId);
  const record = approval?.data;
  const { carrier } = useCarrierUpdates(record?.carrier?.id);
  const { carrierVehicles } = useCarrierVehiclesUpdates(record?.carrier?.id);
  const { VehicleWidget } = useWidgetActions(WidgetContainers.VehicleDetails);
  const onVehicleMoreInfoClick = (vehicle: Vehicle) => VehicleWidget.show({ vehicle });
  const isFetching = carrier.isInitialLoading;
  if (isFetching) return <UiSpinner visible mt={48} />;
  return (
    <div ref={ref}>
      <VehiclesListCompact
        vehicles={carrierVehicles.data?.data}
        department={carrier.data?.data?.department.name}
        selectedVehicleId={record?.vehicle?.id}
        onVehicleMoreInfoClick={onVehicleMoreInfoClick}
      />
      <WidgetContainer containerKey={WidgetContainers.VehicleDetails} parentRef={ref} />
    </div>
  );
};
