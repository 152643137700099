import { FC } from 'react';
import { Form } from 'react-final-form';
import { FormMode, VerificationProps } from 'components/verification-forms/types';
import { Box, Grid, GridItem, HStack, Text } from '@chakra-ui/react';
import { KPP_MASK, OGRN_MASK, OKPO_MASK, PASSPORT_MASK } from 'components/auth-form/constants';
import { vatOptions } from 'config/constants/vat-options';
import { DocumentTypes } from 'config/constants/document-types';
import { AttachmentDeps } from 'config/constants/types';
import '../verification-form.scss';
import {
  UiAttachmentsInput,
  UiButtonFilled,
  UiButtonOutlined,
  UiCheckboxField,
  UiIconButton,
  UiInputField,
  UiMaskedField,
  UiMultiDatePickerField,
  UiSelectField,
  UiTextareaField,
} from '@/ui-elements';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { OrganizationVerificationForm } from '@/types/models/organization';
import { useApproveOrganizationForm } from './use-approve-organization-form';
import { UiPhoneField } from '@/ui-elements/form-fields/ui-phone-field/ui-phone-field';
import { CarrierAddressesSubform } from '@/components/verification-forms/organization-verification/subforms/carrier-addresses-subform';
import { useGetMyOrganizationTypes } from '@/features/my-organization/use-get-organization-types';
import { DateFormat } from '@/config/constants/date';

export const ApproveOrganizationForm: FC<
  Partial<VerificationProps<OrganizationVerificationForm, OrganizationVerificationForm & { comment?: string }>>
> = ({ onApprovalApproved, onApprovalDeclined, carrier, driver, onClose }) => {
  const { onSubmit, initialValues, CloseIcon } = useApproveOrganizationForm(driver, carrier, onApprovalApproved, onApprovalDeclined);
  const { organizationTypes } = useGetMyOrganizationTypes();
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Text className="carriers-verification-form__title">Вы проверяете организацию:</Text>
          <Box pos="absolute" right={5} top={0}>
            <UiIconButton aria-label="close" icon={CloseIcon} onClick={onClose} />
          </Box>

          <div className="carriers-verification-form__body">
            <UiScrollbars autoHide>
              <div className="carriers-verification-form__main">
                <Grid templateColumns="repeat(3, 1fr)" gap={6} mt={3.5}>
                  <GridItem className="carriers-verification-form__small-row">
                    <UiSelectField
                      options={organizationTypes}
                      name="organizationTypeID"
                      label="Тип организации"
                      placeholder="Тип"
                      required
                      isDisabled
                    />
                  </GridItem>
                  <GridItem className="carriers-verification-form__small-row">
                    <UiInputField name="name" label="Название организации" placeholder="Организация" required isDisabled />
                  </GridItem>
                  <GridItem className="carriers-verification-form__small-row">
                    <UiInputField name="INN" type="number" label="ИНН" placeholder="ИНН" required isDisabled />
                  </GridItem>
                </Grid>
                <Grid templateColumns="repeat(3, 1fr)" gap={6} mt={3.5}>
                  <GridItem className="carriers-verification-form__small-row">
                    <UiMaskedField name="KPP" mask={KPP_MASK} label="КПП" placeholder="КПП" isDisabled />
                  </GridItem>
                  <GridItem className="carriers-verification-form__small-row">
                    <UiMaskedField name="OGRN" mask={OGRN_MASK} label="ОГРН" placeholder="ОГРН" isDisabled />
                  </GridItem>
                  <GridItem className="carriers-verification-form__small-row">
                    <UiMaskedField name="OKPO" mask={OKPO_MASK} label="ОКПО" placeholder="ОКПО" isDisabled />
                  </GridItem>
                </Grid>

                <CarrierAddressesSubform mode={FormMode.check} />

                <Text fontWeight={700} fontSize={18} mt={-1.5}>
                  Руководитель
                </Text>

                <Grid templateColumns="repeat(3, 1fr)" gap={6} mt={3.5} className="carriers-verification-form">
                  <GridItem className="carriers-verification-form__small-row">
                    <UiInputField name="directorSurname" label="Фамилия" isDisabled />
                  </GridItem>
                  <GridItem className="carriers-verification-form__small-row">
                    <UiInputField name="directorName" label="Имя" isDisabled />
                  </GridItem>
                  <GridItem className="carriers-verification-form__small-row">
                    {initialValues?.directorPatronymic ? <UiInputField name="directorPatronymic" label="Отчество" isDisabled /> : ''}
                  </GridItem>
                </Grid>

                <Grid templateColumns="repeat(3, 1fr)" gap={6} mt={-1} className="carriers-verification-form">
                  <GridItem className="carriers-verification-form__small-row">
                    <UiMaskedField
                      name="passportNumber"
                      label="Серия и номер паспорта"
                      placeholder="Серия/номер"
                      isDisabled
                      mask={PASSPORT_MASK}
                      defaultValue={initialValues?.passportNumber}
                    />
                  </GridItem>
                  <GridItem className="carriers-verification-form__small-row">
                    <UiMultiDatePickerField
                      name="passportDate"
                      label="Дата выдачи"
                      placeholder="Дата выдачи"
                      format={DateFormat.DDMMYYYYSlash}
                      required
                      isDisabled
                    />
                  </GridItem>
                  <GridItem className="carriers-verification-form__small-row">
                    <UiMultiDatePickerField
                      name="birthday"
                      label="Дата рождения"
                      placeholder="Дата рождения"
                      format={DateFormat.DDMMYYYYSlash}
                      required
                      isDisabled
                    />
                  </GridItem>
                </Grid>
                <Box mt={-1}>
                  <UiInputField name="passportGiven" label="Кем выдан" placeholder="Кем выдан" isDisabled />
                </Box>

                <Box mt={-1}>
                  <UiCheckboxField name="loans" label="Наличие кредитов" defaultChecked={initialValues?.loans} />
                </Box>

                {/* <Box mt={4}> */}
                {/*   <UiCheckboxField name="companyConvictions" label="Наличие судов организации" defaultChecked={initialValues?.companyConvictions} /> */}
                {/* </Box> */}

                <Box mt={4}>
                  <UiCheckboxField name="convictions" label="Наличие судимостей" defaultChecked={!!initialValues?.convictions} />
                </Box>

                <Box mt={1}>
                  <Text fontSize={18} fontWeight={700}>
                    Документы
                  </Text>
                </Box>
                {carrier && (
                  <Box mt={4}>
                    <UiAttachmentsInput
                      label="1. Разворот паспорта с фотографией"
                      dependent={AttachmentDeps.Carrier}
                      dependentId={carrier.id}
                      type={DocumentTypes.dlDocumentCarrierID}
                      mb={3}
                    />
                  </Box>
                )}
                {carrier && (
                  <Box>
                    <UiAttachmentsInput
                      label="2. (ИНН, ОГРНИП)"
                      dependent={AttachmentDeps.Carrier}
                      dependentId={carrier.id}
                      type={DocumentTypes.dlDocumentCarrierTIN}
                      mb={4}
                    />
                  </Box>
                )}

                <Box>
                  <Text fontSize={18} fontWeight={700}>
                    Расчетный счет для оплаты
                  </Text>
                </Box>

                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={3.5} className="carriers-verification-form">
                  <GridItem>
                    <UiInputField type="number" name="bik" label="БИК банка" placeholder="БИК банка" isDisabled />
                  </GridItem>
                  <GridItem>
                    <UiInputField name="bankAccount" label="Номер расчетного счета" placeholder="Номер расчетного счета" isDisabled />
                  </GridItem>
                </Grid>
                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={-1} className="carriers-verification-form">
                  <GridItem>
                    <UiInputField name="bankName" label="Наименование банка" placeholder="Наименование банка" isDisabled />
                  </GridItem>
                  <GridItem>
                    <UiInputField type="number" name="correspondentAccount" label="Корр. счет" placeholder="Корр. счет" isDisabled />
                  </GridItem>
                </Grid>

                <Box mt={-1}>
                  <UiSelectField options={vatOptions} name="vatType" label="Плательщик НДС" isDisabled />
                </Box>

                <Text fontSize={18} fontWeight={700} mt={6}>
                  Контакты
                </Text>

                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={3} className="carriers-verification-form">
                  <GridItem>
                    <UiInputField name="contactName" label="Контактное лицо от организации" placeholder="Контактное лицо" isDisabled />
                  </GridItem>
                  <GridItem>
                    <UiPhoneField
                      name="phone"
                      label="Телефон контактного лица"
                      placeholder="Телефон контактного лица"
                      isDisabled
                      defaultValue={initialValues?.phone}
                    />
                  </GridItem>
                </Grid>

                <Box mt={4} mb={6}>
                  <UiTextareaField
                    name="securityComment"
                    className="carriers-verification-form__comment"
                    label="Комментарий"
                    variant="noResize"
                    placeholder="Введите текст"
                  />
                </Box>
              </div>
            </UiScrollbars>
          </div>

          <HStack justifyContent="right" mt={4} mb={2} mr={2}>
            <UiButtonOutlined
              type="submit"
              className="carriers-verification-form__btn-decline"
              mode="light"
              onClick={() => form.change('action', 'decline')}
              w={128}
              isDisabled={submitting}
            >
              Отклонить
            </UiButtonOutlined>
            <UiButtonFilled
              type="submit"
              className="carriers-verification-form__btn-confirm"
              mode="dark"
              w={200}
              onClick={() => form.change('action', 'approve')}
              isDisabled={submitting}
            >
              Принять
            </UiButtonFilled>
          </HStack>
        </form>
      )}
    />
  );
};
