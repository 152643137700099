import { FC } from 'react';
import { HStack } from '@chakra-ui/react';
import { SET_CARRIER } from 'components/order-details/order-details-client-offer-info/texts';
import { UiButtonOutlined } from '@/ui-elements';
import { emptyFunction } from '@/helpers/empty-function';

export const OrderDetailsClientOfferInfoButtons: FC<{ onClickSubmit?: VoidFunction; disabled?: boolean }> = ({
  onClickSubmit = emptyFunction,
  disabled,
}) => (
  <HStack justifyContent="flex-end">
    <UiButtonOutlined mode="dark" disabled={disabled} onClick={onClickSubmit}>
      {SET_CARRIER}
    </UiButtonOutlined>
  </HStack>
);
