import { useIcons } from '@/hooks/use-icons';
import { UiTextInput } from '../ui-text-input/ui-text-input';
import { validateDateField } from '@/helpers/validate-date-field';

type Props = {
  isMulti?: boolean;
  isDisabled?: boolean;
  separator?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  value?: any;
  onChange?: any;
  placeholder?: string;
};

export const UiMultiDatePicker = ({ onFocus, value, separator, isMulti, onChange, placeholder, isDisabled }: Props) => {
  let values;
  let from;
  let to;

  if (isMulti) {
    values = value.split(separator);
    from = values[0] || '';
    to = values[1] || '';
  }

  const { Calendar } = useIcons();
  const inputValue = (isMulti && from && to ? `${from} - ${to}` : from) || value;

  const onChangeValue = (e: any) => {
    const validationResult = validateDateField(e);
    if (validationResult) onChange(e);
  };

  return (
    <UiTextInput
      icon={<Calendar />}
      onFocus={onFocus}
      value={inputValue}
      onChange={onChangeValue}
      placeholder={placeholder}
      isDisabled={isDisabled}
    />
  );
};
