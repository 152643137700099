import { FC } from 'react';
import { UiTab, UiTabListMenuSelect, UiTabPanel, UiTabPanels, UiTabs } from '@/ui-elements';
import { Driver } from '@/types/models/driver';
import './driver-widget.scss';
import { driverWidgetTabs } from '@/features/drivers/texts';
import { DriverTab } from './driver-tab';
import { RewiewsTab } from './reviews-tab';
import { TripsTab } from './trips-tab';
import { FinesTab } from './fines-tab';
import { Contract } from '@/types/models/contract';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';

type Props = {
  driver?: Driver;
  tripsAmount?: number;
  failedTripsAmount?: number;
  lowRatedTripsAmount?: number;
  tripsDoc?: Contract;
  failedTripsDoc?: Contract;
  lowRatedTripsDoc?: Contract;
  isFetching: boolean;
};

export const DriverWidget: FC<Props> = ({
  driver,
  tripsAmount,
  failedTripsAmount,
  lowRatedTripsAmount,
  tripsDoc,
  failedTripsDoc,
  lowRatedTripsDoc,
  isFetching,
}) => {
  if (isFetching) return <UiSpinner visible mt={48} />;
  return (
    <div className="driver-widget">
      {driver ? (
        <UiTabs>
          <UiTabListMenuSelect size="lg" className="driver-widget__tablist">
            <UiTab>{driverWidgetTabs.Driver}</UiTab>
            <UiTab>{driverWidgetTabs.Rewiews}</UiTab>
            <UiTab>{driverWidgetTabs.Fines}</UiTab>
            <UiTab>{driverWidgetTabs.ExecutedTrips}</UiTab>
          </UiTabListMenuSelect>
          <UiTabPanels>
            <UiTabPanel>
              <DriverTab driver={driver} />
            </UiTabPanel>
            <UiTabPanel>
              <RewiewsTab driver={driver} />
            </UiTabPanel>
            <UiTabPanel>
              <FinesTab driver={driver} />
            </UiTabPanel>
            <UiTabPanel>
              <TripsTab
                tripsAmount={tripsAmount}
                failedTripsAmount={failedTripsAmount}
                lowRatedTripsAmount={lowRatedTripsAmount}
                tripsDoc={tripsDoc}
                failedTripsDoc={failedTripsDoc}
                lowRatedTripsDoc={lowRatedTripsDoc}
              />
            </UiTabPanel>
          </UiTabPanels>
        </UiTabs>
      ) : (
        <p> Водитель не найден</p>
      )}
    </div>
  );
};
