import { Box, HStack } from '@chakra-ui/react';
import { UiDivider } from '@/ui-elements';
import { FunnelPaymentDocsForm } from '@/components/funnel/payment-docs-form/funnel-payment-docs-form';
import { useFunnelPaymentDocsForm } from '@/features/funnel/hooks';
import { FunnelAutoCalculationButton } from '@/components/funnel/payment-docs-component/auto-calculation-button/funnel-auto-calculation-button';
import { Load } from '@/types/models/loads';

export const FunnelPaymentDocsComponent = ({ load }: { load: Load }) => {
  const { initialValues, isUpdating, submitting, documents, deleteDocuments, postDocuments, downloadDocuments, onSubmit } = useFunnelPaymentDocsForm({
    load,
  });

  return (
    <Box>
      <HStack justifyContent="flex-end" paddingBottom="24px">
        <FunnelAutoCalculationButton />
      </HStack>
      <UiDivider mb={6} variant="extraDark" />
      <FunnelPaymentDocsForm
        initialValues={initialValues}
        isUpdating={isUpdating}
        submitting={submitting}
        documents={documents}
        deleteDocuments={deleteDocuments}
        postDocuments={postDocuments}
        downloadDocuments={downloadDocuments}
        onSubmit={onSubmit}
      />
    </Box>
  );
};
