import { Text } from '@chakra-ui/react';
import { AnalyticsPeriodNames } from '@/config/constants/texts';
import { PickerTab } from './picker-tab';
import './period-picker.scss';

type Props = {
  value?: string;
  onChange: (arg1: string) => void;
};

export const PeriodPicker = ({ value = 'all', onChange }: Props) => (
  <div className="period-picker">
    <PickerTab value={value} onChange={onChange} position="left" period="all">
      <Text textStyle="p3" noOfLines={1}>
        {AnalyticsPeriodNames.all}
      </Text>
    </PickerTab>
    <PickerTab value={value} onChange={onChange} position="middle" period="today">
      <Text textStyle="p3">{AnalyticsPeriodNames.today}</Text>
    </PickerTab>
    <PickerTab value={value} onChange={onChange} position="middle" period="weekly">
      <Text textStyle="p3">{AnalyticsPeriodNames.weekly}</Text>
    </PickerTab>
    <PickerTab value={value} onChange={onChange} position="right" period="monthly">
      <Text textStyle="p3">{AnalyticsPeriodNames.monthly}</Text>
    </PickerTab>
  </div>
);
