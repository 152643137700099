import { FC } from 'react';
import { HStack } from '@chakra-ui/react';
import { Driver } from '@/types/models/driver';
import './drivers-list.scss';
import { CarrierCardDriver } from '@/components/carrier-card/carrier-card-driver-info/carrier-card-driver';
import { UiButtonFilled, UiButtonOutlined, UiDivider } from '@/ui-elements';
import { ModalType } from '@/types/common/modals';
import { ModalAction, useModal } from '@/hooks/use-modal';

type Props = {
  driver: Driver;
  onDriverMoreInfoClick?: (driverId: number) => void;
};

export const DriverComponent: FC<Props> = ({ driver, onDriverMoreInfoClick }) => {
  const { modalView: ModalEditDriver, setModalOpened: showEditDriverModal } = useModal<{ driverId?: number } & ModalAction>(ModalType.EditDriver, {
    driverId: driver.id,
  });
  const { modalView: ModalDeleteDriver, setModalOpened: showDeleteDriver } = useModal<{ text: string; driverID?: number } & ModalAction>(
    ModalType.DeleteDriver,
    {
      text: 'Водитель будет удален',
      driverID: driver.id,
    }
  );
  const handleDeleteDriver = () => {
    showDeleteDriver(true);
  };
  const handleOpenEditModal = () => {
    showEditDriverModal(true);
  };
  return (
    <div className="drivers-list__card-container">
      <CarrierCardDriver driver={driver} key={driver.id} readOnly />
      <HStack gap={3}>
        <UiButtonFilled mode="dark" onClick={handleOpenEditModal}>
          Редактировать
        </UiButtonFilled>
        <UiButtonFilled mode="dark" onClick={handleDeleteDriver}>
          Удалить водителя
        </UiButtonFilled>
        <UiButtonOutlined mode="dark" onClick={() => onDriverMoreInfoClick?.(driver.id)}>
          Подробнее
        </UiButtonOutlined>
      </HStack>
      <UiDivider pt="25px" variant="dark" />
      {ModalEditDriver}
      {ModalDeleteDriver}
    </div>
  );
};
