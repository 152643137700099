import Tabs from 'rc-tabs';
import { FC } from 'react';
import classNames from 'classnames';
import './switcher.scss';
import { Field } from 'react-final-form';
import { Tab } from './types';

export type UiSwitcherProps = {
  tabsList: Array<Tab> | Record<string, string>;
  disabled?: boolean;
  className?: string;
  onChange?: (activeKey: string) => void;
  activeTab?: string;
  defaultActiveTab?: string;
  name?: string;
  value?: string | number;
};

const getTabList = (tabsInput: Record<string, string>) =>
  Object.keys(tabsInput).map((tabName) => ({
    key: tabName,
    label: tabsInput[tabName],
  }));

export const UiSwitcher: FC<UiSwitcherProps> = ({ disabled = false, tabsList, className, value, onChange, activeTab, defaultActiveTab, name }) => {
  const disableAllTabs = disabled;
  const tabs = Array.isArray(tabsList) ? tabsList : getTabList(tabsList);
  const expandedTabList = tabs.map((tab) => ({
    ...tab,
    disabled: disableAllTabs,
  }));

  return (
    <>
      <Tabs
        className={classNames(className, 'ui-switcher')}
        onChange={onChange}
        defaultActiveKey={defaultActiveTab}
        items={expandedTabList}
        activeKey={activeTab}
        animated={{
          inkBar: false,
          tabPane: false,
        }}
      />
      {name && (
        <div style={{ display: 'none' }}>
          <Field name={name} component="input" initialValue={value} />
        </div>
      )}
    </>
  );
};
