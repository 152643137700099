import { isEmpty } from 'lodash';
import { EMPTY_STRING } from '@/config/constants/common-strings';
import { Driver } from '@/types/models/driver';

export const getDefaultDriverDetails = (driver?: Driver) => {
  return !isEmpty(driver)
    ? {
        convictions: Boolean(driver?.convictions),
        loans: Boolean(driver?.loans),
        accidents: Boolean(driver?.accidents),
        country: driver?.country,
        driverLicense: driver?.driverLicense || EMPTY_STRING,
        driverLicenseDate: driver?.driverLicenseDate || EMPTY_STRING,
      }
    : {};
};
