import { FC, useEffect } from 'react';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import { REG_NUMBER_TRAILER_MASK } from 'components/auth-form/constants';
import { useFieldArray } from 'react-final-form-arrays';
import { UiCheckboxField, UiInputField, UiMaskedField } from '@/ui-elements';

export const TrailerField: FC<{ security: boolean }> = ({ security }) => {
  const { fields } = useFieldArray('trailers');
  useEffect(() => {
    if (fields.length === 0) fields.push({});
  });

  return (
    <>
      {fields.map((name) => (
        <Box key={name}>
          <Grid templateColumns="repeat(4, 1fr)" gap={6} mt={4} className="carriers-verification-form">
            <GridItem>
              <UiMaskedField
                name="trailers[0].regNumber"
                label="Номер Полуприцепа"
                placeholder="Номер полуприцепа"
                required
                isDisabled={security}
                mask={REG_NUMBER_TRAILER_MASK}
              />
            </GridItem>
            <GridItem>
              <UiInputField name="trailers[0].capacity" label="Грузоподъемность, т." placeholder="Грузоподъемность" isDisabled={security} />
            </GridItem>
            <GridItem>
              <UiInputField
                name="trailers[0].volume"
                label="Объем (в кубических метрах)"
                type="number"
                placeholder="Объем"
                required
                isDisabled={security}
              />
            </GridItem>
            <GridItem>
              <UiInputField name="trailers[0].dimensions" label="Размеры (ДхШхВ) в метрах" placeholder="(ДхШхВ)" required isDisabled={security} />
            </GridItem>
          </Grid>
          <Box mt={-1}>
            <UiCheckboxField name="trailers[0].accidentsInPastThreeYears" label="Участвовал в ДТП за последние 3 года" isDisabled={security} />
          </Box>
        </Box>
      ))}
    </>
  );
};
