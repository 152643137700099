// import { TClearTrashMode } from '../store';
// import { AxiosResponse } from 'axios';
// import { ResponseAPI, ErrorAPIData } from '@/api/documents/common';
import { TemplateData } from '@/api/documents/templates-types';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';

export interface DocumentAttachment {
  ID: number;
  Subject: string;
  UrlOriginal: string;
  UrlThumbnail: string;
  OriginalName: string;
  Extension: string;
}

export interface Document {
  ID: number;
  Type: DocumentType;
  Created: number;
  TypeName: string;
  DocumentAttachment: DocumentAttachment[];
  children?: Document[];
  DocumentTemplateID?: number;
  Archive?: number;
  Edited?: number;
  Deleted?: number;
}

export enum DocumentType {
  Deal = 'deal',
  Contract = 'contract',
  ContractAnnex = 'contractAnnex',
  LadingBill = 'ladingBill',
  RouteList = 'routeList',
  Bill = 'bill',
  Custom = 'custom',
}

export const DocumentTypeName: Record<DocumentType, string> = {
  [DocumentType.Deal]: 'Сделка',
  [DocumentType.Contract]: 'Договор',
  [DocumentType.ContractAnnex]: 'Приложение',
  [DocumentType.LadingBill]: 'ТТН',
  [DocumentType.RouteList]: 'Маршрутный лист',
  [DocumentType.Custom]: 'Другое',
  [DocumentType.Bill]: 'Счет',
};

export enum DocumentAction {
  View = 'View',
  Delete = 'Delete',
  Archive = 'Archive',
  RestoreArchive = 'RestoreArchive',
  RestoreDocument = 'RestoreDocument',
  Download = 'Download',
  Update = 'Update',
  SaveAsTemplate = 'SaveAsTemplate',
  DeleteAttachTemplate = 'DeleteAttachTemplate',
  UpdateDealTemplate = 'UpdateDealTemplate',
  DeleteSet = 'DeleteSet',
  Send = 'Send',
  SendItem = 'SendItem',
}

export enum DocumentTemplateType {
  Deal = 'template-deal',
  Contract = 'template-contract',
  ContractAnnex = 'template-contractAnnex',
  LadingBill = 'template-ladingBill',
  RouteList = 'template-routeList',
}

export const documentTypeToLabelMap: Record<DocumentType, string> = {
  [DocumentType.Deal]: 'Сделки',
  [DocumentType.Contract]: 'Договоры',
  [DocumentType.ContractAnnex]: 'Приложения',
  [DocumentType.LadingBill]: 'ТТН',
  [DocumentType.RouteList]: 'Маршрутные листы',
  [DocumentType.Bill]: 'Счета',
  [DocumentType.Custom]: 'Другие',
};

export const modalTemplateTitle: Record<DocumentTemplateType, string> = {
  [DocumentTemplateType.Deal]: 'Шаблоны сделок',
  [DocumentTemplateType.Contract]: 'Шаблоны договоров',
  [DocumentTemplateType.ContractAnnex]: 'Шаблоны приложений',
  [DocumentTemplateType.LadingBill]: 'Шаблоны ТТН',
  [DocumentTemplateType.RouteList]: 'Шаблоны маршрутных листов',
};

export const modalTemplateType: Record<DocumentTemplateType, string> = {
  [DocumentTemplateType.Deal]: DocumentType.Deal,
  [DocumentTemplateType.Contract]: DocumentType.Contract,
  [DocumentTemplateType.ContractAnnex]: DocumentType.ContractAnnex,
  [DocumentTemplateType.LadingBill]: DocumentType.LadingBill,
  [DocumentTemplateType.RouteList]: DocumentType.RouteList,
};

export enum TemplateType {
  Contract = 'contract',
  ContractAnnex = 'contractAnnex',
  LadingBill = 'ladingBill',
  RouteList = 'routeList',
  Bill = 'bill',
  File = 'file',
  Custom = 'custom',
}

export interface DocumentTemplateResItem {
  AccountID: number;
  Created: number;
  Data: string;
  Deleted: number;
  ID: number;
  Name: string;
  ParentID: number;
  Type: TemplateType;
}

export interface DocumentTemplateData {
  [key: string]: DocumentTemplateResItem;
}

// export interface UserOptions {
//   clearTrashMode: TClearTrashMode;
// }

export interface DocumentTemplateRes {
  data: DocumentTemplateData;
  success: boolean;
}

export interface IDocumentsPayload {
  type: DocumentType;
  template: number;
  setId: number;

  [key: string]: number | string;
}

export interface IDocumentsUpdatePayload {
  data: { id: number; values?: { Deleted?: number; Archive?: number; Type?: DocumentType } };
  action: DocumentAction;
  updateCurrentDoc?: boolean;
}

export interface IPayloadUpdate {
  [key: string]: string;
}

export interface DocumentIndexPayload {
  type: DocumentType;
  deleted?: number;
  archive?: number;
}

export interface IDocumentDeleteFromTrash {
  ids: number;
}

export interface IDependentListPayload {
  type: string;
  projectId?: number;
  setId: string;
}

export enum OrganizationDocumentType {
  Carrier = 'carrier',
  Customer = 'customer',
}

export type OrganizationDocument = {
  organizationId?: number;
  bankId?: number;
  order?: number;
  closeModal?: VoidFunction;
  postAttachments?: (files: File[], onSuccessCallback?: VoidFunction) => void;
  origin?: OrganizationDocumentType;
};

export type IDocumentsApi = {
  getDocument({ organizationId, bankId, order }: OrganizationDocument): Promise<ResponseWithoutPagerSimple<TemplateData>>;

  // indexTemplates(data?: { type?: string }): Promise<AxiosResponse<ResponseAPI<any> | ErrorAPIData>>;

  // indexDocuments(payload: DocumentIndexPayload): Promise<AxiosResponse<ResponseAPI<DocumentTemplateResItem> | ErrorAPIData>>;

  // getDependentList({ type, projectId, setId }: IDependentListPayload): Promise<AxiosResponse<ResponseAPI<any> | ErrorAPIData>>;

  // create(data: IDocumentsPayload): Promise<AxiosResponse<ResponseAPI<DocumentTemplateRes> | ErrorAPIData>>;
  // createDeal(data: IDocumentsPayload): Promise<AxiosResponse<ResponseAPI<DocumentTemplateRes> | ErrorAPIData>>;

  // update(data: IDocumentsUpdatePayload): Promise<AxiosResponse<ResponseAPI<DocumentTemplateRes> | ErrorAPIData>>;

  // getDocumentData(data: { id: number; isInbox?: number }): Promise<AxiosResponse<ResponseAPI<Document> | ErrorAPIData>>;

  // deleteFromTrash(data: IDocumentDeleteFromTrash): Promise<AxiosResponse<ResponseAPI<DocumentTemplateRes> | ErrorAPIData>>;

  // saveAsTemplate(data: { id: number | string }): Promise<AxiosResponse<ResponseAPI<DocumentTemplateRes> | ErrorAPIData>>;

  // getUserOptions(): Promise<AxiosResponse<ResponseAPI<UserOptions> | ErrorAPIData>>;

  // setUserOptions(data: UserOptions): Promise<AxiosResponse<ResponseAPI<unknown> | ErrorAPIData>>;
  // suggestContractor(data: any): Promise<AxiosResponse<ResponseAPI<unknown> | ErrorAPIData>>;
  // sendContractor(data: any): Promise<AxiosResponse<ResponseAPI<unknown> | ErrorAPIData>>;

  // getDocumentMetrics(data: { type: string }): Promise<AxiosResponse<ResponseAPI<unknown> | ErrorAPIData>>;

  // getReceivedDocuments(): Promise<AxiosResponse<ResponseAPI<unknown> | ErrorAPIData>>;

  // getSendedDocuments(): Promise<AxiosResponse<ResponseAPI<unknown> | ErrorAPIData>>;

  // setDocumentStatus(data: { id: number; status: number }): Promise<AxiosResponse<ResponseAPI<unknown> | ErrorAPIData>>;
};
