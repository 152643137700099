import React, { FC } from 'react';
import classNames from 'classnames';
import { BG_STATUS_PREPAYMENT, COLOR_STATUS_PREPAYMENT, SPACING } from 'components/booker-orders/booker-orders-list/constants';
import { Flex, Text } from '@chakra-ui/react';
import { UiStatus } from '@/ui-elements/status/status';
import './my-organizations.scss';
import { MyOrganizationTypeWithBankDetails } from '@/types/models/my-organization/my-organization';
import { UiDivider } from '@/ui-elements';
import { emptyFunction } from '@/helpers/empty-function';
import { getVatString } from '@/helpers/vat/get-vat-string';

/*
 * Карточка организации аккаунта
 * */
type Props = {
  data: MyOrganizationTypeWithBankDetails;
  selected: boolean;
  handleMyOrganizationsClick?: Function;
};
export const MyOrganizationsItem: FC<Props> = ({ data, selected = false, handleMyOrganizationsClick = emptyFunction }) => {
  const vatString = getVatString(data?.vatType);
  return (
    <Flex
      flexDirection="column"
      className={classNames('my-organizations-item', { selected })}
      pl={SPACING}
      pr={SPACING}
      onClick={() => handleMyOrganizationsClick(data.id)}
    >
      <Flex justifyContent="space-between" mt="16px">
        <Text>{data.name}</Text>
        <UiStatus text={vatString} backGroundColor={BG_STATUS_PREPAYMENT} color={COLOR_STATUS_PREPAYMENT} className="my-organizations-item__VAT" />
      </Flex>
      <UiDivider pt="25px" variant="dark" />
      <Text mt="16px" mb="16px">
        ИНН: {data.INN}
      </Text>
    </Flex>
  );
};
