import { OrdersApi } from '@/api/orders';
import { useQueryWithUpdates } from '@/hooks/use-query-with-updates';
import { OrderCreateSource } from '@/types/api/order-partial';
import { Load } from '@/types/models/loads';
import { Order } from '@/types/models/order';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';

type Props = {
  id?: number;
  staleTime?: number;
  fromSource?: OrderCreateSource;
  update?: boolean;
};

export const useOrderUpdates = ({ id, staleTime, fromSource = OrderCreateSource.ILSExecution, update }: Props) => {
  const queryFn = (isFirst: boolean) => OrdersApi.getOrder(id!, { updates: update ?? !isFirst, fromSource });
  const order = useQueryWithUpdates<ResponseWithoutPagerSimple<Load | Order>>({
    queryKey: ['order', id],
    enabled: !!id,
    staleTime,
    queryFn,
  });
  return { order };
};
