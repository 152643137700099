import { FC, MouseEventHandler } from 'react';
import { TEXT_ERROR_1 } from 'components/error-404-component/text';
import { WIDTH_BUTTON } from 'components/error-404-component/constants';
import { Box, Text } from '@chakra-ui/react';
import { useIcons } from '@/hooks/use-icons';
import { UiButtonFilled } from '@/ui-elements';
import './error-404-component.scss';

type Props = {
  handleAddOrder: MouseEventHandler<HTMLButtonElement>;
  text?: string;
};
export const Error404Component: FC<Props> = ({ handleAddOrder, text }) => {
  const { Error404 } = useIcons();

  return (
    <div className="error-404">
      <Error404 />
      <Box>
        <Text mt="40px" className="error-404__text">
          {TEXT_ERROR_1}
        </Text>
        <Text mb="56px" className="error-404__text">
          {`Вернитесь к ${text} или выберите раздел в верхнем меню.`}
        </Text>
      </Box>
      <UiButtonFilled width={WIDTH_BUTTON} onClick={handleAddOrder}>
        {`К ${text}`}
      </UiButtonFilled>
    </div>
  );
};
