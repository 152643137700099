import { useEffect, useState } from 'react';

export const FirstRender = () => {
  const [isFirst, setIsFirst] = useState(true);
  useEffect(() => {
    if (isFirst) {
      // Update the state to false after the first render
      setIsFirst(false);
    }
  }, [isFirst]);
  return { isFirst };
};
