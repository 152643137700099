import { Text } from '@chakra-ui/react';
import { Approval } from '@/types/models/approval';
import { getApprovalStatus } from '@/helpers/get-approval-status';

type Props = {
  approval: Approval;
};

export const ApprovalHistory = ({ approval }: Props) => {
  const currentStatus = getApprovalStatus(approval);
  return (
    <Text textStyle="h5" pt="10px" color={currentStatus.match(/не/i) ? 'accentRed.150' : 'products.200'}>
      {currentStatus}
    </Text>
  );
};
