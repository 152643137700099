/* eslint-disable react/function-component-definition */
import { FormApi } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { FC, FormEventHandler, useMemo } from 'react';
import { Form, FormProps } from 'react-final-form';

export const useForm = <T extends unknown>() => {
  type Props = {
    initialValues?: Partial<T>;
    onSubmit: (values: T, form: FormApi<T>) => Object | Promise<Object> | void;
    onChange?: FormEventHandler<HTMLFormElement>;
    children: React.ReactNode;
    className?: string;
  };

  return useMemo(() => {
    const UiForm: FC<Props & FormProps<T, Partial<T>>> = ({ children, onChange, ...props }) => (
      <Form
        {...props}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit }) => (
          <form onChange={onChange} onSubmit={handleSubmit} autoComplete="off" className={props.className}>
            {children}
          </form>
        )}
      />
    );
    return UiForm;
  }, []);
};
