import { Input, Stack, InputRightElement, InputGroup, InputProps } from '@chakra-ui/react';
import { Children, forwardRef } from 'react';
import classNames from 'classnames';
import { UiClearButton } from '@/ui-elements';
import { isNilOrEmpty } from '@/helpers/is-nil-or-empty';
import { styles } from './styles';
import './ui-input.scss';

type Props = InputProps & {
  onClear?: (() => void) | ((e: any) => void);
  hideClearButton?: boolean;
};

export const UiInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { children, onClear, hideClearButton = false, ...rest } = props;
  const { placeholder = 'Введите текст', value } = rest;
  const countButton = Children.count(children) + 1;
  const showButton =
    !hideClearButton && !!onClear && (!isNilOrEmpty(value) || !isNilOrEmpty(ref && (ref as any).current?.value)) && !props.isDisabled;
  const RightElement = (
    <Stack direction="row" className="ui-input-stack">
      {showButton && <UiClearButton onClear={onClear} />}
      {children}
    </Stack>
  );

  const rightElementClass = `ui-input-stack-width-${countButton}`;
  const inputPaddingClass = `ui-input-padding-${countButton}`;

  return (
    <InputGroup size="md">
      <Input placeholder={placeholder} {...styles} {...rest} ref={ref} className={classNames(props.className, { [inputPaddingClass]: children })} />
      <InputRightElement children={RightElement} className={rightElementClass} />
    </InputGroup>
  );
});
