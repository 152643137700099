import { FC } from 'react';
import { PageWrapper } from 'pages/page-wrapper';
import { HStack } from '@chakra-ui/react';
import { BookerOrdersView } from '@/views/booker/booker-orders-view/booker-orders-view';
import { BookerOrdersSidebarView } from '@/views/booker/booker-orders-sidebar-view/booker-orders-sidebar-view';

export const BookerOrdersPage: FC = () => (
  <PageWrapper>
    <HStack>
      <BookerOrdersView />
      <BookerOrdersSidebarView />
    </HStack>
  </PageWrapper>
);
