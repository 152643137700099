import { useQuery } from '@tanstack/react-query';
import { CarriersApi } from '@/api/carriers';

export const useCarriersEnum = (q: string = '') => {
  const organizationsEnum = useQuery({
    queryKey: ['organizationsEnum'],
    refetchOnWindowFocus: false,
    queryFn: () => CarriersApi.getCarriersEnum(q),
  });
  const organizationsEnumData = Object.entries(organizationsEnum?.data?.data ?? []).map(([key, value]) => {
    return {
      value: +key,
      label: value,
    };
  });

  return {
    organizationsEnum,
    organizationsEnumData,
  };
};
