import { Box, Heading, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { Driver } from '@/types/models/driver';

type Props = {
  driver: Driver;
};

export const FinesTab: FC<Props> = ({ driver }) => {
  const { speedFines, trafficFines, driversLicenseRevoked, accidents, convictions } = driver;
  return (
    <Box className="fines-tab">
      <Heading>Штраф за привышение скорости</Heading>
      <Text>{speedFines || 0}</Text>
      <Heading>Штрафы за нарушение ПДД</Heading>
      <Text>{trafficFines || 0}</Text>
      <Heading>Лишение прав</Heading>
      <Text>{driversLicenseRevoked || 0}</Text>
      <Heading>Аварии</Heading>
      <Text>{accidents || 0}</Text>
      <Heading>Из них по вине водителя</Heading>
      <Text>{convictions || 0}</Text>
    </Box>
  );
};
