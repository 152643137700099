import { FC } from 'react';
import { Divider } from '@chakra-ui/react';
import { Approval } from '@/types/models/approval';
import './security-table-component.scss';

export const SecurityTableRowFooter: FC<{ approval: Approval }> = ({ approval }) => (
  <div className="security-table-component__cell-footer">
    <Divider />
    <div className="security-table-component__comment">
      <span>{approval.comment}</span>
    </div>
  </div>
);
