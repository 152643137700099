import { useFormState } from 'react-final-form';
import { TrailerField } from 'components/verification-forms/vehicle-verification/subforms/trailer-field';
import { TrailerFieldArray } from 'components/verification-forms/vehicle-verification/subforms/trailer-field-array';
import { Box, Text } from '@chakra-ui/react';

export const TrailerGroupBlock = ({ security }: { security?: boolean }) => {
  const { values } = useFormState();
  return (
    <>
      <Box mt={5} mb={2}>
        <Text fontSize={18} fontWeight={700}>
          Полуприцеп:
        </Text>
      </Box>
      {values.vehicleType?.value === 64 ? <TrailerFieldArray security={!!security} /> : <TrailerField security={!!security} />}
    </>
  );
};
