import { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { loadOptions } from '@/components/create-order-form/subforms/form-configs';
import { REG_NUMBER_MASK } from '@/components/auth-form/constants';
import { VehicleGroupEnum, vehicleTypeLabels } from '@/components/verification-forms/constants';
import { ResourcesFormFieldNames, ResourcesFormLabels, ResourcesFormPlaceholders } from '@/features/resources/constants/form';
import { UiMaskedField, UiSelectField, UiCheckboxField, UiDivider } from '@/ui-elements';
import { UiVehicleTypesPickerField } from '@/ui-elements/form-fields/ui-vehicle-type-picker-field/ui-vehicle-type-picker-field';
import { UiCheckbox } from '@/ui-elements/checkbox/checkbox';
import { UiSwitcher } from '@/ui-elements/switcher/switcher';
import { ResourcesSubformSpecifications } from '@/components/resources/forms/vehicle/subform-specifications/subform-specifications';
import { FormValues } from '@/components/verification-forms/types';

interface Props {
  initialValues?: FormValues;
  vehicleGroup: VehicleGroupEnum;
  handleChangeVehicleType: (val: string) => void;
  withoutTrailer: boolean;
  handleChangeWithoutTrailer: VoidFunction;
}
export const ResourcesSubformGeneralInfo: FC<Props> = ({
  initialValues,
  vehicleGroup,
  handleChangeVehicleType,
  handleChangeWithoutTrailer,
  withoutTrailer,
}) => {
  const vehicleGroupDefaultTab = initialValues?.vehicleGroup ? vehicleTypeLabels[initialValues?.vehicleGroup]?.key : vehicleTypeLabels[0].key;
  return (
    <Box className="resources-form-column">
      <UiSwitcher
        name={ResourcesFormFieldNames.vehicleGroup}
        value={vehicleGroup}
        defaultActiveTab={vehicleGroupDefaultTab}
        onChange={handleChangeVehicleType}
        tabsList={vehicleTypeLabels}
      />
      {vehicleGroup === VehicleGroupEnum.special && (
        <>
          <UiCheckbox defaultChecked={withoutTrailer} label={ResourcesFormLabels.withoutTrailer} onChange={handleChangeWithoutTrailer} />
          <UiDivider />
        </>
      )}
      <Box className="resources-form-row">
        <UiVehicleTypesPickerField label={ResourcesFormLabels.vehicleType} name={ResourcesFormFieldNames.vehicleType} isMulti={false} />
        <UiMaskedField
          mask={REG_NUMBER_MASK}
          name={ResourcesFormFieldNames.regNumber}
          label={ResourcesFormLabels.regNumber}
          placeholder={ResourcesFormPlaceholders.regNumber}
          required
        />
      </Box>
      {withoutTrailer && <ResourcesSubformSpecifications />}
      <UiSelectField
        options={loadOptions}
        name={ResourcesFormFieldNames.loadingType}
        label={ResourcesFormLabels.loadingType}
        placeholder={ResourcesFormPlaceholders.loadingType}
        isMulti
      />
      <UiCheckboxField
        name={ResourcesFormFieldNames.accidentsInPastThreeYears}
        label={ResourcesFormLabels.accidentsInPastThreeYears}
        defaultChecked={initialValues?.accidentsInPastThreeYears}
      />
    </Box>
  );
};
