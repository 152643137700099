import { ResourcesContentTab, ResourcesSidebarMode } from '@/features/resources/types/general';
import { ResourcesFormFieldNames, ResourcesFormLabels } from '@/features/resources/constants/form';
import { ResourcesDetailsSidebar } from '@/views/resources/sidebars/details-sidebar/resources-details-sidebar';
import { ResourcesFormSidebar } from '@/views/resources/sidebars/form-sidebar/resources-form-sidebar';
import { ResourcesDriverDetailsView } from '@/views/resources/details/resources-driver-details-view';
import { ResourcesVehicleDetailsView } from '@/views/resources/details/resources-vehicle-details-view';
import { ResourcesDriverFormView } from '@/views/resources/form/resources-driver-form-view';
import { ResourcesVehicleFormView } from '@/views/resources/form/resources-vehicle-form-view';

/** Компоненты Sidebar - либо подробная информация, либо форма, в зависимости от ResourcesSidebarMode */
export const RESOURCES_SIDEBARS = {
  [ResourcesSidebarMode.Details]: ResourcesDetailsSidebar,
  [ResourcesSidebarMode.EditForm]: ResourcesFormSidebar,
  [ResourcesSidebarMode.CreateForm]: ResourcesFormSidebar,
  [ResourcesSidebarMode.None]: null,
};

/** Контейнеры Details - подробная информация либо о водителе, либо о ТС, в зависимости от ResourcesContentTab */
export const RESOURCES_DETAILS_VIEWS = {
  [ResourcesContentTab.Drivers]: ResourcesDriverDetailsView,
  [ResourcesContentTab.Vehicles]: ResourcesVehicleDetailsView,
};

/** Контейнеры Form - либо создание, редактирование водителя, либо ТС, в зависимости от ResourcesContentTab */
export const RESOURCES_FORM_VIEWS = {
  [ResourcesContentTab.Drivers]: ResourcesDriverFormView,
  [ResourcesContentTab.Vehicles]: ResourcesVehicleFormView,
};

export const RESOURCES_DRIVER_DOCUMENTS = [
  {
    fieldName: ResourcesFormFieldNames.passportDoc,
    label: ResourcesFormLabels.passportDoc,
  },
  {
    fieldName: ResourcesFormFieldNames.driversLicenseDoc,
    label: ResourcesFormLabels.driversLicenseDoc,
  },
  {
    fieldName: ResourcesFormFieldNames.laborContractDoc,
    label: ResourcesFormLabels.laborContractDoc,
  },
];
export const RESOURCES_VEHICLE_DOCUMENTS = [
  {
    fieldName: ResourcesFormFieldNames.VRCFront,
    label: ResourcesFormLabels.VRCFront,
  },
  {
    fieldName: ResourcesFormFieldNames.VRCBack,
    label: ResourcesFormLabels.VRCBack,
  },
  {
    fieldName: ResourcesFormFieldNames.VPFront,
    label: ResourcesFormLabels.VPFront,
  },
  {
    fieldName: ResourcesFormFieldNames.VPBack,
    label: ResourcesFormLabels.VPBack,
  },
  {
    fieldName: ResourcesFormFieldNames.ExtraDoc,
    label: ResourcesFormLabels.ExtraDoc,
  },
];
