import { SettingsFormValues } from 'components/settings/types';
import { SetFormValues } from 'components/settings/settings-details-empty/schema';

export const createFormSettingsValues = (settings: SetFormValues): SettingsFormValues => {
  return {
    templatesForCarrier: settings?.templatesForCarrier?.value,
    templatesForCustomers: settings?.templatesForCustomers?.value,
    organizationForCarrier: settings?.organizationForCarrier?.value,
    organizationForCustomers: settings?.organizationForCustomers?.value,
  };
};
