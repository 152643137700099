import { FunnelCount, FunnelSwitcherStatus } from '@/types/models/funnel';
import { Tab } from '@/ui-elements/switcher/types';

export const getLabelsForFunnelTabs = (metrics?: FunnelCount, switcherNames?: Record<FunnelSwitcherStatus, string> | undefined): Array<Tab> => {
  const labels: Array<{ key: string; label: string }> = [];
  if (switcherNames && metrics) {
    Object.entries(switcherNames).map(([key, value]) => {
      labels.push({
        key,
        label: `${value} (${metrics?.[key as keyof typeof metrics] || 0})`,
      });
    });
  }
  return labels;
};
