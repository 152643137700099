import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { Flex, HStack, Text } from '@chakra-ui/react';
import { SortType } from 'config/constants/types';
import { useIcons } from '@/hooks/use-icons';
import { UiButtonFilled } from '@/ui-elements/button/button-filled';
import './search-carrier-filter-bottom.scss';
import { emptyFunction } from '@/helpers/empty-function';

type Props = {
  addAllCarriersRequest: () => void;
  onChangeSorting: (sortType: SortType) => void;
  foundAmount?: number;
  canRequestAll: boolean;
};

export const SearchCarrierFilterBottom: FC<Props> = ({ foundAmount, addAllCarriersRequest, onChangeSorting = emptyFunction, canRequestAll }) => {
  const { MapPinFill, Sorting } = useIcons();
  const [sorting, setSorting] = useState(false);
  const sortingName = sorting ? SortType.Asc : SortType.Desc;
  const foundCarrierAmount = foundAmount ?? '0';

  const handleChangeSorting = () => {
    setSorting((prev) => !prev);
  };

  useEffect(() => {
    onChangeSorting(sortingName);
  }, [sortingName, onChangeSorting]);

  return (
    <Flex className="search-carrier-filter-bottom">
      <div className="bottom-item">
        <Text className="bottom-item__search-text">{`Найдено ${foundCarrierAmount}`}</Text>
        <HStack className="bottom-item__button-text">
          <MapPinFill />
          <div>Показать на карте</div>
        </HStack>
        <HStack
          ml={22}
          onClick={handleChangeSorting}
          className={classNames('search-carrier-filter__bottom-item__sorting', { asc: sortingName === SortType.Asc })}
        >
          <Sorting />
          <Text>По рейтингу</Text>
        </HStack>
      </div>
      {canRequestAll && (
        <UiButtonFilled
          onClick={addAllCarriersRequest}
          type="submit"
          /** Логика не доработана, временная блокировка во избежание ошибок */
          disabled
          // disabled={!foundAmount}
        >
          {`Запросить у всех (${foundCarrierAmount})`}
        </UiButtonFilled>
      )}
    </Flex>
  );
};
