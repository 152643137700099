import { FC } from 'react';
import { GridItem } from '@chakra-ui/react';
import { VerificationFormMode } from 'components/verification-forms/types';
import { UiInputField } from '@/ui-elements';
import { UiPhoneField } from '@/ui-elements/form-fields/ui-phone-field/ui-phone-field';

export const DriverContactFields: FC<{ mode: VerificationFormMode; initialValues: any }> = ({ mode, initialValues }) => {
  return (
    <>
      <GridItem>
        <UiInputField
          name="country"
          label="Страна регистрации водителя"
          placeholder="Страна регистрации водителя"
          content="По документу, подтверждающему личность"
          required
          isDisabled={mode === 'check'}
        />
      </GridItem>
      <GridItem>
        <UiPhoneField
          defaultValue={initialValues?.phoneNumber}
          name="phoneNumber"
          label="Телефон основной"
          placeholder="Телефон основной"
          content="Телефон, по которому можно связаться"
          required
          isDisabled={mode === 'check'}
        />
      </GridItem>
    </>
  );
};
