/** Табы водители и ТС */
export enum ResourcesContentTab {
  Drivers = 'Drivers',
  Vehicles = 'Vehicles',
}

/* Табы со статусами водителей и транспортных средств */
export enum ResourcesStatusTab {
  All = 'all',
  Checking = 'checking',
  Checked = 'checked',
  Canceled = 'canceled',
}

/* Типы sidebar */
export enum ResourcesSidebarMode {
  None = 'None',
  Details = 'Details',
  EditForm = 'EditForm',
  CreateForm = 'CreateForm',
}
