import { Button, ButtonProps, Icon, Tooltip } from '@chakra-ui/react';
import { FC, ReactElement } from 'react';
import classNames from 'classnames';
import { Mode, Size } from '@/ui-elements/button/types';
import './button.scss';

export type UiButtonProps = Omit<ButtonProps, 'leftIcon' | 'rightIcon'> & {
  mode?: Mode;
  size?: Size;
  leftIcon?: string | ReactElement | React.ElementType;
  rightIcon?: string | ReactElement | React.ElementType;
  className?: string;
  tooltip?: string;
};

export const UiButton: FC<UiButtonProps> = ({
  isLoading = false,
  disabled = false,
  mode = 'light',
  size = 'large',
  children,
  onClick,
  leftIcon,
  rightIcon,
  className,
  tooltip,
  ...props
}) => {
  const isDisabled = disabled || isLoading;
  const handleClick = (e: any) => !isDisabled && onClick?.(e);
  return (
    <Button
      isLoading={isLoading}
      disabled={isDisabled}
      // @ts-ignore несовместимость типов в ChakraUi
      leftIcon={leftIcon && <Icon as={leftIcon} />}
      // @ts-ignore несовместимость типов в ChakraUi
      rightIcon={rightIcon && <Icon as={rightIcon} />}
      className={classNames(
        className,
        'ui-button',
        { 'ui-button_disabled': isDisabled },
        { 'ui-button_light': mode === 'light' },
        { 'ui-button_dark': mode === 'dark' },
        { 'ui-button_neutral': mode === 'neutral' },
        { 'ui-button_lightdark': mode === 'lightdark' },
        { 'ui-button_transparent': mode === 'transparent' },
        { 'ui-button_accent': mode === 'accent' },
        { 'ui-button_large': size === 'large' },
        { 'ui-button_small': size === 'small' }
      )}
      onClick={handleClick}
      {...props}
    >
      {tooltip ? <Tooltip label={tooltip} placement="top" hasArrow children={children} /> : children}
    </Button>
  );
};
