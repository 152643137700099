import { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import './customers-table-component.scss';
import { CustomersTableComponentItem } from 'components/customers/customers-table-component/customers-table-component-item';
import { CustomersTableComponentHeader } from 'components/customers/customers-table-component/customers-table-component-header';
import { FormApi } from 'final-form';
import { CustomersCreateFormValues } from 'components/create-order-form/schema';
import { CustomerAddForm } from '@/components/customers/customer-add-form/customer-add-form';
import { emptyFunction } from '@/helpers/empty-function';
import { VoidFunction } from '@/types/common/void-function';
import { Customer } from '@/types/models/customer';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { UserRole } from '@/types/api/user-role';
import { CustomerUpdateView } from '@/views/customer-update-view/customer-update-view';
import { InfiniteScroll } from '@/views/infinite-scroll/infinite-scroll';
import { AccountState } from '@/features/account/types';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';

type Props = {
  data?: Array<Customer>;
  onSelectCustomers: Function;
  onUnSelectCustomers: Function;
  selectedCustomersId?: number;
  isActiveForm?: boolean;
  closeForm?: VoidFunction;
  addCustomer?: (values: CustomersCreateFormValues, form: FormApi) => Object | Promise<Object> | void;
  shortManagers: Array<SelectOption>;
  role?: string;
  customersRefetch: Function;
  loadMore: (args: any) => void;
  loading?: boolean;
  currentAccount?: AccountState;
  isInitialLoading?: boolean;
  submitting?: boolean;
};
export const CustomersTableComponent: FC<Props> = ({
  data,
  onSelectCustomers,
  onUnSelectCustomers,
  selectedCustomersId,
  isActiveForm,
  closeForm = emptyFunction,
  addCustomer = emptyFunction,
  shortManagers,
  role,
  customersRefetch,
  loadMore,
  loading = false,
  currentAccount,
  isInitialLoading,
  submitting,
}) => {
  const handleCustomersClick = (customerId: number) => {
    if (customerId === selectedCustomersId) {
      onUnSelectCustomers();
    } else {
      onSelectCustomers(customerId);
    }
  };

  const initialValues =
    currentAccount?.user?.userRole === UserRole.Manager
      ? {
          manager: {
            value: currentAccount?.user?.id,
            label: currentAccount?.user?.name,
          },
        }
      : undefined;

  return (
    <Flex flexDirection="column" m="16px" className="customer-table-component">
      <CustomersTableComponentHeader />
      {isInitialLoading ? (
        <Box h="60vh" mt={48}>
          <UiSpinner visible />
        </Box>
      ) : (
        <UiScrollbars style={{ width: '102%', height: '78vh' }}>
          {isActiveForm && (
            <CustomerAddForm
              closeForm={closeForm}
              addCustomer={addCustomer}
              managers={shortManagers}
              updatedValues={initialValues}
              submitting={submitting}
            />
          )}
          <InfiniteScroll callback={loadMore} loading={loading} skip={isEmpty(data)}>
            {data?.map((customer) => {
              if (!customer) return null;
              return (selectedCustomersId === customer.id && role === UserRole.Administrator) ||
                (selectedCustomersId === customer.id && role === UserRole.Manager) ? (
                <CustomerUpdateView key={`${customer.id}`} managers={shortManagers} customersRefetch={customersRefetch} />
              ) : (
                <CustomersTableComponentItem
                  customer={customer}
                  handleCustomersClick={handleCustomersClick}
                  selected={selectedCustomersId === customer.id}
                  key={`${customer.id}`}
                />
              );
            })}
          </InfiniteScroll>
        </UiScrollbars>
      )}
    </Flex>
  );
};
