import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/types/redux/root-state';

export const resourcesContentTabSelector = createSelector(
  (state: RootState) => state.resources,
  (resources) => resources.activeContentTab
);

export const resourcesActiveStatusTabSelector = createSelector(
  (state: RootState) => state.resources,
  (resources) => resources.activeStatusTab
);

export const resourcesSidebarModeSelector = createSelector(
  (state: RootState) => state.resources,
  (resources) => resources.sidebarMode
);

export const resourcesSelectedDriverIdSelector = createSelector(
  (state: RootState) => state.resources,
  (resources) => resources.selectedDriverId
);

export const resourcesSelectedVehicleIdSelector = createSelector(
  (state: RootState) => state.resources,
  (resources) => resources.selectedVehicleId
);
