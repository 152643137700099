import { FC } from 'react';
import { ModalsProps } from '@/types/common/modals';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';
import { ModalDriver } from './modal-driver';
import { useModalDriver } from '@/features/modals/hooks/use-modal-driver';

export const ModalEditDriver: FC<ModalsProps<{ driverId?: number }>> = ({ onClose, driverId, modalOpened }) => {
  const { initialValues, driver } = useModalDriver({ driverId });

  if (!driver) return <UiSpinner visible />;

  return <ModalDriver modalOpened={modalOpened} onClose={onClose} initialValues={initialValues} driver={driver} />;
};
