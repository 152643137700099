import debounce from 'lodash/debounce';
import { toast } from 'react-toastify';
import { styles } from '@/ui-elements/inputs/ui-search-async/styles';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { UiSelectAsync } from '@/ui-elements/inputs/ui-select/ui-select-async';

type Props = {
  fetchOptions: (arg: string) => Promise<Array<SelectOption>>;
  treshold?: number;
  isInvalid?: boolean;
  isDisabled?: boolean;
};

export const UiSearchAsync = ({ fetchOptions, treshold = 2, ...props }: Props) => {
  const loadOptions = (inputValue: string, callback: (options: Array<SelectOption>) => void) => {
    if (inputValue && inputValue.length > treshold) {
      fetchOptions(inputValue)
        .then((x) => callback(x))
        .catch(() => toast.error('Адрес не найден. Проверьте правильность введенных данных'));
    }
  };

  const debounceFunc = debounce(loadOptions, 600, { leading: false });

  return <UiSelectAsync isSearchable chakraStyles={styles} isClearable {...props} loadOptions={debounceFunc} noOptionsMessage={() => null} />;
};
