import { FC } from 'react';
import { PrepaymentFormFieldNames } from 'components/order-details/order-details-prepayment/types';
import { UiSliderField } from '@/ui-elements/ui-slider-field/ui-slider-field';

export type PrepaymentWidgetProps = {
  setPrepayment: (arg: number) => void;
  prepaymentStep?: number | undefined;
  tax: number;
  maxPrepayment?: number;
  prepayment: number;
};

export const PrepaymentWidget: FC<PrepaymentWidgetProps> = ({ setPrepayment, prepayment, maxPrepayment, prepaymentStep, tax }) => {
  return (
    <UiSliderField
      onChange={setPrepayment}
      name={PrepaymentFormFieldNames.prepayment}
      defaultValue={prepayment || 0}
      min={0}
      max={maxPrepayment}
      step={prepaymentStep}
      sliderName="Деньгами"
      counterName="Комиссия"
      counterValue={tax}
    />
  );
};
