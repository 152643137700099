import { useMutation } from '@tanstack/react-query';
import { OrderCreateSource, OrderMerge, OrderNew, OrderReview, OrderSetStatus } from '@/types/api/order-partial';
import { OrdersApi } from '@/api/orders';
import { LoadNew, LoadPaymentNew } from '@/types/models/funnel';

export const useFunnelDataActions = (id: number) => {
  const addOrder = useMutation({
    mutationKey: ['addOrder'],
    mutationFn: (options: OrderNew | LoadNew) => OrdersApi.addOrder(options),
  });

  const updateOrder = useMutation({
    mutationKey: ['updateOrder'],
    mutationFn: (options: Partial<LoadNew & LoadPaymentNew>) => OrdersApi.updateOrder(id, options),
  });

  const mergeOrder = useMutation({
    mutationKey: ['mergeOrder'],
    mutationFn: (options: OrderMerge) => OrdersApi.addOrderMerge(options),
  });

  const uploadDocument = useMutation({
    mutationKey: ['uploadDocument'],
    mutationFn: (body: FormData) => OrdersApi.uploadDocument(id, body),
  });

  const deleteDocument = useMutation({
    mutationKey: ['deleteDocument'],
    mutationFn: (documentId: number) => OrdersApi.deleteDocument(id, documentId),
  });

  const splitOrder = useMutation({
    mutationKey: ['splitOrder'],
    mutationFn: (options: Array<OrderNew>) => OrdersApi.addOrderSplit(id, options),
  });

  const updateOrderStatus = useMutation({
    mutationKey: ['updateOrderStatus'],
    mutationFn: (options: OrderSetStatus) => OrdersApi.updateOrderStatus(id, options),
  });

  const deleteOrder = useMutation({
    mutationKey: ['deleteOrder'],
    mutationFn: (orderId: number) => OrdersApi.deleteOrder(orderId, OrderCreateSource.ILSConfirmation),
  });
  const cloneOrder = useMutation({
    mutationKey: ['cloneOrder'],
    mutationFn: (orderId: number) => OrdersApi.cloneOrder(orderId),
  });

  const addReview = useMutation({
    mutationKey: ['addReview'],
    mutationFn: (options: OrderReview) => OrdersApi.addReview(id, options),
  });

  const setCarrierVat = useMutation({
    mutationKey: ['setCarrierVat'],
    mutationFn: (options: { vat: boolean }) => OrdersApi.setCarrierVat(id, options),
  });

  return {
    addOrder,
    mergeOrder,
    uploadDocument,
    deleteDocument,
    splitOrder,
    updateOrderStatus,
    deleteOrder,
    updateOrder,
    addReview,
    setCarrierVat,
    cloneOrder,
  };
};
