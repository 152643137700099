import { CompletedStatusNames } from '@/config/constants/texts';
import { OrderCompletedSwitcherStatus } from '@/config/constants/statuses';

export const orderCompletedOptions = [
  {
    value: OrderCompletedSwitcherStatus.All,
    label: CompletedStatusNames.all,
  },
  {
    value: OrderCompletedSwitcherStatus.CompletedPayed,
    label: CompletedStatusNames.completedPayed,
  },
  {
    value: OrderCompletedSwitcherStatus.CompletedWithDocs,
    label: CompletedStatusNames.completedWithDocs,
  },
  {
    value: OrderCompletedSwitcherStatus.CompletedWithoutDocs,
    label: CompletedStatusNames.completedWithoutDocs,
  },
];
