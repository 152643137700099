import { isNumber } from 'lodash';
import { useMutation } from '@tanstack/react-query';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { AttachmentsApi } from '@/api/attachments';
import { AttachmentNew, AttachmentQuery, AttachmentToConfirmDeleteQuery } from '@/types/models/attachments';
import { VoidFunction } from '@/types/common/void-function';

export const useAttachmentsActions = () => {
  const postAttachment = useMutation({
    mutationKey: ['postAttachment'],
    mutationFn: (body: AttachmentNew) => AttachmentsApi.postAttachment(body),
  });

  const deleteAttachment = useMutation({
    mutationKey: ['deleteAttachment'],
    mutationFn: (id: number) => AttachmentsApi.deleteAttachment(id),
  });

  const deleteToConfirm = useMutation({
    mutationKey: ['deleteToConfirm'],
    mutationFn: (body: AttachmentToConfirmDeleteQuery) => AttachmentsApi.deleteToConfirm(body),
  });

  const postAttachments = (files: File[], options: AttachmentQuery, callbacks?: { onSuccess?: VoidFunction; onError?: VoidFunction }) => {
    if (!isNumber(options.dependentID)) {
      files.forEach((file) => {
        postAttachment.mutate(
          {
            attachment: file,
            dependent: options?.dependent,
            subject: options?.subject || EMPTY_STRING,
            dependentID: options.dependentID,
          },
          {
            ...callbacks,
          }
        );
      });
    }
  };

  return {
    postAttachment,
    deleteAttachment,
    deleteToConfirm,
    postAttachments,
  };
};
