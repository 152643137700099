import './carriers-loads-list-view.scss';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';
import { UseCarriersLoadsList } from './use-carriers-loads-list';
import { CarriersLoadsList } from '@/components/carriers-loads/list/carriers-loads-list';
import { CarriersLoadsFiltersForm } from '@/components/carriers-loads/form/carriers-loads-form';

export const CarriersLoadsListView = () => {
  const {
    isInitialLoading,
    allRecords,
    activeOrderId,
    handleSelect,
    fetchNextPage,
    isFetchingNextPage,
    handleRequestOrder,
    handleFilterChange,
    ConfirmCreateOfferModal,
    requestButtonProps,
  } = UseCarriersLoadsList();

  return (
    <div className="carriers-loads-list-view">
      <CarriersLoadsFiltersForm onFormValuesChange={handleFilterChange} />

      {isInitialLoading ? (
        <UiSpinner visible mt={48} />
      ) : (
        <CarriersLoadsList
          orderList={allRecords}
          selectedOrderId={activeOrderId}
          loadMore={fetchNextPage}
          loading={isFetchingNextPage}
          handleOrderClick={handleSelect}
          handleRequestOrder={handleRequestOrder}
          requestButtonProps={requestButtonProps}
        />
      )}
      {ConfirmCreateOfferModal}
    </div>
  );
};
