import { useMutation, useQueryClient } from '@tanstack/react-query';
import { VehicleFormValues } from 'components/verification-forms/schema';
import { VehiclesApi } from '@/api/vehicles';

export const useVehicleActions = (id?: number) => {
  const queryClient = useQueryClient();
  const updateVehicle = useMutation({
    mutationKey: ['updateVehicle'],
    mutationFn: (options: VehicleFormValues) => VehiclesApi.updateVehicle(id!, true, options),
    onSuccess: () => {
      queryClient.invalidateQueries(['approvals', { statuses: 'waiting' }]);
      queryClient.invalidateQueries(['approveMetrics']);
    },
  });
  const updateVehicleWithoutApprovals = useMutation({
    mutationKey: ['updateVehicle'],
    mutationFn: (options: VehicleFormValues) => VehiclesApi.updateVehicle(id!, false, options),
    onSuccess: () => {
      queryClient.invalidateQueries(['approvals', { statuses: 'waiting' }]);
      queryClient.invalidateQueries(['approveMetrics']);
    },
  });

  const addVehicle = useMutation({
    mutationKey: ['addVehicle'],
    mutationFn: (options: VehicleFormValues) => VehiclesApi.addVehicle(options),
  });

  const deleteVehicle = useMutation({
    mutationKey: ['deleteVehicle'],
    mutationFn: (vehicleId: number | undefined) => VehiclesApi.deleteVehicle(vehicleId!),
  });

  return { updateVehicle, addVehicle, deleteVehicle, updateVehicleWithoutApprovals };
};
