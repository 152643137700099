import { isNumber, toNumber } from 'lodash';
import { toast } from 'react-toastify';
import { useCallback, useMemo, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@/config/constants/query-keys';
import { EMPTY_STRING } from '@/config/constants/common-strings';
import { initialLoadsCreateOfferFormValues } from '@/config/constants/carriers-loads';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';
import { OfferNew } from '@/types/models/offers';
import { CarriersLoadsCreateOfferField, CarriersLoadsCreateOfferValues, CarriersLoadsCreateOfferValuesForm } from '@/types/models/carriers-loads';
import { ModalAction, useModal } from '@/hooks/use-modal';
import { ModalType } from '@/types/common/modals';
import { useDriversEnum } from '@/features/drivers/hooks/use-driver-enum';
import { useRoadTrainsEnum } from '@/features/vehicles/hooks/use-road-trains-enum';
import { useIntermediaryResources } from '@/features/resources/hooks';
import { useOffersActions } from '@/features/offers/hooks/use-offers-actions';
import { CarriersLoadsCreateOfferForm } from '@/components/carriers-loads/offer-form/create-offer-form';
import { VatType } from '@/config/constants/types';

export const useChooseOwnDriverVehicleModal = ({ order }: { order?: Order | Load }) => {
  const { carrierId } = useIntermediaryResources();

  const { addOffer } = useOffersActions();
  const queryClient = useQueryClient();

  const priceVAT = Boolean(order?.carrierPayment.vatType === VatType.WithVat || order?.carrierPayment.vatType === VatType.CombinedVat);
  // Данные по форме
  const formValue = useRef<CarriersLoadsCreateOfferValues>({
    driverId: undefined,
    price: undefined,
    vehicleId: undefined,
  });

  /** Варианты для выбора водителей */
  const { driversEnumData } = useDriversEnum({
    q: EMPTY_STRING,
    carrierId,
    options: {
      enabled: true,
    },
  });
  /** Варианты для выбора ТС */
  const driverOptions: SelectOption[] = driversEnumData;
  const { roadTrainsEnumData } = useRoadTrainsEnum({
    q: EMPTY_STRING,
    carrierId,
    options: {
      enabled: true,
    },
  });
  const vehicleOptions: SelectOption[] = roadTrainsEnumData;

  const handleChangeFrom = useCallback((values: Partial<CarriersLoadsCreateOfferValuesForm>) => {
    formValue.current = {
      driverId: toNumber(values?.[CarriersLoadsCreateOfferField.DriverId]?.value) || undefined,
      vehicleId: toNumber(values[CarriersLoadsCreateOfferField.VehicleId]?.value) || undefined,
      price: toNumber(values[CarriersLoadsCreateOfferField.Price]) || undefined,
    };
  }, []);

  const createOffer = (closeCallback?: () => void) => {
    const createOfferPayload = {
      orderId: order?.id,
      ...formValue.current,
      driverId: formValue.current.driverId ?? 0,
      carrierId,
    };
    if (Object.values(createOfferPayload).every(isNumber)) {
      closeCallback?.();
      addOffer.mutate(createOfferPayload as OfferNew, {
        onSuccess: () => {
          toast.success('Вы выбрали водителя и авто');
          queryClient.invalidateQueries([QueryKeys.allOrders]);
          queryClient.invalidateQueries([QueryKeys.order, order?.id]);
        },
      });
    }
  };

  // Начальные значения для формы отклика
  const initialValues: CarriersLoadsCreateOfferValuesForm = useMemo(() => {
    return {
      ...initialLoadsCreateOfferFormValues,
      price: priceVAT ? order?.carrierPayment?.priceWithVAT : order?.carrierPayment?.priceWithoutVAT || 0,
    };
  }, [order, priceVAT]);

  const { modalView: ModalChooseOwnDriverVehicle, setModalOpened: showChooseOwnDriverVehicleModal } = useModal<
    { text: string; submitButtonText: string; formComponent: React.ReactElement } & ModalAction
  >(ModalType.ChooseOwnDriverVehicle, {
    onSuccess: () => createOffer(() => showChooseOwnDriverVehicleModal(false)),
    text: 'Выбрать своего Водителя и ТС',
    submitButtonText: 'Назначить',
    formComponent: (
      <CarriersLoadsCreateOfferForm
        disablePrice
        priceVAT
        drivers={driverOptions}
        vehicles={vehicleOptions}
        isCarrierSimple={false}
        onFormValuesChange={handleChangeFrom}
        initialValues={initialValues}
        hidePriceField
      />
    ),
  });

  const onChooseOwnDriverAndVehicle = () => {
    showChooseOwnDriverVehicleModal(true);
  };
  return { onChooseOwnDriverAndVehicle, ModalChooseOwnDriverVehicle };
};
