import { FC } from 'react';
import { HStack, VStack } from '@chakra-ui/react';
import { ACTION_HEADER, CREATED_HEADER, CREATOR_HEADER, TIMELINE_HEADER } from '@/features/order/texts';
import { OrderTimeline } from '@/types/models/order-timeline';
import './order-timeline-component.scss';
import { formatMomentLocal } from '@/helpers/moment/format-moment-local';
import { MOMENT_L_LT } from '@/helpers/moment/constants';

type Props = {
  items?: Array<OrderTimeline>;
};
export const OrderTimelineComponent: FC<Props> = ({ items }) => (
  <VStack>
    <span className="order-timeline__caption">{TIMELINE_HEADER}</span>
    <HStack className="order-timeline__headers">
      <span className="order-timeline__created">{CREATED_HEADER}</span>
      <span className="order-timeline__title">{ACTION_HEADER}</span>
      <span className="order-timeline__creator">{CREATOR_HEADER}</span>
    </HStack>
    <div className="order-timeline__rows">
      {items?.map((timeline) => (
        <HStack className="order-timeline__row" key={timeline.created}>
          <span className="order-timeline__created">{formatMomentLocal(timeline.created, MOMENT_L_LT)}</span>
          <span className="order-timeline__title">{timeline.title}</span>
          <span className="order-timeline__creator">{timeline.creator.name}</span>
        </HStack>
      ))}
    </div>
  </VStack>
);
