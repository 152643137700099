import { OrdersApi } from '@/api/orders';
import { useQueryWithUpdates } from '@/hooks/use-query-with-updates';
import { OrderCount } from '@/types/api/order-partial';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';

export const useOrdersCountUpdates = () => {
  const ordersCount = useQueryWithUpdates<ResponseWithoutPagerSimple<OrderCount>>({
    queryKey: ['ordersCount'],
    refetchOnWindowFocus: false,
    queryFn: (isFirst: boolean) => OrdersApi.getOrdersCount(!isFirst),
  });
  return { ordersCount };
};
