import { Box, Text, Flex } from '@chakra-ui/react';
import { ApproveStatus } from 'config/constants/statuses';
import moment from 'moment/moment';
import { OrganisationRating } from 'components/organisation-rating/organisation-rating';
import { RatingType } from 'config/constants/types';
import { getEmptyStr } from '@/helpers/get-empty-str';
import { UiButtonFilled } from '@/ui-elements';
import { Approval } from '@/types/models/approval';
import { Customer } from '@/types/models/customer';
import { MOMENT_DD_MM_YYYY } from '@/helpers/moment/constants';

type Props = {
  setChecking: (arg0: boolean) => void;
  approval?: Approval;
  customerInfo?: Customer;
};

export const CustomerDetails = ({ setChecking, approval, customerInfo }: Props) => {
  const managerName = `${getEmptyStr(customerInfo?.manager?.surname)} ${getEmptyStr(customerInfo?.manager.name)} ${getEmptyStr(
    customerInfo?.manager?.patronymic
  )}`;

  if (!approval) return null;
  const { customer } = approval;
  if (!customer) return null;
  return (
    <Box>
      <Flex gap={2}>
        <Box>{customerInfo?.vipCustomer !== 0 ? <OrganisationRating ratingType={RatingType.Vip} /> : undefined}</Box>
        <Text fontWeight={700} fontSize={18}>
          {customerInfo?.name}
        </Text>
      </Flex>
      <Text fontWeight={600} mt={2.5}>
        ИНН: {customerInfo?.INN}
      </Text>
      {customerInfo?.contractNumber && (
        <Text fontWeight={600} mt={2.5}>
          Договор: {customerInfo?.contractNumber}
        </Text>
      )}
      {customerInfo?.expiration && (
        <Text fontWeight={600} mt={2.5}>
          Дата договора: {moment(customerInfo?.expiration).format(MOMENT_DD_MM_YYYY)}
        </Text>
      )}
      {customerInfo?.manager.id && (
        <Text fontWeight={600} mt={2.5}>
          Ответственный менеджер: {managerName}
        </Text>
      )}
      {customerInfo?.tariffComment && (
        <Text fontWeight={600} mt={2.5}>
          Тарифное соглашение: {customerInfo?.tariffComment}
        </Text>
      )}
      {customerInfo?.comment && (
        <Text fontWeight={600} mt={2.5}>
          Комментарий: {customerInfo?.comment}
        </Text>
      )}

      {approval.customer && approval.status === ApproveStatus.Waiting && (
        <Box mt={4}>
          <UiButtonFilled mode="dark" onClick={() => setChecking(true)} width={172}>
            Начать проверку
          </UiButtonFilled>
        </Box>
      )}
    </Box>
  );
};
