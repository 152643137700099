import { Flex } from '@chakra-ui/react';
import { PageWrapper } from '@/pages/page-wrapper';
import { FunnelSidebar } from '@/views/funnel-sidebar/funnel-sidebar';
import { FunnelListView } from '@/views/funnel-list-view/funnel-list-view';

export const FunnelPage = () => {
  return (
    <PageWrapper>
      <Flex>
        <FunnelListView />
        <FunnelSidebar />
      </Flex>
    </PageWrapper>
  );
};
