import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { DocumentTypes } from '@/config/constants/document-types';
import { QueryKeys } from '@/config/constants/query-keys';
import { AttachmentDeps } from '@/config/constants/types';
import { ResourcesDriverFormValues } from '@/features/resources/constants/form';
import { useDriverActions } from '@/features/drivers/hooks/use-driver-actions';
import { useAttachmentsActions } from '@/features/attachments/hooks/use-attachments-actions';
import { driverFormValuesToData } from '@/features/resources/helpers/driver-form-values-to-data';

type Props = {
  carrierId?: number;
  closeForm: VoidFunction;
};

export const useCreateResourcesDriver = ({ carrierId, closeForm }: Props) => {
  const client = useQueryClient();
  const { addDriver } = useDriverActions();
  const { postAttachment } = useAttachmentsActions();

  const handleCreateDriver = (values: ResourcesDriverFormValues) => {
    const normalisedValues = driverFormValuesToData(values, carrierId);
    addDriver.mutate(normalisedValues, {
      onSuccess: (response: any) => {
        const { id } = response.data;
        client.invalidateQueries([QueryKeys.allDrivers]);
        toast.success('Водитель успешно создан');
        closeForm();
        if (id) {
          values.passportDoc?.map((file: File) => {
            postAttachment.mutate({
              attachment: file,
              dependent: AttachmentDeps.Driver,
              dependentID: id,
              subject: DocumentTypes.dlDocumentDriverPassport,
            });
          });
          values.driversLicenseDoc?.map((file: File) => {
            postAttachment.mutate({
              attachment: file,
              dependent: AttachmentDeps.Driver,
              dependentID: id,
              subject: DocumentTypes.dlDocumentDriverLicense,
            });
          });
          values.laborContractDoc?.map((file: File) => {
            postAttachment.mutate({
              attachment: file,
              dependent: AttachmentDeps.Driver,
              dependentID: id,
              subject: DocumentTypes.dlDocumentDriverContract,
            });
          });
        }
      },
    });
  };
  return { handleCreateDriver };
};
