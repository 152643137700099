import { FC } from 'react';
import { OrderPointWithIcon } from '@/components';
import './order-route.scss';
import { ROUTE } from '@/features/order/texts';
import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';

type Props = {
  order: Order | Load;
};
/**
 * Маршрут по заявке - вертикальное отображение
 * */
export const OrderRoute: FC<Props> = ({ order }) => {
  const { points } = order;
  return (
    <div className="order-route">
      <div className="order-route__caption">{ROUTE}</div>
      {points &&
        points.map((point, index) => <OrderPointWithIcon key={point.id} point={point} isLastPoint={index === points.length - 1} order={order} />)}
    </div>
  );
};
