import { VatType } from './types';

export const vatOptions: Array<{ value: VatType; label: string }> = [
  { value: VatType.WithVat, label: 'Является плательщиком НДС' },
  { value: VatType.WithoutVat, label: 'Не является плательщиком НДС' },
  { value: VatType.CombinedVat, label: 'Совмещенная система налогообложения' },
];

export const PRICES_WITH_VAT = [VatType.WithVat, VatType.CombinedVat];
export const PRICES_WITHOUT_VAT = [VatType.WithoutVat, VatType.CombinedVat];

export enum VatTypeMap {
  withVat = 'c НДС',
  withoutVat = 'без НДС',
  combinedVat = 'совмещенный тип НДС',
}

export const VAT_TYPE = {
  0: VatType.WithoutVat,
  1: VatType.WithVat,
  2: VatType.CombinedVat,
};
