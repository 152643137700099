import { Dictionary } from 'config/constants/dictionary';
import { VehicleFormValues } from 'components/verification-forms/schema';
import { ApiDelete, ApiGet, ApiPatch, ApiPost } from '@/helpers/api/methods';
import { AllVehiclesFilter, VehiclesEnumRequest } from '@/types/api/vehicles';
import { ResponseWithoutPagerSimple, ResponseWithPager } from '@/types/models/pager';
import { Vehicle } from '@/types/models/vehicle';
import { validateQueryString } from '@/helpers/validate-query-string';
import { ResponseSimpleApi } from '@/types/response';

export const VehiclesApi = {
  getAllVehicles: (query?: AllVehiclesFilter) => ApiGet<ResponseWithPager<Vehicle>>(`${Dictionary.Vehicles}${validateQueryString(query)}`),

  addVehicle: (options: VehicleFormValues) => ApiPost<ResponseWithoutPagerSimple<Vehicle>>(`${Dictionary.Vehicles}`, options),

  getVehicle: (id: number) => ApiGet<ResponseWithoutPagerSimple<Vehicle>>(`${Dictionary.Vehicles}/${id}`),

  updateVehicle: (id: number, approvals: boolean, options: VehicleFormValues) =>
    ApiPatch<ResponseWithoutPagerSimple<Vehicle>>(`${Dictionary.Vehicles}/${id}?approvals=${approvals}`, options),

  deleteVehicle: (id: number) => ApiDelete<ResponseSimpleApi>(`${Dictionary.Vehicles}/${id}`),

  getRoadTrainEnum: (options?: VehiclesEnumRequest) =>
    ApiGet<ResponseWithoutPagerSimple<Record<string, string>>>(`${Dictionary.RoadTrain}/enum${validateQueryString(options)}`),
};
