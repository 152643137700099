import { FC } from 'react';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import { REG_NUMBER_MASK } from 'components/auth-form/constants';
import { VerificationFormMode } from 'components/verification-forms/types';
import { UiMaskedField } from '@/ui-elements';
// import { convertVehiclesToSelectOptions } from '@/helpers/convert-vehicles-to-select-options';
import { UiVehicleTypesPickerField } from '@/ui-elements/form-fields/ui-vehicle-type-picker-field/ui-vehicle-type-picker-field';

export const VehicleParamsSubform: FC<{ mode: VerificationFormMode }> = ({ mode }) => {
  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={0} className="carriers-verification-form">
      <GridItem>
        <Box mt={3} mb={3}>
          <UiVehicleTypesPickerField required label="Выберите тип ТС" name="vehicleType" isMulti={false} />
        </Box>
      </GridItem>
      <GridItem>
        <Box mt={3} mb={3}>
          <UiMaskedField
            mask={REG_NUMBER_MASK}
            name="regNumber"
            label="Регистрационный номер"
            placeholder="Регистрационный номер"
            required
            isDisabled={mode === 'check'}
          />
        </Box>
      </GridItem>
    </Grid>
  );
};
