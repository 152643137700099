import './funnel-filters-form.scss';
import { FC, memo } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { FormSpy } from 'react-final-form';
import { useForm } from '@/helpers/forms/use-form';
import { FormAutosave } from '@/hooks/use-autosave';
import { emptyFunction } from '@/helpers/empty-function';
import { UiInputField } from '@/ui-elements';
import { PeriodPicker } from '@/components/period-picker/period-picker';
import { FunnelListFilters } from '@/types/models/funnel';

type Props = {
  periodValue: string;
  handleChangePeriod: (value: string) => void;
  handleChangeFilters: (values: FunnelListFilters) => void;
};

const FunnelFiltersForm: FC<Props> = ({ periodValue, handleChangePeriod, handleChangeFilters = emptyFunction }) => {
  const Form = useForm();
  return (
    <Box className="funnel-filters-form">
      <Form onSubmit={emptyFunction}>
        <FormSpy subscription={{ values: true }}>{({ values }) => <FormAutosave values={values} onSave={handleChangeFilters} />}</FormSpy>
        <HStack justifyContent="space-between" alignItems="center">
          <UiInputField name="search" placeholder="Поиск по заявкам" hideBottomText />
          <PeriodPicker value={periodValue} onChange={handleChangePeriod} />
        </HStack>
      </Form>
    </Box>
  );
};

// memo чтобы форма не обновлялась и не сбрасывала поля при каждом изменении state фильтров
export default memo(FunnelFiltersForm);
