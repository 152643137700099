import { useAppSelector } from '@/hooks/use-app-selector';
import { SidebarWrapper } from '@/components/sidebar-wrapper/sidebar-wrapper';
import { resourcesContentTabSelector } from '@/features/resources/selectors';
import { RESOURCES_DETAILS_VIEWS } from '@/features/resources/constants/display-blocks';

export const ResourcesDetailsSidebar = ({ closeSidebar }: { closeSidebar: VoidFunction }) => {
  const activeTabContent = useAppSelector(resourcesContentTabSelector);
  const ResourcesDetailsContent = RESOURCES_DETAILS_VIEWS[activeTabContent];

  return (
    <SidebarWrapper closeSidebar={closeSidebar}>
      <ResourcesDetailsContent />
    </SidebarWrapper>
  );
};
