import { routesPath } from 'config/routes/routes-path';
import { Routes } from 'config/routes/routes';
import { UserRole } from '@/types/api/user-role';
import { NavigationMenu } from '@/types/routes/navigation-menu';
import { getMenuRoutes } from '@/helpers/routes/get-menu-routes';
import { AccountRole } from '@/types/api/account-role';

/**
 // *Функция возвращает пункт меню навигации, на который будет переадресован пользователь с определенной ролью по-умолчанию.
 * */
export const getDefaultPathForRole = (
  navigationMenu: NavigationMenu,
  role: UserRole | UserRole[] | undefined,
  accountRole: AccountRole | Array<AccountRole> | undefined
): string => {
  // TODO: поменять тип UserRole на массив
  const menu: NavigationMenu = getMenuRoutes(navigationMenu, role as unknown as UserRole[], accountRole);
  const firstItem =
    menu.items.find((item) => item.route.isDefault) || menu.items.find((item) => item.route.path && item.route.path !== routesPath[Routes.CRM]);
  return firstItem?.route?.path || routesPath[Routes.UnderConstruct];
};
