import { NO_CUSTOMERS } from 'components/customers/customers-details-empty/texts';
import { useIcons } from '@/hooks/use-icons';
import './customers-details-empty.scss';

/*
 * Сайдбар, когда не выбран клиент в роли логиста
 * */
export const CustomersDetailsEmpty = () => {
  const { ClaimIcon64 } = useIcons();
  return (
    <div className="customers-details-empty">
      <div>
        <ClaimIcon64 />
        {NO_CUSTOMERS}
      </div>
    </div>
  );
};
