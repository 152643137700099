import { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { VerificationFormMode } from 'components/verification-forms/types';
import { DocumentTypes } from 'config/constants/document-types';
import { AttachmentDeps } from 'config/constants/types';
import { UiFileUploadField, UiFileUploadInput } from '@/ui-elements';
import { useDependentAttachments } from '@/features/attachments/hooks/use-dependent-attachments';

export const VehicleRentDocSubform: FC<{ mode: VerificationFormMode; vehicleId?: number }> = ({ mode, vehicleId }) => {
  const {
    attachments: attachmentsContract,
    deleteAttachmentById: deleteAttachmentContractById,
    postAttachments: postAttachmentsContract,
    downloadAttachments: downloadAttachmentsContract,
  } = useDependentAttachments({
    dependent: AttachmentDeps.Vehicle,
    dependentId: vehicleId,
    type: DocumentTypes.dlDocumentVehicleContract,
  });

  return (
    <Box mt={5}>
      <Text mt={3} mb={2}>
        Загрузите скан договора
      </Text>
      {mode === 'create' ? (
        <UiFileUploadField name="rentDoc" />
      ) : (
        <UiFileUploadInput
          savedFileList={attachmentsContract}
          name="rentDoc"
          onDeleteFileClick={deleteAttachmentContractById}
          onIconClick={downloadAttachmentsContract}
          onImmediateUploadFile={postAttachmentsContract}
        />
      )}
    </Box>
  );
};
