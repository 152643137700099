import { Box, Text, Flex } from '@chakra-ui/react';
import { DriverRiskGroup } from 'components/driver-risk-group/driver-risk-group';
import { ApproveStatus } from 'config/constants/statuses';
import { UiButtonFilled, UiDivider } from '@/ui-elements';
import { Approval } from '@/types/models/approval';
import { ApprovalHistory } from '@/components/approval-history/approval-history';

type Props = {
  setChecking: (arg0: boolean) => void;
  approval?: Approval;
};

export const DriverDetails = ({ setChecking, approval }: Props) => {
  if (!approval) return null;
  const { driver } = approval;
  if (!driver) return null;
  return (
    <Box>
      <Flex>
        <Box mr={3}>
          <DriverRiskGroup riskGroup={driver.riskGroup} />
        </Box>
        <Text fontWeight={700}>{driver.name}</Text>
      </Flex>
      <Text className="carriers-verification-info__phone">{driver.phoneNumber}</Text>
      <ApprovalHistory approval={approval} />
      {approval.status === ApproveStatus.Waiting && (
        <Box mt={4}>
          <UiButtonFilled mode="dark" onClick={() => setChecking(true)} width={172}>
            Начать проверку
          </UiButtonFilled>
        </Box>
      )}
      <UiDivider variant="dark" mt={6} mb={6} />
    </Box>
  );
};
