import { ClientContractTemplateType, TemplateData } from '@/api/documents/templates-types';

declare const Stimulsoft: any;

export const generateReportFromJson = (templateData: TemplateData | ClientContractTemplateType) => {
  Stimulsoft.Base.StiLicense.loadFromFile('/assets/scripts/stimulsoft/license.key');
  Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile('/assets/scripts/stimulsoft/ru.xml');

  const reportInst = new Stimulsoft.Report.StiReport();
  reportInst.load(templateData.template);
  reportInst.dictionary.databases.clear();

  const dataSet = new Stimulsoft.System.Data.DataSet('dataNew');
  dataSet.readJson(JSON.stringify(templateData.data));
  reportInst.regData(dataSet.dataSetName, '', dataSet);

  return reportInst;
};
