import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { ResourcesContentTab, ResourcesSidebarMode } from '@/features/resources/types/general';
import { ResourcesContentTabNames } from '@/features/resources/constants/general';
import { useResourcesDispatchActions } from '@/features/resources/hooks';
import { useOrganizations } from '@/features/my-organizations/hooks/use-all-organizations';
import { useAccount } from '@/features/account/hooks/use-account';

export const useResourcesView = () => {
  /* Проверяем, есть ли у пользователя организация */
  /* TODO: необходимо проверять, прошла ли организация проверку в СБ */

  const { isCarrierSimple } = useAccount();

  const {
    allOrganizations: { data: allOrganizationsResp },
  } = useOrganizations();
  const currentOrganizations = allOrganizationsResp?.data;
  const disabled = isEmpty(currentOrganizations);

  /* Переключатель табов: Водители-ТС */
  const { handleSetContentTab, handleSetSidebarMode, handleSelectVehicle, handleSelectDriver } = useResourcesDispatchActions();

  const handleSelectContentTab = (tabIndex: number) => {
    const contentTab = Object.keys(ResourcesContentTabNames)[tabIndex];
    handleSetSidebarMode(ResourcesSidebarMode.None);
    handleSelectVehicle(null);
    handleSelectDriver(null);
    handleSetContentTab(contentTab as ResourcesContentTab);
  };

  useEffect(() => {
    handleSelectVehicle(null);
    handleSelectDriver(null);
    handleSetSidebarMode(ResourcesSidebarMode.None);
    handleSetContentTab(!isCarrierSimple ? ResourcesContentTab.Drivers : ResourcesContentTab.Vehicles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { disabled, isCarrierSimple, handleSelectContentTab };
};
