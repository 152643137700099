import { FC } from 'react';
import { HStack, VStack } from '@chakra-ui/react';
import { UiButtonOutlined } from '@/ui-elements';
import { useIcons } from '@/hooks/use-icons';
import { Load } from '@/types/models/loads';
import { OrderGeneralDistance } from '@/components/order-general-distance/order-general-distance';
import { OrderGeneralInfo } from '@/components/order-general-info/order-general-info';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { loadExtender } from '@/features/loads/extender/load-extender';
import { orderActions } from '@/features/order/texts';

interface Props {
  load: Load;
  deleteAllowed: boolean;
  handleUpdateLoad?: () => void;
  handleDeleteLoad?: () => void;
}

export const FunnelExecutionDetails: FC<Props> = ({ load, deleteAllowed, handleUpdateLoad, handleDeleteLoad }) => {
  const { distance, type, shippedTotals } = useExtendedObject(load, loadExtender);
  const { DeleteIcon, EditIcon } = useIcons();

  return (
    <HStack justifyContent="space-between">
      <VStack spacing={6} alignItems="flex-start">
        <OrderGeneralDistance range={distance} type={type} />
        <OrderGeneralInfo vehicleTypes={load?.vehicleTypes} shippedTotals={shippedTotals} loadingType={load?.loadingType || []} />
      </VStack>
      <VStack>
        <UiButtonOutlined onClick={handleDeleteLoad} mode="dark" leftIcon={DeleteIcon} disabled={!deleteAllowed}>
          {orderActions.Delete}
        </UiButtonOutlined>
        {/* TODO: задача по редактированию в разработке */}
        <UiButtonOutlined onClick={handleUpdateLoad} mode="dark" leftIcon={EditIcon} disabled>
          {orderActions.Edit}
        </UiButtonOutlined>
      </VStack>
    </HStack>
  );
};
