import { FC } from 'react';
import { Divider, HStack, Text, Center, Box, Heading } from '@chakra-ui/react';
import { CarrierCardRegNumber } from '../carrier-card/carrier-card-reg-number';
import { VehicleGeneralInfo } from '@/components/vehicle-general-info/vehicle-general-info';
import './vehicle-tab.scss';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { getVehicleRegNumber, getVehicleRegion } from '@/features/vehicles/extender/vehicle-extender';
import { UiDivider } from '@/ui-elements';
import { Carrier } from '@/types/models/carrier';
import { Vehicle } from '@/types/models/vehicle';

export const VehicleTab: FC<{ carrier: Carrier; vehicles?: Array<Vehicle> }> = ({ carrier, vehicles }) => {
  return (
    <UiScrollbars style={{ width: '100%', height: '100vh' }}>
      <div className="carrier-widget__vehicle">
        <Heading>Проверен в филиале</Heading>
        <Text>{carrier.department.name}</Text>
        {/* TODO: уточнить, нужно ди на бэке поле Комментарии сотрудника СБ */}
        {/* <Heading>Комментарии сотрудника СБ</Heading>
        <Text>{driver.securityComment || 'Нет'}</Text> */}
        {vehicles?.map((vehicle) => (
          <>
            <Box>
              <Heading>ТС</Heading>
              <Box key={vehicle.id}>
                <HStack>
                  {vehicle && <CarrierCardRegNumber regNumber={getVehicleRegNumber(vehicle)} region={getVehicleRegion(vehicle)} />}
                  <Center className="carrier-widget__vehicle-divider-container">
                    <Divider className="carrier-widget__vehicle-divider" orientation="vertical" />
                  </Center>
                  {vehicle.vehicleType && (
                    <VehicleGeneralInfo
                      vehicleTypes={[vehicle.vehicleType]}
                      capacity={vehicle.capacity}
                      volume={vehicle.volume}
                      loadingType={vehicle.loadingType}
                    />
                  )}
                </HStack>
                {vehicle.documents?.length && (
                  <HStack className="carrier-widget__vehicle-documents">
                    {vehicle.documents?.map((document) => (
                      <img key={document.id} src={`${document.link}&bin=1`} alt="document" />
                    ))}
                  </HStack>
                )}
                <UiDivider pt="2px" variant="dark" mt={3} mb={3} />
              </Box>
            </Box>
            {!!vehicle.trailers?.length && (
              <Box>
                <Heading>Прицеп</Heading>
                {vehicle.trailers?.map((trailer) => (
                  <>
                    <HStack>
                      {trailer && <CarrierCardRegNumber regNumber={getVehicleRegNumber(trailer)} region={getVehicleRegion(trailer)} />}
                      <Center className="carrier-widget__vehicle-divider-container">
                        <Divider className="carrier-widget__vehicle-divider" orientation="vertical" />
                      </Center>
                      {trailer && trailer?.vehicleType && <VehicleGeneralInfo vehicleTypes={[trailer.vehicleType]} volume={trailer?.volume} />}
                    </HStack>
                    {trailer?.documents?.length && (
                      <HStack className="carrier-widget__vehicle-documents">
                        {trailer?.documents?.map((document) => (
                          <img key={document.id} src={`${document.link}&bin=1`} alt="document" />
                        ))}
                      </HStack>
                    )}
                    <UiDivider pt="2px" variant="dark" mt={3} mb={3} />
                  </>
                ))}
              </Box>
            )}
          </>
        ))}
      </div>
    </UiScrollbars>
  );
};
