import { useMutation } from '@tanstack/react-query';
import { DriversApi } from '@/api/drivers';
import { DriverVerificationRequest } from '@/types/models/driver-verification';
import { FileFormField } from '@/types/models/my-organization/form';
import { AttachmentDeps } from '@/config/constants/types';

type UseMyOrganizationDriverActionsProps = {
  driverID?: number;
  sendFiles(data: { files: Record<FileFormField, File[]>; dependentID: number; dependent: AttachmentDeps }): void;
  carrierId?: number;
};

type SubmitDriverProps = {
  driverRequestData: DriverVerificationRequest;
  files: Record<FileFormField, File[]>;
};

/* submitDriver: отправляет запрос /drivers на создание, либо обновление после запроса организации, если текущий аккаунт - carrier_simple */

export const useMyOrganizationDriverActions = ({ driverID, sendFiles, carrierId }: UseMyOrganizationDriverActionsProps) => {
  const { mutate: createDriver } = useMutation({
    mutationKey: ['postDriverData'],
    mutationFn: (data: DriverVerificationRequest) => DriversApi.addDriver(data),
  });

  const { mutate: updateDriver } = useMutation({
    mutationKey: ['updateDriverData'],
    mutationFn: (data: DriverVerificationRequest) => DriversApi.updateDriver(driverID!, false, data),
  });

  const submitDriver = ({ driverRequestData, files }: SubmitDriverProps) => {
    if (driverID) {
      updateDriver(
        { ...driverRequestData, carrierId },
        {
          onSuccess: ({ data: driverResponseData }) => sendFiles({ files, dependentID: driverResponseData?.id, dependent: AttachmentDeps.Driver }),
        }
      );
    } else {
      createDriver(
        { ...driverRequestData, carrierId },
        {
          onSuccess: ({ data: driverResponseData }) => sendFiles({ files, dependentID: driverResponseData?.id, dependent: AttachmentDeps.Driver }),
        }
      );
    }
  };

  return { submitDriver };
};
