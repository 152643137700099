import { FC } from 'react';
import { Driver } from '@/types/models/driver';
import './drivers-list.scss';
import { emptyFunction } from '@/helpers/empty-function';
import { DriverComponent } from './driver';

type Props = {
  drivers?: Array<Driver>;
  onDriverMoreInfoClick?: (driverId: number) => void;
};

export const DriversList: FC<Props> = ({ drivers, onDriverMoreInfoClick = emptyFunction }) => {
  return (
    <div className="drivers-list">
      {drivers?.map((driver) => (
        <DriverComponent driver={driver} onDriverMoreInfoClick={onDriverMoreInfoClick} key={driver.id} />
      ))}
    </div>
  );
};
