import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@/config/constants/query-keys';
import { useVehicle } from '@/features/vehicles/hooks/use-vehicle';
import { useAppSelector } from '@/hooks/use-app-selector';
import { ModalType } from '@/types/common/modals';
import { ModalAction, useModal } from '@/hooks/use-modal';
import { resourcesSelectedVehicleIdSelector } from '@/features/resources/selectors';
import { useEditResource, useResourcesDispatchActions } from '@/features/resources/hooks';
import { ResourcesContentTab, ResourcesSidebarMode } from '@/features/resources/types/general';

export const useVehicleDetailsView = () => {
  const client = useQueryClient();
  const selectedVehicleId = useAppSelector(resourcesSelectedVehicleIdSelector);
  const { handleSetSidebarMode } = useResourcesDispatchActions();

  const { vehicle: vehicleQuery } = useVehicle(selectedVehicleId!);
  const { data: response, isInitialLoading } = vehicleQuery;
  const vehicle = response?.data;

  const onSuccessDelete = () => {
    toast.success('Транспортное средство успешно удалено');
    handleSetSidebarMode(ResourcesSidebarMode.None);
    client.invalidateQueries([QueryKeys.allVehicles]);
  };

  const { modalView: ModalDeleteVehicle, setModalOpened: showDeleteVehicle } = useModal<
    { text: string; vehicleID?: number; modalWidth: number } & ModalAction
  >(ModalType.DeleteVehicle, {
    text: 'ТС будет удалено',
    vehicleID: selectedVehicleId!,
    onSuccess: onSuccessDelete,
    modalWidth: 320,
  });

  const { handleEditResource, ModalEditNotify } = useEditResource({
    approvalStatuses: vehicle?.approvalStatuses,
    activeTab: ResourcesContentTab.Vehicles,
  });

  const handleDeleteVehicle = () => {
    showDeleteVehicle(true);
  };

  return { vehicle, loading: isInitialLoading, ModalDeleteVehicle, ModalEditNotify, handleEditVehicle: handleEditResource, handleDeleteVehicle };
};
