import { FC, useState } from 'react';
import { SecurityTableComponent } from 'components/security/security-table-component/security-table-component';
import { ApproveStatus } from 'config/constants/statuses';
import { useSecurityActions } from '@/features/security/hooks/use-security-actions';
import { ApprovalsFilter } from '@/types/api/approvals';
import { useDebounce } from '@/hooks/use-debounce';
import { useInfiniteApprovalsUpdates } from '@/features/approvals/hooks/use-approvals-infinite-updates';

type Props = {
  filter?: ApprovalsFilter;
};

export const SecurityTable: FC<Props> = ({ filter }) => {
  const [activeTab, setActiveTab] = useState(ApproveStatus.Waiting);
  const [searchValue, setSearchValue] = useState('');
  const debouncedQuery = useDebounce(searchValue);
  const { data: approvals, fetchNextPage, isFetching } = useInfiniteApprovalsUpdates({ ...filter, search: debouncedQuery, statuses: activeTab });

  const allRecords = approvals?.pages ? approvals.pages.flatMap((d) => d.data) : [];

  const { activeApprovalId, selectApproval } = useSecurityActions({ approvals: allRecords, selectFirstApproval: true });

  return (
    <SecurityTableComponent
      approvals={allRecords || []}
      loading={isFetching}
      loadMore={fetchNextPage}
      selectedApprovalId={activeApprovalId}
      onClickApproval={selectApproval as any}
      onTabChange={(value: string) => setActiveTab(value as ApproveStatus)}
      onSearchChange={(inputValue) => setSearchValue(inputValue)}
      searchValue={searchValue}
    />
  );
};
