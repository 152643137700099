import { Box } from '@chakra-ui/react';
import { useFunnelDetails } from '@/features/funnel/hooks';
import { FunnelDetailsComponent } from '@/components/funnel/details-component/funnel-details-component';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';

export const FunnelDetailsView = () => {
  const {
    order,
    isLoadLoading,
    activeTab,
    buttonsDisabled,
    userHasIntegration,
    deleteAllowed,
    ModalDeleteLoad,
    handleClickBack,
    handleClickForward,
    handleUpdateLoad,
    handleDeleteLoad,
  } = useFunnelDetails();
  return (
    <Box>
      {!isLoadLoading ? (
        <FunnelDetailsComponent
          order={order}
          activeTab={activeTab}
          userHasIntegration={userHasIntegration}
          deleteAllowed={deleteAllowed}
          buttonsDisabled={buttonsDisabled}
          modalDeleteLoad={ModalDeleteLoad}
          handleClickBack={handleClickBack}
          handleClickForward={handleClickForward}
          handleUpdateLoad={handleUpdateLoad}
          handleDeleteLoad={handleDeleteLoad}
        />
      ) : (
        <UiSpinner visible mt={28} loadingMessage="Загрузка..." />
      )}
    </Box>
  );
};
