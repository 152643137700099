import { FC } from 'react';
import { HStack } from '@chakra-ui/react';
import { OrderPoint } from '@/components';
import { useIcons } from '@/hooks/use-icons';
import './order-card-route.scss';
import { Point } from '@/types/models/point';
import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';
import { useAppSelector } from '@/hooks/use-app-selector';
import { cargoTypesSelector } from '@/features/cargo/selectors/cargo-types-selector';

type Props = {
  points: Array<Point>;
  order: Order | Load;
};

export const OrderCardRoute: FC<Props> = ({ points, order }) => {
  const { RightArrow } = useIcons();
  const cargoTypes = useAppSelector(cargoTypesSelector);

  return (
    <HStack className="order-card-route">
      {points.map((point, index) => (
        <HStack key={point.id} className="order-card-route__point">
          <OrderPoint point={point} order={order} isLastPoint={index === points.length - 1} cargoTypes={cargoTypes} />
          {index !== points.length - 1 && <RightArrow />}
        </HStack>
      ))}
    </HStack>
  );
};
