import { useQuery } from '@tanstack/react-query';
import { OrdersApi } from '@/api/orders';

export const useCargoTypes = () => {
  const cargoTypes = useQuery({
    queryKey: ['cargoTypes'],
    refetchOnWindowFocus: false,
    queryFn: () => OrdersApi.getCargoTypes(),
  });
  const data = cargoTypes?.data?.data;
  return data
    ? Object.keys(data).map((key) => {
        return {
          value: key,
          label: data[+key],
        };
      })
    : [];
};
