import { WidgetContainer } from 'components/widget-container/widget-container';
import { FC } from 'react';
import { WidgetContainers } from '@/features/widget/widget-containers';
import { useCarrierLoadDetails } from './use-carriers-loads-details';
import { CarrierLoadDetailsComponent } from '@/components/carriers-loads/details/details-component/load-details-component';

export const CarrierLoadDetailsView: FC<{ isFuel?: boolean }> = () => {
  const { ref, order, ModalSplit, ReviewModal } = useCarrierLoadDetails();

  return (
    <div>
      <CarrierLoadDetailsComponent load={order} />
      <WidgetContainer containerKey={WidgetContainers.OrderDetails} parentRef={ref} />
      {ModalSplit}
      {ReviewModal}
    </div>
  );
};
