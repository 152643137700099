import {
  ADD_SERVICE,
  ADDITIONAL_SERVICES,
  BY_AGREEMENT,
  BY_CARRIER_AGREEMENT,
  TRANSPORTATION_SERVICE,
} from 'components/order-details/order-details-service/texts';
import { FC } from 'react';
import { HStack, VStack } from '@chakra-ui/react';
import { COLON, PRICE } from 'config/constants/common-strings';
import { OrderRouteCompact } from 'components/order-route-compact/order-route-compact';
import { OrderDetailsStatus } from 'components/order-details/order-details-status/order-details-status';
import { OrderDetailsAdditionalService } from 'components/order-details';
import { CARRIER_NOT_SET } from 'components/order-details/order-details-component/texts';
import { Order } from '@/types/models/order';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { orderExtender } from '@/features/order/extender/order-extender';
import './order-details-service.scss';
import { UiLink } from '@/ui-elements/ui-link/ui-link';
import { OrderGeneralDistance, OrderGeneralInfo } from '@/components';
import { UiButtonFilled } from '@/ui-elements';
import { useIcons } from '@/hooks/use-icons';
import { emptyFunction } from '@/helpers/empty-function';
import { OrderService } from '@/types/models/order-service';
import { OrderMode } from '@/types/common/mode';
import { Load } from '@/types/models/loads';

type Props = {
  order: Order | Load;
  orderMode: OrderMode;
  services?: Array<OrderService>;
  onServiceDocumentDeleteClick?: (documentId: number) => void;
  companyName?: string;
  carrierName?: string;
  onAddServiceClick?: VoidFunction;
  fuelReadOnly?: boolean;
};
/*
 *Вкладка "Услуги" для разделов Заказ Клиента / Заказ Исполнителя
 *
 * */
export const OrderDetailsService: FC<Props> = ({
  companyName,
  carrierName,
  order,
  orderMode,
  onAddServiceClick,
  onServiceDocumentDeleteClick = emptyFunction,
  services,
  fuelReadOnly = false,
}) => {
  const { getFormattedPrice } = useExtendedObject(order, orderExtender);
  const { PlusIcon } = useIcons();
  return (
    <div className="order-details-service">
      <HStack className="order-details-service__body">
        <VStack className="order-details-service__info">
          <span className="order-details-service__header">{TRANSPORTATION_SERVICE}</span>
          <div>
            <span className="order-details-service__customer">{companyName || CARRIER_NOT_SET.toUpperCase()}</span>
            <span className="order-details-service__title">{order.id}</span>
          </div>
          <div>
            {orderMode === 'customer' ? (
              <>
                <span className="order-details-service__label">
                  {BY_AGREEMENT}
                  {COLON}
                </span>
                <UiLink href={`/clients?id=${order.customer.id}`} className="order-details-service__agreement" isActive={false}>
                  {order.contract?.number}
                </UiLink>
              </>
            ) : (
              <span className="order-details-service__label">{BY_CARRIER_AGREEMENT}</span>
            )}
          </div>
          <div>
            <span className="order-details-service__label">
              {PRICE}
              {COLON}
            </span>
            <span className="order-details-service__value">{getFormattedPrice(orderMode)}</span>
          </div>
        </VStack>
        <OrderDetailsStatus order={order} />
      </HStack>
      <OrderRouteCompact points={order.points} route={order} />
      <HStack className="order-details-service__general-info">
        <OrderGeneralDistance range={order.distance} type={order.type} />
        <OrderGeneralInfo loadingType={order.loadingType || []} vehicleTypes={order.vehicleTypes} shippedTotals={order.shippedTotals} />
      </HStack>
      <div className="order-details-service__header">{ADDITIONAL_SERVICES}</div>
      {services?.map((service) => {
        return (
          <OrderDetailsAdditionalService
            order={order}
            carrierName={carrierName}
            orderMode={orderMode}
            key={service.id}
            className="order-details-service__item"
            service={service}
            companyName={companyName}
            onServiceDocumentDeleteClick={onServiceDocumentDeleteClick}
          />
        );
      })}
      <div className="order-details-service__add-button">
        <UiButtonFilled disabled={fuelReadOnly} leftIcon={PlusIcon} onClick={onAddServiceClick}>
          {ADD_SERVICE}
        </UiButtonFilled>
      </div>
    </div>
  );
};
