import './loads-card.scss';
import { FC } from 'react';
import classNames from 'classnames';
import { Box, Center, Divider, HStack, VStack } from '@chakra-ui/react';
import { Load } from '@/types/models/loads';
import { FunnelSwitcherStatus } from '@/types/models/funnel';
import { toPriceFormat } from '@/helpers/price/to-price-format';
import { PaymentChip } from '@/ui-elements/payment-chip/payment-chip';
import { OrderCardRoute } from '@/components/order-card-route/order-card-route';
import { OrderGeneralDistance } from '@/components/order-general-distance/order-general-distance';
import { OrderGeneralInfo } from '@/components/order-general-info/order-general-info';
import { LoadsTariffTypeTag } from '@/components/loads/tariff-type-tag/loads-tariff-type-tag';
import { LoadTakenForCalculation } from '@/components/loads/taken-for-calculation/load-taken-for-calculation';
import { LoadStatusTag } from '@/components/loads/status-tag/load-status-tag';
import { LoadCardPaymentDetails } from '@/components/loads/card-payment-details/load-card-payment-details';
import { LoadTitles } from '@/components/loads/titles/load-titles';

interface Props {
  load?: Load;
  currentLoadID: number | undefined;
  /* Поле isFunnelCard - указывает, что карточка используется на вкладке "Согласование" */
  isFunnelCard?: boolean;
  activeTab?: FunnelSwitcherStatus;
  handleSelectLoad: (id?: number) => void;
}

export const LoadsCard: FC<Props> = ({ load, currentLoadID, isFunnelCard, activeTab, handleSelectLoad }) => {
  if (!load) return null;

  const isSelected = currentLoadID === load.id;
  const isLoadInCalculation = Boolean(load.status);

  const onClickSelectLoad = () => {
    if (!isSelected) {
      handleSelectLoad(load.id);
    }
  };

  return (
    <Box className={classNames('loads-card', { 'loads-card_selected': isSelected })} onClick={onClickSelectLoad}>
      <HStack className="loads-card__header">
        <LoadTitles name={load.customer?.name} id={load.id} freightExchangePlatform={load.freightExchangePlatform} />
        <VStack>
          <HStack alignItems="flex-start">
            {/* У собственных заявок нет originalPrice, для них не показываем originalPrice */}
            {load?.originalPrice && <PaymentChip type="priceWithVAT" amount={toPriceFormat(load?.originalPrice?.priceWithVAT, true)} />}
            <LoadsTariffTypeTag tariff={load.tariffType} />
            {/* Статус груза показываем только на вкладке Согласование */}
            {isFunnelCard && load?.status && (
              <LoadStatusTag
                status={load?.status}
                logisticsStatus={activeTab === FunnelSwitcherStatus.CostConfirmed ? load?.logisticsStatus : undefined}
              />
            )}
          </HStack>
          {/* Статус "Взято на расчет" показываем только на вкладке Поиск грузов */}
          {!isFunnelCard && <LoadTakenForCalculation isLoadInCalculation={isLoadInCalculation} className="loads-card__calculation" />}
        </VStack>
      </HStack>
      <OrderCardRoute points={load.points} order={load} />
      <HStack className="loads-card__information">
        <HStack className="loads-card__information-general">
          <OrderGeneralDistance type={load.type} range={load.distance || 0} />
          <Center className="loads-card__information-divider__container">
            <Divider className="loads-card__information-divider" orientation="vertical" />
          </Center>
          <OrderGeneralInfo shippedTotals={load.shippedTotals} vehicleTypes={load.vehicleTypes} loadingType={load.loadingType || []} order={load} />
          <Center className="loads-card__information-divider__container">
            <Divider className="loads-card__information-divider" orientation="vertical" />
          </Center>
        </HStack>
        {activeTab !== FunnelSwitcherStatus.NewCalculation && isFunnelCard && (
          <LoadCardPaymentDetails customerPayment={load?.customerPayment} carrierPayment={load?.carrierPayment} size="small" />
        )}
      </HStack>
    </Box>
  );
};
