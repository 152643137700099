import { useState, useCallback, useMemo } from 'react';
import { useDropzone, DropzoneProps, DropzoneInputProps } from 'react-dropzone';
import { Container, Text, HStack, VStack } from '@chakra-ui/react';
import { useIcons } from '@/hooks/use-icons';
import { UiFileUploadCard } from './ui-file-upload-card/ui-file-upload-card';
import './ui-file-upload.scss';
import { emptyFunction } from '@/helpers/empty-function';
import { saveFileToDisk } from '@/helpers/save-file-to-disk';
import { UiFileUploadCardWidget } from '@/ui-elements/inputs/ui-file-upload-input/ui-file-upload-card/ui-file-upload-card-widget';

type Props = DropzoneProps &
  Omit<DropzoneInputProps, 'onChange'> & {
    name?: string;
    // компонент
    component?: React.ReactNode;
    // список документов для контролируемого инпута загрузчика. Если не undefined - то компонент ожидает, что файлы приходят из вне
    savedFileList?: Array<{ id: number; name: string; extension: string }>;
    // если указан - то вызывается сразу после одобавления файла, вместо onChange
    onImmediateUploadFile?: (file: File[]) => void;
    //  для контролируемого инпута загрузчика. Если указан - то вызывается сразу после клика на иконке удаления
    onDeleteFileClick?: (documentId: number) => void;
    onIconClick?: (documentId: number) => void;
    onChange?: (files: File[]) => void;
    // скрыть  загрузку и удаление
    disableUpload?: boolean;
  };

/*
 * для управляемого загрузчика файлов нужно передать
 * */

export const UiFileUploadInputWidget = ({
  name,
  disabled,
  component,
  multiple,
  onChange,
  savedFileList,
  onDeleteFileClick,
  onIconClick,
  onImmediateUploadFile,
  disableUpload,
}: Props) => {
  const [fileList, setFileList] = useState([] as File[]);
  const { Paperclip } = useIcons();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (onImmediateUploadFile) {
        onImmediateUploadFile(acceptedFiles);
      } else {
        const newFiles = [...fileList, ...acceptedFiles];
        setFileList(newFiles);
        if (onChange) {
          onChange(newFiles);
        }
      }
    },
    [fileList, onChange, onImmediateUploadFile]
  );

  const removeFile = useCallback(
    (file: File) => {
      const newFiles = [...fileList];
      newFiles.splice(newFiles.indexOf(file), 1);
      setFileList(newFiles);
      if (onChange) {
        onChange(newFiles);
      }
    },
    [fileList, onChange]
  );
  const onDownloadFile = (file: File) => {
    saveFileToDisk(file);
  };

  const files = useMemo(() => {
    const savedFilesCards =
      savedFileList?.map((file, index) => {
        return (
          <UiFileUploadCard
            key={file.id}
            fileName={`${file.name}_${index + 1}.${file.extension}`}
            onIconClick={onIconClick ? () => onIconClick(file.id) : emptyFunction}
            onDeleteClick={onDeleteFileClick ? () => onDeleteFileClick(file.id) : emptyFunction}
            hideDeleteButton={disableUpload}
          />
        );
      }) || [];

    const notSaveFilesCards = fileList.map((file, index) => (
      <UiFileUploadCardWidget
        fileName={file.name}
        key={`${file.name}_${index + 1}`}
        onIconClick={() => onDownloadFile(file)}
        onDeleteClick={() => removeFile(file)}
      />
    ));
    return [...savedFilesCards, ...notSaveFilesCards];
  }, [fileList, onDeleteFileClick, removeFile, savedFileList, disableUpload, onIconClick]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    disabled,
    accept: {
      'image/*': ['.png', '.jpeg'],
      'text/*': ['.pdf', '.doc', '.docx', '.xls'],
    },
  });

  return (
    <VStack spacing={5} width="full" align="start">
      {!disableUpload && (
        <div {...getRootProps({ className: 'dropzone file-upload-dropzone' })}>
          <input {...getInputProps()} name={name} />
          {component || (
            <Container variant="dashed" centerContent w="full" width="full">
              <HStack spacing="10px">
                <Paperclip />
                <Text textStyle="p4">Загрузить</Text>
              </HStack>
              <Text mt="14px" textStyle="p5">
                Допустимые форматы: jpeg, png, pdf, doc, docx, xls
              </Text>
            </Container>
          )}
        </div>
      )}
      <HStack spacing={5} align="top" flexWrap="wrap">
        {files}
      </HStack>
    </VStack>
  );
};
