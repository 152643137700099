import { FC, useState } from 'react';
import './set-carrier-widget-content.scss';
import { SetCarrierOrderInfo } from 'components/set-carrier-widget-content/set-carrier-order-info';
import { SetCarrierDriverInfo } from 'components/set-carrier-widget-content/set-carrier-driver-info/set-carrier-driver-info';
import { SetCarrierForm } from 'components/set-carrier-widget-content/set-carrier-form';
import { SetCarrierButtons } from 'components/set-carrier-widget-content/set-carrier-buttons';
import { Order } from '@/types/models/order';
import { CarrierSimple } from '@/types/models/carrier';
import { Driver } from '@/types/models/driver';
import { emptyFunction } from '@/helpers/empty-function';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { Vehicle } from '@/types/models/vehicle';
import { Load } from '@/types/models/loads';

type Props = {
  order: Order | Load;
  carrier: CarrierSimple;
  driver: Driver;
  vehicle: Vehicle;
  onSet?: (files: Array<File>) => void;
  onCancel?: () => void;
};
export const SetCarrierWidgetContent: FC<Props> = ({ order, carrier, driver, vehicle, onSet = emptyFunction, onCancel = emptyFunction }) => {
  const [files, setFiles] = useState<Array<File>>([]);

  const onClickSet = () => {
    onSet(files);
  };
  const onSetFiles = (newFiles?: Array<File>) => {
    setFiles(newFiles || []);
  };
  return (
    <div className="set-carrier">
      <div className="set-carrier__body">
        <UiScrollbars autoHide>
          <div className="set-carrier__main">
            <SetCarrierOrderInfo order={order} />
            <SetCarrierDriverInfo carrier={carrier} driver={driver} vehicle={vehicle} shippedTotals={order.shippedTotals} />
            <SetCarrierForm onChange={onSetFiles} />
          </div>
        </UiScrollbars>
        <SetCarrierButtons onClickSet={onClickSet} onClickCancel={onCancel} />
      </div>
    </div>
  );
};
