import { useQuery } from '@tanstack/react-query';
import { DriversApi } from '@/api/drivers';
import { QueryKeys } from '@/config/constants/query-keys';

export const useDriver = (id?: number) => {
  const driver = useQuery({
    queryKey: [QueryKeys.driver, id],
    refetchOnWindowFocus: false,
    enabled: !!id,
    queryFn: () => DriversApi.getDriver(id!),
  });
  return { driver };
};
