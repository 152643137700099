import { Text } from '@chakra-ui/react';
import { UiTab } from '@/ui-elements';

type Props = {
  children: React.ReactNode;
};

export const CarrierInfoTab = ({ children }: Props) => (
  <UiTab p={0} mr="20px">
    <Text textStyle="p2">{children}</Text>
  </UiTab>
);
