import { isEmpty } from 'lodash';
import { EMPTY_STRING } from '@/config/constants/common-strings';
import { ResourcesDriverFormValues } from '@/features/resources/constants/form';
import { DriverVerificationRequest } from '@/types/models/driver-verification';
import { formatDateObjectToString } from '@/helpers/date/format-date-object-to-string';

export const driverFormValuesToData = (values: ResourcesDriverFormValues, carrierId?: number): DriverVerificationRequest => {
  return {
    carrierId,
    /* contacts */
    country: values.country,
    phoneNumber: values.phoneNumber || EMPTY_STRING,
    /* full name */
    name: values.name,
    surname: values.surname,
    patronymic: values.patronymic,
    /* passport */
    russianPassport: values.russianPassport,
    passport: values.passport,
    passportDate: formatDateObjectToString(values.passportDate),
    birthday: formatDateObjectToString(values.birthday),
    passportGiven: values.passportGiven,
    convictions: values.convictions,
    loans: values.loans,
    accidents: values.accidents,
    /* license */
    driverLicense: values.driverLicense,
    driverLicenseDate: formatDateObjectToString(values.driverLicenseDate),
    isDocumentsUploaded: !isEmpty(values?.passportDoc) || !isEmpty(values?.driversLicenseDoc) || !isEmpty(values?.laborContractDoc),
  };
};
