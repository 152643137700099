export type VehicleGroupType = 'default' | 'withTrailer' | 'special'; // фургон (дефолтный) - полуприцеп - специальные

export type VehicleOwnType = 0 | 1 | 2; // аренда - собственность - лизинг

export enum VehicleType {
  Jumbo = 'jumbo',
  TentSemi = 'tentSemi',
  FlatbedSemi = 'flatbedSemi',
  MegaTruck = 'megaTruck',
  ContainerTruck = 'containerTruck',
  Tractor = 'tractor',
  GoodsVan = 'goodsVan',
  MeatVan = 'meatVan',
  IsometricVan = 'isometricVan',
  TailLiftVan = 'tailLiftVan',
  FullMetalVan = 'fullMetalVan',
  CurtainVan = 'curtainVan',
  TentVan = 'tentVan',
  FlatbedVan = 'flatbedVan',
  Refrigerator = 'refrigerator',
  Trawl = 'trawl',
  LongTruck = 'longTruck',
  TankTruck = 'tankTruck',
  BitumenTruck = 'bitumenTruck',
  DumpTruck = 'dumpTruck',
  CraneTruck = 'craneTruck',
  TowerTruck = 'towerTruck',
  Manipulator = 'manipulator',
  Mixer = 'mixer',
  CementTruck = 'cementTruck',
  GasolineTanker = 'gasolineTanker',
  ChemicalTruck = 'chemicalTruck',
  GasTanker = 'gasTanker',
  GasTruck = 'gasTruck',
  Tanker = 'tanker',
  GrainTruck = 'grainTruck',
  LogTruck = 'logTruck',
  FlourTruck = 'flourTruck',
  FeedTruck = 'feedTruck',
  TimberTruck = 'timberTruck',
  BunkerTruck = 'bunkerTruck',
  MilkTruck = 'milkTruck',
  CarTransporter = 'carTransporter',
  ScrapTruck = 'scrapTruck',
  GlassTruck = 'glassTruck',
  PanelTruck = 'panelTruck',
  BulkTruck = 'bulkTruck',
  StickTruck = 'stickTruck',
  CattleTruck = 'cattleTruck',
  MultiLift = 'multiLift',
  Chaland = 'chaland',
  HorseTruck = 'horseTruck',
}
