import { FormType } from 'components/register/regster-form/constants';
import { toast } from 'react-toastify';
import { Dispatch, SetStateAction } from 'react';
import { UseMutationResult } from '@tanstack/react-query';
import { FormFieldName } from 'config/constants/form';
import { CodeCheck, CodeSend } from '@/types/api/code-partial';
import { RegisterRequest } from '@/types/api/credentials';
import { ResponseApi } from '@/types/response';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';
import { setFormTypeSessionStorage } from '@/views/register/register-form-view/helpers/set-form-type-session-storage';
import { setItemSessionStorage } from '@/helpers/set-item-session-storage';
import { AccountRole } from '@/types/api/account-role';

export type RegisterFormValues = Omit<RegisterRequest, 'accountRole'> & { accountRole: { value: AccountRole; label: string } };

export const handleSubmitRegisterForm =
  ({
    formType,
    setFormType,
    sendCode,
    checkCode,
    register,
  }: {
    formType: FormType;
    setFormType: Dispatch<SetStateAction<FormType>>;
    sendCode: UseMutationResult<ResponseApi, unknown, CodeSend>;
    checkCode: UseMutationResult<ResponseWithoutPagerSimple<{ password: string }>, unknown, CodeCheck>;
    register: (registerRequest: Required<RegisterRequest>) => void;
  }) =>
  (values: CodeSend | CodeCheck | RegisterFormValues) => {
    switch (formType) {
      case FormType.getCode:
        if (FormFieldName.target in values) {
          const { target } = values as CodeSend;
          sendCode.mutate(values, {
            onSuccess: () => {
              toast.success('Код отправлен');
              setFormTypeSessionStorage(FormType.checkCode);
              setItemSessionStorage('phone', target);
              setFormType(FormType.checkCode);
            },
          });
        }
        break;
      case FormType.checkCode:
        checkCode.mutate(values as CodeCheck, {
          onSuccess: (response) => {
            toast.success('Для завершения регистрации введите пароль');
            setFormTypeSessionStorage(FormType.register);
            setItemSessionStorage('password', response.data.password);
            setFormType(FormType.register);
          },
        });
        break;
      case FormType.register:
        if (FormFieldName.password in values) {
          const payload: RegisterRequest = {
            password: values.password,
            phone: values.phone,
            accountRole: values.accountRole.value,
          };
          register(payload);
        }
        break;
      default:
        break;
    }
  };
