import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@/config/constants/query-keys';
import { DriversApi } from '@/api/drivers';

/* Хук, который получает начальные значения данных Водителя, если роль аккаунта carrier_simple */

export const useMyOrganizationDriverData = ({ driverId }: { driverId: number }) => {
  const responseDriverData = useQuery({
    queryKey: [QueryKeys.driver, driverId],
    refetchOnWindowFocus: false,
    queryFn: () => DriversApi.getDriver(driverId),
    enabled: !!driverId,
  });
  const driver = responseDriverData?.data?.data;

  return { driver };
};
