import { OrderSwitcherStatus } from '@/config/constants/statuses';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { OrderPrice } from '@/types/models/order';

/* Фильтрация "Поиск грузов" */
export enum CarriersLoadsFiltersField {
  From = 'from',
  To = 'to',
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
  VehicleTypes = 'vehicleTypes',
  TariffType = 'tariffType',
  SingleLoading = 'singleLoading',
}

export interface CarriersLoadsFilterValues {
  status: OrderSwitcherStatus.CarrierSearch;
  [CarriersLoadsFiltersField.From]: string;
  [CarriersLoadsFiltersField.To]: string;
  [CarriersLoadsFiltersField.DateFrom]: string;
  [CarriersLoadsFiltersField.DateTo]: string;
  [CarriersLoadsFiltersField.VehicleTypes]: Array<any>;
  [CarriersLoadsFiltersField.SingleLoading]: boolean;
}

/* Форма назначения ТС на заявку */
export enum CarriersLoadsCreateOfferField {
  DriverId = 'driverId',
  VehicleId = 'vehicleId',
  Price = 'price',
}

export interface CarriersLoadsCreateOfferValues {
  [CarriersLoadsCreateOfferField.DriverId]: number | undefined;
  [CarriersLoadsCreateOfferField.VehicleId]: number | undefined;
  [CarriersLoadsCreateOfferField.Price]: OrderPrice;
}

export interface CarriersLoadsCreateOfferValuesForm {
  [CarriersLoadsCreateOfferField.DriverId]: SelectOption | undefined;
  [CarriersLoadsCreateOfferField.VehicleId]: SelectOption | undefined;
  [CarriersLoadsCreateOfferField.Price]: OrderPrice;
}
