import { Box, HStack } from '@chakra-ui/react';
import { UiNumberPickerField, UiSelectField } from '@/ui-elements';

type Props = {
  fieldsName: string;
};

export const cargoInfoInitialValues = {
  cargoCategory: '',
  cargoWeight: '',
  cargoVolume: '',
  // cargoSizes: '',
};

const cargoCategoryOptions = [
  {
    value: 1,
    label: 'Груз',
  },
];

export const CargoInfoFields = ({ fieldsName }: Props) => (
  <Box w="full" mt={6}>
    <HStack spacing={4} mt={4} mb="22px">
      <UiSelectField
        options={cargoCategoryOptions}
        value={1}
        isDisabled
        name={`${fieldsName}.cargoCategory`}
        label="Категория груза"
        placeholder="Выберите из списка"
      />
      <UiNumberPickerField name={`${fieldsName}.cargoWeight`} label="Вес в тоннах" placeholder="0" required />
    </HStack>
    <HStack spacing={4} mt={4} mb="22px">
      <UiNumberPickerField
        name={`${fieldsName}.cargoVolume`}
        label={
          <span>
            Объем м<sup>3</sup>
          </span>
        }
        required
      />
      {/* <UiNumberPickerField name={`${fieldsName}.cargoSizes`} label='Размеры груза' /> */}
    </HStack>
  </Box>
);
