import { FC } from 'react';
import './top-routes-menu.scss';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { UiLink } from '@/ui-elements/ui-link/ui-link';
import { NavigationMenu } from '@/types/routes/navigation-menu';
import { UiButton } from '@/ui-elements';

type Props = {
  menu: NavigationMenu['items'];
  onClose(): void;
  isDesktop?: boolean;
};

export const TopRoutesMenu: FC<Props> = ({ menu, onClose, isDesktop }) => {
  const location = useLocation();

  const isActiveRoute = (routePath: string) => location.pathname.split('/').includes(routePath);
  return (
    <div className={classNames('routes-menu', { desktop: isDesktop })}>
      {menu.map((item) => (
        <UiButton onClick={onClose}>
          <UiLink href={item.route.path} isExternal={false} key={item.route.path} isActive={isActiveRoute(item.route.path)}>
            {item.name}
          </UiLink>
        </UiButton>
      ))}
    </div>
  );
};
