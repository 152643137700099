import { FC, useState } from 'react';
import { Box, Center } from '@chakra-ui/react';
import { ModalView } from '@/views/order-modal/order-modal-view';
import { UiRatingStarsField } from '@/ui-elements/ui-rating-stars-field/ui-rating-stars-field';
import { UiTextareaField } from '@/ui-elements';
import { ModalsProps } from '@/types/common/modals';
import { UiToggleField } from '@/ui-elements/form-fields/ui-toggle-field/ui-toggle-field';
import { formatFormFields } from '@/helpers/forms/format-form-fields';
import { useOrderDataActions } from '@/features/order/hooks/use-order-data-actions';
import { OrderReview } from '@/types/api/order-partial';

export const ModalReview: FC<ModalsProps<{ orderId?: number; driverId?: number }>> = ({ modalOpened, onClose, hasBlacklist, orderId, driverId }) => {
  const { addReview } = useOrderDataActions(orderId!);
  const [blacklist, setBlacklist] = useState(false);

  const onSubmit = (prepValues: OrderReview) => {
    let values: OrderReview;
    if (blacklist) {
      values = formatFormFields(prepValues, 'omit', ['ratingValue']) as OrderReview; // убираем рейтинг если кидаем водилу в чс
    } else {
      values = formatFormFields(prepValues, 'omit', ['blacklist']) as OrderReview; // убираем поле ЧС если выставляем рейтинг
    }
    values.driverId = driverId!; // добавляем вручную связанный id водилы
    addReview.mutate(values);
    onClose();
    // todo добавить notification на успех/фейл. надо ли?
  };
  return (
    <ModalView
      isOpen={modalOpened}
      onClose={onClose}
      title="Оцените Водителя"
      onSubmit={(values: OrderReview) => onSubmit(values)}
      submitButtonText={blacklist ? 'В черный список' : 'Оценить'}
    >
      <Center justifyContent={hasBlacklist ? 'space-between' : 'center'} mr={hasBlacklist ? 2 : 0}>
        {hasBlacklist && (
          <Box mt={-2}>
            <UiToggleField name="blacklist" label="В черный список" onClick={() => setBlacklist((prev) => !prev)} />
          </Box>
        )}
        <UiRatingStarsField name="ratingValue" blacklist={blacklist} />
      </Center>
      <Box fontSize="sm" marginBottom={5} color={blacklist ? 'red.500' : 'black'}>
        {blacklist ? 'Вы отправляете водителя в черный список' : `Вы оцениваете исполнение заявки ${orderId || ''}`}
      </Box>
      <UiTextareaField name="review" label="Комментарий" placeholder={blacklist ? 'Укажите причину' : 'Введите текст'} counter />
    </ModalView>
  );
};
