import { FC } from 'react';
import { NOT_SET } from 'config/constants/common-strings';
import { pointExtender } from '@/features/order/extender/order-point-extender';
import { useExtendedObject } from '@/hooks/use-extended-object';
import './order-point.scss';
import { CARGO_TYPE } from '@/features/order/texts';
import { Point } from '@/types/models/point';
import { UiTooltip } from '@/ui-elements/tooltip/tooltip';
import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';
import { Loading } from '@/types/models/loading';

type Props = { point: Point; isLastPoint: boolean; order: Order | Load; cargoTypes: Array<{ value: string; label: string }> };
/**
 * Точка маршрута
 * */
export const OrderPoint: FC<Props> = ({ point, isLastPoint, order, cargoTypes }) => {
  const cargosWithType = order.cargos.map((cargo) => ({
    ...cargo,
    type: cargoTypes.find((cargoType) => Number(cargoType.value) === Number(cargo.type))?.label,
  }));
  const extendedPoint = useExtendedObject({ point, cargos: cargosWithType, action: order.actions } as Loading, pointExtender);
  return (
    <div className="order-point">
      <div className="order-point__description">{extendedPoint.pointDescription(isLastPoint)}</div>
      <UiTooltip label={extendedPoint.cargoType}>
        <div className="order-point__type">{CARGO_TYPE}</div>
      </UiTooltip>
      <div className="order-point__name">{extendedPoint.point.city || NOT_SET}</div>
      <div className="order-point__address">{extendedPoint.point.address}</div>
      <div className="order-point__arrival">{extendedPoint.arrivalString}</div>
    </div>
  );
};
