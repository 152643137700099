import { DocumentTypes } from 'config/constants/document-types';
import { AttachmentDeps } from 'config/constants/types';
import { FC } from 'react';
import { useOrderPayment } from '@/features/order/hooks/use-order-payment';
import { useOrderDetailsView } from '@/features/order/hooks/use-order-details-view';
import { useDependentAttachments } from '@/features/attachments/hooks/use-dependent-attachments';
import { OrderMode } from '@/types/common/mode';
import { useBookerOrdersCountInvalidate } from '@/features/booker/hooks/use-booker-orders-count-invalidate';
import { OrderDetailsPaymentCarrierView } from '@/views/order-details-payment-view/order-details-payment-carrier-view';
import { OrderDetailsPaymentCustomerView } from '@/views/order-details-payment-view/order-details-payment-customer-view';

export const OrderDetailsPaymentView: FC<{ orderMode: OrderMode; fuelReadOnly?: boolean }> = ({ orderMode, fuelReadOnly = false }) => {
  const { orderId, order, orderRefetch, isBooker, selectedCarrier } = useOrderDetailsView();

  const { setOrderPaid, setOrderServicePayment } = useOrderPayment(orderId!);
  const { invalidateBookerOrdersCount } = useBookerOrdersCountInvalidate();

  const { deleteAttachmentById, postAttachments, attachments, downloadAttachments } = useDependentAttachments({
    dependent: AttachmentDeps.Order,
    dependentId: orderId,
    type: DocumentTypes.dlDocumentOrderPayment,
  });

  const togglePayment = () => {
    setOrderPaid.mutate(
      {
        paid: orderMode === 'carrier' ? !order?.carrierPayment?.paid : !order?.customerPayment?.paid,
        orderMode,
      },
      {
        onSuccess: () => {
          orderRefetch();
          invalidateBookerOrdersCount();
        },
      }
    );
  };

  const toggleServicePayment = (id: number) => {
    const isServicePaid = !!order?.services?.filter((x) =>
      orderMode === 'carrier' ? x.carrierPayment?.paid : x.customerPayment?.paid && x.id === id
    ).length;
    const args =
      orderMode === 'carrier'
        ? {
            id,
            carrierPayment: { paid: !isServicePaid },
          }
        : {
            id,
            clientPayment: { paid: !isServicePaid },
          };
    setOrderServicePayment.mutate(
      { ...args },
      {
        onSuccess: () => orderRefetch(),
      }
    );
  };

  if (!orderId) return null;

  if (!order) return null;

  if (orderMode === 'carrier') {
    return (
      <OrderDetailsPaymentCarrierView
        order={order}
        selectedCarrier={selectedCarrier}
        onUploadPaymentDocuments={postAttachments}
        onDeletePaymentDocumentClick={deleteAttachmentById}
        onTogglePayment={togglePayment}
        onToggleServicePayment={toggleServicePayment}
        onSaveDocumentsClick={downloadAttachments}
        showPaymentsToggler={isBooker}
        attachments={attachments}
        fuelReadOnly={fuelReadOnly}
        orderRefetch={orderRefetch}
        invalidateBookerOrdersCount={invalidateBookerOrdersCount}
      />
    );
  }

  return (
    <OrderDetailsPaymentCustomerView
      order={order}
      onUploadPaymentDocuments={postAttachments}
      onDeletePaymentDocumentClick={deleteAttachmentById}
      onTogglePayment={togglePayment}
      onToggleServicePayment={toggleServicePayment}
      onSaveDocumentsClick={downloadAttachments}
      showPaymentsToggler={isBooker}
      attachments={attachments}
      fuelReadOnly={fuelReadOnly}
    />
  );
};
