import '../../../inputs/ui-checkbox-input/ui-checkbox-input.scss';
import { GroupBase, OptionProps, components } from 'chakra-react-select';
import { Checkbox, HStack } from '@chakra-ui/react';
import { UiText } from '@/ui-elements/ui-text/ui-text';
import { emptyFunction } from '@/helpers/empty-function';

export const UiVehicleCustomCheckbox = (props: OptionProps<unknown, boolean, GroupBase<unknown>>) => {
  const { isSelected, children } = props;

  return (
    <components.Option {...(props as any)}>
      <HStack ml={3}>
        <Checkbox isChecked={isSelected} className="ui-checkbox" onChange={emptyFunction} />
        <UiText textStyle="h4">{children}</UiText>
      </HStack>
    </components.Option>
  );
};
