import { HStack, Text, VStack } from '@chakra-ui/react';
import { Vehicle } from '@/types/models/vehicle';
import { ResourcesStatusesList } from '@/components/resources-statuses-list/resources-statuses-list';
import { CarrierCardRegNumber as RegNumber } from '@/components/carrier-card/carrier-card-reg-number';

export const OrderClientInfoVehicleDetails = ({ vehicle }: { vehicle?: Vehicle }) => {
  if (!vehicle) return null;

  return (
    <VStack alignItems="flex-start">
      <HStack>
        <Text className="order-details-client-offer-info__subtitle">Транспортное средство:</Text>
        <span className="order-details-client-offer-info__value">{vehicle?.name}</span>
        {vehicle?.regNumber && <RegNumber regNumber={vehicle?.regNumber} />}
      </HStack>
      <ResourcesStatusesList approvalStatuses={vehicle?.approvalStatuses} />
    </VStack>
  );
};
