import '../sort/loads-sort.scss';
import { FC } from 'react';
import classNames from 'classnames';
import { Field, FieldInputProps, useFormState } from 'react-final-form';
import { Box, HStack } from '@chakra-ui/react';
import { LoadsSortValues, LoadsSortButtonValues, LoadsSortField } from '@/types/models/loads';
import { initialSortingValues } from '@/config/constants/loads';
import { SortType } from '@/config/constants/types';
import { useIcons } from '@/hooks/use-icons';
import { UiText } from '@/ui-elements/ui-text/ui-text';

interface Props {
  sortOption: LoadsSortButtonValues;
}

export const LoadsSortButton: FC<Props> = ({ sortOption }) => {
  const { Sorting } = useIcons({ height: '12', width: '12' });
  const { values } = useFormState();
  const selectedSortValues = values.sorting;
  const isSelected = sortOption.value === selectedSortValues.value;
  const isDefault = sortOption.value === LoadsSortValues.Default;

  const onClickChangeSorting = (input: FieldInputProps<LoadsSortButtonValues, HTMLElement>) => {
    if (isDefault) {
      input.onChange(initialSortingValues);
      return;
    }
    const oppositeDirection: SortType = selectedSortValues.sortingDirection === SortType.Asc ? SortType.Desc : SortType.Asc;
    input.onChange({ value: sortOption.value, sortingDirection: isSelected ? oppositeDirection : SortType.Desc });
  };

  return (
    <Field name={LoadsSortField.Sorting} value={sortOption}>
      {({ input }) => (
        <HStack
          onClick={() => onClickChangeSorting(input)}
          spacing={1}
          className={classNames('loads-sort__button', { 'loads-sort__button_selected': isSelected })}
        >
          <UiText>{sortOption.title}</UiText>
          <Box
            className={classNames('loads-sort__icon-wrapper', {
              'loads-sort__icon-wrapper_visible': isSelected && !isDefault,
              asc: selectedSortValues.sortingDirection === SortType.Asc,
            })}
          >
            <Sorting />
          </Box>
        </HStack>
      )}
    </Field>
  );
};
