import { FC } from 'react';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { carrierToFormValues } from 'components/verification-forms/helpers/carrier-to-form-values';
import { CarrierFormData, FormMode } from 'components/verification-forms/types';
import { formValuesToCarrier } from 'components/verification-forms/helpers/form-values-to-carrier';
import { ModalsProps } from '@/types/common/modals';
import { useCarrier } from '@/features/carriers/hooks/use-carrier';
import { useCreateApproval } from '@/features/approvals/hooks/use-create-approval';
import { useAccount } from '@/features/account/hooks/use-account';
import { ModalCarrier } from '@/features/modals/carrier/modal-carrier';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';
import { useCarrierActions } from '@/features/carriers/hooks/use-carrier-actions';
import { useCarrierInvalidate } from '@/features/carriers/hooks/use-carrier-invalidate';
import { useGetMyOrganizationTypes } from '@/features/my-organization/use-get-organization-types';

export const ModalEditCarrier: FC<ModalsProps<{ carrierId?: number }>> = ({ onClose, carrierId, modalOpened }) => {
  const {
    carrier: { data: carrier },
    invalidateCarrier,
  } = useCarrier(carrierId);
  const { allCarriersInvalidate } = useCarrierInvalidate();
  const { createApproval } = useCreateApproval();
  const { updateCarrier } = useCarrierActions(carrierId!);
  const { currentAccount } = useAccount();
  const { organizationTypes } = useGetMyOrganizationTypes();
  const onUpdateCarrier = (values: CarrierFormData) => {
    const newValues = formValuesToCarrier(values);
    updateCarrier.mutate(newValues, {
      onSuccess: () => {
        createApproval.mutate(
          { comment: values.comment || EMPTY_STRING, carrierId, departmentId: currentAccount?.user?.department?.id },
          {
            onSuccess: () => {
              invalidateCarrier().then(() => {
                onClose();
              });
              allCarriersInvalidate();
            },
          }
        );
      },
    });
  };
  const initialValues = carrierToFormValues(carrier?.data, organizationTypes);
  if (!carrier) return <UiSpinner visible />;
  return (
    <ModalCarrier
      title={`Вы редактируете перевозчика ${carrier.data?.name}`}
      carrierId={carrierId}
      onSubmit={onUpdateCarrier}
      modalOpened={modalOpened}
      onClose={onClose}
      initialValues={initialValues}
      mode={FormMode.edit}
      submitting={updateCarrier.isLoading}
    />
  );
};
