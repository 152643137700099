import { FC } from 'react';
import { HStack } from '@chakra-ui/react';
import { ApproveStatus } from 'config/constants/statuses';
import { ApproveStatusNames } from 'config/constants/texts';
import { ApproveStatusColors } from 'config/constants/approve-status-colors';
import { UiStatus } from '@/ui-elements/status/status';

/**
 * ячейка таблицы со статусом запроса
 * */
export const SecurityTableStatusCell: FC<{ status?: ApproveStatus }> = ({ status }) => {
  if (!status) return null;
  return (
    <HStack justifyContent="flex-end" alignItems="center" width="100%">
      <UiStatus
        text={ApproveStatusNames[status]}
        backGroundColor={ApproveStatusColors[status].background}
        color={ApproveStatusColors[status].color}
      />
    </HStack>
  );
};
