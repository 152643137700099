import { EMPTY_STRING } from 'config/constants/common-strings';
import { regex, regexTrailer } from 'components/carrier-card/carrier-card-driver-info/constants';
import { ModelExtender } from '@/types/model-extender';
import { Trailer, Vehicle } from '@/types/models/vehicle';
import { VehicleExtended } from '@/features/vehicles/types/vehicle-extended';

export const getVehicleRegNumber = (vehicle?: Vehicle | Trailer) => {
  if (!vehicle) return '';
  const { regNumber } = vehicle;
  if (!regNumber) {
    return EMPTY_STRING;
  }
  const regNumberUpper = regNumber.toUpperCase();
  if (regNumberUpper.match(regex) || regNumberUpper.match(regexTrailer)) {
    return regNumberUpper.slice(0, 6);
  }
  return regNumberUpper;
};
export const getVehicleRegion = (vehicle: Vehicle | Trailer) => {
  if (!vehicle) return '';
  const { regNumber } = vehicle;
  if (!regNumber) {
    return EMPTY_STRING;
  }
  const regNumberUpper = regNumber.toUpperCase();
  if (regNumberUpper.match(regex) || regNumberUpper.match(regexTrailer)) {
    return regNumberUpper.slice(6, regNumberUpper.length);
  }
  return EMPTY_STRING;
};

export const vehicleExtender: ModelExtender<Vehicle, VehicleExtended> = {
  vehicleRegNumber: getVehicleRegNumber,
  vehicleRegion: getVehicleRegion,
};
