import { useLocation } from 'react-router-dom';
import { parse } from 'qs';
import { routesPath } from 'config/routes/routes-path';
import { Routes } from 'config/routes/routes';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { AccountRole } from '@/types/api/account-role';

export const useRegisterPaths = () => {
  const { search } = useLocation();

  const { alias } = parse(search, { ignoreQueryPrefix: true });

  const hrefToLogin = document.location.href.replace(routesPath[Routes.Register] + search, routesPath[Routes.LogIn]);
  const forceRedirectPath = '/register?alias=carrier';
  return { alias: (alias || EMPTY_STRING) as AccountRole, hrefToLogin, forceRedirectPath };
};
