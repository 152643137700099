import { FC } from 'react';
import { PageWrapper } from 'pages/page-wrapper';
import { Flex } from '@chakra-ui/react';
import { MyOrganizationsView } from '@/views/my-organizations/my-organizations-view';
import { MyOrganizationsSidebarView } from '@/views/my-organizations/my-organizations-sidebar-view';

export const MyOrganizationsPage: FC = () => (
  <PageWrapper>
    <Flex>
      <MyOrganizationsView />
      <MyOrganizationsSidebarView />
    </Flex>
  </PageWrapper>
);
