import { useCallback, useEffect, useState } from 'react';
import { AttachmentDeps } from 'config/constants/types';
import { DocumentTypes } from 'config/constants/document-types';
import { flatten } from 'lodash';
import { FormValues } from 'components/create-order-form/schema';
import { normalizeFormValues } from 'components/create-order-form/normalize-form-values';
import { initialValues } from 'components/create-order-form/initial-values';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { useActiveOrder } from '@/features/order/hooks/use-active-order';
import { useOrderActions } from '@/features/order/hooks/use-order-actions';
import { useOrderInvalidate } from '@/features/order/hooks/use-order-invalidate';
import { useOrderDataActions } from '@/features/order/hooks/use-order-data-actions';
import { useAccount } from '@/features/account/hooks/use-account';
import { getEmptyStr } from '@/helpers/get-empty-str';
import { useOrder } from '@/features/order/hooks/use-order';
import { useAttachmentsActions } from '@/features/attachments/hooks/use-attachments-actions';
import { emptyFunction } from '@/helpers/empty-function';
import { OrderNew } from '@/types/api/order-partial';
import { getCargoTypes } from '@/features/cargo/actions/get-cargo-types';

type DocToSend = { file: File; dependent: AttachmentDeps; dependentId: number; subject: DocumentTypes };

export const useOrderCreateView = () => {
  const dispatch = useAppDispatch();
  const { activeOrderId } = useActiveOrder();
  const { cancelCreate } = useOrderActions();
  const { allOrdersInvalidate } = useOrderInvalidate();
  const { addOrder } = useOrderDataActions(activeOrderId!);
  const { currentAccount } = useAccount();
  const { user } = currentAccount || {};
  const responsiblePerson = user ? { value: user.id, label: `${getEmptyStr(user.surname)}` } : { value: 0, label: '' };
  const [newOrderId, setNewOrderId] = useState<number | undefined>();
  const [newPointDocuments, setNewPointDocuments] = useState<Array<Array<File>>>([]);
  const [newOrderDocuments, setNewOrderDocuments] = useState<Array<File>>([]);
  const [docsSent, setDocsSent] = useState(false);
  const { order } = useOrder(newOrderId);
  const { postAttachments, postAttachment } = useAttachmentsActions();
  const { select } = useOrderActions();

  const sendOneDoc = useCallback(
    (doc: DocToSend, next?: VoidFunction) => {
      postAttachment.mutate(
        { dependent: doc.dependent, dependentID: doc.dependentId, subject: doc.subject, attachment: doc.file },
        { onSuccess: next, onError: next }
      );
    },
    [postAttachment]
  );

  const sendDocs = useCallback(
    (docs: Array<DocToSend>) => {
      if (!docs.length) cancelCreate();
      docs.forEach((doc, index) => {
        const next = docs.length - 1 === index ? cancelCreate : emptyFunction;
        sendOneDoc(doc, next);
      });
    },
    [cancelCreate, sendOneDoc]
  );

  useEffect(() => {
    if (order?.isSuccess && !docsSent && newOrderId) {
      setDocsSent(true);
      const orderDocs: Array<DocToSend> = newOrderDocuments.map((doc) => {
        return {
          dependent: AttachmentDeps.Order,
          subject: DocumentTypes.dlDocumentOrder,
          dependentId: newOrderId,
          file: doc,
        };
      });
      const pointsDocs: Array<DocToSend> = flatten(
        order.data.data.points.map((point, index) => {
          return newPointDocuments[index].map((doc) => {
            return {
              dependent: AttachmentDeps.Point,
              subject: DocumentTypes.dlDocumentPoint,
              dependentId: point.id,
              file: doc,
            };
          });
        })
      );
      sendDocs([...orderDocs, ...pointsDocs]);
    }
  }, [docsSent, newOrderDocuments, newOrderId, newPointDocuments, order, postAttachments, sendDocs]);

  const onAddOrder = useCallback(
    (orderNew: OrderNew, documents?: { orderDocuments?: Array<File>; pointDocuments?: Array<Array<File>> }, callback?: VoidFunction) => {
      setDocsSent(false);
      setNewOrderId(undefined);
      addOrder.mutate(orderNew, {
        onSuccess: (response) => {
          callback?.();
          setNewOrderId(response.data.id);
          if (documents?.pointDocuments) setNewPointDocuments(documents?.pointDocuments);
          if (documents?.orderDocuments) setNewOrderDocuments(documents?.orderDocuments);
          allOrdersInvalidate(() => {
            select(+response.data.id);
          });
          dispatch(getCargoTypes());
        },
      });
    },
    [addOrder, allOrdersInvalidate, select, dispatch]
  );

  const onSubmit = (values: FormValues) => {
    try {
      const normalizedValues = normalizeFormValues(values);
      const pointDocuments = (values?.route?.points || []).map((point) => point.documents || []) || [];
      onAddOrder?.(normalizedValues, {
        orderDocuments: values.documents,
        pointDocuments,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const initialOrderCreateFormValues = {
    ...initialValues,
    orderInfo: {
      responsiblePerson,
    },
  };

  const { isLoading } = addOrder;

  return {
    onSubmit,
    initialOrderCreateFormValues,
    isLoading,
    cancelCreate,
  };
};
