import { Switch, SwitchProps, FormControl, FormLabel, HStack, Text } from '@chakra-ui/react';
import helpIcon from 'public/assets/icons/HelpIcon.svg';
import { useField } from 'react-final-form';
import { UiIconButton } from '@/ui-elements';

// TODO поправить верстку

type Props = {
  label?: string;
  content?: string;
  required?: boolean;
  name: string;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (v: any) => void;
  withHelp?: boolean;
} & SwitchProps;

export const UiSwitchField = ({ label, withHelp = false, ...props }: Props) => {
  const { input } = useField(props.name, { type: 'checkbox' });
  return (
    <FormControl display="flex" alignItems="center">
      <Switch id={props.name} {...props} {...input} isChecked={input.checked} />
      <HStack>
        <FormLabel ml={3} mb={0}>
          <Text textStyle="p4">{label}</Text>
        </FormLabel>
        {withHelp && <UiIconButton icon={helpIcon} aria-label="help" ml="25px" />}
      </HStack>
    </FormControl>
  );
};
