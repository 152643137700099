import { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { FORM_UI_ELEMENTS, ORGANIZATION_MANAGER_FIELDS } from './constants';
import { FormSectionTitle } from './my-organization-form-header';

interface IProps {
  disabled: boolean;
}

export const MyOrganizationManager: FC<IProps> = ({ disabled }) => {
  return (
    <Box>
      <FormSectionTitle title="Генеральный директор" />
      <Flex className="my-organization-flex" direction="row" align="center" wrap="wrap">
        {ORGANIZATION_MANAGER_FIELDS.map((field) => {
          const { Component, defaultProps } = FORM_UI_ELEMENTS[field];
          return <Component disabled={disabled} {...defaultProps} />;
        })}
      </Flex>
    </Box>
  );
};
