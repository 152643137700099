import { useMutation } from '@tanstack/react-query';
import { OrderServicePatch } from '@/types/models/order-service';
import { OrderServicesApi } from '@/api/order-services';

export const useOrderServicesEdit = (id: number) => {
  const updateOrderService = useMutation({
    mutationKey: ['updateOrderService'],
    mutationFn: (options: OrderServicePatch) => OrderServicesApi.updateOrderService(id, options),
  });

  const deleteOrderService = useMutation({
    mutationKey: ['deleteOrderService'],
    mutationFn: () => OrderServicesApi.deleteOrderService(id),
  });
  return {
    deleteOrderService,
    updateOrderService,
  };
};
