import { FC } from 'react';
import { TableContainer, HStack } from '@chakra-ui/react';
import { UiTable } from '@/ui-elements';
import './analytics-component.scss';
import { TableConfig, TableDataSource } from '@/types/common/table';

type Props = {
  config: TableConfig;
  dataSource: TableDataSource;
};
export const TableComponent: FC<Props> = (props) => {
  return (
    <HStack w="full">
      <TableContainer height="100vh" flexGrow={1}>
        <UiTable {...props} className="analytics-component__table" />
      </TableContainer>
    </HStack>
  );
};
