import { FC } from 'react';
import { UiText } from '@/ui-elements/ui-text/ui-text';
import { OrderOffer } from '@/types/models/order-offer';
import { Order } from '@/types/models/order';
import { PaymentChip } from '@/ui-elements/payment-chip/payment-chip';
import { toPriceFormat } from '@/helpers/price/to-price-format';

interface IProps {
  price: OrderOffer['price'];
  carrierPayment: Order['carrierPayment'];
  accepted: OrderOffer['accepted'];
}

export const OfferPrice: FC<IProps> = ({ price, carrierPayment, accepted }) => {
  const hasOwnPriceOffer = carrierPayment.priceOffer;

  if (accepted) {
    return <UiText color="accentGreen.150" children="Оператор утвердил вашу цену" />;
  }

  return (
    <>
      <PaymentChip width="fit-content" label={`Ваша цена ${toPriceFormat(price)}`} type="price" />
      {hasOwnPriceOffer && (
        <UiText
          color="neutrals.150"
          children="Если вы отправляете отклик на заявку по своей цене,
          то мы не сможем зарезервировать за вами этот рейс. Нужно будет дождаться ответа
        диспетчера."
        />
      )}
    </>
  );
};
