import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/types/redux/root-state';
import { OrderSidebarMode } from '@/types/sidebar/order-sidebar-mode';

export const sidebarModeSelector = createSelector(
  (state: RootState) => state.carriersLoads,
  () => {
    return OrderSidebarMode.Details;
  }
);
