import { isEmpty } from 'lodash';
import { EMPTY_STRING } from '@/config/constants/common-strings';
import { MyOrganizationFormValues } from '@/types/models/my-organization/form';

export const getDriverFormValuesToData = (formValues: MyOrganizationFormValues & { phoneNumber?: string }) => {
  const driverRequestData = {
    /* contacts */
    country: formValues.country,
    phoneNumber: formValues?.phoneNumber?.replace(/\D/gi, EMPTY_STRING) || EMPTY_STRING,
    /* full name */
    name: formValues.directorName,
    surname: formValues.directorSurname,
    patronymic: formValues?.directorPatronymic || EMPTY_STRING,
    /* passport */
    russianPassport: formValues.russianPassport,
    passport: formValues.passportNumber,
    passportDate: formValues?.passportDate?.toString(),
    birthday: formValues?.birthday?.toString(),
    passportGiven: formValues.passportGiven,
    convictions: formValues.convictions,
    loans: formValues.loans,
    accidents: formValues.accidents,
    /* license */
    driverLicense: formValues.driverLicense,
    driverLicenseDate: formValues?.driverLicenseDate?.toString(),
    isDocumentsUploaded: !isEmpty(formValues?.passportDocument) || !isEmpty(formValues?.innOgrnDocument) || !isEmpty(formValues?.driversLicenseDoc),
  };
  return { driverRequestData };
};
