import { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { PageWrapper } from 'pages/page-wrapper';
import { AnalyticsView } from '@/views/analytics-view/analytics-view';

export const AnalyticsPage: FC = () => (
  <PageWrapper>
    <Flex>
      <AnalyticsView />
    </Flex>
  </PageWrapper>
);
