import { FC } from 'react';
import { WidgetType } from '@/features/widget/widget-type';
import { WidgetProps } from '@/features/widget/types/widget-item';
import './carrier-reviews-widget.scss';
import { CarrierReviewsWidget } from '@/components/carrier-reviews-widget/carrier-reviews-widget';
import { useCarrierUpdates } from '@/features/carriers/hooks/use-carrier-updates';

export const CarrierReviewsWidgetView: FC<WidgetProps & WidgetType['CarrierReviewsWidget']> = ({ carrierId }) => {
  const {
    carrier: { data: carrierResp },
  } = useCarrierUpdates(carrierId);
  const carrier = carrierResp?.data;
  return <div className="carrier-reviews-widget-view">{carrier && <CarrierReviewsWidget carrier={carrier} />}</div>;
};
