import classNames from 'classnames';
import { emptyFunction } from '@/helpers/empty-function';
import './period-picker.scss';

type Props = {
  position: 'left' | 'middle' | 'right';
  disabled?: boolean;
  children: React.ReactNode;
  value: string;
  onChange: (arg1: string) => void;
  period: 'all' | 'today' | 'weekly' | 'monthly';
};

export const PickerTab = ({ position, disabled = false, children, value, onChange, period = 'all' }: Props) => (
  <div
    className={classNames('period-picker__tab', `period-picker__tab_${position}`, { [`period-picker__tab_${position}_selected`]: value === period })}
    onClick={() => (disabled ? emptyFunction : onChange(period))}
    tabIndex={0}
    role="button"
    onKeyDown={() => (disabled ? emptyFunction : onChange(period))}
  >
    {children}
  </div>
);
