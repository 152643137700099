import { useCallback } from 'react';
import { UISidebar } from '@/ui-elements/ui-sidebar/ui-sidebar';
import { useReviewsSidebar } from '@/features/order/hooks/use-reviews-sidebar';
import { Reviews } from '@/views/reviews/reviews';
import { useOrderActions } from '@/features/order/hooks/use-order-actions';
import { useCarrierReviews } from '@/features/carriers/hooks/use-carrier-reviews';
import './reviews-sidebar.scss';
import { useCarrierUpdates } from '@/features/carriers/hooks/use-carrier-updates';
import { useCarrierVehiclesUpdates } from '@/features/carriers/hooks/use-carrier-vehicles-updates';

export const ReviewsSidebar = () => {
  const { openReviews } = useOrderActions();
  const { carrierIdForReviews } = useReviewsSidebar();
  const {
    carrierReviews: { data },
  } = useCarrierReviews(carrierIdForReviews);
  const {
    carrier: { data: carrierResp },
  } = useCarrierUpdates(carrierIdForReviews);
  const carrier = carrierResp?.data;
  const { carrierVehicles } = useCarrierVehiclesUpdates(carrierIdForReviews);

  const closeReviews = useCallback(() => {
    openReviews(undefined);
  }, [openReviews]);
  return (
    <UISidebar isOpen={!!carrierIdForReviews} className="comments-sidebar" isDrawer>
      <Reviews carrier={carrier} vehiclesAmount={carrierVehicles.data?.data.length} reviews={data?.data} onCloseClick={closeReviews} />
    </UISidebar>
  );
};
