import React, { forwardRef, ReactElement, ReactNode, RefObject, useRef } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import './ui-sidebar.scss';
import classNames from 'classnames';
import { Portal, Slide } from '@chakra-ui/react';

type Props = {
  isOpen: boolean;
  isFullscreen?: boolean;
  isDrawer?: boolean;
  hasOverlay?: boolean;
  hasShadow?: boolean;
  animation?: boolean;
  children: ReactElement | ReactNode;
  className?: string;
  fixedBlockRef?: RefObject<HTMLDivElement> | null;
  delay?: number | Partial<Record<'exit' | 'enter', number>> | undefined;
  blocksScroll?: boolean;
};

/**
 * Sidebar
 *
 *
 * isOpen  По умолчанию  true
 *
 * isDrawer  - если true - открывается как портал отдельно от основого конента, по умолчанию false.
 * Если isDrawer false - надо поместить во flex контейнер
 *
 * hasOverlay - затеменение. По умолчанию  false
 *
 * hasShadow - тень. По умолчанию  true
 *
 * isFullscreen - полный экран. По умолчанию  false
 *
 * blocksScroll - блокировать скролл в режиме isDrawer По умолчанию  false
 *
 * animation -  есть анимация или нет
 *
 * delay?: number | Partial<Record<"exit" | "enter", number>> | undefined
 *
 * fixedBlockRef - если указан ref на фиксированный эелемент, то ширина сайдбра высчитывается, чтобы занять все оставшщееся место на экране по ширине
 * */
export const UISidebar = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    children,
    isDrawer = false,
    hasOverlay = false,
    hasShadow = true,
    isOpen = true,
    isFullscreen = false,
    animation = true,
    className = '',
    fixedBlockRef,
    blocksScroll = false,
    delay,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const sizesClasses = {
    'ui-sidebar-fullscreen': isFullscreen,
    'ui-sidebar-fixed': !isDrawer,
  };

  const portalProps = !isDrawer ? { containerRef } : {};

  return (
    <>
      {!isDrawer && isOpen && <div className={classNames(className, 'ui-sidebar-placeholder', sizesClasses)} ref={containerRef} />}
      {isOpen && (
        <Portal {...portalProps}>
          {hasOverlay && isDrawer && !isFullscreen && <div className="ui-sidebar-overlay" />}
          <Slide
            motionProps={!animation ? { initial: false } : undefined}
            direction="right"
            in={isOpen}
            className={classNames(className, 'ui-sidebar ui-sidebar-portal', {
              ...sizesClasses,
              'ui-sidebar-shadow': hasShadow && !isFullscreen,
            })}
            style={{
              width: fixedBlockRef ? `calc(100vw - ${fixedBlockRef?.current?.clientWidth}px)` : undefined,
              maxWidth: fixedBlockRef ? `calc(100vw - ${fixedBlockRef.current?.clientWidth}px)` : undefined,
              top: undefined,
              left: undefined,
              right: undefined,
              bottom: undefined,
            }}
            delay={delay}
            ref={ref}
          >
            <RemoveScroll enabled={isDrawer && blocksScroll} noIsolation removeScrollBar>
              {}
            </RemoveScroll>

            {children}
          </Slide>
        </Portal>
      )}
    </>
  );
});
