import { FC, ReactElement } from 'react';
import {
  ADDITIONAL_SERVICE,
  CLIENT_CONTRACT,
  CLIENT_PAYMENT,
  NOT_PAYED,
  PRICE_FOR_MAIN_SERVICE,
  TOTAL_PAYED,
  TOTAL_TO_PAY,
} from 'components/order-details/order-details-payment/texts';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { OrderDetailsPayment } from 'components/order-details';
import { getCustomerTotal } from 'components/order-details/order-details-payment/helpers/getCustomerTotal';
import { VatType } from 'config/constants/types';
import { VatTypeMap } from 'config/constants/vat-options';
import { Order } from '@/types/models/order';
import { emptyFunction } from '@/helpers/empty-function';
import 'components/order-details/order-details-payment/order-details-payment.scss';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { orderExtender } from '@/features/order/extender/order-extender';
import { FileDocument } from '@/types/models/document';
import { toPriceFormat } from '@/helpers/price/to-price-format';
import { UiLink } from '@/ui-elements/ui-link/ui-link';
import { Load } from '@/types/models/loads';

type Props = {
  order: Order | Load;

  attachments?: Array<FileDocument>;

  onSaveDocumentsClick?: VoidFunction;
  // загрузить документ оплаты в заявку
  onUploadPaymentDocuments?: (files: Array<File>) => void;

  // удалить документ оплаты из заявки
  onDeletePaymentDocumentClick?: (documentId: number) => void;

  // обработчик переключателя оплаты
  onTogglePayment?: VoidFunction;

  // обработчик переключателя оплаты услуги
  onToggleServicePayment?: (id: number) => void;

  // показать переключатели оплаты
  showPaymentsToggler?: boolean;
  fuelReadOnly?: boolean;
};

type LabelType = {
  key?: string;
  label: string;
  value?: { className?: string; text: string | ReactElement };
  status?: { className?: string; text: string };
  hasToggler?: boolean;
  toggleValue?: boolean;
  onToggle?: VoidFunction;
};

export const getPaidStatusClassName = (paid?: boolean, partly?: boolean) => {
  if (partly) return 'orange-status';
  return paid ? 'green-status' : 'red-status';
};

export const OrderDetailsPaymentCustomerView: FC<Props> = ({
  order,
  fuelReadOnly = false,
  onSaveDocumentsClick = emptyFunction,
  onUploadPaymentDocuments = emptyFunction,
  onDeletePaymentDocumentClick = emptyFunction,
  onTogglePayment = emptyFunction,
  onToggleServicePayment = emptyFunction,
  showPaymentsToggler = false,
  attachments = [],
}) => {
  const { services, getFormattedPrice } = useExtendedObject(order, orderExtender);

  const paid = order.customerPayment?.paid || false;
  const mainPrice = toPriceFormat(order.customerPayment?.price) || EMPTY_STRING;
  const totalPrice = toPriceFormat(getCustomerTotal(order)) || EMPTY_STRING;
  const vatCaption = VatTypeMap[order.customerPayment?.vatType || VatType.WithoutVat];

  const serviceLabels: Array<LabelType> =
    services?.map((service) => {
      const servicePaid = service.customerPayment?.paid;
      return {
        label: ADDITIONAL_SERVICE,
        value: {
          text: getFormattedPrice('customer'),
          className: 'price',
        },
        status: { text: servicePaid ? TOTAL_PAYED : NOT_PAYED, className: getPaidStatusClassName(servicePaid) },
        onToggle: () => onToggleServicePayment(service.id),
        hasToggler: true,
        toggleValue: servicePaid,
        key: service.id?.toString(),
      };
    }) || [];

  const properties: Array<LabelType> = [
    {
      label: CLIENT_CONTRACT,
      value: {
        text:
          (
            <UiLink href={`/clients?id=${order.customer.id}`} isActive={false} className="order-details-payment__agreement">
              {order.contract?.number}
            </UiLink>
          ) || EMPTY_STRING,
        className: 'agreement',
      },
    },
    {
      label: `${PRICE_FOR_MAIN_SERVICE} ${vatCaption}`,
      value: {
        text: mainPrice,
      },
      hasToggler: true,
      toggleValue: paid,
      onToggle: onTogglePayment,
      status: { text: paid ? TOTAL_PAYED : NOT_PAYED, className: getPaidStatusClassName(paid) },
    },
    ...serviceLabels,
    { label: `${TOTAL_TO_PAY} ${vatCaption}`, value: { text: totalPrice } },
    ...serviceLabels,
  ];

  return (
    <OrderDetailsPayment
      order={order}
      fuelReadOnly={fuelReadOnly}
      onSaveDocumentsClick={onSaveDocumentsClick}
      onUploadPaymentDocuments={onUploadPaymentDocuments}
      onDeletePaymentDocumentClick={onDeletePaymentDocumentClick}
      showPaymentsToggler={showPaymentsToggler}
      attachments={attachments}
      properties={properties}
      header={CLIENT_PAYMENT}
    />
  );
};
