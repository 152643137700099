import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { SortType } from 'config/constants/types';
import { useIcons } from '@/hooks/use-icons';
import './carrier-filter-bottom.scss';
import { UiToggleField } from '@/ui-elements/form-fields/ui-toggle-field/ui-toggle-field';

type Props = {
  foundAmount?: number;
  onChangeSorting: (sortType: SortType) => void;
};

export const CarrierFilterBottom: FC<Props> = ({ foundAmount = 0, onChangeSorting }) => {
  const { Sorting } = useIcons();
  const [sorting, setSorting] = useState(false);
  const sortingName = sorting ? SortType.Asc : SortType.Desc;

  const handleChangeSorting = () => {
    setSorting((prev) => !prev);
  };

  useEffect(() => {
    onChangeSorting(sortingName);
  }, [sortingName, onChangeSorting]);

  return (
    <HStack className="carrier-filter__bottom">
      <HStack>
        <Text className="carrier-filter__bottom-amount" ml={16}>{`Найдено ${foundAmount}`}</Text>
        <HStack ml={22} onClick={handleChangeSorting} className={classNames('carrier-filter__bottom-sorting', { asc: sortingName === SortType.Asc })}>
          <Sorting />
          <Text>По рейтингу</Text>
        </HStack>
      </HStack>
      <HStack>
        <UiToggleField name="isfree" label="Только свободные" />
      </HStack>
    </HStack>
  );
};
