import { useMutation } from '@tanstack/react-query';
import { OrderServiceNew } from '@/types/models/order-service';
import { OrderServicesApi } from '@/api/order-services';

export const useOrderServicesActions = (id?: number) => {
  const addOrderService = useMutation({
    mutationKey: ['addOrderService'],
    mutationFn: (options: OrderServiceNew) => OrderServicesApi.addOrderService(id!, options),
  });

  const uploadOrderServiceDocument = useMutation({
    mutationKey: ['uploadServiceDocument'],
    mutationFn: (body: Array<FormData>) => OrderServicesApi.uploadOrderServiceDocument(id!, body),
  });

  const deleteOrderServiceDocument = useMutation({
    mutationKey: ['deleteOrderServiceDocument'],
    mutationFn: (documentId: number) => OrderServicesApi.deleteOrderServiceDocument(documentId),
  });

  return {
    addOrderService,
    uploadOrderServiceDocument,
    deleteOrderServiceDocument,
  };
};
