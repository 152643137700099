import { FC, SyntheticEvent } from 'react';
import { useForm } from '@/helpers/forms/use-form';
import { validateForm } from '@/helpers/forms/validate';
import { MyOrganizationType } from '@/components/my-organization/my-organization-type';
import { MyOrganizationManager } from '@/components/my-organization/my-organization-manager';
import { MyOrganizationPassportDetails } from '@/components/my-organization/my-organization-passport-details';
import { MyOrganizationBankDetails } from '@/components/my-organization/my-organization-bank-details';
import { MyOrganizationDocuments } from '@/components/my-organization/my-organization-documents';
import { MyOrganizationActionButtons } from '@/components/my-organization/my-organization-action-buttons';
import { MyOrganizationHeader } from '@/components/my-organization/my-organization-header';
import { MyOrganizationDriverGeneral } from '@/components/my-organization/my-organization-driver-general';
import { FileFormField, MyOrganizationFormOptions, MyOrganizationFormValues } from '@/types/models/my-organization/form';
import { FileDocument } from '@/types/models/document';
import { VerificationStatus } from '@/config/constants/statuses';
import { MyOrganizationDriverData } from '@/components/my-organization/my-organization-driver-data';
import { createOrganizationSchema } from '@/helpers/forms/validation-schemas/create-organization-schema';
import { createOrganizationWithDriverFormSchema } from '@/helpers/forms/validation-schemas/create-organization-with-driver-form-schema';

interface IProps {
  initialValues: any;
  options: MyOrganizationFormOptions;
  approvalStatuses?: Array<VerificationStatus>;
  disabled: boolean;
  documents: Record<FileFormField, FileDocument[] | undefined>;
  organizationID?: number;
  isEditStatus: boolean;
  isCarrierSimple?: boolean;

  onSubmit(values: MyOrganizationFormValues): void;

  removeAttachment(id: number, fieldName: FileFormField): void;

  updateFile(file: File[], fieldName: FileFormField): void;

  downloadAttachmentById(id: number, fieldName: FileFormField): void;

  setEditForm(e: SyntheticEvent): void;

  onToggleEditForm(e: SyntheticEvent): void;
}

export const MyOrganization: FC<IProps> = ({
  onSubmit,
  removeAttachment,
  organizationID,
  documents,
  initialValues,
  options,
  approvalStatuses,
  disabled,
  updateFile,
  downloadAttachmentById,
  isEditStatus,
  setEditForm,
  onToggleEditForm,
  isCarrierSimple,
}) => {
  const Form = useForm<MyOrganizationFormValues>();
  const validate = validateForm(createOrganizationSchema);
  const validateOrganizationWithDriver = validateForm(createOrganizationWithDriverFormSchema);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      validate={isCarrierSimple ? validateOrganizationWithDriver : validate}
    >
      <MyOrganizationHeader approvalStatuses={approvalStatuses} title="Моя организация" />
      {!isCarrierSimple ? (
        <>
          <MyOrganizationType disabled={disabled} options={options} />
          <MyOrganizationManager disabled={disabled} />
        </>
      ) : (
        <MyOrganizationDriverGeneral disabled={disabled} options={options} />
      )}
      <MyOrganizationPassportDetails disabled={disabled} />
      <MyOrganizationBankDetails disabled={disabled} />
      {isCarrierSimple && <MyOrganizationDriverData disabled={disabled} />}
      <MyOrganizationDocuments
        downloadAttachmentById={downloadAttachmentById}
        updateFile={updateFile}
        removeAttachment={removeAttachment}
        organizationID={organizationID}
        documents={documents}
        disabled={disabled}
        isCarrierSimple={isCarrierSimple}
      />
      <MyOrganizationActionButtons
        approvalStatuses={approvalStatuses}
        isEditStatus={isEditStatus}
        onToggleEditForm={onToggleEditForm}
        setEditForm={setEditForm}
        disabled={disabled}
      />
    </Form>
  );
};
