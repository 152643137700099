import { FC } from 'react';
import { EditServiceWidget } from 'components/edit-service-widget/edit-service-widget';
import { WidgetProps } from '@/features/widget/types/widget-item';
import { WidgetType } from '@/features/widget/widget-type';
import { useEditServiceWidget } from '@/views/edit-service-widget-view/use-edit-service-widget';

export const EditServiceWidgetView: FC<WidgetProps & WidgetType['EditServiceWidget']> = ({ service, order, carrierName, hideWidget }) => {
  const { handleSubmit } = useEditServiceWidget(order, hideWidget, service);
  return (
    <div className="add-service-widget-view">
      <EditServiceWidget service={service} order={order} companyName={carrierName} onCancelClick={hideWidget} onSubmit={handleSubmit} />
    </div>
  );
};
