import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { ModalType } from '@/types/common/modals';
import { ModalAction, useModal } from '@/hooks/use-modal';
import { useResourcesDispatchActions, useIntermediaryResources } from '@/features/resources/hooks';
import { FORBIDDEN_EDIT_STATUSES, NOTIFY_EDIT_STATUSES, VerificationStatus } from '@/config/constants/statuses';
import { EDIT_NOTIFY_TITLE, EDIT_NOTIFY_DESC, EDIT_FORBIDDEN_TITLE } from '@/features/resources/constants/general';
import { ResourcesContentTab, ResourcesSidebarMode } from '@/features/resources/types/general';
import { checkResourceStatus } from '@/helpers/check-resources-status';

type UseDetailsEditModalProps = {
  approvalStatuses?: VerificationStatus[];
  activeTab: ResourcesContentTab;
};

/** Хук, который используется в сайдбаре вкладки Мои ресурсы для водителей и ТС
 * Проверяем статусы и роль текущего аккаунта, чтобы управлять логикой кнопки редактирования ресурса.
 */
export const useEditResource = ({ approvalStatuses, activeTab }: UseDetailsEditModalProps) => {
  const { handleSetSidebarMode } = useResourcesDispatchActions();
  const { isIntermediaryRole } = useIntermediaryResources();

  const handleOpenEditForm = () => handleSetSidebarMode(ResourcesSidebarMode.EditForm);

  const { modalView: ModalEditNotify, setModalOpened: showEditNotifyModal } = useModal<{ title: string; desc: string } & ModalAction>(
    ModalType.EditNotify,
    {
      title: EDIT_NOTIFY_TITLE[activeTab],
      desc: EDIT_NOTIFY_DESC[activeTab],
      onSuccess: handleOpenEditForm,
    }
  );

  const handleEditResource = () => {
    // если нет статусов проверки, то разрешаем редактировать.
    // или если роль аккаунта - intermediary
    if (isEmpty(approvalStatuses) || isIntermediaryRole) {
      return handleOpenEditForm();
    }

    // если среди статусов есть "На проверке", запрещаем редактирование
    const resourceIsBeingChecked = checkResourceStatus({
      approvalStatuses,
      checkStatuses: FORBIDDEN_EDIT_STATUSES,
    });
    if (resourceIsBeingChecked) {
      return toast.error(EDIT_FORBIDDEN_TITLE[activeTab]);
    }

    // если среди статусов есть "Проверено", то разрешаем, но сообщаем, что проверка будет сброшена
    const resourceChecked = checkResourceStatus({ approvalStatuses, checkStatuses: NOTIFY_EDIT_STATUSES });
    if (resourceChecked) {
      return showEditNotifyModal(true);
    }

    // остается случай, когда статус проверки "Отклонен", либо после редактирования null,
    // можно редактировать, старый объект проверки будет существовать, но статус будет null
    return handleOpenEditForm();
  };

  return { handleEditResource, ModalEditNotify };
};
