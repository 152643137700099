import './load-navigate-to-platform-button.scss';
import classNames from 'classnames';
import { Box } from '@chakra-ui/react';
import { EMPTY_STRING } from '@/config/constants/common-strings';
import { NAVIGATE_TO_PLATFORM } from '@/config/constants/loads';
import { UiLink } from '@/ui-elements/ui-link/ui-link';

export const LoadNavigateToPlatformButton = ({ url }: { url?: string }) => {
  const disabled = !url;

  return (
    <Box className={classNames('load-navigate-to-platform-button', { disabled })}>
      <UiLink href={url || EMPTY_STRING} isExternal isActive={disabled}>
        {NAVIGATE_TO_PLATFORM}
      </UiLink>
    </Box>
  );
};
