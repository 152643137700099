export const getRatingStyles = (rating: number) => {
  let styles;
  if (rating < 2) {
    styles = {
      backgroundColor: 'neutrals.50',
      color: 'typography.250',
    };
  } else if (rating < 3) {
    styles = {
      backgroundColor: 'accentRed.100',
      color: 'typography.50',
    };
  } else if (rating === 3) {
    styles = {
      backgroundColor: 'accentYellow.50',
      color: 'typography.50',
    };
  } else if (rating < 4) {
    styles = {
      backgroundColor: 'accentYellow.150',
      color: 'typography.50',
    };
  } else {
    styles = {
      backgroundColor: 'accentLime.50',
      color: 'typography.50',
    };
  }

  return { ...styles };
};
