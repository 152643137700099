import { CargoForm, PointForm } from '@/components/create-order-form/schema';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { LoadTypeValues, RouteType } from '@/types/models/funnel';

/* Поля формы "Создать заявку" в разделе "Согласование" */
export enum CreateLoadFormField {
  Cargo = 'cargo',
  State = 'state',
  LoadInfo = 'loadInfo',
  Route = 'route',
  VehicleType = 'vehicleType',
  Requirements = 'requirements',
}
export enum InfoField {
  CustomerCompany = 'customerCompany',
  ResponsiblePerson = 'responsiblePerson',
  ContactPerson = 'contactPerson',
  ContactPhone = 'contactPhone',
}
export enum RouteField {
  RouteTypeField = 'routeType',
  Points = 'points',
  Transit = 'transit',
}
export enum VehicleField {
  VehicleTypes = 'vehicleTypes',
  LoadingType = 'loadingType',
  AdditionalRequirements = 'additionalRequirements',
  LoadType = 'loadType',
  Belts = 'belts',
  Tonnage = 'tonnage',
  TIR = 'TIR',
  CMR = 'CMR',
  T1 = 'T1',
  MedicalBook = 'medicalBook',
}

export type CreateLoadFormValues = {
  [CreateLoadFormField.Cargo]: Array<CargoForm>;
  [CreateLoadFormField.State]: {
    [CreateLoadFormField.Cargo]: Array<number | [number | number]>;
  };
  [CreateLoadFormField.LoadInfo]: {
    [InfoField.CustomerCompany]: SelectOption | undefined;
    [InfoField.ResponsiblePerson]: SelectOption;
    [InfoField.ContactPerson]: SelectOption;
    [InfoField.ContactPhone]: SelectOption;
  };
  [CreateLoadFormField.Route]: {
    [RouteField.RouteTypeField]: RouteType;
    [RouteField.Points]: Array<PointForm>;
    [RouteField.Transit]: Array<{
      cityTransit: string;
      addresstransit: string;
    }>;
  };
  [CreateLoadFormField.VehicleType]: {
    [VehicleField.VehicleTypes]: any;
    [VehicleField.LoadingType]: any;
    [VehicleField.AdditionalRequirements]: string;
    [VehicleField.LoadType]: LoadTypeValues;
    [VehicleField.Belts]: number;
    [VehicleField.Tonnage]: SelectOption;
    [VehicleField.TIR]: boolean;
    [VehicleField.CMR]: boolean;
    [VehicleField.T1]: boolean;
    [VehicleField.MedicalBook]: boolean;
  };
};
