import { FC } from 'react';
import { FormControl, FormControlProps, FormErrorMessage, InputProps } from '@chakra-ui/react';
import { useField } from 'react-final-form';
import { UiCheckboxInput } from '@/ui-elements';

type CheckboxFieldProps = {
  name: string;
  label?: string;
  isDisabled?: boolean;
  outerProps?: FormControlProps;
  className?: string;
  defaultChecked?: boolean;
  onChange?: VoidFunction;
};

export const UiCheckboxField: FC<CheckboxFieldProps & InputProps> = ({
  name,
  label,
  isDisabled,
  className,
  outerProps,
  defaultChecked,
  onChange,
}) => {
  const {
    input,
    meta: { touched, error, submitError },
  } = useField(name, { type: 'checkbox' });

  const normalizedError = Array.isArray(error) ? error.join(', ') : error || submitError;
  const onChangeProps = onChange ? { onChange } : {};

  return (
    <FormControl isInvalid={touched && normalizedError} isDisabled={isDisabled} {...outerProps}>
      <UiCheckboxInput {...input} defaultChecked={defaultChecked} isInvalid={touched && normalizedError} className={className} {...onChangeProps}>
        {label}
      </UiCheckboxInput>
      <FormErrorMessage>{normalizedError}</FormErrorMessage>
    </FormControl>
  );
};
