import { FC } from 'react';
import { Divider } from '@chakra-ui/react';
import { securityDetailsTabs } from 'components/security/security-details/texts';
import { SecurityDetailsOrganisation } from 'components/security/security-details-organisation/security-details-organisation';
import { DriversAndVehiclesList } from 'components/security/drivers-and-vehicles-list/drivers-and-vehicles-list';
import { UiTab, UiTabListMenuSelect, UiTabPanel, UiTabPanels, UiTabs, UiUnderConstruct } from '@/ui-elements';
import './security-details.scss';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { emptyFunction } from '@/helpers/empty-function';
import { Carrier } from '@/types/models/carrier';
import { Driver } from '@/types/models/driver';
import { Vehicle } from '@/types/models/vehicle';

type Props = {
  // подробнее о водителе
  onDriverMoreInfoClick?: (id: number) => void;
  onVehicleMoreInfoClick?: (vehicle: Vehicle) => void;
  // редактировать перевозчика
  onEditCarrierClick?: VoidFunction;
  carrier: Carrier;
  driver?: Driver;
  vehicle?: Vehicle;
  isFetching: boolean;
};

/**
 * Внтуренности сайдбара на вкладке Руководитель::безопасность - главный компонент
 * * */
export const SecurityDetails: FC<Props> = ({
  carrier,
  isFetching,
  driver,
  onDriverMoreInfoClick = emptyFunction,
  onVehicleMoreInfoClick = emptyFunction,
  onEditCarrierClick = emptyFunction,
  vehicle,
}) => {
  return (
    <div className="security-details">
      <UiTabs className="security-details__tabs" isLazy>
        <UiTabListMenuSelect size="lg" className="security-details__tablist">
          <UiTab>{securityDetailsTabs.Organisation}</UiTab>
          <UiTab>{securityDetailsTabs.CarriersAndDrivers}</UiTab>
          <Divider orientation="vertical" className="security-details__separator" />
          <UiTab>{securityDetailsTabs.Events}</UiTab>
        </UiTabListMenuSelect>
        <div className="security-details__tab-panels">
          <UiTabPanels className="security-details__tab-content">
            <UiTabPanel>
              <UiScrollbars autoHide>
                <SecurityDetailsOrganisation
                  isFetching={isFetching}
                  carrier={carrier}
                  driver={driver}
                  vehicle={vehicle}
                  onDriverMoreInfoClick={onDriverMoreInfoClick}
                  onVehicleMoreInfoClick={onVehicleMoreInfoClick}
                  onEditClick={onEditCarrierClick}
                />
              </UiScrollbars>
            </UiTabPanel>
            <UiTabPanel>
              <DriversAndVehiclesList
                drivers={carrier?.drivers || []}
                department={carrier.department.name}
                selectedDriver={driver}
                onDriverMoreInfoClick={onDriverMoreInfoClick}
                isFetching={isFetching}
              />
            </UiTabPanel>
            <UiTabPanel>
              <UiUnderConstruct />
            </UiTabPanel>
          </UiTabPanels>
        </div>
      </UiTabs>
    </div>
  );
};
