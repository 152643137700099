import { Review } from '@/types/models/review';

const userMock = {
  id: 1,
  name: 'Иванов',
  surname: 'И.',
  patronymic: 'И.',
};

export const reviewsMock: Array<Review> = [
  {
    id: 1,
    creator: userMock,
    created: '22.01.2023',
    value: 'Рейс выполнен без опозданий, но одна коробка с грузом была разбита.',
    ratingValue: 4,
    tripId: 2,
  },
  {
    id: 2,
    creator: userMock,
    created: '22.01.2023',
    value: 'Рейс выполнен без опозданий. Водитель сделал все по шагам. Не выключал приложение в процессе выполнения рейса',
    ratingValue: 5,
    tripId: 3,
  },
  {
    id: 3,
    creator: userMock,
    created: '22.01.2023',
    value: 'Рейс выполнен без опозданий. Весь груз доставлен в идеальном состоянии. По цене рейса.',
    ratingValue: 5,
    tripId: 5,
  },
  {
    id: 4,
    creator: userMock,
    created: '22.01.2023',
    value: 'Рейс выполнен без опозданий. Весь груз доставлен в идеальном состоянии. По цене рейса.',
    ratingValue: 5,
    tripId: 7,
  },
];

export const mockedTripsDoc = {
  id: 12,
  name: 'Список рейсов с подробностями',
  link: 'https://drive.google.com/file/d/1jA3XTmcVN3zZMTszJi-QhjuhjfPYDewi/view?usp=sharing',
};
