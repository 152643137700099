/* eslint-disable @typescript-eslint/no-unused-vars */
import { CarrierFilter } from '@/types/api/carrier-partial';
import { CarriersApi } from '@/api/carriers';
import { useInfiniteQueryWithUpdates } from '@/hooks/use-infinite-query-with-updates';

type Props = {
  perPage?: number;
  query?: CarrierFilter;
};

export const useAllCarriersInfiniteUpdates = ({ perPage = 10, query }: Props) => {
  const queryFn =
    (isFirst: boolean) =>
    ({ pageParam = 1 }) =>
      CarriersApi.getAllCarriers({ ...query, updates: false, perPage, page: pageParam });

  return useInfiniteQueryWithUpdates({ queryKey: 'allCarriers', query, refetchOnWindowFocus: false, queryFn });
};
