import { Box, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { FC } from 'react';
import closedIcon from 'public/assets/icons/CloseIcon.svg';
import { DriverVerificationForm } from 'components/verification-forms/driver-verification/driver-verification';
import { FormMode } from '@/components/verification-forms/types';
import { UiIconButton } from '@/ui-elements';
import { useModal } from '@/hooks/use-modal';
import { ModalsProps, ModalType } from '@/types/common/modals';
import { emptyFunction } from '@/helpers/empty-function';
import { Driver } from '@/types/models/driver';

export const ModalDriver: FC<
  ModalsProps<{
    driver: Driver;
    initialValues?: any;
  }>
> = ({ onCancel = emptyFunction, onClose, modalOpened, driver, initialValues }) => {
  const { modalView: confirmModal, setModalOpened } = useModal(ModalType.Confirm, {
    onCloseModal: () => setModalOpened(false),
    onSuccess: () => {
      onCancel();
      onClose();
    },
    modalWidth: 524,
    text: 'Вы действительно хотите закрыть окно?',
    removeXBtn: true,
    desc: 'Измененные данные не сохранятся',
    alignDesc: 'left',
    cancelButtonText: 'Назад',
    submitButtonText: 'Закрыть',
    accentXBtn: true,
  });

  const onCancelEdit = () => {
    setModalOpened(true);
  };
  return (
    <Modal isOpen={modalOpened} onClose={onCancelEdit}>
      {confirmModal}
      <ModalOverlay />
      <ModalContent borderRadius={24} boxShadow="xl" pos="relative" maxW={798} w={798} p={10} h="80vh">
        <Box position="absolute" right={12} top="43px">
          <UiIconButton aria-label="close-modal-button" icon={closedIcon} onClick={onCancelEdit} />
        </Box>
        <DriverVerificationForm
          driver={driver}
          mode={FormMode.edit}
          onCancelCreation={onCancelEdit}
          onClose={onCancelEdit}
          initialValues={initialValues}
          closeModal={onClose}
        />
      </ModalContent>
    </Modal>
  );
};
