import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@/config/constants/query-keys';
import { useVehicleActions } from '@/features/vehicles/hooks/use-vehicle-actions';
import { createFormVehicleValues } from '@/components/verification-forms/helpers/create-form-vehicle-values';
import { FormValues } from '@/components/verification-forms/types';
import { VehicleGroupEnum, VehicleOwnTypeEnum } from '@/components/verification-forms/constants';

interface Props {
  vehicleId?: number | null;
  withoutTrailer: boolean;
  onClose: VoidFunction;
  vehicleGroup: VehicleGroupEnum;
  ownType: VehicleOwnTypeEnum;
}

export const useEditResourcesVehicle = ({ vehicleId, withoutTrailer, onClose, vehicleGroup, ownType }: Props) => {
  const client = useQueryClient();
  const { updateVehicle } = useVehicleActions(vehicleId!);

  const handleEditVehicle = (values: FormValues) => {
    updateVehicle.mutate(createFormVehicleValues({ ...values, vehicleGroup, ownType }, withoutTrailer), {
      onSuccess: () => {
        client.invalidateQueries([QueryKeys.allVehicles]);
        client.invalidateQueries([QueryKeys.vehicle, vehicleId]);
        toast.success('Транспортное средство успешно изменено');
        onClose?.();
      },
    });
  };
  return { handleEditVehicle };
};
