import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SecurityState } from '@/features/security/types/security-state';

const initialState: SecurityState = {
  activeApprovalId: undefined,
  carrierId: undefined,
};

const slice = createSlice({
  name: 'security',
  initialState,
  reducers: {
    // выбрать  объект проверки
    selectApproval: (state, action: PayloadAction<{ approvalId: number; carrierId?: number }>) => {
      state.activeApprovalId = action.payload.approvalId;
    },
    clear: () => initialState,
  },
});

export const securityReducers = slice.reducer;
export const securityActions = slice.actions;
