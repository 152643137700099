import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MyOrganizationsState } from '@/features/my-organizations/types/my-organizations-state';

const initialState: MyOrganizationsState = {
  activeMyOrganizationsId: undefined,
};

const slice = createSlice({
  name: 'myOrganizations',
  initialState,
  reducers: {
    select: (state, action: PayloadAction<number>) => {
      state.activeMyOrganizationsId = action.payload;
    },
    unselect: (state) => {
      state.activeMyOrganizationsId = undefined;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const myOrganizationsReducers = slice.reducer;
export const myOrganizationsActions = slice.actions;
