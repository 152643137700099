import { FC } from 'react';
import { Box, Center, Divider, Flex, Text } from '@chakra-ui/react';
import { LAST_CRUISE } from 'components/driver-card-compact/texts';
import { CarrierCardRegNumber } from 'components/carrier-card/carrier-card-reg-number';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { Trailer, Vehicle } from '@/types/models/vehicle';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { vehicleExtender } from '@/features/vehicles/extender/vehicle-extender';
import { useIcons } from '@/hooks/use-icons';

type Props = {
  trailer: Trailer;
  vehicle: Vehicle;
  department?: string;
};
/**
 *Компактная карточка с описанием прицепа транспортного средства
 * */
export const VehicleTrailersCardCompact: FC<Props> = ({ trailer, vehicle, department }) => {
  const { lastCruiseNumber } = useExtendedObject(vehicle, vehicleExtender);
  const { Volume, TonageIcon } = useIcons();
  const trailerRegion = vehicleExtender.vehicleRegion(trailer as unknown as Vehicle);
  const trailerRegNumber = vehicleExtender.vehicleRegNumber(trailer as unknown as Vehicle);
  return (
    <Box mt="12px">
      <div className="vehicle-card-compact__info">
        <Flex justifyContent="space-between">
          <Text>Прицеп</Text>
          <Box>
            <span className="vehicle-card-compact__label">{LAST_CRUISE}:</span>
            <span className="vehicle-card-compact__last">{lastCruiseNumber}</span>
          </Box>
        </Flex>
        {department && <div className="vehicle-card-compact__area">{department}</div>}
      </div>
      <Flex justifyContent="space-between">
        <Flex gap="12px" mt="6px">
          <CarrierCardRegNumber regNumber={trailerRegNumber || EMPTY_STRING} region={trailerRegion || EMPTY_STRING} />
          <Text>Прицеп</Text>
          <Center className="order-general-info__divider-container">
            <Divider className="order-general-info__divider" orientation="vertical" />
          </Center>
          <Flex mt="2px" gap="6px">
            <Volume />
            {trailer.volume}м³
          </Flex>
          {trailer.capacity !== 0 ? (
            <>
              <Center className="order-general-info__divider-container">
                <Divider className="order-general-info__divider" orientation="vertical" />
              </Center>
              <Flex mt="2px" gap="6px">
                <TonageIcon />
                {trailer.capacity}т
              </Flex>
            </>
          ) : null}
        </Flex>
      </Flex>
    </Box>
  );
};
