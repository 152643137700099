import { FC } from 'react';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { SAVE } from 'config/constants/common-strings';
import { PrepaymentFormFieldNames } from 'components/order-details/order-details-prepayment/types';
import { PREPAYMENT_CALCULATION, PREPAYMENT_FUEL } from 'components/order-details/order-details-prepayment/texts';
import { PrepaymentWidget } from 'components/widgets/payment/prepayment-widget';
import { isNil } from 'lodash';
import { Order } from '@/types/models/order';
import { UiSliderField } from '@/ui-elements/ui-slider-field/ui-slider-field';
import { useForm } from '@/helpers/forms/use-form';
import { UiButtonOutlined, UiDivider, UiSelectFieldAsync } from '@/ui-elements';
import { UiText } from '@/ui-elements/ui-text/ui-text';
import { PrepaymentFormValues } from '@/types/models/prepayment';
import { fetchFuelCompaniesEnum } from '@/features/fuel-companies/hooks/use-fuel-companies-enum';
import { Load } from '@/types/models/loads';
import { toPriceFormat } from '@/helpers/price/to-price-format';

type Props = {
  onSubmit: (values: PrepaymentFormValues) => void;
  initialValues: PrepaymentFormValues;
  vat: boolean;
  setPrepayment: (arg: number) => void;
  order: Order | Load;
  maxPrepayment: number;
  prepaymentStep: number;
  tax: number;
  setFuel: (arg: number) => void;
  maxFuelPrepayment: number;
  fuelPrepaymentStep: number;
  benefit: number;
  getPaymentRest: (prepayment: number, fuel: number, tax: number, benefit: number, vat: boolean) => number;
  fuel: number;
  prepayment: number;
  hasPrepayment: boolean;
};

export const OrderDetailsPrepayment: FC<Props> = ({
  order,
  setPrepayment,
  prepaymentStep,
  fuelPrepaymentStep,
  maxFuelPrepayment,
  maxPrepayment,
  setFuel,
  benefit,
  vat,
  tax,
  getPaymentRest,
  onSubmit,
  initialValues,
  fuel,
  prepayment,
  hasPrepayment,
}) => {
  const Form = useForm<PrepaymentFormValues>();

  if (!hasPrepayment || isNil(order.prepayment?.prepayment)) {
    return null;
  }

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      <UiDivider mb={5} />
      <HStack flexDirection="row-reverse" alignItems="flex-start" justifyContent="flex-end" gap={12} ml={10}>
        <Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box>
              {order.prepayment?.possibility && (
                <>
                  <UiText color="typography.400" fontSize="2xl" justifyContent="center" display="flex" mb={2}>
                    {PREPAYMENT_CALCULATION}
                  </UiText>
                  <PrepaymentWidget
                    prepayment={order.prepayment?.prepayment}
                    setPrepayment={setPrepayment}
                    maxPrepayment={maxPrepayment}
                    prepaymentStep={prepaymentStep}
                    tax={tax}
                  />
                </>
              )}

              {!vat && order.prepayment?.fuelPossibility && (
                <>
                  <UiSliderField
                    onChange={setFuel}
                    name={PrepaymentFormFieldNames.fuel}
                    defaultValue={order.prepayment?.fuel || 0}
                    min={0}
                    max={maxFuelPrepayment}
                    step={fuelPrepaymentStep}
                    sliderName={PREPAYMENT_FUEL}
                    counterName="Бонус"
                    counterValue={benefit}
                    positive
                  />
                  <UiSelectFieldAsync placeholder="Начните ввод" fetchOptions={fetchFuelCompaniesEnum} name={PrepaymentFormFieldNames.fuelCompany} />
                </>
              )}
            </Box>
          </Box>
          <VStack bg="gray.200" mb={2}>
            <div>+{toPriceFormat(getPaymentRest(prepayment, fuel, tax, benefit, vat))}</div>
            <Box>После получения оригиналов</Box>
          </VStack>
          <HStack mb={5} mt={5} alignItems="center" justifyContent="flex-start">
            <UiButtonOutlined type="submit">{SAVE}</UiButtonOutlined>
          </HStack>
        </Box>
      </HStack>
    </Form>
  );
};
