import { createBrowserRouter } from 'react-router-dom';
import { Error404Page } from 'pages/404-page';
import { App } from '@/App';
import { getRouterObjects } from '@/helpers/routes/get-router-objects';
import { RoutesConfig } from '@/types/routes/routes-config';
import { store } from '@/redux/store';
import { getSelfAccountAction } from '@/features/account/actions/get-self-account-action';
import { AppToolbarView } from '@/views/toolbar/app-toolbar-view';

export const getRouter = (config: RoutesConfig) => {
  const appRoutes = [
    {
      path: '*',
      element: (
        <>
          <AppToolbarView />
          <Error404Page />
        </>
      ),
    },
    {
      path: '/',
      element: <App />,
      children: getRouterObjects(config.routes),
    },
    ...Object.values(config.anonymousRoutes),
  ];

  const appRoutesWithLoader = appRoutes.map((route) => ({
    ...route,
    // loader для проверки авторизации
    // TODO Нужно с этим что то сделать
    loader: async () => {
      if (!store.getState().account.logoutPending && !store.getState().account.authenticated) {
        store.dispatch(getSelfAccountAction());
      }
      return null;
    },
  }));

  return createBrowserRouter(appRoutesWithLoader);
};
