import { FC } from 'react';
import './driver-tab.scss';
import { Driver } from '@/types/models/driver';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { DriverTabContent } from './driver-tab-content';

type Props = {
  driver: Driver;
  isScroll?: boolean;
};

export const DriverTab: FC<Props> = ({ driver, isScroll = true }) => {
  return (
    <div>
      {isScroll ? (
        <UiScrollbars style={{ width: '100%', height: '100vh' }}>
          <DriverTabContent driver={driver} />
        </UiScrollbars>
      ) : (
        <DriverTabContent driver={driver} />
      )}
    </div>
  );
};
