import { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { CarrierRating } from 'components/carrier-rating/carrier-rating';
import { FONT_SIZE_CONTENT_12, MARGIN_TOP_DRIVER, WIDTH_DRIVER } from 'components/carrier-card/carrier-card-driver-info/style-constants-content';
import { getEmptyStr } from '@/helpers/get-empty-str';
import { Driver } from '@/types/models/driver';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { driverExtender } from '@/features/drivers/extender/driver-extender';
import { UiWarningNumber } from '@/ui-elements/ui-warning-number/ui-warning-number';

type Props = {
  driver: Driver;
  // скрыть информацию о пройденной проверке
  hideApprovalInformation?: boolean;
};
export const CarrierCardDriverInfo: FC<Props> = ({ driver, hideApprovalInformation = false }) => {
  const { approveStatusCaption } = useExtendedObject(driver, driverExtender);
  const driverName = `${getEmptyStr(driver?.surname)} ${getEmptyStr(driver?.name)} ${getEmptyStr(driver?.patronymic)}`;
  if (!driver) return null;
  return (
    <Box>
      <Flex mb="12px" w={WIDTH_DRIVER} className="carrier-card-driver-info__name">
        <UiWarningNumber number={driver?.riskGroup || 0} />
        <Text ml="8px">{driverName}</Text>
      </Flex>
      <CarrierRating rating={driver?.rating || 0} />
      {/* <Text fontSize={FONT_SIZE_CONTENT_14} mt="14px">
        {driver?.department}
      </Text> */}
      {!hideApprovalInformation && (
        <Text className="text-control-driver" mt={MARGIN_TOP_DRIVER} fontSize={FONT_SIZE_CONTENT_12}>
          {approveStatusCaption}
        </Text>
      )}
      <Text mt={MARGIN_TOP_DRIVER} fontWeight={700} color="typography.100">
        {driver?.phone || 123}
      </Text>
    </Box>
  );
};
