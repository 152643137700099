import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { theme } from 'scss/custom-theme/theme';
import { QueryClientProvider } from '@tanstack/react-query';
import { ChakraProvider } from '@chakra-ui/react';
import { ToastContainer } from 'react-toastify';
import { RouterProvider } from 'react-router-dom';
import { routesConfig } from 'config/routes/routes-config';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';
import { getRouter } from '@/helpers/routes/get-router';
import { store } from '@/redux/store';
import { queryClient } from '@/config/query-client';
import { SpinnerView } from '@/views';
import { options } from '@/config/toast-default';
import { injectStore } from '@/helpers/api/instance';

if (import.meta.env.VITE_REACT_APP_USE_SENTRY) {
  Sentry.init({
    dsn: import.meta.env.VITE_REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      }),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const router = getRouter(routesConfig);
injectStore(store);

// TODO Решить что делать с этим
// NOTE: Используется для моков запросов модулей Поиск грузов и Согласование, пока бэкенд в разработке
if (process.env.NODE_ENV === 'development') {
  // worker.start();
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <SpinnerView />
        <ToastContainer {...options} />
        <RouterProvider router={router} />
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </ChakraProvider>
    </Provider>
  </QueryClientProvider>
);
