export type RiskValues = 1 | 2 | 3 | 4 | 5 | 6;

export type RatingValues = 1 | 2 | 3 | 4 | 5;

export type OrderModification = 'split' | 'union' | null;

export enum SortType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum MeasureType {
  Weight = 'weight',
  Volume = 'volume',
  Pallets = 'pallets',
}

export enum OrderType {
  Simple = 'simple',
  Roundtrip = 'roundtrip',
}

export enum OrderLoadType {
  Loading = 'loading',
  Unloading = 'unloading',
}

export enum OrderLoadTypeId {
  loading = 1,
  unloading = 0,
}

export enum RatingType {
  Gold = 'gold',
  Silver = 'silver',
  Platinum = 'platinum',
  Vip = 'vip',
}

export enum NotificationType {
  Statuses = 'statuses',
  Dispatchers = 'dispatchers',
  News = 'news',
}

export enum NotificationMethod {
  Push = 'push',
  Sms = 'sms',
  Email = 'email',
}

export enum CarrierType {
  IP = 'IP',
  OOO = 'OOO',
  AO = 'AO',
  ZAO = 'ZAO',
  PAO = 'PAO',
  NPP = 'NPP',
  NONE = 'NONE',
}

export enum VatType {
  WithVat = 'withVat',
  WithoutVat = 'withoutVat',
  CombinedVat = 'combinedVat',
}

export type VatTypeSelectOption = {
  value?: VatType;
  label: string | number | undefined;
};

export const isVatSelectOption = (option?: string | VatTypeSelectOption): option is VatTypeSelectOption => {
  return (option as VatTypeSelectOption)?.value !== undefined;
};

export enum TransportationType {
  Single = 1,
  TopUp = 2,
}

export enum AnalyticsPeriod {
  All = 'all',
  Today = 'today',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export enum AttachmentDeps {
  User = 'user',
  Vehicle = 'vehicle',
  Order = 'tender',
  Customer = 'customer',
  Driver = 'driver',
  Carrier = 'carrier',
  Point = 'tenderWaypoint',
  Organization = 'organization',
  Load = 'load',
}

export enum NotificationDeps {
  Order = 'order',
  OrderPoint = 'orderPoint',
}
