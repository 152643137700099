import { useMutation } from '@tanstack/react-query';
import { CustomersApi } from '@/api/customers';
import { CustomerEdit } from '@/types/api/customer-partial';

export const useCustomerActions = () => {
  const addCustomer = useMutation({
    mutationKey: ['addCustomer'],
    mutationFn: (options: CustomerEdit) => CustomersApi.addCustomer(options),
  });

  return { addCustomer };
};
