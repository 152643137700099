import { useMutation } from '@tanstack/react-query';
import { SettingsFormValues } from 'components/settings/types';
import { SettingsApi } from '@/api/settings';

export const useSettingsActions = () => {
  const addSettings = useMutation({
    mutationKey: ['addSettings'],
    mutationFn: (options: SettingsFormValues) => SettingsApi.addSettings(options),
  });
  return { addSettings };
};
