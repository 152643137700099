/** Преобразует поля типа SelectOption в number
 *
 * @param data -исходный объект
 * @param fields - массив ключей для преобразования
 */
export const selectOptionToValue = <T>(data: T, fields: Array<keyof T>) => {
  return fields.reduce((acc, curr) => {
    const currentValue = acc[curr] as any as { value: number };
    const newValue = currentValue?.value ? currentValue.value : currentValue;
    return { ...acc, [curr]: newValue };
  }, data);
};
