import { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { Carrier } from '@/types/models/carrier';
import { RatingTypeNames } from '@/config/constants/texts';
import './carrier-reviews-title.scss';
import { VatType } from '@/config/constants/types';
import { getCarrierOrganizationINN } from '@/features/carriers/helpers/get-carrier-organization-inn';
import { getCarrierOrganizationVatType } from '@/features/carriers/helpers/get-carrier-organization-vat-type';
import { EMPTY_STRING } from '@/config/constants/common-strings';

type Props = {
  carrier?: Carrier;
  vehiclesAmount: number;
};
export const CarrierReviewsTitle: FC<Props> = ({ carrier, vehiclesAmount }) => {
  const carrierOrganizationINN = getCarrierOrganizationINN(carrier);
  const carrierOrganizationVatType = getCarrierOrganizationVatType(carrier);

  return (
    <Box className="carrier-info">
      <Text className="carrier-info__name">{carrier?.name}</Text>
      <Text className="carrier-info__details">
        ИНН {carrierOrganizationINN} / {carrierOrganizationVatType === VatType.WithVat ? 'Плательщик НДС /' : EMPTY_STRING} ТС: {vehiclesAmount}
      </Text>
      <Text className="carrier-info__rating">Уровень сотрудничества: {carrier?.rating ? RatingTypeNames[carrier?.rating] : 'Не указан'}</Text>
    </Box>
  );
};
