import { FC } from 'react';
import './icon-button-widget.scss';

interface IconUserProps {
  onClick?: (e: any) => void;
}

export const IconButtonDowWidget: FC<IconUserProps> = ({ onClick = () => {} }) => {
  return (
    <svg onClick={onClick} className="ui-button-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C10.3787 2 10.6857 2.307 10.6857 2.68571L10.6857 12.0017L14.0866 8.60084C14.3543 8.33305 14.7885 8.33305 15.0563 8.60084C15.3241 8.86863 15.3241 9.3028 15.0563 9.57059L10.4849 14.142C10.3563 14.2706 10.1819 14.3429 10 14.3429C9.81814 14.3429 9.64372 14.2706 9.51513 14.142L4.9437 9.57059C4.67591 9.3028 4.67591 8.86863 4.9437 8.60084C5.21149 8.33305 5.64566 8.33305 5.91344 8.60084L9.31429 12.0017L9.31429 2.68571C9.31429 2.307 9.62129 2 10 2ZM2 17.3143C2 16.9356 2.307 16.6286 2.68571 16.6286H17.3143C17.693 16.6286 18 16.9356 18 17.3143C18 17.693 17.693 18 17.3143 18H2.68571C2.307 18 2 17.693 2 17.3143Z"
        fill="#4E4E5A"
      />
    </svg>
  );
};
