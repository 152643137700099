// @ts-nocheck
import { OrderLoadType, OrderLoadTypeId } from 'config/constants/types';
import { PointForm } from 'components/create-order-form/schema';
import { Cargos } from '@/types/models/cargos';
import { extractCargo } from '@/helpers/forms/extract-cargo';
import { PointNew } from '@/types/models/point';
import { Action } from '@/types/models/action';
import { getArrivalDateTime } from '@/helpers/forms/point-cargo-actions/get-arrival-date-time';
import { getArrivalDuration } from '@/helpers/forms/point-cargo-actions/get-arrival-duration';

export const ONE_DAY_IN_SECONDS = 86400;

export const extractPointsCargosActions = (points: Array<PointForm>) => {
  const pointsResult = [] as Array<PointNew>;
  const cargosResult = [] as Array<Cargos>;
  const actionsResult = [] as Array<Action>;

  points.map((point, index) => {
    pointsResult.push({
      id: point?.id,
      ind: index,
      address: point?.address.label,
      arrivalDateTime: getArrivalDateTime(point),
      arrivalDuration: getArrivalDuration(point),
    });

    point.cargos.map((cargo) => {
      if (point.type === OrderLoadType.Loading) cargosResult.push(extractCargo(cargo));

      actionsResult.push({
        pointId: point.id,
        cargoId: point.type === OrderLoadType.Loading ? cargo.id : cargo.unloadCargo?.value,
        type: OrderLoadTypeId[point.type],
      });
    });
  });

  return { pointsResult, cargosResult, actionsResult };
};
