import { FormType, REGISTER_FORM_TYPE_CONTENT } from 'components/register/regster-form/constants';
import { getPhoneFieldName } from 'components/register/regster-form/helpers/get-phone-field-name';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { FormFieldName } from 'config/constants/form';
import { useForm } from '@/helpers/forms/use-form';

export const useRegisterForm = (registerFormType: FormType) => {
  const Form = useForm();
  const registerFormContent = REGISTER_FORM_TYPE_CONTENT[registerFormType];
  const phoneFieldName = getPhoneFieldName(registerFormType);
  const initialValues = {
    [FormFieldName.accountRole]: EMPTY_STRING,
    [FormFieldName.target]: sessionStorage.getItem(FormFieldName.phone) ?? EMPTY_STRING,
    [FormFieldName.code]: EMPTY_STRING,
    [FormFieldName.phone]: sessionStorage.getItem(FormFieldName.phone) ?? EMPTY_STRING,
    [FormFieldName.password]: sessionStorage.getItem(FormFieldName.password) ?? EMPTY_STRING,
  };
  const isCheckCodePhase = registerFormType === FormType.checkCode;
  const isRegisterPhase = registerFormType === FormType.register;

  return {
    Form,
    registerFormContent,
    phoneFieldName,
    initialValues,
    isCheckCodePhase,
    isRegisterPhase,
  };
};
