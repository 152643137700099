import { FC } from 'react';
import { PaymentChip } from '@/ui-elements/payment-chip/payment-chip';
import { toPriceFormat } from '@/helpers/price/to-price-format';
import { Payment } from '@/types/models/payment';

interface IProps {
  priceWithVAT: Payment['priceWithVAT'];
  priceWithoutVAT: Payment['priceWithoutVAT'];
  isVisiblePrice: ({ vat }: { vat: boolean }) => boolean;
}

export const OrderCardPriceWidget: FC<IProps> = ({ priceWithVAT, priceWithoutVAT, isVisiblePrice }) => {
  return (
    <>
      {isVisiblePrice({ vat: true }) && Boolean(priceWithVAT) && <PaymentChip type="priceWithVAT" amount={toPriceFormat(priceWithVAT, true)} />}
      {isVisiblePrice({ vat: false }) && Boolean(priceWithoutVAT) && (
        <PaymentChip type="priceWithoutVAT" amount={toPriceFormat(priceWithoutVAT, true)} />
      )}
    </>
  );
};
