import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/types/redux/root-state';
import { EMPTY_STRING } from '@/config/constants/common-strings';

/**
 * NOTЕ это роль пользователя
 */
export const accountUserRoleSelector = createSelector(
  (state: RootState) => state.account,
  (account) => account.user?.userRole
);

/**
 * NOTЕ это роль аккаунта
 */
export const accountRoleSelector = createSelector(
  (state: RootState) => state.account,
  (account) => account.user?.accountRole
);

export const accountPhoneSelector = createSelector(
  (state: RootState) => state.account,
  (account) => account.user?.phone ?? EMPTY_STRING
);
