import { Box, Text, HStack, Flex } from '@chakra-ui/react';
import { ApproveStatus } from 'config/constants/statuses';
import { Carrier } from '@/types/models/carrier';
import { UiDownloadCard, UiButtonFilled } from '@/ui-elements';
import { Approval } from '@/types/models/approval';
import { ApprovalHistory } from '@/components/approval-history/approval-history';
import { getCarrierOrganizationINN } from '@/features/carriers/helpers/get-carrier-organization-inn';

type Props = {
  carrierInfo: Carrier;
  approval: Approval;
  setChecking: (arg0: boolean) => void;
};

export const OrganizationDetails = ({ carrierInfo, approval, setChecking }: Props) => {
  const carrierOrganizationINN = getCarrierOrganizationINN(carrierInfo);

  return (
    <Box>
      <Text fontWeight={700} fontSize={18}>
        {carrierInfo.name}
      </Text>
      <Text fontWeight={600} mt={2.5}>
        ИНН: {carrierOrganizationINN}
      </Text>
      <Text fontWeight={600} mt={3}>
        Телефон организации: <span className="carriers-verification-info__accent">{carrierInfo.phoneNumber}</span>
      </Text>
      <Flex mt={3}>
        <Text fontWeight={600} mr={6}>
          ТС в парке: <span className="carriers-verification-info__accent">{carrierInfo.countVehicles}</span>
        </Text>
        <Text fontWeight={600}>
          Водителей в штате: <span className="carriers-verification-info__accent">{carrierInfo.countDrivers}</span>
        </Text>
      </Flex>
      <Text fontWeight={700} mt={3} mb={3}>
        {carrierInfo.department.name}
      </Text>
      <ApprovalHistory approval={approval} />
      <Text fontWeight={700} mt={6} fontSize={18}>
        Документы по организации
      </Text>
      <HStack spacing={1} align="top" mt={4}>
        {(carrierInfo.documents || []).map((doc) => (
          <UiDownloadCard w={172} doc={doc} fileName={doc.name} url={doc.link} key={doc.id} mb={1} />
        ))}
      </HStack>
      {!approval.driver && !approval.vehicle && approval.status === ApproveStatus.Waiting && (
        <Box mt={4}>
          <UiButtonFilled mode="dark" onClick={() => setChecking(true)} width={172}>
            Начать проверку
          </UiButtonFilled>
        </Box>
      )}
    </Box>
  );
};
