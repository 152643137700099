import { useState, useEffect } from 'react';
import pullAll from 'lodash/pullAll';
import _ from 'lodash';
import { List, ListItem, Box } from '@chakra-ui/react';
import { useAppSelector } from '@/hooks/use-app-selector';
import { vehicleTypesSelector } from '@/features/vehicle-types/selectors/vehicle-types-selector';
import { UiCheckboxInput } from '@/ui-elements';
import './ui-vehicle-types-picker.scss';

type Props = {
  isMulti?: boolean;
  onChange?: (val: Array<number | { value: number | string }>) => void;
  defaultValue?: Array<number>;
};

export type VehicleType = {
  id: number;
  name: string;
  key: string;
  feature: string;
  isTrailer: 1 | 0;
};

export type VehicleGroup = {
  id: number;
  isGroup: boolean;
  name: string;
  types: Array<VehicleType>;
};

export const UiVehicleTypesPicker = ({ isMulti = true, onChange, defaultValue = [] }: Props) => {
  const [values, setValues] = useState<Array<number | { value: number | string }>>(defaultValue);
  const [groupValues, setGroupValues] = useState<Array<number>>([]);
  const vehicles = useAppSelector<Array<VehicleGroup & VehicleType>>(vehicleTypesSelector as any);
  useEffect(() => {
    onChange?.(values);
  }, [values, onChange]);
  return (
    <div className="ui-vehicle-types-picker">
      <List spacing={2}>
        {_.isArray(vehicles) &&
          vehicles.map((vehicle) =>
            vehicle.isGroup ? (
              <Box key={vehicle.id}>
                <UiCheckboxInput
                  mb={2}
                  isDisabled={!isMulti}
                  checked={groupValues.includes(vehicle.id)}
                  onChange={() => {
                    const ids = vehicle.types.map((type) => type.id);
                    if (groupValues.includes(vehicle.id)) {
                      setValues((items) => pullAll(items, ids));
                      setGroupValues((items) => items.filter((item) => item !== vehicle.id));
                    } else {
                      const uniqueValues = ids.filter((id) => !values.includes(id));
                      setValues([...values, ...uniqueValues]);
                      setGroupValues([...groupValues, vehicle.id]);
                    }
                  }}
                >
                  {vehicle.name}
                </UiCheckboxInput>
                {vehicle.types.map(({ id, name }) => (
                  <ListItem ml={4} key={id} mb={2}>
                    <UiCheckboxInput
                      isDisabled={!isMulti && !values.includes(id) && values.length > 0}
                      checked={
                        values &&
                        values
                          .map((v) => {
                            return _.isObject(v) ? +v.value : +v;
                          })
                          .includes(id)
                      }
                      onChange={() => (values.includes(id) ? setValues((items) => items.filter((item) => item !== id)) : setValues([...values, id]))}
                    >
                      {name}
                    </UiCheckboxInput>
                  </ListItem>
                ))}
              </Box>
            ) : (
              <ListItem key={vehicle.id}>
                <UiCheckboxInput
                  isDisabled={!isMulti && !values.includes(vehicle.id) && values.length > 0}
                  checked={
                    values &&
                    values
                      .map((v) => {
                        return _.isObject(v) ? +v.value : +v;
                      })
                      .includes(vehicle.id)
                  }
                  onChange={() =>
                    values.includes(vehicle.id)
                      ? setValues((items) => items.filter((item) => item !== vehicle.id))
                      : setValues([...values, vehicle.id])
                  }
                >
                  {vehicle.name}
                </UiCheckboxInput>
              </ListItem>
            )
          )}
      </List>
    </div>
  );
};
