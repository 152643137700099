import { FormControl, FormErrorMessage, FormLabel, RadioGroup } from '@chakra-ui/react';

type Props = {
  input?: any;
  meta?: any;
  label?: string;
  children?: React.ReactNode;
};

export const UiRadioGroup = ({ input, meta, label, children }: Props) => (
  <FormControl isInvalid={meta.touched && meta.invalid}>
    <FormLabel htmlFor={input.name}>{label}</FormLabel>
    <RadioGroup {...input}>{children}</RadioGroup>
    <FormErrorMessage>{meta.error}</FormErrorMessage>
  </FormControl>
);
