import { FC, ReactNode } from 'react';
import { Tag, TagProps } from '@chakra-ui/react';
import classNames from 'classnames';

type Props = TagProps & {
  children: ReactNode;
  className?: string;
};

export const UiTag: FC<Props> = ({ className, children, ...props }) => (
  <Tag className={classNames(className, 'ui-tag')} {...props}>
    {children}
  </Tag>
);
