import { useOrderInvalidate } from '@/features/order/hooks/use-order-invalidate';
// @ts-ignore
import { AddServiceFormFields } from './type';
import { useOrderServicesEdit } from '@/features/order-services/hooks/use-order-services-edit';
import { OrderService } from '@/types/models/order-service';
import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';

export const useEditServiceWidget = (order: Order | Load, hideWidget: VoidFunction, service: OrderService) => {
  const { invalidateOrderServices } = useOrderInvalidate();
  const { updateOrderService } = useOrderServicesEdit(service?.id);

  const handleSubmit = (fields: AddServiceFormFields) => {
    const { ...rest } = fields;
    const request = {
      carrierPayment: {
        price: rest.carrierPrice,
        vat: rest.carrierVat,
      },
      customerPayment: {
        price: rest.customerPrice,
        vat: rest.customerVat,
      },
      ...rest,
    };
    updateOrderService.mutate(request, {
      onSuccess: () => {
        invalidateOrderServices(order.id);
        hideWidget();
      },
    });
  };
  return { handleSubmit };
};
