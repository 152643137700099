import { DocumentTypes } from 'config/constants/document-types';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { AttachmentDeps } from 'config/constants/types';
import { useAttachmentsActions } from '@/features/attachments/hooks/use-attachments-actions';
import { downloadFileDocument } from '@/helpers/api/download-file-document';
import { emptyFunction } from '@/helpers/empty-function';
import { AttachmentToConfirmDeleteQuery } from '@/types/models/attachments';
import { useAttachments } from './use-attachments';
import { FileDocument } from '@/types/models/document';

type Props = { dependent: AttachmentDeps; dependentId?: number | null; type?: DocumentTypes; preventLoading?: boolean; staleTime?: number };
/**
 * Хук для получение, добавления и удаления вложений
 * */
export type UseDependentAttachmentsReturnValues = {
  deleteAttachmentById: (documentId: number) => void;
  postAttachments: (files: File[], onSuccessCallback?: (...args: any[]) => void) => void;
  downloadAttachments: () => void;
  downloadAttachmentById: (id: number) => void;
  attachments: FileDocument[] | undefined;
  deleteAttachmentToConfirm: ({ dependent, dependentID, reason }: AttachmentToConfirmDeleteQuery) => void;
};

export const useDependentAttachments = ({ dependent, dependentId, type, preventLoading, staleTime }: Props): UseDependentAttachmentsReturnValues => {
  const { postAttachment, deleteAttachment, deleteToConfirm } = useAttachmentsActions();

  const attachmentQuery = useAttachments(
    {
      dependent,
      dependentID: dependentId!,
      subject: type || EMPTY_STRING,
    },
    preventLoading,
    staleTime
  );

  const { attachments } = attachmentQuery;

  const deleteAttachmentById = (documentId: number) => {
    deleteAttachment.mutate(documentId, {
      onSuccess: () => attachments.refetch(),
    });
  };

  const deleteAttachmentToConfirm = ({
    dependent: attachmentDependent,
    dependentID: attachmentDependentID,
    reason,
  }: AttachmentToConfirmDeleteQuery) => {
    deleteToConfirm.mutate(
      { dependent: attachmentDependent, dependentID: attachmentDependentID, reason },
      {
        onSuccess: () => attachments.refetch(),
      }
    );
  };

  const downloadAttachments = () => {
    attachments.data?.data?.forEach((doc) => {
      downloadFileDocument(doc);
    });
  };

  const downloadAttachmentById = (id: number) => {
    const doc = attachments.data?.data?.find((x) => x.id === id);
    if (doc) downloadFileDocument(doc);
  };

  const postAttachments = (files: File[], onSuccessCallback = emptyFunction) => {
    if (dependentId) {
      files.forEach((file) => {
        postAttachment.mutate(
          {
            attachment: file,
            dependent,
            subject: type || EMPTY_STRING,
            dependentID: dependentId,
          },
          {
            onSuccess: () => {
              attachments.refetch();
              onSuccessCallback();
            },
          }
        );
      });
    }
  };

  return {
    deleteAttachmentById,
    postAttachments,
    downloadAttachments,
    downloadAttachmentById,
    attachments: attachments.data?.data,
    deleteAttachmentToConfirm,
  };
};
