import { useMutation } from '@tanstack/react-query';
import { UsersApi } from '@/api/users';
import { QueryKeys } from '@/config/constants/query-keys';

export const useResetDriverPassword = () => {
  const resetDriverPassword = useMutation({
    mutationKey: [QueryKeys.resetDriverPassword],
    mutationFn: (id: number | null) => UsersApi.sendUserPassword(id),
  });
  return {
    resetDriverPassword,
  };
};
