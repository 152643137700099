import { FC, useState } from 'react';
import { Form } from 'react-final-form';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import '../verification-form.scss';
import { customerToApprovalForm } from 'components/verification-forms/helpers/customer-to-approval-form';
import { CustomersCreateFormValues } from 'components/create-order-form/schema';
import {
  UiButtonFilled,
  UiButtonOutlined,
  UiFileUploadField,
  UiIconButton,
  UiInputField,
  UiMultiDatePickerField,
  UiSwitchField,
  UiTextarea,
} from '@/ui-elements';
import { formatFormFields } from '@/helpers/forms/format-form-fields';
import { Customer } from '@/types/models/customer';
import { useIcons } from '@/hooks/use-icons';
import { useCustomerUpdateActions } from '@/features/customers/hooks/use-customer-update-actions';
import { ApprovalBody } from '@/types/models/approval';
import { DependentType } from '@/api/documents/dependent-types';

type Props = {
  customer: Customer;
  onApprovalDeclined?: (approvalBody: ApprovalBody) => void;
  onApprovalApproved?: (approvalBody: ApprovalBody) => void;
  onClose?: VoidFunction; // закрытие модалки проверки
};
export const ApproveCustomerForm: FC<Props> = ({ customer, onApprovalDeclined, onApprovalApproved, onClose }) => {
  const { CloseIcon } = useIcons();
  const initialValues: any = customerToApprovalForm({ customer });
  const { updateCustomerFalse } = useCustomerUpdateActions(customer?.id);

  const [review, setReview] = useState('');

  const onApprove = (values: CustomersCreateFormValues) => {
    const newValues = formatFormFields(values, 'pick', ['loans', 'convictions']);
    updateCustomerFalse.mutate(newValues as any, {
      onSuccess: () => {
        onApprovalApproved?.({
          dependent: DependentType.Customer,
          dependentId: customer.id,
          comment: values.comment,
        });
      },
    });
  };

  const onDecline = (values: CustomersCreateFormValues) => {
    onApprovalDeclined?.({
      dependent: DependentType.Customer,
      dependentId: customer.id,
      comment: values.comment,
    });
  };

  const onSubmit = (values: CustomersCreateFormValues) => (values.action === 'approve' ? onApprove(values) : onDecline(values));

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Text className="carriers-verification-form__title">Вы проверяете клиента:</Text>
          <Box pos="absolute" right={5} top={0}>
            <UiIconButton aria-label="close" icon={CloseIcon} onClick={onClose} />
          </Box>
          <HStack>
            <Flex w="98%" mt={4} mb={6} gap={6}>
              <UiInputField name="customer" label="Клиент" isDisabled />
              <UiInputField name="inn" label="ИНН" placeholder="12 цифр" isDisabled />
            </Flex>
          </HStack>
          <HStack>
            <Flex w="98%" mb={6} gap={6}>
              <UiInputField name="contract" label="Договор-номер" isDisabled />
              <UiMultiDatePickerField name="date" label="Дата договора" placeholder="Выберите дату" isDisabled />
              <UiInputField name="manager" label="Ответственный менеджер" isDisabled />
            </Flex>
          </HStack>

          <Box w="98%" mb={6}>
            <UiInputField name="comment" label="Комментарий" isDisabled />
            <UiInputField name="tarif" label="Тарифное соглашение" isDisabled />
            <UiSwitchField name="vipCustomer" label="VIP клиент" isDisabled />
          </Box>
          <Box>
            <Box mt={1}>
              <Text>Документы</Text>
            </Box>
            <UiFileUploadField withDownloadButton name="file-upload" />
          </Box>

          <div className="carriers-verification-form__main">
            <Box mt={4} mb={6}>
              <UiTextarea
                className="carriers-verification-form__comment"
                label="Комментарий"
                variant="noResize"
                placeholder="Введите текст"
                value={review}
                onChange={(e) => setReview(e.target.value)}
              />
            </Box>
          </div>

          <HStack justifyContent="right" mt={4} mb={2} mr={2}>
            <UiButtonOutlined
              type="submit"
              className="carriers-verification-form__btn-decline"
              mode="light"
              onClick={() => form.change('action', 'decline')}
              w={128}
              isDisabled={submitting}
            >
              Отклонить
            </UiButtonOutlined>
            <UiButtonFilled
              type="submit"
              className="carriers-verification-form__btn-confirm"
              mode="dark"
              w={200}
              onClick={() => form.change('action', 'approve')}
              isDisabled={submitting}
            >
              Принять
            </UiButtonFilled>
          </HStack>
        </form>
      )}
    />
  );
};
