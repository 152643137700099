import './load-payment-details.scss';
import { FC } from 'react';
import { HStack, VStack } from '@chakra-ui/react';
import { COLON, EMPTY_STRING } from '@/config/constants/common-strings';
import { CLIENT_PAYMENT, TOTAL_TO_PAY_WITH_VAT } from '@/components/order-details/order-details-payment/texts';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { toPriceFormat } from '@/helpers/price/to-price-format';
import { LoadTakenForCalculation } from '@/components/loads/taken-for-calculation/load-taken-for-calculation';
import { LoadPlacedText } from '@/components/loads/load-placed-text/load-placed-text';
import { Order } from '@/types/models/order';
import { orderExtender } from '@/features/order/extender/order-extender';

interface Props {
  load: Order;
}

export const CarrierLoadPaymentDetails: FC<Props> = ({ load }) => {
  const { createdFormatted } = useExtendedObject(load, orderExtender);
  const totalPriceWithVat = toPriceFormat(load?.carrierPayment?.priceWithVAT) || EMPTY_STRING;

  return (
    <div className="load-payment-details">
      <HStack className="load-payment-details__body" alignItems="flex-start">
        <VStack className="load-payment-details__info" alignItems="flex-start">
          <span className="load-payment-details__header">{CLIENT_PAYMENT}</span>
          <HStack className="load-payment-details__properties">
            <span className="load-payment-details__label">
              {TOTAL_TO_PAY_WITH_VAT}
              {COLON}
            </span>
            <div className="load-payment-details__values">
              <div className="load-payment-details__value">{totalPriceWithVat}</div>
            </div>
          </HStack>
        </VStack>
        <VStack className="load-payment-details__status" alignItems="flex-end">
          <LoadPlacedText title={createdFormatted} />
          <LoadTakenForCalculation isLoadInCalculation={Boolean(load?.status)} />
        </VStack>
      </HStack>
    </div>
  );
};
