import '../verification-form.scss';
import { FC, memo } from 'react';
import { Grid, GridItem } from '@chakra-ui/react';
import { FormMode, VerificationProps } from 'components/verification-forms/types';
import { DriverHeaderSubform } from 'components/verification-forms/driver-verification/subforms/driver-header-subform';
import { DriverCarriersSubform } from 'components/verification-forms/driver-verification/subforms/driver-carriers-subform';
import { DriverButtonsSubform } from 'components/verification-forms/driver-verification/subforms/driver-buttons-subform';
import { DriverSecuritySubform } from 'components/verification-forms/driver-verification/subforms/driver-security-subform';
import { DriverContactFields } from 'components/verification-forms/driver-verification/subforms/driver-contact-fields';
import { DriverPassportSubform } from 'components/verification-forms/driver-verification/subforms/driver-passport-subform';
import { DriverLicenseSubform } from 'components/verification-forms/driver-verification/subforms/driver-license-subform';
import { DriverDocumentsSubform } from 'components/verification-forms/driver-verification/subforms/driver-documents-subform';
import { DriverNameSubform } from 'components/verification-forms/driver-verification/subforms/driver-name-subform';
import { useDriverVerificationForm } from 'components/verification-forms/hooks/use-driver-verification-form';
import { DriverVerificationForm as DriverVerificationFormFields } from '@/types/models/driver-verification';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { DriverSecurityFieldsSubform } from './subforms/driver-security-fields-subform';

type Props = VerificationProps<DriverVerificationFormFields, DriverVerificationFormFields> & { closeModal?: VoidFunction };

const DriverForm: FC<Props> = ({ driver, mode = FormMode.create, onClose, onCancelCreation, initialValues = {}, closeModal }) => {
  const {
    Form,
    passportForm,
    validateWithoutPassport,
    validate,
    submitHandler,
    isCheckMode,
    setPassportForm,
    submitting,
    comment,
    setComment,
    calendarRef2,
    calendarRef3,
    calendarRef1,
  } = useDriverVerificationForm({
    closeModal,
    mode,
    driver,
    onClose,
  });

  return (
    <Form onSubmit={submitHandler(mode)} initialValues={initialValues} validate={passportForm ? validate : validateWithoutPassport}>
      <DriverHeaderSubform mode={mode} onClose={onClose!} driverName={driver?.name!} />

      {!isCheckMode && <DriverCarriersSubform />}

      <div className="carriers-verification-form__body">
        <UiScrollbars autoHide>
          <div className="carriers-verification-form__main">
            <Grid templateColumns="repeat(2, 1fr)" gap={4} className="carriers-verification-form">
              {isCheckMode && <DriverSecuritySubform mode={mode} initialValues={initialValues} />}
              <DriverContactFields mode={mode} initialValues={initialValues} />
              <GridItem colSpan={2}>
                <DriverNameSubform isDisabled={isCheckMode} />
              </GridItem>
              <GridItem colSpan={2}>
                <DriverPassportSubform
                  mode={mode}
                  initialValues={initialValues}
                  passportForm={passportForm}
                  setPassportForm={setPassportForm}
                  datePickerRef={calendarRef1}
                  datePickerSecondRef={calendarRef2}
                />
              </GridItem>
              <DriverLicenseSubform datePickerRef={calendarRef3} />
            </Grid>

            {isCheckMode && <DriverSecurityFieldsSubform comment={comment} setComment={setComment} />}

            <DriverDocumentsSubform mode={mode} driverId={driver?.id!} />
          </div>
        </UiScrollbars>
        <DriverButtonsSubform mode={mode} onClick={isCheckMode ? onClose : onCancelCreation} submitting={submitting} />
      </div>
    </Form>
  );
};

export const DriverVerificationForm = memo(DriverForm);
