export const formatDate = (date: Date | string | number): string => {
  if (typeof date === 'string') return date;
  if (typeof date === 'number') {
    if (date < 0) return '';
  }
  const dateFormat = new Date(date);

  const day = String(dateFormat.getDate()).padStart(2, '0');
  const month = String(dateFormat.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1
  const year = String(dateFormat.getFullYear());

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};
