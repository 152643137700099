import { ControlProps, PlaceholderProps } from 'chakra-react-select';

export const getBorderColor = ({ isFocused, isDisabled, selectProps }: ControlProps) => {
  const { isInvalid } = selectProps;
  switch (true) {
    case isFocused:
      return 'products.200';
    case isDisabled:
      return 'neutrals.500';
    case isInvalid:
      return 'accentRed.100';
    default:
      return 'neutrals.250';
  }
};

export const getPlaceholderColor = ({ isDisabled, selectProps }: PlaceholderProps) => {
  const { isInvalid } = selectProps;
  switch (true) {
    case isDisabled:
      return 'typography.150';
    case isInvalid:
      return 'neutrals.50';
    default:
      return 'neutrals.250';
  }
};
