import { FC } from 'react';
import { Box, Heading, HStack, Text } from '@chakra-ui/react';
import { FormApi } from 'final-form';
import { formatInitialServiceWidget } from 'components/edit-service-widget/format-initial-service-widget';
import { validateForm } from '@/helpers/forms/validate';
import { AddServiceFormFields } from '@/views/add-service-widget/type';
import { additionalServiceSchema } from '@/helpers/forms/validation-schemas/additional-service-schema';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { UiButtonFilled, UiButtonOutlined, UiInputField, UiNumberPickerField, UiSelectField, UiSwitchField, UiTextareaField } from '@/ui-elements';
import { OrderService } from '@/types/models/order-service';
import { Order } from '@/types/models/order';
import { useForm } from '@/helpers/forms/use-form';
import { UiFileUploadFieldWidget } from '@/ui-elements/form-fields/ui-file-upload-field/ui-file-upload-field-widget';
import { Load } from '@/types/models/loads';

type Props = {
  service: OrderService;
  order: Order | Load;
  companyName: string | undefined;
  onSubmit: (fields: AddServiceFormFields, form: FormApi<AddServiceFormFields>) => void;
  onCancelClick: () => void;
};
export const EditServiceWidget: FC<Props> = ({ service, order, onSubmit, onCancelClick, companyName }) => {
  const validate = validateForm<AddServiceFormFields>(additionalServiceSchema);

  const initialValues = formatInitialServiceWidget(service);
  const Form = useForm<AddServiceFormFields>();

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues} validate={validate}>
      <UiScrollbars
        style={{
          width: '100%',
          height: '100vh',
        }}
      >
        <div className="add-service-widget">
          <HStack className="add-service-widget__paragraph">
            <Text>{order.customer.name}</Text>
            <Text className="add-service-widget__title">{order.id}</Text>
          </HStack>
          <Box>
            <HStack className="add-service-widget__default-selectors" gap="24px">
              <UiSelectField name="customerCompany" label="Клиент" options={[]} isDisabled defaultInputValue={order.customer.name} />
              <UiSelectField name="carrier" label="Исполнитель" options={[]} isDisabled defaultInputValue={companyName ?? 'Не назначен'} />
            </HStack>
            <UiInputField
              name="name"
              label="Наименование услуги"
              content="Что нужно сделать исполнителю дополнительно"
              placeholder="Введите текст"
              defaultValue={service.name}
            />
            <UiTextareaField
              name="comment"
              label="Комментарий"
              content="Дополнительное описание для исполнителя"
              placeholder="Введите текст"
              value={service.comment}
            />
          </Box>
          <Box>
            <Heading>Стоимость услуги для клиента</Heading>
            <HStack gap="24px">
              <UiNumberPickerField
                name="customerPrice"
                label="Цена за единицу"
                placeholder="Назначьте цену"
                content="Впишите цену услуги"
                required
                hideStepper
              />
              <UiSwitchField name="customerVat" label="НДС" />
            </HStack>
            <Heading>Стоимость услуги для перевозчика</Heading>
            <HStack gap="24px">
              <UiNumberPickerField
                name="carrierPrice"
                label="Цена за единицу"
                placeholder="Назначьте цену"
                content="Впишите цену услуги"
                required
                hideStepper
              />
              <UiSwitchField name="carrierVat" label="НДС" />
            </HStack>
            <UiNumberPickerField
              className="add-service-widget__amount"
              name="amount"
              label="Количество"
              placeholder="0"
              defaultValue={service.amount}
            />
          </Box>

          <Box className="add-service-widget__upload">
            <Heading>Прикрепленные файлы</Heading>
            <UiFileUploadFieldWidget withDownloadButton name="file-upload" />
          </Box>
        </div>
      </UiScrollbars>
      <HStack justify="flex-end" gap="16px" className="add-service-widget__btn-group">
        <UiButtonOutlined className="add-service-widget__cancel" mode="dark" onClick={onCancelClick}>
          Отменить
        </UiButtonOutlined>
        <UiButtonFilled className="add-service-widget__submit" type="submit" mode="dark">
          Изменить
        </UiButtonFilled>
      </HStack>
    </Form>
  );
};
