import { Box, Grid, GridItem } from '@chakra-ui/react';
import { CarriersLoadsFiltersFieldLabel, CarriersLoadsFiltersHelpText, PLACEHOLDER_FORM_ITEM } from '@/config/constants/carriers-loads';
import { CarriersLoadsFiltersField } from '@/types/models/carriers-loads';
import { UiInputField } from '@/ui-elements';
import './carriers-loads-filters.scss';

export const CarriersLoadsFilters = () => {
  return (
    <Box className="carriers-loads-filters">
      <Grid
        className="carriers-loads-filters__container"
        alignContent="center"
        justifyContent="center"
        templateColumns="repeat(auto-fit, minmax(250px, 1fr))"
        width="100vw"
        gap={2}
      >
        <GridItem className="carriers-loads-filters__direction">
          <UiInputField
            name={CarriersLoadsFiltersField.From}
            label={CarriersLoadsFiltersFieldLabel.From}
            placeholder={CarriersLoadsFiltersHelpText.From}
            _placeholder={PLACEHOLDER_FORM_ITEM}
          />
        </GridItem>
        <GridItem className="carriers-loads-filters__direction">
          <UiInputField
            name={CarriersLoadsFiltersField.To}
            label={CarriersLoadsFiltersFieldLabel.To}
            placeholder={CarriersLoadsFiltersHelpText.To}
            _placeholder={PLACEHOLDER_FORM_ITEM}
          />
        </GridItem>
      </Grid>
    </Box>
  );
};
