import { FC } from 'react';
import { Box, Grid, GridItem, Heading } from '@chakra-ui/react';
import './carrier-filter-form.scss';
import { UiInputField, UiNumberPickerField, UiSelectField } from '@/ui-elements';
import { carrierStatuses, radiusSearch, riskGroup } from '@/components/search-carrier/search-carrier-filter/constants';
import { CONTENT_TYPE_TS } from '../search-carrier/search-carrier-filter/texts';
import { UiVehicleTypesPickerField } from '@/ui-elements/form-fields/ui-vehicle-type-picker-field/ui-vehicle-type-picker-field';

export const CarrierFilterForm: FC = () => {
  return (
    <Box className="carrier-filter__form">
      <Heading className="carrier-filter__header-title">Поиск перевозчиков</Heading>
      <Grid templateColumns="repeat(3, 1fr)" gap="16px">
        <GridItem colSpan={3}>
          {/* <VehicleTypeSelectorField
            isMulti
            className="carrier-filter__vehicle"
            name="vehicle"
            label="Тип ТС можно несколько"
            options={vehicleTypesSortingOptions}
            placeholder="Выберите из списка"
            content={CONTENT_TYPE_TS}
          /> */}
          <UiVehicleTypesPickerField label="Тип ТС можно несколько" name="vehicle" content={CONTENT_TYPE_TS} />
        </GridItem>
        <GridItem>
          <UiNumberPickerField name="weight" label="Вес груза в тоннах" />
        </GridItem>
        <GridItem>
          <UiNumberPickerField name="volume" label="Объем м³" />
        </GridItem>
        <GridItem>
          <UiSelectField name="risk-group" label="Группа риска" options={riskGroup} placeholder={1} overflown />
        </GridItem>
        <GridItem>
          <UiInputField name="start" label="Откуда (город, населенный пункт)" />
        </GridItem>
        <GridItem>
          <UiSelectField name="radius" label="Радиус поиска от 1 точки" options={radiusSearch} placeholder="0 км" overflown />
        </GridItem>
        <GridItem>
          <UiSelectField name="status" label="Статус перевозчика" options={carrierStatuses} placeholder="Выберите статус перевозчика" overflown />
        </GridItem>
      </Grid>
    </Box>
  );
};
