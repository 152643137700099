import { ORDER_STATUS_SCHEME } from 'config/constants/order-status-colors';
import { Order } from '@/types/models/order';

type Props = {
  status: Order['status'];
};

export const getCarrierStatusesStyles = ({ status }: Props) => {
  return ORDER_STATUS_SCHEME?.[status] || null;
};
