export enum OrderDetailsTabs {
  DetailsClient = 'DetailsClient',
  DetailsCarrier = 'DetailsCarrier',
}
export const tabNames: Record<string, string> = {
  [OrderDetailsTabs.DetailsClient]: 'Заказ Клиента',
  [OrderDetailsTabs.DetailsCarrier]: 'Заказ Исполнителя',
};

export const scrollStyle = { width: '100%', height: '100%' };
