import { FC } from 'react';
import { ModalView } from '@/views/order-modal/order-modal-view';
import { ModalsProps } from '@/types/common/modals';
import { useCarrierActions } from '@/features/carriers/hooks/use-carrier-actions';
import { useCarrierInvalidate } from '@/features/carriers/hooks/use-carrier-invalidate';

export const ModalDeleteCarrier: FC<
  ModalsProps<{
    text?: string;
    modalWidth?: number;
    marginRight?: number;
    submitButtonText?: string;
    cancelButtonText?: string;
    desc?: string;
    alignDesc?: 'center' | 'left' | 'right';
    accentXBtn?: boolean;
    carrierID?: number;
  }>
> = ({ modalOpened, onClose, desc, alignDesc, carrierID }) => {
  const { deleteCarrier } = useCarrierActions(carrierID!);
  const { allCarriersInvalidate } = useCarrierInvalidate();
  const onSubmit = () => {
    deleteCarrier.mutate(carrierID, {
      onSuccess: () => {
        allCarriersInvalidate(() => {
          onClose();
        });
      },
    });
  };

  return (
    <ModalView
      isOpen={modalOpened}
      onClose={onClose}
      title="Вы действительно хотите удалить организацию?"
      onSubmit={onSubmit}
      submitButtonText="Удалить организацию"
      desc={desc}
      alignDesc={alignDesc}
      modalWidth={524}
      justifyTitle="left"
    />
  );
};
