import { useCallback, useEffect, useMemo, useRef } from 'react';
import { FuelStatus, OrderExecutionStatus, OrderStatus } from 'config/constants/statuses';
import { toast } from 'react-toastify';
import { useOrderDetailsView } from '@/features/order/hooks/use-order-details-view';
import { useAppSelector } from '@/hooks/use-app-selector';
import { accountUserRoleSelector } from '@/features/account/selectors';
import { useOrderSidebar } from '@/features/order/hooks/use-order-sidebar';
import { useAccount } from '@/features/account/hooks/use-account';
import { useWidgetActions } from '@/features/widget/hooks/use-widget-actions';
import { WidgetContainers } from '@/features/widget/widget-containers';
import { useOrderActions } from '@/features/order/hooks/use-order-actions';
import { useOrderDataActions } from '@/features/order/hooks/use-order-data-actions';
import { useOrderFuelStatus } from '@/features/order/hooks/use-order-fuel-status';
import { ModalAction, useModal } from '@/hooks/use-modal';
import { ModalType } from '@/types/common/modals';
import { cancelWarningTexts } from '@/features/order/texts';
import { Order } from '@/types/models/order';
import { OrderOffer } from '@/types/models/order-offer';
import { UserRole } from '@/types/api/user-role';
import { OrderSidebarMode } from '@/types/sidebar/order-sidebar-mode';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { getOrder } from '@/features/order/actions/get-order';
import { Load } from '@/types/models/loads';
import { useChooseOwnDriverVehicleModal } from '@/features/order/hooks/use-choose-own-driver-vehicle-modal';

const DISABLE_SEARCH_STATUS: Array<OrderStatus> = [OrderExecutionStatus.CarrierSearch, OrderExecutionStatus.New];
export const useOrderDetails = (isFuel = false) => {
  const {
    order,
    isOrderLoading,
    orderOffers,
    shipmentRequests,
    selectedOffer,
    createApproval,
    activeOrdersArray,
    activeOrderId,
    cancelOrder,
    setNewOrderStatus,
    isEmptyOrderList,
  } = useOrderDetailsView();

  const userRole = useAppSelector(accountUserRoleSelector);
  const dispatch = useAppDispatch();

  const { mode } = useOrderSidebar();
  const { currentAccount } = useAccount();

  const ref = useRef(null);

  const { SetCarrierWidget, CarrierWidget } = useWidgetActions(WidgetContainers.OrderDetails);

  const { searchForCarrier, unselectAll, update } = useOrderActions();

  const { mergeOrder } = useOrderDataActions(activeOrderId!);
  const { setNewOrderFuelStatus } = useOrderFuelStatus(order);

  const { modalView: ConfirmModal, setModalOpened: showConfirm } = useModal<{ text: string } & ModalAction>(ModalType.Confirm, {
    text: cancelWarningTexts[order?.status || OrderExecutionStatus.New] || 'Заявка будет отменена',
    onSuccess: () => cancelOrder(order?.status, () => searchForCarrier(undefined)),
  });

  const { modalView: ReviewModal, setModalOpened: showReviewModal } = useModal<
    { orderId?: number; hasBlacklist: boolean; driverId?: number } & ModalAction
  >(ModalType.Review, {
    orderId: order?.id,
    driverId: selectedOffer?.driver?.id,
    hasBlacklist: true,
  });

  const { modalView: ModalSplit, setModalOpened: showSplit } = useModal<{ order?: Order | Load } & ModalAction>(ModalType.Split, {
    order,
  });

  const { modalView: ModalDeleteOrder, setModalOpened: showDeleteOrder } = useModal<{ text: string } & ModalAction>(ModalType.DeleteOrder, {
    text: 'Заявка будет удалена',
  });

  const { modalView: ModalCloneOrder, setModalOpened: showCloneOrder } = useModal<{ text: string } & ModalAction>(ModalType.CloneOrder, {
    text: 'Скопировать выделенную заявку?',
  });

  const showSetCarrierWidget = (orderOffer: Pick<OrderOffer, 'carrier' | 'driver' | 'vehicle'>, offerId?: number) => {
    SetCarrierWidget.show({
      orderId: activeOrderId || 1,
      orderOffer,
      offerId,
      title: order?.number || `Рейс ${order?.id}`,
    });
  };

  const updateOrderFuelStatus = (status?: FuelStatus) => {
    setNewOrderFuelStatus(status);
  };

  const onCancelMultipleSelect = () => {
    unselectAll();
  };

  const onCancelOrder = () => {
    showConfirm(true);
  };

  const onJoinOrders = () => {
    mergeOrder.mutate({ orderIds: activeOrdersArray });
  };

  const onCarrierSearchClick = () => {
    if (order) {
      // открывает сайдбар
      searchForCarrier(order);

      // устанавливаем новый статус, если у заказа статус Новый
      setNewOrderStatus(OrderExecutionStatus.CarrierSearch);
    }
  };

  const { onChooseOwnDriverAndVehicle, ModalChooseOwnDriverVehicle } = useChooseOwnDriverVehicleModal({ order });

  const onStartCarrierSearch = () => {
    if (order?.status === OrderExecutionStatus.New) {
      setNewOrderStatus(OrderExecutionStatus.CarrierSearch);
    }
  };

  const onSplit = () => {
    if (order) showSplit(true);
  };

  // подтвердить исполнение
  const onConfirmOrder = () => {
    setNewOrderStatus(OrderExecutionStatus.CompletedWithoutDocs, () => showReviewModal(true));
  };
  // подтвердить, что оригиналы получены
  const onConfirmOriginals = () => {
    setNewOrderStatus(OrderExecutionStatus.CompletedWithDocs);
  };

  const handleOrderStart = () => {
    setNewOrderStatus(OrderExecutionStatus.Executing);
  };
  const handleDeleteOrder = () => {
    showDeleteOrder(true);
  };

  const handleUpdateOrder = useCallback(() => {
    if (order) dispatch(getOrder(order.id));
    update();
  }, [update, dispatch, order]);

  const onSendToCheck = (offer: Pick<OrderOffer, 'carrier' | 'driver' | 'vehicle'>) => {
    createApproval.mutate(
      {
        carrierId: offer.carrier.id,
        driverId: offer.driver.id,
        vehicleId: offer.vehicle.id,
        departmentId: currentAccount?.user?.department.id,
      },
      {
        onSuccess: () => {
          toast.success('Отправлено');
        },
      }
    );
  };

  useEffect(() => {
    if (order?.confirmedCarrierDocs && order?.confirmedCustomerDocs && order?.status === OrderExecutionStatus.Preparation) {
      setNewOrderStatus(OrderExecutionStatus.Confirmed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.confirmedCarrierDocs, order?.confirmedCustomerDocs]);

  const onCloneOrder = () => {
    showCloneOrder(true);
  };

  const onMoreInformation = (id: number) => {
    CarrierWidget.show({ carrierId: id });
  };

  const orderIsClosed =
    order?.status === OrderExecutionStatus.CompletedWithoutDocs ||
    order?.status === OrderExecutionStatus.CompletedWithDocs ||
    order?.status === OrderExecutionStatus.CompletedPayed;

  const isLogist = userRole === UserRole.Administrator || userRole === UserRole.Manager;

  const disableSearch = !order?.status || mode === OrderSidebarMode.Search || !DISABLE_SEARCH_STATUS.includes(order?.status);

  const visibleRequests = useMemo(() => {
    if (order?.status !== OrderExecutionStatus.CarrierSearch) return [];
    if (shipmentRequests?.isError) return [];
    return (shipmentRequests.data?.data || []).filter((x) => !x.accepted) || [];
  }, [order?.status, shipmentRequests.data?.data, shipmentRequests.isError]);

  const fuelReadOnly = isFuel && (userRole === UserRole.Manager || userRole === UserRole.Booker);
  return {
    ref,
    showSetCarrierWidget,
    orderOffers,
    visibleRequests,
    selectedOffer,
    activeOrdersArray,
    order,
    onConfirmOrder,
    onCarrierSearchClick,
    onChooseOwnDriverAndVehicle,
    onStartCarrierSearch,
    onSplit,
    onCancelMultipleSelect,
    onJoinOrders,
    updateOrderFuelStatus,
    onCancelOrder,
    disableSearch,
    isOrderLoading,
    handleUpdateOrder,
    onSendToCheck,
    onMoreInformation,
    handleDeleteOrder,
    orderIsClosed,
    isLogist,
    handleOrderStart,
    onConfirmOriginals,
    onCloneOrder,
    isEmptyOrderList,
    fuelReadOnly,
    ConfirmModal,
    ModalSplit,
    ModalDeleteOrder,
    ReviewModal,
    ModalCloneOrder,
    ModalChooseOwnDriverVehicle,
  };
};
