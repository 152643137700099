import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MutationKeys, QueryKeys } from 'config/constants/query-keys';
import { NotificationsApi } from '@/api/notifications';

export const useNotificationMarked = () => {
  const queryClient = useQueryClient();
  const setNotificationMarked = useMutation({
    mutationKey: [MutationKeys.setNotificationMarked],
    mutationFn: (id: number) => NotificationsApi.setNotificationMarked(id),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.notifications]);
      queryClient.invalidateQueries([QueryKeys.notificationsCounter]);
    },
  });

  const setAllNotificationsMarked = useMutation({
    mutationKey: [MutationKeys.setAllNotificationMarked],
    mutationFn: () => NotificationsApi.setAllNotificationsMarked(),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.notifications]);
      queryClient.invalidateQueries([QueryKeys.notificationsCounter]);
    },
  });
  return { setNotificationMarked, setAllNotificationsMarked };
};
