import { FC } from 'react';
import { VStack, Box } from '@chakra-ui/react';
import { UiDivider } from '@/ui-elements';
import { CLIENT_CONTRACT_TEMPLATE_POINT_DETAILS, CLIENT_CONTRACT_FORM_UI_ELEMENTS } from '@/features/contract-template/constants/form';

type DocumentTemplateContractSubformProps = {
  routesAmount?: number;
  disabled: boolean;
};

export const DocumentTemplateContractSubform: FC<DocumentTemplateContractSubformProps> = ({ routesAmount = 0, disabled }) => {
  return (
    <Box>
      {Array.from({ length: routesAmount }).map((_, routeInd) => (
        <VStack>
          {CLIENT_CONTRACT_TEMPLATE_POINT_DETAILS.map((field) => {
            const { Component, defaultProps } = CLIENT_CONTRACT_FORM_UI_ELEMENTS[field];
            const fieldName = `routeDetails[${routeInd}].${defaultProps?.name}`;
            return <Component {...defaultProps} name={fieldName} disabled={disabled} key={fieldName} />;
          })}
          <UiDivider />
        </VStack>
      ))}
    </Box>
  );
};
