import { SetFormValues } from 'components/settings/settings-details-empty/schema';
import { SettingsBase } from '@/types/api/settings-partial';

export const settingsToFormValues = (organization: Record<number, string>, settings: SettingsBase): SetFormValues => {
  return {
    templatesForCarrier: settings?.templatesForCarrier,
    templatesForCustomers: settings?.templatesForCustomers,
    organizationForCarrier: {
      value: settings.organizationForCarrier,
      label: settings.organizationForCarrier ? organization?.[settings.organizationForCarrier] : undefined,
    },
    organizationForCustomers: {
      value: settings.organizationForCustomers,
      label: settings.organizationForCustomers ? organization?.[settings.organizationForCustomers] : undefined,
    },
  };
};
