import { FC, useState } from 'react';
import { Box, Center } from '@chakra-ui/react';
import { useField } from 'react-final-form';
import { MINUS, PLUS, SPACE } from 'config/constants/common-strings';
import { UiSlider, UiSliderProps } from '@/ui-elements/slider/slider';
import { UiText } from '@/ui-elements/ui-text/ui-text';
import { toPriceFormat } from '@/helpers/price/to-price-format';

export const UiSliderField: FC<
  UiSliderProps & {
    name: string; // поле field
    defaultValue: number; // центр слайдера
    width?: number;
    onChange?: (val: number) => void;
    sliderName: string;
    counterName: string;
    counterValue: number;
    positive?: boolean; // + или - для counter
  }
> = ({ name, defaultValue = 0, width = 300, onChange, sliderName, counterName, counterValue, positive, ...sliderProps }) => {
  const [value, setValue] = useState<number>(defaultValue);
  const { input } = useField(name);
  const onInputChange = (val: number) => {
    setValue(val);
    onChange?.(val);
    input.onChange(val);
  };

  return (
    <Box>
      <Box>
        <UiText color="typography.300" mt={1}>
          {sliderName}
        </UiText>
        <UiText color="typography.200" mt={1}>
          {counterName}
          {SPACE}
          <UiText color={positive ? 'green.500' : 'red.500'} display="inline">
            {positive ? PLUS : MINUS}
            {toPriceFormat(counterValue)}
          </UiText>
        </UiText>
      </Box>
      <Box w={width}>
        <Center my={2}>
          <UiText color="blue.500">
            {PLUS}
            {toPriceFormat(value)}
          </UiText>
        </Center>
        <UiSlider {...sliderProps} defaultValue={defaultValue} {...input} onChange={onInputChange} />
      </Box>
    </Box>
  );
};
