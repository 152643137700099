import { WidgetContainer } from 'components/widget-container/widget-container';
import { useRef } from 'react';
import { useAppSelector } from '@/hooks/use-app-selector';
import { CarrierInfo } from '@/components';
import { selectedCarrierIdSelector } from '@/features/carriers/selectors';
import { useWidgetActions } from '@/features/widget/hooks/use-widget-actions';
import { WidgetContainers } from '@/features/widget/widget-containers';
import { UISidebar } from '@/ui-elements/ui-sidebar/ui-sidebar';
import './carrier-details-view.scss';
import { ModalAction, useModal } from '@/hooks/use-modal';
import { ModalType } from '@/types/common/modals';
import { Vehicle } from '@/types/models/vehicle';
import { useAllOrdersUpdates } from '@/features/order/hooks/use-all-orders-updates';
import { useCarrierUpdates } from '@/features/carriers/hooks/use-carrier-updates';
import { useCarrierVehiclesUpdates } from '@/features/carriers/hooks/use-carrier-vehicles-updates';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';

export const CarrierDetailsView = () => {
  const id = useAppSelector(selectedCarrierIdSelector);
  const ref = useRef<HTMLDivElement>(null);
  const {
    carrier: { data: carrier, isInitialLoading },
  } = useCarrierUpdates(id);
  const { CarrierReviewsWidget, TripWidget, DriverWidget, VehicleWidget } = useWidgetActions(WidgetContainers.CarrierDetails);
  const { carrierVehicles } = useCarrierVehiclesUpdates(id);
  const filter = id ? { carrierId: id } : {};
  const { data: orders, fetchNextPage, isFetchingNextPage } = useAllOrdersUpdates({ query: filter });
  const allRecords = orders?.pages ? orders.pages.flatMap((d) => d.data).filter((d) => d !== undefined) : [];
  const { modalView: ModalEditCarrier, setModalOpened: showEditCarrierModal } = useModal<{ carrierId?: number } & ModalAction>(
    ModalType.EditCarrier,
    {
      carrierId: id,
    }
  );
  const { modalView: ModalDeleteCarrier, setModalOpened: showDeleteCarrier } = useModal<{ text: string; carrierID?: number } & ModalAction>(
    ModalType.DeleteCarrier,
    {
      text: 'Организация будет удалена',
      carrierID: id,
    }
  );
  const handleDeleteCarrier = () => {
    showDeleteCarrier(true);
  };
  const showCarrierReviews = (carrierId: number) => {
    CarrierReviewsWidget.show({ carrierId });
  };
  const showTrip = (orderId?: number) => {
    TripWidget.show({
      orderId,
      carrierId: id,
    });
  };
  const showDriver = (driverId: number) => {
    DriverWidget.show({ id: driverId });
  };
  const onVehicleMoreInfoClick = (vehicle: Vehicle) => {
    VehicleWidget.show({ vehicle });
  };

  const onEditCarrierClick = () => {
    showEditCarrierModal(true);
  };

  return (
    <UISidebar className="carrier-details-view__sidebar" isOpen ref={ref}>
      {isInitialLoading ? (
        <UiSpinner visible mt={358} />
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {carrier && carrier.data ? (
            <CarrierInfo
              carrier={carrier.data}
              showCarriersReviews={showCarrierReviews}
              showTrip={showTrip}
              showDriver={showDriver}
              vehicles={carrierVehicles.data?.data}
              onEditCarrierClick={onEditCarrierClick}
              onVehicleMoreInfoClick={onVehicleMoreInfoClick}
              handleDeleteCarrier={handleDeleteCarrier}
              orders={allRecords}
              loadMore={fetchNextPage}
              loading={isFetchingNextPage}
            />
          ) : null}
        </>
      )}
      <WidgetContainer parentRef={ref} containerKey={WidgetContainers.CarrierDetails} />
      {ModalEditCarrier}
      {ModalDeleteCarrier}
    </UISidebar>
  );
};
