import { ReactNode } from 'react';
import { Text, HStack, StackProps } from '@chakra-ui/react';

type Props = {
  text: string;
  value?: string | number;
  color?: string;
  textColor?: string;
  icon?: ReactNode;
} & StackProps;

export const InfoLine = ({ text, value, color = 'products.200', textColor = 'typography.50', icon, ...props }: Props) => (
  <HStack {...props}>
    <Text textStyle="h5" textColor={textColor}>
      {text}
    </Text>
    {!icon ? (
      <Text textColor={color} textStyle="h6">
        {typeof value === 'string' ? value : value?.toString()}
      </Text>
    ) : (
      icon
    )}
  </HStack>
);
