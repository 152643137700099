import { VehicleType } from 'config/constants/vehicle';
import { FuelStatus, OrderCompletedSwitcherStatus, OrderStatus, OrderSwitcherStatus } from 'config/constants/statuses';
import { SortNPageFilter } from '@/types/common/sort-page-filter';
import { FunnelLoadStatus } from '../models/funnel';

export type Contact = {
  name: string;
  phone: string;
};

export type OrderFilter = {
  search?: string;
  status?: OrderSwitcherStatus | OrderCompletedSwitcherStatus | string;
  fuelStatus?: FuelStatus | string;
  dateFrom?: string;
  dateTo?: string;
  vehicleTypes?: Array<VehicleType>;
  carrierId?: number;
  fromSource?: OrderCreateSource;
  updates?: boolean;
} & SortNPageFilter;

export type OrderWithMyParticipationFilter = Partial<{
  accepted: 0 | 1;
}> &
  OrderFilter;

export type OrderNew = {
  customerID?: number | string;
  contact: any;
  transportationType?: 'ftl' | 'ltl';
  loadingType?: any;
  riskGroup?: any;
  type: any;
  points: Array<any>;
  cargos: Array<any>;
  actions: Array<any>;
  additionalRequirements?: Object;
  responsiblePerson?: { id: number | string; name?: number | string };
  confirmedCustomerDocs?: boolean;
  confirmedCarrierDocs?: boolean;
  customerPayment: {
    price: any;
    vat: any;
    paymentConditions: string | undefined;
  };
  carrierPayment: {
    priceWithoutVAT: number;
    priceWithVAT: number;
    paymentConditions: string | undefined;
    priceOffer?: boolean;
  };
  carrierOrigins?: {
    trackNumber?: string;
    confirmed?: boolean;
  };
  fromSource: OrderCreateSource;
  vehicleTypes: any;
  requiredVehicleCount?: number;
  // paymentConditions: string;
  prepayment: {
    possibility: boolean;
    fuelPossibility: boolean;
  };
  expiration?: string;
  fuelStatus?: string;
  permissions?: {
    TIR?: boolean;
    CMR?: boolean;
    T1?: boolean;
    medicalBook?: boolean;
  };
};

export type OrderSetStatus = {
  status?: OrderStatus | FunnelLoadStatus;
  fuelStatus?: FuelStatus;
  fromSource?: OrderCreateSource;
};

export type OrderCount = {
  new: number;
  carrierSearch: number;
  executing: number;
  preparation: number;
  confirmed: number;
  failed: number;
  all: number;
  completedPayed: number;
  completedWithDocs: number;
  completedWithoutDocs: number;
};

export type ResponseFuelCount = {
  alias: FuelStatus;
  count: string;
  id: number;
};

export type OrderFuelCount = Array<ResponseFuelCount>;

export type OrderMerge = {
  orderIds: Array<number>;
};

export type OrderReview = {
  review: string;
  ratingValue?: number;
  driverId: number;
  blacklist?: boolean;
};

/** Откуда была создана заявка */
export enum OrderCreateSource {
  ILSExecution = 'ilsExecution',
  ILSConfirmation = 'ilsConfirmation',
  Outer = 'outer',
}
