import { useResourcesVehicleForm } from '@/features/resources/hooks';
import { ResourcesVehicleForm } from '@/components/resources/forms/vehicle/resources-vehicle-form';

export const ResourcesVehicleFormView = () => {
  const {
    initialValues,
    vehicleInitialDocs,
    mode,
    submitHandlers,
    closeForm,
    withoutTrailer,
    vehicleGroup,
    ownType,
    handleChangeVehicleType,
    handleChangeWithoutTrailer,
    handleChangeOwnType,
    validate,
  } = useResourcesVehicleForm();

  return (
    <ResourcesVehicleForm
      mode={mode}
      initialValues={initialValues}
      vehicleInitialDocs={vehicleInitialDocs}
      submitHandlers={submitHandlers}
      closeForm={closeForm}
      withoutTrailer={withoutTrailer}
      vehicleGroup={vehicleGroup}
      ownType={ownType}
      handleChangeVehicleType={handleChangeVehicleType}
      handleChangeWithoutTrailer={handleChangeWithoutTrailer}
      handleChangeOwnType={handleChangeOwnType}
      validate={validate}
    />
  );
};
