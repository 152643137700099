import { HStack, Text, VStack } from '@chakra-ui/react';
import { CarrierOrganization } from '@/types/models/my-organization/my-organization';
import { ResourcesStatusesList } from '@/components/resources-statuses-list/resources-statuses-list';

export const OrderClientInfoCarrierOrganizationDetails = ({ carrierOrganization }: { carrierOrganization?: CarrierOrganization }) => {
  if (!carrierOrganization) return null;
  return (
    <VStack alignItems="flex-start">
      <HStack>
        <Text className="order-details-client-offer-info__subtitle">Организация:</Text>
        <span className="order-details-client-offer-info__value">{carrierOrganization?.name}</span>
      </HStack>
      <ResourcesStatusesList approvalStatuses={carrierOrganization?.approvalStatuses} />
    </VStack>
  );
};
