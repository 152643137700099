import { Center, Divider, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { LoadingType } from 'config/constants/loading-types';
import classNames from 'classnames';
import { LoadingTypeComponent } from 'components/loading-type/loading-type';
import { useIcons } from '@/hooks/use-icons';
import './vehicle-general-info.scss';
import { UiTooltip } from '@/ui-elements/tooltip/tooltip';
import { useVehicleTypesNames } from '@/features/vehicle-types/hooks/use-vehicle-types-names';
import { useVehicleTypesIcons } from '@/features/vehicle-types/hooks/use-vehicle-types-icons';

type Props = {
  capacity?: number;
  volume?: number;
  vehicleTypes: Array<number>;
  loadingType?: Array<LoadingType>;
  className?: string;
};

export const VehicleGeneralInfo: FC<Props> = ({ capacity, volume = [], vehicleTypes, className, loadingType = [] }) => {
  const { VolumeIcon, MaxTonage } = useIcons();
  const isTooltip = vehicleTypes && vehicleTypes.length > 1;
  const vehicleTypesNames = useVehicleTypesNames();
  const vehicleTypesIcons = useVehicleTypesIcons();
  const vehicleIcon = vehicleTypesIcons[vehicleTypes?.[0]];

  return (
    <HStack className={classNames(className, 'vehicle-general-info')}>
      {isTooltip ? (
        <UiTooltip label={vehicleTypes.map((vehicleType) => vehicleTypesNames[vehicleType]).join(', ')}>
          <HStack>
            {vehicleTypes && vehicleIcon && <img width="48px" height="20px" src={vehicleTypes && vehicleIcon} alt="vehicle img" />}
            <Text color="products.200">Тип ТС</Text>
          </HStack>
        </UiTooltip>
      ) : (
        <>
          {vehicleTypes && vehicleIcon && <img width="48px" height="20px" src={vehicleTypes && vehicleIcon} alt="vehicle img" />}
          <Text>{vehicleTypesNames?.[vehicleTypes?.[0]]}</Text>
        </>
      )}
      <Center className="vehicle-general-info__divider-container">
        <Divider className="vehicle-general-info__divider" orientation="vertical" />
      </Center>
      <LoadingTypeComponent loadingType={loadingType} />

      {capacity && (
        <>
          <Center className="vehicle-general-info__divider-container">
            <Divider className="vehicle-general-info__divider" orientation="vertical" />
          </Center>
          <HStack className="vehicle-general-info__tonage">
            <MaxTonage />
            <Text>{`${capacity} т`}</Text>
          </HStack>
        </>
      )}
      {volume && (
        <>
          <Center className="vehicle-general-info__divider-container">
            <Divider className="vehicle-general-info__divider" orientation="vertical" />
          </Center>
          <HStack className="vehicle-general-info__volume">
            <VolumeIcon />
            <Text>{`${volume} м³`}</Text>
          </HStack>
        </>
      )}
    </HStack>
  );
};
