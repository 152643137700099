import { FC } from 'react';
import { GridItem, Text } from '@chakra-ui/react';
import { LICENSE_MASK } from 'components/auth-form/constants';
import { DateFormat } from 'config/constants/date';
import { UiMaskedField, UiMultiDatePickerField } from '@/ui-elements';

export const DriverLicenseSubform: FC<{ datePickerRef: any }> = ({ datePickerRef }) => {
  return (
    <>
      <GridItem colSpan={2}>
        <Text className="carriers-verification-form__subtitle" fontSize={18}>
          Водительское удостоверение
        </Text>
      </GridItem>
      <GridItem mt={-1}>
        <UiMaskedField mask={LICENSE_MASK} type="number" name="driverLicense" label="Серия/номер" placeholder="Серия/номер" required />
      </GridItem>
      <GridItem mt={-1}>
        <UiMultiDatePickerField
          name="driverLicenseDate"
          label="Дата выдачи"
          placeholder="Дата выдачи"
          required
          datePickerRef={datePickerRef}
          format={DateFormat.DDMMYYYYSlash}
        />
      </GridItem>
    </>
  );
};
