import { Box, Text, HStack, Center, Divider, Flex } from '@chakra-ui/react';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { useAppSelector } from '@/hooks/use-app-selector';
import { securityActiveApprovalSelector } from '@/features/security/selectors/security-active-approval-selector';
import { VerificationCard } from '@/components/verification-card/verification-card';
import { useIcons } from '@/hooks/use-icons';
import { securityActions } from '@/features/security/slice';
import { CarrierCardRegNumber as RegNumber } from '@/components/carrier-card/carrier-card-reg-number';
import { DriverRiskGroup } from '@/components/driver-risk-group/driver-risk-group';
import { formatMomentLocal } from '@/helpers/moment/format-moment-local';
import { approvalTimeLeft } from '@/views/carriers-verification/approval-time-left';
import { Approval } from '@/types/models/approval';
import { useVehicleTypesIcons } from '@/features/vehicle-types/hooks/use-vehicle-types-icons';
import { getApprovalStatus } from '@/helpers/get-approval-status';
import { VeificationSubjects } from '@/features/approvals/types/verification-subjects';
import { getApprovalElementStatus } from '@/features/approvals/helpers/get-approval-element-status';

type Props = {
  approval: Approval;
  setChecking: (arg0: boolean) => void;
};

export const VerificationCardBuilder = ({ approval, setChecking }: Props) => {
  const { driver, carrier, vehicle, created, id, user, customer } = approval;
  const dispatch = useAppDispatch();
  const activeApprovalId = useAppSelector(securityActiveApprovalSelector);
  const onCardSelect = () => {
    dispatch(securityActions.selectApproval({ approvalId: id }));
    setChecking(false);
  };
  const vehicleTypesIcons = useVehicleTypesIcons();
  let vehicleIcon;
  if (vehicle) {
    vehicleIcon = vehicle?.vehicleTypeId && vehicleTypesIcons[vehicle?.vehicleTypeId];
  }

  const { City, Profile, TrailerHead, TonageIcon, VolumeIcon } = useIcons();
  let icon;
  let checking;
  let subject;

  switch (true) {
    case Boolean(driver):
      icon = <Profile />;
      checking = 'Проверка водителя';
      subject = VeificationSubjects.Driver;
      break;
    case Boolean(vehicle):
      icon = (
        <div className="order-general-info__vehicle-icon">
          {vehicleIcon ? <img width="48px" height="20px" src={vehicleIcon} alt="vehicle" /> : <TrailerHead />}
        </div>
      );
      checking = 'Проверка транспортного средства';
      subject = VeificationSubjects.Vehicle;
      break;
    case Boolean(carrier):
      icon = <City />;
      checking = 'Проверка организации';
      subject = VeificationSubjects.Organization;
      break;
    case Boolean(customer):
      icon = <City />;
      checking = 'Проверка клиента';
      subject = VeificationSubjects.Customer;
      break;
    default:
      icon = undefined;
  }

  let subjectName;
  switch (subject) {
    case VeificationSubjects.Driver:
      subjectName = driver ? driver.name : '';
      break;
    case VeificationSubjects.Organization:
      subjectName = carrier ? carrier.name : '';
      break;
    case VeificationSubjects.Customer:
      subjectName = customer ? customer.name : '';
      break;
    default:
      subjectName = '';
  }
  const header = (
    <HStack spacing={0} w="400px">
      <Box mr="14px">{icon}</Box>
      {subject === 'vehicle' && (
        <Text textStyle="p4" pr={3}>
          {vehicle!.name}
        </Text>
      )}
      {subject === 'vehicle' ? (
        <Flex gap="3px">
          <RegNumber regNumber={vehicle?.regNumber} />
          {vehicle?.volume ? (
            <>
              <Center className="order-general-info__divider-container">
                <Divider className="order-general-info__divider" orientation="vertical" />
              </Center>
              <Flex>
                <VolumeIcon />
                <Text>{vehicle?.volume}м³</Text>
              </Flex>
            </>
          ) : null}
          {vehicle?.capacity ? (
            <>
              <Center className="order-general-info__divider-container">
                <Divider className="order-general-info__divider" orientation="vertical" />
              </Center>
              <Flex mr={10}>
                <TonageIcon />
                <Text>{vehicle?.capacity}т</Text>
              </Flex>
            </>
          ) : null}
        </Flex>
      ) : (
        <HStack spacing={3}>
          {subject === 'driver' && driver && <DriverRiskGroup riskGroup={driver.riskGroup} />}
          <Text textStyle="p4">{subjectName}</Text>
        </HStack>
      )}
      ;
    </HStack>
  );
  const timeLeft = approvalTimeLeft(created);
  const meta = (user?.userRole || []).some((r) => ['administrator', 'manager', 'security'].includes(r))
    ? `Добавил: ${user?.name || ''}`
    : 'Запрос от водителя';
  const approvalHistory = getApprovalStatus(approval);
  const approvalElementStatus = getApprovalElementStatus(approval, subject);

  return (
    <VerificationCard
      subject={header}
      timeLeft={timeLeft}
      start={formatMomentLocal(created, 'DD.MM.YYYY')}
      area=""
      meta={meta}
      currentStatus={approvalHistory}
      status={approvalElementStatus}
      onCardSelect={onCardSelect}
      isSelected={activeApprovalId === id}
      checking={checking}
    />
  );
};
