import { Box, HStack, VStack, Badge, Text, BoxProps } from '@chakra-ui/react';
import classNames from 'classnames';
import last from 'lodash/last';
import './file-upload-card.scss';
import { IconButtonDowWidget } from '@/ui-elements/icon-button/icon-button-dow-widget';
import { IconButtonDeleteWidget } from '@/ui-elements/icon-button/icon-button-delete-widget';

export type Extension = 'jpg' | 'jpeg' | 'pdf' | 'docx' | 'xls' | 'doc' | 'png' | undefined;

type Props = {
  fileName: string;
  onIconClick?: VoidFunction;
  onDeleteClick?: ((id: string | number) => void) | VoidFunction;
  hideDeleteButton?: boolean;
  icon?: string;
  className?: string;
  extension?: Extension;
} & BoxProps;

export const UiFileUploadCardWidget = ({ fileName, onIconClick, onDeleteClick, hideDeleteButton = false, className, extension, icon }: Props) => {
  const fileExtension = extension ?? (last(fileName.split('.')) as Extension);

  const getBadge = (ext: Extension) => {
    switch (ext) {
      case 'jpeg':
      case 'jpg':
      case 'doc':
      case 'png':
      case 'docx':
        return <Badge colorScheme="twitter">{ext.toUpperCase()}</Badge>;
      case 'pdf':
        return <Badge colorScheme="red">{ext.toUpperCase()}</Badge>;
      case 'xls':
        return <Badge colorScheme="green">{ext.toUpperCase()}</Badge>;
      default:
        return null;
    }
  };
  return (
    <Box className={classNames('file-upload-card', className)}>
      <HStack align="top" justify="start" w="full" spacing={0}>
        <VStack align="start" className="file-upload-card__content" w="full">
          <Text className="file-upload-card__file-name" noOfLines={2} textStyle="p5">
            {fileName}
          </Text>
          {getBadge(fileExtension)}
        </VStack>
        <VStack spacing={0} align="end">
          {onIconClick && (
            <Box mt="4px" ml="6px">
              <IconButtonDowWidget onClick={onIconClick} />
            </Box>
          )}
          {(!hideDeleteButton || Boolean(onDeleteClick)) && (
            <Box mt="16px" ml="6px">
              <IconButtonDeleteWidget onClick={onDeleteClick as any} />
            </Box>
          )}
        </VStack>
      </HStack>
    </Box>
  );
};
