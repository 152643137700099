import { FC } from 'react';
import { Box, Flex, FormControl, FormLabel } from '@chakra-ui/react';
import { FormSectionTitle } from './my-organization-form-header';
import { FORM_UI_ELEMENTS, ORGANIZATION_DOCUMENTS_FIELDS, ORGANIZATION_DRIVER_DOCUMENTS_FIELDS, ORGANIZATION_LABEL } from './constants';
import { FileFormField, MyOrganizationFormField } from '@/types/models/my-organization/form';
import { UiFileUploadInput } from '@/ui-elements';
import { FileDocument } from '@/types/models/document';

interface IProps {
  disabled: boolean;
  documents: Record<
    MyOrganizationFormField.PassportDocument | MyOrganizationFormField.InnOgrnDocument | MyOrganizationFormField.DriversLicenseDoc,
    FileDocument[] | undefined
  >;
  organizationID?: number;
  isCarrierSimple?: boolean;

  removeAttachment(id: number, fieldName: FileFormField): void;

  updateFile(file: File[], fieldName: FileFormField): void;

  downloadAttachmentById(id: number, fieldName: FileFormField): void;
}

export const MyOrganizationDocuments: FC<IProps> = ({
  disabled,
  downloadAttachmentById,
  updateFile,
  removeAttachment,
  documents,
  organizationID,
  isCarrierSimple,
}) => {
  const DOCUMENTS_FIELDS = !isCarrierSimple ? ORGANIZATION_DOCUMENTS_FIELDS : ORGANIZATION_DRIVER_DOCUMENTS_FIELDS;
  return (
    <div>
      <FormSectionTitle title="Документы" />
      <Box mt={4}>
        <Flex className="my-organization-flex start" direction="row" align="center" wrap="wrap">
          {DOCUMENTS_FIELDS.map((field) => {
            const { Component, defaultProps } = FORM_UI_ELEMENTS[field];
            return !organizationID ? (
              <Box>
                <Component savedFileList={documents?.[field] ?? []} label={ORGANIZATION_LABEL[field]} disabled={disabled} {...defaultProps} />
              </Box>
            ) : (
              <Box>
                <FormControl isRequired={defaultProps.required}>
                  <FormLabel mb={1} fontSize="xs" className="ui-field__label">
                    {ORGANIZATION_LABEL[field]}
                  </FormLabel>
                  <UiFileUploadInput
                    {...defaultProps}
                    disabled={disabled}
                    savedFileList={documents?.[field] ?? []}
                    onDeleteFileClick={(documentId: number) => removeAttachment(documentId, field)}
                    onIconClick={(documentId: number) => downloadAttachmentById(documentId, field)}
                    onImmediateUploadFile={(file: File[]) => updateFile(file, field)}
                  />
                </FormControl>
              </Box>
            );
          })}
        </Flex>
      </Box>
    </div>
  );
};
