import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { MyOrganizationsApi } from '@/api/my-organizations';
import { ResponseWithPager } from '@/types/models/pager';
import { MyOrganizationTypeWithBankDetails } from '@/types/models/my-organization/my-organization';

export const useOrganizations = (options?: UseQueryOptions<ResponseWithPager<MyOrganizationTypeWithBankDetails>>) => {
  const allOrganizations = useQuery({
    queryKey: ['allOrganizations'],
    refetchOnWindowFocus: false,
    queryFn: () => MyOrganizationsApi.getAllOrganizations(),
    ...options,
  });
  return { allOrganizations };
};
