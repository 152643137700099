import { toast } from 'react-toastify';

export const showUserDefaultError = ({
  error,
  message = 'Возникла ошибка, если она блокирует необходимые действия обратитесь в тех. поддержку',
}: {
  error: unknown;
  message?: string;
}) => {
  if (error instanceof Error) {
    toast.error(message + error.name);
  } else {
    toast.error(message);
  }
  console.error(error);
};
