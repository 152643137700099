import './funnel-payment-docs-form.scss';
import { FC } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Box, VStack, Text } from '@chakra-ui/react';
import { PaymentTermsClientSubform } from '@/components/create-order-form/subforms/payment-terms-client-subform';
import { UiButtonFilled, UiDivider, UiFileUploadField, UiFileUploadInput } from '@/ui-elements';
import { PaymentTermsSubform } from '@/components/create-order-form/subforms/payment-terms-subform';
import { DOCUMENTS_TITLE, SAVE_BUTTON_TEXT } from '@/config/constants/loads';
import { FunnelPaymentDocsFormFields, FunnelPaymentDocsFormValues } from '@/types/models/funnel';
import { FileDocument } from '@/types/models/document';

interface Props {
  initialValues?: Partial<FunnelPaymentDocsFormValues>;
  isUpdating?: boolean;
  documents?: Array<FileDocument>;
  submitting: boolean;
  deleteDocuments?: (documentId: number) => void;
  postDocuments?: (files: Array<File>, onSuccessCallback?: (...args: any[]) => void) => void;
  downloadDocuments?: () => void;
  onSubmit: (formValues: FunnelPaymentDocsFormValues) => void;
}

export const FunnelPaymentDocsForm: FC<Props> = ({
  initialValues,
  isUpdating,
  documents,
  submitting,
  deleteDocuments,
  postDocuments,
  downloadDocuments,
  onSubmit,
}) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      mutators={{ ...arrayMutators }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <VStack align="left">
              <PaymentTermsClientSubform namespace={FunnelPaymentDocsFormFields.ClientPaymentTerms} />
              <UiDivider my={4} variant="extraDark" />
              {/* @ts-ignore */}
              <PaymentTermsSubform namespace={FunnelPaymentDocsFormFields.CarrierPaymentTerms} isUpdating={isUpdating} />
              {!isUpdating ? (
                <>
                  <UiDivider my={4} variant="extraDark" />
                  <Text textStyle="h3" align="left" mb={4}>
                    {DOCUMENTS_TITLE}
                  </Text>
                  <UiFileUploadField name={FunnelPaymentDocsFormFields.Documents} />
                </>
              ) : (
                <UiFileUploadInput
                  name={FunnelPaymentDocsFormFields.Documents}
                  savedFileList={documents}
                  onDeleteFileClick={deleteDocuments}
                  onIconClick={downloadDocuments}
                  onImmediateUploadFile={postDocuments}
                />
              )}
            </VStack>
          </Box>
          <Box display="flex" w="100%" justifyContent="flex-end">
            <UiButtonFilled type="submit" className="funnel-payment-docs-form__submit-button" isDisabled={submitting} isLoading={submitting}>
              {SAVE_BUTTON_TEXT}
            </UiButtonFilled>
          </Box>
        </form>
      )}
    />
  );
};
