import { FC } from 'react';
import { UiButtonFilled } from '@/ui-elements';
import { CARRIER_LOAD_REQUEST_BUTTON_LABEL } from '@/config/constants/carriers-loads';
import { UiTooltip } from '@/ui-elements/tooltip/tooltip';

interface Props {
  handleRequestOrder: () => void;
  buttonProps?: {
    label?: string;
    disable?: boolean;
  };
}

export const CarrierLoadReqestButton: FC<Props> = ({ handleRequestOrder, buttonProps }) => {
  return (
    <UiTooltip label={buttonProps?.label}>
      <UiButtonFilled variant="outline" size="small" disabled={buttonProps?.disable} isLoading={false} onClick={handleRequestOrder}>
        {CARRIER_LOAD_REQUEST_BUTTON_LABEL}
      </UiButtonFilled>
    </UiTooltip>
  );
};
