export const loadOptions = [
  { value: 1, label: 'верхняя' },
  { value: 2, label: 'боковая' },
  { value: 3, label: 'задняя' },
  { value: 4, label: 'с полной растентовкой' },
  { value: 5, label: 'со снятием поперечных перекладин' },
  { value: 6, label: 'со снятием стоек' },
  { value: 7, label: 'без ворот' },
  { value: 8, label: 'гидроборт' },
  { value: 9, label: 'аппарели' },
  { value: 10, label: 'с обрешеткой' },
  { value: 11, label: 'с бортами' },
  { value: 12, label: 'боковая с 2-х сторон' },
];

export const tonnageOptions = [
  { value: 1, label: '1 т - Хендай Портер, ГАЗ Соболь' },
  { value: 2, label: '1.5 т - Газель и аналоги' },
  { value: 3, label: '2 т - Газель с удлиненным кузовом' },
  { value: 4, label: '3 т - Бычок и аналоги' },
  { value: 5, label: '5 т - Mercedes Altego и аналоги' },
  { value: 6, label: '7 т - Mercedes Altego, MAN TGL, Volvo FL' },
  { value: 7, label: '10 т - Mercedes Actros, MAN TGL, Volvo' },
  { value: 8, label: '20 т - полуприцеп Фуры' },
];

export const loadTypeConfig = {
  ftl: 'отдельной машиной',
  ltl: 'отдельной машиной или догрузом',
};

export const routeTypeConfig = {
  roundtrip: 'кругорейс',
  simple: 'только туда',
};

export const unloadOptions = [
  { value: 1, label: 'Вес и объем' },
  { value: 2, label: 'Упаковка' },
  { value: 3, label: 'ТТН' },
];

export const weightOptions = [
  { value: 1, label: 'т' },
  { value: 2, label: 'кг' },
];

export const packageOptions = [
  { value: 1, label: 'не указано' },
  // { value: 2, label: 'навалом' },
  { value: 3, label: 'коробки' },
  // { value: 4, label: 'россыпью' },
  { value: 5, label: 'палеты' },
  // { value: 6, label: 'пачки' },
  // { value: 7, label: 'мешки' },
  // { value: 8, label: 'биг-бэги' },
  // { value: 9, label: 'ящики' },
  // { value: 10, label: 'листы' },
  // { value: 11, label: 'бочки' },
  // { value: 12, label: 'канистры' },
  // { value: 13, label: 'рулоны' },
  // { value: 14, label: 'пирамида' },
  // { value: 15, label: 'еврокубы' },
  // { value: 16, label: 'катушки' },
  // { value: 17, label: 'барабаны' },
];

export enum TimeType {
  exact = 'exact',
  interval = 'interval',
  simple = 'simple',
}

export enum TimeTypeMap {
  exact = 'Указать точное время',
  interval = 'Указать диапазон времени',
  simple = 'Не указывать время',
}

const TIME_TYPES = Object.keys(TimeType) as Array<TimeType>;

export const arrivalTimeTypeOptions = TIME_TYPES.reduce((options, value) => {
  options[value] = {
    value,
    label: TimeTypeMap[value],
  };
  return options;
}, {} as Record<TimeType, { value: TimeType; label: string }>);

export const arrivalTimeTypeConfig = Object.values(arrivalTimeTypeOptions);

export const emptyCargoFields = {
  weightOptions: { value: 1, label: 'т' },
  package: { value: 1, label: 'не указано' },
};

export const emptyCargoUnloadFields = {
  unloadCargo: {},
};
