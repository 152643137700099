import { FC } from 'react';
import { FormApi } from 'final-form';
import { VStack } from '@chakra-ui/layout';
import { UiDivider } from '@/ui-elements';
import { Cargos } from '@/types/models/cargos';
import { RouteSubform } from '@/components/create-order-form/subforms/ati-su-route';
import { CreateLoadFormField } from '@/types/models/funnel-create-load-form';
import { usePackages } from '@/features/packages/use-packages';
import { VehicleTypeSubform } from '@/components/create-order-form/subforms/ati-su-vehicle-type';
import { LoadInfoSubform } from '@/components/funnel/create-load-form/subform/load-info-sub-form';

interface Props {
  form: FormApi<any, Partial<any>>;
  cargos?: Array<Cargos>;
}

export const CreateLoadFormFields: FC<Props> = ({ form, cargos }) => {
  const packageTypes = usePackages();
  return (
    <VStack align="left" mr={4} overflowX="hidden">
      <LoadInfoSubform form={form} namespace={CreateLoadFormField.LoadInfo} />
      <UiDivider variant="extraDark" />
      <RouteSubform form={form} namespace={CreateLoadFormField.Route} packageTypes={packageTypes} cargos={cargos} />
      <UiDivider py={4} variant="extraDark" />
      <VehicleTypeSubform namespace={CreateLoadFormField.VehicleType} />
    </VStack>
  );
};
