import { OrderLoadType, OrderType, RiskValues, TransportationType } from 'config/constants/types';
import { DateObject } from 'react-multi-date-picker';
import { VehicleType } from 'config/constants/vehicle';
import { TimeType } from 'components/create-order-form/subforms/form-configs';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { CustomerManagerForm } from '@/types/api/customer-partial';

export enum Namespaces {
  clientPaymentTerms = 'clientPaymentTerms',
  carrierPaymentTerms = 'carrierPaymentTerms',
  orderInfo = 'orderInfo',
  documents = 'documents',
  route = 'route',
  requirements = 'requirements',
  vehicleType = 'vehicleType',
  cargo = 'cargo',
  state = 'state',
}

export type OrderCreateOrderInfo = {
  contactPerson?: string;
  contactPhone?: string;
  customerCompany?: { value: number | undefined; label: string | undefined };
  riskGroup?: { value: RiskValues | undefined; label: string };
  transportationOption?: { value: TransportationType; label: string };
  responsiblePerson?: SelectOption;
};

export type OrderCreatePaymentTerms = {
  vat?: { value: boolean | undefined; label: string };
  price?: string;
  hasPrepayment?: boolean;
  paymentConditions?: string;
};
export type OrderCreateCarrierPaymentTerms = {
  hasVat?: boolean;
  withoutVat?: boolean;
  priceWithVAT?: number | null;
  priceWithoutVAT?: number | null;
  hasPrepayment?: boolean;
  hasFuel?: boolean;
  paymentConditions?: string;
};

export type OrderCreateRoute = {
  routeType?: OrderType;
  points?: Array<{
    prevId?: number;
    documents?: Array<File>;
    address?: SelectOption;
    coordinates?: string;
    loading?: OrderLoadType;
    cargo?: Array<{
      cargoCategory: {
        value: number;
        label: string;
      };
      cargoWeight: number;
      cargoVolume: number;
    }>;
    date?: DateObject;
    time?: string;
  }>;
};
export type OrderCreateRequirements = {
  vehicleTypes: Array<{ value: VehicleType }>;
  loadingType: Array<SelectOption>;
  additionalRequirements: string;
};

export type OrderCreateFormValues = {
  orderInfo: OrderCreateOrderInfo;
  carrierPaymentTerms: OrderCreateCarrierPaymentTerms;
  clientPaymentTerms: OrderCreatePaymentTerms;
  route: OrderCreateRoute;
  requirements: OrderCreateRequirements;
  documents: Array<File>;
};
export type CustomersCreateFormValues = {
  customer: string;
  inn: number;
  contract: string;
  date: string;
  manager?: CustomerManagerForm;
  comment: string;
  tarif: string;
  vipCustomer: boolean | number;
  action?: 'approve' | 'decline';
};

export type CargoForm = {
  id: number | string;
  cargoType: SelectOption;
  weight: number;
  weightOptions: SelectOption;
  volume: number;
  package: SelectOption;
  quantity: number;
  tenderLotID: number;
  tenderLotPositionID: number;
  size: {
    lenght: number;
    width: number;
    height: number;
    diameter: number;
  };
  unloadCargo: SelectOption;
};

export type PointForm = {
  id: number | string;
  showLoadTime: boolean;
  address: { value: string; label: string };
  arrivalTimeOption: { value: TimeType; label: string };
  arrivalDate: Date;
  arrivalStartTime: Date;
  arrivalEndTime: Date;
  cargos: Array<Partial<CargoForm>>;
  documents: Array<File>;
  type: OrderLoadType;
};

// type TransitWaypoint = {
//   cityTransit: string;
//   addresstransit: string;
// };

export type FormValues = {
  orderInfo: {
    customerCompany?: SelectOption;
    responsiblePerson?: SelectOption;
    riskGroup?: SelectOption;
    contactPerson?: any;
    contactPhone?: string;
  };
  route: {
    routeType: 'roundtrip' | 'simple';
    points: Array<Partial<PointForm>>;
    // transit: Array<TransitWaypoint>;
  };
  vehicleType: {
    vehicleTypes?: any;
    loadingType?: any;
    additionalRequirements?: string;
    loadType: 'ltl' | 'ftl';
    belts?: number;
    tonnage?: SelectOption;
    TIR?: boolean;
    CMR?: boolean;
    T1?: boolean;
    medicalBook?: boolean;
  };
  carrierPaymentTerms: {
    hasVat: boolean;
    withoutVat: boolean;
    priceWithVAT?: number;
    priceWithoutVAT?: number;
    paymentConditions?: string;
    priceOffer?: number;
    hasPrepayment?: boolean;
    hasFuel?: boolean;
  };
  clientPaymentTerms?: any;
  prepayment?: {
    possibility?: boolean;
    fuelPossibility?: boolean;
  };
  cargo: Array<CargoForm>;
  state: { cargo: Array<[[number, number]]> };
  documents?: Array<File>;
};
