import { RiskValues, SortType } from '@/config/constants/types';
import { Order } from '@/types/models/order';
import { FunnelLoadStatus, LogisticsLoadStatus } from '@/types/models/funnel';

/* Сортировка "Поиск грузов" */
export enum LoadsSortField {
  Sorting = 'sorting',
}

export const enum LoadsSortValues {
  Default = 'Default',
  StartPrice = 'StartPrice',
  PricePerKm = 'PricePerKm',
  LoadingStart = 'LoadingStart',
  UnloadingStart = 'UnloadingStart',
}

export const enum LoadsSortTitles {
  Default = 'по умолчанию',
  StartPrice = 'по цене',
  PricePerKm = 'по цене ₽/км',
  LoadingStart = 'начало погрузки',
  UnloadingStart = 'начало выгрузки',
}

export interface LoadsSortButtonValues {
  value: LoadsSortValues;
  title: LoadsSortTitles;
  id: number;
}

export interface LoadsFormSortValues {
  value: LoadsSortValues;
  sortingDirection: SortType | null;
}

/* Фильтрация "Поиск грузов" */
export enum LoadsFiltersField {
  From = 'from',
  To = 'to',
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
  Vehicle = 'vehicle',
  TariffType = 'tariffType',
  SingleLoading = 'singleLoading',
}

export enum LoadsFiltersFieldLabel {
  From = 'Откуда',
  To = 'Куда',
  DateFrom = 'Дата погрузки с',
  DateTo = 'Дата погрузки по',
  Vehicle = 'Тип ТС',
  TariffType = 'Тип тарифа',
  SingleLoading = 'Одна погрузка одна выгрузка',
}

export interface LoadsFilterValues {
  from: string;
  to: string;
  dateFrom: string;
  dateTo: string;
  vehicle: Array<any>;
  tariffType: TariffTypes;
  singleLoading: boolean;
}

/* Тип состояния фильтры + сортировка */
export interface LoadsFormValues extends LoadsFilterValues {
  [LoadsSortField.Sorting]: LoadsFormSortValues;
}

/* Тарифы */
export enum TariffTypes {
  Any = 'any',
  Fixed = 'fixed',
  Auction = 'auction',
}

export const TariffTypeOptions: Record<string, string> = {
  [TariffTypes.Any]: 'Любой',
  [TariffTypes.Fixed]: 'Фикс',
  [TariffTypes.Auction]: 'Аукцион',
};

/* Биржи грузоперевозок */
export enum FreightExchangePlatform {
  AtiSu = 'AtiSu',
  MonopolyOnline = 'MonopolyOnline',
}

/* LoadOriginalPrice - это желаемая цена клиента, которую мы получили изначально с биржи грузоперевозок */
/* У груза три цены:
 * 1 - начальная цена, которую запросил клиент на бирже;
 * 2 - цена clientPayment, которую заполняем в форме вкладки Рассчет стоимости (как в обычной заявке);
 * 3 - цена carrierPayment, которую заполняем в форме вкладки Рассчет стоимости (как в обычной заявке);
 * */
export type LoadOriginalPrice = {
  hasVat?: boolean;
  priceWithVAT?: number;
  priceWithoutVAT?: number;
};

/* Груз */
export interface Load extends Omit<Order, 'riskGroup'> {
  originalPrice?: LoadOriginalPrice;
  status: FunnelLoadStatus;
  logisticsStatus?: LogisticsLoadStatus;
  tariffType?: TariffTypes;
  riskGroup: RiskValues | undefined;
  freightExchangePlatform?: FreightExchangePlatform;
  platformUrl?: string;
}

/* Функция для проверки типа, чтобы использовать в компоненте, который принимает значение Order и Load */
export const isLoad = (value?: Order | Load): value is Load => {
  return (value as Load).tariffType !== undefined;
};
