import { driverToFormValues } from '@/components/verification-forms/helpers/driver-to-form-values';
import { useDependentAttachments } from '@/features/attachments/hooks/use-dependent-attachments';
import { AttachmentDeps } from '@/config/constants/types';
import { DocumentTypes } from '@/config/constants/document-types';
import { ResourcesFormFieldNames } from '@/features/resources/constants/form';
import { useDriver } from '@/features/drivers/hooks/use-driver';

interface Props {
  driverId?: number | null;
  carrierId?: number;
}

export const useDriverFormInitialValues = ({ driverId, carrierId }: Props) => {
  const {
    driver: { data: driverResp },
  } = useDriver(driverId!);
  const driver = driverResp?.data;

  const updatedValues = driverToFormValues({ carrier: { id: carrierId }, values: driver });
  const driverPassportDocs = useDependentAttachments({
    dependent: AttachmentDeps.Driver,
    dependentId: driver?.id,
    type: DocumentTypes.dlDocumentDriverPassport,
  });
  const driverLicenseDocs = useDependentAttachments({
    dependent: AttachmentDeps.Driver,
    dependentId: driver?.id,
    type: DocumentTypes.dlDocumentDriverLicense,
  });
  const driverlaborContractDocs = useDependentAttachments({
    dependent: AttachmentDeps.Driver,
    dependentId: driver?.id,
    type: DocumentTypes.dlDocumentDriverContract,
  });

  const driverInitialDocs = {
    [ResourcesFormFieldNames.passportDoc]: driverPassportDocs,
    [ResourcesFormFieldNames.driversLicenseDoc]: driverLicenseDocs,
    [ResourcesFormFieldNames.laborContractDoc]: driverlaborContractDocs,
  };

  return { updatedValues, driverInitialDocs };
};
