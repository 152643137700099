import qs from 'query-string';
import { useNavigate } from 'react-router-dom';
import { assign } from 'lodash';
import { Tab } from '@/ui-elements/switcher/types';
import { routesPath } from '@/config/routes/routes-path';
import { useAppSelector } from '@/hooks/use-app-selector';
import { Load } from '@/types/models/loads';
import { FunnelListFilters, FunnelLoadStatus, FunnelSwitcherStatus } from '@/types/models/funnel';
import { FunnelSidebarMode } from '@/types/sidebar/funnel-sidebar-mode';
import { FetchNextPageType } from '@/types/models/pager';
import { activeFunnelTabSelector } from '@/features/funnel/selectors/active-funnel-tab-selector';
import { funnelPageSelectedLoadIDSelector } from '@/features/funnel/selectors/funnel-page-selected-load-id';
import { funnelSidebarModeSelector } from '@/features/funnel/selectors/funnel-sidebar-mode-selector';
import { userHasIntegrationSelector } from '@/features/loads/selectors/user-has-integration';
import { useSetSelectedFunnelLoadWithQuery, useFunnelActions, useFunnelSwitcherLabels, useFunnelListFormValues } from '@/features/funnel/hooks';
import { useAllOrdersUpdates } from '@/features/order/hooks/use-all-orders-updates';
import { OrderCreateSource } from '@/types/api/order-partial';
import { useFunnelPage } from './use-funnel-page';
import { addSourceOrderToQuery } from '../helpers';

interface ReturnValues {
  currentLoadID?: number;
  allLoads: Array<Load> | Array<never>;
  activeTab: FunnelSwitcherStatus;
  switcherLabels: Array<Tab>;
  periodValue: string;
  listIsLoading: boolean;
  userHasIntegration: boolean;
  handleSelectLoad: (id?: number) => void;
  handleChangeActiveTab: (activeKey: string) => void;
  handleCreateLoad: VoidFunction;
  fetchNextPage: FetchNextPageType<Load>;
  handleChangePeriod: (value: string) => void;
  handleChangeFilters: (values: FunnelListFilters) => void;
}

export const useFunnelList = (): ReturnValues => {
  const navigate = useNavigate();
  const activeTab = useAppSelector(activeFunnelTabSelector);
  const currentLoadID = useAppSelector(funnelPageSelectedLoadIDSelector);
  const mode = useAppSelector(funnelSidebarModeSelector);

  const { selectLoadID, selectTab, create, cancelCreate } = useFunnelActions();

  useFunnelPage();

  // TODO: получать из данных аккаунта;
  // userHasIntegration: false = у пользователя не настроена интеграция;
  const userHasIntegration = useAppSelector(userHasIntegrationSelector);

  const { filters, periodValue, handleChangeFilters, handleChangePeriod, handleChangeFiltersImmediately } = useFunnelListFormValues();

  const { data, fetchNextPage, isFetchingNextPage, isInitialLoading, isRefetching } = useAllOrdersUpdates({
    query: addSourceOrderToQuery(filters),
  });
  // const { data, fetchNextPage, isFetchingNextPage, isInitialLoading } = useAllLoadsInfiniteUpdates({
  //   query: filters,
  // });
  const allLoads = data?.pages ? data.pages.flatMap((d) => d.data) : [];

  const listIsLoading = isFetchingNextPage || isInitialLoading || isRefetching;
  const { switcherLabels } = useFunnelSwitcherLabels();

  useSetSelectedFunnelLoadWithQuery(selectLoadID);
  // TODO: компонент не обновляется вовремя при смене активного таба.
  const handleChangeActiveTab = (activeKey: string) => {
    selectTab(activeKey as FunnelSwitcherStatus);
    handleChangeFiltersImmediately({ ...filters, status: activeKey as FunnelLoadStatus });
    selectLoadID(undefined);
  };

  const handleSelectLoad = (id?: number) => {
    const url = qs.stringifyUrl({
      url: `/${routesPath.Funnel}`,
      query: {
        id,
      },
    });
    if (mode === FunnelSidebarMode.NewLoad || mode === FunnelSidebarMode.UpdateLoad) cancelCreate();
    navigate(url);
    selectLoadID(id);
  };

  const handleCreateLoad = () => create();

  return {
    currentLoadID,
    allLoads,
    activeTab,
    switcherLabels,
    periodValue,
    listIsLoading,
    userHasIntegration,
    handleSelectLoad,
    handleChangeActiveTab,
    handleCreateLoad,
    fetchNextPage,
    handleChangePeriod,
    handleChangeFilters,
  };
};
