import { useMutation } from '@tanstack/react-query';
import { ApprovalsApi } from '@/api/approvals';
import { ApprovalRequest } from '@/types/models/approval';

export const useCreateApproval = () => {
  const createApproval = useMutation({
    mutationKey: ['createApproval'],
    mutationFn: (body: ApprovalRequest) => ApprovalsApi.createApproval(body),
  });

  return {
    createApproval,
  };
};
