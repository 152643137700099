import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { GAP_TITLE, PADDING } from 'components/carrier-card/style-constants';
import { OrganisationRating } from 'components/organisation-rating/organisation-rating';
import { UiButtonOutlined } from '@/ui-elements';
import { Carrier } from '@/types/models/carrier';
import { emptyFunction } from '@/helpers/empty-function';
import { getApprovalStatusCarrier } from '@/helpers/get-approval-status-carrier';

type Props = {
  carrier: Carrier;
  onClickShowInfo?: (data: { id: number }) => void;
};

export const CarrierCardTitle: FC<Props> = ({ carrier, onClickShowInfo = emptyFunction }) => (
  <Flex justifyContent="space-between" p={PADDING} w="100%">
    {carrier.status ? (
      <Flex alignItems="center" gap={GAP_TITLE}>
        <OrganisationRating ratingType={carrier.status} />
        <Text className="carrier-card__text-title">{carrier.name}</Text>
      </Flex>
    ) : (
      <Text className="carrier-card__text-title">{carrier.name}</Text>
    )}
    <Flex className="carrier-card__info">
      <Text className="carrier-card__info-approved">{getApprovalStatusCarrier(carrier.approvalStatus)}</Text>
      <UiButtonOutlined mode="dark" onClick={() => onClickShowInfo({ id: carrier.id })}>
        Подробнее
      </UiButtonOutlined>
    </Flex>
  </Flex>
);
