import { Spinner, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { DOWNLOADING } from 'config/constants/common-strings';
import { UiSpinnerPropsProps } from '@/ui-elements/ui-spinner/ui-spinner-props';

export const UiSpinner: FC<UiSpinnerPropsProps> = ({
  visible,
  size = 'xxl',
  speed = '1s',
  color = 'products.200',
  thickness = '4px',
  loadingMessage = DOWNLOADING,
  textStyle = 'p6',
  spacing = '34px',
  ...props
}) => {
  return visible ? (
    <VStack spacing={spacing}>
      <Spinner color={color} thickness={thickness} speed={speed} size={size} {...props} />
      <Text textStyle={textStyle}>{loadingMessage}</Text>
    </VStack>
  ) : null;
};
