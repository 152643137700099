import { FC } from 'react';
import classNames from 'classnames';
import { Box, HStack, Text } from '@chakra-ui/react';
import { head, toNumber } from 'lodash';
import { OrderExecutionStatus } from 'config/constants/statuses';
import { OrderCardRoute } from 'components/order-card-route/order-card-route';
import { START_ORDER } from 'components/order-details/order-details-carrier-driver-info/texts';
import { VehicleProfileWidget } from 'components/carriers-loads/widgets/vehicle-profile-widget';
import { getCarrierStatusesStyles } from 'components/carriers-loads/card/get-carrier-statuses-styles';
import { Order } from '@/types/models/order';
import { PaymentChip } from '@/ui-elements/payment-chip/payment-chip';
import { UiStatus } from '@/ui-elements/status/status';
import { OrderGeneralDistance } from '@/components';
import { emptyFunction } from '@/helpers/empty-function';
import { toPriceFormat } from '@/helpers/price/to-price-format';
import { UiButtonFilled } from '@/ui-elements';
import { Load } from '@/types/models/loads';
import { UiText } from '@/ui-elements/ui-text/ui-text';
import { OrderOffer } from '@/types/models/order-offer';
import { handlePriceChipVisibility } from '@/helpers/price/handle-price-chip-visibility';

type Props = {
  order: Order | Load;
  selected?: boolean;
  handleOrderClick?: (orderId: number) => void;
  onOrderStart?: () => void;
};

export const OrderInfo: FC<Props> = ({ order, selected = false, handleOrderClick = emptyFunction, onOrderStart }) => {
  const { id, prepayment, carrierPayment, customer, status, distance, shippedTotals, points, type, offer } = order;

  const statusStyle = getCarrierStatusesStyles({ status });
  const carrierLoadsOffer: OrderOffer | undefined = head(offer);

  const isAccepted = carrierLoadsOffer?.accepted ?? 0;

  const isVisiblePrice = handlePriceChipVisibility({ carrierAcceptedOffer: carrierLoadsOffer, carrierPayment, status });

  const totalVolume = shippedTotals?.totalVolume;
  const totalMass = shippedTotals?.totalMass;

  return (
    <Box className={classNames('carriers-loads orders-with-my-participation-info', { selected })} onClick={() => handleOrderClick(id)}>
      <HStack className="orders-with-my-participation-info__header">
        <HStack>
          <Text className="orders-with-my-participation-info__company">{customer?.name}</Text>
          <UiText>{order?.id}</UiText>
        </HStack>
        <HStack>
          {prepayment?.value && <PaymentChip type="advance" amount={toPriceFormat(prepayment.value)} />}
          {statusStyle && (
            <UiStatus key={statusStyle.text} text={statusStyle.text} backGroundColor={statusStyle.backgroundColor} color={statusStyle.color} />
          )}
        </HStack>
      </HStack>
      <OrderGeneralDistance className="orders-with-my-participation-info-distance" type={type} range={distance || 0} />
      <OrderCardRoute points={points} order={order} />
      <HStack className="orders-with-my-participation-info__information">
        <HStack className="orders-with-my-participation-info__information-general">
          <VehicleProfileWidget mass={totalMass} volume={totalVolume} />
        </HStack>
        <HStack className="orders-with-my-participation-info__information-payment">
          {isVisiblePrice({ vat: true }) && toNumber(carrierPayment.priceWithVAT) > 0 && (
            <PaymentChip type="priceWithVAT" amount={toPriceFormat(carrierPayment.priceWithVAT, true)} />
          )}
          {isVisiblePrice({ vat: false }) && toNumber(carrierPayment.priceWithoutVAT) && (
            <PaymentChip type="priceWithoutVAT" amount={toPriceFormat(carrierPayment.priceWithoutVAT, true)} />
          )}
        </HStack>
      </HStack>
      {Boolean(onOrderStart && isAccepted && status === OrderExecutionStatus.Confirmed) && (
        <UiButtonFilled children={START_ORDER} className="orders-with-my-participation-details__button" onClick={onOrderStart} />
      )}
    </Box>
  );
};
