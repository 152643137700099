import { DocumentTypes } from '@/config/constants/document-types';

export const VehicleOwnTypeLabels = [
  {
    key: '0',
    label: 'Аренда',
  },
  {
    key: '1',
    label: 'Собственный ТС',
  },
  {
    key: '2',
    label: 'Лизинг',
  },
];

export const ResourcesVehicleDocs = [
  {
    title: 'Свидетельство о регистрации транспортного средства (СТС)',
    types: [DocumentTypes.VRCFront, DocumentTypes.VRCBack],
  },
  {
    title: 'Паспорт транспортного средства (ПТС)',
    types: [DocumentTypes.VPFront, DocumentTypes.VPBack],
  },
  {
    title: 'Другие документы',
    types: [DocumentTypes.ExtraDoc],
  },
];
