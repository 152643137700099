import { FC } from 'react';
import { PageWrapper } from 'pages/page-wrapper';
import { Flex } from '@chakra-ui/react';
import { MyOrganizationView } from '@/views/my-organization-view/my-organization-view';

export const MyOrganizationPage: FC = () => (
  <PageWrapper>
    <Flex align="center" justify="center" p="4">
      <MyOrganizationView />
    </Flex>
  </PageWrapper>
);
