import { FC } from 'react';
import { DriverCardCompact } from 'components/driver-card-compact/driver-card-compact';
import { Divider } from '@chakra-ui/react';
import { YOU_HAVE_CHOSEN } from './texts';
import { Driver } from '@/types/models/driver';
import './drivers-compact-list.scss';
import { emptyFunction } from '@/helpers/empty-function';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';

type Props = {
  drivers?: Array<Driver>;
  department?: string;
  selectedDriver?: Driver;
  // подробнее о водителе
  onDriverMoreInfoClick?: (id: number) => void;
  isFetching: boolean;
};
export const DriversCompactList: FC<Props> = ({ isFetching, drivers, department, selectedDriver, onDriverMoreInfoClick = emptyFunction }) => {
  if (isFetching) return <UiSpinner visible mt={48} />;
  return (
    <div className="drivers-compact-list">
      {selectedDriver && (
        <div className="drivers-compact-list__block">
          <div className="drivers-compact-list__title">{YOU_HAVE_CHOSEN}</div>
          <DriverCardCompact
            driver={selectedDriver}
            department={department}
            className="drivers-compact-list__selected"
            onMoreInfoClick={() => onDriverMoreInfoClick(selectedDriver?.id)}
          />
          <Divider className="drivers-compact-list__divider" />
        </div>
      )}
      {drivers?.map((driver) =>
        driver.id !== selectedDriver?.id ? (
          <DriverCardCompact
            key={driver.id}
            driver={driver}
            department={department}
            className="drivers-compact-list__item"
            onMoreInfoClick={() => onDriverMoreInfoClick(driver?.id)}
          />
        ) : null
      )}
    </div>
  );
};
