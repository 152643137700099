import './funnel-details.scss';
import { FC } from 'react';
import { Load } from '@/types/models/loads';
import { UiDivider } from '@/ui-elements';
import { LoadRouteDetails } from '@/components/loads/details/route-details/load-route-details';
import { FunnelSwitcherStatus } from '@/types/models/funnel';
import { FunnelPaymentDocsComponent } from '@/components/funnel/payment-docs-component/funnel-payment-docs-component';
import { FunnelPaymentDetails } from '@/components/funnel/details/payment-details/funnel-payment-details';
import { FunnelExecutionDetails } from '@/components/funnel/details/execution-details/funnel-execution-details';
import { FunnelCalculationDetails } from '@/components/funnel/details/calculation-details/funnel-calculation-details';
import { FunnelExecutionDetailsWithMap } from '@/components/funnel/details/execution-details-with-map/funnel-execution-details-with-map';
import { FunnelDocumentsDetails } from '@/components/funnel/details/documents-details/funnel-documents-details';
import { FunnelTimelineView } from '@/views/funnel-timeline-view/funnel-timeline-view';
import { LoadTitles } from '@/components/loads/titles/load-titles';

interface Props {
  load: Load;
  deleteAllowed: boolean;
  activeTab?: FunnelSwitcherStatus;
  handleUpdateLoad?: () => void;
  handleDeleteLoad: VoidFunction;
}

export const FunnelDetails: FC<Props> = ({ load, deleteAllowed, activeTab, handleUpdateLoad, handleDeleteLoad }) => {
  const isCostCalculationTab = activeTab === FunnelSwitcherStatus.CostCalculation;
  const showCalculationDetails = activeTab !== FunnelSwitcherStatus.NewCalculation && !isCostCalculationTab;

  return (
    <div className="funnel-details">
      <LoadTitles name={load.customer?.name} id={load.id} freightExchangePlatform={load.freightExchangePlatform} />
      <UiDivider mt={6} mb={6} variant="dark" />
      {showCalculationDetails ? (
        <FunnelCalculationDetails load={load} deleteAllowed={deleteAllowed} handleDeleteLoad={handleDeleteLoad} />
      ) : (
        <FunnelPaymentDetails load={load} />
      )}
      <UiDivider mt={6} mb={6} variant="dark" />
      {showCalculationDetails ? (
        <FunnelExecutionDetailsWithMap load={load} />
      ) : (
        <FunnelExecutionDetails load={load} handleUpdateLoad={handleUpdateLoad} deleteAllowed={deleteAllowed} handleDeleteLoad={handleDeleteLoad} />
      )}
      <UiDivider mt={6} mb={6} variant="dark" />
      <LoadRouteDetails load={load} />
      {showCalculationDetails && (
        <>
          <UiDivider mt={6} mb={6} variant="dark" />
          <FunnelDocumentsDetails />
        </>
      )}
      {isCostCalculationTab && (
        <>
          <UiDivider mt={6} mb={6} variant="dark" />
          <FunnelPaymentDocsComponent load={load} />
        </>
      )}
      {showCalculationDetails && (
        <>
          <UiDivider mt={6} mb={6} variant="dark" />
          <FunnelTimelineView id={load.id} />
        </>
      )}
    </div>
  );
};
