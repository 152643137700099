import { BookerOrderDetailsTabs } from 'config/constants/statuses';
import { BookerTabNames } from 'config/constants/texts';

/*
 * labels для switchers booker order
 * */

export const labelsDetails = [
  {
    key: BookerOrderDetailsTabs.Customer,
    label: `${BookerTabNames[BookerOrderDetailsTabs.Customer]} `,
  },
  {
    key: BookerOrderDetailsTabs.Carrier,
    label: `${BookerTabNames[BookerOrderDetailsTabs.Carrier]}`,
  },
];
