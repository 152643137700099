import { FC } from 'react';
import { OrderPoint } from 'components/order-point/order-point';
import { Flex, Icon } from '@chakra-ui/react';
import noop from 'lodash/noop';
import classNames from 'classnames';
import { Point } from '@/types/models/point';
import { UiFileUploadInput, UiSelect } from '@/ui-elements';
import { CARRIER_POINT_STATUSES_OPTIONS, OPTION_WITHOUT_STATUS, StatusesTransitionNames } from '@/components/order-point/options';
import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';
import { useRoutePointWidget } from '@/features/carriers-loads/hooks/orders-with-my-participation/use-route-point-widget';

type Props = {
  point: Point;
  isLastPoint?: boolean;
  order: Order | Load;
};

/**
 * Точка маршрута с иконками погрузки разгрузки и вертикальной стрелкой
 * */
export const RoutePointWidget: FC<Props> = ({ point, order, isLastPoint = false }) => {
  const {
    extendedPoint,
    UnloadIcon,
    LoadIcon,
    LongArrowIcon,
    setStatus,
    cargoTypes,
    isExecuting,
    isPointDocumentsAvailable,
    orderAction,
    status,
    Form,
    hasEdit,
    attachments,
    deleteDocument,
    downloadAttachments,
    uploadDocument,
  } = useRoutePointWidget(order, point);

  return (
    <Flex>
      <div className="order-point__icons">
        <div className="order-point__type_icons">
          {extendedPoint.isUnloading && <Icon as={UnloadIcon} />}
          {extendedPoint.isLoading && <Icon as={LoadIcon} />}
        </div>
        <div>{!isLastPoint && <Icon as={LongArrowIcon} />}</div>
      </div>
      <Flex direction="column-reverse" justify="space-between" w="full" gap="1em">
        {isPointDocumentsAvailable && (
          <Form onSubmit={noop}>
            <UiFileUploadInput
              required
              disableUpload={!hasEdit}
              savedFileList={attachments}
              onDeleteFileClick={deleteDocument}
              hideDeleteButton={!deleteDocument}
              onIconClick={downloadAttachments}
              onImmediateUploadFile={uploadDocument}
            />
          </Form>
        )}
        {isExecuting && (
          <Flex direction="column" gap="1em">
            <UiSelect
              className={classNames('order-point__status', { [`order-point__status_${status}`]: true })}
              isClearable={false}
              options={CARRIER_POINT_STATUSES_OPTIONS[orderAction]}
              value={
                {
                  value: status,
                  label: status ? `${StatusesTransitionNames[status][orderAction]}` : OPTION_WITHOUT_STATUS.label,
                } as any
              }
              placeholder={status}
              onChange={setStatus as any}
              menuPlacement="auto"
              overflown
              isSearchable={false}
            />
          </Flex>
        )}
        <OrderPoint point={extendedPoint.point} order={order} isLastPoint={isLastPoint} cargoTypes={cargoTypes} />
      </Flex>
    </Flex>
  );
};
