import { FormControl, FormLabel, FormErrorMessage, FormHelperText, Box } from '@chakra-ui/react';
import { Props as SelectProps } from 'chakra-react-select';
import { useField } from 'react-final-form';
import { UiSelect } from '@/ui-elements';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';

export type UiSelectFieldProps = {
  label?: string;
  options: Array<SelectOption>;
  content?: string;
  name: string;
  required?: boolean;
  isDisabled?: boolean;
  disabled?: boolean;
  defaultInputValue?: string;
  overflown?: boolean;
  selectedTab?: number;
  setSelectedTab?: (arg0: number) => void;
  isCreatable?: boolean;
} & Omit<SelectProps, 'defaultValue'>;

export const UiSelectField = ({ name, label, required, content, onChange, value, isCreatable, ...props }: UiSelectFieldProps) => {
  const { isDisabled } = props;
  const {
    input,
    meta: { error, touched },
  } = useField(name);
  const invalid = error && touched;

  return (
    <Box w="full">
      <FormControl isRequired={required && !isDisabled} isInvalid={invalid}>
        <FormLabel mb={1} fontSize="xs" className="ui-field__label">
          {label}
        </FormLabel>
        <UiSelect
          {...props}
          {...input}
          isInvalid={invalid}
          onChangeHandler={onChange}
          value={value || input.value}
          isCreatable={isCreatable}
          defaultValue={input.value}
        />
        {invalid ? <FormErrorMessage>{error}</FormErrorMessage> : <FormHelperText>{content}</FormHelperText>}
      </FormControl>
    </Box>
  );
};
