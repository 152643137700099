import '../tariff-radio-buttons/loads-tariff-radio-buttons.scss';
import classNames from 'classnames';
import { Box, UseRadioProps, useRadio } from '@chakra-ui/react';
import { TariffTypeOptions, TariffTypes } from '@/types/models/loads';
import { useIcons } from '@/hooks/use-icons';
import { EMPTY_STRING } from '@/config/constants/common-strings';

export const LoadsTariffRadioButton = (props: UseRadioProps & { tariff: TariffTypes }) => {
  const { tariff, ...radioProps } = props;
  const { state, getInputProps, getRadioProps, getLabelProps } = useRadio(radioProps);
  const { Auction } = useIcons();

  const input = getInputProps();
  const checkbox = getRadioProps();
  const label = getLabelProps();

  const tariffTitle = TariffTypeOptions[tariff] || EMPTY_STRING;

  return (
    <Box as="label">
      <input {...input} />
      <Box {...checkbox} className={classNames('loads-tariff-radio-button', { 'loads-tariff-radio-button_checked': state.isChecked })} {...label}>
        {tariff === TariffTypes.Auction && <Auction />}
        {tariffTitle}
      </Box>
    </Box>
  );
};
