import { FC } from 'react';
import { useFormState } from 'react-final-form';
import { Grid, GridItem } from '@chakra-ui/react';
import { UiCheckboxField, UiInputField } from '@/ui-elements';
import { VerificationFormMode } from '@/components/verification-forms/types';

type CarrierAddressesSubformProps = {
  mode: VerificationFormMode;
};

export const CarrierAddressesSubform: FC<CarrierAddressesSubformProps> = ({ mode }) => {
  const { values } = useFormState();
  const addressesCoincide = values?.addressedCoincide;

  return (
    <>
      <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={3.5}>
        {mode !== 'check' && (
          <GridItem>
            <UiCheckboxField name="addressedCoincide" label="Почтовый адрес совпадает с юридическим" />
          </GridItem>
        )}
      </Grid>
      <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={3.5}>
        <GridItem>
          <UiInputField name="legalAddress" label="Юридический адрес" placeholder="Юридический адрес" isDisabled={mode === 'check'} required />
        </GridItem>
        {!addressesCoincide && (
          <GridItem>
            <UiInputField name="actualAddress" label="Почтовый адрес" placeholder="Почтовый адрес" isDisabled={mode === 'check'} />
          </GridItem>
        )}
      </Grid>
    </>
  );
};
