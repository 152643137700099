import { CarrierFormData } from 'components/verification-forms/types';
import { CarrierEdit } from '@/types/models/carrier';
import { EMPTY_STRING } from '@/config/constants/common-strings';
import { isSelectOption } from '@/ui-elements/inputs/ui-select/types';
import { isVatSelectOption } from '@/config/constants/types';

export const formValuesToCarrier = (values: CarrierFormData): CarrierEdit => {
  const bankDetails = {
    bankAccount: values.bankAccount || EMPTY_STRING,
    name: values.bankName || EMPTY_STRING,
    bik: values.bik || EMPTY_STRING,
    correspondentAccount: values.correspondentAccount || EMPTY_STRING,
    id: values?.bankDetailsId,
  };

  return {
    name: values.name,
    securityComment: values.securityComment,
    organizations: {
      vatType: isVatSelectOption(values?.vatType) ? values?.vatType?.value : undefined,
      organizationTypeID: isSelectOption(values?.organizationTypeID) ? Number(values?.organizationTypeID?.value) : undefined,
      name: values.name,
      INN: values.INN,
      KPP: values?.KPP,
      OGRN: values?.OGRN,
      OKPO: values?.OKPO,

      legalAddress: values?.legalAddress,
      actualAddress: values?.addressedCoincide ? values?.legalAddress : values?.actualAddress,

      directorName: values.directorName,
      directorSurname: values.directorSurname,
      directorPatronymic: values.directorPatronymic,
      birthday: values?.birthday?.toString(),

      passportNumber: values.passportNumber,
      passportDate: values.passportDate?.toString(),
      passportGiven: values.passportGiven,

      contactName: values?.contactName,
      contactSurname: values?.contactSurname,
      contactPatronymic: values?.contactPatronymic,
      phone: values.phone,
      bankDetails: [bankDetails],
      default: true,
    },
  };
};
