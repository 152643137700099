import { FC } from 'react';
import { OrderExecutionStatus, OrderStatus, PaymentOrderStatus } from 'config/constants/statuses';
import { NO } from 'config/constants/common-strings';
import { toast } from 'react-toastify';
import { getCarrierTotalWithVAT } from 'components/order-details/order-details-payment/helpers/getCarrierTotalWithVAT';
import { getCarrierTotalWithoutVAT } from 'components/order-details/order-details-payment/helpers/getCarrierTotalWithoutVAT';
import { VAT_EXISTENCE } from 'components/order-details/order-details-prepayment/texts';
import { LabelType, OrderDetailsPayment } from 'components/order-details/order-details-payment/order-details-payment';
import {
  ADDITIONAL_SERVICE,
  CARRIER_PAYMENT,
  COMMISSION,
  DID_NOT_ASK,
  NOT_PAYED,
  PAYMENT_DELAY,
  PAYMENT_ORDER,
  PAYMENT_REDUCED,
  PREPAYMENT,
  PRICE_FOR_MAIN_SERVICE,
  TOTAL_PAYED,
  TOTAL_TO_PAY,
  WILL_REDUCE_PAYMENT,
} from 'components/order-details/order-details-payment/texts';
import { VatType } from 'config/constants/types';
import { PaymentOrderStatusNames } from 'config/constants/texts';
import { isNil } from 'lodash';
import { Box, HStack } from '@chakra-ui/react';
import { VatWidget } from 'components/widgets/payment/vat-widget';
import { PRICES_WITH_VAT, PRICES_WITHOUT_VAT, VatTypeMap } from 'config/constants/vat-options';
import { useOrderDataActions } from '@/features/order/hooks/use-order-data-actions';
import { UiDivider } from '@/ui-elements';
import { UiText } from '@/ui-elements/ui-text/ui-text';
import { getDaysString } from '@/helpers/strings/get-days-string';
import { toPriceFormat } from '@/helpers/price/to-price-format';
import { ORDER_STATUS_NEW_AND_SEARCH, orderExtender } from '@/features/order/extender/order-extender';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { useOrderInvalidate } from '@/features/order/hooks/use-order-invalidate';
import { useOrderPayment } from '@/features/order/hooks/use-order-payment';
import { OrderDetailsPrepaymentView } from '@/views/order-details-prepayment-view/order-details-prepayment-view';
import { FileDocument } from '@/types/models/document';
import { getPaidStatusClassName } from '@/views/order-details-payment-view/order-details-payment-customer-view';
import { Order } from '@/types/models/order';
import { CarrierSimple } from '@/types/models/carrier';
import { Load } from '@/types/models/loads';

type Props = {
  attachments?: Array<FileDocument>;

  onSaveDocumentsClick?: VoidFunction;
  // загрузить документ оплаты в заявку
  onUploadPaymentDocuments?: (files: Array<File>) => void;

  // удалить документ оплаты из заявки
  onDeletePaymentDocumentClick?: (documentId: number) => void;

  // обработчик переключателя оплаты
  onTogglePayment?: VoidFunction;

  // обработчик переключателя оплаты услуги
  onToggleServicePayment: (id: number) => void;

  // показать переключатели оплаты
  showPaymentsToggler?: boolean;
  fuelReadOnly?: boolean;
  //
  order: Order | Load;
  orderRefetch: VoidFunction;
  selectedCarrier?: CarrierSimple;
  invalidateBookerOrdersCount: VoidFunction;
};

export const OrderDetailsPaymentCarrierView: FC<Props> = ({
  order,
  orderRefetch,
  selectedCarrier,
  onTogglePayment,
  onToggleServicePayment,
  showPaymentsToggler,
  onUploadPaymentDocuments,
  onSaveDocumentsClick,
  onDeletePaymentDocumentClick,
  fuelReadOnly,
  attachments,
  invalidateBookerOrdersCount,
}) => {
  const { allOrdersInvalidate } = useOrderInvalidate();

  const { setOrderPrepaymentPaid } = useOrderPayment(order.id);

  const togglePrepaymentPayment = () => {
    setOrderPrepaymentPaid.mutate(
      { paid: !order?.prepayment?.paid },
      {
        onSuccess: () => {
          orderRefetch();
          invalidateBookerOrdersCount();
        },
      }
    );
  };

  const { prepaymentValue, services, paymentOrderStatus, getFormattedPrice } = useExtendedObject(order, orderExtender);
  const carrierVatType: VatType | undefined = selectedCarrier?.vatType || VatType.CombinedVat;
  const { vatType } = order.carrierPayment;
  const mainPriceWithVAT = toPriceFormat(order.carrierPayment?.priceWithVAT);
  const mainPriceWithoutVAT = toPriceFormat(order.carrierPayment?.priceWithoutVAT);
  const paid = order.carrierPayment?.paid || false;
  const totalPriceWithVAT = toPriceFormat(getCarrierTotalWithVAT(order));
  const totalPriceWithoutVAT = toPriceFormat(getCarrierTotalWithoutVAT(order));
  const paymentDelay = getDaysString(order.carrierPayment?.carrierPaymentDelay ?? 0);

  const serviceLabels: Array<LabelType> =
    services?.map((service) => {
      const servicePaid = service.carrierPayment?.paid;
      return {
        label: ADDITIONAL_SERVICE,
        value: {
          text: getFormattedPrice('carrier'),
          className: 'price',
        },
        status: { text: servicePaid ? TOTAL_PAYED : NOT_PAYED, className: getPaidStatusClassName(servicePaid) },
        onToggle: () => onToggleServicePayment(service.id),
        hasToggler: true,
        toggleValue: servicePaid,
        key: service.id?.toString(),
      };
    }) || [];

  const { setCarrierVat } = useOrderDataActions(order.id);

  // Disabled если только в order одна цена
  const prices = [order.carrierPayment.priceWithVAT, order.carrierPayment.priceWithoutVAT];
  const isVatToggleDisabledPrices = prices.some(isNil) || prices.some((price) => price === 0);
  // Disabled если статус new или CarrierSearch
  const isVatToggleDisabledStatus = ORDER_STATUS_NEW_AND_SEARCH.includes(order.status);
  // Disabled при paid=true
  const isVatToggleDisabledOrderPaid = order.carrierPayment?.paid;
  // Disabled при VatType отличного от CombinedVat
  const vatTypes = [carrierVatType, vatType];
  const isVatToggleDisabledVatType = vatTypes.some((type) => type !== VatType.CombinedVat);
  // Disabled при FuelPossible true и статус не 'на оформлении'
  const isVatToggleDisabledFuelPossible =
    order.prepayment?.fuelPossibility && !([OrderExecutionStatus.Preparation] as OrderStatus[]).includes(order.status);
  const isVatToggleDisabled =
    isVatToggleDisabledVatType ||
    isVatToggleDisabledPrices ||
    isVatToggleDisabledOrderPaid ||
    isVatToggleDisabledStatus ||
    isVatToggleDisabledFuelPossible;

  // const isWithVat = vatTypes.some((type) => type === VatType.WithVat);
  const isWithVat = carrierVatType === VatType.WithVat;
  const onVatChange = () => {
    setCarrierVat.mutate(
      { vat: !isWithVat },
      {
        onSuccess: () => {
          orderRefetch();
          allOrdersInvalidate();
          toast.success('Статус НДС обновлен');
        },
      }
    );
  };

  const showWithVat = PRICES_WITH_VAT.includes(carrierVatType);
  const showWithoutVat = PRICES_WITHOUT_VAT.includes(carrierVatType);

  const properties: Array<LabelType> = [
    ...(showWithoutVat
      ? [
          {
            label: `${PRICE_FOR_MAIN_SERVICE} ${VatTypeMap.withoutVat}`,
            value: {
              text: mainPriceWithoutVAT,
            },
            hasToggler: true,
            toggleValue: paid,
            onToggle: onTogglePayment,
            status: { text: paid ? TOTAL_PAYED : NOT_PAYED, className: getPaidStatusClassName(paid) },
          },
          {
            label: `${TOTAL_TO_PAY} ${VatTypeMap.withoutVat}`,
            value: { text: totalPriceWithoutVAT },
          },
        ]
      : []),
    ...(showWithVat
      ? [
          {
            label: `${PRICE_FOR_MAIN_SERVICE} ${VatTypeMap.withVat}`,
            value: {
              text: mainPriceWithVAT,
            },
            hasToggler: true,
            toggleValue: paid,
            onToggle: onTogglePayment,
            status: { text: paid ? TOTAL_PAYED : NOT_PAYED, className: getPaidStatusClassName(paid) },
          },
          { label: `${TOTAL_TO_PAY} ${VatTypeMap.withVat}`, value: { text: totalPriceWithVAT } },
        ]
      : []),
    ...serviceLabels,
    ...(order.prepayment?.possibility
      ? [
          {
            label: PREPAYMENT,
            value: { text: prepaymentValue || '-' },
            status: {
              // eslint-disable-next-line no-nested-ternary
              text: prepaymentValue ? (order.prepayment?.paid ? TOTAL_PAYED : NOT_PAYED) : DID_NOT_ASK,
              className: prepaymentValue ? getPaidStatusClassName(order.prepayment?.paid) : '',
            },
            hasToggler: !!prepaymentValue,
            toggleValue: order.prepayment?.paid,
            onToggle: togglePrepaymentPayment,
          },
          {
            label: COMMISSION,
            value: { text: order.prepayment?.tax ? toPriceFormat(order.prepayment?.tax) : '-' },
            status: {
              // eslint-disable-next-line no-nested-ternary
              text: !order.prepayment?.tax ? NO : paid ? PAYMENT_REDUCED : WILL_REDUCE_PAYMENT,
              className: paid ? 'green-status' : '',
            },
          },
        ]
      : []),
    {
      label: PAYMENT_ORDER,
      status: {
        text: PaymentOrderStatusNames[paymentOrderStatus],
        className: getPaidStatusClassName(PaymentOrderStatus.Paid === paymentOrderStatus, PaymentOrderStatus.Ready === paymentOrderStatus),
      },
    },
    /** Отсрочка платежа для клиента (заполняется при создании клиента) или исполнителя (заполняется при создании заявки) */
    {
      label: PAYMENT_DELAY,
      value: { text: paymentDelay },
    },
  ];

  return (
    <>
      <>
        <UiDivider mb={5} />
        <Box>{`У заявки ${VatTypeMap[vatType || VatType.CombinedVat]}`}</Box>
        <UiDivider mb={5} />
        <HStack mb={4} gap={38}>
          <UiText color="typography.400" fontSize="2xl" ml={5}>
            {VAT_EXISTENCE}
          </UiText>
          <Box ml={85}>
            <VatWidget vat={isWithVat} isVatToggleDisabled={isVatToggleDisabled} onVatChange={onVatChange} />
          </Box>
        </HStack>
      </>
      <OrderDetailsPrepaymentView orderRefetch={orderRefetch} order={order} vat={isWithVat} />
      <UiDivider mb={5} />
      <OrderDetailsPayment
        order={order}
        fuelReadOnly={fuelReadOnly}
        onSaveDocumentsClick={onSaveDocumentsClick}
        onUploadPaymentDocuments={onUploadPaymentDocuments}
        onDeletePaymentDocumentClick={onDeletePaymentDocumentClick}
        showPaymentsToggler={showPaymentsToggler}
        attachments={attachments}
        properties={properties}
        header={`${CARRIER_PAYMENT} (${VatTypeMap[carrierVatType || VatType.CombinedVat]})`}
      />
    </>
  );
};
