import { FC } from 'react';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { ModalView } from '@/views/order-modal/order-modal-view';
import { ModalsProps } from '@/types/common/modals';
import { emptyFunction } from '@/helpers/empty-function';

export const ModalConfirm: FC<
  ModalsProps<{
    text?: string;
    modalWidth?: number;
    marginRight?: number;
    submitButtonText?: string;
    cancelButtonText?: string;
    desc?: string;
    alignDesc?: 'center' | 'left' | 'right';
    accentXBtn?: boolean;
  }>
> = ({
  modalOpened,
  text,
  onSuccess = emptyFunction,
  onCancel = emptyFunction,
  onClose,
  modalWidth = 424,
  submitButtonText = 'Да',
  cancelButtonText = 'Нет',
  desc,
  alignDesc,
  accentXBtn,
}) => {
  const onSuccessConfirm = () => {
    onSuccess();
    onClose();
  };
  const onCancelConfirm = () => {
    onCancel();
    onClose();
  };
  return (
    <ModalView
      isOpen={modalOpened}
      onClose={onCancelConfirm}
      title={text || EMPTY_STRING}
      onSubmit={onSuccessConfirm}
      submitButtonText={submitButtonText}
      cancelButtonText={cancelButtonText}
      modalWidth={modalWidth}
      justifyTitle="left"
      desc={desc}
      alignDesc={alignDesc}
      accentXBtn={accentXBtn}
    />
  );
};
