import { CustomersApi } from '@/api/customers';
import { useQueryWithUpdates } from '@/hooks/use-query-with-updates';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';
import { Customer } from '@/types/models/customer';

export const useCustomerUpdates = (id?: number) => {
  const customer = useQueryWithUpdates<ResponseWithoutPagerSimple<Customer>>({
    queryKey: ['customer', id],
    enabled: !!id,
    refetchOnWindowFocus: false,
    queryFn: (isFirst: boolean) => CustomersApi.getCustomer(id!, !isFirst),
  });
  return { customer };
};
