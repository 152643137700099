import { useCallback, useRef } from 'react';
import { useOutsideClick } from '@chakra-ui/react';

export const useOutsideCalendarClick = () => {
  const outsideRef = useRef(null);
  const handleDatePickerClose = useCallback(() => (outsideRef?.current as any)?.closeCalendar(), [outsideRef]);
  useOutsideClick({
    ref: outsideRef,
    handler: handleDatePickerClose,
  });
  return { outsideRef };
};
