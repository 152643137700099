import { useCallback } from 'react';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { myOrganizationsActions } from '@/features/my-organizations/slice';

export const useMyOrganizationsSelect = () => {
  const dispatch = useAppDispatch();
  const select = (myOrganizationsId: number) => {
    dispatch(myOrganizationsActions.select(myOrganizationsId));
  };
  const unselect = useCallback(() => {
    dispatch(myOrganizationsActions.unselect());
  }, [dispatch]);

  return { select, unselect };
};
