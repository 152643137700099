import { FC } from 'react';
import { RegNumber } from 'components/widgets/vehicle/reg-number/reg-number';
import { VehicleType } from 'components/widgets/vehicle/vehicle-type/vehicle-type';
import { Flex } from '@chakra-ui/react';
import { Vehicle } from '@/types/models/vehicle';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { vehicleExtender } from '@/features/vehicles/extender/vehicle-extender';
import { useVehicleTypesNames } from '@/features/vehicle-types/hooks/use-vehicle-types-names';
import { useVehicleTypesIcons } from '@/features/vehicle-types/hooks/use-vehicle-types-icons';

type Props = {
  vehicle: Vehicle;
};

export const VehicleWidget: FC<Props> = ({ vehicle }) => {
  const { vehicleRegNumber, vehicleRegion } = useExtendedObject(vehicle, vehicleExtender);
  const vehicleTypeId = vehicle.vehicleType;
  const vehicleTypesNames = useVehicleTypesNames();
  const vehicleTypesIcons = useVehicleTypesIcons();

  return (
    <Flex align="end" gap="1em">
      <VehicleType vehicleTypeIcon={vehicleTypesIcons[vehicleTypeId]} vehicleTypeName={vehicleTypesNames[vehicleTypeId]} />
      <RegNumber regNumber={vehicleRegNumber} region={vehicleRegion} />
    </Flex>
  );
};
