import { OrdersApi } from '@/api/orders';
import { useQueryWithUpdates } from '@/hooks/use-query-with-updates';
import { OrderFuelCount } from '@/types/api/order-partial';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';

export const useOrdersFuelCountUpdates = () => {
  const queryFn = (isFirst: boolean) => OrdersApi.getOrdersFuelCount(!isFirst);

  const ordersFuelCount = useQueryWithUpdates<ResponseWithoutPagerSimple<OrderFuelCount>>({
    queryKey: ['ordersFuelCount'],
    refetchOnWindowFocus: false,
    queryFn,
  });
  return { ordersFuelCount };
};
