import { OrderExecutionStatus } from 'config/constants/statuses';
import { PRICES_WITH_VAT, PRICES_WITHOUT_VAT } from 'config/constants/vat-options';
import { VatType } from 'config/constants/types';
import { OrderOffer } from '@/types/models/order-offer';
import { Order } from '@/types/models/order';

export type OrderStatuses = Order['status'];

const VISIBLE_PRICE_STATUS: Array<OrderStatuses> = [OrderExecutionStatus.New, OrderExecutionStatus.CarrierSearch];

export const handlePriceChipVisibility =
  ({
    carrierAcceptedOffer,
    carrierPayment,
    status,
  }: {
    carrierAcceptedOffer?: OrderOffer | undefined;
    carrierPayment: Order['carrierPayment'];
    status: OrderStatuses;
  }) =>
  ({ vat }: { vat: boolean }): boolean => {
    const checkArray = vat ? PRICES_WITH_VAT : PRICES_WITHOUT_VAT;
    const carrierVatType = carrierAcceptedOffer?.carrier.vatType;
    if (carrierVatType) return checkArray.includes(carrierVatType);
    if (VISIBLE_PRICE_STATUS.includes(status)) return true;
    return checkArray.includes(carrierPayment.vatType || VatType.CombinedVat);
  };
