import { ChakraStylesConfig } from 'chakra-react-select';
import { getBorderColor, getPlaceholderColor } from './helpers';

export const chakraStyles: ChakraStylesConfig = {
  dropdownIndicator: (prev, { selectProps, hasValue }) =>
    hasValue
      ? {
          display: 'none',
        }
      : {
          ...prev,
          '> svg': {
            transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
          },
        },
  downChevron: (provided, { isDisabled }) => ({
    ...provided,
    color: isDisabled ? 'neutrals.200' : 'neutrals.100',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: getPlaceholderColor(state),
  }),
  container: (provided, { selectProps }) => ({
    ...provided,
    background: selectProps.isDisabled ? 'neutrals.500' : 'neutrals.350',
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor: getBorderColor(state),
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'neutrals.50',
      boxShadow: 'none',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? 'products.200' : 'neutrals.350',
    _hover: {
      background: state.isSelected ? 'products.200' : 'products.400',
    },
  }),
};
