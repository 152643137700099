import { FC } from 'react';
import { isEmpty, noop } from 'lodash';
import { Flex } from '@chakra-ui/react';
import { UiText } from '@/ui-elements/ui-text/ui-text';
import { UiDownloadCard, UiFileUploadInput } from '@/ui-elements';
import { useForm } from '@/helpers/forms/use-form';
import { useCarriersLoadsApplicationAgreement } from '@/features/carriers-loads/hooks/use-carriers-loads-application-agreement';
import {
  CarrierLoadApplicationAgreementFieldNames,
  CarrierLoadApplicationAgreementsFormLabels,
  CarrierLoadApplicationAgreementsFormValues,
} from '@/features/carriers-loads/types/form';

type Props = {
  exist?: boolean;
  accepted?: boolean;
  orderId?: number;
};

/* exist - true: статус НЕ на оформлении (Preparation) */
/* ничего нельзя, можно только скачать подписанный документ */
/* exist - false */
/* можно удалять, скачивать шаблон, загружать новые документы */

export const ApplicationAgreement: FC<Props> = ({ exist = false, orderId, accepted = false }) => {
  const { applicationAgreementOriginalDocs, applicationAgreementSigned } = useCarriersLoadsApplicationAgreement({ orderId });
  const Form = useForm<CarrierLoadApplicationAgreementsFormValues>();

  if (!accepted) return null;

  return (
    <Form onSubmit={noop}>
      <Flex direction="column" gap="1em">
        <UiText textStyle="h3" children={exist ? 'Договор-заявка' : 'Теперь нужно подписать договор-заявку'} />
        <UiDownloadCard
          doc={applicationAgreementOriginalDocs?.attachments?.[0]}
          fileName="Скачайте документы к подписанию"
          disabled={isEmpty(applicationAgreementOriginalDocs?.attachments)}
        />
        {!exist && <UiText textStyle="h3" children={CarrierLoadApplicationAgreementsFormLabels.applicationAgreementSigned} />}
        <UiFileUploadInput
          name={CarrierLoadApplicationAgreementFieldNames.applicationAgreementSigned}
          savedFileList={applicationAgreementSigned?.attachments ?? []}
          onDeleteFileClick={!exist ? applicationAgreementSigned?.deleteAttachmentById : undefined}
          onIconClick={applicationAgreementSigned?.downloadAttachmentById}
          onImmediateUploadFile={applicationAgreementSigned?.postAttachments}
          disableUpload={exist}
          required
        />
      </Flex>
    </Form>
  );
};
