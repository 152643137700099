import { FC } from 'react';
import { ModalView } from '@/views/order-modal/order-modal-view';
import { ModalsProps } from '@/types/common/modals';
import { useDriverActions } from '@/features/drivers/hooks/use-driver-actions';
import { useCarrierInvalidate } from '@/features/carriers/hooks/use-carrier-invalidate';
import { useAppSelector } from '@/hooks/use-app-selector';
import { selectedCarrierIdSelector } from '@/features/carriers/selectors';

export const ModalDeleteDriver: FC<
  ModalsProps<{
    text?: string;
    modalWidth?: number;
    marginRight?: number;
    submitButtonText?: string;
    cancelButtonText?: string;
    desc?: string;
    alignDesc?: 'center' | 'left' | 'right';
    accentXBtn?: boolean;
    driverID?: number;
    carrierId?: number;
    onSuccess?: VoidFunction;
  }>
> = ({ modalOpened, onClose, desc, alignDesc, driverID, modalWidth = 524, onSuccess: handleSuccessDelete }) => {
  const { deleteDriver } = useDriverActions(driverID);
  const id = useAppSelector(selectedCarrierIdSelector);
  const { carrierInvalidate } = useCarrierInvalidate();
  const { allCarriersInvalidate } = useCarrierInvalidate();

  const onSubmit = () => {
    deleteDriver.mutate(driverID, {
      onSuccess: () => {
        handleSuccessDelete?.();
        allCarriersInvalidate(() => {
          carrierInvalidate(id);
          onClose();
        });
      },
    });
  };

  return (
    <ModalView
      isOpen={modalOpened}
      onClose={onClose}
      title="Вы действительно хотите удалить водителя?"
      onSubmit={onSubmit}
      submitButtonText="Удалить водителя"
      desc={desc}
      alignDesc={alignDesc}
      modalWidth={modalWidth}
      justifyTitle="left"
    />
  );
};
