import { isEmpty } from 'lodash';
import { Driver } from '@/types/models/driver';
import { MyOrganizationBanking, MyOrganizationType } from '@/types/models/my-organization/my-organization';
import { getDefaultBankDetails } from '@/features/my-organization/helper/get-default-bank-details';
import { getDefaultDriverDetails } from '@/features/my-organization/helper/get-default-driver-details';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { getSelectOptionValue } from '@/helpers/select-options/get-select-option-form-value';
import { checkHasVat } from '@/helpers/price/check-has-vat';

type Props = {
  data: (MyOrganizationType & { bankDetails?: MyOrganizationBanking[] }) | undefined;
  driver?: Driver;
  isCarrierSimple?: boolean;
  organizationTypes: Array<SelectOption>;
};

export const getDataToFormValues = ({ data, driver, isCarrierSimple, organizationTypes }: Props) => {
  const currentData = { ...(data ?? {}) };
  const bankDetails = getDefaultBankDetails(currentData?.bankDetails ?? []) ?? ({} as MyOrganizationBanking);
  if (!isEmpty(currentData)) {
    const formBankDetails = {
      ...bankDetails,
      bank: bankDetails?.name,
    };
    delete formBankDetails.name;
    delete currentData?.bankDetails;

    const driverDetails = getDefaultDriverDetails(driver);
    return {
      ...currentData,
      ...formBankDetails,
      ...driverDetails,
      organizationTypeID: getSelectOptionValue(currentData?.organizationTypeID, organizationTypes),
      vat: checkHasVat(currentData.vatType) ? 1 : 0,
    };
  }
  if (isCarrierSimple) {
    return {
      organizationTypeID: organizationTypes?.find(({ label }) => label === 'ИП'),
    };
  }
  return {};
};
