import React, { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { BookerOrdersTabs } from 'components/booker-orders/booker-orders-filters/booker-orders-tabs';
import { BookerOrdersSwitcher } from 'components/booker-orders/booker-orders-filters/booker-orders-switcher';
import { labelsDetails } from 'components/booker-orders/booker-orders-filters/labels';
import { BookerOrderDetailsTabs, BookerOrderSwitcherStatus } from 'config/constants/statuses';
import { BookerOrdersSearch } from 'components/booker-orders/booker-orders-filters/booker-orders-search';
import { SPACING_20 } from 'components/booker-orders/booker-orders-filters/constants';
import { BookerOrdersSearchDebt } from 'components/booker-orders/booker-orders-filters/booker-orders-search-debt';
import { Tab } from '@/ui-elements/switcher/types';
import './booker-order-filters.scss';
import { emptyFunction } from '@/helpers/empty-function';
import { BookerFilterFields } from '@/features/booker/hooks/booker-filter';
import { Summary } from '@/types/models/booker-order';

/*
 * Учет оплаты рейсов filters
 * */
type Props = {
  switcherLabels: Tab[];
  switcherLabelsCustomer: Tab[];
  activeTab: BookerOrderSwitcherStatus;
  onChange: (value: string) => void;
  onChangeTabs: (activeKey: any) => void;
  tab?: string;
  handleFilterChange?: (values: BookerFilterFields) => void;
  allSummary?: Summary;
};
export const BookerOrdersFilters: FC<Props> = ({
  switcherLabels,
  activeTab,
  onChange = emptyFunction,
  tab,
  onChangeTabs = emptyFunction,
  handleFilterChange = emptyFunction,
  switcherLabelsCustomer,
  allSummary,
}) => {
  return (
    <Flex flexDirection="column" mb="16px" className="booker-order-filter-container">
      <Flex>
        <Box w="50%" mt={SPACING_20} mr="24px" ml={SPACING_20}>
          <BookerOrdersTabs switcherLabels={labelsDetails} onChange={onChange} />
        </Box>
        <Box w="50%" mt="14px" mr={SPACING_20}>
          <BookerOrdersSearch handleFilterChange={handleFilterChange} />
        </Box>
      </Flex>
      {tab === BookerOrderDetailsTabs.Carrier ? (
        <BookerOrdersSwitcher switcherLabels={switcherLabels} activeTab={activeTab} onChange={onChangeTabs} />
      ) : (
        // TODO будет другой список фильтров (пока нет макетов)
        <Box>
          <Flex gap={4} mr={SPACING_20} ml={SPACING_20}>
            <Box mt={2}>
              <BookerOrdersSwitcher switcherLabels={switcherLabelsCustomer} activeTab={activeTab} onChange={onChangeTabs} />
            </Box>
            {activeTab === BookerOrderSwitcherStatus.FinallySettlement && (
              <Box>
                <BookerOrdersSearchDebt handleFilterChange={handleFilterChange} />
              </Box>
            )}
          </Flex>
          {activeTab === BookerOrderSwitcherStatus.FinallySettlement && (
            <Flex gap={2} mr={SPACING_20} ml={SPACING_20}>
              <Text>Найдено:</Text>
              <Text>заявок - {allSummary?.find};</Text>
              <Text>клиентов - {allSummary?.customers};</Text>
              <Text>Общая сумма задолженности: {allSummary?.debt}руб</Text>
            </Flex>
          )}
        </Box>
      )}
    </Flex>
  );
};
