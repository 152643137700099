import { PageWrapper } from 'pages/page-wrapper';
import { Flex } from '@chakra-ui/react';
import { UiUnderConstruct } from '@/ui-elements';

export const UnderConstructPage = () => (
  <PageWrapper>
    <Flex justifyContent="center" alignItems="centers">
      <UiUnderConstruct />
    </Flex>
  </PageWrapper>
);
