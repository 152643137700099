// @ts-nocheck
import { isNumber } from 'lodash';
import { initialResponsiblePerson } from 'components/create-order-form/initial-values';
import { OrderCreateSource, OrderNew } from '@/types/api/order-partial';
import { FormValues } from '@/components/create-order-form/schema';
import { extractValue } from '@/helpers/extract-value';
import { extractPointsCargosActions } from '@/helpers/forms/point-cargo-actions/extract-point-cargos-actions';

export const normalizeFormValues = (values: FormValues): OrderNew => {
  // @ts-ignore
  const vType = values.vehicleType?.vehicleTypes?.map((vt) => {
    return isNumber(vt) ? vt : vt.value;
  });

  const { pointsResult, cargosResult, actionsResult } = extractPointsCargosActions(values.route.points);
  return {
    customerID: values.orderInfo?.customerCompany?.value,
    contact: {
      name: values.orderInfo?.contactPerson,
      phone: (values.orderInfo as any)?.contactPhone,
    },
    responsiblePerson: {
      id: values.orderInfo?.responsiblePerson?.value ?? initialResponsiblePerson.value,
      name: values.orderInfo?.responsiblePerson?.label,
    },
    transportationType: values.vehicleType?.loadType,
    riskGroup: values.orderInfo?.riskGroup?.value,
    type: values.route?.routeType,
    points: pointsResult,
    cargos: cargosResult,
    actions: actionsResult,
    additionalRequirements: {
      belts: values.vehicleType?.belts,
      tonnage: values.vehicleType?.tonnage?.value,
      additionalRequirements: values.vehicleType?.additionalRequirements || '',
    },
    permissions: {
      TIR: values.vehicleType.TIR,
      CMR: values.vehicleType.CMR,
      T1: values.vehicleType.T1,
      medicalBook: values.vehicleType.medicalBook,
    },
    customerPayment: {
      price: values.clientPaymentTerms?.price,
      vat: values.clientPaymentTerms?.vat?.value,
      paymentConditions: values.clientPaymentTerms?.paymentTerms,
    },
    carrierPayment: {
      priceWithVAT: values.carrierPaymentTerms?.priceWithVAT,
      priceWithoutVAT: values.carrierPaymentTerms?.priceWithoutVAT,
      paymentConditions: values.carrierPaymentTerms?.paymentConditions,
      priceOffer: values.carrierPaymentTerms.priceOffer,
    },
    prepayment: {
      possibility: Boolean(values.carrierPaymentTerms?.hasPrepayment),
      fuelPossibility: Boolean(values.carrierPaymentTerms?.hasFuel),
    },
    fromSource: OrderCreateSource.ILSExecution,
    vehicleTypes: vType || [],
    loadingType: (values.vehicleType?.loadingType || []).map(extractValue),
  };
};
