import { useAppSelector } from '@/hooks/use-app-selector';
import { activeOrderSelector } from '@/features/order/selectors/active-order-selector';
import { activeOrdersArraySelector } from '@/features/order/selectors/acitve-order-array-selector';
import { isEmptyOrderListSelector } from '@/features/order/selectors';

export const useActiveOrder = () => {
  const activeOrderId = useAppSelector(activeOrderSelector);
  const activeOrdersArray = useAppSelector(activeOrdersArraySelector);
  const isEmptyOrderList = useAppSelector(isEmptyOrderListSelector);
  return { activeOrderId, activeOrdersArray, isEmptyOrderList };
};
