import { useState } from 'react';
import { isEqual } from 'lodash';
import { BackForwardButtonsDisabledType, ButtonDirection } from '@/types/models/funnel';
import { initialButtonsDisabledState } from '@/config/constants/funnel';

export const useBackForwardButtonsDisabled = () => {
  const [buttonsDisabled, setButtonsDisabled] = useState<BackForwardButtonsDisabledType>(initialButtonsDisabledState);

  const resetButtonsDisabledState = () => {
    if (!isEqual(initialButtonsDisabledState, buttonsDisabled)) {
      setButtonsDisabled(initialButtonsDisabledState);
    }
  };

  const handleChangeButtonDisabledState = (direction: ButtonDirection, value: boolean) => {
    setButtonsDisabled({ ...buttonsDisabled, [direction]: value });
  };

  return { buttonsDisabled, handleChangeButtonDisabledState, resetButtonsDisabledState };
};
