import { OrderServicesApi } from '@/api/order-services';
import { useQueryWithUpdates } from '@/hooks/use-query-with-updates';
import { OrderService } from '@/types/models/order-service';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';

type Props = {
  id?: number;
  staleTime?: number;
};

export const useOrderServicesUpdates = ({ id, staleTime }: Props) => {
  const queryFn = (isFirst: boolean) => OrderServicesApi.getOrderService(id!, { updates: !isFirst });
  const orderService = useQueryWithUpdates<ResponseWithoutPagerSimple<[OrderService]>>({
    queryKey: ['orderService', id],
    enabled: !!id,
    staleTime,
    queryFn,
  });
  return { orderService };
};
