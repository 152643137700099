import { FC } from 'react';
import classNames from 'classnames';
import { HStack } from '@chakra-ui/react';
import { INSURANCE_TITLE, SAVE_DOCS } from 'components/order-details/order-details-insurance/texts';
import { Order } from '@/types/models/order';
import './order-details-insurance.scss';
import { UiButtonOutlined, UiFileUploadInput } from '@/ui-elements';
import { useDependentAttachments } from '@/features/attachments/hooks/use-dependent-attachments';
import { AttachmentDeps } from '@/config/constants/types';
import { DocumentTypes } from '@/config/constants/document-types';
import { Load } from '@/types/models/loads';

/**
 * Блок Страховка на вкладке Заказ Клиента и Заказ Исполнителя
 * */
export const OrderDetailsInsurance: FC<{
  order: Order | Load;
  // заблокировать загрузку документов
  disableDocumentUpload?: boolean;
  fuelReadOnly?: boolean;
}> = ({ order, disableDocumentUpload, fuelReadOnly = false }) => {
  const {
    attachments: attachmentsInsurance,
    deleteAttachmentById: deleteAttachmentInsuranceById,
    postAttachments: postAttachmentsInsurance,
    downloadAttachments: downloadAttachmentsInsurance,
  } = useDependentAttachments({
    dependent: AttachmentDeps.Order,
    dependentId: order.id,
    type: DocumentTypes.dlDocumentOrderInsurance,
  });

  return (
    <div className={classNames('order-details-insurance')}>
      <div className="order-details-insurance__title"> {INSURANCE_TITLE}</div>
      <UiFileUploadInput
        name="file"
        disabled={fuelReadOnly}
        savedFileList={attachmentsInsurance}
        onDeleteFileClick={deleteAttachmentInsuranceById}
        onImmediateUploadFile={postAttachmentsInsurance}
        onIconClick={downloadAttachmentsInsurance}
        disableUpload={disableDocumentUpload}
      />
      <HStack className="order-details-insurance__actions">
        <UiButtonOutlined disabled={!order.documents.length || fuelReadOnly} onClick={downloadAttachmentsInsurance}>
          {SAVE_DOCS}
        </UiButtonOutlined>
      </HStack>
    </div>
  );
};
