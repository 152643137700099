import { useQuery } from '@tanstack/react-query';
import { VehicleTypesApi } from '@/api/vehicle-types';

export const useVehicleTypes = () => {
  const vehicleTypes = useQuery({
    queryKey: ['vehicleTypes'],
    refetchOnWindowFocus: false,
    enabled: false,
    queryFn: () => VehicleTypesApi.getVehicleTypes(),
  });
  return { vehicleTypes };
};
