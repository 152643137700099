import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { ResponseError } from '@/types/api/response-error';

export const handleAxiosError = (err: any, rejectWithValue: (value: ResponseError) => any) => {
  const axiosError = err as unknown as AxiosError<any>;
  const responseData = axiosError?.response?.data as { error: ResponseError };
  const payload = responseData.error || axiosError.message;
  if (axiosError?.response?.status !== 401) {
    toast.error(Array.isArray(payload) ? payload[0] : payload, { toastId: 'auth_error' });
  }
  return rejectWithValue(payload);
};
