export const BRANCH = 'Наш филиал';
export const CARRIER_FILTERS = 'Похожие фильтры у перевозчика';
export const FREEDOM = 'Только свободные';
export const TYPE_TC_2 = 'Тип ТС можно несколько';
export const WEIGHT = 'Вес груза в тоннах';
export const VOLUME = 'Объем м³';
export const GROUP_RISK = 'Группа риска';
export const FROM = 'Откуда (город, населенный пункт)';
export const WHERE = 'Куда (город, населенный пункт)';
export const RADIUS = 'Радиус поиска от 1 точки';
export const RADIUS_PLACEHOLDER = '0 км';
export const DIRECTION = 'Начните писать название';
export const CONTENT_TYPE_TS = 'Начните писать название типа или выбирайте из списка';
export const GROUP_RISK_PLACEHOLDER = '1';
