import { FC } from 'react';
import './search-carrier-filter.scss';
import { Flex, Text } from '@chakra-ui/react';
import { useAppSelector } from '@/hooks/use-app-selector';
import { activeOrderIdsSelector } from '@/features/order/selectors';

type Props = {
  isExpanded: boolean;
};
export const SearchCarrierFilterTitle: FC<Props> = () => {
  const activeOrderIds = useAppSelector(activeOrderIdsSelector);
  return (
    <Flex justifyContent="space-between" className="search-carrier-filter-title">
      <Text className="search-carrier-filter-title-text">Поиск исполнителя заявки № {activeOrderIds}</Text>
    </Flex>
  );
};
