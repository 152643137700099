import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { getBackGroundColor } from 'components/customers/customers-managers-list/helpers/get-color';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { getEmptyStr } from '@/helpers/get-empty-str';
import { UiStatus } from '@/ui-elements/status/status';
import { ManagerSimple } from '@/types/models/manager';

type Props = {
  manager: ManagerSimple;
  max: number;
  min: number;
  amount: number;
};

export const CustomersManager: FC<Props> = ({ manager, max, min, amount }) => {
  const backGroundColor = getBackGroundColor(manager.customerQuantity || 0, max, min, amount);
  const managerName = `${getEmptyStr(manager?.surname)} ${getEmptyStr(manager.name)} ${getEmptyStr(manager?.patronymic)}`;

  return (
    <Flex className="customer-manager" justifyContent="space-between">
      <Text>{managerName}</Text>
      <UiStatus text={manager.customerQuantity?.toString() || EMPTY_STRING} backGroundColor={backGroundColor} color="#FFFFFF" />
    </Flex>
  );
};
