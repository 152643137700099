import { Dictionary } from 'config/constants/dictionary';
import { ApiGet } from '@/helpers/api/methods';
import { ResponseWithPager, ResponseWithoutPagerSimple } from '@/types/models/pager';
import { BookerOrderCount, BookerOrderFilter } from '@/types/api/booker-order-partial';
import { BookerOrder } from '@/types/models/booker-order';
import { validateQueryStringAndCleanEmpty } from '@/helpers/validate-query-string-and-clean-empty';
import { BookerOrderCountContractor } from '@/features/booker/types/booker-order-count';
import { stringifyQuery } from '@/helpers/validate-query-string';

export const BookerOrdersApi = {
  getAllBookerOrders: (query?: BookerOrderFilter) =>
    ApiGet<ResponseWithPager<BookerOrder>>(`${Dictionary.Orders}/bookerList${stringifyQuery(query)}`),
  getBookerOrdersCount: (query: BookerOrderCountContractor) =>
    ApiGet<ResponseWithoutPagerSimple<BookerOrderCount>>(`${Dictionary.Orders}/bookerList/count${validateQueryStringAndCleanEmpty(query)}`),
  getBookerOrder: (id: number) => ApiGet<ResponseWithoutPagerSimple<BookerOrder>>(`${Dictionary.Orders}/${id}`),
};
