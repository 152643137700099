import { CustomersFilter } from 'components/customers/сustomers-filter/customers-filter';
import { CustomersTableComponent } from 'components/customers/customers-table-component/customers-table-component';
import { useCallback, useState } from 'react';
import { CustomersCreateFormValues } from 'components/create-order-form/schema';
import { toast } from 'react-toastify';
import { useCustomerActions } from '@/features/customers/hooks/use-customer-actions';
import { useAppSelector } from '@/hooks/use-app-selector';
import { activeCustomerIdSelector } from '@/features/customers/selectors/active-customer-id-selector';
import { CustomerFilter } from '@/types/api/customer-partial';
import { CustomersFilterFields } from '@/features/customers/hooks/customers-filter';
import { useAccount } from '@/features/account/hooks/use-account';
import { useCustomerSelect } from '@/features/customers/hooks/use-customer-select';
import { useGetQueryId } from '@/hooks/use-get-query-id';
import { useManagersInvalidate } from '@/features/managers/use-managers-invalidate';
import { useCreateApproval } from '@/features/approvals/hooks/use-create-approval';
import { useAllCustomersUpdates } from '@/features/customers/hooks/use-all-customers-updates';
import { useManagersShortUpdates } from '@/features/managers/use-managers-short-updates';

export const CustomersListView = () => {
  const { addCustomer } = useCustomerActions();
  const { select, unselect } = useCustomerSelect();
  const activeCustomersId = useAppSelector(activeCustomerIdSelector);
  const { currentRole } = useAccount();
  const [isActiveForm, setIsActiveForm] = useState(false);
  const shortManagers = useManagersShortUpdates();
  const customerId = useGetQueryId();
  const { currentAccount } = useAccount();
  const { createApproval } = useCreateApproval();

  const [filters, setFilters] = useState<CustomerFilter>({
    search: undefined,
    customerIds: customerId ? Number(customerId) : undefined,
    managerIds: undefined,
  });
  const { invalidateAllManagers } = useManagersInvalidate();

  const {
    data: customers,
    fetchNextPage,
    isFetchingNextPage,
    refetch: customersRefetch,
    isInitialLoading,
  } = useAllCustomersUpdates({ query: filters });
  const allRecords = customers?.pages ? customers.pages.flatMap((d) => d.data) : [];

  const addCustomerFunction = (values: CustomersCreateFormValues) => {
    const req = {
      name: values.customer,
      INN: values.inn,
      contractNumber: values.contract,
      expiration: values.date,
      managerId: values.manager?.value,
      comment: values.comment,
      tariffComment: values.tarif,
      contactNumber: '',
      vipCustomer: values.vipCustomer,
    };
    addCustomer.mutate(req, {
      onSuccess: (response) => {
        createApproval.mutate({
          customerId: response.data.id,
          departmentId: currentAccount?.user?.department?.id,
        });
        toast.success('Клиент добавлен');
        invalidateAllManagers();
        customersRefetch();
        unselect();
      },
    });
    setIsActiveForm(false);
  };

  const handleFilterChange = useCallback((values: CustomersFilterFields) => {
    setFilters({
      search: values?.companyName ? values?.companyName : undefined,
      customerIds: values?.customer?.value,
      managerIds: values?.manager?.value,
    });
  }, []);
  const showForm = () => {
    setIsActiveForm(true);
    unselect();
  };

  return (
    <div className="customers-list-view">
      <CustomersFilter managersSelect={shortManagers} showForm={showForm} handleFilterChange={handleFilterChange} role={currentRole} />
      <CustomersTableComponent
        data={allRecords}
        onSelectCustomers={select}
        onUnSelectCustomers={unselect}
        addCustomer={addCustomerFunction}
        selectedCustomersId={activeCustomersId}
        isActiveForm={isActiveForm}
        closeForm={() => setIsActiveForm(false)}
        shortManagers={shortManagers}
        role={currentRole}
        customersRefetch={customersRefetch}
        loadMore={fetchNextPage}
        loading={isFetchingNextPage}
        currentAccount={currentAccount}
        isInitialLoading={isInitialLoading}
        submitting={addCustomer.isLoading}
      />
    </div>
  );
};
