import { TemplateContractFormFields, TemplateContractFormUiElements } from '@/features/contract-template/types/form';
import { UiInputField, UiPhoneField } from '@/ui-elements';

export const CLIENT_CONTRACT_TEMPLATE_LABEL: Record<TemplateContractFormFields, string> = {
  [TemplateContractFormFields.Sender]: 'Грузоотправитель',
  [TemplateContractFormFields.Receiver]: 'Грузополучатель',
  [TemplateContractFormFields.SourceContactName]: 'Контактное лицо',
  [TemplateContractFormFields.SourceContactNumber]: 'Телефон контактного лица',
  [TemplateContractFormFields.TargetContactName]: 'Контактное лицо',
  [TemplateContractFormFields.TargetContactNumber]: 'Телефон контактного лица',
};

export const CLIENT_CONTRACT_CONTENT_TEXT: Partial<Record<TemplateContractFormFields, string>> = {
  [TemplateContractFormFields.SourceContactName]: 'на точке погрузки',
  [TemplateContractFormFields.TargetContactName]: 'на точке разгрузки',
  [TemplateContractFormFields.SourceContactNumber]: 'на точке погрузки',
  [TemplateContractFormFields.TargetContactNumber]: 'на точке разгрузки',
};

export const CLIENT_CONTRACT_TEMPLATE_POINT_DETAILS = [
  TemplateContractFormFields.Sender,
  TemplateContractFormFields.SourceContactName,
  TemplateContractFormFields.SourceContactNumber,
  TemplateContractFormFields.Receiver,
  TemplateContractFormFields.TargetContactName,
  TemplateContractFormFields.TargetContactNumber,
];

export const CLIENT_CONTRACT_FORM_UI_ELEMENTS: TemplateContractFormUiElements = {
  [TemplateContractFormFields.Sender]: {
    Component: UiInputField,
    defaultProps: {
      required: false,
      name: TemplateContractFormFields.Sender,
      label: CLIENT_CONTRACT_TEMPLATE_LABEL[TemplateContractFormFields.Sender],
    },
  },
  [TemplateContractFormFields.Receiver]: {
    Component: UiInputField,
    defaultProps: {
      required: false,
      name: TemplateContractFormFields.Receiver,
      label: CLIENT_CONTRACT_TEMPLATE_LABEL[TemplateContractFormFields.Receiver],
    },
  },
  [TemplateContractFormFields.SourceContactName]: {
    Component: UiInputField,
    defaultProps: {
      required: false,
      name: TemplateContractFormFields.SourceContactName,
      content: CLIENT_CONTRACT_CONTENT_TEXT[TemplateContractFormFields.SourceContactName],
      label: CLIENT_CONTRACT_TEMPLATE_LABEL[TemplateContractFormFields.SourceContactName],
    },
  },
  [TemplateContractFormFields.TargetContactName]: {
    Component: UiInputField,
    defaultProps: {
      required: false,
      name: TemplateContractFormFields.TargetContactName,
      content: CLIENT_CONTRACT_CONTENT_TEXT[TemplateContractFormFields.TargetContactName],
      label: CLIENT_CONTRACT_TEMPLATE_LABEL[TemplateContractFormFields.TargetContactName],
    },
  },
  [TemplateContractFormFields.SourceContactNumber]: {
    Component: UiPhoneField,
    defaultProps: {
      required: false,
      type: 'number',
      name: TemplateContractFormFields.SourceContactNumber,
      content: CLIENT_CONTRACT_CONTENT_TEXT[TemplateContractFormFields.SourceContactNumber],
      label: CLIENT_CONTRACT_TEMPLATE_LABEL[TemplateContractFormFields.SourceContactNumber],
    },
  },
  [TemplateContractFormFields.TargetContactNumber]: {
    Component: UiPhoneField,
    defaultProps: {
      required: false,
      type: 'number',
      name: TemplateContractFormFields.TargetContactNumber,
      content: CLIENT_CONTRACT_CONTENT_TEXT[TemplateContractFormFields.TargetContactNumber],
      label: CLIENT_CONTRACT_TEMPLATE_LABEL[TemplateContractFormFields.TargetContactNumber],
    },
  },
};

export const TEMPLATE_MODAL_WIDTH = '920';
export const TEMPLATE_MODAL_WITH_FORM_WIDTH = '90vw';
