import { FC, MutableRefObject } from 'react';
import { isEmpty } from 'lodash';
import { Box } from '@chakra-ui/react';
import { Carrier } from '@/types/models/carrier';
import { CarrierOrganization } from '@/components/carrier-organzation/carrier-organzation';
import './carrier-list.scss';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { InfiniteScroll } from '@/views/infinite-scroll/infinite-scroll';

type Props = {
  carrierList?: Array<Carrier>;
  scrollRef: MutableRefObject<HTMLDivElement | null>;
  handleCarrierClick: (id?: number) => void;
  selectedCarrierId?: number;
  loadMore: (args: any) => void;
  loading?: boolean;
};

export const CarrierList: FC<Props> = ({ carrierList, scrollRef, handleCarrierClick, selectedCarrierId, loadMore, loading = false }) => (
  <Box ref={scrollRef} className="carrier-list">
    <UiScrollbars autoHide style={{ width: '100%', height: '100%' }}>
      <Box className="carrier-list__content">
        <InfiniteScroll callback={loadMore} loading={loading} skip={isEmpty(carrierList)}>
          {carrierList?.map((carrier) => (
            <CarrierOrganization
              selected={selectedCarrierId === carrier.id}
              handleOrganizationClick={() => handleCarrierClick(carrier.id)}
              carrier={carrier}
              key={carrier.id}
              readOnly
            />
          ))}
        </InfiniteScroll>
      </Box>
    </UiScrollbars>
  </Box>
);
