import { VatType } from 'config/constants/types';

export const checkIsVatMatch = ({ orderVatType, vatType }: { orderVatType: VatType | undefined; vatType?: VatType }): boolean => {
  switch (orderVatType) {
    case VatType.CombinedVat:
      return true;
    case VatType.WithoutVat:
    case VatType.WithVat:
      return vatType === orderVatType || vatType === VatType.CombinedVat;
    default:
      return true;
  }
};
