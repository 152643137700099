import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { CustomersAdditionalInformation } from 'components/customers/customers-additional-information/customers-additional-information';
import { CustomersDetailsEmpty } from 'components/customers/customers-details-empty/customers-details-empty';
import { CustomersManagersList } from 'components/customers/customers-managers-list/customers-managers-list';
import { UISidebar } from '@/ui-elements/ui-sidebar/ui-sidebar';
import './customer-sidebar.scss';
import { useAppSelector } from '@/hooks/use-app-selector';
import { activeCustomerIdSelector } from '@/features/customers/selectors/active-customer-id-selector';
import { SHOW_CREATE_ORDER } from '@/views/constants/show-create-order';
import { useAccount } from '@/features/account/hooks/use-account';
import { UserRole } from '@/types/api/user-role';
import { useAllManagersUpdates } from '@/features/managers/use-all-managers-updates';
import { useCustomerUpdates } from '@/features/customers/hooks/use-customer-updates';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { ordersActions } from '@/features/order/slice';

/**
 * Сайдбар для страницы Клиенты
 * * */
export const CustomerSidebar: FC = () => {
  const activeCustomersId = useAppSelector(activeCustomerIdSelector);
  const { customer } = useCustomerUpdates(activeCustomersId);
  const { data, isLoading } = customer;
  const { currentRole } = useAccount();
  const navigate = useNavigate();
  const allManagers = useAllManagersUpdates();
  const dispatch = useAppDispatch();

  const handleAddOrder = () => {
    if (data?.data) {
      dispatch(ordersActions.setCreatingFromCustomersId({ id: data?.data.id, name: `${data?.data.name} ИНН: ${data?.data.INN}` }));
    }

    const url = qs.stringifyUrl({
      url: '/orders',
      query: {
        [SHOW_CREATE_ORDER]: true,
      },
    });
    navigate(url);
  };
  const customerInformation = activeCustomersId ? (
    <CustomersAdditionalInformation customer={data?.data} handleAddOrder={handleAddOrder} currentRole={currentRole} isLoading={isLoading} />
  ) : (
    <CustomersDetailsEmpty />
  );

  return (
    <UISidebar className="customer-sidebar" isOpen>
      {currentRole === UserRole.Manager || currentRole === UserRole.Booker ? (
        customerInformation
      ) : (
        <CustomersManagersList handleAddOrder={handleAddOrder} isLoading={isLoading} data={allManagers} currentRole={currentRole} />
      )}
    </UISidebar>
  );
};
