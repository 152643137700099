import { UseInfiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query';
import { OrderFilter } from '@/types/api/order-partial';
import { OrdersApi } from '@/api/orders';
import { isLastPage } from '@/helpers/api/is-last-page';
import { Order } from '@/types/models/order';
import { ResponseWithPager } from '@/types/models/pager';
import { QueryKeys } from '@/config/constants/query-keys';

type Props = {
  perPage?: number;
  query?: OrderFilter;
};

/**
 * Для авторизованного будет использовать запрос на получение заявок без отклика
 * Для не авторизованного будет запрашивать все заявки
 */
export const useAllParticipatableOrders = (
  { perPage = 10, query }: Props,
  options?: UseInfiniteQueryOptions<ResponseWithPager<Order>>,
  auth: boolean = false
) => {
  const queryFn = auth
    ? ({ pageParam = 1 }) => OrdersApi.participatable({ ...query, updates: false, perPage, page: pageParam })
    : ({ pageParam = 1 }) => OrdersApi.getAllOrders({ ...query, updates: false, perPage, page: pageParam }, { useBearer: false });

  return useInfiniteQuery({
    queryKey: [QueryKeys.allParticipatableCarriersLoads, query],
    refetchOnWindowFocus: false,
    queryFn,
    getNextPageParam: ({ pager }) => (isLastPage(pager) ? undefined : pager.page + 1),
    ...options,
  });
};
