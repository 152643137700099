import { FC } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import './carrier-card.scss';
import { CarrierCardBottom } from 'components/carrier-card/carrier-card-bottom';
import { PADDING, SPACING } from 'components/carrier-card/style-constants';
import { CarrierCardDriver } from 'components/carrier-card/carrier-card-driver-info/carrier-card-driver';
import { Driver } from '@/types/models/driver';
import { emptyFunction } from '@/helpers/empty-function';
import { VoidFunction } from '@/types/common/void-function';
import { Vehicle } from '@/types/models/vehicle';

export type Props = {
  isVatMatch?: boolean;
  driver: Driver;
  vehicle?: Vehicle;
  addCarriersRequest?: VoidFunction;
  onClickReviewButton?: VoidFunction;
  readOnly?: boolean;
};
export const CarrierCard: FC<Props> = ({
  onClickReviewButton = emptyFunction,
  driver,
  addCarriersRequest = emptyFunction,
  readOnly = false,
  vehicle,
  isVatMatch,
}) => (
  <Box className="carrier-card">
    <HStack spacing={SPACING} p={PADDING} width="100%">
      <CarrierCardDriver driver={driver} onClickReviewButton={onClickReviewButton} readOnly={readOnly} />
    </HStack>
    {vehicle && <CarrierCardBottom isVatMatch={isVatMatch} vehicle={vehicle} addCarriersRequest={addCarriersRequest} readOnly={readOnly} />}
  </Box>
);
