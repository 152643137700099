import { isNaN } from 'lodash';
import { Chars } from '@/config/constants/common-strings';

export const formatMass = (mass: number | string | null | undefined) => {
  if (!mass) return '0 кг';
  if (typeof mass === 'string' && isNaN(Number(mass))) return '0 кг';
  const massToFormat = typeof mass === 'string' ? Number(mass) : mass;
  const kgs = new Intl.NumberFormat('ru', { style: 'unit', unit: 'kilogram' });
  const ts = new Intl.NumberFormat('ru', { maximumFractionDigits: 2 });
  const withDecimals = Math.floor((massToFormat / 1000) * 100) / 100;
  return massToFormat < 999 ? kgs.format(massToFormat) : `${ts.format(withDecimals)}${Chars.NoBreak}т`;
};
