import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderState } from '@/features/order/types/order-state';
import { OrderSwitcherStatus } from '@/config/constants/statuses';
import { OrderStatusesType } from '@/types/models/order-statuses';
import { Order } from '@/types/models/order';
import { getOrder } from '@/features/order/actions/get-order';

const initialState: OrderState = {
  // выбранная заявка/завявки
  activeOrderIds: [],
  // выбранный таб в списке заявок
  activeOrderTab: OrderSwitcherStatus.New,
  // поиск исполнителя заявки - если true отобразить сайдбар с поиском
  activeSearchForCarrier: false,
  // разделение актвиной заявки, activeSplit -количество на которое планируется разделить активную заявку
  splittingOrdersCount: 0,
  // режим создания заявки
  isCreating: false,
  // режим редактирования заявки
  isUpdating: false,
  // режим выбора заявок (по 1 или по нескольуо)
  isMultipleSelection: false,
  // id перевозчика для отображения сайдбара комментариев
  carrierIdForReviews: undefined,
  // статусы заявки
  orderStatuses: {
    orderStatuses: undefined,
    orderSwitcherStatuses: undefined,
    bookerSwitcherStatuses: undefined,
    fuelStatuses: undefined,
  },
  // огранизация для предзаполнения при создании заявки из карточки клиента
  creatingFromCustomers: undefined,
  orderEdit: {
    isLoading: false,
    error: undefined,
    order: {} as Order,
  },
};

const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    // создание новой заявки
    create: (state) => {
      state.isCreating = true;
      state.activeSearchForCarrier = false;
      state.splittingOrdersCount = 0;
    },
    cancelCreate: (state) => {
      state.isCreating = false;
      state.isUpdating = false;
      state.creatingFromCustomers = undefined;
    },
    // изменить заявку
    update: (state) => {
      state.isUpdating = true;
    },
    // выбор заявки
    select: (state, action: PayloadAction<number | undefined>) => {
      // если включен режим isMultipleSelection - то добавляем к уже выделенным
      if (!action.payload) {
        state.activeOrderIds = [];
      } else {
        state.activeOrderIds = state.isMultipleSelection ? [...state.activeOrderIds, action.payload] : [action.payload];
      }
      state.activeSearchForCarrier = false;
      state.splittingOrdersCount = 0;
    },
    // снять выбор с  заявки
    unselect: (state, action: PayloadAction<number>) => {
      state.activeOrderIds = state.activeOrderIds.filter((x) => x !== action.payload);
    },
    // выбор таба в списке заявок
    selectTab: (state, action: PayloadAction<OrderSwitcherStatus>) => {
      state.activeOrderTab = action.payload;
    },
    // снять выбор со всех
    unselectAll: (state) => {
      state.activeOrderIds = [];
    },
    // выбор режима селекта заявок
    setMultipleSelection: (state, action: PayloadAction<boolean | undefined>) => {
      if (action.payload) {
        state.isMultipleSelection = action.payload;
      } else state.isMultipleSelection = !state.isMultipleSelection;
      // при переключении режима мультиселекта на однострочный селект менять массив с несколькими выбранными строками на 1ю строку
      if (!state.isMultipleSelection && state.activeOrderIds.length > 1) {
        state.activeOrderIds = [state?.activeOrderIds?.[0]];
      }
    },
    // поиск исполнителя
    searchForCarrier: (state, action: PayloadAction<{ id: number } | undefined>) => {
      if (action.payload) state.activeOrderIds = [action.payload.id];
      state.activeSearchForCarrier = !!action.payload;
      state.splittingOrdersCount = 0;
    },
    // разделение заявки
    split: (state, action: PayloadAction<{ order?: { id: number }; partsToSplit?: number } | undefined>) => {
      if (action.payload) state.activeOrderIds = action.payload?.order?.id ? [action.payload?.order?.id] : [];
      state.splittingOrdersCount = action.payload?.partsToSplit || 0;
      state.activeSearchForCarrier = false;
    },
    /*
     * Управление отображением комментариев
     */
    showReviews: (state, action: PayloadAction<{ carrier?: { id: number } }>) => {
      state.carrierIdForReviews = action.payload?.carrier?.id;
    },
    /*
     * Устанавливает колиечстов вдимых заявок на выбранном табе
     */
    setTotalVisibleOrdersCount: (state, action: PayloadAction<number | undefined>) => {
      state.totalVisibleOrdersCount = action.payload;
    },
    /*
     * Устанавливает статусы заявки
     */
    setOrderStatuses: (state, action: PayloadAction<OrderStatusesType>) => {
      state.orderStatuses = action.payload;
    },
    /*
     * Устанавливает огранизацию для предзаполнения при создании заявки из карточки клиента
     */
    setCreatingFromCustomersId: (state, action: PayloadAction<{ id?: number; name?: string }>) => {
      state.creatingFromCustomers = action.payload;
    },
    clearState: () => {
      return initialState;
    },
  },
  extraReducers: {
    [getOrder.pending.type]: (state: OrderState) => {
      state.orderEdit.isLoading = true;
      state.orderEdit.error = undefined;
      return state;
    },
    [getOrder.fulfilled.type]: (state: OrderState, action: PayloadAction<Order>) => {
      state.orderEdit.isLoading = false;
      state.orderEdit.order = action.payload;
      return state;
    },
    [getOrder.rejected.type]: (state: OrderState, action: PayloadAction<string>) => {
      state.orderEdit.isLoading = false;
      state.orderEdit.error = action.payload;
      return state;
    },
  },
});

export const ordersReducers = slice.reducer;
export const ordersActions = slice.actions;
