import { useQuery } from '@tanstack/react-query';
import { CarrierFilter } from '@/types/api/carrier-partial';
import { CarriersApi } from '@/api/carriers';

export const useAllCarriers = (query?: CarrierFilter) => {
  const allCarriers = useQuery({
    queryKey: ['allCarriers', query],
    refetchOnWindowFocus: false,
    queryFn: () => CarriersApi.getAllCarriers(query),
  });

  return { allCarriers };
};
