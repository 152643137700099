import { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useOrderActions } from '@/features/order/hooks/use-order-actions';
import { UISidebar } from '@/ui-elements/ui-sidebar/ui-sidebar';
import { OrderDetailsView } from '@/views/order-details/order-details-view';
import { UiCloseablePortal } from '@/ui-elements/ui-sidebar/ui-closeable-portal';
import { OrderSearchView } from '@/views/order-search/order-search-view';
import { ReviewsSidebar } from '@/views/order-sidebar/reviews-sidebar/reviews-sidebar';

export const OrderDetailsSidebar: FC<{
  showSearch: boolean;
  open: boolean;
  isFuel?: boolean;
}> = ({ open, showSearch, isFuel = false }) => {
  const { searchForCarrier, openReviews } = useOrderActions();
  const ref = useRef<HTMLDivElement>(null);
  const [closeAnimate, setCloseAnimate] = useState(false);

  const onClose = () => {
    searchForCarrier(undefined);
    setCloseAnimate(true);
    openReviews(undefined);
  };

  useEffect(() => {
    setCloseAnimate(false);
  }, [open]);
  return (
    <>
      <UISidebar
        className={classNames('order-sidebar', { 'order-sidebar_left': showSearch, 'order-sidebar_close': closeAnimate && !showSearch })}
        isDrawer
        animation={false}
        isOpen={open}
        ref={ref}
      >
        <OrderDetailsView isFuel={isFuel} />
      </UISidebar>
      <UISidebar className="order-sidebar_right" isOpen={showSearch} isDrawer animation fixedBlockRef={ref} blocksScroll>
        <UiCloseablePortal onClose={onClose}>
          <div className="order-sidebar_right-container">
            <OrderSearchView />
            <ReviewsSidebar />
          </div>
        </UiCloseablePortal>
      </UISidebar>
    </>
  );
};
