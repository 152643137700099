import { vehicleToFormValues } from '@/components/verification-forms/helpers/vehicle-to-form-values';
import { DocumentTypes } from '@/config/constants/document-types';
import { AttachmentDeps } from '@/config/constants/types';
import { useDependentAttachments } from '@/features/attachments/hooks/use-dependent-attachments';
import { useVehicle } from '@/features/vehicles/hooks/use-vehicle';
import { ResourcesFormFieldNames } from '@/features/resources/constants/form';

interface Props {
  vehicleId?: number | null;
  carrierId?: number;
}

export const useVehicleFormInitialValues = ({ vehicleId, carrierId }: Props) => {
  const {
    vehicle: { data: vehicle, isInitialLoading: vehicleIsLoading },
  } = useVehicle(vehicleId!);

  const initialValues = vehicle && vehicleToFormValues({ values: vehicle.data, carrier: { id: carrierId } });
  const loading = vehicleIsLoading;

  const Contract = useDependentAttachments({
    dependent: AttachmentDeps.Vehicle,
    dependentId: vehicleId,
    type: DocumentTypes.Contract,
  });
  const VRCFrontDoc = useDependentAttachments({
    dependent: AttachmentDeps.Vehicle,
    dependentId: vehicleId,
    type: DocumentTypes.VRCFront,
  });
  const VRCBackDoc = useDependentAttachments({
    dependent: AttachmentDeps.Vehicle,
    dependentId: vehicleId,
    type: DocumentTypes.VRCBack,
  });
  const VPFrontDoc = useDependentAttachments({
    dependent: AttachmentDeps.Vehicle,
    dependentId: vehicleId,
    type: DocumentTypes.VPFront,
  });
  const VPBackDoc = useDependentAttachments({
    dependent: AttachmentDeps.Vehicle,
    dependentId: vehicleId,
    type: DocumentTypes.VPBack,
  });
  const ExtraDoc = useDependentAttachments({
    dependent: AttachmentDeps.Vehicle,
    dependentId: vehicleId,
    type: DocumentTypes.ExtraDoc,
  });

  const vehicleInitialDocs = {
    [ResourcesFormFieldNames.Contract]: Contract,
    [ResourcesFormFieldNames.ExtraDoc]: ExtraDoc,
    [ResourcesFormFieldNames.VRCFront]: VRCFrontDoc,
    [ResourcesFormFieldNames.VRCBack]: VRCBackDoc,
    [ResourcesFormFieldNames.VPFront]: VPFrontDoc,
    [ResourcesFormFieldNames.VPBack]: VPBackDoc,
  };

  return { initialValues, vehicleInitialDocs, loading };
};
