import { FC, ReactNode } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import classNames from 'classnames';
import './ui-statistic-block.scss';

type StatisticsLevel = 'accepted' | 'checked' | 'rejected';
type Props = {
  number?: number;
  level?: StatisticsLevel;
  title: ReactNode;
  titleLines?: number;
  className?: string;
};
export const UiStatisticBlock: FC<Props> = ({ number = 0, level = 'accepted', title, titleLines = 2, className }) => (
  <div className="ui-statistics-block">
    <Box ml={6} className={classNames(`ui-statistics-block__${level}`, className)}>
      <HStack spacing={2}>
        <Text textStyle="h1">{number}</Text>
        <Text textStyle="h4" noOfLines={titleLines} w={100}>
          {title}
        </Text>
      </HStack>
    </Box>
  </div>
);
