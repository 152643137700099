import { FC } from 'react';
import { ModalView } from '@/views/order-modal/order-modal-view';
import { ModalsProps } from '@/types/common/modals';
import { useFunnelActions } from '@/features/funnel/hooks';

export const ModalCloseUpdateLoad: FC<
  ModalsProps<{
    text?: string;
    modalWidth?: number;
    marginRight?: number;
    submitButtonText?: string;
    cancelButtonText?: string;
    desc?: string;
    alignDesc?: 'center' | 'left' | 'right';
    accentXBtn?: boolean;
    selectNewLoad: () => void;
  }>
> = ({ modalOpened, onClose, desc, alignDesc, selectNewLoad }) => {
  const { cancelCreate } = useFunnelActions();
  const onSubmit = () => {
    selectNewLoad();
    cancelCreate();
    onClose();
  };

  return (
    <ModalView
      isOpen={modalOpened}
      onClose={onClose}
      title="Вы действительно хотите перейти на другую заявку?"
      onSubmit={onSubmit}
      submitButtonText="Подтвердить"
      desc={desc}
      alignDesc={alignDesc}
      modalWidth={544}
      justifyTitle="left"
    />
  );
};
