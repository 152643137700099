import { Text } from '@chakra-ui/react';

type Props = {
  title: string;
};

export const SubformTitle = ({ title }: Props) => (
  <Text textStyle="h3" pb={4}>
    {title}
  </Text>
);
