import { FC } from 'react';
import { STARS_COUNT } from 'config/constants/orders';
import { Field } from 'react-final-form';
import { useIcons } from '@/hooks/use-icons';
import { parseFormInt } from '@/helpers/forms/parse-form-int';
import './ui-rating-stars.scss';

type PropsT = {
  rating?: number | undefined;
  form?: boolean;
  formName?: string;
  setRating?: (arg: number) => void;
};

export const UiRatingStars: FC<PropsT> = ({ form, formName, rating, setRating }) => {
  const { StarBlack } = useIcons();

  const mappedStars = [...Array(STARS_COUNT)].map((_, index) => (
    <label className={`star-icon ${index < rating! ? 'starred' : ''}`} key={`${index + (rating ? 6 : 1)}`}>
      {form && (
        <Field
          parse={(val) => parseFormInt(val)}
          name={formName!}
          component="input"
          type="radio"
          value={index + 1}
          onClick={() => setRating!(index + 1)}
        />
      )}

      <StarBlack />
    </label>
  ));

  return <div className="stars-container">{mappedStars}</div>;
};
