import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadsState } from '@/features/loads/types/state';
import { Dictionary } from '@/config/constants/dictionary';

const initialState: LoadsState = {
  currentLoadID: undefined,
  userHasIntegration: true,
};

const slice = createSlice({
  name: Dictionary.Loads,
  initialState,
  reducers: {
    select: (state, action: PayloadAction<number | undefined>) => {
      state.currentLoadID = action.payload;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const loadsReducer = slice.reducer;
export const loadsActions = slice.actions;
