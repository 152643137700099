import { CustomerFilter } from '@/types/api/customer-partial';
import { CustomersApi } from '@/api/customers';
import { useInfiniteQueryWithUpdates } from '@/hooks/use-infinite-query-with-updates';

type Props = {
  perPage?: number;
  query?: CustomerFilter;
};

export const useAllCustomersUpdates = ({ query, perPage = 10 }: Props) => {
  const queryFn =
    (isFirst: boolean) =>
    ({ pageParam = 1 }) =>
      CustomersApi.getAllCustomers({ ...query, updates: !isFirst, perPage, page: pageParam });

  return useInfiniteQueryWithUpdates({ queryKey: 'allCustomers', query, refetchOnWindowFocus: false, queryFn });
};
