import { VatTypeMap } from 'config/constants/vat-options';

export const taxOptions = [
  {
    value: 1,
    label: VatTypeMap.withVat,
  },
  {
    value: 0,
    label: VatTypeMap.withoutVat,
  },
];
