import { FC } from 'react';
import { Box, TabIndicator, VStack } from '@chakra-ui/react';
import { Routes } from 'config/routes/routes';
import { routesPath } from 'config/routes/routes-path';
import { UiTabListMenuSelect, UiTabPanel, UiTabPanels, UiTabs } from '@/ui-elements';
import { ResourcesTab } from '@/components/resources/tabs/tab/resources-tab';
import {
  RESOURCES_NO_ORGANISATION,
  ResourcesButtonActions,
  ResourcesContentTabNames,
  ResourcesContentTabNamesForDriver,
} from '@/features/resources/constants/general';
import { ResourcesEmptyComponent } from '@/components/resources/empty-component/resources-empty-component';
import { ResourcesDriversView } from '@/views/resources/resources-drivers-view/resources-drivers-view';
import { ResourcesVehiclesView } from '@/views/resources/resources-vehicles-view/resources-vehicles-view';
import { useNavigateTo } from '@/hooks/use-navigate-to';

interface Props {
  disabled: boolean;
  isCarrierSimple: boolean;
  handleSelectContentTab: (tabIndex: number) => void;
}

export const ResourcesTabs: FC<Props> = ({ disabled, isCarrierSimple, handleSelectContentTab }) => {
  const { navigateTo } = useNavigateTo();
  return (
    <VStack alignItems="stretch">
      <Box>
        <UiTabs variant="unstyled" onChange={handleSelectContentTab}>
          <Box className="resources-view-tabs">
            <UiTabListMenuSelect size="md">
              {Object.values(!isCarrierSimple ? ResourcesContentTabNames : ResourcesContentTabNamesForDriver).map((resourceTab) => (
                <ResourcesTab isDisabled={disabled} key={resourceTab}>
                  {resourceTab}
                </ResourcesTab>
              ))}
            </UiTabListMenuSelect>
            {!disabled && <TabIndicator className="resources-view-tab-indicator" />}
          </Box>
          {!disabled && (
            <UiTabPanels>
              {!isCarrierSimple && (
                <UiTabPanel>
                  <ResourcesDriversView />
                </UiTabPanel>
              )}
              <UiTabPanel>
                <ResourcesVehiclesView />
              </UiTabPanel>
            </UiTabPanels>
          )}
        </UiTabs>
      </Box>
      {disabled && !isCarrierSimple && (
        <ResourcesEmptyComponent
          text={RESOURCES_NO_ORGANISATION}
          buttonText={ResourcesButtonActions.AddOrganisation}
          onClick={navigateTo(routesPath[Routes.MyOrganization])}
        />
      )}
    </VStack>
  );
};
