import { OrderDetailsService } from 'components/order-details';
import { FC, useRef } from 'react';
import { WidgetContainer } from 'components/widget-container/widget-container';
import { useOrderDetailsView } from '@/features/order/hooks/use-order-details-view';
import { WidgetContainers } from '@/features/widget/widget-containers';
import { useWidgetActions } from '@/features/widget/hooks/use-widget-actions';
import { useOrderServicesActions } from '@/features/order-services/hooks/use-order-services-actions';
import { OrderMode } from '@/types/common/mode';
import { useOrderServicesUpdates } from '@/features/order-services/hooks/use-order-services-updates';
import { OrderService } from '@/types/models/order-service';

export const OrderDetailsServiceView: FC<{ orderMode: OrderMode; fuelReadOnly?: boolean }> = ({ orderMode, fuelReadOnly }) => {
  const { order, selectedOffer, activeOrderId } = useOrderDetailsView();
  const ref = useRef(null);
  const { AddServiceWidget } = useWidgetActions(WidgetContainers.OrderDetailsService);
  const { deleteOrderServiceDocument } = useOrderServicesActions(activeOrderId);
  const { orderService: orderServiceQuery } = useOrderServicesUpdates({ id: activeOrderId });
  const showServiceAddWidget = () => {
    if (order) AddServiceWidget.show({ orderId: order.id, orderMode, companyName: selectedOffer?.carrier?.name });
  };
  const onServiceDocumentDeleteClick = (documentId: number) => {
    if (documentId) deleteOrderServiceDocument.mutate(documentId, { onSuccess: () => orderServiceQuery.refetch() });
  };
  if (!order) return null;
  return (
    <div ref={ref}>
      <OrderDetailsService
        order={order}
        orderMode={orderMode}
        services={orderServiceQuery?.data?.data || ([] as Array<OrderService>)}
        companyName={orderMode === 'customer' ? order?.customer?.name : selectedOffer?.carrier?.name}
        onAddServiceClick={showServiceAddWidget}
        onServiceDocumentDeleteClick={onServiceDocumentDeleteClick}
        fuelReadOnly={fuelReadOnly}
      />
      <WidgetContainer containerKey={WidgetContainers.OrderDetailsService} parentRef={ref} />
    </div>
  );
};
