import { ManagersFilter } from '@/types/api/managers-partial';
import { ManagersApi } from '@/api/managers';
import { useQueryWithUpdates } from '@/hooks/use-query-with-updates';
import { ManagerSimple } from '@/types/models/manager';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';

export const useAllManagersUpdates = (query?: ManagersFilter) => {
  const queryFn = (isFirst: boolean) => {
    return ManagersApi.getAllManagers({ ...query, updates: !isFirst });
  };
  const allManagers = useQueryWithUpdates<ResponseWithoutPagerSimple<Array<ManagerSimple>>>({
    queryKey: ['allManagers', query],
    refetchOnWindowFocus: false,
    queryFn,
  });
  return allManagers?.data?.data ? Object.values(allManagers?.data.data) : [];
};
