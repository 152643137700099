import { FC } from 'react';
import { CustomersCreateFormValues } from 'components/create-order-form/schema';
import { CustomerAddForm } from 'components/customers/customer-add-form/customer-add-form';
import { toast } from 'react-toastify';
import { getRemainder } from 'components/customers/helpers/get-remainder';
import { useCustomerUpdateActions } from '@/features/customers/hooks/use-customer-update-actions';
import { useCustomerSelect } from '@/features/customers/hooks/use-customer-select';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { useCustomer } from '@/features/customers/hooks/use-customer';
import { activeCustomerIdSelector } from '@/features/customers/selectors/active-customer-id-selector';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useManagersInvalidate } from '@/features/managers/use-managers-invalidate';
import { useAccount } from '@/features/account/hooks/use-account';
import { useCreateApproval } from '@/features/approvals/hooks/use-create-approval';

type Props = {
  managers?: SelectOption[];
  customersRefetch: Function;
};

export const CustomerUpdateView: FC<Props> = ({ managers, customersRefetch }) => {
  const activeCustomersId = useAppSelector(activeCustomerIdSelector);
  const { customer } = useCustomer(activeCustomersId);
  const { invalidateAllManagers } = useManagersInvalidate();
  const { data } = customer;

  const { updateCustomerTrue } = useCustomerUpdateActions(activeCustomersId);
  const { unselect } = useCustomerSelect();
  const { currentAccount } = useAccount();
  const { createApproval } = useCreateApproval();
  const remainder = getRemainder(customer?.data?.data.circulationLimit, customer?.data?.data.inCirculation);

  const updatedValues = data
    ? {
        customer: data.data.name,
        inn: data.data.INN,
        contract: data.data.contractNumber,
        date: data.data.expiration,
        manager: {
          value: data.data.manager?.id,
          label: data.data.manager?.name,
        },
        comment: data.data.comment,
        tarif: data.data.tariffComment,
        vipCustomer: data.data.vipCustomer,
        inCirculation: data.data.inCirculation,
        circulationLimit: data.data.circulationLimit,
        remainder,
      }
    : undefined;

  const updateValue = (values: CustomersCreateFormValues) => {
    const req = {
      id: data?.data.id,
      name: values.customer,
      INN: values.inn,
      contractNumber: values.contract,
      expiration: values.date,
      managerId: values.manager?.value,
      comment: values.comment,
      tariffComment: values.tarif,
      contactNumber: '',
      vipCustomer: values.vipCustomer,
    };

    updateCustomerTrue.mutate(req, {
      onSuccess: () => {
        createApproval.mutate({
          customerId: activeCustomersId,
          departmentId: currentAccount?.user?.department?.id,
        });
        toast.success('Клиент успешно изменен');
        invalidateAllManagers();
        unselect();
        customersRefetch();
      },
    });
  };
  return (
    <CustomerAddForm
      updatedValues={updatedValues}
      closeForm={unselect}
      addCustomer={updateValue}
      managers={managers}
      submitting={updateCustomerTrue.isLoading}
    />
  );
};
