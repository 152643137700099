import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import classNames from 'classnames';
import './verification-card.scss';
import { VerificationItemApproveStatus } from '@/config/constants/statuses';
import { VerificationItemStatusTag } from '@/components/verification-card/verification-item-status-tag/verification-item-status-tag';

type Props = {
  isSelected?: boolean;
  subject: React.ReactElement | JSX.Element;
  start?: string;
  timeLeft?: string;
  meta?: string;
  area?: string;
  currentStatus?: string;
  status?: VerificationItemApproveStatus | null;
  onCardSelect: React.MouseEventHandler<HTMLDivElement>;
  checking?: string;
};

export const VerificationCard = ({ isSelected, subject, start, meta, timeLeft, area, currentStatus, status, onCardSelect, checking }: Props) => (
  <Box className={classNames('verification-card', { selected: isSelected })} onClick={onCardSelect}>
    <VStack spacing={0} alignItems="flex-start">
      <HStack spacing={0} className="verification-card__header">
        <HStack spacing={0}>
          {subject}
          <Text textStyle="p4" w="136px">
            {start}
          </Text>
          <Text textStyle="p4">{meta}</Text>
        </HStack>
        <VerificationItemStatusTag status={status} />
      </HStack>
      <HStack spacing={0} className="verification-card__verification">
        <Text textStyle="p4">{checking}</Text>
        <HStack spacing={0}>
          <Text>Осталось времени на проверку</Text>
          <Text className="verification-card__time-left">{timeLeft}</Text>
        </HStack>
      </HStack>
      <Text textStyle="h6" pt="14px">
        {area}
      </Text>
      <Text textStyle="h5" pt="10px" color={currentStatus?.match(/не/i) ? 'accentRed.150' : 'products.200'}>
        {currentStatus}
      </Text>
    </VStack>
  </Box>
);
