import { NotificationContent } from 'components/notifications-popover/notification-content';
import { FC, useState } from 'react';
import { useNotifications } from '@/features/notifications/hooks/use-notifications';
import { useOrderActions } from '@/features/order/hooks/use-order-actions';
import { useNotificationMarked } from '@/features/notifications/hooks/use-notification-mark';

export const NotificationsView: FC<{ onClose: VoidFunction }> = ({ onClose }) => {
  const [onlyNew, setOnlyNew] = useState(false);
  const { navigateToOrder } = useOrderActions();
  const { notifications: notificationsQuery } = useNotifications(onlyNew);
  const { setAllNotificationsMarked, setNotificationMarked } = useNotificationMarked();

  const { data, fetchNextPage, isFetching } = notificationsQuery;
  const allRecords = data?.pages ? data.pages.flatMap((d) => d.data) : [];

  const handleNavigateToOrder = (id: number) => {
    navigateToOrder(id);
    onClose();
  };

  const handleMarkAsReadClick = (id: number) => {
    setNotificationMarked.mutate(id);
  };

  const handleMarkAllAsReadClick = () => {
    setAllNotificationsMarked.mutate();
  };

  return (
    <NotificationContent
      notifications={allRecords}
      isOnlyNew={onlyNew}
      onlyNewToggle={() => setOnlyNew((prev) => !prev)}
      loading={isFetching}
      loadMore={fetchNextPage}
      navigateToOrderClick={handleNavigateToOrder}
      markAsReadClick={handleMarkAsReadClick}
      markAllAsReadClick={handleMarkAllAsReadClick}
    />
  );
};
