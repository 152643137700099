import { isArray, isEmpty } from 'lodash';
import { VerificationItemApproveStatus, VerificationStatus } from '@/config/constants/statuses';

type Props = {
  approvalStatuses?: Array<VerificationStatus>;
  checkStatuses: Array<VerificationItemApproveStatus>;
};

export const checkResourceStatus = ({ approvalStatuses, checkStatuses }: Props) => {
  if (!isArray(approvalStatuses) || isEmpty(approvalStatuses)) return false;

  return approvalStatuses?.some((approvalStatus) => checkStatuses?.some((checkStatus) => checkStatus === approvalStatus?.status));
};
