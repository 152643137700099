import { isNumber } from 'lodash';
import { LoadNew } from '@/types/models/funnel';
import { extractPointsCargosActions } from '@/helpers/forms/point-cargo-actions/extract-point-cargos-actions';
import { CreateLoadFormValues } from '@/types/models/funnel-create-load-form';
import { extractValue } from '@/helpers/extract-value';
import { OrderCreateSource } from '@/types/api/order-partial';

export const normalizeLoadFormValues = (values: CreateLoadFormValues): LoadNew => {
  const vType = values.vehicleType?.vehicleTypes?.map((vt: any) => {
    return isNumber(vt) ? vt : vt.value;
  });
  const { pointsResult, cargosResult, actionsResult } = extractPointsCargosActions(values.route.points);
  return {
    customerID: values.loadInfo?.customerCompany?.value,
    contact: {
      name: values.loadInfo?.contactPerson,
      phone: (values.loadInfo as any)?.contactPhone,
    },
    responsiblePerson: {
      id: values.loadInfo?.responsiblePerson?.value,
      name: values.loadInfo?.responsiblePerson?.label,
    },
    transportationType: values.vehicleType?.loadType,
    type: values.route?.routeType,
    points: pointsResult,
    cargos: cargosResult,
    actions: actionsResult,
    additionalRequirements: {
      belts: values.vehicleType?.belts,
      tonnage: values.vehicleType?.tonnage?.value,
      additionalRequirements: values.vehicleType?.additionalRequirements || '',
    },
    permissions: {
      TIR: values.vehicleType.TIR,
      CMR: values.vehicleType.CMR,
      T1: values.vehicleType.T1,
      medicalBook: values.vehicleType.medicalBook,
    },
    fromSource: OrderCreateSource.ILSConfirmation,
    vehicleTypes: vType || [],
    loadingType: (values.vehicleType?.loadingType || []).map(extractValue),
  };
};
