import { SecurityTableRowFooter } from 'components/security/security-table-component/security-table-row-footer';
import { SecurityTableRiskCell } from 'components/security/security-table-component/cells/security-table-risk-cell';
import { SecurityTableStatusCell } from 'components/security/security-table-component/cells/security-table-status-cell';
import { SecurityTableVerificationSubjectCell } from 'components/security/security-table-component/cells/security-table-verification-subject-cell';
import { GridTableConfig } from '@/ui-elements/ui-grid-table/uii-grid-table-type';
import { Approval } from '@/types/models/approval';
import { userSimpleExtender } from '@/features/user-simple/extender/user-simple-extender';
import { formatMomentLocal } from '@/helpers/moment/format-moment-local';
import { MOMENT_L, MOMENT_LT } from '@/helpers/moment/constants';

/**
 * Конфиг для таблицы с запросами на проверку.
 * */
export const securityTableConfig: GridTableConfig<Approval> = {
  columns: [
    {
      key: 'time',
      label: 'Время',
      render: (model) => formatMomentLocal(model.created, MOMENT_LT, true),
    },
    {
      key: 'created',
      label: 'Дата',
      render: (model) => formatMomentLocal(model.created, MOMENT_L, true),
    },
    {
      key: 'responsiblePerson',
      label: 'Сотрудник СБ',
      render: (model) => userSimpleExtender.shortName(model.responsiblePerson),
      width: 1,
    },
    {
      key: 'carrier',
      label: 'Перевозчик',
      width: 2,
      render: (model) => <SecurityTableVerificationSubjectCell approval={model} />,
    },
    {
      key: 'riskGroup',
      label: 'Группа риска',
      width: 1,
      centredHeader: true,
      render: (model) => <SecurityTableRiskCell number={model.driver?.riskGroup} />,
    },
    {
      key: 'status',
      label: 'Статус',
      width: 1,
      centredHeader: true,
      render: (model) => <SecurityTableStatusCell status={model?.status} />,
    },
  ],
  rowFooter: (approval) => <SecurityTableRowFooter approval={approval} />,
};
