import { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { FormValues, VerificationFormMode } from 'components/verification-forms/types';
import { ownTypeLabels } from 'components/verification-forms/constants';
import { UiCheckboxField } from '@/ui-elements';
import { UiSwitcher } from '@/ui-elements/switcher/switcher';

export const VehicleOwnTypeSubform: FC<{
  initialValues: FormValues;
  setOwnType: (arg: number) => void;
  mode: VerificationFormMode;
  ownType: number;
}> = ({ initialValues, setOwnType, mode, ownType }) => {
  return (
    <>
      <Box mt={6}>
        <Text fontSize={18} fontWeight={700}>
          Право владения
        </Text>
      </Box>

      <Box mt={3}>
        <UiCheckboxField defaultChecked={initialValues.bailed} name="bailed" label="Находится в залоге" isDisabled={mode === 'check'} />
      </Box>
      <Box mt={4}>
        <UiCheckboxField defaultChecked={initialValues.casco} name="casco" label="ТС застраховано КАСКО" isDisabled={mode === 'check'} />
      </Box>

      <Box mt={4}>
        <UiSwitcher
          className="carriers-verification-form__switcher-long"
          onChange={(val: string) => setOwnType(Number(val))}
          tabsList={ownTypeLabels}
          disabled={mode === 'check'}
          name="ownType"
          value={ownType}
          defaultActiveTab={ownTypeLabels[ownType]?.key || ownTypeLabels[0].key}
        />
      </Box>
    </>
  );
};
