import { Dictionary } from 'config/constants/dictionary';
import { ApiPost, ApiPut } from '@/helpers/api/methods';
import { Account } from '@/types/models/account';
import { ChangePasswordRequest, Credentials, RegisterRequest } from '@/types/api/credentials';
import { ResponseSimpleApi } from '@/types/response';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';
import { ContentType } from '@/types/api/content-type';

export const AuthApi = {
  signIn: (body: Credentials) => ApiPost<ResponseWithoutPagerSimple<Account>>(`${Dictionary.Auth}/signIn`, body, { useBearer: false }),
  signOut: () => ApiPost<ResponseSimpleApi>(`${Dictionary.Auth}/signOut`, {}, { useBearer: true }),
  register: (body: RegisterRequest) =>
    ApiPost<ResponseSimpleApi>(`${Dictionary.Auth}/register`, body, {
      useBearer: false,
      contentType: ContentType.FORM_DATA,
    }),
  changePassword: (body: ChangePasswordRequest) => ApiPut<ResponseSimpleApi>(`${Dictionary.Auth}/changePassword`, body, { useBearer: false }),
};
