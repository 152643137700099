import moment, { Moment } from 'moment/moment';
import _ from 'lodash';
import { MOMENT_DD_MM_YY } from '@/helpers/moment/constants';

export const formatMomentDate = (
  inputDate: string | Moment | number,
  format: string = MOMENT_DD_MM_YY,
  onlyNumbers: boolean = false,
  convertToLocal: boolean = false
) => {
  const date = _.isNumber(inputDate) ? moment.unix(inputDate) : inputDate;

  const oneDayAgo = moment(date).diff(moment(), 'days') < 0;

  const parsedDate = convertToLocal ? moment(date).local() : moment(date);
  if (oneDayAgo || onlyNumbers) {
    return parsedDate.format(format);
  }
  return parsedDate.calendar(null, {
    sameDay: '[Сегодня]',
    lastDay: '[Вчера]',
  });
};
