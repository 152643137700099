import { FC } from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { Exit } from 'components/profile-menu/texts';
import { useIcons } from '@/hooks/use-icons';
import { UiIconButton } from '@/ui-elements';
import { UiDropdownMenuItem } from '@/ui-elements/ui-dropdown/ui-dropdown-menu-item';
import { UiDropdown } from '@/ui-elements/ui-dropdown/ui-dropdown';
import { UiDropdownMenuButton } from '@/ui-elements/ui-dropdown/ui-dropdown-menu-button';
import { UiDropdownMenuList } from '@/ui-elements/ui-dropdown/ui-dropdown-menu-list';
import { emptyFunction } from '@/helpers/empty-function';
import { AccountState } from '@/features/account/types';
import { getEmptyStr } from '@/helpers/get-empty-str';
import { UserRoleMap } from '@/types/api/user-role';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  onToggle: VoidFunction;
  onLogoutClick?: VoidFunction;
  account: AccountState;
};
export const ProfileMenu: FC<Props> = ({ onClose, onToggle, isOpen, onLogoutClick = emptyFunction, account }) => {
  const { user } = account || {};
  const { Profile } = useIcons();
  return (
    <UiDropdown isOpen={isOpen} onClose={onClose}>
      <UiDropdownMenuButton as={UiIconButton} aria-label="profile" icon={Profile} onClick={onToggle} />
      <UiDropdownMenuList right={0}>
        {user && (
          <VStack align="start" w="full" pl="12px">
            <Text textStyle="p3" whiteSpace="nowrap">
              Вы вошли как
            </Text>
            <Text whiteSpace="normal" textStyle="p8">{`${getEmptyStr(user.name)} ${getEmptyStr(user.surname)}`}</Text>
            <Text whiteSpace="normal" textStyle="p8">
              Тел. {`${user.phone || ''}`}
            </Text>
            <Text whiteSpace="normal" textStyle="p8">
              Роль: {`${UserRoleMap[user.userRole]}`}
            </Text>
            <Text whiteSpace="normal" textStyle="p8">
              Организация: {`${getEmptyStr(user.department?.name)}`}
            </Text>
          </VStack>
        )}
        <UiDropdownMenuItem onClick={onLogoutClick}>{Exit}</UiDropdownMenuItem>
      </UiDropdownMenuList>
    </UiDropdown>
  );
};
