import _ from 'lodash';

export const formatFormFields = (obj: Object, method: 'omit' | 'pick', keys: Array<string>) => {
  let newObj;
  switch (method) {
    case 'omit':
      newObj = _.omit(obj, keys);
      break;
    case 'pick':
      newObj = _.pick(obj, keys);
      break;
    default:
      newObj = {};
      break;
  }
  return newObj;
};
