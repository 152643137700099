import { FC } from 'react';
import { OrderDetailsDocumentsConfirm } from 'components/order-details/order-details-documents/order-details-documents-confirm';
import { toast } from 'react-toastify';
import { AttachmentDeps } from 'config/constants/types';
import { DocumentTypes } from 'config/constants/document-types';
import { OrderExecutionStatus } from 'config/constants/statuses';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { PointStatus } from 'components/order-point/options';
import { useDependentAttachments } from '@/features/attachments/hooks/use-dependent-attachments';
import { useSetPointStatus } from '@/features/waypoint/hooks/use-point-status';
import { useDocumentsStatus } from '@/features/waypoint/hooks/use-documents-status';
import { useOrderInvalidate } from '@/features/order/hooks/use-order-invalidate';
import { useOrderDetailsView } from '@/features/order/hooks/use-order-details-view';
import { useAccount } from '@/features/account/hooks/use-account';
import { UserRole } from '@/types/api/user-role';
import { orderStatusArray } from '@/views/order-details-document/helper/get-order-status';
/*
   Компонент для отображение блока загрузки документов по точке в списке общих документов
 */
export const PointDocumentView: FC<{ pointId: number }> = ({ pointId }) => {
  const { orderInvalidate } = useOrderInvalidate();
  const { order, setNewOrderStatus } = useOrderDetailsView();
  const { currentRole } = useAccount();

  const point = order?.points.find((x) => x.id === pointId);

  const hasEditRight = currentRole && [UserRole.Administrator, UserRole.Manager].includes(currentRole);

  const {
    deleteAttachmentById: deleteDocument,
    attachments,
    downloadAttachmentById,
    postAttachments,
  } = useDependentAttachments({
    dependent: AttachmentDeps.Point,
    dependentId: point?.id,
    type: DocumentTypes.dlDocumentPoint,
    staleTime: 3000,
  });

  const { setPointStatus } = useSetPointStatus({ orderId: order!.id, pointId: point!.id });
  const { setPointDocumentsStatus } = useDocumentsStatus({ orderId: order!.id, pointId: point!.id });

  const confirm = () => {
    if (order?.id && point) {
      setPointDocumentsStatus.mutate('approved');
      setNewOrderStatus(OrderExecutionStatus.Executing);
      setPointStatus.mutate(PointStatus.Completed, {
        onSuccess: () => {
          orderInvalidate(order!.id);
          toast.success('Статус точки успешно изменен');
        },
      });
    }
  };
  const isCompleted = point?.status === 'completed';
  const isLoadingPoint = Boolean(order?.actions.find((p) => p.pointId === point?.id)?.type === 1);
  const title = `${isLoadingPoint ? 'Погрузка: ' : 'Разгрузка:'} ${point?.city}`;
  let subtitle = isCompleted && !attachments?.length ? 'документы не были загружены' : EMPTY_STRING;
  subtitle = isLoadingPoint ? `точка погрузки${subtitle && `, ${subtitle}`}` : subtitle;
  const isOrderExecuting = orderStatusArray.some(() => order?.status !== OrderExecutionStatus.Executing);

  if (!point) return null;
  return (
    <OrderDetailsDocumentsConfirm
      title={title}
      subtitle={subtitle}
      documents={attachments || []}
      disabled={isCompleted || !hasEditRight || isOrderExecuting}
      confirmed={isCompleted}
      onUpload={postAttachments}
      onSaveClick={downloadAttachmentById}
      onDeleteClick={!isCompleted && hasEditRight ? deleteDocument : undefined}
      onConfirmClick={hasEditRight ? confirm : undefined}
    />
  );
};
