import { OrderExecutionStatus } from 'config/constants/statuses';
import { toast } from 'react-toastify';
import { head } from 'lodash';
import { OrderLoadType } from '@/config/constants/types';
import { useSetPointStatus } from '@/features/waypoint/hooks/use-point-status';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { Loading } from '@/types/models/loading';
import { pointExtender } from '@/features/order/extender/order-point-extender';
import { useIcons } from '@/hooks/use-icons';
import { Point, PointStatusesTransition } from '@/types/models/point';
import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';
import { useAppSelector } from '@/hooks/use-app-selector';
import { cargoTypesSelector } from '@/features/cargo/selectors/cargo-types-selector';
import { useForm } from '@/helpers/forms/use-form';
import { useOrdersWithMyParticipationUpdates } from '@/features/order/hooks/use-orders-with-my-participation-updates';
import { useCarrierOrderPointDocumentView } from '@/views/order-details-document/use-carrier-order-point-document-view';
import { useLocationPathes } from '@/hooks/use-location-pathes';
import { routesPath } from '@/config/routes/routes-path';

export const useRoutePointWidget = (order: Order | Load, point: Point) => {
  const cargoTypes = useAppSelector(cargoTypesSelector);

  const { paths } = useLocationPathes();
  const isCurrentRoutes = paths.includes(routesPath.CurrentRoutes);
  const isCompletedRoutes = paths.includes(routesPath.CompletedRoutes);

  const isPointDocumentsAvailable = isCurrentRoutes || isCompletedRoutes;

  const { id: orderId, status: orderStatus } = order;
  const { refetch: orderRefetch } = useOrdersWithMyParticipationUpdates({ id: orderId });

  const isExecuting = orderStatus === OrderExecutionStatus.Executing;

  const { attachments, deleteDocument, downloadAttachments, uploadDocument, hasEdit } = useCarrierOrderPointDocumentView({ point });
  const { status } = point;
  const { setPointStatus } = useSetPointStatus({ orderId, pointId: point?.id });
  const extendedPoint = useExtendedObject({ point, cargos: order.cargos, action: order.actions } as Loading, pointExtender);
  const { LoadIcon, UnloadIcon, LongArrowIcon } = useIcons();

  const orderAction: OrderLoadType = extendedPoint.isUnloading ? OrderLoadType.Unloading : OrderLoadType.Loading;
  const Form = useForm<any>();

  const setStatus = ({ value }: { value: PointStatusesTransition }) =>
    setPointStatus.mutate(value, {
      onSuccess: (response) => {
        orderRefetch();
        toast.success(head(response.messages));
      },
    });

  return {
    extendedPoint,
    UnloadIcon,
    LoadIcon,
    LongArrowIcon,
    setStatus,
    cargoTypes,
    isExecuting,
    isPointDocumentsAvailable,
    orderAction,
    status,
    Form,
    hasEdit,
    attachments,
    deleteDocument: isCompletedRoutes ? undefined : deleteDocument,
    downloadAttachments,
    uploadDocument,
  };
};
