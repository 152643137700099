import { FocusEvent, useState } from 'react';
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, InputProps, Text } from '@chakra-ui/react';
import { useField } from 'react-final-form';
import { UiAddressSearch } from '@/ui-elements';

type Props = {
  name: string;
  label?: string;
  content?: string;
  required?: boolean;
  placeholder?: string;
  overflown?: boolean;
};

export const UiAddressSearchField = ({ name, label, required, content, isDisabled, ...props }: Props & Omit<InputProps, 'placeholder'>) => {
  const {
    input,
    meta: { error, touched },
  } = useField(name);
  const invalid = Boolean(error && touched);
  const [isTouched, setIsTouched] = useState(false);

  const onFocus = (event?: FocusEvent<HTMLElement>) => {
    input.onFocus(event);
    if (!isTouched) setIsTouched(true);
  };

  return (
    <FormControl isRequired={required && !isDisabled} isInvalid={invalid}>
      <FormLabel mb={1} fontSize="xs" className="ui-field__label">
        <Text noOfLines={1}>{label}</Text>
      </FormLabel>
      <UiAddressSearch
        {...props}
        {...input}
        onFocus={onFocus}
        onSelect={input.onChange}
        isInvalid={!input.value && isTouched}
        isDisabled={isDisabled}
      />
      {invalid ? <FormErrorMessage>{error}</FormErrorMessage> : <FormHelperText>{content}</FormHelperText>}
    </FormControl>
  );
};
