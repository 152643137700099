import L, { FitBoundsOptions, LatLngBoundsExpression } from 'leaflet';
import { Marker } from './marker';
import { MAP_INIT_ERROR_MESSAGE, attribution } from './constants';
import { MarkerType } from './types';
import { osm, googleTerrain, googleHybrid } from './config';

export default class MapClass {
  private map: L.Map | undefined;

  private markers: MarkerType[] = [];

  private distance: number = 0;

  private markerLayer: L.LayerGroup = L.layerGroup();

  private tiles: Record<'googleHybrid' | 'osm' | 'googleTerrain', L.TileLayer> = {
    osm,
    googleTerrain,
    googleHybrid,
  };

  private activeTileLayer = this.tiles.googleHybrid;

  public constructor() {
    this.map = new L.Map('map', {
      boxZoom: false,
      zoomSnap: 0.5,
      zoomDelta: 0.5,
      wheelPxPerZoomLevel: 120,
      center: [55.753, 37.63],
      zoom: 10,
      maxZoom: 18,
    });

    this.map.doubleClickZoom.disable();

    this.map.addLayer(this.activeTileLayer);

    this.map.attributionControl.addAttribution(attribution);
    this.map.attributionControl.setPrefix('');

    const tileLayers = Array.from(document.querySelectorAll('.tile-layer-item'));
    tileLayers[0].addEventListener('click', () => {
      this.layerChange('osm');
    });
    tileLayers[1].addEventListener('click', () => {
      this.layerChange('googleHybrid');
    });
    tileLayers[2].addEventListener('click', () => {
      this.layerChange('googleTerrain');
    });
  }

  public get Map() {
    return this.map;
  }

  public fitBounds(bounds: LatLngBoundsExpression, options?: FitBoundsOptions) {
    if (this.map) {
      this.map.fitBounds(bounds, options);
    }
  }

  public getBounds() {
    if (this.map) {
      return this.map.getBounds();
    }
    return null;
  }

  public getZoom() {
    if (this.map) {
      return this.map.getZoom();
    }
    return null;
  }

  public addMarkers(markers: MarkerType[]) {
    this.markers = markers;
  }

  public setDistance(distance: number) {
    this.distance = distance;

    const customControl = new L.Control({ position: 'bottomleft' });
    customControl.onAdd = () => {
      const div = L.DomUtil.create('div', 'custom-attribution');
      div.innerHTML = `<div className="distance-attribute">
      <span style="color: #4E4E5A; margin-right: 10px" className="distance-attribute__copyright">© INTELOGIS</span>
      <span className="distance-attribute__distance">↦ ${this.distance}км ↤</span>
      </div>`;
      return div;
    };
    if (this.map) customControl.addTo(this.map);
  }

  public addMarker(MarkerOptions: MarkerType, to?: L.Map | L.LayerGroup) {
    if (this.map) {
      const marker = new Marker(MarkerOptions).L;
      return marker.addTo(to || this.map);
    }
    throw new Error(MAP_INIT_ERROR_MESSAGE);
  }

  public addLayerGroup(layerProps: Parameters<typeof L.layerGroup>, to?: L.Map | L.LayerGroup) {
    if (this.map) {
      const layerG = L.layerGroup(...layerProps);
      layerG.addTo(to || this.map);
      return layerG;
    }
    throw new Error(MAP_INIT_ERROR_MESSAGE);
  }

  public deinit() {
    if (this.map) {
      this.map.remove();
    }
  }

  private layerChange(layer: 'googleHybrid' | 'osm' | 'googleTerrain') {
    if (this.map) {
      if (this.tiles[layer] !== this.activeTileLayer) {
        this.map.removeLayer(this.activeTileLayer);
        this.activeTileLayer = this.tiles[layer];
        this.map.addLayer(this.activeTileLayer);
      }
    }
  }
}
