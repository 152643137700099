import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { loginAction } from '@/features/account/actions/login-action';
import { logoutAction } from '@/features/account/actions/logout-action';
import { Credentials, RegisterRequest } from '@/types/api/credentials';
import { registerAction } from '@/features/account/actions/register-action';

export const useAuth = () => {
  const dispatch = useAppDispatch();

  const login = (credentials: Credentials) => {
    dispatch(loginAction(credentials));
  };

  const logout = () => {
    dispatch(logoutAction());
  };

  const register = (registerRequest: RegisterRequest) => {
    dispatch(registerAction(registerRequest));
  };

  const changePassword = () => {};

  return {
    login,
    logout,
    register,
    changePassword,
  };
};
