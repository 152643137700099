import { FC } from 'react';
import { Heading, Box } from '@chakra-ui/react';
import { UiCheckboxField } from '@/ui-elements';
import { UiSwitcher } from '@/ui-elements/switcher/switcher';
import { VehicleOwnTypeEnum, ownTypeLabels } from '@/components/verification-forms/constants';
import { ResourcesFormFieldNames, ResourcesFormLabels } from '@/features/resources/constants/form';

interface Props {
  ownType: VehicleOwnTypeEnum;
  handleChangeOwnType: (val: string) => void;
}

export const ResourcesSubformOwnType: FC<Props> = ({ ownType, handleChangeOwnType }) => {
  return (
    <Box>
      <Heading as="h3" className="resources-form-subtitle">
        Право владения
      </Heading>
      <Box className="resources-form-column">
        <UiCheckboxField name={ResourcesFormFieldNames.bailed} label={ResourcesFormLabels.bailed} />
        <UiCheckboxField name={ResourcesFormFieldNames.casco} label={ResourcesFormLabels.casco} />
        <UiSwitcher
          name={ResourcesFormFieldNames.ownType}
          value={ownType}
          tabsList={ownTypeLabels}
          defaultActiveTab={ownTypeLabels[ownType]?.key || ownTypeLabels[0].key}
          onChange={handleChangeOwnType}
        />
      </Box>
    </Box>
  );
};
