import { STORAGE_NAME } from 'config/constants/storage';

export const storage = {
  getValue: <T>(name: string): T | null => {
    const settingsJson = localStorage.getItem(STORAGE_NAME);
    if (settingsJson) {
      try {
        return JSON.parse(settingsJson)[name];
      } catch (e) {
        localStorage.removeItem(STORAGE_NAME);
        return null;
      }
    } else {
      return null;
    }
  },

  setValue: <T>(name: string, value: T): void => {
    const settingsJson = localStorage.getItem(STORAGE_NAME);
    let settings: { [key: string]: T } = {};
    if (settingsJson) {
      try {
        settings = JSON.parse(settingsJson);
      } catch (e) {
        localStorage.removeItem(STORAGE_NAME);
      }
    }

    settings[name] = value;
    localStorage.setItem(STORAGE_NAME, JSON.stringify(settings));
  },
};
