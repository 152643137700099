import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/types/redux/root-state';
import { OrderSidebarMode } from '@/types/sidebar/order-sidebar-mode';

export const sidebarModeSelector = createSelector(
  (state: RootState) => state.orders,
  (orders) => {
    if (orders.splittingOrdersCount && orders.activeOrderIds?.length === 1) return OrderSidebarMode.Split;
    if (orders.isCreating) return OrderSidebarMode.NewOrder;
    if (orders.isUpdating) return OrderSidebarMode.UpdateOrder;
    if (orders.activeOrderIds?.length === 1 && orders.activeSearchForCarrier) return OrderSidebarMode.Search;
    return OrderSidebarMode.Details;
  }
);
