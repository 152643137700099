import { FC } from 'react';
import { PageWrapper } from 'pages/page-wrapper';
import { Flex } from '@chakra-ui/react';
import { CompletedRoutesView } from '@/views/carriers-loads/completed-routes/completed-routes';

export const CompletedRoutesPage: FC = () => (
  <PageWrapper>
    <Flex>
      <section className="completed-routes">
        <CompletedRoutesView />
      </section>
    </Flex>
  </PageWrapper>
);
