import { Box, BoxProps, TabIndicator } from '@chakra-ui/react';
import orderBy from 'lodash/orderBy';
import { MouseEventHandler } from 'react';
import { UiTabListMenuSelect, UiTabPanel, UiTabPanels, UiTabs, UiUnderConstruct } from '@/ui-elements';
import { carrierExtender } from '@/features/carriers/extender/carrier-extender';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { CarrierTabs } from '@/components/carrier-info/types/tabs';
import { CarrierInfoTab } from '@/components/carrier-info/components/carrier-info-tab/carrier-info-tab';
import { CarrierGeneralInfo } from './components/carrier-general-info/carrier-general-info';
import './carrier-info.scss';
import { Carrier } from '@/types/models/carrier';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { CarrierRoutesAndPaymentTab } from './components/carrier-routes-and-payment-tab/carrier-routes-and-payment-tab';
import { CarrierDriversTab } from './components/carrier-drivers-tab/carrier-drivers-tab';
import { Vehicle } from '@/types/models/vehicle';
import { Order } from '@/types/models/order';

type Props = {
  carrier: Carrier;
  vehicles?: Array<Vehicle>;
  showCarriersReviews: (carrierId: number) => void;
  showTrip: VoidFunction;
  showDriver: (driverId: number) => void;
  onEditCarrierClick: VoidFunction;
  onVehicleMoreInfoClick?: (vehicle: Vehicle) => void;
  handleDeleteCarrier?: MouseEventHandler<HTMLButtonElement>;
  orders?: Array<Order>;
  loadMore: (args: any) => void;
  loading?: boolean;
} & BoxProps;

export const CarrierInfo = ({
  carrier,
  showCarriersReviews,
  showTrip,
  showDriver,
  vehicles = [],
  onEditCarrierClick,
  onVehicleMoreInfoClick,
  handleDeleteCarrier,
  orders,
  loadMore,
  loading,
}: Props) => {
  const { approveStatusCaption, drivers } = useExtendedObject(carrier, carrierExtender);
  const [driver] = orderBy(drivers, 'rating', 'desc');

  return (
    <Box className="carrier-info-component" position="relative">
      <UiTabs variant="unstyled">
        <UiTabListMenuSelect size="md">
          <CarrierInfoTab>{CarrierTabs.Organization}</CarrierInfoTab>
          <CarrierInfoTab>{CarrierTabs.DriversAndVehicles}</CarrierInfoTab>
          <CarrierInfoTab>{CarrierTabs.FuelCards}</CarrierInfoTab>
          <CarrierInfoTab>{CarrierTabs.RoutesAndPayment}</CarrierInfoTab>
        </UiTabListMenuSelect>
        <TabIndicator mt="2px" height="2px" bg="products.200" />
        <UiScrollbars className="carrier-info-component__scroll">
          <UiTabPanels>
            <UiTabPanel>
              <CarrierGeneralInfo
                carrier={carrier}
                approveStatusCaption={approveStatusCaption}
                showCarriersReviews={showCarriersReviews}
                driver={driver}
                onEditCarrierClick={onEditCarrierClick}
                handleDeleteCarrier={handleDeleteCarrier}
                onDriverMoreInfoClick={showDriver}
              />
            </UiTabPanel>
            <UiTabPanel>
              <CarrierDriversTab carrier={carrier} showDriver={showDriver} vehicles={vehicles} onVehicleMoreInfoClick={onVehicleMoreInfoClick} />
            </UiTabPanel>
            <UiTabPanel>
              <UiUnderConstruct />
            </UiTabPanel>
            <UiTabPanel>
              <CarrierRoutesAndPaymentTab carrier={carrier} routes={orders} showTrip={showTrip} loadMore={loadMore} loading={loading} />
            </UiTabPanel>
          </UiTabPanels>
        </UiScrollbars>
      </UiTabs>
    </Box>
  );
};
