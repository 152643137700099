import { Routes } from 'config/routes/routes';

export const routesPath: Record<Routes, string> = {
  [Routes.Orders]: 'orders',
  [Routes.Carriers]: 'carriers',
  [Routes.CarriersLoads]: 'carriers_loads', // Поиск грузов для анонима
  [Routes.CarriersLoadsAuthorized]: 'carriers_loads_auth', // Поиск грузов для аторизованного перевозчика
  [Routes.CarriersLoadsAuthorizedSimple]: 'carriers_loads_auth', // Поиск для carrier_simple
  [Routes.Clients]: 'clients',
  [Routes.Analytics]: 'analytics',
  [Routes.Map]: 'map',
  [Routes.Security]: 'security',
  [Routes.Verification]: 'verification',
  [Routes.VerificationHistory]: 'verification_history',
  [Routes.Statistics]: 'statistics',
  [Routes.LogIn]: 'login',
  [Routes.Register]: 'register',
  [Routes.FuelCards]: 'fuel',
  [Routes.UnderConstruct]: 'under_construct',
  [Routes.BookerOrders]: 'booker_orders',
  [Routes.BookerClients]: 'booker_clients',
  [Routes.MyOrganizations]: 'my_organizations',
  [Routes.MyOrganization]: 'my_organization',
  [Routes.MyOrganizationSimple]: 'my_organization',
  [Routes.Settings]: 'settings',
  [Routes.PublicOfferAgreement]: 'assets/documents/public_offer_agreement.pdf',
  [Routes.Loads]: 'loads',
  [Routes.Funnel]: 'funnel',
  [Routes.CRM]: 'crm',
  [Routes.CurrentRoutes]: 'current_routes',
  [Routes.OrdersWithMyParticipation]: 'orders_with_my_participation',
  [Routes.Resources]: 'resources',
  [Routes.FutureRoutes]: 'future_routes',
  [Routes.FutureRoutesSimple]: 'future_routes',
  [Routes.CompletedRoutes]: 'completed_routes',
  [Routes.CompletedRoutesSimple]: 'completed_routes',
  [Routes.ResourcesSimple]: 'resources',
};
