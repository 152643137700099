import './security.scss';
import { DateObject } from 'react-multi-date-picker';
import { HStack, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { SecurityHeaderView } from './security-header-view';
import { SecurityTable } from './security-table';
import { SecuritySidebarView } from './security-sidebar-view';
import { MOMENT_DD_MM_YYYY } from '@/helpers/moment/constants';
import { getPeriodFilterValue } from '@/helpers/get-period-filter-value';

type Filter = { dateFrom: string; dateTo: string };
export const SecurityView = () => {
  const [value, setValue] = useState('today');
  const [rangeValue, setRangeValue] = useState<Array<DateObject>>([]);
  const [filter, setFilter] = useState<Filter | undefined>(undefined);

  useEffect(() => {
    if (value !== '') {
      setFilter(getPeriodFilterValue(value));
      setRangeValue([]);
    }
  }, [value]);

  useEffect(() => {
    if (rangeValue.length !== 0) {
      if (rangeValue.length === 1) setFilter({ dateFrom: rangeValue[0].format(MOMENT_DD_MM_YYYY), dateTo: '' });
      if (rangeValue.length === 2) setFilter({ dateFrom: rangeValue[0].format(MOMENT_DD_MM_YYYY), dateTo: rangeValue[1].format(MOMENT_DD_MM_YYYY) });
      setValue('');
    }
  }, [rangeValue]);

  return (
    <div className="security-view">
      <VStack className="security-view__vstak">
        <SecurityHeaderView
          onChangeCalendarValue={setRangeValue}
          selectedRangeButton={value}
          onClickRangeButton={setValue}
          filter={filter}
          rangeValue={rangeValue}
        />
        <HStack className="security-view__hstak">
          <div className="security-view__table">{filter && <SecurityTable filter={filter} />}</div>
          <SecuritySidebarView />
        </HStack>
      </VStack>
    </div>
  );
};
