import './create-offer-form.scss';
import { FC, memo } from 'react';
import { FormSpy } from 'react-final-form';
import { Box } from '@chakra-ui/react';
import { FormAutosave } from '@/hooks/use-autosave';
import { useForm } from '@/helpers/forms/use-form';
import { CreateOfferFormItems } from './create-offer-form-items';
import { CarriersLoadsCreateOfferValuesForm } from '@/types/models/carriers-loads';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';

type Props = {
  onFormValuesChange: (values: Partial<CarriersLoadsCreateOfferValuesForm>) => void;
  drivers: Array<SelectOption>;
  vehicles: Array<SelectOption>;
  initialValues: CarriersLoadsCreateOfferValuesForm;
  disablePrice: boolean;
  priceVAT: boolean;
  isCarrierSimple: boolean;
  hidePriceField?: boolean;
};

const CreateOfferForm: FC<Props> = ({
  onFormValuesChange,
  isCarrierSimple,
  drivers,
  vehicles,
  initialValues,
  disablePrice,
  priceVAT,
  hidePriceField,
}) => {
  const Form = useForm<CarriersLoadsCreateOfferValuesForm>();

  return (
    <Box className="create-offer-form">
      <Form initialValues={initialValues} onSubmit={onFormValuesChange}>
        <FormSpy subscription={{ values: true }}>{({ values }) => <FormAutosave values={values} onSave={onFormValuesChange} />}</FormSpy>
        <CreateOfferFormItems
          isCarrierSimple={isCarrierSimple}
          drivers={drivers}
          vehicles={vehicles}
          disablePrice={disablePrice}
          priceVAT={priceVAT}
          hidePriceField={hidePriceField}
        />
      </Form>
    </Box>
  );
};

export const CarriersLoadsCreateOfferForm = memo(CreateOfferForm);
