import { FormControl, FormErrorMessage, FormHelperText, FormLabel, InputProps } from '@chakra-ui/react';
import { useField } from 'react-final-form';
import React, { useState } from 'react';
import closedEye from 'public/assets/icons/closed-eye.svg';
import eye from 'public/assets/icons/eye.svg';
import { UiIconButton, UiInputMasked } from '@/ui-elements';

export type UiMaskedFieldProps = InputProps & {
  name: string;
  label?: string;
  content?: string;
  required?: boolean;
  mask: string;
  canHide?: boolean;
};

export const UiMaskedField = ({ name, label, required, content, canHide, ...props }: UiMaskedFieldProps) => {
  const { isDisabled } = props;
  const {
    input,
    meta: { error, touched },
  } = useField(name);
  const [textIsHidden, setTextIsHidden] = useState(false);
  const invalid = error && touched;

  return (
    <FormControl isRequired={required && !isDisabled} isInvalid={invalid}>
      <FormLabel mb={1} fontSize="xs" className="ui-field__label">
        {label}
      </FormLabel>
      <UiInputMasked {...props} {...input} isInvalid={invalid} type={textIsHidden ? 'password' : 'text'}>
        {canHide && (
          <UiIconButton aria-label="show" icon={canHide && !textIsHidden ? closedEye : eye} onClick={() => setTextIsHidden((prev) => !prev)} />
        )}
      </UiInputMasked>
      {invalid ? <FormErrorMessage>{error}</FormErrorMessage> : <FormHelperText>{content}</FormHelperText>}
    </FormControl>
  );
};
