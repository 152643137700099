import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys);

const variants = {
  filterDropdown: {
    list: {
      py: '8px',
      borderRadius: '8px',
      border: 'none',
      boxShadow: '0 4px 16px rgba(0,0,0, 0.18)',
    },
    item: {
      color: 'typography.50',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 600,
      height: '40px',
      _hover: {
        bg: 'products.400',
      },
      _focus: {
        bg: 'products.400',
      },
      _checked: {
        bg: 'products.200',
        color: 'typography.250',
      },
    },
  },
};

export const menuTheme = defineMultiStyleConfig({ variants });
