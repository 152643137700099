import './carriers-loads-card.scss';
import { FC } from 'react';
import classNames from 'classnames';
import { Box, Center, Divider, Heading, HStack, Text } from '@chakra-ui/react';
import { head, isEmpty, toNumber } from 'lodash';
import { getCarrierStatusesStyles } from 'components/carriers-loads/card/get-carrier-statuses-styles';
import { toPriceFormat } from '@/helpers/price/to-price-format';
import { PaymentChip } from '@/ui-elements/payment-chip/payment-chip';
import { OrderCardRoute } from '@/components/order-card-route/order-card-route';
import { OrderGeneralDistance } from '@/components/order-general-distance/order-general-distance';
import { Order } from '@/types/models/order';
import { CarrierLoadGeneralInfo } from '../general-info/general-info';
import { CarrierLoadReqestButton } from '../request-button/request-button';
import { UiStatus } from '@/ui-elements/status/status';
import { OrderOffer } from '@/types/models/order-offer';
import { handlePriceChipVisibility } from '@/helpers/price/handle-price-chip-visibility';

interface Props {
  load?: Order;
  withStatus: boolean;
  selected: boolean;
  handleSelectLoad: (id: number) => void;
  handleRequestOrder?: (order: Order) => void;
  requestButtonProps?: {
    label?: string;
    disable?: boolean;
  };
}

export const CarrierLoadsCard: FC<Props> = ({ load, handleSelectLoad, handleRequestOrder, requestButtonProps, selected, withStatus }) => {
  if (isEmpty(load)) return null;
  const { id, carrierPayment, customer, distance, shippedTotals, points, type, loadingType, status, offer } = load;

  const statusStyle = withStatus
    ? getCarrierStatusesStyles({
        status,
      })
    : null;

  const carrierLoadsOffer: OrderOffer | undefined = head(offer);

  const isVisiblePrice = handlePriceChipVisibility({ carrierAcceptedOffer: carrierLoadsOffer, carrierPayment, status });

  return (
    <Box className={classNames('carrier-load-card', { selected })} onClick={() => handleSelectLoad(id)}>
      <HStack className="carrier-load-card__header" wrap="wrap">
        <HStack wrap="wrap">
          <Text className="carrier-load-card__title">{customer?.name}</Text>
          <Heading className="carrier-load-card__sub-title">{load?.id}</Heading>
        </HStack>
        <HStack wrap="wrap">
          {isVisiblePrice({ vat: true }) && toNumber(carrierPayment.priceWithVAT) > 0 && (
            <PaymentChip type="priceWithVAT" amount={toPriceFormat(carrierPayment.priceWithVAT, true)} />
          )}
          {isVisiblePrice({ vat: false }) && toNumber(carrierPayment.priceWithoutVAT) && (
            <PaymentChip type="priceWithoutVAT" amount={toPriceFormat(carrierPayment.priceWithoutVAT, true)} />
          )}
          {statusStyle && (
            <UiStatus key={statusStyle.text} text={statusStyle.text} backGroundColor={statusStyle.backgroundColor} color={statusStyle.color} />
          )}
        </HStack>
      </HStack>
      <OrderCardRoute points={points} order={load} />
      <HStack wrap="wrap" justifyContent="space-between">
        <HStack className="carrier-load-card__information">
          <HStack className="carrier-load-card__information-general" wrap="wrap">
            <OrderGeneralDistance type={type} range={distance || 0} />
            <Center className="carrier-load-card__information-divider__container">
              <Divider className="carrier-load-card__information-divider" orientation="vertical" />
            </Center>
            <CarrierLoadGeneralInfo shippedTotals={shippedTotals} vehicleTypes={load.vehicleTypes} loadingType={loadingType || []} />
          </HStack>
        </HStack>

        {handleRequestOrder && (
          <CarrierLoadReqestButton
            handleRequestOrder={() => {
              handleRequestOrder(load);
            }}
            buttonProps={requestButtonProps}
          />
        )}
      </HStack>
    </Box>
  );
};
