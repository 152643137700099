import { OrdersApi } from '@/api/orders';
import { useQueryWithUpdates } from '@/hooks/use-query-with-updates';
import { OrderCreateSource } from '@/types/api/order-partial';
import { Load } from '@/types/models/loads';
import { Order } from '@/types/models/order';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';

type Props = {
  id?: number;
  staleTime?: number;
  fromSource?: OrderCreateSource;
  updates?: boolean;
};

export const useOrdersWithMyParticipationUpdates = ({ id, staleTime, fromSource = OrderCreateSource.ILSExecution, updates = false }: Props) => {
  const queryFn = () => OrdersApi.getOrderWithMyParticipation(id!, { updates, fromSource });

  const orderQuery = useQueryWithUpdates<ResponseWithoutPagerSimple<Load | Order>>({
    queryKey: ['order', id],
    enabled: !!id,
    staleTime,
    queryFn,
  });
  const { data, isInitialLoading, refetch, isRefetching } = orderQuery;
  const order = data?.data;

  return {
    order,
    isInitialLoading,
    refetch,
    isRefetching,
  };
};
