import { FC } from 'react';
import { FormMode } from '@/components/verification-forms/types';
import { ModalsProps } from '@/types/common/modals';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';
import { ModalVehicle } from './modal-vehicle';
import { useVehicle } from '@/features/vehicles/hooks/use-vehicle';
import { useCarrier } from '@/features/carriers/hooks/use-carrier';
import { vehicleToFormValues } from '@/components/verification-forms/helpers/vehicle-to-form-values';

export const ModalEditVehicle: FC<ModalsProps<{ vehicleId?: number }>> = ({ onClose, vehicleId, modalOpened }) => {
  const {
    vehicle: { data: vehicle },
  } = useVehicle(vehicleId);
  const {
    carrier: { data: carrierResp },
  } = useCarrier(vehicle?.data.carrierId);
  const carrier = carrierResp?.data;
  const initialValues = vehicle && carrier && vehicleToFormValues({ values: vehicle.data, carrier });

  if (!vehicle) return <UiSpinner visible />;
  return (
    <ModalVehicle
      title={`Вы редактируете ТС ${vehicle.data.name}`}
      mode={FormMode.edit}
      modalOpened={modalOpened}
      onClose={onClose}
      initialValues={initialValues}
      vehicleId={vehicleId}
    />
  );
};
