import { FC } from 'react';
import { JOIN_ORDERS, MULTIPLE_SELECTED_MESSAGE } from 'components/order-details/order-details-multiple-selected/texts';
import { CANCEL } from 'config/constants/common-strings';
import { useIcons } from '@/hooks/use-icons';
import './order-details-multiple-selected.scss';
import { UiButtonFilled, UiButtonOutlined } from '@/ui-elements';

type Props = {
  // отмена выбора несолкьих заявок
  onCancelMultipleSelectClick: VoidFunction;

  // клик по кнопке объединить заявки
  onJoinOrdersClick: VoidFunction;
};
export const OrderDetailsMultipleSelected: FC<Props> = ({ onCancelMultipleSelectClick, onJoinOrdersClick }) => {
  const { JoinIcon } = useIcons();
  return (
    <div className="order-details-multiple-selected">
      <div>{MULTIPLE_SELECTED_MESSAGE}</div>
      <div className="order-details-multiple-selected__buttons">
        <UiButtonOutlined onClick={onCancelMultipleSelectClick}>{CANCEL}</UiButtonOutlined>
        <UiButtonFilled leftIcon={JoinIcon} onClick={onJoinOrdersClick}>
          {JOIN_ORDERS}
        </UiButtonFilled>
      </div>
    </div>
  );
};
