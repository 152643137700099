import { Routes } from 'config/routes/routes';
import { EMPTY_STRING } from 'config/constants/common-strings';

export const navigationTitles: Record<Routes, string> = {
  [Routes.Orders]: 'Исполнение',
  [Routes.BookerOrders]: 'Учет оплаты рейсов',
  [Routes.BookerClients]: 'Клиенты',
  [Routes.Carriers]: 'Перевозчики',
  [Routes.Clients]: 'Клиенты',
  [Routes.Analytics]: 'Аналитика',
  [Routes.Map]: 'Карта',
  [Routes.Security]: 'Безопасность',
  [Routes.Verification]: 'Проверка',
  [Routes.VerificationHistory]: 'История проверок',
  [Routes.Statistics]: 'Статистика',
  [Routes.LogIn]: 'Вход',
  [Routes.Register]: 'Регистрация',
  [Routes.FuelCards]: 'Топливо',
  [Routes.UnderConstruct]: EMPTY_STRING,
  [Routes.MyOrganizations]: 'Мои организации',
  [Routes.MyOrganization]: 'Моя организация',
  [Routes.PublicOfferAgreement]: EMPTY_STRING,
  [Routes.Settings]: 'Настройки',
  [Routes.Loads]: 'Поиск грузов',
  [Routes.CarriersLoads]: 'Поиск грузов',
  [Routes.CarriersLoadsAuthorized]: 'Поиск грузов',
  [Routes.CarriersLoadsAuthorizedSimple]: 'Поиск рейсов',
  [Routes.Funnel]: 'Согласование',
  [Routes.CRM]: 'CRM',
  [Routes.CurrentRoutes]: 'Текущие рейсы',
  [Routes.OrdersWithMyParticipation]: 'Мои отклики',
  [Routes.Resources]: 'Мои ресурсы',
  [Routes.FutureRoutes]: 'Будущие рейсы',
  [Routes.CompletedRoutes]: 'Завершенные рейсы',
  [Routes.FutureRoutesSimple]: 'Будущие рейсы',
  [Routes.CompletedRoutesSimple]: 'Завершенные рейсы',
  [Routes.MyOrganizationSimple]: 'Регистрационные данные',
  [Routes.ResourcesSimple]: 'Мои авто',
};
