import { FC } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { Chars } from 'config/constants/common-strings';
import { useIcons } from '@/hooks/use-icons';

type Props = {
  className: string;
  volume: string | number | null | undefined;
};

export const Volume: FC<Props> = ({ volume, className }) => {
  const { VolumeIcon } = useIcons();

  if (isNil(volume)) return null;

  return (
    <HStack className={classNames('volume', className)}>
      <VolumeIcon />
      <Text>{`${volume}${Chars.NoBreak}м³`}</Text>
    </HStack>
  );
};
