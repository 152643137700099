import { useDisclosure } from '@chakra-ui/react';
import React from 'react';

export function usePopover<T extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>>(
  element: T,
  props: Omit<React.ComponentProps<T>, 'onToggle' | 'onClose' | 'isOpen'>
) {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const popoverButton = React.createElement(element, {
    isOpen,
    onClose,
    onToggle,
    ...props,
  });

  return { popoverButton };
}
