import React, { FC } from 'react';
import classNames from 'classnames';
import { Flex, Text } from '@chakra-ui/react';
import './settings.scss';
import { emptyFunction } from '@/helpers/empty-function';
import { SettingsCardType } from '@/types/models/settings-card';

/*
 * Карточка раздела настройки (формирование документов)
 * */
type Props = { data: SettingsCardType; selected: boolean; handleSettingsClick?: Function };
export const SettingsCard: FC<Props> = ({ data, selected = false, handleSettingsClick = emptyFunction }) => {
  return (
    <Flex
      className={classNames('settings-card', { selected })}
      pl="16px"
      pr="16px"
      m="24px"
      w="100%"
      alignItems="center"
      onClick={() => handleSettingsClick(data.id)}
    >
      <Text className="settings-card__text" w="100%">
        {data.name}
      </Text>
    </Flex>
  );
};
