import qs from 'query-string';
import { UserRole } from '@/types/api/user-role';

export const getURL = (currentRole: UserRole | undefined): string => {
  let url = '';
  if (currentRole === UserRole.Administrator || currentRole === UserRole.Manager) {
    url = qs.stringifyUrl({
      url: '/orders',
    });
  } else if (currentRole === UserRole.Booker) {
    url = qs.stringifyUrl({
      url: '/booker_orders',
    });
  } else if (currentRole === UserRole.Security) {
    url = qs.stringifyUrl({
      url: '/verification',
    });
  }
  return url;
};
