import { Box, HStack, VStack, Badge, Text, BoxProps } from '@chakra-ui/react';
import classNames from 'classnames';
import last from 'lodash/last';
import { UiIconButton } from '@/ui-elements';
import './file-upload-card.scss';
import { useIcons } from '@/hooks/use-icons';

export type Extension = 'jpg' | 'jpeg' | 'pdf' | 'docx' | 'xls' | 'doc' | 'png' | undefined;

type Props = {
  fileName: string;
  onIconClick?: VoidFunction;
  onDeleteClick?: ((id: string | number) => void) | null;
  hideDeleteButton?: boolean;
  icon?: string;
  className?: string;
  extension?: Extension;
} & BoxProps;

export const UiFileUploadCard = ({ fileName, onIconClick, onDeleteClick, hideDeleteButton = false, icon, className, extension }: Props) => {
  const fileExtension = extension ?? (last(fileName.split('.')) as Extension);
  const { Download, DeleteIcon } = useIcons();

  const getBadge = (ext: Extension) => {
    switch (ext) {
      case 'jpeg':
      case 'jpg':
      case 'doc':
      case 'png':
      case 'docx':
        return <Badge colorScheme="twitter">{ext.toUpperCase()}</Badge>;
      case 'pdf':
        return <Badge colorScheme="red">{ext.toUpperCase()}</Badge>;
      case 'xls':
        return <Badge colorScheme="green">{ext.toUpperCase()}</Badge>;
      default:
        return null;
    }
  };
  return (
    <Box className={classNames('file-upload-card', className)}>
      <HStack align="top" justify="start" w="full" spacing={0}>
        <VStack align="start" className="file-upload-card__content" w="full">
          <Text className="file-upload-card__file-name" noOfLines={2} textStyle="p5">
            {fileName}
          </Text>
          {getBadge(fileExtension)}
        </VStack>
        <VStack spacing={0} align="end">
          {onIconClick && (
            <Box mt="4px" ml="6px">
              <UiIconButton icon={icon || Download} onClick={onIconClick} aria-label="download" className="file-upload-card__btn" />
            </Box>
          )}
          {(!hideDeleteButton || Boolean(onDeleteClick)) && (
            <Box mt="4px" ml="6px">
              <UiIconButton icon={DeleteIcon} onClick={onDeleteClick as any} aria-label="delete" className="file-upload-card__btn" />
            </Box>
          )}
        </VStack>
      </HStack>
    </Box>
  );
};
