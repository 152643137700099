import { useEffect } from 'react';
import qs from 'query-string';
import { useNavigate } from 'react-router-dom';
import { useFunnelActions } from '@/features/funnel/hooks/use-funnel-actions';
import { Load } from '@/types/models/loads';

export const useFunnelPage = (loads?: Array<Load>, activeLoadIds?: Array<number>, redirectId?: number) => {
  const { clearState } = useFunnelActions();
  const navigate = useNavigate();

  useEffect(() => {
    if (!activeLoadIds?.length && loads?.length && !redirectId) {
      // select(orders[0].id);
      const url = qs.stringifyUrl({
        url: '/funnel',
        query: {
          id: loads[0].id,
        },
      });
      navigate(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loads, activeLoadIds, redirectId]); // + select

  useEffect(() => {
    // при переключении на другую страницу сбрасывает стор с настройками страницы с заказами
    // TODO: подумать возможно это стоит сделать на уровне роутера?
    return () => {
      clearState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
