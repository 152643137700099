import { FC } from 'react';
import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import { INN_MASK, KPP_MASK, OGRN_MASK, OKPO_MASK, PASSPORT_MASK } from 'components/auth-form/constants';
import { VerificationFormMode } from 'components/verification-forms/types';
import { DateFormat } from 'config/constants/date';
import { UiInputField, UiMaskedField, UiMultiDatePickerField } from '@/ui-elements';
import { CarrierAddressesSubform } from '@/components/verification-forms/organization-verification/subforms/carrier-addresses-subform';
import { CarrierOrganizationType } from '@/components/verification-forms/organization-verification/subforms/carrier-organization-type';

export const CarrierInfoSubform: FC<{ mode: VerificationFormMode; datePickerRef: any }> = ({ mode, datePickerRef }) => {
  return (
    <>
      <Grid templateColumns="repeat(3, 1fr)" gap={6} mt={3.5}>
        <GridItem className={mode === 'check' ? `carriers-verification-form__small-row` : ''}>
          <CarrierOrganizationType mode={mode} />
        </GridItem>
        <GridItem className={mode === 'check' ? `carriers-verification-form__small-row` : ''}>
          <UiInputField name="name" label="Название организации" placeholder="Организация" required isDisabled={mode === 'check'} />
        </GridItem>
        <GridItem className={mode === 'check' ? `carriers-verification-form__small-row` : ''}>
          <UiMaskedField name="INN" mask={INN_MASK} label="ИНН" placeholder="ИНН" required isDisabled={mode === 'check'} />
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(3, 1fr)" gap={6} mt={3.5}>
        <GridItem className={mode === 'check' ? `carriers-verification-form__small-row` : ''}>
          <UiMaskedField name="KPP" mask={KPP_MASK} label="КПП" placeholder="КПП" isDisabled={mode === 'check'} />
        </GridItem>
        <GridItem className={mode === 'check' ? `carriers-verification-form__small-row` : ''}>
          <UiMaskedField name="OGRN" mask={OGRN_MASK} label="ОГРН" placeholder="ОГРН" isDisabled={mode === 'check'} />
        </GridItem>
        <GridItem className={mode === 'check' ? `carriers-verification-form__small-row` : ''}>
          <UiMaskedField name="OKPO" mask={OKPO_MASK} label="ОКПО" placeholder="ОКПО" isDisabled={mode === 'check'} />
        </GridItem>
      </Grid>

      <CarrierAddressesSubform mode={mode} />

      <Text fontWeight={700} fontSize={18} mt={mode === 'check' ? -1.5 : 1}>
        Руководитель
      </Text>

      <Grid templateColumns="repeat(3, 1fr)" gap={6} mt={3.5} className="carriers-verification-form">
        <GridItem className={mode === 'check' ? `carriers-verification-form__small-row` : ''}>
          <UiInputField name="directorSurname" label="Фамилия" placeholder="Фамилия" isDisabled={mode === 'check'} />
        </GridItem>
        <GridItem className={mode === 'check' ? `carriers-verification-form__small-row` : ''}>
          <UiInputField name="directorName" label="Имя" placeholder="Имя" isDisabled={mode === 'check'} />
        </GridItem>
        <GridItem className={mode === 'check' ? `carriers-verification-form__small-row` : ''}>
          <UiInputField name="directorPatronymic" label="Отчество" placeholder="Отчество" isDisabled={mode === 'check'} />
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(3, 1fr)" gap={6} mt={-1} className="carriers-verification-form">
        <GridItem className={mode === 'check' ? `carriers-verification-form__small-row` : ''}>
          <UiMaskedField
            name="passportNumber"
            label="Серия и номер паспорта"
            placeholder="Серия/номер"
            isDisabled={mode === 'check'}
            mask={PASSPORT_MASK}
          />
        </GridItem>
        <GridItem className={mode === 'check' ? `carriers-verification-form__small-row` : ''}>
          <UiMultiDatePickerField
            name="passportDate"
            label="Дата выдачи"
            placeholder="Дата выдачи"
            isDisabled={mode === 'check'}
            datePickerRef={datePickerRef}
            format={DateFormat.DDMMYYYYSlash}
          />
        </GridItem>
        <GridItem className={mode === 'check' ? `carriers-verification-form__small-row` : ''}>
          <UiMultiDatePickerField
            name="birthday"
            label="Дата рождения"
            placeholder="Дата рождения"
            isDisabled={mode === 'check'}
            datePickerRef={datePickerRef}
            format={DateFormat.DDMMYYYYSlash}
          />
        </GridItem>
      </Grid>
      <Box mt={-1}>
        <UiInputField name="passportGiven" label="Кем выдан" placeholder="Кем выдан" isDisabled={mode === 'check'} />
      </Box>
    </>
  );
};
