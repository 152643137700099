import { FC } from 'react';
import classNames from 'classnames';
import { Box, Center, Divider, Flex, HStack, Text } from '@chakra-ui/react';
import { DELETE, LAST_CRUISE, MORE_INFO } from 'components/driver-card-compact/texts';
import { CarrierCardRegNumber } from 'components/carrier-card/carrier-card-reg-number';
import { VehicleTrailersCardCompact } from 'components/vehicle-card-compact/vehicle-trailers-card-compact';
import { EDIT_BUTTON } from 'components/vehicle-card-compact/texts';
import { LoadingTypeComponent } from 'components/loading-type/loading-type';
import { UiButtonOutlined, UiDivider, UiIconButton } from '@/ui-elements';
import { emptyFunction } from '@/helpers/empty-function';
import './vehicle-card-compact.scss';
import { Vehicle } from '@/types/models/vehicle';
import { OrderGeneralInfo } from '@/components';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { vehicleExtender } from '@/features/vehicles/extender/vehicle-extender';
import { UiStatus } from '@/ui-elements/status/status';
import { ShippedTotals } from '@/types/models/shipped-totals';
import { useVehicleTypesIcons } from '@/features/vehicle-types/hooks/use-vehicle-types-icons';
import { useVehicleTypesNames } from '@/features/vehicle-types/hooks/use-vehicle-types-names';
import { AccidentsStatus } from '@/ui-elements/status/accidents-status';
import { ModalAction, useModal } from '@/hooks/use-modal';
import { ModalType } from '@/types/common/modals';
import { useIcons } from '@/hooks/use-icons';
import { UiTooltip } from '@/ui-elements/tooltip/tooltip';
import { getApprovalStatusCarrier } from '@/helpers/get-approval-status-carrier';

type Props = {
  vehicle: Vehicle;
  department?: string;
  onMoreInfoClick?: (vehicle: Vehicle) => void;
  className?: string;
  shippedTotals?: ShippedTotals;
  showGeneralInfo?: boolean;
  hideEditButtons?: boolean;
};
/**
 *Компактная карточка с описанием транспортного средства
 * */
export const VehicleCardCompact: FC<Props> = ({
  shippedTotals = {},
  vehicle,
  department,
  className,
  onMoreInfoClick = emptyFunction,
  showGeneralInfo = false,
  hideEditButtons = false,
}) => {
  const { lastCruiseNumber, vehicleRegNumber, vehicleRegion, accidentsCount, loadingType } = useExtendedObject(vehicle, vehicleExtender);
  const { EditIcon, DeleteIcon, TonageIcon, VolumeIcon } = useIcons();
  const vehicleTypeIcons = useVehicleTypesIcons();
  const vehicleTypeNames = useVehicleTypesNames();
  const { modalView: ModalEditVehicle, setModalOpened: showEditVehicleModal } = useModal<{ vehicleId: number } & ModalAction>(ModalType.EditVehicle, {
    vehicleId: vehicle.id,
  });
  const handleOpenEditModal = () => {
    showEditVehicleModal(true);
  };
  const { modalView: ModalDeleteVehicle, setModalOpened: showDeleteVehicle } = useModal<{ text: string; vehicleID?: number } & ModalAction>(
    ModalType.DeleteVehicle,
    {
      text: 'ТС будет удалено',
      vehicleID: vehicle.id,
    }
  );
  const handleDeleteVehicle = () => {
    showDeleteVehicle(true);
  };
  return (
    <div className={classNames('vehicle-card-compact', className)}>
      <HStack className="vehicle-card-compact__body" justifyContent="space-between" alignItems="flex-start">
        <div className="vehicle-card-compact__info">
          <HStack className="vehicle-card-compact__title">
            {vehicle.vehicleType && <span className="vehicle-card-compact__title_name">{(vehicleTypeNames as any)[vehicle.vehicleType]}</span>}
          </HStack>
          {department && <div className="vehicle-card-compact__area">{department}</div>}
        </div>
        <div className="vehicle-card-compact__statuses">
          <div className="vehicle-card-compact__statuses-box">
            <span className="vehicle-card-compact__statuses-box__approved">{getApprovalStatusCarrier(vehicle.approvalStatus)}</span>
            {accidentsCount === 0 && <UiStatus backGroundColor="accentGreen.150" text="Аварий не было" color="typography.250" />}
            {accidentsCount > 0 && <AccidentsStatus accidentsCount={accidentsCount} />}
          </div>

          <div>
            <span className="vehicle-card-compact__label">{LAST_CRUISE}:</span>
            <span className="vehicle-card-compact__last">{lastCruiseNumber}</span>
          </div>
        </div>
      </HStack>
      <HStack className="vehicle-card-compact__reg" justifyContent="space-between">
        <div className="vehicle-card-compact__reg_info">
          {vehicle?.vehicleType && <img width="48px" height="20px" src={(vehicleTypeIcons as any)[vehicle.vehicleType]} alt="vehicle img" />}
          {vehicle.vehicleType && <span className="vehicle-card-compact__small-name">{vehicleTypeNames[vehicle.vehicleType] || ''}</span>}

          <CarrierCardRegNumber regNumber={vehicleRegNumber} region={vehicleRegion} />
          {vehicle.volume ? (
            <>
              <Center className="order-general-info__divider-container">
                <Divider className="order-general-info__divider" orientation="vertical" />
              </Center>
              <Flex>
                <VolumeIcon />
                <Text>{vehicle.volume}м³</Text>
              </Flex>
            </>
          ) : null}
          {vehicle.capacity ? (
            <>
              <Center className="order-general-info__divider-container">
                <Divider className="order-general-info__divider" orientation="vertical" />
              </Center>
              <Flex>
                <TonageIcon />
                <Text>{vehicle.capacity}т</Text>
              </Flex>
            </>
          ) : null}
          {showGeneralInfo ? (
            <OrderGeneralInfo loadingType={vehicle.loadingType || []} shippedTotals={shippedTotals} vehicleTypes={[vehicle.vehicleType]} />
          ) : (
            <Flex>
              <LoadingTypeComponent loadingType={loadingType} />
            </Flex>
          )}
        </div>
      </HStack>

      <Flex justifyContent="end" gap={2} mt={3}>
        {!hideEditButtons && (
          <UiTooltip label={EDIT_BUTTON}>
            <UiIconButton
              className="vehicle-card-compact__button"
              onClick={handleOpenEditModal}
              disabled={!vehicle.id}
              aria-label={EDIT_BUTTON}
              icon={EditIcon}
            />
          </UiTooltip>
        )}
        {!hideEditButtons && (
          <UiTooltip label={DELETE}>
            <UiIconButton
              className="vehicle-card-compact__button"
              aria-label={DELETE}
              onClick={handleDeleteVehicle}
              disabled={!vehicle.id}
              icon={DeleteIcon}
            />
          </UiTooltip>
        )}
        <UiButtonOutlined disabled={!vehicle.id} onClick={() => onMoreInfoClick(vehicle)}>
          {MORE_INFO}
        </UiButtonOutlined>
      </Flex>

      <Box gap={2}>
        {vehicle.trailers?.map((t) => (
          <Box key={t.regNumber}>
            <UiDivider pt="20px" variant="dark" />
            <VehicleTrailersCardCompact trailer={t} vehicle={vehicle} department={department} />
          </Box>
        ))}
      </Box>
      {ModalDeleteVehicle}
      {ModalEditVehicle}
    </div>
  );
};
