import { LoadsApi } from '@/api/loads';
import { OrdersApi } from '@/api/orders';
import { useQueryWithUpdates } from '@/hooks/use-query-with-updates';
import { OrderCreateSource } from '@/types/api/order-partial';
import { FunnelCount } from '@/types/models/funnel';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';

export const useLoadsCountUpdates = () => {
  const loadsCount = useQueryWithUpdates<ResponseWithoutPagerSimple<FunnelCount>>({
    queryKey: ['loadsCount'],
    refetchOnWindowFocus: false,
    queryFn: (isFirst: boolean) => OrdersApi.getOrdersCount(!isFirst, OrderCreateSource.ILSConfirmation),
  });
  return { loadsCount };
};
