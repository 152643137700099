export enum Dictionary {
  Account = 'account',
  Address = 'address',
  Auth = 'Auth',
  Carriers = 'carriers',
  Code = 'code',
  Customers = 'customers',
  Drivers = 'drivers',
  RoadTrain = 'roadTrain',
  Notifications = 'notifications',
  Orders = 'orders',
  OrderServices = 'orderServices',
  Organizations = 'organizations',
  Vehicles = 'vehicles',
  VehicleTypes = 'vehicleTypes',
  Users = 'users',
  Reviews = 'reviews',
  Accept = 'accept',
  Decline = 'decline',
  Service = 'service',
  Attachments = 'attachments',
  Approvals = 'approvals',
  Offers = 'offers',
  Managers = 'managers',
  Documents = 'documents',
  Packages = 'packages',
  BankRequisites = 'bank/requisites',
  Settings = 'settings',
  Loads = 'loads',
}
