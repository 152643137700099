import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from '@chakra-ui/react';
import React, { FC } from 'react';

interface IProps {
  isOpen: boolean;
  onClose(): void;
  onConfirm(): void;
  title: string;
  description: string;
}

export const MyOrganizationConfirm: FC<IProps> = ({ isOpen, title, description, onClose, onConfirm }) => {
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>
          <AlertDialogBody>{description}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Нет
            </Button>
            <Button colorScheme="red" onClick={onConfirm} ml={3}>
              Да
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
