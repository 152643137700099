import { FC, SyntheticEvent, useRef } from 'react';
import { securityTableConfig } from 'components/security/security-table-component/security-table-config';
import { ApproveStatusNames } from 'config/constants/texts';
import { HStack } from '@chakra-ui/react';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { Approval } from '@/types/models/approval';
import { UiGridTable } from '@/ui-elements/ui-grid-table/ui-grid-table';
import './security-table-component.scss';
import { emptyFunction } from '@/helpers/empty-function';
import { UiSwitcher } from '@/ui-elements/switcher/switcher';
import { GridTableRefProps } from '@/ui-elements/ui-grid-table/uii-grid-table-type';
import { UiInput } from '@/ui-elements';

type Props = {
  approvals: Array<Approval>;
  selectedApprovalId?: number;
  onClickApproval?: (item: Approval) => void;
  loadMore?: (args: any) => void;
  onTabChange: (value: string) => void;
  onSearchChange: (value: string) => void;
  loading?: boolean;
  searchValue: string;
};

/**
 * Компонент  - таблица с запросами на проверку.
 * */
export const SecurityTableComponent: FC<Props> = ({
  approvals,
  selectedApprovalId,
  onClickApproval = emptyFunction,
  onTabChange,
  loadMore,
  loading,
  onSearchChange,
  searchValue,
}) => {
  const ref = useRef<GridTableRefProps>(null);
  const onChange = (value: string) => {
    onTabChange(value);
    ref.current?.scrollToTop();
  };

  const handleSearchChange = (e: SyntheticEvent<HTMLInputElement>) => {
    onSearchChange(e.currentTarget.value);
  };

  return (
    <UiGridTable
      config={securityTableConfig}
      headerClassName="security-table-component__header"
      bodyClassName="security-table-component__body"
      className="security-table-component"
      data={approvals}
      selectedId={selectedApprovalId}
      onClickItem={onClickApproval}
      loading={loading}
      loadMore={loadMore}
      isInfiniteScroll
      ref={ref}
    >
      <HStack className="security-table-component__filters" justifyContent="flex-start">
        <UiInput
          className="security-table-component__search"
          onChange={handleSearchChange}
          value={searchValue}
          onClear={() => onSearchChange(EMPTY_STRING)}
        />
        <UiSwitcher onChange={onChange} className="security-table-component__switcher" tabsList={ApproveStatusNames} />
      </HStack>
    </UiGridTable>
  );
};
