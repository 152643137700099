import { FC } from 'react';
import { ModalView } from '@/views/order-modal/order-modal-view';
import { ModalsProps } from '@/types/common/modals';
import { useOrderActions } from '@/features/order/hooks/use-order-actions';

export const ModalCloseUpdateOrder: FC<
  ModalsProps<{
    text?: string;
    modalWidth?: number;
    marginRight?: number;
    submitButtonText?: string;
    cancelButtonText?: string;
    desc?: string;
    alignDesc?: 'center' | 'left' | 'right';
    accentXBtn?: boolean;
    selectNewOrder: () => void;
  }>
> = ({ modalOpened, onClose, desc, alignDesc, selectNewOrder }) => {
  const { cancelCreate } = useOrderActions();
  const onSubmit = () => {
    selectNewOrder();
    cancelCreate();
    onClose();
  };

  return (
    <ModalView
      isOpen={modalOpened}
      onClose={onClose}
      title="Вы действительно хотите перейти на другую заявку?"
      onSubmit={onSubmit}
      submitButtonText="Подтвердить"
      desc={desc}
      alignDesc={alignDesc}
      modalWidth={544}
      justifyTitle="left"
    />
  );
};
