import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountState } from '@/features/account/types';
import { loginAction } from '@/features/account/actions/login-action';
import { Account } from '@/types/models/account';
import { logoutAction } from '@/features/account/actions/logout-action';
import { getSelfAccountAction } from '@/features/account/actions/get-self-account-action';

const initialState: AccountState = {
  user: undefined,
  authenticationPending: true,
  authenticated: false,
  logoutPending: false,
  error: undefined,
  loginError: undefined,
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginAction.pending, () => ({ ...initialState, authenticationPending: true, error: undefined }));
    builder.addCase(loginAction.fulfilled, (state, action: PayloadAction<Account>) => {
      state.user = action.payload;
      state.authenticated = true;
      state.authenticationPending = false;
      state.loginError = undefined;
    });
    builder.addCase(loginAction.rejected, (state, action) => {
      state.loginError = action.payload;
    });
    builder.addCase(logoutAction.pending, (state) => {
      state.logoutPending = true;
      state.authenticated = false;
      state.error = undefined;
      state.loginError = undefined;
    });
    builder.addCase(logoutAction.fulfilled, (state) => {
      state.authenticated = false;
      state.logoutPending = false;
    });
    builder.addCase(logoutAction.rejected, (state, action) => {
      state.logoutPending = false;
      state.error = action.payload;
    });
    builder.addCase(getSelfAccountAction.pending, (state) => {
      state.authenticationPending = true;
    });
    builder.addCase(getSelfAccountAction.fulfilled, (state, action: PayloadAction<Account>) => {
      state.authenticationPending = false;
      state.authenticated = true;
      state.user = { ...action.payload };

      return state;
    });
    builder.addCase(getSelfAccountAction.rejected, (state, action) => {
      state.authenticationPending = false;
      state.authenticated = false;
      state.error = action.payload;
      return state;
    });
  },
});

export const accountReducer = slice.reducer;
export const accountActions = slice.actions;
