import { FC } from 'react';
import { WidgetType } from '@/features/widget/widget-type';
import { WidgetProps } from '@/features/widget/types/widget-item';
import './add-service-widget.scss';
import { AddServiceWidget } from '@/components/add-service-widget/add-service-widget';
import { useAddServiceWidget } from '@/views/add-service-widget/use-add-service-widget';

export const AddServiceWidgetView: FC<WidgetProps & WidgetType['AddServiceWidget']> = ({ orderId, hideWidget, companyName, ...props }) => {
  const { order, handleSubmit } = useAddServiceWidget(orderId, hideWidget);
  return (
    <div className="add-service-widget-view">
      {order && order.data && (
        <AddServiceWidget order={order.data.data} companyName={companyName} onCancelClick={hideWidget} onSubmit={handleSubmit} {...props} />
      )}
    </div>
  );
};
