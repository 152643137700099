import { forwardRef } from 'react';
import { VStack } from '@chakra-ui/react';
import { GridTableConfig, GridTableRefProps } from '@/ui-elements/ui-grid-table/uii-grid-table-type';
import { UiGridTableHeaderMemoized, UiGridTableBody } from '@/ui-elements/ui-grid-table';
import { emptyFunction } from '@/helpers/empty-function';

type Props<T extends { id: number }> = {
  data: Array<T>;
  config: GridTableConfig<T>;
  className?: string;
  headerClassName?: string;
  bodyClassName?: string;
  selectedId?: number;
  onClickItem?: (item: T) => void;
  isInfiniteScroll?: boolean;
  loadMore?: (args: unknown) => void;
  loading?: boolean;
  children?: JSX.Element;
};

export const UiGridTable = forwardRef<GridTableRefProps, Props<any>>((props, ref) => {
  const {
    config,
    className,
    headerClassName,
    onClickItem = emptyFunction,
    data,
    bodyClassName,
    selectedId,
    loadMore = emptyFunction,
    loading = false,
    isInfiniteScroll = false,
    children,
  } = props;

  return (
    <VStack className={className}>
      {children}
      <UiGridTableHeaderMemoized config={config} className={headerClassName} />
      <UiGridTableBody
        config={config}
        data={data}
        className={bodyClassName}
        selectedId={selectedId}
        onClickItem={onClickItem}
        loading={loading}
        isInfiniteScroll={isInfiniteScroll}
        loadMore={loadMore}
        ref={ref}
      />
    </VStack>
  );
});
