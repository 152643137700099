import { VehicleWidget } from '@/components/vehicle-widget/vehicle-widget';
import { useVehicleTypesIcons } from '@/features/vehicle-types/hooks/use-vehicle-types-icons';
import { Vehicle } from '@/types/models/vehicle';
import './vehicle-widget.scss';
import { useVehicleTypesNames } from '@/features/vehicle-types/hooks/use-vehicle-types-names';
import { useVehicle } from '@/features/vehicles/hooks/use-vehicle';

type Props = {
  vehicle: Vehicle;
};

export const VehicleWidgetView = ({ vehicle }: Props) => {
  const data = useVehicle(vehicle.id);
  const vehicleTypeIcons = useVehicleTypesIcons();
  const vehicleTypesNames = useVehicleTypesNames();
  if (!vehicle) return null;
  return (
    <div className="vehicle-widget-view">
      <VehicleWidget
        doc={data.vehicle.data?.data.documents}
        vehicle={vehicle}
        vehicleTypeIcon={(vehicleTypeIcons as any)[vehicle.vehicleType] || ''}
        vehicleTypeName={vehicleTypesNames[vehicle.vehicleType] || ''}
      />
    </div>
  );
};
