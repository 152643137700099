import { Text, HStack, Box } from '@chakra-ui/react';
import { Trailer } from '@/types/models/vehicle';
import { UiInputField } from '@/ui-elements';

type Props = {
  trailers: Array<Trailer & { type: string }>;
};

export const TrailerBlock = ({ trailers }: Props) => {
  return trailers.length === 1 ? (
    <>
      <Text textStyle="h7">{`Полуприцеп: ${trailers[0].type || ''}`}</Text>
      <HStack w="100%">
        <UiInputField name="trailerRegNumber" initialValue={trailers[0].regNumber || 'Нет данных'} isDisabled label="Номер полуприцепа" />
        <UiInputField name="trailerCapacity" initialValue={trailers[0].capacity || 'Нет данных'} isDisabled label="Грузоподъемность в тоннах" />
        <UiInputField name="trailerDimensions" initialValue={trailers[0].dimensions || 'Нет данных'} isDisabled label="Размеры (ДхШхВ) в метрах" />
      </HStack>
    </>
  ) : (
    <>
      {trailers.map((trailer, index) => (
        <Box key={trailer.regNumber}>
          <Text textStyle="h7">{`Полуприцеп: ${trailer.type || ''}`}</Text>
          <HStack w="100%">
            <UiInputField name={`trailerType.${index}`} initialValue={trailer.type || 'Нет данных'} isDisabled label="Тип полуприцепа" />
            <UiInputField name={`trailerRegNumber.${index}`} initialValue={trailer.regNumber || 'Нет данных'} isDisabled label="Номер полуприцепа" />
            <UiInputField
              name={`trailerCapacity.${index}`}
              initialValue={trailer.capacity || 'Нет данных'}
              isDisabled
              label="Грузоподъемность в тоннах"
            />
            <UiInputField
              name={`trailerDimensions.${index}`}
              initialValue={trailer.dimensions || 'Нет данных'}
              isDisabled
              label="Размеры (ДхШхВ) в метрах"
            />
          </HStack>
        </Box>
      ))}
    </>
  );
};
