import { FC } from 'react';
import { HStack } from '@chakra-ui/react';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { useIcons } from '@/hooks/use-icons';
import { formatMass } from '@/helpers/format-mass';
import { UiText } from '@/ui-elements/ui-text/ui-text';

type Props = {
  className: string;
  mass: string | number | null | undefined;
};

export const Mass: FC<Props> = ({ mass, className }) => {
  const { TonageIcon } = useIcons();

  if (isNil(mass)) return null;

  return (
    <HStack className={classNames('mass', className)}>
      <TonageIcon />
      <UiText>{formatMass(mass)}</UiText>
    </HStack>
  );
};
