export const FormTheme = {
  Input: {
    variants: {
      outline: () => ({
        field: {
          _invalid: {
            borderColor: 'accentRed.100',
            boxShadow: 'none',
          },
        },
      }),
    },
    defaultProps: {
      errorBorderColor: 'accentRed.100',
    },
  },
  Form: {
    baseStyle: () => ({
      requiredIndicator: {
        color: 'accentRed.150',
        lineHeight: '16px',
        fontSize: '12px',
        fontWeight: 600,
      },
      container: {
        label: {
          fontSize: '12px',
          lineHeight: '16px',
          fontWeight: 400,
          color: 'typography.50',
          display: 'flex',
        },
      },
      helperText: {
        color: 'typography.150',
        lineHeight: '16px',
        fontSize: '12px',
        fontWeight: 400,
        marginTop: '4px',
        minHeight: '16px',
      },
    }),
  },
  FormError: {
    baseStyle: () => ({
      text: {
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 400,
        color: 'accentRed.100',
        marginTop: '4px',
        minHeight: '16px',
      },
    }),
  },
};
