import { useRef } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AttachmentsApi } from '@/api/attachments';
import { AttachmentNew } from '@/types/models/attachments';
import { FileFormField, MyOrganizationFormField } from '@/types/models/my-organization/form';
import { useCRUDorganizationDocuments } from '@/features/my-organization/use-crud-organization-documents';
import { useGetMyOrganizationDocuments } from './use-get-my-organization-documents';

type UseMyOrganizationDocuments = { organizationID: number | undefined; driverId?: number | undefined };
type FilesToDelete = { id: number | null; fileField: FileFormField | null };

export const useMyOrganizationDocuments = (args: UseMyOrganizationDocuments) => {
  const { organizationID, driverId } = args;
  const queryClient = useQueryClient();
  const filesToDelete = useRef<FilesToDelete>({ id: null, fileField: null });

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { mutate: postDocument } = useMutation({
    mutationKey: ['postAttachment'],
    mutationFn: (data: AttachmentNew) => AttachmentsApi.postAttachment(data),
    onSuccess: () => queryClient.invalidateQueries(['myOrganizationData']),
  });

  const { driverLicenseDoc, innOgrnDocuments, passportDocuments } = useGetMyOrganizationDocuments({ organizationID, driverId });

  const documents = {
    [MyOrganizationFormField.PassportDocument]: passportDocuments?.data,
    [MyOrganizationFormField.InnOgrnDocument]: innOgrnDocuments?.data,
    [MyOrganizationFormField.DriversLicenseDoc]: driverLicenseDoc?.data,
  };

  const { sendFiles, updateFile, downloadAttachmentById, removeAttachment } = useCRUDorganizationDocuments({
    organizationID,
    driverId,
    postDocument,
    documents,
  });

  const onCloseDeleteDocumentModal = () => {
    filesToDelete.current = {
      id: null,
      fileField: null,
    };
    onClose();
  };

  const onCloseByDeleteDocument = () => {
    const { id, fileField } = filesToDelete.current;
    if (id && fileField) {
      removeAttachment(id, fileField);
    }
    onCloseDeleteDocumentModal();
  };

  const onOpenDeleteDocumentModal = (id: number, fileField: FileFormField) => {
    filesToDelete.current = {
      id,
      fileField,
    };
    onOpen();
  };

  const documentConfirmProps = {
    isOpen,
    onClose: onCloseDeleteDocumentModal,
    onConfirm: onCloseByDeleteDocument,
    title: 'Удаление документа',
    description: 'Продолжить?',
  };

  return {
    postDocument,
    documentConfirmProps,
    onOpenDeleteDocumentModal,
    documents,
    sendFiles,
    updateFile,
    filesToDelete,
    downloadAttachmentById,
    removeAttachment,
  };
};
