import './funnel-list-view.scss';
import { useFunnelList } from '@/features/funnel/hooks';
import { FunnelList } from '@/views/funnel-list/funnel-list';
import { FunnelFilters } from '@/components/funnel/filters/funnel-filters';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';

export const FunnelListView = () => {
  const {
    currentLoadID,
    allLoads,
    activeTab,
    switcherLabels,
    periodValue,
    listIsLoading,
    userHasIntegration,
    handleSelectLoad,
    handleChangeActiveTab,
    handleCreateLoad,
    fetchNextPage,
    handleChangePeriod,
    handleChangeFilters,
  } = useFunnelList();

  return (
    <div className="funnel-list-view">
      <FunnelFilters
        activeTab={activeTab}
        switcherLabels={switcherLabels}
        periodValue={periodValue}
        onChangeActiveTab={handleChangeActiveTab}
        onCreateLoad={handleCreateLoad}
        userHasIntegration={userHasIntegration}
        handleChangePeriod={handleChangePeriod}
        handleChangeFilters={handleChangeFilters}
      />
      {listIsLoading ? (
        <UiSpinner visible mt={28} />
      ) : (
        <FunnelList
          loadsList={allLoads}
          isLoading={listIsLoading}
          currentLoadID={currentLoadID}
          userHasIntegration={userHasIntegration}
          handleSelectLoad={handleSelectLoad}
          fetchNextPage={fetchNextPage}
        />
      )}
    </div>
  );
};
