import { FuelStatus } from 'config/constants/statuses';

/*
 * Карта перехода статусов. possibleNextStatuses  показывает возможные варианты движения
 *  "*" - движение куда угодно
 *
 * */
export const orderFuelStatusMap: Record<
  FuelStatus,
  { possibleNextStatuses: Array<FuelStatus> | undefined; defaultPrevStatus?: FuelStatus; defaultNextStatus?: FuelStatus }
> = {
  confirmedWithoutFuel: { possibleNextStatuses: undefined },
  executingWithoutFuel: { possibleNextStatuses: undefined },
  completedWithoutFuel: { possibleNextStatuses: undefined },
  confirmedWithFuel: { possibleNextStatuses: [FuelStatus.ExecutingFuelProvided, FuelStatus.CompletedFuelClosed] },
  executingStartPreparation: { possibleNextStatuses: [FuelStatus.ExecutingFuelProvided, FuelStatus.CompletedFuelClosed] },
  executingFuelProvided: { possibleNextStatuses: [FuelStatus.CompletedFuelClosed] },
  completedBlockFuel: { possibleNextStatuses: [FuelStatus.CompletedFuelClosed] },
  completedFuelClosed: { possibleNextStatuses: undefined },
};
