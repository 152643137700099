import { useQueryClient } from '@tanstack/react-query';
import { OrderStatus } from 'config/constants/statuses';
import { useOrderDataActions } from '@/features/order/hooks/use-order-data-actions';
import { checkOrderNextStatus } from '@/features/order/helpers/check-order-next-status';
import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';

export const useOrderStatus = (order?: Order | Load | null) => {
  const { updateOrderStatus } = useOrderDataActions(order?.id!);
  const client = useQueryClient();

  const setNewOrderStatus = (nextStatus: OrderStatus, onSuccess?: VoidFunction) => {
    if (!order) return;
    if (checkOrderNextStatus(order, nextStatus)) {
      updateOrderStatus.mutate(
        { status: nextStatus },
        {
          onSuccess: () => {
            client.invalidateQueries(['order', order.id]);
            client.invalidateQueries(['allOrders']);
            client.invalidateQueries(['ordersCount']);
            onSuccess?.();
          },
        }
      );
    }
  };

  return { setNewOrderStatus };
};
