import { useMemo } from 'react';
import { driverToFormValues } from 'components/verification-forms/helpers/driver-to-form-values';
import { useDriver } from '@/features/drivers/hooks/use-driver';
import { useCarrier } from '@/features/carriers/hooks/use-carrier';
import { Driver } from '@/types/models/driver';

export const useModalDriver = ({ driverId }: { driverId?: Driver['id'] }) => {
  const {
    driver: { data: driverResp },
  } = useDriver(driverId);

  const driver = driverResp?.data;

  const {
    carrier: { data: carrierResp },
  } = useCarrier(driver?.carrierId);

  const carrier = carrierResp?.data;

  const initialValues = useMemo(() => driverToFormValues({ carrier, values: driver }), [driver]);

  return {
    initialValues,
    driver,
  };
};
