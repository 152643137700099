import { OrderForm } from '@/components/create-order-form/order-form';
import { useOrderUpdateView } from '@/views/order-update-view/use-order-update-view';

export const OrderUpdateView = () => {
  const {
    // isInitialLoading,
    updatedValues,
    documents,
    deleteDocuments,
    postDocuments,
    downloadDocuments,
    onSubmit,
    submitting,
    cancelUpdate,
    cargos,
  } = useOrderUpdateView();

  return (
    <OrderForm
      onSubmit={onSubmit}
      isUpdating
      initialValues={updatedValues as any}
      submitting={submitting}
      documents={documents}
      deleteDocuments={deleteDocuments}
      postDocuments={postDocuments}
      downloadDocuments={downloadDocuments}
      cargos={cargos}
      onCancel={cancelUpdate}
    />
  );
};
