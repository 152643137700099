import { FC } from 'react';
import { PageWrapper } from 'pages/page-wrapper';
import { Flex } from '@chakra-ui/react';
import { CarriersLoadsAnonymToolbar } from '@/components/carriers-loads/toolbar/anonym-toolbar';
import { CarriersLoadsListView } from '@/views/carriers-loads/carriers-loads-list/carriers-loads-list-view';

export const CarriersLoadsAnonymPage: FC = () => (
  <PageWrapper>
    <CarriersLoadsAnonymToolbar />
    <Flex alignContent="center" justifyContent="center">
      <CarriersLoadsListView />
    </Flex>
  </PageWrapper>
);
