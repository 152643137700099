import { Text, HStack } from '@chakra-ui/react';
import classNames from 'classnames';
import { useIcons } from '@/hooks/use-icons';
import { TableConfig } from '@/types/common/table';
import './ui-table.scss';

type Props = {
  config: TableConfig;
};

export const UiTableHead = ({ config }: Props) => {
  const { columns, headClassName = '' } = config;
  const { DownChevronIcon } = useIcons();

  return (
    <thead className={classNames(headClassName, 'ui-table__header')}>
      <tr>
        {columns.map(({ id, name, sorting }) => (
          <th key={id}>
            <HStack>
              {sorting && <DownChevronIcon />}
              <Text textStyle="p4" id={id}>
                {name}
              </Text>
            </HStack>
          </th>
        ))}
      </tr>
    </thead>
  );
};
