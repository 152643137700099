import { loadOptions } from 'components/create-order-form/subforms/form-configs';
import { Carrier } from '@/types/models/carrier';
import { Vehicle } from '@/types/models/vehicle';
import { FormValues } from '../types';

export const vehicleToFormValues = ({ carrier, values }: { carrier: Partial<Carrier>; values?: Vehicle }): FormValues | undefined => {
  if (!values) return undefined;
  return {
    carrierId: { value: carrier?.id, label: carrier?.name },
    vehicleGroup: values.vehicleGroup,
    vehicleType: [values.vehicleType],
    regNumber: values.regNumber,
    volume: values.volume,
    capacity: values.capacity,
    dimensions: values.dimensions,
    accidentsInPastThreeYears: values.accidentsInPastThreeYears || false,
    bailed: values.bailed,
    casco: values.casco,
    ownType: values.ownType,
    tracker: values.tracker,
    permanentTracking: values.permanentTracking,
    driverId: { label: values?.driverName || String(values?.driverId), value: values?.driverId },
    loadingType: values?.loadingType?.map((type) => {
      return {
        value: loadOptions.find((x) => x.label === type)?.value,
        label: loadOptions.find((x) => x.label === type)?.label,
      };
    }),
    trailers: values.trailers?.map((trailer) => {
      return {
        vehicleType: trailer.vehicleType,
        regNumber: trailer.regNumber,
        volume: trailer.volume,
        dimensions: trailer.dimensions,
        capacity: trailer.capacity || 0,
        accidentsInPastThreeYears: trailer.accidentsInPastThreeYears || false,
      };
    }),
  };
};
