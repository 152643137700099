import './security.scss';
import { SecurityDetails } from 'components/security/security-details/security-details';
import { WidgetContainer } from 'components/widget-container/widget-container';
import { useRef } from 'react';
import { UISidebar } from '@/ui-elements/ui-sidebar/ui-sidebar';
import { useSecurityActions } from '@/features/security/hooks/use-security-actions';
import { WidgetContainers } from '@/features/widget/widget-containers';
import { useWidgetActions } from '@/features/widget/hooks/use-widget-actions';
import { useDriver } from '@/features/drivers/hooks/use-driver';
import { ModalAction, useModal } from '@/hooks/use-modal';
import { ModalType } from '@/types/common/modals';
import { useVehicle } from '@/features/vehicles/hooks/use-vehicle';
import { Vehicle } from '@/types/models/vehicle';
import { useCarrierUpdates } from '@/features/carriers/hooks/use-carrier-updates';
import { useApprovalUpdates } from '@/features/approvals/hooks/use-approval-updates';

export const SecuritySidebarView = () => {
  const { activeApprovalId } = useSecurityActions();
  const { data: approval } = useApprovalUpdates(activeApprovalId);
  const record = approval?.data;
  const ref = useRef<HTMLDivElement>(null);
  const { DriverWidget, VehicleWidget } = useWidgetActions(WidgetContainers.SecurityDetails);
  const { carrier } = useCarrierUpdates(record?.carrier?.id);
  const { driver } = useDriver(record?.driver?.id);
  const { vehicle } = useVehicle(record?.vehicle?.id);

  const { modalView: ModalEditCarrier, setModalOpened: showEditCarrierModal } = useModal<{ carrierId?: number } & ModalAction>(
    ModalType.EditCarrier,
    {
      carrierId: record?.carrier?.id,
    }
  );
  const onDriverMoreInfoClick = (id: number) => {
    DriverWidget.show({ id });
  };

  const onVehicleMoreInfoClick = (widgetVehicle: Vehicle) => {
    if (widgetVehicle.id) VehicleWidget.show({ vehicle: widgetVehicle });
  };

  const onEditCarrierClick = () => {
    showEditCarrierModal(true);
  };
  return (
    <>
      <UISidebar className="security-sidebar" isOpen ref={ref}>
        {carrier.data && (
          <SecurityDetails
            carrier={carrier.data.data}
            driver={driver.data?.data}
            vehicle={vehicle.data?.data}
            onDriverMoreInfoClick={onDriverMoreInfoClick}
            onVehicleMoreInfoClick={onVehicleMoreInfoClick}
            onEditCarrierClick={onEditCarrierClick}
            isFetching={carrier.isInitialLoading}
          />
        )}
        <WidgetContainer containerKey={WidgetContainers.SecurityDetails} parentRef={ref} />
      </UISidebar>
      {ModalEditCarrier}
    </>
  );
};
