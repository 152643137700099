import * as yup from 'yup';
import { DRIVER_LICENSE_RULES, ORGANIZATION_RULES, PASSPORT_RULES, requiredString } from '@/helpers/forms/validation-schemas/constants';

export const createOrganizationWithDriverFormSchema = yup.object({
  ...ORGANIZATION_RULES,
  ...DRIVER_LICENSE_RULES,
  passportGiven: PASSPORT_RULES.passportGiven,
  passportNumber: requiredString,
  name: yup.string(),
});
