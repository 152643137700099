import { LegacyRef, forwardRef } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer } from '@chakra-ui/react';
import './component-to-print.scss';
import { TableConfig, TableDataSource } from '@/types/common/table';

type Props = {
  config: TableConfig;
  dataSource: TableDataSource;
};

export const ComponentToPrint = forwardRef(({ config, dataSource }: Props, ref: LegacyRef<HTMLDivElement>) => {
  const { columns } = config;
  return (
    <div className="component-to-print">
      <TableContainer ref={ref}>
        <Table variant="simple" overflowY="scroll">
          <Thead>
            <Tr>
              {columns.map((column) => (
                <Th>{column.name}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {dataSource.map((item) => (
              <Tr>
                {(Object.values?.(item) || []).map((val) => (
                  <Td key={val}>{val}</Td>
                ))}
              </Tr>
            ))}
          </Tbody>
          {/* <Tfoot>
          <Tr>
            <Th>To convert</Th>
            <Th>into</Th>
            <Th isNumeric>multiply by</Th>
          </Tr>
        </Tfoot> */}
        </Table>
      </TableContainer>
    </div>
  );
});
