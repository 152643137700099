import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { UiTr, UiTh, UiTd } from '@/ui-elements/ui-table';

type Props = {
  className?: string;
  footerData?: Array<string>;
};

export const UiTfoot = ({ className, footerData }: Props) => {
  if (isEmpty(footerData)) return null;
  const [header, ...rest] = footerData || [];
  return (
    <tfoot className={classNames(className, 'ui-table__footer')}>
      <UiTr align="left">
        <UiTh colSpan={1}>{header}</UiTh>
        {rest.map((item) => (
          <UiTd colSpan={1} key={item}>
            {item}
          </UiTd>
        ))}
      </UiTr>
    </tfoot>
  );
};
