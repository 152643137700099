import { useState } from 'react';
import { FormType } from 'components/register/regster-form/constants';
import { useAuth } from '@/features/account/hooks/use-auth';
import { useCodeActions } from '@/features/code/hooks/use-code-actions';
import { useRegisterPaths } from '@/features/account/hooks/use-register-paths';
import { handleSubmitRegisterForm } from '@/views/register/register-form-view/helpers/handle-submit-register-form';
import { AccountRole, AccountRoleMap } from '@/types/api/account-role';
import { getAliasMapTransform } from '@/views/register/register-form-view/helpers/get-alias-map-transform';

const ROLES_AVAILABLE_FOR_REGISTRATION: Array<string> = [AccountRole.Carrier, AccountRole.CarrierSimple];

export const useRegister = () => {
  const { register } = useAuth();
  const [formType, setFormType] = useState<FormType>(() => (sessionStorage.getItem('formType') as FormType) ?? FormType.getCode);
  const { sendCode, checkCode } = useCodeActions();
  const { alias, forceRedirectPath } = useRegisterPaths();

  const onSubmit = handleSubmitRegisterForm({ register, formType, setFormType, sendCode, checkCode });

  const registerAvailable = ROLES_AVAILABLE_FOR_REGISTRATION.includes(alias);
  const forceRedirect = !alias;
  const registerNotAvailableContent = `Регистрация ${AccountRoleMap[alias]} через данную страницу не доступна`;
  const registerHeadingContent = `Регистрация ${getAliasMapTransform(alias)}`;

  return {
    formType,
    onSubmit,
    forceRedirect,
    forceRedirectPath,
    registerAvailable,
    registerHeadingContent,
    registerNotAvailableContent,
  };
};
