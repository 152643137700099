import { FC } from 'react';
import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import { vatOptions } from 'config/constants/vat-options';
import { VerificationFormMode } from 'components/verification-forms/types';
import { useFormState } from 'react-final-form';
import { BANK_ACCOUNT_MASK } from 'components/auth-form/constants';
import { UiInputField, UiMaskedField, UiSelectField } from '@/ui-elements';
import { useBankRequisites } from '@/features/bank-requisites/use-bank-requisites';

export const CarrierBankSubform: FC<{ mode: VerificationFormMode }> = ({ mode }) => {
  const { values } = useFormState();
  const { bankRequisites } = useBankRequisites(values.bik && values.bik.length > 3 ? values.bik : undefined);
  return (
    <>
      <Box>
        <Text fontSize={18} fontWeight={700}>
          Расчетный счет для оплаты
        </Text>
      </Box>

      <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={3.5} className="carriers-verification-form">
        <GridItem>
          <UiInputField type="number" name="bik" label="БИК банка" placeholder="БИК банка" required isDisabled={mode === 'check'} />
        </GridItem>
        <GridItem>
          <UiMaskedField
            type="number"
            name="bankAccount"
            mask={BANK_ACCOUNT_MASK}
            label="Номер расчетного счета"
            placeholder="Номер расчетного счета"
            required
            isDisabled={mode === 'check'}
          />
        </GridItem>
      </Grid>
      <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={-1} className="carriers-verification-form">
        <GridItem>
          <UiInputField
            name="bankName"
            label="Наименование банка"
            placeholder="Наименование банка"
            required
            isDisabled={mode === 'check'}
            initialValue={bankRequisites.data?.data.name}
          />
        </GridItem>
        <GridItem>
          <UiInputField
            type="number"
            name="correspondentAccount"
            label="Корр. счет"
            placeholder="Корр. счет"
            required
            isDisabled={mode === 'check'}
            initialValue={bankRequisites.data?.data.correspondentAccount}
          />
        </GridItem>
      </Grid>

      <Box mt={-1}>
        <UiSelectField required options={vatOptions} name="vatType" label="Плательщик НДС" isDisabled={mode === 'check'} />
      </Box>
    </>
  );
};
