import { FileFormField, MyOrganizationFormField } from '@/types/models/my-organization/form';

type SplitFormType = {
  driverFiles: Record<FileFormField, File[]>;
  organizationFiles: Record<FileFormField, File[]>;
};

export const splitFormFiles = (files: Record<FileFormField, File[]>) => {
  return Object.keys(files).reduce(
    (result, key) => {
      switch (true) {
        case [MyOrganizationFormField.PassportDocument, MyOrganizationFormField.InnOgrnDocument].includes(key as FileFormField):
          result.organizationFiles = {
            ...result.organizationFiles,
            [key]: files[key as FileFormField],
          };
          break;
        case [MyOrganizationFormField.DriversLicenseDoc].includes(key as FileFormField):
          result.driverFiles = {
            ...result.driverFiles,
            [key]: files[key as FileFormField],
          };
          break;
        default:
          break;
      }
      return result;
    },
    {
      driverFiles: {},
      organizationFiles: {},
    } as SplitFormType
  );
};
