import { FC } from 'react';
import { Heading, Box } from '@chakra-ui/react';
import { UseDependentAttachmentsReturnValues } from '@/features/attachments/hooks/use-dependent-attachments';
import { ResourcesFormFieldNames } from '@/features/resources/constants/form';
import { ResourcesSidebarMode } from '@/features/resources/types/general';
import { RESOURCES_VEHICLE_DOCUMENTS } from '@/features/resources/constants/display-blocks';
import { ResourcesSubformAttachment } from '@/components/resources/forms/subform-attachment/resources-subform-attachment';

interface Props {
  mode: ResourcesSidebarMode;
  vehicleInitialDocs: Partial<Record<ResourcesFormFieldNames, UseDependentAttachmentsReturnValues>>;
}

export const ResourcesSubformVehicleDocuments: FC<Props> = ({ mode, vehicleInitialDocs }) => {
  return (
    <Box>
      <Heading as="h3" className="resources-form-subtitle">
        Документы
      </Heading>
      {RESOURCES_VEHICLE_DOCUMENTS.map((document) => (
        <ResourcesSubformAttachment
          mode={mode}
          attachmentsAndHandlers={vehicleInitialDocs[document.fieldName]}
          {...document}
          key={document.fieldName}
        />
      ))}
    </Box>
  );
};
