import { useAllVehiclesWithPages } from '@/features/vehicles/hooks/use-all-vehicles-with-pages';
import { useAppSelector } from '@/hooks/use-app-selector';
import { resourcesSelectedVehicleIdSelector } from '@/features/resources/selectors';
import { useIntermediaryResources, useResourcesDispatchActions } from '@/features/resources/hooks';
import { ResourcesSidebarMode } from '@/features/resources/types/general';
import { flatMapDataPages } from '@/helpers/api/flat-map-data-pages';

export const useResourcesVehiclesView = () => {
  const { handleSetSidebarMode, handleSelectVehicle } = useResourcesDispatchActions();
  const selectedVehicleId = useAppSelector(resourcesSelectedVehicleIdSelector);

  const { isIntermediaryRole, carrierId } = useIntermediaryResources();

  const { data, fetchNextPage, isFetchingNextPage, isInitialLoading } = useAllVehiclesWithPages({ perPage: 10, carrierId });
  const vehicles = flatMapDataPages(data);
  const loading = isInitialLoading;

  const selectVehicle = (driverId: number) => {
    handleSelectVehicle(driverId);
    handleSetSidebarMode(ResourcesSidebarMode.Details);
  };

  const handleCreateVehicle = () => {
    handleSetSidebarMode(ResourcesSidebarMode.CreateForm);
  };

  return {
    vehicles,
    loading,
    selectedVehicleId,
    isFetchingNextPage,
    showApprovalStatuses: !isIntermediaryRole,
    fetchNextPage,
    selectVehicle,
    handleCreateVehicle,
  };
};
