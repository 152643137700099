import isEmpty from 'lodash/isEmpty';
import uuid from 'react-uuid';
import { Box, HStack, InputGroup, InputRightElement, Text, VStack } from '@chakra-ui/react';
import { FormApi } from 'final-form';
import { useFieldArray } from 'react-final-form-arrays';
import { useFormState } from 'react-final-form';
import plus from 'public/assets/icons/PlusIcon.svg';
import React from 'react';
import { SubformTitle } from 'components/create-order-form/subform-title';
import _ from 'lodash';
import { OrderLoadType } from '@/config/constants/types';
import { UiButtonOutlined, UiDeleteButton, UiIconButton, UiNumberPickerField, UiSelectField, UiSelectFieldAsync } from '@/ui-elements';
import { useIcons } from '@/hooks/use-icons';
import { emptyCargoFields, emptyCargoUnloadFields, weightOptions } from '../form-configs';
import { fetchPackagesOptions } from '@/api/fetch-packages-options';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { CargoForm, PointForm } from '../../schema';
import { Cargos } from '@/types/models/cargos';
import { onKeyPress } from '@/ui-elements/form-fields/ui-number-picker-field/helpers/get-press-value';

type Props = {
  namespace: string;
  form: FormApi<any, Partial<any>>;
  packageTypes?: Array<SelectOption>;
  type: string;
  pointIndex: number;
  handleAddCargo: (cargo: CargoForm) => void;
  handleUpdateCargo: (newValue: any, field: string, index: number, formCargoState: any) => void;
  handleDeleteCargo: (index: number) => void;
  restToUnloadCargos: Array<Cargos>;
  cargoTypes: {
    value: string;
    label: string;
  }[];
  unloadOptions: {
    value: string | number;
    label: string;
  }[];
};

// TODO очищать поля при закрытии (для полей, которые открываются-скрываются)

export const CargoSubform = ({
  namespace,
  form,
  packageTypes,
  type,
  pointIndex,
  handleAddCargo,
  restToUnloadCargos,
  handleUpdateCargo,
  handleDeleteCargo,
  cargoTypes,
  unloadOptions,
}: Props) => {
  const { CloseIcon } = useIcons({ height: '14px', width: '14px' });
  const { fields } = useFieldArray(`${namespace}.cargos`);
  const { values } = useFormState();
  const initialCargo = type === OrderLoadType.Loading ? emptyCargoFields : emptyCargoUnloadFields;

  // При изменении типа груза, веса или объема срабатывает проверка:
  // - если вес и тип заполнены - груз добавляется в выбор для разгрузки
  // - если какое-то из 3‑х полей изменилось - груз меняется и в разгрузке
  // - если вес или тип были удалены - груз удаляется из разгрузки
  const handleCargoChange = (value: any, cargoIndex: number, field: string) => {
    const formCargoState = values.route?.points[pointIndex].cargos[cargoIndex];
    const newValue = { ...formCargoState, [field]: value };
    const isExist = restToUnloadCargos.find((cargo) => cargo.id === newValue.id);
    if (newValue.cargoType && newValue.weight) {
      if (!isExist) {
        handleAddCargo(newValue);
      } else {
        handleUpdateCargo(value, field, newValue.id, formCargoState);
      }
    } else if (isExist) {
      handleDeleteCargo(newValue.id);
      // удаление field в разгрузке
      values.route.points.map((point: PointForm, indexP: number) => {
        if (point.type === OrderLoadType.Unloading) {
          const cargosIndexes = point.cargos
            .map((cargo, ind) => ({ ...cargo, ind }))
            .filter((cargo) => {
              return cargo.unloadCargo?.value === formCargoState.id;
            });

          if (cargosIndexes) {
            cargosIndexes.map((cargo) => {
              form.change(
                `route.points[${indexP}].cargos`,
                values.route.points[indexP].cargos.filter((cargoValue: any, ind: number) => ind !== cargo.ind)
              );
              form.change(
                `state.cargo[${indexP}]`,
                values.state.cargo[indexP].filter((item: number, ind: number) => ind !== cargo.ind)
              );
              form.resetFieldState(`route.points[${indexP}].cargos`);
            });
          }
        }
      });
    }
  };

  return (
    <VStack spacing={0} align="start" mt={2}>
      <SubformTitle title="Груз" />
      {type === OrderLoadType.Loading && (
        <div>
          {fields.map((name, index) => {
            return (
              <Box key={name}>
                <HStack spacing={4} align="self-start">
                  <Box width="260px">
                    <UiSelectField
                      name={`${name}.cargoType`}
                      label="Груз"
                      options={cargoTypes}
                      required
                      isCreatable
                      onChange={(value) => {
                        handleCargoChange(value, index, 'cargoType');
                      }}
                    />
                  </Box>
                  <Box minWidth="100px" width="100px">
                    <UiNumberPickerField
                      name={`${name}.weight`}
                      placeholder="Вес"
                      hideStepper
                      label="Вес"
                      required
                      hideClearButton
                      onKeyPress={onKeyPress}
                      onChange={(value) => {
                        handleCargoChange(value, index, 'weight');
                      }}
                    />
                  </Box>
                  <Box minWidth="100px" pt={4}>
                    <UiSelectField
                      name={`${name}.weightOptions`}
                      placeholder="т"
                      options={weightOptions}
                      onChange={(value) => {
                        handleCargoChange(value, index, 'weightOptions');
                      }}
                    />
                  </Box>
                  <InputGroup minWidth="100px" width="100px">
                    <UiNumberPickerField
                      name={`${name}.volume`}
                      placeholder="Объем"
                      hideClearButton
                      hideStepper
                      label="Объем"
                      onKeyPress={onKeyPress}
                      required
                      onChange={(value) => {
                        handleCargoChange(value, index, 'volume');
                      }}
                    />
                    <InputRightElement>
                      <Text mt={10}>
                        м<sup>3</sup>
                      </Text>
                    </InputRightElement>
                  </InputGroup>
                  <Box textStyle="p4" width={16}>
                    Рассчет на одну машину
                  </Box>
                  {!!fields.length && fields.length > 1 && index !== 0 && (
                    <UiDeleteButton
                      alignSelf="center"
                      onClick={() => {
                        // удаление связаных грузов в разгрузке
                        const formCargoState = values.route.points[pointIndex].cargos[index];
                        handleDeleteCargo(formCargoState.id);

                        values.route.points.map((point: PointForm, indexP: number) => {
                          if (point.type === OrderLoadType.Unloading) {
                            const cargosIndexes = point.cargos
                              .map((cargo, ind) => ({ ...cargo, ind }))
                              .filter((cargo) => {
                                return cargo.unloadCargo?.value === formCargoState.id;
                              });

                            if (cargosIndexes) {
                              cargosIndexes.map((cargo) => {
                                form.change(
                                  `route.points[${indexP}].cargos`,
                                  values.route.points[indexP].cargos.filter((cargoValue: any, ind: number) => ind !== cargo.ind)
                                );

                                form.change(
                                  `state.cargo[${indexP}]`,
                                  values.state.cargo[indexP].filter((item: number, ind: number) => ind !== cargo.ind)
                                );

                                form.resetFieldState(`route.points[${indexP}].cargos`);
                              });
                            }
                          }
                        });
                        // удаление controls
                        form.change(
                          `state.cargo[${pointIndex}]`,
                          values.state.cargo[pointIndex].filter((item: number, ind: number) => ind !== index)
                        );
                        // удаление поля с грузом в погрузке
                        if (_.isFunction(fields.remove)) {
                          fields.remove(index);
                        }
                      }}
                    >{`Груз ${index + 1}`}</UiDeleteButton>
                  )}
                </HStack>
                {!!values.state?.cargo[pointIndex] && !values.state?.cargo[pointIndex][index]?.includes(1) && (
                  <HStack minWidth="500px">
                    <UiIconButton
                      aria-label="close"
                      icon={CloseIcon}
                      onClick={() => {
                        form.change(`state.cargo[${pointIndex}][${index}]`, [...values.state.cargo[pointIndex][index], 1]);
                        form.change(`${name}.package`, '');
                        form.change(`${name}.quantity`, '');
                      }}
                    />
                    <Box width="200px">
                      <UiSelectField name={`${name}.package`} label="Упаковка" options={packageTypes || []} />
                    </Box>
                    <InputGroup minWidth="100px" width="100px">
                      <UiNumberPickerField name={`${name}.quantity`} placeholder="" hideClearButton hideStepper label="Кол-во, шт" />
                    </InputGroup>
                  </HStack>
                )}
                {!!values.state?.cargo[pointIndex] && !values.state?.cargo[pointIndex][index]?.includes(2) && (
                  <HStack minWidth="500px">
                    <VStack spacing={0} align="start">
                      <HStack minWidth="400px">
                        <Box pt="16px">
                          <UiIconButton
                            aria-label="close"
                            icon={CloseIcon}
                            onClick={() => {
                              form.change(`state.cargo[${pointIndex}][${index}]`, [...values.state.cargo[pointIndex][index], 2]);
                              form.change(`${name}.size.length`, '');
                              form.change(`${name}.size.width`, '');
                              form.change(`${name}.size.height`, '');
                              form.change(`${name}.size.diameter`, '');
                            }}
                          />
                        </Box>
                        <InputGroup minWidth="100px" width="100px">
                          <UiNumberPickerField
                            name={`${name}.size.length`}
                            placeholder=""
                            showContent={false}
                            hideClearButton
                            hideStepper
                            label="Габариты, Д"
                            precision={1}
                          />
                          <InputRightElement mt="13%">
                            <Text mt={2}>м</Text>
                          </InputRightElement>
                        </InputGroup>
                        <InputGroup minWidth="100px" width="100px">
                          <UiNumberPickerField
                            name={`${name}.size.width`}
                            placeholder=""
                            showContent={false}
                            hideClearButton
                            hideStepper
                            label="Ш"
                            precision={1}
                          />
                          <InputRightElement mt="13%">
                            <Text mt={2}>м</Text>
                          </InputRightElement>
                        </InputGroup>
                        <InputGroup minWidth="100px" width="100px">
                          <UiNumberPickerField
                            name={`${name}.size.height`}
                            placeholder=""
                            showContent={false}
                            hideClearButton
                            hideStepper
                            label="В"
                            precision={1}
                          />
                          <InputRightElement mt="13%">
                            <Text mt={2}>м</Text>
                          </InputRightElement>
                        </InputGroup>
                        <InputGroup minWidth="100px" width="100px">
                          <UiNumberPickerField
                            name={`${name}.size.diameter`}
                            placeholder=""
                            showContent={false}
                            hideClearButton
                            hideStepper
                            label="Диаметр"
                            precision={1}
                          />
                          <InputRightElement mt="13%">
                            <Text mt={2}>м</Text>
                          </InputRightElement>
                        </InputGroup>
                      </HStack>
                    </VStack>
                  </HStack>
                )}
                <HStack spacing={2} pt={2} pb={4}>
                  {!isEmpty(values.state?.cargo?.[pointIndex]?.[index]) && !!fields.length && <Text>Добавить</Text>}
                  {values.state?.cargo?.[pointIndex]?.[index]?.includes(1) && !!fields.length && (
                    <UiButtonOutlined
                      leftIcon={plus}
                      onClick={() => {
                        form.change(
                          `state.cargo[${pointIndex}][${index}]`,
                          values.state.cargo[pointIndex]?.[index]?.filter((item: number) => item !== 1) ?? []
                        );
                      }}
                    >
                      Упаковка и кол-во
                    </UiButtonOutlined>
                  )}
                  {values.state?.cargo?.[pointIndex]?.[index]?.includes(2) && !!fields.length && (
                    <UiButtonOutlined
                      leftIcon={plus}
                      onClick={() => {
                        form.change(
                          `state.cargo[${pointIndex}][${index}]`,
                          values.state?.cargo[pointIndex]?.[index]?.filter((item: number) => item !== 2) ?? []
                        );
                      }}
                    >
                      Габариты и диаметр
                    </UiButtonOutlined>
                  )}
                </HStack>
              </Box>
            );
          })}
        </div>
      )}
      {type === OrderLoadType.Unloading && (
        <div>
          {fields.map((name, index) => {
            return (
              <Box key={name}>
                <HStack mt={3} spacing={4} align="self-start">
                  <Box minWidth="300px">
                    <UiSelectField
                      name={`${name}.unloadCargo`}
                      options={unloadOptions}
                      placeholder="Выберите груз"
                      label="Груз для разгрузки"
                      required
                    />
                  </Box>
                  {!!fields.length && fields.length > 1 && index !== 0 && (
                    <UiDeleteButton
                      alignSelf="center"
                      onClick={() => {
                        form.change(
                          `state.cargo[${pointIndex}]`,
                          values.state.cargo?.[pointIndex]?.filter((item: number, ind: number) => ind !== index) ?? []
                        );

                        fields.remove(index);
                      }}
                    >{`Груз ${index + 1}`}</UiDeleteButton>
                  )}
                </HStack>
              </Box>
            );
          })}
        </div>
      )}
      <UiButtonOutlined
        leftIcon={plus}
        onClick={() => {
          const id = uuid();
          if (_.isArray(values.state?.cargo[pointIndex])) {
            form.change(`state.cargo[${pointIndex}]`, [...(values.state.cargo?.[pointIndex] ?? []), [1, 2]]);
          }
          fields.push({ ...initialCargo, id: type === OrderLoadType.Loading && `cargo_${id}` });
        }}
      >
        Еще груз
      </UiButtonOutlined>
    </VStack>
  );
};
