import './set-carrier-widget-content.scss';
import { FC } from 'react';
import { ATTACHED_FILES } from 'components/set-carrier-widget-content/texts';
import { emptyFunction } from '@/helpers/empty-function';
import { UiFileUploadInputWidget } from '@/ui-elements/inputs/ui-file-upload-input/ui-file-upload-input-widget';

export const SetCarrierForm: FC<{ onChange: (files?: Array<File>) => void }> = ({ onChange = emptyFunction }) => (
  <div className="set-carrier__form">
    <div className="set-carrier__formtitle"> {ATTACHED_FILES}</div>
    <UiFileUploadInputWidget onChange={onChange} name="file" />
  </div>
);
