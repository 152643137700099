import { FC } from 'react';
import { useFormState } from 'react-final-form';
import { UiInputField, UiMultiDatePickerField, UiSelectField } from '@/ui-elements';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import './customer-add-form.scss';

type Props = {
  managers?: SelectOption[];
};

export const CustomerAddFormReq: FC<Props> = ({ managers = [] }) => {
  const { values } = useFormState();

  return (
    <>
      <UiInputField required={values.vipCustomer} name="contract" label="Договор-номер" placeholder="Договор" className="customer-add__contract" />
      <UiMultiDatePickerField required={values.vipCustomer} name="date" label="Дата договора" placeholder="Выберите дату" />
      <UiSelectField
        required={values.vipCustomer}
        options={managers}
        name="manager"
        label="Ответственный менеджер"
        placeholder="Выберите из списка"
      />
    </>
  );
};
