import { FC, MouseEventHandler } from 'react';
import { HStack } from '@chakra-ui/react';
import classNames from 'classnames';
import { UiButtonOutlined } from '@/ui-elements';
import './order-actions.scss';
import { useIcons } from '@/hooks/use-icons';
import { orderActions } from '@/features/order/texts';
import { VoidFunction } from '@/types/common/void-function';
import { emptyFunction } from '@/helpers/empty-function';
import { OrderInfoSearchCarrierButtons } from '@/components/order-info/search-carrier-buttons/order-info-search-carrier-buttons';
import { OrderExecutionStatus } from '@/config/constants/statuses';
import { FunnelLoadStatus } from '@/types/models/funnel';

type Props = {
  onSplitClick: (partsToSplit: number) => void;
  // открыть поиск исполнителя
  onCarrierSearchClick: VoidFunction;
  // открыть выбор собственных ресурсов
  onChooseOwnDriverAndVehicle?: VoidFunction;
  // перевести в статус поиск исполнителя
  onStartCarrierSearch?: VoidFunction;
  className?: string;
  onCancelButtonClick?: VoidFunction;
  disableSearchButton?: boolean;
  showEditButton?: boolean;
  showSplitButton?: boolean;
  showCancelButton?: boolean;
  handleUpdateOrder?: MouseEventHandler<HTMLButtonElement>;
  handleDeleteOrder?: MouseEventHandler<HTMLButtonElement>;
  cancelCaption?: string;
  onCloneOrder: (orderId: number) => void;
  orderStatus?: OrderExecutionStatus | FunnelLoadStatus;
};
export const OrderActions: FC<Props> = ({
  className,
  onCarrierSearchClick,
  onChooseOwnDriverAndVehicle,
  onStartCarrierSearch,
  onSplitClick,
  disableSearchButton,
  onCancelButtonClick = emptyFunction,
  showEditButton = true,
  showSplitButton = true,
  showCancelButton = false,
  handleUpdateOrder = emptyFunction,
  handleDeleteOrder = emptyFunction,
  cancelCaption = orderActions.Cancel,
  onCloneOrder = emptyFunction,
  orderStatus,
}) => {
  const { SplitIcon, ArrowLeft, DeleteIcon, PlusIcon } = useIcons();

  return (
    <HStack className={classNames('order-actions', className)}>
      {/* Не показываем кнопку редактирования на вкладке Исполнение */}
      {/* <div>
        {showEditButton && (
          <UiButtonOutlined onClick={handleUpdateOrder} mode="dark" leftIcon={EditIcon}>
            {orderActions.Edit}
          </UiButtonOutlined>
        )}
      </div> */}
      <div>
        {showEditButton && (
          <UiButtonOutlined onClick={handleDeleteOrder} mode="dark" leftIcon={DeleteIcon}>
            {orderActions.Delete}
          </UiButtonOutlined>
        )}
      </div>
      <div>
        {showEditButton && (
          <UiButtonOutlined onClick={onCloneOrder} mode="dark" leftIcon={PlusIcon}>
            {orderActions.Clone}
          </UiButtonOutlined>
        )}
      </div>
      <div className="order-actions__split">
        {/* TODO:заменить partsToSplit - на результат выбора пользователя */}
        {showSplitButton && (
          <UiButtonOutlined disabled mode="dark" onClick={() => onSplitClick(4)} leftIcon={SplitIcon}>
            {orderActions.Split} <br />
            (в разработке)
          </UiButtonOutlined>
        )}
        {showCancelButton && (
          <UiButtonOutlined mode="dark" onClick={onCancelButtonClick} leftIcon={ArrowLeft}>
            {cancelCaption}
          </UiButtonOutlined>
        )}
      </div>
      {!disableSearchButton && (
        <OrderInfoSearchCarrierButtons
          orderStatus={orderStatus}
          disabled={disableSearchButton}
          onCarrierSearchClick={onCarrierSearchClick}
          onChooseOwnDriverAndVehicle={onChooseOwnDriverAndVehicle}
          onStartCarrierSearch={onStartCarrierSearch}
        />
      )}
    </HStack>
  );
};
