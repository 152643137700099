import './completed-routes.scss';
import { FC } from 'react';
import { SidebarMode } from 'components/sidebar-wrapper/constants';
import { Routes } from 'config/routes/routes';
import { useAppSelector } from '@/hooks/use-app-selector';
import { CompletedRoutesSidebarView } from '@/views/carriers-loads/completed-routes/completed-routes-sidebar-view';
import { CompletedRoutesListView } from '@/views/carriers-loads/completed-routes/completed-routes-list-view';
import { pageSidebarModeSelector } from '@/features/carriers-loads/selectors/page-sidebar-mode-selector';

export const CompletedRoutesView: FC = () => {
  const mode = useAppSelector((state) => pageSidebarModeSelector(state, Routes.CompletedRoutes));

  return (
    <>
      <CompletedRoutesListView />
      {SidebarMode.None !== mode && <CompletedRoutesSidebarView />}
    </>
  );
};
