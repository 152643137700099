import { useState } from 'react';
import uuid from 'react-uuid';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { useFieldArray } from 'react-final-form-arrays';
import { FormApi } from 'final-form';
import { useFormState } from 'react-final-form';
import plus from 'public/assets/icons/PlusIcon.svg';
import { arrivalTimeTypeOptions, TimeType } from 'components/create-order-form/subforms/form-configs';
import { OrderLoadType } from '@/config/constants/types';
import { useIcons } from '@/hooks/use-icons';
import { UiAddressSearchField, UiButtonOutlined, UiDeleteButton, UiIconButton, UiRadioGroupField, UiTextButton } from '@/ui-elements';
import { SubformTitle } from '@/components/create-order-form/subform-title';
import { CargoSubform } from '@/components/create-order-form/subforms/ati-su-cargo/ati-su-cargo';
import { emptyFunction } from '@/helpers/empty-function';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import './ati-su-route.scss';
import { CargoForm, PointForm } from '../schema';
import { TimeSubform } from './ati-su-time';
import { Cargos } from '@/types/models/cargos';
import { useCargoTypes } from '@/features/order/hooks/use-cargo-types';

type Props = {
  namespace: string;
  form: FormApi<any, Partial<any>>;
  packageTypes?: Array<SelectOption>;
  cargos?: Array<Cargos>;
};

const routeTypeConfig = {
  roundtrip: 'кругорейс',
  simple: 'только туда',
};

export const RouteSubform = ({ namespace, form, packageTypes, cargos = [] }: Props) => {
  const { CloseIcon } = useIcons({
    height: '14px',
    width: '14px',
  });
  const { fields: pointsFields } = useFieldArray(`${namespace}.points`);
  const cargoTypes = useCargoTypes();
  const { values } = useFormState();
  const [restToUnloadCargos, setRestToUnloadCargos] = useState<Array<Cargos>>(cargos);
  // Опции для выбора груза в разделе разгрузки
  const unloadOptions = restToUnloadCargos?.map((cargo, index) => {
    return {
      value: cargo.id,
      label: `Груз ${index + 1} ${cargoTypes.find((cargoType) => Number(cargoType.value) === Number(cargo.type))?.label || cargo.type} ${
        cargo.properties.Mass
      }/${cargo.properties.Volume}`,
    };
  });

  const handleAddCargo = (cargo: CargoForm) => {
    const transformedCargo = {
      id: cargo.id,
      type: cargo.cargoType.value,
      properties: {
        Mass: cargo.weight,
        Volume: cargo.volume || '',
      },
    };
    setRestToUnloadCargos((prev) => [...prev, transformedCargo as Cargos]);
  };
  const handleUpdateCargo = (newValue: any, field: string, index: number, formCargoState: any) => {
    setRestToUnloadCargos((prev) => {
      const newArray = [...prev];
      const existedCargoIndex = newArray.findIndex((cargo) => cargo.id === index);
      if (field === 'weight') {
        newArray[existedCargoIndex].properties.Mass = newValue;
      }
      if (field === 'volume') {
        newArray[existedCargoIndex].properties.Volume = newValue;
      }
      if (field === 'cargoType') {
        newArray[existedCargoIndex].type = newValue.value;
      }

      // обновление полей груза в связаных точках разгрузки
      const updatedOptions = restToUnloadCargos?.map((cargo, ind) => {
        return {
          value: cargo.id,
          label: `Груз ${ind + 1} ${cargoTypes.find((cargoType) => Number(cargoType.value) === Number(cargo.type))?.label || cargo.type} ${
            cargo.properties.Mass
          }/${cargo.properties.Volume}`,
        };
      });
      values.route.points?.map((point: PointForm, indexP: number) => {
        const cargosIndexes =
          point.type === OrderLoadType.Unloading &&
          point.cargos
            ?.map((cargo, ind) => ({ ...cargo, ind }))
            .filter((cargo) => {
              return cargo.unloadCargo?.value === formCargoState.id;
            });

        if (cargosIndexes) {
          cargosIndexes?.map((cargo) => {
            form.change(
              `route.points[${indexP}].cargos[${cargo.ind}].unloadCargo`,
              updatedOptions.find((option) => option.value === cargo?.unloadCargo?.value)
            );
            form.resetFieldState(`route.points[${indexP}].cargos[${cargo.ind}].unloadCargo`);
          });
        }
      });
      return newArray;
    });
  };
  const handleDeleteCargo = (index: number | string) => {
    setRestToUnloadCargos((prev) => {
      const newValues = prev.filter((cargo) => cargo.id !== index);
      return [...newValues];
    });
  };

  return (
    <VStack spacing={0} align="start" pr={4}>
      {pointsFields?.map((name, index) => {
        const { type } = pointsFields.value[index];
        return (
          <Box mb={4} key={name} w="full">
            <SubformTitle title={type === OrderLoadType.Loading ? 'Загрузка' : 'Разгрузка'} />
            <HStack spacing={4} w="full">
              <Box w="full" mr={3}>
                <UiAddressSearchField
                  placeholder={type === OrderLoadType.Loading ? 'Адрес погрузки' : 'Адрес разгрузки'}
                  name={`${name}.address`}
                  label={type === OrderLoadType.Loading ? 'Адрес погрузки' : 'Адрес разгрузки'}
                  required
                />
              </Box>
              {!!pointsFields.length && pointsFields.length > 1 && index !== 0 && (
                <UiDeleteButton
                  onClick={() => {
                    if (pointsFields.value[index].type === OrderLoadType.Loading) {
                      const pointLoadingCargos = pointsFields.value[index]?.cargos;
                      if (pointLoadingCargos) {
                        const loadingCargosIds = pointLoadingCargos?.map((cargo: any) => cargo.id);
                        loadingCargosIds?.map((cargoId: number | string) => handleDeleteCargo(cargoId));

                        values.route.points?.map((point: PointForm, indexP: number) => {
                          if (point.type === OrderLoadType.Unloading) {
                            const cargosIndexes = point.cargos
                              ?.map((cargo, ind) => ({ ...cargo, ind }))
                              .filter((cargo: any) => {
                                return loadingCargosIds.includes(cargo.unloadCargo?.value);
                              });
                            if (cargosIndexes) {
                              cargosIndexes?.map((cargo) => {
                                form.change(
                                  `route.points[${indexP}].cargos`,
                                  values.route.points[indexP].cargos.filter((cargoValue: any, ind: number) => ind !== cargo.ind)
                                );
                                values.state.cargo?.[indexP]?.splice(cargo.ind, 1);
                                form.resetFieldState(`route.points[${indexP}].cargos`);
                              });
                            }
                          }
                        });
                      }
                    }

                    form.change(
                      `state.cargo`,
                      values.state.cargo.filter((item: number, ind: number) => ind !== index)
                    );

                    pointsFields.remove(index);
                  }}
                >{`Удалить точку ${type === OrderLoadType.Loading ? 'загрузки' : 'разгрузки'} ${index + 1}`}</UiDeleteButton>
              )}
            </HStack>
            {values[namespace]?.points[index]?.showLoadTime && (
              <UiButtonOutlined leftIcon={plus} onClick={() => form.change(`${name}.showLoadTime`, false)}>
                Дата и время
              </UiButtonOutlined>
            )}
            {!values[namespace]?.points[index]?.showLoadTime && (
              <VStack spacing={0} align="start">
                <HStack spacing={0}>
                  <UiIconButton onClick={() => form.change(`${name}.showLoadTime`, true)} aria-label="close" icon={CloseIcon} />
                  <Text textStyle="p4" pl={2}>
                    Дата и время
                  </Text>
                </HStack>
                <TimeSubform arrivalTimeOption={values.route?.points[index]?.arrivalTimeOption} name={name} />
              </VStack>
            )}
            <CargoSubform
              namespace={name}
              form={form}
              packageTypes={packageTypes}
              type={type}
              pointIndex={index}
              handleAddCargo={handleAddCargo}
              handleUpdateCargo={handleUpdateCargo}
              handleDeleteCargo={handleDeleteCargo}
              restToUnloadCargos={restToUnloadCargos}
              cargoTypes={cargoTypes}
              unloadOptions={unloadOptions}
            />
          </Box>
        );
      })}

      <HStack spacing={4} py={4}>
        <Text>Добавить точку маршрута: </Text>
        <UiTextButton
          onClick={() => {
            const id = uuid();
            pointsFields.push({
              showLoadTime: true,
              type: OrderLoadType.Loading,
              id: `point_${id}`,
              cargos: [],
              arrivalTimeOption: arrivalTimeTypeOptions[TimeType.simple],
            });
            form.change(`state.cargo`, [...values.state.cargo, []]);
          }}
        >
          Загрузка
        </UiTextButton>
        <UiTextButton
          onClick={() => {
            const id = uuid();
            pointsFields.push({
              showLoadTime: true,
              type: OrderLoadType.Unloading,
              id: `point_${id}`,
              cargos: [],
              arrivalTimeOption: { value: TimeType.simple, label: 'Не указывать время' },
            });
            form.change(`state.cargo`, [...values.state.cargo, []]);
          }}
        >
          Разгрузка
        </UiTextButton>
        {/* <UiTextButton onClick={() => transitFields.push({})} tooltip="В разработке" disabled> */}
        <UiTextButton onClick={emptyFunction} tooltip="В разработке" disabled>
          Ехать через
        </UiTextButton>
        {/* <UiTextButton>Таможня</UiTextButton> */}
      </HStack>
      <HStack spacing={4}>
        <Text>Маршрут</Text>
        <UiRadioGroupField config={routeTypeConfig} name={`${namespace}.routeType`} />
      </HStack>
    </VStack>
  );
};
