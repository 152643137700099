import './resources-empty-component.scss';
import { FC } from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { UiButtonFilled } from '@/ui-elements';

interface Props {
  text: string;
  buttonText: string;
  onClick: VoidFunction;
}

export const ResourcesEmptyComponent: FC<Props> = ({ text, buttonText, onClick }) => {
  return (
    <VStack className="resources-empty-component" spacing={8}>
      <Text className="resources-empty-component-text">{text}</Text>
      <UiButtonFilled onClick={onClick}>{buttonText}</UiButtonFilled>
    </VStack>
  );
};
