import { FC, useState } from 'react';
import { VStack } from '@chakra-ui/react';
import { Header } from './header';
import { TableComponent } from './table';
import { TableConfig, TableDataSource } from '@/types/common/table';

type Props = {
  config: TableConfig;
  dataSource: TableDataSource;
};

export const AnalyticsTableComponentForManager: FC<Props> = ({ config, dataSource }) => {
  const [dateRange, setDateRange] = useState('all');
  return (
    <VStack px={4} py={5} w="full">
      <Header value={dateRange} onChange={setDateRange} />
      <TableComponent config={config} dataSource={dataSource} />
    </VStack>
  );
};
