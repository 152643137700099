import { HStack, Text, Box } from '@chakra-ui/react';
import './route-card.scss';
import { Order } from '@/types/models/order';
import { UiButtonOutlined } from '@/ui-elements';
import { OrderGeneralDistance, OrderGeneralInfo } from '@/components';
import { OrderRouteCompact } from '@/components/order-route-compact/order-route-compact';
import { PaymentStatusStyles } from '@/features/trip/styles';
import { UiStatus } from '@/ui-elements/status/status';
import { DETAILS_BUTTON, ROUTE_NUMBER } from './texts';

type Props = {
  route: Order;
  onDetailsClick: () => void;
};

export const RouteCard = ({ route, onDetailsClick }: Props) => {
  const statusConfig = route.carrierPayment.paid ? PaymentStatusStyles.Paid : PaymentStatusStyles.NotPaid;

  return (
    <Box className="route-card">
      <HStack className="route-card__header">
        <Text>
          {ROUTE_NUMBER} {route.id}
        </Text>
        <UiStatus text={statusConfig.text} backGroundColor={statusConfig.background} color={statusConfig.color} />
      </HStack>
      <OrderRouteCompact points={route.points} route={route} />
      <HStack className="route-card__general-info">
        <HStack className="route-card__general-info__vehicle">
          <OrderGeneralDistance range={route.distance} type={route.type} />
          <OrderGeneralInfo loadingType={route.loadingType || []} vehicleTypes={route.vehicleTypes} shippedTotals={route.shippedTotals} />
        </HStack>
        <UiButtonOutlined onClick={onDetailsClick} mode="dark">
          {DETAILS_BUTTON}
        </UiButtonOutlined>
      </HStack>
    </Box>
  );
};
