import { FC } from 'react';
import { Grid, GridItem } from '@chakra-ui/react';
import { vehicleTypeLabels } from 'components/verification-forms/constants';
import { FormValues, VerificationFormMode } from 'components/verification-forms/types';
import classNames from 'classnames';
import { UiSelectFieldAsync } from '@/ui-elements';
import { fetchCarriersOptions } from '@/api/fetch-carriers-options';
import { UiSwitcher } from '@/ui-elements/switcher/switcher';
import { useCarriersEnum } from '@/features/carriers/hooks/use-carriers-enum';

export const VehicleOptionsSubform: FC<{
  mode: VerificationFormMode;
  handleVehicleType: (arg: string) => void;
  vehicleGroup: number;
  initialValues: FormValues;
}> = ({ mode, handleVehicleType, vehicleGroup, initialValues }) => {
  const { organizationsEnumData } = useCarriersEnum();
  const handleVehicleTypeChange = (val: string) => {
    handleVehicleType(val);
  };
  const isCheck = mode === 'check';
  const gridRowClassName = classNames({ [`carriers-verification-form__small-row`]: isCheck });

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={6}>
      <GridItem className={gridRowClassName} mt={0.5}>
        <UiSelectFieldAsync
          isDisabled={isCheck}
          fetchOptions={fetchCarriersOptions}
          name="carrierId"
          required
          label="К какой организации относится ТС"
          treshhold={1}
          defaultOptions={organizationsEnumData}
        />
      </GridItem>

      <GridItem className={gridRowClassName} mt={6}>
        <UiSwitcher
          className="carriers-verification-form__switcher"
          onChange={(val: string) => handleVehicleTypeChange(val)}
          tabsList={vehicleTypeLabels}
          disabled={isCheck}
          name="vehicleGroup"
          value={vehicleGroup}
          defaultActiveTab={vehicleTypeLabels[initialValues.vehicleGroup!]?.key || vehicleTypeLabels[0].key}
        />
      </GridItem>
    </Grid>
  );
};
