import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@/config/constants/query-keys';
import { useDriver } from '@/features/drivers/hooks/use-driver';
import { useAppSelector } from '@/hooks/use-app-selector';
import { resourcesSelectedDriverIdSelector } from '@/features/resources/selectors';
import { ModalAction, useModal } from '@/hooks/use-modal';
import { ModalType } from '@/types/common/modals';
import { DocumentTypes } from '@/config/constants/document-types';
import { AttachmentDeps } from '@/config/constants/types';
import { ResourcesContentTab, ResourcesSidebarMode } from '@/features/resources/types/general';
import { useResourcesDispatchActions, useEditResource } from '@/features/resources/hooks';
import { useDependentAttachments } from '@/features/attachments/hooks/use-dependent-attachments';

export const useDriverDetailsView = () => {
  const client = useQueryClient();
  const { handleSetSidebarMode } = useResourcesDispatchActions();
  const selectedDriverId = useAppSelector(resourcesSelectedDriverIdSelector);
  const { driver: driverQuery } = useDriver(selectedDriverId!);
  const { data: response, isInitialLoading } = driverQuery;
  const driver = response?.data;

  const onSuccessDelete = () => {
    toast.success('Водитель успешно удален');
    handleSetSidebarMode(ResourcesSidebarMode.None);
    client.invalidateQueries([QueryKeys.allDrivers]);
  };

  const { modalView: ModalDeleteDriver, setModalOpened: showDeleteDriver } = useModal<
    { text: string; driverID?: number; modalWidth: number } & ModalAction
  >(ModalType.DeleteDriver, {
    text: 'Водитель будет удален',
    driverID: selectedDriverId!,
    onSuccess: onSuccessDelete,
    modalWidth: 320,
  });

  const handleDeleteDriver = () => {
    showDeleteDriver(true);
  };

  const { handleEditResource, ModalEditNotify } = useEditResource({
    approvalStatuses: driver?.approvalStatuses,
    activeTab: ResourcesContentTab.Drivers,
  });

  const { modalView: ModalResetPassword, setModalOpened: showModalRegisterDriver } = useModal<{} & ModalAction>(ModalType.ResetPassword, {});

  const handleShowResetPasswordModal = () => {
    showModalRegisterDriver(true);
  };

  const driverPassportDocs = useDependentAttachments({
    dependent: AttachmentDeps.Driver,
    dependentId: selectedDriverId,
    type: DocumentTypes.dlDocumentDriverPassport,
  });

  const driverLicenseDocs = useDependentAttachments({
    dependent: AttachmentDeps.Driver,
    dependentId: selectedDriverId,
    type: DocumentTypes.dlDocumentDriverLicense,
  });

  return {
    driver,
    driverPassportDocs,
    driverLicenseDocs,
    loading: isInitialLoading,
    ModalDeleteDriver,
    ModalEditNotify,
    ModalResetPassword,
    handleShowResetPasswordModal,
    handleDeleteDriver,
    handleEditDriver: handleEditResource,
  };
};
