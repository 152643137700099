import { Box, Heading, Text } from '@chakra-ui/react';
import { FC } from 'react';
import './reviews-tab.scss';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { Driver } from '@/types/models/driver';
import { CarrierRating } from '@/components/carrier-rating/carrier-rating';
import { ReviewComponent } from './review';
import { declensionNouns } from '@/helpers/declension-of-nouns';

type Props = {
  driver: Driver;
};

export const RewiewsTab: FC<Props> = ({ driver }) => (
  <UiScrollbars style={{ width: '100%', height: '100vh' }}>
    <Box className="reviews-tab">
      <Heading>ФИО</Heading>
      <Text className="reviews-tab__name">{driver.name}</Text>
      <CarrierRating rating={driver.rating} />
      <Heading>Отзывы</Heading>
      {driver?.reviews?.length ? (
        <>
          <Text mt="12px">
            {`${declensionNouns(driver.reviews.length, ['Найден', 'Найдено', 'Найдено'])} ${driver.reviews.length} ${declensionNouns(
              driver.reviews.length,
              ['отзыв', 'отзыва', 'отзывов']
            )}`}
          </Text>
          {driver.reviews.map((review, id) => (
            <ReviewComponent key={`${review.value + id}`} review={review} />
          ))}
        </>
      ) : (
        <Text>Нет</Text>
      )}
    </Box>
  </UiScrollbars>
);
