import { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { Carrier } from '@/types/models/carrier';
import { Vehicle } from '@/types/models/vehicle';
import { DriversAndVehiclesTabs, driversAndVehiclesTabNames } from '@/components/security/drivers-and-vehicles-list/constants';
import { UiSwitcher } from '@/ui-elements/switcher/switcher';
import { VehiclesListCompact } from '@/components/security/vehicles-compact-list/vehicles-list-compact';
import { DriversList } from './drivers-list';

type Props = {
  carrier: Carrier;
  vehicles: Array<Vehicle>;
  showDriver: (driverId: number) => void;
  onVehicleMoreInfoClick?: (vehicle: Vehicle) => void;
};

export const CarrierDriversTab = ({ carrier, showDriver, vehicles, onVehicleMoreInfoClick }: Props) => {
  const [tab, setTab] = useState<string>(DriversAndVehiclesTabs.Drivers);
  const onViewChange = (value: string) => {
    setTab(value);
  };

  return (
    <Box className="carrier-drivers-tab">
      <UiSwitcher onChange={onViewChange} className="drivers-and-vehicles-list__switcher" tabsList={driversAndVehiclesTabNames} />
      <div className="drivers-and-vehicles-list__content">
        {tab === DriversAndVehiclesTabs.Drivers && <DriversList drivers={carrier.drivers} onDriverMoreInfoClick={showDriver} />}
        {tab === DriversAndVehiclesTabs.Vehicles && (
          <VehiclesListCompact vehicles={vehicles} department={carrier.department.name} onVehicleMoreInfoClick={onVehicleMoreInfoClick} />
        )}
      </div>
    </Box>
  );
};
