import { useMutation } from '@tanstack/react-query';
import { PaymentPartial } from '@/types/api/payment-partial';
import { OrdersApi } from '@/api/orders';
import { OrderServicePatch } from '@/types/models/order-service';
import { OrderServicesApi } from '@/api/order-services';
import { PrepaymentPartial } from '@/types/api/prepayment-partial';

export const useOrderPayment = (id: number) => {
  const setOrderPaid = useMutation({
    mutationKey: ['setOrderPayment'],
    mutationFn: (options: PaymentPartial) => OrdersApi.setOrderPaid(id, options),
  });
  const setOrderPrepaymentPaid = useMutation({
    mutationKey: ['setOrderPrepaymentPaid'],
    mutationFn: (options: PrepaymentPartial) => OrdersApi.setOrderPrepaymentPaid(id, options),
  });

  const setOrderServicePayment = useMutation({
    mutationKey: ['updateOrderService'],
    mutationFn: (options: OrderServicePatch) => OrderServicesApi.updateOrderService(id, options),
  });

  return { setOrderPaid, setOrderPrepaymentPaid, setOrderServicePayment };
};
