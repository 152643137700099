import { Box, Grid, GridItem, InputAddon, InputGroup } from '@chakra-ui/react';
import { VatTypeMap } from 'config/constants/vat-options';
import { CarriersLoadsCreateOfferField } from '@/types/models/carriers-loads';
import { UiNumberPickerField, UiSelectFieldAsync } from '@/ui-elements';
import { PLACEHOLDER_FORM_ITEM } from '@/config/constants/carriers-loads';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { toPriceFormat } from '@/helpers/price/to-price-format';
import { fetchRoadTrainsOptions } from '@/api/fetch-road-trains-options';
import { fetchDriversOptions } from '@/api/fetch-drivers-options';

type Props = {
  drivers: Array<SelectOption>;
  vehicles: Array<SelectOption>;
  disablePrice: boolean;
  priceVAT: boolean;
  isCarrierSimple: boolean;
  hidePriceField?: boolean;
};

export const CreateOfferFormItems = ({ drivers, isCarrierSimple, vehicles, disablePrice, priceVAT, hidePriceField = false }: Props) => {
  return (
    <Box className="carriers-loads-form__contents">
      <Grid
        className="carriers-loads-form__container"
        alignContent="center"
        justifyContent="center"
        templateColumns="repeat(auto-fit, minmax(250px, 1fr))"
        width="100vw"
        gap={2}
      >
        <GridItem className="carriers-loads-form__direction">
          <UiSelectFieldAsync
            name={CarriersLoadsCreateOfferField.VehicleId}
            required
            label="ТС"
            placeholder="ТС"
            treshhold={1}
            defaultOptions={vehicles}
            fetchOptions={fetchRoadTrainsOptions}
          />
        </GridItem>
        {!isCarrierSimple && (
          <GridItem className="carriers-loads-form__direction">
            <UiSelectFieldAsync
              name={CarriersLoadsCreateOfferField.DriverId}
              required
              label="Водитель"
              placeholder="Водитель"
              treshhold={1}
              defaultOptions={drivers}
              fetchOptions={fetchDriversOptions}
            />
          </GridItem>
        )}
        {!hidePriceField && (
          <GridItem className="carriers-loads-form__direction">
            <InputGroup className="carriers-loads-form__group">
              <UiNumberPickerField
                name={CarriersLoadsCreateOfferField.Price}
                label="Цена"
                min={0}
                required
                format={(value) => toPriceFormat(Number(value), true).trim()}
                hideClearButton
                hideStepper
                isDisabled={disablePrice}
                placeholder="Предложить стоимость доставки"
                _placeholder={PLACEHOLDER_FORM_ITEM}
              />
              <InputAddon children={priceVAT ? VatTypeMap.withVat : VatTypeMap.withoutVat} />
            </InputGroup>
          </GridItem>
        )}
      </Grid>
    </Box>
  );
};
