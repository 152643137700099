import { MyOrganizationsApi } from '@/api/my-organizations';
import { QueryKeys } from '@/config/constants/query-keys';
import { useQueryWithUpdates } from '@/hooks/use-query-with-updates';
import { MyOrganizationTypeWithBankDetails } from '@/types/models/my-organization/my-organization';
import { ResponseWithPager } from '@/types/models/pager';

export const useOrganizationsUpdates = () => {
  const queryFn = (isFirst: boolean) => MyOrganizationsApi.getAllOrganizations(!isFirst);

  const allOrganizations = useQueryWithUpdates<ResponseWithPager<MyOrganizationTypeWithBankDetails>>({
    queryKey: [QueryKeys.allOrganizations],
    refetchOnWindowFocus: false,
    queryFn,
  });

  return { allOrganizations };
};
