import { FC } from 'react';
import { PageWrapper } from 'pages/page-wrapper';
import { Flex } from '@chakra-ui/react';
import { CarriersLoadsListView } from '@/views/carriers-loads/carriers-loads-list/carriers-loads-list-view';
import { CarrierLoadDetailsView } from '@/views/carriers-loads/carriers-loads-details/carriers-loads-details-view';

export const CarriersLoadsPage: FC = () => (
  <PageWrapper>
    <Flex>
      <CarriersLoadsListView />
      <CarrierLoadDetailsView />
    </Flex>
  </PageWrapper>
);
