import { FC } from 'react';
import { Text, Box, Grid, GridItem } from '@chakra-ui/react';
import './trip-documents.scss';
import { UiDownloadCard } from '@/ui-elements';
import { FileDocument } from '@/types/models/document';

type Props = {
  confirmationDocuments?: Array<FileDocument>;
};

export const TripDocuments: FC<Props> = ({ confirmationDocuments }) => (
  <Box>
    <Box className="trip-widget-documents">
      <Text className="trip-widget-documents__title">Документы</Text>
      <Grid templateColumns="repeat(3, 1fr)" gap="20px">
        {confirmationDocuments?.map((doc) => (
          <GridItem key={`confirmation${doc.id}`}>
            <UiDownloadCard fileName={doc.name} url={doc.link} />
          </GridItem>
        ))}
      </Grid>
    </Box>
  </Box>
);
