export const dataSourceForManager = [
  {
    name: 'Витая сталь',
    finished: '60',
    broken: '2',
    done: '58',
    margin: '120 000 ₽',
  },
  {
    name: 'Витая сталь',
    finished: '60',
    broken: '2',
    done: '58',
    margin: '120 000 ₽',
  },
  {
    name: 'Витая сталь',
    finished: '60',
    broken: '2',
    done: '58',
    margin: '120 000 ₽',
  },
  {
    name: 'Витая сталь',
    finished: '60',
    broken: '2',
    done: '58',
    margin: '120 000 ₽',
  },
  {
    name: 'Витая сталь',
    finished: '60',
    broken: '2',
    done: '58',
    margin: '120 000 ₽',
  },
  {
    name: 'Витая сталь',
    finished: '60',
    broken: '2',
    done: '58',
    margin: '120 000 ₽',
  },
  {
    name: 'Витая сталь',
    finished: '60',
    broken: '2',
    done: '58',
    margin: '120 000 ₽',
  },
  {
    name: 'Витая сталь',
    finished: '60',
    broken: '2',
    done: '58',
    margin: '120 000 ₽',
  },
  {
    name: 'Витая сталь',
    finished: '60',
    broken: '2',
    done: '58',
    margin: '120 000 ₽',
  },
  {
    name: 'Витая сталь',
    finished: '60',
    broken: '2',
    done: '58',
    margin: '120 000 ₽',
  },
  {
    name: 'Витая сталь',
    finished: '60',
    broken: '2',
    done: '58',
    margin: '120 000 ₽',
  },
  {
    name: 'Витая сталь',
    finished: '60',
    broken: '2',
    done: '58',
    margin: '120 000 ₽',
  },
  {
    name: 'Витая сталь',
    finished: '60',
    broken: '2',
    done: '58',
    margin: '120 000 ₽',
  },
];
