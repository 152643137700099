import _ from 'lodash';
import qs from 'qs';

export const validateQueryString = (query: Record<string, any> | undefined) => {
  let str = '';
  if (!_.isEmpty(query)) {
    str = str.concat('?');
    Object.entries(query).forEach(([key, value]) => {
      if (typeof query[key] === 'object') {
        // eslint-disable-next-line
        Object.entries(query[key]).forEach(([key, value]) => {
          str = str.concat(`&${key}=${value}`);
        });
      } else str = str.concat(`&${key}=${value}`);
    });
  }
  return str;
};

// преобразует массив для GET запроса в вид ?query=1,2,3 и игнорирует null, undefined
export const stringifyQuery = (query: Record<string, any> | undefined) =>
  qs.stringify(query, { skipNulls: true, addQueryPrefix: true, arrayFormat: 'comma', encode: false });
