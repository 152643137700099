import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Error404Component } from 'components/error-404-component/error-404-component';
import { getText } from 'components/error-404-component/helpers/get-text';
import { getURL } from 'components/error-404-component/helpers/get-url';
import { useAccount } from '@/features/account/hooks/use-account';

export const Error404View: FC = () => {
  const navigate = useNavigate();
  const { currentRole } = useAccount();
  const text = getText(currentRole);
  const handleAddOrder = () => {
    const navigateUrl = getURL(currentRole);
    navigate(navigateUrl);
  };
  return <Error404Component handleAddOrder={handleAddOrder} text={text} />;
};
