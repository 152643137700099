import './load-point-with-icon.scss';
import { FC } from 'react';
import { HStack, Icon, VStack } from '@chakra-ui/react';
import { useIcons } from '@/hooks/use-icons';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { pointExtender } from '@/features/order/extender/order-point-extender';
import { useAppSelector } from '@/hooks/use-app-selector';
import { cargoTypesSelector } from '@/features/cargo/selectors/cargo-types-selector';
import { Load } from '@/types/models/loads';
import { Point } from '@/types/models/point';
import { Loading } from '@/types/models/loading';
import { OrderPoint } from '@/components/order-point/order-point';

type Props = {
  point: Point;
  isLastPoint?: boolean;
  load: Load;
};

export const LoadPointWithIcon: FC<Props> = ({ point, load, isLastPoint = false }) => {
  const { LoadIcon, UnloadIcon, LongArrowIcon } = useIcons();
  const extendedPoint = useExtendedObject({ point, cargos: load.cargos, action: load.actions } as Loading, pointExtender);
  const cargoTypes = useAppSelector(cargoTypesSelector);

  return (
    <HStack className="load-point">
      <VStack className="load-point__icons" alignItems="center">
        <HStack className="load-point__type_icons">
          {extendedPoint.isUnloading && <Icon as={UnloadIcon} />}
          {extendedPoint.isLoading && <Icon as={LoadIcon} />}
        </HStack>
        <div>{!isLastPoint && <Icon as={LongArrowIcon} />}</div>
      </VStack>
      <HStack spacing={0} justify="space-between" w="full">
        <OrderPoint point={extendedPoint.point} order={load} isLastPoint={isLastPoint} cargoTypes={cargoTypes} />
      </HStack>
    </HStack>
  );
};
