import { CargoForm } from '@/components/create-order-form/schema';
import { cargoProperties } from '@/helpers/forms/cargo-properties';

export const extractCargo = (cargo: CargoForm) => ({
  id: cargo?.id,
  tenderLotID: cargo?.tenderLotID,
  tenderLotPositionID: cargo?.tenderLotPositionID,
  type: cargo?.cargoType?.value,
  package: cargo?.package?.value,
  quantity: Number(cargo?.quantity),
  properties: cargoProperties(cargo),
});
