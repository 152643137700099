import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FunnelState } from '@/features/funnel/types/funnel-state';
import { FunnelSwitcherStatus } from '@/types/models/funnel';
import { Load } from '@/types/models/loads';
import { getLoad } from '@/features/funnel/actions/get-load';
import { getOrder } from '@/features/order/actions/get-order';

const initialState: FunnelState = {
  /* активный таб */
  activeFunnelTab: FunnelSwitcherStatus.NewCalculation,
  /* id активного груза */
  funnelPageSelectedLoadID: undefined,
  isCreating: false,
  isUpdating: false,
  loadEdit: {
    isLoading: false,
    error: undefined,
    load: {} as Load,
  },
};

const slice = createSlice({
  name: 'funnel',
  initialState,
  reducers: {
    selectTab: (state, action: PayloadAction<FunnelSwitcherStatus>) => {
      state.activeFunnelTab = action.payload;
    },
    selectLoadID: (state, action: PayloadAction<number | undefined>) => {
      state.funnelPageSelectedLoadID = action.payload;
    },
    // создание новой заявки
    create: (state) => {
      state.isCreating = true;
    },
    cancelCreate: (state) => {
      state.isCreating = false;
      state.isUpdating = false;
    },
    // изменить заявку
    update: (state) => {
      state.isUpdating = true;
    },
    clearState: () => {
      return initialState;
    },
  },
  extraReducers: {
    [getOrder.pending.type]: (state: FunnelState) => {
      state.loadEdit.isLoading = true;
      state.loadEdit.error = undefined;
      return state;
    },
    [getOrder.fulfilled.type]: (state: FunnelState, action: PayloadAction<Load>) => {
      state.loadEdit.isLoading = false;
      state.loadEdit.load = action.payload;
      return state;
    },
    [getOrder.rejected.type]: (state: FunnelState, action: PayloadAction<string>) => {
      state.loadEdit.isLoading = false;
      state.loadEdit.error = action.payload;
      return state;
    },
    [getLoad.pending.type]: (state: FunnelState) => {
      state.loadEdit.isLoading = true;
      state.loadEdit.error = undefined;
      return state;
    },
    [getLoad.fulfilled.type]: (state: FunnelState, action: PayloadAction<Load>) => {
      state.loadEdit.isLoading = false;
      state.loadEdit.load = action.payload;
      return state;
    },
    [getLoad.rejected.type]: (state: FunnelState, action: PayloadAction<string>) => {
      state.loadEdit.isLoading = false;
      state.loadEdit.error = action.payload;
      return state;
    },
  },
});

export const funnelReducers = slice.reducer;
export const funnelActions = slice.actions;
