import { FC } from 'react';
import './search-carrier-filter.scss';
import { SearchCarrierFilterBottom } from 'components/search-carrier/search-carrier-filter/search-carrier-filter-bottom';
import { SearchCarrierFilterTitle } from 'components/search-carrier/search-carrier-filter/search-carrier-filter-title';
import SearchCarrierFilterForm from 'components/search-carrier/search-carrier-filter/search-carrier-filter-form';
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex, HStack, Text } from '@chakra-ui/react';
import classNames from 'classnames';
import { emptyFunction } from '@/helpers/empty-function';
import { useIcons } from '@/hooks/use-icons';
import { CarrierFilterShortened } from '@/components/carrier-filter/carrier-filter-shortened';
import { SortType } from '@/config/constants/types';
import { CarrierSearchFilter } from './type';
import { CarrierFilter } from '@/types/api/carrier-partial';

type Props = {
  addAllCarriersRequest: () => void;
  isExpanded: boolean;
  onClickExpand: React.MouseEventHandler<HTMLButtonElement>;
  onChangeSorting: (sortType: SortType) => void;
  onFilterChange: (values: CarrierSearchFilter) => void;
  filters: CarrierFilter;
  foundAmount?: number;
  canRequestAll: boolean;
};
export const SearchCarrierFilter: FC<Props> = ({
  addAllCarriersRequest,
  isExpanded = true,
  onClickExpand = emptyFunction,
  onChangeSorting = emptyFunction,
  onFilterChange,
  filters,
  foundAmount,
  canRequestAll,
}) => {
  const { ArrowUp } = useIcons();
  return (
    <Accordion w="100%" defaultIndex={[0]} index={isExpanded ? [0] : [-1]} allowToggle className="search-carrier-filter">
      <AccordionItem w="100%">
        <AccordionButton className="carrier-filter-accordion__button" px={0} onClick={onClickExpand}>
          <Flex className="carrier-filter-accordion__button-container" justifyContent="space-between">
            <Box w="39vw">
              <SearchCarrierFilterTitle isExpanded={isExpanded} />
              {!isExpanded && <CarrierFilterShortened foundAmount={2} filters={filters} />}
            </Box>
            <HStack className={classNames('carrier-filter__header-button-collapse', { expanded: !isExpanded })}>
              <ArrowUp />
              <Text>{isExpanded ? 'Свернуть' : 'Развернуть'}</Text>
            </HStack>
          </Flex>
        </AccordionButton>
        <AccordionPanel>
          <SearchCarrierFilterForm onSubmit={onFilterChange} />
          <SearchCarrierFilterBottom
            canRequestAll={canRequestAll}
            addAllCarriersRequest={addAllCarriersRequest}
            onChangeSorting={onChangeSorting}
            foundAmount={foundAmount}
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
