import { HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { UiButtonFilled, UiTab, UiTabListMenuSelect, UiTabPanel, UiTabPanels, UiTabs } from '@/ui-elements';
import { carrierWidgetTabs } from '@/features/carriers/texts';
import { VehicleTab } from './vehicle-tab';
import './carrier-widget.scss';
import { Carrier } from '@/types/models/carrier';
import { CarrierDriverTab } from './driver-tab';
import { RewiewsTab } from './reviews-tab';
import { FinesTab } from './fines-tab';
import { Vehicle } from '@/types/models/vehicle';

export const CarrierWidget: FC<{ carrier?: Carrier; vehicles?: Array<Vehicle> }> = ({ carrier, vehicles }) => (
  <div className="carrier-widget">
    {carrier ? (
      <UiTabs>
        <UiTabListMenuSelect size="lg" className="carrier-widget__tablist">
          <UiTab>{carrierWidgetTabs.Vehicle}</UiTab>
          <UiTab>{carrierWidgetTabs.Driver}</UiTab>
          <UiTab>{carrierWidgetTabs.Rewiews}</UiTab>
          <UiTab>{carrierWidgetTabs.Fines}</UiTab>
          <UiTab>{carrierWidgetTabs.ExecutedTrips}</UiTab>
        </UiTabListMenuSelect>
        <UiTabPanels>
          <UiTabPanel>
            <VehicleTab carrier={carrier} vehicles={vehicles} />
          </UiTabPanel>
          <UiTabPanel>
            <CarrierDriverTab carrier={carrier} />
          </UiTabPanel>
          <UiTabPanel>
            <RewiewsTab carrier={carrier} />
          </UiTabPanel>
          <UiTabPanel>
            <FinesTab carrier={carrier} />
          </UiTabPanel>
          <UiTabPanel>
            <HStack height="500px" alignItems="center" justifyContent="center">
              <UiButtonFilled mode="dark">Скачать отчет о рейсах</UiButtonFilled>
            </HStack>
          </UiTabPanel>
        </UiTabPanels>
      </UiTabs>
    ) : (
      <Text>Перевозчик не найден</Text>
    )}
  </div>
);
