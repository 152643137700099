import { FC } from 'react';
import { UiButtonFilled } from '@/ui-elements';
import { orderActions } from '@/features/order/texts';
import { useIntermediaryResources } from '@/features/resources/hooks';
import { OrderExecutionStatus } from '@/config/constants/statuses';
import { FunnelLoadStatus } from '@/types/models/funnel';

type OrderInfoSearchCarrierButtonsProps = {
  disabled?: boolean;
  onCarrierSearchClick: VoidFunction;
  onChooseOwnDriverAndVehicle?: VoidFunction;
  onStartCarrierSearch?: VoidFunction;
  orderStatus?: OrderExecutionStatus | FunnelLoadStatus;
};

/**  Компонент кнопок для поиска исполнителей
 * если роль Посредник и статус Новые, то показываем кнопку "Распределение", которая переводит заявку в статус Поиск исполнителя.
 * Далее в статусе Поиск исполнителя (Таб "В распределении") показываем две кнопки "Найти внешнего исполнителя" и "Выбрать своего водителя и ТС"
 * Либо если текущая роль не посредник, то просто показываем кнопку "Найти исполнителя",
 * которая переводит заявку в статус "Поиск исполнителя" и открывает сайдбар для поиска внешних исполнителей.
 */

export const OrderInfoSearchCarrierButtons: FC<OrderInfoSearchCarrierButtonsProps> = ({
  disabled,
  orderStatus,
  onCarrierSearchClick,
  onChooseOwnDriverAndVehicle,
  onStartCarrierSearch,
}) => {
  const { isIntermediaryRole } = useIntermediaryResources();
  const showCarrierSearchButton = orderStatus === OrderExecutionStatus.New && isIntermediaryRole;

  return showCarrierSearchButton ? (
    <UiButtonFilled mode="dark" disabled={disabled} onClick={onStartCarrierSearch}>
      {orderActions.StartCarrierSearch}
    </UiButtonFilled>
  ) : (
    <>
      <UiButtonFilled mode="dark" disabled={disabled} onClick={onCarrierSearchClick}>
        {!isIntermediaryRole ? orderActions.Search : orderActions.SearchExternalCarrier}
      </UiButtonFilled>
      {isIntermediaryRole && (
        <UiButtonFilled mode="dark" disabled={disabled} onClick={onChooseOwnDriverAndVehicle}>
          {orderActions.ChooseOwnDriverAndVehicle}
        </UiButtonFilled>
      )}
    </>
  );
};
