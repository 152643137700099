import { useQueryClient } from '@tanstack/react-query';

export const useSettingsInvalidate = () => {
  const client = useQueryClient();
  const settingsInvalidate = () => {
    client.invalidateQueries(['allSettingss']);
  };

  return { settingsInvalidate };
};
