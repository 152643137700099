import { useQuery } from '@tanstack/react-query';
import { MyOrganizationsApi } from '@/api/my-organizations';
import { convertEnumToFormOptions } from '@/helpers/convert-enum-to-form-options';

export const useGetMyOrganizationTypes = () => {
  const organizationTypes = useQuery({
    queryKey: ['organizationTypes'],
    refetchOnWindowFocus: false,
    queryFn: () => MyOrganizationsApi.getOrganizationTypes(),
  });
  return { organizationTypes: convertEnumToFormOptions(organizationTypes?.data?.data) };
};
