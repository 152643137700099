import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { Order } from '@/types/models/order';
import { CarriersLoadsState } from '@/features/carriers-loads/types/carriers-loads-state';
import { CarrierLoadsPageWithOrders } from '@/features/carriers-loads/hooks/use-carriers-loads-actions';

export const selectOrderForPage = (
  state: Draft<CarriersLoadsState>,
  action: PayloadAction<{ orderId: Order['id'] | undefined; page: CarrierLoadsPageWithOrders }>
) => {
  const { orderId, page } = action.payload;
  if (!orderId) {
    state[page].id = undefined;
  } else {
    state[page].id = orderId;
  }
};
