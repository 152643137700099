import { CustomersApi } from '@/api/customers';
import { normalizeSelectOptionsResponce } from '@/helpers/api/normalize-select-options-responce';

export const fetchCustomersOptions = async (query: string) => {
  const res = await CustomersApi.getCustomersEnum(query);
  return normalizeSelectOptionsResponce(res);
};

export const fetchCustomersOptionsWithExtras = async (query: string) => {
  const res = await CustomersApi.getCustomersEnum(`${query}&extraParams=inn:ИНН`);
  return normalizeSelectOptionsResponce(res.data);
};
