import { useCallback } from 'react';
import { Routes } from 'config/routes/routes';
import { SidebarMode } from 'components/sidebar-wrapper/constants';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { carriersLoadsActions } from '@/features/carriers-loads/slice';
import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';

export type CarrierLoadsPageWithOrders =
  | Routes.CurrentRoutes
  | Routes.FutureRoutes
  | Routes.OrdersWithMyParticipation
  | Routes.CarriersLoads
  | Routes.CarriersLoadsAuthorized
  | Routes.CompletedRoutes;

export const useCarrierLoadActions = (page: CarrierLoadsPageWithOrders = Routes.CarriersLoads) => {
  const dispatch = useAppDispatch();

  /**
   * Select order with my (Carrier) participation
   * @param orderId
   */
  const selectOrderForPage = useCallback(
    (orderId?: number) => {
      dispatch(carriersLoadsActions.selectOrderForPage({ orderId, page }));
    },
    [page, dispatch]
  );

  /**
   * Set sidebar mode for pages for Carrier
   * @param mode
   */
  const setSidebarMode = useCallback(
    (mode: SidebarMode = SidebarMode.None) => {
      dispatch(carriersLoadsActions.setSidebarMode({ mode, page }));
    },
    [page, dispatch]
  );

  const closeSidebar = () => setSidebarMode();

  /**
   * show search form
   * @param order
   */
  const searchForCarrier = useCallback(
    (order?: Order | Load) => {
      dispatch(carriersLoadsActions.searchForCarrier(order));
    },
    [dispatch]
  );

  /**
   * Отобразить компонент разделения заявки
   * @param order - исходная заявка
   * @param partsToSplit - количество частей для разделения
   */
  const split = useCallback(
    ({ order, partsToSplit }: { order?: Order; partsToSplit?: number }) => {
      dispatch(carriersLoadsActions.split({ order, partsToSplit }));
    },
    [dispatch]
  );

  /**
   * Select order
   * @param orderId
   */
  const select = useCallback(
    // TODO switch page (one method)
    (orderId?: number) => {
      dispatch(carriersLoadsActions.select(orderId));
    },
    [dispatch]
  );

  /**
   * Select order
   * @param orderId
   */
  const setTotalVisibleOrdersCount = useCallback(
    (total?: number) => {
      dispatch(carriersLoadsActions.setTotalVisibleOrdersCount(total));
    },
    [dispatch]
  );

  /**
   *Показать отзызвы о перевозчике, если carrier - undefined -то скрывает отзывы
   * */
  const clearState = useCallback(() => {
    dispatch(carriersLoadsActions.clearState());
  }, [dispatch]);

  return {
    split,
    select,
    searchForCarrier,
    clearState,
    setTotalVisibleOrdersCount,
    selectOrderForPage,
    setSidebarMode,
    closeSidebar,
  };
};
