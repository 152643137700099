import { createPortal } from 'react-dom';
import { useAppSelector } from '@/hooks/use-app-selector';
import { UiSpinnerGlobal } from '@/ui-elements';
import { spinnerSelector } from '@/features/spinner/selector';

const spinnerRoot = document.getElementById('spinner-root');

export const SpinnerView = () => {
  const visible = useAppSelector(spinnerSelector);

  return spinnerRoot ? createPortal(<UiSpinnerGlobal visible={visible} loadingMessage="Загрузка..." />, spinnerRoot) : null;
};
