import { useCallback } from 'react';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { settingsActions } from '@/features/settings/slice';

export const useSettingsSelect = () => {
  const dispatch = useAppDispatch();
  const select = (settingsId: number) => {
    dispatch(settingsActions.select(settingsId));
  };
  const unselect = useCallback(() => {
    dispatch(settingsActions.unselect());
  }, [dispatch]);

  return { select, unselect };
};
