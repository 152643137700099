import { LoadsApi } from '@/api/loads';
import { OrdersApi } from '@/api/orders';
import { useQueryWithUpdates } from '@/hooks/use-query-with-updates';
import { OrderTimeline } from '@/types/models/order-timeline';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';

export const useLoadTimelineUpdates = (id: number) => {
  const queryFn = (isFirst: boolean) => OrdersApi.getOrderTimeline(id!, !isFirst);

  const loadTimeline = useQueryWithUpdates<ResponseWithoutPagerSimple<OrderTimeline[]>>({
    queryKey: ['loadTimeline', id],
    refetchOnWindowFocus: false,
    staleTime: 5000,
    enabled: !!id,
    queryFn,
  });
  return { loadTimeline };
};
