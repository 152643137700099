import './loads-sort.scss';
import classNames from 'classnames';
import { ButtonGroup, HStack } from '@chakra-ui/react';
import { LoadsSortingOptions, SORT_LOADS } from '@/config/constants/loads';
import { UiText } from '@/ui-elements/ui-text/ui-text';
import { LoadsSortButton } from '@/components/loads/sort-button/loads-sort-button';

export const LoadsSort = ({ disabled }: { disabled: boolean }) => {
  return (
    <HStack className={classNames('loads-sort', { 'loads-sort_disabled': disabled })} spacing={6}>
      <UiText className="loads-sort__title">{SORT_LOADS}</UiText>
      <ButtonGroup spacing={4}>
        {LoadsSortingOptions.map((sortOption) => (
          <LoadsSortButton sortOption={sortOption} key={sortOption.id} />
        ))}
      </ButtonGroup>
    </HStack>
  );
};
