import { FC } from 'react';
import { CarrierCardRegNumber } from 'components/carrier-card/carrier-card-reg-number';
import { VehicleDetails } from 'components/vehicle-widget/vehicle-details';
import { Vehicle } from '@/types/models/vehicle';

interface IProps {
  vehicle: Vehicle;
  vehicleTypeIcon?: string;
  vehicleTypeName?: string;
}

export const VehicleRegInfo: FC<IProps> = ({ vehicle, vehicleTypeIcon, vehicleTypeName }) => {
  const { volume, capacity } = vehicle;

  return (
    <div className="vehicle-widget__reg_info">
      <CarrierCardRegNumber regNumber={vehicle.regNumber} region="" />
      {vehicleTypeIcon && <img width="48px" height="20px" src={vehicleTypeIcon} alt="vehicle img" />}
      <span className="vehicle-widget__small-name">{vehicleTypeName}</span>
      <VehicleDetails volume={volume} capacity={capacity} />
    </div>
  );
};
