import { Divider } from '@chakra-ui/react';
import { FC, MouseEventHandler } from 'react';
import { OrderDetailsClientExecution } from 'components/order-details';
import { OrderDetailsDocuments } from 'components/order-details/order-details-documents/order-details-documents';
import { scrollStyle } from 'components/order-details/order-details-component/constants';
import { OrderTimelineView } from '@/views/order-timeline/order-timeline-view';
import { Order } from '@/types/models/order';
import './order-details-component.scss';
import { UiTabs } from '@/ui-elements/ui-tabs/tabs/ui-tabs';
import { UiTab } from '@/ui-elements/ui-tabs/tab/ui-tab';
import { UiTabPanels } from '@/ui-elements/ui-tabs/ui-tab-panel/ui-tab-panels';
import { UiTabPanel } from '@/ui-elements/ui-tabs/ui-tab-panel/ui-tab-panel';
import { orderClientTabs } from '@/features/order/texts';
import { UiTabListMenuSelect } from '@/ui-elements/ui-tabs/tab-list/ui-tab-list-menu-select';
import { VoidFunction } from '@/types/common/void-function';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { OrderOffer } from '@/types/models/order-offer';
import { emptyFunction } from '@/helpers/empty-function';
import { OrderDetailsPaymentView } from '@/views/order-details-payment-view/order-details-payment-view';
import { OrderDetailsServiceView } from '@/views/order-details-service-view/order-details-service-view';
import { ShipmentRequest } from '@/types/models/shipment-request';
import { FuelStatus } from '@/config/constants/statuses';
import { Load } from '@/types/models/loads';

type Props = {
  order: Order | Load;
  // отправленные запросы
  shipmentRequests?: Array<ShipmentRequest>;
  // Предложения
  orderOffers: Array<OrderOffer>;
  // Выбранное предложение
  selectedOffer?: OrderOffer;
  // открыть форму разделения заявки
  onSplitClick: (partsToSplit: number) => void;
  // открыть поиск исполнителя
  onCarrierSearchClick: VoidFunction;
  // открыть выбор собственных ресурсов
  onChooseOwnDriverAndVehicle: VoidFunction;
  // перевести в статус поиск исполнителя
  onStartCarrierSearch?: VoidFunction;
  // открыть виджет выбрать исполнителя
  onClickSubmitOffer: (orderOffer: Pick<OrderOffer, 'carrier' | 'driver' | 'vehicle'>, offerId?: number) => void;
  // отменть заявку
  onCancelOrderClick?: VoidFunction;
  // отправить оффер на проверку
  onSendToCheck: (offer: Pick<OrderOffer, 'carrier' | 'driver' | 'vehicle'>) => void;
  // кнопка Подробнее - открыть виджет Информация о перевозчике
  onMoreInformation: (id: number) => void;
  // отключает кнопку Найти исполнителя
  disableSearchButton?: boolean;
  // скрыть все кнопки по управлению заявкой
  hideOrderActions?: boolean;
  // скрыть предложения
  hideOffers?: boolean;
  // скрыть все кнопки по управлению заявкой
  hideOrderLogistInfo?: boolean;
  // скрыть группу риска для заявки
  hideOrderRiskGroup?: boolean;
  handleUpdateOrder?: MouseEventHandler<HTMLButtonElement>;
  // Открыть редактирование заявки
  handleDeleteOrder?: MouseEventHandler<HTMLButtonElement>;
  // Открыть модальное окно для подтверждения удаления заявки
  onUpdateOrderFuelStatus?: (status?: FuelStatus) => void;
  // Копировать заявку
  onCloneOrder: (orderId: number) => void;
  isFuel?: boolean;
  fuelReadOnly?: boolean;
  // подтвердить получение оригиналов
  onConfirmOriginalsClick?: VoidFunction;
};
const mode = 'customer';
/**
 *Детали заявки -> Заказ клиента
 * * */
export const OrderDetailsClient: FC<Props> = ({
  order,
  onCarrierSearchClick,
  onChooseOwnDriverAndVehicle,
  onStartCarrierSearch,
  onSplitClick,
  orderOffers,
  selectedOffer,
  onClickSubmitOffer,
  onCancelOrderClick = emptyFunction,
  disableSearchButton = false,
  hideOrderActions = false,
  hideOffers = false,
  hideOrderLogistInfo = false,
  hideOrderRiskGroup = false,
  handleUpdateOrder = emptyFunction,
  handleDeleteOrder = emptyFunction,
  onSendToCheck = emptyFunction,
  onMoreInformation = emptyFunction,
  shipmentRequests = [],
  onUpdateOrderFuelStatus,
  isFuel = false,
  fuelReadOnly = false,
  onConfirmOriginalsClick = emptyFunction,
  onCloneOrder,
}) => {
  return (
    <UiTabs className="order-details__tabs" isLazy>
      <UiTabListMenuSelect size="lg" className="order-details__tablist">
        <UiTab>{orderClientTabs.ExecutionDetails}</UiTab>
        <UiTab>{orderClientTabs.Documents}</UiTab>
        <Divider orientation="vertical" className="order-details__separator" />
        <UiTab>{orderClientTabs.Services}</UiTab>
        <UiTab>{orderClientTabs.DocsAndPays}</UiTab>
      </UiTabListMenuSelect>

      <div className="order-details__tab-panels">
        <UiScrollbars autoHide style={scrollStyle}>
          <UiTabPanels className="order-details__tab-content">
            <UiTabPanel>
              <OrderDetailsClientExecution
                onClickSubmitOffer={onClickSubmitOffer}
                order={order}
                selectedOffer={selectedOffer}
                onCarrierSearchClick={onCarrierSearchClick}
                onChooseOwnDriverAndVehicle={onChooseOwnDriverAndVehicle}
                onStartCarrierSearch={onStartCarrierSearch}
                onSplitClick={onSplitClick}
                orderOffers={orderOffers}
                onCancelOrderClick={onCancelOrderClick}
                disableSearchButton={disableSearchButton}
                hideOrderActions={hideOrderActions}
                hideOrderLogistInfo={hideOrderLogistInfo}
                hideOffers={hideOffers}
                hideOrderRiskGroup={hideOrderRiskGroup}
                handleUpdateOrder={handleUpdateOrder}
                handleDeleteOrder={handleDeleteOrder}
                onCloneOrder={onCloneOrder}
                onSendToCheck={onSendToCheck}
                onMoreInformation={onMoreInformation}
                shipmentRequests={shipmentRequests}
                onUpdateOrderFuelStatus={onUpdateOrderFuelStatus}
                isFuel={isFuel}
                fuelReadOnly={fuelReadOnly}
              />
              <OrderTimelineView orderId={order.id} />
            </UiTabPanel>
            <UiTabPanel>
              <OrderDetailsDocuments
                order={order}
                companyName={order.customer?.name}
                fuelReadOnly={fuelReadOnly}
                showCustomerDocuments
                showConfirmCustomerOriginals={!hideOrderLogistInfo}
                onConfirmOriginalsClick={onConfirmOriginalsClick}
              />
              <OrderTimelineView orderId={order.id} />
            </UiTabPanel>
            <UiTabPanel>
              <OrderDetailsServiceView orderMode={mode} fuelReadOnly={fuelReadOnly} />
            </UiTabPanel>
            <UiTabPanel>
              <OrderDetailsPaymentView orderMode={mode} fuelReadOnly={fuelReadOnly} />
            </UiTabPanel>
          </UiTabPanels>
        </UiScrollbars>
      </div>
    </UiTabs>
  );
};
