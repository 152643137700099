import { FC } from 'react';
import { PageWrapper } from 'pages/page-wrapper';
import { Flex } from '@chakra-ui/react';
import { CurrentRoutesView } from '@/views/carriers-loads/current-routes/current-routes-view';

export const CurrentRoutesPage: FC = () => {
  return (
    <PageWrapper>
      <Flex>
        <section className="current-routes">
          <CurrentRoutesView />
        </section>
      </Flex>
    </PageWrapper>
  );
};
