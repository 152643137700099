import { NO_SETTINGS } from 'components/settings/settings-details-empty/texts';
import { useIcons } from '@/hooks/use-icons';
import './settings-details-empty.scss';

/*
 * Сайдбар, когда не выбран клиент в роли логиста
 * */
export const SettingsDetailsEmpty = () => {
  const { ClaimIcon64 } = useIcons();
  return (
    <div className="settings-details-empty">
      <div>
        <ClaimIcon64 />
        {NO_SETTINGS}
      </div>
    </div>
  );
};
