import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { WidgetType } from '@/features/widget/widget-type';
import { WidgetState, WidgetStateItem } from '@/features/widget/types/widget-state';

const initialState: WidgetState = {
  activeWidgets: {},
};
const getDeactivatedWidgets = (state: WidgetState) =>
  _.mapValues(state.activeWidgets, (w) => {
    return { ...w, isActive: false };
  }) || {};

const slice = createSlice({
  name: 'widgets',
  initialState,
  reducers: {
    showWidget: (state, action: PayloadAction<{ name: keyof WidgetType; data: any; containerKey?: string; title?: string }>) => {
      const { name, data, containerKey, title } = action.payload;
      state.activeWidgets = {
        ...getDeactivatedWidgets(state),
        [name]: {
          open: true,
          data,
          containerKey,
          title,
          isActive: true,
        },
      };
    },
    hideWidget: (state, action: PayloadAction<keyof WidgetType>) => {
      state.activeWidgets = { ...state.activeWidgets, [action.payload]: { open: false } };
    },
    // устанваливает у виджета isActive в true, а у остальных виджетов в false
    setActive: (state, action: PayloadAction<keyof WidgetType>) => {
      state.activeWidgets = {
        ...getDeactivatedWidgets(state),
        [action.payload]: {
          ...state.activeWidgets[action.payload],
          isActive: true,
        },
      };
    },
    closeAllWidgets: (state, action: PayloadAction<{ containerKey?: string }>) => {
      const { containerKey } = action.payload;
      state.activeWidgets = Object.keys(state.activeWidgets).reduce((acc, curr) => {
        const widgetName = curr as any as keyof WidgetType;
        if (state.activeWidgets[widgetName]?.containerKey !== containerKey) {
          acc[widgetName] = state.activeWidgets[widgetName];
        }
        return acc;
      }, {} as { [name in keyof WidgetType]?: WidgetStateItem });
    },
  },
});

export const widgetReducers = slice.reducer;
export const widgetActions = slice.actions;
