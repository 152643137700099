import { useCallback } from 'react';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { BookerOrderSwitcherStatus } from '@/config/constants/statuses';
import { bookerOrdersActions } from '@/features/booker/slice';

export const useBookerOrderActions = () => {
  const dispatch = useAppDispatch();

  /**
   * Select booker order
   * @param bookerOrderId
   */
  const select = useCallback(
    (bookerOrderId: number) => {
      dispatch(bookerOrdersActions.select(bookerOrderId));
    },
    [dispatch]
  );

  /**
   * Unselect booker order
   * @param bookerOrderId
   */
  const unselect = useCallback(() => {
    dispatch(bookerOrdersActions.unselect());
  }, [dispatch]);

  /**
   * Select booker order tab (выбор таба в списке заявок: На оформлении, Завершенные и тд)
   * @param tab
   */
  const selectTab = useCallback(
    (tab: BookerOrderSwitcherStatus) => {
      dispatch(bookerOrdersActions.selectTab(tab));
    },
    [dispatch]
  );

  return {
    select,
    unselect,
    selectTab,
  };
};
