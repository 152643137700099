import { FC } from 'react';
import { Box } from '@chakra-ui/react';
import './carriers-loads-list.scss';
import { Order } from '@/types/models/order';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { InfiniteScroll } from '@/views/infinite-scroll/infinite-scroll';

import { CarrierLoadsCard } from '../card/carriers-loads-card';

type Props = {
  orderList: Array<Order> | undefined;
  selectedOrderId?: number | undefined;
  loadMore: (...args: any) => void;
  loading: boolean;
  withStatus?: boolean;
  handleOrderClick: (id: number) => void;
  handleRequestOrder?: (order: Order) => void;

  requestButtonProps?: {
    label?: string;
    disable?: boolean;
  };
};

export const CarriersLoadsList: FC<Props> = ({
  orderList,
  selectedOrderId,
  loadMore,
  handleOrderClick,
  loading,
  handleRequestOrder,
  withStatus = false,
  requestButtonProps,
}) => {
  return (
    <Box className="carriers-loads-list">
      <UiScrollbars autoHide style={{ width: '100%', height: '100%' }}>
        <Box className="carriers-loads-list__content">
          <InfiniteScroll callback={loadMore} loading={loading} skip={!orderList || orderList.length <= 1}>
            {orderList?.map((load) => (
              <CarrierLoadsCard
                withStatus={withStatus}
                selected={selectedOrderId === load.id}
                handleSelectLoad={handleOrderClick}
                load={load}
                key={`${load.id}`}
                handleRequestOrder={handleRequestOrder}
                requestButtonProps={requestButtonProps}
              />
            ))}
          </InfiniteScroll>
        </Box>
      </UiScrollbars>
    </Box>
  );
};
