import { FC } from 'react';
import './completed-route-details.scss';
import { DetailsComponent } from 'components/carriers-loads/details/details-component/details-component';
import { Load } from '@/types/models/loads';
import { Order } from '@/types/models/order';
import { useIcons } from '@/hooks/use-icons';

interface IProps {
  order: Order | Load;
}

export const CompletedRouteDetails: FC<IProps> = ({ order }) => {
  const { Completed } = useIcons();
  return (
    <div className="completed-route-details">
      <div className="completed-route-details__completed">
        <Completed />
        Рейс №{order?.id} <br />
        <strong>был выполнен</strong>
      </div>

      <DetailsComponent order={order} />
    </div>
  );
};
