import { MenuList, MenuListProps } from '@chakra-ui/react';
import { FC } from 'react';

export const UiDropdownMenuList: FC<MenuListProps> = ({ children, ...props }) => (
  <div>
    <MenuList className="ui-dropdown__list" {...props}>
      {children}
    </MenuList>
  </div>
);
