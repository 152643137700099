import { Box } from '@chakra-ui/react';
import { CarriersVerificationFilter } from '@/components';
import { VerificationPageTabLabels } from '@/features/approvals/constants/verification-page-tabs';
import { useCarriersVerificationView } from '@/views/carriers-verification/use-carriers-verification-view';
import './carriers-verification-view.scss';
import { CarriersVerificationMetrics } from '@/types/models/metrics';

type Props = {
  setChecking: (arg0: boolean) => void;
};

export const CarriersVerificationView = ({ setChecking }: Props) => {
  const { value, setValue, activeTab, onChangeActiveTab, isFetchingNextPage, fetchNextPage, isFetching, allRecords, metrics } =
    useCarriersVerificationView();
  return (
    <Box className="carriers-verification-view">
      <CarriersVerificationFilter
        tabLabels={VerificationPageTabLabels}
        activeTab={activeTab}
        onChangeActiveTab={onChangeActiveTab}
        inputProps={{
          value,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value),
        }}
        approvals={allRecords}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        loading={isFetching}
        metrics={metrics?.data?.data as CarriersVerificationMetrics}
        setChecking={setChecking}
      />
    </Box>
  );
};
