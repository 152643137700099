import { Dictionary } from 'config/constants/dictionary';
import { SettingsFormValues } from 'components/settings/types';
import { ApiGet, ApiPost } from '@/helpers/api/methods';
import { SettingsBase, SettingsTemplateResponse } from '@/types/api/settings-partial';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';

export const SettingsApi = {
  getSettingsTemplates: () => ApiGet<ResponseWithoutPagerSimple<SettingsTemplateResponse>>(`${Dictionary.Settings}/templates`), // возвращает список шаблонов без разбивки для селекта
  addSettings: (options: SettingsFormValues) => ApiPost<ResponseWithoutPagerSimple<SettingsBase>>(`${Dictionary.Settings}`, options),
  getAllSettings: () => ApiGet<ResponseWithoutPagerSimple<SettingsBase>>(`${Dictionary.Settings}`),
};
