import { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { VerificationFormMode } from 'components/verification-forms/types';
import { useDriverDocsSubform } from 'components/verification-forms/hooks/use-driver-docs-subform';
import { UiFileUploadField, UiFileUploadInput } from '@/ui-elements';

export const DriverDocumentsSubform: FC<{ mode: VerificationFormMode; driverId: number }> = ({ mode, driverId }) => {
  const { documentsFields, isCheck, isFileUploadField } = useDriverDocsSubform({ mode, driverId });
  return (
    <>
      <Box mt={1}>
        <Text className="carriers-verification-form__subtitle">Документы</Text>
      </Box>
      {documentsFields.map(({ label, name, actions }) => {
        return (
          <Box mt={7}>
            <Text mt={3} mb={2} children={label} />
            {isFileUploadField ? <UiFileUploadField name={name} /> : <UiFileUploadInput name={name} disabled={isCheck} {...actions} />}
          </Box>
        );
      })}
    </>
  );
};
