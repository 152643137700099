import React, { FC } from 'react';
import { BookerSearch } from 'components/booker-orders/booker-orders-filters/type';
import { FormSpy } from 'react-final-form';
import { DEBT_MASK } from 'components/auth-form/constants';
import { useForm } from '@/helpers/forms/use-form';
import { UiMaskedField } from '@/ui-elements';
import { emptyFunction } from '@/helpers/empty-function';
import { BookerFilterFields } from '@/features/booker/hooks/booker-filter';
import { FormAutosave } from '@/hooks/use-autosave';

/*
 * Учет оплаты рейсов search
 * */

type Props = {
  onSubmit?: () => void;
  handleFilterChange?: (values: BookerFilterFields) => void;
};
export const BookerOrdersSearchDebt: FC<Props> = ({ onSubmit = emptyFunction, handleFilterChange = emptyFunction }) => {
  const Form = useForm<BookerSearch>();
  return (
    <Form onSubmit={onSubmit} className="booker-search">
      <FormSpy subscription={{ values: true }}>{({ values }) => <FormAutosave values={values} onSave={handleFilterChange} />}</FormSpy>
      <UiMaskedField name="customerDebtOverload" mask={DEBT_MASK} placeholder="Календ. дней задолженности" />
    </Form>
  );
};
