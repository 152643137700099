import { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { PageWrapper } from 'pages/page-wrapper';

export const PublicOfferAgreement: FC = () => {
  return (
    <PageWrapper>
      <Flex />
    </PageWrapper>
  );
};
