export const TOTAL_DOCS = 'Всего подтверждающих документов';
export const SENT_TO_CLIENT = 'Отправленно клиенту';

export const ORDER_PLACED = 'Заявка размещена';

export const CONFIRM_EXECUTION = 'Подтвердить исполнение';

export const ORDER_COMPLETED = 'Рейс завершен';

export const CONFIRM_ORIGINALS = 'Оригиналы получены';

export const CONFIRMATION_FILES = 'Подтверждающие документы';

export const DOCUMENTS = 'Документы';
export const NEW_FILES = 'Новые загруженные Исполнителем';

export const DOCUMENT_ORDER = 'Документ заявка';

export const SAVE_DOCS = 'Скачать документы';
export const CONFIRM_DOCS = 'Подтвердить документы';
export const CANCEL_DOCS = 'Отменить подтверждение';
export const CONFIRMED_DOCS = 'Документы подтверждены';
export const DECLINE_DOCS = 'Отклонить';

export const SEND_TO_CLIENT = 'Отправить клиенту';
export const SEND_TO_CARRIER = 'Отправить исполнителю';

export const NEW_UPLOADED = 'Новые загруженные исполнителем';
export const REJECT_DOCUMENTS = 'Отклонить';
export const CONFIRM_DOCUMENTS = 'Подтвердить документы';
export const ACCEPT_DOCUMENTS = 'Принять документы';
