import { useEffect } from 'react';
import { emptyFunction } from '@/helpers/empty-function';

export const useWindowResize = (props: { callback: () => void; condition?: () => boolean; timeout?: number }) => {
  const { callback, condition = () => true, timeout = 250 } = props;

  useEffect(() => {
    if (condition()) {
      let timeoutId: NodeJS.Timeout | null = null;
      const resizeListener = () => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(callback, timeout);
      };
      window.addEventListener('resize', resizeListener);

      return () => {
        window.removeEventListener('resize', resizeListener);
        if (timeoutId) clearTimeout(timeoutId);
      };
    }
    return emptyFunction;
  }, [condition, callback, timeout]);
};
