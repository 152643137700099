import { FC, ReactNode, SyntheticEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@chakra-ui/react';
import './ui-link.scss';
import classNames from 'classnames';

type Props = { href: string; children: ReactNode; isExternal?: boolean; isActive?: boolean; className?: string };
export const UiLink: FC<Props> = ({ href, children, isExternal, isActive = false, className = '' }) => {
  const cssClasses = classNames(className, 'ui-link', { 'ui-link_active': isActive });
  const onClick = (e: SyntheticEvent) => {
    if (isActive) e.preventDefault();
  };

  return (
    <Link as={RouterLink} className={cssClasses} to={href} isExternal={isExternal} onClick={onClick}>
      {children}
    </Link>
  );
};
