import { OrderType } from 'config/constants/types';
import { RadioButtons, RadioButtonsConfigType } from '@/types/common/radio-buttons';

export const RadioButtonsConfig: RadioButtonsConfigType = {
  [RadioButtons.SplitCount]: {
    2: 'На 2 заявки',
    3: 'На 3 заявки',
    4: 'На 4 заявки',
  },
  [RadioButtons.RouteType]: {
    [OrderType.Roundtrip]: 'Закольцовка',
    [OrderType.Simple]: 'Только туда',
  },
  [RadioButtons.LoadingType]: {
    // todo поменять ключи на настоящие значения для бэка
    loading: 'Погрузка',
    unloading: 'Разгрузка',
    loadingNUnloading: 'Разгрузка и погрузка',
  },
};
