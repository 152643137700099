import { FC, memo } from 'react';
import { Flex, Grid, GridItem, HStack } from '@chakra-ui/react';
import { FormSpy } from 'react-final-form';
import {
  BRANCH,
  CARRIER_FILTERS,
  CONTENT_TYPE_TS,
  DIRECTION,
  FREEDOM,
  FROM,
  GROUP_RISK,
  GROUP_RISK_PLACEHOLDER,
  RADIUS,
  RADIUS_PLACEHOLDER,
  TYPE_TC_2,
  VOLUME,
  WEIGHT,
  WHERE,
} from 'components/search-carrier/search-carrier-filter/texts';
import { CarrierSearchFilter } from 'components/search-carrier/search-carrier-filter/type';
import { radiusSearch, riskGroup } from 'components/search-carrier/search-carrier-filter/constants';
import { UiAddressSearchField, UiCheckboxField, UiInputField, UiNumberPickerField, UiSelectField } from '@/ui-elements';
import { useForm } from '@/helpers/forms/use-form';
import { emptyFunction } from '@/helpers/empty-function';
import { useIcons } from '@/hooks/use-icons';
import { UiToggleField } from '@/ui-elements/form-fields/ui-toggle-field/ui-toggle-field';
import { FormAutosave } from '@/hooks/use-autosave';
import { UiVehicleTypesPickerField } from '@/ui-elements/form-fields/ui-vehicle-type-picker-field/ui-vehicle-type-picker-field';

type Props = {
  onSubmit?: (searchOptions: CarrierSearchFilter) => void;
};
const SearchCarrierFilterForm: FC<Props> = ({ onSubmit = emptyFunction }) => {
  const Form = useForm<CarrierSearchFilter>();
  const { Vector } = useIcons();
  return (
    <Form
      initialValues={{
        typeTC: [],
        weight: '',
        volume: '',
        riskGroup: undefined,
        where: '',
        from: '',
        radius: undefined,
      }}
      onSubmit={emptyFunction}
      className="search-carrier-filter__form-area"
    >
      <FormSpy subscription={{ values: true }}>{({ values }) => <FormAutosave values={values} onSave={onSubmit} />}</FormSpy>
      <Grid templateColumns="repeat(3, 1fr)" gap="16px">
        <GridItem colSpan={3}>
          {/* <UiSelectField
            isMulti
            name="typeTC"
            label={TYPE_TC_2}
            options={vehicleTypesSelectOptions}
            content={CONTENT_TYPE_TS}
            overflown
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            blurInputOnSelect={false}
            className="search-carrier-filter__form-area__vehicle"
            components={{
              Menu: CustomMenu,
              Option: CustomOption,
              ValueContainer: CustomValueContainer,
              MultiValueRemove: CustomCloseButton,
            }}
          /> */}
          <UiVehicleTypesPickerField label={TYPE_TC_2} name="typeTC" content={CONTENT_TYPE_TS} />
        </GridItem>
        <GridItem>
          <UiInputField name="search" label="По наименованию" />
        </GridItem>
        <GridItem>
          <UiNumberPickerField name="weight" label={WEIGHT} placeholder="0" />
        </GridItem>
        <GridItem>
          <UiNumberPickerField name="volume" label={VOLUME} placeholder="0" />
        </GridItem>
        <GridItem colSpan={2}>
          <HStack alignItems="flex-end" className="search-carrier-filter__form-area__route">
            <UiAddressSearchField placeholder={DIRECTION} name="from" label={FROM} overflown />
            <Vector />
            <UiAddressSearchField placeholder={DIRECTION} name="where" label={WHERE} overflown />
          </HStack>
        </GridItem>
        <GridItem>
          <UiSelectField name="radius" label={RADIUS} options={radiusSearch} placeholder={RADIUS_PLACEHOLDER} overflown />
        </GridItem>
        <GridItem>
          <UiSelectField name="riskGroup" label={GROUP_RISK} options={riskGroup} placeholder={GROUP_RISK_PLACEHOLDER} />
        </GridItem>
      </Grid>
      <Flex className="search-carrier-filter-checkboxes" gap="12px">
        <UiToggleField name="isfree" label={FREEDOM} />
        <UiCheckboxField name="filial" label={BRANCH} />
        <UiCheckboxField name="similarFilters" label={CARRIER_FILTERS} />
      </Flex>
    </Form>
  );
};

export default memo(SearchCarrierFilterForm);
