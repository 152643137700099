export const MOMENT_24_HOUR_TIME = 'HH:mm';
export const MOMENT_DD_MM_YY = 'DD.MM.YY';
export const MOMENT_DD_MM_YYYY = 'DD.MM.YYYY';

export const MOMENT_L = 'L';
export const MOMENT_LT = 'LT';
export const MOMENT_L_LT = 'L LT';

export const MOMENT_DATEPICKER = 'YYYY/MM/DD';
export const MOMENT_DATETIMEPICKER = 'YYYY/MM/DD HH:mm';
export const MOMENT_NO_TIMEZONE = 'YYYY/MM/DD HH:mm:ss';
