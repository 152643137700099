import { isFunction } from 'lodash';
import { ModelExtender } from '@/types/model-extender';

export const useExtendedObject = <T, ExT extends T>(model: T, extender: ModelExtender<T, ExT>) =>
  Object.entries(extender).reduce(
    (extendedModel, [name, adapter]) => ({
      ...extendedModel,
      [name]: isFunction(adapter) ? (adapter as (model: T) => never)(model) : adapter,
    }),
    model
  ) as ExT;
