import './ui-under-construct.scss';
import { useIcons } from '@/hooks/use-icons';

export const UiUnderConstruct = () => {
  const { ConstructIcon } = useIcons();
  return (
    <div className="ui-under-construct">
      <ConstructIcon />
      <span>Раздел в разработке</span>
    </div>
  );
};
