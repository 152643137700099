import { FC } from 'react';
import { VStack } from '@chakra-ui/react';
import { Order } from '@/types/models/order';
import { CarrierLoadDetails } from '../load-details';

interface Props {
  load?: Order;
}

export const CarrierLoadDetailsComponent: FC<Props> = ({ load }) => {
  if (!load) return null;
  return (
    <VStack paddingY={6} alignItems="stretch">
      <CarrierLoadDetails load={load} />
    </VStack>
  );
};
