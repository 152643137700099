import { FC } from 'react';
import { VStack } from '@chakra-ui/react';
import { ORDER_PLACED } from 'components/order-details/order-details-documents/texts';
import { COLON } from 'config/constants/common-strings';
import { OrderStatusColorScheme } from 'config/constants/order-status-colors';
import { UiStatus } from '@/ui-elements/status/status';
import { Order } from '@/types/models/order';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { orderExtender } from '@/features/order/extender/order-extender';
import './order-details-status.scss';
import { useAppSelector } from '@/hooks/use-app-selector';
import { orderStatusesSelector } from '@/features/order/selectors/order-statuses-selector';
import { OrderStatus } from '@/config/constants/statuses';
import { Load } from '@/types/models/loads';

/*
 * Компонент со статусом заявки: отображается дата создания и текущий статус
 * */
export const OrderDetailsStatus: FC<{ order: Order | Load }> = ({ order }) => {
  const orderStatuses = useAppSelector(orderStatusesSelector);
  const statusText = orderStatuses?.orderStatuses?.[order.status as OrderStatus];

  const { status, createdFormatted } = useExtendedObject(order, orderExtender);
  return (
    <VStack className="order-details-status">
      <span className="order-details-status__placed">{`${ORDER_PLACED}${COLON} ${createdFormatted}`}</span>
      <UiStatus text={statusText || ''} backGroundColor={OrderStatusColorScheme[status]?.background} color={OrderStatusColorScheme[status]?.color} />
    </VStack>
  );
};
