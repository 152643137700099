import './funnel-execution-details-with-map.scss';
import { FC, useState } from 'react';
import classNames from 'classnames';
import { VStack, HStack } from '@chakra-ui/react';
import { loadExtender } from '@/features/loads/extender/load-extender';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { useIcons } from '@/hooks/use-icons';
import { Load } from '@/types/models/loads';
import { UiDivider } from '@/ui-elements';
import { GENERAL_INFO, SHOW_ON_MAP } from '@/components/order-details/order-details-client-execution/text';
import { OrderGeneralDistance } from '@/components/order-general-distance/order-general-distance';
import { OrderGeneralInfo } from '@/components/order-general-info/order-general-info';
import { RouteMap } from '@/components/route-map/route-map';

interface Props {
  load: Load;
}

export const FunnelExecutionDetailsWithMap: FC<Props> = ({ load }) => {
  const [routeMode, setRouteMode] = useState(0);

  const { distance, type, shippedTotals } = useExtendedObject(load, loadExtender);
  const { InfoIcon, MapPinFill } = useIcons();
  return (
    <VStack gap="6" alignItems="stretch">
      <HStack justifyContent="space-between">
        <OrderGeneralDistance range={distance} type={type} />
        <HStack>
          <HStack className={classNames({ 'funnel-execution-details-with-map__general': routeMode === 0 })}>
            <InfoIcon />
            <button
              type="button"
              className={classNames({ 'funnel-execution-details-with-map__general-text': routeMode === 0 })}
              onClick={() => setRouteMode(0)}
            >
              {GENERAL_INFO}
            </button>
          </HStack>
          <UiDivider orientation="vertical" variant="dark" />
          <HStack className={classNames({ 'funnel-execution-details-with-map__map': routeMode === 1 })}>
            <MapPinFill />
            <button
              className={classNames({ 'funnel-execution-details-with-map__map-text': routeMode === 1 })}
              type="button"
              onClick={() => setRouteMode(1)}
            >
              {SHOW_ON_MAP}
            </button>
          </HStack>
        </HStack>
      </HStack>
      {routeMode === 0 ? (
        <OrderGeneralInfo vehicleTypes={load?.vehicleTypes} shippedTotals={shippedTotals} loadingType={load?.loadingType || []} />
      ) : (
        <RouteMap order={load} />
      )}
    </VStack>
  );
};
