import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { RequestCarrierButton } from '@/components/carrier-card/request-carrier-button';
import { CarrierCardRegNumber } from '@/components/carrier-card/carrier-card-reg-number';
import { FONT_SIZE_CONTENT_14, PADDING } from '@/components/carrier-card/style-constants';
import { VehicleGeneralInfo } from '@/components/vehicle-general-info/vehicle-general-info';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { Vehicle } from '@/types/models/vehicle';
import { vehicleExtender } from '@/features/vehicles/extender/vehicle-extender';
import { getApprovalStatusCarrier } from '@/helpers/get-approval-status-carrier';

type Props = {
  isVatMatch?: boolean;
  vehicle: Vehicle;
  addCarriersRequest: VoidFunction;
  readOnly?: boolean;
};

export const CarrierCardBottom: FC<Props> = ({ vehicle, addCarriersRequest, readOnly, isVatMatch }) => {
  const { vehicleRegNumber, vehicleRegion } = useExtendedObject(vehicle, vehicleExtender);

  return (
    <Flex p={PADDING} w="100%" justifyContent="space-between" className="carrier-card__bottom">
      <Flex gap="12px" fontSize={FONT_SIZE_CONTENT_14}>
        {vehicleRegNumber && <CarrierCardRegNumber regNumber={vehicleRegNumber} region={vehicleRegion} />}
        {vehicle.trailers?.map((trailer) => {
          const trailerRegion = vehicleExtender.vehicleRegion(trailer as unknown as Vehicle);
          const trailerRegNumber = vehicleExtender.vehicleRegNumber(trailer as unknown as Vehicle);
          return <CarrierCardRegNumber key={trailerRegNumber} regNumber={trailerRegNumber || EMPTY_STRING} region={trailerRegion || EMPTY_STRING} />;
        })}
        {vehicle && (
          <VehicleGeneralInfo
            loadingType={vehicle.loadingType}
            capacity={vehicle.capacity}
            volume={vehicle.volume}
            vehicleTypes={[vehicle.vehicleType, ...(vehicle.trailers?.map((x) => x.vehicleType || 0) || [])]}
          />
        )}
        {vehicle?.approvalStatus && <Text className="carrier-card__bottom-approved">{getApprovalStatusCarrier(vehicle.approvalStatus)}</Text>}
        <RequestCarrierButton
          addCarriersRequest={addCarriersRequest}
          request={!!vehicle.request}
          show={!readOnly}
          isDisabled={!isVatMatch}
          hasOffer={!!vehicle.hasOffer}
        />
      </Flex>
    </Flex>
  );
};
