export enum LoadingType {
  up = 'верхняя',
  back = 'задняя',
  side = 'боковая',
  fullShading = 'с полной растентовкой',
  crossBarsRemoved = 'со снятием поперечных перекладин',
  removalOfRacks = 'со снятием стоек',
  noGate = 'без ворот',
  tailLift = 'гидроборт',
  ramps = 'аппарели',
  withCrate = 'с обрешеткой',
  withSides = 'с бортами',
  from2Sides = 'боковая с 2-х сторон',
}

export const loadingTypes = [
  {
    value: LoadingType.up,
    label: 'верхняя',
  },
  {
    value: LoadingType.side,
    label: 'боковая',
  },
  {
    value: LoadingType.back,
    label: 'задняя',
  },
  {
    value: LoadingType.fullShading,
    label: 'с полной растентовкой',
  },
  {
    value: LoadingType.crossBarsRemoved,
    label: 'со снятием поперечных перекладин',
  },
  {
    value: LoadingType.removalOfRacks,
    label: 'со снятием стоек',
  },
  {
    value: LoadingType.noGate,
    label: 'без ворот',
  },
  {
    value: LoadingType.tailLift,
    label: 'гидроборт',
  },
  {
    value: LoadingType.ramps,
    label: 'аппарели',
  },
  {
    value: LoadingType.withCrate,
    label: 'с обрешеткой',
  },
  {
    value: LoadingType.withSides,
    label: 'с бортами',
  },
  {
    value: LoadingType.from2Sides,
    label: 'боковая с 2-х сторон',
  },
];
