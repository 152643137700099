import './order-details-payment.scss';
import classNames from 'classnames';
import { SAVE_DOCS } from 'components/order-details/order-details-documents/texts';
import { FC, ReactElement } from 'react';
import { HStack, VStack } from '@chakra-ui/react';
import { PAYMENT_DOCUMENTS } from 'components/order-details/order-details-payment/texts';
import { COLON } from 'config/constants/common-strings';
import { OrderDetailsStatus } from 'components/order-details';
import { Order } from '@/types/models/order';
import { emptyFunction } from '@/helpers/empty-function';
import { UiButtonOutlined, UiFileUploadInput } from '@/ui-elements';
import { UiToggle } from '@/ui-elements/ui-toggle/ui-toggle';
import { FileDocument } from '@/types/models/document';
import { useFiles } from '@/hooks/use-files';
import { Load } from '@/types/models/loads';

export type LabelType = {
  key?: string;
  label: string;
  value?: { className?: string; text: string | ReactElement };
  status?: { className?: string; text: string };
  hasToggler?: boolean;
  toggleValue?: boolean;
  onToggle?: VoidFunction;
};

type Props = {
  order: Order | Load;

  attachments?: Array<FileDocument>;

  onSaveDocumentsClick?: VoidFunction;
  // загрузить документ оплаты в заявку
  onUploadPaymentDocuments?: (files: Array<File>) => void;

  // удалить документ оплаты из заявки
  onDeletePaymentDocumentClick?: (documentId: number) => void;

  // показать переключатели оплаты
  showPaymentsToggler?: boolean;
  fuelReadOnly?: boolean;

  //
  properties: LabelType[];
  header: string;
};

export const OrderDetailsPayment: FC<Props> = ({
  order,
  fuelReadOnly = false,
  onSaveDocumentsClick = emptyFunction,
  onUploadPaymentDocuments = emptyFunction,
  onDeletePaymentDocumentClick = emptyFunction,
  showPaymentsToggler = false,
  attachments = [],
  properties,
  header,
}) => {
  const { onFilesChange } = useFiles();

  return (
    <div className="order-details-payment">
      <HStack className="order-details-payment__body">
        <VStack className="order-details-payment__info">
          <span className="order-details-payment__header">{header}</span>
          {properties.map((prop) => (
            <HStack key={prop.key || prop.label} className="order-details-payment__properties">
              <span className={classNames('order-details-payment__label')}>
                {prop.label}
                {COLON}
              </span>

              <div className={classNames('order-details-payment__values')}>
                {prop.value && (
                  <div className={classNames('order-details-payment__value', `order-details-payment__${prop.value?.className}`)}>
                    {prop.value?.text}
                  </div>
                )}
                {prop.hasToggler && showPaymentsToggler && <UiToggle disabled={fuelReadOnly} onChange={prop.onToggle} checked={prop.toggleValue} />}
                {prop.status && (
                  <div className={classNames('order-details-payment__status', `order-details-payment__${prop.status?.className}`)}>
                    {prop.status?.text}
                  </div>
                )}
              </div>
            </HStack>
          ))}
        </VStack>
        <OrderDetailsStatus order={order} />
      </HStack>
      <div className="order-details-payment__form">
        <div className="order-details-payment__form-title"> {PAYMENT_DOCUMENTS}</div>
        <UiFileUploadInput
          onChange={onFilesChange}
          name="file"
          savedFileList={attachments}
          onDeleteFileClick={onDeletePaymentDocumentClick}
          onImmediateUploadFile={onUploadPaymentDocuments}
          disabled={fuelReadOnly}
          disableUpload={fuelReadOnly}
        />
      </div>
      <HStack className="order-details-payment__actions">
        <UiButtonOutlined disabled={!attachments?.length} onClick={onSaveDocumentsClick}>
          {SAVE_DOCS}
        </UiButtonOutlined>
      </HStack>
    </div>
  );
};
