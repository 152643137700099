import { useQuery } from '@tanstack/react-query';
import { BookerOrdersApi } from '@/api/booker-orders';
import { BookerOrderCountContractor } from '@/features/booker/types/booker-order-count';

export const useBookerOrdersCount = (query: BookerOrderCountContractor) => {
  const bookerOrdersCount = useQuery({
    queryKey: ['bookerOrdersCount', query],
    refetchOnWindowFocus: false,
    queryFn: () => BookerOrdersApi.getBookerOrdersCount(query),
  });
  return { bookerOrdersCount };
};
