import { useMutation, useQuery } from '@tanstack/react-query';
import { MyOrganizationsApi } from '@/api/my-organizations';
import { MyOrganizationTypeWithBankDetails } from '@/types/models/my-organization/my-organization';
import { VerificationStatus } from '@/config/constants/statuses';
import { queryClient } from '@/config/query-client';

export const useMyOrganizationData = () => {
  const myOrganizationData = useQuery({
    queryKey: ['myOrganizationData'],
    refetchOnWindowFocus: false,
    queryFn: () => MyOrganizationsApi.getAllOrganizations(),
  });
  const currentOrganization = myOrganizationData?.data?.data?.[0];

  const { mutate: createOrganization } = useMutation({
    mutationKey: ['postOrganizationData'],
    mutationFn: (data: Partial<MyOrganizationTypeWithBankDetails>) => MyOrganizationsApi.addOrganization(data),
  });

  const { mutate: updateOrganization } = useMutation({
    mutationKey: ['updateOrganizationData'],
    mutationFn: (data: { id: number; params: Partial<MyOrganizationTypeWithBankDetails> }) =>
      MyOrganizationsApi.updateOrganization(data.id, data.params),
    onSuccess: () => queryClient.invalidateQueries(['myOrganizationData']),
  });

  const approvalStatuses = currentOrganization?.approvalStatuses ?? ([] as Array<VerificationStatus>);

  return {
    myOrganizationData: currentOrganization,
    approvalStatuses,
    updateOrganization,
    organizationID: currentOrganization?.id,
    createOrganization,
  };
};
