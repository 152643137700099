export enum QueryKeys {
  offers = 'offers',
  allOrders = 'allOrders',
  ordersCount = 'ordersCount',
  allManagers = 'allManagers',
  updateCustomer = 'updateCustomer',
  allCarriers = 'allCarriers',
  allParticipatableCarriersLoads = 'allParticipatableCarriersLoads',
  allVehicles = 'allVehicles',
  notifications = 'notifications',
  notificationsCounter = 'notificationsCounter',
  bookerOrdersCount = 'bookerOrdersCount',
  loads = 'loads',
  carriersLoads = 'carriersLoads',
  loadsCount = 'loadsCount',
  order = 'order',
  allDrivers = 'allDrivers',
  driver = 'driver',
  vehicle = 'vehicle',
  addUser = 'addUser',
  resetDriverPassword = 'resetDriverPassword',
  roadTrainsEnum = 'roadTrainsEnum',
  driversEnum = 'driversEnum',
  settingsTemplates = 'settingsTemplates',
  allOrganizations = 'allOrganizations',
}

export enum MutationKeys {
  setNotificationMarked = 'setNotificationMarked',
  setAllNotificationMarked = 'setAllNotificationMarked',
}
