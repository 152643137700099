import { FC } from 'react';
import { FormMode, FormValues, VerificationProps } from 'components/verification-forms/types';
import { Box } from '@chakra-ui/react';
import { VehicleFormValues } from 'components/verification-forms/schema';
import { loadOptions } from 'components/create-order-form/subforms/form-configs';
import {
  DriverOptionsSubform,
  TrailerGroupBlock,
  VehicleButtonsSubform,
  VehicleDocsSubform,
  VehicleHeaderSubform,
  VehicleOptionsSubform,
  VehicleOwnTypeSubform,
  VehicleParamsSubform,
  VehicleRentDocSubform,
  VehicleVolumeSubform,
} from 'components/verification-forms/vehicle-verification/subforms';
import { ADD_VEHICLE_FOR_ORGANIZATION_CONTENT } from 'components/verification-forms/constants';
import { useForm } from '@/helpers/forms/use-form';
import '../verification-form.scss';
import { UiCheckboxField, UiSelectField, UiTextarea } from '@/ui-elements';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { UiCheckbox } from '@/ui-elements/checkbox/checkbox';
import { useVehicleVerificationForm } from './use-vehicle-verification';
// import { useVehicleTypesSortedSelector } from '@/features/vehicle-types/hooks/use-vehicle-types-sorted-selector';

export const VehicleVerificationForm: FC<VerificationProps<FormValues, VehicleFormValues>> = ({
  // onApprovalApproved,
  // onApprovalDeclined,
  onCancelCreation,
  onCancel,
  onClose,
  mode = FormMode.create,
  vehicleId,
  title = ADD_VEHICLE_FOR_ORGANIZATION_CONTENT,
  initialValues = {} as FormValues,
}) => {
  const Form = useForm<FormValues>();

  const {
    handleFormSubmit,
    validate,
    vehicleGroup,
    handleVehicleType,
    withoutTrailer,
    setWithoutTrailer,
    ownType,
    setOwnType,
    comment,
    setComment,
    handleFormCancel,
    submitting,
  } = useVehicleVerificationForm(mode, onCancelCreation, onClose, onCancel, vehicleId, initialValues);

  return (
    <Form onSubmit={handleFormSubmit} initialValues={initialValues} validate={validate}>
      <VehicleHeaderSubform mode={mode} onClose={onClose} title={title} />

      <div className="carriers-verification-form__body">
        <UiScrollbars autoHide>
          <div className="carriers-verification-form__main">
            <VehicleOptionsSubform mode={mode} initialValues={initialValues} vehicleGroup={vehicleGroup} handleVehicleType={handleVehicleType} />
            <DriverOptionsSubform mode={mode} initialValues={initialValues} />

            {vehicleGroup === 2 && (
              <Box mt={-1} mb={3}>
                <UiCheckbox
                  defaultChecked={withoutTrailer}
                  label="Без полуприцепа"
                  onChange={() => setWithoutTrailer((val) => !val)}
                  isDisabled={mode === 'check'}
                />
              </Box>
            )}

            <VehicleParamsSubform mode={mode} />

            {withoutTrailer && <VehicleVolumeSubform mode={mode} />}

            <Box mt={3} mb={3}>
              <UiSelectField
                options={loadOptions}
                name="loadingType"
                label="Тип Загрузки/Погрузки(можно выбрать несколько)"
                placeholder="Выберите вариант"
                isMulti
              />
            </Box>

            <Box mt={-1}>
              <UiCheckboxField
                defaultChecked={initialValues.accidentsInPastThreeYears}
                name="accidentsInPastThreeYears"
                label="Участвовал в ДТП за последние 3 года"
                isDisabled={mode === 'check'}
              />
            </Box>

            {!withoutTrailer && vehicleGroup !== 0 && <TrailerGroupBlock security={mode === 'check'} />}

            <VehicleOwnTypeSubform mode={mode} initialValues={initialValues} ownType={ownType} setOwnType={setOwnType} />

            <VehicleRentDocSubform vehicleId={vehicleId!} mode={mode} />

            {mode === 'check' && (
              <Box mt={7} mb={6}>
                <UiTextarea
                  className="carriers-verification-form__comment"
                  label="Комментарии"
                  variant="noResize"
                  placeholder="Введите текст"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </Box>
            )}

            <VehicleDocsSubform vehicleId={vehicleId!} mode={mode} />
          </div>
        </UiScrollbars>
      </div>

      <VehicleButtonsSubform mode={mode} handleFormCancel={handleFormCancel} submitting={submitting} />
    </Form>
  );
};
