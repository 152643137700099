import moment from 'moment';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { OrderExecutionStatus, OrderStatus } from 'config/constants/statuses';
import { useQueryClient } from '@tanstack/react-query';
import { orderStatusMap } from 'config/order-status-map';
import { QueryKeys } from 'config/constants/query-keys';
import { useAccount } from '@/features/account/hooks/use-account';
import { useActiveOrder } from '@/features/order/hooks/use-active-order';
import { useActiveBookerOrder } from '@/features/booker/hooks/use-active-booker-order';
import { useOrderDataActions } from '@/features/order/hooks/use-order-data-actions';
import { useCreateApproval } from '@/features/approvals/hooks/use-create-approval';
import { checkOrderNextStatus } from '@/features/order/helpers/check-order-next-status';
import { useShipmentRequestsUpdates } from '@/features/shipment-request/hooks/use-shipment-requests-updates';
import { useOrderUpdates } from './use-order-updates';
import { useOffersUpdates } from '@/features/offers/hooks/use-offers-updates';

export const useOrderDetailsView = () => {
  const { pathname } = useLocation();
  const client = useQueryClient();
  const { isBooker } = useAccount();
  const { activeOrderId, activeOrdersArray, isEmptyOrderList } = useActiveOrder();
  const { createApproval } = useCreateApproval();
  const { activeBookerOrderId } = useActiveBookerOrder();
  // в зависимости от роли пользователя по разном определяем активную заявку
  const orderId = isBooker && pathname === '/booker_orders' ? activeBookerOrderId : activeOrderId;
  const { order: orderQuery } = useOrderUpdates({ id: orderId, update: false });
  const { data: respData, refetch: orderRefetch, isInitialLoading, isRefetching } = orderQuery;
  const order = respData?.data;
  const { offers } = useOffersUpdates(orderId);
  const { shipmentRequests } = useShipmentRequestsUpdates({
    id: activeOrderId,
    disabled: (order?.status as OrderStatus | undefined) !== OrderExecutionStatus.CarrierSearch,
  });
  const { updateOrderStatus } = useOrderDataActions(activeOrderId!); // TODO: сделать проверку на undefined
  const isOrderLoading = isInitialLoading || isRefetching;
  const orderOffers = offers.data?.data || [];
  const selectedOffer = orderOffers.find((x) => x.accepted);
  const selectedCarrier = selectedOffer?.carrier;
  const setNewOrderStatus = (nextStatus: OrderStatus, onSuccess?: VoidFunction) => {
    if (!order) return;
    if (checkOrderNextStatus(order, nextStatus)) {
      updateOrderStatus.mutate(
        { status: nextStatus },
        {
          onSuccess: () => {
            toast.success('Новый статус успешно установлен');
            orderRefetch();
            client.invalidateQueries([QueryKeys.allOrders]);
            client.invalidateQueries([QueryKeys.ordersCount]);
            client.invalidateQueries([QueryKeys.offers, orderId]);
            onSuccess?.();
          },
        }
      );
    }
  };
  const cancelOrder = (currentStatus?: OrderStatus, onSuccess?: VoidFunction) => {
    const isDateExpired = order?.expiration && order?.expiration <= moment().unix();

    if (!currentStatus) return;
    let newStatus;
    if (currentStatus === OrderExecutionStatus.Preparation && isDateExpired) {
      newStatus = OrderExecutionStatus.New;
    } else newStatus = orderStatusMap[currentStatus]?.defaultPrevStatus;
    if (!newStatus) return;
    updateOrderStatus.mutate(
      { status: newStatus },
      {
        onSuccess: () => {
          orderRefetch();
          client.invalidateQueries([QueryKeys.allOrders]);
          client.invalidateQueries([QueryKeys.ordersCount]);
          client.invalidateQueries([QueryKeys.offers, orderId]);
          onSuccess?.();
        },
      }
    );
  };
  const setBackConfirmedStatus = () => {
    if (order?.status === OrderExecutionStatus.Confirmed) {
      updateOrderStatus.mutate(
        { status: OrderExecutionStatus.Preparation },
        {
          onSuccess: () => {
            orderRefetch();
            client.invalidateQueries([QueryKeys.allOrders]);
            client.invalidateQueries([QueryKeys.ordersCount]);
            client.invalidateQueries([QueryKeys.offers, orderId]);
          },
        }
      );
    }
  };

  return {
    order,
    orderRefetch,
    isOrderLoading,
    isBooker,
    orderId,
    selectedOffer,
    selectedCarrier,
    orderOffers,
    activeOrdersArray,
    activeOrderId,
    cancelOrder,
    setNewOrderStatus,
    setBackConfirmedStatus,
    createApproval,
    shipmentRequests,
    isEmptyOrderList,
  };
};
