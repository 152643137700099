import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'config/constants/query-keys';

export const useManagersInvalidate = () => {
  const client = useQueryClient();

  const invalidateAllManagers = () => {
    client.invalidateQueries([QueryKeys.allManagers]);
  };

  return { invalidateAllManagers };
};
