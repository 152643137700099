import { useState } from 'react';
import { DocumentTemplateNames } from '@/config/constants/document-types';
import { TemplateData, ClientContractTemplateType, ClientContractTemplateData } from '@/api/documents/templates-types';
import { TEMPLATE_MODAL_WIDTH, TEMPLATE_MODAL_WITH_FORM_WIDTH } from '@/features/contract-template/constants/form';
import { updateTemplateContractData } from '@/features/contract-template/helpers/update-template-contract-data';
import { TEMPLATES_WITH_FORM, TemplateContractFormValues } from '@/features/contract-template/types/form';

type Props = {
  document: TemplateData | ClientContractTemplateType;
  handleChangeDocument: (updatedDocument?: TemplateData | ClientContractTemplateType) => void;
};

/** Показываем форму для договора-заявки с клиентом, если шаблон dlContractCustomer2 */
export const useContractTemplateForm = ({ document, handleChangeDocument }: Props) => {
  const isTemplateWithForm = document?.templateAlias ? TEMPLATES_WITH_FORM.includes(document?.templateAlias) : false;
  const [isEditForm, setEditForm] = useState(false);

  const toggleEditForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setEditForm(!isEditForm);
  };

  const submitEditForm = (values?: TemplateContractFormValues) => {
    const updatedTemplateData = updateTemplateContractData({ templateData: document?.data as ClientContractTemplateData, updatedValues: values });
    if (document?.template && updatedTemplateData) {
      handleChangeDocument({ template: document?.template, data: updatedTemplateData, templateAlias: DocumentTemplateNames.dlContractCustomer2 });
    }
    setEditForm(!isEditForm);
  };

  /** Количество групп погрузки-разгрузки */
  const routesAmount = document?.data?.Routes?.length ?? 0;

  const modalWidth = !isTemplateWithForm || !routesAmount ? TEMPLATE_MODAL_WIDTH : TEMPLATE_MODAL_WITH_FORM_WIDTH;

  return { isTemplateWithForm, isEditForm, routesAmount, modalWidth, toggleEditForm, submitEditForm };
};
