import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'config/constants/query-keys';
import { NotificationsApi } from '@/api/notifications';

export const useNotificationsCounter = (refetchInterval?: number) => {
  const notificationsCounter = useQuery({
    queryKey: [QueryKeys.notificationsCounter],
    refetchInterval: refetchInterval || false,
    refetchOnWindowFocus: false,
    queryFn: () => NotificationsApi.getNewNotificationsCount(),
  });
  return { notificationsCounter };
};
