import { useOderStatuses } from './use-order-statuses';
import { useOrderStatusesActions } from './use-order-statuses-actions';

export const useOrderStatusesLoad = () => {
  const { oderStatuses } = useOderStatuses();
  const { setOrderStatuses } = useOrderStatusesActions();

  const loadOrderStatuses = () => oderStatuses.refetch().then((data) => data.data?.data && setOrderStatuses(data.data.data));

  return { loadOrderStatuses };
};
