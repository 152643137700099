import { FC } from 'react';
import './icon-button-widget.scss';

interface IconUserProps {
  onClick?: (e: any) => void;
}

export const IconButtonDeleteWidget: FC<IconUserProps> = ({ onClick = () => {} }) => {
  return (
    <svg onClick={onClick} className="ui-button-icon" width="20" height="20" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.30829 1.41202V2.35303H12V4.23505H0V2.35303H3.69268V1.41202C3.69294 1.03761 3.83893 0.678617 4.09858 0.413869C4.35824 0.149122 4.71035 0.000264881 5.07757 0H6.92342C7.29056 0.000529086 7.64249 0.149469 7.90209 0.414159C8.16169 0.67885 8.30777 1.03769 8.30829 1.41202ZM4.6156 1.41202V2.35303H7.3844V1.41202C7.3844 1.28728 7.33586 1.16762 7.24943 1.07931C7.16301 0.991012 7.04577 0.941271 6.92342 0.941006H5.07757C5.0169 0.941006 4.95682 0.953195 4.90078 0.976865C4.84473 1.00054 4.79381 1.03523 4.75091 1.07896C4.70801 1.1227 4.67399 1.17462 4.65077 1.23177C4.62756 1.28891 4.6156 1.35017 4.6156 1.41202Z"
        fill="#4E4E5A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.23796 15.6125C1.98032 15.3627 1.82719 15.0211 1.81051 14.659L0.998413 5.17585H10.9986L10.1904 14.659C10.1733 15.0209 10.02 15.3622 9.76245 15.612C9.50491 15.8617 9.1629 16.0007 8.80749 16H3.19343C2.83788 16.0012 2.49561 15.8624 2.23796 15.6125ZM8.30826 7.52889H7.38437V12.7059H8.30826V7.52889ZM6.46144 7.52889H5.53852V12.7059H6.46144V7.52889ZM4.61558 7.52889H3.69266V12.7059H4.61558V7.52889Z"
        fill="#4E4E5A"
      />
    </svg>
  );
};
