import { OrderExecutionStatus } from '@/config/constants/statuses';
import { FunnelLoadStatus } from '@/types/models/funnel';
import { Order } from '@/types/models/order';

type Props = {
  status: Order['status'];
  waitingCount?: number;
  counterOffersCount?: number;
};
// TODO добавить статусы для согласования стоимости
export const getStatusesStyles = ({ status, waitingCount, counterOffersCount }: Props) => {
  const StatusesStyles = {
    [FunnelLoadStatus.NewCalculation]: [],
    [FunnelLoadStatus.CostCalculated]: [],
    [FunnelLoadStatus.CostCalculation]: [],
    [FunnelLoadStatus.CostConfirmed]: [],
    [FunnelLoadStatus.CostCanceled]: [],
    [FunnelLoadStatus.SentCostToClient]: [],
    [OrderExecutionStatus.New]: [],
    [OrderExecutionStatus.CarrierSearch]: [
      {
        text: `Ждем ответа от ${waitingCount || 0}`,
        backgroundColor: 'accentYellow.100',
        color: 'typography.50',
      },
      {
        text: `Согласны ${counterOffersCount || 0}`,
        backgroundColor: 'accentMint.50',
        color: 'typography.250',
      },
    ],
    [OrderExecutionStatus.Preparation]: [
      {
        text: 'На оформлении',
        backgroundColor: 'products.100',
        color: 'typography.250',
      },
    ],
    [OrderExecutionStatus.Confirmed]: [
      {
        text: 'Оформлено',
        backgroundColor: 'accentGreen.150',
        color: 'typography.250',
      },
    ],
    [OrderExecutionStatus.Executing]: [
      {
        text: 'Рейс выполняется',
        backgroundColor: 'accentYellow.150',
        color: 'typography.50',
      },
    ],
    [OrderExecutionStatus.Troubling]: [
      {
        text: 'Возникли проблемы',
        backgroundColor: 'accentRed.100',
        color: 'typography.250',
      },
    ],
    [OrderExecutionStatus.Failed]: [
      {
        text: 'Рейс сорван',
        backgroundColor: 'accentRed.100',
        color: 'typography.250',
      },
    ],
    [OrderExecutionStatus.CompletedWithoutDocs]: [
      {
        text: 'Рейс выполнен',
        backgroundColor: 'accentGreen.150',
        color: 'typography.250',
      },
    ],
    [OrderExecutionStatus.CompletedWithDocs]: [
      {
        text: 'Рейс выполнен',
        backgroundColor: 'accentGreen.150',
        color: 'typography.250',
      },
    ],
    [OrderExecutionStatus.CompletedPayed]: [
      {
        text: 'Рейс выполнен',
        backgroundColor: 'accentGreen.150',
        color: 'typography.250',
      },
    ],
    [OrderExecutionStatus.Completed]: [
      {
        text: 'Рейс выполнен',
        backgroundColor: 'accentGreen.150',
        color: 'typography.250',
      },
    ],
  };

  return StatusesStyles?.[status] || [];
};
