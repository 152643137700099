import { createAsyncThunk } from '@reduxjs/toolkit';
import { Account } from '@/types/models/account';
import { AccountApi } from '@/api/account';
import { handleAxiosError } from '@/helpers/api/handle-axios-error';
import { ResponseError } from '@/types/api/response-error';
import { spinnerActions } from '@/features/spinner/slice';

export const getSelfAccountAction = createAsyncThunk<Account, undefined, { rejectValue: ResponseError }>(
  'account/me',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(spinnerActions.show());
      const res = await AccountApi.getAccountSelf();
      return res.data;
    } catch (err) {
      return handleAxiosError(err, rejectWithValue);
    } finally {
      dispatch(spinnerActions.hide());
    }
  }
);
