import { useQuery } from '@tanstack/react-query';
import { OrdersApi } from '@/api/orders';

export const useOderStatuses = () => {
  const oderStatuses = useQuery({
    queryKey: ['oderStatuses'],
    refetchOnWindowFocus: false,
    enabled: false,
    queryFn: () => OrdersApi.getOrderStatuses(),
  });
  return { oderStatuses };
};
