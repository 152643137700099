import { Input, InputProps, InputGroup, InputRightElement } from '@chakra-ui/react';
import { styles } from '@/ui-elements/inputs/ui-input/styles';

type Props = {
  icon?: React.ReactNode;
};

export const UiTextInput = ({ icon, ...props }: Props & InputProps) => (
  <InputGroup>
    <Input {...props} {...styles} />
    {!!icon && <InputRightElement children={icon} />}
  </InputGroup>
);
