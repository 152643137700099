import { Box, FormControl, FormErrorMessage, FormHelperText, FormLabel, TextareaProps } from '@chakra-ui/react';
import { useField } from 'react-final-form';
import { UiTextarea } from '@/ui-elements';
import './ui-textarea-field.scss';

type Props = {
  name: string;
  label?: string;
  content?: string;
  required?: boolean;
  counter?: boolean;
} & TextareaProps;

export const UiTextareaField = ({ name, label, required, content, counter, ...props }: Props) => {
  const { isDisabled } = props;
  const {
    input,
    meta: { error, touched },
  } = useField(name);
  const symbolsCount = input?.value?.length;
  const invalid = (error && touched) || (counter && symbolsCount > 1000);

  return (
    <FormControl isRequired={required && !isDisabled} isInvalid={invalid}>
      <FormLabel mb={1} fontSize="xs" className="ui-field__label">
        {label}
      </FormLabel>
      <UiTextarea {...props} {...input} isInvalid={invalid} />
      {invalid ? <FormErrorMessage>{error}</FormErrorMessage> : <FormHelperText>{content}</FormHelperText>}
      {counter && <Box className="ui-field__counter">{input?.value?.length} / 1000</Box>}
    </FormControl>
  );
};
