import { useQueryClient } from '@tanstack/react-query';

export const useOrderInvalidate = () => {
  const client = useQueryClient();
  const orderInvalidate = (id: number) => {
    client.invalidateQueries(['order', id]);
  };

  const shipmentRequestsInvalidate = (id: number) => {
    client.invalidateQueries(['shipmentRequests', id]);
  };

  const offersInvalidate = (id: number) => {
    client.invalidateQueries(['offers', id]);
  };
  const allOrdersInvalidate = (onSuccess?: VoidFunction) => {
    client.invalidateQueries(['ordersCount']);
    client.invalidateQueries(['allOrders']).then(() => onSuccess?.());
  };

  const invalidateOrderServices = (id: number) => {
    client.invalidateQueries(['orderService', id]);
  };

  return { orderInvalidate, allOrdersInvalidate, shipmentRequestsInvalidate, offersInvalidate, invalidateOrderServices };
};
