import { SelectOption } from '@/ui-elements/inputs/ui-select/types';

export const normalizeSelectOptionsResponce = (
  response: Record<string | number, any>,
  valueNormalize?: (value: string) => string | number
): Array<SelectOption> => {
  if (!response) return [];
  return Object.entries(response).map(([key, value]) => {
    return { label: value, value: valueNormalize ? valueNormalize(key) : key };
  });
};
