import { VehicleType } from '@/config/constants/vehicle';
import { RiskValues } from '@/config/constants/types';
import { Cargos } from '@/types/models/cargos';
import { PointNew } from '@/types/models/point';
import { Action } from '@/types/models/action';
import { OrderCreateCarrierPaymentTerms } from '@/components/create-order-form/schema';
import { OrderCreateSource } from '@/types/api/order-partial';

/* Табы со статусами заявок */
export enum FunnelSwitcherStatus {
  NewCalculation = 'newCalculation',
  CostCalculation = 'costCalculation',
  CostCalculated = 'costCalculated',
  SentCostToClient = 'sentCostToClient',
  CostConfirmed = 'costConfirmed',
  CostCanceled = 'costCanceled',
}

/* Статусы заявок */
export enum FunnelLoadStatus {
  /** Новые в согласовании */
  NewCalculation = 'newCalculation',
  /** Расчет стоимости */
  CostCalculation = 'costCalculation',
  /** Запрос расчитан */
  CostCalculated = 'costCalculated',
  /** Отправлен клиенту */
  SentCostToClient = 'sentCostToClient',
  /** Согласовано */
  CostConfirmed = 'costConfirmed',
  /** Отклонено */
  CostCanceled = 'costCanceled',
}

/* Статусы грузов для модуля "Логистика" */
export enum LogisticsLoadStatus {
  New = 'new',
  IsProcessed = 'costCalculation',
}

/* Количество заявок в табах */
export type FunnelCount = {
  [FunnelSwitcherStatus.NewCalculation]: number;
  [FunnelSwitcherStatus.CostCalculation]: number;
  [FunnelSwitcherStatus.CostCalculated]: number;
  [FunnelSwitcherStatus.SentCostToClient]: number;
  [FunnelSwitcherStatus.CostConfirmed]: number;
  [FunnelSwitcherStatus.CostCanceled]: number;
};

/* Кругорейс, только туда */
export enum RouteType {
  RoundTrip = 'roundtrip',
  Simple = 'simple',
}

/* Тип загрузки Full Truck Load - Less than Truckload */
export enum LoadTypeValues {
  LTL = 'ltl',
  FTL = 'ftl',
}

/* Кнопки перехода статуса заявки */
export enum ButtonDirection {
  Back = 'back',
  Forward = 'forward',
}

/* Преобразованный объект Load для отправки на бэк для создания нового груза */
export type LoadNew = {
  customerID?: number | string;
  contact: any;
  responsiblePerson: { id: number | string; name?: number | string };
  transportationType?: LoadTypeValues;
  riskGroup?: string | number | RiskValues;
  type: RouteType;
  points: Array<PointNew>;
  cargos: Array<Cargos>;
  actions: Array<Action>;
  additionalRequirements?: {
    belts: number;
    tonnage: string | number;
    additionalRequirements: string;
  };
  permissions?: {
    TIR?: boolean;
    CMR?: boolean;
    T1?: boolean;
    medicalBook?: boolean;
  };
  fromSource: OrderCreateSource;
  vehicleTypes: Array<VehicleType>;
  loadingType?: number;
};

/* Преобразованные условия оплаты объекта Load для отправки на бэк для обновления груза */
export type LoadPaymentNew = {
  customerPayment?: {
    price: string | undefined;
    vat: number | undefined;
    paymentConditions: string | undefined;
  };
  carrierPayment?: {
    priceWithVAT: number | undefined | null;
    priceWithoutVAT: number | undefined | null;
    paymentConditions: string | undefined;
    priceOffer: boolean | undefined;
    vat: boolean | undefined;
  };
  prepayment?: {
    possibility: boolean | undefined;
    fuelPossibility: boolean | undefined;
  };
};

/* Тип активного-заблокированого состояния кнопок */
export type BackForwardButtonsDisabledType = {
  [ButtonDirection.Back]: boolean;
  [ButtonDirection.Forward]: boolean;
};

/* Поля формы Условия оплаты + Документы на вкладке Согласование/Рассчет стоимости */
export enum FunnelPaymentDocsFormFields {
  ClientPaymentTerms = 'clientPaymentTerms',
  CarrierPaymentTerms = 'carrierPaymentTerms',
  Documents = 'documents',
}

/* Типы значений для блока формы Условия оплаты для Исполнителя */
export interface CarrierPaymentTermsFormType extends OrderCreateCarrierPaymentTerms {
  priceOffer?: boolean;
}

/* Типы значений для блока формы Условия оплаты для Клиента */
export interface ClientPaymentTermsFormType {
  price?: string;
  vat: {
    value: number;
    label: string;
  };
  paymentTerms?: string;
}

export type FunnelPaymentDocsFormValues = {
  [FunnelPaymentDocsFormFields.CarrierPaymentTerms]: CarrierPaymentTermsFormType;
  [FunnelPaymentDocsFormFields.ClientPaymentTerms]: ClientPaymentTermsFormType;
  [FunnelPaymentDocsFormFields.Documents]?: Array<File>;
};

export type FunnelListFilters = {
  status: FunnelLoadStatus;
  dateFrom?: string;
  dateTo?: string;
  search?: string;
};
