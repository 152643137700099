import './current-routes.scss';
import { FC } from 'react';
import { SidebarMode } from 'components/sidebar-wrapper/constants';
import { Routes } from 'config/routes/routes';
import { CurrentRoutesSidebarView } from '@/views/carriers-loads/current-routes/current-routes-sidebar-view';
import { CurrentRoutesListView } from '@/views/carriers-loads/current-routes/current-routes-list-view';
import { useAppSelector } from '@/hooks/use-app-selector';
import { pageSidebarModeSelector } from '@/features/carriers-loads/selectors/page-sidebar-mode-selector';

export const CurrentRoutesView: FC = () => {
  const mode = useAppSelector((state) => pageSidebarModeSelector(state, Routes.CurrentRoutes));

  return (
    <>
      <CurrentRoutesListView />
      {SidebarMode.None !== mode && <CurrentRoutesSidebarView />}
    </>
  );
};
