import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OrdersApi } from '@/api/orders';

export const useSetPointComment = (orderId: number, pointId: number) => {
  const queryClient = useQueryClient();
  const setPointComment = useMutation({
    mutationKey: ['setPointComment', pointId],
    mutationFn: (comment: string) => OrdersApi.setWaypointComment({ orderId, pointId, comment }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getPointComment', pointId] });
    },
  });

  return {
    setPointComment,
  };
};
