import { OrderLoadType } from '@/config/constants/types';
import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { getPointDisplay } from '@/features/funnel/helpers';

export const getInitialControls = (
  respData: Order | Load,
  packageTypes: Array<SelectOption>,
  cargoTypes: Array<SelectOption>,
  unloadCargoOptions: Array<SelectOption>
) => {
  const { points } = respData;
  const newPoints = points
    ?.map((point) => {
      const pointCargoIdsLoading = respData.actions.filter((action) => action.pointId === point.id && action.type === 1).map((act) => act.cargoId);
      const pointCargoIdsUnloading = respData.actions.filter((action) => action.pointId === point.id && action.type === 0).map((act) => act.cargoId);

      const cargoListLoading = respData.cargos.filter((cargo) => pointCargoIdsLoading.includes(cargo.tenderLotPositionID));
      const cargoListUnloading = respData.cargos.filter((cargo) => pointCargoIdsUnloading.includes(cargo.tenderLotPositionID));

      const newObjLoading =
        (cargoListLoading.length && getPointDisplay(point, cargoListLoading, OrderLoadType.Loading, packageTypes, cargoTypes, unloadCargoOptions)) ||
        undefined;
      const newObjUnloading =
        (cargoListUnloading.length &&
          getPointDisplay(point, cargoListUnloading, OrderLoadType.Unloading, packageTypes, cargoTypes, unloadCargoOptions)) ||
        undefined;

      // eslint-disable-next-line no-nested-ternary
      return newObjLoading && newObjUnloading ? [newObjUnloading, newObjLoading] : newObjLoading ? [newObjLoading] : [newObjUnloading];
    })
    ?.flat();
  return newPoints?.map((point) =>
    point?.cargos.map((cargo) => {
      const isMeasurements = cargo?.size.length || cargo?.size.width || cargo?.size.height || cargo?.size.diameter;
      const isPackage = cargo?.package && cargo?.quantity;
      if (isMeasurements && isPackage) {
        return [];
      }
      if (isMeasurements) {
        return [1];
      }
      if (isPackage) {
        return [2];
      }
      return [1, 2];
    })
  );
};
