import L from 'leaflet';
import markerIcon from '../../../public/assets/icons/Marker2x.png';

export const googleTerrain = L.tileLayer(`http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}&hl=ru`, {
  maxZoom: 18,
  subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
  updateWhenIdle: true,
});

export const osm = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
  maxZoom: 18,
  id: 'mapbox/streets-v11',
  updateWhenIdle: true,
  tileSize: 512,
  zoomOffset: -1,
});

export const googleHybrid = L.tileLayer(`http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}&hl=ru`, {
  maxZoom: 18,
  subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
  tileSize: 256,
  updateWhenIdle: true,
});

export const defaultIcon = L.icon({
  iconUrl: markerIcon,
  iconSize: [25, 40],
});
