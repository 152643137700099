import { VatTypeMap } from 'config/constants/vat-options';

export const PREPAYMENT_CALCULATION = 'Оформление аванса';
export const PREPAYMENT_MONEY = 'Деньгами';

export const PREPAYMENT_FUEL = 'Топливом';

export const VAT_EXISTENCE = 'Наличие НДС';

export const WITH_VAT = VatTypeMap.withVat;
export const WITHOUT_VAT = VatTypeMap.withoutVat;
