import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';

export const getCarrierTotalWithVAT = (order: Order | Load): number => {
  const totalService =
    order.services?.reduce((acc, curr) => {
      return acc + (curr.carrierPayment?.price || 0);
    }, 0) || 0;
  return (order.carrierPayment?.priceWithVAT || 0) + totalService;
};
