import { useQuery } from '@tanstack/react-query';
import { OrdersApi } from '@/api/orders';

export const usePackages = () => {
  const packageTypes = useQuery({
    queryKey: ['packageTypes'],
    refetchOnWindowFocus: false,
    enabled: true,
    queryFn: () => OrdersApi.getPackages(),
  });
  const data = packageTypes?.data?.data;
  return data
    ? Object.keys(data).map((key) => {
        return {
          value: key,
          label: data[+key],
        };
      })
    : [];
};
