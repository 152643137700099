import { OrderLoadType } from '@/config/constants/types';
import { getEmptyStr } from '@/helpers/get-empty-str';
import { Account } from '@/types/models/account';
import { CreateLoadFormField, InfoField, RouteField, VehicleField } from '@/types/models/funnel-create-load-form';
import { LoadTypeValues, RouteType } from '@/types/models/funnel';
import { emptyCargoFields, TimeType } from '@/components/create-order-form/subforms/form-configs';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';

export const generateLoadFormInitialValues = (user?: Account, carrierForReviews?: { id?: number; name?: string }) => {
  const userName = `${getEmptyStr(user?.surname)}`;
  const responsiblePerson = {
    value: user?.id,
    label: userName,
  } as SelectOption;
  const carrierForReviewsValue = carrierForReviews
    ? ({
        value: carrierForReviews.id,
        label: carrierForReviews.name,
      } as SelectOption)
    : undefined;

  return {
    [CreateLoadFormField.State]: {
      [CreateLoadFormField.Cargo]: [[[1, 2]]],
    },
    [CreateLoadFormField.Cargo]: [],
    [CreateLoadFormField.LoadInfo]: {
      [InfoField.CustomerCompany]: carrierForReviewsValue,
      [InfoField.ResponsiblePerson]: responsiblePerson,
    },
    [CreateLoadFormField.Route]: {
      [RouteField.RouteTypeField]: RouteType.Simple,
      [RouteField.Points]: [
        {
          showLoadTime: true,
          cargos: [{ ...emptyCargoFields, id: 'cargo_0' }],
          arrivalTimeOption: TimeType.simple,
          type: OrderLoadType.Loading,
          id: 'point_0',
        },
      ],
    },
    [CreateLoadFormField.VehicleType]: {
      [VehicleField.LoadType]: LoadTypeValues.FTL,
    },
  };
};
