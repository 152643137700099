import './resources-vehicle-details-component.scss';
import { FC } from 'react';
import { capitalize, isEmpty, isNil } from 'lodash';
import classNames from 'classnames';
import { Box, HStack, Text } from '@chakra-ui/react';
import { COMMA_SPACE } from '@/config/constants/common-strings';
import { useIcons } from '@/hooks/use-icons';
import { Vehicle } from '@/types/models/vehicle';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';
import { UiButtonFilled, UiButtonOutlined, UiDivider } from '@/ui-elements';
import { AVAILABLED_TEXT, BAILED_TEXT, ResourcesButtonActions } from '@/features/resources/constants/general';
import { useVehicleTypesNames } from '@/features/vehicle-types/hooks/use-vehicle-types-names';
import { getVehicleRegNumber, getVehicleRegion } from '@/features/vehicles/extender/vehicle-extender';
import { useVehicleTypesIcons } from '@/features/vehicle-types/hooks/use-vehicle-types-icons';
import { CarrierCardRegNumber } from '@/components/carrier-card/carrier-card-reg-number';
import { ResourcesVehicleDocs, VehicleOwnTypeLabels } from '@/features/resources/constants/vehicle';
import { ResourcesVehicleSpecifications } from '@/components/resources/vehicle-specifications/resources-vehicle-specifications';
import { ResourcesDocumentsList } from '@/components/resources/documents-list/resources-documents-list';

interface Props {
  vehicle?: Vehicle;
  loading: boolean;
  handleEditVehicle: VoidFunction;
  handleDeleteVehicle: VoidFunction;
}

export const ResourcesVehicleDetailsComponent: FC<Props> = ({ vehicle, loading, handleEditVehicle, handleDeleteVehicle }) => {
  const { DeleteIcon, EditIcon } = useIcons();

  const vehicleTypesNames = useVehicleTypesNames();
  const vehicleTypesIcons = useVehicleTypesIcons();

  if (!vehicle) {
    return null;
  }

  const vehicleIcon = vehicle?.vehicleType && vehicleTypesIcons?.[vehicle?.vehicleType];
  const vehicleName = vehicle?.vehicleType && vehicleTypesNames?.[vehicle?.vehicleType];
  const vehicleLoadingTypes = vehicle?.loadingType ? vehicle?.loadingType.join(COMMA_SPACE) : AVAILABLED_TEXT.NOT_AVAILABLE;

  if (loading) {
    return <UiSpinner visible />;
  }

  return (
    <Box className="resources-vehicle-details">
      {/* Заголовок */}
      <h2 className="resources-vehicle-details__title">Информация о транспортном средстве</h2>

      {/* Детали */}
      <Box className="resources-vehicle-details__content">
        {/* Тип ТС */}
        <HStack>
          <Text className="resources-vehicle-details__subtitle">Тип ТС:</Text>
          <HStack>
            {vehicleName && <Text className="resources-vehicle-details__text">{capitalize(vehicleName)}</Text>}
            {vehicleIcon && <img className="resources-vehicle-details__vehicle-image" src={vehicleIcon} alt="vehicle img" />}
          </HStack>
        </HStack>

        {/* Регистрационный номер ТС и номер полуприцепа */}
        <HStack>
          <Text className="resources-vehicle-details__subtitle">Регистрационный номер ТС:</Text>
          {vehicle?.regNumber && <CarrierCardRegNumber regNumber={getVehicleRegNumber(vehicle)} region={getVehicleRegion(vehicle)} />}
        </HStack>

        {/* тип собственности ТС (аренда-собственность-лизинг) */}
        <HStack>
          <Text className="resources-vehicle-details__subtitle">Тип собственности:</Text>
          <Box className="resources-vehicle-details__row">
            <Text className={classNames('resources-vehicle-details__text available', { not_available: isNil(vehicle?.ownType) })}>
              {!isNil(vehicle?.ownType) ? VehicleOwnTypeLabels[vehicle?.ownType]?.label : AVAILABLED_TEXT.NOT_AVAILABLE}
            </Text>
            <Text className="resources-vehicle-details__text bailed">
              {vehicle?.bailed ? `(${BAILED_TEXT.BAILED})` : `(${BAILED_TEXT.NOT_BAILED})`}
            </Text>
          </Box>
        </HStack>

        {/* Трекинг */}
        <HStack>
          <Text className="resources-vehicle-details__subtitle">Трекинг:</Text>
          <Text className={classNames('resources-vehicle-details__text available', { not_available: !vehicle?.tracker })}>
            {vehicle?.tracker ? AVAILABLED_TEXT.AVAILABLE : AVAILABLED_TEXT.NOT_AVAILABLE}
          </Text>
        </HStack>

        {/* Тип погрузки */}
        <HStack>
          <Text className="resources-vehicle-details__subtitle">Погрузка:</Text>˝
          <Text className="resources-vehicle-details__text">{vehicleLoadingTypes}</Text>
        </HStack>

        {/* Грузоподъемность, Объем, Размер */}
        <ResourcesVehicleSpecifications vehicle={vehicle} />

        {/* Прицепы */}
        {!isEmpty(vehicle?.trailers) && (
          <>
            <UiDivider />
            <Box>
              <h3 className="resources-vehicle-details__title">Прицеп:</h3>
              {vehicle?.trailers?.map((trailer) => (
                <Box key={trailer?.regNumber}>
                  <Box className="resources-vehicle-details__row">
                    {/* Номер прицепа */}
                    <HStack>
                      <Text className="resources-vehicle-details__subtitle">Номер прицепа:</Text>
                      <CarrierCardRegNumber regNumber={getVehicleRegNumber(trailer)} region={getVehicleRegion(trailer)} />
                    </HStack>
                    {/* Грузоподъемность, Объем, Размер */}
                    <ResourcesVehicleSpecifications vehicle={trailer} />
                  </Box>
                </Box>
              ))}
            </Box>
          </>
        )}
        {/* Документы */}
        {!isEmpty(vehicle?.documents) && (
          <>
            <UiDivider />
            <Box>
              <h3 className="resources-vehicle-details__title">Документы</h3>
              {ResourcesVehicleDocs.map((documentSection) => (
                <ResourcesDocumentsList vehicleDocuments={vehicle?.documents} documentSection={documentSection} />
              ))}
            </Box>
          </>
        )}
      </Box>

      {/* Кнопки */}
      <HStack className="resources-vehicle-details-buttons">
        <UiButtonOutlined mode="light" onClick={handleEditVehicle} leftIcon={EditIcon}>
          {ResourcesButtonActions.Edit}
        </UiButtonOutlined>
        <UiButtonFilled mode="light" onClick={handleDeleteVehicle} leftIcon={DeleteIcon}>
          {ResourcesButtonActions.DeleteVehicle}
        </UiButtonFilled>
      </HStack>
    </Box>
  );
};
