import { useIcons } from '@/hooks/use-icons';
import { useCarrierActions } from '@/features/carriers/hooks/use-carrier-actions';
import { carrierToApprovalForm } from '../helpers/carrier-to-approval-form';
import { Carrier } from '@/types/models/carrier';
import { Driver } from '@/types/models/driver';
import { OrganizationVerificationForm } from '@/types/models/organization';
import { formatFormFields } from '@/helpers/forms/format-form-fields';
import { ApprovalBody } from '@/types/models/approval';
import { DependentType } from '@/api/documents/dependent-types';
import { useGetMyOrganizationTypes } from '@/features/my-organization/use-get-organization-types';

export const useApproveOrganizationForm = (
  driver?: Driver,
  carrier?: Carrier,
  onApprovalApproved?: (approvalBody: ApprovalBody) => void,
  onApprovalDeclined?: (approvalBody: ApprovalBody) => void
) => {
  const { organizationTypes } = useGetMyOrganizationTypes();
  const { CloseIcon } = useIcons();
  const { updateCarrierWithoutApprovals } = useCarrierActions(carrier?.id!);
  const initialValues = carrierToApprovalForm({
    carrier,
    driver,
    organizationTypes,
  });

  const onApprove = (values: OrganizationVerificationForm) => {
    const newValues = formatFormFields(values, 'pick', ['loans', 'convictions']);
    updateCarrierWithoutApprovals.mutate(newValues as any, {
      onSuccess: () => {
        onApprovalApproved?.({
          dependent: DependentType.Carrier,
          dependentId: carrier?.id,
        });
      },
    });
  };

  const onDecline = () => {
    onApprovalDeclined?.({
      dependent: DependentType.Carrier,
      dependentId: carrier?.id,
    });
  };

  const onSubmit = (values: OrganizationVerificationForm) => (values.action === 'approve' ? onApprove(values) : onDecline());

  return { onSubmit, initialValues, CloseIcon };
};
