import { Dictionary } from 'config/constants/dictionary';
import { ApiGet, ApiPatch, ApiPost, ApiPut } from '@/helpers/api/methods';
import { PageFilter } from '@/types/common/sort-page-filter';
import { validateQueryString } from '@/helpers/validate-query-string';
import { NotificationsSettings } from '@/types/api/notifications-partial';
import { ResponseWithoutPagerSimple, ResponseWithPager } from '@/types/models/pager';
import { AppNotification } from '@/types/models/app-notification';

export const NotificationsApi = {
  getNotifications: (query?: PageFilter & { onlyNew?: boolean }) =>
    ApiGet<ResponseWithPager<AppNotification>>(`${Dictionary.Notifications}${validateQueryString(query)}`),

  getNewNotificationsCount: () => ApiGet<ResponseWithoutPagerSimple<{ newCount: number }>>(`${Dictionary.Notifications}/newCount`),

  updateNotifications: (options: NotificationsSettings) =>
    ApiPut<ResponseWithoutPagerSimple<AppNotification>>(`${Dictionary.Notifications}/settings`, options),

  setNotificationMarked: (id: number) => ApiPatch<ResponseWithoutPagerSimple<AppNotification>>(`${Dictionary.Notifications}/${id}`, { viewed: true }),

  setAllNotificationsMarked: () => ApiPost<ResponseWithoutPagerSimple<AppNotification>>(`${Dictionary.Notifications}/mark`),
};
