export const validateDateField = (event: React.ChangeEvent<HTMLInputElement>) => {
  const inputValidateValue = event.target.value;

  // Define a regular expression to allow only numbers, ., /
  const regex = /^[./0-9\s]*$/;
  if (regex.test(inputValidateValue)) {
    return true;
  }
  return false;
};
