import { FC } from 'react';
import classNames from 'classnames';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { HStack, Text } from '@chakra-ui/react';
import { OrderType } from '@/config/constants/types';
import { OrderTypeNames } from '@/config/constants/texts';
import { useIcons } from '@/hooks/use-icons';
import './order-general-distance.scss';

type Props = {
  type?: OrderType;
  range?: number;
  className?: string;
};

export const OrderGeneralDistance: FC<Props> = ({ type, range = 0, className }) => {
  const { ReverseIcon, FromDotsToDots } = useIcons();

  return (
    <HStack className={classNames('order-general-distance', className)}>
      <Text className="order-general-distance__type">
        {OrderTypeNames[OrderType.Simple] ? <FromDotsToDots /> : <ReverseIcon />}
        {OrderTypeNames[type!]}
      </Text>
      <Text className="order-general-distance__number">{range ? `${range.toLocaleString('ru')} км` : EMPTY_STRING}</Text>
    </HStack>
  );
};
