import { FC } from 'react';
import { emptyFunction } from '@/helpers/empty-function';
import { useAppSelector } from '@/hooks/use-app-selector';
import { modifyVehicleSelectOptions } from '@/features/loads/helpers/modify-vehicle-select-options';
import { VehicleGroup, VehicleType } from '@/ui-elements/inputs/ui-vehicle-types-picker/ui-vehicle-types-picker';
import { vehicleTypesSelector } from '@/features/vehicle-types/selectors/vehicle-types-selector';
import { UiSelectField } from '@/ui-elements/form-fields/ui-select-field/ui-select-field';
import { UiVehicleCustomCheckbox } from '@/ui-elements/form-fields/ui-vehicle-type-select/custom-checkbox/ui-vehicle-custom-checkbox';
import { UiVehicleCustomGroupHeading } from '@/ui-elements/form-fields/ui-vehicle-type-select/group-heading/ui-vehicle-custom-group-heading';

type Props = {
  name: string;
  label?: string;
  content?: string;
  isMulti?: boolean;
  required?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
};

export const UiVehicleTypesSelect: FC<Props> = (props) => {
  const vehicles = useAppSelector<Array<VehicleGroup & VehicleType>>(vehicleTypesSelector as any);
  const options = modifyVehicleSelectOptions(vehicles);

  return (
    <UiSelectField
      options={options}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      isClearable={false}
      onChange={emptyFunction}
      isMulti
      overflown
      {...props}
      components={{
        Option: UiVehicleCustomCheckbox,
        GroupHeading: UiVehicleCustomGroupHeading,
      }}
    />
  );
};
