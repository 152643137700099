import { AuthForm } from 'components/auth-form/auth-form';
import { useAuth } from '@/features/account/hooks/use-auth';
import { Credentials } from '@/types/api/credentials';
import { useAppSelector } from '@/hooks/use-app-selector';
import { logoutPendingSelector } from '@/features/account/selectors/logout-pending-selector';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';
import { accountSelector } from '@/features/account/selectors/account-selector';

export const AuthFormView = () => {
  const isLogoutPending = useAppSelector(logoutPendingSelector);
  const { login } = useAuth();
  const { loginError } = useAppSelector(accountSelector);
  const errorToString = Array.isArray(loginError) ? loginError.join(', ') : loginError;

  const onSubmit = (credentials: Credentials) => {
    login(credentials);
  };
  return isLogoutPending ? <UiSpinner visible /> : <AuthForm onSubmit={onSubmit} loginError={errorToString} />;
};
