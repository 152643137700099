export const CARRIER_PAYMENT = 'Оплата Исполнителю';

export const CLIENT_PAYMENT = 'Оплата от клиента';
export const PRICE_FOR_MAIN_SERVICE = 'Основная услуга';
export const PRICE_FOR_MAIN_SERVICE_WITH_VAT = 'Основная услуга с НДС';
export const PRICE_FOR_MAIN_SERVICE_WITHOUT_VAT = 'Основная услуга без НДС';
export const CARRIER_AGREEMENT = 'Договор-заявка';

export const CLIENT_CONTRACT = 'Договор с клиентом';
export const SAVE_DOCUMENTS = 'Скачать документы';
export const PREPAYMENT = 'Аванс';
export const COMMISSION = 'Комиссия';
export const ADDITIONAL_SERVICE = 'Дополнительная услуга';
export const TOTAL_TO_PAY = 'Итого за рейс';
export const TOTAL_TO_PAY_WITHOUT_VAT = 'Итого за рейс без НДС';
export const TOTAL_TO_PAY_WITH_VAT = 'Итого за рейс с НДС';
export const PAYMENT_ORDER = 'Платежное поручение';
export const TOTAL_PAYED = 'Оплачено';
export const NOT_PAYED = 'Не оплачено';

export const WILL_REDUCE_PAYMENT = 'Будет вычтено из оплаты';
export const PAYMENT_REDUCED = 'Вычтено из оплаты';

export const PAYMENT_DOCUMENTS = 'Документы по оплате';
export const DID_NOT_ASK = 'не запрашивал';
export const PAYMENT_DELAY = 'Отсрочка платежа';
