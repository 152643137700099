import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { AbacProvider } from 'react-abac';
import isEmpty from 'lodash/isEmpty';
import { rules } from 'config/abac/rules';
import { routesConfig } from 'config/routes/routes-config';
import { getRouteRules } from '@/helpers/routes/get-routes-rules';
import { useAccount } from '@/features/account/hooks/use-account';
import '@/scss/app.scss';
import 'config/moment-config';
import { useCheckAuth } from '@/features/account/hooks/use-check-auth';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useAppDispatch } from './hooks/use-app-dispatch';
import { useVehicleTypesLoad } from '@/features/vehicle-types/hooks/use-vehicle-types-load';
import { vehicleTypesSelector } from '@/features/vehicle-types/selectors/vehicle-types-selector';
import { orderStatusesSelector } from '@/features/order/selectors/order-statuses-selector';
import { AppToolbarView } from '@/views/toolbar/app-toolbar-view';
import { getCargoTypes } from '@/features/cargo/actions/get-cargo-types';
import { useOrderStatusesLoad } from './features/order/hooks/use-order-statuses-load';
import { UserRole } from '@/types/api/user-role';

export const App = () => {
  const dispatch = useAppDispatch();
  const { currentAccount, currentRole, accountRole } = useAccount();
  const summaryRules = getRouteRules(rules, routesConfig, accountRole);
  const { authenticationPending } = useCheckAuth();

  const vehicleTypes = useAppSelector(vehicleTypesSelector);
  const orderStatuses = useAppSelector(orderStatusesSelector);

  const { loadVehicleTypes } = useVehicleTypesLoad();
  const { loadOrderStatuses } = useOrderStatusesLoad();

  if (isEmpty(vehicleTypes)) {
    loadVehicleTypes();
  }
  if (
    isEmpty(orderStatuses?.orderStatuses) ||
    isEmpty(orderStatuses?.bookerSwitcherStatuses) ||
    isEmpty(orderStatuses?.fuelStatuses) ||
    isEmpty(orderStatuses?.orderSwitcherStatuses)
  ) {
    loadOrderStatuses();
  }
  useEffect(() => {
    dispatch(getCargoTypes());
  }, [dispatch]);
  return (
    <AbacProvider user={currentAccount} roles={currentRole as unknown as UserRole[]} rules={summaryRules.rules}>
      {!authenticationPending && (
        <>
          <AppToolbarView />
          <Outlet />
        </>
      )}
    </AbacProvider>
  );
};
