import { VatType } from '@/config/constants/types';
import { VatTypeMap } from '@/config/constants/vat-options';
import { INCOMPATIBLE_PAYMENT_TOOLTIP } from '@/features/carriers-loads/constants';

type Props = {
  orderVat?: VatType;
  organizationVat?: VatType;
};

export const getOfferSubmitTooltipText = ({ orderVat, organizationVat }: Props) => {
  if (!orderVat || !organizationVat) {
    return INCOMPATIBLE_PAYMENT_TOOLTIP;
  }
  return `${INCOMPATIBLE_PAYMENT_TOOLTIP}
  Условия оплаты заявки: ${VatTypeMap[orderVat]}.
  Условия оплаты перевозчика: ${VatTypeMap[organizationVat]}.`;
};
