import './loads-empty.scss';
import { useIcons } from '@/hooks/use-icons';

export const LoadsEmpty = ({ text, visible }: { text?: string; visible?: boolean }) => {
  const { ClaimIcon64 } = useIcons();
  if (!visible || !text) {
    return null;
  }

  return (
    <div className="load-empty">
      <div className="load-empty__caption">
        <ClaimIcon64 />
        {text}
      </div>
    </div>
  );
};
