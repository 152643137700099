import { useState } from 'react';
import { Form } from 'react-final-form';
import { LICENSE_MASK, PASSPORT_MASK } from 'components/auth-form/constants';
import { VerificationRiskGroupOptions } from 'components/carriers-verification/config';
import { Box, Grid, GridItem, HStack, Text } from '@chakra-ui/react';
import { driverFields, driverFieldsToTransform } from 'components/verification-forms/constants';
import { DriverVerification, DriverVerificationForm as DriverVerificationFormFields } from '@/types/models/driver-verification';
import {
  UiAttachmentsInput,
  UiCheckboxField,
  UiIconButton,
  UiInputField,
  UiMaskedField,
  UiMultiDatePickerField,
  UiSelectField,
  UiTextareaField,
} from '@/ui-elements';
import './verification-form.scss';
import { useIcons } from '@/hooks/use-icons';
import { formatFormFields } from '@/helpers/forms/format-form-fields';
import { useDriverActions } from '@/features/drivers/hooks/use-driver-actions';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { AttachmentDeps } from '@/config/constants/types';
import { DocumentTypes } from '@/config/constants/document-types';
import { selectOptionToValue } from '@/helpers/forms/select-option-to-value';
import { driverToFormValues } from '@/components/verification-forms/helpers/driver-to-form-values';
import { SubmitButtonsGroup } from '@/components/verification-forms/organization-verification/submit-buttons-group';
import { Driver } from '@/types/models/driver';
import { Carrier } from '@/types/models/carrier';
import { ApprovalBody } from '@/types/models/approval';
import { DependentType } from '@/api/documents/dependent-types';
import { UiPhoneField } from '@/ui-elements/form-fields/ui-phone-field/ui-phone-field';

type Props = {
  driver: Driver;
  onApprovalDeclined: (approvalBody: ApprovalBody) => void;
  onApprovalApproved: (approvalBody: ApprovalBody) => void;
  onClose: VoidFunction;
  carrier: Carrier;
};

export const ApproveDriverForm = ({ driver, onApprovalDeclined, onApprovalApproved, onClose, carrier }: Props) => {
  const { CloseIcon } = useIcons();
  const [passportForm, setPassportForm] = useState(true);
  const { updateDriverWithoutApprovals } = useDriverActions(driver.id);

  const onApprove = (values: any) => {
    const newValues = formatFormFields(selectOptionToValue(values, driverFieldsToTransform), 'pick', driverFields);
    updateDriverWithoutApprovals.mutate(newValues as DriverVerification, {
      onSuccess: () => {
        onApprovalApproved?.({
          dependent: DependentType.Driver,
          dependentId: driver.id,
          comment: values.comment,
        });
      },
    });
  };

  const onDecline = (values: DriverVerificationFormFields) => {
    onApprovalDeclined?.({
      dependent: DependentType.Driver,
      dependentId: driver.id,
      comment: values.comment,
    });
  };

  const initialValues = driverToFormValues({ carrier, values: driver }) || ({} as DriverVerificationFormFields);

  const onSubmit = (values: DriverVerificationFormFields) => (values.action === 'approve' ? onApprove(values) : onDecline(values));

  const validate = (values: DriverVerificationFormFields) => {
    const errors: { riskGroup?: string } = { riskGroup: undefined };
    if (values.action === 'approve' && !values.riskGroup) {
      errors.riskGroup = 'Группа риска обязательное поле';
    }
    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      keepDirtyOnReinitialize
      render={({ handleSubmit, form, submitting }) => (
        <form onSubmit={handleSubmit}>
          <HStack spacing={0} justify="space-between">
            <Text className="carriers-verification-form__title">Вы проверяете водителя:</Text>
            <UiIconButton className="ui-sidebar__close-button" aria-label="close" icon={CloseIcon} onClick={onClose} />
          </HStack>
          <div className="carriers-verification-form__body">
            <UiScrollbars autoHide>
              <div className="carriers-verification-form__main">
                <Grid templateColumns="repeat(2, 1fr)" gap={4} className="carriers-verification-form">
                  <GridItem mt={3.5}>
                    <UiInputField name="carrierName" label="К какой организации относится" isDisabled />
                  </GridItem>
                  <GridItem mt={3.5} className="carriers-verification-form__row">
                    <UiSelectField
                      options={VerificationRiskGroupOptions}
                      name="riskGroup"
                      label="Назначьте группу риска"
                      placeholder="Группа риска не назначена"
                      content="Определите группу риска"
                      required
                    />
                  </GridItem>
                  <GridItem>
                    <Box>
                      <Text className="carriers-verification-form__subtitle">{initialValues?.formattedName}</Text>
                    </Box>
                    <Box mt={-2}>
                      <Text className="carriers-verification-form__phone">{initialValues?.phoneNumber}</Text>
                    </Box>
                  </GridItem>
                  <GridItem mt={0.3} colSpan={2}>
                    <UiCheckboxField
                      name="selfAssignment"
                      label="С правом самоназначения на рейс"
                      defaultChecked={!!initialValues?.selfAssignment}
                      isDisabled
                    />
                  </GridItem>
                  <GridItem>
                    <UiInputField
                      name="country"
                      label="Страна регистрации водителя"
                      placeholder="Страна регистрации водителя"
                      content="По документу, подтверждающему личность"
                      isDisabled
                    />
                  </GridItem>
                  <GridItem>
                    <UiPhoneField
                      defaultValue={initialValues?.phoneNumber}
                      name="phoneNumber"
                      label="Телефон основной"
                      placeholder="Телефон основной"
                      content="Телефон, по которому можно связаться"
                      isDisabled
                    />
                  </GridItem>
                  {/* PASSPORT */}
                  <GridItem colSpan={2}>
                    <Text className="carriers-verification-form__subtitle" fontSize={16}>
                      Паспортные данные
                    </Text>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <UiCheckboxField
                      name="russianPassport"
                      label="Паспорт РФ"
                      isDisabled
                      defaultChecked
                      onChange={() => setPassportForm((val) => !val)}
                    />
                  </GridItem>
                  {passportForm && (
                    <>
                      <GridItem>
                        <UiMaskedField name="passport" label="Серия и номер" mask={PASSPORT_MASK} defaultValue={initialValues?.passport} isDisabled />
                      </GridItem>
                      <GridItem>
                        <UiInputField name="surname" label="Фамилия" isDisabled />
                      </GridItem>
                      <GridItem mt={-5}>
                        <UiMultiDatePickerField name="passportDate" label="Дата выдачи" placeholder="Дата выдачи" isDisabled />
                      </GridItem>
                      <GridItem mt={-5}>
                        <UiInputField name="name" label="Имя" isDisabled />
                      </GridItem>
                      <GridItem mt={-5}>
                        <UiMultiDatePickerField name="birthday" label="Дата рождения" placeholder="Дата рождения" isDisabled />
                      </GridItem>
                      <GridItem mt={-5}>
                        <UiInputField name="patronymic" label="Отчество" placeholder="Отчество" isDisabled />
                      </GridItem>
                    </>
                  )}
                </Grid>
                {passportForm ? (
                  <Box mt={-1}>
                    <UiInputField name="passportGiven" label="Кем выдан" defaultChecked isDisabled />
                  </Box>
                ) : (
                  <Box mt={4}>
                    <UiTextareaField name="foreignPassport" className="carriers-verification-form__row" label="Введите данные вручную" />
                  </Box>
                )}
                {/* BOOLEAN FIELDS */}
                <GridItem colSpan={2}>
                  <Text className="carriers-verification-form__subtitle" fontSize={16}>
                    Водительское удостоверение
                  </Text>
                </GridItem>
                <Grid templateColumns="repeat(2, 1fr)" gap={4} className="carriers-verification-form">
                  <Box mt={-1}>
                    <UiMaskedField
                      mask={LICENSE_MASK}
                      name="driverLicense"
                      label="Серия и номер"
                      defaultValue={initialValues?.driverLicense}
                      isDisabled
                    />
                  </Box>
                  <Box mt={-1}>
                    <UiMultiDatePickerField name="driverLicenseDate" label="Дата выдачи" isDisabled />
                  </Box>
                </Grid>
                <Box>
                  <Box mt={-1}>
                    <UiCheckboxField name="convictions" label="Наличие судимости" />
                  </Box>
                  <Box mt={4}>
                    <UiCheckboxField name="loans" label="Наличие кредитов" />
                  </Box>
                  <Box mt={4}>
                    <UiCheckboxField name="speedFines" label="Наличие штрафов за превышение скорости" />
                  </Box>
                  <Box mt={4}>
                    <UiCheckboxField name="trafficFines" label="Наличие штрафов за нарушение ПДД" />
                  </Box>
                  <Box mt={4}>
                    <UiCheckboxField name="accidents" label="Наличие зарегистрированных ДТП за 3 года (по вине водителя)" />
                  </Box>
                  <Box mt={4}>
                    <UiCheckboxField name="driversLicenseRevoked" label="Лишение прав" />
                  </Box>
                  <Box mt={4} mb={6}>
                    <UiTextareaField
                      className="carriers-verification-form__comment"
                      label="Комментарии"
                      variant="noResize"
                      placeholder="Введите текст"
                      name="comment"
                    />
                  </Box>
                </Box>
                {/* DOCUMENTS */}
                <Box mt={1}>
                  <Text className="carriers-verification-form__subtitle">Документы</Text>
                </Box>
                <UiAttachmentsInput
                  mt={4}
                  label="1. Разворот паспорта с фотографией"
                  dependent={AttachmentDeps.Driver}
                  dependentId={driver.id}
                  type={DocumentTypes.dlDocumentDriverPassport}
                />
                <UiAttachmentsInput
                  mt={4}
                  label="2. Водительское удостоверение, обе стороны в одном файле на одной странице"
                  dependent={AttachmentDeps.Driver}
                  dependentId={driver.id}
                  type={DocumentTypes.dlDocumentDriverLicense}
                />
                <UiAttachmentsInput
                  mt={4}
                  label="3. Трудовой договор (если водитель является владельцем ИП, то документы ОГРНИП)"
                  dependent={AttachmentDeps.Driver}
                  dependentId={driver.id}
                  type={DocumentTypes.dlDocumentDriverContract}
                />
              </div>
            </UiScrollbars>
          </div>
          <SubmitButtonsGroup justifyContent="right" mr={2} mt={4} form={form} isDisabled={submitting} />
        </form>
      )}
    />
  );
};
