import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthFormView } from '@/views/auth-form-view';
import { useCheckAuth } from '@/features/account/hooks/use-check-auth';
import './login-page.scss';

export const LoginPage: FC = () => {
  const { redirectTo } = useCheckAuth();
  if (redirectTo) {
    return <Navigate to={redirectTo} />;
  }
  return (
    <div className="login-page">
      <div className="login-page-content">
        <div className="login-page-header">
          <div className="login-page-title">Intelogis</div>
          <div className="login-page-subtitle">Цифровой экспедитор</div>
        </div>
        <AuthFormView />
      </div>
    </div>
  );
};
