import { FC } from 'react';
import { Grid, GridItem, Text } from '@chakra-ui/react';
import { VerificationFormMode } from 'components/verification-forms/types';
import { UiInputField } from '@/ui-elements';
import { UiPhoneField } from '@/ui-elements/form-fields/ui-phone-field/ui-phone-field';

export const CarrierContactsSubform: FC<{ mode: VerificationFormMode }> = ({ mode }) => {
  return (
    <>
      <Text fontSize={18} fontWeight={700} mt={6}>
        Контакты
      </Text>

      <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={3} className="carriers-verification-form">
        <GridItem>
          <UiInputField
            name="contactName"
            label="Контактное лицо от организации"
            placeholder="Контактное лицо"
            required
            isDisabled={mode === 'check'}
          />
        </GridItem>
        <GridItem>
          <UiPhoneField name="phone" label="Телефон контактного лица" placeholder="Телефон контактного лица" required isDisabled={mode === 'check'} />
        </GridItem>
      </Grid>
    </>
  );
};
