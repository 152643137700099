import moment from 'moment/moment';
import { MOMENT_DD_MM_YYYY } from './constants';

// Метод для вычисления даты от текущей (например, дата неделю назад)
export const durationToDates = (days: number) => {
  const currentDate = moment(); // Current date
  const pastDate = currentDate.clone().subtract(days, 'days'); // Date some days ago

  return pastDate.format(MOMENT_DD_MM_YYYY);
};
