import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { EMPTY_STRING } from '@/config/constants/common-strings';
import { DocumentTemplateNames } from '@/config/constants/document-types';
import { OrganizationDocumentType } from '@/api/documents/documents-types';
import { SettingsTemplateResponse } from '@/types/api/settings-partial';
import { getTypedObjectKeys } from '@/helpers/types/get-types-object-keys';

type Props = {
  settingsTemplates?: SettingsTemplateResponse;
  organizationDocumentType: OrganizationDocumentType;
};

export const getSettingsTemplatesOptions = ({ settingsTemplates, organizationDocumentType }: Props) => {
  if (!settingsTemplates) return [];

  const organizationTypeTemplates = settingsTemplates[organizationDocumentType];

  return getTypedObjectKeys<DocumentTemplateNames>(organizationTypeTemplates).reduce((templatesOptions, documentType) => {
    const option = {
      value: documentType,
      label: organizationTypeTemplates[documentType] ?? EMPTY_STRING,
    };

    templatesOptions.push(option);

    return templatesOptions;
  }, [] as Array<SelectOption>);
};
