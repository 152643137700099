import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { UiButtonFilled } from '@/ui-elements';
import { emptyFunction } from '@/helpers/empty-function';

/*
 * Кнопка создания заявки
 * */

type Props = {
  handleAddOrder: () => void;
  isLoading?: boolean;
};
export const CreateOrderFromCustomersButton: FC<Props> = ({ handleAddOrder = emptyFunction, isLoading }) => {
  return (
    <Flex mt="48px" justifyContent="center">
      <UiButtonFilled disabled={isLoading} onClick={handleAddOrder}>
        Создать заявку
      </UiButtonFilled>
    </Flex>
  );
};
