import './document-template-contract-form.scss';
import { FC, MouseEventHandler } from 'react';
import { Box, Text, VStack } from '@chakra-ui/react';
import { useForm } from '@/helpers/forms/use-form';
import { useIcons } from '@/hooks/use-icons';
import { UiButtonOutlined } from '@/ui-elements';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { TemplateContractFormValues } from '@/features/contract-template/types/form';
import { DocumentTemplateContractSubform } from '@/components/document-template-viewer/contract-form/document-template-contract-form-subform';

type DocumentTemplateContractFormProps = {
  isEditForm: boolean;
  routesAmount?: number;
  toggleEditForm: MouseEventHandler<HTMLButtonElement>;
  submitEditForm: (values: TemplateContractFormValues) => void;
};

export const DocumentTemplateContractForm: FC<DocumentTemplateContractFormProps> = ({ isEditForm, routesAmount, toggleEditForm, submitEditForm }) => {
  const Form = useForm<TemplateContractFormValues>();
  const { EditIcon, CreateOrder } = useIcons();

  if (!routesAmount) return null;

  return (
    <Box className="document-template-contract-form">
      <Form onSubmit={submitEditForm} keepDirtyOnReinitialize>
        <VStack alignItems="stretch">
          <Text pb={2} mt={2} fontWeight={700} fontSize={18}>
            Вы можете заполнить дополнительную информацию
          </Text>
          <Box h="50vh">
            <UiScrollbars autoHide>
              <DocumentTemplateContractSubform routesAmount={routesAmount} disabled={!isEditForm} />
            </UiScrollbars>
          </Box>
        </VStack>
        <Box py="2">
          {isEditForm ? (
            <UiButtonOutlined leftIcon={CreateOrder} type="submit">
              Сохранить
            </UiButtonOutlined>
          ) : (
            <UiButtonOutlined leftIcon={EditIcon} onClick={toggleEditForm}>
              Редактировать
            </UiButtonOutlined>
          )}
        </Box>
      </Form>
    </Box>
  );
};
