import { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { UiCheckboxField, UiTextarea } from '@/ui-elements';

export const DriverSecurityFieldsSubform: FC<{ comment: string; setComment: (arg: string) => void }> = ({ comment, setComment }) => {
  return (
    <>
      <Box mt={-1}>
        <UiCheckboxField name="convictions" label="Наличие судимости" />
      </Box>
      <Box mt={4}>
        <UiCheckboxField name="loans" label="Наличие кредитов" />
      </Box>
      <Box mt={4}>
        <UiCheckboxField name="speedFines" label="Наличие штрафов за превышение скорости" />
      </Box>
      <Box mt={4}>
        <UiCheckboxField name="trafficFines" label="Наличие штрафов за нарушение ПДД" />
      </Box>
      <Box mt={4}>
        <UiCheckboxField name="accidents" label="Наличие зарегистрированных ДТП за 3 года (по вине водителя)" />
      </Box>
      <Box mt={4}>
        <UiCheckboxField name="driversLicenseRevoked" label="Лишение прав" />
      </Box>

      <Box mt={4} mb={6}>
        <UiTextarea
          className="carriers-verification-form__comment"
          label="Комментарии"
          variant="noResize"
          placeholder="Введите текст"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Box>
    </>
  );
};
