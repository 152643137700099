import { BookerOrderFilter } from '@/types/api/booker-order-partial';
import { BookerOrdersApi } from '@/api/booker-orders';
import { useInfiniteQueryWithUpdates } from '@/hooks/use-infinite-query-with-updates';

export const useAllBookerOrdersUpdates = (query?: BookerOrderFilter, perPage: number = 10) => {
  const queryFn =
    (isFirst: Boolean) =>
    ({ pageParam = 1 }) =>
      BookerOrdersApi.getAllBookerOrders({ ...query, updates: !isFirst, perPage, page: pageParam });

  return useInfiniteQueryWithUpdates({
    queryKey: 'allBookerOrders',
    query,
    refetchOnWindowFocus: false,
    queryFn,
  });
};
