import { Dictionary } from 'config/constants/dictionary';
import qs from 'qs';
import { ApiGet, ApiPatch, ApiPost } from '@/helpers/api/methods';
import { CustomerEdit, CustomerFilter } from '@/types/api/customer-partial';
import { ResponseWithoutPagerSimple, ResponseWithPager } from '@/types/models/pager';
import { Customer } from '@/types/models/customer';
import { stringifyQuery } from '@/helpers/validate-query-string';

export const CustomersApi = {
  getAllCustomers: (query?: CustomerFilter) => ApiGet<ResponseWithPager<Customer>>(`${Dictionary.Customers}${stringifyQuery(query)}`),

  getCustomer: (id: number, updates?: boolean) => ApiGet<ResponseWithoutPagerSimple<Customer>>(`${Dictionary.Customers}/${id}?updates=${!!updates}`),

  addCustomer: (options: CustomerEdit) => ApiPost<ResponseWithoutPagerSimple<Customer>>(`${Dictionary.Customers}`, options),

  getCustomersEnum: (search?: string, updates?: boolean) =>
    ApiGet<ResponseWithoutPagerSimple<Record<number, string>>>(
      `${Dictionary.Customers}/enum${search ? `?search=${search}` : ''}${search ? `&updates=${!!updates}` : `?updates=${!!updates}`}`
    ), // возвращает список клиентов без постраничной разбивки для селекта

  getCarriersEnumWithExtras: ({ query, updates, extraParams }: { query: string; updates?: boolean; extraParams?: Array<string> }) =>
    ApiGet<ResponseWithoutPagerSimple<Record<string, string>>>(
      `${Dictionary.Customers}/enum${qs.stringify(
        { search: query, extraParams, updates },
        {
          addQueryPrefix: true,
          skipNulls: true,
          arrayFormat: 'comma',
        }
      )}`
    ),

  updateCustomer: (id: number, approvals: boolean, options: CustomerEdit) =>
    ApiPatch<ResponseWithoutPagerSimple<Customer>>(`${Dictionary.Customers}/${id}?approvals=${approvals}`, options),
};
