import { FC, InputHTMLAttributes, ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import './ui-toggle.scss';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  children?: ReactElement | ReactNode;
  className?: string;
  disabled?: boolean;
  isInvalid?: boolean;
  checked?: boolean;
};

export const UiToggle: FC<Props> = ({ disabled, isInvalid, className, children, checked, ...props }) => (
  <label className={classNames('ui-toggle', className, { error: isInvalid })}>
    <input className="ui-toggle-checkbox" disabled={disabled} type="checkbox" checked={checked} {...props} />
    <div className="ui-toggle-switch" />
    <span className="ui-toggle-label">{children}</span>
  </label>
);
