import './resources-statuses-list.scss';
import { isEmpty } from 'lodash';
import { VStack } from '@chakra-ui/react';
import { VerificationStatus } from '@/config/constants/statuses';
import { ResourcesCardStatus } from '@/components/resources/card-status/resources-card-status';

export const ResourcesStatusesList = ({ approvalStatuses }: { approvalStatuses?: Array<VerificationStatus> }) => {
  const hasEmptyStatuses = isEmpty(approvalStatuses) || approvalStatuses?.every((approvalStatus) => !approvalStatus?.status);
  if (hasEmptyStatuses) return null;

  return (
    <VStack spacing="2" className="resources-statuses-list">
      {approvalStatuses?.map((approvalStatus) => (
        <ResourcesCardStatus status={approvalStatus} />
      ))}
    </VStack>
  );
};
