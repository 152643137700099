import { useMutation } from '@tanstack/react-query';
import { CarriersApi } from '@/api/carriers';
import { CarrierReview } from '@/types/api/carrier-partial';
import { CarrierEdit } from '@/types/models/carrier';

export const useCarrierActions = (id: number) => {
  const addCarrier = useMutation({
    mutationKey: ['addCarrier', id],
    mutationFn: (body: CarrierEdit) => CarriersApi.addCarrier(body),
  });

  const updateCarrier = useMutation({
    mutationKey: ['updateCarrier', id],
    mutationFn: (body: CarrierEdit) => CarriersApi.updateCarrier(id, true, body),
  });
  const updateCarrierWithoutApprovals = useMutation({
    mutationKey: ['updateCarrier', id],
    mutationFn: (body: CarrierEdit) => CarriersApi.updateCarrier(id, false, body),
  });

  /**
   * Deprecated
   */
  const addCarrierReview = useMutation({
    mutationKey: ['addCarrierReview', id],
    mutationFn: (options: CarrierReview) => CarriersApi.addCarrierReview(id, options),
  });
  const deleteCarrier = useMutation({
    mutationKey: ['deleteCarrier', id],
    mutationFn: (carrierId: number | undefined) => CarriersApi.deleteCarrier(carrierId!),
  });

  return { addCarrier, updateCarrier, addCarrierReview, deleteCarrier, updateCarrierWithoutApprovals };
};
