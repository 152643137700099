import { useState } from 'react';
import { toast } from 'react-toastify';
import { AddressAutoCompleteOptions } from '@/helpers/api/normalize-address-auto-complete-response';
import { getAddressFetcher } from '@/api/fetch-address';
import { ADDRESS_SEARCH_ERROR } from '@/ui-elements/inputs/ui-address-search/constants';

export const useAddressItems = ({ threshold = 2 }: { threshold?: number }) => {
  const [items, setItems] = useState<AddressAutoCompleteOptions>([]);

  const handleOnSearch = async (inputValue: string) => {
    try {
      if (inputValue && inputValue.length > threshold) {
        const options = await getAddressFetcher(inputValue);
        setItems(options);
      }
    } catch (error) {
      toast.error(ADDRESS_SEARCH_ERROR);
    }
  };

  return {
    items,
    handleOnSearch,
  };
};
