import './load-route.scss';
import { Load } from '@/types/models/loads';
import { ROUTE } from '@/features/order/texts';
import { LoadPointWithIcon } from '@/components/loads/point-with-icon/load-point-with-icon';

export const LoadsRoute = ({ load }: { load: Load }) => {
  const { points } = load;
  return (
    <div className="load-route">
      <div className="load-route__caption">{ROUTE}</div>
      {points &&
        points.map((point, index) => <LoadPointWithIcon key={point.id} point={point} isLastPoint={index === points.length - 1} load={load} />)}
    </div>
  );
};
