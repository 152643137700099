import './resources-vehicle-specifications.scss';
import { HStack, Text } from '@chakra-ui/react';
import { Vehicle } from '@/types/models/vehicle';
import { useIcons } from '@/hooks/use-icons';

/* Грузоподъемность, Объем, Размер */
export const ResourcesVehicleSpecifications = ({ vehicle }: { vehicle?: Partial<Vehicle> }) => {
  const { VolumeIcon, MaxTonage, Dimensions } = useIcons();
  if (!vehicle?.capacity && !vehicle?.volume && !vehicle?.dimensions) {
    return null;
  }

  return (
    <HStack className="resources-vehicle-specifications">
      {vehicle?.capacity && (
        <HStack className="resources-vehicle-specifications__divider-group">
          <MaxTonage />
          <Text className="resources-vehicle-specifications__text">{`${vehicle?.capacity} т`}</Text>
        </HStack>
      )}
      {vehicle?.volume && (
        <HStack className="resources-vehicle-specifications__divider-group">
          <VolumeIcon />
          <Text className="resources-vehicle-specifications__text">{`${vehicle?.volume} м³`}</Text>
        </HStack>
      )}
      {vehicle?.dimensions && (
        <HStack>
          <Dimensions />
          <Text className="resources-vehicle-specifications__text">{vehicle?.dimensions}</Text>
        </HStack>
      )}
    </HStack>
  );
};
