import { useNavigate } from 'react-router-dom';
import { stringifyUrl } from 'query-string';
import { useEffect } from 'react';
import { Order } from '@/types/models/order';
import { useLocationTyped } from '@/hooks/use-location-typed';
import { CarrierLoadsPageWithOrders, useCarrierLoadActions } from '@/features/carriers-loads/hooks/use-carriers-loads-actions';

export const useCarriersLoadsRoutesPage = (
  orders?: Array<Order>,
  activeOrderId?: number,
  redirectId?: number,
  page?: CarrierLoadsPageWithOrders,
  isAuth = false
) => {
  const { clearState, selectOrderForPage } = useCarrierLoadActions(page);
  const navigate = useNavigate();
  const { pathname: url } = useLocationTyped();

  useEffect(() => {
    if (!activeOrderId && orders?.length && !redirectId && isAuth) {
      const [order] = orders;
      const { id } = order;
      selectOrderForPage(id);
      const to = stringifyUrl({
        url,
        query: { id },
      });
      navigate(to);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, activeOrderId, selectOrderForPage, redirectId]);

  useEffect(
    () =>
      // при переключении на другую страницу сбрасывает store с настройками страницы
      () =>
        clearState(),
    [clearState]
  );
};
