import { FC } from 'react';
import { NO_ORDERS, SELECT_ORDER } from 'components/order-details/order-details-empty/texts';
import { useIcons } from '@/hooks/use-icons';
import './order-details-empty.scss';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';

/*
 *  Сайдбар, когда не выбрана ни одна заявка
 * */
export const OrderDetailsEmpty: FC<{ isLoading: boolean; isEmpty: boolean }> = ({ isLoading = false, isEmpty = false }) => {
  const { ClaimIcon64 } = useIcons();
  return (
    <div className="order-details-empty">
      {isLoading ? (
        <div className="order-details-empty__spinner">
          <UiSpinner visible loadingMessage="Загрузка заказа..." />
        </div>
      ) : (
        <div className="order-details-empty__caption">
          <ClaimIcon64 />
          {isEmpty ? NO_ORDERS : SELECT_ORDER}
        </div>
      )}
    </div>
  );
};
