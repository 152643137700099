import { FC } from 'react';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { UiDivider } from '@/ui-elements';
import { Carrier } from '@/types/models/carrier';
import { DriverTab } from '../driver-widget/driver-tab';

export const CarrierDriverTab: FC<{ carrier: Carrier }> = ({ carrier }) => {
  return (
    <UiScrollbars style={{ width: '100%', height: '480px' }}>
      <div className="carrier-widget__driver">
        {carrier.drivers.map((driver) => (
          <>
            <DriverTab isScroll={false} driver={driver} />
            <UiDivider pt="25px" variant="dark" mt={3} mb={3} />
          </>
        ))}
      </div>
    </UiScrollbars>
  );
};
