import { HStack } from '@chakra-ui/react';
import { FC } from 'react';
import {
  DECLINE_APPROVALS_COUNT_CAPTION,
  EXPORT,
  PRINT,
  SUCCESS_APPROVALS_COUNT_CAPTION,
  TOTAL_APPROVALS_COUNT_CAPTION,
} from 'components/security/security-header-component/texts';
import { PeriodPicker } from '@/components';
import { UiButtonsGroup, UiDatePickerInput, UiTextButton } from '@/ui-elements';
import { useIcons } from '@/hooks/use-icons';
import './security-header-component.scss';
import { UiStatisticBlock } from '@/ui-elements/ui-statistic-block/ui-statistic-block';
import { Metrics } from '@/types/models/metrics';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';

type Props = {
  onClickRangeButton: (value: string) => void;
  selectedRangeButton: string;
  onChangeCalendarValue: (range: [from: Date, to: Date]) => void;
  selectedRange?: [from: Date, to: Date];
  metrics?: ResponseWithoutPagerSimple<Metrics>;
};
export const SecurityHeaderComponent: FC<Props> = ({ onClickRangeButton, selectedRangeButton, onChangeCalendarValue, selectedRange, metrics }) => {
  const { PrintIcon, ExportIcon } = useIcons();
  return (
    <div className="security-header-component">
      <HStack w="full" px={6} className="security-header-component__body" justifyContent="space-evenly">
        <PeriodPicker value={selectedRangeButton} onChange={onClickRangeButton} />
        <UiDatePickerInput value={selectedRange} onChange={onChangeCalendarValue} isMulti className="security-header-component__datepicker" />
        <HStack>
          <UiStatisticBlock title={TOTAL_APPROVALS_COUNT_CAPTION} number={metrics?.data?.total || 0} level="checked" />
          <UiStatisticBlock title={SUCCESS_APPROVALS_COUNT_CAPTION} number={metrics?.data?.approved || 0} />
          <UiStatisticBlock title={DECLINE_APPROVALS_COUNT_CAPTION} number={metrics?.data?.declined || 0} level="rejected" />
        </HStack>

        <UiButtonsGroup
          className="security-header-component__buttons"
          spacing={5}
          buttons={[<UiTextButton leftIcon={ExportIcon}>{EXPORT}</UiTextButton>, <UiTextButton leftIcon={PrintIcon}>{PRINT}</UiTextButton>]}
        />
      </HStack>
    </div>
  );
};
