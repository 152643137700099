import { isEmpty } from 'lodash';
import { Flex } from '@chakra-ui/react';
import { VerificationStatus } from '@/config/constants/statuses';
import { MyOrganizationApprovalStatus } from '@/components/my-organization/my-organization-approval-status';

export const MyOrganizationStatusesList = ({ approvalStatuses }: { approvalStatuses?: Array<VerificationStatus> }) => {
  const hasEmptyStatuses = isEmpty(approvalStatuses) || approvalStatuses?.every((approvalStatus) => !approvalStatus?.status);
  if (hasEmptyStatuses) return null;

  return (
    <Flex direction="column">
      {approvalStatuses?.map((approvalStatus) => (
        <MyOrganizationApprovalStatus approvalStatus={approvalStatus} />
      ))}
    </Flex>
  );
};
