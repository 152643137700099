import { FC, useState } from 'react';
import { Box, FormErrorMessage } from '@chakra-ui/react';
import { useField } from 'react-final-form';
import { UiRatingStars } from '@/ui-elements/inputs/ui-rating-stars/ui-rating-stars';
import './ui-rating-stars-field.scss';

type Props = {
  name: string;
  blacklist?: boolean;
};

export const UiRatingStarsField: FC<Props> = ({ name, blacklist }) => {
  const [rating, setRating] = useState<number | undefined>(undefined); // нужен только для отслеживания желтых звездочек
  const {
    input,
    meta: { error },
  } = useField(name);
  const invalid = error || Boolean(input.value);

  const mappedRatedStars = blacklist ? <UiRatingStars /> : <UiRatingStars rating={rating} setRating={setRating} formName="ratingValue" form />;

  return (
    <Box>
      <ul className={`ui-stars__wrapper ${blacklist ? 'ui-stars__grayed' : 'ui-stars__starred'}`}>{mappedRatedStars}</ul>
      {invalid && <FormErrorMessage>{error}</FormErrorMessage>}
    </Box>
  );
};
