import { useQuery } from '@tanstack/react-query';
import { CarriersApi } from '@/api/carriers';
import { DateNPageFilter } from '@/types/api/common-partial';

export const useCarrierReviews = (id?: number, query?: DateNPageFilter) => {
  const carrierReviews = useQuery({
    queryKey: ['carrierReviews', id],
    refetchOnWindowFocus: false,
    enabled: !!id,
    queryFn: id ? () => CarriersApi.getCarrierReviews(id, query) : undefined,
  });
  return { carrierReviews };
};
