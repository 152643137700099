import { FormMode, VerificationFormMode, VerificationProps } from 'components/verification-forms/types';
import { useState } from 'react';
import { formValuesToDriver } from 'components/verification-forms/helpers/form-values-to-driver';
import { driverFields, driverFieldsToTransform } from 'components/verification-forms/constants';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { AttachmentDeps } from 'config/constants/types';
import { DocumentTypes } from 'config/constants/document-types';
import { useForm } from '@/helpers/forms/use-form';
import { DriverVerification, DriverVerificationForm as DriverVerificationFormFields } from '@/types/models/driver-verification';
import { useAttachmentsActions } from '@/features/attachments/hooks/use-attachments-actions';
import { useCreateApproval } from '@/features/approvals/hooks/use-create-approval';
import { useAccount } from '@/features/account/hooks/use-account';
import { useDriverActions } from '@/features/drivers/hooks/use-driver-actions';
import { useCarrierInvalidate } from '@/features/carriers/hooks/use-carrier-invalidate';
import { useOutsideCalendarClick } from '@/hooks/useOutsideRef';
import { formatFormFields } from '@/helpers/forms/format-form-fields';
import { selectOptionToValue } from '@/helpers/forms/select-option-to-value';
import { validateForm } from '@/helpers/forms/validate';
import { createDriversSchema } from '@/helpers/forms/validation-schemas/create-drivers-form';
import { createDriversSchemaWithoutPassport } from '@/helpers/forms/validation-schemas/create-drivers-form-without-passport';

export const useDriverVerificationForm = ({
  closeModal,
  mode,
  driver,
  onClose,
}: {
  onClose?: VoidFunction;
  closeModal?: VoidFunction;
  mode: VerificationProps<DriverVerificationFormFields, DriverVerificationFormFields>['mode'];
  driver: VerificationProps<DriverVerificationFormFields, DriverVerificationFormFields>['driver'];
}) => {
  const Form = useForm<DriverVerificationFormFields>();

  const isCheckMode = mode === FormMode.check;
  const isEditMode = mode === FormMode.edit;

  const { postAttachment } = useAttachmentsActions();
  const { createApproval } = useCreateApproval();
  const { currentAccount } = useAccount();
  const [passportForm, setPassportForm] = useState(driver?.russianPassport !== undefined ? driver?.russianPassport : true);
  const [comment, setComment] = useState('');
  const { updateDriver, addDriver } = useDriverActions(driver?.id);
  const { allCarriersInvalidate, carrierInvalidate } = useCarrierInvalidate();
  const { outsideRef: calendarRef1 } = useOutsideCalendarClick();
  const { outsideRef: calendarRef2 } = useOutsideCalendarClick();
  const { outsideRef: calendarRef3 } = useOutsideCalendarClick();

  const onUpdate = (values: DriverVerificationFormFields) => {
    const newValues = formValuesToDriver(values, passportForm);
    updateDriver.mutate(newValues, {
      onSuccess: () => {
        createApproval.mutate(
          {
            carrierId: values.carrierId?.value,
            driverId: driver?.id,
            departmentId: currentAccount?.user?.department?.id,
          },
          {
            onSuccess: () => {
              carrierInvalidate(driver?.carrierId);
              allCarriersInvalidate();
              closeModal?.();
            },
          }
        );
      },
    });
  };

  const onApprove = (values: any) => {
    const newValues = formatFormFields(selectOptionToValue(values, driverFieldsToTransform), 'pick', driverFields);
    updateDriver.mutate(newValues as DriverVerification);
    onClose?.();
  };

  const onCreate = (values: DriverVerificationFormFields) => {
    addDriver.mutate(formValuesToDriver(values, passportForm), {
      onSuccess: (response) => {
        const { id } = response.data;
        createApproval.mutate(
          {
            carrierId: values?.carrierId?.value,
            driverId: id,
            departmentId: currentAccount?.user?.department?.id,
            comment: comment || EMPTY_STRING,
          },
          {
            onSuccess: () => {
              carrierInvalidate(values?.carrierId?.value);
              allCarriersInvalidate();
              onClose?.();
            },
          }
        );
        if (id) {
          values.passportDoc?.map((file: File) => {
            postAttachment.mutate({
              attachment: file,
              dependent: AttachmentDeps.Driver,
              dependentID: id,
              subject: DocumentTypes.dlDocumentDriverPassport,
            });
          });
          values.driversLicenseDoc?.map((file: File) => {
            postAttachment.mutate({
              attachment: file,
              dependent: AttachmentDeps.Driver,
              dependentID: id,
              subject: DocumentTypes.dlDocumentDriverLicense,
            });
          });
          values.laborContractDoc?.map((file: File) => {
            postAttachment.mutate({
              attachment: file,
              dependent: AttachmentDeps.Driver,
              dependentID: id,
              subject: DocumentTypes.dlDocumentDriverContract,
            });
          });
        }
      },
    });
  };

  const submitting = isCheckMode || isEditMode ? updateDriver.isLoading : addDriver.isLoading;

  const submitHandler = (formMode: VerificationFormMode) => {
    switch (formMode) {
      case FormMode.check:
        return onApprove;
      case FormMode.create:
        return onCreate;
      case FormMode.edit:
        return onUpdate;
      default:
        return onApprove;
    }
  };
  const validate = validateForm<DriverVerificationFormFields>(createDriversSchema);
  const validateWithoutPassport = validateForm<DriverVerificationFormFields>(createDriversSchemaWithoutPassport);

  return {
    Form,
    submitHandler,
    passportForm,
    validate,
    validateWithoutPassport,
    isCheckMode,
    setPassportForm,
    calendarRef1,
    calendarRef2,
    calendarRef3,
    comment,
    setComment,
    submitting,
  };
};
