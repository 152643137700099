import { UiSelectField } from '@/ui-elements';
import { VerificationFormMode } from '@/components/verification-forms/types';
import { useGetMyOrganizationTypes } from '@/features/my-organization/use-get-organization-types';

export const CarrierOrganizationType = ({ mode }: { mode: VerificationFormMode }) => {
  const { organizationTypes } = useGetMyOrganizationTypes();
  return (
    <UiSelectField
      options={organizationTypes}
      name="organizationTypeID"
      label="Тип организации"
      placeholder="Тип"
      required
      isDisabled={mode === 'check'}
    />
  );
};
