import { isEmpty } from 'lodash';
import { Form, Field } from 'react-final-form';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { FormValues } from 'components/verification-forms/types';
import { DocumentTypes } from 'config/constants/document-types';
import { AttachmentDeps } from 'config/constants/types';
import { Box, HStack, Text } from '@chakra-ui/react';
import { ownTypeLabels } from 'components/verification-forms/constants';
import '../verification-form.scss';
import { UiCheckboxField, UiTextareaField, UiButtonOutlined, UiButtonFilled, UiInputField, UiIconButton, UiAttachmentsInput } from '@/ui-elements';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { Vehicle } from '@/types/models/vehicle';
import { useVehicleActions } from '@/features/vehicles/hooks/use-vehicle-actions';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useIcons } from '@/hooks/use-icons';
import { vehicleToApprovalForm } from '@/components/verification-forms/helpers/vehicle-to-approval-form';
import { UiSwitcher } from '@/ui-elements/switcher/switcher';
import { TrailerBlock } from '@/components/verification-forms/vehicle-verification/subforms/trailer-block';
import { VehicleTypesByIdsSelector } from '@/features/vehicle-types/selectors/vehicle-types-by-ids-selector';
import { Carrier } from '@/types/models/carrier';
import { ApprovalBody } from '@/types/models/approval';
import { DependentType } from '@/api/documents/dependent-types';
import { useVehicleTypesNames } from '@/features/vehicle-types/hooks/use-vehicle-types-names';

type Props = {
  onApprovalDeclined?: (approvalBody: ApprovalBody) => void;
  onApprovalApproved?: (approvalBody: ApprovalBody) => void;
  onClose?: VoidFunction; // закрытие модалки проверки
  vehicle: Vehicle;
  carrier: Carrier;
};

export const ApproveVehicleForm = ({ onApprovalApproved, onApprovalDeclined, onClose, vehicle, carrier }: Props) => {
  const { trailers = [] } = vehicle;
  const { CloseIcon } = useIcons();
  const vehicleTypeNames = useVehicleTypesNames();
  const vehicleTypeName = vehicle?.vehicleType ? vehicleTypeNames[vehicle?.vehicleType] : '';
  const initialValues = vehicleToApprovalForm({ carrier, vehicle, vehicleTypeName });
  const { updateVehicleWithoutApprovals } = useVehicleActions(vehicle.id);
  const vehicleTypes = useAppSelector(VehicleTypesByIdsSelector(trailers.map((trailer) => trailer.vehicleType)));
  const vehicleTrailers = (vehicle.trailers || []).map((trailer, index) => ({ ...trailer, type: vehicleTypes[index] }));

  const onApprove = (values: FormValues) => {
    const newValues = omitBy(
      {
        accidentsInPastThreeYears: values.accidentsInPastThreeYears,
        bailed: values.bailed,
        casco: values.casco,
        ownType: values.ownType ? Number(values.ownType) : undefined,
        permanentTracking: values.permanentTracking,
        tracker: values.tracker,
      },
      isNil
    );
    updateVehicleWithoutApprovals.mutate(newValues as any, {
      onSuccess: () => {
        onApprovalApproved?.({
          dependent: DependentType.Vehicle,
          dependentId: vehicle.id,
          comment: values.comment,
        });
      },
    });
  };

  const onDecline = (values: FormValues) => {
    onApprovalDeclined?.({
      dependent: DependentType.Vehicle,
      dependentId: vehicle.id,
      comment: values.comment,
    });
  };

  const onSubmit = (values: FormValues) => (values.action === 'approve' ? onApprove(values) : onDecline(values));

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Text className="carriers-verification-form__title">Вы проверяете транспортное средство:</Text>
          <Box pos="absolute" right={5} top={0}>
            <UiIconButton aria-label="close" icon={CloseIcon} onClick={onClose} />
          </Box>
          <div className="carriers-verification-form__body">
            <UiScrollbars autoHide>
              <div className="carriers-verification-form__main">
                <HStack mt={4}>
                  <UiInputField name="carrierName" label="К какой организации относится" isDisabled />
                  <UiInputField name="vehicleType" label="Тип ТС" isDisabled />
                </HStack>
                <HStack>
                  {isEmpty(initialValues.trailers) && <UiInputField name="volume" label="Грузоподъемность в тоннах" isDisabled />}
                  {isEmpty(initialValues.trailers) && <UiInputField name="dimensions" label="Размеры (ДхШхВ) в метрах" isDisabled />}
                </HStack>
                <UiInputField
                  name="regNumber"
                  label={isEmpty(initialValues.trailers) ? 'Регистрационный номер' : 'Регистрационный номер Тягач'}
                  isDisabled
                />
                <Box mt={-1} mb={4}>
                  <UiCheckboxField
                    defaultChecked={initialValues.accidentsInPastThreeYears}
                    name="accidentsInPastThreeYears"
                    label="Участвовал в ДТП за последние 3 года"
                  />
                </Box>
                <UiTextareaField
                  className="carriers-verification-form__comment"
                  label="Комментарии"
                  variant="noResize"
                  placeholder="Введите текст"
                  name="comment"
                />
                <TrailerBlock trailers={vehicleTrailers} />

                <Text textStyle="h7" mt={4}>
                  Право владения
                </Text>

                <Box mt={3}>
                  <UiCheckboxField defaultChecked={initialValues.bailed} name="bailed" label="Находится в залоге" />
                </Box>
                <Box mt={4}>
                  <UiCheckboxField defaultChecked={initialValues.casco} name="casco" label="ТС застраховано КАСКО" />
                </Box>

                <Box mt={4}>
                  <Field name="ownType">
                    {({ input }) => (
                      <UiSwitcher
                        className="carriers-verification-form__switcher-long"
                        {...input}
                        tabsList={ownTypeLabels}
                        defaultActiveTab={ownTypeLabels[initialValues.ownType || 0].key}
                      />
                    )}
                  </Field>
                </Box>
                <UiAttachmentsInput
                  mt={4}
                  label="Загрузите скан договора"
                  dependent={AttachmentDeps.Vehicle}
                  dependentId={vehicle.id}
                  type={DocumentTypes.dlDocumentVehicleContract}
                />
                <Box mt={4}>
                  <Text textStyle="h7">Трекинг</Text>
                </Box>

                <Box mt={4}>
                  <UiCheckboxField
                    defaultChecked={initialValues.tracker}
                    name="tracker"
                    label="Есть постоянно подключенное бортовое устройство слежения"
                  />
                </Box>
                <Box mt={4}>
                  <UiCheckboxField
                    defaultChecked={initialValues.permanentTracking}
                    name="permanentTracking"
                    label="Готов предоставить полный и постоянный доступ к треку"
                  />
                </Box>

                <Text textStyle="h7" mt={4}>
                  Документы
                </Text>
                <Text mt={4}>Свидетельство о регистрации транспортного средства (СТС)</Text>
                <UiAttachmentsInput
                  mt={4}
                  label="1. Лицевая сторона, с информацией про ТС (рег. номер, марка, модель и т.д.)"
                  dependent={AttachmentDeps.Vehicle}
                  dependentId={vehicle.id}
                  type={DocumentTypes.dlDocumentVehicleFrontDoc}
                />
                <UiAttachmentsInput
                  mt={3}
                  label="2. Обратная сторона, с информацией про собственника (ФИО и т.д.)"
                  dependent={AttachmentDeps.Vehicle}
                  dependentId={vehicle.id}
                  type={DocumentTypes.dlDocumentVehicleBackDoc}
                />
                <UiAttachmentsInput
                  mt={3}
                  label="3. Паспорт транспортного средства, ПТС (Необязательно)"
                  dependent={AttachmentDeps.Vehicle}
                  dependentId={vehicle.id}
                  type={DocumentTypes.dlDocumentVehicleVDPDoc}
                />
                <UiAttachmentsInput
                  mt={3}
                  label="4. Другие документы"
                  dependent={AttachmentDeps.Vehicle}
                  dependentId={vehicle.id}
                  type={DocumentTypes.dlDocumentVehicleExtraDoc}
                />
              </div>
            </UiScrollbars>
          </div>
          <HStack justifyContent="right" mr={2} mt={4}>
            <UiButtonOutlined
              className="carriers-verification-form__btn-decline"
              mode="light"
              type="submit"
              onClick={() => form.change('action', 'decline')}
              w={128}
              isDisabled={submitting}
            >
              Отклонить
            </UiButtonOutlined>
            <UiButtonFilled
              className="carriers-verification-form__btn-confirm"
              mode="dark"
              type="submit"
              w={200}
              onClick={() => form.change('action', 'approve')}
              isDisabled={submitting}
            >
              Принять
            </UiButtonFilled>
          </HStack>
        </form>
      )}
    />
  );
};
