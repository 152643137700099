import { useQueryClient } from '@tanstack/react-query';
import { FuelStatus } from 'config/constants/statuses';
import { useOrderDataActions } from '@/features/order/hooks/use-order-data-actions';
import { Order } from '@/types/models/order';
import { checkOrderNextFuelStatus } from '../helpers/check-order-fuel-next-status';
import { Load } from '@/types/models/loads';

export const useOrderFuelStatus = (order?: Order | Load) => {
  const { updateOrderStatus } = useOrderDataActions(order?.id!);
  const client = useQueryClient();

  const setNewOrderFuelStatus = (nextStatus?: FuelStatus | undefined, onSuccess?: VoidFunction) => {
    if (!order) return;

    if (checkOrderNextFuelStatus(order, nextStatus)) {
      updateOrderStatus.mutate(
        { fuelStatus: nextStatus },
        {
          onSuccess: () => {
            client.invalidateQueries(['order', order.id]);
            client.invalidateQueries(['allOrders']);
            client.invalidateQueries(['ordersFuelCount']);
            onSuccess?.();
          },
        }
      );
    }
  };

  return { setNewOrderFuelStatus };
};
