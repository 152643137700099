import { OrderLoadType } from '@/config/constants/types';

export enum PointStatus {
  Waiting = 'waiting',
  Proceeding = 'proceeding',
  Completed = 'completed',
  Problems = 'problems',
}

export const StatusesNames: Record<PointStatus, string> = {
  [PointStatus.Problems]: 'Возникли проблемы',
  [PointStatus.Waiting]: 'Ожидает',
  [PointStatus.Proceeding]: 'В процессе',
  [PointStatus.Completed]: 'Завершено',
};

export const StatusesTransitionNames = {
  [PointStatus.Waiting]: {
    unloading: 'Приехал',
    loading: 'Приехал',
  },
  [PointStatus.Proceeding]: {
    unloading: 'Разгрузка Началась',
    loading: 'Погрузка Началась',
  },
  [PointStatus.Completed]: {
    unloading: 'Разгрузился',
    loading: 'Погрузился',
  },
  [PointStatus.Problems]: {
    unloading: 'Возникли проблемы',
    loading: 'Возникли проблемы',
  },
};

export const OPTION_WITHOUT_STATUS = {
  value: null as unknown as PointStatus,
  label: 'Без статуса',
};

const STATUS_OPTIONS = [PointStatus.Waiting, PointStatus.Proceeding, PointStatus.Completed, PointStatus.Problems];

export const CARRIER_POINT_STATUSES_OPTIONS: Record<OrderLoadType, Array<{ value: PointStatus; label: string }>> = {
  unloading: STATUS_OPTIONS.map((value) => {
    return {
      value,
      label: `${StatusesTransitionNames[value].unloading}`,
    };
  }),
  loading: STATUS_OPTIONS.map((value) => {
    return {
      value,
      label: `${StatusesTransitionNames[value].loading}`,
    };
  }),
};

export const options = [
  {
    value: PointStatus.Waiting,
    label: `${StatusesNames[PointStatus.Waiting]}`,
  },
  {
    value: PointStatus.Proceeding,
    label: `${StatusesNames[PointStatus.Proceeding]}`,
  },
  {
    value: PointStatus.Completed,
    label: `${StatusesNames[PointStatus.Completed]}`,
  },
  OPTION_WITHOUT_STATUS,
];
