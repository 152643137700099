import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import { MyOrganizationsGeneralInfo } from 'components/my-organizations/my-organizations-info/my-organizations-general-info';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { MyOrganizationBanking, MyOrganizationType } from '@/types/models/my-organization/my-organization';

type Props = {
  organizationData: MyOrganizationType;
  bankingData: Array<MyOrganizationBanking>;
  onBankingAdd: (arg0: boolean) => void;
  hasEditRight?: boolean;
};

export const MyOrganizationsInfo: FC<Props> = (props: Props) => {
  return (
    <Box className="my-organizations-info-component" position="relative">
      <UiScrollbars className="carrier-info-component__scroll">
        <MyOrganizationsGeneralInfo {...props} />
      </UiScrollbars>
    </Box>
  );
};
