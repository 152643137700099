import { FC } from 'react';
import { Box, Heading, HStack, Text } from '@chakra-ui/react';
import { FormApi } from 'final-form';
import { Form } from 'react-final-form';
import './add-service-widget.scss';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { Order } from '@/types/models/order';
import { UiButtonFilled, UiButtonOutlined, UiInputField, UiNumberPickerField, UiSelectField, UiSwitchField, UiTextareaField } from '@/ui-elements';
import { AddServiceFormFields } from '@/views/add-service-widget/type';
import { UiFileUploadFieldWidget } from '@/ui-elements/form-fields/ui-file-upload-field/ui-file-upload-field-widget';
import { ErrorMessage } from '@/helpers/forms/validation-schemas/error-messages';
import { Load } from '@/types/models/loads';

type Props = {
  order: Order | Load;
  onSubmit: (fields: AddServiceFormFields, form: FormApi<AddServiceFormFields>) => void;
  onCancelClick: () => void;
  orderMode?: string;
  companyName?: string;
};

export const AddServiceWidget: FC<Props> = ({ order, onSubmit, onCancelClick, orderMode, companyName = 'Не назначен' }) => {
  const optionsCustomer = [
    {
      value: order.customer.name,
      label: order.customer.name,
    },
  ];
  const optionsCarrier = [
    {
      value: order.responsiblePerson?.name || '',
      label: order.responsiblePerson?.name || '',
    },
  ];

  const validate = (values: AddServiceFormFields) => {
    const errors: { name?: string; customerPrice?: string; carrierPrice?: string } = {};
    if (!values.name) {
      errors.name = ErrorMessage.Required;
    }
    if (!values.customerPrice) {
      errors.customerPrice = ErrorMessage.Required;
    }
    if (!values.carrierPrice) {
      errors.carrierPrice = ErrorMessage.Required;
    }
    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, pristine, submitting }) => (
        <form onSubmit={handleSubmit}>
          <UiScrollbars
            style={{
              width: '100%',
              height: '100vh',
            }}
          >
            <div className="add-service-widget">
              <HStack className="add-service-widget__paragraph">
                <Text>{order.customer.name}</Text>
                <Text className="add-service-widget__title">{order.id}</Text>
              </HStack>
              <Box>
                <HStack className="add-service-widget__default-selectors" gap="24px">
                  <UiSelectField name="customerCompany" label="Клиент" options={optionsCustomer} isDisabled defaultInputValue={order.customer.name} />
                  <UiSelectField
                    name="carrier"
                    label="Исполнитель"
                    options={optionsCarrier}
                    isDisabled
                    defaultInputValue={orderMode === 'carrier' ? companyName : order.responsiblePerson?.name ?? 'Не назначен'}
                  />
                </HStack>
                <UiInputField
                  name="name"
                  label="Наименование услуги"
                  content="Что нужно сделать исполнителю дополнительно"
                  placeholder="Введите текст"
                  required
                />
                <UiTextareaField name="comment" label="Комментарий" content="Дополнительное описание для исполнителя" placeholder="Введите текст" />
              </Box>
              <Box>
                <Heading>Стоимость услуги для клиента</Heading>
                <HStack gap="24px">
                  <UiNumberPickerField
                    name="customerPrice"
                    label="Цена за единицу"
                    placeholder="Назначьте цену"
                    content="Впишите цену услуги"
                    required
                    hideStepper
                  />
                  <UiSwitchField name="customerVat" label="НДС" />
                </HStack>
                <Heading>Стоимость услуги для перевозчика</Heading>
                <HStack gap="24px">
                  <UiNumberPickerField
                    name="carrierPrice"
                    label="Цена за единицу"
                    placeholder="Назначьте цену"
                    content="Впишите цену услуги"
                    required
                    hideStepper
                  />
                  <UiSwitchField name="carrierVat" label="НДС" />
                </HStack>
                <UiNumberPickerField className="add-service-widget__amount" name="amount" label="Количество" placeholder="0" />
              </Box>

              <Box className="add-service-widget__upload">
                <Heading>Прикрепленные файлы</Heading>
                <UiFileUploadFieldWidget withDownloadButton name="file-upload" />
              </Box>
            </div>
          </UiScrollbars>
          <HStack justify="flex-end" gap="16px" className="add-service-widget__btn-group">
            <UiButtonOutlined className="add-service-widget__cancel" mode="dark" onClick={onCancelClick}>
              Отменить
            </UiButtonOutlined>
            <UiButtonFilled className="add-service-widget__submit" type="submit" mode="dark" isDisabled={pristine || submitting}>
              Добавить
            </UiButtonFilled>
          </HStack>
        </form>
      )}
    />
  );
};
