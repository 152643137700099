import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'config/routes/routes';
import { routesPath } from 'config/routes/routes-path';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { ordersActions } from '@/features/order/slice';
import { Order } from '@/types/models/order';
import { Carrier } from '@/types/models/carrier';
import { OrderSwitcherStatus } from '@/config/constants/statuses';
import { Load } from '@/types/models/loads';

export const useOrderActions = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  /**
   * shows new order form
   * */
  const create = useCallback(() => {
    dispatch(ordersActions.create());
  }, [dispatch]);
  /**
   * shows update order form
   * */
  const update = useCallback(() => {
    dispatch(ordersActions.update());
  }, [dispatch]);

  /**
   * shows new order form
   * */
  const cancelCreate = useCallback(() => {
    dispatch(ordersActions.cancelCreate());
  }, [dispatch]);

  /**
   * show search form
   * @param order
   */
  const searchForCarrier = useCallback(
    (order?: Order | Load) => {
      dispatch(ordersActions.searchForCarrier(order));
    },
    [dispatch]
  );

  /**
   * Отобразить компонент разделения заявки
   * @param order - исходная заявка
   * @param partsToSplit - количество частей для разделения
   */
  const split = useCallback(
    ({ order, partsToSplit }: { order?: Order; partsToSplit?: number }) => {
      dispatch(ordersActions.split({ order, partsToSplit }));
    },
    [dispatch]
  );

  /**
   * show union form
   * */
  const union = useCallback((orders: Array<Order>) => {
    console.warn('not implemented yet', orders);
  }, []);

  /**
   * Select order
   * @param orderId
   */
  const select = useCallback(
    (orderId?: number) => {
      dispatch(ordersActions.select(orderId));
    },
    [dispatch]
  );

  /**
   * Unselect order
   * @param orderId
   */
  const unselect = useCallback(
    (orderId: number) => {
      dispatch(ordersActions.unselect(orderId));
    },
    [dispatch]
  );

  /**
   * Select order
   * @param orderId
   */
  const setTotalVisibleOrdersCount = useCallback(
    (total?: number) => {
      dispatch(ordersActions.setTotalVisibleOrdersCount(total));
    },
    [dispatch]
  );

  /**
   * Select order tab (выбор таба в списке заявок: Новые, Завершенные и тд)
   * @param tab
   */
  const selectTab = useCallback(
    (tab: OrderSwitcherStatus) => {
      dispatch(ordersActions.selectTab(tab));
    },
    [dispatch]
  );

  /**
   * Unselect all orders
   *
   */
  const unselectAll = useCallback(() => {
    dispatch(ordersActions.unselectAll());
  }, [dispatch]);

  /**
   * Select one more order
   * @param order
   */
  const setMultipleSelection = useCallback(
    (isMultiple?: boolean) => {
      dispatch(ordersActions.setMultipleSelection(isMultiple));
    },
    [dispatch]
  );

  /**
   *Показать отзызвы о перевозчике, если carrier - undefined -то скрывает отзывы
   * */
  const openReviews = useCallback(
    (carrier?: Carrier) => {
      dispatch(ordersActions.showReviews({ carrier }));
    },
    [dispatch]
  );

  /**
   *Показать отзызвы о перевозчике, если carrier - undefined -то скрывает отзывы
   * */
  const clearState = useCallback(() => {
    dispatch(ordersActions.clearState());
  }, [dispatch]);

  const navigateToOrder = useCallback(
    (id: number) => {
      navigate(`/${routesPath[Routes.Orders]}`, { state: { redirectId: id } });
    },
    [navigate]
  );

  return {
    create,
    split,
    union,
    select,
    unselect,
    unselectAll,
    selectMode: setMultipleSelection,
    cancelCreate,
    cancelUpdate: cancelCreate,
    searchForCarrier,
    openReviews,
    clearState,
    selectTab,
    update,
    setTotalVisibleOrdersCount,
    navigateToOrder,
  };
};
