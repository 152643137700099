import './funnel-filters.scss';
import { FC } from 'react';
import { HStack, VStack } from '@chakra-ui/react';
import { Tab } from '@/ui-elements/switcher/types';
import { UiSwitcher } from '@/ui-elements/switcher/switcher';
import { FunnelListFilters, FunnelSwitcherStatus } from '@/types/models/funnel';
import { CreateLoadButton } from '@/components/funnel/create-load-button/create-load-button';
import FunnelFiltersForm from '@/components/funnel/filters-form/funnel-filters-form';

type Props = {
  switcherLabels: Array<Tab>;
  activeTab: FunnelSwitcherStatus;
  userHasIntegration: boolean;
  periodValue: string;
  onChangeActiveTab: (activeKey: string) => void;
  onCreateLoad: () => void;
  handleChangePeriod: (value: string) => void;
  handleChangeFilters: (values: FunnelListFilters) => void;
};

export const FunnelFilters: FC<Props> = ({
  switcherLabels,
  activeTab,
  userHasIntegration,
  periodValue,
  onChangeActiveTab,
  onCreateLoad,
  handleChangePeriod,
  handleChangeFilters,
}) => {
  const isNew = activeTab === FunnelSwitcherStatus.NewCalculation;
  return (
    <VStack className="funnel-filters" spacing="18px">
      <UiSwitcher
        className="funnel-filters__switcher"
        activeTab={activeTab}
        tabsList={switcherLabels}
        defaultActiveTab={FunnelSwitcherStatus.NewCalculation}
        onChange={onChangeActiveTab}
        disabled={!userHasIntegration}
      />
      <HStack justifyContent="space-between" width="100%">
        <FunnelFiltersForm periodValue={periodValue} handleChangePeriod={handleChangePeriod} handleChangeFilters={handleChangeFilters} />
        {isNew && <CreateLoadButton onCreateLoad={onCreateLoad} />}
      </HStack>
    </VStack>
  );
};
