import { createAsyncThunk } from '@reduxjs/toolkit';
import { TOKEN_KEY } from 'config/constants/storage';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { AuthApi } from '@/api/auth';
import { storage } from '@/helpers/storage';
import { ResponseError } from '@/types/api/response-error';
import { ResponseApi } from '@/types/response';
import { handleAxiosError } from '@/helpers/api/handle-axios-error';
import { queryClient } from '@/config/query-client';
import { customersActions } from '@/features/customers/slice';
import { carriersActions } from '@/features/carriers/slice';
import { ordersActions } from '@/features/order/slice';
import { securityActions } from '@/features/security/slice';
import { bookerOrdersActions } from '@/features/booker/slice';
import { myOrganizationsActions } from '@/features/my-organizations/slice';
import { accountActions } from '../slice';

export const logoutAction = createAsyncThunk<ResponseApi, undefined, { rejectValue: ResponseError }>(
  'account/logout',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const res = await AuthApi.signOut();
      storage.setValue(TOKEN_KEY, EMPTY_STRING);
      dispatch(accountActions.clearState());
      dispatch(customersActions.clearState());
      dispatch(ordersActions.clearState());
      dispatch(carriersActions.clearState());
      dispatch(bookerOrdersActions.clearState());
      dispatch(myOrganizationsActions.clearState());
      dispatch(securityActions.clear());
      queryClient.resetQueries();
      return res;
    } catch (err) {
      return handleAxiosError(err, rejectWithValue);
    }
  }
);
