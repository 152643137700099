export const amountTableConfig = {
  columns: [
    {
      id: '1',
      name: 'Менеджер',
      sorting: true,
    },
    {
      id: '2',
      name: 'Кол-во выполненных',
      sorting: true,
    },
    {
      id: '3',
      name: 'Общая сумма',
      sorting: true,
    },
    {
      id: '4',
      name: 'Маржа',
      sorting: false,
    },
  ],
  cellClassName: 'analytics-component__table-td',
  rowClassName: 'analytics-component__table-tr',
  footerData: ['Итого', '100000', '100000', '100000'],
};
