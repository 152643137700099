import { FC } from 'react';
import { VStack } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { SELECT_LOAD } from '@/config/constants/loads';
import { Load } from '@/types/models/loads';
import { BackForwardButtonsDisabledType, FunnelSwitcherStatus } from '@/types/models/funnel';
import { LoadsEmpty } from '@/components/loads/empty/loads-empty';
import { FunnelDetailsButtons } from '@/components/funnel/details-buttons/funnel-details-buttons';
import { FunnelDetails } from '@/components/funnel/details/funnel-details';
import { Order } from '@/types/models/order';

interface Props {
  order?: Load;
  activeTab: FunnelSwitcherStatus;
  userHasIntegration: boolean;
  deleteAllowed: boolean;
  buttonsDisabled: BackForwardButtonsDisabledType;
  modalDeleteLoad?: JSX.Element | null;
  handleClickBack?: VoidFunction;
  handleClickForward?: VoidFunction | VoidFunction[];
  handleUpdateLoad: VoidFunction;
  handleDeleteLoad: VoidFunction;
}

export const FunnelDetailsComponent: FC<Props> = ({
  order,
  activeTab,
  userHasIntegration,
  deleteAllowed,
  buttonsDisabled,
  modalDeleteLoad,
  handleClickBack,
  handleClickForward,
  handleUpdateLoad,
  handleDeleteLoad,
}) => {
  return !isEmpty(order) ? (
    <VStack paddingY={6} alignItems="stretch">
      <FunnelDetails
        load={order}
        handleUpdateLoad={handleUpdateLoad}
        activeTab={activeTab}
        deleteAllowed={deleteAllowed}
        handleDeleteLoad={handleDeleteLoad}
      />
      <FunnelDetailsButtons
        activeTab={activeTab}
        loadUrlAtPlatform={order?.platformUrl}
        handleClickBack={handleClickBack}
        handleClickForward={handleClickForward}
        buttonsDisabled={buttonsDisabled}
      />
      {modalDeleteLoad}
    </VStack>
  ) : (
    <LoadsEmpty text={SELECT_LOAD} visible={userHasIntegration} />
  );
};
