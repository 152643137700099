import printIcon from 'public/assets/icons/PrintIcon.svg';
import sendIcon from 'public/assets/icons/ExportIcon.svg';
import { UiDatePickerInput, UiTextButton, UiButtonsGroup } from '@/ui-elements';
import { PeriodPicker } from '@/components';
import { CarriersStatistics } from './carriers-statistics';
import './analytics-component.scss';

type Props = {
  value?: string;
  onChange: (arg1: string) => void;
  handlePrint?: VoidFunction;
};

export const Header = ({ handlePrint, ...props }: Props) => {
  return (
    <div className="analytics-component__header">
      <PeriodPicker {...props} />
      <UiDatePickerInput {...props} isMulti className="analytics-component__datepicker" />
      <CarriersStatistics />
      <UiButtonsGroup
        buttons={[
          <UiTextButton leftIcon={sendIcon}>Экспорт</UiTextButton>,
          <UiTextButton leftIcon={printIcon} onClick={handlePrint}>
            Распечатать
          </UiTextButton>,
        ]}
        className="analytics-component__buttons"
        spacing={5}
      />
    </div>
  );
};
