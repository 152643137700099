import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import { useFormState } from 'react-final-form';
import { BANK_ACCOUNT_MASK } from 'components/auth-form/constants';
import { UiCheckboxField, UiInputField, UiMaskedField } from '@/ui-elements';
import { useBankRequisites } from '@/features/bank-requisites/use-bank-requisites';

export const BankingSubform = ({ isFirstDefaultBanking }: { isFirstDefaultBanking?: boolean }) => {
  const { values } = useFormState();
  const { bankRequisites } = useBankRequisites(values.bank?.bik && values.bank?.bik?.length > 3 ? values.bank?.bik : undefined);
  return (
    <Box>
      <Text mt={2} fontWeight={700} fontSize={18}>
        Расчетный счет для оплаты
      </Text>

      <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={3.5}>
        <GridItem>
          <UiInputField type="number" name="bank.bik" label="БИК" placeholder="БИК банка" />
        </GridItem>
        <GridItem>
          <UiInputField name="bank.name" label="Наименование банка" placeholder="Наименование банка" initialValue={bankRequisites.data?.data.name} />
        </GridItem>
      </Grid>
      <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={-1}>
        <GridItem>
          <UiInputField
            type="number"
            name="bank.correspondentAccount"
            label="Корр. счет"
            placeholder="Корр. счет"
            initialValue={bankRequisites.data?.data.correspondentAccount}
          />
        </GridItem>
        <GridItem>
          <UiMaskedField
            type="number"
            name="bank.bankAccount"
            mask={BANK_ACCOUNT_MASK}
            label="Номер расчетного счета"
            placeholder="Номер расчетного счета"
          />
        </GridItem>
      </Grid>

      <Box mb={4}>
        <UiCheckboxField name="bank.default" label="Использовать по умолчанию" isDisabled={isFirstDefaultBanking} />
      </Box>
    </Box>
  );
};
