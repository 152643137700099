import { Center, Divider, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import classNames from 'classnames';
import { LoadingType } from 'config/constants/loading-types';
import { LoadingTypeComponent } from 'components/loading-type/loading-type';
import { useIcons } from '@/hooks/use-icons';
import './general-info.scss';
import { UiTooltip } from '@/ui-elements/tooltip/tooltip';
import { ShippedTotals } from '@/types/models/shipped-totals';
import { useVehicleTypesNames } from '@/features/vehicle-types/hooks/use-vehicle-types-names';
import { useVehicleTypesIcons } from '@/features/vehicle-types/hooks/use-vehicle-types-icons';
import { UiText } from '@/ui-elements/ui-text/ui-text';

import { formatMass } from '@/helpers/format-mass';

type Props = {
  loadingType?: Array<LoadingType>;
  shippedTotals?: ShippedTotals;
  vehicleTypes?: Array<number>;
  className?: string;
};

export const CarrierLoadGeneralInfo: FC<Props> = ({ shippedTotals, vehicleTypes, className, loadingType = [] }) => {
  const totalVolume = shippedTotals?.totalVolume;
  const totalMass = shippedTotals?.totalMass;
  const vehicleTypesNames = useVehicleTypesNames();
  const vehicleTypesIcons = useVehicleTypesIcons();

  const vehicleIcon = vehicleTypes?.[0] ? vehicleTypesIcons[vehicleTypes?.[0]] : undefined;
  const { TonageIcon, VolumeIcon } = useIcons();
  const isMultiple = (vehicleTypes?.length || 0) > 1;

  return (
    <HStack className={classNames(className, 'carrier-load-general-info')} wrap="wrap">
      <HStack className="carrier-load-general-info__vehicle">
        {!isMultiple && vehicleIcon && <img width="48px" height="20px" src={vehicleIcon} alt="vehicle img" />}
        <UiTooltip label={vehicleTypes?.map((vehicleType) => vehicleTypesNames[vehicleType]).join(', ') || 'не указан'}>
          <UiText color="products.200">Тип ТС</UiText>
        </UiTooltip>
      </HStack>

      <Center className="carrier-load-general-info__divider-container">
        <Divider className="carrier-load-general-info__divider" orientation="vertical" />
      </Center>
      <LoadingTypeComponent loadingType={loadingType} />

      {totalMass && (
        <>
          <Center className="carrier-load-general-info__divider-container">
            <Divider className="carrier-load-general-info__divider" orientation="vertical" />
          </Center>
          <HStack className="carrier-load-general-info__tonage">
            <TonageIcon />
            <Text>{formatMass(totalMass)}</Text>
          </HStack>
        </>
      )}
      {totalVolume && (
        <>
          <Center className="carrier-load-general-info__divider-container">
            <Divider className="carrier-load-general-info__divider" orientation="vertical" />
          </Center>
          <HStack className="carrier-load-general-info__volume">
            <VolumeIcon />
            <Text>{`${totalVolume} м³`}</Text>
          </HStack>
        </>
      )}
    </HStack>
  );
};
