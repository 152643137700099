import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomersState } from '@/features/customers/types/customers-state';

const initialState: CustomersState = {
  activeCustomersId: undefined,
};

const slice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    select: (state, action: PayloadAction<number>) => {
      state.activeCustomersId = action.payload;
    },
    unselect: (state) => {
      state.activeCustomersId = undefined;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const customersReducers = slice.reducer;
export const customersActions = slice.actions;
