import { Dictionary } from 'config/constants/dictionary';
import { ApiDelete, ApiGet, ApiPatch, ApiPost, ApiPut } from '@/helpers/api/methods';
import {
  OrderCount,
  OrderCreateSource,
  OrderFilter,
  OrderFuelCount,
  OrderMerge,
  OrderNew,
  OrderReview,
  OrderSetStatus,
} from '@/types/api/order-partial';
import { stringifyQuery, validateQueryString } from '@/helpers/validate-query-string';
import { ResponseWithoutPagerSimple, ResponseWithPager } from '@/types/models/pager';
import { Order } from '@/types/models/order';
import { ResponseId, ResponseIds, ResponseSimpleApi } from '@/types/response';
import { OrderTimeline } from '@/types/models/order-timeline';
import { FileDocument } from '@/types/models/document';
import { PaymentPartial } from '@/types/api/payment-partial';
import { ContentType } from '@/types/api/content-type';
import { PrepaymentPartial } from '@/types/api/prepayment-partial';
import { ShipmentRequest } from '@/types/models/shipment-request';
import { PackagesResponseType } from '@/types/models/packages';
import { OrderStatusesType } from '@/types/models/order-statuses';
import { LoadNew } from '@/types/models/funnel';

export const OrdersApi = {
  getAllOrders: (query?: OrderFilter, options?: { useBearer?: boolean }) =>
    ApiGet<ResponseWithPager<Order>>(`${Dictionary.Orders}${validateQueryString(query)}`, options),

  addOrder: (options: OrderNew | LoadNew) => ApiPost<ResponseWithoutPagerSimple<Order>>(`${Dictionary.Orders}`, options),

  updateOrder: (id: number, options: Partial<OrderNew | LoadNew>) =>
    ApiPatch<ResponseWithoutPagerSimple<Order>>(`${Dictionary.Orders}/${id}`, options),

  getOrder: (id: number, query?: { updates?: boolean; fromSource?: OrderCreateSource }) =>
    ApiGet<ResponseWithoutPagerSimple<Order>>(`${Dictionary.Orders}/${id}${stringifyQuery(query)}`),

  participatable: (query?: OrderFilter) => ApiGet<ResponseWithPager<Order>>(`${Dictionary.Orders}/participatable/${validateQueryString(query)}`),

  getOrderWithMyParticipation: (id: number, query?: { updates?: boolean; fromSource?: OrderCreateSource }) =>
    ApiGet<ResponseWithoutPagerSimple<Order>>(`${Dictionary.Orders}/withMyParticipation/${id}${validateQueryString(query)}`),

  deleteOrder: (id: number, fromSource?: OrderCreateSource) =>
    ApiDelete<ResponseSimpleApi>(`${Dictionary.Orders}/${id}${stringifyQuery({ fromSource })}`),

  cloneOrder: (id: number) => ApiPost<ResponseWithoutPagerSimple<Order>>(`${Dictionary.Orders}/${id}/clone`),

  setCarrierVat: (id: number, options: { vat: boolean }) => ApiPost<ResponseSimpleApi>(`${Dictionary.Orders}/${id}/setCarrierVat`, options),

  uploadDocument: (id: number, body: FormData) =>
    ApiPut<FileDocument>(`${Dictionary.Orders}/${id}/document`, body, { contentType: ContentType.FORM_DATA }),

  deleteDocument: (id: number, documentId: number) => ApiDelete<ResponseSimpleApi>(`${Dictionary.Orders}/${id}/document/${documentId}`),

  getOrderTimeline: (id: number, updates?: boolean) =>
    ApiGet<ResponseWithoutPagerSimple<Array<OrderTimeline>>>(`${Dictionary.Orders}/${id}/timeline?updates=${!!updates}`),

  addOrderSplit: (id: number, options: Array<OrderNew>) => ApiPost<ResponseIds>(`${Dictionary.Orders}/${id}/split`, options),

  updateOrderStatus: (id: number, options: OrderSetStatus) => ApiPost<ResponseSimpleApi>(`${Dictionary.Orders}/${id}/setStatus`, options),

  getOrdersCount: (updates?: boolean, fromSource?: OrderCreateSource) =>
    ApiGet<ResponseWithoutPagerSimple<OrderCount>>(`${Dictionary.Orders}/count${stringifyQuery({ updates: !!updates, fromSource })}`),

  getOrdersFuelCount: (updates?: boolean) =>
    ApiGet<ResponseWithoutPagerSimple<OrderFuelCount>>(`${Dictionary.Orders}/fuelstatus_count?updates=${!!updates}`),

  addOrderMerge: (options: OrderMerge) => ApiPost<ResponseId>(`${Dictionary.Orders}/merge`, options),

  setOrderPaid: (id: number, options: PaymentPartial) =>
    ApiPost<ResponseSimpleApi>(`${Dictionary.Orders}/${id}/payment/${options.orderMode}/${options.paid ? 'confirm' : 'request'}`),

  setOrderPrepaymentPaid: (id: number, options: PrepaymentPartial) =>
    ApiPost<ResponseSimpleApi>(`${Dictionary.Orders}/${id}/payment/prepayment/${options.paid ? 'confirm' : 'request'}`, options),

  setOrderWaypointStatus: ({ orderId, pointId }: { orderId: number; pointId: number }, status: 'waiting' | 'completed' | 'proceeding' | '') =>
    ApiPost<ResponseSimpleApi>(`${Dictionary.Orders}/${orderId}/point/${pointId}/setStatus`, { status }),

  getShipmentRequest: (id: number, query?: { updates: boolean }) =>
    ApiGet<ResponseWithPager<ShipmentRequest>>(`${Dictionary.Orders}/${id}/shipmentRequests${stringifyQuery(query)}`),

  setWaypointDocumentsStatus: ({ orderId, pointId }: { orderId: number; pointId: number }, status: 0 | 1 | 'declined' | 'approved') =>
    ApiPost<ResponseSimpleApi>(`${Dictionary.Orders}/${orderId}/point/${pointId}/setDocumentStatus`, { status }),

  getWaypointComment: ({ orderId, pointId, updates }: { orderId: number; pointId: number; updates?: boolean }) =>
    ApiGet<ResponseWithPager<Order>>(`${Dictionary.Orders}/${orderId}/point/${pointId}/comment?updates=${!!updates}`),

  setWaypointComment: ({ orderId, pointId, comment }: { orderId: number; pointId: number; comment: string }) =>
    ApiPut<ResponseSimpleApi>(`${Dictionary.Orders}/${orderId}/point/${pointId}/comment`, { comment }),

  addReview: (id: number, options: OrderReview) => ApiPost<ResponseSimpleApi>(`${Dictionary.Orders}/${id}/reviews`, options),

  getPackages: () => ApiGet<ResponseWithoutPagerSimple<PackagesResponseType>>(`${Dictionary.Orders}/${Dictionary.Packages}`),

  getCargoTypes: () => ApiGet<ResponseWithoutPagerSimple<Record<number, string>>>(`${Dictionary.Orders}/cargoTypes`),

  getOrderStatuses: () => ApiGet<ResponseWithoutPagerSimple<OrderStatusesType>>(`${Dictionary.Orders}/orderStatuses`),

  getOrdersWithMyParticipation: (query?: OrderFilter, options?: { useBearer?: boolean }) =>
    ApiGet<ResponseWithPager<Order>>(`${Dictionary.Orders}/withMyParticipation${validateQueryString(query)}`, options),
};
