import { isNil } from 'lodash';
import { WEIGHT_UNITS } from 'config/constants/orders';
import { NOT_SET } from 'config/constants/common-strings';
import { Action } from '@/types/models/action';
import { Cargos } from '@/types/models/cargos';
import { formatMass } from '@/helpers/format-mass';

export const convertWeightToKG = (mass?: number, weightUnit?: string) => {
  if (isNil(mass) || isNil(weightUnit)) {
    return 0;
  }
  return weightUnit === WEIGHT_UNITS.T ? mass * 1000 : mass;
};

export const countCargoFromPoint = (cargos?: Array<Cargos>): number => {
  const count = cargos?.reduce((sum, x) => {
    const mass = convertWeightToKG(x.properties?.Mass, x.properties?.Units);
    return sum + mass;
  }, 0);
  return count || 0;
};

export const findAllLoadingCargos = (pointId: number, actions?: Array<Action>, cargos?: Array<Cargos>): Array<Cargos> => {
  const filter: Array<number | string> | undefined = actions
    ?.filter((item) => {
      return item.pointId === pointId && item.type === 1;
    })
    .map((item) => item.cargoId);

  const filteredCargos = cargos?.filter((c) => {
    return filter?.includes(c.id);
  });
  return filteredCargos || [];
};

export const findAllUnLoadingCargos = (pointId: number, actions?: Array<Action>, cargos?: Array<Cargos>): Array<Cargos> => {
  const filter: Array<number | string> | undefined = actions
    ?.filter((item) => {
      return item.pointId === pointId && item.type === 0;
    })
    .map((item) => item.cargoId);

  const filteredCargos = cargos?.filter((c) => {
    return filter?.includes(c.id);
  });
  return filteredCargos || [];
};

export const findUnitsCargos = (pointId: number, actions?: Array<Action>, cargos?: Array<Cargos>) => {
  const cargo = cargos?.find((cargoItem) => {
    const action = actions?.find((actionItem) => actionItem.pointId === pointId);
    return action && cargoItem.id === action.cargoId;
  });

  return cargo?.properties.Units;
};

export const getMassFromPoint = (cargo: Array<Cargos>) => {
  return cargo.map((x) => {
    const massInKG = formatMass(convertWeightToKG(x.properties?.Mass, x.properties?.Units));
    return `${x.type ? x.type : NOT_SET}(${massInKG})`;
  });
};
