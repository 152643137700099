import { OrdersApi } from '@/api/orders';
import { useQueryWithUpdates } from '@/hooks/use-query-with-updates';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';
import { ShipmentRequest } from '@/types/models/shipment-request';

type Props = {
  id?: number;
  staleTime?: number;
  disabled?: boolean;
};

export const useShipmentRequestsUpdates = ({ id, staleTime, disabled }: Props) => {
  /** В случая глубокого кеширования вместо откликов отображается null т.к.
   * isFirst не работает корректно,
   * в случае необходимости кеширования – проработать логику */
  const queryFn = () => OrdersApi.getShipmentRequest(id!, { updates: false });
  const shipmentRequests = useQueryWithUpdates<ResponseWithoutPagerSimple<[ShipmentRequest]>>({
    queryKey: ['shipmentRequests', id],
    enabled: !!id && !disabled,
    staleTime,
    queryFn,
  });
  return { shipmentRequests };
};
