import { FC } from 'react';
import './top-routes-menu.scss';
import { useLocation } from 'react-router-dom';
import { UiLink } from '@/ui-elements/ui-link/ui-link';
import { NavigationMenu } from '@/types/routes/navigation-menu';

type Props = {
  menu: NavigationMenu;
};

export const TopRoutesMenuMobile: FC<Props> = ({ menu }) => {
  const location = useLocation();

  const isActiveRoute = (routePath: string) => location.pathname.split('/').includes(routePath);
  return (
    <div className="routes-menu-mobile">
      {menu.items.map((item) => (
        <UiLink href={`/${item.route.path}`} isExternal={false} key={item.route.path} isActive={isActiveRoute(item.route.path)}>
          {item.name}
        </UiLink>
      ))}
    </div>
  );
};
