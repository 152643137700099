import { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { DetailsComponent } from 'components/carriers-loads/details/details-component/details-component';
import { isNil } from 'lodash';
import { SidebarWrapper } from 'components/sidebar-wrapper/sidebar-wrapper';
import { CarrierLoadsPageWithOrdersSidebarProps } from 'components/carriers-loads/sidebar/constants';

export const FutureRoutesSidebar: FC<CarrierLoadsPageWithOrdersSidebarProps> = ({ order, closeSidebar, onOrderStart }) => {
  if (isNil(order)) return null;

  return (
    <SidebarWrapper closeSidebar={closeSidebar}>
      <Box className="current-route-details">
        <DetailsComponent order={order} onOrderStart={onOrderStart} />
      </Box>
    </SidebarWrapper>
  );
};
