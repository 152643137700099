import { FC, MouseEventHandler, useState } from 'react';
import { Divider, HStack } from '@chakra-ui/react';
import { OrderDetailsClientOffers } from 'components/order-details/order-details-client-offers/order-details-client-offers';
import { GENERAL_INFO, SELECTED_CARRIER, SHOW_ON_MAP } from 'components/order-details/order-details-client-execution/text';
import { FuelStatus, OrderExecutionStatus, OrderStatus } from 'config/constants/statuses';
import { OrderDetailsShipmentRequests } from 'components/order-details/order-details-shipment-requests/order-details-shipment-requests';
import { Order } from '@/types/models/order';
import { OrderGeneralDistance, OrderGeneralInfo, OrderInfoWithActions, OrderRoute } from '@/components';
import { VoidFunction } from '@/types/common/void-function';
import { OrderOffer } from '@/types/models/order-offer';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { orderExtender } from '@/features/order/extender/order-extender';
import { emptyFunction } from '@/helpers/empty-function';
import { useIcons } from '@/hooks/use-icons';
import './order-details-client-execution.scss';
import { ShipmentRequest } from '@/types/models/shipment-request';
import { UiButtonFilled } from '@/ui-elements';
import { orderFuelStatusMapTexts } from '@/features/order/texts';
import { orderFuelStatusMap } from '@/config/order-fuel-status-map';
import { RouteMap } from '@/components/route-map/route-map';
import { Load } from '@/types/models/loads';

type Props = {
  // открыть форму разделения заявки
  onSplitClick: (partsToSplit: number) => void;
  // открыть поиск исполнителя
  onCarrierSearchClick: VoidFunction;
  // открыть выбор собственных ресурсов
  onChooseOwnDriverAndVehicle?: VoidFunction;
  // перевести в статус поиск исполнителя
  onStartCarrierSearch?: VoidFunction;
  // отправить оффер на проверку
  onSendToCheck: (offer: Pick<OrderOffer, 'carrier' | 'driver' | 'vehicle'>) => void;
  // кнопка Подробнее - открыть виджет Информация о перевозчике
  onMoreInformation: (id: number) => void;
  order: Order | Load;
  // Предложения
  orderOffers: Array<OrderOffer>;
  // отправленные запросы
  shipmentRequests?: Array<ShipmentRequest>;
  // Выбранное предложение
  selectedOffer?: OrderOffer;
  // открыть виджет выбрать исполнителя заявку
  onClickSubmitOffer: (orderOffer: Pick<OrderOffer, 'carrier' | 'driver' | 'vehicle'>, offerId?: number) => void;
  // отменить заявку
  onCancelOrderClick?: VoidFunction;
  // отключает кнопку 'найти исполнителя'
  disableSearchButton?: boolean;
  // скрыть все кнопки по управлению заявкой
  hideOrderActions?: boolean;
  // скрыть предложения
  hideOffers?: boolean;
  // скрыть все кнопки по управлению заявкой
  hideOrderLogistInfo?: boolean;
  // скрыть группу риска для заявки
  hideOrderRiskGroup?: boolean;
  handleUpdateOrder?: MouseEventHandler<HTMLButtonElement>;
  handleDeleteOrder?: MouseEventHandler<HTMLButtonElement>;
  onUpdateOrderFuelStatus?: (status?: FuelStatus) => void;
  // страница топлива
  isFuel?: boolean;
  fuelReadOnly?: boolean;
  // Копировать заявку
  onCloneOrder: (orderId: number) => void;
};
/**
 * Блок исполнение и детали на вкладке Заказ Клиента
 * */
export const OrderDetailsClientExecution: FC<Props> = ({
  order,
  isFuel,
  fuelReadOnly,
  onClickSubmitOffer,
  selectedOffer,
  orderOffers,
  onCarrierSearchClick,
  onChooseOwnDriverAndVehicle,
  onStartCarrierSearch,
  onSplitClick,
  disableSearchButton = false,
  onCancelOrderClick = emptyFunction,
  hideOrderActions = false,
  hideOffers = false,
  hideOrderLogistInfo = false,
  hideOrderRiskGroup = false,
  handleUpdateOrder = emptyFunction,
  handleDeleteOrder = emptyFunction,
  onSendToCheck = emptyFunction,
  onMoreInformation = emptyFunction,
  shipmentRequests = [],
  onUpdateOrderFuelStatus,
  onCloneOrder,
}) => {
  const [routeMode, setRouteMode] = useState(0);
  const { distance, type, shippedTotals, getIsCancelable, getCancelCaption, carrierPayment } = useExtendedObject(order, orderExtender);
  const { priceWithoutVAT, priceWithVAT } = carrierPayment;
  const { InfoIcon, MapPinFill } = useIcons();
  const possibleNextStatuses = order.fuelStatus && orderFuelStatusMap[order.fuelStatus].possibleNextStatuses;

  return (
    <>
      <OrderInfoWithActions
        showEditButton={order.status === OrderExecutionStatus.New}
        showSplitButton={order.status === OrderExecutionStatus.New}
        showCancelButton={getIsCancelable()}
        cancelCaption={getCancelCaption()}
        disableSearchButton={disableSearchButton}
        onCancelButtonClick={onCancelOrderClick}
        order={order}
        onCarrierSearchClick={onCarrierSearchClick}
        onChooseOwnDriverAndVehicle={onChooseOwnDriverAndVehicle}
        onStartCarrierSearch={onStartCarrierSearch}
        onSplitClick={onSplitClick}
        hideOrderActions={hideOrderActions}
        hideOrderRiskGroup={hideOrderRiskGroup}
        handleUpdateOrder={handleUpdateOrder}
        handleDeleteOrder={handleDeleteOrder}
        onCloneOrder={onCloneOrder}
      />
      {isFuel && !fuelReadOnly && possibleNextStatuses && (
        <HStack justifyContent="flex-end" gap={2}>
          {possibleNextStatuses.map((nextButtonStatus) => (
            <UiButtonFilled
              key={nextButtonStatus}
              className="order-details-client-execution__fuel-status"
              mode="dark"
              onClick={() => onUpdateOrderFuelStatus && onUpdateOrderFuelStatus(nextButtonStatus)}
            >
              {orderFuelStatusMapTexts[nextButtonStatus]}
            </UiButtonFilled>
          ))}
        </HStack>
      )}
      {!hideOrderLogistInfo && (
        <>
          <Divider className="order-details__divider" />
          <div className="order-details-client-execution__general-distance">
            <OrderGeneralDistance range={distance} type={type} />
            <HStack className="order-details-client-execution__links">
              <HStack className={routeMode === 0 ? 'order-details-client-execution__general' : ''}>
                <InfoIcon />
                <button
                  type="button"
                  className={routeMode === 0 ? 'order-details-client-execution__general-text' : ''}
                  onClick={() => setRouteMode(0)}
                >
                  {GENERAL_INFO}
                </button>
              </HStack>
              <Divider orientation="vertical" className="order-details-client-execution__separator" />
              <HStack className={routeMode === 1 ? 'order-details-client-execution__map' : ''}>
                <MapPinFill />
                <button className={routeMode === 1 ? 'order-details-client-execution__map-text' : ''} type="button" onClick={() => setRouteMode(1)}>
                  {SHOW_ON_MAP}
                </button>
              </HStack>
            </HStack>
          </div>
          {routeMode === 0 ? (
            <OrderGeneralInfo
              className="order-details-client-execution__general-info"
              vehicleTypes={order.vehicleTypes}
              shippedTotals={shippedTotals}
              loadingType={order.loadingType || []}
            />
          ) : (
            <RouteMap order={order} />
          )}
        </>
      )}
      {!hideOffers && (
        <>
          <OrderDetailsClientOffers
            onSendToCheck={onSendToCheck}
            onMoreInformation={onMoreInformation}
            onClickSubmitOffer={onClickSubmitOffer}
            disableSelect
            offers={selectedOffer ? [selectedOffer] : []}
            orderPrice={{ priceWithoutVAT, priceWithVAT }}
            title={SELECTED_CARRIER}
          >
            <Divider className="order-details__divider" />
          </OrderDetailsClientOffers>
          <OrderDetailsShipmentRequests
            onSendToCheck={onSendToCheck}
            onMoreInformation={onMoreInformation}
            onClickSubmitOffer={onClickSubmitOffer}
            requests={shipmentRequests}
          >
            <Divider className="order-details__divider" />
          </OrderDetailsShipmentRequests>
          <OrderDetailsClientOffers
            onSendToCheck={onSendToCheck}
            onMoreInformation={onMoreInformation}
            onClickSubmitOffer={onClickSubmitOffer}
            orderPrice={{ priceWithoutVAT, priceWithVAT }}
            offers={orderOffers}
          >
            <Divider className="order-details__divider" />
          </OrderDetailsClientOffers>
        </>
      )}
      <Divider className="order-details__divider" />
      <OrderRoute order={order} />
      <Divider className="order-details__divider" />
    </>
  );
};
