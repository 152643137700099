import '../../../inputs/ui-checkbox-input/ui-checkbox-input.scss';
import { Box } from '@chakra-ui/react';
import { GroupBase, GroupHeadingProps } from 'chakra-react-select';
import { UiText } from '@/ui-elements/ui-text/ui-text';

export const UiVehicleCustomGroupHeading = (props: GroupHeadingProps<unknown, boolean, GroupBase<unknown>>) => {
  return (
    <Box m={2}>
      <UiText textStyle="h4">{props?.children}</UiText>
    </Box>
  );
};
