import './forbidden-caution.scss';
import { isEmpty } from 'lodash';
import { PageWrapper } from 'pages/page-wrapper';
import { Heading } from '@chakra-ui/react';
import { UiLink } from '@/ui-elements/ui-link/ui-link';
import { UiButtonFilled } from '@/ui-elements';
import { useAccount } from '@/features/account/hooks/use-account';
import { UiText } from '@/ui-elements/ui-text/ui-text';

export const ForbiddenCautionComponent = () => {
  const { currentRole, accountRole } = useAccount();
  const userHasRoles = Boolean(currentRole && !isEmpty(accountRole));

  return (
    <PageWrapper>
      <section className="forbidden-caution">
        <Heading>Извините, Вам не разрешен доступ к этой странице</Heading>
        {userHasRoles ? (
          <UiButtonFilled children={<UiLink className="forbidden-caution__link" href="/" children="Перейти к доступным страницам" />} />
        ) : (
          <UiText>Для Вашей роли не предусмотрен доступ в данный раздел. Для получения доступа обратитесь к руководителю.</UiText>
        )}
      </section>
    </PageWrapper>
  );
};
