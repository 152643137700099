export const VerificationRiskGroupOptions = [
  {
    value: 1,
    label: '1',
    'data-testid': 'riskGroup1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
];
