import { Box, Divider, Flex, Grid, GridItem, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import classNames from 'classnames';
import moment from 'moment/moment';
import { OrganisationRating } from 'components/organisation-rating/organisation-rating';
import { RatingType } from 'config/constants/types';
import { BG_LIMIT, COLOR_LIMIT } from 'components/customers/customers-table-component/constants';
import { getRemainder } from 'components/customers/helpers/get-remainder';
import { emptyFunction } from '@/helpers/empty-function';
import { Customer } from '@/types/models/customer';
import { MOMENT_DD_MM_YYYY } from '@/helpers/moment/constants';
import { userSimpleExtender } from '@/features/user-simple/extender/user-simple-extender';
import { UiStatus } from '@/ui-elements/status/status';
import { toPriceFormat } from '@/helpers/price/to-price-format';
import { getCustomerStatus } from '@/helpers/get-customer-status';

type Props = {
  customer: Customer;
  handleCustomersClick?: (id: number) => void;
  selected?: boolean;
};
export const CustomersTableComponentItem: FC<Props> = ({ customer, handleCustomersClick = emptyFunction, selected = false }) => {
  const shortName = userSimpleExtender.shortName(customer.manager);
  const remainder = getRemainder(customer?.circulationLimit, customer?.inCirculation);

  return (
    <Box className={classNames('customer-table-component-item', { selected })} overflow="auto" onClick={() => handleCustomersClick(customer.id)}>
      <HStack m="16px 16px 0 16px">
        <Flex w="100%" justifyContent="space-between">
          <Box>{customer.vipCustomer !== 0 ? <OrganisationRating ratingType={RatingType.Vip} /> : undefined}</Box>
          <Flex w="100%" justifyContent="flex-end">
            <Text className="customer-table-component-item__approved">{getCustomerStatus(customer.approvalStatus)}</Text>
          </Flex>
        </Flex>
      </HStack>
      <Grid templateColumns="repeat(6, 1fr)" gap={2} m="24px 16px 0 16px">
        <GridItem>
          <Text>{customer.id}</Text>
        </GridItem>
        <GridItem>
          <Text>{customer.name}</Text>
        </GridItem>
        <GridItem>
          <Text>{customer.INN}</Text>
        </GridItem>
        <GridItem>
          <Text>{customer.contractNumber}</Text>
        </GridItem>
        <GridItem>{customer.expiration && <Text data-testid="expiration">{moment(customer.expiration).format(MOMENT_DD_MM_YYYY)}</Text>}</GridItem>
        <GridItem>
          <Text>{shortName}</Text>
        </GridItem>
      </Grid>
      {customer.comment || customer.tariffComment ? (
        <>
          <Divider mt="16px" />
          <Flex m="16px 16px 24px 16px">
            <Box w="50%">
              <Text>{customer.comment}</Text>
            </Box>
            <Box>
              <Text>{customer.tariffComment}</Text>
            </Box>
          </Flex>
        </>
      ) : undefined}
      <Divider mt="16px" />
      <Flex gap={5} m="16px">
        <Box>
          <Text>Лимит по факторингу</Text>
          <UiStatus
            text={toPriceFormat(customer.circulationLimit)}
            backGroundColor={BG_LIMIT}
            color={COLOR_LIMIT}
            className="customer-table-component-item__limit"
          />
        </Box>
        <Box>
          <Text textAlign="center">В обороте</Text>
          <UiStatus
            text={toPriceFormat(customer.inCirculation)}
            backGroundColor={BG_LIMIT}
            color={COLOR_LIMIT}
            className="customer-table-component-item__limit"
          />
        </Box>
        <Box>
          <Text>Доступный остаток</Text>
          <UiStatus text={toPriceFormat(remainder)} backGroundColor={BG_LIMIT} color={COLOR_LIMIT} className="customer-table-component-item__limit" />
        </Box>
      </Flex>
    </Box>
  );
};
