import { Box } from '@chakra-ui/react';
import { Children } from 'react';
import classNames from 'classnames';
import './ui-buttons-group.scss';

type Props = {
  buttons?: Array<React.ReactElement | JSX.Element>;
  className?: string;
  spacing?: number;
};

export const UiButtonsGroup = ({ buttons = [], className = '', spacing = 0 }: Props) => (
  <div className={classNames('ui-buttons-group', className)}>{Children.toArray(buttons.map((button) => <Box mr={spacing}>{button}</Box>))}</div>
);

/*
 * Компонент для вывода "умных" кнопок в ряд. Проп spacing добавляет промежутки между кнопками.
 */
