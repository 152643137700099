import { FC } from 'react';

export enum MyOrganizationFormField {
  Name = 'name',
  OrganizationTypeId = 'organizationTypeID',
  DirectorName = 'directorName',
  DirectorSurname = 'directorSurname',
  DirectorPatronymic = 'directorPatronymic',
  Inn = 'INN',
  PassportNumber = 'passportNumber',
  PassportDate = 'passportDate',
  PassportGiven = 'passportGiven',
  RussianPassport = 'russianPassport',
  Birthday = 'birthday',
  BIK = 'bik',
  Bank = 'bank',
  BankAccount = 'bankAccount',
  CorrespondentAccount = 'correspondentAccount',
  VAT = 'vat',
  PassportDocument = 'passportDocument',
  InnOgrnDocument = 'innOgrnDocument',
  /* driver */
  Country = 'country',
  Convictions = 'convictions',
  Loans = 'loans',
  Accidents = 'accidents',
  DriverLicense = 'driverLicense',
  DriverLicenseDate = 'driverLicenseDate',
  DriversLicenseDoc = 'driversLicenseDoc',
  PhoneNumber = 'phoneNumber',
  ContactName = 'contactName',
  Phone = 'phone',
  VatType = 'vatType',
}

export enum MyOrganizationFormSection {
  BankDetails = 'bankDetails',
}

export type FormUiElements = Record<
  MyOrganizationFormField,
  {
    Component: FC<any>;
    defaultProps: {
      type?: string;
      required: boolean;
      options?: [];
      mask?: any;
      name: MyOrganizationFormField;
      label?: string;
    };
  }
>;

export type MyOrganizationFormOptions = Partial<Record<MyOrganizationFormField, Array<{ value: string; label: string }>>>;
export type OrganizationFiles = Record<MyOrganizationFormField.PassportDocument | MyOrganizationFormField.InnOgrnDocument, Array<File>>;

export type MyOrganizationFormValues = Record<MyOrganizationFormField, any>;
export type FileFormField =
  | MyOrganizationFormField.InnOgrnDocument
  | MyOrganizationFormField.PassportDocument
  | MyOrganizationFormField.DriversLicenseDoc;
