import { Box, Heading } from '@chakra-ui/react';
import { UiMaskedField, UiInputField, UiCheckboxField, UiDivider } from '@/ui-elements';
import { REG_NUMBER_TRAILER_MASK } from '@/components/auth-form/constants';
import { ResourcesFormFieldNames, ResourcesFormLabels, ResourcesFormPlaceholders } from '@/features/resources/constants/form';

export const ResourcesSubformTrailer = () => {
  return (
    <>
      <UiDivider className="resources-form-divider" />
      <Heading as="h3" className="resources-form-subtitle">
        Полуприцеп
      </Heading>
      <Box>
        <UiMaskedField
          name={ResourcesFormFieldNames['trailers[0].regNumber']}
          label={ResourcesFormLabels.trailersRegNumber}
          placeholder={ResourcesFormPlaceholders.trailersRegNumber}
          mask={REG_NUMBER_TRAILER_MASK}
          required
        />
        <Box className="resources-form-row">
          <UiInputField
            name={ResourcesFormFieldNames['trailers[0].capacity']}
            label={ResourcesFormLabels.capacity}
            placeholder={ResourcesFormPlaceholders.capacity}
          />
          <UiInputField
            name={ResourcesFormFieldNames['trailers[0].volume']}
            label={ResourcesFormLabels.volume}
            placeholder={ResourcesFormPlaceholders.volume}
          />
          <UiInputField
            name={ResourcesFormFieldNames['trailers[0].dimensions']}
            label={ResourcesFormLabels.dimensions}
            placeholder={ResourcesFormPlaceholders.dimensions}
          />
        </Box>
        <UiCheckboxField
          name={ResourcesFormFieldNames['trailers[0].accidentsInPastThreeYears']}
          label={ResourcesFormLabels.accidentsInPastThreeYears}
        />
      </Box>
    </>
  );
};
