import { MenuButton, MenuButtonProps } from '@chakra-ui/react';
import { FC, ReactElement } from 'react';

/**
 *
 * @param as для добавления компонента, на который добавлен dropdown
 */
export const UiDropdownMenuButton: FC<MenuButtonProps & { icon?: string | ReactElement | React.ElementType }> = ({ children, ...props }) => (
  <MenuButton {...props}>{children}</MenuButton>
);
