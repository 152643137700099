import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/types/redux/root-state';

export const VehicleTypesByIdsSelector = (ids: Array<number>) =>
  createSelector(
    (state: RootState) => state.vehicleTypes,
    (vehicleTypes) => {
      const filtered = vehicleTypes.vehicleTypes.filter((type) => ids.includes(type.id));
      return filtered.map((item) => item.name);
    }
  );
