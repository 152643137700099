import { Errors } from 'config/errors';

export const errorMessages = {
  [Errors.Error_400]: 'Невозможно выполнить запрос',
  [Errors.Error_404]: 'Такой страницы не существует',
  [Errors.Error_500]: 'Ошибка сервера',
  [Errors.Error_Network]: 'Ошибка сети',
  [Errors.Error_401]: 'Вы не авторизованы',
  [Errors.Error_403]: 'В доступе отказано',
};
