import './register-form.scss';
import { FC } from 'react';
import { ACCEPT_USER_AGREEMENT, FormType, ROLE, ROLE_OPTIONS, SEND_BY_SMS, SMS_CODE } from 'components/register/regster-form/constants';
import { RegisterToLogin } from 'components/register/register-to-login/register-to-login';
import { useRegisterForm } from 'components/register/regster-form/use-register-form';
import { FormFieldName, FormFieldPlaceholder } from 'config/constants/form';
import { UiButtonOutlined, UiInputField, UiPasswordField, UiSelectField } from '@/ui-elements';
import { emptyFunction } from '@/helpers/empty-function';
import { UiPhoneField } from '@/ui-elements/form-fields/ui-phone-field/ui-phone-field';
import { UiCheckbox } from '@/ui-elements/checkbox/checkbox';
import { RegisterFormValues } from '@/views/register/register-form-view/helpers/handle-submit-register-form';
import { CodeCheck, CodeSend } from '@/types/api/code-partial';

type Props = {
  onSubmit: (values: CodeSend | CodeCheck | RegisterFormValues) => void;
  registerFormType: FormType;
};

export const RegisterForm: FC<Props> = ({ onSubmit = emptyFunction, registerFormType }) => {
  const { registerFormContent, Form, phoneFieldName, initialValues, isRegisterPhase, isCheckCodePhase } = useRegisterForm(registerFormType);

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} className="register-form">
      {registerFormContent.title}
      <UiPhoneField required content="Для связи и входа в аккаунт" name={phoneFieldName} />
      {isCheckCodePhase && <UiInputField required label={SMS_CODE} name={FormFieldName.code} placeholder={FormFieldPlaceholder.code} />}
      {isRegisterPhase && (
        <>
          <UiSelectField
            options={ROLE_OPTIONS}
            required
            label={ROLE}
            name={FormFieldName.accountRole}
            placeholder={FormFieldPlaceholder.accountRole}
          />
          <UiPasswordField content={SEND_BY_SMS} />
        </>
      )}
      <UiButtonOutlined type="submit" width="full" children={registerFormContent.submitButton} />

      {registerFormContent.hasExtraContent && (
        <>
          <RegisterToLogin />
          <UiCheckbox checked className="register-form__user-agreement-checkbox" children={ACCEPT_USER_AGREEMENT} />
        </>
      )}
    </Form>
  );
};
