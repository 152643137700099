import { FC } from 'react';
import { HStack, Text, Heading } from '@chakra-ui/react';
import { Carrier } from '@/types/models/carrier';
import './carrier-reviews-widget.scss';
import { OrganisationRating } from '@/components/organisation-rating/organisation-rating';
import { declensionNouns } from '@/helpers/declension-of-nouns';
import { ReviewComponent } from './review';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { getCarrierOrganizationINN } from '@/features/carriers/helpers/get-carrier-organization-inn';

type Props = {
  carrier: Carrier;
};

export const CarrierReviewsWidget: FC<Props> = ({ carrier }) => {
  const carrierOrganizationINN = getCarrierOrganizationINN(carrier);
  return (
    <UiScrollbars style={{ width: '100%', height: '100vh' }}>
      <div className="carrier-reviews-widget">
        <HStack>
          <OrganisationRating ratingType={carrier.rating} />
          <Heading>{carrier.name}</Heading>
        </HStack>
        <Text>{`ИНН: ${carrierOrganizationINN}`}</Text>
        <Heading>Отзывы</Heading>
        {carrier.reviews && (
          <Text>
            {`${declensionNouns(carrier.reviews.length, ['Найден', 'Найдено', 'Найдено'])} ${carrier.reviews.length} ${declensionNouns(
              carrier.reviews.length,
              ['отзыв', 'отзыва', 'отзывов']
            )}`}
          </Text>
        )}
        {carrier.reviews?.map((review, id) => (
          <ReviewComponent key={`${review.value + id}`} review={review} />
        ))}
      </div>
    </UiScrollbars>
  );
};
