import { Tooltip, TooltipProps } from '@chakra-ui/react';
import { FC } from 'react';
import classNames from 'classnames';
import './tooltip.scss';

export const UiTooltip: FC<TooltipProps> = ({ className, children, ...props }) => (
  <Tooltip shouldWrapChildren hasArrow className={classNames('ui-tooltip', className)} {...props}>
    {children}
  </Tooltip>
);
