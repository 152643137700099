import { Box, Text } from '@chakra-ui/react';

type Props = {
  title: string;
};

export const FormTitle = ({ title }: Props) => (
  <Box mb={5}>
    <Text fontSize={24} fontWeight={700}>
      {title}
    </Text>
  </Box>
);
