export const typography = {
  h1: {
    fontSize: ['24px'],
    lineHeight: '32px',
    fontWeight: 700,
    color: 'typography.50',
  },
  h2: {
    fontSize: ['20px'],
    lineHeight: '28px',
    fontWeight: 700,
    color: 'typography.50',
  },
  h3: {
    fontSize: ['18px'],
    lineHeight: '24px',
    fontWeight: 600,
    color: 'typography.50',
  },
  h4: {
    fontSize: ['14px'],
    lineHeight: '20px',
    fontWeight: 600,
  },
  h5: {
    fontSize: ['16px'],
    lineHeight: '24px',
    fontWeight: 600,
  },
  h6: {
    fontSize: ['16px'],
    lineHeight: '24px',
    fontWeight: 700,
  },
  h7: {
    fontSize: ['18'],
    lineHeight: '24px',
    fontWeight: 700,
  },
  p2: {
    fontSize: ['18px'],
    lineHeight: '24px',
    fontWeight: 400,
  },
  p3: {
    fontSize: ['14px'],
    lineHeight: '20px',
    fontWeight: 700,
  },
  p4: {
    fontSize: ['14px'],
    lineHeight: '20px',
    fontWeight: 400,
    color: 'typography.100',
  },
  p5: {
    fontSize: ['12px'],
    lineHeight: '16px',
    fontWeight: 400,
    color: 'typography.100',
  },
  p6: {
    fontSize: ['20px'],
    lineHeight: '32px',
    fontWeight: 400,
    letterSpacing: '-2%',
  },
  p7: {
    fontSize: ['16px'],
    lineHeight: '24px',
    fontWeight: 400,
  },
  p8: {
    fontSize: ['12px'],
    lineHeight: '16px',
    fontWeight: 600,
  },
};
