import { FC, useState } from 'react';
import classNames from 'classnames';
import { driversAndVehiclesTabNames, DriversAndVehiclesTabs } from 'components/security/drivers-and-vehicles-list/constants';
import { DriversCompactList } from 'components/security/drivers-compact-list/drivers-compact-list';
import { UiSwitcher } from '@/ui-elements/switcher/switcher';
import './drivers-and-vehicles-list.scss';
import { Driver } from '@/types/models/driver';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { SecurityVehiclesView } from '@/views/security/security-vehicles-view';
import { emptyFunction } from '@/helpers/empty-function';

type Props = {
  drivers?: Array<Driver>;
  selectedDriver?: Driver;
  department?: string;
  // подробнее о водителе
  onDriverMoreInfoClick?: (id: number) => void;
  className?: string;
  isFetching: boolean;
};

export const DriversAndVehiclesList: FC<Props> = ({
  isFetching,
  department,
  drivers,
  className,
  selectedDriver,
  onDriverMoreInfoClick = emptyFunction,
}) => {
  const [tab, setTab] = useState<string>(DriversAndVehiclesTabs.Drivers);
  const onViewChange = (value: string) => {
    setTab(value);
  };

  return (
    <div className={classNames(className, 'drivers-and-vehicles-list')}>
      <UiSwitcher onChange={onViewChange} className="drivers-and-vehicles-list__switcher" tabsList={driversAndVehiclesTabNames} />

      <UiScrollbars autoHide>
        <div className="drivers-and-vehicles-list__content">
          {tab === DriversAndVehiclesTabs.Drivers && (
            <DriversCompactList
              drivers={drivers}
              onDriverMoreInfoClick={onDriverMoreInfoClick}
              selectedDriver={selectedDriver}
              department={department}
              isFetching={isFetching}
            />
          )}
          {tab === DriversAndVehiclesTabs.Vehicles && <SecurityVehiclesView />}
        </div>
      </UiScrollbars>
    </div>
  );
};
