import { FC } from 'react';
import { Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderProps } from '@chakra-ui/react';
import classNames from 'classnames';
import './slider.scss';

export type UiSliderProps = SliderProps;

export const UiSlider: FC<UiSliderProps> = ({ children, className, ...props }) => (
  <Slider {...props} className={classNames('ui-slider', className)}>
    <SliderTrack bg="neutrals.500">
      <SliderFilledTrack bg="products.50" />
    </SliderTrack>
    <SliderThumb />
    {children}
  </Slider>
);
