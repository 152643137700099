import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { ApproveStatus } from 'config/constants/statuses';
import { UiButtonFilled, UiDivider } from '@/ui-elements';
import { CarrierCardRegNumber as RegNumber } from '@/components/carrier-card/carrier-card-reg-number';
import { Approval } from '@/types/models/approval';
import { ApprovalHistory } from '@/components/approval-history/approval-history';
import { useVehicleTypesIcons } from '@/features/vehicle-types/hooks/use-vehicle-types-icons';

type Props = {
  approval?: Approval;
  setChecking: (arg0: boolean) => void;
};

export const VehicleDetails = ({ approval, setChecking }: Props) => {
  const vehicleTypesIcons = useVehicleTypesIcons();
  if (!approval) return null;
  const { vehicle } = approval;
  const vehicleIcon = vehicle?.vehicleTypeId && vehicleTypesIcons[vehicle?.vehicleTypeId];
  if (!vehicle) return null;
  return (
    <VStack align="flex-start" spacing={0}>
      <HStack spacing={4}>
        <Text textStyle="h7">{vehicle.name}</Text>
        {vehicleIcon && <img width="48px" height="20px" src={vehicleIcon} alt="vehicle" />}
        <RegNumber regNumber={vehicle.regNumber} />
      </HStack>
      <ApprovalHistory approval={approval} />
      {approval.status === ApproveStatus.Waiting && (
        <Box pt={6}>
          <UiButtonFilled mode="dark" onClick={() => setChecking(true)} width={172}>
            Начать проверку
          </UiButtonFilled>
        </Box>
      )}
      <UiDivider variant="dark" mb={6} pb={6} />
    </VStack>
  );
};
