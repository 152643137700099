import { VStack, Text, HStack, Spacer } from '@chakra-ui/react';
import { CarrierCardDriverInfo } from 'components/carrier-card/carrier-card-driver-info/carrier-card-driver-info';
import { UiButtonOutlined } from '@/ui-elements';
import { CarrierCardDriverPreferences } from '@/components/carrier-card/carrier-card-driver-info/carrier-card-driver-preferences';
import { Driver } from '@/types/models/driver';

type Props = {
  driver?: Driver;
  onDriverMoreInfoClick?: (driverId: number) => void;
};

export const BestDriverCard = ({ driver, onDriverMoreInfoClick }: Props) => {
  if (!driver) return null;
  return (
    <VStack align="start" mt={6}>
      <Text textStyle="h2" pb={6}>
        Водитель с лучшим рейтингом
      </Text>
      <HStack w="full" pb={1}>
        <CarrierCardDriverInfo driver={driver} />
        <CarrierCardDriverPreferences driver={driver} />
      </HStack>
      <HStack w="full">
        <Spacer />
        <UiButtonOutlined onClick={() => onDriverMoreInfoClick && onDriverMoreInfoClick(driver.id)} mode="dark">
          Подробнее
        </UiButtonOutlined>
      </HStack>
    </VStack>
  );
};
