import { AttachmentDeps } from 'config/constants/types';
import { DocumentTypes } from 'config/constants/document-types';
import { OrderDetailsDocumentsConfirm } from 'components/order-details/order-details-documents/order-details-documents-confirm';
import { OrderExecutionStatus, OrderStatus } from 'config/constants/statuses';
import { useOrderDetailsView } from '@/features/order/hooks/use-order-details-view';
import { useDependentAttachments } from '@/features/attachments/hooks/use-dependent-attachments';
import { UserRole } from '@/types/api/user-role';
import { useAccount } from '@/features/account/hooks/use-account';
import { ModalAction, useModal } from '@/hooks/use-modal';
import { OrganizationDocument, OrganizationDocumentType } from '@/api/documents/documents-types';
import { ModalType } from '@/types/common/modals';
import { useOrderDataActions } from '@/features/order/hooks/use-order-data-actions';
import { useOrderInvalidate } from '@/features/order/hooks/use-order-invalidate';
import { useOrderWithOwnResources } from '@/features/order/hooks/use-order-with-own-resources';

export const CustomerOrderDocumentView = () => {
  const { order, setNewOrderStatus, setBackConfirmedStatus, activeOrderId } = useOrderDetailsView();
  const { currentRole } = useAccount();
  const hasEditRight = currentRole && [UserRole.Administrator, UserRole.Manager].includes(currentRole);
  const { orderWithOwnResources } = useOrderWithOwnResources({ order });
  const { updateOrder } = useOrderDataActions(activeOrderId!);
  const { orderInvalidate, allOrdersInvalidate } = useOrderInvalidate();
  const {
    deleteAttachmentById: deleteDocument,
    attachments,
    downloadAttachments,
    downloadAttachmentById,
    postAttachments,
  } = useDependentAttachments({
    dependent: AttachmentDeps.Order,
    dependentId: order?.id,
    type: DocumentTypes.dlDocumentOrder,
    staleTime: 3000,
  });
  const { modalView: documentTemplateModal, setModalOpened: showDocumentTemplateModal } = useModal<OrganizationDocument & ModalAction>(
    ModalType.DocumentTemplate,
    {
      bankId: 8,
      organizationId: 4,
      order: order?.id,
      origin: OrganizationDocumentType.Customer,
      closeModal: () => showDocumentTemplateModal(false),
      postAttachments,
    }
  ); // TODO на будущую договор-заявку для клиента

  // Если заявка из собственных ресурсов, то подтверждаем оба документа
  const confirmCarrierDocs = orderWithOwnResources ? { confirmedCarrierDocs: true } : {};

  const canConfirmOrder =
    order?.confirmedCarrierDocs && (order?.confirmedCustomerDocs || orderWithOwnResources) && order?.status === OrderExecutionStatus.Preparation;
  // клиент договор
  const submitClientDocuments = () => {
    if (order?.id) {
      updateOrder.mutate(
        { confirmedCustomerDocs: true, ...confirmCarrierDocs },
        {
          onSuccess: () => {
            orderInvalidate(order.id);
            allOrdersInvalidate();
            if (canConfirmOrder) {
              setNewOrderStatus(OrderExecutionStatus.Confirmed);
            }
          },
        }
      );
    }
  };

  const declineClientDocuments = () => {
    if (order?.id) {
      updateOrder.mutate(
        { confirmedCustomerDocs: false },
        {
          onSuccess: () => {
            orderInvalidate(order.id);
            setBackConfirmedStatus();
          },
        }
      );
    }
  };
  return (
    <>
      <OrderDetailsDocumentsConfirm
        title="Документы"
        documents={attachments || []}
        onUpload={hasEditRight ? postAttachments : undefined}
        onSaveAllClick={downloadAttachments}
        onSaveClick={downloadAttachmentById}
        onDeleteClick={hasEditRight ? deleteDocument : undefined}
        showSaveAll
        orderDocumentCreation={[OrderExecutionStatus.Preparation.toLowerCase()].includes(order ? order.status?.toLowerCase() : ('' as OrderStatus))}
        onDocumentsClick={showDocumentTemplateModal}
        disabled={!hasEditRight}
        onConfirmDocClick={submitClientDocuments}
        onDeclineDocClick={declineClientDocuments}
        orderStatus={order?.status as OrderStatus}
        confirmed={order?.confirmedCustomerDocs}
      />
      {documentTemplateModal}
    </>
  );
};
