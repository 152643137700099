import { InputProps } from '@chakra-ui/react';
import { useIMask } from 'react-imask';
import { ChangeEvent, FC, useEffect } from 'react';
import { UiInput } from '@/ui-elements';

export const UiInputMasked: FC<InputProps & { mask: string; unmask?: boolean }> = ({ value, ...props }) => {
  const { onChange, mask, unmask = true, ...rest } = props;

  const { ref, setValue } = useIMask(
    { mask },
    {
      onAccept: (v, maskRef) => {
        if (props?.name) {
          const { name } = props;
          const target = { name, value: unmask ? maskRef?.unmaskedValue : v } as EventTarget & HTMLInputElement;
          const event = { target } as unknown as ChangeEvent<HTMLInputElement>;
          onChange?.(event);
        }
      },
    }
  );

  const onClear = () => {
    setValue('');
  };

  useEffect(() => {
    if (typeof value === 'string') {
      setValue(value);
    }
  }, [value, setValue]);

  return <UiInput {...rest} ref={ref} onClear={onClear} defaultValue={value} />;
};
