import { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { OrderPointInfoCompact } from 'components/order-point-info-compact/order-point-info-compact';
import { Point } from '@/types/models/point';
import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';

export const OrderRouteCompact: FC<{ points?: Array<Point>; route?: Order | Load }> = ({ points, route }) => (
  <Flex flexWrap="wrap" gap={3}>
    {points && points.map((point, index) => <OrderPointInfoCompact key={point.id} point={point} route={route} isFirstPoint={!index} />)}
  </Flex>
);
