export const tabStyles = {
  padding: '8px 12px',
  border: '1px solid #4E4E5A',
  fontSize: '12px',
  lineHeight: '16px',
  _selected: {
    bg: '#005AD3',
    color: '#FFFFFF',
    border: '1px solid #0049AD',
  },
  _hover: {
    color: '#4E4E5A',
    border: '1px solid #2F80ED',
    _selected: {
      color: '#FFFFFF',
      border: '1px solid #2F80ED',
    },
  },
};
