/* eslint-disable @typescript-eslint/no-unused-vars */
import qs from 'query-string';
import { useNavigate } from 'react-router-dom';
import { FC } from 'react';
import { WidgetType } from '@/features/widget/widget-type';
import { WidgetProps } from '@/features/widget/types/widget-item';
import { TripWidget } from '@/components/trip-widget/trip-widget';
import './trip-widget-view.scss';
import { OrderSwitcherStatus } from '@/config/constants/statuses';
import { useOrderActions } from '@/features/order/hooks/use-order-actions';
import { useOrderUpdates } from '@/features/order/hooks/use-order-updates';
import { useCarrierUpdates } from '@/features/carriers/hooks/use-carrier-updates';
import { useOffersUpdates } from '@/features/offers/hooks/use-offers-updates';

export const TripWidgetView: FC<WidgetProps & WidgetType['TripWidget']> = ({ orderId, carrierId }) => {
  const { order } = useOrderUpdates({ id: orderId });
  const orderData = order.data?.data;
  const { offers } = useOffersUpdates(orderId);
  const orderOffers = offers.data?.data || [];
  const selectedOffer = orderOffers.find((x) => x.accepted);
  const { carrier } = useCarrierUpdates(carrierId);
  const carrierData = carrier.data?.data;
  const driver = selectedOffer?.driver;
  const vehicle = selectedOffer?.vehicle;
  const navigate = useNavigate();
  const { select, selectTab } = useOrderActions();

  const handleToTrip = (id?: number) => {
    const url = qs.stringifyUrl({
      url: '/orders',
      query: {
        id,
      },
    });
    select(id);
    selectTab(OrderSwitcherStatus.New);
    navigate(url);
  };

  return (
    <div className="trip-widget-view">
      <TripWidget order={orderData} carrier={carrierData} driver={driver!} vehicle={vehicle!} handleToTripClick={handleToTrip} orderId={orderId} />
    </div>
  );
};
