import { VatType } from 'config/constants/types';
import { isNil } from 'lodash';
import { CarrierSimple } from '@/types/models/carrier';
import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';

export const getPriceForCarrier = (order: Order | Load) => {
  return (carrier: CarrierSimple) => {
    if (isNil(carrier.vatType)) return 0;
    /** Для старых/некоторых перевозчиков сервер возвращает vatType '' (пустая строка)
     * мы должны воспринимать это не как отсутствие vatType, а его "неважность" для клиента,
     * то есть – желание работать по совмещенной системе НДС.
     * В случае, если Перевозчика это не устраивает,
     * он должен отредактировать свой тип НДС и установить его фиксированно
     * */
    const vatType = carrier.vatType || VatType.CombinedVat;
    if (order?.carrierPayment?.priceWithVAT && [VatType.WithVat, VatType.CombinedVat].includes(vatType) && order?.carrierPayment?.priceWithVAT > 0)
      if (order?.carrierPayment?.priceWithVAT && [VatType.WithVat, VatType.CombinedVat].includes(vatType) && order?.carrierPayment?.priceWithVAT > 0)
        return order?.carrierPayment?.priceWithVAT;
    if (
      order.carrierPayment?.priceWithoutVAT &&
      [VatType.WithoutVat, VatType.CombinedVat].includes(vatType) &&
      order?.carrierPayment?.priceWithoutVAT > 0
    )
      return order?.carrierPayment?.priceWithoutVAT;
    return 0;
  };
};
