import { Carrier } from '@/types/models/carrier';
import { Driver } from '@/types/models/driver';
import { carrierToFormValues } from '@/components/verification-forms/helpers/carrier-to-form-values';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';

type Props = {
  carrier?: Carrier;
  driver?: Driver;
  organizationTypes: Array<SelectOption>;
};

export const carrierToApprovalForm = ({ carrier, driver, organizationTypes }: Props) => {
  const carrierValues = carrierToFormValues(carrier, organizationTypes);

  return {
    ...carrierValues,
    loans: driver?.loans || false,
    convictions: driver?.convictions || 0,
  };
};
