import { Dictionary } from 'config/constants/dictionary';
import { ApiGet, ApiPost } from '@/helpers/api/methods';
import { ResponseWithoutPagerSimple, ResponseWithPager } from '@/types/models/pager';
import { Approval, ApprovalBodySimple, ApprovalRequest } from '@/types/models/approval';
import { Metrics } from '@/types/models/metrics';
import { ApprovalsFilter } from '@/types/api/approvals';
import { validateQueryString, stringifyQuery } from '@/helpers/validate-query-string';

export const ApprovalsApi = {
  createApproval: (body: ApprovalRequest) => ApiPost<ResponseWithoutPagerSimple<Approval>>(`${Dictionary.Approvals}`, body),

  getApprovals: (query?: ApprovalsFilter) => ApiGet<ResponseWithPager<Approval>>(`${Dictionary.Approvals}${stringifyQuery(query)}`),

  getApproval: (id: number, updates?: boolean) => ApiGet<ResponseWithoutPagerSimple<Approval>>(`${Dictionary.Approvals}/${id}?updates=${!!updates}`),

  getMetrics: (query?: ApprovalsFilter) =>
    ApiGet<ResponseWithoutPagerSimple<Metrics>>(`${Dictionary.Approvals}/metrics${validateQueryString(query)}`),

  markApprovalApproved: (body: ApprovalBodySimple) => ApiPost<ResponseWithoutPagerSimple<Approval>>(`${Dictionary.Approvals}/approve`, body),

  markApprovalDeclined: (body: ApprovalBodySimple) => ApiPost<ResponseWithoutPagerSimple<Approval>>(`${Dictionary.Approvals}/decline`, body),
};
