import { isEmpty } from 'lodash';
import { FC, SyntheticEvent } from 'react';
import { HStack } from '@chakra-ui/react';
import { UiButtonFilled, UiButtonOutlined } from '@/ui-elements';
import { MyOrganizationInfo } from './my-organization-info';
import { VerificationStatus } from '@/config/constants/statuses';

interface IProps {
  disabled: boolean;
  isEditStatus: boolean;
  approvalStatuses?: Array<VerificationStatus>;

  setEditForm(e: SyntheticEvent): void;

  onToggleEditForm(e: SyntheticEvent): void;
}

export const MyOrganizationActionButtons: FC<IProps> = ({ disabled, isEditStatus, onToggleEditForm, approvalStatuses, setEditForm }) => {
  const readyForEdit = !isEditStatus && !isEmpty(approvalStatuses);
  return (
    <div className="my-organization-actions actions">
      <div className="actions-container">
        <MyOrganizationInfo />
        <HStack justifyContent="center">
          <UiButtonOutlined disabled={disabled} onClick={onToggleEditForm} className="carriers-verification-form__btn-decline" mode="light" w={128}>
            Отменить
          </UiButtonOutlined>
          <UiButtonFilled
            type={readyForEdit ? undefined : 'submit'}
            onClick={readyForEdit ? setEditForm : undefined}
            disabled={false}
            className=""
            mode="dark"
            w={200}
          >
            {readyForEdit ? 'Редактировать' : 'Сохранить'}
          </UiButtonFilled>
        </HStack>
      </div>
    </div>
  );
};
