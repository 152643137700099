import { MyOrganizationsCard } from 'components/my-organizations/my-organizations-info/my-organizations-card';
import { MyOrganizationsInfoCard } from 'components/my-organizations/my-organizations-info/my-organizations-info-card';
import { MyOrganizationsPaymentInfoCard } from 'components/my-organizations/my-organizations-info/my-organizations-payment-info-card';
import { FC } from 'react';
import { UiDivider } from '@/ui-elements';
import { MyOrganizationBanking, MyOrganizationType } from '@/types/models/my-organization/my-organization';

type Props = {
  organizationData: MyOrganizationType;
  bankingData: Array<MyOrganizationBanking>;
  onBankingAdd: (arg0: boolean) => void;
  hasEditRight?: boolean;
};

export const MyOrganizationsGeneralInfo: FC<Props> = ({ organizationData, bankingData, onBankingAdd, hasEditRight }) => (
  <>
    <MyOrganizationsCard data={organizationData} hasEditRight={hasEditRight} />
    <UiDivider pt="25px" variant="dark" />
    <MyOrganizationsInfoCard data={organizationData} />
    <UiDivider pt="25px" variant="dark" />
    <MyOrganizationsPaymentInfoCard
      data={bankingData}
      onBankingAdd={onBankingAdd}
      organizationId={organizationData?.id}
      hasEditRight={hasEditRight}
    />
  </>
);
