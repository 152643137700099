import { FormControl, FormLabel, FormErrorMessage, FormHelperText, InputProps, Text } from '@chakra-ui/react';
import { useField } from 'react-final-form';
import eye from 'public/assets/icons/eye.svg';
import closedEye from 'public/assets/icons/closed-eye.svg';
import React, { useState } from 'react';
import { UiIconButton, UiInput } from '@/ui-elements';

type Props = {
  name: string;
  label?: string;
  content?: string;
  required?: boolean;
  initialValue?: string | number;
  hideBottomText?: boolean;
  canHide?: boolean;
  hideClearButton?: boolean;
};

export const UiInputField = ({ name, label, required, content, hideBottomText, canHide, initialValue, ...props }: Props & InputProps) => {
  const [textIsHidden, setTextIsHidden] = useState(false);
  const { isDisabled } = props;
  const {
    input,
    meta: { error, touched },
  } = useField(name, { initialValue });
  const invalid = error && touched;

  return (
    <FormControl isRequired={required && !isDisabled} isInvalid={invalid}>
      <FormLabel mb={1}>
        <Text noOfLines={1}>{label}</Text>
      </FormLabel>
      <UiInput {...props} {...input} isInvalid={invalid} onClear={() => input.onChange('')} type={textIsHidden ? 'password' : 'text'}>
        {canHide && (
          <UiIconButton aria-label="show" icon={canHide && !textIsHidden ? closedEye : eye} onClick={() => setTextIsHidden((prev) => !prev)} />
        )}
      </UiInput>
      {!hideBottomText && (invalid ? <FormErrorMessage>{error}</FormErrorMessage> : <FormHelperText>{content}</FormHelperText>)}
    </FormControl>
  );
};
