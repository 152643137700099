import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { reducer } from '@/redux/reducer';

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
  configureStore({
    reducer,
    devTools: process.env.NODE_ENV !== 'production',
    ...options,
  });

export const store = createStore();
