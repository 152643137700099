import { OrderLoadType } from '@/config/constants/types';
import { getEmptyStr } from '@/helpers/get-empty-str';
import { Load } from '@/types/models/loads';
import { CreateLoadFormField, InfoField, RouteField, VehicleField } from '@/types/models/funnel-create-load-form';
import { loadOptions, tonnageOptions } from '@/components/create-order-form/subforms/form-configs';
import { getInitialControls, getPointDisplay } from '@/features/funnel/helpers';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { loadingTypes } from '@/config/constants/loading-types';

interface Props {
  respData?: Load;
  packageTypes: Array<SelectOption>;
  cargoTypes: Array<SelectOption>;
  vehicleTypesNames: Record<number, string>;
  unloadCargoOptions?: Array<SelectOption>;
}

export const getUpdatedValuesFromRespData = ({ respData, packageTypes, cargoTypes, vehicleTypesNames, unloadCargoOptions }: Props) => {
  const responsiblePersonName = `${getEmptyStr(respData?.responsiblePerson?.surname)} ${getEmptyStr(respData?.responsiblePerson?.name)} ${getEmptyStr(
    respData?.responsiblePerson?.patronymic
  )}`;

  return {
    [CreateLoadFormField.LoadInfo]: {
      [InfoField.ResponsiblePerson]: { value: respData?.responsiblePerson?.id, label: responsiblePersonName },
      // [InfoField.RiskGroup]: { value: respData?.riskGroup, label: respData?.riskGroup },
      [InfoField.CustomerCompany]: { value: respData?.customer?.id, label: respData?.customer?.name },
      [InfoField.ContactPerson]: respData?.contact?.name,
      [InfoField.ContactPhone]: respData?.contact?.phone,
      id: respData?.id,
    },
    [CreateLoadFormField.VehicleType]: {
      [VehicleField.VehicleTypes]: respData?.vehicleTypes?.map(Number),
      [VehicleField.LoadingType]: respData?.loadingType?.map((type) => ({
        value: loadOptions.find((x) => x.label === type)?.value,
        label: loadOptions.find((x) => x.label === type)?.label,
      })),
      [VehicleField.AdditionalRequirements]: respData?.additionalRequirements?.additionalRequirements,
      [VehicleField.LoadType]: respData?.transportationType,
      [VehicleField.Belts]: respData?.additionalRequirements?.belts,
      [VehicleField.Tonnage]: tonnageOptions.find((x) => x.value === Number(respData?.additionalRequirements?.tonnage)),
      [VehicleField.TIR]: respData?.permissions?.TIR,
      [VehicleField.CMR]: respData?.permissions?.CMR,
      [VehicleField.T1]: respData?.permissions?.T1,
      [VehicleField.MedicalBook]: respData?.permissions?.medicalBook,
    },
    [CreateLoadFormField.Route]: {
      [RouteField.RouteTypeField]: respData?.type,
      [RouteField.Points]: respData?.points
        ?.map((point) => {
          // массивы с точками погрузки и точками разгрузки (информация о типе достается из actions)
          const pointCargoIdsLoading = respData.actions
            .filter((action) => action.pointId === point.id && action.type === 1)
            .map((act) => act.cargoId);
          const pointCargoIdsUnloading = respData.actions
            .filter((action) => action.pointId === point.id && action.type === 0)
            .map((act) => act.cargoId);

          // массивы с грузами для погрузки и разгрузки (информация о типе достается из actions)
          const cargoListLoading = respData.cargos.filter((cargo) => pointCargoIdsLoading.includes(cargo.tenderLotPositionID));
          const cargoListUnloading = respData.cargos.filter((cargo) => pointCargoIdsUnloading.includes(cargo.tenderLotPositionID));
          const newObjLoading =
            (cargoListLoading.length &&
              getPointDisplay(point, cargoListLoading, OrderLoadType.Loading, packageTypes, cargoTypes, unloadCargoOptions as SelectOption[])) ||
            undefined;
          const newObjUnloading =
            (cargoListUnloading.length &&
              getPointDisplay(point, cargoListUnloading, OrderLoadType.Unloading, packageTypes, cargoTypes, unloadCargoOptions as SelectOption[])) ||
            undefined;
          // eslint-disable-next-line no-nested-ternary
          return newObjLoading && newObjUnloading ? [newObjUnloading, newObjLoading] : newObjLoading ? [newObjLoading] : [newObjUnloading];
        })
        ?.flat(),
    },
    [CreateLoadFormField.State]: {
      [CreateLoadFormField.Cargo]: respData
        ? getInitialControls(respData, packageTypes, cargoTypes, unloadCargoOptions as SelectOption[])
        : [[[1, 2]]],
    },
    [CreateLoadFormField.Requirements]: {
      loadingType: respData?.loadingType?.map((type) => {
        return { value: type, label: loadingTypes.find((x) => x.value === type)?.label };
      }),
      additionalRequirements: respData?.additionalRequirements,
      vehicleTypes: respData?.vehicleTypes?.map((id) => {
        return { label: vehicleTypesNames[id], value: id };
      }),
    },
  };
};
