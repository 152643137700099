import { Box, Heading, Text } from '@chakra-ui/react';
import { FC } from 'react';
import './reviews-tab.scss';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { declensionNouns } from '@/helpers/declension-of-nouns';
import { ReviewComponent } from '../driver-widget/review';
import { Carrier } from '@/types/models/carrier';
import { OrganisationRating } from '../organisation-rating/organisation-rating';

type Props = {
  carrier: Carrier;
};

export const RewiewsTab: FC<Props> = ({ carrier }) => (
  <UiScrollbars style={{ width: '100%', height: '100vh' }}>
    <Box className="reviews-tab">
      <Heading>Название организации</Heading>
      <Text className="reviews-tab__name">{carrier.name}</Text>
      <OrganisationRating ratingType={carrier.rating} />
      <Heading>Отзывы</Heading>
      {carrier?.reviews?.length ? (
        <>
          <Text mt="12px">
            {`${declensionNouns(carrier.reviews.length, ['Найден', 'Найдено', 'Найдено'])} ${carrier.reviews.length} ${declensionNouns(
              carrier.reviews.length,
              ['отзыв', 'отзыва', 'отзывов']
            )}`}
          </Text>
          {carrier.reviews.map((review, id) => (
            <ReviewComponent key={`${review.value + id}`} review={review} />
          ))}
        </>
      ) : (
        <Text>Нет</Text>
      )}
    </Box>
  </UiScrollbars>
);
