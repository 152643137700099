import { useQuery } from '@tanstack/react-query';
import { MyOrganizationsApi } from '@/api/my-organizations';

export const useOrganizationEnum = () => {
  const organizationsEnum = useQuery({
    queryKey: ['settingsEnum'],
    refetchOnWindowFocus: false,
    queryFn: () => MyOrganizationsApi.getOrganizationEnum(),
  });
  const settingOrganizationsEnuData = Object.entries(organizationsEnum?.data?.data ?? []).map(([key, value]) => {
    return {
      value: +key,
      label: value,
    };
  });

  return {
    settingOrganizationsEnuData,
    organizationsEnum,
  };
};
