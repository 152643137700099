import { OrdersApi } from '@/api/orders';
import { useQueryWithUpdates } from '@/hooks/use-query-with-updates';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';
import { OrderTimeline } from '@/types/models/order-timeline';

export const useOrderTimelineUpdates = (id?: number) => {
  const queryFn = (isFirst: boolean) => OrdersApi.getOrderTimeline(id!, !isFirst);

  const orderTimeline = useQueryWithUpdates<ResponseWithoutPagerSimple<OrderTimeline[]>>({
    queryKey: ['orderTimeline', id],
    refetchOnWindowFocus: false,
    staleTime: 5000,
    enabled: !!id,
    queryFn,
  });
  return { orderTimeline };
};
