import { isNil } from 'lodash';
import { toPriceFormat } from '@/helpers/price/to-price-format';
import { RUB_CURRENCY, VatOptionsTexts } from '@/config/constants/loads';
import { OrderPrice } from '@/types/models/order';

export const getCardPriceTitle = (type: 'customer' | 'carrier', withVat: boolean = true, price?: OrderPrice) => {
  if (isNil(price)) return null;
  const priceString = toPriceFormat(price, true);
  const vatString = withVat ? VatOptionsTexts.withVat : VatOptionsTexts.withoutVat;

  return `Цена для ${type === 'customer' ? 'клиента' : 'исполнителя'}: ${priceString}${RUB_CURRENCY} ${vatString}`;
};
