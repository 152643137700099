import classNames from 'classnames';
import { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { FONT_SIZE_CONTENT_12, MARGIN_TOP } from 'components/carrier-card/carrier-card-driver-info/style-constants-content';
import { UiStatus } from '@/ui-elements/status/status';
import { Driver } from '@/types/models/driver';
import './carrier-card-driver-preferences.scss';
import { UiTooltip } from '@/ui-elements/tooltip/tooltip';

type Props = {
  driver: Driver;
  readOnly?: boolean;
};
export const CarrierCardDriverPreferences: FC<Props> = ({ driver, readOnly }) => {
  if (!driver) return null;
  return (
    <Box className={classNames('destinations', { readOnly })} flex-direction="column" align-items="flex-start">
      {driver.driveStatus === 'waiting' && <UiStatus text="Сейчас свободен" color="typography.250" backGroundColor="accentMint.50" />}
      <Text fontSize={FONT_SIZE_CONTENT_12} mt="16px">
        {driver.preferredDirections.join(' ')}
      </Text>
      <Flex gap="12px" fontSize={FONT_SIZE_CONTENT_12} mt={MARGIN_TOP}>
        <Text>
          Крайний тариф: <span className="destinations__bold">{driver.lastPrice}</span> ₽
        </Text>
        <Text>
          Крайний рейс:
          <UiTooltip label={driver.lastCruiseNumber}>
            <span className="destinations__last-cruise">№ {driver.lastCruiseNumber}</span>
          </UiTooltip>
        </Text>
      </Flex>
    </Box>
  );
};
