import { useQueryClient } from '@tanstack/react-query';

export const useCarrierInvalidate = () => {
  const client = useQueryClient();
  const carrierInvalidate = (id?: number) => {
    client.invalidateQueries(['carrier', id]);
  };

  const allCarriersInvalidate = (onSuccess?: VoidFunction) => {
    client.invalidateQueries(['allCarriers']).then(() => onSuccess?.());
  };

  return {
    allCarriersInvalidate,
    carrierInvalidate,
  };
};
