import { List, ListItem } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { VerificationCardBuilder } from '@/views/carriers-verification/verification-card-builder';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import './carriers-verification-list.scss';
import { Approval } from '@/types/models/approval';
import { InfiniteScroll } from '@/views/infinite-scroll/infinite-scroll';

type Props = {
  carriersVerificationList: Array<Approval>;
  loadMore: (args: any) => void;
  loading?: boolean;
  setChecking: (arg0: boolean) => void;
};

export const CarriersVerificationList = ({ carriersVerificationList, loadMore, loading, setChecking }: Props) => (
  <List className="carriers-verification-list">
    <UiScrollbars autoHide style={{ width: '100%', height: '100%' }}>
      <InfiniteScroll callback={loadMore} loading={loading} skip={isEmpty(carriersVerificationList)}>
        {carriersVerificationList.map((item) => {
          if (!item) return null;
          return (
            <ListItem key={item.id} m={4}>
              <VerificationCardBuilder approval={item} setChecking={setChecking} />
            </ListItem>
          );
        })}
      </InfiniteScroll>
    </UiScrollbars>
  </List>
);
