import { useEffect, useRef, useState } from 'react';
import { COLLAPSE_FILTER_SCROLL_AMOUNT } from '@/config/constants/carriers';

type Props = {
  initialValue?: boolean;
};

/**
 * Хук для автосворачивания Accordion или Expandable при скролле другого компонента
 * */

const useAutomaticCollapse = ({ initialValue = true }: Props) => {
  const [isExpanded, setIsExpanded] = useState(initialValue);

  const handleExpandChange = () => {
    setIsExpanded((prevExpanded) => !prevExpanded);
  };
  const compRef = useRef<HTMLDivElement | null>(null);

  /**
   * При скролле больше COLLAPSE_FILTER_SCROLL_AMOUNT компонента, на который навешивается compRef срабатывает сворачивание Accordion
   * */

  useEffect(() => {
    const el = compRef?.current;
    const handleScroll = () => {
      const scrollPosition = el?.scrollTop;
      if (scrollPosition && scrollPosition > COLLAPSE_FILTER_SCROLL_AMOUNT) {
        setIsExpanded(false);
      }
    };

    el?.addEventListener('scroll', handleScroll);

    return () => {
      el?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { compRef, handleExpandChange, isExpanded };
};

export default useAutomaticCollapse;
