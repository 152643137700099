import { FC } from 'react';
import { PageWrapper } from 'pages/page-wrapper';
import { Flex } from '@chakra-ui/react';
import { OrderSidebar } from '@/views/order-sidebar/order-sidebar';
import { OrderListView } from '@/views/order-list/order-list-view';
import { useStimulSoft } from '@/hooks/use-stimulsoft';

export const OrdersPage: FC = () => {
  useStimulSoft();
  return (
    <PageWrapper>
      <Flex>
        <OrderListView />
        <OrderSidebar />
      </Flex>
    </PageWrapper>
  );
};
