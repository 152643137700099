import { FC } from 'react';
import { Flex, HStack } from '@chakra-ui/react';
import { CarrierCardDriverInfo } from 'components/carrier-card/carrier-card-driver-info/carrier-card-driver-info';
import { CarrierCardRegNumber } from 'components/carrier-card/carrier-card-reg-number';
import { CarrierSimple } from '@/types/models/carrier';
import { EXECUTOR } from '@/features/order/texts';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { Driver } from '@/types/models/driver';
import './set-carrier-driver-info.scss';
import { OrderGeneralInfo } from '@/components';
import { ShippedTotals } from '@/types/models/shipped-totals';
import { Vehicle } from '@/types/models/vehicle';
import { vehicleExtender } from '@/features/vehicles/extender/vehicle-extender';

/**
 *Информация о перевозчике и водителе, может принимать children - будут отображены справа от блока с информацией
 * */
export const SetCarrierDriverInfo: FC<{
  carrier?: CarrierSimple;
  driver: Driver;
  vehicle: Vehicle;
  // убирает дополнительную информацию по заявке, которая нужна только логисту
  hideOrderLogistInfo?: boolean;
  children?: JSX.Element | Array<JSX.Element>;
  shippedTotals?: ShippedTotals;
}> = ({ carrier, driver, children, vehicle, shippedTotals = {}, hideOrderLogistInfo = false }) => {
  const name = carrier?.name;
  const { vehicleRegNumber, vehicleRegion } = useExtendedObject(vehicle, vehicleExtender);

  return (
    <div className="set-carrier-driver-info">
      <HStack justifyContent="space-between" alignItems="flex-start">
        <div className="set-carrier-driver-info__left">
          <div className="set-carrier-driver-info__customer">
            {EXECUTOR}: {name}
          </div>
          <CarrierCardDriverInfo driver={driver} hideApprovalInformation={hideOrderLogistInfo} />
        </div>
        <div className="set-carrier-driver-info__right">{children}</div>
      </HStack>

      {/* Данные по транспорту: гос. номер и характеристики */}
      {!hideOrderLogistInfo && (
        <Flex className="set-carrier-driver-info__reg">
          <CarrierCardRegNumber regNumber={vehicleRegNumber} region={vehicleRegion} />
          <OrderGeneralInfo loadingType={vehicle?.loadingType || []} shippedTotals={shippedTotals} vehicleTypes={[vehicle?.vehicleType]} />
        </Flex>
      )}
    </div>
  );
};
