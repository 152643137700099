import { useQuery } from '@tanstack/react-query';
import { OrdersApi } from '@/api/orders';

export const useOrder = (id?: number, staleTime?: number) => {
  const order = useQuery({
    queryKey: ['orderWithoutUpdate', id],
    enabled: !!id,
    staleTime,
    refetchOnWindowFocus: false,
    queryFn: () => OrdersApi.getOrder(id!),
  });
  return { order };
};
