import { useCallback } from 'react';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { customersActions } from '@/features/customers/slice';

export const useCustomerSelect = () => {
  const dispatch = useAppDispatch();
  const select = (customerId: number) => {
    dispatch(customersActions.select(customerId));
  };
  const unselect = useCallback(() => {
    dispatch(customersActions.unselect());
  }, [dispatch]);

  return { select, unselect };
};
