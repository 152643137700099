import { EMPTY_STRING } from 'config/constants/common-strings';

export type AddressAutoCompleteOption = {
  id: string;
  name: string;
  label: string;
  value: string;
};

export type AddressAutoCompleteOptions = Array<AddressAutoCompleteOption>;

export const normalizeAddressAutoCompleteResponse = (
  variants: Array<{ value: string; data: { geo_lat: string | null; geo_lon: string | null } }>
) => {
  return Object.values(variants).reduce((acc: AddressAutoCompleteOptions, { value, data }) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { geo_lat, geo_lon } = data;
    const latLon = geo_lat && geo_lat ? `${geo_lat},${geo_lon}` : EMPTY_STRING;
    const option = { label: value, name: value, value: latLon, id: latLon };
    return [...acc, option];
  }, []);
};
