/* eslint-disable @typescript-eslint/no-unused-vars */
import { MyOrganizationsApi } from '@/api/my-organizations';
import { useQueryWithUpdates } from '@/hooks/use-query-with-updates';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';
import { MyOrganizationBanking } from '@/types/models/my-organization/my-organization';

export const useOrganizationBankingUpdates = (id?: number): any => {
  const queryFn = (isFirst: boolean) => MyOrganizationsApi.getOrganizationBanking(id!, false);
  return useQueryWithUpdates<ResponseWithoutPagerSimple<MyOrganizationBanking>>({
    queryKey: ['organizationBanking', id],
    refetchOnWindowFocus: false,
    enabled: !!id,
    queryFn,
  });
};
