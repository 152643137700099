import './load-status-tag.scss';
import classNames from 'classnames';
import { VStack } from '@chakra-ui/react';
import { FunnelLoadStatus, LogisticsLoadStatus } from '@/types/models/funnel';
import { FunnelLoadStatusTitles, FunnelLogisticsStatusTitles } from '@/config/constants/funnel';
import { UiStatus } from '@/ui-elements/status/status';
import { UiText } from '@/ui-elements/ui-text/ui-text';

export const LoadStatusTag = ({ status, logisticsStatus }: { status?: FunnelLoadStatus; logisticsStatus?: LogisticsLoadStatus }) => {
  if (!status) return null;

  return (
    <VStack className={classNames('load-status-tag', { [status]: status })} justifyContent="center" spacing="1">
      <UiStatus text={FunnelLoadStatusTitles?.[status]} backGroundColor="transparent" color="#232735" />
      {logisticsStatus && <UiText textTransform="lowercase">{`(${FunnelLogisticsStatusTitles[logisticsStatus]})`}</UiText>}
    </VStack>
  );
};
