import { InputProps } from '@chakra-ui/react';
import { CreatableSelect, Props as SelectProps, Select } from 'chakra-react-select';
import classNames from 'classnames';
import { chakraStyles } from './config';
import { SelectOption } from './types';
import './ui-select.scss';

type Props = {
  options: Array<SelectOption>;
  className?: string;
  overflown?: boolean;
  isCreatable?: boolean;
  onChangeHandler?: any;
};

export const UiSelect = ({
  placeholder = 'Выберите вариант',
  isClearable = true,
  options = [],
  className,
  isCreatable,
  overflown = false, // prop для вывода dropdown в портале, нужен, если dropdown обрезается родителем с overflow:hidden
  onChange,
  onChangeHandler,
  value,
  noOptionsMessage,
  ...props
}: Props & Omit<InputProps, 'size' | 'variant' | 'placeholder'> & SelectProps) => {
  const noOptions = () => <p>Нет данных</p>;
  return isCreatable ? (
    <CreatableSelect
      {...props}
      className={classNames('ui-select', className)}
      options={options}
      placeholder={placeholder}
      errorBorderColor="accentRed.100"
      focusBorderColor="products.200"
      isClearable={isClearable}
      onChange={(event: any) => {
        onChange?.(event);
        onChangeHandler?.(event);
      }}
      value={value}
      styles={{
        menuPortal: (base: any) => ({
          ...base,
          zIndex: 1200,
        }),
      }}
      chakraStyles={chakraStyles}
      menuPortalTarget={overflown ? document.body : undefined}
      menuPosition={overflown ? 'fixed' : 'absolute'}
      noOptionsMessage={noOptionsMessage || noOptions}
      formatCreateLabel={(type: string) => `Добавить новый тип - '${type}'`}
    />
  ) : (
    <Select
      {...props}
      className={classNames('ui-select', className)}
      options={options}
      placeholder={placeholder}
      errorBorderColor="accentRed.100"
      focusBorderColor="products.200"
      value={value}
      onChange={(event: any) => {
        onChange?.(event);
        onChangeHandler?.(event);
      }}
      isClearable={isClearable}
      styles={{
        menuPortal: (base: any) => ({
          ...base,
          zIndex: 1200,
        }),
      }}
      chakraStyles={chakraStyles}
      menuPortalTarget={overflown ? document.body : undefined}
      menuPosition={overflown ? 'fixed' : 'absolute'}
      noOptionsMessage={noOptionsMessage || noOptions}
    />
  );
};
