import { Textarea, TextareaProps, Text } from '@chakra-ui/react';
import { FC } from 'react';
import './textarea.scss';
import classNames from 'classnames';

type Props = {
  onClear?: () => void;
  label?: string;
} & TextareaProps;

export const UiTextarea: FC<Props> = ({ children, label, ...props }) => {
  const { className } = props;

  return (
    <div>
      <Text mb={1} fontSize="xs" className="ui-field__label">
        {label}
      </Text>
      <Textarea className={classNames(className, 'ui-textarea')} {...props} />
    </div>
  );
};
