import { FC } from 'react';
import classNames from 'classnames';
import { Divider, HStack, VStack } from '@chakra-ui/react';
import { CONFIRM_EXECUTION, CONFIRM_ORIGINALS, ORDER_COMPLETED } from 'components/order-details/order-details-documents/texts';
import { OrderDetailsStatus } from 'components/order-details/order-details-status/order-details-status';
import { Order } from '@/types/models/order';
import './order-details-documents.scss';
import { UiButtonFilled } from '@/ui-elements';
import { VoidFunction } from '@/types/common/void-function';
import { emptyFunction } from '@/helpers/empty-function';
import { OrderExecutionStatus } from '@/config/constants/statuses';
import { PointDocumentView } from '@/views/order-details-document/point-document-view';
import { CarrierOrderDocumentView } from '@/views/order-details-document/carrier-order-document-view';
import { CustomerOrderDocumentView } from '@/views/order-details-document/customer-order-document-view';
import { CarrierOriginOrderDocumentView } from '@/views/order-details-document/carrier-origin-order-document-view';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { orderExtender } from '@/features/order/extender/order-extender';
import { useIcons } from '@/hooks/use-icons';
import { Load } from '@/types/models/loads';

/**
 * Блок Документы и детали на вкладке Заказ Клиента и Заказ Исполнителя
 * */
export const OrderDetailsDocuments: FC<{
  order: Order | Load;
  title?: string;
  companyName: string;
  onConfirmClick?: VoidFunction;
  // подтвердить получение оригиналов
  onConfirmOriginalsClick?: VoidFunction;
  fuelReadOnly?: boolean;
  showPointsDocuments?: boolean;
  showCarrierConfirmDocuments?: boolean;
  showCustomerDocuments?: boolean;
  showConfirmExecution?: boolean;
  showConfirmCustomerOriginals?: boolean;
  showCarrierOriginDocuments?: boolean;
}> = ({
  companyName,
  order,
  title,
  onConfirmClick,
  onConfirmOriginalsClick = emptyFunction,
  fuelReadOnly = false,
  showPointsDocuments = false,
  showCarrierConfirmDocuments = false,
  showCarrierOriginDocuments = false,
  showCustomerDocuments = false,
  showConfirmExecution = false,
  showConfirmCustomerOriginals = false,
}) => {
  const { isCompleted } = useExtendedObject(order, orderExtender);
  const { DocumentsAccepted } = useIcons();
  const onConfirmOriginalsHandler = () => {
    onConfirmOriginalsClick();
  };

  return (
    <div className={classNames('order-details-documents')}>
      <HStack className="order-details-documents__body">
        <VStack className="order-details-documents__info">
          <span className="order-details-documents__header">{title}</span>
          <div>
            <span className="order-details-documents__customer">{companyName}</span>
            <span className="order-details-documents__title">{order.number}</span>
          </div>
        </VStack>
        <OrderDetailsStatus order={order} />
      </HStack>
      {showCustomerDocuments && <CustomerOrderDocumentView />}
      {showCarrierConfirmDocuments && <CarrierOrderDocumentView />}
      {showPointsDocuments &&
        order.points.map((point) => {
          return <PointDocumentView pointId={point.id} key={point.id} />;
        })}
      {showCarrierOriginDocuments && <CarrierOriginOrderDocumentView />}
      <Divider className="order-details-documents__divider" />

      <HStack className="order-details-documents__confirm-button">
        {showConfirmExecution && (
          <HStack>
            <UiButtonFilled
              disabled={order.status !== OrderExecutionStatus.Executing || fuelReadOnly || !order.carrierOrigins?.confirmed || isCompleted}
              onClick={onConfirmClick}
            >
              {isCompleted ? ORDER_COMPLETED : CONFIRM_EXECUTION}
            </UiButtonFilled>
            {isCompleted && <DocumentsAccepted />}
          </HStack>
        )}
        {showConfirmCustomerOriginals && (
          <UiButtonFilled disabled={order.status !== OrderExecutionStatus.CompletedWithoutDocs || fuelReadOnly} onClick={onConfirmOriginalsHandler}>
            {CONFIRM_ORIGINALS}
          </UiButtonFilled>
        )}
      </HStack>
    </div>
  );
};
