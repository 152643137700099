import { OrderFuelCount } from '@/types/api/order-partial';

export const initialCounts = {
  confirmedWithoutFuel: 0,
  executingWithoutFuel: 0,
  completedWithoutFuel: 0,
  confirmedWithFuel: 0,
  executingStartPreparation: 0,
  executingFuelProvided: 0,
  completedBlockFuel: 0,
  completedFuelClosed: 0,
};

export const responseToFuelCount = (resp: OrderFuelCount) => {
  const result: { [key: string]: number } = resp.reduce((acc, obj) => ({ ...acc, [obj.alias]: Number(obj.count) }), { ...initialCounts });
  return result;
};
