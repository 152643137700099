import { Load } from '@/types/models/loads';
import { Order } from '@/types/models/order';

export const checkLoadCalculatedState = (load?: Load | Order) => {
  if (!load) return false;

  const { customerPayment, carrierPayment } = load;
  const customerPaymentExists = customerPayment?.priceWithVAT || customerPayment?.priceWithoutVAT || customerPayment?.price;
  const carrierPaymentExists = carrierPayment?.priceWithVAT || carrierPayment?.priceWithoutVAT;

  return Boolean(customerPaymentExists && carrierPaymentExists);
};
