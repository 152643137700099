import './resources-driver-details-component.scss';
import { FC } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { Box, HStack, Heading, Text, VStack } from '@chakra-ui/react';
import { UiButtonFilled, UiButtonOutlined, UiDivider } from '@/ui-elements';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';
import { useIcons } from '@/hooks/use-icons';
import { Driver } from '@/types/models/driver';
import { UiFileList } from '@/ui-elements/ui-file-list/ui-file-list';
import { UseDependentAttachmentsReturnValues } from '@/features/attachments/hooks/use-dependent-attachments';
import { ResourcesButtonActions } from '@/features/resources/constants/general';

interface Props {
  driver?: Driver;
  driverPassportDocs: UseDependentAttachmentsReturnValues;
  driverLicenseDocs: UseDependentAttachmentsReturnValues;
  loading: boolean;
  handleDeleteDriver: VoidFunction;
  handleEditDriver: VoidFunction;
  handleShowResetPasswordModal: VoidFunction;
}

export const ResourcesDriverDetailsComponent: FC<Props> = ({
  driver,
  driverPassportDocs,
  driverLicenseDocs,
  loading,
  handleDeleteDriver,
  handleEditDriver,
  handleShowResetPasswordModal,
}) => {
  const driverLicenseDate = moment(driver?.driverLicenseDate).format('DD/MM/YYYY');
  const { DeleteIcon, EditIcon } = useIcons();
  if (loading) {
    return <UiSpinner visible />;
  }

  return (
    <Box className="resources-driver-details">
      <Heading as="h2" size="md" className="resources-driver-details-title">
        Информация о водителе
      </Heading>
      <UiDivider />
      <VStack className="resources-driver-details-content">
        <Box>
          <Heading as="h3" size="sm">
            Проверен в филиале
          </Heading>
          <Text>{driver?.department}</Text>
        </Box>
        <Box>
          <Heading as="h3" size="sm">
            Комментарии сотрудника СБ
          </Heading>
          <Text>{driver?.securityComment || 'Нет'}</Text>
        </Box>
        <Box>
          <Heading as="h3" size="sm">
            ФИО
          </Heading>
          <Text>{`${driver?.surname} ${driver?.name} ${driver?.patronymic}`}</Text>
        </Box>
        <Box>
          <Heading as="h3" size="sm">
            Телефон
          </Heading>
          <Text>{driver?.phone}</Text>
        </Box>
        <Box>
          <Heading as="h3" size="sm">
            Водительские права
          </Heading>
          <Text>
            {driver?.driverLicense}
            {driver?.driverLicenseDate && ` от ${driverLicenseDate}`}
          </Text>
          {!isEmpty(driverLicenseDocs?.attachments) && (
            <UiFileList
              fileList={driverLicenseDocs.attachments}
              onDownload={driverLicenseDocs.downloadAttachmentById}
              onDelete={driverLicenseDocs.deleteAttachmentById}
            />
          )}
        </Box>
        <Box>
          <Heading as="h3" size="sm">
            Паспорт
          </Heading>
          {!isEmpty(driverPassportDocs?.attachments) && (
            <UiFileList
              fileList={driverPassportDocs.attachments}
              onDownload={driverPassportDocs.downloadAttachmentById}
              onDelete={driverPassportDocs.deleteAttachmentById}
            />
          )}
        </Box>
      </VStack>
      <HStack className="resources-driver-details-buttons">
        <UiButtonOutlined mode="light" onClick={handleEditDriver} leftIcon={EditIcon}>
          {ResourcesButtonActions.Edit}
        </UiButtonOutlined>
        <UiButtonFilled mode="light" onClick={handleDeleteDriver} leftIcon={DeleteIcon}>
          {ResourcesButtonActions.DeleteDriver}
        </UiButtonFilled>
        <UiButtonFilled mode="light" onClick={handleShowResetPasswordModal}>
          {ResourcesButtonActions.ForgetPassword}
        </UiButtonFilled>
      </HStack>
    </Box>
  );
};
