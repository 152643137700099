import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'config/constants/query-keys';

export const useBookerOrdersCountInvalidate = () => {
  const client = useQueryClient();

  const invalidateBookerOrdersCount = () => {
    client.invalidateQueries([QueryKeys.bookerOrdersCount]);
  };

  return { invalidateBookerOrdersCount };
};
