import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BookerOrderSwitcherStatus } from '@/config/constants/statuses';
import { BookerOrderState } from '@/features/booker/types/booker-order-state';

const initialState: BookerOrderState = {
  // выбранный таб в списке заявок
  activeBookerOrderTab: BookerOrderSwitcherStatus.Preparation,
};

const slice = createSlice({
  name: 'bookersOrders',
  initialState,
  reducers: {
    // выбор заявки
    select: (state, action: PayloadAction<number>) => {
      state.activeBookerOrderId = action.payload;
    },
    unselect: (state) => {
      state.activeBookerOrderId = undefined;
    },
    // выбор таба в списке заявок
    selectTab: (state, action: PayloadAction<BookerOrderSwitcherStatus>) => {
      state.activeBookerOrderTab = action.payload;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const bookerOrdersReducers = slice.reducer;
export const bookerOrdersActions = slice.actions;
