import { CarriersApi } from '@/api/carriers';
import { useQueryWithUpdates } from '@/hooks/use-query-with-updates';
import { ResponseWithPager } from '@/types/models/pager';
import { Vehicle } from '@/types/models/vehicle';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useCarrierVehiclesUpdates = (id?: number) => {
  const carrierVehicles = useQueryWithUpdates<ResponseWithPager<Vehicle>>({
    queryKey: ['carrierVehicles', id],
    refetchOnWindowFocus: false,
    enabled: !!id,
    queryFn: id ? () => CarriersApi.getCarrierVehicles(id) : undefined,
  });
  return { carrierVehicles };
};
