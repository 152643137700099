import { FC } from 'react';
import { WITH_VAT, WITHOUT_VAT } from 'components/order-details/order-details-prepayment/texts';
import { UiToggle } from '@/ui-elements/ui-toggle/ui-toggle';

interface IProps {
  onVatChange: VoidFunction;
  isVatToggleDisabled?: boolean;
  vat?: boolean;
}

export const VatWidget: FC<IProps> = ({ onVatChange, isVatToggleDisabled = false, vat = false }) => {
  return (
    <UiToggle onChange={onVatChange} disabled={isVatToggleDisabled} defaultChecked={vat}>
      {vat ? WITH_VAT : WITHOUT_VAT}
    </UiToggle>
  );
};
