import { AccountRole } from '@/types/api/account-role';

export const GET_SMS_CODE_SUBMIT_CONTENT = 'Получить код по СМС';
export const CONFIRM_SMS_CODE_AND_GET_PASSWORD = 'Подтвердить код и получить пароль';
export const REGISTER = 'Зарегистрироваться';

export const SMS_CODE = 'СМС код';
export const ROLE = 'Роль';
export const SEND_BY_SMS = 'Выслали по СМС';

export const GET_SMS_CODE_TITLE = 'Укажите, пожалуйста, номер телефона он будет привязан к аккаунту и будет использоваться для связи и входа';
export const CONFIRM_SMS_CODE_TITLE =
  'Мы отправили смс-код на указанный номер, чтобы убедиться, что это именно ваш телефон. Введите смс-код, пожалуйста';
export const REGISTER_TITLE = 'Спасибо мы подтвердили ваш номер телефона и выслали на него ваш пароль, вы сможете изменить его позже';

export const ACCEPT_USER_AGREEMENT = `Я принимаю условия Пользовательского соглашения
и даю свое согласие на обработку моей персональной информации на условиях,
определенных Политикой конфиденциальности.`;

export const GET_SMS_CODE_FORM_CONTENT = {
  title: GET_SMS_CODE_TITLE,
  submitButton: GET_SMS_CODE_SUBMIT_CONTENT,
  hasExtraContent: true,
};

export const CHECK_CODE_FORM_CONTENT = {
  title: CONFIRM_SMS_CODE_TITLE,
  submitButton: CONFIRM_SMS_CODE_AND_GET_PASSWORD,
  hasExtraContent: false,
};

export const REGISTER_FORM_CONTENT = {
  title: REGISTER_TITLE,
  submitButton: REGISTER,
  hasExtraContent: true,
};

export enum FormType {
  getCode = 'getCode',
  checkCode = 'checkCode',
  register = 'register',
}

export const REGISTER_FORM_TYPE_CONTENT = {
  [FormType.getCode]: GET_SMS_CODE_FORM_CONTENT,
  [FormType.checkCode]: CHECK_CODE_FORM_CONTENT,
  [FormType.register]: REGISTER_FORM_CONTENT,
};

export const ROLE_OPTIONS = [
  {
    value: AccountRole.Carrier,
    label: 'Транспортная компания, в которой руководитель назначает на рейс своих водителей',
  },
  {
    value: AccountRole.CarrierSimple,
    label: 'Перевозчик-водитель (единоличный)',
  },
];
