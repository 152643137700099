import { Order } from '@/types/models/order';

export type ModalFormType = 'org' | 'driver' | 'vehicle';

export enum ModalType {
  Prepayment = 'Prepayment',
  Review = 'Review',
  Split = 'Split',
  Confirm = 'Confirm',
  Form = 'Form',
  EditCarrier = 'EditCarrier',
  Documents = 'Documents',
  Upload = 'Upload',
  CreateCarrier = 'CreateCarrier',
  Decline = 'Decline',
  DeleteOrder = 'DeleteOrder',
  EditDriver = 'EditDriver',
  Comments = 'Comments',
  EditVehicle = 'EditVehicle',
  DeleteDriver = 'DeleteDriver',
  DeleteVehicle = 'DeleteVehicle',
  DeleteCarrier = 'DeleteCarrier',
  DocumentTemplate = 'DocumentTemplate',
  OrganizationModal = 'OrganizationModal',
  CloneOrder = 'CloneOrder',
  CloseUpdateOrder = 'CloseUpdateOrder',
  CloseUpdateLoad = 'ModalCloseUpdateLoad',
  DeleteLoad = 'DeleteLoad',
  EditNotify = 'EditNotify',
  ResetPassword = 'ResetPassword',
  CreateCarrierOffer = 'createCarrierOffer',
  ChooseOwnDriverVehicle = 'chooseOwnDriverVehicle',
}

export type ModalProps = {
  Confirm: { text: string };
  Review: { carrierId: number };
  Split: { order: Order };
  Decline: { orderId: number };
};

export type ModalsProps<T> = T & {
  initialValues?: any;
  modalOpened: boolean;
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
  onCancel?: VoidFunction;
  onFormSuccess?: (values: any) => void;
  hasBlacklist?: boolean;
  onSubmit?: (values: any) => void;
};
