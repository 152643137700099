import { FC } from 'react';
import { SetCarrierWidgetContent } from 'components/set-carrier-widget-content/set-carrier-widget-content';
import { OrderExecutionStatus, OrderStatus } from 'config/constants/statuses';
import { DocumentTypes } from 'config/constants/document-types';
import { AttachmentDeps } from 'config/constants/types';
import { toast } from 'react-toastify';
import { WidgetType } from '@/features/widget/widget-type';
import { WidgetProps } from '@/features/widget/types/widget-item';
import './set-carrier-widget.scss';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';
import { useOffersActions } from '@/features/offers/hooks/use-offers-actions';
import { useOrderInvalidate } from '@/features/order/hooks/use-order-invalidate';
import { useOrderActions } from '@/features/order/hooks/use-order-actions';
import { useAttachmentsActions } from '@/features/attachments/hooks/use-attachments-actions';
import { useOrderStatus } from '@/features/order/hooks/use-order-status';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { orderExtender } from '@/features/order/extender/order-extender';
import { useOrderUpdates } from '@/features/order/hooks/use-order-updates';

export const SetCarrierWidgetView: FC<WidgetProps & Omit<WidgetType['SetCarrierWidget'], 'title'>> = ({
  orderOffer,
  offerId,
  orderId,
  hideWidget,
}) => {
  const { driver, carrier, vehicle } = orderOffer;
  const { addOffer, acceptOffer } = useOffersActions();
  const { postAttachments } = useAttachmentsActions();

  const { searchForCarrier } = useOrderActions();
  const {
    order: { data: orderResp },
  } = useOrderUpdates({ id: orderId });
  const order = orderResp?.data;
  const { setNewOrderStatus } = useOrderStatus(order);
  const { getPriceForCarrier } = useExtendedObject(order!, orderExtender);
  const { offersInvalidate, shipmentRequestsInvalidate, orderInvalidate, allOrdersInvalidate } = useOrderInvalidate();
  const postFiles = (files: Array<File>) => {
    if (files.length) {
      postAttachments(
        files,
        {
          dependent: AttachmentDeps.Order,
          subject: DocumentTypes.dlDocumentOrder,
          dependentID: orderId,
        },
        {
          onSuccess: hideWidget,
        }
      );
    } else {
      hideWidget();
    }
  };
  const onAcceptOffer = (files: Array<File>, id?: number) => {
    const offerIdToAccept = id || offerId;
    if (offerIdToAccept) {
      acceptOffer.mutate(offerIdToAccept, {
        onSuccess: () => {
          setNewOrderStatus(OrderExecutionStatus.Preparation, () => {
            searchForCarrier(undefined);
            postFiles(files);
          });
        },
      });
    }
  };

  const setCarrier = (files: Array<File>) => {
    if (order?.status !== OrderExecutionStatus.CarrierSearch) return;
    // если оффер есть - то про его прнимаем
    if (offerId) {
      onAcceptOffer(files);
    }
    // если оффера нет  - то  вначале его создаем -и он автоматически принимается на бэке
    else {
      const offerPrice = getPriceForCarrier?.(carrier) || 0;
      if (offerPrice > 0) {
        addOffer.mutate(
          {
            carrierId: carrier.id,
            driverId: driver.id,
            vehicleId: vehicle.id,
            price: offerPrice,
            orderId,
          },
          {
            onSuccess: () => {
              offersInvalidate(orderId);
              shipmentRequestsInvalidate(orderId);
              allOrdersInvalidate();
              orderInvalidate(orderId);
              searchForCarrier(undefined);
              postFiles(files);
            },
          }
        );
      } else toast.error('Не удалось определить цену предложения', { toastId: 'fail_offer_price' });
    }
  };
  if (!order) return <UiSpinner visible />;
  return (
    <div className="set-carrier-widget">
      {carrier && (
        <SetCarrierWidgetContent order={order} carrier={carrier} driver={driver} vehicle={vehicle} onSet={setCarrier} onCancel={hideWidget} />
      )}
    </div>
  );
};
