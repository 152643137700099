import axios, { AxiosRequestConfig } from 'axios';
import { TOKEN_KEY } from 'config/constants/storage';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { storage } from '@/helpers/storage';
import { handleApiError } from './handle-api-error';

let store: ToolkitStore;

export const injectStore = (_store: ToolkitStore) => {
  store = _store;
};

export const apiInstance = (useBearer: boolean = true) => {
  const token = storage.getValue(TOKEN_KEY);
  const AxiosConfig: AxiosRequestConfig = {
    baseURL: import.meta.env.VITE_REACT_APP_API_HOST,
    withCredentials: true,
  };

  if (token && useBearer) {
    AxiosConfig.headers = { ...AxiosConfig.headers, Authorization: `Bearer ${token}` };
  }

  const axiosInstance = axios.create(AxiosConfig);

  if (token) {
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (axios.isAxiosError(error)) handleApiError(error, store);
        return Promise.reject(error);
      }
    );
  }

  return axiosInstance;
};
