import './verification-item-status-tag.scss';
import classNames from 'classnames';
import { UiTag } from '@/ui-elements/ui-tag/ui-tag';
import { VerificationItemApproveStatus } from '@/config/constants/statuses';
import { VerificationItemApproveStatusNames } from '@/config/constants/texts';

/* Компонент, который отображает статус проверки отдельной сущности - либо carrierOrganization, либо Driver, либо Vehicle */
export const VerificationItemStatusTag = ({ status }: { status?: VerificationItemApproveStatus | null }) => {
  if (!status) return null;

  return (
    <UiTag className={classNames('verification-item-status-tag', { [status]: status })} variant="solid">
      {VerificationItemApproveStatusNames[status]}
    </UiTag>
  );
};
