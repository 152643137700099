import { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { CarrierReview } from 'components/carrier-review/type';
import { LABEL_TEXT, PLACEHOLDER_TEXT } from 'components/carrier-review/constants';
import { UiButtonOutlined, UiTextareaField } from '@/ui-elements';
import { useForm } from '@/helpers/forms/use-form';
import { useIcons } from '@/hooks/use-icons';
import { emptyFunction } from '@/helpers/empty-function';

type Props = {
  onSubmit?: (review: CarrierReview) => void;
};

export const CarrierReviewsForm: FC<Props> = ({ onSubmit = emptyFunction }) => {
  const Form = useForm<CarrierReview>();
  const { Send } = useIcons();
  return (
    <Box>
      <Form
        initialValues={{
          review: '',
        }}
        onSubmit={onSubmit}
      >
        <UiTextareaField name="review" label={LABEL_TEXT} placeholder={PLACEHOLDER_TEXT} className="carrier-reviews__text-area" />
      </Form>
      <Flex justifyContent="end">
        <UiButtonOutlined mode="dark" className="carrier-reviews__button-send">
          <Send />
          Отправить
        </UiButtonOutlined>
      </Flex>
    </Box>
  );
};
