import { MouseEventHandler } from 'react';
import { UiDivider } from '@/ui-elements';
import { CarrierOrganizationCard } from './carrier-organization-card/carrier-organization-card';
import { CarrierInfoCard } from './carrier-info-card/carrier-info-card';
// import { CarrierTripsCard } from './carrier-trips-card/carrier-trips';
import { BestDriverCard } from './best-driver-card/best-driver-card';
import { Carrier } from '@/types/models/carrier';
import { Driver } from '@/types/models/driver';

type Props = {
  carrier: Carrier;
  approveStatusCaption: string;
  showCarriersReviews: (carrierId: number) => void;
  onEditCarrierClick: VoidFunction;
  driver: Driver;
  handleDeleteCarrier?: MouseEventHandler<HTMLButtonElement>;
  onDriverMoreInfoClick?: (driverId: number) => void;
};

export const CarrierGeneralInfo = ({
  carrier,
  approveStatusCaption,
  onDriverMoreInfoClick,
  showCarriersReviews,
  driver,
  onEditCarrierClick,
  handleDeleteCarrier,
}: Props) => (
  <>
    <CarrierOrganizationCard
      carrier={carrier}
      status={approveStatusCaption}
      showCarriersReviews={showCarriersReviews}
      onEditClick={onEditCarrierClick}
      handleDeleteCarrier={handleDeleteCarrier}
    />
    <UiDivider pt="25px" variant="dark" />
    <CarrierInfoCard phone={carrier?.organizations?.[0]?.phone} vehicleCount={carrier.countVehicles} driversCount={carrier.countDrivers} />
    <UiDivider pt="25px" variant="dark" />
    {/* //TODO временно скрыто - нет бека для этого блока */}
    {/* <CarrierTripsCard /> */}
    {/* <UiDivider pt="25px" variant="dark" /> */}
    <BestDriverCard onDriverMoreInfoClick={onDriverMoreInfoClick} driver={driver} />
  </>
);
