import { FC } from 'react';
import { DocumentTemplateNames } from '@/config/constants/document-types';

export enum TemplateContractFormFields {
  Sender = 'Sender',
  Receiver = 'Receiver',
  SourceContactName = 'SourceContactName',
  SourceContactNumber = 'SourceContactNumber',
  TargetContactName = 'TargetContactName',
  TargetContactNumber = 'TargetContactNumber',
}

export type TemplateContractFormValues = {
  routeDetails: Array<Record<TemplateContractFormFields, string>>;
};

export type TemplateContractFormUiElements = Record<
  TemplateContractFormFields,
  {
    Component: FC<any>;
    defaultProps: {
      type?: string;
      required: boolean;
      options?: [];
      mask?: any;
      name: TemplateContractFormFields;
      label?: string;
      content?: string;
    };
  }
>;

export const TEMPLATES_WITH_FORM = [DocumentTemplateNames.dlContractCustomer2, DocumentTemplateNames.dlContractCarrier2];
