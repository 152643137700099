import { FC } from 'react';
import { RegisterForm } from 'components/register/regster-form/register-form';
import { Navigate } from 'react-router-dom';
import { useRegister } from '@/features/account/hooks/use-register';

export const RegisterFormView: FC = () => {
  const { formType, onSubmit, registerAvailable, forceRedirect, registerNotAvailableContent, forceRedirectPath, registerHeadingContent } =
    useRegister();

  if (!registerAvailable) {
    return forceRedirect ? <Navigate to={forceRedirectPath} /> : <div className="register-page">{registerNotAvailableContent}</div>;
  }

  return (
    <div className="register-page">
      <h1 className="register-page-title">{registerHeadingContent}</h1>
      <RegisterForm onSubmit={onSubmit} registerFormType={formType} />
    </div>
  );
};
