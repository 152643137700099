import { useLocation } from 'react-router-dom';
import qs from 'qs';

// returns id from query search
export const useGetQueryId = () => {
  const location = useLocation();
  const { id } = qs.parse(location?.search, { ignoreQueryPrefix: true });

  return id;
};
