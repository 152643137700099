import { useMutation } from '@tanstack/react-query';
import { QueryKeys } from 'config/constants/query-keys';
import { CustomersApi } from '@/api/customers';
import { CustomerEdit } from '@/types/api/customer-partial';

export const useCustomerUpdateActions = (id?: number) => {
  const updateCustomerWithoutApprovals = useMutation({
    mutationKey: [QueryKeys.updateCustomer],
    mutationFn: (options: CustomerEdit) => CustomersApi.updateCustomer(id!, false, options),
  });

  const updateCustomerWithApprovals = useMutation({
    mutationKey: [QueryKeys.updateCustomer],
    mutationFn: (options: CustomerEdit) => CustomersApi.updateCustomer(id!, true, options),
  });

  return { updateCustomerFalse: updateCustomerWithoutApprovals, updateCustomerTrue: updateCustomerWithApprovals };
};
