import DatePicker from 'react-multi-date-picker';
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, InputProps, Text } from '@chakra-ui/react';
import { useField } from 'react-final-form';
import { UiMultiDatePicker } from '@/ui-elements';
import gregorian_ru from './locale';
import './ui-multi-datepicker.scss';

type Props = {
  name: string;
  label?: string;
  content?: string;
  required?: boolean;
  isMulti?: boolean;
  datePickerRef?: React.MutableRefObject<null>;
  plugins?: Array<any>;
  format?: string;
  placeholder?: string;
  onOpenPickNewDate?: boolean;
  calendarPosition?: string;
  fixMainPosition?: boolean;
  fixRelativePosition?: boolean;
  portal?: boolean;
};

export const UiMultiDatePickerField = ({ name, label, required, content, isMulti, datePickerRef, plugins, format, ...props }: Props & InputProps) => {
  const { isDisabled, onOpenPickNewDate, calendarPosition, fixMainPosition, fixRelativePosition, portal } = props;
  const {
    input,
    meta: { error, touched },
  } = useField(name);
  const invalid = error && touched;

  return (
    <FormControl isRequired={required && !isDisabled} isInvalid={invalid}>
      <FormLabel mb={1}>
        <Text noOfLines={1}>{label}</Text>
      </FormLabel>
      <DatePicker
        range={isMulti}
        plugins={plugins}
        format={format}
        render={<UiMultiDatePicker isInvalid={invalid} {...props} />}
        {...input}
        locale={gregorian_ru}
        containerClassName={`ui-datepicker-input ${isDisabled ? 'datepicker-input__disabled' : ''}`}
        disabled={isDisabled}
        ref={datePickerRef}
        weekStartDayIndex={1}
        onOpenPickNewDate={onOpenPickNewDate}
        calendarPosition={calendarPosition}
        fixMainPosition={fixMainPosition}
        fixRelativePosition={fixRelativePosition}
        portal={portal}
      />
      {invalid ? <FormErrorMessage>{error}</FormErrorMessage> : <FormHelperText>{content}</FormHelperText>}
    </FormControl>
  );
};
