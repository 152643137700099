import { isEmpty } from 'lodash';
import { VehicleGroup, VehicleType } from '@/ui-elements/inputs/ui-vehicle-types-picker/ui-vehicle-types-picker';

export const modifyVehicleSelectOptions = (vehicles: Array<VehicleGroup & VehicleType> = []) => {
  if (isEmpty(vehicles)) return [];

  return vehicles.map((vehicle) => {
    if (vehicle?.isGroup) {
      const vehicleGroupOptions = vehicle?.types.map((type) => ({ label: type.name, value: type.id }));
      return { label: vehicle.name, value: vehicle.id, options: vehicleGroupOptions };
    }
    return { label: vehicle.name, value: vehicle.id };
  });
};
