import { FC } from 'react';
import classNames from 'classnames';
import { UiChip, UiChipProps } from '@/ui-elements/chip/chip';
import { PaymentChipType } from '@/ui-elements/chip/types';
import './payment-chip.scss';

type Props = UiChipProps & {
  type: PaymentChipType;
  amount?: string;
  label?: string;
};

export const PaymentChip: FC<Props> = ({ amount, type, label, ...props }) => {
  const text = {
    price: `Цена ${amount} ₽`,
    priceWithVAT: `Цена ${amount} ₽ с НДС`,
    priceWithoutVAT: `Цена ${amount} ₽ без НДС`,
    advance: `Аванс ${amount} ₽`,
    'advance-paid': 'Аванс выплачен',
  };

  return (
    <UiChip
      className={classNames('ui-chip-money', {
        'ui-chip-money_price': type === 'price' || type === 'priceWithVAT' || type === 'priceWithoutVAT',
        'ui-chip-money_advance': type === 'advance',
        'ui-chip-money_advance-paid': type === 'advance-paid',
      })}
      {...props}
    >
      {label || text[type]}
    </UiChip>
  );
};
