import { useNavigate } from 'react-router-dom';
import { getPathForNavigate } from '@/helpers/routes/get-path-for-navigate';

export const useNavigateTo = (replace: boolean = true) => {
  const navigate = useNavigate();

  const navigateTo = (path: string) => () => navigate(getPathForNavigate(path), { replace });

  return { navigateTo };
};
