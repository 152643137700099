import { OrderLoadType } from '@/config/constants/types';
import { FormValues } from './schema';
import { arrivalTimeTypeOptions, emptyCargoFields, TimeType } from './subforms/form-configs';

export const initialResponsiblePerson = { value: 0, label: '' };
export const initialValues: FormValues = {
  state: {
    cargo: [[[1, 2]]],
    // cargo: [[[1, 2]], [[1, 2]]],
  },
  cargo: [],
  orderInfo: {
    customerCompany: undefined,
    responsiblePerson: undefined,
  },
  route: {
    routeType: 'simple',
    points: [
      {
        showLoadTime: true,
        cargos: [{ ...emptyCargoFields, id: 'cargo_0' }],
        arrivalTimeOption: arrivalTimeTypeOptions[TimeType.simple],
        type: OrderLoadType.Loading,
        id: 'point_0',
      },
    ],
  },
  vehicleType: {
    loadType: 'ftl',
  },
  carrierPaymentTerms: {
    hasVat: true,
    withoutVat: true,
  },
};
