import { Box, Heading } from '@chakra-ui/react';
import { UiCheckboxField } from '@/ui-elements';
import { ResourcesFormFieldNames, ResourcesFormLabels } from '@/features/resources/constants/form';

export const ResourcesSubformTracking = () => {
  return (
    <Box>
      <Heading as="h3" className="resources-form-subtitle">
        Трекинг
      </Heading>
      <Box className="resources-form-column">
        <UiCheckboxField name={ResourcesFormFieldNames.tracker} label={ResourcesFormLabels.tracker} />
        <UiCheckboxField name={ResourcesFormFieldNames.permanentTracking} label={ResourcesFormLabels.permanentTracking} />
      </Box>
    </Box>
  );
};
