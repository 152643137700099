export const getPaymentStep = () => {
  return (max: number) => {
    let coef;
    if (max < 5000) {
      coef = max < 500 ? 10 : 100;
    } else {
      coef = 1000;
    }
    if (max < 50) coef = 1;

    return Math.round(max / (10 * coef)) * coef;
  };
};
