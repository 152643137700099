import { Namespaces, OrderCreateFormValues } from 'components/create-order-form/schema';
import { Box, Text, Center } from '@chakra-ui/react';
import { useFieldArray } from 'react-final-form-arrays';
import { RadioButtonsConfig } from 'config/constants/radio-buttons';
import { emptyFunction } from '@/helpers/empty-function';
import { UiRadioGroupField, UiButtonFilled, UiDeleteButton, UiDivider } from '@/ui-elements';
import { WaypointInfo } from './waypoint-info';

type Props = {
  namespace: Namespaces | string;
  updateValues?: Partial<OrderCreateFormValues>;
};
export const routeInitialValues = {
  routeDescription: 'Только туда',
  waypoint: [
    {
      streetAddress: 'Некрасова',
      houseAddress: '19',
      date: '',
      time: '',
      loading: 'Погрузка',
      addOns: '',
      comments: '',
      file: '',
      cargo: [{ weight: '' }],
    },
  ],
};

const emptyWaypointFields = {
  streetAddress: '',
  houseAddress: '',
  date: '',
  time: '',
  loading: '',
  addOns: '',
  comments: '',
  file: '',
};

export const RouteSubform = ({ namespace, updateValues }: Props) => {
  const { fields } = useFieldArray(`${namespace}.points`);
  return (
    <Box w="full">
      <Text textStyle="h3" align="left" mb={6}>
        Маршрут
      </Text>
      <UiRadioGroupField config={RadioButtonsConfig.type} name={`${namespace}.routeType`} />
      {fields.map((name, index) => (
        <Box key={name}>
          <UiDeleteButton onClick={index > 0 ? () => fields.remove(index) : emptyFunction} isDisabled={index === 0}>
            {`Точка ${index + 1}`}
          </UiDeleteButton>
          <WaypointInfo name={name} updateValues={updateValues} />
        </Box>
      ))}
      <Center mt={6}>
        <UiButtonFilled onClick={() => fields.push(emptyWaypointFields)}>Добавить Точку</UiButtonFilled>
      </Center>
      <UiDivider variant="normal" my={6} />
    </Box>
  );
};
