import { FC } from 'react';
import { HStack } from '@chakra-ui/react';
import { UiWarningNumber } from '@/ui-elements/ui-warning-number/ui-warning-number';

/**
 * ячейка таблицы со группой риска водителя
 * */
export const SecurityTableRiskCell: FC<{ number?: number }> = ({ number }) => {
  if (!number) return null;
  return (
    <HStack justifyContent="center" alignItems="center" width="100%">
      <UiWarningNumber number={number} />
    </HStack>
  );
};
