import { DriversApi } from '@/api/drivers';
import { AllDriversFilter } from '@/types/api/driver-partial';
import { QueryKeys } from '@/config/constants/query-keys';
import { useInfiniteQueryWithUpdates } from '@/hooks/use-infinite-query-with-updates';

export const useAllDriversWithPages = ({ perPage = 10, carrierId }: AllDriversFilter) => {
  const queryFn =
    () =>
    ({ pageParam = 1 }) =>
      DriversApi.getAllDrivers({ perPage, page: pageParam, updates: false, carrierId });

  return useInfiniteQueryWithUpdates({ queryKey: QueryKeys.allDrivers, refetchOnWindowFocus: false, queryFn });
};
