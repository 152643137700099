import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    width: 5,
    height: 5,
    borderWidth: '1px',
    borderRadius: '10px',
    borderColor: 'products.200',
    _checked: {
      background: 'products.200',
      borderColor: 'products.200',
      _hover: {
        background: 'products.150',
        borderColor: 'products.150',
      },
      _disabled: {
        background: 'products.450',
        borderColor: 'products.450',
        color: 'neutrals.350',
      },
    },
    _disabled: {
      borderWidth: '1.4px',
      background: 'neutrals.350',
      borderColor: 'products.450',
      color: 'neutrals.450',
    },
    _hover: {
      borderWidth: '2px',
      background: 'neutrals.350',
      borderColor: 'products.150',
    },
    _invalid: {
      borderWidth: '1.4px',
      background: 'neutrals.350',
      borderColor: 'accentRed.100',
      _hover: {
        borderWidth: '1.4px',
        background: 'neutrals.350',
        borderColor: 'accentRed.100',
      },
    },
  },
  label: {
    ml: 3,
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    color: 'typography.50',
    _disabled: {
      color: 'typography.200',
    },
    _invalid: {
      color: 'accentRed.100',
    },
  },
});

export const radioTheme = defineMultiStyleConfig({ baseStyle });
