import { useAppSelector } from '@/hooks/use-app-selector';
import { ResourcesSidebarMode, ResourcesStatusTab } from '@/features/resources/types/general';
import { useResourcesDispatchActions, useIntermediaryResources } from '@/features/resources/hooks';
import { resourcesActiveStatusTabSelector, resourcesSelectedDriverIdSelector } from '@/features/resources/selectors';
import { useAllDriversWithPages } from '@/features/drivers/hooks/use-all-drivers-with-pages';
import { flatMapDataPages } from '@/helpers/api/flat-map-data-pages';

export const useResourcesDriversView = () => {
  const { handleSetSidebarMode, handleSelectDriver, handleSetStatusTab } = useResourcesDispatchActions();
  const activeTab = useAppSelector(resourcesActiveStatusTabSelector);
  const selectedDriverId = useAppSelector(resourcesSelectedDriverIdSelector);

  const { isIntermediaryRole, carrierId } = useIntermediaryResources();
  const { data, fetchNextPage, isFetchingNextPage, isInitialLoading } = useAllDriversWithPages({
    perPage: 10,
    carrierId,
  });
  const drivers = flatMapDataPages(data);
  const loading = isInitialLoading;

  /* На текущий момент убираем функционал фильтра по статусам, возможно в будущем добавим */
  const handleChangeActiveTab = (activeKey: string) => {
    handleSetStatusTab(activeKey as ResourcesStatusTab);
  };

  const selectDriver = (driverId: number) => {
    handleSelectDriver(driverId);
    handleSetSidebarMode(ResourcesSidebarMode.Details);
  };

  const handleCreateDriver = () => {
    handleSetSidebarMode(ResourcesSidebarMode.CreateForm);
  };

  return {
    drivers,
    loading,
    activeTab,
    selectedDriverId,
    isFetchingNextPage,
    showApprovalStatuses: !isIntermediaryRole,
    fetchNextPage,
    handleChangeActiveTab,
    handleSelectDriver: selectDriver,
    handleCreateDriver,
  };
};
