import './my-organizations.scss';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import { MyOrganizationsInfo } from 'components/my-organizations/my-organizations-info/my-organizations-info';
import { useAppSelector } from '@/hooks/use-app-selector';
import { activeMyOrganizationsIdSelector } from '@/features/my-organizations/selectors/active-my-organizations-id-selector';
import { useOrganization } from '@/features/my-organizations/hooks/use-my-organization';
import { useOrganizationActions } from '@/features/my-organizations/hooks/use-organization-actions';
import { ModalAction, useModal } from '@/hooks/use-modal';
import { ModalType } from '@/types/common/modals';
import { MyOrganizationBanking } from '@/types/models/my-organization/my-organization';
import { useAccount } from '@/features/account/hooks/use-account';
import { UserRole } from '@/types/api/user-role';
import { normalizeData } from '@/views/my-organizations/helpers/get-normalize-data';
import { useOrganizationBankingUpdates } from '@/features/my-organizations/hooks/use-my-organization-banking-updates';

export const MyOrganizationsDetailsView = () => {
  const { currentRole } = useAccount();
  const hasEditRight = currentRole && [UserRole.Administrator].includes(currentRole);
  const id = useAppSelector(activeMyOrganizationsIdSelector);
  const { addOrganizationBanking } = useOrganizationActions(id);

  const { data: organizationInfo } = useOrganization(id);
  const { data: organizationBanking } = useOrganizationBankingUpdates(id);

  /** если заполняются первые реквизиты, то кнопка "Использовать по умолчанию" активная и заблокирована */
  const isFirstDefaultBanking = isEmpty(organizationBanking?.data!);

  const { modalView: addBanking, setModalOpened } = useModal<
    ModalAction & { mode: string; title: string; isFirstDefaultBanking?: boolean; initialValues: { bank: { default: boolean } } }
  >(ModalType.OrganizationModal, {
    onSubmit: (values: { bank: MyOrganizationBanking }) => {
      addOrganizationBanking.mutate(
        { organizationId: id!, options: values.bank },
        {
          onSuccess: () => {
            toast.success('Банковские реквизиты успешно добавлены');
            setModalOpened(false);
          },
        }
      );
    },
    mode: 'banking',
    title: 'Добавление реквизитов банка',
    initialValues: {
      bank: {
        default: isFirstDefaultBanking,
      },
    },
    isFirstDefaultBanking,
  });

  if (!id) return null;
  return (
    <div className="my-organizations-details-view__sidebar">
      <MyOrganizationsInfo
        organizationData={normalizeData(organizationInfo?.data)}
        bankingData={organizationBanking?.data!}
        onBankingAdd={() => setModalOpened(true)}
        hasEditRight={hasEditRight}
      />
      {addBanking}
    </div>
  );
};
