import { isArray } from 'lodash';
import { useAppSelector } from '@/hooks/use-app-selector';
import {
  accountRoleSelector,
  accountSelector,
  accountUserRoleSelector,
  authenticatedSelector,
  authenticationPendingSelector,
} from '@/features/account/selectors';
import { UserRole } from '@/types/api/user-role';
import { AccountRole } from '@/types/api/account-role';
import { accountPhoneSelector } from '../selectors/account-role-selector';

export const useAccount = () => {
  const currentRole = useAppSelector(accountUserRoleSelector);
  const accountRole = useAppSelector(accountRoleSelector);
  const accountPhone = useAppSelector(accountPhoneSelector);
  const currentAccount = useAppSelector(accountSelector);
  const authenticationPending = useAppSelector(authenticationPendingSelector);
  const authenticated = useAppSelector(authenticatedSelector);
  const isBooker = currentRole === UserRole.Booker || currentRole === UserRole.Administrator;
  const isDriver = currentRole === UserRole.Driver;
  const isCarrierSimple = isArray(accountRole) ? accountRole.includes(AccountRole.CarrierSimple) : accountRole === AccountRole.CarrierSimple;

  return {
    currentRole,
    accountRole,
    currentAccount,
    authenticated,
    authenticationPending,
    isCarrierSimple,
    isDriver,
    // true -если роль пользователя Бухгалтер
    isBooker,
    accountPhone,
  };
};
