import { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { FORM_UI_ELEMENTS, ORGANIZATION_BANK_DETAILS_FIELDS } from './constants';
import { FormSectionTitle } from './my-organization-form-header';

interface IProps {
  disabled: boolean;
}

export const MyOrganizationBankDetails: FC<IProps> = ({ disabled }) => {
  return (
    <Box>
      <FormSectionTitle title="Банковские реквизиты" />
      <Flex className="my-organization-flex" direction="row" align="center" wrap="wrap">
        {ORGANIZATION_BANK_DETAILS_FIELDS.map((field) => {
          const { Component, defaultProps } = FORM_UI_ELEMENTS[field];
          return <Component isDisabled={disabled} key={field} {...defaultProps} disabled={disabled} />;
        })}
      </Flex>
    </Box>
  );
};
