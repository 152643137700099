import { ButtonDirection, FunnelSwitcherStatus } from '@/types/models/funnel';
import { EMPTY_STRING } from './common-strings';
import {
  LoadsFilterValues,
  LoadsFormSortValues,
  LoadsFormValues,
  LoadsSortButtonValues,
  LoadsSortField,
  LoadsSortTitles,
  LoadsSortValues,
  TariffTypes,
} from '@/types/models/loads';

/* Кнопки сортировки в "Поиск грузов" */
export const LoadsSortingOptions: Array<LoadsSortButtonValues> = [
  {
    title: LoadsSortTitles.Default,
    value: LoadsSortValues.Default,
    id: 0,
  },
  {
    title: LoadsSortTitles.StartPrice,
    value: LoadsSortValues.StartPrice,
    id: 1,
  },
  {
    title: LoadsSortTitles.PricePerKm,
    value: LoadsSortValues.PricePerKm,
    id: 2,
  },
  {
    title: LoadsSortTitles.LoadingStart,
    value: LoadsSortValues.LoadingStart,
    id: 3,
  },
  {
    title: LoadsSortTitles.UnloadingStart,
    value: LoadsSortValues.UnloadingStart,
    id: 4,
  },
];

/* Начальное значение сортировки */
export const initialSortingValues: LoadsFormSortValues = { value: LoadsSortValues.Default, sortingDirection: null };

/* Начальное значение фильтров */
export const initialFiltersValues: LoadsFilterValues = {
  from: EMPTY_STRING,
  to: EMPTY_STRING,
  dateFrom: EMPTY_STRING,
  dateTo: EMPTY_STRING,
  vehicle: [],
  tariffType: TariffTypes.Any,
  singleLoading: false,
};

/* Начальное значение состояния фильтры + сортировка */
export const initialLoadsFormValues: LoadsFormValues = {
  ...initialFiltersValues,
  [LoadsSortField.Sorting]: initialSortingValues,
};

/* Текст-подсказка для фильтров */
export const LoadsFiltersHelpText = {
  From: 'Например, Москва',
  To: 'Например, Самарская область',
};

export const TAKEN_FOR_CALCULATION = 'взято на расчет';
export const RESET_BUTTON_TEXT = 'Сбросить';
export const SAVE_BUTTON_TEXT = 'Сохранить';
export const SORT_LOADS = 'Сортировать грузы';
export const UPDATE_LOADS = 'Обновить';
export const CLOSE_LOAD_DETAILS = 'Закрыть';
export const NAVIGATE_TO_PLATFORM = 'Перейти на площадку';
export const TAKE_FOR_CALCULATION = 'Взять на расчет';
export const CANCEL_CALCULATION = 'Отменить расчет';
export const SELECT_LOAD = 'Выберите груз';
export const USER_HAS_NO_INTEGRATION = 'У вас нет настроенной интеграции';
export const TAKE_LOADS_FOR_CALCULATION = 'Необходимо взять на расчет заявки в модуле "Поиск грузов" или создать заявку';
export const RETURN_TO_NEW = 'Вернуть в новые';
export const MOVE_TO_CALCULATION = 'На расчет';
export const LOAD_CALCULATED = 'Запрос рассчитан';
export const DOCUMENTS_TITLE = 'Документы по заявке';
export const RETURN_TO_CALCULATION = 'Вернуть в расчет';
export const SENT_TO_CLIENT = 'Отправлено клиенту';
export const RETURN_BACK = 'Вернуть назад';
export const AUTO_CALCULATION = 'Авторасчет';
export const MOVE_TO_CONFIRMED = 'Согласовано';
export const MOVE_TO_CANCELED = 'Отклонено';

export const FunnelButtonsBackForwardText = {
  [FunnelSwitcherStatus.NewCalculation]: {
    [ButtonDirection.Back]: undefined,
    [ButtonDirection.Forward]: MOVE_TO_CALCULATION,
  },
  [FunnelSwitcherStatus.CostCalculation]: {
    [ButtonDirection.Back]: RETURN_TO_NEW,
    [ButtonDirection.Forward]: LOAD_CALCULATED,
  },
  [FunnelSwitcherStatus.CostCalculated]: {
    [ButtonDirection.Back]: RETURN_TO_CALCULATION,
    [ButtonDirection.Forward]: SENT_TO_CLIENT,
  },
  [FunnelSwitcherStatus.SentCostToClient]: {
    [ButtonDirection.Back]: RETURN_BACK,
    [ButtonDirection.Forward]: [MOVE_TO_CONFIRMED, MOVE_TO_CANCELED],
  },
  [FunnelSwitcherStatus.CostConfirmed]: {
    [ButtonDirection.Back]: RETURN_BACK,
    [ButtonDirection.Forward]: undefined,
  },
  [FunnelSwitcherStatus.CostCanceled]: {
    [ButtonDirection.Back]: RETURN_BACK,
    [ButtonDirection.Forward]: undefined,
  },
};

export const VatOptionsTexts = {
  withVat: 'с НДС',
  withoutVat: 'без НДС',
};

export const RUB_CURRENCY = 'руб';

export const PRICE_FROM_CLIENT = 'Цена от клиента';
export const PRICE_FOR_CLIENT = 'Цена для клиента';
export const PRICE_FOR_CARRIER = 'Цена для исполнителя';
