import { VatTypeMap } from 'config/constants/vat-options';

export const TRANSPORTATION_SERVICE = 'Услуга на перевозку';
export const BY_AGREEMENT = 'По договору';

export const ADDITIONAL_SERVICES = 'Дополнительные услуги';
export const ADD_SERVICE = 'Добавить услугу';

export const EXECUTOR = 'Исполнитель';

export const BY_CARRIER_AGREEMENT = 'По договору-заявке';

export const CUSTOMER_PRICE = 'Цена клиента';
export const CARRIER_PRICE = 'Цена исполнителя';

export const WITH_VAT = 'с учетом НДС';
export const WITHOUT_VAT = VatTypeMap.withoutVat;
