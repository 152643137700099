import { useAppSelector } from '@/hooks/use-app-selector';
import { carrierIdForReviewsSelector } from '@/features/order/selectors';

/*
 * carrierIdForComments - id перевозчика для которого открывается view с комментариями
 * */
export const useReviewsSidebar = () => {
  /**
   *  id перевозчика для которого открывается view с комментариями
   * */
  const carrierIdForReviews = useAppSelector(carrierIdForReviewsSelector);
  return { carrierIdForReviews };
};
