import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

// Для обновления формы автоматически на каждое действие без нажатия на кнопку submit
// Пример использования
// <Form onSubmit={emptyFunction}>
// <FormSpy subscription={{ values: true }}>
//   {({ values }) => <FormAutosave values={values} onSave={onChangeHandler} />}
// </FormSpy>
// </Form>

interface Props<T> {
  onSave: (values: T) => void;
  values: T;
}

export const FormAutosave = <T extends {}>(props: Props<T>) => {
  const { onSave, values } = props;
  const [saveValues, setSaveValues] = useState(values);

  useEffect(() => {
    const isEqualValues = isEqual(saveValues, values);
    if (!isEqualValues) {
      setSaveValues(values);
      onSave(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return null;
};
