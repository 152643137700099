import { FC, ReactElement, RefObject, useRef } from 'react';
import './widget-window.scss';
import { HStack, Portal } from '@chakra-ui/react';
import useDragWidget from 'components/widget-window/hooks/use-drag-widget';
import { WIDGET_Y_MIN_POSITION } from 'components/widget-window/constants';
import classNames from 'classnames';
import { WidgetType } from '@/features/widget/widget-type';
import { useIcons } from '@/hooks/use-icons';
import { UiIconButton } from '@/ui-elements';
import { emptyFunction } from '@/helpers/empty-function';

export const WidgetWindow: FC<{
  isActive?: boolean;
  widgetKey: string;
  children: ReactElement;
  hideWidget?: (widget: keyof WidgetType) => void;
  setActive?: (widget: keyof WidgetType) => void;
  parentRef: RefObject<HTMLDivElement | undefined>;
  title?: string;
}> = ({ title = ' ', widgetKey, children, hideWidget = emptyFunction, setActive = emptyFunction, parentRef, isActive }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { pointerDrag } = useDragWidget({
    ref,
    parentRef,
    topMinPosition: WIDGET_Y_MIN_POSITION,
  });
  const { CloseIcon } = useIcons();

  const onClose = () => {
    hideWidget(widgetKey);
  };
  const onActivate = () => {
    setActive(widgetKey);
  };
  return (
    <Portal>
      <div ref={ref} className={classNames('widget-window', { 'widget-window_active': isActive })} onPointerDown={onActivate}>
        <HStack className="widget-window__header">
          <div onPointerDown={pointerDrag} className="widget-window__title">
            <p>{title}</p>
          </div>
          <UiIconButton aria-label="close" icon={CloseIcon} onClick={onClose} onPointerDown={(e) => e.stopPropagation()} />
        </HStack>
        <div>{children}</div>
      </div>
    </Portal>
  );
};
