import { FC } from 'react';
import { isArray } from 'lodash';
import { HStack, VStack } from '@chakra-ui/layout';
import classNames from 'classnames';
import { BackForwardButtonsDisabledType, ButtonDirection, FunnelSwitcherStatus } from '@/types/models/funnel';
import { FunnelBackForwardButton } from '@/components/funnel/back-forward-button/funnel-back-forward-button';
import { LoadNavigateToPlatformButton } from '@/components/loads/navigate-to-platform-button/load-navigate-to-platform-button';
import { FunnelButtonsBackForwardText } from '@/config/constants/loads';

interface Props {
  loadUrlAtPlatform?: string;
  activeTab: FunnelSwitcherStatus;
  buttonsDisabled: BackForwardButtonsDisabledType;
  handleClickBack?: VoidFunction;
  handleClickForward?: VoidFunction | VoidFunction[];
}

export const FunnelDetailsButtons: FC<Props> = ({ activeTab, loadUrlAtPlatform, buttonsDisabled, handleClickBack, handleClickForward }) => {
  const { back, forward } = FunnelButtonsBackForwardText[activeTab];

  return (
    <HStack justifyContent="center" alignItems="flex-start" spacing={6} paddingY={4}>
      <FunnelBackForwardButton title={back} buttonDirection={ButtonDirection.Back} onClickGoBack={handleClickBack} disabled={buttonsDisabled.back} />
      <LoadNavigateToPlatformButton url={loadUrlAtPlatform} />
      {isArray(forward) && isArray(handleClickForward) ? (
        <VStack spacing={3} alignItems="stretch">
          {forward.map((forwardTitle, index) => (
            <FunnelBackForwardButton
              title={forwardTitle}
              onClickGoForward={handleClickForward[index]}
              disabled={buttonsDisabled.forward}
              key={forwardTitle}
              className={classNames({ 'funnel-back-forward-button_confirmed': index === 0, 'funnel-back-forward-button_canceled': index === 1 })}
            />
          ))}
        </VStack>
      ) : (
        <FunnelBackForwardButton
          title={forward as string}
          buttonDirection={ButtonDirection.Forward}
          onClickGoForward={handleClickForward as VoidFunction}
          disabled={buttonsDisabled.forward}
        />
      )}
    </HStack>
  );
};
