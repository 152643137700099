import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { EMPTY_STRING } from 'config/constants/common-strings';

type Props = {
  regNumber?: string;
  region?: string;
};

export const RegNumber: FC<Props> = ({ regNumber, region }) => (
  <Flex className="regNumber">
    <Text padding="4px">{regNumber || EMPTY_STRING}</Text>
    {region && (
      <>
        <div className="regNumber__divider" />
        <Text padding="4px">{region || EMPTY_STRING}</Text>
      </>
    )}
  </Flex>
);
