import { OrderStatus } from 'config/constants/statuses';

export const BY_AGREEMENT = 'По договору';
export const CONTACT = 'Контактное лицо';
export const PHONE = 'Телефон';
export const RESPONSIBLE = 'Ответственный';
export const RISK_GROUP_DESIRED = 'Желаемая группа риска';

export const CLIENT = 'Клиент';

export const EXECUTOR = 'Исполнитель';
export const ACCEPTABLE_PRICE = 'Приемлемая цена';
export const BY_PRICE = 'По цене';

export const PARTIAL_UNLOADING = 'Частичная разгрузка';
export const UNLOADING = 'Разгрузка';
export const LOADING = 'Погрузка';

export const CARGO_TYPE = 'Тип груза';
export const ROUTE = 'Маршрут';

export const CREATED_HEADER = 'Дата и время';
export const ACTION_HEADER = 'Действие';
export const CREATOR_HEADER = 'Ответственный';

export const TIMELINE_HEADER = 'События в целом по заказу ';

export const orderActions = {
  Edit: 'Редактировать',
  Split: 'Разделить заявку',
  Search: 'Найти исполнителя',
  Cancel: 'Отменить',
  CancelSearch: 'Отменить поиск',
  CancelPreparation: 'Отменить оформление',
  Delete: 'Удалить заявку',
  Clone: 'Копировать',
  SearchExternalCarrier: 'Найти внешнего исполнителя',
  ChooseOwnDriverAndVehicle: 'Выбрать своего водителя и ТС',
  StartCarrierSearch: 'Распределение',
};

export const orderClientTabs = {
  ExecutionDetails: 'Исполнение и детали',
  Documents: 'Документы',
  Services: 'Услуги',
  DocsAndPays: 'Оплата',
  Insurance: 'Страховка',
};

export const cancelCaptionTexts: Partial<Record<OrderStatus, string>> = {
  carrierSearch: orderActions.CancelSearch,
  preparation: orderActions.CancelPreparation,
};
export const cancelWarningTexts: Partial<Record<OrderStatus, string>> = {
  carrierSearch: 'Поиск будет отменен',
  preparation: 'Оформление будет отменено. Исполнитель будет сброшен',
};

export const orderFuelStatusMapTexts = {
  confirmedWithoutFuel: null,
  executingWithoutFuel: 'На исполнение (без запроса на топливо)',
  completedWithoutFuel: 'Завершить (без запроса на топливо)',
  confirmedWithFuel: null,
  executingStartPreparation: 'На исполнение (с топливом)',
  executingFuelProvided: 'Топливо предоставлено',
  completedBlockFuel: 'Рейс завершен, заблокировать топливо',
  completedFuelClosed: 'Закрыть рейс',
};
