import { Grid, GridItem, Text } from '@chakra-ui/react';

export const CustomersTableComponentHeader = () => (
  <div className="customer-table-component-header">
    <Grid templateColumns="repeat(6, 1fr)" gap={2} padding="4px 16px 0 16px">
      <GridItem>
        <Text>ID</Text>
      </GridItem>
      <GridItem>
        <Text>Заказчик</Text>
      </GridItem>
      <GridItem>
        <Text>ИНН</Text>
      </GridItem>
      <GridItem>
        <Text>Договор</Text>
      </GridItem>
      <GridItem>
        <Text>Дата договора</Text>
      </GridItem>
      <GridItem>
        <Text>Ответственный</Text>
      </GridItem>
    </Grid>
  </div>
);
