import { FC } from 'react';
import classNames from 'classnames';
import { UiButton, UiButtonProps } from '@/ui-elements/button/button';
import './button-outlined.scss';

type Props = UiButtonProps;

export const UiButtonOutlined: FC<Props> = ({ className, ...props }) => (
  <UiButton className={classNames('ui-button-outlined', className)} {...props} />
);
