import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const deleteVariant = defineStyle(() => ({
  background: 'transparent',
  color: 'neutrals.50',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  iconSpacing: 5,
  px: '9px',
  _hover: {
    svg: {
      fill: 'products.150',
      stroke: 'products.150',
      path: {
        fill: 'products.150',
        stroke: 'transparent',
      },
    },
  },
  _disabled: {
    svg: {
      fill: 'neutrals.250',
      stroke: 'neutrals.250',
      path: {
        fill: 'neutrals.250',
        stroke: 'transparent',
      },
    },
    _hover: {
      svg: {
        fill: 'neutrals.250',
        stroke: 'neutrals.250',
        path: {
          fill: 'neutrals.250',
          stroke: 'transparent',
        },
      },
    },
  },
  _focus: {
    svg: {
      fill: 'products.100',
      stroke: 'products.100',
      path: {
        fill: 'products.100',
        stroke: 'transparent',
      },
    },
  },
}));

const textVariant = defineStyle(() => ({
  background: 'transparent',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 400,
  color: 'products.100',
  padding: 0,
  margin: 0,
  height: 'revert',
  svg: {
    path: {
      fill: 'products.100',
      stroke: 'transparent',
    },
  },
}));

const variants = {
  delete: deleteVariant,
  text: textVariant,
};

export const buttonTheme = defineStyleConfig({ variants });
