import classNames from 'classnames';
import { FC } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { Carrier } from '@/types/models/carrier';
import { OrganisationRating } from '@/components/organisation-rating/organisation-rating';
import './carrier-organzation.scss';
import { CarrierCard } from '@/components/carrier-card/carrier-card';
import { emptyFunction } from '@/helpers/empty-function';
import { getApprovalStatusCarrier } from '@/helpers/get-approval-status-carrier';
import { getCarrierOrganizationINN } from '@/features/carriers/helpers/get-carrier-organization-inn';

type Props = {
  carrier: Carrier;
  selected?: boolean;
  handleOrganizationClick?: VoidFunction;
  readOnly?: boolean;
};

export const CarrierOrganization: FC<Props> = ({ carrier, selected = false, handleOrganizationClick = emptyFunction, readOnly }) => {
  const { status, drivers, name, department, approvalStatus } = carrier;
  const carrierOrganizationINN = getCarrierOrganizationINN(carrier);
  // if (!drivers || drivers.every((x) => !x.vehicles || x.vehicles?.length === 0)) return null;
  const isMultiple = drivers.filter((x) => x.vehicles?.length).length > 1 || drivers.some((x) => x.vehicles && x.vehicles?.length > 1);
  return (
    <Box className={classNames('carrier-organization', { selected })} onClick={handleOrganizationClick}>
      {isMultiple ? (
        <Box className="carrier-organization__multy">
          <HStack className="carrier-organization__multy-header">
            <OrganisationRating ratingType={status} />
            <Text className="carrier-organization__multy-header__name">{name}</Text>
            <Text className="carrier-organization__multy-header__inn">{`ИНН ${carrierOrganizationINN}`}</Text>
            <Text className="carrier-organization__multy-header__area">{department.name}</Text>
            <Text className="carrier-organization__multy-header__approved">{getApprovalStatusCarrier(approvalStatus)}</Text>
          </HStack>
          {drivers
            .filter((x) => x.vehicles && x.vehicles?.length > 0)
            .map((driver) => {
              return driver.vehicles?.map((vehicle) => {
                return <CarrierCard key={`${driver.id}-${vehicle.id}`} vehicle={vehicle} driver={driver} readOnly={readOnly} />;
              });
            })}
        </Box>
      ) : (
        <Box className="carrier-organization__single">
          <HStack className="carrier-organization__single-header">
            <HStack>
              <Text className="carrier-organization__single-header__name">{name}</Text>
              <Text className="carrier-organization__single-header__area">{department.name}</Text>
            </HStack>
            <Text className="carrier-organization__multy-header__approved">{getApprovalStatusCarrier(approvalStatus)}</Text>
          </HStack>
          {drivers[0] && <CarrierCard driver={drivers[0]} vehicle={drivers[0].vehicles?.[0]} readOnly={readOnly} />}
        </Box>
      )}
    </Box>
  );
};
