export const iconNames = [
  'CarMockIcon',
  'TemperatureIcon',
  'TonageIcon',
  'VolumeIcon',
  'Profile',
  'NotificationsBell',
  'IconIntelogis',
  'Chat',
  'Line',
  'ReverseIcon',
  'StepUp',
  'StepDown',
  'Sorting',
  'RightArrow',
  'RiskGroupTwo',
  'RiskGroupNumberTwo',
  'Success',
  'Messenger',
  'TSJumbo',
  'Temperature',
  'Volume',
  'MaxTonage',
  'Paperclip',
  'AddIcon',
  'HelpIcon',
  'DeleteIcon',
  'EditIcon',
  'SplitIcon',
  'CloseIcon',
  'ConstructIcon',
  'ReverseIcon',
  'MapPinFill',
  'SortBy',
  'Close',
  'Vector',
  'FileAdd',
  'CarType',
  'CreateOrder',
  'Swap',
  'ArrowUp',
  'Fire',
  'ConstructIcon',
  'StarBlack',
  'StarGolden',
  'Send',
  'DividerArrowIcon',
  'Calendar',
  'PrintIcon',
  'ExportIcon',
  'PlusIcon',
  'DownChevronIcon',
  'Download',
  'ClaimIcon64',
  'Checkmark',
  'JoinIcon',
  'LoadIcon',
  'UnloadIcon',
  'LongArrowIcon',
  'ArrowLeft',
  'RiskGroupOne',
  'RiskGroupThree',
  'RiskGroupFour',
  'RiskGroupFive',
  'RiskGroupSix',
  'City',
  'TrailerHead',
  'InfoIcon',
  'Warningicon',
  'Organisation',
  'Error404',
  'FromDotsToDots',
  'DocumentsAccepted',
  'DocumentsRejected',
  'AtiSu',
  'MonopolyOnline',
  'Auction',
  'Dimensions',
  'ILS',
  'Completed',
] as const;
