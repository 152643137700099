import { Flex } from '@chakra-ui/react';
import './ui-spinner.scss';
import { UiSpinnerPropsProps } from '@/ui-elements/ui-spinner/ui-spinner-props';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';

export const UiSpinnerGlobal = (props: UiSpinnerPropsProps) => {
  const { visible } = props;
  return !visible ? null : (
    <Flex className="ui-spinner">
      <UiSpinner {...props} />
    </Flex>
  );
};
