import { Box, Heading, Text } from '@chakra-ui/react';
import { FC } from 'react';
import './review.scss';
import { Review } from '@/types/models/review';
import { UiRatingStars } from '@/ui-elements/inputs/ui-rating-stars/ui-rating-stars';

type Props = {
  review: Review & { tripId: number };
};

export const ReviewComponent: FC<Props> = ({ review }) => {
  const { name } = review.creator;
  return (
    <Box className="review">
      <UiRatingStars rating={review.ratingValue} />
      <Heading className="review-title">Рейс №{review.tripId}</Heading>
      <Text mt="12px" className="review-value">
        {review.value}
      </Text>
      <Text mt="12px" className="review-author">{`${name}`}</Text>
    </Box>
  );
};
