import { Dictionary } from 'config/constants/dictionary';
import { ApiDelete, ApiGet, ApiPost, ApiPut } from '@/helpers/api/methods';
import { SortNPageFilter } from '@/types/common/sort-page-filter';
import { validateQueryString } from '@/helpers/validate-query-string';
import { ResponseWithPager } from '@/types/models/pager';
import { User } from '@/types/models/user';
import { UserAdd, UserEdit } from '@/types/api/user-partial';
import { ResponseApi, ResponseId } from '@/types/response';
import { Role } from '@/types/common/role';
import { UserRoleNew } from '@/types/api/user-role-partial';
import { ResponseWithData } from '@/types/api/response';

export const UsersApi = {
  getAllUsers: (query: SortNPageFilter) => ApiGet<ResponseWithPager<User>>(`${Dictionary.Users}${validateQueryString(query)}`),

  addUser: (options: UserAdd) => ApiPost<ResponseId>(`${Dictionary.Users}`, options),

  getUser: (id: number) => ApiGet<User>(`${Dictionary.Users}/${id}`),

  updateUser: (id: number, options: UserEdit) => ApiPut<ResponseApi>(`${Dictionary.Users}/${id}`, options),

  deleteUser: (id: number) => ApiDelete<ResponseApi>(`${Dictionary.Users}/${id}`),

  getUserRoles: () => ApiGet<ResponseWithData<Role>>(`${Dictionary.Users}/getRoleEnum`),

  updateUserRole: (id: number, options: UserRoleNew) => ApiPut<ResponseApi>(`${Dictionary.Users}/${id}/setRole`, options),

  sendUserPassword: (id?: number | null) => ApiGet<User>(`${Dictionary.Users}/${id}/sendPassword`),
};
