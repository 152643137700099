import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const dashedVariant = defineStyle(() => ({
  borderRadius: '8px',
  border: '1px dashed',
  borderColor: 'neutrals.200',
  padding: '10px 12px',
  margin: 0,
  maxWidth: '100%',
}));

const variants = {
  dashed: dashedVariant,
};

export const containerTheme = defineStyleConfig({ variants });
