import { FunnelLoadStatus } from '@/types/models/funnel';

export enum ApproveStatus {
  Waiting = 'waiting', // ожидает проверку
  InProcessing = 'inProcessing', // на проверке в ОТК
  Processed = 'processed', // обраработано
}

// Статус проверки отдельных сущностей: водителя, ТС, организации
export enum VerificationItemApproveStatus {
  Waiting = 'waiting',
  Accepted = 'accepted',
  Declined = 'declined',
  InProcessing = 'inProcessing',
}

export const FORBIDDEN_EDIT_STATUSES = [VerificationItemApproveStatus.Waiting, VerificationItemApproveStatus.InProcessing];
export const NOTIFY_EDIT_STATUSES = [VerificationItemApproveStatus.Accepted];

// После редактирования весь объект проверки сущности остается, но поле status - null;
// Возможно для редактирования будет добавлен в будущем статус Expired, пока null;
export type VerificationStatus = {
  status: VerificationItemApproveStatus | null;
  departmentId: number;
  departmentName: string; // название филиала
  date?: string; // дата отправки на проверку
  comment?: string;
};

export enum CustomersApproveStatus {
  Waiting = 'waiting',
  Approved = 'approved',
  Declined = 'declined',
  Expired = 'expired',
}

export enum DriverStatus {
  Executing = 'executing',
  Waiting = 'waiting',
}

export enum OrderSwitcherStatus {
  /** Создание заявки */
  New = 'new',
  /** Поиск исполнителя */
  CarrierSearch = 'carrierSearch',
  /** Исполнитель найден - подготовка документов */
  Preparation = 'preparation',
  /** документы подтверждены */
  Confirmed = 'confirmed',
  /** Заявка в работе */
  Executing = 'executing',
  /**
   * completedWithoutDocs - Завершена, ожидает документы оплаты
   * completedWithDocs - Завершена, документы на оплату получены
   * completedPayed - Завершена и оплачена */
  Completed = 'completed',
  Failed = 'failed',
  /***/
  All = 'all',
}

export enum OrderCompletedSwitcherStatus {
  CompletedWithoutDocs = 'completedWithoutDocs',
  CompletedWithDocs = 'completedWithDocs',
  CompletedPayed = 'completedPayed',
  All = 'all',
}

export enum OrderExecutionStatus {
  New = 'new',
  CarrierSearch = 'carrierSearch',
  Executing = 'executing',
  Failed = 'failed',
  Preparation = 'preparation',
  Confirmed = 'confirmed',
  Troubling = 'troubling',
  /** Завершена, ожидает документы оплаты */
  CompletedWithoutDocs = 'completedWithoutDocs',
  /** Завершена, документы на оплату получены */
  CompletedWithDocs = 'completedWithDocs',
  /** Завершена и оплачена */
  CompletedPayed = 'completedPayed',
  /** Все завершенные */
  Completed = 'completed',
}

export type OrderStatus = OrderExecutionStatus | FunnelLoadStatus;

export const SwitcherWithoutFuelStatus = {
  ConfirmedWithoutFuel: 'confirmedWithoutFuel',
  ExecutingWithoutFuel: 'executingWithoutFuel',
  CompletedWithoutFuel: 'completedWithoutFuel',
};

export const SwitcherWithFuelStatus = {
  ConfirmedWithFuel: 'confirmedWithFuel',
  ExecutingStartPreparation: 'executingStartPreparation',
  ExecutingFuelProvided: 'executingFuelProvided',
  CompletedBlockFuel: 'completedBlockFuel',
  CompletedFuelClosed: 'completedFuelClosed',
};

export enum FuelStatus {
  ConfirmedWithoutFuel = 'confirmedWithoutFuel',
  ExecutingWithoutFuel = 'executingWithoutFuel',
  CompletedWithoutFuel = 'completedWithoutFuel',
  ConfirmedWithFuel = 'confirmedWithFuel',
  ExecutingStartPreparation = 'executingStartPreparation',
  ExecutingFuelProvided = 'executingFuelProvided',
  CompletedBlockFuel = 'completedBlockFuel',
  CompletedFuelClosed = 'completedFuelClosed',
}

export enum CommonStatus {
  New = 'new',
  Approved = 'approved',
  Declined = 'declined',
  Waiting = 'waiting',
  All = 'all',
}

export enum PaymentOrderStatus {
  None = 'none',
  Ready = 'ready',
  Paid = 'paid',
}

export enum BookerOrderSwitcherStatus {
  Preparation = 'preparation',
  Confirmed = 'confirmed',
  Executing = 'executing',
  Completed = 'completed',
  Failed = 'failed',
  RequestAdvance = 'requestAdvance',
  FinallySettlement = 'finallySettlement',
  All = 'all',
}

export enum BookerOrderDetailsTabs {
  Customer = 'Customer',
  Carrier = 'Carrier',
}

export enum BookerOrderStatus {
  Preparation = 'preparation',
  Confirmed = 'confirmed',
  Executing = 'executing',
  Completed = 'completed',
  Failed = 'failed',
  CompletedWithoutDocs = 'completedWithoutDocs',
  CompletedWithDocs = 'completedWithDocs',
  CompletedPayed = 'completedPayed',
}
