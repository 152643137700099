import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useAppSelector } from '@/hooks/use-app-selector';
import { ResourcesSidebarMode } from '@/features/resources/types/general';
import { resourcesSelectedVehicleIdSelector, resourcesSidebarModeSelector } from '@/features/resources/selectors';
import {
  useVehicleFormInitialValues,
  useResourcesDispatchActions,
  useEditResourcesVehicle,
  useCreateResourcesVehicle,
} from '@/features/resources/hooks';
import { useAccount } from '@/features/account/hooks/use-account';
import { VehicleFormErrors } from '@/types/models/vehicle';
import { FormValues } from '@/components/verification-forms/types';
import { VehicleGroupEnum, VehicleOwnTypeEnum } from '@/components/verification-forms/constants';
import { validateTrailerRegNumber, validateVehicleRegNumber } from '@/helpers/forms/validate-vehicle-reg-number';

export const useResourcesVehicleForm = () => {
  /* id текущего перевозчика */
  const { currentAccount } = useAccount();
  const carrierId = currentAccount.user?.accountId;

  /* id выбранного тс для редактирования */
  const mode = useAppSelector(resourcesSidebarModeSelector);
  const selectedVehicleId = useAppSelector(resourcesSelectedVehicleIdSelector);
  const vehicleId = mode === ResourcesSidebarMode.EditForm ? selectedVehicleId : null;

  /* начальные значения для формы редактирования */
  const { initialValues, vehicleInitialDocs } = useVehicleFormInitialValues({ vehicleId, carrierId });

  const [withoutTrailer, setWithoutTrailer] = useState<boolean>(isEmpty(initialValues?.trailers));
  const [vehicleGroup, setVehicleGroup] = useState<VehicleGroupEnum>(initialValues?.vehicleGroup || VehicleGroupEnum.default);
  const [ownType, setOwnType] = useState<VehicleOwnTypeEnum>(initialValues?.ownType || VehicleOwnTypeEnum.rent);

  const handleChangeVehicleType = (val: string) => {
    setVehicleGroup(Number(val));
    if (Number(val) === 0) {
      setWithoutTrailer(true);
    } else if (Number(val) !== 2) {
      setWithoutTrailer(false);
    }
  };
  const handleChangeWithoutTrailer = () => setWithoutTrailer((prev) => !prev);
  const handleChangeOwnType = (val: string) => {
    setOwnType(Number(val));
  };

  /* закрытие формы */
  const { handleSetSidebarMode } = useResourcesDispatchActions();
  const closeForm = () => handleSetSidebarMode(ResourcesSidebarMode.None);

  /* submit формы */
  const { handleCreateVehicle } = useCreateResourcesVehicle({ withoutTrailer, carrierId, onClose: closeForm });
  const { handleEditVehicle } = useEditResourcesVehicle({ vehicleId, withoutTrailer, vehicleGroup, ownType, onClose: closeForm });

  const submitHandlers: Partial<Record<ResourcesSidebarMode, (values: FormValues) => void>> = {
    [ResourcesSidebarMode.CreateForm]: handleCreateVehicle,
    [ResourcesSidebarMode.EditForm]: handleEditVehicle,
  };

  const validate = (values: FormValues) => {
    const errors: VehicleFormErrors = {};
    const REQUIRED = 'Обязательно поле';
    if (isEmpty(values.vehicleType)) {
      errors.vehicleType = REQUIRED;
    }
    if (withoutTrailer && !values.volume) {
      errors.volume = REQUIRED;
    }
    if (withoutTrailer && !values.capacity) {
      errors.capacity = REQUIRED;
    }
    if (withoutTrailer && !values.dimensions) {
      errors.dimensions = REQUIRED;
    }
    if (!values.regNumber) {
      errors.regNumber = REQUIRED;
    }
    if (values?.regNumber) {
      const vehicleRegNumberValidationError = validateVehicleRegNumber(values?.regNumber);
      if (vehicleRegNumberValidationError) {
        errors.regNumber = vehicleRegNumberValidationError;
      }
    }
    if (!isEmpty(values?.trailers) && values?.trailers?.[0]?.regNumber) {
      const trailerRegNumberValidationError = validateTrailerRegNumber(values?.trailers?.[0]?.regNumber);
      if (trailerRegNumberValidationError) {
        errors.trailers = [];
        const trailerRegNumberError = { regNumber: trailerRegNumberValidationError };
        errors.trailers?.push(trailerRegNumberError);
      }
    }
    return errors;
  };

  return {
    initialValues,
    vehicleInitialDocs,
    mode,
    vehicleId,
    submitHandlers,
    closeForm,
    withoutTrailer,
    vehicleGroup,
    ownType,
    handleChangeVehicleType,
    handleChangeWithoutTrailer,
    handleChangeOwnType,
    validate,
  };
};
