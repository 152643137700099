// Метод для склонения существительного в зависимости от числительного перед ним
// Передавать число и массив существительных
// Пример: declensionNouns(value, ['товар', 'товара', 'товаров'])

import isEmpty from 'lodash/isEmpty';

export const declensionNouns = (value: number, words: Array<String>) => {
  if (isEmpty(words)) return '';
  const relativeValue = Math.abs(value) % 100;
  const num = relativeValue % 10;
  if (relativeValue > 10 && relativeValue < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num === 1) return words[0];
  return words[2];
};
