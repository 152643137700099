import './loads-tariff-radio-buttons.scss';
import classNames from 'classnames';
import { useField } from 'react-final-form';
import { FormControl, FormLabel, HStack, RadioGroup, useRadioGroup } from '@chakra-ui/react';
import { LoadsFiltersField, LoadsFiltersFieldLabel, TariffTypes } from '@/types/models/loads';
import { UiText } from '@/ui-elements/ui-text/ui-text';
import { LoadsTariffRadioButton } from '@/components/loads/tariff-radio-button/loads-tariff-radio-button';

export const LoadsTariffRadioButtons = ({ disabled = false }: { disabled: boolean }) => {
  const { input } = useField(LoadsFiltersField.TariffType);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: LoadsFiltersField.TariffType,
    value: input?.value || TariffTypes.Any,
    onChange: input.onChange,
  });

  const group = getRootProps();

  return (
    <FormControl>
      <FormLabel className="loads-tariff-radio-buttons__label">
        <UiText>{LoadsFiltersFieldLabel.TariffType}</UiText>
      </FormLabel>
      <RadioGroup>
        <HStack {...group} spacing={2} className={classNames('loads-tariff-radio-buttons', { 'loads-tariff-radio-buttons_disabled': disabled })}>
          {Object.values(TariffTypes).map((tariffType) => {
            const radio = getRadioProps({ value: tariffType });
            return <LoadsTariffRadioButton key={tariffType} tariff={tariffType} {...radio} />;
          })}
        </HStack>
      </RadioGroup>
    </FormControl>
  );
};
