export enum ErrorMessage {
  Email_Required = 'Обязательное поле email',
  Email_Invalid = 'Введите валидный e-mail адрес',
  Required = 'Обязательное поле',
  Required_At_Least_1 = 'Необходимо выбрать хотя бы одно значение',
  Passport_Date_Invalid = 'Выдача паспорта возможна с 14 лет',
  License_Date_Invalid = 'Дата выдачи водительского удостоверения должна быть с 18 лет',
  Birth_Date_Invalid = 'Лицо должно быть старше 18 лет',
  Min_Pass_Length = 'Длина пароля не менее 4 символов',
  Vehicle_Reg_Number = `Некорректный формат регистрационного номера. 
  Пожалуйста, запишите номер в соответствии с форматом: "буква — 3 цифры — 2 буквы, код региона"`,
  Trailer_Reg_Number = `Некорректный формат регистрационного номера полуприцепа. 
  Пожалуйста, запишите номер в соответствии с форматом: "2 буквы — 4 цифры, код региона"`,
}
