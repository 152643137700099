import { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { FORM_UI_ELEMENTS, ORGANIZATION_TYPE_FIELDS } from './constants';
import { FormSectionTitle } from './my-organization-form-header';
import { MyOrganizationFormOptions } from '@/types/models/my-organization/form';

interface IProps {
  options: MyOrganizationFormOptions;
  disabled: boolean;
}

export const MyOrganizationType: FC<IProps> = ({ options, disabled }) => {
  return (
    <Box>
      <FormSectionTitle title="Название и тип организации" />
      <Flex className="my-organization-flex" direction="row" align="center" wrap="wrap">
        {ORGANIZATION_TYPE_FIELDS.map((field) => {
          const { Component, defaultProps } = FORM_UI_ELEMENTS[field];
          return <Component isDisabled={disabled} disabled={disabled} key={field} options={options[field]} {...defaultProps} />;
        })}
      </Flex>
    </Box>
  );
};
