import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import { FC } from 'react';
import classNames from 'classnames';
import './checkbox.scss';

export type Props = CheckboxProps & {
  checked?: boolean;
  disabled?: boolean;
  onChange?: () => void;
  className?: string;
  label?: string;
};

export const UiCheckbox: FC<Props> = ({ label, checked, disabled, onChange, children, className, ...props }) => (
  <Checkbox
    isChecked={checked}
    isDisabled={disabled}
    onChange={(e) => !disabled && onChange?.(e)}
    className={classNames(className, 'ui-checkbox')}
    {...props}
  >
    {label || children}
  </Checkbox>
);
