import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useFormState } from 'react-final-form';
import plus from 'public/assets/icons/PlusIcon.svg';
import { VStack, HStack, Text, Box, Flex } from '@chakra-ui/react';
import {
  UiRadioGroupField,
  UiIconButton,
  UiNumberPickerField,
  UiSelectField,
  UiButtonOutlined,
  UiTextareaField,
  UiCheckboxField,
} from '@/ui-elements';
import { useIcons } from '@/hooks/use-icons';
import { SubformTitle } from '@/components/create-order-form/subform-title';
import { loadOptions, loadTypeConfig, tonnageOptions } from './form-configs';
import { UiTooltip } from '@/ui-elements/tooltip/tooltip';
import { UiVehicleTypesPickerField } from '@/ui-elements/form-fields/ui-vehicle-type-picker-field/ui-vehicle-type-picker-field';
import './ati-su-vehicle-type.scss';

type Props = {
  namespace: string;
};

export const VehicleTypeSubform = ({ namespace }: Props) => {
  const { CloseIcon, HelpIcon } = useIcons({ height: '14px', width: '14px' });
  const initialSubform: number[] = [];
  const { values } = useFormState();
  const isPermissions = values.vehicleType?.CMR || values.vehicleType?.TIR || values.vehicleType?.T1 || values.vehicleType?.medicalBook;
  if (!values.vehicleType?.tonnage) initialSubform.push(1);
  if (!values.vehicleType?.belts) initialSubform.push(2);
  if (!isPermissions) initialSubform.push(3);

  const [subformControls, setSubformControls] = useState<Array<number>>(initialSubform);

  return (
    <VStack className="vehicle-type" spacing={0} align="start">
      <SubformTitle title="Требования к транспорту" />
      <Box w="full">
        <Box w="full" mb="22px">
          {/* <VehicleTypeSelectType name={`${namespace}.vehicleTypes`} content="Максимальный вес перевозки 20 тонн" required /> */}
          <UiVehicleTypesPickerField required label="Тип кузова" name={`${namespace}.vehicleTypes`} content="Максимальный вес перевозки 20 тонн" />
        </Box>
        <UiSelectField
          options={loadOptions}
          name={`${namespace}.loadingType`}
          label="Тип Загрузки/Погрузки(можно выбрать несколько)"
          placeholder="Выберите вариант"
          isMulti
        />
        <UiTextareaField
          name={`${namespace}.additionalRequirements`}
          label="Дополнительные требования к исполнителю"
          placeholder="Напишите комментарии при необходимости"
        />
      </Box>
      <HStack spacing={4} py={4}>
        <Text>Загрузка</Text>
        <UiRadioGroupField config={loadTypeConfig} name={`${namespace}.loadType`} />
      </HStack>
      {!subformControls.includes(1) && (
        <HStack>
          <UiIconButton aria-label="close" icon={CloseIcon} onClick={() => setSubformControls((prev) => [...prev, 1])} />
          <Text>Ремней</Text>
          <UiNumberPickerField name={`${namespace}.belts`} maxWidth="150px" />
          <Text>шт</Text>
        </HStack>
      )}
      {!subformControls.includes(2) && (
        <HStack minWidth="500px">
          <UiIconButton aria-label="close" icon={CloseIcon} onClick={() => setSubformControls((prev) => [...prev, 2])} />
          <Text>Грузоподъемность</Text>
          <Box minWidth="200px">
            <UiSelectField name={`${namespace}.tonnage`} options={tonnageOptions} placeholder="т" />
          </Box>
          <Text>Выберите или введите требуемую грузоподъемность ТС</Text>
        </HStack>
      )}
      {!subformControls.includes(3) && (
        <HStack minWidth="500px" className="vehicle-type__permissions">
          <UiIconButton aria-label="close" icon={CloseIcon} onClick={() => setSubformControls((prev) => [...prev, 3])} />
          <Text className="vehicle-type__permissions-header">Разрешения</Text>
          <HStack ml="20px" justifyContent="space-between" minWidth="500px">
            <HStack>
              <UiCheckboxField defaultChecked={values.vehicleType?.TIR} name={`${namespace}.TIR`} label="TIR" />
              <UiTooltip label="Разрешение на транзит опломбированной машины">
                <HelpIcon />
              </UiTooltip>
            </HStack>
            <HStack>
              <UiCheckboxField defaultChecked={values.vehicleType?.T1} name={`${namespace}.T1`} label="T1" />
              {/* eslint-disable-next-line max-len */}
              <UiTooltip label="Международная товарно-транспортная накладная. Подтверждает заключение договора перевозки и определяет ответственность отправителя, перевозчика и получателя товара.">
                <HelpIcon />
              </UiTooltip>
            </HStack>
            <HStack>
              <UiCheckboxField defaultChecked={values.vehicleType?.CMR} name={`${namespace}.CMR`} label="CMR" />
              {/* eslint-disable-next-line max-len */}
              <UiTooltip label="Транзитная декларация или Северный паспорт. Документ оформляется для товаров, пересекающих территорию ЕС транзитом, а также для гарантии доставки товара от границы ЕС к таможенному складу или внутренней таможне или наоборот.">
                <HelpIcon />
              </UiTooltip>
            </HStack>
            <HStack>
              <UiCheckboxField defaultChecked={values.vehicleType?.medicalBook} name={`${namespace}.medicalBook`} label="Медкнижка" />
            </HStack>
          </HStack>
        </HStack>
      )}
      <HStack>
        {!isEmpty(subformControls) && <Text>Добавить</Text>}
        <Flex wrap="wrap" w="full">
          {subformControls.includes(1) && (
            <UiButtonOutlined leftIcon={plus} onClick={() => setSubformControls((prev) => prev.filter((item) => item !== 1))} m={1}>
              Ремней, шт
            </UiButtonOutlined>
          )}
          {subformControls.includes(2) && (
            <UiButtonOutlined leftIcon={plus} onClick={() => setSubformControls((prev) => prev.filter((item) => item !== 2))} m={1}>
              Грузоподъемность
            </UiButtonOutlined>
          )}
          {subformControls.includes(3) && (
            <UiButtonOutlined leftIcon={plus} onClick={() => setSubformControls((prev) => prev.filter((item) => item !== 3))} m={1}>
              TIR, CMR, T1, Медкнижка
            </UiButtonOutlined>
          )}
        </Flex>
      </HStack>
    </VStack>
  );
};
