import { FC } from 'react';
import './app-toolbar.scss';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { navigationMenu } from 'config/navigation-menu';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { ProfileMenu } from 'components/profile-menu/profile-menu';
import { NotificationsPopover } from 'components/notifications-popover/notifications-popover';
import { TopRoutesMenu } from '@/components/top-menu/top-routes-menu';
import { getMenuRoutes } from '@/helpers/routes/get-menu-routes';
import { NavigationMenu } from '@/types/routes/navigation-menu';
import { useAccount } from '@/features/account/hooks/use-account';
import { usePopover } from '@/hooks/use-popover';
import { useAuth } from '@/features/account/hooks/use-auth';
import { BurgerMenu } from '@/components/app-toolbar/burger-menu/burger-menu';
import { useBurger } from '@/hooks/use-burger';
import { HeaderLogo } from '@/components/app-toolbar/header-logo/header-logo';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { useSplitTopMenu } from '@/hooks/use-split-top-menu';
import { UserRole } from '@/types/api/user-role';

export const AppToolbar: FC<{ notificationsCounter: number }> = ({ notificationsCounter }) => {
  const { currentRole, currentAccount, accountRole } = useAccount();
  const { logout } = useAuth();
  const { isOpen, onToggle, onClose } = useDisclosure();

  const menu: NavigationMenu = getMenuRoutes(navigationMenu, currentRole as unknown as UserRole[], accountRole);

  const { open, onToggleMenu, onCloseMenu } = useBurger();

  const {
    topMenu: { desktopMenu, burgerMenu },
  } = useSplitTopMenu({ menu: menu.items });

  const { popoverButton } = usePopover(NotificationsPopover, { notificationsCounter });
  // const { popoverButton: menuButton } = usePopover(ProfileMenu, { onLogoutClick: logout });

  return (
    <header className="app-toolbar">
      <HeaderLogo menu={menu} />
      <TopRoutesMenu menu={desktopMenu} onClose={onCloseMenu} isDesktop={Boolean(true)} />
      <div className={classNames('app-toolbar__menu', { active: open })}>
        <UiScrollbars autoHide className="app-toolbar__scroll">
          <TopRoutesMenu menu={burgerMenu} onClose={onCloseMenu} />
        </UiScrollbars>
      </div>
      <Flex gap="18px" justifyContent="center" className="app-toolbar__icon">
        {!isEmpty(burgerMenu) && <BurgerMenu open={open} onToggle={onToggleMenu} />}
        {popoverButton}
        {/* {menuButton} */}
        <ProfileMenu isOpen={isOpen} onToggle={onToggle} onClose={onClose} onLogoutClick={logout} account={currentAccount} />
      </Flex>
    </header>
  );
};
