import { Box } from '@chakra-ui/react';
import './carriers-verification-sidebar.scss';
import { CarriersVerificationInfo } from 'components/carriers-verification/carriers-verification-info';
import { ApproveOrganizationForm } from 'components/verification-forms/organization-verification/approve-organization-form';
import { ApproveCustomerForm } from 'components/verification-forms/customer-verification/approve-customer-form';
import { CustomerDetails } from 'components/verification-forms/customer-verification/customer-details';
import { DriverDetails } from '@/components/carriers-verification/driver-details';
import { VehicleDetails } from '@/components/carriers-verification/vehicle-details';
import { ApproveDriverForm } from '@/components/verification-forms/approve-driver-form';
import { ApproveVehicleForm } from '@/components/verification-forms/vehicle-verification/approve-vehicle-form';
import { useVerificationSidebar } from '@/views/carriers-verification-sidebar/use-verification-sidebar';
import { VerificationEmpty } from '@/components/verification-empty/verification-empty';

type Props = {
  checking: boolean;
  setChecking: (arg0: boolean) => void;
};

export const VerificationSidebar = ({ checking, setChecking }: Props) => {
  const { isLoading, carrier, driver, vehicle, customer, onAccept, onDecline, onClose, approval, record } = useVerificationSidebar(setChecking);
  let subject;
  let form;
  switch (true) {
    case Boolean(approval?.data?.customer):
      subject = <CustomerDetails setChecking={setChecking} approval={record} customerInfo={customer?.data} />;
      form = customer?.data ? (
        <ApproveCustomerForm onApprovalApproved={onAccept} onApprovalDeclined={onDecline} onClose={onClose} customer={customer?.data} />
      ) : null;
      break;
    case Boolean(approval?.data?.driver):
      subject = <DriverDetails setChecking={setChecking} approval={record} />;
      form =
        driver?.data && carrier?.data ? (
          <ApproveDriverForm
            onApprovalApproved={onAccept}
            onApprovalDeclined={onDecline}
            onClose={onClose}
            driver={driver?.data}
            carrier={carrier.data}
          />
        ) : null;
      break;
    case Boolean(approval?.data?.vehicle):
      subject = <VehicleDetails setChecking={setChecking} approval={record} />;
      form =
        vehicle?.data && carrier?.data ? (
          <ApproveVehicleForm
            onApprovalApproved={onAccept}
            onApprovalDeclined={onDecline}
            onClose={onClose}
            vehicle={vehicle.data}
            carrier={carrier.data}
          />
        ) : null;
      break;
    default:
      subject = null;
      form = carrier ? (
        <ApproveOrganizationForm
          onApprovalApproved={onAccept}
          onApprovalDeclined={onDecline}
          onClose={onClose}
          carrier={carrier.data}
          driver={driver?.data}
        />
      ) : null;
  }

  if (!approval) return <VerificationEmpty isLoading={isLoading} />;

  return (
    <Box className="carriers-verification-sidebar-wrapper">
      {!checking && (
        <CarriersVerificationInfo
          approval={record}
          loading={isLoading}
          subject={subject}
          carrier={carrier?.data}
          customer={customer?.data}
          setChecking={setChecking}
        />
      )}
      {checking && (
        <Box position="absolute" top={6} bg="neutrals.350" w="33%">
          {form}
        </Box>
      )}
    </Box>
  );
};
