import moment from 'moment';
import { MOMENT_DD_MM_YYYY } from './moment/constants';
import { durationToDates } from './moment/duration-to-dates';

export const getPeriodFilterValue = (periodValue: string) => {
  switch (periodValue) {
    case 'today':
      return { dateFrom: moment().format(MOMENT_DD_MM_YYYY), dateTo: '' };
    case 'weekly':
      return { dateFrom: durationToDates(7), dateTo: moment().format(MOMENT_DD_MM_YYYY) };
    case 'monthly':
      return { dateFrom: durationToDates(30), dateTo: moment().format(MOMENT_DD_MM_YYYY) };
    default:
      return { dateFrom: '', dateTo: '' };
  }
};
