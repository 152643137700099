export const findNestedVehicleTypeById = (vehicleTypes: Array<any>, vehicleType: number | string): any => {
  // eslint-disable-next-line no-restricted-syntax
  for (const obj of vehicleTypes) {
    if (obj.id === Number(vehicleType)) {
      return obj;
    }
    if (obj.types && obj.types.length > 0) {
      const found = findNestedVehicleTypeById(obj.types, vehicleType);
      if (found) {
        return found;
      }
    }
  }
  return null;
};
