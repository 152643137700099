import { FC } from 'react';
import { Box, Center, Divider, Heading, HStack, Text } from '@chakra-ui/react';
import classNames from 'classnames';
import { OrderCardPriceWidget } from 'components/order-card/order-card-widgets/order-card-price-widget';
import { head } from 'lodash';
import { Order } from '@/types/models/order';
import { PaymentChip } from '@/ui-elements/payment-chip/payment-chip';
import { getStatusesStyles } from './styles';
import { UiStatus } from '@/ui-elements/status/status';
import { FuelStatus, OrderExecutionStatus } from '@/config/constants/statuses';
import { UiStatusRequest } from '@/ui-elements/status/status-request';
import { OrderGeneralDistance, OrderGeneralInfo } from '@/components';
import { OrderCardRoute } from '../order-card-route/order-card-route';
import './order-card.scss';
import { emptyFunction } from '@/helpers/empty-function';
import { toPriceFormat } from '@/helpers/price/to-price-format';
import { handlePriceChipVisibility } from '@/helpers/price/handle-price-chip-visibility';

type Props = {
  order: Order;
  selected?: boolean;
  handleOrderClick?: (orderId: number) => void;
  isFuel?: boolean;
  fuelStatuses?: Record<FuelStatus, string>;
};

export const OrderCard: FC<Props> = ({ order, isFuel = false, selected = false, handleOrderClick = emptyFunction, fuelStatuses }) => {
  const {
    id,
    prepayment,
    carrierPayment,
    customer,
    status,
    countRequestShipments,
    countOffers,
    distance,
    shippedTotals,
    expiration,
    points,
    type,
    responsiblePerson,
    loadingType,
  } = order;

  const statusStyle = getStatusesStyles({
    status,
    waitingCount: countRequestShipments,
    counterOffersCount: countOffers,
  });

  const carrierAcceptedOffer = head(order.offer);

  const isVisiblePrice = handlePriceChipVisibility({ carrierPayment, status, carrierAcceptedOffer });

  return (
    <Box className={classNames('order-card', { selected })} onClick={() => handleOrderClick(id)}>
      <HStack className="order-card__header">
        <HStack>
          <Text className="order-card__company">{customer?.name}</Text>
          <Heading className="order-card__title">{order?.id}</Heading>
        </HStack>
        <HStack>
          {isFuel && order.fuel?.fuelCompany.name && (
            <UiStatus text={order.fuel?.fuelCompany.name} backGroundColor="products.200" color="typography.250" />
          )}
          {isFuel && order.fuel?.value && <UiStatus text={`Топливо ${order.fuel?.value}`} backGroundColor="products.200" color="typography.250" />}
          {!isFuel && prepayment?.value && <PaymentChip type="advance" amount={toPriceFormat(prepayment.value)} />}
          <OrderCardPriceWidget
            isVisiblePrice={isVisiblePrice}
            priceWithVAT={carrierPayment.priceWithVAT}
            priceWithoutVAT={carrierPayment.priceWithoutVAT}
          />
          {!isFuel &&
            statusStyle.map((item) => <UiStatus key={item.text} text={item.text} backGroundColor={item.backgroundColor} color={item.color} />)}
          {status === OrderExecutionStatus.New && !isFuel && <UiStatusRequest date={expiration} />}
          {isFuel && order.fuel?.status && (
            <UiStatus
              className="order-card__header__fuel-status"
              text={fuelStatuses?.[order.fuel?.status] || ''}
              backGroundColor="#FBAD69"
              color="typography.250"
            />
          )}
        </HStack>
      </HStack>
      <OrderCardRoute points={points} order={order} />
      <HStack className="order-card__information">
        <HStack className="order-card__information-general">
          <OrderGeneralDistance type={type} range={distance || 0} />
          <Center className="order-card__information-divider__container">
            <Divider className="order-card__information-divider" orientation="vertical" />
          </Center>
          <OrderGeneralInfo
            shippedTotals={shippedTotals}
            vehicleTypes={order.vehicleTypes}
            loadingType={loadingType || []}
            order={order}
            hasProgressBar
          />
        </HStack>
        <Text className="order-card__information-responsible">{responsiblePerson.name}</Text>
      </HStack>
    </Box>
  );
};
