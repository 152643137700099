import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/types/redux/root-state';
import { WidgetType } from '@/features/widget/widget-type';
import { WidgetStateItem } from '@/features/widget/types/widget-state';

export const activeWidgetsSelector = (containerKey: string) =>
  createSelector(
    (state: RootState) => state.widgets,
    (widgets) =>
      Object.entries(widgets.activeWidgets).reduce((acc, [type, widget]) => {
        const widgetKey = type as any as keyof WidgetType;
        if (widget.open && widget.containerKey === containerKey) acc.push({ key: widgetKey, ...widget });
        return acc;
      }, [] as Array<WidgetStateItem & { key: keyof WidgetType }>)
  );
