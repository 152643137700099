import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useAppSelector } from '@/hooks/use-app-selector';
import { funnelPageSelectedLoadIDSelector } from '@/features/funnel/selectors/funnel-page-selected-load-id';
import { FunnelPaymentDocsFormValues, LoadPaymentNew } from '@/types/models/funnel';
import { Load } from '@/types/models/loads';
import { useDependentAttachments } from '@/features/attachments/hooks/use-dependent-attachments';
import { normalizePaymentDocFormValues } from '@/features/funnel/helpers/normalize-payment-doc-form-values';
import { DocumentTypes } from '@/config/constants/document-types';
import { AttachmentDeps } from '@/config/constants/types';
import { FunnelPaymentDocInitialValues } from '@/config/constants/funnel';
import { getPaymentUpdatedValues } from '@/features/funnel/helpers/get-payment-updated-values';
import { addSourceOrderToQuery, checkLoadCalculatedState } from '@/features/funnel/helpers';
import { useLoadsDataActions } from '@/features/loads/hooks';
import { useOrderDataActions } from '@/features/order/hooks/use-order-data-actions';
import { useFunnelActions } from './use-funnel-actions';
import { useFunnelDataActions } from './use-funnel-data-action';
import { QueryKeys } from '@/config/constants/query-keys';
import { useOrderUpdates } from '@/features/order/hooks/use-order-updates';
import { OrderCreateSource } from '@/types/api/order-partial';

export const useFunnelPaymentDocsForm = ({ load }: { load: Load }) => {
  const client = useQueryClient();

  const activeLoadID = useAppSelector(funnelPageSelectedLoadIDSelector);
  const currentLoadIsCalculated = checkLoadCalculatedState(load);

  const updatedValues = getPaymentUpdatedValues(load);
  const initialValues = currentLoadIsCalculated ? updatedValues : FunnelPaymentDocInitialValues;

  const {
    attachments: documents,
    deleteAttachmentById: deleteDocuments,
    postAttachments: postDocuments,
    downloadAttachments: downloadDocuments,
  } = useDependentAttachments({
    dependent: AttachmentDeps.Load,
    dependentId: activeLoadID,
    type: DocumentTypes.dlDocumentLoad,
  });

  const { updateOrder } = useFunnelDataActions(activeLoadID!);

  /** Запрашиваем повторно данные текущей заявки, но нужен запрос без update, так как с update: true, возвращается data: null
   * необходимо для того, чтобы разблокировать кнопку перехода на следующий статус
   */
  const { order: orderQ } = useOrderUpdates({ id: activeLoadID, fromSource: OrderCreateSource.ILSConfirmation, update: false });
  const { refetch: loadRefetchWithoutUpdate } = orderQ;

  const onUpdateLoad = (loadPaymentNew: LoadPaymentNew) => {
    updateOrder.mutate(addSourceOrderToQuery(loadPaymentNew), {
      onSuccess: () => {
        client.invalidateQueries([QueryKeys.allOrders]);
        loadRefetchWithoutUpdate();
        toast.success('Заявка успешно обновлена');
      },
    });
  };

  const onSubmit = (formValues: FunnelPaymentDocsFormValues) => {
    try {
      const normalizedValues = normalizePaymentDocFormValues(formValues);
      onUpdateLoad(normalizedValues);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    initialValues,
    isUpdating: false,
    documents,
    submitting: updateOrder.isLoading,
    deleteDocuments,
    postDocuments,
    downloadDocuments,
    onSubmit,
  };
};
