import { useQueryClient } from '@tanstack/react-query';

export const useVehicleInvalidate = () => {
  const client = useQueryClient();
  const allVehiclesInvalidate = (onSuccess?: VoidFunction) => {
    client.invalidateQueries(['carrierVehicles']).then(() => onSuccess?.());
  };

  return {
    allVehiclesInvalidate,
  };
};
