import React from 'react';
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, NumberInputProps, Text } from '@chakra-ui/react';
import { useField } from 'react-final-form';
import { UiNumberPicker } from '@/ui-elements';
import './ui-number-picker-field.scss';
import { onKeyPress } from '@/ui-elements/form-fields/ui-number-picker-field/helpers/get-press-value';

type Props = {
  label?: string | React.ReactNode;
  content?: string;
  required?: boolean;
  name: string;
  numbersBehindDecimal?: number;
  hideClearButton?: boolean;
  hideStepper?: boolean;
  showContent?: boolean;
  onKeyPress?: (e: React.KeyboardEvent) => false | RegExp;
  placeholder?: string;
};

export const UiNumberPickerField = ({
  name,
  label,
  required,
  content,
  width = '100%',
  onChange,
  showContent = true,
  isValidCharacter,
  ...props
}: Props & NumberInputProps) => {
  const { isDisabled } = props;
  const {
    input,
    meta: { error, touched },
  } = useField(name);
  const invalid = error && touched;

  return (
    <FormControl isRequired={required && !isDisabled} isInvalid={invalid}>
      <FormLabel mb={1} fontSize="xs" className="ui-field__label">
        <Text noOfLines={1}>{label}</Text>
      </FormLabel>
      <UiNumberPicker
        {...props}
        {...input}
        onHandleChange={onChange}
        isInvalid={invalid}
        onClear={() => input.onChange('')}
        width={width}
        className="ui-number-picker"
        onKeyPress={onKeyPress}
      />
      {showContent && (invalid ? <FormErrorMessage>{error}</FormErrorMessage> : <FormHelperText>{content}</FormHelperText>)}
    </FormControl>
  );
};
