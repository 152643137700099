export enum Routes {
  Analytics = 'Analytics',
  BookerClients = 'BookerClients',
  BookerOrders = 'BookerOrders',
  Carriers = 'Carriers',
  CarriersLoads = 'CarrierLoads',
  CarriersLoadsAuthorized = 'CarrierLoadsAuthorized',
  CarriersLoadsAuthorizedSimple = 'CarrierLoadsAuthorizedSimple',
  Clients = 'Clients',
  CRM = 'CRM',
  CurrentRoutes = 'CurrentRoutes',
  FuelCards = 'FuelCards',
  Funnel = 'Funnel',
  FutureRoutes = 'FutureRoutes',
  Loads = 'Loads',
  LogIn = 'LogIn',
  Map = 'Map',
  MyOrganization = 'MyOrganization',
  MyOrganizations = 'MyOrganizations',
  Orders = 'Orders',
  OrdersWithMyParticipation = 'OrdersWithMyParticipation',
  PublicOfferAgreement = 'PublicOfferAgreement',
  Register = 'Register',
  Resources = 'Resources',
  Security = 'Security',
  Settings = 'Settings',
  Statistics = 'Statistics',
  UnderConstruct = 'UnderConstruct',
  Verification = 'Verification',
  VerificationHistory = 'VerificationHistory',
  CompletedRoutes = 'CompletedRoutes',
  FutureRoutesSimple = 'FutureRoutesSimple',
  CompletedRoutesSimple = 'CompletedRoutesSimple',
  MyOrganizationSimple = 'MyOrganizationSimple',
  ResourcesSimple = 'ResourcesSimple',
}
