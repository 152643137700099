import { FC } from 'react';
import { isEmpty } from 'lodash';
import { Box, VStack } from '@chakra-ui/react';
import { Driver } from '@/types/models/driver';
import { InfiniteScroll } from '@/views/infinite-scroll/infinite-scroll';
import { FetchNextPageType } from '@/types/models/pager';
import { ResourcesDriverCard } from '@/components/resources/driver-card/resources-driver-card';
import { ResourcesEmptyComponent } from '@/components/resources/empty-component/resources-empty-component';
import { RESOURCES_NO_DRIVERS, ResourcesButtonActions } from '@/features/resources/constants/general';

interface Props {
  drivers: Array<Driver>;
  selectedDriverId: number | null;
  isFetchingNextPage: boolean;
  showApprovalStatuses: boolean;
  fetchNextPage: FetchNextPageType<Driver>;
  handleSelectDriver: (driverId: number) => void;
  handleCreateDriver: VoidFunction;
}

export const ResourcesDriversList: FC<Props> = ({
  drivers,
  selectedDriverId,
  isFetchingNextPage,
  showApprovalStatuses,
  fetchNextPage,
  handleSelectDriver,
  handleCreateDriver,
}) => {
  return (
    <Box className="resources-list-wrapper">
      {!isEmpty(drivers) ? (
        <InfiniteScroll callback={fetchNextPage} loading={isFetchingNextPage} skip={isEmpty(drivers)}>
          <VStack className="resources-list-content">
            {drivers?.map((driver) => (
              <ResourcesDriverCard
                driver={driver}
                selectedDriverId={selectedDriverId}
                showApprovalStatuses={showApprovalStatuses}
                handleSelectDriver={handleSelectDriver}
                key={`${driver.id}`}
              />
            ))}
          </VStack>
        </InfiniteScroll>
      ) : (
        <ResourcesEmptyComponent text={RESOURCES_NO_DRIVERS} buttonText={ResourcesButtonActions.AddDriver} onClick={handleCreateDriver} />
      )}
    </Box>
  );
};
