import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { UiDivider } from '@/ui-elements';
import { Carrier } from '@/types/models/carrier';
import './carrier-routes-and-payment-tab.scss';
import { Order } from '@/types/models/order';
import { RouteCard } from './route-card';
import { FOUND } from './texts';
import { InfiniteScroll } from '@/views/infinite-scroll/infinite-scroll';

type Props = {
  carrier: Carrier;
  routes?: Array<Order>;
  showTrip: (orderId: number) => void;
  loadMore: (args: any) => void;
  loading?: boolean;
};

export const CarrierRoutesAndPaymentTab = ({ carrier, routes, showTrip, loadMore, loading }: Props) => {
  return (
    <Box className="routes-payment-tab">
      <Text className="routes-payment-tab__title" textStyle="h2">
        Заявки выполненные <span className="routes-payment-tab__title-carrier">{carrier.name}</span> в текущем филиале
      </Text>
      <HStack>
        <Text className="routes-payment-tab__count">
          {FOUND} {routes?.length}
        </Text>
      </HStack>
      <VStack className="routes-payment-tab__routes-list">
        <InfiniteScroll callback={loadMore} loading={loading} skip={isEmpty(routes)}>
          {routes?.map((route, i) => (
            <Box className="routes-payment-tab__routes-list__route" key={route.id + +`${i}`}>
              <RouteCard route={route} onDetailsClick={() => showTrip(route.id)} />
              <UiDivider pt="25px" variant="dark" />
            </Box>
          ))}
        </InfiniteScroll>
      </VStack>
    </Box>
  );
};
