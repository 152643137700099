import { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useIcons } from '@/hooks/use-icons';
import { Review } from '@/types/models/review';
import { formatMomentLocal } from '@/helpers/moment/format-moment-local';
import { MOMENT_L, MOMENT_LT } from '@/helpers/moment/constants';

type Props = {
  review: Review;
};
export const ComponentReview: FC<Props> = ({ review }) => {
  const { Messenger } = useIcons();

  const date = formatMomentLocal(review.created, MOMENT_L);
  const time = formatMomentLocal(review.created, MOMENT_LT);

  return (
    <Box>
      <Flex gap="10px">
        <Messenger />
        <Text className="carrier-reviews__review-header">
          {review.creator.name} {date} {time}
        </Text>
      </Flex>
      <Text className="carrier-reviews__review-body">{review.value}</Text>
    </Box>
  );
};
