import { FC } from 'react';
import { GridItem, HStack, Text } from '@chakra-ui/react';
import { UiInputField } from '@/ui-elements';

type Props = {
  isDisabled: boolean;
};

export const DriverNameSubform: FC<Props> = ({ isDisabled }) => {
  return (
    <>
      <GridItem colSpan={2}>
        <Text className="carriers-verification-form__subtitle" fontSize={isDisabled ? 16 : 18}>
          Данные водителя
        </Text>
      </GridItem>
      <HStack mt={2}>
        <UiInputField name="surname" label="Фамилия" required isDisabled={isDisabled} />
        <UiInputField name="name" label="Имя" required isDisabled={isDisabled} />
        <UiInputField name="patronymic" label="Отчество" placeholder="Отчество" isDisabled={isDisabled} />
      </HStack>
    </>
  );
};
