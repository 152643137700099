import { useState } from 'react';
import { useInfiniteApprovalsUpdates } from '@/features/approvals/hooks/use-approvals-infinite-updates';
import { useMetricsUpdates } from '@/features/approvals/hooks/use-metrics-updates';
import { useDebounce } from '@/hooks/use-debounce';
import { ApproveStatus } from '@/config/constants/statuses';

export const useCarriersVerificationView = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [value, setValue] = useState('');
  const debouncedQuery = useDebounce(value);
  const onChangeActiveTab = (index: number) => setActiveTab(index);
  const { data, isFetchingNextPage, fetchNextPage, isFetching } = useInfiniteApprovalsUpdates({
    search: debouncedQuery,
    statuses: Object.values(ApproveStatus)[activeTab],
  });
  const allRecords = data?.pages ? data.pages.flatMap((d) => d.data) : [];
  const { metrics } = useMetricsUpdates();
  return { value, setValue, activeTab, onChangeActiveTab, isFetchingNextPage, fetchNextPage, isFetching, allRecords, metrics };
};
