import { Box, Heading } from '@chakra-ui/react';
import { DateFormat } from 'config/constants/date';
import { PASSPORT_MASK } from '@/components/auth-form/constants';
import { ResourcesFormFieldNames, ResourcesFormLabels, ResourcesFormPlaceholders } from '@/features/resources/constants/form';
import { UiCheckboxField, UiInputField, UiMaskedField, UiMultiDatePickerField } from '@/ui-elements';

export const ResourcesDriverSubformPassport = () => {
  return (
    <Box>
      <Heading as="h3" className="resources-form-subtitle">
        Паспортные данные
      </Heading>
      <UiCheckboxField
        name={ResourcesFormFieldNames.russianPassport}
        label={ResourcesFormLabels.russianPassport}
        className="resources-form-checkbox"
      />
      <Box className="resources-form-row">
        <UiMaskedField name={ResourcesFormFieldNames.passport} label={ResourcesFormLabels.passport} required mask={PASSPORT_MASK} />
        <UiMultiDatePickerField
          name={ResourcesFormFieldNames.passportDate}
          label={ResourcesFormLabels.passportDate}
          placeholder={ResourcesFormPlaceholders.passportDate}
          format={DateFormat.DDMMYYYYSlash}
          required
        />
        <UiMultiDatePickerField
          name={ResourcesFormFieldNames.birthday}
          label={ResourcesFormLabels.birthday}
          placeholder={ResourcesFormPlaceholders.birthday}
          format={DateFormat.DDMMYYYYSlash}
          required
        />
      </Box>
      <UiInputField name={ResourcesFormFieldNames.passportGiven} label={ResourcesFormLabels.passportGiven} required />
      <Box className="resources-form-row">
        <UiCheckboxField name={ResourcesFormFieldNames.convictions} label={ResourcesFormLabels.convictions} />
        <UiCheckboxField name={ResourcesFormFieldNames.loans} label={ResourcesFormLabels.loans} />
        <UiCheckboxField name={ResourcesFormFieldNames.accidents} label={ResourcesFormLabels.accidents} />
      </Box>
    </Box>
  );
};
