import { declensionNouns } from '@/helpers/declension-of-nouns';
import { findNestedVehicleTypeById } from '@/helpers/get-nested-object-by-id';
import { CarrierFilter } from '@/types/api/carrier-partial';
import { VehicleResponseType } from '@/types/models/vehicle-response';
import { carrierStatuses } from '../search-carrier/search-carrier-filter/constants';

export const getShortenedFilter = (filters: CarrierFilter, vehicleTypes: VehicleResponseType[]) => {
  let shortenedFilters = [];
  if (filters.from) shortenedFilters.push(filters.from);
  if (filters.capacityFrom)
    shortenedFilters.push(`${filters.capacityFrom} ${declensionNouns(Number(filters.capacityFrom), ['тонна', 'тонны', 'тонн'])}`);
  if (filters.volumeFrom) shortenedFilters.push(`${filters.volumeFrom} м³`);
  if (filters.vehicleTypes?.length) {
    shortenedFilters.push(
      filters.vehicleTypes
        .split(',')
        .map((vehicleType) => {
          return findNestedVehicleTypeById(vehicleTypes, vehicleType);
        })
        .map((vehicleType) => vehicleType?.name)
        .join(', ')
    );
  }
  if (filters.onlyAvailable) shortenedFilters.push('Только свободные');
  if (filters.riskGroup) shortenedFilters.push(`${filters.riskGroup} группа риска`);
  if (filters.radiusSearch) shortenedFilters.push(`${filters.radiusSearch} км радиус поиска`);
  if (filters.status) shortenedFilters.push(carrierStatuses.find((status) => status.value === filters.status)?.label);

  if (shortenedFilters.length > 5) {
    const restFiltersCount = shortenedFilters.length - 5;
    const restFiltersText = `и еще ${restFiltersCount} ${declensionNouns(Number(restFiltersCount), ['фильтр', 'фильтра', 'фильтров'])}`;
    shortenedFilters = [...shortenedFilters.slice(0, 5), restFiltersText];
  }

  return { shortenedFilters };
};
