import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { useState, SyntheticEvent } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { FORBIDDEN_EDIT_STATUSES, VerificationStatus } from '@/config/constants/statuses';
import { checkResourceStatus } from '@/helpers/check-resources-status';

export const useMyOrganizationForm = ({ approvalStatuses }: { approvalStatuses?: Array<VerificationStatus> }) => {
  const [isEditStatus, setEditStatus] = useState(false);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const onToggleEditForm = (e?: SyntheticEvent) => {
    e?.preventDefault();
    setEditStatus((prev) => {
      if (!isEmpty(approvalStatuses)) {
        return !prev;
      }
      return prev;
    });
    onClose();
  };

  const setEditForm = (e: SyntheticEvent) => {
    // если среди статусов есть "На проверке", запрещаем редактирование
    const organizationIsBeingChecked = checkResourceStatus({
      approvalStatuses,
      checkStatuses: FORBIDDEN_EDIT_STATUSES,
    });
    if (organizationIsBeingChecked) {
      toast.error('Вы не можете редактировать организацию на проверке, пожалуйста, подождите, пока организация не будет проверена');
    } else {
      e.preventDefault();
      onOpen();
    }
  };

  const formEditConfirmProps = {
    isOpen,
    onClose,
    onConfirm: onToggleEditForm,
    title: 'Перейти в режим редактирования?',
    description: 'Сохраняя внесенные изменения вы сбросите статус проверки организации. Продолжить?',
  };

  return { onToggleEditForm, isEditStatus, setEditForm, formEditConfirmProps };
};
