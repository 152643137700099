import { DriverVerificationForm, DriverVerificationRequest } from '@/types/models/driver-verification';
import { formatDateObjectToString } from '@/helpers/date/format-date-object-to-string';

export const formValuesToDriver = (values: DriverVerificationForm, passportForm: boolean): DriverVerificationRequest => {
  return {
    country: values.country,
    name: values.name,
    phoneNumber: values.phoneNumber || '',
    surname: values.surname,
    patronymic: values.patronymic,
    russianPassport: passportForm,
    foreignPassport: values.foreignPassport,
    passport: values.passport,
    passportDate: formatDateObjectToString(values.passportDate),
    birthday: formatDateObjectToString(values.birthday),
    passportGiven: values.passportGiven,
    selfAssignment: values.selfAssignment,
    isDocumentsUploaded: values.isDocumentsUploaded,
    carrierId: values?.carrierId?.value,
    driverLicense: values.driverLicense,
    driverLicenseDate: formatDateObjectToString(values.driverLicenseDate),
  };
};
