import { FC } from 'react';
import { IS_OWN_ASSIGNED } from 'components/driver-own-assigned-mark/texts';
import classNames from 'classnames';
import { useIcons } from '@/hooks/use-icons';
import './dirver-own-assigned-mark.scss';

/**
 * Отметка о том, что водитель с правом самоназначения. (маленькая зеелная галка и текст)
 * */
export const DriverOwnAssignedMark: FC<{ isOwnAssigned: boolean; className?: string }> = ({ isOwnAssigned, className }) => {
  const { Success } = useIcons();
  if (isOwnAssigned) {
    return (
      <div className={classNames('driver-own-assigned-mark', className)}>
        <Success />
        <span>{IS_OWN_ASSIGNED}</span>
      </div>
    );
  }
  return null;
};
