import { FC } from 'react';
import { Center, DividerProps } from '@chakra-ui/react';
import { UiDivider } from '@/ui-elements';

type Props = DividerProps & {};

export const UiDividerCentered: FC<Props> = (props) => {
  return (
    <Center className="ui-divider ui-divider__container ui-divider__container_centered">
      <UiDivider {...props} />
    </Center>
  );
};
