export const colors = {
  typography: {
    50: '#232735',
    100: '#505565',
    150: '#8B90A0',
    200: '#9C9C9C',
    250: '#FFFFFF',
  },
  neutrals: {
    50: '#231F20',
    100: '#4E4E5A',
    150: '#727279',
    200: '#A1A4B1',
    250: '#D3D4D8',
    300: '#FAFAFC',
    350: '#FFFFFF',
    400: '#949494',
    450: '#BDBDBD',
    500: '#F0F1F3',
  },
  accentGreen: {
    50: '#186E3C',
    100: '#27AE60',
    150: '#12C85F',
    200: '#1EDB6E',
  },
  accentMint: {
    50: '#09BA95',
    100: '#BBF1D2',
  },
  accentLime: {
    50: '#D5FE61',
  },
  accentRed: {
    50: '#9E2B2B',
    100: '#EB5757',
    150: '#F93737',
    200: '#FF6868',
    250: '#FFBCBC',
    300: '#FFEDED',
  },
  accentYellow: {
    50: '#F2994A',
    100: '#F4CD51',
    150: '#FAF546',
    200: '#FFE7AA',
  },
  products: {
    50: '#01306F',
    100: '#0049AD',
    150: '#005AD3',
    200: '#2F80ED',
    250: '#68A9FF',
    300: '#A7CAF7',
    350: '#C7DBF3',
    400: '#F0F6FE',
    450: '#F6FAFF',
  },
};
