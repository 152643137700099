export const getFoundLoadsTitle = (allFoundValue: number = 0) => {
  const foundValueString = allFoundValue.toString();
  switch (foundValueString.at(-1)) {
    case '1':
      return `Найден ${foundValueString} груз`;
    case '2':
    case '3':
    case '4':
      return `Найдено ${foundValueString} груза`;
    default:
      return `Найдено ${foundValueString} грузов`;
  }
};
