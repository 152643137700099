import { debounce, first, isEmpty } from 'lodash';
import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { DateObject } from 'react-multi-date-picker';
import { useNavigate } from 'react-router-dom';
import { OrderSwitcherStatus } from '@/config/constants/statuses';
import { SortType } from '@/config/constants/types';
import { useCarriersLoadsPage } from '@/features/carriers-loads/hooks/use-carriers-loads-page';
import { activeCarriersLoadsSelector } from '@/features/carriers-loads/selectors';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useGetQueryId } from '@/hooks/use-get-query-id';
import { useLocationTyped } from '@/hooks/use-location-typed';
import { useCarrierLoadActions } from '@/features/carriers-loads/hooks/use-carriers-loads-actions';
import { accountSelector, authenticatedSelector } from '@/features/account/selectors';
import { routesPath } from '@/config/routes/routes-path';
import { Routes } from '@/config/routes/routes';
import { CarriersLoadsFilterValues } from '@/types/models/carriers-loads';
import { Order } from '@/types/models/order';
import { useCreateOfferModal } from './create-offer/use-create-offer-modal';
import { useOrganizations } from '@/features/my-organizations/hooks/use-all-organizations';
import { NO_ORGANIZATION_REQEST_BUTTON_PROPS } from './constants';
import { flatMapDataPages } from '@/helpers/api/flat-map-data-pages';
import { useAccount } from '@/features/account/hooks/use-account';
import { useAllParticipatableOrders } from '@/features/carriers-loads/hooks/use-participatable-carriers-loads';

export const UseCarriersLoadsList = () => {
  const queryOrderId = useGetQueryId();
  // Проверяем авторизован ли пользователь
  const authenticated = useAppSelector(authenticatedSelector);
  const { user } = useAppSelector(accountSelector);
  const carrierId = user?.accountId;
  const { isCarrierSimple } = useAccount();

  const { allOrganizations } = useOrganizations({ enabled: authenticated });
  // У перевозчика должна быть только одна организация
  const organization = first(allOrganizations.data?.data);
  // Использует ли перевозчик оплату по НДС
  const organizationVat = organization?.vatType;

  const [filters, setFilters] = useState<CarriersLoadsFilterValues>({
    status: OrderSwitcherStatus.CarrierSearch,
    dateFrom: '',
    dateTo: '',
    from: '',
    singleLoading: false,
    to: '',
    vehicleTypes: [],
  });

  const { select } = useCarrierLoadActions();
  const navigate = useNavigate();

  const [rangeValue, setRangeValue] = useState<Array<DateObject>>([]);
  const location = useLocationTyped();

  const isRedirecting = Boolean(location?.state?.redirectId);

  useEffect(() => {
    const redirectId = location.state?.redirectId;
    if (redirectId && authenticated) {
      select(redirectId);
      navigate(`${location.pathname}?id=${redirectId}`, { state: { redirectId: null } });
    }
  }, [location.pathname, location.state, navigate, select, authenticated]);

  const handleSetRangeValue = (range: DateObject[]) => {
    setRangeValue(range);
  };

  useEffect(() => {
    if (queryOrderId) {
      select(Number(queryOrderId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select]);

  // модальное окно для отклика на заявку
  const { ConfirmCreateOfferModal, handleCreateOffer } = useCreateOfferModal({ organizationVat, isCarrierSimple, carrierId, authenticated });

  const { data, fetchNextPage, isFetchingNextPage, isInitialLoading } = useAllParticipatableOrders(
    { query: filters },
    {},
    // authenticated
    false
  );

  const allRecords: Array<Order> = flatMapDataPages(data);

  const activeOrderId = useAppSelector(activeCarriersLoadsSelector);

  useCarriersLoadsPage(allRecords, activeOrderId, location?.state?.redirectId, authenticated);

  const handleFilterChange = debounce((values: Partial<CarriersLoadsFilterValues>) => {
    setFilters(() => {
      return {
        dateFrom: values.dateFrom || '',
        dateTo: values.dateTo || '',
        from: values.from || '',
        singleLoading: values.singleLoading || false,
        to: values.to || '',
        vehicleTypes: values.vehicleTypes || [],
        status: OrderSwitcherStatus.CarrierSearch,
      };
    });
  }, 800);

  const setActiveCarrierLoad = (id: number, url: string) => {
    select(id);
    navigate(url);
  };

  // TODO location id еще не используется
  const handleSelect = (id: number) => {
    if (authenticated) {
      const url = qs.stringifyUrl({
        url: location.pathname,
        query: { id },
      });
      setActiveCarrierLoad(id, url);
    }
  };

  // TODO еще не используется
  const handleSetSorting = useCallback(
    (sortBy: string, sortType: SortType) =>
      setFilters((prev) => ({
        ...prev,
        sortBy,
        sortType,
      })),
    []
  );
  // для кнопки откликнуться на заявку
  const handleRequestOrder = useCallback(
    (order: Order) => {
      if (authenticated) {
        const url = qs.stringifyUrl({
          url: `/${routesPath[Routes.CarriersLoadsAuthorized]}`,
          query: { id: order.id },
        });
        setActiveCarrierLoad(order.id, url);
        handleCreateOffer(order);
      } else {
        const url = qs.stringifyUrl({
          url: `/${routesPath[Routes.Register]}`,
        });
        navigate(url);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [authenticated]
  );

  // Если у авторизованного нет организации, то выключаем кнопку отклика
  const requestButtonProps = authenticated && isEmpty(organization) ? NO_ORGANIZATION_REQEST_BUTTON_PROPS : undefined;

  return {
    activeOrderId,
    handleFilterChange,
    handleSetSorting,
    handleSetRangeValue,
    rangeValue,
    isRedirecting,
    isInitialLoading,
    allRecords,
    handleRequestOrder,
    handleSelect,
    fetchNextPage,
    isFetchingNextPage,
    requestButtonProps,
    ConfirmCreateOfferModal,
  };
};
