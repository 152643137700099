import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import edit from 'public/assets/icons/EditIcon.svg';
import { FC } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@/config/constants/query-keys';
import { useIcons } from '@/hooks/use-icons';
import { UiButtonOutlined } from '@/ui-elements';
import { MyOrganizationType, MyOrganizationsFormValuesType } from '@/types/models/my-organization/my-organization';
import { ModalAction, useModal } from '@/hooks/use-modal';
import { ModalType } from '@/types/common/modals';
import { useOrganizationActions } from '@/features/my-organizations/hooks/use-organization-actions';
import { useMyOrganizationsSelect } from '@/features/my-organizations/hooks/use-my-organizations-select';
import { useDependentAttachments } from '@/features/attachments/hooks/use-dependent-attachments';
import { AttachmentDeps } from '@/config/constants/types';
import { DocumentTypes } from '@/config/constants/document-types';
import { FileDocument } from '@/types/models/document';
import { useGetMyOrganizationTypes } from '@/features/my-organization/use-get-organization-types';
import { myOrganizationsToFormValues } from '@/features/my-organizations/helpers/my-organizations-to-form-values';
import { myOrganizationsDataToFormValues } from '@/views/my-organizations/helpers/my-organizations-form-values-to-data';

type Props = {
  data: MyOrganizationType;
  hasEditRight?: boolean;
};

export const MyOrganizationsCard: FC<Props> = ({ data, hasEditRight }) => {
  const { HelpIcon, DeleteIcon } = useIcons();
  const queryClient = useQueryClient();
  const { organizationTypes } = useGetMyOrganizationTypes();
  const { deleteOrganization, updateOrganization } = useOrganizationActions(data?.id);
  const { unselect } = useMyOrganizationsSelect();
  const { modalView: confirmDeleteModal, setModalOpened: setConfirmDeleteModalOpened } = useModal(ModalType.Confirm, {
    onSuccess: () => deleteOrganization.mutate(data.id, { onSuccess: () => unselect() }),
    modalWidth: 524,
    text: `Вы действительно хотите удалить организацию?`,
    cancelButtonText: 'Отменить',
    submitButtonText: 'Удалить',
    accentXBtn: true,
  });
  const {
    attachments: attachmentsStampDoc,
    deleteAttachmentById: deleteStampDoc,
    postAttachments: postStampDoc,
    downloadAttachments: downloadStampDoc,
  } = useDependentAttachments({
    dependent: AttachmentDeps.Organization,
    dependentId: data.id,
    type: DocumentTypes.dlStamp,
  });
  const {
    attachments: attachmentsSignatureDoc,
    deleteAttachmentById: deleteSignatureDoc,
    postAttachments: postSignatureDoc,
    downloadAttachments: downloadSignatureDoc,
  } = useDependentAttachments({
    dependent: AttachmentDeps.Organization,
    dependentId: data.id,
    type: DocumentTypes.dlSignature,
  });

  const { modalView: editOrganizationModal, setModalOpened } = useModal<
    ModalAction & {
      mode: 'edit';
      initialValues: MyOrganizationsFormValuesType;
      attachmentsStampDoc?: Array<FileDocument>;
      deleteStampDoc?: (documentId: number) => void;
      postStampDoc?: (files: Array<File>) => void;
      downloadStampDoc?: VoidFunction;
      attachmentsSignatureDoc?: Array<FileDocument>;
      deleteSignatureDoc?: (documentId: number) => void;
      postSignatureDoc?: (files: Array<File>) => void;
      downloadSignatureDoc?: VoidFunction;
      submitting?: boolean;
    }
  >(ModalType.OrganizationModal, {
    onSubmit: (values: any) => {
      updateOrganization.mutate(myOrganizationsDataToFormValues(values), {
        onSuccess: () => {
          toast.success('Организация успешно изменена');
          queryClient.invalidateQueries([QueryKeys.allOrganizations]);
          setModalOpened(false);
        },
      });
    },
    mode: 'edit',
    initialValues: myOrganizationsToFormValues({ organization: data, organizationTypes }),
    attachmentsStampDoc,
    deleteStampDoc,
    postStampDoc,
    downloadStampDoc,
    attachmentsSignatureDoc,
    deleteSignatureDoc,
    postSignatureDoc,
    downloadSignatureDoc,
    submitting: updateOrganization.isLoading,
  });

  if (!data) return null;

  return (
    <>
      <HStack>
        <VStack m={0} align="start" flex={1}>
          <Text textStyle="h2">{data.name}</Text>
          <Text textStyle="h5" color="typography.50" pt={1}>
            ИНН: {data.INN}
          </Text>
          {hasEditRight && (
            <Flex gap="6px">
              <UiButtonOutlined mode="dark" leftIcon={edit} onClick={() => setModalOpened(true)}>
                Редактировать данные
              </UiButtonOutlined>
              <Box className="carrier-organization-card__help">
                <HelpIcon />
              </Box>
              <UiButtonOutlined mode="dark" leftIcon={DeleteIcon} onClick={() => setConfirmDeleteModalOpened(true)}>
                Удалить организацию
              </UiButtonOutlined>
            </Flex>
          )}
        </VStack>
      </HStack>
      {confirmDeleteModal}
      {editOrganizationModal}
    </>
  );
};
