import './ui-address-search.scss';
import { FC, memo } from 'react';
import { ReactSearchAutocompleteProps } from 'react-search-autocomplete/dist/components/ReactSearchAutocomplete';
import classNames from 'classnames';
import { useAddressItems } from '@/ui-elements/inputs/ui-address-search/hooks/use-address-items';
import { AddressAutoCompleteOption } from '@/helpers/api/normalize-address-auto-complete-response';
import { UiSearchAutocomplete } from '@/ui-elements/inputs/ui-search-autocomplete/ui-search-autocomplete';
import { ADDRESS_SEARCH_DEFAULT_PROPS } from '@/ui-elements/inputs/ui-address-search/constants';
import { UiSelect } from '@/ui-elements';
import { UiText } from '@/ui-elements/ui-text/ui-text';

type Props = Omit<ReactSearchAutocompleteProps<AddressAutoCompleteOption>, 'items' | 'styling' | 'onSearch'> & {
  isDisabled?: boolean;
  threshold?: number;
  isInvalid?: boolean;
};

export const UiAddressSearch: FC<Props> = memo(({ threshold = 2, isInvalid, className, isDisabled = false, ...props }: Props) => {
  const { handleOnSearch, items } = useAddressItems({ threshold });
  const classNameFull = classNames(ADDRESS_SEARCH_DEFAULT_PROPS.className, className, { invalid: isInvalid });

  if (isDisabled) {
    return <UiSelect {...props} isDisabled className={classNameFull} options={[]} onSelect={undefined} />;
  }

  return (
    <>
      <UiSearchAutocomplete className={classNameFull} items={items} onSearch={handleOnSearch} {...props} />
      {isInvalid && <UiText color="accentRed.100">Адрес необходимо выбрать из предлагаемого списка</UiText>}
    </>
  );
});
