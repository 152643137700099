import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { PointStatus } from 'components/order-point/options';
import { useIcons } from '@/hooks/use-icons';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { pointExtender } from '@/features/order/extender/order-point-extender';
import { ModalAction, useModal } from '@/hooks/use-modal';
import { ModalType } from '@/types/common/modals';
import { useDependentAttachments } from '@/features/attachments/hooks/use-dependent-attachments';
import { AttachmentDeps } from '@/config/constants/types';
import { DocumentTypes } from '@/config/constants/document-types';
import { useDocumentsStatus } from '@/features/waypoint/hooks/use-documents-status';
import { useSetPointComment } from '@/features/waypoint/hooks/use-set-point-comment';
import { Loading } from '@/types/models/loading';
import { Order } from '@/types/models/order';
import { Point, PointStatusesTransition } from '@/types/models/point';
import { useSetPointStatus } from '@/features/waypoint/hooks/use-point-status';
import { FileDocument } from '@/types/models/document';
import { Load } from '@/types/models/loads';

type ModalProps = {
  documents?: Array<FileDocument>;
  downloadDocument: VoidFunction;
  deleteDocument: (arg0: number) => void;
  onAcceptDocuments: VoidFunction;
  onRejectDocuments: VoidFunction;
};

export const useOrderPointWithIcon = (
  order: Order | Load,
  point: Point,
  cargoTypes: {
    value: string;
    label: string;
  }[]
) => {
  const { id: orderId, status: orderStatus } = order;
  const { setPointStatus } = useSetPointStatus({ orderId, pointId: point?.id });
  const { setPointDocumentsStatus } = useDocumentsStatus({ orderId, pointId: point?.id });
  const extendedPoint = useExtendedObject({ point, cargos: order.cargos, action: order.actions } as Loading, pointExtender);
  const { LoadIcon, UnloadIcon, LongArrowIcon, DocumentsAccepted, DocumentsRejected } = useIcons();
  const {
    deleteAttachmentById: deleteDocument,
    attachments,
    downloadAttachments,
    postAttachments,
  } = useDependentAttachments({
    dependent: AttachmentDeps.Point,
    dependentId: point?.id,
    type: DocumentTypes.dlDocumentPoint,
  });
  const queryClient = useQueryClient();
  const { modalView: ModalDocuments, setModalOpened: showEvaluateDocuments } = useModal<ModalProps & ModalAction>(ModalType.Documents, {
    documents: attachments,
    downloadDocument: downloadAttachments,
    deleteDocument,
    onAcceptDocuments: () => {
      setPointDocumentsStatus.mutate('approved');
      setPointStatus.mutate(PointStatus.Completed, {
        onSuccess: () => {
          showEvaluateDocuments(false);
          queryClient.invalidateQueries(['order', order.id]);
          toast.success('Статус точки успешно изменен');
        },
      });
    },
    onRejectDocuments: () => {
      setPointStatus.mutate(PointStatus.Waiting);
      setPointDocumentsStatus.mutate('declined', {
        onSuccess: () => {
          showEvaluateDocuments(false);
          queryClient.invalidateQueries(['order', order.id]);
        },
      });
    },
  });
  const { setPointComment } = useSetPointComment(orderId, point?.id);
  const { modalView: UploadDocuments, setModalOpened: showUploadDocuments } = useModal<{ postAttachments: (files: File[]) => void } & ModalAction>(
    ModalType.Upload,
    {
      postAttachments,
    }
  );
  const { modalView: PostComments, setModalOpened: showPostComments } = useModal<
    { sendComments: (comment: string, callback?: Function) => void; comment?: any; orderId: number; pointId: number } & ModalAction
  >(ModalType.Comments, {
    sendComments: (comment, callback) => {
      setPointComment.mutate(comment, {
        onSuccess: () => callback?.(),
      });
    },
    orderId,
    pointId: point?.id,
  });
  const onEvaluateDocuments = () => showEvaluateDocuments(true);
  const onUploadDocuments = () => showUploadDocuments(true);
  const onPostComments = () => showPostComments(true);
  const setStatus = ({ value }: { value: PointStatusesTransition }) => setPointStatus.mutate(value);

  // const { currentRole } = useAccount();
  let statusIcon;
  switch (point.documentStatus) {
    case 'approved':
      statusIcon = <DocumentsAccepted />;
      break;
    case 'declined':
      statusIcon = <DocumentsRejected />;
      break;
    default:
      statusIcon = null;
  }
  return {
    extendedPoint,
    UnloadIcon,
    LoadIcon,
    LongArrowIcon,
    orderStatus,
    setStatus,
    cargoTypes,
    onPostComments,
    onEvaluateDocuments,
    statusIcon,
    onUploadDocuments,
    ModalDocuments,
    UploadDocuments,
    PostComments,
  };
};
