import { OrderExecutionStatus, OrderStatus, PaymentOrderStatus } from 'config/constants/statuses';
import { FUEL_BENEFIT_COEFFICIENT, MAX_PREPAYMENT_COEFFICIENT, PREPAYMENT_TAX_COEFFICIENT } from 'config/prepayment-constans';
import _ from 'lodash';
import { ModelExtender } from '@/types/model-extender';
import { Order } from '@/types/models/order';
import { OrderExtended } from '@/features/order/types/order-extended';
import { userSimpleExtender } from '@/features/user-simple/extender/user-simple-extender';
import { ACCEPTABLE_PRICE, BY_PRICE, cancelCaptionTexts, orderActions } from '@/features/order/texts';
import { formatMomentLocal } from '@/helpers/moment/format-moment-local';
import { MOMENT_L } from '@/helpers/moment/constants';
import { toPriceFormat } from '@/helpers/price/to-price-format';
import { OrderMode } from '@/types/common/mode';
import { getPaymentStep } from '@/features/order/helpers/get-payment-step';
import { getPriceForCarrier } from '@/features/order/helpers/get-price-for-carrier';
import { checkHasVat } from '@/helpers/price/check-has-vat';
import { Load } from '@/types/models/loads';

export const ORDER_STATUS_NEW_AND_SEARCH: Array<OrderStatus> = [OrderExecutionStatus.New, OrderExecutionStatus.CarrierSearch];
export const ORDER_CANCABLE_STATUSES: Array<OrderStatus> = [OrderExecutionStatus.Preparation, OrderExecutionStatus.CarrierSearch];
export const ORDER_STATUSES_COMPLITED: Array<OrderStatus> = [
  OrderExecutionStatus.CompletedPayed,
  OrderExecutionStatus.CompletedWithoutDocs,
  OrderExecutionStatus.CompletedWithDocs,
];

export const orderExtender: ModelExtender<Order | Load, OrderExtended> = {
  desiredRiskNumber: (order) => order?.riskGroup,
  responsibleShort: (order) => userSimpleExtender.shortName(order?.responsiblePerson),
  prepaymentValue: (order) => {
    if (!order?.prepayment?.prepayment) return undefined;
    return toPriceFormat(order?.prepayment?.prepayment);
  },
  createdFormatted: (order) => formatMomentLocal(order?.created, MOMENT_L),
  expirationFormatted: (order) => formatMomentLocal(order?.expiration, MOMENT_L),
  priceCaption: (order) => (ORDER_STATUS_NEW_AND_SEARCH.includes(order?.status) ? ACCEPTABLE_PRICE : BY_PRICE),
  paymentOrderStatus: (order) => {
    if (!order?.carrierPayment?.paid && ORDER_STATUS_NEW_AND_SEARCH.includes(order?.status)) return PaymentOrderStatus.None;
    if (!order?.carrierPayment?.paid && !ORDER_STATUS_NEW_AND_SEARCH.includes(order?.status)) return PaymentOrderStatus.Ready;
    if (order?.carrierPayment?.paid) return PaymentOrderStatus.Paid;
    return PaymentOrderStatus.None;
  },
  getFormattedPrice: (order) => {
    return (mode: OrderMode) => {
      return mode === 'carrier'
        ? toPriceFormat(checkHasVat(order?.carrierPayment?.vatType) ? order?.carrierPayment?.priceWithVAT : order?.carrierPayment?.priceWithoutVAT)
        : toPriceFormat(order?.customerPayment?.price);
    };
  },
  getIsCancelable: (order) => {
    return () => ORDER_CANCABLE_STATUSES.includes(order?.status);
  },

  getCancelCaption: (order) => {
    return () => cancelCaptionTexts[order?.status] || orderActions.Cancel;
  },
  getMaxPrepayment: (order) => {
    return (vat: boolean, fuel: number) => {
      if (vat) {
        return (order?.carrierPayment?.priceWithVAT || 0) * MAX_PREPAYMENT_COEFFICIENT - fuel;
      }
      return (order?.carrierPayment?.priceWithoutVAT || 0) * MAX_PREPAYMENT_COEFFICIENT - fuel;
    };
  },
  getPaymentStep,
  getPrepaymentTax: () => {
    return (prepayment: number) => {
      return _.round(prepayment * PREPAYMENT_TAX_COEFFICIENT, 2);
    };
  },
  getFuelBenefit: () => {
    return (fuel: number) => {
      return _.round(fuel * FUEL_BENEFIT_COEFFICIENT, 2);
    };
  },
  getMaxFuelPrepayment: (order) => {
    return (vat: boolean, prepayment: number) => {
      if (vat) {
        return (order?.carrierPayment?.priceWithVAT || 0) * MAX_PREPAYMENT_COEFFICIENT - prepayment;
      }
      return (order?.carrierPayment?.priceWithoutVAT || 0) * MAX_PREPAYMENT_COEFFICIENT - prepayment;
    };
  },
  getPaymentRest: (order) => {
    return (prepayment: number, fuel: number, tax: number, benefit: number, vat: boolean) => {
      const payed = prepayment + fuel + tax - benefit;
      if (vat) {
        return (order?.carrierPayment?.priceWithVAT || 0) - payed;
      }
      return (order?.carrierPayment?.priceWithoutVAT || 0) - payed;
    };
  },
  getPriceForCarrier,
  isCompleted: (order) => ORDER_STATUSES_COMPLITED.includes(order?.status),
};
