import { useEffect, useState } from 'react';
import { OrderList } from '@/components/order-list/order-list';
import { useOrderActions } from '@/features/order/hooks/use-order-actions';
import { activeOrderIdsSelector } from '@/features/order/selectors';
import { useAppSelector } from '@/hooks/use-app-selector';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';
import './fuel-list-view.scss';
import { OrderFuelFilter } from '@/components/order-fuel-filter/order-fuel-filter';
import { FuelStatus } from '@/config/constants/statuses';
import { getFuelLabels } from '@/components/order-fuel-filter/switcher-labels';
import { initialCounts, responseToFuelCount } from '@/features/order/helpers/response-to-fuel-count';
import { orderStatusesSelector } from '@/features/order/selectors/order-statuses-selector';
import { useAllOrdersUpdates } from '@/features/order/hooks/use-all-orders-updates';
import { useOrdersFuelCountUpdates } from '@/features/order/hooks/use-orders-fuel-count-updates';

export const FuelListView = () => {
  const [activeTab, setActiveTab] = useState<string>(FuelStatus.ConfirmedWithoutFuel);
  const { data, fetchNextPage, isFetchingNextPage, isInitialLoading } = useAllOrdersUpdates({
    query: {
      fuelStatus: activeTab,
    },
  });
  const { ordersFuelCount: ordersFuelCountResp } = useOrdersFuelCountUpdates();
  const ordersFuelCount = ordersFuelCountResp.data;
  const ordersFuelCounts = ordersFuelCount?.data ? responseToFuelCount(ordersFuelCount.data) : initialCounts;

  const allRecords = data?.pages ? data.pages.flatMap((d) => d.data) : [];
  const activeOrderIds = useAppSelector(activeOrderIdsSelector);
  const { select, unselect, clearState } = useOrderActions();
  const orderStatuses = useAppSelector(orderStatusesSelector);
  const fuelStatuses = orderStatuses?.fuelStatuses;
  const labelsFuel = getFuelLabels(ordersFuelCounts, fuelStatuses);

  const handleSelect = (id: number) => {
    select(id);
  };

  const handleSwicherChange = (activeKey: string) => {
    setActiveTab(activeKey);
  };

  useEffect(
    () =>
      // при переключении на другую страницу сбрасывает стор с настройками страницы с топливными заказами
      () =>
        clearState(),
    [clearState]
  );

  return (
    <div className="fuel-list-view">
      <OrderFuelFilter onChangeActiveTab={handleSwicherChange} activeTab={activeTab} switcherLabels={labelsFuel} />
      {isInitialLoading ? (
        <UiSpinner visible mt={48} />
      ) : (
        <OrderList
          isMultiple={false}
          orderList={allRecords}
          selectedOrderIds={activeOrderIds}
          onSelectOrders={handleSelect}
          onUnselectOrder={unselect}
          loadMore={fetchNextPage}
          loading={isFetchingNextPage}
          fuelStatuses={fuelStatuses}
          isFuel
        />
      )}
    </div>
  );
};
