import { ManagersApi } from '@/api/managers';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';
import { useQueryWithUpdates } from '@/hooks/use-query-with-updates';

export const useManagersShortUpdates = () => {
  const queryFn = (isFirst: boolean) => ManagersApi.getShortManagers({ updates: !isFirst });

  const shortManagers = useQueryWithUpdates<ResponseWithoutPagerSimple<Record<number, string>>>({
    queryKey: ['shortManagers'],
    refetchOnWindowFocus: false,
    queryFn,
  });

  const data = shortManagers?.data?.data;
  return data
    ? Object.keys(data).map((key) => {
        return {
          value: key,
          label: data[+key],
        };
      })
    : [];
};
