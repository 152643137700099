import { ReactSVG } from 'react-svg';
import { ElementType } from 'react';
import { iconNames } from '@/config/icon-names';
import { emptyFunction } from '@/helpers/empty-function';

const ROOT_PATH = '/assets/icons/';

type Attributes = {
  height: string;
  width: string;
};

export const useIcons = (attributes?: Partial<Attributes>) =>
  iconNames.reduce(
    (acc, name) => ({
      ...acc,
      [name]: () => (
        <ReactSVG
          src={`${ROOT_PATH}${name}.svg`}
          beforeInjection={
            attributes
              ? (svg) => {
                  svg.classList.add(`svg-icon-${name}`);
                  svg.setAttribute('style', `width: ${attributes.width || '20px'}; height: ${attributes.height || '20px'}`);
                }
              : emptyFunction
          }
        />
      ),
    }),
    {} as { [k in (typeof iconNames)[number]]: ElementType }
  );
