import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { DocumentsApi } from '@/api/documents/documents-api';
import { OrganizationDocumentType } from '@/api/documents/documents-types';
import { ClientContractTemplateType, TemplateData } from '@/api/documents/templates-types';
import { useContractTemplateForm } from '@/features/contract-template/hooks/use-contract-template-form';

type UseContractDocumentTemplateProps = {
  organizationId?: number;
  bankId?: number;
  order?: number;
  origin?: OrganizationDocumentType;
};

export const useContractDocumentTemplate = ({ organizationId, bankId, order, origin }: UseContractDocumentTemplateProps) => {
  const [document, setDocument] = useState<TemplateData | ClientContractTemplateType>({} as TemplateData | ClientContractTemplateType);

  const handleChangeDocument = (updatedDocument?: TemplateData | ClientContractTemplateType) => {
    if (!isEmpty(updatedDocument)) {
      setDocument(updatedDocument);
    }
  };

  const fetchDocument = useCallback(async () => {
    if (!organizationId || !bankId) return;
    const res = await DocumentsApi.getDocument({
      organizationId,
      bankId,
      order,
      origin,
    });
    setDocument(res.data);
  }, [bankId, order, organizationId, origin]);

  useEffect(() => {
    fetchDocument();
  }, [fetchDocument]);

  const { isTemplateWithForm, isEditForm, routesAmount, modalWidth, toggleEditForm, submitEditForm } = useContractTemplateForm({
    document,
    handleChangeDocument,
  });

  return { document, modalWidth, isTemplateWithForm, isEditForm, routesAmount, toggleEditForm, submitEditForm };
};
