export const HAS_OFFER = 'Есть предложение';
export const HAS_REQUEST = 'Запрошено';
export const REQUEST = 'Запросить';
export const TOOLTIP = 'Невозможно запросить из-за различающихся налоговых требований перевозчика и заявки';

export const getButtonText = ({ hasOffer, request }: { hasOffer: boolean; request: boolean }) => {
  if (hasOffer) return HAS_OFFER;
  if (request) return HAS_REQUEST;

  return REQUEST;
};
