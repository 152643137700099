import { Box, BoxProps, Text } from '@chakra-ui/react';
import { DocumentTypes } from 'config/constants/document-types';
import { AttachmentDeps } from 'config/constants/types';
import { UiFileUploadInput } from '@/ui-elements';
import { useDependentAttachments } from '@/features/attachments/hooks/use-dependent-attachments';

type Props = {
  dependent: AttachmentDeps;
  dependentId: number;
  type: DocumentTypes;
  label?: string;
};

export const UiAttachmentsInput = ({ dependent, dependentId, type, label, ...boxProps }: Props & BoxProps) => {
  const { attachments, deleteAttachmentById, postAttachments, downloadAttachments } = useDependentAttachments({
    dependent,
    dependentId,
    type,
  });

  return (
    <Box {...boxProps}>
      <Text mb={2}>{label}</Text>
      <UiFileUploadInput
        savedFileList={attachments}
        onDeleteFileClick={deleteAttachmentById}
        onIconClick={downloadAttachments}
        onImmediateUploadFile={postAttachments}
      />
    </Box>
  );
};
