import { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { VerificationFormMode } from 'components/verification-forms/types';

export const CarrierTitleSubform: FC<{ mode: VerificationFormMode }> = ({ mode }) => {
  return (
    <Box mb={5}>
      <Text fontSize={24} fontWeight={700}>
        {`Вы ${
          {
            create: 'добавляете перевозчика',
            edit: 'редактируете перевозчика',
            check: 'проверяете перевозчика',
            banking: 'добавляете банковские реквизиты',
            editBanking: 'редактируете банковские реквизиты',
          }[mode]
        }`}
      </Text>
    </Box>
  );
};
