import { SettingsForm } from 'components/settings/settings-form';
import { SettingsDetailsEmpty } from 'components/settings/settings-details-empty/settings-details-empty';
import { createFormSettingsValues } from 'components/verification-forms/helpers/create-form-settings-values';
import { SetFormValues } from 'components/settings/settings-details-empty/schema';
import { settingsToFormValues } from 'components/verification-forms/helpers/settings-to-form-values';
import { toast } from 'react-toastify';
import { useSettingsSelect } from '@/features/settings/hooks/use-settings-select';
import { activeSettingsIdSelector } from '@/features/settings/selectors/active-settings-id-selector';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useSettingsActions } from '@/features/settings/hooks/use-settings-actions';
import { useAllSettings } from '@/features/settings/hooks/use-all-settings';
import { useOrganizationEnum } from '@/features/my-organizations/hooks/use-organization-enum';
import { useSettingsInvalidate } from '@/features/settings/hooks/use-settings-invalidate';

export const SettingsDetailsView = () => {
  const activeSettingsId = useAppSelector(activeSettingsIdSelector);
  const { unselect } = useSettingsSelect();
  const { addSettings } = useSettingsActions();
  const { allSettings } = useAllSettings();
  const { organizationsEnum } = useOrganizationEnum();
  const { settingsInvalidate } = useSettingsInvalidate();

  const initialValues: SetFormValues | undefined =
    organizationsEnum.data?.data && allSettings.data && settingsToFormValues(organizationsEnum.data.data, allSettings.data.data);

  const onCreate = (values: SetFormValues) => {
    addSettings.mutate(createFormSettingsValues(values), {
      onSuccess: () => {
        unselect?.();
        settingsInvalidate();
        toast.success('Настройки сохранены');
      },
    });
  };

  if (!activeSettingsId) return <SettingsDetailsEmpty />;
  return <SettingsForm handleCancel={unselect} onCreate={onCreate} initialValues={initialValues} />;
};
