import { useCallback, useEffect } from 'react';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { securityActions } from '@/features/security/slice';
import { useAppSelector } from '@/hooks/use-app-selector';
import { securityActiveApprovalSelector } from '@/features/security/selectors/security-active-approval-selector';
import { Approval } from '@/types/models/approval';
import { securityActiveCarrierSelector } from '@/features/security/selectors/security-active-carrier-selector';

type Props = {
  approvals?: Array<Approval>;
  selectFirstApproval?: boolean;
};
/**
 * Если передать массив объектов позициониурется на первом
 * * */
export const useSecurityActions = (args?: Props) => {
  const { approvals = [], selectFirstApproval } = args ?? {};
  const dispatch = useAppDispatch();
  const activeApprovalId = useAppSelector(securityActiveApprovalSelector);
  const activeCarrierId = useAppSelector(securityActiveCarrierSelector);

  /**
   * выбор активного объекта проверки
   * */

  const selectApproval = useCallback(
    (approval: Approval) => {
      dispatch(securityActions.selectApproval({ approvalId: approval.id }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (selectFirstApproval && !activeApprovalId && approvals?.length) selectApproval(approvals[0] as any);
  }, [activeApprovalId, approvals, selectApproval, selectFirstApproval]);

  /**
   *  очистка состояния
   * */
  const clear = () => {
    dispatch(securityActions.clear());
  };

  return {
    selectApproval,
    clear,
    activeApprovalId,
    activeCarrierId,
  };
};
