import { Box, Center, PopoverBody, PopoverContent, PopoverHeader } from '@chakra-ui/react';
import { NotificationBlock } from 'components/notifications-popover/notification-block';
import { FC } from 'react';
import { UiToggle } from '@/ui-elements/ui-toggle/ui-toggle';
import { UiTextButton } from '@/ui-elements';
import { useIcons } from '@/hooks/use-icons';
import { AppNotification } from '@/types/models/app-notification';
import { InfiniteScroll } from '@/views/infinite-scroll/infinite-scroll';
import { emptyFunction } from '@/helpers/empty-function';
import { VoidResponseFunction } from '@/types/common/void-function';

type Props = {
  notifications: Array<AppNotification>;
  isOnlyNew: boolean;
  onlyNewToggle: VoidFunction;
  loading?: boolean;
  loadMore?: VoidResponseFunction<any>;
  navigateToOrderClick?: VoidResponseFunction<number>;
  markAllAsReadClick?: VoidFunction;
  markAsReadClick?: VoidResponseFunction<number>;
};

export const NotificationContent: FC<Props> = ({
  navigateToOrderClick = emptyFunction,
  notifications,
  onlyNewToggle,
  isOnlyNew,
  loading,
  loadMore = emptyFunction,
  markAllAsReadClick = emptyFunction,
  markAsReadClick = emptyFunction,
}) => {
  const { Checkmark } = useIcons();

  const hasUnread = notifications.some((x) => !x.viewed);
  const sortedNotifications = notifications
    .sort((a, b) => +b.created - +a.created)
    .map((item) => {
      return (
        <NotificationBlock item={item} key={item.id} navigateToOrderClick={navigateToOrderClick} markAsReadClick={() => markAsReadClick(item.id)} />
      );
    });

  return (
    <PopoverContent className="popover-content">
      <PopoverHeader className="popover-header" pb={1}>
        <Box display="flex" justifyContent="space-between">
          <span>Уведомления</span>
          <UiToggle onChange={onlyNewToggle} defaultChecked={isOnlyNew} children={<span>Только непрочитанные</span>} />
        </Box>
        {hasUnread && (
          <Box mt={2.5} ml={2}>
            <UiTextButton leftIcon={Checkmark} mode="lightdark" onClick={markAllAsReadClick}>
              Отметить все как прочитанное
            </UiTextButton>
          </Box>
        )}
      </PopoverHeader>
      <PopoverBody>
        <InfiniteScroll callback={loadMore} loading={loading} viewHeight="90%">
          {!notifications.length && !loading && <Center>Новых уведомлений нет</Center>}
          {sortedNotifications}
        </InfiniteScroll>
      </PopoverBody>
    </PopoverContent>
  );
};
