import { FC } from 'react';
import { CarrierReviews } from 'components/carrier-review/carrier-reviews';
import { Review } from '@/types/models/review';
import { Carrier } from '@/types/models/carrier';

export const Reviews: FC<{ reviews?: Array<Review>; onCloseClick?: VoidFunction; vehiclesAmount?: number; carrier?: Carrier }> = ({
  reviews,
  onCloseClick,
  vehiclesAmount = 0,
  carrier,
}) => <CarrierReviews reviews={reviews} onCloseClick={onCloseClick} vehiclesAmount={vehiclesAmount} carrier={carrier} />;
