import { FC } from 'react';
import { Grid, GridItem } from '@chakra-ui/react';
import { VerificationFormMode } from 'components/verification-forms/types';
import { UiInputField } from '@/ui-elements';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const VehicleVolumeSubform: FC<{ mode: VerificationFormMode }> = ({ mode }) => {
  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={6} mt={-1} className="carriers-verification-form">
      <GridItem>
        <UiInputField name="volume" label="Объем (в кубических метрах)" type="number" placeholder="Объем" required isDisabled={mode === 'check'} />
      </GridItem>
      <GridItem>
        <UiInputField name="capacity" label="Грузоподъемность, т." placeholder="Грузоподъемность" required isDisabled={mode === 'check'} />
      </GridItem>
      <GridItem>
        <UiInputField name="dimensions" label="Размеры (ДхШхВ) в метрах" placeholder="(ДхШхВ)" required isDisabled={mode === 'check'} />
      </GridItem>
    </Grid>
  );
};
