import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { DropzoneInputProps, DropzoneProps, useDropzone } from 'react-dropzone';
import { Container, HStack, Text, VStack } from '@chakra-ui/react';
import classNames from 'classnames';
import { useIcons } from '@/hooks/use-icons';
import { UiFileUploadCard } from './ui-file-upload-card/ui-file-upload-card';
import './ui-file-upload.scss';
import { emptyFunction } from '@/helpers/empty-function';
import { saveFileToDisk } from '@/helpers/save-file-to-disk';

type Props = DropzoneProps &
  Omit<DropzoneInputProps, 'onChange'> & {
    name?: string;
    // компонент
    component?: React.ReactNode;
    // Список документов для контролируемого инпута загрузчика. Если не undefined - то компонент ожидает, что файлы приходят из вне
    savedFileList?: Array<{ id: number; name: string; extension: string }>;
    // если указан - то вызывается сразу после одобавления файла, вместо onChange
    onImmediateUploadFile?: (file: File[]) => void;
    //  для контролируемого инпута загрузчика. Если указан - то вызывается сразу после клика на иконке удаления
    onDeleteFileClick?: (documentId: number) => void;
    onIconClick?: (documentId: number) => void;
    onChange?: (files: File[]) => void;
    // скрыть загрузку и удаление
    disableUpload?: boolean;
    hideDeleteButton?: boolean;
  };

/*
 * для управляемого загрузчика файлов нужно передать
 * */

export const UiFileUploadInput = ({
  name,
  disabled,
  component,
  multiple,
  onChange,
  savedFileList,
  onDeleteFileClick,
  onIconClick,
  onImmediateUploadFile,
  disableUpload,
  hideDeleteButton,
}: Props) => {
  const [fileList, setFileList] = useState([] as File[]);
  const { Paperclip } = useIcons();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (onImmediateUploadFile) {
        onImmediateUploadFile(acceptedFiles);
      } else {
        const newFiles = [...fileList, ...acceptedFiles];
        setFileList(newFiles);
        if (onChange) {
          onChange(newFiles);
        }
      }
    },
    [fileList, onChange, onImmediateUploadFile]
  );

  const removeFile = useCallback(
    (file: File) => {
      const newFiles = [...fileList];
      newFiles.splice(newFiles.indexOf(file), 1);
      setFileList(newFiles);
      if (onChange) {
        onChange(newFiles);
      }
    },
    [fileList, onChange]
  );
  const onDownloadFile = (file: File) => {
    saveFileToDisk(file);
  };

  const files = useMemo(() => {
    const savedFilesCards =
      savedFileList?.map((file) => {
        return (
          <UiFileUploadCard
            key={file.id}
            fileName={`${file.name}.${file.extension}`}
            onIconClick={onIconClick ? () => onIconClick(file.id) : emptyFunction}
            onDeleteClick={onDeleteFileClick ? () => onDeleteFileClick(file.id) : null}
            hideDeleteButton={disableUpload || hideDeleteButton}
          />
        );
      }) || [];

    const notSaveFilesCards = fileList.map((file, index) => (
      <UiFileUploadCard
        fileName={file.name}
        key={`${file.name}_${index + 1}`}
        onIconClick={() => onDownloadFile(file)}
        onDeleteClick={() => removeFile(file)}
      />
    ));
    return [...savedFilesCards, ...notSaveFilesCards];
  }, [savedFileList, fileList, onIconClick, onDeleteFileClick, disableUpload, hideDeleteButton, removeFile]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    disabled,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'text/*': ['.pdf', '.doc', '.docx', '.xls'],
    },
    onDropRejected: () => {
      toast.error('Неверный формат файла', { toastId: 'err_fileFormat' });
    },
  });

  return (
    <VStack spacing={5} width="full" align="start" className="file-upload-container">
      {!disableUpload && (
        <div {...getRootProps({ className: 'dropzone file-upload-dropzone' })}>
          <input {...getInputProps()} name={name} />
          {component || (
            <Container variant="dashed" centerContent w="full" width="full">
              <HStack spacing="10px">
                <Paperclip />
                <Text textStyle="p4">Загрузить</Text>
              </HStack>
              <Text mt="14px" textStyle="p5">
                Допустимые форматы: jpeg, png, pdf, doc, docx, xls
              </Text>
            </Container>
          )}
        </div>
      )}
      <HStack spacing={5} align="top" flexWrap="wrap" className={classNames({ disabledFiles: disabled })}>
        {files}
      </HStack>
    </VStack>
  );
};
