import { CarrierSimple } from '@/types/models/carrier';
import { Driver } from '@/types/models/driver';
import { Vehicle } from '@/types/models/vehicle';
import { CarrierOrganization } from '@/types/models/my-organization/my-organization';

// TODO: уточнить статусы
export enum OrderOfferStatus {
  // в ожидании ответа от перевозчика
  Waiting = 'waiting',
  // утвержденный перевозчик
  Approved = 'approved',
  // перевозчик откликнулся по своей цене
  OwnPrice = 'ownPrice',
  // перевозчик откликнулся по нашей цене, но из другого региона
  OwnRegion = 'ownRegion',
}

/*
 * Отклик на заявку/запрос перевозчику
 * * */
export type OrderOffer = {
  id: number;
  carrier: CarrierSimple;
  driver: Driver;
  vehicle: Vehicle;
  price: number;
  accepted: 0 | 1;
  created: string;
  vat?: boolean;
  carrierOrganization?: CarrierOrganization;
};
