import { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { UiText } from '@/ui-elements/ui-text/ui-text';
import { Driver } from '@/types/models/driver';

type Props = {
  driver: Driver;
};

export const DriverWidget: FC<Props> = ({ driver: { formattedName, phone } }) => {
  return (
    <Flex direction="column" gap="1em">
      <UiText textStyle="h3" children="Водитель" />
      <UiText children={formattedName} />
      <UiText color="products.200" children={phone} />
    </Flex>
  );
};
