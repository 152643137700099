import { FC } from 'react';
import { HStack, VStack } from '@chakra-ui/react';
import { BY_CARRIER_AGREEMENT, EXECUTOR, WITH_VAT, WITHOUT_VAT } from 'components/order-details/order-details-service/texts';
import { COMMENT, EMPTY_STRING, PRICE, SERVICE } from 'config/constants/common-strings';
import { textColor } from 'components/order-details/order-details-service/constants';
import { InfoLine } from '@/components';
import { emptyFunction } from '@/helpers/empty-function';
import { UiButtonFilled, UiButtonOutlined, UiDivider, UiFileUploadInput } from '@/ui-elements';
import { OrderService } from '@/types/models/order-service';
import { OrderMode } from '@/types/common/mode';
import { toPriceFormat } from '@/helpers/price/to-price-format';
import { useIcons } from '@/hooks/use-icons';
import { useOrderServicesEdit } from '@/features/order-services/hooks/use-order-services-edit';
import { useWidgetActions } from '@/features/widget/hooks/use-widget-actions';
import { WidgetContainers } from '@/features/widget/widget-containers';
import { Order } from '@/types/models/order';
import { useModal } from '@/hooks/use-modal';
import { ModalType } from '@/types/common/modals';
import { useOrderInvalidate } from '@/features/order/hooks/use-order-invalidate';
import { Load } from '@/types/models/loads';

/**
 * Описание дополнителной услуги
 * */
export const OrderDetailsAdditionalService: FC<{
  order: Order | Load;
  carrierName?: string;
  service: OrderService;
  orderMode: OrderMode;
  companyName?: string;
  className?: string;
  onServiceDocumentDeleteClick: (documentId: number) => void;
}> = ({ service, companyName, onServiceDocumentDeleteClick = emptyFunction, className, orderMode, order, carrierName }) => {
  const { deleteOrderService } = useOrderServicesEdit(service.id);
  const { EditServiceWidget } = useWidgetActions(WidgetContainers.OrderDetailsService);
  const { invalidateOrderServices } = useOrderInvalidate();
  const { DeleteIcon } = useIcons();
  const price = orderMode === 'carrier' ? toPriceFormat(service?.carrierPayment?.price) : toPriceFormat(service?.customerPayment?.price);
  const vat = orderMode === 'carrier' ? service.carrierPayment?.vat : service.customerPayment?.vat;
  const vatCaption = vat ? WITH_VAT : WITHOUT_VAT;
  const showServiceEditWidget = () => {
    EditServiceWidget.show({
      service,
      order,
      carrierName,
    });
  };
  const { modalView: confirmModal, setModalOpened } = useModal(ModalType.Confirm, {
    onCloseModal: () => setModalOpened(false),
    onSuccess: () => {
      deleteOrderService.mutate(undefined, {
        onSuccess: () => {
          invalidateOrderServices(order.id);
        },
      });
    },
    modalWidth: 524,
    text: 'Вы действительно хотите удалить услугу?',
    alignDesc: 'left',
    cancelButtonText: 'Отмена',
    submitButtonText: 'Удалить',
    accentXBtn: true,
  });

  return (
    <>
      <HStack className={className}>
        <VStack justifyContent="flex-start" alignItems="flex-start">
          <InfoLine text={EXECUTOR} textColor={textColor} value={companyName || EMPTY_STRING} />
          <InfoLine text={BY_CARRIER_AGREEMENT} textColor={textColor} value={EMPTY_STRING} />
          <InfoLine text={PRICE} textColor={textColor} value={`${price} (${vatCaption})`} />
          <InfoLine text={SERVICE} textColor={textColor} value={service.name || EMPTY_STRING} />
          <InfoLine text={COMMENT} textColor={textColor} value={service.comment || EMPTY_STRING} />
          <UiFileUploadInput savedFileList={service.documents} onDeleteFileClick={onServiceDocumentDeleteClick} disableUpload />
          <HStack gap={5}>
            <UiButtonOutlined mode="light" onClick={showServiceEditWidget}>
              Изменить детали
            </UiButtonOutlined>
            <UiButtonFilled mode="dark" leftIcon={DeleteIcon} onClick={() => setModalOpened(true)}>
              Удалить
            </UiButtonFilled>
          </HStack>
        </VStack>
        {confirmModal}
      </HStack>
      <UiDivider />
    </>
  );
};
