import { useCallback } from 'react';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { FunnelSwitcherStatus } from '@/types/models/funnel';
import { funnelActions } from '@/features/funnel/slice';
import { getLoad } from '@/features/funnel/actions/get-load';

export const useFunnelActions = () => {
  const dispatch = useAppDispatch();

  const selectLoadID = useCallback(
    (id?: number) => {
      dispatch(funnelActions.selectLoadID(id));
    },
    [dispatch]
  );

  const selectTab = useCallback(
    (tab: FunnelSwitcherStatus) => {
      dispatch(funnelActions.selectTab(tab));
    },
    [dispatch]
  );

  const create = useCallback(() => {
    dispatch(funnelActions.create());
  }, [dispatch]);

  const cancelCreate = useCallback(() => {
    dispatch(funnelActions.cancelCreate());
  }, [dispatch]);

  const update = useCallback(() => {
    dispatch(funnelActions.update());
  }, [dispatch]);

  const fetchLoadForUpdate = useCallback(
    (id: number) => {
      dispatch(getLoad(id));
    },
    [dispatch]
  );

  const clearState = useCallback(() => {
    dispatch(funnelActions.clearState());
  }, [dispatch]);

  return {
    selectLoadID,
    selectTab,
    create,
    cancelCreate,
    cancelUpdate: cancelCreate,
    update,
    fetchLoadForUpdate,
    clearState,
  };
};
