import { useMutation } from '@tanstack/react-query';
import { OrdersApi } from '@/api/orders';

export const useDocumentsStatus = (ids: { orderId: number; pointId: number }) => {
  const setPointDocumentsStatus = useMutation({
    mutationKey: ['setPointStatus'],
    mutationFn: (status: 0 | 1 | 'approved' | 'declined') => OrdersApi.setWaypointDocumentsStatus(ids, status),
  });

  return {
    setPointDocumentsStatus,
  };
};
