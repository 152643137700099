import { DateObject } from 'react-multi-date-picker';
import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import './order-filter.scss';
import { UiSwitcher } from '@/ui-elements/switcher/switcher';
import { Tab } from '@/ui-elements/switcher/types';
import { OrderSwitcherStatus } from '@/config/constants/statuses';
import { OrderFilterFields } from '@/features/order/types/order-filter';
import OrderFilterForm from './order-filter-form';
import { SortType } from '@/config/constants/types';

type Props = {
  switcherLabels: Tab[];
  handleCreateOrder?: React.MouseEventHandler<HTMLButtonElement>;
  onFilterChange: (values: OrderFilterFields) => void;
  onChangeActiveTab: (activeKey: string) => void;
  activeTab: OrderSwitcherStatus;
  onSetSorting: (sortBy: string, sortType: SortType) => void;
  handleSelectMode: () => void;
  toggleValue: boolean;
  setRangeValue: (range: DateObject[]) => void;
  periodValue: string;
  rangeValue: DateObject[];
  setPeriodValue: (value: string) => void;
  defaultFilter?: string;
  isRedirecting?: boolean;
};

export const OrderFilter: FC<Props> = ({
  switcherLabels,
  handleCreateOrder,
  onFilterChange,
  onChangeActiveTab,
  activeTab,
  onSetSorting,
  handleSelectMode,
  toggleValue,
  setRangeValue,
  periodValue,
  rangeValue,
  setPeriodValue,
  defaultFilter,
  isRedirecting,
}) => (
  <Box className="order-filter">
    <UiSwitcher
      className="order-filter__switcher"
      tabsList={switcherLabels}
      defaultActiveTab={OrderSwitcherStatus.New}
      onChange={onChangeActiveTab}
      activeTab={activeTab}
    />
    {!isRedirecting && (
      <OrderFilterForm
        handleCreateOrder={handleCreateOrder}
        onFilterChange={onFilterChange}
        onSetSorting={onSetSorting}
        handleSelectMode={handleSelectMode}
        toggleValue={toggleValue}
        activeTab={activeTab}
        setRangeValue={setRangeValue}
        periodValue={periodValue}
        rangeValue={rangeValue}
        setPeriodValue={setPeriodValue}
        defaultFilter={defaultFilter}
      />
    )}
  </Box>
);
