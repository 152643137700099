import { UserRole } from '@/types/api/user-role';

export const getText = (currentRole: UserRole | undefined): string => {
  let text = '';
  if (currentRole === UserRole.Manager || currentRole === UserRole.Administrator) {
    text = 'заявкам';
  } else if (currentRole === UserRole.Booker) {
    text = 'учету оплаты рейсов';
  } else if (currentRole === UserRole.Security) {
    text = 'проверке перевозчиков';
  }
  return text;
};
