import { useMemo } from 'react';
import { useAppSelector } from '@/hooks/use-app-selector';
import { cargoTypesSelector } from '@/features/cargo/selectors/cargo-types-selector';
import { funnelEditingLoadSelector } from '@/features/funnel/selectors/funnel-editing-load-selector';
import { usePackages } from '@/features/packages/use-packages';
import { useVehicleTypesNames } from '@/features/vehicle-types/hooks/use-vehicle-types-names';
import { getCargoOptions, getUpdatedValuesFromRespData } from '@/features/funnel/helpers';

export const useFunnelFormUpdatedValues = () => {
  const cargoTypes = useAppSelector(cargoTypesSelector);
  const vehicleTypesNames = useVehicleTypesNames();
  const packageTypes = usePackages();
  const { load: respData } = useAppSelector(funnelEditingLoadSelector);
  // массив, хранящий грузы для связи точек погрузки и разгрузки (предоставляет в точках погрузки только грузы, существующие в точках погрузки)
  const unloadCargoOptions = getCargoOptions(cargoTypes, respData?.cargos);

  const updatedValues = useMemo(
    () => getUpdatedValuesFromRespData({ respData, packageTypes, cargoTypes, vehicleTypesNames, unloadCargoOptions }),
    [respData, packageTypes, cargoTypes, vehicleTypesNames, unloadCargoOptions]
  );
  return { updatedValues, cargos: respData?.cargos };
};
