import { HStack, Box, Text } from '@chakra-ui/react';
import { taxOptions } from 'config/constants/tax-options';
import { UiNumberPickerField, UiSelectField, UiTextareaField } from '@/ui-elements';
import { Namespace } from './types';

export const paymentTermsInitialValues = {
  hasAdvance: true,
  paymentTerms: 'Оплата в течение 5 банковских дней',
};

export const PaymentTermsClientSubform = ({ namespace }: Namespace) => (
  <Box w="full">
    <Text textStyle="h3" align="left" mb={6}>
      Условия оплаты для клиента
    </Text>
    <HStack spacing={5} mb={4}>
      <UiNumberPickerField name={`${namespace}.price`} label="Назначить цену" content="Впишите цену заявки" required hideStepper />
      <UiSelectField name={`${namespace}.vat`} options={taxOptions} label="НДС" content="Является ли грузовладелец плательщиком" required />
    </HStack>
    <UiTextareaField name={`${namespace}.paymentTerms`} label="Условия оплаты" variant="noResize" />
  </Box>
);
