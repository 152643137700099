import { COMMA, DASH_WITH_SPACES, EMPTY_STRING } from 'config/constants/common-strings';
import { ModelExtender } from '@/types/model-extender';
import { LOADING, PARTIAL_UNLOADING, UNLOADING } from '@/features/order/texts';
import { countCargoFromPoint, findAllLoadingCargos, findAllUnLoadingCargos, getMassFromPoint } from '@/features/order/helpers/count-cargo-from-point';
import { PointExtended } from '@/features/order/types/point-extended';
import { formatMomentLocal } from '@/helpers/moment/format-moment-local';
import { MOMENT_L, MOMENT_LT } from '@/helpers/moment/constants';
import { Loading } from '@/types/models/loading';
import { formatMass } from '@/helpers/format-mass';

export const pointExtender: ModelExtender<Loading, PointExtended> = {
  isLoading: (model) => Boolean(countCargoFromPoint(findAllLoadingCargos(model.point.id, model.action, model.cargos))),
  isUnloading: (model) => Boolean(countCargoFromPoint(findAllUnLoadingCargos(model.point.id, model.action, model.cargos))),
  pointDescription: (model) => {
    return (isLastPoint: boolean) => {
      const loading = countCargoFromPoint(findAllLoadingCargos(model.point.id, model.action, model.cargos));
      const unloading = countCargoFromPoint(findAllUnLoadingCargos(model.point.id, model.action, model.cargos));

      const unloadingString = unloading > 0 ? `${!isLastPoint ? PARTIAL_UNLOADING : UNLOADING} ${formatMass(unloading)}` : EMPTY_STRING;
      const loadingString = loading > 0 ? `${unloading > 0 ? DASH_WITH_SPACES : EMPTY_STRING}${LOADING} ${formatMass(loading)}` : EMPTY_STRING;
      return `${unloadingString}${loadingString}`;
    };
  },
  arrivalString: (model) => {
    if (!model.point.arrival) return '';
    const date = formatMomentLocal(model.point.arrival, MOMENT_L, false, model.point.timezone);
    const time = formatMomentLocal(model.point.arrival, MOMENT_LT, false, model.point.timezone);
    return `${date} прибыть к ${time}`;
  },
  cargoType: (model) =>
    [
      ...getMassFromPoint(findAllLoadingCargos(model.point.id, model.action, model.cargos)),
      ...getMassFromPoint(findAllUnLoadingCargos(model.point.id, model.action, model.cargos)),
    ].join(COMMA),
};
