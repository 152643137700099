import './carriers-loads-form.scss';
import { FC, memo } from 'react';
import { FormSpy } from 'react-final-form';
import { Box } from '@chakra-ui/react';
import { initialLoadsFormValues } from '@/config/constants/loads';
import { FormAutosave } from '@/hooks/use-autosave';
import { useForm } from '@/helpers/forms/use-form';
import { CarriersLoadsFilters } from '@/components/carriers-loads/filters/carriers-loads-filters';
import { CarriersLoadsFilterValues } from '@/types/models/carriers-loads';

type Props = {
  onFormValuesChange: (values: Partial<CarriersLoadsFilterValues>) => void;
};

const FiltersForm: FC<Props> = ({ onFormValuesChange }) => {
  const Form = useForm<CarriersLoadsFilterValues>();

  return (
    <Box className="carriers-loads-form">
      <Form initialValues={initialLoadsFormValues} onSubmit={onFormValuesChange}>
        <FormSpy subscription={{ values: true }}>{({ values }) => <FormAutosave values={values} onSave={onFormValuesChange} />}</FormSpy>
        <CarriersLoadsFilters />
      </Form>
    </Box>
  );
};

export const CarriersLoadsFiltersForm = memo(FiltersForm);
