import { isArray } from 'lodash';
import { AccountRole } from '@/types/api/account-role';

export const checkAccountRole = ({
  accountRole,
  availableAccountRoles,
}: {
  availableAccountRoles: Array<AccountRole> | undefined;
  accountRole: Array<AccountRole> | AccountRole | undefined;
}) => {
  return availableAccountRoles?.find((availableRole) => {
    if (isArray(accountRole)) {
      return accountRole.includes(availableRole);
    }
    return availableRole === accountRole;
  });
};
