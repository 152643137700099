import { Box, Text, Flex } from '@chakra-ui/react';
import { useFormState } from 'react-final-form';
import { UiSwitchField, UiNumberPickerField, UiCheckboxField, UiTextareaField } from '@/ui-elements';
import { Namespace } from './types';
import { WhenFieldChanges } from '@/helpers/forms/when-field-changes';

export const paymentTermsInitialValues = {
  hasAdvance: true,
  paymentTerms: 'Оплата в течение 5 банковских дней',
};

export const PaymentTermsSubform = ({ namespace }: Namespace, isUpdating: boolean) => {
  const { values } = useFormState();

  const changePrepayment = () => {
    return !values.carrierPaymentTerms?.hasPrepayment;
  };

  return (
    <Box w="full">
      <Text textStyle="h3" align="left" mb={6}>
        Условия оплаты для исполнителя
      </Text>
      <Flex mb={4} justifyContent="spase-between">
        <Box mr={6} mt={8}>
          <UiSwitchField name={`${namespace}.hasVat`} label="С НДС" />
        </Box>
        <Box w="81%">
          <WhenFieldChanges set={`${namespace}.priceWithVAT`} field={`${namespace}.hasVat`} to={() => ''} />
          <UiNumberPickerField
            isDisabled={!values.carrierPaymentTerms?.hasVat}
            name={`${namespace}.priceWithVAT`}
            label="Назначить цену"
            content="Впишите цену заявки"
            hideStepper
            required
          />
        </Box>
      </Flex>
      <Flex mb={4} justifyContent="spase-between">
        <Box mr={3} mt={8}>
          <UiSwitchField name={`${namespace}.withoutVat`} label="Без НДС" />
        </Box>
        <Box w="80%">
          <WhenFieldChanges set={`${namespace}.priceWithoutVAT`} field={`${namespace}.withoutVat`} to={() => ''} />
          <WhenFieldChanges set={`${namespace}.hasFuel`} field={`${namespace}.withoutVat`} to={() => false} />
          <UiNumberPickerField
            isDisabled={!values.carrierPaymentTerms?.withoutVat}
            name={`${namespace}.priceWithoutVAT`}
            label="Назначить цену"
            content="Впишите цену заявки"
            hideStepper
            required
          />
        </Box>
      </Flex>
      <Box mb={4}>
        <WhenFieldChanges isApplicable={changePrepayment} set={`${namespace}.hasPrepayment`} field={`${namespace}.hasFuel`} to={(v) => v} />
        <UiSwitchField isDisabled={values.carrierPaymentTerms?.hasFuel} name={`${namespace}.hasPrepayment`} withHelp label="Аванс доступен" />
      </Box>
      <Box mb={4}>
        <UiSwitchField isDisabled={!values.carrierPaymentTerms?.withoutVat} name={`${namespace}.hasFuel`} withHelp label="Топливо доступно" />
      </Box>
      <Box mb={4}>
        <UiCheckboxField
          defaultChecked={isUpdating && values.carrierPaymentTerms?.priceOffer}
          name={`${namespace}.priceOffer`}
          label="Допускается делать встречные предложения по цене"
        />
      </Box>
      <UiTextareaField name={`${namespace}.paymentConditions`} label="Условия оплаты" variant="noResize" />
    </Box>
  );
};
