// @ts-nocheck
import { toast } from 'react-toastify';
import { FileDocument } from '@/types/models/document';
import { AttachmentsApi } from '@/api/attachments';
import { base64ToFile } from '@/helpers/base64-to-file';
import { saveFileToDisk } from '@/helpers/save-file-to-disk';

export const downloadFileDocument = async (file: FileDocument) => {
  if (!file || !file.link) return;
  const link = file.link.split('/').pop();
  toast.success('Скачивание началось', { toastId: 'attachments-download' });
  const { data: attachment } = await AttachmentsApi.downloadAttachment(`attachments/${link}`);
  if (attachment.result) {
    const data = base64ToFile(attachment.data, attachment.name);
    saveFileToDisk(data, attachment.name);
  }
};
