import { FC } from 'react';
import { ModalView } from '@/views/order-modal/order-modal-view';
import { ModalsProps } from '@/types/common/modals';
import { ResourcesButtonActions } from '@/features/resources/constants/general';
import { EMPTY_STRING } from '@/config/constants/common-strings';

export const ModalEditNotify: FC<
  ModalsProps<{
    title?: string;
    desc?: string;
    alignDesc?: 'center' | 'left' | 'right';
    onSuccess?: VoidFunction;
  }>
> = ({ modalOpened, onClose, title, desc, alignDesc, onSuccess }) => {
  const onSubmit = () => {
    onSuccess?.();
  };

  return (
    <ModalView
      isOpen={modalOpened}
      onClose={onClose}
      title={title ?? EMPTY_STRING}
      onSubmit={onSubmit}
      submitButtonText={ResourcesButtonActions.Edit}
      desc={desc}
      alignDesc={alignDesc}
      modalWidth={320}
      marginTop="20vh"
      justifyTitle="left"
    />
  );
};
