import { useQuery } from '@tanstack/react-query';
import { VehiclesApi } from '@/api/vehicles';
import { QueryKeys } from '@/config/constants/query-keys';

export const useVehicle = (id?: number) => {
  const vehicle = useQuery({
    queryKey: [QueryKeys.vehicle, id],
    refetchOnWindowFocus: false,
    enabled: !!id,
    queryFn: () => VehiclesApi.getVehicle(id!),
  });
  return { vehicle };
};
