import { ADD_VEHICLE_FOR_ORGANIZATION_CONTENT } from 'components/verification-forms/constants';
import { ResourcesSidebarMode } from '@/features/resources/types/general';

/* Заголовки формы Водителей */
export const ResourcesDriverFormTitles = {
  [ResourcesSidebarMode.CreateForm]: 'Вы добавляете водителя к организации',
  [ResourcesSidebarMode.EditForm]: 'Вы редактируете водителя',
  [ResourcesSidebarMode.Details]: '',
  [ResourcesSidebarMode.None]: '',
};
/* Заголовки формы ТС */
export const ResourcesVehicleFormTitles = {
  [ResourcesSidebarMode.CreateForm]: ADD_VEHICLE_FOR_ORGANIZATION_CONTENT,
  [ResourcesSidebarMode.EditForm]: 'Вы редактируете автомобиль',
  [ResourcesSidebarMode.Details]: '',
  [ResourcesSidebarMode.None]: '',
};

export enum ResourcesFormFieldNames {
  // driver
  selfAssignment = 'selfAssignment',
  country = 'country',
  phoneNumber = 'phoneNumber',
  surname = 'surname',
  name = 'name',
  patronymic = 'patronymic',
  russianPassport = 'russianPassport',
  passport = 'passport',
  passportDate = 'passportDate',
  birthday = 'birthday',
  passportGiven = 'passportGiven',
  driverLicense = 'driverLicense',
  driverLicenseDate = 'driverLicenseDate',
  convictions = 'convictions',
  loans = 'loans',
  speedFines = 'speedFines',
  trafficFines = 'trafficFines',
  accidents = 'accidents',
  driversLicenseRevoked = 'driversLicenseRevoked',
  passportDoc = 'passportDoc',
  driversLicenseDoc = 'driversLicenseDoc',
  laborContractDoc = 'laborContractDoc',
  // vehicle
  vehicleGroup = 'vehicleGroup',
  regNumber = 'regNumber',
  volume = 'volume',
  capacity = 'capacity',
  dimensions = 'dimensions',
  'trailers[0].regNumber' = 'trailers[0].regNumber',
  'trailers[0].capacity' = 'trailers[0].capacity',
  'trailers[0].volume' = 'trailers[0].volume',
  'trailers[0].dimensions' = 'trailers[0].dimensions',
  'trailers[0].accidentsInPastThreeYears' = 'trailers[0].accidentsInPastThreeYears',
  bailed = 'bailed',
  casco = 'casco',
  ownType = 'ownType',
  loadingType = 'loadingType',
  accidentsInPastThreeYears = 'accidentsInPastThreeYears',
  tracker = 'tracker',
  permanentTracking = 'permanentTracking',
  vehicleType = 'vehicleType',
  VRCFront = 'VRCFront',
  VRCBack = 'VRCBack',
  VPFront = 'VPFront',
  VPBack = 'VPBack',
  ExtraDoc = 'ExtraDoc',
  Contract = 'Contract',
  password = 'password',
}

export enum ResourcesFormLabels {
  selfAssignment = 'С правом самоназначения на рейс',
  country = 'Страна регистрации водителя',
  phoneNumber = 'Телефон основной',
  surname = 'Фамилия',
  name = 'Имя',
  patronymic = 'Отчество',
  russianPassport = 'Паспорт РФ',
  passport = 'Серия и номер',
  passportDate = 'Дата выдачи',
  birthday = 'Дата рождения',
  passportGiven = 'Кем выдан',
  driverLicense = 'Серия/номер',
  driverLicenseDate = 'Дата выдачи',
  convictions = 'Наличие судимости',
  loans = 'Наличие кредитов',
  speedFines = 'Наличие штрафов за превышение скорости',
  trafficFines = 'Наличие штрафов за нарушение ПДД',
  accidents = 'Наличие зарегистрированных ДТП за 3 года',
  driversLicenseRevoked = 'Лишение прав',
  passportDoc = '1. Разворот паспорта с фотографией',
  driversLicenseDoc = '2. Водительское удостоверение, обе стороны в одном файле на одной странице',
  laborContractDoc = '3. Трудовой договор (если вы являетесь владельцем ИП то документы ОГРНИП)',
  // vehicle
  withoutTrailer = 'Без полуприцепа',
  regNumber = 'Регистрационный номер',
  volume = ' Объем (в кубических метрах)',
  capacity = 'Грузоподъемность, т.',
  dimensions = 'Размеры (ДхШхВ) в метрах',
  trailersRegNumber = 'Номер Полуприцепа',
  trailersAccidentsInPastThreeYears = 'Участвовал в ДТП за последние 3 года',
  bailed = 'Находится в залоге',
  casco = 'ТС застраховано КАСКО',
  loadingType = 'Тип Загрузки/Погрузки(можно выбрать несколько)',
  accidentsInPastThreeYears = 'Участвовал в ДТП за последние 3 года',
  tracker = 'Есть постоянно подключенное бортовое устройство слежения',
  permanentTracking = 'Готов предоставить полный и постоянный доступ к треку',
  vehicleType = 'Выберите тип ТС',
  VRCFront = 'Свидетельство о регистрации транспортного средства (СТС) - лицевая сторона',
  VRCBack = 'Свидетельство о регистрации транспортного средства (СТС) - обратная сторона',
  VPFront = 'Паспорт транспортного средства (ПТС) - лицевая сторона',
  VPBack = 'Паспорт транспортного средства (ПТС) - обратная сторона',
  ExtraDoc = 'Другие документы',
  Contract = 'Загрузите скан договора',
}

export enum ResourcesFormPlaceholders {
  country = 'Введите страну регистрации водителя',
  phoneNumber = 'Введите номер телефона',
  passportDate = 'Выберите дату',
  birthday = 'Выберите дату',
  driverLicenseDate = 'Выберите дату',
  // vehicle
  regNumber = 'Регистрационный номер',
  volume = 'Объем',
  capacity = 'Грузоподъемность',
  dimensions = '(ДхШхВ)',
  trailersRegNumber = 'Номер Полуприцепа',
  loadingType = 'Выберите вариант',
}

export enum ResourcesFormHelperText {
  country = 'По документу, подтверждающему личность',
  phoneNumber = 'Телефон, по которому можно связаться',
  resetDriverPassword = 'Система отправит водителю смс с паролем по указанному номеру для входа в приложение',
}

export interface ResourcesCreateDriverFormValues {
  [ResourcesFormFieldNames.selfAssignment]: any;
}

export type ResourcesDriverFormValues = {
  /* contacts */
  [ResourcesFormFieldNames.country]: string;
  [ResourcesFormFieldNames.phoneNumber]: string;
  /* full name */
  [ResourcesFormFieldNames.name]: string;
  [ResourcesFormFieldNames.surname]: string;
  [ResourcesFormFieldNames.patronymic]: string;
  /* passport */
  [ResourcesFormFieldNames.russianPassport]: boolean;
  [ResourcesFormFieldNames.passport]: string;
  [ResourcesFormFieldNames.passportDate]: string | Date | number;
  [ResourcesFormFieldNames.birthday]: string;
  [ResourcesFormFieldNames.passportGiven]: string;
  [ResourcesFormFieldNames.convictions]?: boolean;
  [ResourcesFormFieldNames.loans]?: boolean;
  [ResourcesFormFieldNames.accidents]?: boolean;
  /* license */
  [ResourcesFormFieldNames.driverLicense]: string;
  [ResourcesFormFieldNames.driverLicenseDate]: string;
  /* docs */
  [ResourcesFormFieldNames.passportDoc]?: Array<File>;
  [ResourcesFormFieldNames.driversLicenseDoc]?: Array<File>;
  [ResourcesFormFieldNames.laborContractDoc]?: Array<File>;
};

export type ResourcesRegisterDriverFormValues = {
  [ResourcesFormFieldNames.phoneNumber]: string;
};
