import { numberInputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(numberInputAnatomy.keys);

const baseStyle = definePartsStyle({
  stepper: {
    color: 'neutrals.100',
    border: 'none',
  },
});

export const numberPickerTheme = defineMultiStyleConfig({ baseStyle });
