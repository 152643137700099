import { OrderLoadType } from '@/config/constants/types';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { Cargos } from '@/types/models/cargos';
import { Point } from '@/types/models/point';
import { formatMomentLocal } from '@/helpers/moment/format-moment-local';
import { MOMENT_24_HOUR_TIME, MOMENT_DATEPICKER } from '@/helpers/moment/constants';
import { arrivalTimeTypeConfig, weightOptions } from '@/components/create-order-form/subforms/form-configs';

export const getPointDisplay = (
  point: Point,
  cargos: Array<Cargos>,
  type: OrderLoadType,
  packageTypes: Array<SelectOption>,
  cargoTypes: Array<SelectOption>,
  unloadCargoOptions: Array<SelectOption>
) => {
  return {
    id: point.id,
    address: { label: point.address, value: point.address },
    arrivalDate: formatMomentLocal(point.arrival, MOMENT_DATEPICKER, false, point.timezone),
    arrivalStartTime: formatMomentLocal(point.arrival, MOMENT_24_HOUR_TIME, false, point.timezone),
    arrivalEndTime: formatMomentLocal(point.arrival + (point?.arrivalDuration || 0), MOMENT_24_HOUR_TIME, false, point.timezone),
    arrivalTimeOption:
      // eslint-disable-next-line no-nested-ternary
      point.arrivalDuration === 86400 ? arrivalTimeTypeConfig[2] : point.arrivalDuration === 0 ? arrivalTimeTypeConfig[0] : arrivalTimeTypeConfig[1],
    coordinates: point.coordinates,
    type,
    cargos: cargos.map((l) => {
      return {
        id: l.tenderLotPositionID,
        cargoType: cargoTypes.find((x) => Number(x.value) === Number(l.type)),
        weight: l.properties.Mass,
        volume: l.properties.Volume,
        size: {
          length: l.properties.Length,
          width: l.properties.Width,
          height: l.properties.Height,
          diameter: l.properties.Diameter,
        },
        weightOptions: weightOptions.find((x) => x.label === l.properties.Units),
        quantity: l.quantity,
        package: packageTypes.find((x) => Number(x.value) === Number(l.package)),
        tenderLotID: l.tenderLotID,
        tenderLotPositionID: l.tenderLotPositionID,
        unloadCargo: type === OrderLoadType.Unloading && unloadCargoOptions.find((cargoOption) => cargoOption.value === l.id),
      };
    }),
  };
};
