import { OrderFilter } from '@/components/order-filter/order-filter';
import './order-list-view.scss';
import { OrderList } from '@/components/order-list/order-list';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';
import { getLabelsForTabs } from '@/helpers/get-labels-for-tabs';
import { UseOrderList } from '@/views/order-list/use-order-list';

export const OrderListView = () => {
  const {
    activeTab,
    handleCreateOrder,
    metrics,
    switcherNames,
    handleFilterChange,
    handleActiveTab,
    handleSetSorting,
    handleSelectMode,
    isMultiple,
    handleSetRangeValue,
    periodValue,
    rangeValue,
    setPeriodValue,
    isRedirecting,
    isInitialLoading,
    allRecords,
    activeOrderIds,
    handleSelect,
    unselect,
    fetchNextPage,
    isFetchingNextPage,
    fuelStatuses,
  } = UseOrderList();

  return (
    <div className="order-list-view">
      <OrderFilter
        activeTab={activeTab}
        handleCreateOrder={handleCreateOrder}
        switcherLabels={getLabelsForTabs(metrics, switcherNames)}
        onFilterChange={handleFilterChange}
        onChangeActiveTab={handleActiveTab}
        onSetSorting={handleSetSorting}
        handleSelectMode={handleSelectMode}
        toggleValue={isMultiple}
        setRangeValue={handleSetRangeValue}
        periodValue={periodValue}
        rangeValue={rangeValue}
        setPeriodValue={setPeriodValue}
        // defaultFilter={defaultFilter}
        isRedirecting={isRedirecting}
      />

      {isInitialLoading ? (
        <UiSpinner visible mt={48} />
      ) : (
        <OrderList
          isMultiple={isMultiple}
          orderList={allRecords}
          selectedOrderIds={activeOrderIds}
          onSelectOrders={handleSelect}
          onUnselectOrder={unselect}
          loadMore={fetchNextPage}
          loading={isFetchingNextPage}
          fuelStatuses={fuelStatuses}
        />
      )}
    </div>
  );
};
