import { useRef, useCallback } from 'react';
import { Box, Grid, GridItem, Text, useOutsideClick } from '@chakra-ui/react';
import { vatOptions } from '@/config/constants/vat-options';
import { UiCheckboxField, UiInputField, UiMaskedField, UiMultiDatePickerField, UiSelectField } from '@/ui-elements';
import { INN_MASK, KPP_MASK, OGRN_MASK, OKPO_MASK, PASSPORT_MASK } from '@/components/auth-form/constants';
import { OrganizationDetailsAddressSubform } from '@/features/modals/organization/address-subform';
import { useGetMyOrganizationTypes } from '@/features/my-organization/use-get-organization-types';

export const DetailsSubform = () => {
  const passportDateRef = useRef(null);
  const birthdayDateRef = useRef(null);
  const handlePassportDateClose = useCallback(() => (passportDateRef?.current as any)?.closeCalendar(), [passportDateRef]);
  const handleBirthdayDateClose = useCallback(() => (birthdayDateRef?.current as any)?.closeCalendar(), [birthdayDateRef]);
  useOutsideClick({
    ref: passportDateRef,
    handler: handlePassportDateClose,
  });
  useOutsideClick({
    ref: birthdayDateRef,
    handler: handleBirthdayDateClose,
  });
  const { organizationTypes } = useGetMyOrganizationTypes();

  return (
    <Box>
      <Grid templateColumns="repeat(3, 1fr)" gap={6} mt={3.5}>
        <GridItem>
          <UiSelectField options={organizationTypes} name="organizationTypeID" label="Тип организации" placeholder="Тип" required />
        </GridItem>
        <GridItem>
          <UiInputField name="name" label="Название организации" placeholder="Организация" required />
        </GridItem>
        <GridItem>
          <UiMaskedField name="INN" mask={INN_MASK} label="ИНН" placeholder="ИНН" required />
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(3, 1fr)" gap={6} mt={3.5}>
        <GridItem>
          <UiMaskedField name="KPP" mask={KPP_MASK} label="КПП" placeholder="КПП" />
        </GridItem>
        <GridItem>
          <UiMaskedField name="OGRN" mask={OGRN_MASK} label="ОГРН" placeholder="ОГРН" />
        </GridItem>
        <GridItem>
          <UiMaskedField name="OKPO" mask={OKPO_MASK} label="ОКПО" placeholder="ОКПО" />
        </GridItem>
      </Grid>

      <Box>
        <UiSelectField options={vatOptions} name="vatType" label="Плательщик НДС" />
      </Box>

      <OrganizationDetailsAddressSubform />

      <Text mt={2} fontWeight={700} fontSize={18}>
        Генеральный директор
      </Text>

      <Grid templateColumns="repeat(3, 1fr)" gap={6} mt={2}>
        <GridItem>
          <UiInputField name="directorSurname" label="Фамилия" placeholder="Фамилия" required />
        </GridItem>
        <GridItem>
          <UiInputField name="directorName" label="Имя" placeholder="Имя" required />
        </GridItem>
        <GridItem>
          <UiInputField name="directorPatronymic" label="Отчество" placeholder="Отчество" />
        </GridItem>
      </Grid>

      <Text mt={2} fontWeight={700} fontSize={18}>
        Паспорт директора
      </Text>

      <Box mb={4} mt={2}>
        <UiCheckboxField name="russianPassport" label="Паспорт РФ" />
      </Box>

      <Grid templateColumns="repeat(3, 1fr)" gap={6} mt={-1} className="carriers-verification-form">
        <GridItem>
          <UiMaskedField name="passportNumber" label="Серия и номер паспорта" placeholder="Серия/номер" required mask={PASSPORT_MASK} />
        </GridItem>
        <GridItem>
          <UiMultiDatePickerField name="passportDate" label="Дата выдачи" placeholder="Дата выдачи" required datePickerRef={passportDateRef} />
        </GridItem>
        <GridItem>
          <UiMultiDatePickerField name="birthday" label="Дата рождения" placeholder="Дата рождения" required datePickerRef={birthdayDateRef} />
        </GridItem>
      </Grid>
      <Box mt={-1}>
        <UiInputField name="passportGiven" label="Кем выдан" placeholder="Точно как в паспорте" required />
      </Box>
    </Box>
  );
};
