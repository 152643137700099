import { FC } from 'react';
import { OrderDetailsClientOfferInfoButtons, OrderDetailsClientOfferInfo } from 'components/order-details';
import './order-details-shipment-requests.scss';
import { ShipmentRequest } from '@/types/models/shipment-request';
import { checkAcceptedStatuses } from '@/features/order/helpers/check-accepted-statuses';

type Props = {
  requests: Array<ShipmentRequest>;
  title?: string;
  disableSelect?: boolean;
  children?: JSX.Element;
  // открыть виджет выбрать исполнителя
  onClickSubmitOffer: (request: ShipmentRequest) => void;
  // отправить оффер на проверку
  onSendToCheck: (request: ShipmentRequest) => void;
  // кнопка Подробнее - открыть виджет Информация о перевозчике
  onMoreInformation: (id: number) => void;
};

export const OrderDetailsShipmentRequests: FC<Props> = ({
  requests,
  title,
  disableSelect = false,
  children,
  onClickSubmitOffer,
  onSendToCheck,
  onMoreInformation,
}) => {
  if (!requests?.length) return null;
  return (
    <div className="order-details-shipment-requests">
      {children}
      {title && <div className="order-details-shipment-requests__title">{title}</div>}
      {requests.map((request) => {
        const approved = checkAcceptedStatuses({ offer: request });
        return (
          <OrderDetailsClientOfferInfo
            onMoreInformation={onMoreInformation}
            onSendToCheckClick={() => onSendToCheck(request)}
            key={request.id}
            orderOffer={request}
            className="order-details-client-offers__item"
            approved={approved}
            isOwnPrice={false}
            isWaiting
          >
            {!disableSelect ? <OrderDetailsClientOfferInfoButtons onClickSubmit={() => onClickSubmitOffer(request)} disabled={!approved} /> : <div />}
          </OrderDetailsClientOfferInfo>
        );
      })}
    </div>
  );
};
