import L, { LatLngExpression } from 'leaflet';
import { defaultIcon } from './config';

export type LayerHandler<T> = (resp?: { data?: any; event?: L.LeafletEvent & { originalEvent?: MouseEvent }; layer?: T }) => void;

export class Marker {
  public LMarker: L.Marker;

  public constructor(marker: {
    coords: LatLngExpression;
    options?: L.MarkerOptions & {
      icon: L.Icon<L.IconOptions> | L.DivIcon | undefined;
      draggable?: boolean;
      data?: any;
    };
    popup?: Parameters<L.Marker['bindPopup']>;
    handlers?: {
      [K: string]: LayerHandler<L.Marker>;
    };
    tooltip?: Parameters<L.Layer['bindTooltip']>;
  }) {
    const { coords, options, popup, handlers, tooltip } = marker;

    this.LMarker = L.marker(coords, { ...options, icon: options?.icon || defaultIcon });
    if (popup) {
      this.LMarker.bindPopup(...popup);
    }
    if (tooltip) {
      this.LMarker.bindTooltip(...tooltip);
    }
    if (handlers) {
      Object.keys(handlers).forEach((eventType) => {
        if (handlers[eventType]) {
          this.LMarker.on(eventType, (event) => {
            handlers[eventType]({
              data: options?.data,
              event,
              layer: this.LMarker,
            });
          });
        }
      });
    }
  }

  public get Marker() {
    return this.LMarker;
  }

  public get L() {
    return this.LMarker;
  }
}
