import { HStack, VStack, Text } from '@chakra-ui/react';
import { Driver } from '@/types/models/driver';
import { UiWarningNumber } from '@/ui-elements/ui-warning-number/ui-warning-number';
import { ResourcesStatusesList } from '@/components/resources-statuses-list/resources-statuses-list';
import { CarrierRating } from '@/components/carrier-rating/carrier-rating';

export const OrderClientInfoDriverDetails = ({ driver }: { driver?: Driver }) => {
  if (!driver) return null;

  return (
    <VStack alignItems="flex-start">
      <HStack>
        <Text className="order-details-client-offer-info__subtitle">Водитель:</Text>
        <CarrierRating rating={driver.rating || 0} />
        <UiWarningNumber number={driver?.riskGroup || 0} />
        <span className="order-details-client-offer-info__value">{driver?.formattedName || driver?.name}</span>
      </HStack>
      <HStack>
        <Text className="order-details-client-offer-info__subtitle">Телефон водителя:</Text>
        <span className="order-details-client-offer-info__value">{driver?.phone}</span>
      </HStack>
      <ResourcesStatusesList approvalStatuses={driver?.approvalStatuses} />
    </VStack>
  );
};
