import { useEffect } from 'react';
import { FunnelLoadStatus, FunnelSwitcherStatus } from '@/types/models/funnel';
import { Load } from '@/types/models/loads';
import { useBackForwardButtonsDisabled, useDisableButtonForNotCalculated, useDisableButtonForProcessed } from '@/features/funnel/hooks';
import { Order } from '@/types/models/order';
import { OrderExecutionStatus } from '@/config/constants/statuses';

export const useBackForwardButtonsActions = (
  activeTab: FunnelSwitcherStatus,
  setNewLoadStatus: (nextStatus: FunnelLoadStatus | OrderExecutionStatus.New, onSuccess?: VoidFunction) => void,
  load?: Load | Order
) => {
  const { buttonsDisabled, handleChangeButtonDisabledState, resetButtonsDisabledState } = useBackForwardButtonsDisabled();

  /* При переключении вкладок сбрасываем состояние кнопок, если изменилось */
  useEffect(() => {
    resetButtonsDisabledState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useDisableButtonForNotCalculated({ load, activeTab, handleChangeButtonDisabledState });
  useDisableButtonForProcessed({ load, activeTab, handleChangeButtonDisabledState });

  /* Таб: Новые; переход "На рассчет" -> */
  const handleMoveToCalculation = () => {
    return setNewLoadStatus(FunnelLoadStatus.CostCalculation);
  };
  /* Таб: Расчет стоимости; переход "Вернуть в новые" <- */
  const handleReturnToNew = () => {
    return setNewLoadStatus(FunnelLoadStatus.NewCalculation);
  };
  /* Таб: Расчет стоимости; переход "Запрос рассчитан" -> */
  const handleCompleteCalculation = () => {
    return setNewLoadStatus(FunnelLoadStatus.CostCalculated);
  };
  /* Таб: Запрос рассчитан; переход "Вернуть в расчет" <- */
  const handleReturnToCalculation = () => {
    return setNewLoadStatus(FunnelLoadStatus.CostCalculation);
  };
  /* Таб: Запрос рассчитан; переход "Отправлено клиенту" -> */
  const handleMoveToSentToClient = () => {
    return setNewLoadStatus(FunnelLoadStatus.SentCostToClient);
  };
  /* Таб: Отправлено клиенту; переход "Вернуть назад" <- */
  const handleReturnToCalculated = () => {
    return setNewLoadStatus(FunnelLoadStatus.CostCalculated);
  };
  /* Таб: Отправлено клиенту; переход "Согласовано" -> */
  const handleMoveToConfirmed = () => {
    return setNewLoadStatus(OrderExecutionStatus.New);
  };
  /* Таб: Отправлено клиенту; переход "Отклонено" -> */
  const handleMoveToCanceled = () => {
    return setNewLoadStatus(FunnelLoadStatus.CostCanceled);
  };
  /* Таб: Согласовано; переход "Вернуть назад" <- */
  const handleReturnToSentToClientFromConfirmed = () => {
    return setNewLoadStatus(FunnelLoadStatus.SentCostToClient);
  };
  /* Таб: Отклонено; переход "Вернуть назад" <- */
  const handleReturnToSentToClient = () => {
    return setNewLoadStatus(FunnelLoadStatus.SentCostToClient);
  };

  switch (activeTab) {
    case FunnelSwitcherStatus.NewCalculation:
      return {
        handleClickBack: undefined,
        handleClickForward: handleMoveToCalculation,
        buttonsDisabled,
      };
    case FunnelSwitcherStatus.CostCalculation:
      return {
        handleClickBack: handleReturnToNew,
        handleClickForward: handleCompleteCalculation,
        buttonsDisabled,
      };
    case FunnelSwitcherStatus.CostCalculated:
      return {
        handleClickBack: handleReturnToCalculation,
        handleClickForward: handleMoveToSentToClient,
        buttonsDisabled,
      };
    case FunnelSwitcherStatus.SentCostToClient:
      return {
        handleClickBack: handleReturnToCalculated,
        handleClickForward: [handleMoveToConfirmed, handleMoveToCanceled],
        buttonsDisabled,
      };
    case FunnelSwitcherStatus.CostConfirmed:
      return {
        handleClickBack: handleReturnToSentToClientFromConfirmed,
        handleClickForward: undefined,
        buttonsDisabled,
      };
    case FunnelSwitcherStatus.CostCanceled:
      return {
        handleClickBack: handleReturnToSentToClient,
        handleClickForward: undefined,
        buttonsDisabled,
      };
    default:
      return {
        handleClickBack: undefined,
        handleClickForward: undefined,
        buttonsDisabled,
      };
  }
};
