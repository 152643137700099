import { FC } from 'react';
import close from 'public/assets/icons/close.svg';
import burger from 'public/assets/icons/Menu.svg';
import { Button } from '@chakra-ui/react';
import { UiIconButton } from '@/ui-elements';

interface IProps {
  open: boolean;
  onToggle(): void;
}

export const BurgerMenu: FC<IProps> = ({ open, onToggle }) => {
  return (
    <Button className="app-toolbar__burger" onClick={onToggle}>
      <UiIconButton aria-label="close-modal-button" icon={!open ? burger : close} size="34px" />
      <span>Еще</span>
    </Button>
  );
};
