import { Box, Flex, Text } from '@chakra-ui/react';
import './customers-additional-information.scss';
import { FC } from 'react';
import moment from 'moment';
import { BG_LIMIT, COLOR_LIMIT } from 'components/customers/customers-table-component/constants';
import { OrganisationRating } from 'components/organisation-rating/organisation-rating';
import { RatingType } from 'config/constants/types';
import { getRemainder } from 'components/customers/helpers/get-remainder';
import { getEmptyStr } from '@/helpers/get-empty-str';
import { Customer } from '@/types/models/customer';
import { emptyFunction } from '@/helpers/empty-function';
import { UserRole } from '@/types/api/user-role';
import { MOMENT_DD_MM_YYYY } from '@/helpers/moment/constants';
import { UiStatus } from '@/ui-elements/status/status';
import { toPriceFormat } from '@/helpers/price/to-price-format';
import { CreateOrderFromCustomersButton } from '@/components/customers/create-order-from-customers-button/create-order-from-customers-button';

/*
 * Сайдбар, когда выбран клиент в роли логиста
 * */

type Props = {
  customer?: Customer;
  handleAddOrder: () => void;
  currentRole?: UserRole;
  isLoading?: boolean;
};
export const CustomersAdditionalInformation: FC<Props> = ({ customer, handleAddOrder = emptyFunction, currentRole, isLoading = false }) => {
  const managerName = `${getEmptyStr(customer?.manager.surname)} ${getEmptyStr(customer?.manager.name)} ${getEmptyStr(customer?.manager.patronymic)}`;
  const remainder = getRemainder(customer?.circulationLimit, customer?.inCirculation);
  return (
    <Flex flexDirection="column" m="24px">
      <Box>
        <Box mb="8px">{customer?.vipCustomer && <OrganisationRating ratingType={RatingType.Vip} />}</Box>
        <Text className="customers-additional-information__title">{customer?.name}</Text>
        <Flex flexDirection="column" gap="20px">
          <Flex gap="12px">
            <Text className="customers-additional-information__title-description_bold">ID</Text>
            <Text>{customer?.id}</Text>
          </Flex>
          <Flex gap="12px">
            <Text className="customers-additional-information__title-description_bold">ИНН</Text>
            <Text>{customer?.INN}</Text>
          </Flex>
          <Flex gap="12px">
            <Text className="customers-additional-information__title-description_bold">Договор-номер</Text>
            <Text>{customer?.contractNumber}</Text>
          </Flex>
          <Flex gap="12px">
            <Text className="customers-additional-information__title-description_bold">Дата договора</Text>
            <Text>{moment(customer?.expiration).format(MOMENT_DD_MM_YYYY)}</Text>
          </Flex>
          <Flex gap="12px">
            <Text className="customers-additional-information__title-description_bold">Ответственный менеджер</Text>
            <Text>{managerName}</Text>
          </Flex>
          <Flex flexDirection="column">
            <Text className="customers-additional-information__title-description_bold">Комментарий </Text>
            <Text>{customer?.comment}</Text>
          </Flex>
          <Flex flexDirection="column">
            <Text className="customers-additional-information__title-description_bold">Тарифное соглашение </Text>
            <Text>{customer?.tariffComment}</Text>
          </Flex>
        </Flex>
        <Flex gap={5} mt="16px">
          <Box>
            <Text>Лимит по факторингу</Text>
            <UiStatus
              text={toPriceFormat(customer?.circulationLimit)}
              backGroundColor={BG_LIMIT}
              color={COLOR_LIMIT}
              className="customers-additional-information__limit"
            />
          </Box>
          <Box>
            <Text textAlign="center">В обороте</Text>
            <UiStatus
              text={toPriceFormat(customer?.inCirculation)}
              backGroundColor={BG_LIMIT}
              color={COLOR_LIMIT}
              className="customers-additional-information__limit"
            />
          </Box>
          <Box>
            <Text>Доступный остаток</Text>
            <UiStatus
              text={toPriceFormat(remainder)}
              backGroundColor={BG_LIMIT}
              color={COLOR_LIMIT}
              className="customers-additional-information__limit"
            />
          </Box>
        </Flex>
      </Box>
      {currentRole === UserRole.Manager ? <CreateOrderFromCustomersButton isLoading={isLoading} handleAddOrder={handleAddOrder} /> : ''}
    </Flex>
  );
};
