import Scrollbars, { ScrollbarProps } from 'react-custom-scrollbars-2';
import { forwardRef } from 'react';
import './ui-scrollbars.scss';

export const UiScrollbars = forwardRef<Scrollbars, Partial<ScrollbarProps>>((props, ref) => {
  const { children, style = { height: '100%', width: '100%' }, ...rest } = props;
  return (
    <Scrollbars ref={ref} className="ui-scrollbars" style={style} {...rest}>
      {children}
    </Scrollbars>
  );
});
// { children, ...props }
