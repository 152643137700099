import { HStack } from '@chakra-ui/react';
import { PageWrapper } from 'pages/page-wrapper';
import { CustomersListView } from '@/views/customers-list/customers-list-view';
import { CustomerSidebar } from '@/views/customer-sidebar/customer-sidebar';

export const BookerClientsPage = () => {
  return (
    <PageWrapper>
      <HStack>
        <CustomersListView />
        <CustomerSidebar />
      </HStack>
    </PageWrapper>
  );
};
