import { EMPTY_STRING } from 'config/constants/common-strings';
import { ModelExtender } from '@/types/model-extender';
import { UserSimple } from '@/types/models/user-simple';
import { UserSimpleExtended } from '@/features/user-simple/types/user-simple-extended';

export const userSimpleExtender: ModelExtender<UserSimple, UserSimpleExtended> = {
  shortName: (user) => {
    if (!user) return '';
    const { surname, name, patronymic } = user;
    let nameShort;
    if (name && !surname) {
      nameShort = name;
    } else {
      nameShort = name ? `${name.substring(0, 1)}.` : EMPTY_STRING;
    }
    const patronymicShort = name && patronymic ? `${patronymic.substring(0, 1)}.` : EMPTY_STRING;
    return `${surname || EMPTY_STRING} ${nameShort}${patronymicShort}`;
  },
};
