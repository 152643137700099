import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/types/redux/root-state';
import { FunnelSidebarMode } from '@/types/sidebar/funnel-sidebar-mode';

export const funnelSidebarModeSelector = createSelector(
  (state: RootState) => state.funnel,
  (funnel) => {
    if (funnel.isCreating) {
      return FunnelSidebarMode.NewLoad;
    }
    if (funnel.isUpdating) return FunnelSidebarMode.UpdateLoad;
    return FunnelSidebarMode.Details;
  }
);
