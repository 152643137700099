import React from 'react';
import { OrderDetailsComponent } from '@/components';
import { useActiveBookerOrder } from '@/features/booker/hooks/use-active-booker-order';
import { useWidgetActions } from '@/features/widget/hooks/use-widget-actions';
import { WidgetContainers } from '@/features/widget/widget-containers';
import { useOrderDetailsView } from '@/features/order/hooks/use-order-details-view';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';
import { useOrderUpdates } from '@/features/order/hooks/use-order-updates';

export const BookerOrdersDetailsView = () => {
  const { activeBookerOrderId } = useActiveBookerOrder();
  const { orderOffers, selectedOffer } = useOrderDetailsView();
  const { CarrierWidget } = useWidgetActions(WidgetContainers.OrderDetails);

  const {
    order: { data: orderResp, isInitialLoading },
  } = useOrderUpdates({ id: activeBookerOrderId });
  const order = orderResp?.data;

  const onSendToCheck = () => {
    // TODO: добавить метод создания Approval, когда появится на бэке
  };
  const onMoreInformation = (id: number) => {
    CarrierWidget.show({ carrierId: id });
  };

  return (
    <div>
      {isInitialLoading ? (
        <UiSpinner visible mt={48} />
      ) : (
        <OrderDetailsComponent
          selectedOffer={selectedOffer}
          orderOffers={orderOffers}
          order={order}
          disableSearchButton
          hideOrderActions
          hideOffers
          hideOrderLogistInfo
          hideOrderRiskGroup
          onSendToCheck={onSendToCheck}
          onMoreInformation={onMoreInformation}
        />
      )}
    </div>
  );
};
