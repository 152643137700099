import { FC } from 'react';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel } from '@chakra-ui/react';
import './carrier-filter.scss';
import { CarrierFilterHeader } from './carrier-filter-header';
import { emptyFunction } from '@/helpers/empty-function';
import { FormFields } from '@/features/carriers/types/carrier-filter';
import CarrierFilterAccordionPanel from './carrier-filter-accordion-panel';
import { SortType } from '@/config/constants/types';
import { CarrierFilterShortened } from './carrier-filter-shortened';
import { CarrierFilter } from '@/types/api/carrier-partial';

type Props = {
  isExpanded: boolean;
  onClickExpand: React.MouseEventHandler<HTMLButtonElement>;
  onFilterChange: (values: FormFields) => void;
  onChangeSorting: (sortType: SortType) => void;
  filters: CarrierFilter;
  allFound?: number;
};

export const CarrierFilterComponent: FC<Props> = ({
  isExpanded = true,
  onClickExpand = emptyFunction,
  onFilterChange = emptyFunction,
  onChangeSorting = emptyFunction,
  filters,
  allFound = 0,
}) => (
  <Accordion defaultIndex={[0]} index={isExpanded ? [0] : [-1]} allowToggle className="carrier-filter">
    <AccordionItem>
      <AccordionButton className="carrier-filter-accordion__button" px={0}>
        <CarrierFilterHeader isExpanded={isExpanded} onClickExpand={onClickExpand} />
        {!isExpanded && <CarrierFilterShortened foundAmount={allFound} filters={filters} />}
      </AccordionButton>
      <AccordionPanel>
        <CarrierFilterAccordionPanel onFilterChange={onFilterChange} onChangeSorting={onChangeSorting} allFound={allFound} />
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
);
