import './resources-view.scss';
import { Box } from '@chakra-ui/react';
import { ResourcesTabs } from '@/components/resources/tabs/resources-tabs';
import { useResourcesView } from '@/features/resources/hooks';

export const ResourcesView = () => {
  const { disabled, isCarrierSimple, handleSelectContentTab } = useResourcesView();

  return (
    <Box className="resources-view">
      <ResourcesTabs disabled={disabled} isCarrierSimple={isCarrierSimple} handleSelectContentTab={handleSelectContentTab} />
    </Box>
  );
};
