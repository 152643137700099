import { useAppSelector } from '@/hooks/use-app-selector';
import { RESOURCES_SIDEBARS } from '@/features/resources/constants/display-blocks';
import { resourcesSidebarModeSelector } from '@/features/resources/selectors';
import { ResourcesSidebarMode } from '@/features/resources/types/general';
import { useResourcesDispatchActions } from '@/features/resources/hooks';

export const ResourcesSidebar = () => {
  const { handleSetSidebarMode } = useResourcesDispatchActions();
  const mode = useAppSelector(resourcesSidebarModeSelector);
  const ResourceSidebarContent = RESOURCES_SIDEBARS[mode];

  const handleCloseSidebar = () => {
    handleSetSidebarMode(ResourcesSidebarMode.None);
  };

  return ResourceSidebarContent ? <ResourceSidebarContent closeSidebar={handleCloseSidebar} /> : null;
};
