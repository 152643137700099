import { FC } from 'react';
import { Divider } from '@chakra-ui/react';
import './carrier-filter-shortened.scss';
import { getShortenedFilter } from './get-shortened-filter';
import { CarrierFilter } from '@/types/api/carrier-partial';
import { useAppSelector } from '@/hooks/use-app-selector';
import { vehicleTypesSelector } from '@/features/vehicle-types/selectors/vehicle-types-selector';

type Props = {
  foundAmount?: number;
  filters: CarrierFilter;
};

export const CarrierFilterShortened: FC<Props> = ({ foundAmount = 0, filters }) => {
  const vehicleTypes = useAppSelector(vehicleTypesSelector);
  const { shortenedFilters } = getShortenedFilter(filters, vehicleTypes);
  return (
    <div className="carrier-filter-shortened">
      <p className="carrier-filter-shortened__amount">{`Найдено ${foundAmount}`}</p>
      <div className="carrier-filter-shortened__applied">
        {shortenedFilters.map((value) => (
          <>
            <p key={value}>{value}</p>
            <Divider key={value} orientation="vertical" className="carrier-filter-shortened__applied-separator" />
          </>
        ))}
      </div>
    </div>
  );
};
