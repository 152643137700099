import { Dictionary } from 'config/constants/dictionary';
import { ApiGet, ApiPut } from '@/helpers/api/methods';
import { Account } from '@/types/models/account';
import { AccountChangeEmail, AccountChangePass, AccountChangePhone, AccountEdit } from '@/types/api/account-partial';
import { DateNPageFilter } from '@/types/api/common-partial';
import { validateQueryString } from '@/helpers/validate-query-string';
import { Organization } from '@/types/models/organization';
import { ResponseApi } from '@/types/response';
import { ResponseWithData } from '@/types/api/response';
import { AccountRatingResponse } from '@/types/api/account-rating-response';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';

export const AccountApi = {
  getAccountSelf: () => ApiGet<ResponseWithoutPagerSimple<Account>>(`${Dictionary.Account}/me`),

  updateAccountSelf: (options: AccountEdit) => ApiPut<Account>(`${Dictionary.Account}/me`, options),

  updateAccountPass: (options: AccountChangePass) => ApiPut<ResponseApi>(`${Dictionary.Account}/changePassword`, options),

  updateAccountPhone: (options: AccountChangePhone) => ApiPut<ResponseApi>(`${Dictionary.Account}/changePhone`, options),

  updateAccountEmail: (options: AccountChangeEmail) => ApiPut<ResponseApi>(`${Dictionary.Account}/changeEmail`, options),

  getAccountRating: (query?: DateNPageFilter) => ApiGet<AccountRatingResponse>(`${Dictionary.Account}/rating${validateQueryString(query)}`),

  getAllAccountOrganizations: () => ApiGet<ResponseWithData<Organization>>(`${Dictionary.Account}/${Dictionary.Organizations}`),

  getAccountOrganization: (id: number) => ApiGet<Organization>(`${Dictionary.Account}/${Dictionary.Organizations}/${id}`),
};
