import { AnalyticsPeriod, OrderType, RatingType } from '@/config/constants/types';

import {
  ApproveStatus,
  BookerOrderDetailsTabs,
  BookerOrderSwitcherStatus,
  FuelStatus,
  OrderCompletedSwitcherStatus,
  OrderExecutionStatus,
  OrderStatus,
  OrderSwitcherStatus,
  PaymentOrderStatus,
  VerificationItemApproveStatus,
} from '@/config/constants/statuses';
import { OrderOfferStatus } from '@/types/models/order-offer';
import { FunnelLoadStatus } from '@/types/models/funnel';

const WAITING = 'На проверке';
const APPROVED = 'Принято';
const DECLINED = 'Отклонено';
const PROCESSED = 'Проверено';

export const OrderTypeNames = {
  [OrderType.Simple]: 'Только туда',
  [OrderType.Roundtrip]: 'Туда и обратно',
};

/**
 * @deprecated
 */
export const OrderSwitcherNames = {
  [OrderSwitcherStatus.New]: 'Новые',
  [OrderSwitcherStatus.CarrierSearch]: 'В распределении',
  [OrderSwitcherStatus.Preparation]: 'На оформлении',
  [OrderSwitcherStatus.Confirmed]: 'Оформленные',
  [OrderSwitcherStatus.Executing]: 'На исполнении',
  [OrderSwitcherStatus.Completed]: 'Завершенные',
  [OrderSwitcherStatus.Failed]: 'Срыв',
  [OrderSwitcherStatus.All]: 'Все',
};
/**
 * @deprecated
 */
export const OrderWithoutFuelSwitcherNames = {
  [FuelStatus.ConfirmedWithoutFuel]: 'Рейс оформлен без запроса на топливо',
  [FuelStatus.ExecutingWithoutFuel]: 'Рейс на исполнении, без запроса на топливо',
  [FuelStatus.CompletedWithoutFuel]: 'Рейс завершен, без запроса на топливо',
};
/**
 * @deprecated
 */
export const OrderWithFuelSwitcherNames = {
  [FuelStatus.ConfirmedWithFuel]: 'Рейс оформлен с запросом на топливо',
  [FuelStatus.ExecutingStartPreparation]: 'Рейс на исполнении, можно оформлять',
  [FuelStatus.ExecutingFuelProvided]: 'Рейс на исполнении, топливо предоставлено',
  [FuelStatus.CompletedBlockFuel]: 'Рейс завершен, заблокировать топливо',
  [FuelStatus.CompletedFuelClosed]: 'Рейс завершен, по топливу все закрыто',
};

/**
 * @deprecated
 */
export const OrderFuelStatusNames: Record<FuelStatus, string> = {
  ...OrderWithoutFuelSwitcherNames,
  ...OrderWithFuelSwitcherNames,
};

export const RatingTypeNames = {
  [RatingType.Gold]: 'Золотой',
  [RatingType.Silver]: 'Серебряный',
  [RatingType.Platinum]: 'Платиновый',
  [RatingType.Vip]: 'VIP',
};

export const AnalyticsPeriodNames = {
  [AnalyticsPeriod.All]: 'Весь период',
  [AnalyticsPeriod.Today]: 'Сегодня',
  [AnalyticsPeriod.Weekly]: 'Неделя',
  [AnalyticsPeriod.Monthly]: 'Месяц',
};

export const CompletedStatusNames = {
  [OrderCompletedSwitcherStatus.All]: 'Все завершенные',
  [OrderCompletedSwitcherStatus.CompletedWithoutDocs]: 'Ждем оригиналы',
  [OrderCompletedSwitcherStatus.CompletedWithDocs]: 'Оригиналы получены',
  [OrderCompletedSwitcherStatus.CompletedPayed]: 'Оплачено',
};

export const OrderOfferStatusNames: Record<OrderOfferStatus, string> = {
  [OrderOfferStatus.Waiting]: 'Ожидаем ответ',
  [OrderOfferStatus.Approved]: 'Выбран исполнителем',
  [OrderOfferStatus.OwnPrice]: 'Своя цена',
  [OrderOfferStatus.OwnRegion]: 'По нашей цене',
};

export const ApproveStatusNames: Record<ApproveStatus, string> = {
  [ApproveStatus.Waiting]: WAITING,
  [ApproveStatus.InProcessing]: WAITING,
  [ApproveStatus.Processed]: PROCESSED,
};

export const VerificationItemApproveStatusNames: Record<VerificationItemApproveStatus, string> = {
  [VerificationItemApproveStatus.Waiting]: WAITING,
  [VerificationItemApproveStatus.InProcessing]: WAITING,
  [VerificationItemApproveStatus.Accepted]: APPROVED,
  [VerificationItemApproveStatus.Declined]: DECLINED,
};

export const PaymentOrderStatusNames: Record<PaymentOrderStatus, string> = {
  [PaymentOrderStatus.None]: 'Не назначено в оплату',
  [PaymentOrderStatus.Ready]: 'Можно назначить в оплату',
  [PaymentOrderStatus.Paid]: 'Назначено в оплату',
};

/**
 * @deprecated
 */
export const OrderStatusNames: Record<OrderStatus, string> = {
  [OrderExecutionStatus.New]: 'Новая',
  [OrderExecutionStatus.CarrierSearch]: 'В распределении',
  [OrderExecutionStatus.Preparation]: 'На оформлении',
  [OrderExecutionStatus.Confirmed]: 'Оформлено',
  [OrderExecutionStatus.Executing]: 'Рейс выполняется',
  [OrderExecutionStatus.CompletedWithoutDocs]: 'Рейс выполнен',
  [OrderExecutionStatus.CompletedWithDocs]: 'Рейс выполнен',
  [OrderExecutionStatus.CompletedPayed]: 'Рейс выполнен',
  [OrderExecutionStatus.Completed]: 'Рейс выполнен',
  [OrderExecutionStatus.Failed]: 'Рейс сорван',
  [OrderExecutionStatus.Troubling]: 'Возникли проблемы',
  [FunnelLoadStatus.NewCalculation]: '',
  [FunnelLoadStatus.CostCalculation]: '',
  [FunnelLoadStatus.CostCalculated]: '',
  [FunnelLoadStatus.SentCostToClient]: '',
  [FunnelLoadStatus.CostConfirmed]: '',
  [FunnelLoadStatus.CostCanceled]: '',
};

/**
 * @deprecated
 */
export const BookerOrderSwitcherNames = {
  [BookerOrderSwitcherStatus.Preparation]: 'На оформлении',
  [BookerOrderSwitcherStatus.Confirmed]: 'Оформленные',
  [BookerOrderSwitcherStatus.Executing]: 'На исполнении',
  [BookerOrderSwitcherStatus.Completed]: 'Завершенные',
  [BookerOrderSwitcherStatus.Failed]: 'Срыв',
  [BookerOrderSwitcherStatus.RequestAdvance]: 'Запрос аванса',
  [BookerOrderSwitcherStatus.FinallySettlement]: 'Окончательный расчет',
  // [BookerOrderSwitcherStatus.All]: 'Все',
};
export const BookerTabNames = {
  [BookerOrderDetailsTabs.Customer]: 'Клиенты',
  [BookerOrderDetailsTabs.Carrier]: 'Исполнители',
};
