import '../resources-form.scss';
import { FC } from 'react';
import { Box, Heading, HStack } from '@chakra-ui/react';
import { Form } from 'react-final-form';
import { emptyFunction } from '@/helpers/empty-function';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { UiButtonFilled, UiButtonOutlined, UiDivider } from '@/ui-elements';
import { listScrollStyle, ResourcesButtonActions } from '@/features/resources/constants/general';
import { validateForm } from '@/helpers/forms/validate';
import { DriverVerificationForm, DriverVerificationForm as DriverVerificationFormFields } from '@/types/models/driver-verification';
import { createDriversWithoutCarrierSchema } from '@/helpers/forms/validation-schemas/create-drivers-without-carrier';
import { ResourcesSidebarMode } from '@/features/resources/types/general';
import { ResourcesDriverFormTitles, ResourcesDriverFormValues, ResourcesFormFieldNames } from '@/features/resources/constants/form';
import { ResourcesDriverSubformContacts } from '@/components/resources/forms/driver/subform-contacts/resources-driver-subform-contact';
import { ResourcesDriverSubformFullName } from '@/components/resources/forms/driver/subform-full-name/resources-driver-subform-full-name';
import { ResourcesDriverSubformPassport } from '@/components/resources/forms/driver/subform-passport/resources-driver-subform-passport';
import { ResourcesDriverSubformLicense } from '@/components/resources/forms/driver/subform-license/resources-driver-subform-license';
import { ResourcesDriverSubformDocuments } from '@/components/resources/forms/driver/subform-documents/resources-driver-documents';
import { UseDependentAttachmentsReturnValues } from '@/features/attachments/hooks/use-dependent-attachments';

interface Props {
  initialValues: DriverVerificationForm | undefined;
  driverInitialDocs: Partial<Record<ResourcesFormFieldNames, UseDependentAttachmentsReturnValues>>;
  mode: ResourcesSidebarMode;
  submitHandlers: Partial<Record<ResourcesSidebarMode, (values: ResourcesDriverFormValues) => void>>;
  submitButtonTexts: Partial<Record<ResourcesSidebarMode, string>>;
  closeForm: VoidFunction;
}

export const ResourcesDriverForm: FC<Props> = ({ initialValues, driverInitialDocs, mode, submitHandlers, submitButtonTexts, closeForm }) => {
  const validate = validateForm<DriverVerificationFormFields>(createDriversWithoutCarrierSchema);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={submitHandlers[mode] ?? emptyFunction}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="resources-form">
          <Heading as="h2" className="resources-form-title">
            {ResourcesDriverFormTitles[mode]}
          </Heading>
          <UiDivider />
          <Box className="resources-form-rows">
            <UiScrollbars autoHide style={listScrollStyle}>
              <ResourcesDriverSubformContacts />
              <ResourcesDriverSubformFullName />
              <ResourcesDriverSubformPassport />
              <ResourcesDriverSubformLicense />
              <ResourcesDriverSubformDocuments mode={mode} driverInitialDocs={driverInitialDocs} />
              <HStack className="resources-form-buttons">
                <UiButtonOutlined onClick={closeForm}>{ResourcesButtonActions.Cancel}</UiButtonOutlined>
                <UiButtonFilled type="submit" isDisabled={false} isLoading={false}>
                  {submitButtonTexts[mode]}
                </UiButtonFilled>
              </HStack>
            </UiScrollbars>
          </Box>
        </form>
      )}
    />
  );
};
