import { Box, InputProps, Input, AbsoluteCenter } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { UiTab, UiTabs, UiTabList, UiTabPanel, UiTabPanels } from '@/ui-elements/ui-tabs';
import { VERIFICATION_METRICS_ZERO_VALUE } from '@/features/approvals/constants/verification-page-tabs';
import { styles } from '@/ui-elements/inputs/ui-input/styles';
import './carriers-verification-filter.scss';
import { tabStyles } from './styles';
import { CarriersVerificationList } from '@/components/carriers-verification-filter/carriers-verification-list';
import { Approval } from '@/types/models/approval';
import { CarriersVerificationMetrics } from '@/types/models/metrics';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';

type Props = {
  tabLabels: Array<string>;
  activeTab: number;
  onChangeActiveTab: (index: number) => void;
  inputProps: InputProps;
  approvals: Array<Approval>;
  loading: boolean;
  fetchNextPage: (...args: any) => void;
  isFetchingNextPage?: boolean;
  metrics?: CarriersVerificationMetrics;
  setChecking: (arg0: boolean) => void;
};

export const CarriersVerificationFilter = ({
  tabLabels,
  onChangeActiveTab,
  inputProps,
  approvals,
  loading,
  metrics,
  fetchNextPage,
  setChecking,
}: Props) => {
  return (
    <Box className="carriers-verification-filter">
      <UiTabs variant="unstyled" onChange={onChangeActiveTab} isFitted w="100%">
        <UiTabList alignItems="center" w="100%" px={4}>
          <Input className="carriers-verification-filter__input" {...inputProps} {...styles} mr={3} />
          <UiTab className="carriers-verification-filter__tab_left" {...tabStyles}>
            {`${tabLabels[0]} (${metrics?.waiting ?? VERIFICATION_METRICS_ZERO_VALUE})`}
          </UiTab>
          <UiTab {...tabStyles}>{`${tabLabels[1]} (${metrics?.inProcessing ?? VERIFICATION_METRICS_ZERO_VALUE})`}</UiTab>
          <UiTab className="carriers-verification-filter__tab_right" {...tabStyles}>
            {`${tabLabels[2]} (${metrics?.processed ?? VERIFICATION_METRICS_ZERO_VALUE})`}
          </UiTab>
        </UiTabList>
        <UiTabPanels>
          {(tabLabels || []).map((k, i) => (
            <UiTabPanel key={tabLabels[i]}>
              {isEmpty(approvals) ? (
                <AbsoluteCenter>
                  <UiSpinner visible={loading} />
                </AbsoluteCenter>
              ) : (
                <CarriersVerificationList carriersVerificationList={approvals} loading={loading} loadMore={fetchNextPage} setChecking={setChecking} />
              )}
            </UiTabPanel>
          ))}
        </UiTabPanels>
      </UiTabs>
    </Box>
  );
};
