import { OrderStatus } from 'config/constants/statuses';
import { orderStatusMap } from 'config/order-status-map';
import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';

/*
 * Проверка доступности перехода в следующий статус согласно карте
 * */
export const checkOrderNextStatus = (order: Order | Load, nextStatus: OrderStatus): boolean => {
  const map = orderStatusMap[order?.status]?.possibleNextStatuses;
  if (!map) return false;
  if (map === '*') return true;
  return map.includes(nextStatus);
};
