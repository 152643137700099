import { useAppSelector } from '@/hooks/use-app-selector';
import { funnelPageSelectedLoadIDSelector } from '@/features/funnel/selectors/funnel-page-selected-load-id';
import { useDependentAttachments } from '@/features/attachments/hooks/use-dependent-attachments';
import { DocumentTypes } from '@/config/constants/document-types';
import { AttachmentDeps } from '@/config/constants/types';

export const useFunnelDocumentsComponent = () => {
  const activeLoadID = useAppSelector(funnelPageSelectedLoadIDSelector);

  const {
    attachments: documents,
    deleteAttachmentById,
    postAttachments,
    downloadAttachmentById,
  } = useDependentAttachments({
    dependent: AttachmentDeps.Load,
    dependentId: activeLoadID,
    type: DocumentTypes.dlDocumentLoad,
  });

  return {
    documents,
    deleteAttachmentById,
    postAttachments,
    downloadAttachmentById,
  };
};
