import { VehicleFormValues } from 'components/verification-forms/schema';
import { FormValues } from '../types';

export const createFormVehicleValues = (vehicle: FormValues, withoutTrailer?: boolean): VehicleFormValues => {
  const isWithoutTrailer = vehicle.vehicleGroup === 0 || (vehicle.vehicleGroup === 2 && withoutTrailer);
  return {
    carrierId: vehicle.carrierId?.value,
    vehicleType: vehicle.vehicleType[0],
    vehicleGroup: vehicle.vehicleGroup,
    volume: vehicle.volume || 0,
    capacity: vehicle.capacity || 0,
    regNumber: vehicle.regNumber,
    dimensions: vehicle.dimensions || '',
    accidentsInPastThreeYears: vehicle?.accidentsInPastThreeYears || false,
    bailed: vehicle?.bailed || false,
    casco: vehicle?.casco || false,
    ownType: vehicle.ownType,
    ...(vehicle.driverId?.value ? { driverId: vehicle.driverId.value } : null),
    // @ts-ignore
    loadingType: vehicle.loadingType?.map((x) => x.value) || [],
    tracker: vehicle?.tracker,
    permanentTracking: vehicle?.permanentTracking || false,
    trailers: !isWithoutTrailer
      ? vehicle.trailers?.map((t) => {
          return {
            accidentsInPastThreeYears: t.accidentsInPastThreeYears,
            vehicleType: vehicle.vehicleType[0],
            regNumber: t.regNumber || '',
            dimensions: t.dimensions || '',
            volume: t.volume || 0,
            capacity: t.capacity || 0,
          };
        }) || []
      : [],
  };
};
