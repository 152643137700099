import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { OrderStatusesType } from '@/types/models/order-statuses';
import { ordersActions } from '../slice';

export const useOrderStatusesActions = () => {
  const dispatch = useAppDispatch();

  const setOrderStatuses = (orderStatuses: OrderStatusesType) => {
    dispatch(ordersActions.setOrderStatuses(orderStatuses));
  };

  return { setOrderStatuses };
};
