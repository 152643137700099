import '../form/loads-form.scss';
import { FC } from 'react';
import { HStack, Heading } from '@chakra-ui/react';
import { getFoundLoadsTitle } from '@/features/loads/helpers/get-found-loads-title';
import { LoadsResetButton } from '@/components/loads/reset-button/reset-button';
import { LoadsUpdateButton } from '@/components/loads/update-button/update-button';

type Props = {
  allFound: number;
  disabled: boolean;
  handleRefetchLoads: () => void;
};

export const LoadsFormHeader: FC<Props> = ({ allFound = 0, disabled = false, handleRefetchLoads }) => {
  return (
    <HStack className="loads-form__header">
      <HStack spacing="6">
        <Heading className="loads-form__header-title">{getFoundLoadsTitle(!disabled ? allFound : 0)}</Heading>
        <LoadsResetButton handleRefetchLoads={handleRefetchLoads} />
      </HStack>
      <LoadsUpdateButton disabled={disabled} handleRefetchLoads={handleRefetchLoads} />
    </HStack>
  );
};
