import { Props as SelectProps } from 'react-select/dist/declarations/src';
import { FC } from 'react';
import { UiSelectFieldAsync } from '@/ui-elements/form-fields/ui-select-field/ui-select-field-async';
import { fetchCustomersOptionsWithExtras } from '@/api/fetch-customers-options';
import { useCustomersEnumWithExtrasUpdates } from '@/features/customers/hooks/use-customers-enum-with-extras-updates';

type Props = {
  label?: string;
  content?: string;
  name: string;
  required?: boolean;
  isDisabled?: boolean;
  disabled?: boolean;
  defaultInputValue?: string;
  overflown?: boolean;
} & Omit<SelectProps, 'defaultValue'>;

export const CustomerSelectField: FC<Props> = (props) => {
  const { carriersEnumWithExtras } = useCustomersEnumWithExtrasUpdates({
    query: '',
    extraParams: ['inn:ИНН'],
  });
  return <UiSelectFieldAsync fetchOptions={fetchCustomersOptionsWithExtras} defaultOptions={carriersEnumWithExtras} {...props} treshhold={0} />;
};
