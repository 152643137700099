import { useMutation } from '@tanstack/react-query';
import { CarriersApi } from '@/api/carriers';
import { CarrierDriverRequest, CarrierRequest, CarrierRequestFilter } from '@/types/api/carrier-partial';

export const useCarrierRequest = () => {
  const addCarriersRequest = useMutation({
    mutationKey: ['addCarrierRequest'],
    mutationFn: (options: CarrierDriverRequest) => CarriersApi.addCarrierRequest(options),
  });

  const addAllCarriersRequest = useMutation({
    mutationKey: ['addAllCarriersRequest'],
    mutationFn: (options: CarrierRequestFilter & CarrierRequest) => CarriersApi.addAllCarriersRequest(options),
  });
  return { addCarriersRequest, addAllCarriersRequest };
};
