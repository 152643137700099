import { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import './my-organization.scss';
import { MyOrganization } from '@/components/my-organization/my-organization';
import { useMyOrganization } from '@/features/my-organization/use-my-organization';
import { MyOrganizationConfirm } from '@/components/my-organization/my-organization-confirm-delete';

export const MyOrganizationView: FC = () => {
  const {
    onSubmit,
    initialValues,
    options,
    approvalStatuses,
    disabled,
    documents,
    organizationID,
    updateFile,
    downloadAttachmentById,
    confirmProps,
    isCarrierSimple,
    onOpenDeleteDocumentModal,
    setEditForm,
    isEditStatus,
    onToggleEditForm,
  } = useMyOrganization();
  return (
    <Flex align="center" justify="center" className="my-organization-form">
      {confirmProps.map((props) => {
        return <MyOrganizationConfirm {...props} />;
      })}
      <MyOrganization
        isCarrierSimple={isCarrierSimple}
        documents={documents}
        onSubmit={onSubmit}
        approvalStatuses={approvalStatuses}
        options={options}
        initialValues={initialValues}
        disabled={disabled}
        organizationID={organizationID}
        removeAttachment={onOpenDeleteDocumentModal}
        updateFile={updateFile}
        downloadAttachmentById={downloadAttachmentById}
        setEditForm={setEditForm}
        onToggleEditForm={onToggleEditForm}
        isEditStatus={isEditStatus}
      />
    </Flex>
  );
};
