import { FC, useRef } from 'react';
import { Text } from '@chakra-ui/react';
import MapClass from '../map/map';
import { useMapInit } from '../../helpers/map/use-map-init';
import './route-map.scss';
import { Order } from '@/types/models/order';
import { MarkerType } from '../map/types';
import { Load } from '@/types/models/loads';

type Props = {
  order?: Order | Load;
};

export const RouteMap: FC<Props> = ({ order }) => {
  const points = order?.points;
  const routeText = points?.map((point) => point.city).join(' - ');
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<MapClass | null>(null);
  const pointsToMap = points?.map((point): MarkerType => {
    return { coords: { lat: point.lat, lng: point.lon }, color: '#2F80ED' };
  });

  useMapInit(mapRef, pointsToMap, order?.distance);

  return (
    <div>
      {routeText && <Text className="route-map__text">{routeText}</Text>}
      <div className="map-container route-map" ref={mapContainerRef}>
        <div id="map" />
      </div>
    </div>
  );
};
