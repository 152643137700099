import { Box, Text, HStack, Flex, Spacer } from '@chakra-ui/react';
import { Form } from 'react-final-form';
import { UiButtonFilled, UiButtonOutlined, UiTextareaField } from '@/ui-elements';
import { REJECT_DOCUMENTS, ACCEPT_DOCUMENTS } from '@/components/order-details/order-details-documents/texts';
import { FileDocument } from '@/types/models/document';
import { UiFileUploadCard } from '@/ui-elements/inputs/ui-file-upload-input/ui-file-upload-card/ui-file-upload-card';
import { emptyFunction } from '@/helpers/empty-function';
import './order-point-with-icon.scss';

type Props = {
  documents?: Array<FileDocument>;
  downloadDocument?: VoidFunction;
  deleteDocument?: (arg0: number) => void;
  onAcceptDocuments?: VoidFunction;
  onRejectDocuments?: VoidFunction;
};

export const OrderPointDocumentsForm = ({
  documents = [],
  downloadDocument = emptyFunction,
  deleteDocument = emptyFunction,
  onAcceptDocuments,
  onRejectDocuments = emptyFunction,
}: Props) => (
  <Box>
    <Text textStyle="h3" mb={6}>
      Документы по точке
    </Text>
    <Text textStyle="p7" color="typography.50" mb={6}>
      Внимательно проверьте документы, которые загрузил Исполнитель. Если вы нашли ошибку, укажите причину Отклонения документов по точке в
      комментарии.
    </Text>
    <HStack spacing={0} wrap="wrap" gap={2} maxH="310px">
      {documents.map((document) => (
        <UiFileUploadCard
          fileName={document.name}
          onIconClick={downloadDocument}
          onDeleteClick={() => deleteDocument(document.id)}
          className="order-point__file-card"
        />
      ))}
    </HStack>
    <Form onSubmit={onRejectDocuments}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <UiTextareaField label="Комментарий" name="comment" variant="noResize" />
          <Flex w="full" justify="end" mt={6}>
            <HStack spacing={0}>
              <UiButtonOutlined type="submit" mode="dark" isDisabled={documents.length === 0 || submitting}>
                {REJECT_DOCUMENTS}
              </UiButtonOutlined>
              <Spacer w={4} />
              <UiButtonFilled isDisabled={documents.length === 0 || submitting} onClick={onAcceptDocuments}>
                {ACCEPT_DOCUMENTS}
              </UiButtonFilled>
            </HStack>
          </Flex>
        </form>
      )}
    </Form>
  </Box>
);
