import { useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { useEffect } from 'react';
import { useOrderActions } from '@/features/order/hooks/use-order-actions';
import { Order } from '@/types/models/order';

export const useOrderPage = (orders?: Array<Order>, activeOrderIds?: Array<number>, redirectId?: number) => {
  const { clearState, select } = useOrderActions();
  const navigate = useNavigate();

  useEffect(() => {
    if (!activeOrderIds?.length && orders?.length && !redirectId) {
      select(orders[0].id);
      const url = qs.stringifyUrl({
        url: '/orders',
        query: {
          id: orders[0].id,
        },
      });
      navigate(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, activeOrderIds, select, redirectId]);

  useEffect(
    () =>
      // при переключении на другую страницу сбрасывает стор с настройками страницы с заказами
      // TODO: подумать возможно это стоит сделать на уровне роутера?
      () =>
        clearState(),
    [clearState]
  );
};
