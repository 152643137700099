import { DocumentTypes } from '@/config/constants/document-types';
import { AttachmentDeps } from '@/config/constants/types';
import { useDependentAttachments } from '@/features/attachments/hooks/use-dependent-attachments';

export const useCarriersLoadsApplicationAgreement = ({ orderId }: { orderId?: number }) => {
  /* Оригинал договора-заявки */
  const applicationAgreementOriginalDocs = useDependentAttachments({
    dependent: AttachmentDeps.Order,
    dependentId: orderId,
    type: DocumentTypes.dlDocumentOrderToConfirm,
    staleTime: 3000,
  });

  /* Подписанные перевозчиком документы */
  const applicationAgreementSigned = useDependentAttachments({
    dependent: AttachmentDeps.Order,
    dependentId: orderId,
    type: DocumentTypes.dlDocumentOrderSigned,
    staleTime: 3000,
  });

  return {
    applicationAgreementOriginalDocs,
    applicationAgreementSigned,
  };
};
