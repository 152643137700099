import { FC } from 'react';
import { PageWrapper } from 'pages/page-wrapper';
import { Flex } from '@chakra-ui/react';
import { SettingsSidebarView } from '@/views/settings/settings-sidebar-view';
import { SettingsView } from '@/views/settings/settings-view';

export const SettingsPage: FC = () => (
  <PageWrapper>
    <Flex>
      <SettingsView />
      <SettingsSidebarView />
    </Flex>
  </PageWrapper>
);
