import { FormControl, FormErrorMessage, FormHelperText, FormLabel, InputProps } from '@chakra-ui/react';
import { useField } from 'react-final-form';
import { FormFieldName } from 'config/constants/form';
import { UiPasswordInput } from '@/ui-elements';
import { PASSWORD, PASSWORD_PLACEHOLDER } from '@/ui-elements/form-fields/ui-password-field/constants';

type Props = {
  name?: string;
  label?: string;
  content?: string;
  required?: boolean;
  hideClearButton?: boolean;
};

export const UiPasswordField = ({
  name = FormFieldName.password,
  label = PASSWORD,
  required,
  content,
  placeholder = PASSWORD_PLACEHOLDER,
  ...props
}: Props & InputProps) => {
  const { isDisabled } = props;
  const {
    input,
    meta: { error, touched },
  } = useField(name);
  const invalid = error && touched;

  return (
    <FormControl isRequired={required && !isDisabled} isInvalid={invalid}>
      <FormLabel mb={1} fontSize="xs" className="ui-field__label">
        {label}
      </FormLabel>
      <UiPasswordInput {...props} placeholder={placeholder} {...input} isInvalid={invalid} onClear={() => input.onChange('')} />
      {invalid ? <FormErrorMessage>{error}</FormErrorMessage> : <FormHelperText>{content}</FormHelperText>}
    </FormControl>
  );
};
