import { useState } from 'react';
import { OrderExecutionStatus, OrderStatus } from 'config/constants/statuses';
import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { orderExtender } from '@/features/order/extender/order-extender';

const HAS_PREPAYMENT_STATUS: Array<OrderStatus> = [OrderExecutionStatus.Executing, OrderExecutionStatus.Confirmed];

export const useOrderPrepayment = ({ vat, order }: { vat: boolean; order: Order | Load }) => {
  const prepaymentPossibility = order.prepayment?.possibility;
  const hasPrepayment = Boolean(prepaymentPossibility && HAS_PREPAYMENT_STATUS.includes(order?.status));

  const { getMaxPrepayment, getPrepaymentTax, getPaymentStep } = useExtendedObject(order, orderExtender);

  const [prepayment, setPrepayment] = useState(order.prepayment?.prepayment || 0);
  const tax = getPrepaymentTax(prepayment);

  const maxPrepayment = getMaxPrepayment(vat, order.prepayment?.fuel || 0);
  const prepaymentStep = getPaymentStep(maxPrepayment);

  return { setPrepayment, maxPrepayment, prepaymentStep, tax, prepayment, hasPrepayment, prepaymentPossibility };
};
