import { FC } from 'react';
import { isNil } from 'lodash';
import { SidebarWrapper } from 'components/sidebar-wrapper/sidebar-wrapper';
import { CurrentRouteDetails } from 'components/carriers-loads/details/current-route-details/current-route-details';
import { CarrierLoadsPageWithOrdersSidebarProps } from 'components/carriers-loads/sidebar/constants';

export const CurrentRoutesSidebar: FC<CarrierLoadsPageWithOrdersSidebarProps> = ({ closeSidebar, order, onOrderEnd }) => {
  if (isNil(order) || isNil(onOrderEnd)) return null;

  return (
    <SidebarWrapper closeSidebar={closeSidebar}>
      <CurrentRouteDetails order={order} onOrderEnd={onOrderEnd} />
    </SidebarWrapper>
  );
};
