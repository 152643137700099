import { useApprovalUpdates } from '@/features/approvals/hooks/use-approval-updates';
import { useApprovalsActions } from '@/features/approvals/hooks/use-approvals-actions';
import { useCarrier } from '@/features/carriers/hooks/use-carrier';
import { useCustomer } from '@/features/customers/hooks/use-customer';
import { useDriver } from '@/features/drivers/hooks/use-driver';
import { useSecurityActions } from '@/features/security/hooks/use-security-actions';
import { securityActiveApprovalSelector } from '@/features/security/selectors/security-active-approval-selector';
import { useVehicle } from '@/features/vehicles/hooks/use-vehicle';
import { useAppSelector } from '@/hooks/use-app-selector';
import { ApprovalBody } from '@/types/models/approval';

export const useVerificationSidebar = (setChecking: (arg0: boolean) => void) => {
  const approvalId = useAppSelector(securityActiveApprovalSelector);
  const { markApprovalApproved, markApprovalDeclined } = useApprovalsActions();
  const { clear } = useSecurityActions();

  const { data: approval, isInitialLoading, isRefetching } = useApprovalUpdates(approvalId);
  const isLoading = isInitialLoading || isRefetching;
  const record = approval?.data;
  const {
    carrier: { data: carrier },
  } = useCarrier(record?.carrier?.id);
  const {
    driver: { data: driver },
  } = useDriver(record?.driver?.id);
  const {
    vehicle: { data: vehicle },
  } = useVehicle(record?.vehicle?.id);
  const {
    customer: { data: customer },
  } = useCustomer(record?.customer?.id);
  // const { customer } = useCustomer(record?.customer?.id);

  const onAccept = (approvalBody: ApprovalBody) => {
    markApprovalApproved.mutate({
      approvalId: record?.id,
      comment: approvalBody.comment,
    });
    setChecking(false);
    clear();
  };
  const onDecline = (approvalBody: ApprovalBody) => {
    markApprovalDeclined.mutate({
      approvalId: record?.id,
      comment: approvalBody.comment,
    });
    setChecking(false);
    clear();
  };
  const onClose = () => setChecking(false);

  return {
    isLoading,
    carrier,
    driver,
    vehicle,
    customer,
    onAccept,
    onDecline,
    onClose,
    approval,
    record,
  };
};
