import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { normalizeFormValues } from '@/components/create-order-form/normalize-form-values';
import { FormValues, Namespaces } from '@/components/create-order-form/schema';
import { arrivalTimeTypeConfig, loadOptions, tonnageOptions, weightOptions } from '@/components/create-order-form/subforms/form-configs';
import { DocumentTypes } from '@/config/constants/document-types';
import { loadingTypes } from '@/config/constants/loading-types';
import { taxOptions } from '@/config/constants/tax-options';
import { AttachmentDeps, OrderLoadType } from '@/config/constants/types';
import { queryClient } from '@/config/query-client';
import { useDependentAttachments } from '@/features/attachments/hooks/use-dependent-attachments';
import { getCargoTypes } from '@/features/cargo/actions/get-cargo-types';
import { cargoTypesSelector } from '@/features/cargo/selectors/cargo-types-selector';
import { useActiveOrder } from '@/features/order/hooks/use-active-order';
// import { useOrder } from '@/features/order/hooks/use-order';
import { useOrderActions } from '@/features/order/hooks/use-order-actions';
import { useOrderDataActions } from '@/features/order/hooks/use-order-data-actions';
import { usePackages } from '@/features/packages/use-packages';
import { useVehicleTypesNames } from '@/features/vehicle-types/hooks/use-vehicle-types-names';
import { getEmptyStr } from '@/helpers/get-empty-str';
import { MOMENT_24_HOUR_TIME, MOMENT_DATEPICKER } from '@/helpers/moment/constants';
import { formatMomentLocal } from '@/helpers/moment/format-moment-local';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { useAppSelector } from '@/hooks/use-app-selector';
import { Point } from '@/types/models/point';
import { OrderNew } from '@/types/api/order-partial';
import { Cargos } from '@/types/models/cargos';
import { Order } from '@/types/models/order';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { editingOrderSelector } from '@/features/order/selectors/editing-order-selector';
import { checkHasVat } from '@/helpers/price/check-has-vat';

export const useOrderUpdateView = () => {
  const getPointDisplay = (
    point: Point,
    cargos: Array<Cargos>,
    type: OrderLoadType,
    packageTypes: Array<SelectOption>,
    cargoTypes: Array<SelectOption>,
    unloadCargoOptions: Array<SelectOption>
  ) => {
    return {
      id: point.id,
      address: { label: point.address, value: point.address },
      arrivalDate: formatMomentLocal(point.arrival, MOMENT_DATEPICKER, false, point.timezone),
      arrivalStartTime: formatMomentLocal(point.arrival, MOMENT_24_HOUR_TIME, false, point.timezone),
      arrivalEndTime: formatMomentLocal(point.arrival + (point?.arrivalDuration || 0), MOMENT_24_HOUR_TIME, false, point.timezone),
      arrivalTimeOption:
        // eslint-disable-next-line no-nested-ternary
        point.arrivalDuration === 86400
          ? arrivalTimeTypeConfig[2]
          : point.arrivalDuration === 0
          ? arrivalTimeTypeConfig[0]
          : arrivalTimeTypeConfig[1],
      coordinates: point.coordinates,
      type,
      cargos: cargos.map((l) => {
        return {
          id: l.tenderLotPositionID,
          cargoType: cargoTypes.find((x) => Number(x.value) === Number(l.type)),
          weight: l.properties.Mass,
          volume: l.properties.Volume,
          size: {
            length: l.properties.Length,
            width: l.properties.Width,
            height: l.properties.Height,
            diameter: l.properties.Diameter,
          },
          weightOptions: weightOptions.find((x) => x.label === l.properties.Units),
          quantity: l.quantity,
          package: packageTypes.find((x) => Number(x.value) === Number(l.package)),
          tenderLotID: l.tenderLotID,
          tenderLotPositionID: l.tenderLotPositionID,
          unloadCargo: type === OrderLoadType.Unloading && unloadCargoOptions.find((cargoOption) => cargoOption.value === l.id),
        };
      }),
    };
  };

  const getInitialControls = useCallback(
    (respData: Order, packageTypes: Array<SelectOption>, cargoTypes: Array<SelectOption>, unloadCargoOptions: Array<SelectOption>) => {
      const { points } = respData;
      const newPoints = points
        .map((point) => {
          const pointCargoIdsLoading = respData.actions
            .filter((action) => action.pointId === point.id && action.type === 1)
            .map((act) => act.cargoId);
          const pointCargoIdsUnloading = respData.actions
            .filter((action) => action.pointId === point.id && action.type === 0)
            .map((act) => act.cargoId);

          const cargoListLoading = respData.cargos.filter((cargo) => pointCargoIdsLoading.includes(cargo.tenderLotPositionID));
          const cargoListUnloading = respData.cargos.filter((cargo) => pointCargoIdsUnloading.includes(cargo.tenderLotPositionID));

          const newObjLoading =
            (cargoListLoading.length &&
              getPointDisplay(point, cargoListLoading, OrderLoadType.Loading, packageTypes, cargoTypes, unloadCargoOptions)) ||
            undefined;
          const newObjUnloading =
            (cargoListUnloading.length &&
              getPointDisplay(point, cargoListUnloading, OrderLoadType.Unloading, packageTypes, cargoTypes, unloadCargoOptions)) ||
            undefined;

          // eslint-disable-next-line no-nested-ternary
          return newObjLoading && newObjUnloading ? [newObjUnloading, newObjLoading] : newObjLoading ? [newObjLoading] : [newObjUnloading];
        })
        .flat();
      return newPoints.map((point) =>
        point?.cargos.map((cargo) => {
          const isMeasurements = cargo?.size.length || cargo?.size.width || cargo?.size.height || cargo?.size.diameter;
          const isPackage = cargo?.package && cargo?.quantity;
          if (isMeasurements && isPackage) {
            return [];
          }
          if (isMeasurements) {
            return [1];
          }
          if (isPackage) {
            return [2];
          }
          return [1, 2];
        })
      );
    },
    []
  );
  const { activeOrderId } = useActiveOrder();
  const { order: respData } = useAppSelector(editingOrderSelector);
  // const { isInitialLoading } = order;
  // const { data } = order;
  // const respData = data?.data;
  const responsiblePersonName = `${getEmptyStr(respData?.responsiblePerson.surname)} ${getEmptyStr(respData?.responsiblePerson.name)} ${getEmptyStr(
    respData?.responsiblePerson.patronymic
  )}`;
  // const packageTypes = dataPackageTypes ? normalizeSelectOptionsResponce(dataPackageTypes) : [];
  const vehicleTypesNames = useVehicleTypesNames();
  const cargoTypes = useAppSelector(cargoTypesSelector);

  const packageTypes = usePackages();

  // массив, хранящий грузы для связи точек погрузки и разгрузки (предоставляет в точках погрузки только грузы, существующие в точках погрузки)
  const unloadCargoOptions = respData?.cargos.map((cargo, index) => ({
    value: cargo.tenderLotPositionID,
    label: `Груз ${index + 1} ${cargoTypes.find((cargoType) => Number(cargoType.value) === Number(cargo.type))?.label} ${cargo.properties.Mass}/${
      cargo.properties.Volume
    }`,
  }));

  const updatedValues = useMemo(
    () => ({
      [Namespaces.orderInfo]: {
        responsiblePerson: { value: respData?.responsiblePerson.id, label: responsiblePersonName },
        riskGroup: { value: respData?.riskGroup, label: respData?.riskGroup },
        customerCompany: { value: respData?.customer?.id, label: respData?.customer?.name },
        contactPerson: respData?.contact.name,
        contactPhone: respData?.contact.phone,
        // @ts-ignore
        id: respData?.id,
      },
      [Namespaces.carrierPaymentTerms]: {
        hasVat: !respData ? true : checkHasVat(respData?.carrierPayment.vatType),
        withoutVat: true,
        priceWithVAT: respData?.carrierPayment.priceWithVAT,
        priceWithoutVAT: respData?.carrierPayment.priceWithoutVAT,
        paymentConditions: respData?.carrierPayment.paymentConditions,
        hasPrepayment: respData?.prepayment?.possibility,
        hasFuel: respData?.prepayment?.fuelPossibility,
        priceOffer: respData?.carrierPayment.priceOffer,
      },
      [Namespaces.clientPaymentTerms]: {
        vat: respData?.customerPayment.vat ? taxOptions.find((to) => to.value === 1) : taxOptions.find((to) => to.value === 0),
        price: respData?.customerPayment?.price,
        paymentTerms: respData?.customerPayment.paymentConditions,
        hasPrepayment: respData?.prepayment?.possibility,
      },
      [Namespaces.vehicleType]: {
        vehicleTypes: respData?.vehicleTypes.map((type) => Number(type)),
        loadingType: respData?.loadingType?.map((type) => {
          return {
            value: loadOptions.find((x) => x.label === type)?.value,
            label: loadOptions.find((x) => x.label === type)?.label,
          };
        }),
        additionalRequirements: respData?.additionalRequirements?.additionalRequirements,
        loadType: respData?.transportationType,
        belts: respData?.additionalRequirements?.belts,
        tonnage: tonnageOptions.find((x) => x.value === Number(respData?.additionalRequirements?.tonnage)),
        TIR: respData?.permissions?.TIR,
        CMR: respData?.permissions?.CMR,
        T1: respData?.permissions?.T1,
        medicalBook: respData?.permissions?.medicalBook,
      },
      [Namespaces.route]: {
        routeType: respData?.type,
        points: respData?.points
          .map((point) => {
            // массивы с точками погрузки и точками разгрузки (информация о типе достается из actions)
            const pointCargoIdsLoading = respData.actions
              .filter((action) => action.pointId === point.id && action.type === 1)
              .map((act) => act.cargoId);
            const pointCargoIdsUnloading = respData.actions
              .filter((action) => action.pointId === point.id && action.type === 0)
              .map((act) => act.cargoId);

            // массивы с грузами для погрузки и разгрузки (информация о типе достается из actions)
            const cargoListLoading = respData.cargos.filter((cargo) => pointCargoIdsLoading.includes(cargo.tenderLotPositionID));
            const cargoListUnloading = respData.cargos.filter((cargo) => pointCargoIdsUnloading.includes(cargo.tenderLotPositionID));
            const newObjLoading =
              (cargoListLoading.length &&
                getPointDisplay(point, cargoListLoading, OrderLoadType.Loading, packageTypes, cargoTypes, unloadCargoOptions as SelectOption[])) ||
              undefined;
            const newObjUnloading =
              (cargoListUnloading.length &&
                getPointDisplay(
                  point,
                  cargoListUnloading,
                  OrderLoadType.Unloading,
                  packageTypes,
                  cargoTypes,
                  unloadCargoOptions as SelectOption[]
                )) ||
              undefined;
            // eslint-disable-next-line no-nested-ternary
            return newObjLoading && newObjUnloading ? [newObjUnloading, newObjLoading] : newObjLoading ? [newObjLoading] : [newObjUnloading];
          })
          .flat(),
      },
      [Namespaces.state]: {
        cargo: respData ? getInitialControls(respData, packageTypes, cargoTypes, unloadCargoOptions as SelectOption[]) : [[[1, 2]]],
      },
      [Namespaces.requirements]: {
        loadingType: respData?.loadingType?.map((type) => {
          return { value: type, label: loadingTypes.find((x) => x.value === type)?.label };
        }),
        additionalRequirements: respData?.additionalRequirements,
        vehicleTypes: respData?.vehicleTypes.map((id) => {
          return { label: vehicleTypesNames[id], value: id };
        }),
      },
    }),
    [cargoTypes, respData, responsiblePersonName, packageTypes, vehicleTypesNames, unloadCargoOptions, getInitialControls]
  );

  const {
    attachments: documents,
    deleteAttachmentById: deleteDocuments,
    postAttachments: postDocuments,
    downloadAttachments: downloadDocuments,
  } = useDependentAttachments({
    dependent: AttachmentDeps.Order,
    dependentId: activeOrderId,
    type: DocumentTypes.dlDocumentOrder,
  });

  const { cancelUpdate } = useOrderActions();
  const { updateOrder } = useOrderDataActions(activeOrderId!);
  const dispatch = useAppDispatch();

  const onUpdateOrder2 = (ord: OrderNew) => {
    updateOrder.mutate(ord, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['orders'] });
        queryClient.invalidateQueries({ queryKey: ['orderWithoutUpdate'] });
        toast.success('Заявка успешно сохранена');
        dispatch(getCargoTypes());
        cancelUpdate();
      },
    });
  };

  const onUpdateOrder = (values: OrderNew) => {
    onUpdateOrder2(values);
  };

  const onSubmit = (values: FormValues) => {
    try {
      const normalizedValues = normalizeFormValues(values);
      onUpdateOrder?.(normalizedValues);
    } catch (error) {
      console.error(error);
    }
  };
  return {
    // isInitialLoading,
    updatedValues,
    documents,
    deleteDocuments,
    postDocuments,
    downloadDocuments,
    onSubmit,
    submitting: updateOrder.isLoading,
    cancelUpdate,
    cargos: respData?.cargos,
    order: respData,
  };
};
