import { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { CustomersManager } from 'components/customers/customers-managers-list/customers-manager';
import './customers-managers-list.scss';
import { ManagerSimple } from '@/types/models/manager';
import { UserRole } from '@/types/api/user-role';
import { CreateOrderFromCustomersButton } from '@/components/customers/create-order-from-customers-button/create-order-from-customers-button';

type Props = {
  data: Array<ManagerSimple>;
  handleAddOrder: () => void;
  isLoading?: boolean;
  currentRole?: UserRole;
};
export const CustomersManagersList: FC<Props> = ({ data, handleAddOrder, isLoading, currentRole }) => {
  const minimum = Math.min(...data.map((m) => m.customerQuantity || 0));
  const maximum = Math.max(...data.map((m) => m.customerQuantity || 0));

  return (
    <Flex flexDirection="column" m="24px" gap="24px">
      <Text className="customers-managers-list__title">Ответственные и количество клиентов</Text>
      <Box>
        {data.map((manager) => (
          <Flex flexDirection="column" mt="16px" key={manager?.id}>
            <CustomersManager manager={manager} max={maximum} min={minimum} amount={data.length} />
          </Flex>
        ))}
      </Box>
      {currentRole === UserRole.Administrator && <CreateOrderFromCustomersButton handleAddOrder={handleAddOrder} isLoading={isLoading} />}
    </Flex>
  );
};
