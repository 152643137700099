import { Field, FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { FC } from 'react';

type Props = {
  field: string;
  isApplicable?: (value: any) => boolean;
  set: string;
  to: (value: any) => any;
};
export const WhenFieldChanges: FC<Props> = ({ field, isApplicable = () => true, set, to }) => (
  <Field name={set} subscription={{}}>
    {({ input: { onChange } }) => (
      <FormSpy subscription={{}}>
        {() => (
          <OnChange name={field}>
            {(value) => {
              if (isApplicable(value)) {
                onChange(to(value));
              }
            }}
          </OnChange>
        )}
      </FormSpy>
    )}
  </Field>
);
