import { Field, Form } from 'react-final-form';
import { Box, Spacer, Text, VStack } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import arrayMutators from 'final-form-arrays';
import { UiButtonFilled, UiButtonOutlined, UiDivider, UiFileUploadField, UiFileUploadInput } from '@/ui-elements';
import { RouteSubform } from '@/components/create-order-form/subforms/ati-su-route';
import { VehicleTypeSubform } from '@/components/create-order-form/subforms/ati-su-vehicle-type';
import { OrderInfoSubform } from '@/components/create-order-form/order-info-subform';
import { PaymentTermsClientSubform } from '@/components/create-order-form/subforms/payment-terms-client-subform';
import { PaymentTermsSubform } from '@/components/create-order-form/subforms/payment-terms-subform';
import { FormValues } from '@/components/create-order-form/schema';
import { FileDocument } from '@/types/models/document';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { usePackages } from '@/features/packages/use-packages';
import { Cargos } from '@/types/models/cargos';
// import { useOrderInvalidate } from '@/features/order/hooks/use-order-invalidate';
import { ErrorMessage } from '@/helpers/forms/validation-schemas/error-messages';

type Props = {
  isUpdating?: boolean;
  onSubmit: (values: FormValues) => void;
  initialValues: FormValues;
  documents?: Array<FileDocument>;
  deleteDocuments?: (documentId: number) => void;
  postDocuments?: (files: Array<File>) => void;
  downloadDocuments?: VoidFunction;
  cargos?: Array<Cargos>;
  submitting?: boolean;
  onCancel: VoidFunction;
};

export const OrderForm = ({
  isUpdating,
  onSubmit,
  initialValues,
  documents,
  deleteDocuments,
  postDocuments,
  downloadDocuments,
  cargos,
  submitting,
  onCancel,
}: Props) => {
  const packageTypes = usePackages();
  // const { orderInvalidate } = useOrderInvalidate();
  // const { allOrdersInvalidate } = useOrderInvalidate();

  const validate = (values: FormValues) => {
    const errors: any = { vehicleType: { vehicleTypes: undefined } };
    if (isEmpty(values.vehicleType?.vehicleTypes)) {
      errors.vehicleType.vehicleTypes = ErrorMessage.Required;
    }
    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      keepDirtyOnReinitialize
      validate={validate}
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <Box h="83vh">
            <UiScrollbars autoHide>
              <VStack align="left" mr={4} overflowX="hidden">
                <Field name="state.cargo" render={() => null} style={{ display: 'none' }} />
                <OrderInfoSubform namespace="orderInfo" form={form} isUpdating={isUpdating} />
                {/* <UiDivider variant="extraDark" />
                <CarrierSubform namespace="carrier" /> */}
                <UiDivider variant="extraDark" />
                <RouteSubform cargos={cargos} namespace="route" form={form} packageTypes={packageTypes} />
                <UiDivider py={4} variant="extraDark" />
                <VehicleTypeSubform namespace="vehicleType" />
                <UiDivider py={4} variant="extraDark" />
                <PaymentTermsClientSubform namespace="clientPaymentTerms" />
                <UiDivider py={4} variant="extraDark" />
                {/* @ts-ignore */}
                <PaymentTermsSubform namespace="carrierPaymentTerms" isUpdating={isUpdating} />
                {!isUpdating ? (
                  <>
                    <Text textStyle="h3" align="left" mb={4} mt={6}>
                      Документы по заявке
                    </Text>
                    <UiFileUploadField name="documents" withDownloadButton />
                  </>
                ) : (
                  <UiFileUploadInput
                    savedFileList={documents}
                    name="documents"
                    onDeleteFileClick={deleteDocuments}
                    onIconClick={downloadDocuments}
                    onImmediateUploadFile={postDocuments}
                  />
                )}
              </VStack>
            </UiScrollbars>
          </Box>
          <Box display="flex" w="100%" py="20px">
            <UiButtonOutlined flexGrow={1} mode="dark" onClick={onCancel}>
              Отменить
            </UiButtonOutlined>
            <Spacer maxW="10px" />
            <UiButtonFilled type="submit" flexGrow={1} isDisabled={submitting} isLoading={submitting}>
              Сохранить заявку
            </UiButtonFilled>
          </Box>
        </form>
      )}
    />
  );
};
