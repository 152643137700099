import { regex as vehicleRegNumberRegEx, regexTrailer as trailerRegNumberRegEx } from 'components/carrier-card/carrier-card-driver-info/constants';
import { ErrorMessage } from '@/helpers/forms/validation-schemas/error-messages';

export const validateVehicleRegNumber = (vehicleRegNumber: string) => {
  const regNumberUpper = vehicleRegNumber.toUpperCase();
  if (!regNumberUpper?.match(vehicleRegNumberRegEx)) {
    return ErrorMessage.Vehicle_Reg_Number;
  }
  return null;
};

export const validateTrailerRegNumber = (trailerRegNumber: string) => {
  const trailerNumberUpper = trailerRegNumber.toUpperCase();
  if (!trailerNumberUpper?.match(trailerRegNumberRegEx)) {
    return ErrorMessage.Trailer_Reg_Number;
  }
  return null;
};
