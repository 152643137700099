import { OffersApi } from '@/api/offers';
import { ResponseWithPager } from '@/types/models/pager';
import { OrderOffer } from '@/types/models/order-offer';
import { useQueryWithUpdates } from '@/hooks/use-query-with-updates';

export const useOffersUpdates = (id?: number) => {
  const queryFn = (isFirst: boolean) => OffersApi.getOffers(id!, !isFirst);

  const offers = useQueryWithUpdates<ResponseWithPager<OrderOffer>>({
    queryKey: ['offers', id],
    refetchOnWindowFocus: false,
    enabled: !!id,
    queryFn,
  });
  return { offers };
};
