import TimePicker from 'react-time-picker';
import { FormErrorMessage, FormControl, FormLabel, FormHelperText } from '@chakra-ui/react';
import { useField } from 'react-final-form';
import './ui-time-input-field.scss';

type Props = {
  name: string;
  isDisabled?: boolean;
  required?: boolean;
  label?: string;
  content?: string;
  maxWidth?: string;
};

export const UiTimeInputField = ({ name, isDisabled, required, label, content, maxWidth }: Props) => {
  const {
    input,
    meta: { error, touched },
  } = useField(name);
  const invalid = touched && error;
  return (
    <FormControl style={{ maxWidth }} isRequired={required && !isDisabled} isInvalid={invalid}>
      <FormLabel mb={1} fontSize="xs" className="ui-field__label">
        {label}
      </FormLabel>
      <TimePicker hourPlaceholder="00" minutePlaceholder="00" onChange={input.onChange} value={input.value} format="HH:mm" />
      {invalid ? <FormErrorMessage>{error}</FormErrorMessage> : <FormHelperText>{content}</FormHelperText>}
    </FormControl>
  );
};
