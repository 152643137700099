import { FC } from 'react';
import { GridItem } from '@chakra-ui/react';
import { UiSelectFieldAsync } from '@/ui-elements';
import { fetchCarriersOptions } from '@/api/fetch-carriers-options';
import { useCarriersEnum } from '@/features/carriers/hooks/use-carriers-enum';

export const DriverCarriersSubform: FC = () => {
  const { organizationsEnumData } = useCarriersEnum();
  return (
    <GridItem mt={5}>
      <UiSelectFieldAsync
        fetchOptions={fetchCarriersOptions}
        name="carrierId"
        required
        label="К какой организации относится"
        treshhold={1}
        defaultOptions={organizationsEnumData}
      />
    </GridItem>
  );
};
