import classNames from 'classnames';
import { TableDataSource, TableConfig } from '@/types/common/table';
import { UiTableHead, UiTr, UiTd, UiTfoot } from '@/ui-elements/ui-table';
import './ui-table.scss';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';

type Props = {
  className?: string;
  dataSource: TableDataSource;
  config: TableConfig;
};

export const UiTable = ({ className, dataSource, config }: Props) => (
  <table className={classNames(className, 'ui-table')}>
    <UiTableHead config={config} />
    <tbody className="ui-table__body">
      <UiScrollbars autoHide style={{ width: '100%', height: '100vh' }}>
        <div className="ui-table__body-container">
          {dataSource.map((item) => (
            <UiTr className={config.rowClassName}>
              {(Object.values?.(item) || []).map((val) => (
                <UiTd key={val} className={config.cellClassName}>
                  {val}
                </UiTd>
              ))}
            </UiTr>
          ))}
        </div>
      </UiScrollbars>
    </tbody>
    <UiTfoot footerData={config.footerData} />
  </table>
);
