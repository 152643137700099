import { FC } from 'react';
import { LoadingType, loadingTypes } from 'config/constants/loading-types';
import { UiTooltip } from '@/ui-elements/tooltip/tooltip';
import { UiText } from '@/ui-elements/ui-text/ui-text';

type Props = {
  loadingType?: Array<LoadingType>;
};
export const LoadingTypeComponent: FC<Props> = ({ loadingType = [] }) => {
  const loadingTypeCaption = loadingType?.map((type) => loadingTypes.filter((x) => x.value === type)?.[0]?.label).join(', ');
  const loadingTypeTooltipCaption = loadingType?.map((type) => loadingTypes.filter((x) => x.value === type)?.[0]?.label).join(', ') || 'не указана';
  return (
    <UiTooltip label={loadingTypeTooltipCaption}>
      <UiText noOfLines={1} color="products.200">
        {loadingType.length ? `Погрузка: ${loadingTypeCaption}` : 'Погрузка'}
      </UiText>
    </UiTooltip>
  );
};
