import { PointForm } from 'components/create-order-form/schema';
import { TimeType } from 'components/create-order-form/subforms/form-configs';
import moment from 'moment';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { ONE_DAY_IN_SECONDS } from '@/helpers/forms/point-cargo-actions/extract-point-cargos-actions';

export const getArrivalDuration = (point: PointForm) => {
  const timeType = point?.arrivalTimeOption.value;

  if (timeType === TimeType.simple) {
    return ONE_DAY_IN_SECONDS;
  }
  if (timeType === TimeType.exact) {
    return 0;
  }

  const { arrivalDate, arrivalStartTime, arrivalEndTime } = point;

  return moment(`${arrivalDate} ${arrivalEndTime || EMPTY_STRING}`).unix() - moment(`${arrivalDate} ${arrivalStartTime || EMPTY_STRING}`).unix();
};
