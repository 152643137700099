import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { FC, useState } from 'react';
import plus from 'public/assets/icons/AddIcon.svg';
import edit from 'public/assets/icons/EditIcon.svg';
import { InfoLine } from '@/components';
import { MyOrganizationBanking } from '@/types/models/my-organization/my-organization';
import { UiButtonOutlined } from '@/ui-elements';
import { useIcons } from '@/hooks/use-icons';
import { ModalAction, useModal } from '@/hooks/use-modal';
import { ModalType } from '@/types/common/modals';
import { useOrganizationActions } from '@/features/my-organizations/hooks/use-organization-actions';
import { DASH_WITH_SPACES } from '@/config/constants/common-strings';

type Props = {
  data: Array<MyOrganizationBanking>;
  onBankingAdd: (arg0: boolean) => void;
  organizationId: number;
  hasEditRight?: boolean;
};
export const MyOrganizationsPaymentInfoCard: FC<Props> = ({ data, onBankingAdd, organizationId, hasEditRight }) => {
  const { DeleteIcon, DocumentsAccepted } = useIcons();
  const { deleteOrganizationBanking, updateOrganizationBanking } = useOrganizationActions(organizationId);
  const [selected, setSelected] = useState<MyOrganizationBanking>({} as MyOrganizationBanking);
  const { modalView: editBankingModal, setModalOpened } = useModal<ModalAction & { mode: string; initialValues: any }>(ModalType.OrganizationModal, {
    onSubmit: (values: any) => {
      updateOrganizationBanking.mutate(
        {
          bankId: selected.id,
          options: values.bank,
        },
        {
          onSuccess: () => setModalOpened(false),
        }
      );
    },
    mode: 'editBanking',
    initialValues: { bank: selected },
  });
  const { modalView: confirmDeleteModal, setModalOpened: setDeleteModalOpened } = useModal(ModalType.Confirm, {
    onSuccess: () => deleteOrganizationBanking.mutate(selected.id),
    modalWidth: 524,
    text: `Вы действительно хотите удалить эти реквизиты?`,
    cancelButtonText: 'Отменить',
    submitButtonText: 'Удалить',
    accentXBtn: true,
  });
  if (!data) return null;
  return (
    <VStack align="start" mt={6}>
      <Text textStyle="h2">Банковские реквизиты</Text>
      {hasEditRight && (
        <UiButtonOutlined onClick={onBankingAdd as any} leftIcon={plus}>
          Добавить счет
        </UiButtonOutlined>
      )}
      <VStack textStyle="h5" pt={4} align="start">
        {data.map((item) => (
          <Box key={item?.id}>
            <InfoLine text="БИК:" value={item?.bik} />
            <InfoLine text="Наименование банка:" value={item?.name} />
            <InfoLine text="Корр. счет:" value={item?.correspondentAccount} />
            <InfoLine text="Номер расчетного счета:" value={item?.bankAccount} />
            <HStack alignItems="center">
              {item?.default ? (
                <InfoLine text="Реквизиты по умолчанию:" icon={<DocumentsAccepted />} />
              ) : (
                <InfoLine text="Реквизиты по умолчанию:" value={DASH_WITH_SPACES} />
              )}
            </HStack>
            {hasEditRight && (
              <Flex gap="6px">
                <UiButtonOutlined
                  mode="dark"
                  leftIcon={edit}
                  onClick={() => {
                    setSelected(item);
                    setModalOpened(true);
                  }}
                >
                  Редактировать реквизиты
                </UiButtonOutlined>
                <UiButtonOutlined
                  mode="dark"
                  leftIcon={DeleteIcon}
                  onClick={() => {
                    setSelected(item);
                    setDeleteModalOpened(true);
                  }}
                >
                  Удалить реквизиты
                </UiButtonOutlined>
              </Flex>
            )}
          </Box>
        ))}
      </VStack>
      {editBankingModal}
      {confirmDeleteModal}
    </VStack>
  );
};
