import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@/config/constants/query-keys';
import { ResourcesDriverFormValues } from '@/features/resources/constants/form';
import { useDriverActions } from '@/features/drivers/hooks/use-driver-actions';
import { driverFormValuesToData } from '@/features/resources/helpers/driver-form-values-to-data';

type Props = {
  driverId: number | null;
  closeForm: VoidFunction;
};

export const useEditResourcesDriver = ({ driverId, closeForm }: Props) => {
  const client = useQueryClient();
  const { updateDriver } = useDriverActions(driverId!);

  const handleEditDriver = (values: ResourcesDriverFormValues) => {
    const newValues = driverFormValuesToData(values);
    updateDriver.mutate(newValues, {
      onSuccess: (response: any) => {
        const { id } = response.data;
        client.invalidateQueries([QueryKeys.allDrivers]);
        client.invalidateQueries([QueryKeys.driver, id]);
        toast.success('Водитель успешно изменен');
        closeForm();
      },
    });
  };
  return { handleEditDriver };
};
