import { WidgetConfig } from '@/features/widget/types';
import { SetCarrierWidgetView } from '@/views/set-carrier-widget/set-carrier-widget';
import { TripWidgetView } from '@/views/trip-widget/trip-widget-view';
import { DriverWidgetView } from '@/views/driver-widget/driver-widget';
import { CarrierReviewsWidgetView } from '@/views/carrier-reviews-widget/carrier-reviews-widget';
import { AddServiceWidgetView } from '@/views/add-service-widget/add-service-widget';
import { CarrierWidgetView } from '@/views/carrier-widget/carrier-widget';
import { VehicleWidgetView } from '@/views/vehicle-widget/vehicle-widget';
import { EditServiceWidgetView } from '@/views/edit-service-widget-view/edit-service-widget-view';

export const widgetConfig: WidgetConfig = {
  widgets: {
    DriverWidget: {
      render: (data, props) => <DriverWidgetView id={data.id} {...props} />,
      title: 'Информация о водителе',
    },
    SetCarrierWidget: {
      render: (data, props) => <SetCarrierWidgetView orderId={data.orderId} orderOffer={data.orderOffer} offerId={data.offerId} {...props} />,
      title: (data) => data.title,
    },
    TripWidget: {
      render: (data, props) => <TripWidgetView orderId={data.orderId} carrierId={data.carrierId} {...props} />,
      title: (data) => `Заявка № ${data.orderId}`,
    },
    CarrierWidget: {
      render: (data, props) => <CarrierWidgetView carrierId={data.carrierId} {...props} />,
      title: 'Информация о перевозчике',
    },
    CarrierReviewsWidget: {
      render: (data, props) => <CarrierReviewsWidgetView carrierId={data.carrierId} {...props} />,
      title: 'Отзывы о перевозчике',
    },
    AddServiceWidget: {
      render: (data, props) => <AddServiceWidgetView orderId={data.orderId} orderMode={data.orderMode} companyName={data.companyName} {...props} />,
      title: 'Добавление дополнительной услуги',
    },
    EditServiceWidget: {
      render: (data, props) => <EditServiceWidgetView service={data.service} order={data.order} carrierName={data.carrierName} {...props} />,
      title: 'Редактирование дополнительной услуги',
    },
    VehicleWidget: {
      render: (data, props) => <VehicleWidgetView vehicle={data.vehicle} {...props} />,
      title: 'Информация о транспортном средстве',
    },
  },
};
