import { FC, useCallback, useEffect, useState } from 'react';
import { HStack, VStack } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { ClientContractTemplateType, TemplateData } from '@/api/documents/templates-types';
import { generateReportFromJson } from '@/helpers/documents/generate-report-from-json';
import { UiButtonFilled, UiButtonOutlined } from '@/ui-elements';

declare const Stimulsoft: any;

export const DocumentTemplateViewer: FC<{
  scrollbarsMode?: boolean;
  templateData?: TemplateData | ClientContractTemplateType;
  onReportUpdate?: (report: string) => void;
  height?: string;
  closeModal: VoidFunction;
  postAttachments?: (files: File[], onSuccessCallback?: VoidFunction) => void;
}> = ({ scrollbarsMode, templateData, postAttachments, onReportUpdate, height, closeModal }) => {
  const [report, setReport] = useState<any>();
  const [edit, setEdit] = useState(false);

  const onSave = useCallback(
    (args: any) => {
      onReportUpdate?.(args.report.saveToJsonString());
      setEdit(false);
    },
    [onReportUpdate]
  );

  const createViewer = useCallback(() => {
    const options = new Stimulsoft.Viewer.StiViewerOptions();
    options.toolbar.showDesignButton = !!onReportUpdate;
    options.appearance.scrollbarsMode = scrollbarsMode;
    if (height) options.height = height;
    const viewerInst = new Stimulsoft.Viewer.StiViewer(options, 'StiViewer', false);
    viewerInst.renderHtml('reportViewer');
    viewerInst.report = report;
    viewerInst.onDesignReport = () => {
      setEdit(true);
    };
  }, [height, onReportUpdate, report, scrollbarsMode]);

  const createDesigner = useCallback(() => {
    const options = new Stimulsoft.Designer.StiDesignerOptions();
    options.appearance.allowChangeWindowTitle = false;
    options.appearance.showSaveDialog = false;
    if (height) options.height = height;
    const designerInst = new Stimulsoft.Designer.StiDesigner(options, 'StiDesigner', false);
    designerInst.renderHtml('reportDesigner');
    designerInst.report = report;
    designerInst.onSaveReport = onSave;
    designerInst.onSaveAsReport = onSave;
  }, [height, onSave, report]);

  useEffect(() => {
    Stimulsoft.Base.StiLicense.loadFromFile('/assets/scripts/stimulsoft/license.key');
    Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile('/assets/scripts/stimulsoft/ru.xml');
  }, []);

  useEffect(() => {
    if (!templateData) return;
    setReport(generateReportFromJson(templateData));
  }, [templateData]);

  useEffect(() => {
    if (!report) return;

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    edit ? createDesigner() : createViewer();
  }, [edit, report, createDesigner, createViewer]);

  const onAddDocument = () => {
    if (report) {
      report.renderAsync(() => {
        const blob = new Blob([new Uint8Array(report.exportDocument(Stimulsoft.Report.StiExportFormat.Word2007))], { type: 'application/msword' });
        const fileName = report.reportAlias;
        const file = new File([blob], `${fileName}.docx`);

        postAttachments?.([file], () => toast.success('Договор-заявка добавлен'));
      });
    } else {
      toast.error('Не удалось сгенерировать договор-заявку', { toastId: 'fail_to_generate_report' });
    }
    closeModal();
  };

  return (
    <>
      {!edit && (
        <VStack>
          <div id="reportViewer" />
          <HStack mt={7} justifyContent="right" mr={5}>
            <UiButtonOutlined mode="dark" className="modal__button-cancel" onClick={closeModal}>
              Закрыть
            </UiButtonOutlined>
            <UiButtonFilled onClick={onAddDocument}>Добавить договор-заявку</UiButtonFilled>
          </HStack>
        </VStack>
      )}
      {edit && <div id="reportDesigner" />}
    </>
  );
};
