import './funnel-documents-details.scss';
import { HStack, VStack } from '@chakra-ui/react';
import { UiText } from '@/ui-elements/ui-text/ui-text';
import { UiFileUploadInput } from '@/ui-elements';
import { useFunnelDocumentsComponent } from '@/features/funnel/hooks';

export const FunnelDocumentsDetails = () => {
  const { documents, deleteAttachmentById, postAttachments, downloadAttachmentById } = useFunnelDocumentsComponent();

  return (
    <VStack alignItems="stretch" className="funnel-documents-details">
      <UiText className="funnel-documents-details__caption">Документы</UiText>
      <HStack>
        <UiFileUploadInput
          name="file"
          savedFileList={documents}
          onDeleteFileClick={deleteAttachmentById}
          onIconClick={downloadAttachmentById}
          onImmediateUploadFile={postAttachments}
        />
      </HStack>
    </VStack>
  );
};
