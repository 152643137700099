import { FC } from 'react';
import { Text } from '@chakra-ui/react';
import './auth-form.scss';
import { ENTER } from 'components/auth-form/constants';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { UiPasswordField } from '@/ui-elements';
import { UiButtonFilled } from '@/ui-elements/button/button-filled';
import { emptyFunction } from '@/helpers/empty-function';
import { useForm } from '@/helpers/forms/use-form';
import { Credentials } from '@/types/api/credentials';
import { UiPhoneField } from '@/ui-elements/form-fields/ui-phone-field/ui-phone-field';

type Props = {
  onSubmit?: (credentials: Credentials) => void;
  loginError?: string;
};
export const AuthForm: FC<Props> = ({ onSubmit = emptyFunction, loginError }) => {
  const Form = useForm<Credentials>();
  return (
    <Form
      initialValues={{
        login: EMPTY_STRING,
        password: EMPTY_STRING,
      }}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      className="auth-form"
    >
      <div className="auth-form-fields">
        {loginError && (
          <Text whiteSpace="nowrap" color="accentRed.150">
            {loginError}
          </Text>
        )}
        <UiPhoneField name="login" />
        <UiPasswordField />
        <UiButtonFilled type="submit" width="full">
          {ENTER}
        </UiButtonFilled>
      </div>
    </Form>
  );
};
