import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { AllowedTo } from 'react-abac';
import { routesPath } from 'config/routes/routes-path';
import { ForbiddenCautionComponent } from 'components/forbidden-caution/forbidden-caution';
import { useCheckAuth } from '@/features/account/hooks/use-check-auth';

type Props = { path: string; children: ReactNode };
const forbidden = () => <ForbiddenCautionComponent />;
export const ProtectedPage: FC<Props> = ({ path, children }) => {
  const { authenticated, authenticationPending, location, logoutPending } = useCheckAuth();

  if (!authenticated && !authenticationPending) {
    return <Navigate to={`/${routesPath.LogIn}`} state={!logoutPending ? { from: location } : undefined} />;
  }
  return (
    <AllowedTo perform={path} no={forbidden}>
      {children}
    </AllowedTo>
  );
};
