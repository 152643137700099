import { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { VerificationFormMode } from 'components/verification-forms/types';
import { ADD_VEHICLE_FOR_ORGANIZATION_CONTENT } from 'components/verification-forms/constants';
import { UiIconButton } from '@/ui-elements';
import { useIcons } from '@/hooks/use-icons';

export const VehicleHeaderSubform: FC<{ onClose?: VoidFunction; mode: VerificationFormMode; title: string }> = ({ onClose, mode, title }) => {
  const { CloseIcon } = useIcons();

  return mode === 'check' ? (
    <>
      <Text className="carriers-verification-form__title">Вы проверяете транспортное средство:</Text>
      <Box pos="absolute" right={5} top={0}>
        <UiIconButton aria-label="close" icon={CloseIcon} onClick={onClose} />
      </Box>
    </>
  ) : (
    <Box mb={5}>
      <Text fontSize={24} fontWeight={700}>
        {title ?? ADD_VEHICLE_FOR_ORGANIZATION_CONTENT}
      </Text>
    </Box>
  );
};
