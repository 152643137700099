import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrdersApi } from '@/api/orders';

export const getOrder = createAsyncThunk('ORDER/GET', async (id: number, { rejectWithValue }) => {
  try {
    const { data } = await OrdersApi.getOrder(id);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
