import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CargoTypesState } from '@/features/cargo/types';
import { getCargoTypes } from '@/features/cargo/actions/get-cargo-types';

const initialState: CargoTypesState = {
  cargoTypes: {},
  isLoading: false,
  error: undefined,
};

const slice = createSlice({
  name: 'cargoTypes',
  initialState,
  reducers: {},
  extraReducers: {
    [getCargoTypes.pending.type]: (state: CargoTypesState) => {
      state.isLoading = true;
      state.error = undefined;
      return state;
    },
    [getCargoTypes.fulfilled.type]: (state: CargoTypesState, action: PayloadAction<{ [key: number]: string }>) => {
      state.isLoading = false;
      state.cargoTypes = action.payload;
      return state;
    },
    [getCargoTypes.rejected.type]: (state: CargoTypesState, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      return state;
    },
  },
});

export const cargoTypesActions = slice.actions;
export const cargoTypesReducer = slice.reducer;
