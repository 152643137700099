export enum NotificationActionType {
  carrierSearch = 'carrierSearch',
  preparation = 'preparation',
  newOrderDocument = 'newOrderDocument',
  orderDocumentDeclined = 'orderDocumentDeclined',
  confirmed = 'confirmed',
  confirmedCanceled = 'confirmedCanceled',
  executing = 'executing',
  newOrderPointDocument = 'newOrderPointDocument',
  executionCompleted = 'executionCompleted',
}
