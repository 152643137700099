import { FC } from 'react';
import { CustomersSearchFilter } from 'components/customers/сustomers-filter/type';
import { Box, Flex } from '@chakra-ui/react';
import { FormSpy } from 'react-final-form';
import { useForm } from '@/helpers/forms/use-form';
import { emptyFunction } from '@/helpers/empty-function';
import { UiButtonOutlined, UiInputField, UiSelectField } from '@/ui-elements';
import { useIcons } from '@/hooks/use-icons';
import './customers-filter.scss';
import { CustomersFilterFields } from '@/features/customers/hooks/customers-filter';
import { FormAutosave } from '@/hooks/use-autosave';
import { UserRole } from '@/types/api/user-role';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { CustomerSelectField } from '@/components/form-field/customer-select-field/customer-select-field';

type Props = {
  onSubmit?: (searchOptions: CustomersSearchFilter) => void;
  showForm?: () => void;
  handleFilterChange?: (values: CustomersFilterFields) => void;
  role?: string;
  managersSelect?: SelectOption[];
};
export const CustomersFilter: FC<Props> = ({
  onSubmit = emptyFunction,
  showForm = emptyFunction,
  handleFilterChange = emptyFunction,
  role,
  managersSelect = [],
}) => {
  const { FileAdd } = useIcons();
  const Form = useForm<CustomersSearchFilter>();
  return (
    <Flex className="customers-filter" gap="12px" m="12px 16px 12px 16px">
      <Form
        initialValues={{
          companyName: '',
          customer: '',
          manager: '',
        }}
        onSubmit={onSubmit}
        className="customers-filter__form-area"
      >
        <FormSpy subscription={{ values: true }}>{({ values }) => <FormAutosave values={values} onSave={handleFilterChange} />}</FormSpy>
        <UiInputField name="companyName" placeholder="Поиск по названию" />
        <CustomerSelectField name="customer" placeholder="Выберите клиента" />
        <UiSelectField options={managersSelect} name="manager" placeholder="Выберите менеджера" />
      </Form>
      {role === UserRole.Administrator || role === UserRole.Manager ? (
        <Box mt="10px">
          <UiButtonOutlined mode="dark" onClick={showForm}>
            <FileAdd />
            Добавить клиента
          </UiButtonOutlined>
        </Box>
      ) : (
        ''
      )}
    </Flex>
  );
};
