import { taxOptions } from '@/config/constants/tax-options';
import { FunnelPaymentDocsFormFields, FunnelPaymentDocsFormValues } from '@/types/models/funnel';
import { Load } from '@/types/models/loads';
import { checkHasVat } from '@/helpers/price/check-has-vat';

export const getPaymentUpdatedValues = (load?: Load): Partial<FunnelPaymentDocsFormValues> | undefined => {
  if (!load) return undefined;
  return {
    [FunnelPaymentDocsFormFields.CarrierPaymentTerms]: {
      hasVat: !load?.carrierPayment ? true : checkHasVat(load?.carrierPayment.vatType),
      withoutVat: true,
      priceWithVAT: load?.carrierPayment.priceWithVAT,
      priceWithoutVAT: load?.carrierPayment.priceWithoutVAT,
      paymentConditions: load?.carrierPayment.paymentConditions,
      hasPrepayment: load?.prepayment?.possibility,
      hasFuel: load?.prepayment?.fuelPossibility,
      priceOffer: load?.carrierPayment.priceOffer,
    },
    [FunnelPaymentDocsFormFields.ClientPaymentTerms]: {
      // @ts-ignore
      vat: load?.customerPayment.vat ? taxOptions.find((to) => to.value === 1) : taxOptions.find((to) => to.value === 0),
      price: load?.customerPayment?.price?.toString(),
      paymentTerms: load?.customerPayment.paymentConditions,
      hasPrepayment: load?.prepayment?.possibility,
    },
  };
};
