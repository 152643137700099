import { FC } from 'react';
import { toast } from 'react-toastify';
import { ModalView } from '@/views/order-modal/order-modal-view';
import { ModalsProps } from '@/types/common/modals';
import { useOrderDataActions } from '@/features/order/hooks/use-order-data-actions';
import { useOrderDetailsView } from '@/features/order/hooks/use-order-details-view';
import { useOrderInvalidate } from '@/features/order/hooks/use-order-invalidate';
import { useOrderActions } from '@/features/order/hooks/use-order-actions';

export const ModalDeleteOrder: FC<
  ModalsProps<{
    text?: string;
    modalWidth?: number;
    marginRight?: number;
    submitButtonText?: string;
    cancelButtonText?: string;
    desc?: string;
    alignDesc?: 'center' | 'left' | 'right';
    accentXBtn?: boolean;
  }>
> = ({ modalOpened, onClose, desc, alignDesc }) => {
  const { activeOrderId } = useOrderDetailsView();
  const { select } = useOrderActions();
  const { deleteOrder } = useOrderDataActions(activeOrderId!);
  const { allOrdersInvalidate } = useOrderInvalidate();

  const onSubmit = () => {
    deleteOrder.mutate(activeOrderId!, {
      onSuccess: () => {
        allOrdersInvalidate(() => {
          toast.success('Заявка успешно удалена');
          select(undefined);
          onClose();
        });
      },
    });
  };

  return (
    <ModalView
      isOpen={modalOpened}
      onClose={onClose}
      title="Вы действительно хотите удалить заявку?"
      onSubmit={onSubmit}
      submitButtonText="Удалить заявку"
      desc={desc}
      alignDesc={alignDesc}
      modalWidth={524}
      justifyTitle="left"
    />
  );
};
