import { FC } from 'react';
import { HStack, VStack } from '@chakra-ui/react';
import { MORE_INFO, OUR_PRICE_TEXT, OWN_PRICE_TEXT } from 'components/order-details/order-details-client-offer-info/texts';
import { OrderOfferStatusColorScheme } from 'config/constants/order-offer-status-colors';
import classNames from 'classnames';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { NOT_APPROVED_STATUS_CAPTION } from '@/config/constants/approval-statuses';
import { TO_CHECK_BUTTON_TEXT } from '@/features/drivers/texts';
import { OrderOfferStatus } from '@/types/models/order-offer';
import { UiStatus } from '@/ui-elements/status/status';
import './order-details-client-offer-info.scss';
import { UiButtonOutlined, UiDivider } from '@/ui-elements';
import { emptyFunction } from '@/helpers/empty-function';
import { toPriceFormat } from '@/helpers/price/to-price-format';
import { CarrierSimple } from '@/types/models/carrier';
import { Driver } from '@/types/models/driver';
import { Vehicle } from '@/types/models/vehicle';
import { CarrierOrganization } from '@/types/models/my-organization/my-organization';
import { OrderClientInfoCarrierOrganizationDetails } from './carrier-organization/order-client-info-carrier-organization-details';
import { OrderClientInfoDriverDetails } from '@/components/order-details/order-details-client-offer-info/driver/order-client-info-driver-details';
import { OrderClientInfoVehicleDetails } from '@/components/order-details/order-details-client-offer-info/vehicle/order-client-info-vehicle-details';

type Props = {
  orderOffer: { carrier: CarrierSimple; driver: Driver; vehicle?: Vehicle; carrierOrganization?: CarrierOrganization };
  price?: number;
  isOwnPrice: boolean;
  isWaiting?: boolean;
  className?: string;
  children?: JSX.Element | Array<JSX.Element>;
  onMoreInformation: (id: number) => void;
  onSendToCheckClick: VoidFunction;
  approved: boolean;
};
/*
 * Информация о перевозчике на вкладке Заказ Клиента
 * */
export const OrderDetailsClientOfferInfo: FC<Props> = ({
  orderOffer,
  className,
  children,
  approved,
  onMoreInformation,
  onSendToCheckClick = emptyFunction,
  isOwnPrice,
  price,
  isWaiting = false,
}) => {
  const { driver, vehicle, carrierOrganization, carrier } = orderOffer ?? {};

  return (
    <div className={classNames('order-details-client-offer-info', className)}>
      <HStack className="order-details-client-offer-info__body">
        <div className="order-details-client-offer-info__info">
          <VStack alignItems="flex-start">
            <OrderClientInfoCarrierOrganizationDetails carrierOrganization={carrierOrganization} />
            <UiDivider />
            <OrderClientInfoDriverDetails driver={driver} />
            <UiDivider />
            <OrderClientInfoVehicleDetails vehicle={vehicle} />
          </VStack>
          <span
            className={classNames('order-details-client-offer-info__approve', {
              'not-approved': !approved,
            })}
          >
            {!approved ? NOT_APPROVED_STATUS_CAPTION : EMPTY_STRING}
          </span>
          {!approved && (
            <UiButtonOutlined onClick={onSendToCheckClick} className="order-details-client-offer-info__to-check-button" mode="dark">
              {TO_CHECK_BUTTON_TEXT}
            </UiButtonOutlined>
          )}
        </div>
        <div className="order-details-client-offer-info__status">
          {isWaiting ? (
            <UiStatus
              text="Ожидаем ответ"
              backGroundColor={OrderOfferStatusColorScheme[OrderOfferStatus.Waiting].background}
              color={OrderOfferStatusColorScheme[OrderOfferStatus.Waiting].color}
            />
          ) : (
            <UiStatus
              text={`${isOwnPrice ? OWN_PRICE_TEXT : OUR_PRICE_TEXT}: ${toPriceFormat(price)}`}
              backGroundColor={
                isOwnPrice
                  ? OrderOfferStatusColorScheme[OrderOfferStatus.OwnPrice].background
                  : OrderOfferStatusColorScheme[OrderOfferStatus.OwnRegion].background
              }
              color={
                isOwnPrice
                  ? OrderOfferStatusColorScheme[OrderOfferStatus.OwnPrice].color
                  : OrderOfferStatusColorScheme[OrderOfferStatus.OwnRegion].color
              }
            />
          )}
        </div>
      </HStack>
      <HStack justifyContent="flex-end">
        <UiButtonOutlined className="order-details-client-offer-info__more" onClick={() => onMoreInformation(carrier?.id)} mode="dark">
          {MORE_INFO}
        </UiButtonOutlined>
        {children}
      </HStack>
    </div>
  );
};
