import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isNumber, toNumber } from 'lodash';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { ModalAction, useModal } from '@/hooks/use-modal';
import { ModalType } from '@/types/common/modals';
import { CarriersLoadsCreateOfferForm } from '@/components/carriers-loads/offer-form/create-offer-form';
import { CarriersLoadsCreateOfferField, CarriersLoadsCreateOfferValues, CarriersLoadsCreateOfferValuesForm } from '@/types/models/carriers-loads';
import { initialLoadsCreateOfferFormValues } from '@/config/constants/carriers-loads';
import { useOffersActions } from '@/features/offers/hooks/use-offers-actions';
import { Order } from '@/types/models/order';
import { OfferNew } from '@/types/models/offers';
import { VatType } from '@/config/constants/types';
import { useDriversEnum } from '@/features/drivers/hooks/use-driver-enum';
import { useRoadTrainsEnum } from '@/features/vehicles/hooks/use-road-trains-enum';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { QueryKeys } from '@/config/constants/query-keys';
import { getOfferSubmitTooltipText } from '@/features/carriers-loads/helpers/get-offer-submit-tooltip-text';
import { checkIsVatMatch } from '@/helpers/price/check-is-vat-match';
import { checkHasVat } from '@/helpers/price/check-has-vat';

type Arg = {
  /** organizationVat - использует ли перевозчки НДС */
  organizationVat?: VatType;
  /** carrierId - ID аккаунта перевозчика */
  carrierId: number | undefined;
  /** authenticated - авторизован ли пользователь */
  authenticated: boolean;
  isCarrierSimple: boolean;
};

/**
 * Модальное окно для отображения формы отклика на заявку
 */
export const useCreateOfferModal = ({ authenticated, isCarrierSimple, carrierId, organizationVat }: Arg) => {
  const queryClient = useQueryClient();

  const { addOffer } = useOffersActions();

  const { driversEnumData } = useDriversEnum({
    q: '',
    options: {
      enabled: authenticated,
    },
    carrierId,
  });
  const driverOptions: SelectOption[] = driversEnumData;

  const { roadTrainsEnumData } = useRoadTrainsEnum({
    q: '',
    options: {
      enabled: authenticated,
    },
    carrierId,
  });
  const vehicleOptions: SelectOption[] = roadTrainsEnumData;

  const [order, setOrder] = useState<Order | undefined>();

  // ID заявки по которой кликнули используется для коллбека при отправке в форме
  const orderID = useRef<number | undefined>(undefined);

  // ID аккаунта перевозчика используется для коллбека при отправке в форме
  const carrierIdref = useRef(carrierId);
  useEffect(() => {
    carrierIdref.current = carrierId;
  }, [carrierId]);

  // Данные по форме
  const formValue = useRef<CarriersLoadsCreateOfferValues>({
    driverId: undefined,
    price: undefined,
    vehicleId: undefined,
  });

  // Разрешено ли по заявке предлагать свою цену
  // priveOffer = '1', true - можно предлагать свою цену
  const priceOfferAvailable = order?.carrierPayment?.priceOffer;

  // Условия оплаты
  const isVatMatch = checkIsVatMatch({ vatType: organizationVat, orderVatType: order?.carrierPayment.vatType });

  // Блокируем ввод цены, если нельзя предлагать свою цену, либо если несовместимые условия оплаты.
  const disablePrice = !priceOfferAvailable || !isVatMatch;
  const disableSubmit = !isVatMatch;

  const submitButtonTooltip = getOfferSubmitTooltipText({ organizationVat, orderVat: order?.carrierPayment.vatType });

  // Обновление в данных в форме цены
  useEffect(() => {
    if (order) {
      formValue.current[CarriersLoadsCreateOfferField.Price] = order?.carrierPayment.priceWithVAT;
    }
  }, [order]);

  const handleChangeFrom = useCallback((values: Partial<CarriersLoadsCreateOfferValuesForm>) => {
    formValue.current = {
      driverId: toNumber(values?.[CarriersLoadsCreateOfferField.DriverId]?.value) || undefined,
      vehicleId: toNumber(values[CarriersLoadsCreateOfferField.VehicleId]?.value) || undefined,
      price: toNumber(values[CarriersLoadsCreateOfferField.Price]) || undefined,
    };
  }, []);

  const createOffer = (closeCallback?: () => void) => {
    const createOfferPayload = {
      orderId: orderID.current,
      ...formValue.current,
      driverId: formValue.current.driverId ?? 0,
      carrierId: carrierIdref.current,
    };
    if (Object.values(createOfferPayload).every(isNumber)) {
      closeCallback?.();
      addOffer.mutate(createOfferPayload as OfferNew, {
        onSuccess: () => {
          toast.success('Предложение отправлено');
          queryClient.invalidateQueries([QueryKeys.allParticipatableCarriersLoads]);
        },
      });
    }
  };

  // Начальные значения для формы отклика
  const initialValues: CarriersLoadsCreateOfferValuesForm = useMemo(() => {
    return {
      ...initialLoadsCreateOfferFormValues,
      price: checkHasVat(organizationVat) ? order?.carrierPayment?.priceWithVAT : order?.carrierPayment?.priceWithoutVAT || 0,
    };
  }, [order, organizationVat]);

  const { modalView: ConfirmCreateOfferModal, setModalOpened: showConfirm } = useModal<
    { text: string; formComponent: React.ReactElement; disableSubmit: boolean; submitButtonTooltip?: string } & ModalAction
  >(ModalType.CreateCarrierOffer, {
    text: 'Назначение на заявку Водителя и ТС',
    onSuccess: () => createOffer(() => showConfirm(false)),
    disableSubmit,
    submitButtonTooltip,
    formComponent: (
      <CarriersLoadsCreateOfferForm
        initialValues={initialValues}
        onFormValuesChange={handleChangeFrom}
        disablePrice={disablePrice}
        priceVAT={checkHasVat(organizationVat)}
        drivers={driverOptions}
        vehicles={vehicleOptions}
        isCarrierSimple={isCarrierSimple}
      />
    ),
  });

  const handleCreateOffer = (openOrder: Order) => {
    orderID.current = openOrder.id;
    setOrder(openOrder);
    showConfirm(true);
  };

  return { ConfirmCreateOfferModal, handleCreateOffer };
};
