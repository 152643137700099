import { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { FORM_UI_ELEMENTS, ORGANIZATION_DRIVER_GENERAL } from '@/components/my-organization/constants';
import { FormSectionTitle } from '@/components/my-organization/my-organization-form-header';
import { MyOrganizationFormField, MyOrganizationFormOptions } from '@/types/models/my-organization/form';

interface IProps {
  options: MyOrganizationFormOptions;
  disabled: boolean;
}

export const MyOrganizationDriverGeneral: FC<IProps> = ({ options, disabled }) => {
  return (
    <Box>
      <FormSectionTitle title="Данные организации" />
      <Flex className="my-organization-flex" direction="row" align="center" wrap="wrap">
        {ORGANIZATION_DRIVER_GENERAL.map((field) => {
          const { Component, defaultProps } = FORM_UI_ELEMENTS[field];
          /* Блокируем тип организации для ИП */
          const isOrganizationType = defaultProps.name === MyOrganizationFormField.OrganizationTypeId;
          return (
            <Component
              isDisabled={disabled || isOrganizationType}
              disabled={disabled || isOrganizationType}
              key={field}
              options={options[field]}
              {...defaultProps}
            />
          );
        })}
      </Flex>
    </Box>
  );
};
