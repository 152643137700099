import { useState } from 'react';

export const useBurger = () => {
  const [open, setOpen] = useState<boolean>(false);

  const onToggleMenu = () => {
    setOpen((prev) => !prev);
  };

  const onCloseMenu = () => {
    setOpen(false);
  };

  return { onToggleMenu, open, onCloseMenu };
};
