import { omit } from 'lodash';
import { isSelectOption } from '@/ui-elements/inputs/ui-select/types';
import { isVatSelectOption } from '@/config/constants/types';
import { MyOrganizationsFormValuesType, MyOrganizationBanking, MyOrganizationType } from '@/types/models/my-organization/my-organization';

export const myOrganizationsDataToFormValues = (
  values: MyOrganizationsFormValuesType & {
    bank: MyOrganizationBanking;
    signatureDoc: any;
    stampDoc: any;
  }
): Partial<MyOrganizationType> => {
  const newOrganizationValues = {
    ...values,
    birthday: values?.birthday?.toString(),
    passportDate: values.passportDate?.toString(),
    vatType: isVatSelectOption(values?.vatType) ? values?.vatType?.value : undefined,
    organizationTypeID: isSelectOption(values?.organizationTypeID) ? Number(values?.organizationTypeID?.value) : undefined,
  };

  return omit(newOrganizationValues, ['bank', 'documents', 'signatureDoc']);
};
