import { Routes } from 'config/routes/routes';

export type CarrierLoadsRoute =
  | Routes.OrdersWithMyParticipation
  | Routes.CurrentRoutes
  | Routes.FutureRoutes
  | Routes.CompletedRoutesSimple
  | Routes.CompletedRoutes;

const EMPTY_ITEM_TEXT: Record<CarrierLoadsRoute, string> = {
  [Routes.OrdersWithMyParticipation]: 'откликов',
  [Routes.CurrentRoutes]: 'текущих рейсов',
  [Routes.FutureRoutes]: 'запланированных рейсов',
  [Routes.CompletedRoutesSimple]: 'завершенных рейсов',
  [Routes.CompletedRoutes]: 'завершенных рейсов',
};

export const getEmptyText = ({ isDriver, route }: { isDriver: boolean; route: CarrierLoadsRoute }) => {
  const firstPart = `У вас нет ${EMPTY_ITEM_TEXT[route]}`;
  const fullEmptyMessage = `${firstPart} чтобы выполнять рейсы вам нужно откликнуться на заявку и пройти проверку организации и ресурсов`;

  return isDriver ? firstPart : fullEmptyMessage;
};
