import './resources-card-status.scss';
import { FC } from 'react';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { UiStatus } from '@/ui-elements/status/status';
import { VerificationStatus } from '@/config/constants/statuses';
import { VerificationItemApproveStatusNames } from '@/config/constants/texts';
import { VerificationItemApproveStatusColors } from '@/config/constants/approve-status-colors';
import { formatMomentLocal } from '@/helpers/moment/format-moment-local';

export const ResourcesCardStatus: FC<{ approvalStatus?: Partial<VerificationStatus> }> = ({ approvalStatus }) => {
  if (!approvalStatus?.status) return null;

  return (
    <VStack className="resources-card-status" alignItems="flex-start" spacing="1">
      <UiStatus
        text={VerificationItemApproveStatusNames[approvalStatus?.status]}
        backGroundColor={VerificationItemApproveStatusColors[approvalStatus?.status]?.background}
        color={VerificationItemApproveStatusColors[approvalStatus?.status]?.color}
      />
      {approvalStatus?.departmentName && (
        <HStack>
          <Text className="resources-card-status-subtitle">Филиал:</Text>
          <Text className="resources-card-status-value">{approvalStatus?.departmentName}</Text>
        </HStack>
      )}
      {approvalStatus?.date && (
        <HStack>
          <Text className="resources-card-status-subtitle">Отправлено на проверку:</Text>
          <Text className="resources-card-status-value">{formatMomentLocal(approvalStatus?.date, 'YYYY-MM-DD HH:mm')}</Text>
        </HStack>
      )}
    </VStack>
  );
};
