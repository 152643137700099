import { FC } from 'react';
import closedIcon from 'public/assets/icons/CloseIcon.svg';
import { Box, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { UiIconButton } from '@/ui-elements';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';
import { ModalsProps } from '@/types/common/modals';
import { ResourcesResetPasswordForm } from '@/components/resources/forms/reset-password/resources-reset-password-form';
import { useResourcesResetPassword } from '@/features/resources/hooks/use-resources-reset-password';

export const ModalResetPassword: FC<ModalsProps<{}>> = ({ onClose, modalOpened }) => {
  const { initialValues, loading, handleSubmit } = useResourcesResetPassword({
    onClose,
  });

  return (
    <Modal isOpen={modalOpened} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={24} boxShadow="xl" pos="relative" p={5} mt="30vh" mx={5}>
        <Box position="absolute" right="20px" top="20px">
          <UiIconButton aria-label="close-modal-button" icon={closedIcon} onClick={onClose} />
        </Box>
        {loading ? <UiSpinner visible /> : <ResourcesResetPasswordForm initialValues={initialValues} handleSubmit={handleSubmit} />}
      </ModalContent>
    </Modal>
  );
};
