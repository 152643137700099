import { Dictionary } from 'config/constants/dictionary';
import { ApiGet, ApiPost } from '@/helpers/api/methods';
import { ResponseWithoutPagerSimple, ResponseWithPager } from '@/types/models/pager';
import { OfferNew } from '@/types/models/offers';
import { OrderOffer } from '@/types/models/order-offer';

export const OffersApi = {
  getOffers: (id: number, updates?: boolean) => ApiGet<ResponseWithPager<OrderOffer>>(`${Dictionary.Offers}?orderId=${id}&updates=${!!updates}`),

  addOffer: (options: OfferNew) => ApiPost<ResponseWithoutPagerSimple<OrderOffer>>(`${Dictionary.Offers}`, options),

  acceptOffer: (id: number) => ApiPost<ResponseWithoutPagerSimple<OrderOffer>>(`${Dictionary.Offers}/${id}/accept`),
};
