import { AxiosError } from 'axios';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TOKEN_KEY } from 'config/constants/storage';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { errorMessages } from '@/config/constants/error-messages';
import { normalizeError } from '@/helpers/api/normalize-error';
import { storage } from '@/helpers/storage';
import { customersActions } from '@/features/customers/slice';

export const handleApiError = (error: AxiosError<any>, store: ToolkitStore) => {
  // @ts-ignore
  if (error.response?.data?.errors.length) {
    // @ts-ignore
    return toast.error(error.response?.data?.errors.join(', '), { toastId: 'err' });
  }
  switch (error?.response?.status) {
    case 400:
      return toast.error(errorMessages.Error_400, { toastId: 'err_400' });
    case 404:
      return toast.error(
        error.response.data
          ? normalizeError((error.response.data as AxiosError<any> & { error: string | string[] }).error)
          : errorMessages.Error_Network,
        {
          toastId: 'err_404',
        }
      );
    case 401:
      toast.error(errorMessages.Error_401, { toastId: 'err_401' });
      storage.setValue(TOKEN_KEY, EMPTY_STRING);
      store.dispatch(customersActions.clearState());
      return <Navigate to="/login" replace />;
    case 403:
      return toast.error(errorMessages.Error_403, { toastId: 'err_403' });
    case 500:
      return toast.error(errorMessages.Error_500, { toastId: 'err_500' });
    default:
      return toast.error(errorMessages.Error_Network, { toastId: 'err_net' });
  }
};
