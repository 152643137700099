import { OrderFilter } from '@/types/api/order-partial';
import { OrdersApi } from '@/api/orders';
import { useInfiniteQueryWithUpdates } from '../../../hooks/use-infinite-query-with-updates';

type Props = {
  perPage?: number;
  query?: OrderFilter;
};

export const useAllOrdersUpdates = ({ perPage = 10, query }: Props) => {
  const queryFn =
    (isFirst: Boolean) =>
    ({ pageParam = 1 }) =>
      OrdersApi.getAllOrders({ ...query, updates: false, perPage, page: pageParam });

  return useInfiniteQueryWithUpdates({ queryKey: 'allOrders', query, refetchOnWindowFocus: false, queryFn });
};
