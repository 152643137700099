import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useAccount } from '@/features/account/hooks/use-account';
import { QueryKeys } from '@/config/constants/query-keys';
import { generateLoadFormInitialValues } from '@/features/funnel/helpers';
import { creatingFromCustomersSelector } from '@/features/order/selectors/creating-from-customers-id-selector';
import { useFunnelActions } from '@/features/funnel/hooks';
import { CreateLoadFormValues } from '@/types/models/funnel-create-load-form';
import { LoadNew } from '@/types/models/funnel';
import { normalizeLoadFormValues } from '@/components/funnel/create-load-form/normalize-load-form-values';
import { currentLoadIDSelector } from '@/features/loads/selectors/current-load-id-selector';
import { useOrderDataActions } from '@/features/order/hooks/use-order-data-actions';

export const useLoadCreateView = () => {
  const client = useQueryClient();
  const currentLoadID = useAppSelector(currentLoadIDSelector);
  const { cancelCreate, selectLoadID } = useFunnelActions();
  const { currentAccount } = useAccount();
  const { user } = currentAccount || {};

  const carrierForReviews = useAppSelector(creatingFromCustomersSelector);
  const initialValues = generateLoadFormInitialValues(user, carrierForReviews);

  const { addOrder } = useOrderDataActions(currentLoadID!);
  const onAddLoad = useCallback(
    (loadNew: LoadNew, callback?: VoidFunction) => {
      addOrder.mutate(loadNew, {
        onSuccess: (response) => {
          callback?.();
          client.invalidateQueries([QueryKeys.allOrders]);
          client.invalidateQueries([QueryKeys.allOrders]).then(() => {
            cancelCreate();
            selectLoadID(response.data.id);
          });
          toast.success('Груз успешно создан');
        },
      });
    },
    [addOrder, client, selectLoadID, cancelCreate]
  );

  const onSubmit = (values: CreateLoadFormValues) => {
    try {
      const normalizedValues = normalizeLoadFormValues(values);
      onAddLoad?.(normalizedValues);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    initialValues,
    submitting: addOrder.isLoading,
    onSubmit,
    onCancel: cancelCreate,
  };
};
