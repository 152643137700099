import './resources-driver-card.scss';
import { FC } from 'react';
import classNames from 'classnames';
import { HStack, VStack, Text, Box } from '@chakra-ui/react';
import { Driver } from '@/types/models/driver';
import { UiButtonOutlined } from '@/ui-elements';
import { getDriverFullName } from '@/features/resources/helpers/get-driver-full-name';
import { ResourcesButtonActions } from '@/features/resources/constants/general';
import { ResourcesStatusesList } from '@/components/resources/statuses-list/resources-statuses-list';

interface Props {
  driver: Driver;
  selectedDriverId: number | null;
  showApprovalStatuses: boolean;
  handleSelectDriver: (driverId: number) => void;
}

export const ResourcesDriverCard: FC<Props> = ({ driver, selectedDriverId, showApprovalStatuses, handleSelectDriver }) => {
  const driverName = getDriverFullName(driver);
  const isSelected = selectedDriverId === driver.id;
  const onClickSelectDriver = () => handleSelectDriver(driver.id);

  return (
    <Box className={classNames('resources-driver-card', { 'resources-driver-card_active': isSelected })}>
      <Box className="resources-driver-card__row">
        {showApprovalStatuses && <ResourcesStatusesList approvalStatuses={driver?.approvalStatuses} />}
        <UiButtonOutlined className="resources-driver-card__button" mode="dark" onClick={onClickSelectDriver} size="small">
          {ResourcesButtonActions.Details}
        </UiButtonOutlined>
      </Box>

      <HStack className="resources-driver-card__row">
        <VStack alignItems="flex-start">
          <HStack>
            <Text className="resources-driver-card__name">{driverName}</Text>
          </HStack>
          {driver?.phone && <Text className="resources-driver-card__phone">{driver?.phone}</Text>}
        </VStack>
      </HStack>
    </Box>
  );
};
