import { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { VerificationFormMode } from 'components/verification-forms/types';
import { UiFileUploadField, UiFileUploadInput } from '@/ui-elements';
import { useDependentAttachments } from '@/features/attachments/hooks/use-dependent-attachments';
import { AttachmentDeps } from '@/config/constants/types';
import { DocumentTypes } from '@/config/constants/document-types';

export const CarrierDocsSubform: FC<{ mode: VerificationFormMode; carrierId?: number }> = ({ mode, carrierId }) => {
  const {
    attachments: attachmentsPassport,
    deleteAttachmentById: deleteAttachmentPassportById,
    postAttachments: postAttachmentsPassport,
    downloadAttachments: downloadAttachmentsPassport,
  } = useDependentAttachments({
    dependent: AttachmentDeps.Carrier,
    dependentId: carrierId,
    type: DocumentTypes.dlDocumentCarrierID,
  });
  const {
    attachments: attachmentsCompanyDoc,
    deleteAttachmentById: deleteAttachmentCompanyDocById,
    postAttachments: postAttachmentsCompanyDoc,
    downloadAttachments: downloadAttachmentsCompanyDoc,
  } = useDependentAttachments({
    dependent: AttachmentDeps.Carrier,
    dependentId: carrierId,
    type: DocumentTypes.dlDocumentCarrierTIN,
  });

  return (
    <>
      <Box mt={1}>
        <Text fontSize={18} fontWeight={700}>
          Документы {mode !== 'check' && 'руководителя'}
        </Text>
      </Box>
      <Box mt={4}>
        {mode !== 'edit' && <UiFileUploadField name="passportDoc" label="1. Разворот паспорта с фотографией" />}
        {mode === 'edit' && (
          <UiFileUploadInput
            savedFileList={attachmentsPassport}
            name="passportDoc"
            onDeleteFileClick={deleteAttachmentPassportById}
            onIconClick={downloadAttachmentsPassport}
            onImmediateUploadFile={postAttachmentsPassport}
          />
        )}
      </Box>
      <Box>
        {mode !== 'edit' && <UiFileUploadField required name="companyDoc" label="2. (ИНН, ОГРНИП)" />}
        {mode === 'edit' && (
          <UiFileUploadInput
            savedFileList={attachmentsCompanyDoc}
            required
            name="companyDoc"
            onDeleteFileClick={deleteAttachmentCompanyDocById}
            onIconClick={downloadAttachmentsCompanyDoc}
            onImmediateUploadFile={postAttachmentsCompanyDoc}
          />
        )}
      </Box>
    </>
  );
};
