/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApprovalsApi } from '@/api/approvals';
import { useQueryWithUpdates } from '@/hooks/use-query-with-updates';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';
import { Approval } from '@/types/models/approval';

export const useApprovalUpdates = (id?: number) => {
  return useQueryWithUpdates<ResponseWithoutPagerSimple<Approval>>({
    queryKey: ['approvals', id],
    enabled: !!id,
    refetchOnWindowFocus: false,
    queryFn: (isFirst: boolean) => ApprovalsApi.getApproval(id!, false),
  });
};
