import { FC } from 'react';
import { UiLink } from '@/ui-elements/ui-link/ui-link';
import { useRegisterPaths } from '@/features/account/hooks/use-register-paths';

export const RegisterToLogin: FC = () => {
  const { hrefToLogin } = useRegisterPaths();

  return (
    <span>
      Уже зарегистрированы? <UiLink href={hrefToLogin} children="Войти" />
    </span>
  );
};
