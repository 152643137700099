import { FC, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { OrderCard } from '../order-card/order-card';
import './order-list.scss';
import { Order } from '@/types/models/order';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { InfiniteScroll } from '@/views/infinite-scroll/infinite-scroll';
import { FuelStatus } from '@/config/constants/statuses';
import { ModalAction, useModal } from '@/hooks/use-modal';
import { ModalType } from '@/types/common/modals';
import { useAppSelector } from '@/hooks/use-app-selector';
import { activeOrdersUpdateSelector } from '@/features/order/selectors';

type Props = {
  isMultiple?: boolean;
  orderList: Array<Order> | undefined;
  selectedOrderIds?: number[];
  onSelectOrders: Function;
  onUnselectOrder: Function;
  loadMore: (...args: any) => void;
  loading: boolean;
  isFuel?: boolean;
  fuelStatuses?: Record<FuelStatus, string>;
};

export const OrderList: FC<Props> = ({
  isMultiple = false,
  isFuel = false,
  orderList,
  selectedOrderIds,
  onSelectOrders,
  onUnselectOrder,
  loadMore,
  fuelStatuses,
  loading,
}) => {
  const [selectedOrderId, setSelectedOrderId] = useState<number | undefined>(undefined);
  const activeOrdersUpdate = useAppSelector(activeOrdersUpdateSelector);

  const handleSelectOrderClick = (orderId: number | undefined) => {
    if (isMultiple) {
      let isSelected;
      if (orderId != null) {
        isSelected = selectedOrderIds?.includes(orderId);
      }
      if (isSelected) {
        onUnselectOrder(orderId);
      } else {
        onSelectOrders(orderId);
      }
    } else onSelectOrders(orderId);
  };
  const { modalView: ModalCloseUpdateOrder, setModalOpened: showCloseUpdateOrder } = useModal<
    { text: string; selectNewOrder: () => void } & ModalAction
  >(ModalType.CloseUpdateOrder, {
    text: 'Перейти на другую заявку?',
    selectNewOrder: () => handleSelectOrderClick(selectedOrderId),
  });

  const handleOrderClick = (orderId: number) => {
    if (activeOrdersUpdate) {
      setSelectedOrderId(orderId);
      showCloseUpdateOrder(true);
    } else {
      handleSelectOrderClick(orderId);
    }
  };

  return (
    <Box className="order-list">
      <UiScrollbars autoHide style={{ width: '100%', height: '100%' }}>
        <Box className="order-list__content">
          <InfiniteScroll callback={loadMore} loading={loading} skip={!orderList || orderList.length <= 1}>
            {orderList?.map((order) => (
              <OrderCard
                isFuel={isFuel}
                selected={selectedOrderIds?.includes(order.id)}
                handleOrderClick={handleOrderClick}
                order={order}
                key={`${order.id}`}
                fuelStatuses={fuelStatuses}
              />
            ))}
          </InfiniteScroll>
        </Box>
      </UiScrollbars>
      {ModalCloseUpdateOrder}
    </Box>
  );
};
