import { Box, HStack, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import closedIcon from 'public/assets/icons/CloseIcon.svg';
import { ModalsProps } from '@/types/common/modals';
import { OrganizationDocument } from '@/api/documents/documents-types';
import { DocumentTemplateViewer } from '@/components/document-template-viewer/document-template-viewer';
import { UiIconButton } from '@/ui-elements';
import { emptyFunction } from '@/helpers/empty-function';
import { DocumentTemplateContractForm } from '@/components/document-template-viewer/contract-form/document-template-contract-form';
import { useContractDocumentTemplate } from '@/features/contract-template/hooks/use-contract-document-template';

export const ModalDocumentTemplate = ({
  modalOpened,
  postAttachments,
  closeModal = emptyFunction,
  onClose,
  ...props
}: ModalsProps<OrganizationDocument>) => {
  const { organizationId, bankId, order, origin } = props;
  const { document, modalWidth, isTemplateWithForm, isEditForm, routesAmount, toggleEditForm, submitEditForm } = useContractDocumentTemplate({
    organizationId,
    bankId,
    order,
    origin,
  });

  return (
    <Modal isOpen={modalOpened} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={24} boxShadow="xl" pos="relative" maxW={modalWidth} w={modalWidth} p={10} pt={16} h="80vh">
        <HStack>
          {isTemplateWithForm && (
            <DocumentTemplateContractForm
              isEditForm={isEditForm}
              routesAmount={routesAmount}
              toggleEditForm={toggleEditForm}
              submitEditForm={submitEditForm}
            />
          )}
          <Box position="absolute" right="24px" top="20px">
            <UiIconButton aria-label="close-modal-button" icon={closedIcon} onClick={onClose} />
          </Box>
          <DocumentTemplateViewer templateData={document} scrollbarsMode height="64vh" closeModal={closeModal} postAttachments={postAttachments} />
        </HStack>
      </ModalContent>
    </Modal>
  );
};
