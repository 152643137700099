import { stringifyUrl } from 'query-string';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'config/routes/routes-path';
import { SidebarMode } from 'components/sidebar-wrapper/constants';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useLocationTyped } from '@/hooks/use-location-typed';
import { CarrierLoadsPageWithOrders } from '@/features/carriers-loads/hooks/use-carriers-loads-actions';
import { pageOrderIdSelector } from '@/features/carriers-loads/selectors/page-order-id-selector';
import { useCarriersLoadsRoutesPage } from '@/features/carriers-loads/hooks/use-carriers-loads-routes-page';
import { useCarriersLoadsRoutes } from '@/features/carriers-loads/hooks/use-carriers-loads-routes';
import { useAccount } from '@/features/account/hooks/use-account';
import { CARRIER_REDIRECT_ROUTES, CARRIER_SIMPLE_REDIRECT_ROUTES } from '@/views/carriers-loads/constants';

export const useCarriersLoadsRoutesList = (page: CarrierLoadsPageWithOrders) => {
  const { isCarrierSimple, isDriver } = useAccount();
  const redirectRoutes = isCarrierSimple ? CARRIER_SIMPLE_REDIRECT_ROUTES : CARRIER_REDIRECT_ROUTES;

  const activeOrderId = useAppSelector((state) => pageOrderIdSelector(state, page));

  const { fetchNextPage, isFetchingNextPage, isInitialLoading, allRecords: routes, setSidebarMode } = useCarriersLoadsRoutes(page);

  const { state, pathname } = useLocationTyped();

  useCarriersLoadsRoutesPage(routes, activeOrderId, state?.redirectId, page, true);

  const navigate = useNavigate();

  const handleSelect = (id: number) => {
    const url = stringifyUrl({
      url: pathname,
      query: { id },
    });
    navigate(url);
    setSidebarMode(SidebarMode.Details);
  };

  const handleRequestOrder = useCallback(
    (id: number) => {
      const url = stringifyUrl({
        url: `/${routesPath[page]}`,
        query: { id },
      });
      navigate(url);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    routes,
    handleSelect,
    activeOrderId,
    isInitialLoading,
    handleRequestOrder,
    fetchNextPage,
    isFetchingNextPage,
    redirectRoutes,
    isDriver,
  };
};
