import { FC, Ref, useState } from 'react';
import { HStack, VStack } from '@chakra-ui/react';
import { Header } from './header';
import { TableComponent } from './table';
import { TableConfig, TableDataSource } from '@/types/common/table';
import { ComponentToPrint } from '@/components/component-to-print/component-to-print';

type Props = {
  config: TableConfig;
  dataSource: TableDataSource;
  handlePrint: VoidFunction;
  componentRef: Ref<HTMLTableElement>;
};

export const AnalyticsComponent: FC<Props> = ({ config, dataSource, handlePrint, componentRef }) => {
  const [dateRange, setDateRange] = useState('all');
  return (
    <VStack px={4} py={5} w="full">
      <Header value={dateRange} onChange={setDateRange} handlePrint={handlePrint} />
      <HStack w="full">
        <TableComponent config={config} dataSource={dataSource} />
        <TableComponent config={config} dataSource={dataSource} />
        <ComponentToPrint ref={componentRef} config={config} dataSource={[...dataSource, ...dataSource]} />
      </HStack>
    </VStack>
  );
};
