import { FC, MutableRefObject } from 'react';
import { Grid, GridItem, Text } from '@chakra-ui/react';
import { PASSPORT_MASK } from 'components/auth-form/constants';
import { VerificationFormMode } from 'components/verification-forms/types';
import { DateFormat } from 'config/constants/date';
import { UiCheckboxInput, UiInputField, UiMaskedField, UiMultiDatePickerField } from '@/ui-elements';

export const DriverPassportSubform: FC<{
  mode: VerificationFormMode;
  initialValues: any;
  passportForm: boolean;
  datePickerRef: MutableRefObject<null>;
  datePickerSecondRef: MutableRefObject<null>;
  setPassportForm: (val: (val: any) => boolean) => void;
}> = ({ mode, initialValues, passportForm, setPassportForm, datePickerRef, datePickerSecondRef }) => {
  return (
    <>
      <GridItem colSpan={2}>
        <Text className="carriers-verification-form__subtitle" fontSize={mode === 'check' ? 16 : 18}>
          Паспортные данные
        </Text>
      </GridItem>

      <GridItem colSpan={2} gap={2} mt={2}>
        <UiCheckboxInput
          name="russianPassport"
          isDisabled={mode === 'check'}
          defaultChecked={passportForm}
          checked={passportForm}
          onChange={() => setPassportForm((val) => !val)}
          mr={2}
        />
        <span>Паспорт РФ</span>
      </GridItem>

      {Boolean(passportForm) && (
        <>
          <Grid templateColumns="repeat(3, 1fr)" gap={2} mt={2}>
            <GridItem>
              <UiMaskedField
                name="passport"
                label="Серия и номер"
                required
                mask={PASSPORT_MASK}
                defaultValue={initialValues?.passport}
                isDisabled={mode === 'check'}
              />
            </GridItem>
            <GridItem>
              <UiMultiDatePickerField
                name="passportDate"
                label="Дата выдачи"
                placeholder="Дата выдачи"
                required
                isDisabled={mode === 'check'}
                datePickerRef={datePickerRef}
                format={DateFormat.DDMMYYYYSlash}
              />
            </GridItem>
            <GridItem>
              <UiMultiDatePickerField
                name="birthday"
                label="Дата рождения"
                placeholder="Дата рождения"
                required
                isDisabled={mode === 'check'}
                datePickerRef={datePickerSecondRef}
                format={DateFormat.DDMMYYYYSlash}
              />
            </GridItem>
          </Grid>
          <GridItem colSpan={2} mt={-2}>
            <UiInputField name="passportGiven" label="Кем выдан" placeholder="Кем выдан" required isDisabled={mode === 'check'} />
          </GridItem>
        </>
      )}
    </>
  );
};
