/**
 * К дате создания прибавляется 2 часа и из этой даты вычитается текущая дата,
 * пока разница больше: показывает оставшееся время, после возвращает 00:00
 */

import moment from 'moment';

export const approvalTimeLeft = (created: string) => {
  if (!created) return '00:00';

  const endDateForm = moment(created).add(2, 'hours').format('YYYY-MM-DD HH:mm');
  const currentTime = moment().format('YYYY-MM-DD HH:mm');
  const diff = moment(endDateForm).diff(moment(currentTime), 'minutes');
  if (diff > 0) {
    return moment.utc(moment.duration(diff, 'minutes').asMilliseconds()).format('HH:mm');
  }
  return '00:00';
};
