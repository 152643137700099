/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react';
import { ModalView } from '@/views/order-modal/order-modal-view';
import { UiTextareaField } from '@/ui-elements';
import { ModalsProps } from '@/types/common/modals';
import { AttachmentDeps } from '@/config/constants/types';
import { AttachmentToConfirmDeleteQuery } from '@/types/models/attachments';

type OnSubmitProps = {
  reason: string;
};

export const ModalDecline: FC<ModalsProps<{ orderId?: number; handleDecline?: (params: AttachmentToConfirmDeleteQuery) => void }>> = ({
  modalOpened,
  onClose,
  orderId,
  handleDecline,
}) => {
  const onSubmit = (values: OnSubmitProps) => {
    if (orderId) handleDecline?.({ dependent: AttachmentDeps.Order, dependentID: orderId, reason: values.reason });
    onClose();
  };

  return (
    <ModalView
      isOpen={modalOpened}
      onClose={onClose}
      title="Укажите причину отклонения"
      onSubmit={(values: OnSubmitProps) => onSubmit(values)}
      submitButtonText="Отклонить"
      cancelButtonText="Отменить"
      accentXBtn
      buttonsAutoWidth
    >
      <UiTextareaField name="reason" label="Комментарий" counter />
    </ModalView>
  );
};
