import { OrderCreateFormValues } from 'components/create-order-form/schema';
import { Box, Center, HStack, Radio, RadioGroup, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { RadioButtonsConfig } from 'config/constants/radio-buttons';
import {
  UiAddressSearchField,
  UiButtonOutlined,
  UiDeleteButton,
  UiFileUploadField,
  UiInputField,
  UiMultiDatePickerField,
  UiRadioGroupField,
  UiSelectField,
  UiTimeInputField,
} from '@/ui-elements';
import { emptyFunction } from '@/helpers/empty-function';
import { CargoInfoFields } from './cargo-info-fields';

type Props = {
  name: string;
  updateValues?: Partial<OrderCreateFormValues>;
};

const emptyCargoFields = {
  cargoCategory: '',
  cargoWeight: '',
  cargoVolume: '',
  cargoSizes: '',
  cargoPacking: '',
};

export const WaypointInfo = ({ name, updateValues }: Props) => {
  const [additionalServicesNeeded, setAdditionalServiceNeeded] = useState('not needed');
  const { fields } = useFieldArray(`${name}.cargo`);

  return (
    <Box w="full">
      <HStack spacing={4} mb={4}>
        <Box w="full">
          <UiAddressSearchField placeholder="Начните вводить адрес" name={`${name}.address`} label="Адрес" required />
        </Box>
      </HStack>
      <UiInputField name={`${name}.coordinates`} label="Введите координаты если не нашли по названию" placeholder="Например 55.7522, 37.6155" />
      <HStack spacing={4} alignItems="end" mb={4}>
        <Box w="100%">
          <UiMultiDatePickerField name={`${name}.date`} label="Когда и во сколько должна быть машина" required />
        </Box>
        <UiTimeInputField name={`${name}.time`} />
      </HStack>
      <Text textStyle="h3" align="left" mb={6}>
        Что нужно на точке?
      </Text>
      <UiRadioGroupField config={RadioButtonsConfig.loadingType} name={`${name}.loading`} />
      <Text textStyle="h3" align="left" my={6}>
        Дополнительные услуги на точке?
      </Text>
      <RadioGroup onChange={setAdditionalServiceNeeded} value={additionalServicesNeeded} w="full">
        <VStack align="flex-start">
          <Radio value="needed">Нужны</Radio>
          <Box w="full">
            <UiSelectField options={[]} isDisabled={additionalServicesNeeded === 'not needed'} name={`${name}.addOns`} />
          </Box>
          <Box w="full" mb={4}>
            <UiInputField isDisabled={additionalServicesNeeded === 'not needed'} name={`${name}.comments`} label="Комментарии" />
          </Box>
          {!updateValues && <UiFileUploadField name={`${name}.documents`} />}
          <Radio value="not needed" mt={4}>
            Не нужны
          </Radio>
        </VStack>
      </RadioGroup>
      <Box w="full" mt={6}>
        <Text textStyle="h3" align="left" mb={6}>
          Информация о грузе
        </Text>
        {fields.map((fieldName, index) => (
          <Box key={fieldName}>
            <UiDeleteButton mb="22px" onClick={index > 0 ? () => fields.remove(index) : emptyFunction} isDisabled={index === 0}>
              {`Груз ${index + 1}`}
            </UiDeleteButton>
            <CargoInfoFields fieldsName={`${fieldName}`} />
          </Box>
        ))}
        <Center mt={5}>
          <UiButtonOutlined onClick={() => fields.push(emptyCargoFields)}>Добавить Груз</UiButtonOutlined>
        </Center>
      </Box>
    </Box>
  );
};
