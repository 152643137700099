import { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { FormSectionTitle } from '@/components/my-organization/my-organization-form-header';
import { VerificationStatus } from '@/config/constants/statuses';
import { MyOrganizationStatusesList } from '@/components/my-organization/my-organization-approval-statuses-list';

interface IProps {
  title: string;
  approvalStatuses?: Array<VerificationStatus>;
}

export const MyOrganizationHeader: FC<IProps> = ({ title, approvalStatuses }) => {
  return (
    <Flex wrap="wrap" direction="column" align="flex-start">
      <FormSectionTitle title={title} size={24} />
      <MyOrganizationStatusesList approvalStatuses={approvalStatuses} />
    </Flex>
  );
};
