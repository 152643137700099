import { VehiclesApi } from '@/api/vehicles';
import { AllVehiclesFilter } from '@/types/api/vehicles';
import { QueryKeys } from '@/config/constants/query-keys';
import { useInfiniteQueryWithUpdates } from '@/hooks/use-infinite-query-with-updates';

export const useAllVehiclesWithPages = ({ perPage = 10, carrierId }: AllVehiclesFilter) => {
  const queryFn =
    () =>
    ({ pageParam = 1 }) =>
      VehiclesApi.getAllVehicles({ perPage, page: pageParam, carrierId, updates: false });

  return useInfiniteQueryWithUpdates({ queryKey: QueryKeys.allVehicles, refetchOnWindowFocus: false, queryFn });
};
