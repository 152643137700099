import { EMPTY_STRING } from '@/config/constants/common-strings';
import { useAccount } from '@/features/account/hooks/use-account';
import { AccountRole } from '@/types/api/account-role';

/** Хук, который проверяет роль текущего аккаунта. Если роль - intermediary, то необходимо отображать ресурсы только текущего carrierId.
 * Хук возвращает данные перевозчика текущего аккаунта и флаг, что это роль intermediary: по этому флагу будем определять, показывать статусы проверки или нет.
 * Если роль isIntermediaryRole, то статусы проверки для своих ресурсов не показываем.
 */
export const useIntermediaryResources = () => {
  const { currentAccount } = useAccount();
  const accountRoles = currentAccount?.user?.accountRole;

  const isIntermediaryRole = accountRoles?.length === 1 && accountRoles[0] === AccountRole?.Intermediary;

  return { isIntermediaryRole, carrierId: isIntermediaryRole ? currentAccount?.user?.accountId : EMPTY_STRING };
};
