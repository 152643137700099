import { AttachmentDeps } from 'config/constants/types';
import { DocumentTypes } from 'config/constants/document-types';
import { useDependentAttachments } from '@/features/attachments/hooks/use-dependent-attachments';
import { useAccount } from '@/features/account/hooks/use-account';
import { UserRole } from '@/types/api/user-role';
import { Point } from '@/types/models/point';
import { AccountRole } from '@/types/api/account-role';
import { checkAccountRole } from '@/helpers/routes/check-account-role';

// Документы по точкам маршрута в заявке
export const useCarrierOrderPointDocumentView = ({ point }: { point: Point }) => {
  const { currentRole, accountRole } = useAccount();
  const hasEdit =
    currentRole &&
    [UserRole.Administrator, UserRole.Driver].includes(currentRole) &&
    checkAccountRole({
      accountRole,
      availableAccountRoles: [AccountRole.Carrier, AccountRole.CarrierSimple],
    });

  const { deleteAttachmentById, attachments, downloadAttachments, postAttachments } = useDependentAttachments({
    dependent: AttachmentDeps.Point,
    dependentId: point?.id,
    type: DocumentTypes.dlDocumentPoint,
    staleTime: 3000,
  });
  const deleteDocument = hasEdit ? deleteAttachmentById : undefined;
  const uploadDocument = hasEdit ? postAttachments : undefined;
  return { hasEdit, deleteDocument, attachments, downloadAttachments, uploadDocument };
};
