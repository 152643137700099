import { FC } from 'react';
import { Flex, HStack } from '@chakra-ui/react';
import { CarrierCardDriverInfo } from 'components/carrier-card/carrier-card-driver-info/carrier-card-driver-info';
import { CarrierCardDriverPreferences } from 'components/carrier-card/carrier-card-driver-info/carrier-card-driver-preferences';
import { useIcons } from '@/hooks/use-icons';
import { Driver } from '@/types/models/driver';
import { emptyFunction } from '@/helpers/empty-function';
import { UiIconButton } from '@/ui-elements';

type Props = {
  driver: Driver;
  onClickReviewButton?: () => void;
  readOnly?: boolean;
};
export const CarrierCardDriver: FC<Props> = ({ onClickReviewButton = emptyFunction, driver, readOnly = false }) => {
  const { Messenger } = useIcons();
  return (
    <Flex justifyContent="space-between" gap="30px" width="100%">
      <CarrierCardDriverInfo driver={driver} />
      <CarrierCardDriverPreferences driver={driver} readOnly={readOnly} />
      {!readOnly && (
        <HStack justifyContent="flex-end" alignItems="top">
          <UiIconButton onClick={onClickReviewButton} aria-label="reviews" icon={Messenger} />
        </HStack>
      )}
    </Flex>
  );
};
