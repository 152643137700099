import './resources-card-status.scss';
import { FC } from 'react';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { UiStatus } from '@/ui-elements/status/status';
import { VerificationStatus } from '@/config/constants/statuses';
import { VerificationItemApproveStatusNames } from '@/config/constants/texts';
import { VerificationItemApproveStatusColors } from '@/config/constants/approve-status-colors';
import { formatMomentLocal } from '@/helpers/moment/format-moment-local';
import { DateTimeFormat } from '@/config/constants/date';

export const ResourcesCardStatus: FC<{ status?: VerificationStatus }> = ({ status }) => {
  if (!status?.status) return null;

  return (
    <VStack className="resources-card-status" alignItems="flex-start" spacing="1">
      <UiStatus
        text={VerificationItemApproveStatusNames[status?.status]}
        backGroundColor={VerificationItemApproveStatusColors[status?.status]?.background}
        color={VerificationItemApproveStatusColors[status?.status]?.color}
      />
      {status?.departmentName && (
        <HStack>
          <Text className="resources-card-status-subtitle">Филиал:</Text>
          <Text className="resources-card-status-value">{status?.departmentName}</Text>
        </HStack>
      )}
      {status?.date && (
        <HStack>
          <Text className="resources-card-status-subtitle">Отправлено на проверку:</Text>
          <Text className="resources-card-status-value">{formatMomentLocal(status?.date, DateTimeFormat.YYYYMMDDDashHHMM)}</Text>
        </HStack>
      )}
    </VStack>
  );
};
