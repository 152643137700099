import { Navigate } from 'react-router-dom';
import { ProtectedPage } from 'pages/protected-page';
import { navigationMenu } from 'config/navigation-menu';
import { useAccount } from '@/features/account/hooks/use-account';
import { getDefaultPathForRole } from '@/helpers/routes/get-default-path-for-role';

export const DefaultRedirectPage = () => {
  const { currentRole, accountRole } = useAccount();

  const path = getDefaultPathForRole(navigationMenu, currentRole, accountRole);
  return <ProtectedPage path={path}>{path ? <Navigate to={path} /> : null}</ProtectedPage>;
};
