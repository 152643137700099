import { FC, useState } from 'react';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { formValuesToPrepayment } from 'components/verification-forms/helpers/form-values-to-prepayment';
import { toast } from 'react-toastify';
import { OrderDetailsPrepayment } from 'components/order-details/order-details-prepayment/order-details-prepayment';
import { OrderExecutionStatus } from 'config/constants/statuses';
import { useOrderPayment } from '@/features/order/hooks/use-order-payment';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { orderExtender } from '@/features/order/extender/order-extender';
import { PrepaymentFormValues } from '@/types/models/prepayment';
import { Order } from '@/types/models/order';
import { emptyFunction } from '@/helpers/empty-function';
import { useOrderInvalidate } from '@/features/order/hooks/use-order-invalidate';
import { Load } from '@/types/models/loads';

type Props = {
  order: Order | Load;
  vat: boolean;
  orderRefetch?: VoidFunction;
};

export const OrderDetailsPrepaymentView: FC<Props> = ({ order, orderRefetch = emptyFunction, vat }) => {
  const { setOrderPrepaymentPaid } = useOrderPayment(order.id);
  const { allOrdersInvalidate } = useOrderInvalidate();
  const hasPrepayment = [OrderExecutionStatus.Executing, OrderExecutionStatus.Confirmed].includes(order.status as OrderExecutionStatus);

  const [prepayment, setPrepayment] = useState(order.prepayment?.prepayment || 0);
  const [fuel, setFuel] = useState(order.prepayment?.fuel || 0);
  const { getMaxPrepayment, getMaxFuelPrepayment, getPrepaymentTax, getFuelBenefit, getPaymentStep, getPaymentRest } = useExtendedObject(
    order,
    orderExtender
  );

  const initialValues: PrepaymentFormValues = {
    prepayment,
    fuel,
    paid: order.prepayment?.paid || false,
    fuelCompany: {
      label: order.prepayment?.fuelCompany?.name || EMPTY_STRING,
      value: order.prepayment?.fuelCompany?.id?.toString() || EMPTY_STRING,
    },
  };

  const maxPrepayment = getMaxPrepayment(vat, fuel);
  const maxFuelPrepayment = getMaxFuelPrepayment(vat, prepayment);
  const prepaymentStep = getPaymentStep(maxPrepayment);
  const fuelPrepaymentStep = getPaymentStep(maxFuelPrepayment);
  const tax = getPrepaymentTax(prepayment);
  const benefit = getFuelBenefit(fuel);

  const onSubmit = (values: PrepaymentFormValues) => {
    setOrderPrepaymentPaid.mutate(formValuesToPrepayment(values), {
      onSuccess: () => {
        orderRefetch();
        allOrdersInvalidate();
        toast.success('Данные по авансу обновлены');
      },
    });
  };

  return (
    <OrderDetailsPrepayment
      order={order}
      prepayment={prepayment}
      setPrepayment={setPrepayment}
      maxPrepayment={maxPrepayment}
      maxFuelPrepayment={maxFuelPrepayment}
      fuelPrepaymentStep={fuelPrepaymentStep}
      initialValues={initialValues}
      prepaymentStep={prepaymentStep}
      onSubmit={onSubmit}
      benefit={benefit}
      fuel={fuel}
      getPaymentRest={getPaymentRest}
      setFuel={setFuel}
      tax={tax}
      vat={vat}
      hasPrepayment={hasPrepayment}
    />
  );
};
