import { Box } from '@chakra-ui/react';
import { UiInputField } from '@/ui-elements/form-fields/ui-input-field/ui-input-field';
import { ResourcesFormFieldNames, ResourcesFormLabels, ResourcesFormPlaceholders } from '@/features/resources/constants/form';

export const ResourcesSubformSpecifications = () => {
  return (
    <Box className="resources-form-row">
      <UiInputField
        name={ResourcesFormFieldNames.volume}
        label={ResourcesFormLabels.volume}
        placeholder={ResourcesFormPlaceholders.volume}
        type="number"
        required
      />
      <UiInputField
        name={ResourcesFormFieldNames.capacity}
        label={ResourcesFormLabels.capacity}
        placeholder={ResourcesFormPlaceholders.capacity}
        required
      />
      <UiInputField
        name={ResourcesFormFieldNames.dimensions}
        label={ResourcesFormLabels.dimensions}
        placeholder={ResourcesFormPlaceholders.dimensions}
        required
      />
    </Box>
  );
};
