import './register-page.scss';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useCheckAuth } from '@/features/account/hooks/use-check-auth';
import { RegisterFormView } from '@/views/register/register-form-view/register-form-view';

export const RegisterPage: FC = () => {
  const { redirectTo } = useCheckAuth();

  if (redirectTo) {
    return <Navigate to={redirectTo} />;
  }

  return <RegisterFormView />;
};
