import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import classNames from 'classnames';
import Scrollbars from 'react-custom-scrollbars-2';
import { GridTableConfig, GridTableRefProps } from '@/ui-elements/ui-grid-table/uii-grid-table-type';
import './ui-grid-table.scss';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { emptyFunction } from '@/helpers/empty-function';
import { UiGridRowMemoized } from '@/ui-elements/ui-grid-table';
import { InfiniteScroll } from '@/views/infinite-scroll/infinite-scroll';

type Props<T extends { id: number }> = {
  config: GridTableConfig<T>;
  data: Array<T>;
  className?: string;
  selectedId?: number;
  loadMore?: (args: any) => void;
  loading: boolean;
  onClickItem: (item: T) => void;
  isInfiniteScroll: boolean;
};

export const UiGridTableBody = forwardRef<GridTableRefProps, Props<{ id: number }>>((props, ref) => {
  const scrollbarsRef = useRef<Scrollbars>(null);

  useImperativeHandle(ref, () => {
    return { scrollToTop: () => scrollbarsRef?.current?.scrollToTop() };
  });

  const { config, className, data, selectedId, onClickItem = emptyFunction, loading, loadMore = emptyFunction, isInfiniteScroll } = props;
  const countColumns = config.columns.reduce((acc, curr) => acc + (curr?.width || 1), 0);

  return (
    <div className={classNames('ui-grid-table__body', className)}>
      <UiScrollbars autoHide ref={scrollbarsRef}>
        <InfiniteScroll callback={loadMore} loading={loading} skip={!isInfiniteScroll} viewHeight="90%">
          <div className={classNames('ui-grid-table__items')}>
            {data?.map((item) => (
              <UiGridRowMemoized
                key={item.id}
                config={config}
                countColumns={countColumns}
                isSelected={item.id === selectedId}
                item={item}
                onClick={() => onClickItem(item)}
              />
            ))}
          </div>
        </InfiniteScroll>
      </UiScrollbars>
    </div>
  );
});
