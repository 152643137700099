import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/types/redux/root-state';

export const vehicleTypeNamesSelector = createSelector(
  (state: RootState) => state.vehicleTypes,
  (vehicleTypes) =>
    vehicleTypes.vehicleTypes.reduce((acc, curr) => {
      if (curr.isGroup) {
        // @ts-ignore
        const a = curr.types.reduce(
          // @ts-ignore
          (acc2, curr2) => ({
            ...acc2,
            [curr2.id]: curr2.name,
          }),
          {} as Record<number, string>
        );
        return { ...acc, ...a };
      }
      return { ...acc, [curr.id]: curr.name };
    }, {} as Record<number, string>)
);
