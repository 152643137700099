import DatePicker from 'react-multi-date-picker';
import classNames from 'classnames';
import { UiMultiDatePicker } from '@/ui-elements';
import gregorian_ru from './locale';
import './ui-datepicker-input.scss';

type Props = {
  isMulti?: boolean;
  value?: any;
  onChange?: any;
  className?: string;
};

export const UiDatePickerInput = ({ isMulti, className, ...props }: Props) => (
  <DatePicker
    range={isMulti}
    render={<UiMultiDatePicker {...props} />}
    {...props}
    locale={gregorian_ru}
    containerClassName={classNames(className, 'ui-datepicker-input')}
  />
);
