import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { DateObject } from 'react-multi-date-picker';
import qs from 'query-string';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './order-list-view.scss';
import { useOrderActions } from '@/features/order/hooks/use-order-actions';
import { useOrderPage } from '@/features/order/hooks/use-order-page';
import { OrderCompletedSwitcherStatus, OrderExecutionStatus, OrderStatus, OrderSwitcherStatus } from '@/config/constants/statuses';
import { useAppSelector } from '@/hooks/use-app-selector';
import { activeOrderIdsSelector } from '@/features/order/selectors/active-order-ids-selector';
import { isMultipleSelectionSelector } from '@/features/order/selectors/is-multiple-selection-selector';
import { SortType } from '@/config/constants/types';
import { OrderFilterFields } from '@/features/order/types/order-filter';
import { SHOW_CREATE_ORDER } from '@/views/constants/show-create-order';
import { activeOrderTabSelector } from '@/features/order/selectors/active-order-tab-selector';
import { getPeriodFilterValue } from '@/helpers/get-period-filter-value';
import { MOMENT_DD_MM_YYYY } from '@/helpers/moment/constants';
import { OrderFilter as OrderFilterType } from '@/types/api/order-partial';
import { useGetQueryId } from '@/hooks/use-get-query-id';
import { useLocationTyped } from '@/hooks/use-location-typed';
import { orderStatusesSelector } from '@/features/order/selectors/order-statuses-selector';
import { useAllOrdersUpdates } from '@/features/order/hooks/use-all-orders-updates';
import { useOrdersCountUpdates } from '@/features/order/hooks/use-orders-count-updates';
import { sidebarModeSelector } from '@/features/order/selectors';
import { OrderSidebarMode } from '@/types/sidebar/order-sidebar-mode';

export const UseOrderList = () => {
  const [searchParams] = useSearchParams();
  const queryOrderId = useGetQueryId();
  const [filters, setFilters] = useState<OrderFilterType>({
    search: '',
    status: OrderSwitcherStatus.New,
    sortBy: '',
    sortType: SortType.Asc,
    dateFrom: '',
    dateTo: '',
  });

  const [periodValue, setPeriodValue] = useState('all');
  // const [defaultFilter, setDefaultFilter] = useState<string | undefined>();
  const { create, cancelCreate, select, unselect, selectMode, selectTab, setTotalVisibleOrdersCount } = useOrderActions();
  const navigate = useNavigate();

  const [rangeValue, setRangeValue] = useState<Array<DateObject>>([]);
  const location = useLocationTyped();

  const isRedirecting = Boolean(location?.state?.redirectId);

  useEffect(() => {
    const redirectId = location.state?.redirectId;
    if (redirectId) {
      select(redirectId);
      selectTab(OrderSwitcherStatus.New);
      // setDefaultFilter(redirectId.toString());
      // setFilters((prev) => ({ ...prev, status: OrderSwitcherStatus.All, search: redirectId.toString() }));
      navigate(`${location.pathname}?id=${redirectId}`, { state: { redirectId: null } });
    }
  }, [location.pathname, location.state, navigate, select, selectTab]);

  const handleSetRangeValue = (range: DateObject[]) => {
    setRangeValue(range);
  };

  useEffect(() => {
    if (queryOrderId) {
      // setFilters((prev) => ({ ...prev, search: queryOrderId.toString() }));
      // setDefaultFilter(queryOrderId.toString());
      select(Number(queryOrderId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select]);

  useEffect(() => {
    if (periodValue !== '') {
      const { dateFrom, dateTo } = getPeriodFilterValue(periodValue);
      setFilters((prev) => ({ ...prev, dateFrom, dateTo }));
      setRangeValue([]);
    }
  }, [periodValue]);

  useEffect(() => {
    if (rangeValue.length !== 0) {
      if (rangeValue.length === 1)
        setFilters((prev) => ({
          ...prev,
          dateFrom: rangeValue[0].format(MOMENT_DD_MM_YYYY),
          dateTo: '',
        }));
      if (rangeValue.length === 2)
        setFilters((prev) => ({
          ...prev,
          dateFrom: rangeValue[0].format(MOMENT_DD_MM_YYYY),
          dateTo: rangeValue[1].format(MOMENT_DD_MM_YYYY),
        }));
      setPeriodValue('');
    }
  }, [rangeValue]);

  const { data, fetchNextPage, isFetchingNextPage, isInitialLoading } = useAllOrdersUpdates({ query: filters });
  const totalOrdersVisible = data?.pages?.[0]?.pager?.total;
  const allRecords = data?.pages ? data.pages.flatMap((d) => d.data) : [];
  const { ordersCount } = useOrdersCountUpdates();

  const metrics = ordersCount?.data?.data;
  const activeOrderIds = useAppSelector(activeOrderIdsSelector);
  const isMultiple = useAppSelector(isMultipleSelectionSelector);
  const activeTab = useAppSelector(activeOrderTabSelector);
  const mode = useAppSelector(sidebarModeSelector);
  const orderStatuses = useAppSelector(orderStatusesSelector);
  const switcherNames = orderStatuses?.orderSwitcherStatuses;
  const fuelStatuses = orderStatuses?.fuelStatuses;

  useOrderPage(allRecords, activeOrderIds, location?.state?.redirectId);
  useEffect(() => {
    setTotalVisibleOrdersCount(totalOrdersVisible);
  }, [setTotalVisibleOrdersCount, totalOrdersVisible]);

  const handleSelectMode = () => {
    selectMode(!isMultiple);
  };

  const handleFilterChange = debounce((values: OrderFilterFields) => {
    setFilters((prev) => {
      return {
        search: values['order-search'] || '',
        // eslint-disable-next-line no-nested-ternary
        status: values.completed
          ? values.completed.value === OrderCompletedSwitcherStatus.All
            ? `${OrderExecutionStatus.CompletedWithoutDocs}, ${OrderExecutionStatus.CompletedWithDocs}, ${OrderExecutionStatus.CompletedPayed}`
            : values.completed.value
          : prev.status,
        sortBy: prev.sortBy,
        sortType: prev.sortType,
        dateFrom: prev.dateFrom,
        dateTo: prev.dateTo,
      };
    });
  }, 800);

  const handleCreateOrder = useCallback(create, [create]);

  const handleActiveTab = (activeKey: any) => {
    selectTab(activeKey);
    setFilters((prev) => ({
      ...prev,
      status:
        activeKey === OrderSwitcherStatus.Completed
          ? `${OrderExecutionStatus.CompletedWithoutDocs}, ${OrderExecutionStatus.CompletedWithDocs}, ${OrderExecutionStatus.CompletedPayed}`
          : activeKey,
      dateFrom: '',
      dateTo: '',
    }));
  };

  const handleSelect = (id: number) => {
    const url = qs.stringifyUrl({
      url: '/orders',
      query: {
        id,
      },
    });
    if (mode === OrderSidebarMode.NewOrder || mode === OrderSidebarMode.UpdateOrder) cancelCreate();
    navigate(url);
    select(id);
  };

  useEffect(() => {
    if (searchParams.get(SHOW_CREATE_ORDER)) {
      handleCreateOrder();
    }
  }, [handleCreateOrder, searchParams]);

  const handleSetSorting = useCallback(
    (sortBy: string, sortType: SortType) =>
      setFilters((prev) => ({
        ...prev,
        sortBy,
        sortType,
      })),
    []
  );

  return {
    activeTab,
    handleCreateOrder,
    metrics,
    switcherNames,
    handleFilterChange,
    handleActiveTab,
    handleSetSorting,
    handleSelectMode,
    isMultiple,
    handleSetRangeValue,
    periodValue,
    rangeValue,
    setPeriodValue,
    isRedirecting,
    isInitialLoading,
    allRecords,
    activeOrderIds,
    handleSelect,
    unselect,
    fetchNextPage,
    isFetchingNextPage,
    fuelStatuses,
  };
};
