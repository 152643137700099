import { FC } from 'react';
import { Box, HStack, Heading, Text } from '@chakra-ui/react';
import './trip-payment.scss';
import { calculateFinalPrice } from 'components/trip-widget/helpers/get-clean-price';
import { UiStatus } from '@/ui-elements/status/status';
import { PaymentStatusStyles } from '@/features/trip/styles';
import { Order } from '@/types/models/order';
import { toPriceFormat } from '@/helpers/price/to-price-format';
import { Load } from '@/types/models/loads';

type Props = {
  order?: Order | Load;
};

export const TripPayment: FC<Props> = ({ order }) => {
  const priceWithVAT = order?.carrierPayment.priceWithVAT;
  const priceWithoutVAT = order?.carrierPayment.priceWithoutVAT;
  const price = priceWithVAT || priceWithoutVAT || 0;
  const prepayment = order?.prepayment?.prepayment || 0;
  const tax = order?.prepayment?.tax || 0;
  const fuel = order?.prepayment?.fuel || 0;

  const finalPrice = calculateFinalPrice(price, prepayment, tax, fuel);
  const calculationText = order?.prepayment?.possibility
    ? `${price} - ${prepayment} - ${fuel} - ${tax} = ${toPriceFormat(finalPrice)}`
    : `= ${toPriceFormat(finalPrice)}`;

  const statusConfig = order?.carrierPayment.paid ? PaymentStatusStyles.Paid : PaymentStatusStyles.NotPaid;

  return (
    <Box>
      {order?.prepayment?.possibility && (
        <Box className="trip-widget-payment">
          <HStack className="trip-widget-payment__header">
            <Heading className="trip-widget-payment__header-title">Аванс</Heading>
            <HStack>
              <UiStatus text={statusConfig.text} backGroundColor={statusConfig.background} color={statusConfig.color} />
            </HStack>
          </HStack>
          <Text className="trip-widget-payment__info">Аванс в рублях: {toPriceFormat(order?.prepayment?.prepayment)}</Text>
          <Text className="trip-widget-payment__info">Аванс топливом: {toPriceFormat(order?.prepayment?.fuel)}</Text>
          <Text className="trip-widget-payment__info">Комиссия: {toPriceFormat(order.prepayment?.tax)}</Text>
        </Box>
      )}
      <Box className="trip-widget-payment">
        <HStack className="trip-widget-payment__header">
          <Heading className="trip-widget-payment__header-title">Расчет по заявке</Heading>
          <HStack>
            <UiStatus text={statusConfig.text} backGroundColor={statusConfig.background} color={statusConfig.color} />
          </HStack>
        </HStack>
        <Text className="trip-widget-payment__info">Окончательная сумма к расчету по выполнению заявки</Text>
        <Text className="trip-widget-payment__info">{calculationText}</Text>
      </Box>
    </Box>
  );
};
