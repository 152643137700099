import { FunnelPaymentDocsFormValues, LoadPaymentNew } from '@/types/models/funnel';

export const normalizePaymentDocFormValues = (values: FunnelPaymentDocsFormValues): Partial<LoadPaymentNew> => {
  return {
    customerPayment: {
      price: values.clientPaymentTerms?.price,
      vat: values.clientPaymentTerms?.vat?.value,
      paymentConditions: values.clientPaymentTerms?.paymentTerms,
    },
    carrierPayment: {
      priceWithVAT: values.carrierPaymentTerms?.priceWithVAT,
      priceWithoutVAT: values.carrierPaymentTerms?.priceWithoutVAT,
      paymentConditions: values.carrierPaymentTerms?.paymentConditions,
      priceOffer: values.carrierPaymentTerms.priceOffer,
      vat: values.carrierPaymentTerms.hasVat,
    },
    prepayment: {
      possibility: values.carrierPaymentTerms?.hasPrepayment,
      fuelPossibility: values.carrierPaymentTerms?.hasFuel,
    },
  };
};
