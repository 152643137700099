import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetQueryId } from '@/hooks/use-get-query-id';
import { useFunnelActions } from './use-funnel-actions';
import { useLocationTyped } from '@/hooks/use-location-typed';
import { FunnelLoadStatus, FunnelSwitcherStatus } from '@/types/models/funnel';

export const useSetSelectedFunnelLoadWithQuery = (selectLoadID: (id?: number) => void) => {
  const queryloadID = useGetQueryId();

  const { selectTab } = useFunnelActions();
  const navigate = useNavigate();

  const location = useLocationTyped();

  useEffect(() => {
    const redirectId = location.state?.redirectId;
    if (redirectId) {
      selectTab(FunnelSwitcherStatus.NewCalculation);
      navigate(`${location.pathname}?id=${redirectId}`, { state: { redirectId: null } });
    }
  }, [location.pathname, location.state, navigate, selectTab]);

  useEffect(() => {
    if (queryloadID) {
      selectLoadID(Number(queryloadID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
