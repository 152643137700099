import { ApproveStatus, VerificationItemApproveStatus } from 'config/constants/statuses';

export const ApproveStatusColors: Record<ApproveStatus, { background: string; color: string }> = {
  [ApproveStatus.Waiting]: { background: 'accentYellow.100', color: 'typography.50' },
  [ApproveStatus.Processed]: { background: 'accentGreen.150', color: 'typography.250' },
  [ApproveStatus.InProcessing]: { background: 'accentYellow.100', color: 'typography.50' },
};

export const VerificationItemApproveStatusColors: Record<VerificationItemApproveStatus, { background: string; color: string }> = {
  [VerificationItemApproveStatus.Waiting]: { background: 'accentYellow.100', color: 'typography.50' },
  [VerificationItemApproveStatus.Accepted]: { background: 'accentGreen.150', color: 'typography.250' },
  [VerificationItemApproveStatus.Declined]: { background: 'accentRed.100', color: 'typography.250' },
  [VerificationItemApproveStatus.InProcessing]: { background: 'accentYellow.100', color: 'typography.50' },
};
