import { FC } from 'react';
import { PageWrapper } from 'pages/page-wrapper';
import { Flex } from '@chakra-ui/react';
import { FutureRoutesView } from '@/views/carriers-loads/future-routes/future-routes-view';

export const FutureRoutesPage: FC = () => (
  <PageWrapper>
    <Flex>
      <section className="future-routes">
        <FutureRoutesView />
      </section>
    </Flex>
  </PageWrapper>
);
