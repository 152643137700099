import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { vehicleTypesActions } from '@/features/vehicle-types/slice';
import { VehicleResponseType } from '@/types/models/vehicle-response';

export const useVehicleTypesActions = () => {
  const dispatch = useAppDispatch();

  const saveVehicleTypes = (vehicleTypes: Array<VehicleResponseType>) => {
    dispatch(vehicleTypesActions.saveInStore(vehicleTypes));
  };

  return { saveVehicleTypes };
};
