import { FC } from 'react';
import { RadioButtonsConfig } from 'config/constants/radio-buttons';
import { UiRadioGroupField } from '@/ui-elements';
import { ModalView } from '@/views/order-modal/order-modal-view';
import { ModalsProps } from '@/types/common/modals';
import { useOrderActions } from '@/features/order/hooks/use-order-actions';
import { Order } from '@/types/models/order';

type OnSubmitProps = {
  partsToSplit?: string;
};

export const ModalSplit: FC<ModalsProps<{ order?: Order }>> = ({ modalOpened, onClose, order }) => {
  const { split } = useOrderActions();
  const onSubmit = (values: OnSubmitProps) => {
    split({ order, partsToSplit: +values.partsToSplit! ?? 0 });
    onClose(); // todo сделать notification
  };

  return (
    <ModalView
      isOpen={modalOpened}
      onClose={onClose}
      title="На сколько заявок нужно разделить?"
      onSubmit={(values: OnSubmitProps) => onSubmit(values)}
      submitButtonText="Разделить"
      modalWidth={424}
      justifyTitle="left"
    >
      <UiRadioGroupField name="partsToSplit" config={RadioButtonsConfig.splitCount} mb={2} />
    </ModalView>
  );
};
