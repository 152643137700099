import { PointForm } from 'components/create-order-form/schema';
import { TimeType } from 'components/create-order-form/subforms/form-configs';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { normalizeTemplateString } from '@/helpers/normalize-template-string';

export const getArrivalDateTime = (point: PointForm) => {
  const { arrivalDate, arrivalStartTime } = point;

  return normalizeTemplateString(
    point?.arrivalTimeOption.value === TimeType.simple ? `${arrivalDate}` : `${arrivalDate} ${arrivalStartTime || EMPTY_STRING}`
  );
};
