export enum DocumentTypes {
  // документы клиента
  dlDocumentOrder = 'dlDocumentOrder',
  // документы оплаты
  dlDocumentOrderPayment = 'dlDocumentOrderPayment',
  // договор-заявка перевозчика
  dlDocumentOrderToConfirm = 'dlDocumentOrderToConfirm',
  // договор-заявка подписанные документы
  dlDocumentOrderSigned = 'dlDocumentOrderSigned',
  // документы по страховке
  dlDocumentOrderInsurance = 'dlDocumentOrderInsurance',
  // отправка оригиналов исполнителя
  dlDocumentsOrderCarrierOrigins = 'dlDocumentsOrderCarrierOrigins',
  dlDocumentPoint = 'dlDocumentPoint',

  dlDocumentCarrierID = 'dlDocumentCarrierID',
  dlDocumentCarrierTIN = 'dlDocumentCarrierTIN',

  // TODO: убрать из типов dlDocumentVehicle
  dlDocumentVehicleContract = 'dlDocumentVehicleContract',
  dlDocumentVehicleFrontDoc = 'dlDocumentVehicleFrontDoc',
  dlDocumentVehicleBackDoc = 'dlDocumentVehicleBackDoc',
  dlDocumentVehicleVDPDoc = 'dlDocumentVehicleVDPDoc',
  dlDocumentVehicleExtraDoc = 'dlDocumentVehicleExtraDoc',
  Contract = 'Contract',
  VRCFront = 'VRCFront',
  VRCBack = 'VRCBack',
  VPFront = 'VPFront',
  VPBack = 'VPBack',
  ExtraDoc = 'ExtraDoc',

  dlDocumentDriverPassport = 'dlDocumentDriverPassport',
  dlDocumentDriverLicense = 'dlDocumentDriverLicense',
  dlDocumentDriverContract = 'dlDocumentDriverContract',

  dlStamp = 'dlStamp',
  dlSignature = 'dlSignature',
  // документы раздел Согласование - груз
  dlDocumentLoad = 'dlDocumentLoad',
  dlFile = 'dlFile',

  // раздел "моя организация"
  dlDocumentOrganizationManagerPassport = 'dlOrganizationManagerPassport',
  dlDocumentOrganizationInnOgrn = 'dlDocumentOrganizationInnOgrn',
}

export enum DocumentTemplateNames {
  // названия шаблонов договора-заявки с клиентом
  dlContractCustomer = 'dl_contract_customer',
  dlContractCustomer2 = 'dl_contract_customer_2',
  // названия шаблонов договора-заявки с исполнителем
  dlContractCarrier = 'dl_contract_carrier',
  dlContractCarrier2 = 'dl_contract_carrier_2',
}
