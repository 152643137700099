import { FC } from 'react';
import { PageWrapper } from 'pages/page-wrapper';
import { HStack } from '@chakra-ui/react';
import { FuelListView } from '@/views/fuel-list/fuel-list-view';
import { OrderSidebar } from '@/views/order-sidebar/order-sidebar';

export const FuelCardsPage: FC = () => (
  <PageWrapper>
    <HStack>
      <FuelListView />
      <OrderSidebar isFuel />
    </HStack>
  </PageWrapper>
);
