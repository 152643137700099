import { FC } from 'react';
import { OrderDetailsClientOfferInfoButtons, OrderDetailsClientOfferInfo } from 'components/order-details';
import { OrderOffer } from '@/types/models/order-offer';
import './order-details-client-offers.scss';
import { Payment } from '@/types/models/payment';
import { checkAcceptedStatuses } from '@/features/order/helpers/check-accepted-statuses';

type Props = {
  offers: Array<OrderOffer>;
  // цена для перевозчика из заявки
  orderPrice: Pick<Payment, 'priceWithoutVAT' | 'priceWithVAT'>;
  title?: string;
  disableSelect?: boolean;
  children?: JSX.Element;
  // открыть виджет выбрать исполнителя
  onClickSubmitOffer: (orderOffer: OrderOffer, offerId?: number) => void;
  // отправить оффер на проверку
  onSendToCheck: (offer: OrderOffer) => void;
  // кнопка Подробнее - открыть виджет Информация о перевозчике
  onMoreInformation: (id: number) => void;
};

export const OrderDetailsClientOffers: FC<Props> = ({
  offers,
  title,
  disableSelect = false,
  children,
  onClickSubmitOffer,
  onSendToCheck,
  onMoreInformation,
  orderPrice,
}) => {
  if (!offers?.length) return null;
  return (
    <div className="order-details-client-offers">
      {children}
      {title && <div className="order-details-client-offers__title">{title}</div>}
      {offers.map((offer) => {
        const approved = checkAcceptedStatuses({ offer });
        return (
          <OrderDetailsClientOfferInfo
            onMoreInformation={onMoreInformation}
            onSendToCheckClick={() => onSendToCheck(offer)}
            key={offer.id}
            orderOffer={offer}
            className="order-details-client-offers__item"
            approved={approved}
            price={offer.price}
            isOwnPrice={offer.vat ? offer.price !== orderPrice.priceWithVAT : offer.price !== orderPrice.priceWithoutVAT}
          >
            {!disableSelect ? (
              <OrderDetailsClientOfferInfoButtons onClickSubmit={() => onClickSubmitOffer(offer, offer.id)} disabled={!approved} />
            ) : (
              <div />
            )}
          </OrderDetailsClientOfferInfo>
        );
      })}
    </div>
  );
};
