import { FC } from 'react';
import { Order } from '@/types/models/order';
import { SetCarrierOrderInfo } from '../set-carrier-widget-content/set-carrier-order-info';
import { UiButtonFilled, UiTab, UiTabListMenuSelect, UiTabPanel, UiTabPanels, UiTabs } from '@/ui-elements';
import { tripTabs } from '@/features/trip/texts';
import { Driver } from '@/types/models/driver';
import { Carrier } from '@/types/models/carrier';
import './trip-widget.scss';
import { SetCarrierDriverInfo } from '@/components';
import { TripDocuments } from './trip-documents';
import { TripPayment } from './trip-payment';
import { Vehicle } from '@/types/models/vehicle';
import { Load } from '@/types/models/loads';

type Props = {
  order?: Order | Load;
  driver: Driver;
  carrier?: Carrier;
  vehicle: Vehicle;
  handleToTripClick: (id?: number) => void;
  orderId?: number;
};

export const TripWidget: FC<Props> = ({ order, carrier, driver, vehicle, handleToTripClick, orderId }) => {
  return (
    <div className="trip-widget">
      <UiTabs>
        <UiTabListMenuSelect size="lg" className="trip-widget__tablist">
          <UiTab>{tripTabs.Execution}</UiTab>
          <UiTab>{tripTabs.Documents}</UiTab>
          <UiTab>{tripTabs.Payment}</UiTab>
        </UiTabListMenuSelect>
        <UiTabPanels>
          <UiTabPanel>
            <SetCarrierOrderInfo order={order} />
            <SetCarrierDriverInfo carrier={carrier} driver={driver} vehicle={vehicle} />
          </UiTabPanel>
          <UiTabPanel>
            <TripDocuments confirmationDocuments={order?.documents} />
          </UiTabPanel>
          <UiTabPanel>
            <TripPayment order={order} />
          </UiTabPanel>
        </UiTabPanels>
      </UiTabs>
      <UiButtonFilled onClick={() => handleToTripClick(orderId)}>{`Перейти к заявке № ${orderId}`}</UiButtonFilled>
    </div>
  );
};
