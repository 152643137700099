import { FC } from 'react';
import { CarrierCardRegNumber } from 'components/carrier-card/carrier-card-reg-number';
import { HStack } from '@chakra-ui/react';
import { SPACE } from 'config/constants/common-strings';
import { Approval } from '@/types/models/approval';
import { vehicleExtender } from '@/features/vehicles/extender/vehicle-extender';
import { useVehicleTypesIcons } from '@/features/vehicle-types/hooks/use-vehicle-types-icons';
import { useVehicleTypesNames } from '@/features/vehicle-types/hooks/use-vehicle-types-names';

export const SecurityTableVerificationSubjectCell: FC<{ approval: Approval }> = ({ approval }) => {
  const vehicleTypeIcons = useVehicleTypesIcons();
  const vehicleTypeNames = useVehicleTypesNames();
  if (approval.vehicle) {
    const regNumber = vehicleExtender.vehicleRegNumber(approval.vehicle);
    const vehicleRegion = vehicleExtender.vehicleRegion(approval.vehicle);
    const { vehicle } = approval;
    const vehicleTypeId = vehicle?.vehicleType || vehicle?.vehicleTypeId;
    return (
      <HStack>
        {vehicleTypeId && <img width="48px" height="20px" src={vehicleTypeIcons[vehicleTypeId]} alt="vehicle img" />}
        {vehicleTypeId && <span className="vehicle-card-compact__small-name">{vehicleTypeNames[vehicleTypeId]?.split(SPACE)[0]}</span>}
        <CarrierCardRegNumber regNumber={regNumber} region={vehicleRegion} />
      </HStack>
    );
  }
  return <span>{approval.driver?.name || approval.carrier?.name}</span>;
};
