import { isFunction } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { WidgetType } from '@/features/widget/widget-type';
import { widgetActions } from '@/features/widget/slice';
import { widgetConfig } from '@/features/widget/widget-config';

type WidgetList = { [k in keyof WidgetType]: { name: keyof WidgetType; show: (data: WidgetType[k]) => void; hide: () => void } };
/**
 *Хук для управления отображением виджетов. Возвращает виджеты с методами show(data: T)  и hide()
 * */
export const useWidgetActions = (containerKey?: string) => {
  const dispatch = useAppDispatch();
  const showWidget = useCallback(
    (widget: keyof WidgetType, data: WidgetType[typeof widget]) => {
      const { title } = widgetConfig.widgets[widget];
      const widgetTitle = isFunction(title) ? title(data as never) : title;
      dispatch(
        widgetActions.showWidget({
          name: widget,
          data,
          containerKey,
          title: widgetTitle,
        })
      );
    },
    [containerKey, dispatch]
  );
  const hideWidget = useCallback(
    (widget?: keyof WidgetType) => {
      if (widget) dispatch(widgetActions.hideWidget(widget));
    },
    [dispatch]
  );

  const setActive = useCallback(
    (widget?: keyof WidgetType) => {
      if (widget) dispatch(widgetActions.setActive(widget));
    },
    [dispatch]
  );

  const closeAllWidgets = useCallback(() => {
    dispatch(widgetActions.closeAllWidgets({ containerKey }));
  }, [containerKey, dispatch]);

  const widgetList = useMemo(
    () =>
      Object.keys(widgetConfig.widgets).reduce((acc, curr) => {
        const key = curr as any as keyof WidgetType;
        acc[key] = {
          name: key,
          show: (data: WidgetType[typeof key]) => {
            showWidget(key, data);
          },
          hide: () => hideWidget(key),
        };
        return acc;
      }, {} as WidgetList),
    [hideWidget, showWidget]
  );

  return {
    showWidget,
    hideWidget,
    closeAllWidgets,
    setActive,
    ...widgetList,
  };
};
