import { FC } from 'react';
import { VehicleVerificationForm } from 'components/verification-forms/vehicle-verification/vehicle-verification';
import { OrganizationVerificationForm } from 'components/verification-forms/organization-verification/organization-verification';
import { Box, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { DriverVerificationForm } from 'components/verification-forms/driver-verification/driver-verification';
import closedIcon from 'public/assets/icons/CloseIcon.svg';
import { ModalFormType, ModalsProps, ModalType } from '@/types/common/modals';
import { useModal } from '@/hooks/use-modal';
import { UiIconButton } from '@/ui-elements';

export const ModalForm: FC<ModalsProps<{ type?: ModalFormType }>> = ({ type, onClose, modalOpened }) => {
  const { modalView: confirmModal, setModalOpened } = useModal(ModalType.Confirm, {
    onCloseModal: () => setModalOpened(false),
    onSuccess: onClose,
    modalWidth: 524,
    text: 'Вы действительно хотите закрыть окно?',
    removeXBtn: true,
    desc: 'Введенные данные не сохранятся',
    alignDesc: 'left',
    cancelButtonText: 'Назад',
    submitButtonText: 'Закрыть',
    accentXBtn: true,
  });

  const onCancelCreation = () => setModalOpened(true); // закрытие модалки создания формы

  let form: JSX.Element;
  switch (type) {
    case 'vehicle':
      form = <VehicleVerificationForm onClose={onClose} onCancelCreation={onCancelCreation} />;
      break;
    case 'org':
      form = <OrganizationVerificationForm onClose={onClose} onCancelCreation={onCancelCreation} />;
      break;
    case 'driver':
      form = <DriverVerificationForm onClose={onClose} onCancelCreation={onCancelCreation} />;
      break;
    default:
      form = <div>{` `}</div>;
      break;
  }

  return (
    <Modal isOpen={modalOpened} onClose={onCancelCreation}>
      {confirmModal}
      <ModalOverlay />
      <ModalContent borderRadius={24} boxShadow="xl" pos="relative" maxW={798} w={798} p={10}>
        <Box position="absolute" right={12} top="43px">
          <UiIconButton aria-label="close-modal-button" icon={closedIcon} onClick={onCancelCreation} />
        </Box>
        {form}
      </ModalContent>
    </Modal>
  );
};
