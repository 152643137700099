import { Rule } from '@/types/abac/rule';
import { AccountRole } from '@/types/api/account-role';
import { RoutesConfig } from '@/types/routes/routes-config';
import { checkAccountRole } from '@/helpers/routes/check-account-role';

type RoutesRulesType = { permissions: Record<string, string>; rules: Record<string, any> };

export const getRouteRules = (currentRules: Rule, routesConfig: RoutesConfig, accountRole: AccountRole | Array<AccountRole> | undefined) =>
  Object.values(routesConfig.routes).reduce(
    (acc, curr) => {
      acc.permissions[curr.path] = curr.path;
      curr.availableRoles?.forEach((role) => {
        const availableAccountRoles = checkAccountRole({ accountRole, availableAccountRoles: curr.availableAccountRoles });
        if (availableAccountRoles) {
          acc.rules[role] = { ...acc.rules[role], [curr.path]: true };
        }
      });
      return acc;
    },
    { permissions: {}, rules: currentRules } as RoutesRulesType
  );
