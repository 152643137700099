import { FC } from 'react';
import { WidgetType } from '@/features/widget/widget-type';
import { WidgetProps } from '@/features/widget/types/widget-item';
import './carrier-widget.scss';
import { CarrierWidget } from '@/components/carrier-widget/carrier-widget';
import { useCarrierUpdates } from '@/features/carriers/hooks/use-carrier-updates';
import { useCarrierVehiclesUpdates } from '@/features/carriers/hooks/use-carrier-vehicles-updates';

export const CarrierWidgetView: FC<WidgetProps & WidgetType['CarrierWidget']> = ({ carrierId }) => {
  const { carrier } = useCarrierUpdates(carrierId);
  const { carrierVehicles } = useCarrierVehiclesUpdates(carrierId);

  return (
    <div className="carrier-widget-view">
      <CarrierWidget carrier={carrier.data?.data} vehicles={carrierVehicles.data?.data} />
    </div>
  );
};
