import { Box } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useIcons } from '@/hooks/use-icons';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';
import { UiButtonFilled } from '@/ui-elements';
import { useResourcesVehiclesView } from '@/features/resources/hooks';
import { ResourcesButtonActions } from '@/features/resources/constants/general';
import { ResourcesVehiclesList } from '@/components/resources/vehicles-list/resources-vehicles-list';

export const ResourcesVehiclesView = () => {
  const { vehicles, loading, selectedVehicleId, isFetchingNextPage, showApprovalStatuses, fetchNextPage, selectVehicle, handleCreateVehicle } =
    useResourcesVehiclesView();
  const { CarType } = useIcons();

  if (loading) {
    return <UiSpinner visible mt={48} />;
  }

  return (
    <Box className="resources-view-content">
      {!isEmpty(vehicles) && (
        <UiButtonFilled onClick={handleCreateVehicle} leftIcon={CarType} mode="light" className="resources-view-button">
          {ResourcesButtonActions.AddDVehicle}
        </UiButtonFilled>
      )}
      <ResourcesVehiclesList
        vehicles={vehicles}
        selectedVehicleId={selectedVehicleId}
        isFetchingNextPage={isFetchingNextPage}
        showApprovalStatuses={showApprovalStatuses}
        handleSelectVehicle={selectVehicle}
        fetchNextPage={fetchNextPage}
        handleCreateVehicle={handleCreateVehicle}
      />
    </Box>
  );
};
