import { useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { useEffect } from 'react';
import { Order } from '@/types/models/order';
import { useCarrierLoadActions } from './use-carriers-loads-actions';
import { useLocationTyped } from '@/hooks/use-location-typed';

export const useCarriersLoadsPage = (orders?: Array<Order>, activeOrderId?: number, redirectId?: number, isAuth = false) => {
  const { clearState, select } = useCarrierLoadActions();
  const navigate = useNavigate();
  const location = useLocationTyped();

  useEffect(() => {
    if (!activeOrderId && orders?.length && !redirectId && isAuth) {
      select(orders[0].id);
      const url = qs.stringifyUrl({
        url: location.pathname,
        query: {
          id: orders[0].id,
        },
      });
      navigate(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, activeOrderId, select, redirectId]);

  useEffect(
    () =>
      // при переключении на другую страницу сбрасывает стор с настройками страницы с заказами
      () =>
        clearState(),
    [clearState]
  );
};
