import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SettingsState } from '@/features/settings/types/settings-state';

const initialState: SettingsState = {
  activeSettingsId: undefined,
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    select: (state, action: PayloadAction<number>) => {
      state.activeSettingsId = action.payload;
    },
    unselect: (state) => {
      state.activeSettingsId = undefined;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const settingsReducers = slice.reducer;
export const settingsActions = slice.actions;
