import { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { DetailsComponent } from 'components/carriers-loads/details/details-component/details-component';
import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';

type Props = {
  order: Order | Load;
  onOrderEnd: () => void;
};

export const CurrentRouteDetails: FC<Props> = ({ order, onOrderEnd }) => {
  return (
    <Box className="current-route-details">
      <DetailsComponent order={order} onOrderEnd={onOrderEnd} />
    </Box>
  );
};
