import { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { VerificationFormMode } from 'components/verification-forms/types';
import { useVehicleDocsSubform } from 'components/verification-forms/hooks/use-vehicle-docs-subform';
import { UiFileUploadField, UiFileUploadInput } from '@/ui-elements';

export const VehicleDocsSubform: FC<{ mode: VerificationFormMode; vehicleId?: number }> = ({ mode, vehicleId }) => {
  const { documentsFields } = useVehicleDocsSubform({ vehicleId });

  return (
    <>
      <Box mt={5}>
        <Text fontSize={18} fontWeight={700}>
          Документы
        </Text>
        <Text mt={4}>Свидетельство о регистрации транспортного средства (СТС)</Text>
      </Box>
      {documentsFields.map(({ name, label, actions }) => {
        return (
          <Box mt={4}>
            <Text mt={3} mb={2} children={label} />
            {mode === 'create' ? <UiFileUploadField name={name} /> : <UiFileUploadInput {...actions} name={name} />}
          </Box>
        );
      })}
    </>
  );
};
