import { VStack, Text, HStack } from '@chakra-ui/react';
import { PHONE } from 'components/carrier-info/components/carrier-general-info/carrier-info-card/texts';
import { FC } from 'react';
import { InfoLine } from '@/components/';

type Props = {
  phone?: string;
  vehicleCount?: number;
  driversCount?: number;
  className?: string;
};
export const CarrierInfoCard: FC<Props> = ({ vehicleCount, driversCount, phone, className }) => (
  <VStack align="start" mt={6} className={className}>
    <Text textStyle="h2">Информация о перевозчике</Text>
    <HStack pt={6} textStyle="h5">
      {phone && <InfoLine text={PHONE} value={phone} />}
    </HStack>
    <HStack textStyle="h5" pt={4}>
      <InfoLine text="ТС в парке:" value={vehicleCount || 0} />
      <InfoLine text="Водителей в штате:" value={driversCount || 0} />
    </HStack>
  </VStack>
);

// TODO добавить телефон перевозчика, количество ТС и количество водителей.
