import { Dictionary } from 'config/constants/dictionary';
import { ApiDelete, ApiGet, ApiPost } from '@/helpers/api/methods';
import { AttachmentNew, AttachmentQuery, AttachmentToConfirmDeleteQuery } from '@/types/models/attachments';
import { FileDocument } from '@/types/models/document';
import { validateQueryString } from '@/helpers/validate-query-string';
import { ContentType } from '@/types/api/content-type';
import { createFormData } from '@/helpers/forms/create-form-data';
import { ResponseSimpleApi } from '@/types/response';
import { AttachmentData } from '@/types/models/attachment-data';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';

export const AttachmentsApi = {
  getAttachments: (query: AttachmentQuery & { updates?: boolean }) =>
    ApiGet<ResponseWithoutPagerSimple<Array<FileDocument>>>(`${Dictionary.Attachments}${validateQueryString(query)}`),

  postAttachment: (body: AttachmentNew) =>
    ApiPost<ResponseWithoutPagerSimple<FileDocument>>(`${Dictionary.Attachments}`, createFormData(body), { contentType: ContentType.FORM_DATA }),

  deleteAttachment: (id: number) => ApiDelete<ResponseSimpleApi>(`${Dictionary.Attachments}/${id}`),
  deleteToConfirm: (body: AttachmentToConfirmDeleteQuery) =>
    ApiPost<ResponseSimpleApi>(`${Dictionary.Attachments}/deleteToConfirm`, createFormData(body), { contentType: ContentType.FORM_DATA }),

  downloadAttachment: (link: string) => ApiGet<AttachmentData>(link),
};
