import { apiInstance } from '@/helpers/api/instance';
import { ContentType } from '@/types/api/content-type';

const defaultContentType = ContentType.JSON;
const defaultOptions = { useBearer: true, contentType: defaultContentType };
const defaultPostOptions = { useBearer: true, contentType: defaultContentType };
type Options = { useBearer?: boolean; contentType?: string };

export const ApiGet = <T>(endpoint: string, options: Options = defaultOptions): Promise<T> => {
  const { useBearer } = options;
  return apiInstance(useBearer)
    .get<T>(endpoint)
    .then((r) => r.data);
};
export const ApiPatch = <T>(endpoint: string, body: object, options: Options = defaultPostOptions): Promise<T> => {
  const { useBearer, contentType } = options;
  return apiInstance(useBearer)
    .patch<T>(endpoint, body, {
      headers: {
        'Content-Type': contentType || defaultContentType,
      },
    })
    .then((r) => r.data);
};
export const ApiPut = <T>(endpoint: string, body: object, options: Options = defaultPostOptions): Promise<T> => {
  const { useBearer, contentType } = options;

  return apiInstance(useBearer)
    .put<T>(endpoint, body, {
      headers: {
        'Content-Type': contentType || defaultContentType,
      },
    })
    .then((r) => r.data);
};
export const ApiPost = <T>(
  endpoint: string,
  body?: object,
  options: { useBearer?: boolean; contentType?: string } = defaultPostOptions
): Promise<T> => {
  const { useBearer, contentType } = options;

  return apiInstance(useBearer)
    .post<T>(endpoint, body, {
      headers: {
        'Content-Type': contentType || defaultContentType,
      },
    })
    .then((r) => r.data);
};
export const ApiDelete = <T>(endpoint: string, options: Options = defaultOptions): Promise<T> => {
  const { useBearer } = options;
  return apiInstance(useBearer)
    .delete<T>(endpoint)
    .then((r) => r.data);
};
