import * as yup from 'yup';
import { ErrorMessage } from './error-messages';

const requiredString = yup.string().required(ErrorMessage.Required);
const requiredNumber = yup.number().required(ErrorMessage.Required);

export const createCustomersSchema = yup.object({
  customer: requiredString,
  inn: requiredNumber,
});
