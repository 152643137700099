import { Box, Heading } from '@chakra-ui/react';
import {
  ResourcesFormFieldNames,
  ResourcesFormHelperText,
  ResourcesFormLabels,
  ResourcesFormPlaceholders,
} from '@/features/resources/constants/form';
import { UiInputField, UiPhoneField } from '@/ui-elements';

export const ResourcesDriverSubformContacts = () => {
  return (
    <Box>
      <Heading as="h3" className="resources-form-subtitle">
        Контактные данные
      </Heading>
      <Box className="resources-form-row">
        <UiInputField
          name={ResourcesFormFieldNames.country}
          label={ResourcesFormLabels.country}
          placeholder={ResourcesFormPlaceholders.country}
          content={ResourcesFormHelperText.country}
          required
        />
        <UiPhoneField
          name={ResourcesFormFieldNames.phoneNumber}
          label={ResourcesFormLabels.phoneNumber}
          placeholder={ResourcesFormPlaceholders.phoneNumber}
          content={ResourcesFormHelperText.phoneNumber}
          required
        />
      </Box>
    </Box>
  );
};
