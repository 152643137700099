import { ResourcesVehicleDetailsComponent } from '@/components/resources/vehicle-details-component/resources-vehicle-details-component';
import { useVehicleDetailsView } from '@/features/resources/hooks';

export const ResourcesVehicleDetailsView = () => {
  const { vehicle, loading, ModalDeleteVehicle, ModalEditNotify, handleEditVehicle, handleDeleteVehicle } = useVehicleDetailsView();

  return (
    <>
      <ResourcesVehicleDetailsComponent
        vehicle={vehicle}
        loading={loading}
        handleEditVehicle={handleEditVehicle}
        handleDeleteVehicle={handleDeleteVehicle}
      />
      {ModalDeleteVehicle}
      {ModalEditNotify}
    </>
  );
};
