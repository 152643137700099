import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import { organizationFields } from 'components/verification-forms/constants';
import { createFormVehicleValues } from 'components/verification-forms/helpers/create-form-vehicle-values';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { DocumentTypes } from 'config/constants/document-types';
import { AttachmentDeps } from 'config/constants/types';
import { FormValues } from 'components/verification-forms/types';
import { formatFormFields } from '@/helpers/forms/format-form-fields';
import { Vehicle } from '@/types/models/vehicle';
import { useVehicleActions } from '@/features/vehicles/hooks/use-vehicle-actions';
import { useAttachmentsActions } from '@/features/attachments/hooks/use-attachments-actions';
import { useCreateApproval } from '@/features/approvals/hooks/use-create-approval';
import { useAccount } from '@/features/account/hooks/use-account';
import { useCarrierInvalidate } from '@/features/carriers/hooks/use-carrier-invalidate';
import { VoidFunction } from '@/types/common/void-function';
import { VehicleFormValues } from '../schema';

type Errors = {
  driverId?: string;
  carrierId?: string;
  vehicleType?: string;
  regNumber?: string;
  volume?: string;
  capacity?: string;
  dimensions?: string;
};

export const useVehicleVerificationForm = (
  mode: string,
  onCancelCreation?: VoidFunction,
  onClose?: VoidFunction,
  onCancel?: VoidFunction,
  vehicleId?: number,
  initialValues = {} as FormValues
) => {
  const { allCarriersInvalidate, carrierInvalidate } = useCarrierInvalidate();

  const { postAttachment } = useAttachmentsActions();
  const [comment, setComment] = useState('');
  const [withoutTrailer, setWithoutTrailer] = useState(isEmpty(initialValues?.trailers)); // для специальных
  const [vehicleGroup, setVehicleGroup] = useState<number>(initialValues.vehicleGroup || 0);
  const [ownType, setOwnType] = useState<number>(initialValues.ownType || 0);
  const { updateVehicle, addVehicle } = useVehicleActions(vehicleId!);
  // const { VehicleTypesSortedSelector } = useVehicleTypesSortedSelector('group', vehicleGroup);
  // const vehicleTypes = useAppSelector(VehicleTypesSortedSelector);
  // const vehicleTypes = useAppSelector(groupedOptionsSelector);
  const { createApproval } = useCreateApproval();
  const { currentAccount } = useAccount();

  const handleVehicleType = (val: string) => {
    setVehicleGroup(Number(val));
    if (Number(val) === 0) {
      setWithoutTrailer(true);
    } else if (Number(val) !== 2) {
      setWithoutTrailer(false);
    }
  };

  const onApprove = (values: FormValues) => {
    const newValues = formatFormFields(values, 'pick', organizationFields);
    // @ts-ignore
    updateVehicle.mutate(newValues as Vehicle);
    // onApprovalApproved?.(comment);
    onClose?.();
  };

  const queryClient = useQueryClient();

  const onUpdate = (values: VehicleFormValues) => {
    updateVehicle.mutate(values, {
      onSuccess: () => {
        createApproval.mutate(
          {
            carrierId: values.carrierId,
            vehicleId,
            departmentId: currentAccount?.user?.department?.id,
            comment: comment || EMPTY_STRING,
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(['carrierVehicles', values.carrierId]);
              carrierInvalidate(values.carrierId);
              allCarriersInvalidate();
              onClose?.();
            },
          }
        );
      },
    });
  };

  const onCreate = (values: FormValues) => {
    addVehicle.mutate(createFormVehicleValues(values, withoutTrailer), {
      onSuccess: (response) => {
        const record = response.data;
        createApproval.mutate(
          {
            carrierId: values.carrierId?.value,
            vehicleId: record.id,
            departmentId: currentAccount?.user?.department?.id,
            comment: comment || EMPTY_STRING,
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(['carrierVehicles', values.carrierId?.value]);
              carrierInvalidate(values.carrierId?.value);
              allCarriersInvalidate();
              onClose?.();
            },
          }
        );
        if (record) {
          values.rentDoc?.map((file: File) => {
            postAttachment.mutate({
              attachment: file,
              dependent: AttachmentDeps.Vehicle,
              dependentID: record.id,
              subject: DocumentTypes.dlDocumentVehicleContract,
            });
          });
          values.vehicleFrontDoc?.map((file: File) => {
            postAttachment.mutate({
              attachment: file,
              dependent: AttachmentDeps.Vehicle,
              dependentID: record.id,
              subject: DocumentTypes.dlDocumentVehicleFrontDoc,
            });
          });
          values.vehicleBackDoc?.map((file: File) => {
            postAttachment.mutate({
              attachment: file,
              dependent: AttachmentDeps.Vehicle,
              dependentID: record.id,
              subject: DocumentTypes.dlDocumentVehicleBackDoc,
            });
          });
          values.VDPDoc?.map((file: File) => {
            postAttachment.mutate({
              attachment: file,
              dependent: AttachmentDeps.Vehicle,
              dependentID: record.id,
              subject: DocumentTypes.dlDocumentVehicleVDPDoc,
            });
          });
          values.extraDoc?.map((file: File) => {
            postAttachment.mutate({
              attachment: file,
              dependent: AttachmentDeps.Vehicle,
              dependentID: record.id,
              subject: DocumentTypes.dlDocumentVehicleExtraDoc,
            });
          });
        }
      },
    });
  };

  const handleClose = () => {
    // onApprovalDeclined?.(comment);
    onClose?.();
  };

  const handleFormSubmit = (initValues: FormValues) => {
    if (mode === 'edit') {
      const values = createFormVehicleValues(initValues, withoutTrailer);
      return onUpdate(values);
    }
    return mode === 'check' ? onApprove(initValues) : onCreate(initValues);
  };

  const handleFormCancel = () => {
    if (onCancel) {
      return onCancel();
    }
    if (mode === 'check') {
      handleClose();
    }
    return onCancelCreation?.();
  };

  const validate = (values: FormValues) => {
    const errors: Errors = {};
    const REQUIRED = 'Обязательно поле';
    if (isEmpty(values.driverId)) {
      errors.driverId = REQUIRED;
    }
    if (isEmpty(values.carrierId)) {
      errors.carrierId = REQUIRED;
    }
    if (isEmpty(values.vehicleType)) {
      errors.vehicleType = REQUIRED;
    }
    if (withoutTrailer && !values.volume) {
      errors.volume = REQUIRED;
    }
    if (withoutTrailer && !values.capacity) {
      errors.capacity = REQUIRED;
    }
    if (withoutTrailer && !values.dimensions) {
      errors.dimensions = REQUIRED;
    }
    if (!values.regNumber) {
      errors.regNumber = REQUIRED;
    }
    return errors;
  };

  const submitting = mode === 'check' || mode === 'edit' ? updateVehicle.isLoading : addVehicle.isLoading;

  return {
    handleFormSubmit,
    validate,
    vehicleGroup,
    handleVehicleType,
    withoutTrailer,
    setWithoutTrailer,
    ownType,
    setOwnType,
    comment,
    setComment,
    handleFormCancel,
    submitting,
  };
};
