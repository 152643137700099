import { useEffect } from 'react';
import { Load } from '@/types/models/loads';
import { ButtonDirection, FunnelSwitcherStatus, LogisticsLoadStatus } from '@/types/models/funnel';
import { Order } from '@/types/models/order';
import { OrderExecutionStatus } from '@/config/constants/statuses';

/* Если текущий груз после согласования перевели в статус "На оформлении" в модуле Логистика, 
то мы не можем вернуть груз в статус "Отправлено клиенту", необходимо блокировать кнопку */
export const useDisableButtonForProcessed = ({
  load,
  activeTab,
  handleChangeButtonDisabledState,
}: {
  load?: Load | Order;
  activeTab: FunnelSwitcherStatus;
  handleChangeButtonDisabledState: (direction: ButtonDirection, value: boolean) => void;
}) => {
  useEffect(() => {
    if (activeTab === FunnelSwitcherStatus.CostConfirmed) {
      const loadIsProcessed = Boolean(load && 'logisticsStatus' in load && load?.logisticsStatus === LogisticsLoadStatus.IsProcessed);
      const loadIsNotNew = Boolean(load?.status !== OrderExecutionStatus.New);
      handleChangeButtonDisabledState(ButtonDirection.Back, loadIsProcessed || loadIsNotNew);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, load]);
};
