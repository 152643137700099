export const dataSourceForAdministrator = [
  {
    name: 'Петров И.И.',
    finished: '60',
    amount: '6 000 000',
    margin: '250 000',
  },
  {
    name: 'Петров И.И.',
    finished: '60',
    amount: '6 000 000',
    margin: '250 000',
  },
  {
    name: 'Петров И.И.',
    finished: '60',
    amount: '6 000 000',
    margin: '250 000',
  },
  {
    name: 'Петров И.И.',
    finished: '60',
    amount: '6 000 000',
    margin: '250 000',
  },
  {
    name: 'Петров И.И.',
    finished: '60',
    amount: '6 000 000',
    margin: '250 000',
  },
  {
    name: 'Петров И.И.',
    finished: '60',
    amount: '6 000 000',
    margin: '250 000',
  },
  {
    name: 'Петров И.И.',
    finished: '60',
    amount: '6 000 000',
    margin: '250 000',
  },
  {
    name: 'Петров И.И.',
    finished: '60',
    amount: '6 000 000',
    margin: '250 000',
  },
  {
    name: 'Петров И.И.',
    finished: '60',
    amount: '6 000 000',
    margin: '250 000',
  },
  {
    name: 'Петров И.И.',
    finished: '60',
    amount: '6 000 000',
    margin: '250 000',
  },
  {
    name: 'Петров И.И.',
    finished: '60',
    amount: '6 000 000',
    margin: '250 000',
  },
  {
    name: 'Петров И.И.',
    finished: '60',
    amount: '6 000 000',
    margin: '250 000',
  },
  {
    name: 'Петров И.И.',
    finished: '60',
    amount: '6 000 000',
    margin: '250 000',
  },
];
