import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResourcesState } from '@/features/resources/types/state';
import { ResourcesContentTab, ResourcesSidebarMode, ResourcesStatusTab } from '@/features/resources/types/general';

const initialState: ResourcesState = {
  activeContentTab: ResourcesContentTab.Drivers,
  activeStatusTab: ResourcesStatusTab.All,
  sidebarMode: ResourcesSidebarMode.None,
  selectedDriverId: null,
  selectedVehicleId: null,
};

const slice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    setContentTab: (state, action: PayloadAction<ResourcesContentTab>) => {
      state.activeContentTab = action.payload;
    },
    setStatusTab: (state, action: PayloadAction<ResourcesStatusTab>) => {
      state.activeStatusTab = action.payload;
    },
    setSidebarMode: (state, action: PayloadAction<ResourcesSidebarMode>) => {
      state.sidebarMode = action.payload;
    },
    selectDriver: (state, action: PayloadAction<number | null>) => {
      state.selectedDriverId = action.payload;
    },
    selectVehicle: (state, action: PayloadAction<number | null>) => {
      state.selectedVehicleId = action.payload;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const resourcesReducers = slice.reducer;
export const resourcesActions = slice.actions;
