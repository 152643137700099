import { CommonStatus } from '@/config/constants/statuses';
import { VehicleType } from '@/config/constants/vehicle';

export const typeTS = [
  {
    value: VehicleType.Jumbo,
    label: 'Фура Джамбо',
  },
  {
    value: VehicleType.TentSemi,
    label: 'Полуприцепт Тентовый',
  },
  {
    value: VehicleType.FlatbedSemi,
    label: 'Полуприцепт Бортовой',
  },
  {
    value: VehicleType.MegaTruck,
    label: 'Мегафура',
  },
  {
    value: VehicleType.ContainerTruck,
    label: 'Контейнеровоз',
  },
  {
    value: VehicleType.Tractor,
    label: 'Тягач',
  },
  {
    value: VehicleType.GoodsVan,
    label: 'Фургон Промтоварный',
  },
  {
    value: VehicleType.MeatVan,
    label: 'Фургон Тушевоз',
  },
  {
    value: VehicleType.IsometricVan,
    label: 'Фургон Изометрический',
  },
  {
    value: VehicleType.TailLiftVan,
    label: 'Фургон Гидроборт',
  },
  {
    value: VehicleType.FullMetalVan,
    label: 'Фургон Цельнометалический',
  },
  {
    value: VehicleType.CurtainVan,
    label: 'Фургон Шторный',
  },
  {
    value: VehicleType.TentVan,
    label: 'Фургон Тентовый',
  },
  {
    value: VehicleType.FlatbedVan,
    label: 'Фургон Бортовой',
  },
  {
    value: VehicleType.Refrigerator,
    label: 'Рефрижератор',
  },
  {
    value: VehicleType.Trawl,
    label: 'Трал',
  },
  {
    value: VehicleType.LongTruck,
    label: 'Длинномер',
  },
  {
    value: VehicleType.TankTruck,
    label: 'Автоцистерна',
  },
  {
    value: VehicleType.BitumenTruck,
    label: 'Битумовоз',
  },
  {
    value: VehicleType.DumpTruck,
    label: 'Самосвал',
  },
  {
    value: VehicleType.CraneTruck,
    label: 'Кран',
  },
  {
    value: VehicleType.TowerTruck,
    label: 'Автовышка',
  },
  {
    value: VehicleType.Manipulator,
    label: 'Манипулятор',
  },
  {
    value: VehicleType.Mixer,
    label: 'Миксер',
  },
  {
    value: VehicleType.CementTruck,
    label: 'Цементовоз',
  },
  {
    value: VehicleType.GasolineTanker,
    label: 'Бензовоз',
  },
  {
    value: VehicleType.ChemicalTruck,
    label: 'Перевозка химии',
  },
  {
    value: VehicleType.GasTanker,
    label: 'Газозаправщик',
  },
  {
    value: VehicleType.GasTruck,
    label: 'Газовоз',
  },
  {
    value: VehicleType.Tanker,
    label: 'Топливозаправщик',
  },
  {
    value: VehicleType.GrainTruck,
    label: 'Зерновоз',
  },
  {
    value: VehicleType.LogTruck,
    label: 'Сортиментовоз',
  },
  {
    value: VehicleType.FlourTruck,
    label: 'Муковоз',
  },
  {
    value: VehicleType.FeedTruck,
    label: 'Кормовоз',
  },
  {
    value: VehicleType.TimberTruck,
    label: 'Лесовоз',
  },
  {
    value: VehicleType.BunkerTruck,
    label: 'Бункер',
  },
  {
    value: VehicleType.MilkTruck,
    label: 'Молоковоз',
  },
  {
    value: VehicleType.CarTransporter,
    label: 'Автовоз',
  },
  {
    value: VehicleType.ScrapTruck,
    label: 'Ломовоз',
  },
  {
    value: VehicleType.GlassTruck,
    label: 'Стекловоз',
  },
  {
    value: VehicleType.PanelTruck,
    label: 'Панелевоз',
  },
  {
    value: VehicleType.BulkTruck,
    label: 'Балковоз',
  },
  {
    value: VehicleType.StickTruck,
    label: 'Клюшковоз',
  },
  {
    value: VehicleType.CattleTruck,
    label: 'Скотовоз',
  },
  {
    value: VehicleType.MultiLift,
    label: 'Мультилифт',
  },
  {
    value: VehicleType.Chaland,
    label: 'Шаланда',
  },
  {
    value: VehicleType.HorseTruck,
    label: 'Коневоз',
  },
];

export const riskGroup = [
  {
    value: 1,
    label: '1 первая',
  },
  {
    value: 2,
    label: '2 вторая',
  },
  {
    value: 3,
    label: '3 третья',
  },
  {
    value: 4,
    label: '4 четвертая',
  },
  {
    value: 5,
    label: '5 пятая',
  },
  {
    value: 6,
    label: '6 шестая',
  },
];

export const radiusSearch = [
  {
    value: 5,
    label: '5 км',
  },
  {
    value: 10,
    label: '10 км',
  },
  {
    value: 15,
    label: '15 км',
  },
  {
    value: 20,
    label: '20 км',
  },
  {
    value: 25,
    label: '25 км',
  },
  {
    value: 30,
    label: '30 км',
  },
  {
    value: 35,
    label: '35 км',
  },
  {
    value: 40,
    label: '40 км',
  },
  {
    value: 45,
    label: '45 км',
  },
  {
    value: 50,
    label: '50 км',
  },
];

export const carrierStatuses = [
  {
    value: CommonStatus.All,
    label: 'Все',
  },
  {
    value: CommonStatus.Approved,
    label: 'Проверенные',
  },
  {
    value: CommonStatus.Waiting,
    label: 'На проверке',
  },
];
