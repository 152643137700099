import './resoures-vehicle-card.scss';
import { FC } from 'react';
import classNames from 'classnames';
import { capitalize, isNil } from 'lodash';
import { HStack, VStack, Text, Box } from '@chakra-ui/react';
import { Vehicle } from '@/types/models/vehicle';
import { COMMA_SPACE } from '@/config/constants/common-strings';
import { UiButtonOutlined, UiDivider } from '@/ui-elements';
import { AVAILABLED_TEXT, BAILED_TEXT, ResourcesButtonActions } from '@/features/resources/constants/general';
import { useVehicleTypesIcons } from '@/features/vehicle-types/hooks/use-vehicle-types-icons';
import { useVehicleTypesNames } from '@/features/vehicle-types/hooks/use-vehicle-types-names';
import { getVehicleRegNumber, getVehicleRegion } from '@/features/vehicles/extender/vehicle-extender';
import { CarrierCardRegNumber } from '@/components/carrier-card/carrier-card-reg-number';
import { VehicleOwnTypeLabels } from '@/features/resources/constants/vehicle';
import { ResourcesVehicleSpecifications } from '@/components/resources/vehicle-specifications/resources-vehicle-specifications';
import { ResourcesStatusesList } from '@/components/resources/statuses-list/resources-statuses-list';

interface Props {
  vehicle: Vehicle;
  selectedVehicleId: number | null;
  showApprovalStatuses: boolean;
  handleSelectVehicle: (driverId: number) => void;
}

export const ResourcesVehicleCard: FC<Props> = ({ vehicle, selectedVehicleId, showApprovalStatuses, handleSelectVehicle }) => {
  const isSelected = selectedVehicleId === vehicle.id;
  const onClickSelectVehicle = () => handleSelectVehicle(vehicle.id);

  const vehicleTypesNames = useVehicleTypesNames();
  const vehicleTypesIcons = useVehicleTypesIcons();

  const vehicleIcon = vehicle?.vehicleType && vehicleTypesIcons?.[vehicle?.vehicleType];
  const vehicleName = vehicle?.vehicleType && vehicleTypesNames?.[vehicle?.vehicleType];
  const vehicleLoadingTypes = vehicle?.loadingType ? vehicle?.loadingType.join(COMMA_SPACE) : AVAILABLED_TEXT.NOT_AVAILABLE;

  return (
    <VStack className={classNames('resources-vehicle-card', { 'resources-driver-card_active': isSelected })}>
      <Box className="resources-vehicle-card__header">
        {showApprovalStatuses && <ResourcesStatusesList approvalStatuses={vehicle?.approvalStatuses} />}
        <UiButtonOutlined mode="dark" onClick={onClickSelectVehicle} size="small">
          {ResourcesButtonActions.Details}
        </UiButtonOutlined>
      </Box>

      <Box className="resources-vehicle-card__content">
        {/* Заголовок с типом ТС */}
        <HStack>
          {vehicleName && <Text className="resources-vehicle-card__title">{capitalize(vehicleName)}</Text>}
          {vehicleIcon && <img className="resources-vehicle-card__vehicle-image" src={vehicleIcon} alt="vehicle img" />}
        </HStack>

        {/* Регистрационный номер ТС и полуприцепа */}
        <HStack>
          {vehicle?.regNumber && <CarrierCardRegNumber regNumber={getVehicleRegNumber(vehicle)} region={getVehicleRegion(vehicle)} />}
          {vehicle?.trailers?.map((trailer) => (
            <CarrierCardRegNumber key={trailer?.regNumber} regNumber={getVehicleRegNumber(trailer)} region={getVehicleRegion(trailer)} />
          ))}
        </HStack>
        {/* тип собственности ТС (аренда-собственность-лизинг), в залоге */}
        <HStack>
          <Text className="resources-vehicle-card__subtitle">Тип собственности:</Text>
          <Box className="resources-vehicle-card__row">
            <Text className={classNames('resources-vehicle-card__text available', { not_available: isNil(vehicle?.ownType) })}>
              {!isNil(vehicle?.ownType) ? VehicleOwnTypeLabels[vehicle?.ownType].label : AVAILABLED_TEXT.NOT_AVAILABLE}
            </Text>
            <Text className="resources-vehicle-card__text bailed">{vehicle?.bailed ? `(${BAILED_TEXT.BAILED})` : `(${BAILED_TEXT.NOT_BAILED})`}</Text>
          </Box>
        </HStack>
        {/* Трекинг */}
        <HStack>
          <Text className="resources-vehicle-card__subtitle">Трекинг:</Text>
          <Text className={classNames('resources-vehicle-card__text available', { not_available: !vehicle?.tracker })}>
            {vehicle?.tracker ? AVAILABLED_TEXT.AVAILABLE : AVAILABLED_TEXT.NOT_AVAILABLE}
          </Text>
        </HStack>
        {/* Тип погрузки */}
        <HStack>
          <Text className="resources-vehicle-card__subtitle">Погрузка:</Text>
          <Text className="resources-vehicle-card__text">{vehicleLoadingTypes}</Text>
        </HStack>
      </Box>
      {(vehicle?.capacity || vehicle?.volume || vehicle?.volume) && (
        <>
          {/* Грузоподъемность, Объем, Размер */}
          <UiDivider />
          <ResourcesVehicleSpecifications vehicle={vehicle} />
        </>
      )}
    </VStack>
  );
};
