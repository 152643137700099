import React, { FC, useState } from 'react';
import { InputProps } from '@chakra-ui/react';
import eye from 'public/assets/icons/eye.svg';
import closedEye from 'public/assets/icons/closed-eye.svg';
import { UiInput } from '@/ui-elements';
import { UiIconButton } from '@/ui-elements/icon-button/icon-button';

type Props = {
  onClear?: () => void;
  hideClearButton?: boolean;
};

export const UiPasswordInput: FC<Props & InputProps> = (props) => {
  const [textIsHidden, setTextIsHidden] = useState(true);

  return (
    <UiInput {...props} type={textIsHidden ? 'password' : 'text'}>
      <UiIconButton aria-label="show" icon={textIsHidden ? eye : closedEye} onClick={() => setTextIsHidden((prev) => !prev)} />
    </UiInput>
  );
};
