import React, { FC } from 'react';
import { UiSwitcher } from '@/ui-elements/switcher/switcher';
import { Tab } from '@/ui-elements/switcher/types';
import { emptyFunction } from '@/helpers/empty-function';

/*
 * Учет оплаты рейсов switcher
 * */
type Props = {
  switcherLabels: Tab[];
  onChange: (value: string) => void;
};
export const BookerOrdersTabs: FC<Props> = ({ switcherLabels, onChange = emptyFunction }) => {
  return <UiSwitcher onChange={onChange} className="booker-order-details__switcher" tabsList={switcherLabels} />;
};
