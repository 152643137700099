import { FC, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { PageWrapper } from 'pages/page-wrapper';
import { CarriersVerificationView, VerificationSidebar } from '@/views';

export const CarriersVerificationPage: FC = () => {
  const [checking, setChecking] = useState<boolean>(false); // используется для входа-выхода из режима формы в правой панели
  return (
    <PageWrapper>
      <Flex w="full">
        <CarriersVerificationView setChecking={setChecking} />
        <VerificationSidebar checking={checking} setChecking={setChecking} />
      </Flex>
    </PageWrapper>
  );
};
