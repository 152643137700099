import { ROUTES_SIDEBARS } from 'components/carriers-loads/sidebar/constants';
import { OrderExecutionStatus } from 'config/constants/statuses';
import { toast } from 'react-toastify';
import { CarrierLoadsPageWithOrders, useCarrierLoadActions } from '@/features/carriers-loads/hooks/use-carriers-loads-actions';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useOrderStatus } from '@/features/order/hooks/use-order-status';
import { useOrdersWithMyParticipationUpdates } from '@/features/order/hooks/use-orders-with-my-participation-updates';
import { pageSidebarModeSelector } from '@/features/carriers-loads/selectors/page-sidebar-mode-selector';
import { pageOrderIdSelector } from '@/features/carriers-loads/selectors/page-order-id-selector';

const ORDER_STATUS_SUCCESS = {
  [OrderExecutionStatus.Executing]: 'Рейс успешно начат',
  [OrderExecutionStatus.CompletedWithoutDocs]: 'Рейс успешно завершен',
};

export const useCarriersLoadsOrderSidebar = (page: CarrierLoadsPageWithOrders) => {
  const { closeSidebar } = useCarrierLoadActions(page);
  const mode = useAppSelector((state) => pageSidebarModeSelector(state, page));
  const id = useAppSelector((state) => pageOrderIdSelector(state, page));

  const SidebarContent = ROUTES_SIDEBARS[page][mode];

  const { order, isInitialLoading } = useOrdersWithMyParticipationUpdates({ id });

  const { setNewOrderStatus } = useOrderStatus(order);

  const handleOrderStatus = (status: OrderExecutionStatus.Executing | OrderExecutionStatus.CompletedWithoutDocs) => {
    const onSuccess = () => {
      toast.success(ORDER_STATUS_SUCCESS[status]);
      closeSidebar();
    };
    setNewOrderStatus(status, onSuccess);
  };

  const onOrderStart = () => handleOrderStatus(OrderExecutionStatus.Executing);
  const onOrderEnd = () => handleOrderStatus(OrderExecutionStatus.CompletedWithoutDocs);

  return {
    closeSidebar,
    SidebarContent,
    onOrderStart,
    order,
    isInitialLoading,
    onOrderEnd,
  };
};
