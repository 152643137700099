import classNames from 'classnames';
import { FormControl, FormErrorMessage, FormHelperText, FormLabel } from '@chakra-ui/react';
import { useField } from 'react-final-form';
import { UiVehicleTypesPicker } from '@/ui-elements/inputs/ui-vehicle-types-picker/ui-vehicle-types-picker';
import './ui-vehicle-type-picker-field.scss';

type Props = {
  name: string;
  required?: boolean;
  label?: string;
  content?: string;
  maxWidth?: string;
  isMulti?: boolean;
};

export const UiVehicleTypesPickerField = ({ name, required, label, content, maxWidth, isMulti }: Props) => {
  const {
    input,
    meta: { error, touched },
  } = useField(name);
  const invalid = touched && error;
  return (
    <FormControl className="ui-vehicle-types-picker-field" style={{ maxWidth }} isInvalid={invalid}>
      <FormLabel mb={1} fontSize="xs" className={classNames('ui-field__label', { required })}>
        {label} <span className="ui-vehicle-types-picker-field__required">{required ? '*' : ''}</span>
      </FormLabel>
      <UiVehicleTypesPicker onChange={input.onChange} defaultValue={input.value} isMulti={isMulti} />
      {invalid ? <FormErrorMessage>{error}</FormErrorMessage> : <FormHelperText>{content}</FormHelperText>}
    </FormControl>
  );
};
