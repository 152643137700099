import { FC } from 'react';
import { UISidebar } from '@/ui-elements/ui-sidebar/ui-sidebar';
import './order-sidebar.scss';
import { OrderSidebarMode } from '@/types/sidebar/order-sidebar-mode';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useOrderSidebar } from '@/features/order/hooks/use-order-sidebar';
import { useOrderActions } from '@/features/order/hooks/use-order-actions';
import { UiCloseablePortal } from '@/ui-elements/ui-sidebar/ui-closeable-portal';
import { OrderDetailsSidebar } from '@/views/order-sidebar/order-details-sidebar/order-details-sidebar';
import { OrderSplitSidebar } from '@/views/order-sidebar/order-split-sidebar/order-split-sidebar';
import { OrderCreateView } from '@/views/order-create-view/order-create-view';
import { OrderUpdateView } from '@/views/order-update-view/order-update-view';
import { editingOrderSelector } from '@/features/order/selectors/editing-order-selector';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';

/**
 * Сайдбар для страницы Исполенение (бывш.заявки на рейсы)
 * * */
export const OrderSidebar: FC<{ isFuel?: boolean }> = ({ isFuel = false }) => {
  const { mode, splitCount } = useOrderSidebar();
  const { cancelCreate } = useOrderActions();
  const showSearch = mode === OrderSidebarMode.Search;
  const showDetails = mode === OrderSidebarMode.Details || mode === OrderSidebarMode.Search;
  const showNewOrder = mode === OrderSidebarMode.NewOrder;
  const showUpdateOrder = mode === OrderSidebarMode.UpdateOrder;
  const showSplit = mode === OrderSidebarMode.Split;
  const { isLoading } = useAppSelector(editingOrderSelector);

  return (
    <>
      <OrderDetailsSidebar isFuel={isFuel} showSearch={showSearch} open={showDetails} />
      <OrderSplitSidebar open={showSplit} splitSize={splitCount} />
      <UISidebar className="order-sidebar" isOpen={showNewOrder} animation>
        <UiCloseablePortal onClose={cancelCreate}>
          <OrderCreateView />
        </UiCloseablePortal>
      </UISidebar>
      <UISidebar className="order-sidebar" isOpen={showUpdateOrder} animation>
        <UiCloseablePortal onClose={cancelCreate}>{isLoading ? <UiSpinner visible /> : <OrderUpdateView />}</UiCloseablePortal>
      </UISidebar>
    </>
  );
};
