import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {},
  thumb: {
    bg: 'neutrals.350',
    borderRadius: '8px',
  },
  track: {
    bg: 'products.350',
    borderRadius: '15.5px',
    _checked: {
      bg: 'products.200',
      _hover: {
        bg: 'products.150',
      },
      _disabled: {
        bg: 'neutrals.200',
      },
      _invalid: {
        bg: 'accentRed.100',
      },
    },
    _hover: {
      bg: 'products.300',
    },
    _disabled: {
      bg: 'neutrals.200',
    },
  },
});

export const switchTheme = defineMultiStyleConfig({ baseStyle });
