export const driverFields = ['riskGroup', 'convictions', 'loans', 'speedFines', 'trafficFines', 'accidents', 'driversLicenseRevoked'];

export const driverFieldsToTransform = ['riskGroup'];
export const organizationFields = ['loans', 'companyConvictions', 'convictions'];
export const vehicleFields = ['']; // todo

export const ADD_VEHICLE_FOR_ORGANIZATION_CONTENT = 'Вы добавляете ТС к организации';

export enum VehicleGroupEnum {
  default,
  withTrailer,
  special,
}

export const vehicleTypeLabels = [
  {
    key: VehicleGroupEnum.default.toString(),
    label: 'Фургон',
  },
  {
    key: VehicleGroupEnum.withTrailer.toString(),
    label: 'С полуприцепом',
  },
  {
    key: VehicleGroupEnum.special.toString(),
    label: 'Специальные',
  },
];

export enum VehicleOwnTypeEnum {
  rent,
  own,
  leasing,
}

export const ownTypeLabels = [
  {
    key: VehicleOwnTypeEnum.rent.toString(),
    label: 'Аренда',
  },
  {
    key: VehicleOwnTypeEnum.own.toString(),
    label: 'Собственность',
  },
  {
    key: VehicleOwnTypeEnum.leasing.toString(),
    label: 'Лизинг',
  },
];
