import './funnel-sidebar.scss';
import { useRef } from 'react';
import { UISidebar } from '@/ui-elements/ui-sidebar/ui-sidebar';
import { UiCloseablePortal } from '@/ui-elements/ui-sidebar/ui-closeable-portal';
import { FunnelSidebarMode } from '@/types/sidebar/funnel-sidebar-mode';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useFunnelActions } from '@/features/funnel/hooks';
import { funnelSidebarModeSelector } from '@/features/funnel/selectors/funnel-sidebar-mode-selector';
import { funnelEditingLoadSelector } from '@/features/funnel/selectors/funnel-editing-load-selector';
import { FunnelDetailsView } from '@/views/funnel-details-view/funnel-details-view';
import { FunnelCreateLoadView } from '@/views/funnel-create-load-view/funnel-create-load-view';
import { FunnelUpdateLoadView } from '@/views/funnel-update-load-view/funnel-update-load-view';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';

export const FunnelSidebar = () => {
  const ref = useRef<HTMLDivElement>(null);
  const mode = useAppSelector(funnelSidebarModeSelector);
  const showDetails = mode === FunnelSidebarMode.Details;
  const showNewLoad = mode === FunnelSidebarMode.NewLoad;
  const showUpdateLoad = mode === FunnelSidebarMode.UpdateLoad;
  const { cancelCreate } = useFunnelActions();
  const { isLoading } = useAppSelector(funnelEditingLoadSelector);

  return (
    <>
      <UISidebar className="funnel-sidebar" isDrawer animation={false} isOpen={showDetails} ref={ref}>
        <FunnelDetailsView />
      </UISidebar>
      <UISidebar className="funnel-sidebar funnel-sidebar_with-portal" isOpen={showNewLoad} animation>
        <UiCloseablePortal onClose={cancelCreate}>
          <FunnelCreateLoadView />
        </UiCloseablePortal>
      </UISidebar>
      <UISidebar className="funnel-sidebar funnel-sidebar_with-portal" isOpen={showUpdateLoad} animation>
        <UiCloseablePortal onClose={cancelCreate}>{isLoading ? <UiSpinner visible /> : <FunnelUpdateLoadView />}</UiCloseablePortal>
      </UISidebar>
    </>
  );
};
