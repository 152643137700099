import { OrdersWithMyParticipationSidebar } from 'components/carriers-loads/sidebar/orders-with-my-participation-sidebar';
import { SidebarMode } from 'components/sidebar-wrapper/constants';
import { CurrentRoutesSidebar } from 'components/carriers-loads/sidebar/current-routes-sidebar';
import { Routes } from 'config/routes/routes';
import { FutureRoutesSidebar } from 'components/carriers-loads/sidebar/future-routes-sidebar';
import { CompletedRoutesSidebar } from 'components/carriers-loads/sidebar/completed-routes-sidebar';
import { FC } from 'react';
import { CarrierLoadsPageWithOrders } from '@/features/carriers-loads/hooks/use-carriers-loads-actions';
import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';

export type CarrierLoadsPageWithOrdersSidebarProps = {
  order: Order | Load | undefined;
  closeSidebar(): void;
  onOrderStart?(): void;
  onOrderEnd?(): void;
};

export const ROUTES_SIDEBARS: Record<
  CarrierLoadsPageWithOrders,
  Record<SidebarMode.Details | SidebarMode.None, FC<CarrierLoadsPageWithOrdersSidebarProps> | null>
> = {
  [Routes.CurrentRoutes]: {
    [SidebarMode.Details]: CurrentRoutesSidebar,
    [SidebarMode.None]: null,
  },
  [Routes.OrdersWithMyParticipation]: {
    [SidebarMode.Details]: OrdersWithMyParticipationSidebar,
    [SidebarMode.None]: null,
  },
  [Routes.FutureRoutes]: {
    [SidebarMode.Details]: FutureRoutesSidebar,
    [SidebarMode.None]: null,
  },
  [Routes.CompletedRoutes]: {
    [SidebarMode.Details]: CompletedRoutesSidebar,
    [SidebarMode.None]: null,
  },
  [Routes.CarriersLoadsAuthorized]: {
    [SidebarMode.Details]: null,
    [SidebarMode.None]: null,
  },
  [Routes.CarriersLoads]: {
    [SidebarMode.Details]: null,
    [SidebarMode.None]: null,
  },
};
