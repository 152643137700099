import { PageWrapper } from 'pages/page-wrapper';
import { SecurityView } from '@/views/security/security';

export const SecurityPage = () => (
  <PageWrapper>
    <div className="security-page">
      <SecurityView />
    </div>
  </PageWrapper>
);
