import { BooleanVoidFunction, VoidFunction } from '@/types/common/void-function';
import { Carrier, CarrierOrganizationBankDetailsFormValues, CarrierOrganizationType } from '@/types/models/carrier';
import { FileDocument } from '@/types/models/document';
import { Driver } from '@/types/models/driver';
import { Trailer } from '@/types/models/vehicle';
import { ApprovalBody } from '@/types/models/approval';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { VatTypeSelectOption } from '@/config/constants/types';

export type VerificationProps<T, K> = {
  onCancelCreation?: VoidFunction; // закрытие модалки создания формы
  onApprovalDeclined?: (approvalBody: ApprovalBody) => void;
  onApprovalApproved?: (approvalBody: ApprovalBody) => void;
  initialValues?: T;
  onSubmit?: (values: K) => void;
  onCancel?: VoidFunction;
  mode?: VerificationFormMode;
  onClose?: VoidFunction; // закрытие модалки проверки
  driver?: Driver;
  carrierId?: number;
  vehicleId?: number;
  carrier?: Carrier;
  title?: string;
  setConfirmModalOpened?: BooleanVoidFunction;
  attachmentsStampDoc?: Array<FileDocument>;
  deleteStampDoc?: (documentId: number) => void;
  postStampDoc?: (files: Array<File>) => void;
  downloadStampDoc?: VoidFunction;
  attachmentsSignatureDoc?: Array<FileDocument>;
  deleteSignatureDoc?: (documentId: number) => void;
  postSignatureDoc?: (files: Array<File>) => void;
  downloadSignatureDoc?: VoidFunction;
  submitting?: boolean;
  isFirstDefaultBanking?: boolean;
};

export interface CarrierFormData extends Omit<CarrierOrganizationType, 'organizationTypeID' | 'vatType'>, CarrierOrganizationBankDetailsFormValues {
  comment?: string;
  passportDoc?: Array<File>;
  companyDoc?: Array<File>;
  addressedCoincide?: boolean;
  vatType?: VatTypeSelectOption | string;
  organizationTypeID?: SelectOption | string;
  securityComment?: string;
}

export type FormValues = {
  vehicleGroup?: number;
  regNumber: string;
  volume?: number;
  capacity?: number;
  dimensions?: string;
  accidentsInPastThreeYears: boolean;
  bailed?: boolean;
  casco?: boolean;
  ownType?: number;
  tracker?: boolean;
  permanentTracking?: boolean;
  trailers?: Array<Trailer>;
  rentDoc?: File[];
  vehicleFrontDoc?: File[];
  vehicleBackDoc?: File[];
  VDPDoc?: File[];
  extraDoc?: File[];
  vehicleType: Array<number>;
  carrierId?: { label?: string; value?: number };
  driverId?: { label: string; value: number };
  action?: 'approve' | 'decline';
  carrierName?: string;
  comment?: string;
  loadingType?: any;
  Contract?: File[];
  VRCFront?: File[];
  VRCBack?: File[];
  VPFront?: File[];
  VPBack?: File[];
  ExtraDoc?: File[];
};

export enum FormMode {
  create = 'create',
  edit = 'edit',
  check = 'check',
  banking = 'banking',
  editBanking = 'editBanking',
}

export type VerificationFormMode = FormMode;
