/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react';
import { WidgetType } from '@/features/widget/widget-type';
import { WidgetProps } from '@/features/widget/types/widget-item';
import './driver-widget.scss';
import { useDriver } from '@/features/drivers/hooks/use-driver';
import { DriverWidget } from '@/components/driver-widget/driver-widget';
import { mockedTripsDoc } from '@/views/driver-widget/mocks';

export const DriverWidgetView: FC<WidgetProps & WidgetType['DriverWidget']> = ({ id }) => {
  const { driver } = useDriver(id);
  // TODO: нужен запрос для получения документов отчетов по рейсам для вкладки Выполненные рейсы, пока моки
  const tripsAmount = 1;
  const failedTripsAmount = 1;
  const lowRatedTripsAmount = 1;
  const tripsDoc = mockedTripsDoc;
  const failedTripsDoc = undefined;
  const lowRatedTripsDoc = undefined;

  return (
    <div className="driver-widget-view">
      <DriverWidget
        isFetching={driver.isFetching}
        driver={driver.data?.data}
        tripsAmount={tripsAmount}
        failedTripsAmount={failedTripsAmount}
        lowRatedTripsAmount={lowRatedTripsAmount}
        tripsDoc={tripsDoc}
        failedTripsDoc={failedTripsDoc}
        lowRatedTripsDoc={lowRatedTripsDoc}
      />
    </div>
  );
};
