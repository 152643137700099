import { useEffect } from 'react';
import { Center, Spinner } from '@chakra-ui/react';
import { useInView } from 'react-intersection-observer';

type Props = {
  children: React.ReactNode;
  callback: (...args: any) => void; // колбэк для подгрузки данных
  loading?: boolean;
  viewHeight?: string; // высота контейнера, в котором выводится список, по умолчанию 90% высоты экрана
  threshold?: number; // высота прокрутки (до нижней границы контейнера), откуда начинается подгрузка, от 0 до 1 с шагом 0.1
  skip?: boolean;
};

export const InfiniteScroll = ({ children, callback, loading, viewHeight = '90vh', threshold = 0.8, skip = false }: Props) => {
  const { ref, inView } = useInView({ threshold, skip });
  useEffect(() => {
    if (inView && !loading) {
      callback();
    }
  }, [inView, callback, loading]);
  return (
    <div style={{ height: viewHeight }}>
      {children}
      {loading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <div ref={ref} style={{ height: '50px' }} />
      )}
    </div>
  );
};
