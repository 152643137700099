import { FC } from 'react';
import { FormMode, VerificationProps } from 'components/verification-forms/types';
import { Box, HStack } from '@chakra-ui/react';
import { useForm } from '@/helpers/forms/use-form';
import { UiButtonFilled, UiButtonOutlined, UiFileUploadField, UiFileUploadInput } from '@/ui-elements';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { DetailsSubform } from '@/features/modals/organization/details-subform';
import { BankingSubform } from '@/features/modals/organization/banking-subform';
import { FormTitle } from '@/components/verification-forms/organization-verification/subforms/form-title';
import { validateForm } from '@/helpers/forms/validate';
import { createOrganizationsSchema } from '@/helpers/forms/validation-schemas/create-organizations-form';

export const ModalOrganization: FC<Partial<VerificationProps<any, any>>> = ({
  mode = FormMode.create,
  initialValues,
  onClose,
  onSubmit,
  setConfirmModalOpened,
  title,
  attachmentsStampDoc,
  deleteStampDoc,
  postStampDoc,
  downloadStampDoc,
  attachmentsSignatureDoc,
  deleteSignatureDoc,
  postSignatureDoc,
  downloadSignatureDoc,
  submitting,
  isFirstDefaultBanking,
}) => {
  const Form = useForm();
  const onCancelHandler = () => {
    if (mode === 'check') {
      // todo decline approval (СБ)
      onClose?.();
    } else {
      setConfirmModalOpened?.(true);
    }
  };

  // const formattedInitialValues = formatOrganizationValues(initialValues);
  const validate = mode === 'edit' ? validateForm(createOrganizationsSchema) : undefined;

  return (
    <Form onSubmit={onSubmit as any} initialValues={initialValues} keepDirtyOnReinitialize validate={validate}>
      <FormTitle title={title!} />
      <div className="carriers-verification-form__body">
        <UiScrollbars autoHide>
          <div className="carriers-verification-form__main">
            {mode !== 'banking' && mode !== 'editBanking' && <DetailsSubform />}
            {mode !== 'edit' && <BankingSubform isFirstDefaultBanking={isFirstDefaultBanking} />}
            {mode !== 'banking' && mode !== 'editBanking' && mode !== 'edit' && (
              <Box mt={4}>
                <UiFileUploadField name="stampDoc" label="Печать" />
              </Box>
            )}
            {mode === 'edit' && (
              <Box mt={4}>
                <div>Печать</div>
                <UiFileUploadInput
                  savedFileList={attachmentsStampDoc}
                  name="stampDoc"
                  onDeleteFileClick={deleteStampDoc}
                  onIconClick={downloadStampDoc}
                  onImmediateUploadFile={postStampDoc}
                />
              </Box>
            )}
            {mode !== 'banking' && mode !== 'editBanking' && mode !== 'edit' && (
              <Box>
                <UiFileUploadField name="signatureDoc" label="Подпись" />
              </Box>
            )}
            {mode === 'edit' && (
              <Box mt={4}>
                <div>Подпись</div>
                <UiFileUploadInput
                  savedFileList={attachmentsSignatureDoc}
                  name="signatureDoc"
                  onDeleteFileClick={deleteSignatureDoc}
                  onIconClick={downloadSignatureDoc}
                  onImmediateUploadFile={postSignatureDoc}
                />
              </Box>
            )}
          </div>
        </UiScrollbars>
        <HStack justifyContent="right" mt={4} mb={2} mr={2}>
          <UiButtonOutlined className="carriers-verification-form__btn-decline" mode="light" onClick={onCancelHandler} w={128}>
            {mode === 'check' ? 'Отклонить' : 'Отменить'}
          </UiButtonOutlined>
          <UiButtonFilled
            type="submit"
            className="carriers-verification-form__btn-confirm"
            mode="dark"
            w={200}
            isDisabled={submitting}
            isLoading={submitting}
          >
            {mode === 'check' ? 'Принять' : 'Сохранить'}
          </UiButtonFilled>
        </HStack>
      </div>
    </Form>
  );
};
