import { UseMutateFunction, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { FileFormField, MyOrganizationFormField } from '@/types/models/my-organization/form';
import { useAttachmentsActions } from '@/features/attachments/hooks/use-attachments-actions';
import { AttachmentDeps } from '@/config/constants/types';
import { DocumentTypes } from '@/config/constants/document-types';
import { downloadFileDocument } from '@/helpers/api/download-file-document';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';
import { FileDocument } from '@/types/models/document';
import { AttachmentNew } from '@/types/models/attachments';

type UseCRUDorganizationDocuments = {
  documents: Record<FileFormField, FileDocument[] | undefined>;
  organizationID: number | undefined;
  driverId?: number;
  postDocument: UseMutateFunction<ResponseWithoutPagerSimple<FileDocument>, unknown, AttachmentNew>;
};

type SendFiles = { files: Record<FileFormField, File[]>; dependentID: number; dependent: AttachmentDeps };

const FileSubjects = {
  [MyOrganizationFormField.InnOgrnDocument]: DocumentTypes.dlDocumentOrganizationInnOgrn,
  [MyOrganizationFormField.PassportDocument]: DocumentTypes.dlDocumentOrganizationManagerPassport,
  [MyOrganizationFormField.DriversLicenseDoc]: DocumentTypes.dlDocumentDriverLicense,
};

export const useCRUDorganizationDocuments = (data: UseCRUDorganizationDocuments) => {
  const queryClient = useQueryClient();
  const { organizationID, driverId, postDocument, documents } = data;
  const { deleteAttachment } = useAttachmentsActions();

  const updateFileData = (fieldName: FileFormField, title: string) => {
    toast.success(title);
    switch (fieldName) {
      case MyOrganizationFormField.PassportDocument:
        queryClient.invalidateQueries(['passportAttachments']);
        break;
      case MyOrganizationFormField.InnOgrnDocument:
        queryClient.invalidateQueries(['innOgrnAttachments']);
        break;
      case MyOrganizationFormField.DriversLicenseDoc:
        queryClient.invalidateQueries(['driverLicenseAttachments']);
        break;
      default:
        break;
    }
  };

  const removeAttachment = (id: number, fieldName: FileFormField) => {
    deleteAttachment.mutate(id, {
      onSuccess: () => updateFileData(fieldName, 'Документ успешно удален'),
    });
  };

  const downloadAttachmentById = (id: number, fieldName: FileFormField) => {
    const doc = documents[fieldName]?.find((x) => x.id === id);
    if (doc) downloadFileDocument(doc);
  };

  const updateFile = (files: File[], fieldName: FileFormField) => {
    const isDriveDocument = [MyOrganizationFormField.DriversLicenseDoc].includes(fieldName);
    files.map((file) => {
      postDocument(
        {
          dependentID: isDriveDocument ? driverId ?? 0 : organizationID ?? 0,
          dependent: isDriveDocument ? AttachmentDeps.Driver : AttachmentDeps.Organization,
          attachment: file,
          subject: FileSubjects[fieldName],
        },
        {
          onSuccess: () => updateFileData(fieldName, 'Документы успешно обновлены'),
        }
      );
    });
  };

  const sendFiles = (args: SendFiles) => {
    const { files, dependentID, dependent } = args;
    Object.keys(files).map((key) => {
      files[key as FileFormField].map((file) => {
        postDocument(
          {
            dependentID,
            dependent,
            attachment: file,
            subject: FileSubjects[key as FileFormField],
          },
          {
            onSuccess: () => updateFileData(key as FileFormField, 'Документ успешно добавлен'),
          }
        );
      });
    });
  };

  return { sendFiles, updateFile, downloadAttachmentById, removeAttachment };
};
