import { FC, useEffect } from 'react';
import { Box, Grid, GridItem, HStack } from '@chakra-ui/react';
import { useFieldArray } from 'react-final-form-arrays';
import { REG_NUMBER_TRAILER_MASK } from 'components/auth-form/constants';
import { UiButtonFilled, UiButtonOutlined, UiCheckboxField, UiInputField, UiMaskedField, UiSelectField } from '@/ui-elements';
import { useIcons } from '@/hooks/use-icons';

export const TrailerFieldArray: FC<{ security: boolean }> = ({ security }) => {
  const { fields } = useFieldArray('trailers');
  useEffect(() => {
    if (fields.length === 0) fields.push({});
  });
  const { PlusIcon } = useIcons();

  return (
    <>
      {fields.map((name, index) => (
        <Box key={name}>
          <>
            <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={3}>
              <GridItem>
                <UiSelectField
                  options={[
                    {
                      value: 0,
                      label: 'Водитель не прикреплен',
                    },
                  ]}
                  name={`${name}.vehicleType`}
                  label="Выберите водителя организации"
                  placeholder="Выберите водителя"
                  required
                  isDisabled={security}
                />
              </GridItem>
              <GridItem>
                <UiMaskedField
                  name={`${name}.regNumber`}
                  label="Номер Полуприцепа"
                  placeholder="Номер полуприцепа"
                  required
                  isDisabled={security}
                  mask={REG_NUMBER_TRAILER_MASK}
                />
              </GridItem>
            </Grid>
            <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={0} className="carriers-verification-form">
              <GridItem>
                <UiInputField
                  name={`${name}.volume`}
                  label="Грузоподъемность в тоннах"
                  type="number"
                  placeholder="Объем"
                  required
                  isDisabled={security}
                />
              </GridItem>
              <GridItem>
                <UiInputField name={`${name}.dimensions`} label="Размеры (ДхШхВ) в метрах" placeholder="(ДхШхВ)" required isDisabled={security} />
              </GridItem>
            </Grid>
            <Box mt={-1}>
              <UiCheckboxField name={`${name}.accidentsInPastThreeYears`} label="Участвовал в ДТП за последние 3 года" isDisabled={security} />
            </Box>
          </>
          <HStack justifyContent="right" mt={4} right={240}>
            {index > 0 && (
              <UiButtonOutlined className="carriers-verification-form__btn-decline" mode="dark" onClick={() => fields.remove(index)} w={122}>
                Удалить
              </UiButtonOutlined>
            )}
          </HStack>
        </Box>
      ))}
      <HStack justifyContent="right" mt={4} mb={2}>
        <UiButtonFilled mode="light" w={219} leftIcon={PlusIcon} onClick={() => fields.push({})}>
          Добавить полуприцеп
        </UiButtonFilled>
      </HStack>
    </>
  );
};
