import { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';
import './carriers-verification-info.scss';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';
import { Approval } from '@/types/models/approval';
import { OrganizationDetails } from '@/components/carriers-verification/organization-details';
import { Carrier } from '@/types/models/carrier';
import { Customer } from '@/types/models/customer';

type Props = {
  approval?: Approval;
  loading?: boolean;
  subject: React.ReactNode | JSX.Element;
  carrier?: Carrier;
  customer?: Customer;
  setChecking: (arg0: boolean) => void;
};

export const CarriersVerificationInfo: FC<Props> = ({ approval, loading = false, subject, carrier, setChecking, customer }) => {
  if (!carrier && !customer) return null;
  return !approval ? (
    <UiSpinner visible={loading} />
  ) : (
    <Box className="carriers-verification-info">
      <Text className="carriers-verification-info__title" mb={3.5}>
        Вы выбрали для проверки:
      </Text>
      {subject}
      {(approval.driver || approval.vehicle) && (
        <Text className="carriers-verification-info__title" my={2}>
          Относится к организации:
        </Text>
      )}
      {carrier && <OrganizationDetails carrierInfo={carrier} approval={approval} setChecking={setChecking} />}
    </Box>
  );
};
