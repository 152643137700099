export const styles = {
  _hover: {
    borderColor: 'neutrals.50',
    _focus: {
      borderColor: 'products.200',
    },
    _disabled: {
      borderColor: 'neutrals.500',
    },
  },
  _placeholder: {
    color: 'neutrals.250',
    _disabled: {
      color: 'typography.150',
    },
  },
  borderRadius: '8px',
  borderColor: 'neutrals.250',
  fontSize: '14px',
  lineHeight: '20px',
  _focus: {
    borerColor: 'products.200',
    boxShadow: 'none',
  },
  _disabled: {
    background: 'neutrals.500',
    color: 'typography.150',
    _placeholder: {
      color: 'typography.150',
    },
    borderColor: 'neutrals.500',
  },
  _invalid: {
    boxShadow: 'none',
    borderColor: 'accentRed.100',
  },
};
