import { AttachmentDeps } from 'config/constants/types';
import { DocumentTypes } from 'config/constants/document-types';
import { OrderExecutionStatus } from 'config/constants/statuses';
import { OrderDetailsDocumentsConfirm } from 'components/order-details/order-details-documents/order-details-documents-confirm';
import { useOrderDetailsView } from '@/features/order/hooks/use-order-details-view';
import { useDependentAttachments } from '@/features/attachments/hooks/use-dependent-attachments';
import { useAccount } from '@/features/account/hooks/use-account';
import { UserRole } from '@/types/api/user-role';
import { ModalType } from '@/types/common/modals';
import { ModalAction, useModal } from '@/hooks/use-modal';
import { OrganizationDocument, OrganizationDocumentType } from '@/api/documents/documents-types';
import { useOrderDataActions } from '@/features/order/hooks/use-order-data-actions';
import { useOrderInvalidate } from '@/features/order/hooks/use-order-invalidate';
import { useOrderWithOwnResources } from '@/features/order/hooks/use-order-with-own-resources';

export const CarrierOrderDocumentView = () => {
  const { order, setNewOrderStatus, setBackConfirmedStatus, activeOrderId } = useOrderDetailsView();
  const { currentRole } = useAccount();
  const hasEditRight = currentRole && [UserRole.Administrator, UserRole.Manager].includes(currentRole);
  const { orderWithOwnResources } = useOrderWithOwnResources({ order });
  const { updateOrder } = useOrderDataActions(activeOrderId!);
  const { orderInvalidate, allOrdersInvalidate } = useOrderInvalidate();

  const {
    attachments: ApplicationAgreementOriginalDocs,
    deleteAttachmentById: deleteDocument,
    downloadAttachments,
    postAttachments,
  } = useDependentAttachments({
    dependent: AttachmentDeps.Order,
    dependentId: order?.id,
    type: DocumentTypes.dlDocumentOrderToConfirm,
    staleTime: 3000,
  });

  const { attachments: ApplicationAgreementSignedDocs } = useDependentAttachments({
    dependent: AttachmentDeps.Order,
    dependentId: order?.id,
    type: DocumentTypes.dlDocumentOrderSigned,
    staleTime: 3000,
  });
  const applicationAgreementTotal = [...(ApplicationAgreementOriginalDocs ?? []), ...(ApplicationAgreementSignedDocs ?? [])];

  const isCompleted = order?.confirmedCarrierDocs;
  const isDisabled =
    isCompleted || !(order?.status === OrderExecutionStatus.Preparation || order?.status === OrderExecutionStatus.Confirmed) || orderWithOwnResources;

  const confirmedStatus =
    (order?.status === OrderExecutionStatus.Confirmed || order?.status === OrderExecutionStatus.Preparation) && order?.confirmedCarrierDocs;
  const title = `Договор-заявка`;
  const orderDocumentCreation = order?.status === OrderExecutionStatus.Preparation && !orderWithOwnResources;

  const { modalView: documentTemplateModal, setModalOpened: showDocumentTemplateModal } = useModal<OrganizationDocument & ModalAction>(
    ModalType.DocumentTemplate,
    {
      bankId: 8,
      organizationId: 4,
      order: order?.id,
      origin: OrganizationDocumentType.Carrier,
      closeModal: () => showDocumentTemplateModal(false),
      postAttachments,
    }
  );

  const submitCarrierDocuments = () => {
    if (order?.id) {
      updateOrder.mutate(
        { confirmedCarrierDocs: true },
        {
          onSuccess: () => {
            orderInvalidate(order.id);
            allOrdersInvalidate();
            if (order.confirmedCarrierDocs && order.confirmedCustomerDocs && order.status === OrderExecutionStatus.Preparation) {
              setNewOrderStatus(OrderExecutionStatus.Confirmed);
            }
          },
        }
      );
    }
  };

  const declineCarriertDocuments = () => {
    if (order?.id) {
      updateOrder.mutate(
        { confirmedCarrierDocs: false },
        {
          onSuccess: () => {
            orderInvalidate(order.id);
            setBackConfirmedStatus();
          },
        }
      );
    }
  };

  return (
    <>
      <OrderDetailsDocumentsConfirm
        title={title}
        orderDocumentCreation={orderDocumentCreation}
        documents={applicationAgreementTotal || []}
        disabled={isDisabled || !hasEditRight}
        confirmed={isCompleted}
        confirmedStatus={confirmedStatus}
        onPreparationClick={declineCarriertDocuments}
        onUpload={postAttachments}
        onSaveClick={downloadAttachments}
        onDeleteClick={!isDisabled ? deleteDocument : undefined}
        onConfirmClick={hasEditRight ? submitCarrierDocuments : undefined}
        onDocumentsClick={showDocumentTemplateModal}
        orderDocumentCreationDisabled={orderWithOwnResources}
      />
      {documentTemplateModal}
    </>
  );
};
