import { Field } from 'react-final-form';
import { Box, RadioProps, Stack } from '@chakra-ui/react';
import { UiRadioButton, UiRadioGroup } from '@/ui-elements';
import { RadioButtonData } from '@/types/common/radio-buttons';

type Props = {
  name: string;
  config: RadioButtonData;
  title?: string;
  direction?: 'row' | 'column';
  mb?: number;
} & RadioProps;

export const UiRadioGroupField = ({ name, title, config, direction = 'column', mb }: Props) => (
  <Field name={name} component={UiRadioGroup} label={title}>
    <Stack direction={direction}>
      {Object.entries(config).map(([value, index]) => (
        <Box display="flex" key={`${name}_${value + 1}`}>
          <UiRadioButton value={value} mb={mb}>
            {index}
          </UiRadioButton>
        </Box>
      ))}
    </Stack>
  </Field>
);
