import { extendTheme } from '@chakra-ui/react';
import { numberPickerTheme } from '@/ui-elements/inputs/ui-number-picker/config';
import {
  colors,
  fonts,
  typography,
  containerTheme,
  radioTheme,
  FormTheme,
  switchTheme,
  textareaTheme,
  buttonTheme,
  dividerTheme,
  spinnerTheme,
  menuTheme,
  accordionTheme,
  tagTheme,
} from './index';

export const theme = extendTheme({
  colors,
  fonts,
  textStyles: typography,
  components: {
    NumberInput: numberPickerTheme,
    Input: FormTheme.Input,
    Form: FormTheme.Form,
    FormError: FormTheme.FormError,
    Container: containerTheme,
    Radio: radioTheme,
    Switch: switchTheme,
    Textarea: textareaTheme,
    Button: buttonTheme,
    Divider: dividerTheme,
    Spinner: spinnerTheme,
    Menu: menuTheme,
    Accordion: accordionTheme,
    Tag: tagTheme,
  },
});
