export const EMPTY_STRING = '';

export const TILDE = '~';

// тире с пробелами
export const DASH_WITH_SPACES = ' - ';

// т
export const TONS_SYMBOL = 'т';

export const RUBLE = '₽';

// двоеточие
export const COLON = ':';

export const COMMA = ',';

export const COMMA_SPACE = ', ';

// слово - Отмена
export const CANCEL = 'Отмена';

export const PRICE = 'Цена';

export const NO = 'нет';

export const NOT_SET = 'не указано';

export const DOWNLOADING = 'Загрузка...';

export const COMMENT = 'Комментарий';

export const SERVICE = 'Услуга';

export const SPACE = ' ';

export const SAVE = 'Сохранить';

export const PLUS = '+';

export const MINUS = '-';

export const SLASH = '/';

export enum Chars {
  NoBreak = '\u00A0',
}
