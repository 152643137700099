import moment, { Moment } from 'moment-timezone';
import _ from 'lodash';
import 'config/moment-config';

export const formatMomentLocal = (date: string | Moment | number, format: string, convertToLocal?: boolean, tz?: any) => {
  if (_.isNumber(date)) {
    const unixDate = moment.unix(date);
    return tz ? unixDate.tz(tz).format(format) : unixDate.format(format);
  }
  const m = convertToLocal ? moment.utc(date).local() : date;
  return moment(m).format(format);
};
