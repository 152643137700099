import { Tag, TagLabel, TagLeftIcon, TagRightIcon, TagProps } from '@chakra-ui/react';
import { FC, ReactElement } from 'react';
import classNames from 'classnames';
import { Size } from '@/ui-elements/chip/types';
import './chip.scss';

export type UiChipProps = Omit<TagProps, 'leftIcon' | 'rightIcon'> & {
  disabled?: boolean;
  size?: Size;
  leftIcon?: string | ReactElement;
  rightIcon?: string | ReactElement;
  className?: string;
};

// TODO: исправить смещение при наведении при необходимости использовать action UiChip
export const UiChip: FC<UiChipProps> = ({
  disabled = false,
  size = 'large',
  children,
  onClick = () => {},
  leftIcon,
  rightIcon,
  className,
  ...props
}) => {
  const handleClick = (e: any) => !disabled && onClick(e);
  return (
    <Tag
      disabled={disabled}
      className={classNames(
        className,
        'ui-chip',
        { 'ui-chip_disabled': disabled },
        { 'ui-chip_large': size === 'large' },
        { 'ui-chip_small': size === 'small' }
      )}
      onClick={handleClick}
      {...props}
    >
      {/* @ts-ignore несовместимость типов в ChakraUi */}
      {leftIcon && <TagLeftIcon boxSize="14px" as={leftIcon} />}
      <TagLabel>{children}</TagLabel>
      {/* @ts-ignore несовместимость типов в ChakraUi */}
      {rightIcon && <TagRightIcon boxSize="14px" as={rightIcon} />}
    </Tag>
  );
};
