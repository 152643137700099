import { Dictionary } from 'config/constants/dictionary';
import { ApiDelete, ApiGet, ApiPatch, ApiPost } from '@/helpers/api/methods';
import { validateQueryString } from '@/helpers/validate-query-string';
import { ResponseWithPager, ResponseWithoutPagerSimple } from '@/types/models/pager';
import { Driver } from '@/types/models/driver';
import { AllDriversFilter, DriverEdit } from '@/types/api/driver-partial';
import { ResponseSimpleApi } from '@/types/response';
import { DriverVerificationRequest, DriversEnumRequest } from '@/types/models/driver-verification';

export const DriversApi = {
  getAllDrivers: (query?: AllDriversFilter) => ApiGet<ResponseWithPager<Driver>>(`${Dictionary.Drivers}${validateQueryString(query)}`),

  addDriver: (options: DriverVerificationRequest) => ApiPost<ResponseWithoutPagerSimple<Driver>>(`${Dictionary.Drivers}`, options),

  getDriver: (id: number) => ApiGet<ResponseWithoutPagerSimple<Driver>>(`${Dictionary.Drivers}/${id}`),

  updateDriver: (id: number, approvals: boolean, options: DriverEdit) =>
    ApiPatch<ResponseWithoutPagerSimple<Driver>>(`${Dictionary.Drivers}/${id}?approvals=${approvals}`, options),

  deleteDriver: (id: number) => ApiDelete<ResponseSimpleApi>(`${Dictionary.Drivers}/${id}`),

  getDriversEnum: (options?: DriversEnumRequest) =>
    ApiGet<ResponseWithoutPagerSimple<Record<string, string>>>(`${Dictionary.Drivers}/enum${validateQueryString(options)}`),
};
