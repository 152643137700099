import { isEmpty } from 'lodash';
import { VerificationItemApproveStatus } from '@/config/constants/statuses';
import { OrderOffer } from '@/types/models/order-offer';
import { ShipmentRequest } from '@/types/models/shipment-request';

type Props = {
  offer?: OrderOffer | ShipmentRequest;
};

export const checkAcceptedStatuses = ({ offer }: Props) => {
  const organizationStatuses = offer?.carrierOrganization?.approvalStatuses;
  const vehicleApprovalStatuses = offer?.vehicle?.approvalStatuses;
  const driverApprovalStatuses = offer?.driver?.approvalStatuses;

  if (isEmpty(organizationStatuses) || isEmpty(vehicleApprovalStatuses) || isEmpty(driverApprovalStatuses)) {
    return false;
  }

  const approvalStatuses = [...(organizationStatuses ?? []), ...(vehicleApprovalStatuses ?? []), ...(driverApprovalStatuses ?? [])];

  return approvalStatuses?.every((approvalStatus) => approvalStatus?.status === VerificationItemApproveStatus?.Accepted);
};
