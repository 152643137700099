import { useQuery } from '@tanstack/react-query';
import { SettingsApi } from '@/api/settings';
import { OrganizationDocumentType } from '@/api/documents/documents-types';
import { QueryKeys } from '@/config/constants/query-keys';
import { getSettingsTemplatesOptions } from '@/features/settings/helpers/get-settings-templates-options';

export const useSettingsTemplates = ({ organizationDocumentType }: { organizationDocumentType: OrganizationDocumentType }) => {
  const settingsTemplates = useQuery({
    queryKey: [QueryKeys.settingsTemplates],
    refetchOnWindowFocus: false,
    queryFn: () => SettingsApi.getSettingsTemplates(),
  });

  const settingsTemplatesData = getSettingsTemplatesOptions({ settingsTemplates: settingsTemplates?.data?.data, organizationDocumentType });

  return {
    settingsTemplatesData,
  };
};
