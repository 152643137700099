import { ModelExtender } from '@/types/model-extender';
import { Driver } from '@/types/models/driver';
import { DriverExtended } from '@/features/drivers/types/driver-extended';
import { vehicleExtender } from '@/features/vehicles/extender/vehicle-extender';
import { EMPTY_STRING } from '@/config/constants/common-strings';
import { getApprovalStatusCarrier } from '@/helpers/get-approval-status-carrier';

export const driverExtender: ModelExtender<Driver, DriverExtended> = {
  approveStatusCaption: (driver) => {
    if (!driver) return EMPTY_STRING;
    return getApprovalStatusCarrier(driver?.approvalStatus);
  },
  vehicleRegNumber: (driver) => (driver ? vehicleExtender.vehicleRegNumber(driver.vehicle) : EMPTY_STRING),
  vehicleRegion: (driver) => (driver ? vehicleExtender.vehicleRegion(driver.vehicle) : EMPTY_STRING),
  preferredDirectionsString: (driver) => (driver ? driver.preferredDirections?.join(' ') : EMPTY_STRING),
};
