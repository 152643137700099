import { FC } from 'react';
import { Text } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useIcons } from '@/hooks/use-icons';
import { getMenuNameByRoute } from '@/helpers/routes/get-menu-name-by-route';
import { NavigationMenu } from '@/types/routes/navigation-menu';

interface IProps {
  menu: NavigationMenu;
}

export const HeaderLogo: FC<IProps> = ({ menu }) => {
  const location = useLocation();
  const { Line } = useIcons();
  return (
    <div className="app-toolbar__logo logo">
      <div className="logo" />
      <Text className="app-toolbar__text text">
        <Line />
        <span>Цифровой экспедитор</span>
      </Text>
      <strong className="app-toolbar__route">{getMenuNameByRoute(location, menu)}</strong>
    </div>
  );
};
