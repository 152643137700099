import { useQuery, useQueryClient } from '@tanstack/react-query';
import isNil from 'lodash/isNil';
import { CarriersApi } from '@/api/carriers';

export const useCarrier = (id?: number) => {
  const queryClient = useQueryClient();

  const carrier = useQuery({
    queryKey: ['carrier', id],
    refetchOnWindowFocus: false,
    queryFn: () => CarriersApi.getCarrier(id!),
    enabled: !isNil(id),
  });

  const invalidateCarrier = () => {
    return queryClient.invalidateQueries({ queryKey: ['carrier', id] });
  };
  return { carrier, invalidateCarrier };
};
