import { FC } from 'react';
import './status.scss';
import { UiTag } from '../ui-tag/ui-tag';

type Props = {
  text: string | number;
  backGroundColor: string;
  color: string;
  className?: string;
};

export const UiStatus: FC<Props> = ({ text, backGroundColor, color, ...props }) => (
  <UiTag backgroundColor={backGroundColor} color={color} className="ui-status" {...props}>
    {text}
  </UiTag>
);
