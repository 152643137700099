import { FormMode, VerificationFormMode } from 'components/verification-forms/types';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import { useQueryClient } from '@tanstack/react-query';
import { AttachmentDeps, VatTypeSelectOption } from 'config/constants/types';
import { DocumentTypes } from 'config/constants/document-types';
import { QueryKeys } from '@/config/constants/query-keys';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { useAccount } from '@/features/account/hooks/use-account';
import { UserRole } from '@/types/api/user-role';
import { useMyOrganizationsSelect } from '@/features/my-organizations/hooks/use-my-organizations-select';
import { useAppSelector } from '@/hooks/use-app-selector';
import { activeMyOrganizationsIdSelector } from '@/features/my-organizations/selectors/active-my-organizations-id-selector';
import { useOrganizationActions } from '@/features/my-organizations/hooks/use-organization-actions';
import { useAttachmentsActions } from '@/features/attachments/hooks/use-attachments-actions';
import { ModalAction, useModal } from '@/hooks/use-modal';
import { ModalType } from '@/types/common/modals';
import { useOrganizationsUpdates } from '@/features/my-organizations/hooks/use-all-organizations-updates';
import { MyOrganizationBanking, MyOrganizationType, MyOrganizationsFormValuesType } from '@/types/models/my-organization/my-organization';
import { myOrganizationsDataToFormValues } from '@/views/my-organizations/helpers/my-organizations-form-values-to-data';

export const useMyOrganizations = () => {
  type FormValues = MyOrganizationType & {
    bank: MyOrganizationBanking;
    signatureDoc: any;
    stampDoc: any;
    organizationTypeID: SelectOption;
    vatType?: VatTypeSelectOption;
  };
  const queryClient = useQueryClient();
  const { currentRole } = useAccount();
  const hasEditRight = currentRole && [UserRole.Administrator].includes(currentRole);
  const { select, unselect } = useMyOrganizationsSelect();
  const activeMyOrganizationsId = useAppSelector(activeMyOrganizationsIdSelector);
  const handleMyOrganizationsClick = (myOrganizationsId: number) => {
    if (myOrganizationsId === activeMyOrganizationsId) {
      unselect();
    } else {
      select(myOrganizationsId);
    }
  };

  const { addOrganization, addOrganizationBanking } = useOrganizationActions();
  const { postAttachment } = useAttachmentsActions();
  const onCreateOrganization = (
    values: MyOrganizationsFormValuesType & {
      bank: MyOrganizationBanking;
      signatureDoc: any;
      stampDoc: any;
    },
    setModal: any
  ) => {
    addOrganization.mutate(myOrganizationsDataToFormValues(values), {
      onSuccess: ({ data }) => {
        toast.success('Организация успешно добавлена');
        queryClient.invalidateQueries([QueryKeys.allOrganizations]);
        const { id } = data;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        if (!isEmpty(values.bank))
          addOrganizationBanking.mutate(
            { organizationId: id, options: values.bank },
            {
              onSuccess: () => {
                toast.success('Банковские реквизиты успешно добавлены');
              },
            }
          );
        if (values.stampDoc) {
          values.stampDoc.map((file: File) =>
            postAttachment.mutate({
              attachment: file,
              dependent: AttachmentDeps.Organization,
              dependentID: id,
              subject: DocumentTypes.dlStamp,
            })
          );
        }
        if (values.signatureDoc) {
          values.stampDoc.map((file: File) =>
            postAttachment.mutate({
              attachment: file,
              dependent: AttachmentDeps.Organization,
              dependentID: id,
              subject: DocumentTypes.dlSignature,
            })
          );
        }
        setModal(false);
      },
    });
  };

  const { modalView: addOrganizationModal, setModalOpened } = useModal<ModalAction & { mode: VerificationFormMode } & { submitting?: boolean }>(
    ModalType.OrganizationModal,
    {
      onSubmit: (values: FormValues) => {
        onCreateOrganization(values, setModalOpened);
      },
      mode: FormMode.create,
      submitting: addOrganization.isLoading,
    }
  );

  const {
    allOrganizations: { data: allOrganizationsResp },
  } = useOrganizationsUpdates();

  const record = allOrganizationsResp?.data;

  return {
    onCreateOrganization,
    hasEditRight,
    setModalOpened,
    record,
    handleMyOrganizationsClick,
    activeMyOrganizationsId,
    addOrganizationModal,
  };
};
