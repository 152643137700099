import { DriverVerificationForm } from '@/types/models/driver-verification';
import { Driver } from '@/types/models/driver';
import { Carrier } from '@/types/models/carrier';

export const driverToFormValues = ({ carrier, values }: { carrier?: Partial<Carrier>; values?: Driver }): DriverVerificationForm | undefined => {
  if (!values) return undefined;
  return {
    country: values.country || '',
    name: values.name || '',
    phoneNumber: values.phone || '',
    surname: values.surname || '',
    patronymic: values.patronymic || '',
    russianPassport: values.russianPassport ?? false,
    foreignPassport: values.foreignPassport,
    passport: values.passport || '',
    passportDate: (values.passportDate && Date.parse(values.passportDate)) || '',
    driverLicense: values.driverLicense || '',
    driverLicenseDate: (values.driverLicenseDate && Date.parse(values.driverLicenseDate)) || '',
    birthday: values.birthday ? Date.parse(values.birthday) : '',
    passportGiven: values.passportGiven || '',
    selfAssignment: values.selfAssignment || false,
    isDocumentsUploaded: values.isDocumentsUploaded,
    carrierId: carrier ? { value: carrier.id, label: carrier.name } : undefined,
    formattedName: values.formattedName || '',
    carrierName: carrier?.name || '',
    riskGroup: values.riskGroup ? { value: values.riskGroup, label: values.riskGroup.toString() } : undefined,
    convictions: Boolean(values?.convictions),
    loans: Boolean(values?.loans),
    accidents: Boolean(values?.accidents),
  };
};
