import React from 'react';

export const onKeyPress = (e: React.KeyboardEvent) => {
  const pressValue = /[0-9]/;
  if (!pressValue.test(e.key)) {
    e.preventDefault();
    return false;
  }
  return pressValue;
};
