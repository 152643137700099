import { FC } from 'react';
import { PageWrapper } from 'pages/page-wrapper';
import { Flex } from '@chakra-ui/react';
import { CarrierListView } from '@/views/carriers-list/carriers-list-view';
import { CarrierDetailsView } from '@/views';

export const CarriersPage: FC = () => (
  <PageWrapper>
    <Flex>
      <CarrierListView />
      <CarrierDetailsView />
    </Flex>
  </PageWrapper>
);
