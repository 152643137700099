import { Box, Heading } from '@chakra-ui/react';
import { DateFormat } from 'config/constants/date';
import { LICENSE_MASK } from '@/components/auth-form/constants';
import { ResourcesFormFieldNames, ResourcesFormLabels, ResourcesFormPlaceholders } from '@/features/resources/constants/form';
import { UiMaskedField, UiMultiDatePickerField } from '@/ui-elements';

export const ResourcesDriverSubformLicense = () => {
  return (
    <Box>
      <Heading as="h3" className="resources-form-subtitle">
        Водительское удостоверение
      </Heading>
      <Box className="resources-form-row">
        <UiMaskedField
          type="number"
          mask={LICENSE_MASK}
          name={ResourcesFormFieldNames.driverLicense}
          label={ResourcesFormLabels.driverLicense}
          required
        />
        <UiMultiDatePickerField
          name={ResourcesFormFieldNames.driverLicenseDate}
          label={ResourcesFormLabels.driverLicenseDate}
          placeholder={ResourcesFormPlaceholders.driverLicenseDate}
          format={DateFormat.DDMMYYYYSlash}
          required
        />
      </Box>
    </Box>
  );
};
