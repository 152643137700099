import { useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { CarrierList } from '@/components/carrier-list/carrier-list';
import './carriers-list-view.scss';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { useAppSelector } from '@/hooks/use-app-selector';
import { selectedCarrierIdSelector } from '@/features/carriers/selectors';
import { carriersActions } from '@/features/carriers/slice';
import { CarrierFilterComponent } from '@/components/carrier-filter/carrier-filter';
import { SortType } from '@/config/constants/types';
import { CarrierFilter } from '@/types/api/carrier-partial';
import { FormFields } from '@/features/carriers/types/carrier-filter';
import useAutomaticCollapse from '@/hooks/use-auto-collapse';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';
import { useAllCarriersInfiniteUpdates } from '@/features/carriers/hooks/use-all-carriers-infinite-updates';
import { flatMapDataPages } from '@/helpers/api/flat-map-data-pages';

export const CarrierListView = () => {
  const { compRef, handleExpandChange, isExpanded } = useAutomaticCollapse({ initialValue: true });
  const [filters, setFilters] = useState<CarrierFilter>({
    from: '',
    capacityFrom: '',
    volumeFrom: '',
    vehicleTypes: '',
    onlyAvailable: false,
    sortBy: 'Rating',
    sortType: SortType.Desc,
    riskGroup: '',
    radiusSearch: '',
    onlyOurDepartment: false,
  });

  const { data: allCarriers, fetchNextPage, isFetchingNextPage, isInitialLoading } = useAllCarriersInfiniteUpdates({ query: filters });
  const allFound = allCarriers?.pages?.[0]?.pager.total;

  // const vehicleTypes = useAppSelector(vehicleTypesSelector);
  // const vehicleTypesSortingOptions = vehicleTypes.map((vehicleType) => ({
  //   label: vehicleType.name,
  //   value: vehicleType.id,
  // }));

  const handleFilterChange = useCallback((values: FormFields) => {
    setFilters((prev) => {
      return {
        vehicleTypes: values.vehicle?.map((vehicleType) => vehicleType).join(',') || '',
        capacityFrom: values.weight || '',
        volumeFrom: values.volume || '',
        riskGroup: values['risk-group']?.value || '',
        from: values.start || '',
        radiusSearch: values.radius?.value || '',
        onlyAvailable: values.isfree || false,
        sortBy: prev.sortBy,
        sortType: prev.sortType,
        status: values.status?.value,
      };
    });
  }, []);

  const handleChangeSorting = useCallback(
    (sortType: SortType) =>
      setFilters((prev) => ({
        ...prev,
        sortType,
      })),
    []
  );

  const dispatch = useAppDispatch();
  const selectedCarrierId = useAppSelector(selectedCarrierIdSelector);

  useEffect(() => {
    const allRecord = allCarriers ? allCarriers.pages.flatMap((d) => d.data) : [];
    if (!allRecord || isEmpty(allRecord)) return;
    if (!selectedCarrierId) dispatch(carriersActions.select(allRecord[0].id));
  }, [dispatch, allCarriers, selectedCarrierId]);

  const handleCarrierClick = useCallback(
    (id?: number) => {
      dispatch(carriersActions.select(id));
    },
    [dispatch]
  );

  return (
    <div className="carrier-list-view">
      <CarrierFilterComponent
        onClickExpand={handleExpandChange}
        isExpanded={isExpanded}
        onFilterChange={handleFilterChange}
        onChangeSorting={handleChangeSorting}
        filters={filters}
        allFound={allFound}
      />
      {isInitialLoading ? (
        <UiSpinner visible mt={28} />
      ) : (
        <CarrierList
          scrollRef={compRef}
          carrierList={flatMapDataPages(allCarriers)}
          handleCarrierClick={handleCarrierClick}
          selectedCarrierId={selectedCarrierId}
          loadMore={fetchNextPage}
          loading={isFetchingNextPage}
        />
      )}
    </div>
  );
};
