import { FC } from 'react';
import './order-details-component.scss';
import { Divider } from '@chakra-ui/react';
import { OrderDetailsDocuments } from 'components/order-details/order-details-documents/order-details-documents';
import { OrderDetailsInsurance } from 'components/order-details/order-details-insurance/order-details-insurance';
import { CARRIER_NOT_SET } from 'components/order-details/order-details-component/texts';
import { scrollStyle } from 'components/order-details/order-details-component/constants';
import { OrderDetailsCarrierExecution } from 'components/order-details/order-details-carrier-execution/order-details-carrier-execution';
import { CONFIRMATION_FILES } from 'components/order-details/order-details-documents/texts';
import { Order } from '@/types/models/order';
import { UiTab, UiTabListMenuSelect, UiTabPanel, UiTabPanels, UiTabs } from '@/ui-elements';
import { orderClientTabs } from '@/features/order/texts';
import { OrderTimelineView } from '@/views/order-timeline/order-timeline-view';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { OrderOffer } from '@/types/models/order-offer';
import { VoidFunction } from '@/types/common/void-function';
import { OrderDetailsPaymentView } from '@/views/order-details-payment-view/order-details-payment-view';
import { OrderDetailsServiceView } from '@/views/order-details-service-view/order-details-service-view';
import { Load } from '@/types/models/loads';

type Props = {
  order: Order | Load;
  // подтвердить исполнение
  onConfirmClick: VoidFunction;
  // открыть поиск исполнителя
  onCarrierSearchClick: VoidFunction;
  // Выбранное предложение
  selectedOffer?: OrderOffer;
  // Начать рейс
  onOrderStart?: () => void;

  // убирает доплнительную информацию по заявке, которая нужна только логисту
  hideOrderLogistInfo?: boolean;

  fuelReadOnly?: boolean;
};

const mode = 'carrier';
/**
 *Детали заявки -> Заказ исполнителя
 * * */
export const OrderDetailsCarrier: FC<Props> = ({
  order,
  onConfirmClick,
  onCarrierSearchClick,
  selectedOffer,
  hideOrderLogistInfo = false,
  onOrderStart,
  fuelReadOnly = false,
}) => {
  const companyName = selectedOffer?.carrier ? selectedOffer.carrier.name : CARRIER_NOT_SET;
  return (
    <UiTabs className="order-details__tabs" isLazy>
      <UiTabListMenuSelect size="lg" className="order-details__tablist">
        <UiTab>{orderClientTabs.ExecutionDetails}</UiTab>
        <UiTab>{orderClientTabs.Documents}</UiTab>
        <Divider orientation="vertical" className="order-details__separator" />
        <UiTab>{orderClientTabs.Services}</UiTab>
        <UiTab>{orderClientTabs.DocsAndPays}</UiTab>
        <UiTab>{orderClientTabs.Insurance}</UiTab>
      </UiTabListMenuSelect>

      <div className="order-details__tab-panels">
        <UiScrollbars autoHide style={scrollStyle}>
          <UiTabPanels className="order-details__tab-content">
            <UiTabPanel>
              <OrderDetailsCarrierExecution
                order={order}
                onCarrierSearchClick={onCarrierSearchClick}
                selectedOffer={selectedOffer}
                hideOrderLogistInfo={hideOrderLogistInfo}
                onOrderStart={onOrderStart}
              />
              <OrderTimelineView orderId={order.id} />
            </UiTabPanel>
            <UiTabPanel>
              <OrderDetailsDocuments
                order={order}
                title={CONFIRMATION_FILES}
                onConfirmClick={onConfirmClick}
                companyName={companyName}
                fuelReadOnly={fuelReadOnly}
                showCarrierConfirmDocuments
                showPointsDocuments
                showConfirmExecution={!hideOrderLogistInfo}
                showCarrierOriginDocuments
              />
              <OrderTimelineView orderId={order.id} />
            </UiTabPanel>
            <UiTabPanel>
              <OrderDetailsServiceView orderMode={mode} fuelReadOnly={fuelReadOnly} />
            </UiTabPanel>
            <UiTabPanel>
              <OrderDetailsPaymentView orderMode={mode} fuelReadOnly={fuelReadOnly} />
            </UiTabPanel>
            <UiTabPanel>
              <OrderDetailsInsurance order={order} fuelReadOnly={fuelReadOnly} />
            </UiTabPanel>
          </UiTabPanels>
        </UiScrollbars>
      </div>
    </UiTabs>
  );
};
