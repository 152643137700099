import { useQuery } from '@tanstack/react-query';
import { BankRequisitesApi } from '@/api/bank-requisites';

export const useBankRequisites = (bik: string) => {
  const bankRequisites = useQuery({
    queryKey: ['bankRequisites', bik],
    refetchOnWindowFocus: false,
    enabled: !!bik,
    queryFn: () => BankRequisitesApi.getBankRequisites(bik),
  });
  return { bankRequisites };
};
