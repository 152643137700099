import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OrdersApi } from '@/api/orders';
import { PointStatusesTransition } from '@/types/models/point';

export const useSetPointStatus = (ids: { orderId: number; pointId: number }) => {
  const queryClient = useQueryClient();
  const setPointStatus = useMutation({
    mutationKey: ['setPointStatus'],
    mutationFn: (status: PointStatusesTransition) => OrdersApi.setOrderWaypointStatus(ids, status),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['order', ids.orderId] });
    },
  });

  return {
    setPointStatus,
  };
};
