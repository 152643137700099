import { Box } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useIcons } from '@/hooks/use-icons';
import { UiButtonFilled } from '@/ui-elements';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';
import { useResourcesDriversView } from '@/features/resources/hooks';
import { ResourcesButtonActions } from '@/features/resources/constants/general';
import { ResourcesDriversList } from '@/components/resources/drivers-list/resources-drivers-list';

export const ResourcesDriversView = () => {
  const { drivers, loading, selectedDriverId, isFetchingNextPage, showApprovalStatuses, fetchNextPage, handleSelectDriver, handleCreateDriver } =
    useResourcesDriversView();
  const { Profile } = useIcons();

  if (loading) {
    return <UiSpinner visible mt={48} />;
  }

  return (
    <Box className="resources-view-content">
      {/* На текущий момент убираем функционал фильтра по статусам, возможно в будущем добавим */}
      {/* <ResourcesStatusTabs activeTab={activeTab} onChangeActiveTab={handleChangeActiveTab} tabsList={ResourcesStatusTabLabels} /> */}
      {!isEmpty(drivers) && (
        <UiButtonFilled onClick={handleCreateDriver} leftIcon={Profile} mode="light" className="resources-view-button">
          {ResourcesButtonActions.AddDriver}
        </UiButtonFilled>
      )}
      <ResourcesDriversList
        selectedDriverId={selectedDriverId}
        drivers={drivers}
        isFetchingNextPage={isFetchingNextPage}
        showApprovalStatuses={showApprovalStatuses}
        fetchNextPage={fetchNextPage}
        handleSelectDriver={handleSelectDriver}
        handleCreateDriver={handleCreateDriver}
      />
    </Box>
  );
};
