import './funnel-payment-details.scss';
import { FC } from 'react';
import { HStack, VStack } from '@chakra-ui/react';
import { COLON, EMPTY_STRING } from '@/config/constants/common-strings';
import { CLIENT_PAYMENT, TOTAL_TO_PAY_WITH_VAT } from '@/components/order-details/order-details-payment/texts';
import { Load } from '@/types/models/loads';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { loadExtender } from '@/features/loads/extender/load-extender';
import { toPriceFormat } from '@/helpers/price/to-price-format';
import { LoadsTariffTypeTag } from '@/components/loads/tariff-type-tag/loads-tariff-type-tag';
import { LoadStatusTag } from '@/components/loads/status-tag/load-status-tag';
import { LoadPlacedText } from '@/components/loads/load-placed-text/load-placed-text';

interface Props {
  load: Load;
}

export const FunnelPaymentDetails: FC<Props> = ({ load }) => {
  const { createdFormatted } = useExtendedObject(load, loadExtender);
  const totalPriceWithVat = toPriceFormat(load?.originalPrice?.priceWithVAT) || EMPTY_STRING;

  return (
    <HStack className="funnel-payment-details">
      <HStack justifyContent={load?.originalPrice ? 'space-between' : 'flex-end'} alignItems="flex-start" width="100%">
        {/* У собственных заявок нет originalPrice, для них не показываем originalPrice */}
        {load?.originalPrice && (
          <VStack className="funnel-payment-details__info" alignItems="flex-start">
            <span className="funnel-payment-details__header">{CLIENT_PAYMENT}</span>
            <HStack className="funnel-payment-details__properties" justifyContent="space-between">
              <span className="funnel-payment-details__label">
                {TOTAL_TO_PAY_WITH_VAT}
                {COLON}
              </span>
              <div className="funnel-payment-details__values">
                <div className="funnel-payment-details__value">{totalPriceWithVat}</div>
              </div>
            </HStack>
          </VStack>
        )}
        <VStack className="funnel-payment-details__status" alignItems="flex-end">
          <LoadPlacedText title={createdFormatted} />
          <LoadsTariffTypeTag tariff={load?.tariffType} />
          <LoadStatusTag status={load?.status} />
        </VStack>
      </HStack>
    </HStack>
  );
};
