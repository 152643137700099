import { Dictionary } from 'config/constants/dictionary';
import { ApiDelete, ApiGet, ApiPatch, ApiPost, ApiPut } from '@/helpers/api/methods';
import { OrderService, OrderServiceNew, OrderServicePatch } from '@/types/models/order-service';
import { ResponseSimpleApi } from '@/types/response';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';
import { stringifyQuery } from '@/helpers/validate-query-string';

export const OrderServicesApi = {
  getOrderService: (id: number, query?: { updates: boolean }) =>
    ApiGet<ResponseWithoutPagerSimple<Array<OrderService>>>(`${Dictionary.OrderServices}/order/${id}${stringifyQuery(query)}`),

  addOrderService: (id: number, options: OrderServiceNew) =>
    ApiPost<ResponseWithoutPagerSimple<OrderService>>(`${Dictionary.OrderServices}/order/${id}`, options),

  updateOrderService: (id: number, options: OrderServicePatch) =>
    ApiPatch<ResponseWithoutPagerSimple<OrderService>>(`${Dictionary.OrderServices}/${id}`, options),

  deleteOrderService: (id: number) => ApiDelete<ResponseSimpleApi>(`${Dictionary.OrderServices}/${id}`),

  uploadOrderServiceDocument: (id: number, body: Array<FormData>) =>
    ApiPut<ResponseWithoutPagerSimple<OrderService>>(`${Dictionary.OrderServices}/${id}/documents`, body),

  deleteOrderServiceDocument: (id: number) => ApiDelete<ResponseSimpleApi>(`${Dictionary.OrderServices}/documents/${id}`),
};
