import { Field, useField, useForm } from 'react-final-form';
import { DropzoneInputProps, DropzoneProps } from 'react-dropzone';
import { Center, FormControl, FormLabel, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { UiButtonOutlined, UiFileUploadInput } from '@/ui-elements';
import { saveFileToDisk } from '@/helpers/save-file-to-disk';

type Props = {
  name: string;
  required?: boolean;
  label?: string;
  withDownloadButton?: boolean;
  onDeleteFileClick?: (documentId: number) => void;
  onImmediateUploadFile?: (file: File[]) => void;
} & DropzoneProps &
  DropzoneInputProps;

export const UiFileUploadField = ({ name, required, label, withDownloadButton = false, ...rest }: Props) => {
  const [newDocuments, setNewDocuments] = useState<Array<File>>([]);
  const { input } = useField(name);
  const form = useForm();
  const onChange = (files: Array<File>) => {
    form.change(name, files);
    setNewDocuments(files);
  };

  const saveOrderNewDocuments = () => {
    newDocuments.forEach((file) => saveFileToDisk(file));
  };

  return (
    <>
      <FormControl isRequired={required}>
        <FormLabel>
          <Text fontSize={14}>{label}</Text>
        </FormLabel>
        <Field required={required} {...input} {...rest} component={UiFileUploadInput as any} onChange={onChange} multiple />
      </FormControl>
      {withDownloadButton && (
        <Center className="ui-file-upload__download-button">
          <UiButtonOutlined m="auto" onClick={saveOrderNewDocuments}>
            Скачать документы
          </UiButtonOutlined>
        </Center>
      )}
    </>
  );
};
