import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { toNumber } from 'lodash';
import { DriversApi } from '@/api/drivers';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';
import { QueryKeys } from '@/config/constants/query-keys';
import { normalizeSelectOptionsResponce } from '@/helpers/api/normalize-select-options-responce';
import { EMPTY_STRING } from '@/config/constants/common-strings';

type UseDriversEnumProps = {
  q: string;
  carrierId?: string | number;
  options: UseQueryOptions<ResponseWithoutPagerSimple<Record<string, string>>>;
};

export const useDriversEnum = ({ q = EMPTY_STRING, carrierId, options = {} }: UseDriversEnumProps) => {
  const driversEnum = useQuery({
    queryKey: [QueryKeys.driversEnum],
    refetchOnWindowFocus: false,
    queryFn: () => DriversApi.getDriversEnum({ search: q, carrierId }),
    ...options,
  });

  const driversEnumData = normalizeSelectOptionsResponce(driversEnum?.data?.data ?? [], toNumber);

  return {
    driversEnum,
    driversEnumData,
  };
};
