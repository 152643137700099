import { RouteObject } from 'react-router-dom';
import { ProtectedPage } from 'pages/protected-page';
import { DefaultRedirectPage } from 'pages/default-redirect-page';
import { Route } from '@/types/routes/route';

/**
 * Перобразует конфигурацию маршрутов в объект, который используется в react-router
 * */
export const getRouterObjects = (routes: Record<string, Route>): RouteObject[] => {
  const routesArray = Object.values(routes);

  const routeObjects = routesArray.map((route) => ({
    path: route.path,
    element: <ProtectedPage path={route.path}>{route.element}</ProtectedPage>,
    index: true,
    isDefault: route.isDefault,
  }));

  const redirectRoutes = [
    {
      path: '/',
      element: <DefaultRedirectPage />,
    },
  ];

  return [...routeObjects, ...redirectRoutes];
};
