import { FormControl, FormLabel, FormErrorMessage, FormHelperText } from '@chakra-ui/react';
import { Props as SelectProps } from 'chakra-react-select';
import { useField } from 'react-final-form';
import { UiSearchAsync } from '@/ui-elements';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';

type Props = {
  label?: string;
  fetchOptions: (arg: string) => Promise<Array<SelectOption>>;
  content?: string;
  name: string;
  required?: boolean;
  isDisabled?: boolean;
  disabled?: boolean;
  defaultInputValue?: string;
  placeholder?: string | React.ReactNode;
  treshhold?: number;
  overflown?: boolean;
  defaultOptions?: Array<SelectOption>;
} & Omit<SelectProps, 'defaultValue'>;

export const UiSelectFieldAsync = ({ name, label, required, content, ...props }: Props) => {
  const { isDisabled } = props;
  const {
    input,
    meta: { error, touched },
  } = useField(name);
  const invalid = error && touched;

  return (
    <FormControl isRequired={required && !isDisabled} isInvalid={invalid}>
      <FormLabel mb={1} fontSize="xs" className="ui-field__label">
        {label}
      </FormLabel>
      <UiSearchAsync {...props} {...input} isInvalid={invalid} />
      {invalid ? <FormErrorMessage>{error}</FormErrorMessage> : <FormHelperText>{content}</FormHelperText>}
    </FormControl>
  );
};
