import { OrderExecutionStatus, OrderStatus } from 'config/constants/statuses';
import { FunnelLoadStatus } from '@/types/models/funnel';

/*
 * Карта перехода статусов. possibleNextStatuses  показывает возможные варианты движения
 *  "*" - движение куда угодно
 *
 * */
export const orderStatusMap: Record<
  OrderStatus,
  { possibleNextStatuses: Array<OrderStatus> | '*' | null; defaultPrevStatus?: OrderStatus; defaultNextStatus?: OrderStatus }
> = {
  carrierSearch: {
    possibleNextStatuses: [OrderExecutionStatus.New, OrderExecutionStatus.Preparation],
    defaultPrevStatus: OrderExecutionStatus.New,
  },
  completedPayed: { possibleNextStatuses: null },
  completedWithDocs: { possibleNextStatuses: [OrderExecutionStatus.CompletedPayed] },
  completedWithoutDocs: { possibleNextStatuses: [OrderExecutionStatus.CompletedWithDocs] },
  completed: {
    possibleNextStatuses: null,
  },
  confirmed: { possibleNextStatuses: [OrderExecutionStatus.Executing] },
  executing: { possibleNextStatuses: [OrderExecutionStatus.CompletedWithoutDocs] },
  failed: { possibleNextStatuses: '*' },
  new: { possibleNextStatuses: [OrderExecutionStatus.CarrierSearch] },
  preparation: {
    possibleNextStatuses: [OrderExecutionStatus.Confirmed, OrderExecutionStatus.CarrierSearch],
    defaultPrevStatus: OrderExecutionStatus.CarrierSearch,
  },
  troubling: { possibleNextStatuses: '*' },
  [FunnelLoadStatus.NewCalculation]: {
    possibleNextStatuses: null,
    defaultPrevStatus: undefined,
    defaultNextStatus: undefined,
  },
  [FunnelLoadStatus.CostCalculation]: {
    possibleNextStatuses: null,
    defaultPrevStatus: undefined,
    defaultNextStatus: undefined,
  },
  [FunnelLoadStatus.CostCalculated]: {
    possibleNextStatuses: null,
    defaultPrevStatus: undefined,
    defaultNextStatus: undefined,
  },
  [FunnelLoadStatus.SentCostToClient]: {
    possibleNextStatuses: null,
    defaultPrevStatus: undefined,
    defaultNextStatus: undefined,
  },
  [FunnelLoadStatus.CostConfirmed]: {
    possibleNextStatuses: null,
    defaultPrevStatus: undefined,
    defaultNextStatus: undefined,
  },
  [FunnelLoadStatus.CostCanceled]: {
    possibleNextStatuses: null,
    defaultPrevStatus: undefined,
    defaultNextStatus: undefined,
  },
};
