import { MenuOption } from '@/components/order-filter/types';
import { SortType } from './types';

export const sortingOptions: Array<MenuOption> = [
  {
    id: 0,
    sorting: { by: 'OfferEndDT', order: SortType.Desc },
    value: 'По срочности (сначала не срочные)',
  },
  {
    id: 1,
    sorting: { by: 'OfferEndDT', order: SortType.Asc },
    value: 'По срочности (сначала срочные)',
  },
  {
    id: 2,
    sorting: { by: 'Created', order: SortType.Desc },
    value: 'По дате создания (сначала новые)',
  },
  {
    id: 3,
    sorting: { by: 'Created', order: SortType.Asc },
    value: 'По дате создания (сначала старые)',
  },
  {
    id: 4,
    sorting: { by: 'StartPrice', order: SortType.Desc },
    value: 'По цене рейса (сначала дорогие)',
  },
  {
    id: 5,
    sorting: { by: 'StartPrice', order: SortType.Asc },
    value: 'По цене рейса (сначала не дорогие)',
  },
];

export const routesSortingOptions: Array<MenuOption> = [
  {
    id: 0,
    sorting: { by: 'Date', order: SortType.Desc },
    value: 'По дате исполнения (сначала недавние)',
  },
  {
    id: 1,
    sorting: { by: 'Date', order: SortType.Asc },
    value: 'По дате исполнения (сначала давние)',
  },
];
