export function calculateFinalPrice(price: number, prepayment: number, tax: number, fuel: number) {
  if (price < 0) {
    throw new Error('Цена не может быть отрицательным числом');
  }
  if (prepayment < 0) {
    throw new Error('Аванс не может быть отрицательным числом');
  }
  if (fuel < 0) {
    throw new Error('Аванс топливом не может быть отрицательным числом');
  }
  return price - prepayment - tax - fuel;
}
