import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SidebarMode } from 'components/sidebar-wrapper/constants';
import { Routes } from 'config/routes/routes';
import { CarriersLoadsState } from './types/carriers-loads-state';
import { setSidebarMode } from '@/features/carriers-loads/helpers/reducers/set-sidebar-mode';
import { selectOrderForPage } from '@/features/carriers-loads/helpers/reducers/select-order-for-page';

const initialPageState = {
  id: undefined,
  sidebarMode: SidebarMode.None,
};

const initialState: CarriersLoadsState = {
  // выбранная заявка/заявки
  activeOrderId: undefined,
  // TODO not used
  [Routes.CarriersLoadsAuthorized]: initialPageState,
  // TODO not used
  [Routes.CarriersLoads]: initialPageState,
  // выбранная заявка/заявки с предложением перевозчика
  [Routes.OrdersWithMyParticipation]: initialPageState,
  // текущие активные рейсы (Рейс исполняется)
  [Routes.CurrentRoutes]: initialPageState,
  // Будущие активные рейсы (Оформлен)
  [Routes.FutureRoutes]: initialPageState,
  // Завершенные рейсы (Оформлен)
  [Routes.CompletedRoutes]: initialPageState,
  // разделение активной заявки, activeSplit -количество на которое планируется разделить активную заявку
  splittingOrdersCount: 0,
};

const slice = createSlice({
  name: 'carriersLoads',
  initialState,
  reducers: {
    // поиск исполнителя
    searchForCarrier: (state, action: PayloadAction<{ id: number } | undefined>) => {
      if (action.payload) state.activeOrderId = action.payload.id;
      state.splittingOrdersCount = 0;
    },
    // выбор заявки
    select: (state, action: PayloadAction<number | undefined>) => {
      if (!action.payload) {
        state.activeOrderId = undefined;
      } else {
        state.activeOrderId = action.payload;
      }
    },
    setSidebarMode,
    selectOrderForPage,
    // разделение заявки
    split: (state, action: PayloadAction<{ order?: { id: number }; partsToSplit?: number } | undefined>) => {
      if (action.payload) state.activeOrderId = action.payload?.order?.id;
      state.splittingOrdersCount = action.payload?.partsToSplit || 0;
    },
    /*
     * Устанавливает количество видимых заявок на выбранном табе
     */
    setTotalVisibleOrdersCount: (state, action: PayloadAction<number | undefined>) => {
      state.totalVisibleOrdersCount = action.payload;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const carriersLoadsReducers = slice.reducer;
export const carriersLoadsActions = slice.actions;
