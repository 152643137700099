import './funnel-auto-calculation-button.scss';
import { UiButtonFilled } from '@/ui-elements';
import { AUTO_CALCULATION } from '@/config/constants/loads';

export const FunnelAutoCalculationButton = () => {
  return (
    <UiButtonFilled tooltip="В разработке" disabled className="funnel-auto-calculation-button">
      {AUTO_CALCULATION}
    </UiButtonFilled>
  );
};
