import { FC } from 'react';
import { PageFlexWrapper } from 'pages/page-flex-wrapper';
import { ResourcesView } from '@/views/resources/resources-view/resources-view';
import { ResourcesSidebar } from '@/views/resources/sidebars/sidebar/resources-sidebar';

export const ResourcesPage: FC = () => (
  <PageFlexWrapper>
    <ResourcesView />
    <ResourcesSidebar />
  </PageFlexWrapper>
);
