import classNames from 'classnames';
import { FC } from 'react';
import { Box, Divider, Flex, HStack } from '@chakra-ui/react';
import { FormApi } from 'final-form';
import { CustomersCreateFormValues } from 'components/create-order-form/schema';
import { INN_MASK } from 'components/auth-form/constants';
import { CustomerAddFormReq } from 'components/customers/customer-add-form/customer-add-form-req';
import { UiButtonFilled, UiButtonOutlined, UiInputField, UiMaskedField, UiSwitchField } from '@/ui-elements';
import { useForm } from '@/helpers/forms/use-form';
import { emptyFunction } from '@/helpers/empty-function';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import './customer-add-form.scss';
import { VoidFunction } from '@/types/common/void-function';
import { validateForm } from '@/helpers/forms/validate';
import { createCustomersSchema } from '@/helpers/forms/validation-schemas/create-customers-form';

type Props = {
  managers?: SelectOption[];
  active?: boolean;
  closeForm?: VoidFunction;
  addCustomer?: (values: CustomersCreateFormValues, form: FormApi) => Object | Promise<Object> | void;
  updatedValues?: Partial<CustomersCreateFormValues>;
  submitting?: boolean;
};

export const CustomerAddForm: FC<Props> = ({
  managers = [],
  active = true,
  closeForm = emptyFunction,
  addCustomer = emptyFunction,
  updatedValues,
  submitting,
}) => {
  const Form = useForm<CustomersCreateFormValues>();
  const validate = validateForm<CustomersCreateFormValues>(createCustomersSchema);

  return (
    <Box className={classNames('customer-add', { active })}>
      <Form onSubmit={addCustomer} validate={validate} initialValues={updatedValues}>
        <HStack>
          <UiInputField required name="customer" label="Клиент" placeholder="Введите название" className="customer-add__customer" />

          <UiMaskedField name="inn" mask={INN_MASK} label="ИНН" placeholder="12 цифр" className="customer-add__inn" required />

          <CustomerAddFormReq managers={managers} />
        </HStack>
        <Divider />
        <HStack className="customer-add__comments">
          <UiInputField
            className="customer-add__comments-client"
            name="comment"
            label="Комментарий"
            content="Добавьте комментарий о клиенте"
            placeholder="Введите текст"
          />
          <UiInputField
            name="tarif"
            label="Тарифное соглашение"
            content="Добавьте комментарий о тарифном соглашении"
            placeholder="Введите текст"
            className="customer-add__comments-tarif"
          />
        </HStack>
        {updatedValues && (
          <HStack>
            <UiInputField name="circulationLimit" label="Лимит по факторингу" className="customer-add__inn" isDisabled />
            <UiInputField name="inCirculation" label="В обороте" className="customer-add__inn" isDisabled />
            <UiInputField name="remainder" label="Доступный остаток" className="customer-add__inn" isDisabled />
          </HStack>
        )}
        <Flex>
          <UiSwitchField name="vipCustomer" label="VIP клиент" />
          <Flex className="customer-add__buttons" justifyContent="end">
            <UiButtonOutlined onClick={closeForm} mode="dark">
              Отменить добавление
            </UiButtonOutlined>
            <UiButtonFilled type="submit" mode="dark" isDisabled={submitting} isLoading={submitting}>
              На проверку в СБ
            </UiButtonFilled>
          </Flex>
        </Flex>
      </Form>
    </Box>
  );
};
