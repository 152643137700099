import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import './analitics-view.scss';
import { AnalyticsTableComponentForManager } from 'components/analytics-component/analytics-table-component-for-manager';
import { amountTableConfigForManager } from 'components/analytics-component/config/amount-table-for-manager';
import { dataSourceForManager } from 'components/analytics-component/config/mock-data-for-manager';
import { dataSourceForAdministrator } from 'components/analytics-component/config/mock-data';
import { amountTableConfig } from 'components/analytics-component/config/amount-table';
import { useAccount } from '@/features/account/hooks/use-account';
import { AnalyticsComponent } from '@/components';
import { UserRole } from '@/types/api/user-role';

export const AnalyticsView = () => {
  const { currentRole } = useAccount();
  const componentRef = useRef<HTMLTableElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="analytics-view">
      {currentRole === UserRole.Manager ? (
        <AnalyticsTableComponentForManager config={amountTableConfigForManager} dataSource={dataSourceForManager} />
      ) : (
        <AnalyticsComponent
          config={amountTableConfig}
          dataSource={dataSourceForAdministrator}
          componentRef={componentRef}
          handlePrint={handlePrint}
        />
      )}
    </div>
  );
};
