import { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import classNames from 'classnames';
import { VerificationItemApproveStatus, VerificationStatus } from '@/config/constants/statuses';
import { VerificationItemApproveStatusNames } from '@/config/constants/texts';
import { formatMomentLocal } from '@/helpers/moment/format-moment-local';
import { DateTimeFormat } from '@/config/constants/date';

interface IProps {
  approvalStatus?: VerificationStatus;
}

export const MyOrganizationApprovalStatus: FC<IProps> = ({ approvalStatus }) => {
  if (!approvalStatus?.status) return null;

  return (
    <Flex className="my-organization-approval-status" align="center" wrap="wrap">
      <div
        className={classNames('my-organization-approval-label', {
          decline: approvalStatus?.status === VerificationItemApproveStatus.Declined,
          approved: approvalStatus?.status === VerificationItemApproveStatus.Accepted,
          waiting: approvalStatus?.status === VerificationItemApproveStatus.Waiting,
          onedit: approvalStatus?.status === VerificationItemApproveStatus.InProcessing,
        })}
      >
        {VerificationItemApproveStatusNames[approvalStatus?.status]}
      </div>
      <div className="my-organization-approval-description">
        <div>{approvalStatus?.date && formatMomentLocal(approvalStatus?.date, DateTimeFormat.YYYYMMDDDashHHMM)}</div>
      </div>
      <div className="my-organization-approval-description">
        <div>{approvalStatus?.departmentName && approvalStatus?.departmentName}</div>
        <div>{approvalStatus?.comment}</div>
      </div>
    </Flex>
  );
};
