import { FC } from 'react';
import { OrderInfoLabel } from 'components/order-info/order-info-label';
import { HStack, VStack } from '@chakra-ui/react';
import classNames from 'classnames';
import { OrderStatusColorScheme } from 'config/constants/order-status-colors';
import { OrderExecutionStatus, OrderStatus } from 'config/constants/statuses';
import { BY_AGREEMENT, CONTACT, PHONE, RESPONSIBLE, RISK_GROUP_DESIRED } from '@/features/order/texts';
import { Order } from '@/types/models/order';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { orderExtender } from '@/features/order/extender/order-extender';
import { UiStatusRequest } from '@/ui-elements/status/status-request';
import { UiChip } from '@/ui-elements/chip/chip';
import './order-info.scss';
import { UiWarningNumber } from '@/ui-elements/ui-warning-number/ui-warning-number';
import { UiStatus } from '@/ui-elements/status/status';
import { toPriceFormat } from '@/helpers/price/to-price-format';
import { UiLink } from '@/ui-elements/ui-link/ui-link';
import { orderStatusesSelector } from '@/features/order/selectors/order-statuses-selector';
import { useAppSelector } from '@/hooks/use-app-selector';
import { Load } from '@/types/models/loads';

type Props = {
  order: Order | Load;
  hideOrderRiskGroup?: boolean;
  className?: string;
};
/**
 * Основная инфорамция о заявке
 * */
export const OrderInfo: FC<Props> = ({ order, className, hideOrderRiskGroup = false }) => {
  const extendedOrder = useExtendedObject(order, orderExtender);
  const orderStatuses = useAppSelector(orderStatusesSelector);
  const statusText = orderStatuses?.orderStatuses?.[order.status as OrderStatus];

  return (
    <div className={classNames('order-info', className)}>
      <HStack className="order-info__body">
        <VStack className="order-info__info">
          <div>
            <span className="order-info__customer">{order.customer?.name}</span>
            <span className="order-info__title">№ {order.number || order.id}</span>
          </div>
          <span>
            <OrderInfoLabel>{BY_AGREEMENT}</OrderInfoLabel>
            <UiLink href={`/clients?id=${order.customer.id}`} className="order-info__agreement" isActive={false}>
              {order.contract?.number}
            </UiLink>
          </span>
          <div>
            <OrderInfoLabel>{CONTACT}</OrderInfoLabel>
            <span className="order-info__value">{extendedOrder.contact?.name}</span>
          </div>
          <div>
            <OrderInfoLabel>{PHONE}</OrderInfoLabel>
            <span className="order-info__value">{extendedOrder.contact?.phone}</span>
          </div>
          <div>
            <OrderInfoLabel>{RESPONSIBLE}</OrderInfoLabel>
            <span className="order-info__value">{extendedOrder?.responsiblePerson?.name}</span>
          </div>
        </VStack>
        <VStack className="order-info__status">
          {extendedOrder.status !== OrderExecutionStatus.New ? (
            <UiStatus
              text={statusText || ''}
              backGroundColor={OrderStatusColorScheme[extendedOrder.status]?.background}
              color={OrderStatusColorScheme[extendedOrder.status]?.color}
            />
          ) : (
            <UiStatusRequest date={extendedOrder.expiration} />
          )}

          <UiChip className="order-info__price">{`${extendedOrder.priceCaption} ${toPriceFormat(order.customerPayment?.price)}`}</UiChip>
          {!hideOrderRiskGroup && (
            <div>
              <span className="order-info__risk">{RISK_GROUP_DESIRED}</span>
              <UiWarningNumber number={extendedOrder.desiredRiskNumber || 0} />
            </div>
          )}
        </VStack>
      </HStack>
    </div>
  );
};
