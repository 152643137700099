import { FC, RefObject, useEffect } from 'react';
import { WidgetWindow } from 'components/widget-window/widget-window';
import { useWidgetActions } from '@/features/widget/hooks/use-widget-actions';
import { useWidgets } from '@/features/widget/hooks/use-widgets';
import { widgetConfig } from '@/features/widget/widget-config';

export const WidgetContainer: FC<{ parentRef: RefObject<HTMLDivElement | undefined>; containerKey: string }> = ({ parentRef, containerKey }) => {
  const { activeWidgets } = useWidgets(containerKey);
  const { hideWidget, closeAllWidgets, setActive } = useWidgetActions(containerKey);

  useEffect(
    () => () => {
      closeAllWidgets();
    },
    [closeAllWidgets]
  );

  return (
    <>
      {activeWidgets.map((widget) => (
        <WidgetWindow
          isActive={widget.isActive}
          setActive={setActive}
          key={widget.key}
          widgetKey={widget.key}
          title={widget.title}
          hideWidget={hideWidget}
          parentRef={parentRef}
        >
          {widgetConfig.widgets[widget.key].render(widget.data, { widgetKey: widget.key, hideWidget: () => hideWidget(widget.key) })}
        </WidgetWindow>
      ))}
    </>
  );
};
