import { Box, Heading, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { Carrier } from '@/types/models/carrier';

type Props = {
  carrier: Carrier;
};

export const FinesTab: FC<Props> = ({ carrier }) => {
  const fines = carrier.drivers.reduce(
    (acc, current) => {
      acc.speedFines += current?.speedFines || 0;
      acc.trafficFines += current?.trafficFines || 0;
      acc.driversLicenseRevoked += current?.driversLicenseRevoked || 0;
      acc.accidents += current?.accidents || 0;
      acc.convictions += current?.convictions || 0;
      return acc;
    },
    { speedFines: 0, trafficFines: 0, driversLicenseRevoked: 0, accidents: 0, convictions: 0 }
  );
  return (
    <Box className="fines-tab">
      <Heading>Штраф за привышение скорости</Heading>
      <Text>{fines.speedFines || 0}</Text>
      <Heading>Штрафы за нарушение ПДД</Heading>
      <Text>{fines.trafficFines || 0}</Text>
      <Heading>Лишение прав</Heading>
      <Text>{fines.driversLicenseRevoked || 0}</Text>
      <Heading>Аварии</Heading>
      <Text>{fines.accidents || 0}</Text>
      <Heading>Из них по вине водителя</Heading>
      <Text>{fines.convictions || 0}</Text>
    </Box>
  );
};
