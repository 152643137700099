import './booker-orders-view.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { BookerOrdersFilters } from 'components/booker-orders/booker-orders-filters/booker-orders-filters';
import { Flex } from '@chakra-ui/react';
import { BookerOrderDetailsTabs, BookerOrderStatus, BookerOrderSwitcherStatus } from 'config/constants/statuses';
import { BookerOrdersList } from 'components/booker-orders/booker-orders-list/booker-orders-list';
import { activeBookerOrderTabSelector } from '@/features/booker/selectors/active-booker-order-tab-selector';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useBookerOrderActions } from '@/features/booker/hooks/use-booker-order-actions';
import { activeBookerOrderIdSelector } from '@/features/booker/selectors/active-booker-order-id-selector';
import { BookerFilterFields } from '@/features/booker/hooks/booker-filter';
import { BookerOrderFilter } from '@/types/api/booker-order-partial';
import { useBookerOrdersCount } from '@/features/booker/hooks/use-booker-orders-count';
import { orderStatusesSelector } from '@/features/order/selectors/order-statuses-selector';
import { useAllBookerOrdersUpdates } from '@/features/booker/hooks/use-all-booker-orders-updates';

/*
 * Учет оплаты рейсов
 * */
export const BookerOrdersView = () => {
  const activeTab = useAppSelector(activeBookerOrderTabSelector);
  const activeBookerOrderId = useAppSelector(activeBookerOrderIdSelector);
  const { select, unselect, selectTab } = useBookerOrderActions();
  const [filters, setFilters] = useState<BookerOrderFilter>({
    search: '',
    status: BookerOrderSwitcherStatus.All,
    paymentWaiter: '',
    customerDebtOverload: undefined,
  });
  const orderStatuses = useAppSelector(orderStatusesSelector);
  const switcherNames = orderStatuses?.bookerSwitcherStatuses;

  const { data: bookerOrders, fetchNextPage, isFetchingNextPage, isInitialLoading } = useAllBookerOrdersUpdates(filters);
  const allRecords = bookerOrders ? bookerOrders.pages.flatMap((d) => d.data).filter((d) => d !== undefined) : [];
  const allSummary = bookerOrders?.pages[0].summary;

  const handleFilterChange = useCallback((values: BookerFilterFields) => {
    setFilters({
      search: values?.search ? values?.search : undefined,
      customerDebtOverload: values?.customerDebtOverload ? values?.customerDebtOverload : undefined,
    });
  }, []);

  const [tab, setTab] = useState<BookerOrderDetailsTabs | string>(BookerOrderDetailsTabs.Customer);

  const updateFilters = (activeKey: any, mainTab: any) => {
    if (activeKey === BookerOrderSwitcherStatus.RequestAdvance) {
      setFilters((prev) => ({
        ...prev,
        status: '',
        paymentWaiter: 'prepayment',
      }));
    } else if (activeKey === BookerOrderSwitcherStatus.FinallySettlement) {
      setFilters((prev) => ({
        ...prev,
        status: '',
        paymentWaiter: mainTab === BookerOrderDetailsTabs.Carrier ? 'carrier' : 'customer',
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        status:
          activeKey === BookerOrderSwitcherStatus.Completed
            ? `${BookerOrderStatus.CompletedWithoutDocs}, ${BookerOrderStatus.CompletedWithDocs}, ${BookerOrderStatus.CompletedPayed}`
            : activeKey,
        paymentWaiter: '',
      }));
    }
  };
  const { bookerOrdersCount } = useBookerOrdersCount({ contractor: tab.toLowerCase() });
  const metrics = bookerOrdersCount?.data?.data;

  const labels = [
    {
      key: BookerOrderSwitcherStatus.Preparation,
      label: `${switcherNames?.[BookerOrderSwitcherStatus.Preparation]} `,
    },
    {
      key: BookerOrderSwitcherStatus.Confirmed,
      label: `${switcherNames?.[BookerOrderSwitcherStatus.Confirmed]} `,
    },
    {
      key: BookerOrderSwitcherStatus.Executing,
      label: `${switcherNames?.[BookerOrderSwitcherStatus.Executing]}`,
    },
    {
      key: BookerOrderSwitcherStatus.Completed,
      label: `${switcherNames?.[BookerOrderSwitcherStatus.Completed]}`,
    },
    {
      key: BookerOrderSwitcherStatus.Failed,
      label: `${switcherNames?.[BookerOrderSwitcherStatus.Failed]}`,
    },
    {
      key: BookerOrderSwitcherStatus.RequestAdvance,
      label: `${switcherNames?.[BookerOrderSwitcherStatus.RequestAdvance]} (${metrics?.Prepayment || 0})`,
    },
    {
      key: BookerOrderSwitcherStatus.FinallySettlement,
      label: `${switcherNames?.[BookerOrderSwitcherStatus.FinallySettlement]} (${metrics?.CarrierNotPayed || 0})`,
    },
    {
      key: BookerOrderSwitcherStatus.All,
      label: `${switcherNames?.[BookerOrderSwitcherStatus.All]}`,
    },
  ];

  const labelsCustomer = [
    {
      key: BookerOrderSwitcherStatus.Preparation,
      label: `${switcherNames?.[BookerOrderSwitcherStatus.Preparation]} `,
    },
    {
      key: BookerOrderSwitcherStatus.Confirmed,
      label: `${switcherNames?.[BookerOrderSwitcherStatus.Confirmed]} `,
    },
    {
      key: BookerOrderSwitcherStatus.Executing,
      label: `${switcherNames?.[BookerOrderSwitcherStatus.Executing]}`,
    },
    {
      key: BookerOrderSwitcherStatus.Completed,
      label: `${switcherNames?.[BookerOrderSwitcherStatus.Completed]}`,
    },
    {
      key: BookerOrderSwitcherStatus.Failed,
      label: `${switcherNames?.[BookerOrderSwitcherStatus.Failed]}`,
    },
    {
      key: BookerOrderSwitcherStatus.FinallySettlement,
      label: `${switcherNames?.[BookerOrderSwitcherStatus.FinallySettlement]} (${metrics?.CustomerNotPayed || 0})`,
    },
    {
      key: BookerOrderSwitcherStatus.All,
      label: `${switcherNames?.[BookerOrderSwitcherStatus.All]}`,
    },
  ];

  const handleTabChange = useCallback(
    (value: string) => {
      setTab(value);
      updateFilters(activeTab, value);
    },
    [activeTab]
  );
  const handleActiveTab = (activeKey: any) => {
    selectTab(activeKey);
    updateFilters(activeKey, tab);
    unselect();
  };

  useEffect(() => {
    selectTab(BookerOrderSwitcherStatus.All);
  }, [selectTab]);

  return (
    <Flex className="booker-orders-view" flexDirection="column">
      <BookerOrdersFilters
        activeTab={activeTab}
        switcherLabels={labels}
        switcherLabelsCustomer={labelsCustomer}
        onChangeTabs={handleActiveTab}
        onChange={handleTabChange}
        tab={tab}
        handleFilterChange={handleFilterChange}
        allSummary={allSummary}
      />

      <BookerOrdersList
        tab={tab}
        data={allRecords}
        onSelectBookerOrder={select}
        onUnselectBookerOrder={unselect}
        activeBookerOrderId={activeBookerOrderId}
        loadMore={fetchNextPage}
        loading={isFetchingNextPage}
        isInitialLoading={isInitialLoading}
      />
    </Flex>
  );
};
