import React, { FC, MouseEventHandler } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import './settings.scss';
import { SettingsOrganizations } from 'components/settings/settings-organizations';
import { SettingsOrganizationsCustomers } from 'components/settings/settings-organizations-customers';
import { SettingsTemplates } from 'components/settings/settings-templates';
import { SettingsTemplatesForCustomer } from 'components/settings/settings-templates-for-customer';
import { SetFormValues } from 'components/settings/settings-details-empty/schema';
import { UiButtonFilled, UiButtonOutlined } from '@/ui-elements';
import { useForm } from '@/helpers/forms/use-form';

/*
 * Сайдбар раздела настройки (формирование документов)
 * */
type Props = {
  handleCancel?: MouseEventHandler<HTMLButtonElement>;
  onCreate: (values: SetFormValues) => void;
  initialValues?: SetFormValues;
};
export const SettingsForm: FC<Props> = ({ handleCancel, onCreate, initialValues }) => {
  const Form = useForm<SetFormValues>();
  return (
    <Form onSubmit={onCreate} initialValues={initialValues}>
      <Flex flexDirection="column">
        <Flex flexDirection="column" pl="8px" pr="8px" mt="24px">
          <Box>
            <Text className="settings-card__text">Формирование договора заявки с перевозчиком</Text>
            <Flex justifyContent="space-between" gap="8px" mt="16px">
              <Box w="50%">
                <SettingsTemplates />
              </Box>
              <Box w="50%">
                <SettingsOrganizations />
              </Box>
            </Flex>
          </Box>
          <Box mt="24px">
            <Text className="settings-card__text">Формирование договора заявки с клиентом</Text>
            <Flex justifyContent="space-between" gap="8px" mt="16px">
              <Box w="50%">
                <SettingsTemplatesForCustomer />
              </Box>
              <Box w="50%">
                <SettingsOrganizationsCustomers />
              </Box>
            </Flex>
          </Box>
        </Flex>
        <Flex className="settings-card__box-button" w="100%" justifyContent="center" gap={5}>
          <UiButtonOutlined onClick={handleCancel} mode="dark" width={222}>
            Отменить
          </UiButtonOutlined>
          <UiButtonFilled mode="dark" onClick={() => {}} width={222} type="submit">
            Сохранить
          </UiButtonFilled>
        </Flex>
      </Flex>
    </Form>
  );
};
