import { Tab } from '@/ui-elements/switcher/types';
import { useLoadsCountUpdates } from '@/features/funnel/hooks';
import { getLabelsForFunnelTabs } from '@/features/funnel/helpers';
import { FunnelSwitcherTitles, funnelMetricsInitialValues } from '@/config/constants/funnel';

export const useFunnelSwitcherLabels = (): { switcherLabels: Array<Tab> } => {
  const { loadsCount } = useLoadsCountUpdates();
  const metrics = loadsCount?.data?.data || funnelMetricsInitialValues;
  const switcherLabels = getLabelsForFunnelTabs(metrics, FunnelSwitcherTitles);
  return { switcherLabels };
};
