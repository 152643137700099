import { useFormState } from 'react-final-form';
import { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { FormValues, VerificationFormMode } from 'components/verification-forms/types';
import { UiCheckboxField, UiSelectField } from '@/ui-elements';
import { useCarrierDrivers } from '@/features/carriers/hooks/use-carrier-drivers';
import { normalizeSelectOptionsResponce } from '@/helpers/api/normalize-select-options-responce';
// import { fetchCarriersDriversOptions } from '@/api/fetch-carriers-drivers-options';

type Props = {
  mode: VerificationFormMode;
  initialValues: FormValues;
};
export const DriverOptionsSubform: FC<Props> = ({ mode, initialValues }) => {
  const { values } = useFormState();
  const allDrivers = useCarrierDrivers(values.carrierId?.value);

  return (
    <Box mt={4}>
      <Box mt={4}>
        <Text fontSize={18} fontWeight={700}>
          Выберите водителя
        </Text>
      </Box>
      <UiSelectField
        options={normalizeSelectOptionsResponce(allDrivers?.data?.data as any)}
        noOptionsMessage={() => 'Отсутствуют водители. Перед добавлением ТС необходимо создать карточку водителя'}
        isDisabled={mode === 'check'}
        name="driverId"
        label="Выберите водителя организации"
        placeholder="Выберите водителя"
        required
      />

      <Text mt={4} fontSize={18} fontWeight={700}>
        Трекинг
      </Text>
      <Box mt={4}>
        <UiCheckboxField
          defaultChecked={initialValues.tracker}
          name="tracker"
          label="Есть постоянно подключенное бортовое устройство слежения"
          isDisabled={mode === 'check'}
        />
      </Box>
      <Box mt={4}>
        <UiCheckboxField
          defaultChecked={initialValues.permanentTracking}
          name="permanentTracking"
          label="Готов предоставить полный и постоянный доступ к треку"
          isDisabled={mode === 'check'}
        />
      </Box>
    </Box>
  );
};
