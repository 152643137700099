import React, { FC } from 'react';
import { BookerOrderDetailsTabs } from 'config/constants/statuses';
import classNames from 'classnames';
import {
  BG_STATUS,
  BG_STATUS_PREPAYMENT,
  COLOR_STATUS,
  COLOR_STATUS_PREPAYMENT,
  SPACING,
  SPACING_5,
} from 'components/booker-orders/booker-orders-list/constants';
import { Box, Flex, Text } from '@chakra-ui/react';
import { UiStatus } from '@/ui-elements/status/status';
import './booker-orders-list.scss';
import { BookerOrder } from '@/types/models/booker-order';
import { emptyFunction } from '@/helpers/empty-function';
import { toPriceFormat } from '@/helpers/price/to-price-format';
import { formatMomentLocal } from '@/helpers/moment/format-moment-local';
import { MOMENT_L } from '@/helpers/moment/constants';
import { useCarrierUpdates } from '@/features/carriers/hooks/use-carrier-updates';

/*
 * Учет оплаты рейсов Order
 * */
type Props = {
  data: BookerOrder;
  handleBookerOrderClick: Function;
  selected?: boolean;
  tab?: string;
};
export const BookerOrderComponent: FC<Props> = ({ data, handleBookerOrderClick = emptyFunction, selected, tab }) => {
  const {
    carrier: { data: carrierResp },
  } = useCarrierUpdates(data.carrier.id);
  const carrier = carrierResp?.data;
  const name = tab === BookerOrderDetailsTabs.Carrier ? carrier?.name : data.customer.name;
  const prepayment = toPriceFormat(data.prepayment.prepayment);
  const date = formatMomentLocal(data.dateGetOriginals, MOMENT_L);

  return (
    <Box className={classNames('booker-order', { selected })} onClick={() => handleBookerOrderClick(data.id)} mb={SPACING} mt={SPACING_5}>
      <Flex justifyContent="space-between" pl={SPACING} pr={SPACING} pt={SPACING} pb={SPACING}>
        <Flex gap="8px">
          <Text>{name} </Text>
          <Text>Заявка № {data.id}</Text>
        </Flex>
        <Box>
          <Flex>
            {data.prepayment.possibility && (
              <UiStatus
                text={prepayment}
                backGroundColor={BG_STATUS_PREPAYMENT}
                color={COLOR_STATUS_PREPAYMENT}
                className="booker-order__prepayment"
              />
            )}
            <Box ml="16px">
              {tab === BookerOrderDetailsTabs.Carrier ? (
                <Flex gap="8px">
                  <UiStatus
                    text={`${toPriceFormat(data.carrierPayment.priceWithVAT)} с НДС`}
                    backGroundColor={BG_STATUS}
                    color={COLOR_STATUS}
                    className="booker-order__price"
                  />
                  <UiStatus
                    text={`${toPriceFormat(data.carrierPayment.priceWithoutVAT)} без НДС`}
                    backGroundColor={BG_STATUS}
                    color={COLOR_STATUS}
                    className="booker-order__price"
                  />
                </Flex>
              ) : (
                <UiStatus
                  text={`${toPriceFormat(data.customerPayment.price)}`}
                  backGroundColor={BG_STATUS}
                  color={COLOR_STATUS}
                  className="booker-order__price"
                />
              )}
            </Box>
          </Flex>
        </Box>
      </Flex>
      {tab === BookerOrderDetailsTabs.Customer && data.dateGetOriginals && (
        <Flex pl={SPACING} pr={SPACING} pt={SPACING} pb={SPACING}>
          <Text>Дата получения оригиналов: {date} </Text>
        </Flex>
      )}
    </Box>
  );
};
