import { useEffect } from 'react';
import { CRM_URL } from '@/config/constants/crm';
import { SpinnerView } from '@/views';

export const CRMPage = () => {
  useEffect(() => {
    window.location.replace(CRM_URL);
  }, []);
  return <SpinnerView />;
};
