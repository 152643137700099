import { useDispatch } from 'react-redux';
import { resourcesActions } from '@/features/resources/slice';
import { ResourcesContentTab, ResourcesSidebarMode, ResourcesStatusTab } from '@/features/resources/types/general';

export const useResourcesDispatchActions = () => {
  const dispatch = useDispatch();

  /* установка таба: водители или тс */
  const handleSetContentTab = (contentTab: ResourcesContentTab) => {
    dispatch(resourcesActions.setContentTab(contentTab));
  };
  /* выбор контента sidebar: делати, форма или null */
  const handleSetSidebarMode = (sidebarMode: ResourcesSidebarMode) => {
    dispatch(resourcesActions.setSidebarMode(sidebarMode));
  };
  /* выбор статуса для водителей или тс */
  const handleSetStatusTab = (statusTab: ResourcesStatusTab) => {
    dispatch(resourcesActions.setStatusTab(statusTab));
  };
  /* выбор активной карточки водителя */
  const handleSelectDriver = (id: number | null) => {
    dispatch(resourcesActions.selectDriver(id));
  };
  /* выбор активной карточки тс */
  const handleSelectVehicle = (id: number | null) => {
    dispatch(resourcesActions.selectVehicle(id));
  };
  /* очищение хранилища */
  const handleClearState = () => {
    dispatch(resourcesActions.clearState());
  };

  return {
    handleSetContentTab,
    handleSetSidebarMode,
    handleSetStatusTab,
    handleSelectDriver,
    handleSelectVehicle,
    handleClearState,
  };
};
