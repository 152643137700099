import { useEffect } from 'react';
import { ButtonDirection, FunnelSwitcherStatus } from '@/types/models/funnel';
import { Load } from '@/types/models/loads';
import { checkLoadCalculatedState } from '@/features/funnel/helpers';
import { Order } from '@/types/models/order';

/* Если у текущего груза не заполнены условия оплаты для клиента и исполнителя, то блокируем кнопку */
export const useDisableButtonForNotCalculated = ({
  load,
  activeTab,
  handleChangeButtonDisabledState,
}: {
  load?: Load | Order;
  activeTab: FunnelSwitcherStatus;
  handleChangeButtonDisabledState: (direction: ButtonDirection, value: boolean) => void;
}) => {
  const currentLoadIsCalculated = checkLoadCalculatedState(load);
  useEffect(() => {
    if (activeTab === FunnelSwitcherStatus.CostCalculation) {
      handleChangeButtonDisabledState(ButtonDirection.Forward, !currentLoadIsCalculated);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, load, currentLoadIsCalculated]);
};
