import { FC } from 'react';
import { GridItem } from '@chakra-ui/react';
import { UiSelectFieldAsync } from '@/ui-elements';
import { fetchSettingsTemplatesOptions } from '@/api/fetch-settings-templates-options';
import { useSettingsTemplates } from '@/features/settings/hooks/use-settings-templates';
import { OrganizationDocumentType } from '@/api/documents/documents-types';

export const SettingsTemplates: FC = () => {
  const { settingsTemplatesData } = useSettingsTemplates({ organizationDocumentType: OrganizationDocumentType.Carrier });
  return (
    <GridItem mt={5}>
      <UiSelectFieldAsync
        fetchOptions={fetchSettingsTemplatesOptions}
        name="templatesForCarrier"
        label="Выберете шаблон"
        placeholder="Начните вводить название"
        treshhold={1}
        defaultOptions={settingsTemplatesData}
      />
    </GridItem>
  );
};
