import { OrderSwitcherStatus } from '@/config/constants/statuses';
import { OrderCount } from '@/types/api/order-partial';

export const getLabelsForTabs = (metrics?: OrderCount, switcherNames?: Record<OrderSwitcherStatus, string> | undefined) => {
  const labels: Array<{ key: string; label: string }> = [];
  if (switcherNames) {
    Object.entries(switcherNames).map(([key, value]) => {
      // NOTE вырезать с бэка OrderSwitcherStatus.All
      if (key !== OrderSwitcherStatus.Completed && key !== OrderSwitcherStatus.All) {
        labels.push({
          key,
          label: `${value} (${metrics?.[key as keyof typeof metrics] || 0})`,
        });
      }
    });
    labels.splice(5, 0, {
      key: OrderSwitcherStatus.Completed,
      label: `Завершенные (${!metrics ? 0 : (metrics.completedPayed || 0) + (metrics.completedWithDocs || 0) + (metrics.completedWithoutDocs || 0)})`,
    });
  }
  return labels;
};
