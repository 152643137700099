import { useFormState } from 'react-final-form';
import { Grid, GridItem } from '@chakra-ui/react';
import { UiCheckboxField, UiInputField } from '@/ui-elements';

export const OrganizationDetailsAddressSubform = () => {
  const { values } = useFormState();
  const addressesCoincide = values?.addressedCoincide;

  return (
    <>
      <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={3.5}>
        <GridItem>
          <UiCheckboxField name="addressedCoincide" label="Почтовый адрес совпадает с юридическим" />
        </GridItem>
      </Grid>
      <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={3.5}>
        <GridItem>
          <UiInputField name="legalAddress" label="Юридический адрес" placeholder="Юридический адрес" required />
        </GridItem>
        {!addressesCoincide && (
          <GridItem>
            <UiInputField name="actualAddress" label="Почтовый адрес" placeholder="Почтовый адрес" />
          </GridItem>
        )}
      </Grid>
    </>
  );
};
