import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys);

const multiSelect = definePartsStyle({
  container: {
    bg: 'neutrals.500',
    borderRadius: '24px',
  },
  label: {
    color: 'typography.100',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  closeButton: {
    svg: {
      path: {
        fill: 'neutrals.100',
        stroke: 'transparent',
      },
    },
  },
});

const waiting = definePartsStyle({
  container: {
    bg: 'accentYellow.100',
    borderRadius: '16px',
    padding: '4px 20px',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  label: {
    color: 'typography.50',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
});

const approved = definePartsStyle({
  container: {
    bg: 'accentGreen.150',
    borderRadius: '16px',
    padding: '4px 20px',
    color: 'typography.250',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  label: {
    color: 'typography.250',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
});

const declined = definePartsStyle({
  container: {
    bg: 'accentRed.100',
    borderRadius: '16px',
    padding: '4px 20px',
    color: 'typography.250',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  label: {
    color: 'typography.250 !important',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
});

export const tagTheme = defineMultiStyleConfig({
  variants: { multiSelect, waiting, approved, declined },
});
