import React, { FC } from 'react';
import { isEmpty } from 'lodash';
import { BookerOrderComponent } from 'components/booker-orders/booker-orders-list/booker-order-component';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { BookerOrder } from '@/types/models/booker-order';
import { emptyFunction } from '@/helpers/empty-function';
import { InfiniteScroll } from '@/views/infinite-scroll/infinite-scroll';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';

/*
 * Учет оплаты рейсов Order
 * */
type Props = {
  data?: Array<BookerOrder>;
  onUnselectBookerOrder: Function;
  onSelectBookerOrder: Function;
  activeBookerOrderId?: number;
  tab?: string;
  loadMore: (args: any) => void;
  loading?: boolean;
  isInitialLoading?: boolean;
};
export const BookerOrdersList: FC<Props> = ({
  data,
  onSelectBookerOrder = emptyFunction,
  onUnselectBookerOrder = emptyFunction,
  activeBookerOrderId,
  tab,
  loadMore,
  loading = false,
  isInitialLoading,
}) => {
  const handleBookerOrderClick = (bookerOrderId: number) => {
    if (bookerOrderId === activeBookerOrderId) {
      onUnselectBookerOrder();
    } else {
      onSelectBookerOrder(bookerOrderId);
    }
  };
  return (
    <UiScrollbars style={{ width: '100%', height: '77vh' }}>
      {isInitialLoading ? (
        <UiSpinner visible mt={48} />
      ) : (
        <div className="booker-orders-list">
          <InfiniteScroll callback={loadMore} loading={loading} skip={isEmpty(data)}>
            {data?.map((item) => (
              <BookerOrderComponent
                data={item}
                key={item.id}
                handleBookerOrderClick={handleBookerOrderClick}
                selected={activeBookerOrderId === item.id}
                tab={tab}
              />
            ))}
          </InfiniteScroll>
        </div>
      )}
    </UiScrollbars>
  );
};
