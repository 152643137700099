import { useEffect } from 'react';
import { isArray } from 'lodash';
import { OrderExecutionStatus } from 'config/constants/statuses';
import { Routes } from 'config/routes/routes';
import { useGetQueryId } from '@/hooks/use-get-query-id';
import { CarrierLoadsPageWithOrders, useCarrierLoadActions } from '@/features/carriers-loads/hooks/use-carriers-loads-actions';
import { Orders } from '@/types/models/order';
import { flatMapDataPages } from '@/helpers/api/flat-map-data-pages';
import { useFetchOrdersWithMyParticipation } from '@/features/order/hooks/use-fetch-orders-with-my-participation';

const getPrepareParam = (param: string | string[]) => {
  if (isArray(param)) return param.join(',');
  return param;
};

const getQuery = (page: CarrierLoadsPageWithOrders) => {
  const accepted: 0 | 1 = 1;

  switch (page) {
    case Routes.CurrentRoutes:
      return {
        accepted,
        status: OrderExecutionStatus.Executing,
      };
    case Routes.FutureRoutes:
      return {
        accepted,
        status: OrderExecutionStatus.Confirmed,
      };
    case Routes.CompletedRoutes:
      return {
        accepted,
        status: getPrepareParam([OrderExecutionStatus.CompletedWithoutDocs, OrderExecutionStatus.CompletedWithDocs]),
      };
    case Routes.OrdersWithMyParticipation:
    case Routes.CarriersLoads:
    case Routes.CarriersLoadsAuthorized:
    default:
      return {};
  }
};

export const useCarriersLoadsRoutes = (page: CarrierLoadsPageWithOrders) => {
  const { selectOrderForPage, setSidebarMode, closeSidebar } = useCarrierLoadActions(page);

  const queryOrderId = useGetQueryId();

  useEffect(() => {
    if (queryOrderId) {
      selectOrderForPage(Number(queryOrderId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryOrderId]);

  const { data, fetchNextPage, isFetchingNextPage, isInitialLoading } = useFetchOrdersWithMyParticipation({
    query: getQuery(page),
  });
  const allRecords: Orders = flatMapDataPages(data);

  return {
    fetchNextPage,
    isFetchingNextPage,
    isInitialLoading,
    allRecords,
    setSidebarMode,
    closeSidebar,
  };
};
