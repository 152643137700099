import './security.scss';
import { FC } from 'react';
import { SecurityHeaderComponent } from 'components/security/security-header-component/security-header-component';
import { ApprovalsFilter } from '@/types/api/approvals';
import { useMetricsUpdates } from '@/features/approvals/hooks/use-metrics-updates';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';
import { Metrics } from '@/types/models/metrics';

type Props = {
  selectedRangeButton: string;
  onClickRangeButton: (value: string) => void;
  onChangeCalendarValue: any;
  filter?: ApprovalsFilter;
  rangeValue: any;
};

export const SecurityHeaderView: FC<Props> = ({ selectedRangeButton, onClickRangeButton, onChangeCalendarValue, filter, rangeValue }) => {
  const { metrics } = useMetricsUpdates(filter);

  return (
    <div className="security-header">
      <SecurityHeaderComponent
        metrics={metrics.data as ResponseWithoutPagerSimple<Metrics>}
        onChangeCalendarValue={onChangeCalendarValue}
        onClickRangeButton={onClickRangeButton}
        selectedRangeButton={selectedRangeButton}
        selectedRange={rangeValue}
      />
    </div>
  );
};
