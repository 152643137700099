import { ApproveStatus } from '@/config/constants/statuses';
import { Approval } from '@/types/models/approval';

export const getApprovalStatus = (approval: Approval) => {
  if (!approval) return '';
  switch (approval.status) {
    case ApproveStatus.Waiting:
      return 'Не проходил проверку';
    case ApproveStatus.InProcessing:
      return 'На проверке в ОТК';
    case ApproveStatus.Processed:
      return 'Проверка пройдена';
    default:
      return '';
  }
};
