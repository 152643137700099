import { BookerOrderSwitcherStatus } from 'config/constants/statuses';
import React, { FC } from 'react';
import { UiSwitcher } from '@/ui-elements/switcher/switcher';
import { Tab } from '@/ui-elements/switcher/types';
import { emptyFunction } from '@/helpers/empty-function';

/*
 * Учет оплаты рейсов switcher
 * */
type Props = {
  switcherLabels: Tab[];
  activeTab: BookerOrderSwitcherStatus;
  onChange: (activeKey: any) => void;
};
export const BookerOrdersSwitcher: FC<Props> = ({ switcherLabels, activeTab, onChange = emptyFunction }) => {
  return (
    <UiSwitcher
      className="booker-order-filter__switcher"
      tabsList={switcherLabels}
      defaultActiveTab={BookerOrderSwitcherStatus.All}
      onChange={onChange}
      activeTab={activeTab}
    />
  );
};
