import { PrepaymentForm, PrepaymentFormValues } from '@/types/models/prepayment';

export const formValuesToPrepayment = (values: PrepaymentFormValues): PrepaymentForm => {
  return {
    prepayment: values.prepayment,
    fuel: values?.fuel,
    fuelCompany: values.fuelCompany && {
      id: Number(values?.fuelCompany?.value),
      name: values.fuelCompany?.label,
    },
    paid: false,
  };
};
