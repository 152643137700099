import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthApi } from '@/api/auth';
import { ResponseError } from '@/types/api/response-error';
import { ResponseApi } from '@/types/response';
import { handleAxiosError } from '@/helpers/api/handle-axios-error';
import { RegisterRequest } from '@/types/api/credentials';
import { loginAction } from '@/features/account/actions/login-action';

export const registerAction = createAsyncThunk<ResponseApi, RegisterRequest, { rejectValue: ResponseError }>(
  'account/register',
  async (registerRequest, { rejectWithValue, dispatch }) => {
    try {
      const res = await AuthApi.register(registerRequest);
      dispatch(
        loginAction({
          login: registerRequest.phone,
          password: registerRequest.password,
        })
      );
      sessionStorage.clear();
      return res;
    } catch (err) {
      return handleAxiosError(err, rejectWithValue);
    }
  }
);
