import { useQuery } from '@tanstack/react-query';
import { MyOrganizationsApi } from '@/api/my-organizations';

export const useOrganization = (id?: number) => {
  return useQuery({
    queryKey: ['organization', id],
    refetchOnWindowFocus: false,
    enabled: !!id,
    queryFn: () => MyOrganizationsApi.getOrganization(id!),
  });
};

export const useOrganizationBanking = (id?: number): any => {
  return useQuery({
    queryKey: ['organizationBanking', id],
    refetchOnWindowFocus: false,
    enabled: !!id,
    queryFn: () => MyOrganizationsApi.getOrganizationBanking(id!),
  });
};
