export const INN_MASK = '************';
export const PASSPORT_MASK = '**** ******';
export const LICENSE_MASK = '** ** ******';
export const BANK_ACCOUNT_MASK = '********************';

export enum FormFieldName {
  accountRole = 'accountRole',
  target = 'target',
  code = 'code',
  phone = 'phone',
  password = 'password',
}

export enum FormFieldPlaceholder {
  code = '9999',
  accountRole = 'Выберите роль',
}
