import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { MyOrganizationType } from '@/types/models/my-organization/my-organization';
import { VatType } from '@/config/constants/types';
import { vatOptions } from '@/config/constants/vat-options';
import { getSelectOptionValue } from '@/helpers/select-options/get-select-option-form-value';

export const myOrganizationsToFormValues = ({
  organization,
  organizationTypes,
}: {
  organization: MyOrganizationType;
  organizationTypes?: Array<SelectOption>;
}) => {
  return {
    ...organization,
    vatType: getSelectOptionValue<VatType>(organization?.vatType, vatOptions),
    organizationTypeID: getSelectOptionValue(organization?.organizationTypeID, organizationTypes),
  };
};
