import { FC, memo } from 'react';
import { FormSpy } from 'react-final-form';
import './carrier-filter.scss';
import { CarrierFilterForm } from './carrier-filter-form';
import { useForm } from '@/helpers/forms/use-form';
import { CarrierFilterBottom } from './carrier-filter-bottom';
import { emptyFunction } from '@/helpers/empty-function';
import { FormAutosave } from '@/hooks/use-autosave';
import { FormFields } from '@/features/carriers/types/carrier-filter';
import { SortType } from '@/config/constants/types';

type Props = {
  onFilterChange: (values: FormFields) => void;
  onChangeSorting: (sortType: SortType) => void;
  allFound?: number;
};

const CarrierFilterAccordionPanel: FC<Props> = ({ onFilterChange = emptyFunction, onChangeSorting = emptyFunction, allFound = 0 }) => {
  const Form = useForm<FormFields>();

  return (
    <Form onSubmit={emptyFunction}>
      <FormSpy subscription={{ values: true }}>{({ values }) => <FormAutosave values={values} onSave={onFilterChange} />}</FormSpy>
      <CarrierFilterForm />
      <CarrierFilterBottom foundAmount={allFound} onChangeSorting={onChangeSorting} />
    </Form>
  );
};
// memo чтобы форма не обновлялась и не сбрасывала поля при каждом изменении state фильтров
export default memo(CarrierFilterAccordionPanel);
