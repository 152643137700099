import { MyOrganizationsItem } from 'components/my-organizations/my-organizations-item';
import plus from 'public/assets/icons/AddIcon.svg';
import { SPACING } from 'components/booker-orders/booker-orders-list/constants';
import { Box, Flex } from '@chakra-ui/react';
import './my-organizations.scss';
import _ from 'lodash';
import { UiButtonOutlined } from '@/ui-elements';
import { useMyOrganizations } from '@/views/my-organizations/use-my-organizations';

export const MyOrganizationsView = () => {
  const { hasEditRight, setModalOpened, record, handleMyOrganizationsClick, activeMyOrganizationsId, addOrganizationModal } = useMyOrganizations();
  if (!record) return null;

  // NOTE: добавлено органичение на создание организаций - 1 организация для перевозчика,
  // так как отложили разработку выбора организаций для Моих ресурсов
  return (
    <Flex alignItems="end" flexDirection="column" className="my-organizations-view" mb={SPACING} mt="16px" pl={SPACING} pr={SPACING}>
      {hasEditRight && (
        <UiButtonOutlined onClick={() => setModalOpened(true)} leftIcon={plus} isDisabled={record.length >= 1}>
          Добавить организацию
        </UiButtonOutlined>
      )}
      {_.isArray(record) &&
        record.map((organization) => (
          <Box mt="12px" w="100%" key={`${organization.id}`}>
            <MyOrganizationsItem
              data={organization}
              selected={activeMyOrganizationsId === organization.id}
              handleMyOrganizationsClick={handleMyOrganizationsClick}
            />
          </Box>
        ))}
      {addOrganizationModal}
    </Flex>
  );
};
