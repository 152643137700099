import { Center, Divider, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import classNames from 'classnames';
import { LoadingType } from 'config/constants/loading-types';
import { LoadingTypeComponent } from 'components/loading-type/loading-type';
import { ProgressBoxBar } from 'components/progress-box-bar/progress-box-bar';
import { useIcons } from '@/hooks/use-icons';
import './order-general-info.scss';
import { UiTooltip } from '@/ui-elements/tooltip/tooltip';
import { ShippedTotals } from '@/types/models/shipped-totals';
import { useVehicleTypesNames } from '@/features/vehicle-types/hooks/use-vehicle-types-names';
import { useVehicleTypesIcons } from '@/features/vehicle-types/hooks/use-vehicle-types-icons';
import { UiText } from '@/ui-elements/ui-text/ui-text';
import { Order } from '@/types/models/order';
import { Load, isLoad } from '@/types/models/loads';
import { useAppSelector } from '@/hooks/use-app-selector';
import { activeOrderSelector } from '@/features/order/selectors/active-order-selector';
import { ActiveProgressBar } from '@/components/progress-box-bar/active-progress-bar';
import { formatMass } from '@/helpers/format-mass';

type Props = {
  loadingType?: Array<LoadingType>;
  shippedTotals?: ShippedTotals;
  vehicleTypes?: Array<number>;
  className?: string;
  hasProgressBar?: boolean;
  order?: Order | Load;
};

export const OrderGeneralInfo: FC<Props> = ({ order, hasProgressBar, shippedTotals, vehicleTypes, className, loadingType = [] }) => {
  const totalVolume = shippedTotals?.totalVolume;
  const totalMass = shippedTotals?.totalMass;
  const vehicleTypesNames = useVehicleTypesNames();
  const vehicleTypesIcons = useVehicleTypesIcons();
  // @ts-ignore
  const vehicleIcon = vehicleTypesIcons[vehicleTypes?.[0]];
  const { TonageIcon, VolumeIcon } = useIcons();
  const isMultiple = (vehicleTypes?.length || 0) > 1;
  const orderId = useAppSelector(activeOrderSelector);

  return (
    <HStack className={classNames(className, 'order-general-info')}>
      <HStack className="order-general-info__vehicle">
        {!isMultiple && vehicleIcon && <img width="48px" height="20px" src={vehicleIcon} alt="vehicle img" />}
        <UiTooltip label={vehicleTypes?.map((vehicleType) => vehicleTypesNames[vehicleType]).join(', ') || 'не указан'}>
          <UiText color="products.200">Тип ТС</UiText>
        </UiTooltip>

        <Center className="order-general-info__divider-container">
          <Divider className="order-general-info__divider" orientation="vertical" />
        </Center>
        <LoadingTypeComponent loadingType={loadingType} />
      </HStack>

      {totalMass && (
        <>
          <Center className="order-general-info__divider-container">
            <Divider className="order-general-info__divider" orientation="vertical" />
          </Center>
          <HStack className="order-general-info__tonage">
            <TonageIcon />
            <Text>{formatMass(totalMass)}</Text>
          </HStack>
        </>
      )}
      {totalVolume && (
        <>
          <Center className="order-general-info__divider-container">
            <Divider className="order-general-info__divider" orientation="vertical" />
          </Center>
          <HStack className="order-general-info__volume">
            <VolumeIcon />
            <Text>{`${totalVolume} м³`}</Text>
          </HStack>
        </>
      )}
      {/* Note: ProgressBar не используется с Load, проверка типа для typescript */}
      {/* {hasProgressBar && !isLoad(order) && (
        <>
          <Center className="order-card__information-divider__container">
            <Divider className="order-card__information-divider" orientation="vertical" />
          </Center>
          {order?.id !== orderId && <ProgressBoxBar order={order} />}
          {order?.id === orderId && <ActiveProgressBar id={orderId} />}
        </>
      )} */}
    </HStack>
  );
};
