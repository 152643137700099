import { FC } from 'react';
import { CANCEL } from 'config/constants/common-strings';
import { SET_EXECUTOR } from 'components/set-carrier-widget-content/texts';
import { UiButtonFilled, UiButtonOutlined } from '@/ui-elements';
import { emptyFunction } from '@/helpers/empty-function';

export const SetCarrierButtons: FC<{ onClickSet: typeof emptyFunction; onClickCancel: VoidFunction }> = ({ onClickSet, onClickCancel }) => (
  <div className="set-carrier__buttons">
    <UiButtonOutlined mode="dark" onClick={onClickCancel}>
      {CANCEL}
    </UiButtonOutlined>
    <UiButtonFilled mode="dark" onClick={onClickSet}>
      {SET_EXECUTOR}
    </UiButtonFilled>
  </div>
);
