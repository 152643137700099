import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VehicleResponseType } from '@/types/models/vehicle-response';

const initialState = {
  vehicleTypes: [] as Array<VehicleResponseType>,
};

const slice = createSlice({
  name: 'vehicleTypes',
  initialState,
  reducers: {
    saveInStore: (state, action: PayloadAction<Array<VehicleResponseType>>) => {
      const withGroup = action.payload.filter((item) => item.isGroup);
      const withoutGroup = action.payload.filter((item) => !item.isGroup);
      const { types } = withoutGroup[0];
      state.vehicleTypes = [...withGroup, ...types] as Array<VehicleResponseType>;
    },
  },
});

export const vehicleTypesReducers = slice.reducer;
export const vehicleTypesActions = slice.actions;
