import { FC } from 'react';
import classNames from 'classnames';
import { UiButton, UiButtonProps } from '@/ui-elements/button/button';
import './button-text.scss';

type Props = UiButtonProps;

export const UiTextButton: FC<Props> = ({ leftIcon, rightIcon, ...props }) => {
  const hasIcon = leftIcon || rightIcon;

  return (
    <UiButton
      {...props}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      className={classNames('ui-button-text', { 'ui-button-text_underlined': !hasIcon })}
    />
  );
};
