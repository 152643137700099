import { FC } from 'react';
import { HStack } from '@chakra-ui/react';
import { VerificationFormMode } from 'components/verification-forms/types';
import { UiButtonFilled, UiButtonOutlined } from '@/ui-elements';

export const DriverButtonsSubform: FC<{ mode: VerificationFormMode; onClick: any; submitting?: boolean }> = ({ mode, onClick, submitting }) => {
  return (
    <HStack justifyContent="right" mr={mode === 'check' ? 2 : 0}>
      <UiButtonOutlined
        className="carriers-verification-form__btn-decline"
        mode={mode === 'check' ? 'light' : 'dark'}
        onClick={onClick}
        w={mode === 'check' ? 128 : 205}
      >
        {mode === 'check' ? 'Отклонить' : `Отменить ${mode === 'edit' ? 'редактирование' : 'добавление'}`}
      </UiButtonOutlined>
      <UiButtonFilled
        className="carriers-verification-form__btn-confirm"
        mode={mode === 'check' ? 'dark' : 'light'}
        type="submit"
        w={mode === 'check' ? 200 : 176}
        isDisabled={submitting}
        isLoading={submitting}
      >
        {mode === 'check' ? 'Принять' : 'На проверку в СБ'}
      </UiButtonFilled>
    </HStack>
  );
};
