import { FC } from 'react';
import classNames from 'classnames';
import { OrderPoint } from 'components/order-point/order-point';
import { Icon, HStack, VStack } from '@chakra-ui/react';
import { Point } from '@/types/models/point';
import './order-point-with-icon.scss';
import { UiSelect, UiTextButton } from '@/ui-elements';
import { options, StatusesNames } from '@/components/order-point/options';
// import { useAccount } from '@/features/account/hooks/use-account';
import './select.scss';
import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';
import { UiTag } from '@/ui-elements/ui-tag/ui-tag';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { useOrderPointWithIcon } from './use-order-point-with-icon';
import { useAppSelector } from '@/hooks/use-app-selector';
import { cargoTypesSelector } from '@/features/cargo/selectors/cargo-types-selector';

type Props = {
  point: Point;
  isLastPoint?: boolean;
  order: Order | Load;
};

/**
 * Точка маршрута с иконками погрузки разгрузки и вертикальной стрелкой
 * */
export const OrderPointWithIcon: FC<Props> = ({ point, order, isLastPoint = false }) => {
  const cargoTypes = useAppSelector(cargoTypesSelector);

  const {
    extendedPoint,
    UnloadIcon,
    LoadIcon,
    LongArrowIcon,
    orderStatus,
    setStatus,
    onPostComments,
    onEvaluateDocuments,
    statusIcon,
    onUploadDocuments,
    ModalDocuments,
    UploadDocuments,
    PostComments,
  } = useOrderPointWithIcon(order, point, cargoTypes);

  return (
    <div className="order-point__container">
      <div className="order-point__icons">
        <div className="order-point__type_icons">
          {extendedPoint.isUnloading && <Icon as={UnloadIcon} />}
          {extendedPoint.isLoading && <Icon as={LoadIcon} />}
        </div>
        <div>{!isLastPoint && <Icon as={LongArrowIcon} />}</div>
      </div>
      <HStack spacing={0} justify="space-between" w="full">
        <OrderPoint point={extendedPoint.point} order={order} isLastPoint={isLastPoint} cargoTypes={cargoTypes} />
        <VStack spacing={0} align="end">
          {orderStatus === 'executing' && (
            <UiSelect
              isClearable={false}
              options={options as Array<SelectOption & { value: null }>}
              value={
                {
                  value: point?.status,
                  label: point?.status ? `${StatusesNames[point.status]}` : '',
                } as any
              }
              defaultValue={{ value: null, label: 'Без статуса' } as any}
              className="point-status-select"
              placeholder=""
              onChange={setStatus as any}
              isSearchable={false}
            />
          )}
          {point?.status && orderStatus !== 'executing' && (
            <UiTag className={classNames({ [`order-point__status_${point.status}`]: true })}>{`${StatusesNames[point.status]}`}</UiTag>
          )}
          {orderStatus && ['new', 'carrierSearch', 'preparation', 'confirmed', 'executing'].includes(orderStatus) && (
            <UiTextButton onClick={onPostComments}>Оставить комментарий</UiTextButton>
          )}
          {orderStatus === 'executing' && (
            <>
              <HStack spacing="10px">
                <UiTextButton onClick={onEvaluateDocuments}>Документы по точке</UiTextButton>
                {statusIcon}
              </HStack>
              <UiTextButton onClick={onUploadDocuments}>Загрузить документы</UiTextButton>
            </>
          )}
        </VStack>
      </HStack>
      {ModalDocuments}
      {UploadDocuments}
      {PostComments}
    </div>
  );
};
