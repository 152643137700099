import './ui-divider.scss';
import { Divider, DividerProps } from '@chakra-ui/react';
import classNames from 'classnames';
import { UiDividerCentered } from '@/ui-elements/ui-divider/ui-divider-centered';

type Props = DividerProps & {
  centered?: boolean;
};

export const UiDivider = ({ orientation, centered = false, className, ...props }: Props) => {
  if (centered) {
    return <UiDividerCentered {...props} orientation={orientation || 'vertical'} />;
  }

  return <Divider {...props} className={classNames('ui-divider', className)} orientation={orientation} />;
};
