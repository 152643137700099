import './carrier-organization-card.scss';
import { MouseEventHandler } from 'react';
import { HStack, VStack, Text, Box, StackProps, Flex } from '@chakra-ui/react';
import edit from 'public/assets/icons/EditIcon.svg';
import { OrganisationRating } from 'components/organisation-rating/organisation-rating';
import { useIcons } from '@/hooks/use-icons';
import { UiButtonOutlined, UiButtonFilled } from '@/ui-elements';
import { Carrier } from '@/types/models/carrier';
import { emptyFunction } from '@/helpers/empty-function';
import { getCarrierOrganizationINN } from '@/features/carriers/helpers/get-carrier-organization-inn';

type Props = {
  carrier: Carrier;
  status: string;
  showCarriersReviews?: (carrierId: number) => void;
  onEditClick?: VoidFunction;
  hideRating?: boolean;
  handleDeleteCarrier?: MouseEventHandler<HTMLButtonElement>;
} & StackProps;

export const CarrierOrganizationCard = ({
  carrier,
  status,
  showCarriersReviews = emptyFunction,
  onEditClick = emptyFunction,
  handleDeleteCarrier = emptyFunction,
  hideRating = false,
}: Props) => {
  const { name, department, status: carrierStatus, id } = carrier;
  const carrierOrganizationINN = getCarrierOrganizationINN(carrier);
  const { HelpIcon, DeleteIcon } = useIcons();

  return (
    <HStack>
      <VStack m={0} align="start" flex={1}>
        <Text textStyle="h2">{name}</Text>
        <Text textStyle="h5" color="typography.50" pt={1}>
          {`ИНН: ${carrierOrganizationINN}`}
        </Text>
        <Text textStyle="h5" color="typography.100" pt={1}>
          {department.name}
        </Text>
        <Text textStyle="h5" color="products.200" pt={1} pb={6}>
          {status}
        </Text>
        <Flex gap="6px">
          <UiButtonOutlined mode="dark" leftIcon={edit} onClick={onEditClick}>
            Редактировать данные
          </UiButtonOutlined>
          <Box className="carrier-organization-card__help">
            <HelpIcon />
          </Box>
          <UiButtonOutlined mode="dark" leftIcon={DeleteIcon} onClick={handleDeleteCarrier}>
            Удалить организацию
          </UiButtonOutlined>
        </Flex>
      </VStack>
      {!hideRating ? (
        <HStack className="carrier-organization-card__button-group">
          <OrganisationRating ratingType={carrierStatus} className="carrier-organization-card__tag" />
          <UiButtonFilled onClick={() => showCarriersReviews(id)}>Смотреть отзывы</UiButtonFilled>
        </HStack>
      ) : null}
    </HStack>
  );
};
