import { FC } from 'react';
import { Routes } from 'config/routes/routes';
import { useCarriersLoadsOrderSidebar } from '@/features/carriers-loads/hooks/use-carriers-loads-order-sidebar';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';

export const OrdersWithMyParticipationSidebarView: FC = () => {
  const { onOrderStart, SidebarContent, closeSidebar, order, isInitialLoading } = useCarriersLoadsOrderSidebar(Routes.OrdersWithMyParticipation);

  if (!SidebarContent) {
    return null;
  }

  if (isInitialLoading) {
    return <UiSpinner visible mt={48} />;
  }

  return <SidebarContent order={order} closeSidebar={closeSidebar} onOrderStart={onOrderStart} />;
};
