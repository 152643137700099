import { EMPTY_STRING } from 'config/constants/common-strings';
import { showUserDefaultError } from '@/helpers/errors/show-user-default-error';

export const normalizeTemplateString = (string: string): string => {
  try {
    return string.replaceAll('undefined', EMPTY_STRING).trim();
  } catch (error) {
    showUserDefaultError({ error });
    return string;
  }
};
