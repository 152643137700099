import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { SidebarMode } from 'components/sidebar-wrapper/constants';
import { CarriersLoadsState } from '@/features/carriers-loads/types/carriers-loads-state';
import { CarrierLoadsPageWithOrders } from '@/features/carriers-loads/hooks/use-carriers-loads-actions';

export const setSidebarMode = (state: Draft<CarriersLoadsState>, action: PayloadAction<{ mode: SidebarMode; page: CarrierLoadsPageWithOrders }>) => {
  const { mode, page } = action.payload;
  if (!mode) {
    state[page].sidebarMode = SidebarMode.None;
  } else {
    state[page].sidebarMode = mode;
  }
};
