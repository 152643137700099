import './funnel-back-forward-button.scss';
import { FC } from 'react';
import classNames from 'classnames';
import { useIcons } from '@/hooks/use-icons';
import { EMPTY_STRING } from '@/config/constants/common-strings';
import { ButtonDirection } from '@/types/models/funnel';
import { UiButtonFilled, UiButtonOutlined } from '@/ui-elements';

interface Props {
  title?: string;
  buttonDirection?: ButtonDirection;
  disabled: boolean;
  className?: string;
  onClickGoBack?: VoidFunction;
  onClickGoForward?: VoidFunction;
}

export const FunnelBackForwardButton: FC<Props> = ({
  title = EMPTY_STRING,
  buttonDirection,
  disabled,
  className,
  onClickGoBack,
  onClickGoForward,
}) => {
  const { RightArrow } = useIcons({ width: '40', height: '14' });
  const directionBack = buttonDirection === ButtonDirection.Back;

  return directionBack ? (
    <UiButtonOutlined
      className={classNames('funnel-back-forward-button funnel-back-forward-button_back', { hidden: !title }, className)}
      onClick={onClickGoBack}
      mode="light"
      disabled={disabled}
    >
      <RightArrow />
      {title}
    </UiButtonOutlined>
  ) : (
    <UiButtonFilled
      className={classNames('funnel-back-forward-button funnel-back-forward-button_forward', { hidden: !title }, className)}
      onClick={onClickGoForward}
      disabled={disabled}
    >
      {title}
      {buttonDirection && <RightArrow />}
    </UiButtonFilled>
  );
};
