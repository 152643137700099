import { isEmpty } from 'lodash';
import { Cargos } from '@/types/models/cargos';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { getCargoTypeLabel } from '@/features/funnel/helpers/get-cargo-type-label';
import { EMPTY_STRING } from '@/config/constants/common-strings';

export const getCargoOptions = (cargoTypes: Array<SelectOption>, cargos?: Array<Cargos>) => {
  if (isEmpty(cargos) || isEmpty(cargoTypes)) return undefined;

  return cargos?.map((cargo, index) => {
    const cargoType = getCargoTypeLabel(cargoTypes, cargo);
    const massVolume = `${cargo.properties.Mass}/${cargo.properties.Volume}`;

    return {
      value: cargo.tenderLotPositionID || EMPTY_STRING,
      label: `Груз ${index + 1} ${cargoType} ${massVolume}`,
    };
  });
};
