import { UiStatus } from '@/ui-elements/status/status';

type Props = {
  accidentsCount?: number;
};

export const AccidentsStatus = ({ accidentsCount = 0 }: Props) => {
  let bgColor;
  let textColor;
  let content = '';
  switch (accidentsCount) {
    case 0:
      bgColor = 'accentGreen.150';
      textColor = 'typography.250';
      content = 'Аварий не было';
      break;
    case 1:
    case 2:
      bgColor = 'accentYellow.100';
      textColor = 'typography.50';
      content = `Было аварий: ${accidentsCount}`;
      break;
    default:
      bgColor = 'accentRed.100';
      textColor = 'typography.250';
      content = `Было аварий: ${accidentsCount}`;
  }
  return <UiStatus backGroundColor={bgColor} color={textColor} text={content} />;
};
