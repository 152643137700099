import { FunnelLoadStatus } from '@/types/models/funnel';
import { OrderExecutionStatus, OrderStatus } from './statuses';

export const OrderStatusColorScheme: Record<OrderStatus | FunnelLoadStatus, { background: string; color: string }> = {
  [OrderExecutionStatus.New]: { background: 'white', color: 'typography.250' },
  [OrderExecutionStatus.CarrierSearch]: { background: 'accentYellow.100', color: 'typography.50' },
  [OrderExecutionStatus.Preparation]: { background: 'products.100', color: 'typography.250' },
  [OrderExecutionStatus.Confirmed]: { background: 'accentGreen.150', color: 'typography.250' },
  [OrderExecutionStatus.Executing]: { background: 'accentYellow.150', color: 'typography.50' },
  [OrderExecutionStatus.CompletedWithoutDocs]: { background: 'accentGreen.150', color: 'typography.250' },
  [OrderExecutionStatus.CompletedWithDocs]: { background: 'accentGreen.150', color: 'typography.250' },
  [OrderExecutionStatus.CompletedPayed]: { background: 'accentGreen.150', color: 'typography.250' },
  [OrderExecutionStatus.Failed]: { background: 'accentRed.100', color: 'typography.250' },
  [OrderExecutionStatus.Troubling]: { background: 'accentRed.100', color: 'typography.250' },
  [OrderExecutionStatus.Completed]: { background: 'accentGreen.150', color: 'typography.250' },
  [FunnelLoadStatus.CostCalculated]: { background: 'white', color: 'typography.250' },
  [FunnelLoadStatus.CostCalculation]: { background: 'white', color: 'typography.250' },
  [FunnelLoadStatus.CostConfirmed]: { background: 'accentGreen.150', color: 'typography.250' },
  [FunnelLoadStatus.CostCanceled]: { background: 'accentRed.100', color: 'typography.250' },
  [FunnelLoadStatus.NewCalculation]: { background: 'white', color: 'typography.250' },
  [FunnelLoadStatus.SentCostToClient]: { background: 'white', color: 'typography.250' },
};

export const ORDER_STATUS_SCHEME: Record<
  OrderStatus | FunnelLoadStatus,
  {
    text: string;
    backgroundColor: string;
    color: string;
  }
> = {
  [OrderExecutionStatus.New]: {
    text: `Новая`,
    backgroundColor: 'white',
    color: 'typography.250',
  },
  [OrderExecutionStatus.CarrierSearch]: {
    text: `Ожидает подтверждения`,
    backgroundColor: 'accentYellow.100',
    color: 'typography.50',
  },
  [OrderExecutionStatus.Preparation]: {
    text: 'На оформлении',
    backgroundColor: 'products.100',
    color: 'typography.250',
  },
  [OrderExecutionStatus.Confirmed]: {
    text: 'Оформлено',
    backgroundColor: 'accentGreen.150',
    color: 'typography.250',
  },
  [OrderExecutionStatus.Executing]: {
    text: 'Рейс выполняется',
    backgroundColor: 'accentYellow.150',
    color: 'typography.50',
  },
  [OrderExecutionStatus.CompletedWithoutDocs]: {
    text: 'Рейс выполнен',
    backgroundColor: 'accentGreen.150',
    color: 'typography.250',
  },
  [OrderExecutionStatus.CompletedWithDocs]: {
    text: 'Рейс выполнен',
    backgroundColor: 'accentGreen.150',
    color: 'typography.250',
  },
  [OrderExecutionStatus.CompletedPayed]: {
    text: 'Рейс выполнен',
    backgroundColor: 'accentGreen.150',
    color: 'typography.250',
  },
  [OrderExecutionStatus.Failed]: {
    text: 'Рейс сорван',
    backgroundColor: 'accentRed.100',
    color: 'typography.250',
  },
  [OrderExecutionStatus.Troubling]: {
    text: 'Возникли проблемы',
    backgroundColor: 'accentRed.100',
    color: 'typography.250',
  },
  [OrderExecutionStatus.Completed]: {
    text: 'Рейс выполнен',
    backgroundColor: 'accentGreen.150',
    color: 'typography.250',
  },
  [FunnelLoadStatus.CostCalculated]: {
    text: ``,
    backgroundColor: 'white',
    color: 'typography.250',
  },
  [FunnelLoadStatus.CostCalculation]: {
    text: ``,
    backgroundColor: 'white',
    color: 'typography.250',
  },
  [FunnelLoadStatus.CostConfirmed]: {
    text: ``,
    backgroundColor: 'accentGreen.150',
    color: 'typography.250',
  },
  [FunnelLoadStatus.CostCanceled]: {
    text: ``,
    backgroundColor: 'accentRed.100',
    color: 'typography.250',
  },
  [FunnelLoadStatus.NewCalculation]: {
    text: ``,
    backgroundColor: 'white',
    color: 'typography.250',
  },
  [FunnelLoadStatus.SentCostToClient]: {
    text: ``,
    backgroundColor: 'white',
    color: 'typography.250',
  },
};
