import { FC } from 'react';
import { CARRIER_SEARCH_BUTTON_CAPTION, SET_CARRIER_TO_VIEW } from 'components/order-details/order-details-empty-carrier/texts';
import { UiButtonFilled } from '@/ui-elements';
import './order-details-empty-carrier.scss';

export const OrderDetailsEmptyCarrier: FC<{ onCarrierSearchButtonClick: VoidFunction }> = ({ onCarrierSearchButtonClick }) => (
  <div className="order-details-empty-carrier">
    <div className="order-details-empty-carrier__body">
      <span className="order-details-empty-carrier__text"> {SET_CARRIER_TO_VIEW}</span>
    </div>
    <div className="order-details-empty-carrier__actions">
      <UiButtonFilled onClick={onCarrierSearchButtonClick}>{CARRIER_SEARCH_BUTTON_CAPTION}</UiButtonFilled>
    </div>
  </div>
);
