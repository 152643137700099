export enum UserRole {
  Security = 'security',
  Manager = 'manager',
  Administrator = 'administrator',
  Booker = 'booker',
  Driver = 'driver',
  FuelManager = 'fuel',
  Guest = 'guest',
}

export enum UserRoleMap {
  'security' = 'Руководитель СБ',
  'manager' = 'Логист',
  'administrator' = 'Руководитель',
  'booker' = 'Бухгалтер',
  'driver' = 'Водитель',
  'fuel' = 'Топливный менеджер',
  'guest' = 'Гость',
}
