import { useQuery } from '@tanstack/react-query';
import { CustomersApi } from '@/api/customers';

export const useCustomer = (id?: number) => {
  const customer = useQuery({
    queryKey: ['customer', id],
    enabled: !!id,
    refetchOnWindowFocus: false,
    queryFn: () => CustomersApi.getCustomer(id!),
  });
  return { customer };
};
