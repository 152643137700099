import { FC } from 'react';
import { HStack } from '@chakra-ui/react';
import './organisation-rating.scss';
import classNames from 'classnames';
import { RatingType } from '@/config/constants/types';
import { RatingTypeNames } from '@/config/constants/texts';
import { organisationRatingStyle } from './organisation-rating-styles';
import { UiTag } from '@/ui-elements/ui-tag/ui-tag';

type Props = {
  ratingType?: RatingType;
  className?: string;
};

export const OrganisationRating: FC<Props> = ({ ratingType = RatingType.Gold, className, ...props }) =>
  ratingType && (
    <UiTag className={classNames('organisation-rating', className)} {...props}>
      {RatingTypeNames[ratingType]}
      <HStack background={organisationRatingStyle[ratingType]} className="organisation-rating__color" />
    </UiTag>
  );
