import { FC } from 'react';
import { isEmpty } from 'lodash';
import { Box, VStack } from '@chakra-ui/react';
import { Vehicle } from '@/types/models/vehicle';
import { InfiniteScroll } from '@/views/infinite-scroll/infinite-scroll';
import { FetchNextPageType } from '@/types/models/pager';
import { RESOURCES_NO_VEHICLES, ResourcesButtonActions } from '@/features/resources/constants/general';
import { ResourcesEmptyComponent } from '@/components/resources/empty-component/resources-empty-component';
import { ResourcesVehicleCard } from '@/components/resources/vehicle-card/resources-vehicle-card';

interface Props {
  vehicles: Array<Vehicle>;
  selectedVehicleId: number | null;
  isFetchingNextPage: boolean;
  showApprovalStatuses: boolean;
  fetchNextPage: FetchNextPageType<Vehicle>;
  handleSelectVehicle: (driverId: number) => void;
  handleCreateVehicle: VoidFunction;
}

export const ResourcesVehiclesList: FC<Props> = ({
  vehicles,
  selectedVehicleId,
  isFetchingNextPage,
  showApprovalStatuses,
  fetchNextPage,
  handleSelectVehicle,
  handleCreateVehicle,
}) => {
  return (
    <Box className="resources-list-wrapper">
      {!isEmpty(vehicles) ? (
        <InfiniteScroll callback={fetchNextPage} loading={isFetchingNextPage} skip={isEmpty(vehicles)}>
          <VStack className="resources-list-content">
            {vehicles?.map((vehicle) => (
              <ResourcesVehicleCard
                vehicle={vehicle}
                selectedVehicleId={selectedVehicleId}
                showApprovalStatuses={showApprovalStatuses}
                handleSelectVehicle={handleSelectVehicle}
                key={`${vehicle.id}`}
              />
            ))}
          </VStack>
        </InfiniteScroll>
      ) : (
        <ResourcesEmptyComponent text={RESOURCES_NO_VEHICLES} buttonText={ResourcesButtonActions.AddDVehicle} onClick={handleCreateVehicle} />
      )}
    </Box>
  );
};
