import './resources-documents-list.scss';
import { FC } from 'react';
import { isEmpty } from 'lodash';
import { Text, HStack, Box } from '@chakra-ui/react';
import { Document } from '@/types/models/contract';
import { UiFileUploadCard, Extension } from '@/ui-elements/inputs/ui-file-upload-input/ui-file-upload-card/ui-file-upload-card';
import { downloadFileDocument } from '@/helpers/api/download-file-document';
import { FileDocument } from '@/types/models/document';
import { DocumentTypes } from '@/config/constants/document-types';

interface Props {
  vehicleDocuments?: Array<Document>;
  documentSection: {
    title: string;
    types: DocumentTypes[];
  };
}

export const ResourcesDocumentsList: FC<Props> = ({ documentSection, vehicleDocuments }) => {
  const uploadedDocuments = vehicleDocuments?.filter((document) => documentSection.types.some((type) => type === document?.type));
  if (isEmpty(uploadedDocuments)) {
    return null;
  }

  return (
    <Box className="resources-documents-list">
      <Text className="resources-documents-list__subtitle">{documentSection?.title}</Text>
      <HStack className="resources-documents-list__content">
        {uploadedDocuments?.map((doc) => (
          <UiFileUploadCard
            fileName={doc?.name}
            extension={doc?.extension as Extension}
            onIconClick={() => {
              downloadFileDocument(doc as FileDocument);
            }}
            key={doc?.id}
            hideDeleteButton
          />
        ))}
      </HStack>
    </Box>
  );
};
