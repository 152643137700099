import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoadsApi } from '@/api/loads';
import { OrdersApi } from '@/api/orders';
import { OrderCreateSource } from '@/types/api/order-partial';

export const getLoad = createAsyncThunk('LOAD/GET', async (id: number, { rejectWithValue }) => {
  try {
    const { data } = await OrdersApi.getOrder(id, { fromSource: OrderCreateSource.ILSConfirmation });
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
