import { FC } from 'react';
import { isNil } from 'lodash';
import { SidebarWrapper } from 'components/sidebar-wrapper/sidebar-wrapper';
import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';
import { CompletedRouteDetails } from '@/components/carriers-loads/details/completed-route-details/completed-route-details';

type Props = {
  order: Order | Load | undefined;
  closeSidebar(): void;
};

export const CompletedRoutesSidebar: FC<Props> = ({ closeSidebar, order }) => {
  if (isNil(order)) return null;

  return (
    <SidebarWrapper closeSidebar={closeSidebar}>
      <CompletedRouteDetails order={order} />
    </SidebarWrapper>
  );
};
