import { useLocationTyped } from '@/hooks/use-location-typed';
import { useAppSelector } from '@/hooks/use-app-selector';
import { authenticatedSelector, authenticationPendingSelector, logoutPendingSelector } from '@/features/account/selectors';

export const useCheckAuth = () => {
  const authenticated = useAppSelector(authenticatedSelector);
  const authenticationPending = useAppSelector(authenticationPendingSelector);
  const logoutPending = useAppSelector(logoutPendingSelector);
  const location = useLocationTyped();
  const redirectTo = !authenticated ? undefined : location.state?.from?.pathname || '/';
  return {
    redirectTo,
    authenticated,
    authenticationPending,
    location,
    logoutPending,
  };
};
