import { FC } from 'react';
import './vehicles-list-compact.scss';
import { VehicleCardCompact } from 'components/vehicle-card-compact/vehicle-card-compact';
import classNames from 'classnames';
import { YOU_HAVE_CHOSEN } from 'components/security/drivers-compact-list/texts';
import { Vehicle } from '@/types/models/vehicle';
import { UiDivider } from '@/ui-elements';

type Props = {
  vehicles?: Array<Vehicle>;
  department?: string;
  className?: string;
  selectedVehicleId?: number;
  onVehicleMoreInfoClick?: (vehicle: Vehicle) => void;
};
export const VehiclesListCompact: FC<Props> = ({ vehicles, department, className, selectedVehicleId, onVehicleMoreInfoClick }) => {
  const selectedVehicle = vehicles?.find((x) => x.id === selectedVehicleId);
  return (
    <div className={classNames(className, 'vehicles-compact-list')}>
      {selectedVehicle && (
        <div className="vehicles-compact-list__block">
          <div className="vehicles-compact-list__title">{YOU_HAVE_CHOSEN}</div>
          <VehicleCardCompact
            vehicle={selectedVehicle}
            key={selectedVehicleId}
            department={department}
            className="vehicles-compact-list__selected"
            shippedTotals={{ totalVolume: selectedVehicle.volume, totalMass: selectedVehicle.capacity }}
            onMoreInfoClick={onVehicleMoreInfoClick}
          />
          <UiDivider orientation="horizontal" />
        </div>
      )}
      {vehicles
        ?.filter((x) => x.id !== selectedVehicleId)
        .map((vehicle) => (
          <VehicleCardCompact
            vehicle={vehicle}
            key={vehicle.id}
            department={department}
            className="vehicles-compact-list__item"
            shippedTotals={{ totalVolume: vehicle.volume, totalMass: vehicle.capacity }}
            onMoreInfoClick={onVehicleMoreInfoClick}
          />
        ))}
    </div>
  );
};
