import { useState } from 'react';
import { ModalReview } from '@/features/modals/modal-review';
import { ModalType } from '@/types/common/modals';
import { ModalSplit } from '@/features/modals/modal-split';
import { emptyFunction } from '@/helpers/empty-function';
import { ModalConfirm } from '@/features/modals/modal-confirm';
import { ModalForm } from '@/features/modals/modal-form';
import { DocumentsEvaluationModal } from '@/components/order-point/documents-evaluation-modal';
import { DocumentsUploadModal } from '@/components/order-point/documents-upload-modal';
import { PointCommentsModal } from '@/components/order-point/point-comments-modal';
import { ModalDecline } from '@/features/modals/modal-decline';
import { ModalEditCarrier } from '@/features/modals/carrier/modal-edit-carrier';
import { ModalCreateCarrier } from '@/features/modals/carrier/modal-create-carrier';
import { ModalEditDriver } from '@/features/modals/driver/modal-edit-driver';
import { ModalEditVehicle } from '@/features/modals/vehicle/modal-edit-vehicle';
import { ModalDeleteOrder } from '@/features/modals/modal-delete-order';
import { ModalDeleteDriver } from '@/features/modals/carrier/modal-delete-driver';
import { ModalDeleteVehicle } from '@/features/modals/carrier/modal-delete-vehicle';
import { ModalDeleteCarrier } from '@/features/modals/carrier/modal-delete-carrier';
import { ModalDocumentTemplate } from '@/components/document-template-viewer/document-template-viewer-modal';
import { ModalOrganizationView } from '@/features/modals/organization/modal-organization-view';
import { ModalCloneOrder } from '@/features/modals/modal-clone-order';
import { ModalCloseUpdateOrder } from '@/features/modals/modal-close-update-order';
import { ModalCloseUpdateLoad } from '@/features/modals/modal-close-update-load';
import { ModalDeleteLoad } from '@/features/modals/modal-delete-load';
import { ModalEditNotify } from '@/features/modals/carrier/modal-edit-notify';
import { ModalCreateCarrierOffer } from '@/features/modals/carrier-order/modal-carrier-create-offer';
import { ModalResetPassword } from '@/features/modals/driver/modal-reset-password';
import { ModalPrepayment } from '@/features/modals/modal-prepayment';

export type ModalAction = {
  onCloseModal?: VoidFunction;
  onSuccess?: VoidFunction;
  onCancel?: VoidFunction;
  onSubmit?: (values: any) => void;
  submitButtonText?: string;
};

export const useModal = <T extends ModalAction>(type: ModalType, props: T) => {
  const [modalOpened, setModalOpened] = useState(false);
  const { onCloseModal = emptyFunction, submitButtonText, ...rest } = props;
  const onClose = () => {
    onCloseModal();
    setModalOpened(false);
  };
  let modalView;

  if (!modalOpened) {
    modalView = null;
  } else {
    switch (type) {
      case ModalType.Prepayment:
        modalView = <ModalPrepayment modalOpened={modalOpened} onClose={onClose} {...rest} />;
        break;
      case ModalType.Review:
        modalView = <ModalReview modalOpened={modalOpened} onClose={onClose} {...rest} />;
        break;
      case ModalType.Split:
        modalView = <ModalSplit modalOpened={modalOpened} onClose={onClose} {...rest} />;
        break;
      case ModalType.Confirm:
        modalView = <ModalConfirm modalOpened={modalOpened} onClose={onClose} {...rest} />;
        break;
      case ModalType.Form:
        modalView = <ModalForm modalOpened={modalOpened} onClose={onClose} {...rest} />;
        break;
      case ModalType.Decline:
        modalView = <ModalDecline modalOpened={modalOpened} onClose={onClose} {...rest} />;
        break;
      case ModalType.EditCarrier:
        modalView = <ModalEditCarrier modalOpened={modalOpened} onClose={onClose} {...rest} />;
        break;
      case ModalType.EditDriver:
        modalView = <ModalEditDriver modalOpened={modalOpened} onClose={onClose} {...rest} />;
        break;
      case ModalType.EditVehicle:
        modalView = <ModalEditVehicle modalOpened={modalOpened} onClose={onClose} {...rest} />;
        break;
      case ModalType.Documents:
        modalView = <DocumentsEvaluationModal modalOpened={modalOpened} onClose={onClose} {...rest} />;
        break;
      case ModalType.Upload:
        modalView = <DocumentsUploadModal modalOpened={modalOpened} onClose={onClose} {...rest} />;
        break;
      case ModalType.Comments:
        modalView = <PointCommentsModal modalOpened={modalOpened} onClose={onClose} {...rest} />;
        break;
      case ModalType.CreateCarrier:
        modalView = <ModalCreateCarrier modalOpened={modalOpened} onClose={onClose} {...rest} />;
        break;
      case ModalType.DeleteOrder:
        modalView = <ModalDeleteOrder modalOpened={modalOpened} onClose={onClose} desc="Все данные будут удалены" alignDesc="left" {...rest} />;
        break;
      case ModalType.DeleteDriver:
        modalView = <ModalDeleteDriver modalOpened={modalOpened} onClose={onClose} desc="Все данные будут удалены" alignDesc="left" {...rest} />;
        break;
      case ModalType.DeleteVehicle:
        modalView = <ModalDeleteVehicle modalOpened={modalOpened} onClose={onClose} desc="Все данные будут удалены" alignDesc="left" {...rest} />;
        break;
      case ModalType.DeleteCarrier:
        modalView = <ModalDeleteCarrier modalOpened={modalOpened} onClose={onClose} desc="Все данные будут удалены" alignDesc="left" {...rest} />;
        break;
      case ModalType.DocumentTemplate:
        modalView = <ModalDocumentTemplate modalOpened={modalOpened} onClose={onClose} {...rest} />;
        break;
      case ModalType.OrganizationModal:
        modalView = <ModalOrganizationView modalOpened={modalOpened} onClose={onClose} {...rest} />;
        break;
      case ModalType.CloneOrder:
        modalView = <ModalCloneOrder modalOpened={modalOpened} onClose={onClose} {...rest} />;
        break;
      case ModalType.CloseUpdateOrder:
        modalView = (
          // @ts-ignore
          <ModalCloseUpdateOrder modalOpened={modalOpened} onClose={onClose} desc="Все изменения будут утрачены" alignDesc="left" {...rest} />
        );
        break;
      case ModalType.CloseUpdateLoad:
        modalView = (
          // @ts-ignore
          <ModalCloseUpdateLoad modalOpened={modalOpened} onClose={onClose} desc="Все изменения будут утрачены" alignDesc="left" {...rest} />
        );
        break;
      case ModalType.DeleteLoad:
        modalView = <ModalDeleteLoad modalOpened={modalOpened} onClose={onClose} desc="Все данные будут удалены" alignDesc="left" {...rest} />;
        break;
      case ModalType.EditNotify:
        modalView = <ModalEditNotify modalOpened={modalOpened} onClose={onClose} alignDesc="left" {...rest} />;
        break;
      case ModalType.CreateCarrierOffer:
      case ModalType.ChooseOwnDriverVehicle:
        modalView = <ModalCreateCarrierOffer modalOpened={modalOpened} onClose={onClose} submitButtonText={submitButtonText} {...rest} />;
        break;
      case ModalType.ResetPassword:
        modalView = <ModalResetPassword modalOpened={modalOpened} onClose={onClose} desc="Все данные будут удалены" alignDesc="left" {...rest} />;
        break;
      default:
        break;
    }
  }
  return {
    modalView,
    setModalOpened,
    modalOpened,
  };
};
