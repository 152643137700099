import {
  ButtonDirection,
  FunnelCount,
  FunnelLoadStatus,
  FunnelPaymentDocsFormFields,
  FunnelPaymentDocsFormValues,
  FunnelSwitcherStatus,
  LogisticsLoadStatus,
  RouteType,
} from '@/types/models/funnel';

export const FunnelSwitcherTitles = {
  [FunnelSwitcherStatus.NewCalculation]: 'Новые',
  [FunnelSwitcherStatus.CostCalculation]: 'Расчет стоимости',
  [FunnelSwitcherStatus.CostCalculated]: 'Запрос рассчитан',
  [FunnelSwitcherStatus.SentCostToClient]: 'Отправлено клиенту',
  [FunnelSwitcherStatus.CostConfirmed]: 'Согласовано',
  [FunnelSwitcherStatus.CostCanceled]: 'Отклонено',
};

export const FunnelLoadStatusTitles = {
  [FunnelLoadStatus.NewCalculation]: 'Новая',
  [FunnelLoadStatus.CostCalculation]: 'Расчет стоимости',
  [FunnelLoadStatus.CostCalculated]: 'Запрос рассчитан',
  [FunnelLoadStatus.SentCostToClient]: 'Отправлено клиенту',
  [FunnelLoadStatus.CostConfirmed]: 'Согласовано',
  [FunnelLoadStatus.CostCanceled]: 'Отклонено',
};

export const FunnelLogisticsStatusTitles = {
  [LogisticsLoadStatus.New]: 'Новая',
  [LogisticsLoadStatus.IsProcessed]: 'На оформлении',
};

export const RouteTypeTitles = {
  [RouteType.RoundTrip]: 'кругорейс',
  [RouteType.Simple]: 'только туда',
};

export const CREATE_LOAD = 'Создать заявку';
export const SAVE_LOAD = 'Сохранить заявку';
export const CANCEL_CREATE_LOAD = 'Отменить';

export const FunnelPaymentDocInitialValues: Partial<FunnelPaymentDocsFormValues> = {
  [FunnelPaymentDocsFormFields.CarrierPaymentTerms]: {
    hasVat: true,
    withoutVat: true,
  },
};

export const initialButtonsDisabledState = {
  [ButtonDirection.Back]: false,
  [ButtonDirection.Forward]: false,
};

export const funnelMetricsInitialValues: FunnelCount = {
  [FunnelSwitcherStatus.NewCalculation]: 0,
  [FunnelSwitcherStatus.CostCalculation]: 0,
  [FunnelSwitcherStatus.CostCalculated]: 0,
  [FunnelSwitcherStatus.SentCostToClient]: 0,
  [FunnelSwitcherStatus.CostConfirmed]: 0,
  [FunnelSwitcherStatus.CostCanceled]: 0,
};
