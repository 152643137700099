import { useAppSelector } from '@/hooks/use-app-selector';
import { resourcesContentTabSelector } from '@/features/resources/selectors';
import { RESOURCES_FORM_VIEWS } from '@/features/resources/constants/display-blocks';
import { SidebarWrapper } from '@/components/sidebar-wrapper/sidebar-wrapper';

export const ResourcesFormSidebar = ({ closeSidebar }: { closeSidebar: VoidFunction }) => {
  const activeTabContent = useAppSelector(resourcesContentTabSelector);
  const ResourcesFormContent = RESOURCES_FORM_VIEWS[activeTabContent];

  return (
    <SidebarWrapper closeSidebar={closeSidebar}>
      <ResourcesFormContent />
    </SidebarWrapper>
  );
};
