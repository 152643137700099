import { CarrierFormData } from 'components/verification-forms/types';
import { Carrier } from '@/types/models/carrier';
import { vatOptions } from '@/config/constants/vat-options';
import { VatType } from '@/config/constants/types';
import { SelectOption } from '@/ui-elements/inputs/ui-select/types';
import { carrierOrganizationToFormValues } from '@/components/verification-forms/helpers/carrier-organization-to-form-values';
import { getSelectOptionValue } from '@/helpers/select-options/get-select-option-form-value';
import { carrierBankDetailsToFormValues } from '@/components/verification-forms/helpers/carrier-bank-details-to-form-values';

export const carrierToFormValues = (values?: Carrier, organizationTypes?: Array<SelectOption>): CarrierFormData | undefined => {
  if (!values) return undefined;

  const organizationValues = carrierOrganizationToFormValues(values);
  const organizationBankDetailsValues = organizationValues ? carrierBankDetailsToFormValues(organizationValues?.bankDetails?.[0]) : {};

  return {
    ...organizationValues,
    ...organizationBankDetailsValues,
    name: values?.name,
    organizationTypeID: getSelectOptionValue(organizationValues?.organizationTypeID, organizationTypes),
    vatType: getSelectOptionValue<VatType>(organizationValues?.vatType, vatOptions),
    addressedCoincide: organizationValues?.legalAddress === organizationValues?.actualAddress,
  };
};
