import { useEffect } from 'react';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { useFormState } from 'react-final-form';
import { FormApi } from 'final-form';
import { getEmptyStr } from '@/helpers/get-empty-str';
import { CustomerSelectField } from '@/components/form-field/customer-select-field/customer-select-field';
import { UiInputField, UiSelectField } from '@/ui-elements';
import { SubformTitle } from '@/components/create-order-form/subform-title';
import { useManagersShortUpdates } from '@/features/managers/use-managers-short-updates';
import { useCustomerUpdates } from '@/features/customers/hooks/use-customer-updates';
import { useAppSelector } from '@/hooks/use-app-selector';
import { creatingFromCustomersSelector } from '@/features/order/selectors/creating-from-customers-id-selector';
import { UiPhoneField } from '@/ui-elements/form-fields/ui-phone-field/ui-phone-field';

type Props = {
  namespace: string;
  form: FormApi<any, Partial<any>>;
  isUpdating?: boolean;
};

const riskGroupOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
];

export const OrderInfoSubform = ({ namespace, form, isUpdating }: Props) => {
  const { values } = useFormState();
  const managers = useManagersShortUpdates();
  const { customerCompany } = values[namespace] || {};
  const { customer } = useCustomerUpdates(customerCompany?.value);
  const { data } = customer || {};
  const customerInfo = data?.data;
  const managerName = `${getEmptyStr(customerInfo?.manager?.surname)} ${getEmptyStr(customerInfo?.manager?.name)}`;
  const carrierForReviews = useAppSelector(creatingFromCustomersSelector);

  useEffect(() => {
    if (customerInfo?.vipCustomer && managerName) {
      form.change(`${namespace}.responsiblePerson`, {
        value: customerInfo?.manager?.id,
        label: managerName,
      });
    }
    if (carrierForReviews)
      form.change(`${namespace}.customerCompany`, {
        value: carrierForReviews.id,
        label: carrierForReviews.name,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managerName, customerInfo, carrierForReviews]);
  // useEffect(() => {
  //   if (!customerInfo?.vipCustomer) {
  //     form.change(`${namespace}.responsiblePerson`, {
  //       value: customerInfo?.manager?.id,
  //       label: managerName,
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [customerInfo?.id]);

  return (
    <VStack spacing={0} align="start">
      <SubformTitle title="Информация о заявке" />
      <HStack spacing={4} width="full">
        <Box flexBasis={isUpdating ? '40%' : '50%'}>
          <CustomerSelectField
            name={`${namespace}.customerCompany`}
            label="Организация (клиент)"
            placeholder={<Text noOfLines={1}>Начните вводить название клиента</Text>}
            required
          />
        </Box>
        {isUpdating && (
          <Box flexBasis="25%">
            <UiInputField name={`${namespace}.id`} label="Номер заявки" isDisabled />
          </Box>
        )}
        <Box flexBasis={isUpdating ? '35%' : '50%'}>
          <UiSelectField
            name={`${namespace}.responsiblePerson`}
            label="Ответственный"
            options={managers}
            isDisabled={customerInfo?.vipCustomer === 0}
          />
        </Box>
      </HStack>
      <Box width="200px">
        <UiSelectField name={`${namespace}.riskGroup`} label="Группа риска не меньше" options={riskGroupOptions} placeholder="1" required />
      </Box>
      <HStack spacing={4} width="full">
        <Box flexBasis="50%">
          <UiInputField
            name={`${namespace}.contactPerson`}
            label="Контактное лицо"
            placeholder="Имя Фамилия"
            content="Сотрудник организации клиента"
            required
          />
        </Box>
        <Box flexBasis="50%">
          <UiPhoneField
            name={`${namespace}.contactPhone`}
            label="Номер телефона контактного лица"
            placeholder="+7"
            content="Телефон контактного лица клиента"
            required
          />
        </Box>
      </HStack>
    </VStack>
  );
};
