import { FC } from 'react';
import { UISidebar } from '@/ui-elements/ui-sidebar/ui-sidebar';
import './my-organizations-sidebar-view.scss';
import { MyOrganizationsDetailsView } from '@/views/my-organizations/my-organizations-details-view';

/**
 * Сайдбар для страницы мои организации
 * * */
export const MyOrganizationsSidebarView: FC = () => {
  return (
    <UISidebar className="my-organizations-sidebar-view" isOpen>
      <MyOrganizationsDetailsView />
    </UISidebar>
  );
};
