import './freight-exchange-platform-logo.scss';
import { HStack } from '@chakra-ui/react';
import { useIcons } from '@/hooks/use-icons';
import { FreightExchangePlatform } from '@/types/models/loads';

export const FreightExchangePlatformLogo = ({ platform }: { platform?: FreightExchangePlatform }) => {
  const { MonopolyOnline, AtiSu, IconIntelogis } = useIcons();

  let platformLogo;

  switch (platform) {
    case FreightExchangePlatform.AtiSu:
      platformLogo = <AtiSu />;
      break;
    case FreightExchangePlatform.MonopolyOnline:
      platformLogo = <MonopolyOnline />;
      break;
    default:
      platformLogo = <IconIntelogis />;
  }

  return (
    <HStack alignItems="center" className="freight-exchange-platform-logo">
      {platformLogo}
    </HStack>
  );
};
