import { FC } from 'react';
import { getButtonText, TOOLTIP } from 'components/carrier-card/texts';
import { UiButtonFilled } from '@/ui-elements';

type Props = {
  addCarriersRequest: VoidFunction;
  request: boolean;
  hasOffer: boolean;
  show: boolean;
  isDisabled: boolean;
};

export const RequestCarrierButton: FC<Props> = ({ addCarriersRequest, request, show, isDisabled, hasOffer }) => {
  if (!show) return null;

  const text = getButtonText({ request, hasOffer });

  return (
    <UiButtonFilled
      tooltip={isDisabled ? TOOLTIP : undefined}
      isDisabled={isDisabled}
      onClick={addCarriersRequest}
      mode="dark"
      disabled={request || hasOffer}
      children={text}
    />
  );
};
