import { CustomersApproveStatus } from '@/config/constants/statuses';

export const getCustomerStatus = (status?: string) => {
  if (!status) return '';
  switch (status) {
    case CustomersApproveStatus.Waiting:
      return 'На проверке';
    case CustomersApproveStatus.Approved:
      return 'Принят';
    case CustomersApproveStatus.Declined:
      return 'Отклонен';
    case CustomersApproveStatus.Expired:
      return 'Срок статуса проверки истек';
    default:
      return '';
  }
};
