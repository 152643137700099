import { Box, Grid, Heading, Text, GridItem } from '@chakra-ui/react';
import { FC } from 'react';
import './trips-tab.scss';
import { Contract } from '@/types/models/contract';
import { UiDownloadCard } from '@/ui-elements';

type Props = {
  tripsAmount?: number;
  failedTripsAmount?: number;
  lowRatedTripsAmount?: number;
  tripsDoc?: Contract;
  failedTripsDoc?: Contract;
  lowRatedTripsDoc?: Contract;
};

export const TripsTab: FC<Props> = ({
  tripsAmount = 0,
  failedTripsAmount = 0,
  lowRatedTripsAmount = 0,
  tripsDoc,
  failedTripsDoc,
  lowRatedTripsDoc,
}) => (
  <Box className="trips-tab">
    <Grid alignItems="center" templateColumns="240px 1fr 240px" rowGap="24px" columnGap="16px">
      <GridItem>
        <Heading>Рейсы в текущем филиале</Heading>
      </GridItem>
      <GridItem>
        <Text className={tripsAmount > 0 ? 'trips-tab__amount' : ''}>{tripsAmount || '-'}</Text>
      </GridItem>
      <GridItem>
        <UiDownloadCard fileName="Список рейсов с подробностями.pdf" url={tripsDoc?.link || ''} disabled={!(tripsAmount > 0)} />
      </GridItem>
      <GridItem>
        <Heading>Сорвано рейсов</Heading>
      </GridItem>
      <GridItem>
        <Text className={failedTripsAmount > 0 ? 'trips-tab__amount' : ''}>{failedTripsAmount || '-'}</Text>
      </GridItem>
      <GridItem>
        <UiDownloadCard url={failedTripsDoc?.link || ''} disabled={!(failedTripsAmount > 0)} fileName="Список рейсов с подробностями.pdf" />
      </GridItem>
      <GridItem>
        <Heading>Рейсы с плохой оценкой</Heading>
      </GridItem>
      <GridItem>
        <Text className={lowRatedTripsAmount > 0 ? 'trips-tab__amount' : ''}>{lowRatedTripsAmount || '-'}</Text>
      </GridItem>
      <GridItem>
        <UiDownloadCard disabled={!(lowRatedTripsAmount > 0)} fileName="Список рейсов с подробностями.pdf" url={lowRatedTripsDoc?.link || ''} />
      </GridItem>
    </Grid>
  </Box>
);
