import { Dictionary } from 'config/constants/dictionary';
import { ApiGet, ApiPost } from '@/helpers/api/methods';
import { CodeCheck, CodeSend } from '@/types/api/code-partial';
import { ResponseApi } from '@/types/response';
import { validateQueryString } from '@/helpers/validate-query-string';
import { ContentType } from '@/types/api/content-type';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';

export const CodeApi = {
  getCodeCheck: (query: CodeCheck) =>
    ApiGet<ResponseWithoutPagerSimple<{ password: string }>>(`${Dictionary.Code}/check${validateQueryString(query)}`),

  sendCode: (options: CodeSend) =>
    ApiPost<ResponseApi>(`${Dictionary.Code}/send`, options, {
      contentType: ContentType.FORM_DATA,
    }),
};
