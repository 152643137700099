import { Box, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import closedIcon from 'public/assets/icons/CloseIcon.svg';
import { UiIconButton } from '@/ui-elements';
import { OrderPointDocumentsForm } from '@/components/order-point/order-point-documents-form';
import { FileDocument } from '@/types/models/document';

type Props = {
  modalOpened: boolean;
  onClose: VoidFunction;
  documents?: Array<FileDocument>;
  downloadDocument?: VoidFunction;
  onAcceptDocuments?: VoidFunction;
  onRejectDocuments?: VoidFunction;
};

export const DocumentsEvaluationModal = ({ modalOpened, onClose, ...props }: Props) => {
  return (
    <Modal isOpen={modalOpened} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={24} boxShadow="xl" pos="relative" maxW="600px" w="600px" p={10}>
        <Box position="absolute" right={12} top="43px">
          <UiIconButton aria-label="close-modal-button" icon={closedIcon} onClick={onClose} />
        </Box>
        <OrderPointDocumentsForm {...props} />
      </ModalContent>
    </Modal>
  );
};
