import { FC } from 'react';
import { GridItem } from '@chakra-ui/react';
import { UiSelectFieldAsync } from '@/ui-elements';
import { fetchSettingsOrganizationsOptions } from '@/api/fetch-settings-organizations-options';
import { useOrganizationEnum } from '@/features/my-organizations/hooks/use-organization-enum';

export const SettingsOrganizationsCustomers: FC = () => {
  const { settingOrganizationsEnuData } = useOrganizationEnum();
  return (
    <GridItem mt={5}>
      <UiSelectFieldAsync
        fetchOptions={fetchSettingsOrganizationsOptions}
        name="organizationForCustomers"
        label="Моя организация"
        placeholder="Начните вводить название"
        treshhold={1}
        defaultOptions={settingOrganizationsEnuData}
      />
    </GridItem>
  );
};
