import { FC } from 'react';
import { HStack } from '@chakra-ui/react';
import { VerificationFormMode } from 'components/verification-forms/types';
import { UiButtonFilled, UiButtonOutlined } from '@/ui-elements';

export const CarrierButtonsSubform: FC<{ mode: VerificationFormMode; handleFormCancel: VoidFunction; submitting?: boolean }> = ({
  mode,
  handleFormCancel,
  submitting,
}) => {
  const getCancelCaption = (): string => {
    if (mode === 'edit') return 'Отменить редактирование';
    return mode === 'check' ? 'Отклонить' : 'Отменить добавление';
  };

  return (
    <HStack justifyContent="right" mt={4} mb={2} mr={mode === 'check' ? 2 : 0}>
      <UiButtonOutlined
        className="carriers-verification-form__btn-decline"
        mode={mode === 'check' ? 'light' : 'dark'}
        onClick={handleFormCancel}
        w={mode === 'check' ? 128 : 205}
      >
        {getCancelCaption()}
      </UiButtonOutlined>
      <UiButtonFilled
        className="carriers-verification-form__btn-confirm"
        mode={mode === 'check' ? 'dark' : 'light'}
        type="submit"
        w={mode === 'check' ? 200 : 176}
        isDisabled={submitting}
        isLoading={submitting}
      >
        {mode === 'check' ? 'Принять' : 'На проверку в СБ'}
      </UiButtonFilled>
    </HStack>
  );
};
