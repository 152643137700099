import { MutableRefObject, useEffect } from 'react';
import { LatLngBoundsExpression } from 'leaflet';
import MapClass from '../../components/map/map';
import { MarkerType } from '@/components/map/types';

export const useMapInit = (mapRef: MutableRefObject<MapClass | null>, points?: Array<MarkerType>, distance?: number) => {
  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    mapRef.current = new MapClass() as MapClass;
    const curMap: MapClass = mapRef.current;
    points?.map((point: MarkerType) => curMap.addMarker({ ...point }));

    const fitBoundCoords = points?.map((point) => [point.coords.lat, point.coords.lng]);
    curMap.fitBounds(fitBoundCoords as LatLngBoundsExpression);

    if (distance) curMap.setDistance(distance);

    return () => mapRef?.current?.deinit();
  }, [mapRef, points, distance]);
};
