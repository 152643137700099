import { FC } from 'react';
import { useIcons } from '@/hooks/use-icons';
import { CREATE_LOAD } from '@/config/constants/funnel';
import { UiButtonOutlined } from '@/ui-elements';

interface Props {
  onCreateLoad: () => void;
}

export const CreateLoadButton: FC<Props> = ({ onCreateLoad }) => {
  const { CreateOrder } = useIcons();
  return (
    <UiButtonOutlined onClick={onCreateLoad} leftIcon={CreateOrder} mode="dark">
      {CREATE_LOAD}
    </UiButtonOutlined>
  );
};
