import { useVehicleTypes } from '@/features/vehicle-types/hooks/use-vehicle-types';
import { useVehicleTypesActions } from '@/features/vehicle-types/hooks/use-vehicle-types-actions';

export const useVehicleTypesLoad = () => {
  const { vehicleTypes } = useVehicleTypes();
  const { saveVehicleTypes } = useVehicleTypesActions();

  const loadVehicleTypes = () => vehicleTypes.refetch().then((data) => data.data?.data && saveVehicleTypes(data.data.data));

  return { loadVehicleTypes };
};
