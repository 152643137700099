import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useAppSelector } from '@/hooks/use-app-selector';
import { LoadNew } from '@/types/models/funnel';
import { CreateLoadFormValues } from '@/types/models/funnel-create-load-form';
import { funnelPageSelectedLoadIDSelector } from '@/features/funnel/selectors/funnel-page-selected-load-id';
import { useFunnelActions, useFunnelFormUpdatedValues } from '@/features/funnel/hooks';
import { useLoadsDataActions } from '@/features/loads/hooks';
import { normalizeLoadFormValues } from '@/components/funnel/create-load-form/normalize-load-form-values';
import { useOrderDataActions } from '@/features/order/hooks/use-order-data-actions';
import { useFunnelDataActions } from './use-funnel-data-action';
import { QueryKeys } from '@/config/constants/query-keys';

export const useLoadUpdateView = () => {
  const client = useQueryClient();
  const activeLoadID = useAppSelector(funnelPageSelectedLoadIDSelector);
  const { updatedValues, cargos } = useFunnelFormUpdatedValues();
  const { cancelUpdate } = useFunnelActions();

  const { updateOrder } = useFunnelDataActions(activeLoadID!);

  const onUpdateLoad = (loadNew: LoadNew) => {
    updateOrder.mutate(loadNew, {
      onSuccess: () => {
        client.invalidateQueries({ queryKey: [QueryKeys.allOrders] });
        toast.success('Заявка успешно сохранена');
        cancelUpdate();
      },
    });
  };

  const onSubmit = (values: CreateLoadFormValues) => {
    try {
      const normalizedValues = normalizeLoadFormValues(values);
      onUpdateLoad?.(normalizedValues);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    updatedValues,
    submitting: updateOrder.isLoading,
    onSubmit,
    onCancel: cancelUpdate,
    cargos,
  };
};
