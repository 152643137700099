import { Routes } from 'config/routes/routes';
import { navigationTitles } from 'config/constants/navigation-titles';
import { routesConfig } from 'config/routes/routes-config';
import { NavigationMenu } from '@/types/routes/navigation-menu';

/* NOTE: sortingOrder - порядок расположения элементов навигации */
export const navigationMenu: NavigationMenu = {
  items: [
    // TODO - модуль в разработке
    { route: routesConfig.routes[Routes.CRM], name: navigationTitles[Routes.CRM], sortingOrder: 6 },
    // { route: routesConfig.routes[Routes.Loads], name: navigationTitles[Routes.Loads], sortingOrder: 8 },
    { route: routesConfig.routes[Routes.Funnel], name: navigationTitles[Routes.Funnel], sortingOrder: 7 },
    { route: routesConfig.routes[Routes.Orders], name: navigationTitles[Routes.Orders], sortingOrder: 8 },
    { route: routesConfig.routes[Routes.CarriersLoadsAuthorized], name: navigationTitles[Routes.CarriersLoadsAuthorized], sortingOrder: 9 },
    {
      route: routesConfig.routes[Routes.CarriersLoadsAuthorizedSimple],
      name: navigationTitles[Routes.CarriersLoadsAuthorizedSimple],
      sortingOrder: 9,
    },
    {
      route: routesConfig.routes[Routes.BookerOrders],
      name: navigationTitles[Routes.BookerOrders],
      sortingOrder: 11,
    },
    {
      route: routesConfig.routes[Routes.BookerClients],
      name: navigationTitles[Routes.BookerClients],
      sortingOrder: 12,
    },
    { route: routesConfig.routes[Routes.Carriers], name: navigationTitles[Routes.Carriers], sortingOrder: 20 },
    { route: routesConfig.routes[Routes.FuelCards], name: navigationTitles[Routes.FuelCards], sortingOrder: 30 },
    // { route: routesConfig.routes[Routes.Clients], name: navigationTitles[Routes.Clients], sortingOrder: 40 },
    { route: routesConfig.routes[Routes.Analytics], name: navigationTitles[Routes.Analytics], sortingOrder: 50 },
    { route: routesConfig.routes[Routes.Map], name: navigationTitles[Routes.Map], sortingOrder: 60 },
    { route: routesConfig.routes[Routes.Security], name: navigationTitles[Routes.Security], sortingOrder: 70 },
    {
      route: routesConfig.routes[Routes.Verification],
      name: navigationTitles[Routes.Verification],
      sortingOrder: 80,
    },
    {
      route: routesConfig.routes[Routes.VerificationHistory],
      name: navigationTitles[Routes.VerificationHistory],
      sortingOrder: 90,
    },
    { route: routesConfig.routes[Routes.Statistics], name: navigationTitles[Routes.Statistics], sortingOrder: 100 },
    {
      route: routesConfig.routes[Routes.MyOrganizations],
      name: navigationTitles[Routes.MyOrganizations],
      sortingOrder: 81,
    },
    {
      route: routesConfig.routes[Routes.MyOrganization],
      name: navigationTitles[Routes.MyOrganization],
      sortingOrder: 82,
    },
    { route: routesConfig.routes[Routes.Resources], name: navigationTitles[Routes.Resources], sortingOrder: 109 },
    { route: routesConfig.routes[Routes.Settings], name: navigationTitles[Routes.Settings], sortingOrder: 110 },
    {
      route: routesConfig.routes[Routes.OrdersWithMyParticipation],
      name: navigationTitles[Routes.OrdersWithMyParticipation],
      sortingOrder: 111,
    },
    { route: routesConfig.routes[Routes.CurrentRoutes], name: navigationTitles[Routes.CurrentRoutes], sortingOrder: 112 },
    { route: routesConfig.routes[Routes.FutureRoutes], name: navigationTitles[Routes.FutureRoutes], sortingOrder: 113 },
    { route: routesConfig.routes[Routes.CompletedRoutes], name: navigationTitles[Routes.CompletedRoutes], sortingOrder: 114 },

    { route: routesConfig.routes[Routes.MyOrganizationSimple], name: navigationTitles[Routes.MyOrganizationSimple], sortingOrder: 82 },
    { route: routesConfig.routes[Routes.FutureRoutesSimple], name: navigationTitles[Routes.FutureRoutesSimple], sortingOrder: 113 },
    { route: routesConfig.routes[Routes.CompletedRoutesSimple], name: navigationTitles[Routes.CompletedRoutesSimple], sortingOrder: 114 },
    { route: routesConfig.routes[Routes.ResourcesSimple], name: navigationTitles[Routes.ResourcesSimple], sortingOrder: 80 },
  ],
};
