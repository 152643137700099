import { Box, Grid, GridItem } from '@chakra-ui/react';
import classNames from 'classnames';
import { FC, memo } from 'react';
import { isEqual } from 'lodash';
import { emptyFunction } from '@/helpers/empty-function';
import { GridTableConfig } from '@/ui-elements/ui-grid-table/uii-grid-table-type';

type Props<T extends { id: number }> = {
  item: T;
  config: GridTableConfig<T>;
  countColumns: number;
  isSelected: boolean;
  onClick: VoidFunction;
};
export const UiGridTableRow: FC<Props<any>> = ({ item, isSelected, countColumns, onClick = emptyFunction, config }) => (
  <Box className={classNames('ui-grid-table__item', { 'ui-grid-table__item_selected': isSelected })} onClick={onClick} key={item.id}>
    <Grid templateColumns={`repeat(${countColumns}, 1fr)`} gap={config.gap} className={classNames('ui-grid-table__row')}>
      {config.columns.map((column) => (
        <GridItem key={column.key} colSpan={column.width || 1} className={classNames('ui-grid-table__cell')}>
          {column.render(item)}
        </GridItem>
      ))}
    </Grid>
    {config.rowFooter?.(item)}
  </Box>
);

export const UiGridRowMemoized = memo(UiGridTableRow, (p, n) => isEqual(p.item, n.item) && p.isSelected === n.isSelected);
