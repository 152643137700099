import { CarriersLoadsCreateOfferValuesForm, CarriersLoadsFiltersField, CarriersLoadsFilterValues } from '@/types/models/carriers-loads';
import { EMPTY_STRING } from './common-strings';
import { OrderSwitcherStatus } from './statuses';

/* Текст-подсказка для фильтров */
export const CarriersLoadsFiltersHelpText = {
  From: 'Например, Москва',
  To: 'Например, Самара',
};

export enum CarriersLoadsFiltersFieldLabel {
  From = 'Откуда',
  To = 'Куда',
  DateFrom = 'Дата погрузки с',
  DateTo = 'Дата погрузки по',
  Vehicle = 'Тип ТС',
  TariffType = 'Тип тарифа',
  SingleLoading = 'Одна погрузка одна выгрузка',
}

/* Начальное значение фильтров */
export const initialFiltersValues: CarriersLoadsFilterValues = {
  [CarriersLoadsFiltersField.From]: EMPTY_STRING,
  [CarriersLoadsFiltersField.To]: EMPTY_STRING,
  [CarriersLoadsFiltersField.DateFrom]: EMPTY_STRING,
  [CarriersLoadsFiltersField.DateTo]: EMPTY_STRING,
  [CarriersLoadsFiltersField.VehicleTypes]: [],
  [CarriersLoadsFiltersField.SingleLoading]: false,
  status: OrderSwitcherStatus.CarrierSearch,
};

export const PLACEHOLDER_FORM_ITEM = { opacity: 0.8 };

export const CARRIER_LOAD_REQUEST_BUTTON_LABEL = 'Откликнуться на заявку';

export const initialLoadsCreateOfferFormValues: CarriersLoadsCreateOfferValuesForm = {
  vehicleId: undefined,
  driverId: undefined,
  price: undefined,
};
