export const UI_SEARCH_AUTOCOMPLETE_STYLING_CONFIG = {
  backgroundColor: 'white',
  boxShadow: 'rgba(32, 33, 36, 0.28) 0px 1px 6px 0px',
  hoverBackgroundColor: '#eee',
  color: '#232735',
  fontFamily: 'Nunito Sans',
  fontSize: 'var(--chakra-fontSizes-md)',
  iconColor: 'grey',
  lineColor: 'rgb(232, 234, 237)',
  placeholderColor: 'grey',
  clearIconMargin: '0 0.5em',
  searchIconMargin: '0 0.5em',
  height: 'var(--chakra-sizes-10)',
  border: '1px solid var(--chakra-colors-neutrals-250)',
  borderRadius: '8px',
  zIndex: 100,
};

export const UI_SEARCH_AUTOCOMPLETE_DEFAULT_PROPS = {
  inputDebounce: 600,
};
