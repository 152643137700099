import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'config/query-client';
import { ApprovalsApi } from '@/api/approvals';
import { ApprovalBodySimple } from '@/types/models/approval';

export const useApprovalsActions = () => {
  const markApprovalApproved = useMutation({
    mutationKey: ['markApprovalApproved'],
    mutationFn: (body: ApprovalBodySimple) => ApprovalsApi.markApprovalApproved(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['approveMetrics'] });
      queryClient.invalidateQueries(['approvals']);
    },
  });

  const markApprovalDeclined = useMutation({
    mutationKey: ['markApprovalDeclined'],
    mutationFn: (body: ApprovalBodySimple) => ApprovalsApi.markApprovalDeclined(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['approveMetrics'] });
      queryClient.invalidateQueries(['approvals']);
    },
  });

  return {
    markApprovalApproved,
    markApprovalDeclined,
  };
};
