import { Box, Heading, Text } from '@chakra-ui/react';
import './driver-tab.scss';
import moment from 'moment/moment';
import { Driver } from '@/types/models/driver';

export const DriverTabContent = ({ driver }: { driver: Driver }) => {
  const driverLicenseDate = moment(driver.driverLicenseDate).format('DD/MM/YYYY');
  return (
    <Box className="driver-tab">
      <Heading>Проверен в филиале</Heading>
      <Text>{driver.department}</Text>
      <Heading>Комментарии сотрудника СБ</Heading>
      <Text>{driver.securityComment || 'Нет'}</Text>
      <Heading>ФИО</Heading>
      <Text>{`${driver.surname} ${driver.name} ${driver.patronymic}`}</Text>
      <Heading>Телефон</Heading>
      <Text>{driver.phone}</Text>
      <Heading className="driver-tab__documents-title">Водительские права</Heading>
      <Text>
        {driver.driverLicense}
        {driver.driverLicenseDate && ` от ${driverLicenseDate}`}
      </Text>
      {driver.documents
        ?.filter((document) => document.type === 'dlDocumentDriverLicense')
        .map((licence) => (
          <img key={licence.link} src={`${licence.link}&bin=1`} alt="Водительские права" className="driver-tab__documents-license" />
        ))}
      <Heading className="driver-tab__documents-title">Паспорт</Heading>
      {driver.documents
        ?.filter((document) => document.type === 'dlDocumentDriverPassport')
        .map((passport) => (
          <img key={passport.link} src={`${passport.link}&bin=1`} alt="Паспорт водителя" className="driver-tab__documents-passport" />
        ))}
    </Box>
  );
};
