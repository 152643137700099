import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { FirstRender } from '../features/order/hooks/use-first-render';
import { INTERVAL_REQUEST_DELAY } from '@/config/constants/interval-request-delay';

type Props = {
  enabled?: boolean;
  staleTime?: number;
  queryKey: any;
  refetchOnWindowFocus?: boolean;
  queryFn: any;
};

export const useQueryWithUpdates = <T>({
  enabled,
  staleTime,
  queryKey,
  refetchOnWindowFocus = false,
  queryFn,
}: Props): UseQueryResult<T, unknown> => {
  const { isFirst } = FirstRender();
  // TODO: после приведения на бэке ответов к стандратному виду убрать условие isEmpty
  return useQuery({
    queryKey,
    enabled,
    staleTime,
    refetchOnWindowFocus,
    queryFn: () => queryFn(isFirst),
    refetchInterval: INTERVAL_REQUEST_DELAY,
    structuralSharing: (oldData, newData) => {
      if (newData.data === null && oldData) {
        return oldData;
      }
      return newData;
    },
  });
};
