import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { ReactSearchAutocompleteProps } from 'react-search-autocomplete/dist/components/ReactSearchAutocomplete';
import { ReactElement } from 'react';
import { UI_SEARCH_AUTOCOMPLETE_DEFAULT_PROPS, UI_SEARCH_AUTOCOMPLETE_STYLING_CONFIG } from '@/ui-elements/inputs/ui-search-autocomplete/constants';

type Props<T> = ReactSearchAutocompleteProps<T>;

export const UiSearchAutocomplete = <T extends { id: string | number }>({
  inputDebounce = UI_SEARCH_AUTOCOMPLETE_DEFAULT_PROPS.inputDebounce,
  ...props
}: Props<T>): ReactElement => {
  return <ReactSearchAutocomplete inputDebounce={inputDebounce} styling={UI_SEARCH_AUTOCOMPLETE_STYLING_CONFIG} {...props} />;
};
