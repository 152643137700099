import { FC } from 'react';
import './carrier-rating.scss';
import { getRatingStyles } from './get-rating-styles';
import { UiTag } from '@/ui-elements/ui-tag/ui-tag';

type Props = {
  rating: number;
  className?: string;
};

export const CarrierRating: FC<Props> = ({ rating = 0, ...props }) => {
  const { backgroundColor, color } = getRatingStyles(rating);

  return (
    <UiTag backgroundColor={backgroundColor} color={color} className="carrier-rating" {...props}>
      {`Рейтинг ${typeof rating === 'string' ? parseFloat(rating).toFixed(1) : rating.toFixed(1)}`}
    </UiTag>
  );
};
