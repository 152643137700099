import { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { ResourcesFormFieldNames, ResourcesFormLabels } from '@/features/resources/constants/form';
import { ResourcesSidebarMode } from '@/features/resources/types/general';
import { ResourcesSubformAttachment } from '@/components/resources/forms/subform-attachment/resources-subform-attachment';
import { UseDependentAttachmentsReturnValues } from '@/features/attachments/hooks/use-dependent-attachments';

interface Props {
  mode: ResourcesSidebarMode;
  vehicleInitialDocs: Partial<Record<ResourcesFormFieldNames, UseDependentAttachmentsReturnValues>>;
}

export const ResourcesSubformContract: FC<Props> = ({ mode, vehicleInitialDocs }) => {
  return (
    <Box>
      <ResourcesSubformAttachment
        mode={mode}
        fieldName={ResourcesFormFieldNames.Contract}
        label={ResourcesFormLabels[ResourcesFormFieldNames.Contract]}
        attachmentsAndHandlers={vehicleInitialDocs[ResourcesFormFieldNames.Contract]}
      />
    </Box>
  );
};
