import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DriversApi } from '@/api/drivers';
import { DriverEdit } from '@/types/api/driver-partial';
import { DriverVerificationRequest } from '@/types/models/driver-verification';

export const useDriverActions = (id?: number) => {
  const queryClient = useQueryClient();
  const updateDriver = useMutation({
    mutationKey: ['updateDriver'],
    mutationFn: (options: DriverEdit) => DriversApi.updateDriver(id!, true, options),
    onSuccess: () => {
      queryClient.invalidateQueries(['approvals', { statuses: 'waiting' }]);
      queryClient.invalidateQueries(['approveMetrics']);
    },
  });
  const updateDriverWithoutApprovals = useMutation({
    mutationKey: ['updateDriver'],
    mutationFn: (options: DriverEdit) => DriversApi.updateDriver(id!, false, options),
    onSuccess: () => {
      queryClient.invalidateQueries(['approvals', { statuses: 'waiting' }]);
      queryClient.invalidateQueries(['approveMetrics']);
    },
  });

  const addDriver = useMutation({
    mutationKey: ['addDriver'],
    mutationFn: (options: DriverVerificationRequest) => DriversApi.addDriver(options),
  });

  const deleteDriver = useMutation({
    mutationKey: ['deleteDriver'],
    mutationFn: (driverId: number | undefined) => DriversApi.deleteDriver(driverId!),
  });

  return { updateDriver, addDriver, deleteDriver, updateDriverWithoutApprovals };
};
