import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@/config/constants/query-keys';
import { EMPTY_STRING } from '@/config/constants/common-strings';
import { createFormVehicleValues } from '@/components/verification-forms/helpers/create-form-vehicle-values';
import { FormValues } from '@/components/verification-forms/types';
import { useAttachmentsActions } from '@/features/attachments/hooks/use-attachments-actions';
import { useVehicleActions } from '@/features/vehicles/hooks/use-vehicle-actions';
import { AttachmentDeps } from '@/config/constants/types';
import { DocumentTypes } from '@/config/constants/document-types';

interface Props {
  carrierId?: number;
  withoutTrailer: boolean;
  onClose: VoidFunction;
}

const VEHILCE_DOCUMENTS = [
  DocumentTypes.Contract,
  DocumentTypes.VRCFront,
  DocumentTypes.VRCBack,
  DocumentTypes.VPFront,
  DocumentTypes.VPBack,
  DocumentTypes.ExtraDoc,
];

export const useCreateResourcesVehicle = ({ carrierId, withoutTrailer, onClose }: Props) => {
  const queryClient = useQueryClient();
  const { postAttachment } = useAttachmentsActions();
  const { addVehicle } = useVehicleActions();

  const carrierValue = { label: carrierId?.toString() ?? EMPTY_STRING, value: carrierId ?? 0 };

  const handleCreateVehicle = (values: FormValues) => {
    addVehicle.mutate(createFormVehicleValues({ ...values, carrierId: carrierValue }, withoutTrailer), {
      onSuccess: (response) => {
        const { id } = response.data;
        queryClient.invalidateQueries([QueryKeys.allVehicles]);
        toast.success('Транспортное средство успешно создано');
        onClose?.();
        if (id) {
          VEHILCE_DOCUMENTS.map((documentType) => {
            values[documentType as keyof FormValues]?.map((file: File) => {
              postAttachment.mutate({
                attachment: file,
                dependent: AttachmentDeps.Vehicle,
                dependentID: id,
                subject: documentType,
              });
            });
          });
        }
      },
    });
  };
  return { handleCreateVehicle };
};
