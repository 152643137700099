export enum AccountRole {
  Carrier = 'carrier',
  CargoOwner = 'cargoOwner',
  Intermediary = 'intermediary',
  Intelogis_truck = 'intelogis_truck',
  CarrierSimple = 'carrier_simple',
}

export enum AccountRoleMap {
  'carrier' = 'Перевозчик',
  'cargoOwner' = 'Грузовладелец',
  'intermediary' = 'Посредник',
  'intelogis_truck' = 'Логист ЦЭ ИЛС',
  'carrier_simple' = 'Перевозчик',
}
