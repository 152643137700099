import './vehicle-widget.scss';
import { Box, Text } from '@chakra-ui/react';
import { VehicleRegInfo } from 'components/vehicle-widget/vehicle-reg-info';
import { Vehicle } from '@/types/models/vehicle';
import { Document } from '@/types/models/contract';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { VerificationItemApproveStatus } from '@/config/constants/statuses';

type Props = {
  vehicle?: Vehicle;
  vehicleTypeIcon?: string;
  vehicleTypeName?: string;
  doc?: Array<Document>;
};

export const VehicleWidget = ({ vehicle, vehicleTypeIcon, vehicleTypeName, doc }: Props) => {
  if (!vehicle) return null;

  return (
    <Box>
      <Text textStyle="h3">
        {vehicle.approvalStatus === VerificationItemApproveStatus.Accepted ? 'Пройдена проверка в Моей организации' : 'Не проходил проверку'}
      </Text>
      {/* <Text textStyle="h3" mt={6}> */}
      {/*  Комментарии сотрудника СБ */}
      {/* </Text> */}
      {/* <Text mt={3}>ТС в собственности. Обременений не обнаружено.</Text> */}
      <Text textStyle="h3" mt={6}>
        Аварии
      </Text>
      <Text mt={3} mb={6}>
        {vehicle.accidentsCount}
      </Text>
      <VehicleRegInfo vehicle={vehicle} vehicleTypeName={vehicleTypeName} vehicleTypeIcon={vehicleTypeIcon} />
      {doc && (
        <Text textStyle="h3" my={6}>
          Свидетельство о регистрации
        </Text>
      )}
      <UiScrollbars style={{ width: '100%', height: '25vh' }}>
        {doc
          ?.filter((document) => document.type === 'dlDocumentVehicleFrontDoc')
          .map((frontDoc) => (
            <img
              key={frontDoc.link}
              src={`${frontDoc.link}&bin=1`}
              alt="Свидетельство о регистрации"
              className="vehicle-widget__documents-registration"
            />
          ))}
        {doc
          ?.filter((document) => document.type === 'dlDocumentDriverContract')
          .map((DriverContract) => (
            <img
              key={DriverContract.link}
              src={`${DriverContract.link}&bin=1`}
              alt="Свидетельство о регистрации"
              className="vehicle-widget__documents-registration"
            />
          ))}
        {doc
          ?.filter((document) => document.type === 'dlDocumentVehicleVDPDo')
          .map((VehicleVDPDo) => (
            <img
              key={VehicleVDPDo.link}
              src={`${VehicleVDPDo.link}&bin=1`}
              alt="Паспорт транспортного средства"
              className="vehicle-widget__documents-registration"
            />
          ))}
        {doc
          ?.filter((document) => document.type === 'dlDocumentVehicleExtraDoc')
          .map((VehicleExtraDoc) => (
            <img
              key={VehicleExtraDoc.link}
              src={`${VehicleExtraDoc.link}&bin=1`}
              alt="Паспорт водителя"
              className="vehicle-widget__documents-registration"
            />
          ))}
      </UiScrollbars>
    </Box>
  );
};
