import './completed-routes.scss';
import { FC } from 'react';
import { isEmpty } from 'lodash';
import { CarriersLoadsEmpty } from 'components/carriers-loads/empty/empty';
import { CarriersLoadsList } from 'components/carriers-loads/list/carriers-loads-list';
import { Routes } from 'config/routes/routes';
import { getEmptyText } from 'components/carriers-loads/empty/helpers/get-empty-text';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';
import { useCarriersLoadsRoutesList } from '@/features/carriers-loads/hooks/use-carriers-loads-routes-list';

export const CompletedRoutesListView: FC = () => {
  const { isInitialLoading, routes, activeOrderId, handleSelect, fetchNextPage, isFetchingNextPage, isDriver, redirectRoutes } =
    useCarriersLoadsRoutesList(Routes.CompletedRoutes);

  if (isInitialLoading) {
    return <UiSpinner visible mt={48} />;
  }

  if (isEmpty(routes)) {
    return (
      <CarriersLoadsEmpty
        showButtons={!isDriver}
        redirectRoutes={redirectRoutes}
        emptyText={getEmptyText({
          isDriver,
          route: Routes.CompletedRoutes,
        })}
      />
    );
  }

  return (
    <CarriersLoadsList
      withStatus
      selectedOrderId={activeOrderId}
      handleOrderClick={handleSelect}
      orderList={routes}
      loadMore={fetchNextPage}
      loading={isFetchingNextPage}
    />
  );
};
