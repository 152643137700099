import { FC } from 'react';
import './order-route.scss';
import { RoutePointWidget } from 'components/carriers-loads/widgets/route-point-widget';
import { ROUTE } from '@/features/order/texts';
import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';

type Props = {
  order: Order | Load;
};

/**
 * Перевозчик
 * Маршрут по заявке - вертикальное отображение
 * */
export const Route: FC<Props> = ({ order }) => {
  const { points } = order;
  return (
    <div className="order-route">
      <div className="order-route__caption">{ROUTE}</div>
      {points?.map((point, index) => (
        <RoutePointWidget key={point.id} point={point} isLastPoint={index === points.length - 1} order={order} />
      ))}
    </div>
  );
};
