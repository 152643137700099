import { FC } from 'react';
import { CarrierReviewsTitle } from 'components/carrier-review/carrier-reviews-title';
import { ComponentReview } from 'components/carrier-review/componentReview';
import { Box, Divider, Flex, HStack } from '@chakra-ui/react';
import { MARGIN_CONTAINER, MARGIN_DIVIDED, MARGIN_TOP_TITLE } from 'components/carrier-review/constants';
import { CarrierReviewsForm } from 'components/carrier-review/carrier-reviews-form';
import { emptyFunction } from '@/helpers/empty-function';
import './carrier-reviews.scss';
import { Review } from '@/types/models/review';
import { UiIconButton } from '@/ui-elements';
import { useIcons } from '@/hooks/use-icons';
import { Carrier } from '@/types/models/carrier';

type Props = {
  reviews?: Array<Review>;
  onCloseClick?: VoidFunction;
  carrier?: Carrier;
  vehiclesAmount: number;
};
export const CarrierReviews: FC<Props> = ({ reviews, onCloseClick = emptyFunction, carrier, vehiclesAmount }) => {
  const { CloseIcon } = useIcons();

  return (
    <Flex className="carrier-reviews" flexDirection="column" m={MARGIN_CONTAINER}>
      <Box>
        <HStack className="carrier-reviews__carrier-card" justifyContent="space-between" alignItems="flex-start">
          <CarrierReviewsTitle vehiclesAmount={vehiclesAmount} carrier={carrier} />
          <UiIconButton className="carrier-reviews__carrier-card__close-icon" aria-label="close" icon={CloseIcon} onClick={onCloseClick} />
        </HStack>
        <Flex flexDirection="column" mt={MARGIN_TOP_TITLE}>
          {reviews &&
            reviews.map((review) => (
              <div key={review.id} className="carrier-reviews__review">
                <ComponentReview review={review} />
                <Divider m={MARGIN_DIVIDED} />
              </div>
            ))}
        </Flex>
      </Box>
      <div className="carrier-reviews__form-container">
        <CarrierReviewsForm onSubmit={emptyFunction} />
      </div>
    </Flex>
  );
};
