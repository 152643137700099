import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { toNumber } from 'lodash';
import { VehiclesApi } from '@/api/vehicles';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';
import { QueryKeys } from '@/config/constants/query-keys';
import { normalizeSelectOptionsResponce } from '@/helpers/api/normalize-select-options-responce';
import { EMPTY_STRING } from '@/config/constants/common-strings';

type UseRoadTrainsEnumProps = {
  q: string;
  carrierId?: string | number;
  options: UseQueryOptions<ResponseWithoutPagerSimple<Record<string, string>>>;
};

export const useRoadTrainsEnum = ({ q = EMPTY_STRING, carrierId, options = {} }: UseRoadTrainsEnumProps) => {
  const roadTrainsEnum = useQuery({
    queryKey: [QueryKeys.roadTrainsEnum],
    refetchOnWindowFocus: false,
    queryFn: () => VehiclesApi.getRoadTrainEnum({ search: q, carrierId }),
    ...options,
  });

  const roadTrainsEnumData = normalizeSelectOptionsResponce(roadTrainsEnum?.data?.data ?? [], toNumber);

  return {
    roadTrainsEnum,
    roadTrainsEnumData,
  };
};
