import { FC } from 'react';
import { FormControlProps, InputProps } from '@chakra-ui/react';
import { Field } from 'react-final-form';
import { UiToggle } from '@/ui-elements/ui-toggle/ui-toggle';
import { BooleanVoidFunction } from '@/types/common/void-function';

type Props = {
  name: string;
  label?: string;
  isDisabled?: boolean;
  outerProps?: FormControlProps;
  onClick?: BooleanVoidFunction;
};

export const UiToggleField: FC<Props & InputProps> = ({ name, label, isDisabled, onClick, outerProps }) => (
  <Field name={name} type="checkbox" component="input" {...outerProps}>
    {({ input, meta }) => (
      <UiToggle onClick={onClick} {...input} disabled={isDisabled} isInvalid={meta.touched && meta.error}>
        {label}
      </UiToggle>
    )}
  </Field>
);
