import { FC } from 'react';
import { UiMaskedField } from '@/ui-elements';
import { UiMaskedFieldProps } from '@/ui-elements/form-fields/ui-masked-field/ui-masked-field';
import { MOBILE_PHONE_NUMBER, MOBILE_PHONE_PLACEHOLDER, PHONE_MASK } from '@/ui-elements/form-fields/ui-phone-field/constants';

type Props = Omit<UiMaskedFieldProps, 'mask'> & {
  name?: string;
  mask?: UiMaskedFieldProps['mask'];
};

export const UiPhoneField: FC<Props> = ({
  name = 'phone',
  label = MOBILE_PHONE_NUMBER,
  mask = PHONE_MASK,
  placeholder = MOBILE_PHONE_PLACEHOLDER,
  ...props
}) => {
  return <UiMaskedField {...props} label={label} mask={mask} name={name} placeholder={placeholder} />;
};
