import { HStack, StackProps } from '@chakra-ui/react';
import { FormApi } from 'final-form';
import { UiButtonFilled, UiButtonOutlined } from '@/ui-elements';

type Props = {
  isDisabled: boolean;
  form: FormApi<any, any>;
} & StackProps;

export const SubmitButtonsGroup = ({ isDisabled, form, ...stackProps }: Props) => (
  <HStack {...stackProps}>
    <UiButtonOutlined
      className="carriers-verification-form__btn-decline"
      mode="light"
      type="submit"
      onClick={() => form.change('action', 'decline')}
      w={128}
      isDisabled={isDisabled}
    >
      Отклонить
    </UiButtonOutlined>
    <UiButtonFilled
      className="carriers-verification-form__btn-confirm"
      mode="dark"
      type="submit"
      w={200}
      onClick={() => form.change('action', 'approve')}
      isDisabled={isDisabled}
    >
      Принять
    </UiButtonFilled>
  </HStack>
);
