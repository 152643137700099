import { useQuery } from '@tanstack/react-query';
import { CarriersApi } from '@/api/carriers';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useCarrierDrivers = (id?: number) => {
  return useQuery({
    queryKey: ['carrierDrivers', id],
    refetchOnWindowFocus: false,
    enabled: !!id,
    queryFn: id ? () => CarriersApi.getCarrierDrivers(id) : undefined,
  });
};
