import { EMPTY_STRING, SPACE } from '@/config/constants/common-strings';
import { VatType } from '@/config/constants/types';
import { VAT_TYPE } from '@/config/constants/vat-options';
import { FileFormField, MyOrganizationFormField, MyOrganizationFormSection, MyOrganizationFormValues } from '@/types/models/my-organization/form';

type FormValuesToData = {
  requestData: Record<MyOrganizationFormSection | MyOrganizationFormField, any>;
  files: {
    [k in FileFormField]: File[];
  };
};

type GetFormValuesToData = { formValues: MyOrganizationFormValues; isCarrierSimple: boolean; accountPhone: string };

const getOrganizationNameForCarrierSimple = (data: { directorSurname: string; directorName: string; directorPatronymic: string }) => {
  const { directorSurname, directorName, directorPatronymic } = data;
  return [directorSurname, directorName, directorPatronymic].join(SPACE);
};

export const getFormValuesToData = ({ formValues, isCarrierSimple, accountPhone }: GetFormValuesToData) => {
  const data = Object.keys(formValues ?? {}) as MyOrganizationFormField[];

  const resultFormData = data.reduce(
    (result, key) => {
      const currentResult = { ...result };
      switch (true) {
        case [
          MyOrganizationFormField.BIK,
          MyOrganizationFormField.Bank,
          MyOrganizationFormField.BankAccount,
          MyOrganizationFormField.CorrespondentAccount,
          MyOrganizationFormField.VAT,
        ].includes(key):
          currentResult.requestData.bankDetails = {
            ...currentResult.requestData.bankDetails,
            [key === MyOrganizationFormField.Bank ? MyOrganizationFormField.Name : key]:
              key === MyOrganizationFormField.VAT ? Number(formValues[key]) : formValues[key],
          };
          if (key === MyOrganizationFormField.VAT) {
            currentResult.requestData = {
              ...currentResult.requestData,
              [MyOrganizationFormField.VatType]: Number(formValues[key]) ? VatType.WithVat : VatType.WithoutVat,
            };
          }
          return currentResult;
        case [MyOrganizationFormField.InnOgrnDocument, MyOrganizationFormField.PassportDocument, MyOrganizationFormField.DriversLicenseDoc].includes(
          key
        ):
          currentResult.files = {
            ...currentResult.files,
            [key]: formValues[key],
          };
          return currentResult;
        case [MyOrganizationFormField.PassportDate, MyOrganizationFormField.Birthday].includes(key):
          currentResult.requestData = {
            ...currentResult.requestData,
            [key]: formValues[key]?.toString(),
          };
          return currentResult;
        case [MyOrganizationFormField.OrganizationTypeId].includes(key):
          currentResult.requestData = {
            ...currentResult.requestData,
            [key]: formValues[key]?.value,
          };
          return currentResult;
        case [
          MyOrganizationFormField.Country,
          MyOrganizationFormField.Convictions,
          MyOrganizationFormField.Loans,
          MyOrganizationFormField.Accidents,
          MyOrganizationFormField.DriverLicense,
          MyOrganizationFormField.DriverLicenseDate,
        ].includes(key):
          return currentResult;
        case [MyOrganizationFormField.Phone].includes(key):
          currentResult.requestData = {
            ...currentResult.requestData,
            [MyOrganizationFormField.Phone]: formValues[key],
          };
          return currentResult;
        default:
          currentResult.requestData = {
            ...currentResult.requestData,
            [key]: key === MyOrganizationFormField.RussianPassport ? Number(formValues[key]) : formValues[key],
          };
          return currentResult;
      }
    },
    { requestData: { bankDetails: {} }, files: {} } as FormValuesToData
  );
  if (isCarrierSimple) {
    resultFormData.requestData[MyOrganizationFormField.Phone] = accountPhone.replace(/\D/gi, EMPTY_STRING);
    resultFormData.requestData[MyOrganizationFormField.ContactName] = formValues[MyOrganizationFormField.DirectorName];
    resultFormData.requestData[MyOrganizationFormField.Name] = getOrganizationNameForCarrierSimple({
      directorSurname: formValues[MyOrganizationFormField.DirectorSurname],
      directorName: formValues[MyOrganizationFormField.DirectorName],
      directorPatronymic: formValues[MyOrganizationFormField.DirectorPatronymic],
    });
  }

  return resultFormData;
};
