import { ModelExtender } from '@/types/model-extender';
import { Load } from '@/types/models/loads';
import { formatMomentLocal } from '@/helpers/moment/format-moment-local';
import { MOMENT_L } from '@/helpers/moment/constants';

export type LoadExtended = Load & {
  createdFormatted: string;
};

export const loadExtender: ModelExtender<Load, LoadExtended> = {
  createdFormatted: (load) => formatMomentLocal(load?.created, MOMENT_L),
};
