import qs from 'qs';
import { Dictionary } from 'config/constants/dictionary';
import { ApiDelete, ApiGet, ApiPatch, ApiPost } from '@/helpers/api/methods';
import { CarrierDriverRequest, CarrierFilter, CarrierRequest, CarrierRequestFilter, CarrierReview } from '@/types/api/carrier-partial';
import { ResponseWithPager, ResponseWithoutPagerSimple } from '@/types/models/pager';
import { Carrier, CarrierEdit } from '@/types/models/carrier';
import { stringifyQuery, validateQueryString } from '@/helpers/validate-query-string';
import { ResponseSimpleApi } from '@/types/response';
import { DateNPageFilter } from '@/types/api/common-partial';
import { Review } from '@/types/models/review';
import { Vehicle } from '@/types/models/vehicle';
import { DriverVerification } from '@/types/models/driver-verification';

export const CarriersApi = {
  getAllCarriers: (query?: CarrierFilter) => ApiGet<ResponseWithPager<Carrier>>(`${Dictionary.Carriers}${stringifyQuery(query)}`),

  getCarrier: (id: number) => ApiGet<ResponseWithoutPagerSimple<Carrier>>(`${Dictionary.Carriers}/${id}`),

  addCarrier: (body: CarrierEdit) => ApiPost<ResponseWithoutPagerSimple<Carrier>>(`${Dictionary.Carriers}`, body),

  updateCarrier: (id: number, approvals: boolean, options: CarrierEdit) =>
    ApiPatch<ResponseWithoutPagerSimple<Carrier>>(`${Dictionary.Carriers}/${id}?approvals=${approvals}`, options),

  getCarrierAccept: (id: number) => ApiGet<ResponseSimpleApi>(`${Dictionary.Carriers}/${id}/${Dictionary.Accept}`),

  getCarrierDecline: (id: number) => ApiGet<ResponseSimpleApi>(`${Dictionary.Carriers}/${id}/${Dictionary.Decline}`),

  getCarrierReviews: (id: number, query?: DateNPageFilter) =>
    ApiGet<ResponseWithPager<Review>>(`${Dictionary.Carriers}/${id}/${Dictionary.Reviews}${validateQueryString(query)}`),

  /**
   * Deprecated
   */
  addCarrierReview: (id: number, options: CarrierReview) => ApiPost<ResponseSimpleApi>(`${Dictionary.Carriers}/${id}/${Dictionary.Reviews}`, options),

  addCarrierRequest: (options: CarrierDriverRequest) => {
    const { carrierId, ...body } = options;
    return ApiPost<ResponseSimpleApi>(`${Dictionary.Carriers}/${carrierId}/requestShipment`, body);
  },

  addAllCarriersRequest: (options: CarrierRequestFilter & CarrierRequest) =>
    ApiPost<ResponseSimpleApi>(`${Dictionary.Carriers}/requestShipment`, options),

  getCarriersEnum: (query: string) =>
    ApiGet<ResponseWithoutPagerSimple<Record<string, string>>>(
      `${Dictionary.Carriers}/enum${qs.stringify({ search: query }, { addQueryPrefix: true })}`
    ),

  getCarriersEnumWithExtras: ({ query, extraParams }: { query: string; extraParams?: Array<string> }) =>
    ApiGet<ResponseWithoutPagerSimple<Record<string, string>>>(
      `${Dictionary.Carriers}/enum${qs.stringify(
        { search: query, extraParams },
        {
          addQueryPrefix: true,
          skipNulls: true,
          arrayFormat: 'comma',
        }
      )}`
    ),

  getCarrierVehicles: (id: number) => ApiGet<ResponseWithPager<Vehicle>>(`${Dictionary.Carriers}/${id}/vehicles`),
  getCarrierDrivers: (id: number) => ApiGet<ResponseWithPager<DriverVerification>>(`${Dictionary.Carriers}/${id}/drivers`),
  deleteCarrier: (id: number) => ApiDelete<ResponseSimpleApi>(`${Dictionary.Carriers}/${id}`),
};
