import { ModelExtender } from '@/types/model-extender';
import { Carrier } from '@/types/models/carrier';
import { CarrierExtended } from '@/features/carriers/types/carrier-extended';
import { getApprovalStatusCarrier } from '@/helpers/get-approval-status-carrier';

export const carrierExtender: ModelExtender<Carrier, CarrierExtended> = {
  approveStatusCaption: (carrier) => {
    return getApprovalStatusCarrier(carrier.approvalStatus);
  },
};
