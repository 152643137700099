import { FuelStatus } from '@/config/constants/statuses';

export const getFuelLabels = (fuelCount?: { [key: string]: number }, fuelStatuses?: Record<FuelStatus, string>) => {
  const labelsWithoutFuel = [
    {
      key: FuelStatus.ConfirmedWithoutFuel,
      label: `${fuelStatuses?.[FuelStatus.ConfirmedWithoutFuel]} (${fuelCount?.confirmedWithoutFuel || 0})`,
    },
    {
      key: FuelStatus.ExecutingWithoutFuel,
      label: `${fuelStatuses?.[FuelStatus.ExecutingWithoutFuel]} (${fuelCount?.executingWithoutFuel || 0})`,
    },
    {
      key: FuelStatus.CompletedWithoutFuel,
      label: `${fuelStatuses?.[FuelStatus.CompletedWithoutFuel]} (${fuelCount?.completedWithoutFuel || 0})`,
    },
  ];

  const labelsWithFuel = [
    {
      key: FuelStatus.ConfirmedWithFuel,
      label: `${fuelStatuses?.[FuelStatus.ConfirmedWithFuel]} (${fuelCount?.confirmedWithFuel || 0})`,
    },
    {
      key: FuelStatus.ExecutingStartPreparation,
      label: `${fuelStatuses?.[FuelStatus.ExecutingStartPreparation]} (${fuelCount?.executingStartPreparation || 0})`,
    },
    {
      key: FuelStatus.ExecutingFuelProvided,
      label: `${fuelStatuses?.[FuelStatus.ExecutingFuelProvided]} (${fuelCount?.executingFuelProvided || 0})`,
    },
    {
      key: FuelStatus.CompletedBlockFuel,
      label: `${fuelStatuses?.[FuelStatus.CompletedBlockFuel]} (${fuelCount?.completedBlockFuel || 0})`,
    },
    {
      key: FuelStatus.CompletedFuelClosed,
      label: `${fuelStatuses?.[FuelStatus.CompletedFuelClosed]} (${fuelCount?.completedFuelClosed || 0})`,
    },
  ];

  return { labelsWithoutFuel, labelsWithFuel };
};
