import { Heading, Box } from '@chakra-ui/react';
import { UiInputField } from '@/ui-elements';
import { ResourcesFormFieldNames, ResourcesFormLabels } from '@/features/resources/constants/form';

export const ResourcesDriverSubformFullName = () => {
  return (
    <Box>
      <Heading as="h3" className="resources-form-subtitle">
        Данные водителя
      </Heading>
      <Box className="resources-form-row">
        <UiInputField name={ResourcesFormFieldNames.surname} label={ResourcesFormLabels.surname} required />
        <UiInputField name={ResourcesFormFieldNames.name} label={ResourcesFormLabels.name} required />
        <UiInputField name={ResourcesFormFieldNames.patronymic} label={ResourcesFormLabels.patronymic} />
      </Box>
    </Box>
  );
};
