import { FC } from 'react';
import { BY_PRICE, PREPAYMENT, START_ORDER } from 'components/order-details/order-details-carrier-driver-info/texts';
import { HStack } from '@chakra-ui/react';
import { DriverOwnAssignedMark } from 'components/driver-own-assigned-mark/driver-own-assigned-mark';
import { SetCarrierDriverInfo } from '@/components';
import { OrderOffer } from '@/types/models/order-offer';
import { PaymentChip } from '@/ui-elements/payment-chip/payment-chip';
import './order-details-carrier-driver-info.scss';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { driverExtender } from '@/features/drivers/extender/driver-extender';
import { toPriceFormat } from '@/helpers/price/to-price-format';
import { UiButtonFilled } from '@/ui-elements';
import { OrderPrice } from '@/types/models/order';

type Props = {
  orderOffer: OrderOffer;
  // убирает доплнительную информацию по заявке, которая нужна только логисту
  hideOrderLogistInfo?: boolean;
  isOrderConfirmed?: boolean;
  onOrderStart?: () => void;
  prepaymentValue?: number;
  orderPrice?: OrderPrice;
  showOrderPrice?: boolean;
};
/*
 * Информация о перевозчике на вкладке Заказ Исполнителя
 * */
export const OrderDetailsCarrierDriverInfo: FC<Props> = ({
  prepaymentValue = 0,
  orderOffer,
  hideOrderLogistInfo = false,
  onOrderStart,
  isOrderConfirmed = false,
  orderPrice = 0,
  showOrderPrice = false,
}) => {
  const { carrier, driver, vehicle } = orderOffer;
  const { preferredDirectionsString } = useExtendedObject(driver, driverExtender);
  const prepayment = prepaymentValue > 0 ? `${PREPAYMENT} ${toPriceFormat(prepaymentValue)}` : null;
  const offerPrice = !showOrderPrice ? orderOffer.price : orderPrice;
  return (
    <div className="order-details-carrier-driver-info">
      <SetCarrierDriverInfo carrier={carrier} vehicle={vehicle} driver={driver} hideOrderLogistInfo={hideOrderLogistInfo}>
        <>
          {/* Блок со статусами */}
          <HStack className="order-details-carrier-driver-info__statuses">
            {!hideOrderLogistInfo && prepayment && <PaymentChip type="advance" label={prepayment} />}

            <PaymentChip type="price" label={`${BY_PRICE} ${toPriceFormat(offerPrice)}`} />
          </HStack>
          {/* Предпочтиетльные направления */}
          {!hideOrderLogistInfo && <div className="order-details-carrier-driver-info__directions">{preferredDirectionsString}</div>}
          {/* Если есть право самоназначения - отображаем эту инфорамцию */}
          {!hideOrderLogistInfo && (
            <DriverOwnAssignedMark isOwnAssigned={driver?.isOwnAssigning} className="order-details-carrier-driver-info__assign" />
          )}
        </>
      </SetCarrierDriverInfo>
      {isOrderConfirmed && onOrderStart && (
        <HStack justifyContent="center">
          <UiButtonFilled onClick={onOrderStart}>{START_ORDER}</UiButtonFilled>
        </HStack>
      )}
    </div>
  );
};
