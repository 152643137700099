import { FC } from 'react';
import { CarrierFormData, FormMode } from 'components/verification-forms/types';
import { formValuesToCarrier } from 'components/verification-forms/helpers/form-values-to-carrier';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { ModalsProps } from '@/types/common/modals';
import { ModalCarrier } from '@/features/modals/carrier/modal-carrier';
import { useCarrierActions } from '@/features/carriers/hooks/use-carrier-actions';
import { useCreateApproval } from '@/features/approvals/hooks/use-create-approval';
import { useAccount } from '@/features/account/hooks/use-account';
import { useAttachmentsActions } from '@/features/attachments/hooks/use-attachments-actions';
import { AttachmentDeps } from '@/config/constants/types';
import { DocumentTypes } from '@/config/constants/document-types';
import { useCarrierInvalidate } from '@/features/carriers/hooks/use-carrier-invalidate';

export const ModalCreateCarrier: FC<ModalsProps<unknown>> = ({ onClose, modalOpened }) => {
  const { postAttachment } = useAttachmentsActions();
  const { createApproval } = useCreateApproval();
  const { addCarrier } = useCarrierActions(0);
  const { currentAccount } = useAccount();
  const { allCarriersInvalidate } = useCarrierInvalidate();
  const onCreateCarrier = (values: CarrierFormData) => {
    const { comment, ...newCarrier } = values;
    addCarrier.mutate(formValuesToCarrier(newCarrier), {
      onSuccess: (response) => {
        createApproval.mutate(
          {
            carrierId: response.data.id,
            comment: values.comment || EMPTY_STRING,
            departmentId: currentAccount?.user?.department?.id,
          },
          {
            onSuccess: () => {
              allCarriersInvalidate();
              onClose();
            },
          }
        );
        if (response.data.id) {
          values.passportDoc?.map((file: File) => {
            postAttachment.mutate({
              attachment: file,
              dependent: AttachmentDeps.Carrier,
              dependentID: response.data.id,
              subject: DocumentTypes.dlDocumentCarrierID,
            });
          });
          values.companyDoc?.map((file: File) => {
            postAttachment.mutate({
              attachment: file,
              dependent: AttachmentDeps.Carrier,
              dependentID: response.data.id,
              subject: DocumentTypes.dlDocumentCarrierTIN,
            });
          });
        }
      },
    });
  };

  return (
    <ModalCarrier
      title="Вы добавляете Организацию исполнителя"
      onSubmit={onCreateCarrier}
      modalOpened={modalOpened}
      onClose={onClose}
      mode={FormMode.create}
      submitting={addCarrier.isLoading}
    />
  );
};
