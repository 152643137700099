import { Permissions } from 'config/abac/permissions';
import { UserRole } from '@/types/api/user-role';
import { Rule } from '@/types/abac/rule';

export const rules: Rule = {
  [UserRole.Manager]: {
    [Permissions.TEST_RULE]: false,
  },
  [UserRole.Administrator]: {
    [Permissions.TEST_RULE]: true,
  },
  [UserRole.Guest]: {
    [Permissions.TEST_RULE]: false,
  },
};
