import { Dictionary } from 'config/constants/dictionary';
import { ApiGet } from '@/helpers/api/methods';
import { validateQueryStringAndCleanEmpty } from '@/helpers/validate-query-string-and-clean-empty';
import { ManagersFilter } from '@/types/api/managers-partial';
import { ManagerSimple } from '@/types/models/manager';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';

export const ManagersApi = {
  getAllManagers: (query?: ManagersFilter) => {
    return ApiGet<ResponseWithoutPagerSimple<Record<number, ManagerSimple>>>(`${Dictionary.Managers}${validateQueryStringAndCleanEmpty(query)}`);
  },

  getShortManagers: (query?: { updates?: boolean }) => {
    return ApiGet<ResponseWithoutPagerSimple<Record<number, string>>>(`${Dictionary.Managers}/enum?updates=${!!query?.updates}`); // возвращает список менеджеров без постраничной разбивки для селекта}
  },
};
