import { Box, HStack, Text } from '@chakra-ui/react';
import { CustomerSelectField } from 'components/form-field/customer-select-field/customer-select-field';
import { Namespaces } from 'components/create-order-form/schema';
import { FormMode } from '@/components/verification-forms/types';
import { UiDivider, UiInputField, UiSelectField } from '@/ui-elements';
import { UiPhoneField } from '@/ui-elements/form-fields/ui-phone-field/ui-phone-field';

type Props = {
  namespace: Namespaces | string;
  mode?: string;
};

const transportationOptions = [
  { value: 1, label: 'Отдельная машина' },
  { value: 2, label: 'Дозагрузка' },
];
const riskGroupOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
];

export const OrderInfoSubform = ({ namespace, mode = FormMode.create }: Props) => {
  return (
    <Box w="full">
      <Text textStyle="h3" align="left" mb={6}>
        Информация о заявке
      </Text>
      <HStack spacing={4}>
        <CustomerSelectField
          name={`${namespace}.customerCompany`}
          label="Организация (клиент)"
          placeholder={<Text noOfLines={1}>Начните вводить название клиента</Text>}
          isDisabled={mode === 'split'}
        />
        <UiSelectField
          name={`${namespace}.transportationOption`}
          options={transportationOptions}
          label="Вариант транспортировки"
          isDisabled={mode === 'split'}
          required
        />
      </HStack>
      <HStack spacing={4}>
        <HStack flexBasis="50%" spacing={4}>
          <UiInputField name={`${namespace}.id`} label="Номер заявки" isDisabled />
          <UiSelectField name={`${namespace}.responsiblePerson`} label="Ответственный" isDisabled options={[]} />
        </HStack>
        <Box flexBasis="50%">
          <UiSelectField
            name={`${namespace}.riskGroup`}
            label="Группа риска не меньше"
            options={riskGroupOptions}
            placeholder="1"
            isDisabled={mode === 'split'}
            required
          />
        </Box>
      </HStack>
      <HStack spacing={4}>
        <UiInputField
          name={`${namespace}.contactPerson`}
          label="Контактное лицо"
          placeholder="Имя Фамилия"
          content="Сотрудник организации клиента"
          isDisabled={mode === 'split'}
          required
        />
        <UiPhoneField
          name={`${namespace}.contactPhone`}
          label="Номер телефона контактного лица"
          placeholder="+7"
          content="Телефон контактного лица клиента"
          isDisabled={mode === 'split'}
          required
        />
      </HStack>
      <UiDivider variant="normal" mt={4} />
    </Box>
  );
};
