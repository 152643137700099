import { FC } from 'react';
import { CarrierOrganizationCard } from 'components/carrier-info/components/carrier-general-info/carrier-organization-card/carrier-organization-card';
import { Divider, Grid, GridItem } from '@chakra-ui/react';
import { CarrierInfoCard } from 'components/carrier-info/components/carrier-general-info/carrier-info-card/carrier-info-card';
import { CARRIER_DOCS, YOU_HAVE_CHOSEN } from 'components/security/security-details-organisation/texts';
import { DriverCardCompact } from 'components/driver-card-compact/driver-card-compact';
import { VehicleCardCompact } from 'components/vehicle-card-compact/vehicle-card-compact';
import { Carrier } from '@/types/models/carrier';
import { VoidFunction } from '@/types/common/void-function';
import { emptyFunction } from '@/helpers/empty-function';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { carrierExtender } from '@/features/carriers/extender/carrier-extender';
import './security-details-organisation.scss';
import { UiDownloadCard } from '@/ui-elements';
import { Driver } from '@/types/models/driver';
import { UiSpinner } from '@/ui-elements/ui-spinner/ui-spinner';
import { Vehicle } from '@/types/models/vehicle';

type Props = {
  carrier: Carrier;
  driver?: Driver;
  vehicle?: Vehicle;
  onEditClick?: VoidFunction;
  // подробнее о водителе
  onDriverMoreInfoClick?: (id: number) => void;
  // подробнее о ТС
  onVehicleMoreInfoClick?: (vehicle: Vehicle) => void;
  isFetching: boolean;
};
/**
 *  Блок безопасность: Детали по выбранной организации
 * */
export const SecurityDetailsOrganisation: FC<Props> = ({
  isFetching,
  carrier,
  driver,
  vehicle,
  onEditClick = emptyFunction,
  onDriverMoreInfoClick = emptyFunction,
  onVehicleMoreInfoClick = emptyFunction,
}) => {
  const { approveStatusCaption, phoneNumber, countVehicles, countDrivers, documents } = useExtendedObject(carrier, carrierExtender);
  if (isFetching) return <UiSpinner visible mt={48} />;
  return (
    <div className="security-details-organisation">
      {driver && (
        <div className="security-details-organisation__block">
          <div className="security-details-organisation__title">{YOU_HAVE_CHOSEN}</div>
          <DriverCardCompact
            driver={driver}
            department={carrier.department.name}
            className="security-details-organisation__selected"
            onMoreInfoClick={() => onDriverMoreInfoClick(driver?.id)}
          />
          <Divider className="security-details-organisation__divider" />
        </div>
      )}
      {vehicle && (
        <div className="security-details-organisation__block">
          <div className="security-details-organisation__title">{YOU_HAVE_CHOSEN}</div>
          <VehicleCardCompact
            vehicle={vehicle}
            department={carrier.department.name}
            className="security-details-organisation__selected"
            onMoreInfoClick={() => onVehicleMoreInfoClick(vehicle)}
          />
          <Divider className="security-details-organisation__divider" />
        </div>
      )}
      <CarrierOrganizationCard carrier={carrier} status={approveStatusCaption} hideRating onEditClick={onEditClick} />
      <Divider className="security-details-organisation__divider" />
      <CarrierInfoCard phone={phoneNumber} vehicleCount={countVehicles} driversCount={countDrivers} />
      {documents && (
        <>
          <Divider className="security-details-organisation__divider" />
          <div className="security-details-organisation__documents">
            <div className="security-details-organisation__title">{CARRIER_DOCS}</div>
            <Grid templateColumns="repeat(3, 1fr)" gap="20px">
              {documents.map((doc) => (
                <GridItem>
                  <UiDownloadCard key={`payment${doc.id}`} fileName={doc.name} url={doc.link} />
                </GridItem>
              ))}
            </Grid>
          </div>
        </>
      )}
    </div>
  );
};
