import { useQuery } from '@tanstack/react-query';
import { AttachmentQuery } from '@/types/models/attachments';
import { AttachmentsApi } from '@/api/attachments';
import { INTERVAL_REQUEST_DELAY } from '@/config/constants/interval-request-delay';

export const useAttachments = (query: AttachmentQuery, preventLoading?: boolean, staleTime?: number) => {
  const attachments = useQuery({
    queryKey: ['attachments', query],
    refetchOnWindowFocus: false,
    staleTime,
    refetchInterval: INTERVAL_REQUEST_DELAY * 10,
    enabled: !!query.dependentID && !preventLoading,
    queryFn: () => AttachmentsApi.getAttachments(query),
  });
  return { attachments };
};
