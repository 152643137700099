import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';
import { useIntermediaryResources } from '@/features/resources/hooks';

/** Для перевозчика с ролью аккаунта Посредник (intermediary)
 * если id перевозчика в заявке совпадает с id перевозчика текущего аккаунта,
 * то заявка создана из собственных ресурсов;
 * В этом случае не создаем договор-заявку для исполнителя, и при подтверждении договора-заявки с клиентом
 * сразу подтверждаем оба документа и переводим в следующий статус.
 */
export const useOrderWithOwnResources = ({ order }: { order?: Order | Load }) => {
  const { isIntermediaryRole, carrierId } = useIntermediaryResources();
  const orderWithOwnResources = isIntermediaryRole && carrierId === order?.carrier?.id;

  return { orderWithOwnResources };
};
