import { useQuery } from '@tanstack/react-query';
import { AttachmentDeps } from '@/config/constants/types';
import { DocumentTypes } from '@/config/constants/document-types';
import { AttachmentsApi } from '@/api/attachments';

type UseGetMyOrganizationDocuments = {
  organizationID: number | undefined;
  driverId: number | undefined;
};

export const useGetMyOrganizationDocuments = ({ organizationID, driverId }: UseGetMyOrganizationDocuments) => {
  const { data: passportDocuments } = useQuery({
    queryKey: ['passportAttachments', organizationID],
    refetchOnWindowFocus: false,
    queryFn: () =>
      AttachmentsApi.getAttachments({
        dependent: AttachmentDeps.Organization,
        dependentID: organizationID ?? 0,
        subject: DocumentTypes.dlDocumentOrganizationManagerPassport,
      }),
  });

  const { data: innOgrnDocuments } = useQuery({
    queryKey: ['innOgrnAttachments', organizationID],
    refetchOnWindowFocus: false,
    queryFn: () =>
      AttachmentsApi.getAttachments({
        dependent: AttachmentDeps.Organization,
        dependentID: organizationID ?? 0,
        subject: DocumentTypes.dlDocumentOrganizationInnOgrn,
      }),
  });

  const { data: driverLicenseDoc } = useQuery({
    queryKey: ['driverLicenseAttachments', driverId],
    refetchOnWindowFocus: false,
    enabled: !!driverId,
    queryFn: () =>
      AttachmentsApi.getAttachments({
        dependent: AttachmentDeps.Driver,
        dependentID: driverId ?? 0,
        subject: DocumentTypes.dlDocumentDriverLicense,
      }),
  });

  return { driverLicenseDoc, innOgrnDocuments, passportDocuments };
};
