import { FC } from 'react';
import { PageWrapper } from 'pages/page-wrapper';
import { UiUnderConstruct } from '@/ui-elements';

export const StatisticsPage: FC = () => (
  <PageWrapper>
    {' '}
    <UiUnderConstruct />
  </PageWrapper>
);
