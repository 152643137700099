import { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { formValuesToPrepayment } from 'components/verification-forms/helpers/form-values-to-prepayment';
import { toast } from 'react-toastify';
import { UiStatus } from '@/ui-elements/status/status';
import { UiButtonOutlined } from '@/ui-elements';
import { ModalAction, useModal } from '@/hooks/use-modal';
import { ModalsProps, ModalType } from '@/types/common/modals';
import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';
import { useOrderPrepayment } from '@/features/order/hooks/use-order-prepayment';
import { PrepaymentModalProps } from '@/features/modals/modal-prepayment';
import { useOrderPayment } from '@/features/order/hooks/use-order-payment';
import { PrepaymentFormValues } from '@/types/models/prepayment';
import { useOrdersWithMyParticipationUpdates } from '@/features/order/hooks/use-orders-with-my-participation-updates';
import { checkHasVat } from '@/helpers/price/check-has-vat';

type Props = {
  order: Order | Load;
};

export const PrepaymentWidget: FC<Props> = ({ order }) => {
  const { setOrderPrepaymentPaid } = useOrderPayment(order.id);
  const { refetch: orderRefetch } = useOrdersWithMyParticipationUpdates({ id: order.id });

  const prepaymentProps = useOrderPrepayment({
    order,
    vat: checkHasVat(order.carrierPayment.vatType),
  });

  const { modalView, setModalOpened } = useModal<ModalAction & ModalsProps<PrepaymentModalProps>['initialValues'] & PrepaymentModalProps>(
    ModalType.Prepayment,
    {
      prepaymentProps,
      initialValues: { prepayment: prepaymentProps.prepayment },
      onSubmit: (values: PrepaymentFormValues) => {
        setOrderPrepaymentPaid.mutate(formValuesToPrepayment(values), {
          onSuccess: () => {
            orderRefetch();
            setModalOpened(false);
            toast.success('Запрос на аванс отправлен');
          },
        });
      },
    }
  );

  return (
    <Flex gap="1em" align="center" direction="column">
      <UiStatus
        backGroundColor="products.200"
        color="typography.250"
        text={prepaymentProps.prepaymentPossibility ? 'Аванс доступен' : 'Без авансирования'}
      />
      {prepaymentProps.hasPrepayment && (
        <>
          <UiButtonOutlined onClick={() => setModalOpened(true)}>К оформлению аванса</UiButtonOutlined>
          {modalView}
        </>
      )}
    </Flex>
  );
};
