import { FC } from 'react';
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { VerificationFormMode } from 'components/verification-forms/types';
import { ModalsProps, ModalType } from '@/types/common/modals';
import { ModalOrganization } from '@/features/modals/organization/modal-organization';
import { useModal } from '@/hooks/use-modal';
import { FileDocument } from '@/types/models/document';

export const ModalOrganizationView: FC<
  ModalsProps<{
    mode?: VerificationFormMode;
    initialValues?: any;
    attachmentsStampDoc?: Array<FileDocument>;
    deleteStampDoc?: (documentId: number) => void;
    postStampDoc?: (files: Array<File>) => void;
    downloadStampDoc?: VoidFunction;
    attachmentsSignatureDoc?: Array<FileDocument>;
    deleteSignatureDoc?: (documentId: number) => void;
    postSignatureDoc?: (files: Array<File>) => void;
    downloadSignatureDoc?: VoidFunction;
    submitting?: boolean;
  }>
> = ({
  onClose,
  onCancel,
  onSubmit,
  modalOpened,
  mode,
  initialValues,
  attachmentsStampDoc,
  deleteStampDoc,
  postStampDoc,
  downloadStampDoc,
  attachmentsSignatureDoc,
  deleteSignatureDoc,
  postSignatureDoc,
  downloadSignatureDoc,
  ...props
}) => {
  const { modalView: confirmModal, setModalOpened: setConfirmModalOpened } = useModal(ModalType.Confirm, {
    onCloseModal: () => setConfirmModalOpened(false),
    onSuccess: () => {
      onCancel?.();
      onClose?.();
    },
    modalWidth: 524,
    text: `Вы действительно хотите отменить ${
      {
        create: 'добавление',
        edit: 'редактирование',
        check: 'проверку',
        banking: 'добавление банковских реквизитов',
        editBanking: 'редактирование банковских реквизитов',
      }[mode!]
    } организации?`,
    desc: 'Измененные данные не сохранятся',
    alignDesc: 'left',
    cancelButtonText: 'Назад',
    submitButtonText: 'Отменить',
    accentXBtn: true,
  });
  return (
    <Modal isOpen={modalOpened} onClose={() => setConfirmModalOpened(true)}>
      <ModalOverlay />
      <ModalContent borderRadius={24} boxShadow="xl" pos="relative" maxW={798} w={798} p={10}>
        {confirmModal}
        <ModalOrganization
          onClose={onClose}
          onCancel={onCancel}
          mode={mode}
          initialValues={initialValues}
          setConfirmModalOpened={setConfirmModalOpened}
          onSubmit={onSubmit}
          attachmentsStampDoc={attachmentsStampDoc}
          deleteStampDoc={deleteStampDoc}
          postStampDoc={postStampDoc}
          downloadStampDoc={downloadStampDoc}
          attachmentsSignatureDoc={attachmentsSignatureDoc}
          deleteSignatureDoc={deleteSignatureDoc}
          postSignatureDoc={postSignatureDoc}
          downloadSignatureDoc={downloadSignatureDoc}
          {...props}
        />
      </ModalContent>
    </Modal>
  );
};
