import { FC } from 'react';
import { OrderStatusColorScheme } from 'config/constants/order-status-colors';
import { HStack } from '@chakra-ui/react';
import { OrderGeneralDistance } from 'components/order-general-distance/order-general-distance';
import { OrderGeneralInfo } from 'components/order-general-info/order-general-info';
import { OrderRouteCompact } from 'components/order-route-compact/order-route-compact';
import { Order } from '@/types/models/order';
import { CLIENT } from '@/features/order/texts';
import { UiStatus } from '@/ui-elements/status/status';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { orderExtender } from '@/features/order/extender/order-extender';
import './set-carrier-widget-content.scss';
import { useAppSelector } from '@/hooks/use-app-selector';
import { orderStatusesSelector } from '@/features/order/selectors/order-statuses-selector';
import { OrderStatus } from '@/config/constants/statuses';
import { Load } from '@/types/models/loads';

export const SetCarrierOrderInfo: FC<{ order?: Order | Load }> = ({ order }) => {
  // @ts-ignore
  const extendedObject = useExtendedObject(order, orderExtender);
  const { customer, status, points } = extendedObject;
  const orderStatuses = useAppSelector(orderStatusesSelector);
  const statusText = order?.status ? orderStatuses?.orderStatuses?.[order.status as OrderStatus] : '';
  return (
    <div className="set-carrier__order">
      <div className="set-carrier__customer">
        {CLIENT}:{customer?.name}
      </div>
      <div>
        <UiStatus
          text={statusText || ''}
          backGroundColor={OrderStatusColorScheme[status]?.background}
          color={OrderStatusColorScheme[status]?.color}
        />
      </div>
      <OrderRouteCompact points={points} route={order} />
      <HStack className="set-carrier__info">
        <OrderGeneralDistance range={order?.distance} type={order?.type} />
        <OrderGeneralInfo
          className="order-details__general-info"
          vehicleTypes={order?.vehicleTypes}
          shippedTotals={order?.shippedTotals}
          loadingType={order?.loadingType || []}
        />
      </HStack>
    </div>
  );
};
