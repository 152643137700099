import { OptionBase } from 'chakra-react-select';

export type SelectOption = {
  value: string | number;
  label: string | number | undefined;
} & OptionBase;

export type SelectLoadOptions = (inputValue: string, callback: (options: Array<SelectOption>) => void) => void;

export const isSelectOption = (option?: string | SelectOption): option is SelectOption => {
  return (option as SelectOption)?.value !== undefined;
};
