import './funnel-list.scss';
import { FC, useState } from 'react';
import { isEmpty } from 'lodash';
import { Box } from '@chakra-ui/react';
import { Load } from '@/types/models/loads';
import { ModalType } from '@/types/common/modals';
import { FetchNextPageType } from '@/types/models/pager';
import { InfiniteScroll } from '@/views/infinite-scroll/infinite-scroll';
import { useAppSelector } from '@/hooks/use-app-selector';
import { ModalAction, useModal } from '@/hooks/use-modal';
import { funnelUpdatingSelector } from '@/features/funnel/selectors/funnel-updating-selector';
import { activeFunnelTabSelector } from '@/features/funnel/selectors/active-funnel-tab-selector';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { LoadsCard } from '@/components/loads/card/loads-card';
import { scrollStyle } from '@/components/order-details/order-details-component/constants';
import { LoadsEmpty } from '@/components/loads/empty/loads-empty';
import { getFunnelListEmptyText } from '@/features/funnel/helpers/get-funnel-list-empty-text';

interface Props {
  loadsList?: Array<Load>;
  currentLoadID: number | undefined;
  isLoading: boolean;
  userHasIntegration: boolean;
  handleSelectLoad: (id?: number) => void;
  fetchNextPage: FetchNextPageType<Load>;
}

export const FunnelList: FC<Props> = ({ loadsList, currentLoadID, isLoading, userHasIntegration, handleSelectLoad, fetchNextPage }) => {
  const activeTab = useAppSelector(activeFunnelTabSelector);
  const loadIsUpdating = useAppSelector(funnelUpdatingSelector);
  const [selectedLoadID, setSelectedLoadID] = useState<number | undefined>(undefined);
  const emptyListText = getFunnelListEmptyText({ userHasIntegration, activeTab });

  const { modalView: ModalCloseUpdateLoad, setModalOpened: showCloseUpdateLoad } = useModal<
    { text: string; selectNewLoad: () => void } & ModalAction
  >(ModalType.CloseUpdateLoad, {
    text: 'Перейти на другую заявку?',
    selectNewLoad: () => handleSelectLoad(selectedLoadID),
  });

  const handleClickSelectLoad = (id?: number) => {
    if (loadIsUpdating) {
      setSelectedLoadID(id);
      showCloseUpdateLoad(true);
    } else {
      handleSelectLoad(id);
    }
  };

  return (
    <Box className="funnel-list">
      {!isEmpty(loadsList) ? (
        <UiScrollbars autoHide style={scrollStyle}>
          <Box className="funnel-list__content">
            <InfiniteScroll callback={fetchNextPage} loading={isLoading} skip={isEmpty(loadsList)}>
              {loadsList?.map((load) => (
                <LoadsCard
                  load={load}
                  currentLoadID={currentLoadID}
                  handleSelectLoad={handleClickSelectLoad}
                  isFunnelCard
                  key={load?.id}
                  activeTab={activeTab}
                />
              ))}
            </InfiniteScroll>
          </Box>
        </UiScrollbars>
      ) : (
        <LoadsEmpty text={emptyListText} visible />
      )}
      {ModalCloseUpdateLoad}
    </Box>
  );
};
