import { FC } from 'react';
import { PrepaymentWidget, PrepaymentWidgetProps } from 'components/widgets/payment/prepayment-widget';
import { ModalView } from '@/views/order-modal/order-modal-view';
import { ModalsProps } from '@/types/common/modals';

export type PrepaymentModalProps = {
  prepaymentProps?: PrepaymentWidgetProps;
};

export const ModalPrepayment: FC<ModalsProps<PrepaymentModalProps>> = ({ modalOpened, onClose, onSubmit, prepaymentProps, initialValues }) => {
  if (!onSubmit || !prepaymentProps) return null;

  return (
    <ModalView
      marginTop="30vh"
      initialValues={initialValues}
      isOpen={modalOpened}
      onClose={onClose}
      title="Оформление аванса"
      onSubmit={onSubmit}
      submitButtonText="Запросить"
    >
      <PrepaymentWidget
        prepayment={prepaymentProps.prepayment}
        setPrepayment={prepaymentProps.setPrepayment}
        maxPrepayment={prepaymentProps.maxPrepayment}
        prepaymentStep={prepaymentProps.prepaymentStep}
        tax={prepaymentProps.tax}
      />
    </ModalView>
  );
};
