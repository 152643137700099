import { ResourcesDriverDetailsComponent } from '@/components/resources/driver-details-component/resources-driver-details-component';
import { useDriverDetailsView } from '@/features/resources/hooks';

export const ResourcesDriverDetailsView = () => {
  const {
    driver,
    driverPassportDocs,
    driverLicenseDocs,
    loading,
    ModalDeleteDriver,
    ModalResetPassword,
    ModalEditNotify,
    handleDeleteDriver,
    handleEditDriver,
    handleShowResetPasswordModal,
  } = useDriverDetailsView();

  return (
    <>
      <ResourcesDriverDetailsComponent
        driver={driver}
        driverPassportDocs={driverPassportDocs}
        driverLicenseDocs={driverLicenseDocs}
        loading={loading}
        handleDeleteDriver={handleDeleteDriver}
        handleEditDriver={handleEditDriver}
        handleShowResetPasswordModal={handleShowResetPasswordModal}
      />
      {ModalDeleteDriver}
      {ModalEditNotify}
      {ModalResetPassword}
    </>
  );
};
