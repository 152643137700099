import { FC, ReactElement } from 'react';
import classNames from 'classnames';
import { VStack } from '@chakra-ui/react';
import { emptyFunction } from '@/helpers/empty-function';
import { useIcons } from '@/hooks/use-icons';
import { UiIconButton } from '@/ui-elements';
import './ui-sidebar.scss';

type Props = {
  onClose?: () => void;
  children: ReactElement;
  className?: string;
};
export const UiCloseablePortal: FC<Props> = ({ onClose = emptyFunction, children, className }) => {
  const { CloseIcon } = useIcons();
  return (
    <VStack className={classNames(className, 'ui-sidebar__closeable')}>
      <UiIconButton className="ui-sidebar__close-button" aria-label="close" icon={CloseIcon} onClick={() => onClose()} />
      <div className="ui-sidebar__content">{children}</div>
    </VStack>
  );
};
