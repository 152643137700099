import { FC } from 'react';
import { UISidebar } from '@/ui-elements/ui-sidebar/ui-sidebar';
import './settings-sidebar-view.scss';
import { SettingsDetailsView } from '@/views/settings/settings-details-view';

/**
 * Сайдбар для страницы настройки
 * * */
export const SettingsSidebarView: FC = () => {
  return (
    <UISidebar className="settings-sidebar-view" isOpen>
      <SettingsDetailsView />
    </UISidebar>
  );
};
