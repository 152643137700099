import { FC } from 'react';
import { PageWrapper } from 'pages/page-wrapper';
import { Flex } from '@chakra-ui/react';
import { OrdersWithMyParticipationView } from '@/views/carriers-loads/order-with-my-participation/orders-with-my-participation-view';

export const OrdersWithMyParticipationPage: FC = () => {
  return (
    <PageWrapper>
      <Flex>
        <section className="orders-with-my-participation">
          <OrdersWithMyParticipationView />
        </section>
      </Flex>
    </PageWrapper>
  );
};
