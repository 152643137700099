import { AttachmentDeps } from 'config/constants/types';
import { DocumentTypes } from 'config/constants/document-types';
import { VerificationFormMode } from 'components/verification-forms/types';
import { useDependentAttachments } from '@/features/attachments/hooks/use-dependent-attachments';

export const useDriverDocsSubform = ({ mode, driverId }: { mode: VerificationFormMode; driverId: number }) => {
  const {
    attachments: attachmentsPassport,
    deleteAttachmentById: deleteAttachmentPassportById,
    postAttachments: postAttachmentsPassport,
    downloadAttachments: downloadAttachmentsPassport,
  } = useDependentAttachments({
    dependent: AttachmentDeps.Driver,
    dependentId: driverId,
    type: DocumentTypes.dlDocumentDriverPassport,
  });

  const {
    attachments: attachmentsLicense,
    deleteAttachmentById: deleteAttachmentLicenseById,
    postAttachments: postAttachmentsLicense,
    downloadAttachments: downloadAttachmentsLicense,
  } = useDependentAttachments({
    dependent: AttachmentDeps.Driver,
    dependentId: driverId,
    type: DocumentTypes.dlDocumentDriverLicense,
  });

  const {
    attachments: attachmentsVehicleBack,
    deleteAttachmentById: deleteAttachmentContractById,
    postAttachments: postAttachmentsContract,
    downloadAttachments: downloadAttachmentsContract,
  } = useDependentAttachments({
    dependent: AttachmentDeps.Driver,
    dependentId: driverId,
    type: DocumentTypes.dlDocumentDriverContract,
  });

  const isCheck = mode === 'check';
  const isEdit = mode === 'edit';
  const isFileUploadField = !isCheck && !isEdit;

  const documentsFields: Array<{
    name: string;
    label: string;
    actions: {
      savedFileList?: Array<{ id: number; name: string; extension: string }>;
      onDeleteFileClick?: (documentId: number) => void;
      onIconClick?: (documentId: number) => void;
      onImmediateUploadFile?: (file: File[]) => void;
    };
  }> = [
    {
      name: 'passportDoc',
      label: '1. Разворот паспорта с фотографией',
      actions: {
        savedFileList: attachmentsPassport,
        onDeleteFileClick: deleteAttachmentPassportById,
        onIconClick: downloadAttachmentsPassport,
        onImmediateUploadFile: postAttachmentsPassport,
      },
    },
    {
      name: 'driversLicenseDoc',
      label: '2. Водительское удостоверение, обе стороны в одном файле на одной странице',
      actions: {
        savedFileList: attachmentsLicense,
        onDeleteFileClick: deleteAttachmentLicenseById,
        onIconClick: downloadAttachmentsLicense,
        onImmediateUploadFile: postAttachmentsLicense,
      },
    },
    {
      name: 'laborContractDoc',
      label: '3. Трудовой договор (если вы являетесь владельцем ИП то документы ОГРНИП)',
      actions: {
        savedFileList: attachmentsVehicleBack,
        onDeleteFileClick: deleteAttachmentContractById,
        onIconClick: downloadAttachmentsContract,
        onImmediateUploadFile: postAttachmentsContract,
      },
    },
  ];

  return {
    documentsFields,
    isCheck,
    isFileUploadField,
  };
};
