import './loads-filters.scss';
import { Box, Grid, GridItem, HStack } from '@chakra-ui/react';
import { LoadsFiltersField, LoadsFiltersFieldLabel } from '@/types/models/loads';
import { EMPTY_STRING } from '@/config/constants/common-strings';
import { LoadsFiltersHelpText } from '@/config/constants/loads';
import { UiCheckboxField, UiInputField, UiMultiDatePickerField } from '@/ui-elements';
import { UiVehicleTypesSelect } from '@/ui-elements/form-fields/ui-vehicle-type-select/ui-vehicle-type-select';
import { LoadsTariffRadioButtons } from '@/components/loads/tariff-radio-buttons/loads-tariff-radio-buttons';

export const LoadsFilters = ({ disabled = false }: { disabled: boolean }) => {
  return (
    <Box className="loads-filters">
      <fieldset disabled={disabled}>
        <Grid templateColumns="repeat(3, 1fr)" gap={2}>
          <GridItem>
            <UiInputField
              name={LoadsFiltersField.From}
              label={LoadsFiltersFieldLabel.From}
              content={LoadsFiltersHelpText.From}
              placeholder={EMPTY_STRING}
            />
          </GridItem>
          <GridItem>
            <UiInputField
              name={LoadsFiltersField.To}
              label={LoadsFiltersFieldLabel.To}
              content={LoadsFiltersHelpText.To}
              placeholder={EMPTY_STRING}
            />
          </GridItem>
          <GridItem>
            <HStack spacing={2}>
              <UiMultiDatePickerField
                name={LoadsFiltersField.DateFrom}
                label={LoadsFiltersFieldLabel.DateFrom}
                format="YYYY/MM/DD"
                isMulti={false}
                onOpenPickNewDate={false}
              />
              <UiMultiDatePickerField
                name={LoadsFiltersField.DateTo}
                label={LoadsFiltersFieldLabel.DateTo}
                format="YYYY/MM/DD"
                isMulti={false}
                onOpenPickNewDate={false}
                calendarPosition="bottom-end"
                fixMainPosition
                fixRelativePosition
              />
            </HStack>
          </GridItem>
          <GridItem>
            <UiVehicleTypesSelect
              label={LoadsFiltersFieldLabel.Vehicle}
              name={LoadsFiltersField.Vehicle}
              isDisabled={disabled}
              placeholder={EMPTY_STRING}
              content="Максимальный вес перевозки 20 тонн"
            />
          </GridItem>
          <GridItem>
            <LoadsTariffRadioButtons disabled={disabled} />
          </GridItem>
          <GridItem alignSelf="start" marginTop="28px">
            <UiCheckboxField name={LoadsFiltersField.SingleLoading} label={LoadsFiltersFieldLabel.SingleLoading} isDisabled={disabled} />
          </GridItem>
        </Grid>
      </fieldset>
    </Box>
  );
};
