import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MyOrganizationsApi } from '@/api/my-organizations';
import { MyOrganizationBanking, MyOrganizationType } from '@/types/models/my-organization/my-organization';

export const useOrganizationActions = (id?: number) => {
  const queryClient = useQueryClient();
  const updateOrganization = useMutation({
    mutationKey: ['updateOrganization', id],
    mutationFn: (options: Partial<MyOrganizationType>) => MyOrganizationsApi.updateOrganization(id!, options),
    onSuccess: () => {
      queryClient.invalidateQueries(['organization', id]);
    },
  });

  const addOrganization = useMutation({
    mutationKey: ['addOrganization'],
    mutationFn: (options: Partial<MyOrganizationType>) => MyOrganizationsApi.addOrganization(options),
    onSuccess: () => {
      queryClient.invalidateQueries(['allOrganizations']);
    },
  });

  const deleteOrganization = useMutation({
    mutationKey: ['deleteOrganization'],
    mutationFn: (organizationId: number) => MyOrganizationsApi.deleteOrganization(organizationId),
    onSuccess: () => {
      queryClient.invalidateQueries(['allOrganizations']);
    },
  });

  const addOrganizationBanking = useMutation({
    mutationKey: ['addOrganizationBanking', id],
    mutationFn: ({ organizationId, options }: { organizationId: number; options: Partial<MyOrganizationBanking> }) =>
      MyOrganizationsApi.addOrganizationBanking(organizationId!, options),
    onSuccess: () => {
      queryClient.invalidateQueries(['organizationBanking', id]);
    },
  });

  const updateOrganizationBanking = useMutation({
    mutationKey: ['updateOrganizationBanking', id],
    mutationFn: ({ bankId, options }: { bankId: number; options: Partial<MyOrganizationBanking> }) =>
      MyOrganizationsApi.updateOrganizationBanking(id!, bankId, options),
    onSuccess: () => {
      queryClient.invalidateQueries(['organizationBanking', id]);
    },
  });

  const deleteOrganizationBanking = useMutation({
    mutationKey: ['deleteOrganizationBanking'],
    mutationFn: (bankId: number) => MyOrganizationsApi.deleteOrganizationBanking(id!, bankId),
    onSuccess: () => {
      queryClient.invalidateQueries(['organizationBanking', id]);
    },
  });

  return {
    updateOrganization,
    addOrganization,
    deleteOrganization,
    addOrganizationBanking,
    updateOrganizationBanking,
    deleteOrganizationBanking,
  };
};
