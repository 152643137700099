export const amountTableConfigForManager = {
  columns: [
    {
      id: '1',
      name: 'Клиенты',
      sorting: false,
    },
    {
      id: '2',
      name: 'Обработано заявок',
      sorting: true,
    },
    {
      id: '3',
      name: 'Сорвано',
      sorting: true,
    },
    {
      id: '4',
      name: 'Выполнено',
      sorting: true,
    },
    {
      id: '5',
      name: 'Маржа',
      sorting: true,
    },
  ],
  cellClassName: 'analytics-component__table-td',
  rowClassName: 'analytics-component__table-tr',
  footerData: ['ИТОГО ЗА ПЕРИОД', '1200', '7', '1193', '2 880 523 ₽'],
};
