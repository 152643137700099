import '../resources-form.scss';
import { FC } from 'react';
import { Form } from 'react-final-form';
import { Box, HStack, Heading } from '@chakra-ui/react';
import { emptyFunction } from '@/helpers/empty-function';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';
import { UiButtonFilled, UiButtonOutlined, UiDivider } from '@/ui-elements';
import { FormValues } from '@/components/verification-forms/types';
import { listScrollStyle, ResourcesButtonActions } from '@/features/resources/constants/general';
import { UseDependentAttachmentsReturnValues } from '@/features/attachments/hooks/use-dependent-attachments';
import { VehicleFormErrors } from '@/types/models/vehicle';
import { ResourcesSidebarMode } from '@/features/resources/types/general';
import { ResourcesFormFieldNames, ResourcesVehicleFormTitles } from '@/features/resources/constants/form';
import { ResourcesSubformTrailer } from '@/components/resources/forms/vehicle/subform-trailer/resources-subform-trailer';
import { ResourcesSubformGeneralInfo } from '@/components/resources/forms/vehicle/subform-general-info/resources-subform-general-info';
import { ResourcesSubformOwnType } from '@/components/resources/forms/vehicle/subform-own-type/vehicle-subform-own-type';
import { ResourcesSubformTracking } from '@/components/resources/forms/vehicle/subform-tracking/resources-subform-tracking';
import { ResourcesSubformVehicleDocuments } from '@/components/resources/forms/vehicle/subform-vehicle-documents/resources-subform-vehicle-documents';
import { ResourcesSubformContract } from '@/components/resources/forms/vehicle/subform-contract/resources-subform-contract';
import { VehicleGroupEnum, VehicleOwnTypeEnum } from '@/components/verification-forms/constants';

interface Props {
  initialValues?: FormValues;
  vehicleInitialDocs: Partial<Record<ResourcesFormFieldNames, UseDependentAttachmentsReturnValues>>;
  mode: ResourcesSidebarMode;
  submitHandlers: Partial<Record<ResourcesSidebarMode, (values: any) => void>>;
  withoutTrailer: boolean;
  vehicleGroup: VehicleGroupEnum;
  ownType: VehicleOwnTypeEnum;
  closeForm: VoidFunction;
  validate: (values: FormValues) => VehicleFormErrors;
  handleChangeVehicleType: (val: string) => void;
  handleChangeWithoutTrailer: VoidFunction;
  handleChangeOwnType: (val: string) => void;
}

export const ResourcesVehicleForm: FC<Props> = ({
  initialValues,
  vehicleInitialDocs,
  mode,
  submitHandlers,
  withoutTrailer,
  vehicleGroup,
  ownType,
  closeForm,
  validate,
  handleChangeVehicleType,
  handleChangeWithoutTrailer,
  handleChangeOwnType,
}) => {
  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      keepDirtyOnReinitialize
      onSubmit={submitHandlers[mode] ?? emptyFunction}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="resources-form">
          <Heading as="h2" className="resources-form-title">
            {ResourcesVehicleFormTitles[mode]}
          </Heading>
          <UiDivider />
          <Box className="resources-form-rows">
            <UiScrollbars autoHide style={listScrollStyle}>
              <Box className="resources-form-content">
                {/* общая информация о тс */}
                <ResourcesSubformGeneralInfo
                  initialValues={initialValues}
                  withoutTrailer={withoutTrailer}
                  vehicleGroup={vehicleGroup}
                  handleChangeVehicleType={handleChangeVehicleType}
                  handleChangeWithoutTrailer={handleChangeWithoutTrailer}
                />
                {/* прицеп */}
                {!withoutTrailer && <ResourcesSubformTrailer />}
                {/* право владения */}
                <UiDivider className="resources-form-divider" />
                <ResourcesSubformOwnType ownType={ownType} handleChangeOwnType={handleChangeOwnType} />
                {/* договор */}
                <UiDivider className="resources-form-divider" />
                <ResourcesSubformContract mode={mode} vehicleInitialDocs={vehicleInitialDocs} />
                {/* трекинг */}
                <UiDivider className="resources-form-divider" />
                <ResourcesSubformTracking />
                {/* документы */}
                <UiDivider className="resources-form-divider" />
                <ResourcesSubformVehicleDocuments mode={mode} vehicleInitialDocs={vehicleInitialDocs} />
              </Box>
              <HStack className="resources-form-buttons">
                <UiButtonOutlined onClick={closeForm}>{ResourcesButtonActions.Cancel}</UiButtonOutlined>
                <UiButtonFilled type="submit" isDisabled={false} isLoading={false}>
                  {ResourcesButtonActions.Save}
                </UiButtonFilled>
              </HStack>
            </UiScrollbars>
          </Box>
        </form>
      )}
    />
  );
};
