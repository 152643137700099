import '../form/loads-form.scss';
import { FC } from 'react';
import { UiButton } from '@/ui-elements';
import { UPDATE_LOADS } from '@/config/constants/loads';
import { useAppSelector } from '@/hooks/use-app-selector';
import { userHasIntegrationSelector } from '@/features/loads/selectors/user-has-integration';

interface Props {
  disabled: boolean;
  handleRefetchLoads: () => void;
}

export const LoadsUpdateButton: FC<Props> = ({ disabled, handleRefetchLoads }) => {
  const userHasIntegration = useAppSelector(userHasIntegrationSelector);
  const onClickRefetchLoads = () => {
    handleRefetchLoads();
  };

  return (
    <UiButton
      variant="ghost"
      className="loads-form__update-button"
      size="small"
      loadingText={UPDATE_LOADS}
      isLoading={disabled && userHasIntegration}
      isDisabled={disabled}
      onClick={onClickRefetchLoads}
    >
      {UPDATE_LOADS}
    </UiButton>
  );
};
