import { CustomersApi } from '@/api/customers';
import { normalizeSelectOptionsResponce } from '@/helpers/api/normalize-select-options-responce';
import { useQueryWithUpdates } from '@/hooks/use-query-with-updates';
import { ResponseWithoutPagerSimple } from '@/types/models/pager';

export const useCustomersEnumWithExtrasUpdates = ({ query = '', extraParams }: { query: string; extraParams?: Array<string> }) => {
  const queryFn = (isFirst: boolean) =>
    CustomersApi.getCarriersEnumWithExtras({
      query,
      updates: !isFirst,
      extraParams,
    });

  const carriersEnum = useQueryWithUpdates<ResponseWithoutPagerSimple<Record<string, string>>>({
    queryKey: ['carriersEnumWithExtras'],
    refetchOnWindowFocus: false,
    queryFn,
  });
  const carriersEnumWithExtras = normalizeSelectOptionsResponce(carriersEnum?.data?.data || []);
  return {
    carriersEnumWithExtras,
  };
};
