import { FC } from 'react';
import { Mass } from 'components/widgets/vehicle/profile/mass/mass';
import { Volume } from 'components/widgets/vehicle/profile/volume/volume';
import { Flex } from '@chakra-ui/react';
import { UiDivider } from '@/ui-elements';

type Props = Partial<{
  mass: string | number | null | undefined;
  volume: string | number | null | undefined;
}>;

export const VehicleProfileWidget: FC<Props> = ({ mass, volume }) => {
  return (
    <Flex gap="1em">
      <Mass className="order-general-info__tonage" mass={mass} />
      {volume && <UiDivider centered />}
      <Volume className="order-general-info__volume" volume={volume} />
    </Flex>
  );
};
