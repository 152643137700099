import './load-titles.scss';
import { FC } from 'react';
import { HStack } from '@chakra-ui/react';
import { UiText } from '@/ui-elements/ui-text/ui-text';
import { FreightExchangePlatformLogo } from '@/components/loads/freight-exchange-platform-logo/freight-exchange-platform-logo';
import { FreightExchangePlatform } from '@/types/models/loads';

interface Props {
  name: string;
  id: string | number;
  freightExchangePlatform?: FreightExchangePlatform;
}

export const LoadTitles: FC<Props> = ({ name, id, freightExchangePlatform }) => {
  return (
    <HStack alignItems="center" spacing="3" className="load-titles">
      <FreightExchangePlatformLogo platform={freightExchangePlatform} />
      <HStack alignItems="center">
        <UiText className="load-titles__company">{name}</UiText>
        <UiText className="load-titles__id">№{id}</UiText>
      </HStack>
    </HStack>
  );
};
