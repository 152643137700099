import './orders-with-my-participation.scss';
import { FC } from 'react';
import { OrdersWithMyParticipationListView } from '@/views/carriers-loads/order-with-my-participation/orders-with-my-participation-list-view';
import { OrdersWithMyParticipationSidebarView } from '@/views/carriers-loads/order-with-my-participation/orders-with-my-participation-sidebar-view';

export const OrdersWithMyParticipationView: FC = () => {
  return (
    <>
      <OrdersWithMyParticipationListView />
      <OrdersWithMyParticipationSidebarView />
    </>
  );
};
