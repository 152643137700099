import { FC } from 'react';
import { NOT_SET } from 'config/constants/common-strings';
import { HStack } from '@chakra-ui/react';
import { Point } from '@/types/models/point';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { pointExtender } from '@/features/order/extender/order-point-extender';
import { useIcons } from '@/hooks/use-icons';
import './order-point-info-compact.scss';
import { Order } from '@/types/models/order';
import { Loading } from '@/types/models/loading';
import { Load } from '@/types/models/loads';

export const OrderPointInfoCompact: FC<{ point?: Point; isFirstPoint: boolean; route?: Order | Load }> = ({ point, isFirstPoint, route }) => {
  const { DividerArrowIcon } = useIcons();
  const pointExtended = useExtendedObject(
    {
      point,
      cargos: route?.cargos,
      action: route?.actions,
    } as Loading,
    pointExtender
  );

  return (
    <HStack className="order-point-info-compact__pointname">
      <>
        {!isFirstPoint && <DividerArrowIcon />}
        {pointExtended.point.city || NOT_SET}
      </>
    </HStack>
  );
};
