import './funnel-calculation-details.scss';
import { FC } from 'react';
import { HStack, VStack, Text } from '@chakra-ui/react';
import { Load } from '@/types/models/loads';
import { useIcons } from '@/hooks/use-icons';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { loadExtender } from '@/features/loads/extender/load-extender';
import { getDetailsTitlePrice } from '@/features/loads/helpers/get-details-title-price';
import { orderActions } from '@/features/order/texts';
import { UiButtonOutlined } from '@/ui-elements';
import { PRICE_FOR_CARRIER, PRICE_FOR_CLIENT, PRICE_FROM_CLIENT } from '@/config/constants/loads';
import { COLON } from '@/config/constants/common-strings';
import { LoadPlacedText } from '@/components/loads/load-placed-text/load-placed-text';
import { LoadsTariffTypeTag } from '@/components/loads/tariff-type-tag/loads-tariff-type-tag';

interface Props {
  load: Load;
  deleteAllowed: boolean;
  handleDeleteLoad: () => void;
}

export const FunnelCalculationDetails: FC<Props> = ({ load, deleteAllowed, handleDeleteLoad }) => {
  const { createdFormatted } = useExtendedObject(load, loadExtender);
  const { DeleteIcon } = useIcons();

  return (
    <HStack alignItems="flex-start" justifyContent="space-between" className="funnel-calculation-details">
      <HStack alignItems="flex-start">
        <VStack alignItems="flex-start" justifyContent="center">
          {/* У собственных заявок нет originalPrice, для них не показываем originalPrice */}
          {load?.originalPrice && (
            <Text className="funnel-calculation-details__price">
              {PRICE_FROM_CLIENT}
              {COLON}
            </Text>
          )}
          <Text className="funnel-calculation-details__price">
            {PRICE_FOR_CLIENT}
            {COLON}
          </Text>
          <Text className="funnel-calculation-details__price">
            {PRICE_FOR_CARRIER}
            {COLON}
          </Text>
          <Text className="funnel-calculation-details__price">
            {PRICE_FOR_CARRIER}
            {COLON}
          </Text>
        </VStack>
        <VStack alignItems="flex-start" justifyContent="center">
          {load?.originalPrice && (
            <HStack spacing={4}>
              <Text className="funnel-calculation-details__value">{getDetailsTitlePrice(load?.originalPrice?.priceWithVAT)}</Text>
              <LoadsTariffTypeTag tariff={load.tariffType} />
            </HStack>
          )}
          <Text className="funnel-calculation-details__value">{getDetailsTitlePrice(load?.customerPayment.price, load?.customerPayment.vat)}</Text>
          <Text className="funnel-calculation-details__value">{getDetailsTitlePrice(load?.carrierPayment?.priceWithVAT)}</Text>
          <Text className="funnel-calculation-details__value">{getDetailsTitlePrice(load?.carrierPayment?.priceWithoutVAT, false)}</Text>
        </VStack>
      </HStack>
      <VStack alignItems="flex-end" gap="4">
        <LoadPlacedText title={createdFormatted} />
        <UiButtonOutlined onClick={handleDeleteLoad} mode="dark" leftIcon={DeleteIcon} disabled={!deleteAllowed}>
          {orderActions.Delete}
        </UiButtonOutlined>
      </VStack>
    </HStack>
  );
};
