import { FC } from 'react';
import classNames from 'classnames';
import { HStack } from '@chakra-ui/react';
import { LAST_CRUISE, MORE_INFO } from 'components/driver-card-compact/texts';
import { UiWarningNumber } from '@/ui-elements/ui-warning-number/ui-warning-number';
import { Driver } from '@/types/models/driver';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { driverExtender } from '@/features/drivers/extender/driver-extender';
import { UiButtonOutlined } from '@/ui-elements';
import { emptyFunction } from '@/helpers/empty-function';
import './driver-card-compact.scss';

type Props = {
  driver: Driver;
  department?: string;
  onMoreInfoClick?: VoidFunction;
  className?: string;
};
/**
 *Компактая карточка с описанием водителя
 * */
export const DriverCardCompact: FC<Props> = ({ driver, department, className, onMoreInfoClick = emptyFunction }) => {
  const { riskGroup, formattedName, name, approveStatusCaption, lastCruiseNumber } = useExtendedObject(driver, driverExtender);

  return (
    <div className={classNames('driver-card-compact', className)}>
      <HStack className="driver-card-compact__body" justifyContent="space-between" alignItems="flex-start">
        <div className="driver-card-compact__info">
          <HStack className="driver-card-compact__title">
            <UiWarningNumber number={riskGroup} />
            <span className="driver-card-compact__title_name">{formattedName || name}</span>
          </HStack>
          {department && <div className="driver-card-compact__area">{department}</div>}
          <div className="driver-card-compact__approve">{approveStatusCaption}</div>
        </div>
        <div className="driver-card-compact__statues">
          <span className="driver-card-compact__label">{LAST_CRUISE}:</span>
          <span className="driver-card-compact__last">{lastCruiseNumber}</span>
        </div>
      </HStack>
      <UiButtonOutlined onClick={onMoreInfoClick}>{MORE_INFO}</UiButtonOutlined>
    </div>
  );
};
