import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { getPeriodFilterValue } from '@/helpers/get-period-filter-value';
import { FunnelListFilters, FunnelLoadStatus } from '@/types/models/funnel';
import { EMPTY_STRING } from '@/config/constants/common-strings';

export const useFunnelListFormValues = () => {
  const [periodValue, setPeriodValue] = useState('all');

  const [filters, setFilters] = useState<FunnelListFilters>({
    status: FunnelLoadStatus.NewCalculation,
    dateFrom: EMPTY_STRING,
    dateTo: EMPTY_STRING,
    search: EMPTY_STRING,
  });

  useEffect(() => {
    if (periodValue !== EMPTY_STRING) {
      const { dateFrom, dateTo } = getPeriodFilterValue(periodValue);
      setFilters((prev) => ({ ...prev, dateFrom, dateTo }));
    }
  }, [periodValue]);

  const handleChangePeriod = (value: string) => {
    setPeriodValue(value);
  };

  /** колбэк для изменения фильтров, который срабатывает мгновенно */
  const handleChangeFiltersImmediately = (values: FunnelListFilters) => {
    setFilters((prev) => ({ ...prev, ...values, search: values?.search ?? EMPTY_STRING }));
  };

  /** колбэк для изменения фильтров с debounce, например для инпута с поиском */
  const handleChangeFilters = debounce((values: FunnelListFilters) => {
    setFilters((prev) => ({ ...prev, ...values, search: values?.search ?? EMPTY_STRING }));
  }, 800);

  return { filters, periodValue, handleChangeFilters, handleChangePeriod, handleChangeFiltersImmediately };
};
