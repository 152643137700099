import { FC } from 'react';
import { OrderInfo } from 'components/order-info/order-info';
import { OrderActions } from 'components/order-info/order-actions';
import { Order } from '@/types/models/order';
import './order-info-with-actions.scss';
import { VoidFunction } from '@/types/common/void-function';
import { emptyFunction } from '@/helpers/empty-function';
import { orderActions } from '@/features/order/texts';
import { Load } from '@/types/models/loads';

type Props = {
  order: Order | Load;
  // открыть форму разделения заявки
  onSplitClick: (partsToSplit: number) => void;
  // открыть поиск исполнителя
  onCarrierSearchClick: VoidFunction;
  // открыть выбор собственных ресурсов
  onChooseOwnDriverAndVehicle?: VoidFunction;
  // перевести в статус поиск исполнителя
  onStartCarrierSearch?: VoidFunction;
  onCancelButtonClick?: VoidFunction;
  handleUpdateOrder?: VoidFunction;
  handleDeleteOrder?: VoidFunction;
  disableSearchButton?: boolean;
  showEditButton?: boolean;
  showSplitButton?: boolean;
  showCancelButton?: boolean;
  // скрыть все кнопки по управлению заявкой
  hideOrderActions?: boolean;
  // скрыть группу риска для заявки
  hideOrderRiskGroup?: boolean;
  cancelCaption?: string;
  // Копировать заявку
  onCloneOrder: (orderId: number) => void;
};
/**
 * Основная инфорамция о заявке с кнопками
 * */
export const OrderInfoWithActions: FC<Props> = ({
  order,
  onSplitClick,
  onCarrierSearchClick,
  onChooseOwnDriverAndVehicle,
  onStartCarrierSearch,
  disableSearchButton = false,
  onCancelButtonClick = emptyFunction,
  handleUpdateOrder = emptyFunction,
  handleDeleteOrder = emptyFunction,
  showEditButton = true,
  showSplitButton = true,
  showCancelButton = false,
  hideOrderActions = false,
  hideOrderRiskGroup = false,
  cancelCaption = orderActions.Cancel,
  onCloneOrder = emptyFunction,
}) => (
  <>
    <OrderInfo order={order} hideOrderRiskGroup={hideOrderRiskGroup} />
    {!hideOrderActions && (
      <OrderActions
        disableSearchButton={disableSearchButton}
        onCancelButtonClick={onCancelButtonClick}
        handleUpdateOrder={handleUpdateOrder}
        handleDeleteOrder={handleDeleteOrder}
        onCloneOrder={onCloneOrder}
        onSplitClick={onSplitClick}
        showEditButton={showEditButton}
        showSplitButton={showSplitButton}
        showCancelButton={showCancelButton}
        onCarrierSearchClick={onCarrierSearchClick}
        onChooseOwnDriverAndVehicle={onChooseOwnDriverAndVehicle}
        onStartCarrierSearch={onStartCarrierSearch}
        orderStatus={order?.status}
        cancelCaption={cancelCaption}
        className="order-info__buttons"
      />
    )}
  </>
);
