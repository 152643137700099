import { Carrier } from '@/types/models/carrier';
import { Vehicle } from '@/types/models/vehicle';
import { FormValues } from '../types';

export const vehicleToApprovalForm = ({
  carrier,
  vehicle,
  vehicleTypeName,
}: {
  carrier?: Carrier;
  vehicle?: Vehicle;
  vehicleTypeName: string;
}): Omit<FormValues, 'volume'> & { volume: string | number } => {
  return {
    carrierName: carrier?.name || 'Нет данных',
    // @ts-ignore
    vehicleType: vehicleTypeName,
    volume: vehicle?.volume || 'Нет данных',
    dimensions: vehicle?.dimensions || 'Нет данных',
    regNumber: vehicle?.regNumber || 'Нет данных',
    accidentsInPastThreeYears: vehicle?.accidentsInPastThreeYears || false,
    bailed: vehicle?.bailed,
    casco: vehicle?.casco,
    tracker: vehicle?.tracker,
    permanentTracking: vehicle?.permanentTracking,
    trailers: vehicle?.trailers || [],
    ownType: vehicle?.ownType,
  };
};
