import { Radio, RadioProps } from '@chakra-ui/react';
import { FC } from 'react';
import classNames from 'classnames';
import './radio-button.scss';

export type Props = RadioProps & {
  checked?: boolean;
  disabled?: boolean;
  onChange?: () => void;
  className?: string;
  label?: string;
};

export const UiRadioButton: FC<Props> = ({ label, checked, disabled, onChange, children, className, ...props }) => (
  <Radio
    isChecked={checked}
    isDisabled={disabled}
    onChange={(e) => !disabled && onChange?.(e)}
    className={classNames(className, 'ui-checkbox')}
    {...props}
  >
    {label || children}
  </Radio>
);
