import { DEFAULT_PAGE_PARAM, DEFAULT_PER_PAGE_RECORDS } from 'config/constants/page';
import { OrdersApi } from '@/api/orders';
import { OrderWithMyParticipationFilter } from '@/types/api/order-partial';
import { useInfiniteQueryWithUpdates } from '@/hooks/use-infinite-query-with-updates';

type UseOrdersHook = {
  perPage?: number;
  query?: OrderWithMyParticipationFilter;
};

export const fetchOrdersWithMyParticipation =
  ({ query = {}, perPage = DEFAULT_PER_PAGE_RECORDS }) =>
  ({ pageParam = DEFAULT_PAGE_PARAM }) =>
    OrdersApi.getOrdersWithMyParticipation(
      {
        ...query,
        updates: false,
        perPage,
        page: pageParam,
      },
      { useBearer: true }
    );

export const useFetchOrdersWithMyParticipation = ({ query = {}, perPage = DEFAULT_PER_PAGE_RECORDS }: UseOrdersHook | undefined = {}) => {
  const queryFn = () => fetchOrdersWithMyParticipation({ query, perPage });

  return useInfiniteQueryWithUpdates({
    queryKey: 'ordersWithMyParticipation',
    refetchOnWindowFocus: false,
    query,
    queryFn,
  });
};
