import { FC } from 'react';
import { UISidebar } from '@/ui-elements/ui-sidebar/ui-sidebar';
import './booker-orders-sidebar-view.scss';
import { BookerOrdersDetailsView } from '@/views/booker/booker-orders-details-view/booker-orders-details-view';

/**
 * Сайдбар для страницы Учет оплаты рейсов
 * * */
export const BookerOrdersSidebarView: FC = () => {
  return (
    <UISidebar className="booker-orders-sidebar-view" isOpen>
      <BookerOrdersDetailsView />
    </UISidebar>
  );
};
