import { DocumentTypes } from '@/config/constants/document-types';
import { BANK_ACCOUNT_MASK, INN_MASK, LICENSE_MASK, PASSPORT_MASK } from '@/config/constants/form';
import { FormUiElements, MyOrganizationFormField } from '@/types/models/my-organization/form';
import { UiCheckboxField, UiFileUploadField, UiInputField, UiMaskedField, UiMultiDatePickerField, UiPhoneField, UiSelectField } from '@/ui-elements';

export const ORGANIZATION_LABEL: Record<MyOrganizationFormField, string> = {
  [MyOrganizationFormField.OrganizationTypeId]: 'Тип организации',
  [MyOrganizationFormField.Name]: 'Название организации',
  [MyOrganizationFormField.Inn]: 'ИНН',
  [MyOrganizationFormField.DirectorSurname]: 'Фамилия',
  [MyOrganizationFormField.DirectorName]: 'Имя',
  [MyOrganizationFormField.DirectorPatronymic]: 'Отчество',
  [MyOrganizationFormField.RussianPassport]: 'Паспорт РФ',
  [MyOrganizationFormField.Birthday]: 'Дата рождения',
  [MyOrganizationFormField.PassportDate]: 'Дата выдачи',
  [MyOrganizationFormField.PassportGiven]: 'Кем выдан',
  [MyOrganizationFormField.PassportNumber]: 'Серия и номер',
  [MyOrganizationFormField.BIK]: 'БИК',
  [MyOrganizationFormField.Bank]: 'Наименование банка',
  [MyOrganizationFormField.BankAccount]: 'Номер расчетного счета',
  [MyOrganizationFormField.CorrespondentAccount]: 'Корр. счет',
  [MyOrganizationFormField.VAT]: 'с НДС',
  [MyOrganizationFormField.PassportDocument]: 'Разворот паспорта с фотографией',
  [MyOrganizationFormField.InnOgrnDocument]: '(ИНН, ОГРНИП)',
  /* driver */
  [MyOrganizationFormField.Convictions]: 'Наличие судимости',
  [MyOrganizationFormField.Loans]: 'Наличие кредитов',
  [MyOrganizationFormField.Accidents]: 'Наличие зарегистрированных ДТП за 3 года',
  [MyOrganizationFormField.DriverLicense]: 'Серия/номер водительского удостоверения',
  [MyOrganizationFormField.DriverLicenseDate]: 'Дата выдачи водительского удостоверения',
  [MyOrganizationFormField.Country]: 'Страна',
  [MyOrganizationFormField.DriversLicenseDoc]: 'Водительское удостоверение',
  [MyOrganizationFormField.Phone]: 'Телефон',
  [MyOrganizationFormField.PhoneNumber]: 'Контактный телефон',
  [MyOrganizationFormField.ContactName]: 'Контактное лицо',
  [MyOrganizationFormField.VatType]: 'Наличие НДС',
};

export const ORGANIZATION_TYPE_FIELDS = [MyOrganizationFormField.OrganizationTypeId, MyOrganizationFormField.Name, MyOrganizationFormField.Inn];
export const ORGANIZATION_MANAGER_FIELDS = [
  MyOrganizationFormField.DirectorSurname,
  MyOrganizationFormField.DirectorName,
  MyOrganizationFormField.DirectorPatronymic,
  MyOrganizationFormField.Phone,
  MyOrganizationFormField.ContactName,
];

export const ORGANIZATION_PASSPORT_FIELDS = [
  MyOrganizationFormField.PassportNumber,
  MyOrganizationFormField.PassportDate,
  MyOrganizationFormField.Birthday,
  MyOrganizationFormField.PassportGiven,
  MyOrganizationFormField.RussianPassport,
];

export const ORGANIZATION_BANK_DETAILS_FIELDS = [
  MyOrganizationFormField.BIK,
  MyOrganizationFormField.Bank,
  MyOrganizationFormField.BankAccount,
  MyOrganizationFormField.CorrespondentAccount,
  MyOrganizationFormField.VAT,
];

export const ORGANIZATION_DOCUMENTS_FIELDS: Array<MyOrganizationFormField.PassportDocument | MyOrganizationFormField.InnOgrnDocument> = [
  MyOrganizationFormField.PassportDocument,
  MyOrganizationFormField.InnOgrnDocument,
];

export const ORGANIZATION_DRIVER_DOCUMENTS_FIELDS: Array<
  MyOrganizationFormField.PassportDocument | MyOrganizationFormField.InnOgrnDocument | MyOrganizationFormField.DriversLicenseDoc
> = [...ORGANIZATION_DOCUMENTS_FIELDS, MyOrganizationFormField.DriversLicenseDoc];

export const ORGANIZATION_DRIVER_GENERAL = [
  MyOrganizationFormField.DirectorSurname,
  MyOrganizationFormField.DirectorName,
  MyOrganizationFormField.DirectorPatronymic,
  MyOrganizationFormField.OrganizationTypeId,
  MyOrganizationFormField.Inn,
  MyOrganizationFormField.Country,
];

export const ORGANIZATION_DRIVER_DATA_FIELDS = [
  MyOrganizationFormField.Convictions,
  MyOrganizationFormField.Loans,
  MyOrganizationFormField.Accidents,
  MyOrganizationFormField.DriverLicense,
  MyOrganizationFormField.DriverLicenseDate,
];

export const FORM_UI_ELEMENTS: FormUiElements = {
  [MyOrganizationFormField.OrganizationTypeId]: {
    Component: UiSelectField,
    defaultProps: {
      required: true,
      name: MyOrganizationFormField.OrganizationTypeId,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.OrganizationTypeId],
    },
  },
  [MyOrganizationFormField.Name]: {
    Component: UiInputField,
    defaultProps: {
      required: true,
      name: MyOrganizationFormField.Name,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.Name],
    },
  },
  [MyOrganizationFormField.Inn]: {
    Component: UiMaskedField,
    defaultProps: {
      required: true,
      mask: INN_MASK,
      name: MyOrganizationFormField.Inn,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.Inn],
    },
  },
  [MyOrganizationFormField.DirectorSurname]: {
    Component: UiInputField,
    defaultProps: {
      required: true,
      name: MyOrganizationFormField.DirectorSurname,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.DirectorSurname],
    },
  },
  [MyOrganizationFormField.DirectorName]: {
    Component: UiInputField,
    defaultProps: {
      required: true,
      name: MyOrganizationFormField.DirectorName,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.DirectorName],
    },
  },
  [MyOrganizationFormField.ContactName]: {
    Component: UiInputField,
    defaultProps: {
      required: true,
      name: MyOrganizationFormField.ContactName,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.ContactName],
    },
  },
  [MyOrganizationFormField.DirectorPatronymic]: {
    Component: UiInputField,
    defaultProps: {
      required: true,
      name: MyOrganizationFormField.DirectorPatronymic,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.DirectorPatronymic],
    },
  },
  [MyOrganizationFormField.RussianPassport]: {
    Component: UiCheckboxField,
    defaultProps: {
      required: true,
      name: MyOrganizationFormField.RussianPassport,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.RussianPassport],
    },
  },
  [MyOrganizationFormField.Birthday]: {
    Component: UiMultiDatePickerField,
    defaultProps: {
      required: true,
      name: MyOrganizationFormField.Birthday,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.Birthday],
    },
  },
  [MyOrganizationFormField.PassportDate]: {
    Component: UiMultiDatePickerField,
    defaultProps: {
      required: true,
      name: MyOrganizationFormField.PassportDate,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.PassportDate],
    },
  },
  [MyOrganizationFormField.PassportGiven]: {
    Component: UiInputField,
    defaultProps: {
      required: true,
      name: MyOrganizationFormField.PassportGiven,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.PassportGiven],
    },
  },
  [MyOrganizationFormField.PassportNumber]: {
    Component: UiMaskedField,
    defaultProps: {
      required: true,
      mask: PASSPORT_MASK,
      type: 'number',
      name: MyOrganizationFormField.PassportNumber,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.PassportNumber],
    },
  },
  [MyOrganizationFormField.BIK]: {
    Component: UiInputField,
    defaultProps: {
      required: true,
      type: 'number',
      name: MyOrganizationFormField.BIK,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.BIK],
    },
  },
  [MyOrganizationFormField.Bank]: {
    Component: UiInputField,
    defaultProps: {
      required: true,
      name: MyOrganizationFormField.Bank,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.Bank],
    },
  },
  [MyOrganizationFormField.BankAccount]: {
    Component: UiMaskedField,
    defaultProps: {
      required: true,
      type: 'number',
      mask: BANK_ACCOUNT_MASK,
      name: MyOrganizationFormField.BankAccount,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.BankAccount],
    },
  },
  [MyOrganizationFormField.CorrespondentAccount]: {
    Component: UiMaskedField,
    defaultProps: {
      required: true,
      type: 'number',
      mask: BANK_ACCOUNT_MASK,
      name: MyOrganizationFormField.CorrespondentAccount,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.CorrespondentAccount],
    },
  },
  [MyOrganizationFormField.VAT]: {
    Component: UiCheckboxField,
    defaultProps: {
      required: false,
      name: MyOrganizationFormField.VAT,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.VAT],
    },
  },
  [MyOrganizationFormField.VatType]: {
    Component: UiCheckboxField,
    defaultProps: {
      required: false,
      name: MyOrganizationFormField.VatType,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.VatType],
    },
  },
  [MyOrganizationFormField.PassportDocument]: {
    Component: UiFileUploadField,
    defaultProps: {
      required: true,
      name: MyOrganizationFormField.PassportDocument,
      type: DocumentTypes.dlDocumentOrganizationManagerPassport,
    },
  },
  [MyOrganizationFormField.InnOgrnDocument]: {
    Component: UiFileUploadField,
    defaultProps: {
      required: true,
      name: MyOrganizationFormField.InnOgrnDocument,
      type: DocumentTypes.dlDocumentOrganizationInnOgrn,
    },
  },
  [MyOrganizationFormField.Phone]: {
    Component: UiPhoneField,
    defaultProps: {
      required: true,
      name: MyOrganizationFormField.Phone,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.Phone],
    },
  },
  [MyOrganizationFormField.PhoneNumber]: {
    Component: UiPhoneField,
    defaultProps: {
      required: true,
      name: MyOrganizationFormField.PhoneNumber,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.PhoneNumber],
    },
  },
  /* driver */
  [MyOrganizationFormField.Convictions]: {
    Component: UiCheckboxField,
    defaultProps: {
      required: false,
      name: MyOrganizationFormField.Convictions,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.Convictions],
    },
  },
  [MyOrganizationFormField.Loans]: {
    Component: UiCheckboxField,
    defaultProps: {
      required: false,
      name: MyOrganizationFormField.Loans,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.Loans],
    },
  },
  [MyOrganizationFormField.Accidents]: {
    Component: UiCheckboxField,
    defaultProps: {
      required: false,
      name: MyOrganizationFormField.Accidents,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.Accidents],
    },
  },
  [MyOrganizationFormField.DriverLicense]: {
    Component: UiMaskedField,
    defaultProps: {
      required: true,
      mask: LICENSE_MASK,
      name: MyOrganizationFormField.DriverLicense,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.DriverLicense],
      type: 'number',
    },
  },
  [MyOrganizationFormField.DriverLicenseDate]: {
    Component: UiMultiDatePickerField,
    defaultProps: {
      required: true,
      name: MyOrganizationFormField.DriverLicenseDate,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.DriverLicenseDate],
    },
  },
  [MyOrganizationFormField.Country]: {
    Component: UiInputField,
    defaultProps: {
      required: false,
      name: MyOrganizationFormField.Country,
      label: ORGANIZATION_LABEL[MyOrganizationFormField.Country],
    },
  },
  [MyOrganizationFormField.DriversLicenseDoc]: {
    Component: UiFileUploadField,
    defaultProps: {
      required: false,
      name: MyOrganizationFormField.DriversLicenseDoc,
      type: DocumentTypes.dlDocumentDriverLicense,
    },
  },
};
