import { useEffect, useState } from 'react';
import { useAppSelector } from '@/hooks/use-app-selector';
import { resourcesSelectedDriverIdSelector, resourcesSidebarModeSelector } from '@/features/resources/selectors';
import { ResourcesSidebarMode } from '@/features/resources/types/general';
import { ResourcesDriverFormValues } from '@/features/resources/constants/form';
import {
  useDriverFormInitialValues,
  useCreateResourcesDriver,
  useEditResourcesDriver,
  useResourcesDispatchActions,
} from '@/features/resources/hooks';
import { useAccount } from '@/features/account/hooks/use-account';
import { ResourcesButtonActions } from '@/features/resources/constants/general';
import { DriverVerificationForm } from '@/types/models/driver-verification';

export const useResourcesDriverForm = () => {
  /* id текущего перевозчика */
  const { currentAccount } = useAccount();
  const carrierId = currentAccount.user?.accountId;
  const mode = useAppSelector(resourcesSidebarModeSelector);

  /* id выбранного водителя для редактирования */
  const selectedDriverId = useAppSelector(resourcesSelectedDriverIdSelector);
  const driverId = mode === ResourcesSidebarMode.EditForm ? selectedDriverId : null;

  /* начальные значения для формы редактирования */
  const { updatedValues, driverInitialDocs } = useDriverFormInitialValues({ driverId, carrierId });

  /* начальные значения хранятся в state, чтобы при переключении форм корректно менялись начальные значения */
  const [initialValues, setInitialValues] = useState<DriverVerificationForm | undefined>(undefined);

  useEffect(() => {
    setInitialValues(mode === ResourcesSidebarMode.CreateForm ? undefined : updatedValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  /* закрытие формы */
  const { handleSetSidebarMode } = useResourcesDispatchActions();
  const closeForm = () => handleSetSidebarMode(ResourcesSidebarMode.None);

  /* submit формы */
  const { handleCreateDriver } = useCreateResourcesDriver({ carrierId, closeForm });
  const { handleEditDriver } = useEditResourcesDriver({ driverId, closeForm });

  const submitHandlers: Partial<Record<ResourcesSidebarMode, (values: ResourcesDriverFormValues) => void>> = {
    [ResourcesSidebarMode.CreateForm]: handleCreateDriver,
    [ResourcesSidebarMode.EditForm]: handleEditDriver,
  };

  const submitButtonTexts = {
    [ResourcesSidebarMode.CreateForm]: ResourcesButtonActions.SaveAndRegister,
    [ResourcesSidebarMode.EditForm]: ResourcesButtonActions.Edit,
  };

  return {
    initialValues,
    driverInitialDocs,
    mode,
    submitButtonTexts,
    submitHandlers,
    closeForm,
  };
};
