import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const commonParameters = {
  borderWidth: '1px',
  opacity: 1,
  borderStyle: 'solid',
};

const darkVariant = defineStyle(() => ({
  color: 'neutrals.200',
  ...commonParameters,
}));

const normalVariant = defineStyle(() => ({
  color: 'neutrals.250',
  ...commonParameters,
}));

const lightVariant = defineStyle(() => ({
  color: 'neutrals.500',
  ...commonParameters,
}));

const extraDarkVariant = defineStyle(() => ({
  color: 'darkgray',
  ...commonParameters,
}));

const variants = {
  dark: darkVariant,
  normal: normalVariant,
  light: lightVariant,
  extraDark: extraDarkVariant,
};

export const dividerTheme = defineStyleConfig({ variants });
