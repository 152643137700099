import { HStack, Text } from '@chakra-ui/react';
import { useIcons } from '@/hooks/use-icons';

type Props = {
  volume?: number;
  capacity?: number;
};

export const VehicleDetails = ({ volume, capacity }: Props) => {
  const { TonageIcon, VolumeIcon } = useIcons();

  return (
    <HStack spacing={2}>
      {volume && (
        <>
          <VolumeIcon />
          <Text textStyle="h4">{`${volume} м³`}</Text>
        </>
      )}
      {capacity && (
        <>
          <TonageIcon />
          <Text textStyle="h4">{`${capacity} т`}</Text>
        </>
      )}
    </HStack>
  );
};
