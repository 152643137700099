import { VStack, Text, Center, HStack, Box } from '@chakra-ui/react';
import { FC } from 'react';
import times from 'lodash/times';
import { Namespaces, OrderCreateFormValues } from 'components/create-order-form/schema';
import { OrderType } from 'config/constants/types';
import { UiButtonOutlined, UiTextareaField, UiFileUploadField, UiDivider } from '@/ui-elements';
import { useForm } from '@/helpers/forms/use-form';
import { OrderInfoSubform, RouteSubform, PaymentTermsSubform } from '@/components/create-order-form/subforms';
import { emptyFunction } from '@/helpers/empty-function';
import { UiScrollbars } from '@/ui-elements/ui-scrollbars/ui-scrollbars';

type Props = {
  parts?: number;
  title?: string;
};

export const SplitOrderForm: FC<Props> = ({ parts = 2, title = 'Новая заявка из разделенной' }) => {
  const initialValues: Partial<OrderCreateFormValues> = {
    [Namespaces.orderInfo]: {
      transportationOption: { value: 2, label: 'Дозагрузка' },
      riskGroup: { value: 2, label: '2' },
    },
    [Namespaces.carrierPaymentTerms]: {
      paymentConditions: 'Оплата в течение 5 банковских дней',
    },
    [Namespaces.route]: {
      routeType: OrderType.Simple,
    },
  };
  const onSubmit = emptyFunction;

  const Form = useForm<any>();

  return (
    <VStack>
      <UiScrollbars style={{ width: '100%', height: '100vh' }}>
        <HStack>
          {times(parts, (index) => (
            <Box maxH="100vh" overflow="auto">
              <Form key={index} name={`form_${index}`} initialValues={initialValues} onSubmit={onSubmit}>
                <VStack spacing="24px" align="flex-start" px={4}>
                  <Text textStyle="h2" align="left">
                    {title}
                  </Text>
                  <UiDivider variant="normal" />
                  <OrderInfoSubform namespace="orderInfo" mode="split" />
                  <RouteSubform namespace="route" />
                  <PaymentTermsSubform namespace="paymentTerms" />
                  <Text textStyle="h3" align="left" mb={4} mt={6}>
                    Документы по заявке
                  </Text>
                  <UiFileUploadField name="documents" />
                  <Center mt={4} mb={6} w="100%">
                    <UiButtonOutlined>Скачать документы</UiButtonOutlined>
                  </Center>
                  <UiTextareaField
                    name="comments"
                    label="Общие комментарии к заявке"
                    variant="noResize"
                    placeholder="Напишите комментарии при необходимости"
                  />
                </VStack>
              </Form>
            </Box>
          ))}
        </HStack>
      </UiScrollbars>
    </VStack>
  );
};
