import { FC } from 'react';
import { VehicleWidget } from 'components/carriers-loads/widgets/vehicle-widget';
import { DriverWidget } from 'components/carriers-loads/widgets/driver-widget';
import { UiText } from '@/ui-elements/ui-text/ui-text';
import { Vehicle } from '@/types/models/vehicle';
import { Driver } from '@/types/models/driver';

type Props = {
  vehicle?: Vehicle;
  driver?: Driver;
};

export const AssignedResources: FC<Props> = ({ vehicle, driver }) => {
  if (!(vehicle || driver)) return null;

  return (
    <>
      <UiText textStyle="h3">Вы назначили</UiText>
      {vehicle && <VehicleWidget vehicle={vehicle} />}
      {driver && <DriverWidget driver={driver} />}
    </>
  );
};
