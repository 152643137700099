import { FC } from 'react';
import { HStack, VStack } from '@chakra-ui/react';
import { OrderGeneralDistance } from '@/components/order-general-distance/order-general-distance';
import { OrderGeneralInfo } from '@/components/order-general-info/order-general-info';
import { useExtendedObject } from '@/hooks/use-extended-object';
import { orderExtender } from '@/features/order/extender/order-extender';
import { Order } from '@/types/models/order';

interface Props {
  load: Order;
}

export const CarrierLoadExecutionDetails: FC<Props> = ({ load }) => {
  const { distance, type, shippedTotals } = useExtendedObject(load, orderExtender);

  return (
    <HStack justifyContent="space-between">
      <VStack spacing={6} alignItems="flex-start">
        <OrderGeneralDistance range={distance} type={type} />
        <OrderGeneralInfo vehicleTypes={load?.vehicleTypes} shippedTotals={shippedTotals} loadingType={load?.loadingType || []} />
      </VStack>
    </HStack>
  );
};
