import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const noResize = defineStyle({
  fontSize: '14px',
  lineHeight: '20px',
  color: 'typography.50',
  resize: 'none',
  padding: '10 px 8px 10px 12px',
  border: '1px solid',
  borderColor: 'neutrals.250',
  borderRadius: '8px',
  size: 'lg',
});

export const textareaTheme = defineStyleConfig({
  variants: { noResize },
});
