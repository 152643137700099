import './modal-carrier-create-offer.scss';
import { FC } from 'react';
import { Box, Modal, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import closedIcon from 'public/assets/icons/CloseIcon.svg';
import { ModalsProps } from '@/types/common/modals';
import { emptyFunction } from '@/helpers/empty-function';
import { UiButtonFilled, UiButtonOutlined, UiIconButton } from '@/ui-elements';
import { UiTooltip } from '@/ui-elements/tooltip/tooltip';

export const ModalCreateCarrierOffer: FC<
  ModalsProps<{
    text?: string;
    modalWidth?: number;
    marginRight?: number;
    submitButtonText?: string;
    cancelButtonText?: string;
    justifyTitle?: string;
    closedIcon?: string;
    desc?: string;
    alignDesc?: 'center' | 'left' | 'right';
    accentXBtn?: boolean;
    formComponent?: React.ReactElement;
    buttonsAutoWidth?: boolean;
    disableSubmit?: boolean;
    submitButtonTooltip?: string;
  }>
> = ({
  modalOpened,
  text,
  onSuccess = emptyFunction,
  onClose,
  modalWidth = 424,
  marginRight = 0,
  submitButtonText = 'Откликнуться',
  cancelButtonText = 'Отмена',
  buttonsAutoWidth = false,
  justifyTitle = 'center',
  desc,
  alignDesc,
  formComponent,
  accentXBtn,
  disableSubmit,
  submitButtonTooltip,
}) => {
  const onSuccessConfirm = () => {
    onSuccess();
  };
  return (
    <Modal isOpen={modalOpened} onClose={onClose}>
      {/* затемнение фона */}
      <ModalOverlay />

      <ModalContent borderRadius={24} width={modalWidth} boxShadow="xl" pos="relative" mt={65} maxW={modalWidth} w={modalWidth} mr={marginRight}>
        {!accentXBtn && (
          <Box className="close-icon">
            <UiIconButton aria-label="close-modal-button" icon={closedIcon} onClick={onClose} style={{ left: modalWidth - 36 }} />
          </Box>
        )}

        <ModalHeader marginTop={2} display="flex" justifyContent={justifyTitle} fontWeight={700} fontSize="large">
          {text}
        </ModalHeader>

        <Box paddingRight={6} paddingLeft={6} display="flex" flexDirection="column" justifyContent="center" textAlign={alignDesc}>
          {formComponent}
          {desc}
        </Box>

        {accentXBtn ? (
          <Box display="flex" justifyContent="right" mb={8} mt={7} mr={6} ml={6}>
            <UiButtonOutlined width={buttonsAutoWidth ? 'auto' : '100%'} mode="dark" className="modal__button-cancel" onClick={onClose}>
              {cancelButtonText || 'Отменить'}
            </UiButtonOutlined>
            <UiTooltip label={submitButtonTooltip} isDisabled={!disableSubmit} placement="bottom-end" className="submit-btn-tooltip">
              <UiButtonFilled
                onClick={onSuccessConfirm}
                width={buttonsAutoWidth ? 'auto' : '100%'}
                type="submit"
                mode="accent"
                disabled={disableSubmit}
              >
                {submitButtonText}
              </UiButtonFilled>
            </UiTooltip>
          </Box>
        ) : (
          <Box display="flex" justifyContent="right" mr={6} mb={8} mt={6}>
            <UiButtonOutlined mode="dark" className="modal__button-cancel" onClick={onClose}>
              {cancelButtonText || 'Отменить'}
            </UiButtonOutlined>
            <UiTooltip label={submitButtonTooltip} isDisabled={!disableSubmit} placement="bottom-end" className="submit-btn-tooltip">
              <UiButtonFilled type="submit" onClick={onSuccessConfirm} disabled={disableSubmit}>
                {submitButtonText}
              </UiButtonFilled>
            </UiTooltip>
          </Box>
        )}
      </ModalContent>
    </Modal>
  );
};
