import { FC } from 'react';
import classNames from 'classnames';
import { HStack, Text } from '@chakra-ui/react';
import moment from 'moment';
import { BURNING_DAY } from '@/config/constants/orders';
import { DAYS_ENDINGS, EXPIRING_ENDINGS } from '@/config/constants/word-endings';
import { getDateDiff } from '@/helpers/moment/get-date-diff';
import { useIcons } from '@/hooks/use-icons';
import './status-request.scss';
import { declensionNouns } from '@/helpers/declension-of-nouns';
import { formatMomentDate } from '@/helpers/moment/format-moment-date';
import { MOMENT_DD_MM_YYYY } from '@/helpers/moment/constants';
import { TimeStamp } from '@/types/common/timestamp';

type Props = {
  date?: TimeStamp;
  className?: string;
};

export const UiStatusRequest: FC<Props> = ({ date, className }) => {
  const { Fire } = useIcons();

  if (!date) return null;

  const { diffInDays } = getDateDiff(moment.unix(date).format());
  const isBurning = diffInDays <= BURNING_DAY; // TODO:  либо BURNING_DAY должен быть с бэка, либо статус isBurning
  return (
    <HStack className={classNames(className, 'ui-status-request', { 'ui-status-request-burning': isBurning })}>
      <Text>
        {isBurning
          ? `${formatMomentDate(date, MOMENT_DD_MM_YYYY, true)} (${declensionNouns(diffInDays, EXPIRING_ENDINGS)} ${diffInDays} ${declensionNouns(
              diffInDays,
              DAYS_ENDINGS
            )})`
          : `Выполнить до ${formatMomentDate(date, MOMENT_DD_MM_YYYY, true)}`}
      </Text>
      {isBurning && <Fire />}
    </HStack>
  );
};
