export enum DateFormat {
  DDMMYYYYPeriod = 'DD.MM.YYYY',
  YYYYMMDDPeriod = 'YYYY.MM.DD',
  YYYYMMDDDash = 'YYYY-MM-DD',
  DDMMYYYYDash = 'DD-MM-YYYY',
  DDMMYYYYSlash = 'DD/MM/YYYY',
  YYYYMMDDSlash = 'YYYY/MM/DD',
  DaysCountdown = 'D д. H:mm:s',
  Duration = 'D д. HH:mm',
  Countdown = 'H:m:s',
  FIRST_MMYYYYDash = '01-MM-YYYY',
  MMYYYYPeriod = 'MM.YYYY',
  MMYYYYSlash = 'MM/YYYY',
  HHmmDDMMYYYYPeriod = 'HH:mm DD.MM.YYYY',
  YYYYMMDD = 'YYYYMMDD',
}

export enum DateSeparator {
  Period = '.',
  Slash = '/',
  Dash = '-',
  Space = ' ',
}

export enum TimeFormat {
  HHMMSSColon = 'HH:mm',
  HHMMColon = 'HH:mm',
  HHmmssColon = 'HH:mm:ss',
  HHmmColon = 'HH:mm',
  HHMMDash = 'HH-mm',
  HHMMPeriod = 'HH.mm',
  HHMMSpace = 'HH mm',
  HHMMSlash = 'HH-mm',
  HHMMColonSpaceZ = 'HH:mm Z',
  H = 'H',
  HH = 'HH',
  S = 'S',
  SS = 'SS',
  mm = 'mm',
  HHMM = 'HHmm',
}

export enum DateTimeFormat {
  DDMMYYYYPeriodHHMMColonSpaceZ = 'DD.MM.YYYY HH:mm Z',
  DDMMYYYYPeriodHHMM = 'DD.MM.YYYY HH:mm',
  YYYYMMDDDashHHMM = 'YYYY-MM-DD HH:mm',
  YYYYMMDDDashHHMMSS = 'YYYY-MM-DD HH:mm:ss',
  YYYYMMDDDashHHMMS = 'YYYY-MM-DD HH:mm:s',
  HHmmssDMMYY = 'HH:mm:ss D.MM.YY',
  DMYPeriodHISColon = 'd.m.Y H:i:s',
  HHMMColonDDMDot = 'HH:MM DD.M',
  ISO8601 = '',
  YYYYMMDDDashHHMMColon = 'YYYYMMDDDashHHMMColon',
  YYYYMMDDDashHHMMSSColon = 'YYYYMMDDDashHHMMSSColon',
  YYYYMMDDHHMM = 'YYYYMMDDHHMM',
  YYYYMMDDHHMMSS = 'YYYYMMDDHHMMSS',
  UNIX = 'X',
  UNIXMillisecond = 'x',
}
