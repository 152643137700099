import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { OrderInfo } from 'components/carriers-loads/card/order-info/order-info';
import { OfferPrice } from 'components/carriers-loads/card/offer-price/offer-price';
import { ApplicationAgreement } from 'components/carriers-loads/card/application-agreement/application-agreement';
import { AssignedResources } from 'components/carriers-loads/card/assigned-resources/assigned-resources';
import { head } from 'lodash';
import { OrderExecutionStatus } from 'config/constants/statuses';
import { Route } from 'components/carriers-loads/card/route/route';
import { PrepaymentWidget } from 'components/carriers-loads/widgets/prepayment-widget';
import { UiButtonFilled, UiDivider } from '@/ui-elements';
import { Order } from '@/types/models/order';
import { UiText } from '@/ui-elements/ui-text/ui-text';
import { Load } from '@/types/models/loads';
import { UiStatus } from '@/ui-elements/status/status';

interface IProps {
  order: Order | Load;
  onOrderStart?: () => void;
  onOrderEnd?: () => void;
}

const DECLINE_STATUS_PROPS = {
  text: 'Отклонено',
  backGroundColor: 'accentRed.100',
  color: 'typography.250',
};

export const DetailsComponent: FC<IProps> = ({ order, onOrderStart, onOrderEnd }) => {
  const { offer, status, customer } = order;
  const carrierLoadsOffer = head(offer);
  const isExecuting = status === OrderExecutionStatus.Executing;
  const isPreparation = status === OrderExecutionStatus.Preparation;
  const isAccepted = carrierLoadsOffer?.accepted ?? 0;

  if (!isAccepted && isExecuting) {
    return (
      <Flex direction="column" align="center" gap="1em">
        <UiStatus {...DECLINE_STATUS_PROPS} />
        <Text className="orders-with-my-participation-card__company">{customer?.name}</Text>
        <UiText color={DECLINE_STATUS_PROPS.backGroundColor} children="Заявка закрыта, исполнитель выбран" />
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap="1em">
      <OrderInfo order={order} onOrderStart={onOrderStart} />
      <PrepaymentWidget order={order} />
      {!isExecuting && carrierLoadsOffer && (
        <>
          <OfferPrice accepted={isAccepted} price={carrierLoadsOffer.price} carrierPayment={order.carrierPayment} />
          <ApplicationAgreement exist={!isPreparation} orderId={order?.id} accepted={Boolean(isAccepted)} />
        </>
      )}
      <UiDivider />
      <AssignedResources vehicle={carrierLoadsOffer?.vehicle} driver={carrierLoadsOffer?.driver} />
      <UiDivider />
      <Route order={order} />
      {onOrderEnd && <UiButtonFilled onClick={onOrderEnd}>Завершить рейс</UiButtonFilled>}
    </Flex>
  );
};
