import { createAsyncThunk } from '@reduxjs/toolkit';
import { TOKEN_KEY } from 'config/constants/storage';
import { Credentials } from '@/types/api/credentials';
import { AuthApi } from '@/api/auth';
import { Account } from '@/types/models/account';
import { storage } from '@/helpers/storage';
import { handleAxiosError } from '@/helpers/api/handle-axios-error';
import { ResponseError } from '@/types/api/response-error';
import { spinnerActions } from '@/features/spinner/slice';

export const loginAction = createAsyncThunk<Account, Credentials, { rejectValue: ResponseError }>(
  'account/login',
  async (credentials, { rejectWithValue, dispatch }) => {
    try {
      dispatch(spinnerActions.show());
      const res = await AuthApi.signIn(credentials);
      if (res?.data.token) storage.setValue(TOKEN_KEY, res.data.token);
      return res?.data;
    } catch (err) {
      // @ts-ignore
      return err?.response?.status !== 401 ? handleAxiosError(err, rejectWithValue) : rejectWithValue(err?.response?.data?.errors);
    } finally {
      dispatch(spinnerActions.hide());
    }
  }
);
