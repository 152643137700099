import './future-routes.scss';
import { FC } from 'react';
import { SidebarMode } from 'components/sidebar-wrapper/constants';
import { Routes } from 'config/routes/routes';
import { useAppSelector } from '@/hooks/use-app-selector';
import { FutureRoutesSidebarView } from '@/views/carriers-loads/future-routes/future-routes-sidebar-view';
import { FutureRoutesListView } from '@/views/carriers-loads/future-routes/future-routes-list-view';
import { pageSidebarModeSelector } from '@/features/carriers-loads/selectors/page-sidebar-mode-selector';

export const FutureRoutesView: FC = () => {
  const mode = useAppSelector((state) => pageSidebarModeSelector(state, Routes.FutureRoutes));

  return (
    <>
      <FutureRoutesListView />
      {SidebarMode.None !== mode && <FutureRoutesSidebarView />}
    </>
  );
};
