import { FC } from 'react';
import classNames from 'classnames';
import { EMPTY_STRING } from 'config/constants/common-strings';
import './ui-warning-number.scss';

type Props = {
  number: number;
  className?: string;
};
export const UiWarningNumber: FC<Props> = ({ number, className = EMPTY_STRING }) => {
  let warnLevel: number;
  if (number > 6) {
    warnLevel = 6;
  } else {
    warnLevel = number < 1 ? 1 : number;
  }
  return <span className={classNames(className, 'ui-warning-number', `ui-warning-number-${warnLevel}`)}>{number}</span>;
};
