import { Popover, PopoverTrigger } from '@chakra-ui/react';
import { FC } from 'react';
import './notifications-popover.scss';
import { useIcons } from '@/hooks/use-icons';
import { UiIconButton } from '@/ui-elements';
import { NotificationsView } from '@/views/notifications/notifications-view';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  onToggle: VoidFunction;
  notificationsCounter: number;
};
export const NotificationsPopover: FC<Props> = ({ onClose, onToggle, isOpen, notificationsCounter }) => {
  const { NotificationsBell } = useIcons();
  return (
    <div className="notifications-popover">
      <Popover isOpen={isOpen} onClose={onClose} placement="bottom" strategy="fixed">
        <PopoverTrigger>
          <div>
            <UiIconButton
              aria-label="notification-button"
              icon={NotificationsBell}
              amount={notificationsCounter}
              className={isOpen ? 'active' : ''}
              onClick={onToggle}
            />
          </div>
        </PopoverTrigger>
        {isOpen ? <NotificationsView onClose={onClose} /> : null}
      </Popover>
    </div>
  );
};
