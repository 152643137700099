export const getBackGroundColor = (customerQuantity: number, max: number, min: number, amount: number) => {
  if (amount === 1) {
    return '#2F80ED';
  }
  if (customerQuantity === min) {
    return '#09BA95';
  }
  if (customerQuantity === max) {
    return '#EB5757';
  }
  return '#2F80ED';
};
