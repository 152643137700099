import { Box, Spacer } from '@chakra-ui/layout';
import { UiButtonFilled, UiButtonOutlined } from '@/ui-elements';
import { CANCEL_CREATE_LOAD, SAVE_LOAD } from '@/config/constants/funnel';

export const CreateLoadFormBottomButtons = ({ submitting, onCancel }: { submitting?: boolean; onCancel: () => void }) => {
  return (
    <Box display="flex" w="100%" py="20px">
      <UiButtonOutlined flexGrow={1} mode="dark" onClick={onCancel}>
        {CANCEL_CREATE_LOAD}
      </UiButtonOutlined>
      <Spacer maxW="10px" />
      <UiButtonFilled isDisabled={submitting} isLoading={submitting} type="submit" flexGrow={1}>
        {SAVE_LOAD}
      </UiButtonFilled>
    </Box>
  );
};
