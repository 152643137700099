import './resources-reset-password-form.scss';
import { FC } from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { useForm } from '@/helpers/forms/use-form';
import { UiButtonFilled, UiPhoneField } from '@/ui-elements';
import {
  ResourcesFormFieldNames,
  ResourcesFormHelperText,
  ResourcesFormLabels,
  ResourcesRegisterDriverFormValues,
} from '@/features/resources/constants/form';
import { UiTooltip } from '@/ui-elements/tooltip/tooltip';
import { ResourcesButtonActions } from '@/features/resources/constants/general';

interface Props {
  initialValues?: Partial<ResourcesRegisterDriverFormValues>;
  handleSubmit: (values: ResourcesRegisterDriverFormValues) => void;
}

export const ResourcesResetPasswordForm: FC<Props> = ({ initialValues, handleSubmit }) => {
  const Form = useForm<ResourcesRegisterDriverFormValues>();

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit} className="resources-reset-password-form">
      <Text className="resources-reset-password-form-title">Сброс пароля</Text>
      <VStack>
        <UiPhoneField
          name={ResourcesFormFieldNames.phoneNumber}
          label={ResourcesFormLabels.phoneNumber}
          isDisabled
          content={ResourcesFormHelperText.resetDriverPassword}
        />
        <UiTooltip label="Система отправит данному водителю смс с новым паролем для входа в приложение">
          <UiButtonFilled type="submit">{ResourcesButtonActions.ResetPassword}</UiButtonFilled>
        </UiTooltip>
      </VStack>
    </Form>
  );
};
