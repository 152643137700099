import { FC, memo, useState } from 'react';
import { FormSpy } from 'react-final-form';
import { DateObject } from 'react-multi-date-picker';
import { HStack } from '@chakra-ui/react';
import './order-filter.scss';
import { useForm } from '@/helpers/forms/use-form';
import { emptyFunction } from '@/helpers/empty-function';
import { FormAutosave } from '@/hooks/use-autosave';
import { UiButtonOutlined, UiDatePickerInput, UiInputField, UiOptionsMenu, UiSelectField } from '@/ui-elements';
import { useIcons } from '@/hooks/use-icons';
import { OrderFilterFields } from '@/features/order/types/order-filter';
import { sortingOptions } from '@/config/constants/order-sorting-options';
import { SortType } from '@/config/constants/types';
import { UiToggle } from '@/ui-elements/ui-toggle/ui-toggle';
import { OrderSwitcherStatus } from '@/config/constants/statuses';
import { PeriodPicker } from '../period-picker/period-picker';
import './order-filter-form.scss';
import { orderCompletedOptions } from '@/config/constants/order-completed-options';

type Props = {
  handleCreateOrder?: React.MouseEventHandler<HTMLButtonElement>;
  onFilterChange: (values: OrderFilterFields) => void;
  onSetSorting: (sortBy: string, sortType: SortType) => void;
  handleSelectMode: () => void;
  toggleValue: boolean;
  activeTab: string;
  setRangeValue: (range: DateObject[]) => void;
  periodValue: string;
  rangeValue: DateObject[];
  setPeriodValue: (value: string) => void;
  defaultFilter?: string;
};

const OrderFilterForm: FC<Props> = ({
  handleCreateOrder = emptyFunction,
  onFilterChange = emptyFunction,
  onSetSorting,
  handleSelectMode,
  toggleValue,
  activeTab,
  setRangeValue,
  periodValue,
  setPeriodValue,
  rangeValue,
  defaultFilter,
}) => {
  const Form = useForm();
  const { Sorting, CreateOrder } = useIcons();

  const [option, setOption] = useState(0);
  const isCompleted = activeTab === OrderSwitcherStatus.Completed;
  const isAll = activeTab === OrderSwitcherStatus.All;

  const onHandleSortingChange = (value: any) => {
    setOption(value);
    const selectedSorting = sortingOptions.find((optionData) => optionData.id === value);
    onSetSorting(selectedSorting?.sorting?.by || '', selectedSorting?.sorting?.order || SortType.Asc);
  };

  return (
    <Form onSubmit={emptyFunction} className="order-filter__form" initialValues={{ 'order-search': defaultFilter }}>
      <FormSpy>{({ values }) => <FormAutosave values={values} onSave={onFilterChange} />}</FormSpy>
      <HStack className="order-filter__controls">
        <HStack>
          <UiInputField name="order-search" placeholder="Поиск по заявкам" />
          {!isCompleted && !isAll && (
            <UiOptionsMenu
              menuOptions={sortingOptions}
              onChange={onHandleSortingChange}
              menuButtonIcon={<Sorting />}
              className="dropdown-menu-spacer"
              selectedOption={option}
            />
          )}
          {isCompleted && (
            <div className="order-filter__form-completed">
              <UiSelectField
                className="order-filter__form-completed-filter"
                name="completed"
                options={orderCompletedOptions}
                placeholder="Тип завершенных"
              />
            </div>
          )}
        </HStack>
        <HStack>
          {/* {!isCompleted && !isAll && (
            <UiToggle checked={toggleValue} onChange={handleSelectMode} name="select-mode">
              Выбрать несколько
            </UiToggle>
          )} */}
          {/* {!isCompleted && !isAll && (
            <UiButtonOutlined onClick={handleCreateOrder} leftIcon={CreateOrder} mode="dark">
              Создать заявку
            </UiButtonOutlined>
          )} */}
          {(isCompleted || isAll) && (
            <>
              <PeriodPicker value={periodValue} onChange={setPeriodValue} />
              <UiDatePickerInput value={rangeValue} onChange={setRangeValue} isMulti className="order-filter__controls__datepicker" />
            </>
          )}
        </HStack>
      </HStack>
    </Form>
  );
};
// memo чтобы форма не обновлялась и не сбрасывала поля при каждом изменении state фильтров
export default memo(OrderFilterForm);
