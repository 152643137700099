import { AttachmentDeps } from 'config/constants/types';
import { DocumentTypes } from 'config/constants/document-types';
import { useDependentAttachments } from '@/features/attachments/hooks/use-dependent-attachments';

export const useVehicleDocsSubform = ({ vehicleId }: { vehicleId?: number }) => {
  const {
    attachments: attachmentsFrontDoc,
    deleteAttachmentById: deleteAttachmentFrontDocById,
    postAttachments: postAttachmentsFrontDoc,
    downloadAttachments: downloadAttachmentsFrontDoc,
  } = useDependentAttachments({
    dependent: AttachmentDeps.Vehicle,
    dependentId: vehicleId,
    type: DocumentTypes.dlDocumentVehicleFrontDoc,
  });

  const {
    attachments: attachmentsBackDoc,
    deleteAttachmentById: deleteAttachmentBackDocById,
    postAttachments: postAttachmentsBackDoc,
    downloadAttachments: downloadAttachmentsBackDoc,
  } = useDependentAttachments({
    dependent: AttachmentDeps.Vehicle,
    dependentId: vehicleId,
    type: DocumentTypes.dlDocumentVehicleBackDoc,
  });

  const {
    attachments: attachmentsVDPDoc,
    deleteAttachmentById: deleteAttachmentVDPDocById,
    postAttachments: postAttachmentsVDPDoc,
    downloadAttachments: downloadAttachmentsVDPDoc,
  } = useDependentAttachments({
    dependent: AttachmentDeps.Vehicle,
    dependentId: vehicleId,
    type: DocumentTypes.dlDocumentVehicleVDPDoc,
  });

  const {
    attachments: attachmentsExtraDoc,
    deleteAttachmentById: deleteAttachmentExtraDocById,
    postAttachments: postAttachmentsExtraDoc,
    downloadAttachments: downloadAttachmentsExtraDoc,
  } = useDependentAttachments({
    dependent: AttachmentDeps.Vehicle,
    dependentId: vehicleId,
    type: DocumentTypes.dlDocumentVehicleExtraDoc,
  });

  const documentsFields: Array<{
    name: string;
    label: string;
    actions: {
      savedFileList?: Array<{ id: number; name: string; extension: string }>;
      onDeleteFileClick?: (documentId: number) => void;
      onIconClick?: (documentId: number) => void;
      onImmediateUploadFile?: (file: File[]) => void;
    };
  }> = [
    {
      name: 'vehicleFrontDoc',
      label: '1. Лицевая сторона, с информацией про ТС (рег. номер, марка, модель и т.д.)',
      actions: {
        savedFileList: attachmentsFrontDoc,
        onDeleteFileClick: deleteAttachmentFrontDocById,
        onIconClick: downloadAttachmentsFrontDoc,
        onImmediateUploadFile: postAttachmentsFrontDoc,
      },
    },
    {
      name: 'vehicleBackDoc',
      label: '2. Обратная сторона, с информацией про собственника (ФИО и т.д.)',
      actions: {
        savedFileList: attachmentsBackDoc,
        onDeleteFileClick: deleteAttachmentBackDocById,
        onIconClick: downloadAttachmentsBackDoc,
        onImmediateUploadFile: postAttachmentsBackDoc,
      },
    },
    {
      name: 'VDPDoc',
      label: '3. Паспорт транспортного средства, ПТС (Необязательно)',
      actions: {
        savedFileList: attachmentsVDPDoc,
        onDeleteFileClick: deleteAttachmentVDPDocById,
        onIconClick: downloadAttachmentsVDPDoc,
        onImmediateUploadFile: postAttachmentsVDPDoc,
      },
    },
    {
      name: 'extraDoc',
      label: '4. Другие документы',
      actions: {
        savedFileList: attachmentsExtraDoc,
        onDeleteFileClick: deleteAttachmentExtraDocById,
        onIconClick: downloadAttachmentsExtraDoc,
        onImmediateUploadFile: postAttachmentsExtraDoc,
      },
    },
  ];

  return { documentsFields };
};
