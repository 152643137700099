import { FC } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { VerificationFormMode } from 'components/verification-forms/types';
import { UiIconButton } from '@/ui-elements';
import { useIcons } from '@/hooks/use-icons';

export const DriverHeaderSubform: FC<{ mode: VerificationFormMode; onClose: VoidFunction; driverName: string }> = ({ mode, onClose, driverName }) => {
  const { CloseIcon } = useIcons();
  let text = '';
  switch (mode) {
    case 'check':
      text = 'Вы проверяете водителя:';
      break;
    case 'edit':
      text = `Вы редактируете водителя ${driverName || ''}`;
      break;
    case 'create':
      text = 'Вы добавляете Водителя к организации';
      break;
    default:
      break;
  }
  return (
    <HStack spacing={0} justify="space-between">
      <Text className="carriers-verification-form__title">{text}</Text>
      {mode === 'check' && <UiIconButton className="ui-sidebar__close-button" aria-label="close" icon={CloseIcon} onClick={onClose} />}
    </HStack>
  );
};
