import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { NavigationMenu } from '@/types/routes/navigation-menu';

type UseSplitTopMenu = {
  menu: NavigationMenu['items'];
};

type TopMenu = { desktopMenu: NavigationMenu['items']; burgerMenu: NavigationMenu['items'] };

const menuItemWidth = 200;
const maxInnerWidth = 1800;
const padding = 300;
const maxDesktopWidth = 500;

export const useSplitTopMenu = ({ menu }: UseSplitTopMenu) => {
  const [topMenu, setTopMenu] = useState<TopMenu>({
    desktopMenu: menu,
    burgerMenu: [],
  });
  useEffect(() => {
    const splitMenu = (target: Window | null) => {
      if (!target) return;

      const currentWidth = target.innerWidth > maxInnerWidth ? maxInnerWidth - 50 : target.innerWidth - padding;
      const desktopMenuItemsAmount = Math.floor(currentWidth / menuItemWidth);

      const currentMenu = menu.reduce(
        (resultCurrentMenu, itemRoute, index) => {
          if (index <= desktopMenuItemsAmount && currentWidth > maxDesktopWidth) {
            resultCurrentMenu.desktopMenu.push(itemRoute);
          } else {
            resultCurrentMenu.burgerMenu.push(itemRoute);
          }
          return resultCurrentMenu;
        },
        { desktopMenu: [], burgerMenu: [] } as TopMenu
      );

      setTopMenu((prevMenu) => (!isEqual(prevMenu, currentMenu) ? currentMenu : prevMenu));
    };

    splitMenu(window);

    const spyOn = (event: Event) => {
      splitMenu(event.target as Window);
    };

    window.addEventListener('resize', spyOn);
    return () => window.removeEventListener('resize', spyOn);
  }, [menu]);

  return { topMenu };
};
