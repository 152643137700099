import { useOrderServicesActions } from '@/features/order-services/hooks/use-order-services-actions';
import { useOrderInvalidate } from '@/features/order/hooks/use-order-invalidate';
import { useOrderUpdates } from '@/features/order/hooks/use-order-updates';
import { AddServiceFormFields } from './type';

export const useAddServiceWidget = (orderId: number, hideWidget: VoidFunction) => {
  // const {
  //   order: { data: orderResp },
  // } = useOrderUpdates({ id: orderId });
  const { order } = useOrderUpdates({ id: orderId });
  // const order = orderResp?.data;
  const { addOrderService } = useOrderServicesActions(orderId);
  const { invalidateOrderServices } = useOrderInvalidate();

  const handleSubmit = (fields: AddServiceFormFields) => {
    const { carrierPrice, carrierVat, customerPrice, customerVat, ...rest } = fields;
    const request = {
      ...rest,
      carrierPayment: {
        price: carrierPrice,
        vat: carrierVat,
      },
      customerPayment: {
        price: customerPrice,
        vat: customerVat,
      },
    };
    addOrderService.mutate(request, {
      onSuccess: () => {
        invalidateOrderServices(orderId);
        hideWidget();
      },
    });
  };
  return { order, handleSubmit };
};
