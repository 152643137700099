import { FC } from 'react';
import { useIcons } from '@/hooks/use-icons';

type Props = {
  riskGroup: number;
};
export const DriverRiskGroup: FC<Props> = ({ riskGroup }) => {
  const { RiskGroupOne, RiskGroupTwo, RiskGroupThree, RiskGroupFour, RiskGroupFive, RiskGroupSix } = useIcons();
  const getIcon = () => {
    switch (riskGroup) {
      case 1:
        return <RiskGroupOne />;
      case 2:
        return <RiskGroupTwo />;
      case 3:
        return <RiskGroupThree />;
      case 4:
        return <RiskGroupFour />;
      case 5:
        return <RiskGroupFive />;
      case 6:
        return <RiskGroupSix />;
      default:
        return <RiskGroupOne />;
    }
  };
  return getIcon();
};
