import * as yup from 'yup';
import moment from 'moment';
import { ErrorMessage } from '@/helpers/forms/validation-schemas/error-messages';

export const requiredString = yup.string().required(ErrorMessage.Required);
export const requiredObject = yup.object().required(ErrorMessage.Required);

export const PASSPORT_DATE_RULE = requiredString.test('is-passport-date-valid', ErrorMessage.Passport_Date_Invalid, (value, testContext) => {
  return moment(Number(value) || value).diff(moment(Number(testContext.parent.birthday) || testContext.parent.birthday), 'years') >= 14;
});
export const BIRTHDAY_RULE = requiredString.test('is-birth-date-valid', ErrorMessage.Birth_Date_Invalid, (value) => {
  return moment().diff(moment(Number(value) || value), 'years') >= 18;
});

export const PASSPORT_RULES = {
  passport: requiredString,
  passportGiven: requiredString,
};

export const DRIVER_LICENSE_RULES = {
  driverLicense: requiredString,
  driverLicenseDate: requiredString,
};

export const DRIVER_RULES = {
  country: requiredString,
  phoneNumber: requiredString,
  surname: requiredString,
  name: requiredString,
  ...DRIVER_LICENSE_RULES,
};

export const DRIVER_CARRIER_RULES = {
  carrierId: requiredObject,
};

export const CARRIER_ORGANIZATION_RULES = {
  organizationTypeID: requiredObject,
  INN: requiredString,
  directorSurname: requiredString,
  directorName: requiredString,
  passportNumber: requiredString,
  passportGiven: requiredString,
  name: requiredString,
};

export const ORGANIZATION_RULES = {
  ...CARRIER_ORGANIZATION_RULES,
  legalAddress: requiredString,
};
