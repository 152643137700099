import { FC, useState } from 'react';
import { EMPTY_STRING } from 'config/constants/common-strings';
import { Box } from '@chakra-ui/react';
import { NotificationFullInfo } from 'components/notifications-popover/notification-full-info';
import { AppNotification } from '@/types/models/app-notification';
import './notification-block.scss';
import { trimLongMessage } from '@/helpers/trim-long-message';
import { MOMENT_24_HOUR_TIME, MOMENT_DD_MM_YY } from '@/helpers/moment/constants';
import { formatMomentDate } from '@/helpers/moment/format-moment-date';
import { formatMomentLocal } from '@/helpers/moment/format-moment-local';
import { getNotificationTitle } from '@/features/notifications/get-notification-title';
import { emptyFunction } from '@/helpers/empty-function';

export const NotificationBlock: FC<{ item: AppNotification; navigateToOrderClick?: (id: number) => void; markAsReadClick?: VoidFunction }> = ({
  navigateToOrderClick,
  item,
  markAsReadClick = emptyFunction,
}) => {
  const [infoOpened, setInfoOpened] = useState(false);

  if (infoOpened) {
    return <NotificationFullInfo item={item} setInfoOpened={setInfoOpened} navigateToOrderClick={navigateToOrderClick} />;
  }

  return (
    <Box key={item.id} className="notification-block">
      <Box display="flex" justifyContent="space-between">
        <Box display="flex">
          {!item.viewed && <Box as="span" className="notification-block__status" />}
          <Box as="h4" className={`notification-block__type ${item.viewed ? '' : 'notification-block__type-active'}`}>
            {getNotificationTitle(item.action)}
          </Box>
        </Box>
        <Box className="notification-block__time-wrapper">
          <Box as="span">{formatMomentDate(item.created, MOMENT_DD_MM_YY, false, true)} </Box>
          <Box as="span">{formatMomentLocal(item.created, MOMENT_24_HOUR_TIME, true)}</Box>
        </Box>
      </Box>
      <Box className="notification-block__desc">
        {/* eslint-disable-next-line */}
        {trimLongMessage(item.action?.message || EMPTY_STRING, 115)}
        <Box pl={1} as="span" cursor="pointer" onClick={() => setInfoOpened(true)}>
          Подробнее
        </Box>
      </Box>
      {!item.viewed && (
        <Box className="notification-block__mark">
          <Box as="span" cursor="pointer" onClick={markAsReadClick}>
            Отметить прочитанным
          </Box>
        </Box>
      )}
    </Box>
  );
};
