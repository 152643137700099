import { FC } from 'react';
import { Box, GridItem, Text } from '@chakra-ui/react';
import { VerificationRiskGroupOptions } from 'components/carriers-verification/config';
import { VerificationFormMode } from 'components/verification-forms/types';
import { UiCheckboxField, UiSelectField } from '@/ui-elements';

export const DriverSecuritySubform: FC<{ mode: VerificationFormMode; initialValues: any }> = ({ mode, initialValues }) => {
  return (
    <>
      {mode === 'check' && (
        <>
          <GridItem mt={3.5}>
            <UiSelectField options={[]} name="departmentId" label="К какой организации относится" required />
          </GridItem>
          <GridItem mt={3.5} className="carriers-verification-form__row">
            <UiSelectField
              options={VerificationRiskGroupOptions}
              name="riskGroup"
              label="Назначьте группу риска"
              placeholder="Группа риска не назначена"
              content="Определите группу риска"
              required
            />
          </GridItem>
          <GridItem>
            <Box>
              <Text className="carriers-verification-form__subtitle">{initialValues?.formattedName}</Text>
            </Box>
            <Box mt={-2}>
              <Text className="carriers-verification-form__phone">{initialValues?.phoneNumber}</Text>
            </Box>
          </GridItem>
          <GridItem mt={0.3} colSpan={2}>
            <UiCheckboxField name="selfAssignment" label="С правом самоназначения на рейс" defaultChecked={!!initialValues?.selfAssignment} />
          </GridItem>
        </>
      )}
      {mode !== 'check' && (
        <GridItem mt={0.3} colSpan={2}>
          <UiCheckboxField name="selfAssignment" label="С правом самоназначения на рейс" defaultChecked={!!initialValues?.selfAssignment} />
        </GridItem>
      )}
    </>
  );
};
