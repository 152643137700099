import { IconButton, IconButtonProps, Icon } from '@chakra-ui/react';
import { forwardRef, ReactElement } from 'react';
import classNames from 'classnames';
import './icon-button.scss';
import { isNil } from 'lodash';

export type UiIconButtonProps = Omit<IconButtonProps, 'icon'> & {
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  icon?: string | ReactElement | React.ElementType;
  className?: string;
  amount?: number;
  containerClassName?: string;
};

export const UiIconButton = forwardRef<HTMLDivElement, UiIconButtonProps>((props, ref) => {
  const { loading, disabled, size = '20px', children, onClick, icon, className, containerClassName, amount, ...rest } = props;
  const isDisabled = disabled || loading;

  return (
    <div className={classNames('ui-button-icon-container', containerClassName)}>
      <IconButton
        isLoading={loading}
        isDisabled={isDisabled}
        // @ts-ignore Несовместимые типы в ChakraUI Icon и IconButton
        icon={icon && <Icon as={icon} />}
        size={size}
        onClick={(e) => !isDisabled && onClick?.(e)}
        className={classNames(className, 'ui-button-icon', {
          'ui-button-icon_disabled': isDisabled,
        })}
        {...rest}
        ref={ref}
      />
      {!isNil(amount) && amount > 0 && <div className="ui-button-icon_notification">{amount}</div>}
    </div>
  );
});
