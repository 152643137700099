import { isEmpty } from 'lodash';
import { TemplateContractFormFields, TemplateContractFormValues } from '@/features/contract-template/types/form';
import { ClientContractTemplateData, ClientContractTemplateRouteType } from '@/api/documents/templates-types';
import { getTypedObjectKeys } from '@/helpers/types/get-types-object-keys';

type UpdateTemplateContractDataProps = {
  templateData: ClientContractTemplateData;
  updatedValues?: TemplateContractFormValues;
};

export const updateRouteValues = (route: ClientContractTemplateRouteType, updatedRoute: Record<TemplateContractFormFields, any>) => {
  if (!updatedRoute) {
    return route;
  }
  return getTypedObjectKeys<TemplateContractFormFields>(updatedRoute).reduce((result, key) => {
    route[key] = updatedRoute[key];

    return result;
  }, route);
};

export const updateTemplateContractData = ({ templateData, updatedValues }: UpdateTemplateContractDataProps) => {
  if (isEmpty(updatedValues)) return null;

  const routes = templateData?.Routes?.map((route, routeInd) => updateRouteValues(route, updatedValues?.routeDetails?.[routeInd]));
  return { ...templateData, Routes: routes ?? [] };
};
