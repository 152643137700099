import { FC } from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { UiFileUploadField, UiFileUploadInput } from '@/ui-elements';
import { ResourcesSidebarMode } from '@/features/resources/types/general';
import { ResourcesFormFieldNames } from '@/features/resources/constants/form';
import { UseDependentAttachmentsReturnValues } from '@/features/attachments/hooks/use-dependent-attachments';

interface Props {
  mode: ResourcesSidebarMode;
  fieldName: ResourcesFormFieldNames;
  label: string;
  attachmentsAndHandlers?: UseDependentAttachmentsReturnValues;
}

export const ResourcesSubformAttachment: FC<Props> = ({ mode, label, fieldName, attachmentsAndHandlers }) => {
  return (
    <Box>
      <Heading as="h3" className="resources-form-subtitle">
        {label}
      </Heading>
      {mode === ResourcesSidebarMode.CreateForm ? (
        <UiFileUploadField required name={fieldName} />
      ) : (
        <UiFileUploadInput
          name={fieldName}
          required
          savedFileList={attachmentsAndHandlers?.attachments}
          onDeleteFileClick={attachmentsAndHandlers?.deleteAttachmentById}
          onIconClick={attachmentsAndHandlers?.downloadAttachments}
          onImmediateUploadFile={attachmentsAndHandlers?.postAttachments}
        />
      )}
    </Box>
  );
};
