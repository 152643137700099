import { toast } from 'react-toastify';
import { Box, Modal, ModalContent, ModalOverlay, Flex, HStack, Spacer, Text } from '@chakra-ui/react';
import { Form } from 'react-final-form';
import closedIcon from 'public/assets/icons/CloseIcon.svg';
import { UiIconButton, UiButtonOutlined, UiButtonFilled, UiFileUploadField } from '@/ui-elements';

type Props = {
  modalOpened: boolean;
  onClose: VoidFunction;
  postAttachments?: (files: File[], callback: Function) => void;
};

type FormFields = {
  documentsToUpload: Array<File>;
};

export const DocumentsUploadModal = ({ modalOpened, onClose, postAttachments }: Props) => {
  const onSuccess = () => {
    toast.success('Документы успешно загружены');
    onClose();
  };
  const onSubmit = (values: FormFields) => postAttachments?.(values.documentsToUpload, onSuccess);

  return (
    <Modal isOpen={modalOpened} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={24} boxShadow="xl" pos="relative" maxW="600px" w="600px" p={10}>
        <Box position="absolute" right={12} top="43px">
          <UiIconButton aria-label="close-modal-button" icon={closedIcon} onClick={onClose} />
        </Box>
        <Text textStyle="h3" mb={6}>
          Документы по точке
        </Text>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, pristine, submitting }) => (
            <form onSubmit={handleSubmit}>
              <UiFileUploadField name="documentsToUpload" />
              <Flex w="full" justify="end" mt={6}>
                <HStack spacing={0}>
                  <UiButtonOutlined mode="dark" isDisabled={submitting} onClick={onClose}>
                    Отменить
                  </UiButtonOutlined>
                  <Spacer w={4} />
                  <UiButtonFilled type="submit" isDisabled={pristine || submitting}>
                    Загрузить документы
                  </UiButtonFilled>
                </HStack>
              </Flex>
            </form>
          )}
        </Form>
      </ModalContent>
    </Modal>
  );
};
