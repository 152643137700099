import './load-taken-for-calculation.scss';
import classNames from 'classnames';
import { TAKEN_FOR_CALCULATION } from '@/config/constants/loads';
import { UiText } from '@/ui-elements/ui-text/ui-text';

export const LoadTakenForCalculation = ({ isLoadInCalculation, className }: { isLoadInCalculation?: boolean; className?: string }) => {
  return (
    <UiText className={classNames('load-taken-for-calculation', className, { 'load-taken-for-calculation_hidden': !isLoadInCalculation })}>
      {TAKEN_FOR_CALCULATION}
    </UiText>
  );
};
