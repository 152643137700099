import { useQuery } from '@tanstack/react-query';
import { OrdersApi } from '@/api/orders';

export const useGetPointComment = (orderId: number, pointId: number) => {
  const getPointComment = useQuery({
    queryKey: ['getPointComment', pointId],
    enabled: !!orderId,
    refetchOnWindowFocus: false,
    queryFn: () => OrdersApi.getWaypointComment({ orderId, pointId }),
  });

  return {
    getPointComment,
  };
};
