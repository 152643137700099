import './load-card-payment-details.scss';
import classNames from 'classnames';
import { FC } from 'react';
import { isEmpty } from 'lodash';
import { Text } from '@chakra-ui/react';
import { VStack } from '@chakra-ui/layout';
import { Payment } from '@/types/models/payment';
import { getCardPriceTitle } from '@/features/loads/helpers/get-card-title-price';

interface Props {
  customerPayment?: Payment;
  carrierPayment?: Omit<Payment, 'price'>;
  size?: 'standart' | 'small';
}

export const LoadCardPaymentDetails: FC<Props> = ({ customerPayment, carrierPayment, size = 'standart' }) => {
  if (isEmpty(customerPayment) || isEmpty(carrierPayment)) return null;

  const customerWithVatPrice = getCardPriceTitle('customer', customerPayment?.vat, customerPayment.price);
  const carrierWithVatPrice = getCardPriceTitle('carrier', true, carrierPayment.priceWithVAT);
  const carrierWithoutVatPrice = getCardPriceTitle('carrier', false, carrierPayment.priceWithoutVAT);

  return (
    <VStack spacing="0" alignItems="flex-start" className={classNames('load-card-payment-details', { small: size === 'small' })}>
      <Text>{customerWithVatPrice}</Text>
      <Text>{carrierWithVatPrice}</Text>
      <Text>{carrierWithoutVatPrice}</Text>
    </VStack>
  );
};
