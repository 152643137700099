import { FuelStatus } from 'config/constants/statuses';
import { orderFuelStatusMap } from 'config/order-fuel-status-map';
import { Order } from '@/types/models/order';
import { Load } from '@/types/models/loads';

/*
 * Проверка доступности перехода в следующий статус топлива согласно карте
 * */
export const checkOrderNextFuelStatus = (order: Order | Load, nextStatus?: FuelStatus): boolean => {
  const map = order.fuelStatus && orderFuelStatusMap[order.fuelStatus].possibleNextStatuses;
  if (!map) return false;
  return !!nextStatus && map.includes(nextStatus);
};
