import { isArray } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import { isLastPage } from '@/helpers/api/is-last-page';
import { FirstRender } from '../features/order/hooks/use-first-render';
import { INTERVAL_REQUEST_DELAY } from '@/config/constants/interval-request-delay';
import { ResponseWithPager } from '@/types/models/pager';

type Props = {
  query?: any;
  queryKey: string;
  refetchOnWindowFocus: boolean;
  queryFn: (isFirst: boolean) => ({ pageParam }: { pageParam?: number | undefined }) => Promise<ResponseWithPager<any>>;
  enabled?: boolean;
};

export const useInfiniteQueryWithUpdates = ({ query, queryKey, refetchOnWindowFocus = false, queryFn, enabled }: Props) => {
  const { isFirst } = FirstRender();

  // @ts-ignore
  return useInfiniteQuery({
    queryKey: [queryKey, { ...query }],
    refetchOnWindowFocus,
    queryFn: queryFn(isFirst),
    getNextPageParam: ({ pager }) => (isLastPage(pager) ? undefined : pager.page + 1),
    refetchInterval: INTERVAL_REQUEST_DELAY,
    structuralSharing: (oldData, newData) => {
      const oldDataArr = oldData?.pages?.[0]?.data;
      const newDataArr = newData?.pages?.[0]?.data;
      if (oldDataArr && newDataArr === null) {
        return oldData;
      }
      if (!oldDataArr && newDataArr) {
        if (isArray(newDataArr)) {
          return newData;
        }
        return [];
      }
      if (!oldDataArr && !newDataArr) {
        return [];
      }
      if (oldDataArr && isArray(newDataArr)) {
        return newData;
      }
      return [];
    },
    enabled,
  });
};
