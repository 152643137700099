import { FC } from 'react';
import { SidebarWrapper } from 'components/sidebar-wrapper/sidebar-wrapper';
import { DetailsComponent } from 'components/carriers-loads/details/details-component/details-component';
import { CarrierLoadsPageWithOrdersSidebarProps } from 'components/carriers-loads/sidebar/constants';
import { isNil } from 'lodash';

export const OrdersWithMyParticipationSidebar: FC<CarrierLoadsPageWithOrdersSidebarProps> = ({ closeSidebar, onOrderStart, order }) => {
  if (isNil(order) || isNil(onOrderStart)) return null;

  return (
    <SidebarWrapper closeSidebar={closeSidebar}>
      <DetailsComponent order={order} onOrderStart={onOrderStart} />
    </SidebarWrapper>
  );
};
