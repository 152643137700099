import { CarrierOrganizationBankDetails } from '@/types/models/carrier';

export const carrierBankDetailsToFormValues = (bankDetails?: CarrierOrganizationBankDetails) => {
  return {
    bankName: bankDetails?.name,
    bik: bankDetails?.bik,
    bankAccount: bankDetails?.bankAccount,
    correspondentAccount: bankDetails?.correspondentAccount,
    bankDetailsId: bankDetails?.id,
  };
};
