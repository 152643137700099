import { FC, MouseEventHandler } from 'react';
import { Box, Divider, HStack, Spacer } from '@chakra-ui/react';
import { CANCEL_DOCS, CONFIRM_DOCS, CONFIRMED_DOCS, SAVE_DOCS } from 'components/order-details/order-details-documents/texts';
import classNames from 'classnames';
import { OrderExecutionStatus, OrderStatus } from 'config/constants/statuses';
import { FileDocument } from '@/types/models/document';
import { UiButtonFilled, UiButtonOutlined, UiFileUploadInput } from '@/ui-elements';
import { VoidResponseFunction } from '@/types/common/void-function';
import { UiText } from '@/ui-elements/ui-text/ui-text';
import { useIcons } from '@/hooks/use-icons';
import { emptyFunction } from '@/helpers/empty-function';

type Props = {
  title: string;
  subtitle?: string;
  confirmed?: boolean;
  confirmedStatus?: boolean;
  onPreparationClick?: VoidFunction;
  documents: Array<FileDocument>;
  disabled?: boolean;
  onUpload?: VoidResponseFunction<File[]>;
  onSaveClick: VoidResponseFunction<number>;
  onSaveAllClick?: VoidFunction;
  onDeleteClick?: VoidResponseFunction<number>;
  onConfirmClick?: VoidFunction;
  showSaveAll?: boolean;
  confirmableWithOutDocs?: boolean;
  children?: JSX.Element;
  onDocumentsClick?: Function;
  orderDocumentCreation?: boolean;
  orderDocumentCreationDisabled?: boolean;
  onConfirmDocClick?: VoidFunction;
  onDeclineDocClick?: VoidFunction;
  orderStatus?: OrderStatus;
  loading?: boolean;
};
export const OrderDetailsDocumentsConfirm: FC<Props> = ({
  title,
  orderDocumentCreation,
  subtitle,
  documents,
  disabled,
  onUpload = emptyFunction,
  onSaveClick,
  onDeleteClick,
  onConfirmClick,
  confirmed,
  showSaveAll,
  onSaveAllClick,
  children,
  confirmedStatus,
  onPreparationClick,
  confirmableWithOutDocs = false,
  onDocumentsClick,
  orderDocumentCreationDisabled,
  onConfirmDocClick,
  onDeclineDocClick,
  orderStatus,
  loading,
}) => {
  const { DocumentsAccepted, CreateOrder } = useIcons();
  return (
    <div>
      <Divider className="order-details-documents__divider" />
      <div className="order-details-documents__confirm">
        <div className="order-details-documents__formtitle">
          <HStack justifyContent="space-between">
            <HStack w="full">
              {confirmed && <DocumentsAccepted />}
              <UiText display="inline" color="typography.400">
                {title}
              </UiText>
              {subtitle && (
                <UiText display="inline" color="typography.200">
                  {` ( ${subtitle} )`}
                </UiText>
              )}
              {orderDocumentCreation && (
                <>
                  <Spacer />
                  <UiButtonOutlined mode="dark" onClick={onDocumentsClick as MouseEventHandler<HTMLButtonElement>} leftIcon={CreateOrder}>
                    Создать договор-заявку
                  </UiButtonOutlined>
                </>
              )}
              {orderDocumentCreationDisabled && (
                <>
                  <Spacer />
                  <UiButtonOutlined mode="dark" disabled leftIcon={CreateOrder} tooltip="В разработке">
                    Создать договор-заявку
                  </UiButtonOutlined>
                </>
              )}
            </HStack>
            {children}
          </HStack>
        </div>
        <HStack>
          <UiFileUploadInput
            name="file"
            savedFileList={documents}
            onDeleteFileClick={onDeleteClick}
            onIconClick={onSaveClick}
            onImmediateUploadFile={onUpload}
            disableUpload={disabled}
            disabled={disabled}
          />
        </HStack>
      </div>
      {onConfirmDocClick && onDeclineDocClick && (
        <Box mt={7} justifyContent="right" display="flex">
          {(orderStatus === OrderExecutionStatus.Confirmed || orderStatus === OrderExecutionStatus.Preparation) && confirmed && (
            <UiButtonFilled
              className="order-details-documents__actions-confirm"
              disabled={!documents.length || disabled}
              onClick={onDeclineDocClick}
              mode="dark"
            >
              Отменить подтверждение
            </UiButtonFilled>
          )}
          <UiButtonFilled
            className="order-details-documents__actions-confirm"
            disabled={!documents.length || disabled || confirmed}
            onClick={onConfirmDocClick}
          >
            {(!documents.length || disabled || confirmed) && !confirmableWithOutDocs ? CONFIRMED_DOCS : CONFIRM_DOCS}
          </UiButtonFilled>
        </Box>
      )}

      <HStack className={classNames('order-details-documents__actions')} gap={2}>
        {!confirmed && onConfirmClick && (
          <UiButtonFilled
            className="order-details-documents__actions-confirm"
            disabled={(!documents.length || disabled || confirmed) && !confirmableWithOutDocs}
            onClick={onConfirmClick}
            isLoading={loading}
          >
            {(!documents.length || disabled || confirmed) && !confirmableWithOutDocs ? CONFIRMED_DOCS : CONFIRM_DOCS}
          </UiButtonFilled>
        )}
        {confirmedStatus && (
          <UiButtonFilled className="order-details-documents__actions-confirm" onClick={onPreparationClick}>
            {CANCEL_DOCS}
          </UiButtonFilled>
        )}
        {showSaveAll && (
          <UiButtonOutlined className="order-details-documents__actions-save" disabled={!documents.length} onClick={onSaveAllClick}>
            {SAVE_DOCS}
          </UiButtonOutlined>
        )}
      </HStack>
    </div>
  );
};
