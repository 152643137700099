import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(accordionAnatomy.keys);

const overflowVisible = definePartsStyle({
  container: {
    '.chakra-collapse': {
      overflow: 'visible !important',
    },
  },
});

export const accordionTheme = defineMultiStyleConfig({
  variants: { overflowVisible },
});
