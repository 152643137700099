import './loads-form.scss';
import { FC, memo } from 'react';
import { FormSpy } from 'react-final-form';
import { Box } from '@chakra-ui/react';
import { LoadsFormValues } from '@/types/models/loads';
import { initialLoadsFormValues } from '@/config/constants/loads';
import { FormAutosave } from '@/hooks/use-autosave';
import { useForm } from '@/helpers/forms/use-form';
import { emptyFunction } from '@/helpers/empty-function';
import { UiDivider } from '@/ui-elements';
import { LoadsFilters } from '@/components/loads/filters/loads-filters';
import { LoadsSort } from '@/components/loads/sort/loads-sort';
import { LoadsFormHeader } from '@/components/loads/form-header/loads-form-header';

type Props = {
  allFound: number;
  disabled: boolean;
  onFormValuesChange: (values: LoadsFormValues) => void;
  handleRefetchLoads: () => void;
};

const LoadsForm: FC<Props> = ({ allFound = 0, disabled = false, onFormValuesChange = emptyFunction, handleRefetchLoads }) => {
  const Form = useForm<LoadsFormValues>();

  return (
    <Box className="loads-form">
      <Form onSubmit={emptyFunction} initialValues={initialLoadsFormValues}>
        <FormSpy subscription={{ values: true }}>{({ values }) => <FormAutosave values={values} onSave={onFormValuesChange} />}</FormSpy>
        <LoadsFormHeader allFound={allFound} disabled={disabled} handleRefetchLoads={handleRefetchLoads} />
        <UiDivider pt={3} variant="dark" />
        <LoadsFilters disabled={disabled} />
        <LoadsSort disabled={disabled} />
      </Form>
    </Box>
  );
};

export default memo(LoadsForm);
