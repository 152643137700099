import { ResourcesContentTab, ResourcesStatusTab } from '@/features/resources/types/general';

export const RESOURCES_NO_ORGANISATION = 'Вы сможете добавлять водителей и ТС только после проверки вашей организации.';
export const RESOURCES_NO_DRIVERS = `Добавьте водителей и документы по ним, чтобы учавствовать в рейсах. 
Если вы являетесь водителем и владельцем компании, добавьте информацию о себе и о своем ТС.`;
export const RESOURCES_NO_VEHICLES =
  'Добавьте транспортные средства и документы по ним, чтобы учавствовать в рейсах. Если вы являетесь водителем, добавьте информацию о своих ТС.';

export const ResourcesButtonActions = {
  AddOrganisation: 'Добавить организацию',
  AddDriver: 'Добавить водителя',
  AddDVehicle: 'Добавить ТС',
  Edit: 'Редактировать',
  DeleteDriver: 'Удалить водителя',
  DeleteVehicle: 'Удалить ТС',
  Details: 'Подробнее',
  Cancel: 'Отменить',
  SendForApproval: 'На проверку в СБ',
  Save: 'Сохранить',
  SaveAndRegister: 'Сохранить и зарегистрировать',
  ForgetPassword: 'Забыл пароль',
  ResetPassword: 'Сбросить пароль',
};

/** Заголовки табов */
export const ResourcesContentTabNames: Record<string, string> = {
  [ResourcesContentTab.Drivers]: 'Водители',
  [ResourcesContentTab.Vehicles]: 'Транспортные средства',
};
/** Заголовки табов для carrier_simple */
export const ResourcesContentTabNamesForDriver: Record<string, string> = {
  [ResourcesContentTab.Vehicles]: 'Транспортные средства',
};

/* Заголовки табов со статусами водителей и транспортных средств */
export const ResourcesStatusTabNames = {
  [ResourcesStatusTab.All]: 'Все',
  [ResourcesStatusTab.Checking]: 'На проверке',
  [ResourcesStatusTab.Checked]: 'Проверено',
  [ResourcesStatusTab.Canceled]: 'Отклонено',
};

export const listScrollStyle = { width: '100%', height: '100%' };

export const AVAILABLED_TEXT = {
  AVAILABLE: 'Есть',
  NOT_AVAILABLE: 'Не указан',
};
export const BAILED_TEXT = {
  BAILED: 'в залоге',
  NOT_BAILED: 'не в залоге',
};

export const EDIT_NOTIFY_TITLE = {
  [ResourcesContentTab.Drivers]: 'Вы действительно хотите отредактировать проверенные данные водителя?',
  [ResourcesContentTab.Vehicles]: 'Вы действительно хотите отредактировать проверенные данные Транспортного средства?',
};
export const EDIT_NOTIFY_DESC = {
  [ResourcesContentTab.Drivers]: `Вы не сможете принимать участие в рейсах и откликаться на заявки с этим водителем, 
  пока данные не будут проверены еще раз. Это может занять время до одного дня.`,
  [ResourcesContentTab.Vehicles]: `Вы не сможете принимать участие в рейсах и откликаться на заявки этим ТС, 
  пока данные не будут проверены еще раз. Это может занять время до одного дня.`,
};

export const EDIT_FORBIDDEN_TITLE = {
  [ResourcesContentTab.Drivers]: 'Вы не можете редактировать водителя на проверке, пожалуйста, подождите, пока водитель не будет проверен',
  [ResourcesContentTab.Vehicles]:
    'Вы не можете редактировать транспортное средство на проверке, пожалуйста, подождите, пока транспортное средство не будет проверено',
};
